import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, withLatestFrom, map, filter } from 'rxjs/operators';
import {
  getGeneralForm,
  getTermsForm,
  isGeneralTabNotSubmitted,
  selectOnBoardingStatus,
  selectSupplierDetail,
  selectTermsDropdownData
} from '../supplier-detail.selectors';
import { ChecklistNavigationTab, ChecklistNavigationTabStatus } from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';
import { SupplierDetail } from '../../models/supplier-detail';
import { OnBoardingStatus } from '../../models/onboarding-status';
import { SupplierFormService } from '../../services/supplier-form.service';
import { getFormValidationErrors } from '@app/utils/common-utils';
import { SupplierDetailTabs } from '../../models/supplier-detail-tabs';
import { isDraft, isInvitedDraftSupplier, isInvitedSupplier, shouldShowAAPFieldForGeneralTab } from '../../utilities/supplier-utils';
import { Contact, ContactType } from '@app/models/contact';
import { isTabApproved, isTabInReview, isTabNotSubmitted, isTabRejected } from '../../utilities/onboarding-utils';
import { OnboardingTabStatuses } from '@app/models/onboarding-tab-statuses';
import { DocumentStatus } from '@app/modules/supplier-documents/models/document-statuses';
import { Constants } from '@app/constants/constants';
import { Document } from '@app/modules/supplier-documents/models/document';
import { DocusignStatus } from '../../models/docusign-status';
import { UntypedFormGroup } from '@angular/forms';
import { ProductGroup } from '../../models/product-group';
import { convertTermsDecimalFields } from '@app/utils/form-utils';
import { TermsDropdownData } from '../../models/terms-dropdown-data';
import { TermsFormObject } from '../../models/terms-form-object';
import { DocumentType } from '@app/modules/supplier-documents/models/document-type';
import { brokerV2IsValidChanged } from '../../components/broker-tab-v2/broker-actions';
import { getAllProductGroups } from '@app/modules/product-groups/store/product-groups.selectors';
import { selectDocuments, selectDocumentsTabLiveStatus, selectDocumentTypes } from '@app/modules/supplier-documents/store/supplier-documents.selectors';
import { selectContactsTabValidated } from '@app/modules/supplier-contacts/store/supplier-contacts.selectors';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag';
import { cloneDeep } from 'lodash';
import { BrokerTabValidationStore } from '../../components/broker-tab-v2/broker-v2-tab-validation.store';
import { selectSupplierLocationContacts } from '@app/modules/supplier-locations/store/supplier-locations.selectors';
import { SupplierDetailService } from '../../services/supplier-detail.service';

import * as SupplierDocumentsActions from '@app/modules/supplier-documents/store/supplier-documents.actions';
import * as SupplierLocationActions from '@app/modules/supplier-locations/store/supplier-locations.actions';
import * as SupplierDetailActions from '../supplier-detail.actions';
import * as SupplierContactsActions from '@app/modules/supplier-contacts/store/supplier-contacts.actions';
import * as ProductGroupsActions from '@app/modules/product-groups/store/product-groups.actions';

@Injectable()
export class SupplierTabStatusEffects {
  constructor(
    private readonly actions$: Actions,

    private readonly store: Store,
    private readonly supplierFormService: SupplierFormService,
    private readonly supplierDetailService: SupplierDetailService,
    private readonly brokerV2ValidatorStore: BrokerTabValidationStore
  ) {}

  loadSupplierOnboardingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.loadSupplierDetailSuccess),
      withLatestFrom(this.store.select(selectSupplierDetail)),
      switchMap(([, supplierDetail]) => {
        return this.supplierDetailService
          .getSupplierOnboardingStatus(supplierDetail.esn)
          .pipe(
            map((onboardingStatus) =>
              SupplierDetailActions.loadOnBoardingStatusSuccess({
                onboardingStatus,
              })
            )
          );
      })
    )
  );

  checkSupplierValidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SupplierDetailActions.loadSupplierDetailSuccess,
        SupplierDetailActions.generalFormChanged,
        SupplierDetailActions.saveGeneralFormSuccess,
        SupplierDetailActions.termsFormChanged,
        SupplierDetailActions.saveTermsFormSuccess,
        SupplierDetailActions.brokerPatchSuccess,
        SupplierDetailActions.saveNewProductGroupFormSuccess,
        SupplierDetailActions.saveDCProductGroupFormSuccess,
        SupplierDetailActions.confirmCancelEdit,
        SupplierDetailActions.loadOnBoardingStatusSuccess,
        SupplierDetailActions.updateGeneralTabStatusSuccess,
        SupplierDetailActions.sendDocuSignEmailSuccess,
        SupplierDetailActions.saveSupplierFormSuccess,
        SupplierDetailActions.loadSupplierBrokerContactSuccess,
        SupplierDetailActions.saveProductGroupFormSuccess,
        SupplierDocumentsActions.updateDocumentTypeUploadStatus,
        SupplierDocumentsActions.updateDocumentStatusSuccess,
        SupplierContactsActions.loadSupplierContactsSuccess,
        SupplierContactsActions.saveSupplierContactSuccess,
        SupplierContactsActions.deleteSupplierContactSuccess,
        SupplierLocationActions.updateContacts,
        ProductGroupsActions.setProductGroups,
        brokerV2IsValidChanged
      ),
      withLatestFrom(
        this.store.select(selectSupplierDetail),
        this.store.select(getAllProductGroups),
        this.store.select(getGeneralForm),
        this.store.select(getTermsForm),
        this.store.select(selectDocumentTypes),
        this.store.select(selectDocuments),
        this.store.select(selectSupplierLocationContacts),
        this.store.select(selectOnBoardingStatus),
        this.store.select(selectTermsDropdownData),
        this.store.select(selectContactsTabValidated),
        this.store.select(selectDocumentsTabLiveStatus),
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.BrokerV2.key)),
        this.brokerV2ValidatorStore.isValid$,
        this.store.select(isGeneralTabNotSubmitted),
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.SupplierAAPLegalSignatures.key)),
      ),
      filter((data) => isDraft(data[1])),
      map(
        ([
          ,
          supplier,
          productGroups,
          general,
          terms,
          documentTypes,
          documents,
          contacts,
          onboardingStatus,
          termsDropdownData,
          contactsValidated,
          documentsTabLiveStatus,
          brokerV2,
          isBrokerV2Valid,
          isGeneralTabNotSubmitted,
          ffAAPLegalSignatures,
        ]) => {
          const tabs: ChecklistNavigationTab[] = [];

          const isTermsFormValid = this.isTermsFormValid(supplier, cloneDeep(terms), termsDropdownData);

          this.validateGeneralTab(tabs, supplier, cloneDeep(general), onboardingStatus, isGeneralTabNotSubmitted);
          this.validateUserTab(tabs, supplier);
          this.validateContactsTab(tabs, supplier, contactsValidated);
          this.validateBrokerTabV2(tabs, brokerV2, isBrokerV2Valid);
          this.validateLocationsTab(tabs, supplier, contacts);
          this.validateLegalSignatureTab(tabs, supplier, onboardingStatus, isTermsFormValid, ffAAPLegalSignatures);
          this.validateDocumentsTab(tabs, supplier, documentTypes, documents, documentsTabLiveStatus);
          this.validateTermsTab(tabs, supplier, cloneDeep(terms), termsDropdownData, onboardingStatus);
          this.validatePurchasingInfoTab(tabs, supplier, documents);
          this.validateProductGroupsTab(tabs, productGroups);
          this.validateHistoryTab(tabs);

          return SupplierDetailActions.supplierValidated({
            tabs
          });
        }
      )
    )
  );

  validateGeneralTab(tabs: ChecklistNavigationTab[], supplier: SupplierDetail, general: any,
                     onboardingStatus: OnBoardingStatus[], isGeneralTabNotSubmitted: boolean) {
    const generalForm = this.supplierFormService.getGeneralDefaultForm(supplier,
      shouldShowAAPFieldForGeneralTab(supplier, isGeneralTabNotSubmitted));
    generalForm.patchValue(general);
    this.supplierFormService.updateGeneralFormValidations(generalForm);
    const generalFormErrors = getFormValidationErrors(generalForm);
    // Same controller can have multiple errors eg: required + max length
    const uniqueGeneralFormErrorCount = new Set(
      generalFormErrors.map((item) => item.control)
    ).size;
    const generalTabStatus = this.getGeneralTabStatus(
      generalForm,
      supplier,
      onboardingStatus
    );
    tabs.push({
      tab: SupplierDetailTabs.general,
      status: generalTabStatus,
      completed: generalTabStatus === ChecklistNavigationTabStatus.checked,
      remainingCount: uniqueGeneralFormErrorCount,
    });
  }

  validateUserTab(tabs: ChecklistNavigationTab[], supplier: SupplierDetail) {
    if (isInvitedSupplier(supplier)) {
      tabs.push({
        tab: SupplierDetailTabs.users,
        completed: true,
        remainingCount: 0,
      });
    }
  }

  validateContactsTab(tabs: ChecklistNavigationTab[], supplier: SupplierDetail, contactsValidated: boolean) {
    if (isInvitedSupplier(supplier)) {
      tabs.push({
        tab: SupplierDetailTabs.contacts,
        completed: contactsValidated,
        remainingCount: 0,
      });
    }
  }

  validateBrokerTabV2(tabs: ChecklistNavigationTab[], brokerV2Flag: boolean, isBrokerV2Valid: boolean) {
    if (brokerV2Flag) {

      const brokerTabStatus = isBrokerV2Valid
        ? ChecklistNavigationTabStatus.checked
        : ChecklistNavigationTabStatus.unchecked;
      tabs.push({
        tab: SupplierDetailTabs.broker,
        status: brokerTabStatus,
        remainingCount: 0,
        completed: brokerTabStatus === ChecklistNavigationTabStatus.checked,
      });
    }
  }

  validateLocationsTab(tabs: ChecklistNavigationTab[], supplier: SupplierDetail, contacts: Contact[]) {
    if (isInvitedDraftSupplier(supplier)) {
      const locationsTabStatus = this.getLocationsTabStatus(contacts);
      tabs.push({
        tab: SupplierDetailTabs.locations,
        status: locationsTabStatus,
        remainingCount: 0,
        completed: locationsTabStatus === ChecklistNavigationTabStatus.checked,
      });
    }
  }

  validateLegalSignatureTab(
    tabs: ChecklistNavigationTab[],
    supplier: SupplierDetail,
    onboardingStatus: OnBoardingStatus[],
    isTermsFormValid: boolean,
    ffAAPLegalSignatures: boolean
  ) {
    if (isInvitedSupplier(supplier)) {
      const legalTabStatus: ChecklistNavigationTabStatus =
        this.getLegalSignaturesTabStatus(onboardingStatus, supplier, isTermsFormValid, ffAAPLegalSignatures);

      tabs.push({
        tab: SupplierDetailTabs.legalSignatures,
        remainingCount: 0,
        completed: legalTabStatus === ChecklistNavigationTabStatus.checked,
        status: legalTabStatus,
        disabled: isTabNotSubmitted(
          onboardingStatus,
          SupplierDetailTabs.general
        ),
      });
    }
  }

  validateDocumentsTab(
    tabs: ChecklistNavigationTab[],
    supplier: SupplierDetail,
    documentTypes: DocumentType[],
    documents: Document[],
    documentsTabLiveStatus: OnboardingTabStatuses,
  ) {
    if (isInvitedSupplier(supplier)) {
      const docTabStatus = this.getDocumentTabStatus(documentsTabLiveStatus);
      tabs.push({
        tab: SupplierDetailTabs.documents,
        completed: docTabStatus === ChecklistNavigationTabStatus.checked,
        status: docTabStatus,
        remainingCount: 0,
      });
    }
  }

  validateTermsTab(
    tabs: ChecklistNavigationTab[],
    supplier: SupplierDetail,
    terms: TermsFormObject,
    termsDropdownData: TermsDropdownData[],
    onboardingStatus: OnBoardingStatus[],
  ) {

    const termsForm: UntypedFormGroup = this.supplierFormService.getTermsDefaultForm(
      supplier,
      termsDropdownData
    );
    this.supplierFormService.patchTermsForm(supplier, termsForm);
    this.supplierFormService.overrideAAPTermsChanges(termsForm, supplier.terms);

    // In TermsFormObject saved in store percentage values will be 0.x format
    // So we need to multiply by 100 before patch on form
    convertTermsDecimalFields(terms);
    this.supplierFormService.patchTermsFormUpdates(terms, termsForm);

    const termsFormErrors = getFormValidationErrors(termsForm);
    // Same controller can have multiple errors eg: required + max length
    const uniqueTermsFormErrorCount = new Set(
      termsFormErrors.map((item) => item.control)
    ).size;

    if (isInvitedSupplier(supplier)) {
      const termsTabStatus: ChecklistNavigationTabStatus =
        this.getTermsTabStatus(onboardingStatus, termsForm.valid);
      tabs.push({
        tab: SupplierDetailTabs.supplierTerms,
        completed: termsTabStatus === ChecklistNavigationTabStatus.checked,
        status: termsTabStatus,
        remainingCount:
          termsTabStatus === ChecklistNavigationTabStatus.unchecked
            ? uniqueTermsFormErrorCount
            : 0,
      });
    } else {
      tabs.push({
        tab: SupplierDetailTabs.supplierTerms,
        completed: termsForm.valid,
        remainingCount: uniqueTermsFormErrorCount,
      });
    }
  }

  validatePurchasingInfoTab(
    tabs: ChecklistNavigationTab[],
    supplier: SupplierDetail,
    documents: Document[],
  ) {
    if (isInvitedSupplier(supplier)) {
      const purchasingInfoTabStatus = this.getPurchasingInfoTabStatus(documents);
      const purchasingInfo = {
        tab: SupplierDetailTabs.purchasingInfo,
        remainingCount: 0,
        status: purchasingInfoTabStatus,
        completed:
          purchasingInfoTabStatus === ChecklistNavigationTabStatus.checked,
      };

      tabs.push(purchasingInfo);
    }
  }

  validateProductGroupsTab(tabs: ChecklistNavigationTab[], productGroups: ProductGroup[]) {
    const pgWithDCPG = productGroups.filter(
      (item) => item.dcProductGroups && item.dcProductGroups.length > 0
    ).length;
    tabs.push({
      tab: SupplierDetailTabs.productGroups,
      completed: pgWithDCPG > 0,
      remainingCount: pgWithDCPG > 0 ? 0 : 1,
    });
  }

  validateHistoryTab(tabs: ChecklistNavigationTab[]) {
    tabs.push({
      tab: SupplierDetailTabs.history,
      completed: true,
      remainingCount: 0,
    });
  }

  private getGeneralTabStatus(
    generalForm: UntypedFormGroup,
    supplier: SupplierDetail,
    onboardingStatus: OnBoardingStatus[]
  ) {
    if (isTabInReview(onboardingStatus, SupplierDetailTabs.general)) {
      return ChecklistNavigationTabStatus.info;
    } else if (isTabRejected(onboardingStatus, SupplierDetailTabs.general)) {
      return ChecklistNavigationTabStatus.warning;
    } else if (
      isTabNotSubmitted(onboardingStatus, SupplierDetailTabs.general) &&
      isInvitedSupplier(supplier) &&
      isDraft(supplier)
    ) {
      return ChecklistNavigationTabStatus.unchecked;
    } else if (
      isTabApproved(onboardingStatus, SupplierDetailTabs.general) &&
      isInvitedSupplier(supplier) &&
      isDraft(supplier)
    ) {
      if (!generalForm.valid) {
        return ChecklistNavigationTabStatus.unchecked;
      }
      return ChecklistNavigationTabStatus.checked;
    }

    return generalForm.valid
      ? ChecklistNavigationTabStatus.checked
      : ChecklistNavigationTabStatus.unchecked;
  }

  private getLegalSignaturesTabStatus(
    onboardingTabStatuses: OnBoardingStatus[],
    supplier: SupplierDetail,
    isTermsFormValid: boolean,
    ffAAPLegalSignatures: boolean
  ) {
    const isGeneralTabNotApproved = !isTabApproved(onboardingTabStatuses, SupplierDetailTabs.general)
    const isTermsTabNotApproved = !isTermsFormValid
    const isTabLockedForAAP = ffAAPLegalSignatures && (isGeneralTabNotApproved || isTermsTabNotApproved);

    if (
      isTabInReview(onboardingTabStatuses, SupplierDetailTabs.general) ||
      isTabRejected(onboardingTabStatuses, SupplierDetailTabs.general) ||
      isTabLockedForAAP
    ) {
      return ChecklistNavigationTabStatus.locked;
    }
    if (supplier?.docuSignData?.status === DocusignStatus.completed) {
      return ChecklistNavigationTabStatus.checked;
    }
    return ChecklistNavigationTabStatus.unchecked;
  }

  private getTermsTabStatus(
    onboardingTabStatuses: OnBoardingStatus[],
    isFormValid: boolean
  ) {
    if (!isTabApproved(onboardingTabStatuses, SupplierDetailTabs.general)) {
      return ChecklistNavigationTabStatus.locked;
    }
    if (isFormValid) {
      return ChecklistNavigationTabStatus.checked;
    }
    return ChecklistNavigationTabStatus.unchecked;
  }

  private getPurchasingInfoTabStatus(documents: Document[]) {
    const purchasingInfo = documents.find(
      (item) => item.typeId === Constants.PurchasingInfoDocumentTypeId
    );
    const sif = documents.find(
      (item) => item.typeId === Constants.SupplierInfoDocumentTypeId
    );

    if (!sif || !purchasingInfo) {
      return ChecklistNavigationTabStatus.unchecked;
    } else if (sif.status === DocumentStatus.Approved) {
      return ChecklistNavigationTabStatus.checked;
    } else if (sif.status === DocumentStatus.InReview) {
      return ChecklistNavigationTabStatus.info;
    } else if (sif.status === DocumentStatus.Rejected) {
      return ChecklistNavigationTabStatus.warning;
    }
    return ChecklistNavigationTabStatus.unchecked;
  }

  private getDocumentTabStatus(onboardingTabStatus: OnboardingTabStatuses) {
    switch (onboardingTabStatus) {
      case OnboardingTabStatuses.NotSubmitted:
        return ChecklistNavigationTabStatus.unchecked;
      case OnboardingTabStatuses.Approved:
        return ChecklistNavigationTabStatus.checked;
      case OnboardingTabStatuses.Rejected:
        return ChecklistNavigationTabStatus.warning;
      case OnboardingTabStatuses.InReview:
        return ChecklistNavigationTabStatus.info;
    }
  }

  private getLocationsTabStatus(contacts: Contact[]) {
    const address = contacts.find(
      (a) => a.contactType === ContactType.RemittanceAddress
    );
    if (address) {
      return ChecklistNavigationTabStatus.checked;
    }

    return ChecklistNavigationTabStatus.unchecked;
  }

  isTermsFormValid(supplier: SupplierDetail, terms: TermsFormObject, termsDropdownData: TermsDropdownData[]) {
    const termsForm: UntypedFormGroup = this.supplierFormService.getTermsDefaultForm(
      supplier,
      termsDropdownData,
    );
    this.supplierFormService.patchTermsForm(supplier, termsForm);
    this.supplierFormService.overrideAAPTermsChanges(termsForm, supplier.terms);
    convertTermsDecimalFields(terms);
    this.supplierFormService.patchTermsFormUpdates(terms, termsForm);

    return termsForm.valid
  }
}
