import { Component, Input } from '@angular/core';
import { DeliveredAddressStore } from './delivered-address.store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Contact } from '../../../../../models/contact';
import {
  formattedAddress,
  formattedContact,
  pickupContactMap,
} from '../../../utils/address-format-util';
import {
  optionalPickupAddressFields,
  clearFormFieldValues,
} from '../../../utils/delivered-delivery-method-util';

@Component({
  selector: 'app-delivered-address',
  templateUrl: './delivered-address.component.html',
  styleUrls: ['./delivered-address.component.scss'],
  providers: [DeliveredAddressStore],
})
export class DeliveredAddressComponent {
  @Input() parent: UntypedFormGroup;

  vm$ = this._deliveredAddressStore.vm$;

  get hasAddress() {
    if (this.parent) {
      const pickupAddress =
        (this.parent.get('pickup.pickupAddress') as UntypedFormGroup) ||
        (this.parent.get('pickupAddress') as UntypedFormGroup);
      return pickupAddress && pickupAddress.value && pickupAddress.value.name;
    }
    return false;
  }

  get addressContact() {
    if (this.parent) {
      const pickupAddress =
        (this.parent.get('pickup.pickupAddress') as UntypedFormGroup) ||
        (this.parent.get('pickupAddress') as UntypedFormGroup);
      const contact = {
        address: pickupAddress.value,
      };
      const prefix = this.parent.get('pickup') ? 'pickup.' : '';
      Object.keys(pickupContactMap).forEach((key) => {
        const pickupControl = this.parent.get(`${prefix}${key}`) as UntypedFormControl;
        if (pickupControl) {
          contact[pickupContactMap[key]] = pickupControl.value;
        }
      });
      return contact;
    }
    return null;
  }

  constructor(private _deliveredAddressStore: DeliveredAddressStore) {}

  setPickupFieldsOptional() {
    optionalPickupAddressFields.forEach((element) => {
      if (this.parent) {
        const control = this.parent.get(element) as UntypedFormControl;
        control?.removeValidators(Validators.required);
        control?.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  addAddressClicked() {
    this._deliveredAddressStore.openAddressModal();
  }

  closeModal() {
    this._deliveredAddressStore.closeAddressModal();
  }

  getAddressText(contact: Contact) {
    return formattedAddress(contact.address);
  }

  getContactText(contact: Contact) {
    return formattedContact(contact);
  }

  handleAddressEdit() {
    this._deliveredAddressStore.editAddress();
  }

  handleAddressRemove() {
    this.setPickupFieldsOptional();
    const pickup = (this.parent.get('pickup') as UntypedFormGroup) || this.parent;
    const deliveryMethodCode = this.parent.get('deliveryMethodCode');
    deliveryMethodCode.enable();
    deliveryMethodCode.markAsDirty();
    deliveryMethodCode.markAsTouched();
    const prefix = this.parent.get('pickup') ? 'pickup.' : '';
    if (prefix) {
      clearFormFieldValues(pickup);
    } else {
      clearFormFieldValues(this.parent.get('pickupAddress'));
    }
    pickup?.get('pickupAddressNotes').setValue('');
    this.parent?.get('pickupAddressNotes')?.setValue('');
    Object.keys(pickupContactMap).forEach((key) => {
      const pickupControl = this.parent.get(`${prefix}${key}`) as UntypedFormControl;
      if (pickupControl) {
        clearFormFieldValues(pickupControl);
      }
    });
  }
}
