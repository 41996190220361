<kehe-phoenix-modal name="AddProductGroup" modalTitle="Add Product Group" [show]="true"
  (modalEvent)="handleKeheModalEvents($event)">
  <div body>
    <p>Add a Product Group to group products by distribution centers, brand, temperature, and other settings.</p>

    <div [formGroup]="form">
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="nameField" text="Group Name (optional)"></kendo-label>
          <kendo-textbox #nameField formControlName="name" [maxlength]="maxLength" placeholder="Enter a Group Name" (valueChange)="onValueChange($event)">
          </kendo-textbox>
          <div class="d-flex">
            <kendo-formhint>Maximum of {{maxLength}} characters</kendo-formhint>
            <kendo-formhint class="ml-auto">{{counter}}</kendo-formhint>
          </div>
        </kendo-formfield>
      </div>
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="distributionCentersField" text="Distribution Centers"></kendo-label>
          <kendo-multiselect #distributionCentersField [autoClose]="false" [data]="modifiedDCList" [textField]="'code'"
            [valueField]="'value'" formControlName="distributionCenters" placeholder="Select one or more DCs" 
            [filterable]="true" [kendoDropDownFilter]="{operator: 'contains'}" required>
          </kendo-multiselect>
          <kendo-formerror>Distribution Centers is required.</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="brandsField" text="Brands (optional)"></kendo-label>
          <kendo-multiselect #brandsField [autoClose]="false" [data]="brandList" [textField]="'name'"
            [valueField]="'code'" formControlName="brands" placeholder="Select one or more Brands"
            [filterable]="true" [kendoDropDownFilter]="{operator: 'contains'}">
          </kendo-multiselect>
        </kendo-formfield>
      </div>
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="temperatureClassField" text="Temperature Class"></kendo-label>
          <kendo-combobox #temperatureClassField [data]="temperatureClassList" formControlName="temperatureClass"
            placeholder="Select a Temperature Class" required (focus)="onFocus()">
          </kendo-combobox>
          <kendo-formerror>Temperature Class is required.</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </div>
  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-medium btn-link" (click)="cancelModal()">Cancel</kehe-phoenix-button>
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-medium btn-primary" (click)="continue()"
      [disabled]="isSaveDisabled() || isSaveInProgress" [spinner]="isSaveInProgress">Continue</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>