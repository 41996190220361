import { Store } from '@ngrx/store';
import {
  clearUserNotificationsAction,
  getUserNotificationsAction
} from './user-notifications.actions';
import {
} from './user-notifications.selectors';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsFacade {
  constructor(
    private store: Store
  ) {}

  loadUserNotifications(): void {
    this.store.dispatch(getUserNotificationsAction());
  }

  clearUserNotifications(notificationIds: number[]): void {
    this.store.dispatch(clearUserNotificationsAction({ notificationIds }));
  }
}
