import { Component } from '@angular/core';
import { WorkQueueBannerStore } from './work-queue-banner.store';

@Component({
  selector: 'app-work-queue-banner',
  templateUrl: './work-queue-banner.component.html',
  styleUrls: ['./work-queue-banner.component.scss'],
  providers: [WorkQueueBannerStore],
})
export class WorkQueueBannerComponent {
  vm$ = this._componentStore.vm$;

  constructor(public _componentStore: WorkQueueBannerStore) {}

  viewWorkQueue() {
    this._componentStore.viewWorkQueue();
  }
}
