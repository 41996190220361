import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { PhoenixAnnouncementsModule } from '@kehe/phoenix-announcements';

import {
  RecentAnnouncementsTilesComponent
} from './components/recent-announcements-tiles/recent-announcements-tiles.component';
import { AnnouncementsRecentComponent } from './announcements-recent.component';
import { AnnouncementsRecentService } from './services/announcements-recent.service';
import { announcementsRecentFeatureKey, reducer } from './store/announcements-recent.reducer';
import { AnnouncementsRecentEffects } from './store/announcements-recent.effects';

@NgModule({
  declarations: [
    AnnouncementsRecentComponent,
    RecentAnnouncementsTilesComponent
  ],
  exports: [
    AnnouncementsRecentComponent
  ],
  providers: [
    AnnouncementsRecentService
  ],
  imports: [
    CommonModule,
    PhoenixButtonModule,
    PhoenixAnnouncementsModule,
    StoreModule.forFeature(announcementsRecentFeatureKey, reducer),
    EffectsModule.forFeature([AnnouncementsRecentEffects]),
  ]
})
export class AnnouncementsRecentModule { }
