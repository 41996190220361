<kehe-phoenix-delete-modal
  modalTitle="Delete User"
  [errorMessage]="error"
  [isDeleteInProgress]="loading"
  (modalEvent)="closeModal()"
  (cancelClicked)="closeModal()"
  (deleteClicked)="onDeleteClicked()"
  size="md"
>
  <div class="body p-1">
    Are you sure you would like to delete this user?

    <div class="col">
      <div class="row mt-3">
        <span class="col-3 col-sm-3 p-0"><strong>Name:</strong></span>
        <span class="col-9 col-sm-9 kehe-data-value">{{
          user ? user.name : '--'
        }}</span>
      </div>

      <div class="row mt-2">
        <span class="col-3 col-sm-3 p-0"><strong>Email:</strong></span>
        <span class="col-9 col-sm-9 kehe-data-value">{{
          user ? user.email : '--'
        }}</span>
      </div>
    </div>
  </div>
</kehe-phoenix-delete-modal>
