import { Component, EventEmitter, Input, Output } from '@angular/core';
import { formattedAddress, formattedContact } from '../../../supplier-detail/utilities/address-format-util';
import { Contact } from '../../../../models/contact';

@Component({
  selector: 'app-delete-address',
  templateUrl: './delete-address.component.html',
  styleUrls: ['./delete-address.component.scss']
})
export class DeleteAddressComponent {
  @Input()
  contact: Contact;
  @Input()
  loading: boolean;
  @Input()
  error: string;

  @Output()
  cancel = new EventEmitter();
  @Output()
  delete = new EventEmitter<Contact>();

  getAddressText() {
    return formattedAddress(this.contact);
  }

  getContactText() {
    return formattedContact(this.contact);
  }
}
