import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DCPGEditBulkActions } from '../../models/dcpg-edit-bulk-actions';
import { PermissionsService } from '@app/services/permissions.service'; 
import { Constants } from '../../../../constants/constants';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { Subscription } from 'rxjs';
import { dcpgDropdownList } from '../../../../utils/dcpg-utils';
import { DcpgListService } from '../../services/dcpg-list.service';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { Store } from '@ngrx/store';
import { FeatureFlags } from '@app/feature-flag';

@Component({
  selector: 'app-bulk-edit-select-action-card',
  templateUrl: './bulk-edit-select-action-card.component.html',
  styleUrls: ['./bulk-edit-select-action-card.component.scss'],
})
export class BulkEditSelectActionCardComponent
  extends Destroyable
  implements OnInit
{
  @Input() 
  isEditPOEmail: boolean = false;

  @Input() 
  hasWarning: boolean = false;

  @Input()
  dcList: Array<any> = [];

  @Input()
  form: UntypedFormGroup;

  searchList: Array<any> = [];

  searchRequest: Subscription;
  loadingDCPGs = false;
  defaultZipKeHETransitTimeFlag = false;

  bulkActionsDefault = [
    {
      label: 'Delivery Method',
      value: DCPGEditBulkActions.DeliveryMethod,
      tooltip:
        'Update the Delivery Method and Lead Times. If Pickup is selected a Pickup Address will be required.',
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupDeliveryMethodUpdate
      ),
    },
    {
      label: 'Pickup Contact',
      value: DCPGEditBulkActions.PickupContact,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupDeliveryMethodUpdate
      ),
    },
    {
      label: 'Change KeHE Pickup Type',
      value: DCPGEditBulkActions.ChangeKehePickupType,
      tooltip: 'Update the Delivery Method between Pickup Options only.',
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupDeliveryMethodUpdate
      ),
    },
    {
      label: 'Pickup Address',
      value: DCPGEditBulkActions.PickupAddress,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupDeliveryMethodUpdate
      ),
    },
    {
      label: 'Buyers',
      value: DCPGEditBulkActions.Buyers,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupBuyerUpdate
      ),
    },
    {
      label: 'Delivered Lead Times',
      value: DCPGEditBulkActions.DeliveredLeadTimes,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupLeadTimeUpdate
      ),
    },
    {
      label: 'PO Fax',
      value: DCPGEditBulkActions.POFax,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupPoTransmissionUpdate
      ),
    },
    {
      label: 'Delivered Address',
      value: DCPGEditBulkActions.DeliveredAddress,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupDeliveryMethodUpdate
      ),
    },
    {
      label: 'Pickup Lead Times',
      value: DCPGEditBulkActions.PickupLeadTimes,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupLeadTimeUpdate
      ),
    },
  ];

  bulkActionsPOEmail = [
    {
      label: 'Add PO Email',
      value: DCPGEditBulkActions.AddPOEmail,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupPoTransmissionUpdate
      ),
    },
    {
      label: 'Replace PO Email',
      value: DCPGEditBulkActions.ReplacePOEmail,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupPoTransmissionUpdate
      ),
    },
    {
      label: 'Remove PO Email',
      value: DCPGEditBulkActions.RemovePOEmail,
      show: this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupPoTransmissionUpdate
      ),
    },
  ];

  get bulkActions() {
    if (this.isEditPOEmail) {
      return this.bulkActionsPOEmail;
    }
    return this.bulkActionsDefault;
  }

  constructor(
    private _store: Store,
    private readonly _permissionsService: PermissionsService,
    private readonly _dcpgsService: DcpgListService
  ) {
    super();
    this._store
      .select(selectIsFeatureFlagOn(FeatureFlags.DefaultToZipTransitTime.key))
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.defaultZipKeHETransitTimeFlag = val;
        if (val) {
          const item = this.bulkActionsDefault.find(
            (item) => item.value === DCPGEditBulkActions.ChangeKehePickupType
          );
          item.label = 'Change Inbound Route Type';
          item.tooltip = null;
        }
      });
  }

  ngOnInit(): void {
    this.searchList = this.dcList;
  }

  handleFilter(value) {
    if (this.searchRequest) {
      this.searchRequest.unsubscribe();
    }
    this.loadingDCPGs = true;
    this.searchRequest = this._dcpgsService
      .loadDCPGs(value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response.data) {
          this.searchList = dcpgDropdownList(response.data);
        }
        this.loadingDCPGs = false;
      });
  }
}
