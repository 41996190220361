import { createAction, props } from '@ngrx/store';
import { PersonContact } from '../models/person-contact';

export const loadSupplierContacts = createAction(
    '[Supplier Contacts] Load Supplier Contacts',
    props<{ esn: string }>()
);

export const loadSupplierContactsSuccess = createAction(
    '[Supplier Contacts] Load Supplier Contacts Success',
    props<{ contacts: PersonContact[] }>()
);

export const loadSupplierContactsError = createAction(
    '[Supplier Contacts] Load Supplier Contacts Error',
);

export const showAddSupplierContactModal = createAction(
    '[Supplier Contacts] Show Add Supplier Contact Modal',
);

export const cancelManageSupplierContact = createAction(
    '[Supplier Contacts] Cancel Manage Supplier Contact',
);

export const saveSupplierContact = createAction(
    '[Supplier Contacts] Save Supplier Contact',
    props<{ contact: PersonContact }>()
);

export const saveSupplierContactSuccess = createAction(
    '[Supplier Contacts] Save Supplier Contact Success',
    props<{ contact: PersonContact }>()
);

export const saveSupplierContactError = createAction(
    '[Supplier Contacts] Save Supplier Contact Error',
  props<{ errorCode: number }>()
);

export const editSupplierContact = createAction(
    '[Supplier Contacts] Edit Supplier Contact',
    props<{ contact: PersonContact }>()
);

// Delete Contact
export const showDeleteSupplierContactConfirmation = createAction(
    '[Supplier Contacts] Show Delete Supplier Contact Confirmation',
    props<{ contact: PersonContact }>()
);
export const cancelDeleteSupplierContact = createAction(
    '[Supplier Contacts] Cancel Delete Supplier Contact',
);
export const deleteSupplierContact = createAction(
    '[Supplier Contacts] Delete Supplier Contact',
);
export const deleteSupplierContactSuccess = createAction(
    '[Supplier Contacts] Delete Supplier Contact Success',
    props<{ contact: PersonContact }>()
);
export const deleteSupplierContactError = createAction(
    '[Supplier Contacts] Delete Supplier Contact Error',
);

// Assign Primary Contact
export const showAssignPrimaryContactModal = createAction(
    '[Supplier Contacts] Show Assign Primary Contact Modal',
    props<{ contact: PersonContact }>()
);

// Assign Discontinued Products Notification
export const showNotifyDiscProductsModal = createAction(
    '[Supplier Contacts] Show Assign Disc. Products Notification Modal',
    props<{ contact: PersonContact }>()
);


