import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EMPTY } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';
import { WorkQueueService } from '../../modules/work-queue/services/work-queue.service';

export interface WorkQueueBannerState {
  taskCount: number;
  loadingTaskCount: boolean;
}

export const initialState: WorkQueueBannerState = {
  taskCount: undefined,
  loadingTaskCount: true,
};

@Injectable()
export class WorkQueueBannerStore extends ComponentStore<WorkQueueBannerState> {
  constructor(
    private _workQueueService: WorkQueueService,
    private _router: Router
  ) {
    super();
    this.setState(initialState);
    this.getNumberOfTasks();
  }

  taskCount$ = this.select((state) => state.taskCount);

  flagLabel$ = this.select(
    this.taskCount$,
    (taskCount) => `${taskCount === undefined ? '--' : taskCount} ITEMS`
  );

  readonly vm$ = this.select(
    this.state$,
    this.flagLabel$,
    (state, flagLabel) => ({
      ...state,
      flagLabel,
    })
  );

  viewWorkQueue() {
    this._router.navigateByUrl('/workqueue');
  }

  readonly getNumberOfTasks = this.effect((_) => {
    return _.pipe(
      concatMap(() => {
        return this._workQueueService.getTaskCount().pipe(
          tapResponse(
            (response) => {
              this.patchState({
                loadingTaskCount: false,
                taskCount: response?.taskItemAssigned,
              });
            },
            (e: string) =>
              this.patchState({
                loadingTaskCount: false,
                taskCount: undefined,
              })
          ),
          catchError(() => EMPTY)
        );
      })
    );
  });
}
