import { Component, Input } from '@angular/core';
import { filter, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectBuyerList } from '../../../supplier-detail/store/supplier-detail.selectors';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { loadBuyerList } from '../../../supplier-detail/store/supplier-detail.actions';

@Component({
  selector: 'app-buyers-form',
  templateUrl: './buyers-form.component.html',
  styleUrls: ['./buyers-form.component.scss']
})
export class BuyersFormComponent {
  _form: UntypedFormGroup;

  buyerList$ = this._store.select(selectBuyerList).pipe(filter(x => x.length > 0));
  availableDCList$: Observable<{ label: string; value: string; }>;

  @Input()
  set form(form: UntypedFormGroup) {
    this.availableDCList$ = form.valueChanges.pipe(
      startWith(form.getRawValue()),
      map(
        (form: any) => {
          const result = form.buyers
          .reduce((a: any, c: any) => [...a, ...c.buyerDCs], [] as any)
          .map((v: any) => v.value);

          return form.cloneToDCs.filter((dc: any) => !result.includes(dc.value));
        })
      )
    this._form = form;
  }

  constructor(
    private readonly _store: Store,
    private readonly _builder: UntypedFormBuilder,
  ) {
    this._store.dispatch(loadBuyerList());
  }

  onFocus(element: any) {
    element.toggle(true);
  }

  onAddBuyer() {
    const element = this._builder.group({
      buyerCode: [, Validators.required],
      buyerName: [],
      buyerEmail: [],
      buyerRegion: [],
      buyerDCs: [[], Validators.required]
    });

    (this._form.controls.buyers as UntypedFormArray).push(element);
    element.markAsUntouched();
  }

  onRemoveBuyer(index: number) {
    (this._form.controls.buyers as UntypedFormArray).removeAt(index);
  }
}
