import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Destroyable } from '../../../../abstract/destroyable';
import {
  getBrands,
  getNewDCProductGroup,
  getPickupAddressValidationInProgress,
  getRemittanceAddressValidationInProgress,
  selectBuyerList,
  selectDeliveryMethodList,
  selectIsSavingContact,
  selectNewlyAddedContact,
  selectSaveContactError,
  selectSaveInProgress,
  selectSupplierDetail
} from '../../store/supplier-detail.selectors';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ProductGroup } from '../../models/product-group';
import { SupplierDetail } from '../../models/supplier-detail';
import { combineLatest, merge, Subject } from 'rxjs';
import {
  addSupplierContact,
  clearAddNewProductGroupDC,
  clearSupplierContactForm,
  clearValidateRemittanceAddress,
  clearValidatingPickupAddress,
  loadBuyerList,
  newProductGroupFormChanged,
  saveNewProductGroupForm,
  validatePickupAddress,
} from '../../store/supplier-detail.actions';
import { AddressComponent } from '@kehe/phoenix-address';
import { environment } from '../../../../../environments/environment';
import * as DistributionCenterSelectors from '@app/shared/state//distribution-center/distribution-center.selectors';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { DropdownOption } from './../../../../models/dropdown-option';
import { defaultAddress } from '../../../../utils/address-utils';
import { DCProductGroup } from '../../models/dc-product-group';
import {
  PoTransmissionsFormComponent
} from '../../../product-groups/components/po-transmissions-form/po-transmissions-form.component';
import { prepPhone } from '../../../../utils/common-utils';
import { Constants } from '../../../../constants/constants';
import { isValidKeHEEmail } from '../../../../utils/form-utils';
import { isDraft, isInvitedDraftSupplier } from '../../utilities/supplier-utils';
import { Contact, ContactType } from '../../../../models/contact';
import { prepareBuyers } from '../../../product-groups/utils/products-util';
import { optionalPickupAddressFieldsProperties } from '../../../product-groups/utils/delivered-delivery-method-util';
import { DeliveryMethodCodes } from '../../../product-groups/models/delivery-method-codes';
import { InboundRouteType } from '../../../../models/dcpg-inbound-route-type';
import { FeatureFlags } from '@app/feature-flag';

@Component({
  selector: 'app-add-new-product-group-form',
  templateUrl: './add-product-group-form.component.html',
  styleUrls: ['./add-product-group-form.component.scss']
})
export class AddProductGroupFormComponent extends Destroyable implements OnInit, OnDestroy, AfterViewInit {

  contactType = ContactType;
  readonly mask = Constants.ExtensionTextMask;
  readonly buyerRegions = Constants.BuyerRegions;
  deliveryMethodList: DropdownOption[];
  distributionCenter: any;
  dc: any;
  validateAddressOnSave: Subject<void> = new Subject<void>();
  tempAddress = defaultAddress();
  @ViewChild('addressPickupComponent', { static: true }) addressFormPickup: AddressComponent;
  public buyerNames: UntypedFormArray;
  productGroupNumber: string;

  @ViewChild('addressComponentRemittance', { static: true }) addressFormRemittance: AddressComponent;
  @ViewChild('poTransmissionsGroup', { static: true }) poTransmissionsGroup: PoTransmissionsFormComponent;

  public form: UntypedFormGroup;
  isFormReady = false;
  productGroup: ProductGroup;
  supplier: SupplierDetail;
  brands: any[];
  dcGroupEdit: any;
  showBuyerSection: boolean;
  renderBuyerDCWarning: boolean;
  enableAddBuyerBtn: boolean;
  public tempClasses: Array<string> = ['Dry', 'Refrigerated', 'Frozen'];
  isUseHeadquarterInfoChecked: boolean;
  ignoreRemittanceValueChanged: boolean;
  buyerList: any[];
  modifiedBuyerList: any[];
  selectedDcs: number;
  expectedDcs: number;
  remitNameMaxLength = 30;
  public remitNameCounter = `0/${this.remitNameMaxLength}`;
  inboundRouteTypes: { name: string; type: InboundRouteType }[] =
    Constants.InboundRouteTypes;

  @ViewChild('temperatureClassField', { static: true }) public temperatureClassField: any;

  validateRemittanceAddressOnSave: Subject<void> = new Subject<void>();
  smartyStreetsKey = environment.smartyStreetsKey;

  public characterCount = 0;
  public maxLength = 30;
  public counter = `${this.characterCount}/${this.maxLength}`;
  public modifiedDCList: Array<object>;
  public unassignedDCList: Array<object>;
  public pickupAddressNotesCount = 0;
  public pickupAddressNotesCounter = `${this.pickupAddressNotesCount}/${this.maxLength}`;

  dcProductGroups: Array<DCProductGroup> = new Array<DCProductGroup>();
  isSaveInProgress: boolean;
  public remitEmailMaxLength = 30;
  public remitEmailCounter = `0/${this.remitEmailMaxLength}`;
  canShowBuyerRegionEmail = false;

  deliveryAddressFeatureFlag: boolean
  deliveryAddressFeatureFlag$ = this._store.select(
    selectIsFeatureFlagOn(FeatureFlags.ProductGroupDeliveryAddress.key)
  );
  isDraftSupplier: boolean
  isDraftSupplier$ = this._store.select(selectSupplierDetail).pipe(map(supplier => isDraft(supplier)));

  saving$ = this._store.select(selectIsSavingContact);
  error$ = this._store.select(selectSaveContactError);

  isInvitedDraftSupplier: boolean;
  isInvitedDraftSupplier$ = this._store
      .select(selectSupplierDetail)
      .pipe(map((supplier) => isInvitedDraftSupplier(supplier)));

  get remitName() {
    return this.form.get('remitName');
  }

  get remitEmail() {
    return this.form.get('remitEmail');
  }

  get temperatureClass() {
    return this.form.get('temperatureClass');
  }

  get deliveryMethodCode() {
    return this.form.get('deliveryMethodCode');
  }

  get pickupEmail() {
    return this.form.get('pickupEmail');
  }

  get pickupPhoneField() {
    return this.form.get('pickupPhone');
  }

  get isDelivered(): boolean {
    return this.form.controls.deliveryMethod.value === 'Delivered';
  }

  get isPickup(): boolean {
    return (
      this.deliveryMethodCode.value &&
      this.deliveryMethodCode.value !== DeliveryMethodCodes.Delivered
    );
  }

  get hasDeliveryMethod(): boolean {
    return this.form.controls.deliveryMethod.value && this.form.controls.deliveryMethod.value.length > 0;
  }

  get hidePickupAddress(): boolean {
    return !(this.isFormReady && this.form && this.form.controls.deliveryMethod.value && !this.isDelivered);
  }

  get deliveredReadyTime() {
    const leadTimesGroup = this.form.get('deliveredLeadTimes') as UntypedFormGroup;
    return leadTimesGroup.controls['readyTime'];
  }

  get deliveredTransitTime() {
    const leadTimesGroup = this.form.get('deliveredLeadTimes') as UntypedFormGroup;
    return leadTimesGroup.controls['transitTime'];
  }

  get deliveredTotalTime() {
    const leadTimesGroup = this.form.get('deliveredLeadTimes') as UntypedFormGroup;
    return leadTimesGroup.controls['totalTime'];
  }

  get pickUpReadyTime() {
    const leadTimesGroup = this.form.get('pickupLeadTimes') as UntypedFormGroup;
    return leadTimesGroup.controls['readyTime'];
  }

  get readyTimeAvailable(): boolean {
    return this.form.controls.readyTimeAvailable.value;
  }

  get showOnlyTotalLeadTime() {
    if (this.isDelivered) {
      if (!this.readyTimeAvailable) {
        return true;
      }
    }
    return false;
  }

  get deliveredLeadTimesFormGroup(): UntypedFormGroup {
    return this.form.controls.deliveredLeadTimes as UntypedFormGroup;
  }

  get buyersFormGroup() {
    return this.form.get('buyerNames') as UntypedFormArray;
  }

  get distributionCentersFormArray() {
    return this.form.get('distributionCenters') as UntypedFormArray;
  }

  get usedAllDCs() {
    const sum = this.buyersFormGroup.value.map(item => item.dcs.length).reduce((a, b) => a + b);
    return this.distributionCentersFormArray.value.length === sum;
  }

  get pickupAddressNotes() {
    return this.form.get('pickupAddressNotes');
  }

  get remitNameIsEmpty() {
    return this.form.get('remitName').value.length === 0;
  }

  get inboundRouteTypeField() {
    return this.form.get('inboundRouteType');
  }

  get isFormValid() {
    return this.form.valid &&
      (this.poTransmissionsGroup?.form.enabled ? this.poTransmissionsGroup?.form.valid : true);
  }

  get showContactCard() {
    return isInvitedDraftSupplier(this.supplier);
  }

  constructor(
      private _formBuilder: UntypedFormBuilder,
      private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.showBuyerSection = false;
    this.unassignedDCList = [];
    this.renderBuyerDCWarning = false;
    this.enableAddBuyerBtn = true;
    this.selectedDcs = 0;
    this.dcGroupEdit = {distributionCenter: []};
    this.dispatchStoreActions();

    this._store.select(selectSupplierDetail).pipe(takeUntil(this.destroy$))
      .subscribe((supplier) => {
        this.supplier = supplier;
        this.canShowBuyerRegionEmail = isDraft(supplier);
        this.initializeForm();
      });

    this._store.pipe(
        takeUntil(this.destroy$),
        select(DistributionCenterSelectors.selectDistributionCenterList))
        .subscribe(response => {
          this.modifiedDCList = this.mapDcToCodeValue(response?.slice()).filter(item => !Constants.ExcludedDCs.includes(+item.value));
        });

    this._store.pipe(
      select(selectSaveInProgress),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isSaveInProgress = val;
      });
    this._store
      .pipe(select(selectNewlyAddedContact), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value != null && (value.contactType === ContactType.PickupAddress || value.contactType === ContactType.DeliveredAddress)) {
          this.onSelectPickupAddress(value);
        }
      });
    this.deliveryAddressFeatureFlag$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (deliveryAddressFeatureFlag) =>
          (this.deliveryAddressFeatureFlag = deliveryAddressFeatureFlag)
      );
    this.isDraftSupplier$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (isDraftSupplier) => (this.isDraftSupplier = isDraftSupplier)
      );
    this.isInvitedDraftSupplier$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isInvitedDraftSupplier) => (this.isInvitedDraftSupplier = isInvitedDraftSupplier));
    this.productGroupNumber = 'TBD';
  }

  ngOnDestroy() {
    this._store.dispatch(clearAddNewProductGroupDC());
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    this.initializeStoreSelectorObservables();
    this.initializeAddressValidationSubscription();
    this.buyerNames.push(this.createBuyerTransaction());
    this.poTransmissionsGroup?.patchForm(null, []);
  }

  private dispatchStoreActions(): void {
    this._store.dispatch(loadBuyerList());
  }

  private copyValueToFormControl(formControlName: string, value: any) {
    this.ignoreRemittanceValueChanged = true;
    this.form.controls[formControlName].patchValue(value);
    this.ignoreRemittanceValueChanged = false;
    this.form.controls[formControlName].markAsDirty();
  }

  deliveryMethodValueChange(value: any): void {
    if (value) {
      if (value !== DeliveryMethodCodes.Delivered) {
        this.form.controls.readyTimeAvailable.setValue(true);
      }
      const deliveryMethod = this.deliveryMethodList.find(item => item.value === value);
      this.form.controls.deliveryMethod.patchValue(deliveryMethod.label);

      this.updateIBRFromDeliveryMethod(deliveryMethod.value);
    } else {
      this.form.controls.deliveryMethod.patchValue(null);
    }
  }

  updateIBRFromDeliveryMethod(deliveryMethod: string) {
    if (this.isDraftSupplier) {
      if (deliveryMethod === DeliveryMethodCodes.Delivered) {
        this.form.controls.inboundRouteType.setValue(InboundRouteType.None, { emitEvent: false });
      } else {
        this.form.controls.inboundRouteType.setValue(InboundRouteType.LTLDry, { emitEvent: false });
      }
    }
  }

  public onFocus(element: any): void {
    element.toggle(true);
  }

  private initializeAddressValidationSubscription(): void {
    this._store.pipe(
        select(getRemittanceAddressValidationInProgress),
        takeUntil(this.destroy$))
        .subscribe(val => {
          if (val) {
            this.validateRemittanceAddressOnSave.next();
          }
        });
    this._store.pipe(
        select(getPickupAddressValidationInProgress),
        takeUntil(this.destroy$))
        .subscribe(res => {
          if (res) {
            this.validateAddressOnSave.next();
          }
        });
  }

  private redrawLeadTimesFormFields(): void {
    if (this.isDelivered) {
      this.form.controls.pickupLeadTimes.disable();
      this.form.controls.deliveredLeadTimes.enable();
      if (this.readyTimeAvailable) {
        this.deliveredLeadTimesFormGroup.controls.readyTime.enable();
        this.deliveredLeadTimesFormGroup.controls.transitTime.enable();
      } else {
        this.deliveredLeadTimesFormGroup.controls.totalTime.setValidators(
          [Validators.required, Validators.min(1), Validators.max(999)]
        );
        this.deliveredLeadTimesFormGroup.controls.totalTime.markAsTouched();
        this.deliveredLeadTimesFormGroup.controls.readyTime.disable();
        this.deliveredLeadTimesFormGroup.controls.transitTime.disable();
      }
      this.deliveredLeadTimesFormGroup.controls.totalTime.updateValueAndValidity({emitEvent: false});
    } else {
      this.form.controls.pickupLeadTimes.enable();
      this.form.controls.deliveredLeadTimes.disable();
    }
  }

  addBuyer() {
    if (this.enableAddBuyerBtn) {
      this.buyerNames.push(this.createBuyerTransaction());
      this.alignBuyerSelections(true);
    }
  }

  removeBuyerRow(i) {
    this.form.markAsDirty();
    this.buyerNames.markAsDirty();
    this.buyerNames.removeAt(i);
    this.alignBuyerSelections(true);
  }

  createBuyerTransaction(): UntypedFormGroup {
    if (this.canShowBuyerRegionEmail) {
      return this._formBuilder.group({
        dcs: [[]],
        buyer: [''],
        buyerRegion: ['', [Validators.required]],
        buyerEmail: ['', [Validators.required, Validators.email, isValidKeHEEmail]],
      });
    } else {
      return this._formBuilder.group({
        dcs: [[]],
        buyer: [''],
      });
    }
  }

  getBuyersFormGroup(index): UntypedFormGroup {
    return this.buyerNames.controls[index] as UntypedFormGroup;
  }

  calculateLeadTimes() {
    if (this.isDelivered) {
      const readyTime = this.deliveredLeadTimesFormGroup.controls.readyTime.value;
      const transitTime = this.deliveredLeadTimesFormGroup.controls.transitTime.value;

      if (readyTime && readyTime > 0 && transitTime && transitTime > 0) {
        const totalTime: number = +readyTime + +transitTime;
        this.deliveredLeadTimesFormGroup.controls.totalTime.setValue(totalTime);
      }
    }
  }

  private redrawPickupAddressFormFields(): void {
    const pickupAddressIdControl = this.form.get('pickupAddressId');

    if (this.isDelivered) {
      this.handleDeliveredFlow(pickupAddressIdControl);
    } else {
      this.handleNotDeliveredFlow(pickupAddressIdControl);
    }

    this.handleDeliveredAddressForm(pickupAddressIdControl);

    this.form.get('pickupAddress.street2').disable();
    this.form.updateValueAndValidity({ emitEvent: false });
  }

  private handleDeliveredFlow(pickupAddressIdControl: AbstractControl): void {
    if (this.isDraftSupplier) {
      pickupAddressIdControl.enable();
      if (this.isInvitedDraftSupplier) {
        pickupAddressIdControl.addValidators(Validators.required);
      } else {
        pickupAddressIdControl.removeValidators(Validators.required);
      }
      this.disableOldPickupFieldStatus();
    } else if (this.showContactCard) {
      pickupAddressIdControl.disable();
    } else {
      this.disableOldPickupFieldStatus();
    }
  }

  private handleNotDeliveredFlow(pickupAddressIdControl: AbstractControl): void {
    if (this.showContactCard) {
      pickupAddressIdControl.enable();
      pickupAddressIdControl.addValidators(Validators.required);
    } else {
      pickupAddressIdControl.removeValidators(Validators.required);
      this.enableOldPickupFieldStatus();
      this.setPickupFieldsRequired();
      pickupAddressIdControl.disable();
    }
  }

  private handleDeliveredAddressForm(pickupAddressIdControl: AbstractControl): void {
    if (this.isDelivered && this.deliveryAddressFeatureFlag && !this.isDraftSupplier) {
      pickupAddressIdControl.removeValidators(Validators.required);
      if (this.showContactCard) {
        pickupAddressIdControl.reset();
        pickupAddressIdControl.enable();
      }
      this.enableOldPickupFieldStatus();
      this.setPickupFieldsOptional();
    }
  }

  setPickupFieldsOptional() {
    optionalPickupAddressFieldsProperties.forEach((element) => {
      if (this.form) {
        const control = this.form.get(element) as UntypedFormControl;
        control?.removeValidators(Validators.required);
        control?.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  setPickupFieldsRequired() {
    optionalPickupAddressFieldsProperties.forEach((element) => {
      if (this.form) {
        const control = this.form.get(element) as UntypedFormControl;
        control?.addValidators(Validators.required);
        control?.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  onRemittanceAddressSelected(selectedAddress) {
    this.form.controls.remittanceAddress.patchValue(selectedAddress.address);
    this._store.dispatch(clearValidateRemittanceAddress());
    if (this.isPickupAddressDirty() && !this.showContactCard) {
      this._store.dispatch(validatePickupAddress());
    } else {
      this.save();
    }
  }

  onPickUpAddressSelected(selectedAddress) {
    const notes = this.form.controls.pickupAddressNotes.value;
    this.form.controls.pickupAddressNotes.setValue(notes);
    this.form.controls.pickupAddress.patchValue(selectedAddress.address);
    this._store.dispatch(clearValidatingPickupAddress());
    this.save();
  }

  getDCBuyer(i) {
    return this.getBuyersFormGroup(i).controls.buyer;
  }

  getDCBuyerRegionField(i) {
    return this.getBuyersFormGroup(i).controls.buyerRegion;
  }

  getDCBuyerEmailField(i) {
    return this.getBuyersFormGroup(i).controls.buyerEmail;
  }

  private initializeFormValueChanges(): void {
    this.form.controls.deliveryMethod.valueChanges.subscribe(value => {
      this.redrawPickupAddressFormFields();
      this.redrawLeadTimesFormFields();
    });
    this.form.controls.readyTimeAvailable.valueChanges.subscribe(value => {
      this.redrawLeadTimesFormFields();
    });
    this.deliveredLeadTimesFormGroup.controls.readyTime.valueChanges.subscribe(value => {
      this.calculateLeadTimes();
    });
    this.deliveredLeadTimesFormGroup.controls.transitTime.valueChanges.subscribe(value => {
      this.calculateLeadTimes();
    });
    combineLatest([
      this.form.valueChanges.pipe(startWith(null)),
      this.poTransmissionsGroup?.form.valueChanges.pipe(startWith(null))
    ]).pipe(takeUntil(this.destroy$)).subscribe(([form, poTransmissions]) => {
      if (!form) {
        return;
      }
      const productGroup: ProductGroup = this.getProductGroup(form, poTransmissions);
      const action = {
        form: {
          esn: this.supplier ? this.supplier.esn : null,
          productGroups: [
            productGroup
          ],
          updatedBySource: 'Enterprise',
        },
        isDirty: this.form.dirty || this.poTransmissionsGroup?.form.dirty,
        isProductGroupFormValid: this.isFormValid && this.usedAllDCs,
        isPickupAddressDirty: this.isPickupAddressDirty(),
        isRemittanceAddressDirty: this.form.controls.remittanceAddress.dirty,
      };
      this._store.dispatch(newProductGroupFormChanged(action));
    });

    // subscribe to remittance changes that don't come from checkbox so we can toggle checkbox off
    merge(
      this.form.controls['remittanceAddress'].valueChanges,
      this.form.controls['remitPhone'].valueChanges,
      this.form.controls['remitExtension'].valueChanges,
      this.form.controls['remitFax'].valueChanges,
      this.form.controls['remitEmail'].valueChanges
    ).pipe(
      takeUntil(this.destroy$),
      filter(() => !this.ignoreRemittanceValueChanged))
      .subscribe(() => {
        this.isUseHeadquarterInfoChecked = false;
      });
  }

  getProductGroup(form, poTransmissions) {
    // use raw value so we can still save values from disabled address controls
    this.prepareDisabledFields(this.form.getRawValue(), form);

    if (poTransmissions) {
      form.poEmails = poTransmissions.poEmails.filter(
        (mailItem) => mailItem.email && mailItem.email.length > 0
      );
      form.poFax = poTransmissions.poFax;
      form.poOrderMinimum = poTransmissions.poOrderMinimum;
    } else {
      form.poEmails = [];
    }

    return {
      name: this.getProductGroupName(form),
      remittanceAddress: form.remittanceAddress,
      remittanceAddressId: form.remittanceAddressId,
      brands: form.brands,
      temperatureClass: form.temperatureClass,
      dcProductGroups: this.composeDCProductGroups(form),
    };
  }

  prepareDisabledFields(rawPGValue, form) {
    form.inboundRouteType = rawPGValue.inboundRouteType;

    if (rawPGValue.pickupAddress) {
      form.pickupAddress = rawPGValue.pickupAddress;
      form.pickupAddress.phone = rawPGValue.pickupPhone;
      form.pickupAddress.extension = rawPGValue.pickupExtension;
      form.pickupAddress.fax = rawPGValue.pickupFax;
      form.pickupAddress.email = rawPGValue.pickupEmail;
      form.pickupAddress.latitude = rawPGValue.pickupAddress.latitude
        ? parseFloat(rawPGValue.pickupAddress.latitude)
        : 0;
      form.pickupAddress.longitude = rawPGValue.pickupAddress.longitude
        ? parseFloat(rawPGValue.pickupAddress.longitude)
        : 0;
      form.pickupAddress.street2 = rawPGValue.pickupAddress.street2;
      form.pickupAddressNotes = rawPGValue.pickupAddressNotes || '';
    }
    form.pickupAddressId = rawPGValue.pickupAddressId;

    if (form.remittanceAddress) {
      form.remittanceAddress.latitude =
        rawPGValue.remittanceAddress && rawPGValue.remittanceAddress.latitude
          ? parseFloat(rawPGValue.remittanceAddress.latitude)
          : 0;
      form.remittanceAddress.longitude =
        rawPGValue.remittanceAddress && rawPGValue.remittanceAddress.longitude
          ? parseFloat(rawPGValue.remittanceAddress.longitude)
          : 0;

      form.remittanceAddress.name = rawPGValue.remitName;
      form.remittanceAddress.phone = rawPGValue.remitPhone;
      form.remittanceAddress.extension = rawPGValue.remitExtension;
      form.remittanceAddress.fax = rawPGValue.remitFax;
      form.remittanceAddress.email = rawPGValue.remitEmail;
    }
    form.remittanceAddressId = rawPGValue.remittanceAddressId;
  }

  private isPickupAddressDirty() {
    return this.isDelivered || this.showContactCard ? false : this.form.controls.pickupAddress.dirty;
  }

  private composeDCProductGroups(form: any): DCProductGroup[] {
    const buyerMap = {};
    if (form.buyerNames) {
      form.buyerNames.forEach(item => {
        if (item.buyer && item.dcs) {
          item.dcs.forEach(dc => {
            buyerMap[dc.value] = item;
          });
        }
      });
    }

    return form.distributionCenters.map(item => this.prepareDCProductGroups(buyerMap, item, form));
  }

  prepareDCProductGroups(buyerMap, item, form) {
    const buyerSelected = buyerMap[item.value];
    const dcPG = {
      buyerCode: buyerSelected ? buyerSelected.buyer : null,
      buyerRegion: buyerSelected ? buyerSelected.buyerRegion : null,
      buyerEmail: buyerSelected ? buyerSelected.buyerEmail : null,
      buyerName: '',
      deliveryMethod: form.deliveryMethod,
      deliveryMethodCode: form.deliveryMethodCode,
      inboundRouteType: form.inboundRouteType,
      isZipTransitTime: true,
      distributionCenterNumber: item.value,
      readyTimeAvailable: form.readyTimeAvailable,
      pickupLeadTimes: !form.pickupLeadTimes ? {} : {
        readyTime: form.pickupLeadTimes.readyTime,
      },
      deliveredLeadTimes: !form.deliveredLeadTimes ? {} : {
        readyTime: form.deliveredLeadTimes.readyTime,
        transitTime: form.deliveredLeadTimes.transitTime,
        totalTime: form.deliveredLeadTimes.totalTime,
      },
      poFax: form.poFax,
      poEmails: form.poEmails.filter(mailItem => mailItem.email && mailItem.email.length > 0),
      poOrderMinimum: form.poOrderMinimum,
    };
    if (this.showContactCard || (this.isDraftSupplier && form.deliveryMethod === 'Delivered')) {
      dcPG['pickupAddressId'] = form.pickupAddressId;
      dcPG['pickupAddressNotes'] = form.pickupAddressNotes;
    } else {
      dcPG['pickupAddress'] = form.pickupAddress;
      dcPG['pickupAddressNotes'] = form.pickupAddressNotes;
    }
    return dcPG;
  }

  initializeForm(): void {
    this.form = this._formBuilder.group({
      name: ['', [Validators.maxLength(30)]],
      brands: [],
      distributionCenters: new UntypedFormControl([]),
      inboundRouteType: [
        InboundRouteType.LTLDry,
        [Validators.required]
      ],
      buyerNames: this._formBuilder.array([]),
      temperatureClass: ['', [Validators.required]],
      remittanceAddressId: [this.supplier?.remittanceAddressContact?.uniqueId ?? '', Validators.required],
      remittanceAddress: this.addressFormRemittance?.createAddressFormGroup(),
      remitName: ['', [Validators.required]],
      remitPhone: [''],
      remitExtension: [''],
      remitFax: [''],
      remitEmail: ['', [Validators.email]],
      deliveryMethodCode: ['', [Validators.required]],
      deliveryMethod: ['', [Validators.required]],
      pickupAddressId: ['', Validators.required],
      pickupAddress: this.addressFormPickup.createAddressFormGroup(),
      pickupAddressNotes: [''],
      pickupPhone: ['', [Validators.required]],
      pickupExtension: [],
      pickupFax: [],
      pickupEmail: ['', [Validators.email]],
      readyTimeAvailable: [true],
      deliveredLeadTimes: this._formBuilder.group({
        readyTime: ['', [Validators.required, Validators.min(1), Validators.max(499)]],
        transitTime: ['', [Validators.required, Validators.min(1), Validators.max(499)]],
        totalTime: ['', [Validators.required, Validators.min(1), Validators.max(999)]],
      }),
      pickupLeadTimes: this._formBuilder.group({
        readyTime: ['', [Validators.required, Validators.min(1), Validators.max(499)]],
      }),
    });
    this.form.controls.remittanceAddress.patchValue(defaultAddress());
    this.form.controls.pickupAddress.patchValue(defaultAddress());
    this.buyerNames = this.form.get('buyerNames') as UntypedFormArray;
    this.form.controls.temperatureClass.markAsDirty();
    this.form.get('remittanceAddressId').disable();
    if (this.showContactCard) {
      this.disableRemittanceFields();
      this.disableOldPickupFieldStatus();
    } else {
      this.form.get('pickupAddressId').disable();
    }
  }

  enableOldPickupFieldStatus() {
    this.form.get('pickupAddress').enable();
    this.form.get('pickupAddressNotes').enable();
    this.form.get('pickupPhone').enable();
    this.form.get('pickupExtension').enable();
    this.form.get('pickupFax').enable();
    this.form.get('pickupEmail').enable();
  }
  disableOldPickupFieldStatus() {
    this.form.get('pickupAddress').disable();
    this.form.get('pickupAddressNotes').disable();
    this.form.get('pickupPhone').disable();
    this.form.get('pickupExtension').disable();
    this.form.get('pickupFax').disable();
    this.form.get('pickupEmail').disable();
  }

  disableRemittanceFields() {
    this.form.get('remittanceAddress').disable();
    this.form.get('remitName').disable();
    this.form.get('remitPhone').disable();
    this.form.get('remitExtension').disable();
    this.form.get('remitFax').disable();
    this.form.get('remitEmail').disable();
  }

  private initializeStoreSelectorObservables(): void {
    const selectDeliveryMethodList$ = this._store.select(selectDeliveryMethodList).pipe(filter(x => x.length > 0));
    const selectSupplierDetail$ = this._store.select(selectSupplierDetail).pipe(take(1));
    const getBrands$ = this._store.select(getBrands).pipe(take(1));
    const getNewDCProductGroup$ = this._store.select(getNewDCProductGroup).pipe(take(1));
    const buyersListNewProdGroup$ = this._store.select(selectBuyerList).pipe(filter(x => x.length > 0));
    combineLatest([
      selectSupplierDetail$,
      getBrands$,
      getNewDCProductGroup$,
      selectDeliveryMethodList$,
      buyersListNewProdGroup$
    ])
        .pipe(
            takeUntil(this.destroy$),
        )
        .subscribe(([
                      supplierDetailItem,
                      brands,
                      dcGroupEdit,
                      deliveryMethodList,
                      buyersList
                    ]) => {
          this.supplier = supplierDetailItem;
          this.brands = brands;
          this.deliveryMethodList = deliveryMethodList;
          this.dcGroupEdit = dcGroupEdit;
          this.unassignedDCList = [dcGroupEdit.distributionCenters.slice()];
          this.modifiedBuyerList = prepareBuyers(buyersList);
          this.preFillForm();
          this.isFormReady = true;
          this.redrawPickupAddressFormFields();
          this.initializeFormValueChanges();
          this.remitNameChange((this.productGroup && this.productGroup.remittanceAddress)
          ? this.productGroup.remittanceAddress.name : '');
          this.trimNameAndUpdateCharCount();
        });

    this._store.pipe(
        select(getRemittanceAddressValidationInProgress),
        takeUntil(this.destroy$))
        .subscribe(isInProgress => {
          if (isInProgress) {
            this.validateRemittanceAddressOnSave.next();
          }
        });
  }

  onUseHeadquarterInfoChange(): void {
    this.isUseHeadquarterInfoChecked = !this.isUseHeadquarterInfoChecked;
    if (this.isUseHeadquarterInfoChecked) {
      this.copyHeadquarterToRemittance();
    }
  }

  save(): void {
    if (!this.isSaveInProgress) {
      this._store.dispatch(saveNewProductGroupForm());
    }
  }

  noRemittanceAddressSelected(): void {
    this._store.dispatch(clearValidateRemittanceAddress());
  }

  noPickUpAddressSelected(): void {
    this._store.dispatch(clearValidatingPickupAddress());
  }

  public onFocusTemperatureClass() {
    this.temperatureClassField.toggle(true);
  }

  public onNameValueChange(e: any) {
    this.updateNameCharCount(e);
  }

  public onNameBlur() {
    this.trimNameAndUpdateCharCount();
  }

  private updateNameCharCount(val: string) {
    this.characterCount = val.length;
    this.counter = `${this.characterCount}/${this.maxLength}`;
  }

  private trimNameAndUpdateCharCount() {
    this.form.controls.name.setValue(this.form.controls.name.value.trim());
    this.updateNameCharCount(this.form.controls.name.value);
  }

  public preFillForm() {
    this.form.controls.brands.patchValue(this.dcGroupEdit.brands);
    this.form.controls.distributionCenters.patchValue(this.dcGroupEdit.distributionCenters);
    this.form.controls.temperatureClass.patchValue(this.dcGroupEdit.temperatureClass);
    this.form.controls.name.patchValue(this.dcGroupEdit.name);
    this.form.controls.remittanceAddress.patchValue(this.tempAddress);
    this.form.controls.pickupAddress.patchValue(this.tempAddress);

    if (this.dcGroupEdit.distributionCenters.length > 0) {
      this.showBuyerSection = true;
    }
    if (this.dcGroupEdit.distributionCenters.length > 0) {
      this.showBuyerSection = true;
    }
    this.expectedDcs = this.dcGroupEdit.distributionCenters.length;
    this.updateCounter();
  }

  private mapDcToCodeValue(dcList: any[]) {
    return dcList.map(item => ({
      code: item.name.replace('Kehe Dist - ', ''),
      value: item.number,
      codeValue: item.name.replace('Kehe Dist - ', '')
    }));
  }

  private copyHeadquarterToRemittance() {
    this.copyValueToFormControl('remittanceAddress', this.supplier.headquarterAddress);
    this.copyValueToFormControl('remitPhone', prepPhone(this.supplier.headquarterAddress.phone) || '');
    this.copyValueToFormControl('remitExtension', this.supplier.headquarterAddress.extension || '');
    this.copyValueToFormControl('remitFax', prepPhone(this.supplier.headquarterAddress.fax) || '');
    this.copyValueToFormControl('remitEmail', this.supplier.headquarterAddress.email || '');
    this.remitEmailChange(this.supplier.headquarterAddress.email);
  }

  onPickupAddressNotesValueChange(e: any) {
    this.pickupAddressNotesCount = e.length;
    this.pickupAddressNotesCounter = `${this.pickupAddressNotesCount}/${this.maxLength}`;
    this.form.controls.pickupAddressNotes.setValue(e);
  }

  updateCounter() {
    this.pickupAddressNotesCounter = `${this.pickupAddressNotesCount}/${this.maxLength}`;
  }

  alignUnassignedDCs(updateErrors: boolean): void {
    const allowedDCs = this.form.get('distributionCenters').value.slice();
    this.expectedDcs = allowedDCs.length;

    this.buyersFormGroup.controls.forEach((sel, i) => {
      const controls = this.getBuyersFormGroup(i).controls;
      const selectedDcs = controls['dcs'];
      if (selectedDcs.value.length > 0) {
        selectedDcs.value.forEach(dc => {
          const index = allowedDCs.indexOf(dc);
          if (index > -1) {
            allowedDCs.splice(index, 1);
          }
        });
      }
    });

    if (updateErrors) {
      const allDcSelected = allowedDCs.length === 0;
      this.renderBuyerDCWarning = !allDcSelected;
    }

    if (this.buyersFormGroup.controls.length === 1) {
      this.unassignedDCList[0] = allowedDCs;
    } else {
      this.unassignedDCList[this.buyersFormGroup.controls.length] = allowedDCs;
    }
  }

  alignBuyerSelections(updateErrors: boolean) {
    let buyersTotal = 0;
    let dcTotal = 0;

    const allowedDCs = this.form.get('distributionCenters').value.slice();
    this.expectedDcs = allowedDCs.length;
    this.buyersFormGroup.controls.forEach((sel, i) => {
      const controls = this.getBuyersFormGroup(i).controls;
      const buyerCheck = controls['buyer'];
      const selectedDcs = controls['dcs'];
      if (buyerCheck.value && buyerCheck.value.length > 0) {
        buyersTotal++;
      } else {
        /**If buyer is not selected, show error */
        buyerCheck.markAsDirty();
        if (selectedDcs.value.length === 0 && allowedDCs.length > 0) {
          selectedDcs.markAsDirty();
        }
        if (this.canShowBuyerRegionEmail) {
          controls['buyerRegion'].markAsTouched();
          controls['buyerEmail'].markAsTouched();
        }
      }
      /**Iterate over selected DCs and remove them from allowed DCs*/
      if (selectedDcs.value.length > 0) {
        selectedDcs.value.forEach(dc => {
          const index = allowedDCs.indexOf(dc);
          if (index > -1) {
            allowedDCs.splice(index, 1);
            dcTotal++;
          }
        });
      } else {
        /**If DCs have been decremented down to 0 and this isn't the first interaction, reset*/
        if (updateErrors) {
          this.selectedDcs = 0;
          this.renderBuyerDCWarning = true;
        }
      }
    });

    /**Take selected values and add on remaining allowed as possible options for each index*/
    this.buyersFormGroup.controls.forEach((sel, i) => {
      const controls = this.getBuyersFormGroup(i).controls;
      const buyerRow = controls['dcs'];
      const DCsToAdd = allowedDCs.slice();
      /**Confirm it's not an empty string, we need an array*/
      if (buyerRow.value.length !== 0) {
        this.unassignedDCList[i] = buyerRow.value.concat(DCsToAdd);
      } else {
        this.unassignedDCList[i] = DCsToAdd;
      }
    });

    const allDcsFulfilled = allowedDCs.length === 0;
    if (this.buyersFormGroup.controls.length === 1 && dcTotal === 0) {
      this.unassignedDCList[0] = allowedDCs;
      this.renderBuyerDCWarning = false;
    } else {
      /**Take available/allowed DCs for the NEXT row which may or may not be added*/
      this.unassignedDCList[this.buyersFormGroup.controls.length] = allowedDCs;
      this.renderBuyerDCWarning = buyersTotal === 0 ? true : !allDcsFulfilled;
      this.selectedDcs = dcTotal;
      this.enableAddBuyerBtn = !allDcsFulfilled;
    }
    if (dcTotal > 0 && updateErrors) {
      this.renderBuyerDCWarning = !allDcsFulfilled;
    }
    if (dcTotal > 0 && buyersTotal === 0 && updateErrors) {
      this.renderBuyerDCWarning = true;
    }
    if (buyersTotal !== this.buyersFormGroup.controls.length && updateErrors) {
      this.renderBuyerDCWarning = true;
    }
    if (buyersTotal !== this.buyersFormGroup.controls.length) {
      this.renderBuyerDCWarning = true;
    }
    this.selectedDcs = dcTotal;
  }

  updateBuyerOptions(updateErrors: boolean) {
    const allowedDCs = this.form.get('distributionCenters').value.slice();
    const toRemoveFromControls = {};
    const newUnassignedDCs = [];
    this.unassignedDCList.forEach(unassigned => {
      const subUnassignedDCs = [];
      let pushSub = false;
      if (Array.isArray(unassigned)) {
        pushSub = true;
        unassigned.forEach(subDC => {
          const allowed = allowedDCs.indexOf(subDC);
          if (allowed === -1) {
            toRemoveFromControls[subDC.value] = subDC;
            if (this.selectedDcs > 0) {
              this.selectedDcs -= 1;
            }
          } else {
            subUnassignedDCs.push(subDC);
          }
        });
      }

      if (pushSub) {
        newUnassignedDCs.push(subUnassignedDCs);
      }
    });
    const removeKeys = Object.keys(toRemoveFromControls);
    this.buyersFormGroup.controls.forEach((grp, i) => {
      const controls = this.getBuyersFormGroup(i).controls;
      const listOfSelectedDCs = controls['dcs'].value.slice();
      removeKeys.forEach(rk => {
        const index = listOfSelectedDCs.indexOf(toRemoveFromControls[rk]);
        if (index > -1) {
          listOfSelectedDCs.splice(index, 1);
        }
      });
      controls.dcs.setValue(listOfSelectedDCs);
      if (listOfSelectedDCs.length > 0) {
        if (listOfSelectedDCs.length !== allowedDCs.length) {
          this.renderBuyerDCWarning = true;
        }
      }
    });
    this.unassignedDCList = newUnassignedDCs;
    this.alignUnassignedDCs(updateErrors);
  }

  private getProductGroupName(form) {
    if (!form.name || form.name.length === 0) {
      return this.supplier.name.slice(0, 30);
    }
    return form.name;
  }

  public remitNameChange(e: any) {
    this.remitNameCounter = this.charCounterUpdate(e.length, this.remitNameMaxLength);
  }

  public charCounterUpdate(length: any, maxLength: number): string {
    return `${length}/${maxLength}`;
  }

  public getStreet2Length() {
    return '30';
  }

  public getAttentionLength() {
    return '25';
  }

  public getCityLength() {
    return '25';
  }

  public getCityLengthPickup() {
    return '28';
  }

  public getNameLength() {
    return '30';
  }

  public getZipCodeLength() {
    return '10';
  }

  remitEmailChange(e: any) {
    this.remitEmailCounter = this.charCounterUpdate(e.length, this.remitEmailMaxLength);
  }

  // New Contact Card for PickupAddress
  onSelectPickupAddress(contact: Contact) {
    this.form.get('pickupAddressId').markAsDirty();
    this.form.get('pickupAddressId').markAsTouched();
    this.form.get('pickupAddressId').setValue(contact.uniqueId);
  }

  onSmartyStreetsUseAddress(contact: Contact) {
    this._store.dispatch(addSupplierContact({ contact }));
  }

  onSmartyStreetsEditAddress() {
    this._store.dispatch(clearSupplierContactForm());
  }

  onClosePickupAddressModal() {
    this._store.dispatch(clearSupplierContactForm());
  }

  onRemovePickupAddress() {
    this.form.get('pickupAddressId').markAsDirty();
    this.form.get('pickupAddressId').markAsTouched();
    this.form.get('pickupAddressId').setValue(null);
  }

  // Utility Functions
  canShowInboundRouteType() {
    return this.isPickup;
  }
}
