import { createAction, props } from '@ngrx/store';
import { ProductDetail } from '../models/product-detail';
import { NewProductDetailTabs, ProductDetailTabs } from '../models/product-detail-tabs';
import { ProductGeneralFormModel } from '../models/product-general-form-model';

import { IAuditDetail } from '@kehe/phoenix-audit-history';
import { Payload } from '../../../models/payload';
import { ProductHistoryFilterItem } from '../models/product-history-filter';
import { ProductHistoryFilter } from './product-detail.state';
import { IChip } from '@kehe/phoenix-chips';
import { IScheduleChangesItem } from '@kehe/connect-common-scheduled-changes-control-lib';
import { BottleDepositCodes } from '../models/bottle-deposit-codes';
import { SubstitutionUpcFilter } from '../models/substitution-upc-filter';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentType } from '../models/document-type';
import { Document } from '../models/document';
import { ProductDetailAction } from '../models/product-detail-action';
import { NameValuePayload } from '../models/name-value-payload';
import { CodeValue } from '../../../models/code-value';
import { DistributionCenter } from '../models/distribution-center';
import { ProductPriorityRequest } from '../models/product-priority-request.model';
import { CutAndWrapAssociatesMakeProductGridDataItem } from '../models/cut-and-wrap-make-product-grid-data-item';
import { CutWrapComponentCheck } from '../models/cut-wrap-component-check.interface';
import { PendoEvent } from '../models/pendo-event.interface';

export enum ProductDetailActions {
  LoadProductDetail = '[Product Detail] Load Product Detail',
  LoadProductDetailSuccess = '[Product Detail] Load Product Detail Success',
  LoadProductDetailError = '[Product Detail] Load Product Detail Error',
  LoadNewProductDetailError = '[Product Detail] Load New Product Detail Error',
  NavigateToProductDetails = '[Product Detail] Navigate To Product Details',
  NavigateToSnapShotView = '[Product Detail] Navigate To SnapShotView',
  LoadProductTraits = '[Product] Load Product Traits',
  LoadProductTraitsSuccess = '[Product] Load Product Traits Success',
  CheckAndLoadDcShipperInformation = '[New Product] Check and Load Dc Shipper Information',
  LoadDcShipperInformation = '[New Product] Dc Shipper Information',
  SyncDcShipperInformation = '[New Product Detail] Sync Dc Shipper Information',
  LoadDcShipperInformationSuccess = '[New Product] Dc Shipper Information Success',
  CheckAndLoadDocuments = '[New Product Detail] Check and Load Documents',
  LoadDocuments = '[New Product Detail] Load Documents',
  SyncLoadDocuments = '[New Product Detail] Sync Load Documents',
  LoadDocumentsSuccess = '[New Product Detail] Load Documents Success',
  LoadDocumentsListSuccess = '[New Product Detail] Load Documents List Success',
  LoadDocumentTypes = '[Product Detail] Load Document Types',
  LoadDocumentTypesSuccess = '[Product Detail] Load Document Types Success',
  LoadExistingDocumentTypes = '[Product Detail] Load Existing Document Types',
  LoadExistingDocumentTypesSuccess = '[Product Detail] Load Existing Document Types Success',
  LoadComments = '[Product Detail] Load Comments',
  LoadCommentsSuccess = '[Product Detail] Load Comments Success',
  DownloadDocument = '[New Product Detail] Download Document',
  DeleteDocument = '[New Product Detail] Delete Document',
  ConfirmDeleteDocument = '[Product Detail] Confirm Delete Document',
  CancelDeleteDocument = '[Product Detail] Cancel Delete Document',
  DeleteDocumentSuccess = '[Product Detail] Delete Document Success',
  DeleteDocumentError = '[Product Detail] Delete Document Error',
  DownloadDocumentCompleted = '[New Product Detail] Download Document Completed',
  EditProduct = '[Product Detail] Edit Product',
  AttemptCancelEdit = '[Product Detail] Attempt Cancel Edit',
  ConfirmCancelEdit = '[Product Detail] Confirm Cancel Edit',
  ClearProductDetail = '[Product Detail] Clear Product Detail',
  ShowUnsavedChangesModal = '[Product Detail] Show Unsaved Changes Modal',
  HideUnsavedChangesModal = '[Product Detail] Hide Unsaved Changes Modal',
  ShowActivateProductModal = '[Product Detail] Show ActivateProduct Modal',
  HideActivateProductModal = '[Product Detail] Hide ActivateProduct Modal',
  ActivateNewProduct = '[Product Detail] Activate New Product',
  ApproveNewProduct = '[Product Detail] Approve New Product',
  ApproveNewProductSuccess = '[Product Detail] Approve New Product Success',
  ApproveNewProductFailure = '[Product Detail] Approve New Product Failure',
  ApproveNewProductSetCutWrapReviewStatus = '[Product Effects] Approve NewProduct Set CutWrapReview Status',
  ApproveNewProductCutWrapReview = '[Product Detail] Approve New Product Cut Wrap Review',
  ApproveNewProductCutWrapReviewSuccess = '[Product Detail] Approve New Product Cut Wrap Review Success',
  ApproveNewProductCutWrapReviewFailure = '[Product Detail] Approve New Product Cut Wrap Review Failure',
  SaveChanges = '[Product Detail] Save Changes',
  SaveChangesSuccess = '[Product Detail] Save Changes Success',
  SaveScheduledChangesSuccess = '[Product Detail] Save Scheduled Changes Success',
  UpdateScheduledChange = '[Product Detail] Update Scheduled Change',
  UpdateScheduledChangeSuccess = '[Product Detail] Update Scheduled Change Success',
  UpdateScheduledChangeError = '[Product Detail] Update Scheduled Change Error',
  SavePackChangeDataSuccess = '[Product Detail] Save Pack Change Data Success',
  SaveChangesError = '[Product Detail] Save Changes Error',
  SaveChangesValidationError = '[Product Detail] Save Changes Validation Error',
  SaveProductDetailsToStore = '[Product Detail] Save Product Details to store',
  GeneralFormChanged = '[Product Detail] General Form Changed',
  SaveProductRequest = '[Product Detail] Save Product Request',
  ToggleNavigationPanel = '[Product Detail] Toggle Navigation Panel',
  SetCurrentTab = '[Product Detail] Set Current Tab',
  HideAlertMessage = '[Product Detail] Hide Alert Message',
  GetFormDropdownData = '[Product Detail] Get Form Dropdown Data',
  ProductDcDetailsSelectedTabChange = '[Product Detail] Change DC Details Selected Tab',
  LoadProductInventory = '[Product Detail] Load Product Inventory',
  LoadDistributionCenterList = '[Product Detail] Load Distribution Center List',
  LoadDistributionCenterListSuccess = '[Product Detail] Load Distribution Center List Success',
  LoadProductInventorySuccess = '[Product Detail] Load Product Inventory Info Success',
  GetGeneralFormNameValuePairs = '[Product Detail] Load General Form Name Value Pairs',
  GetBeverageFormNameValuePairs = '[Product Detail] Load Beverage Name Value Pairs',
  GetStorageShippingFormNameValuePairs = '[Product Detail] Load Storage Shipping Form Name Value Pairs',
  GetDcDetailsFormNameValuePairs = '[Dc Details] Load Dc Details Name Value Pairs',
  GetPricingFormValuePairs = '[Product Detail] Load Product Pricing Value Pairs',
  GetPricingFormValuePairsSuccess = '[Product Detail] Load Product Pricing Value Pairs Success',
  GetGeneralFormNameValuePairsSuccess = '[Product Detail] Load General Form Name Value Pairs Success',
  GetBeverageFormNameValuePairsSuccess = '[Product Detail] Load Beverage Name Value Pairs Success',
  GetStorageShippingFormNameValuePairsSuccess = '[Product Detail] Load Storage Shipping Form Name Value Pairs Success',
  GetDcDetailsFormNameValuePairsSuccess = '[Dc Details] Load Dc Details Name Value Pairs Success',
  GetDcDetailsFormSubstitutionUpcList = '[Dc Details] Load Dc Details Substitution List Upc',
  GetDcDetailsFormSubstitutionUpcListSuccess = '[Dc Details] Load Dc Details Substitution List Upc Success',
  GetProductsList = '[Dc Details] Load Products List',
  GetProductsListSuccess = '[Dc Details] Load Products List Success',
  // GetProductTypeList = '[Product Detail] Load Product Type Name Value Pairs Success',
  // GetProductTypeListSuccess = '[Product Detail] Load Product Type Name Value Pairs Success',
    // Audit History
  LoadProductHistoryList = '[Product Detail] Load History List',
  LoadProductHistoryListSuccess = '[Product Detail] Load History List Success',
  LoadProductHistoryListError = '[Product Detail] Load History List Error',
  ProductHistoryFilterOpenPanel = '[Product Detail] History open filter panel',
  ProductHistoryFilterClosePanel = '[Product Detail] History close filter panel',
  ProductHistoryApplyFilters = '[Product Detail] History apply filters',
  ProductHistoryClearFilters = '[Product Detail] History clear filters',
  ProductHistoryChipClicked = '[Product Detail] History Chip Clicked',
  ProductHistoryClearAllChipsClicked = '[Product Detail] History Clear All Chips clicked',
  ProductHistoryLoadFiltersData = '[Product Detail] History load filters data',
  ProductHistoryLoadFiltersDataSuccess = '[Product Detail] History load filters data Success',
  ProductHistoryLoadFiltersDataFailure = '[Product Detail] History load filters data Failure',
  ProductHistorySearchChange = '[Product Detail] History search changed',
  ProductHistoryPageIndexChanged = '[Product Detail] History page index changed',
  ProductHistoryPageSizeChanged = '[Product Detail] History page size changed',
  ProductHistoryDisplayDetailModal = '[Product Detail] History display detail modal',
  ProductHistoryCloseDetailModal = '[Product Detail] History close detail modal',
  ShowTicketReasonModal = '[Product Detail] Show Ticket And Reason Modal',
  HideTicketReasonModal = '[Product Detail] Hide Ticket And Reason Modal',
  AddTicketAndReasonToForm = '[Product Detail] Add Ticket And Reason to Form',
  SetScheduleChanges = '[Product Detail] Set Schedule Changes',
  SetSubstitutionUpcFilter = '[Product Detail] Set Substitution Upc Filter',
  GetScheduledChangesCount = '[Product Detail] Get Scheduled Changes Count',
  GetScheduledChangesCountSuccess = '[Product Detail] Get Scheduled Changes Count Success',
  GetScheduledChangesCountFailure = '[Product Detail] Get Scheduled Changes Count Failure',
  ActivateProductSuccess = '[Product Detail] Activate Product Success',
  ActivateProductFailure = '[Product Detail] Activate Product Failure',
  HideScheduledChangesAlert = '[Product Detail] Hide Scheduled Changes Alert',
  GetScheduledChanges = '[Product Detail] Get Scheduled Changes',
  GetScheduledChangesSuccess = '[Product Detail] Get Scheduled Changes Success',
  GetScheduledChangesFailure = '[Product Detail] Get Scheduled Changes Failure',
  DeleteScheduledChanges = '[Product Detail] Delete Scheduled Changes',
  DeleteScheduledChangesSuccess = '[Product Detail] Delete Scheduled Changes Success',
  DeleteScheduledChangesFailure = '[Product Detail] Delete Scheduled Changes Failure',
  CheckScheduledChangesAfterDeleteSuccess = '[Product Detail] Check Scheduled Changes After Delete Success',
  DisplayUploadDocumentsModal = '[Product Detail] Display Upload Documents Modal',
  ShowNewProductCommentsModal = '[Product Detail] Show New Product Comments Modal',
  ShowScheduleChangeEditModal = '[Product Detail] Show Schedule Change Edit Modal',
  ShowAddNameValuePairModal = '[Product Detail] Show Add Name Value Pair Modal',
  ShowNewProductReviseModal = '[Product Detail] Show New Product Revise Modal',
  ShowReviewBrandModal = '[Product Detail] Show Review Brand Modal',
  ShowCreatePriceGroupModal = '[Product Detail] Show Create Price Group Modal',
  SaveBottleDepositCodes = '[Product Detail] Saved Bottle Deposit Codes',
  SetEditingDc = '[Product Detail] Set Currently Editing DC information',
  GetProductGroupsOfDcs = '[Product Detail] Get Product Groups Of Dcs',
  GetProductGroupsOfDcsCompleted = '[Product Detail] Get Product Groups Of Dcs Completed',
  SetDcActivateForForm = '[Product Detail] Set Currently Editing DC to Activate',
  GetProductPricingOfDcs = '[Product Detail] Get Product Pricing Of Dcs',
  GetProductPricingOfDcsCompleted = '[Product Detail] Get Product Pricing Of Dcs Completed',
  SetIsNewProduct = '[Product Detail] Set if Product is New',
  SetIsSnapShot = '[Product Detail] Set Sanp Shot View',
  UpdateTabRemaningCount = '[Product Detail] Update Tab Remaning Count',
  UpdateNewProductAllTabs = '[Product Detail] Update New Product All Tabs',
  UpdatePricingTabCountWithComparableUpc = '[Product Detail Effects] Update PricingTab Count With ComparableUpc',
  GetComparableUpcDetailsForPricingTabCount = '[Product Detail Effects] Get ComparableUpc Details For PricingTab Count',
  GetDCShipperInformations = '[Product Detail] Get DC Shipper Informations',
  GetDCShipperInformationsSuccess = '[Product Detail] Get DC Shipper Informations Success',
  LoadRequiredDocumentTypesSuccess = '[Product Detail] Load Required Document Types Success',
  UploadAndSaveDocument = '[Product Detail] Upload And Save Document',
  UploadDocumentToS3 = '[Product Detail] Upload Document To S3',
  UploadDocument = '[Product Detail] Upload Document',
  UploadExistingDocument = '[Product Detail] Upload Existing Document',
  UploadAndSaveDocumentSuccess = '[Product Detail] Upload And Save Document Success',
  UploadAndSaveDocumentError = '[Product Detail] Upload And Save Document Error',
  UpdateDocumentTabStatus = '[Product Detail] Update Document Tab Status',
  LoadDocumentTraitsSuccess = '[Product Detail] Load Document Traits Success',
  SaveComment = '[Product Detail] Save Comment',
  SaveCommentSuccess = '[Product Detail] Save Comment Success',
  SaveCommentError = '[Product Detail] Save Comment Error',
  SaveReviseStatus = '[Product Detail] Save Revise Status',
  SaveReviseStatusSuccess = '[Product Detail] Save Revise Status Success',
  SaveReviseStatusError = '[Product Detail] Save Revise Status Error',
  LoadPriorityList = '[Product Detail] Load Priority List',
  LoadPriorityListSuccess = '[Product Detail] Load Priority List Success',
  ShowSetPriorityModal = '[Product Detail] Show Set Priority Modal',
  HideSetPriorityModal = '[Product Detail] Hide Set Priority Modal',
  ShowRemovePriorityModal = '[Product Detail] Show/Hide Remove Priority Modal',
  SavePriority = '[Product Detail] Save Priority',
  SavePrioritySuccess = '[Product Detail] Save Priority Success',
  SavePriorityError = '[Product Detail] Save Priority Error',
  RemovePriority = '[Product Detail] Remove Priority',
  RemovePrioritySuccess = '[Product Detail] Remove Priority Success',
  RemovePriorityError = '[Product Detail] Remove Priority Error',
  UpsertNameValuePair = '[Product Detail] Upsert Name Value Pair',
  UpsertNameValuePairSuccess = '[Product Detail] Upsert Name Value Pair Success',
  UpsertNameValuePairError = '[Product Detail] Upsert Name Value Pair Error',
  ReviewBrandFormChanged = '[Approve Review Brand] Form Changed',
  UpdateFormValidityState = '[Approve Review Brand] Update Form Validity',
  ApproveReviewBrandChanges = '[Approve Review Brand] Approve Changes',
  CreateAndApproveNewBrand = '[Approve Review Brand] Create and Approve Brans Changes',
  ApproveReviewBrandChangesSuccess = '[Approve Review Brand] Approve Changes Success',
  ApproveReviewNewBrandChangesSuccess = '[Approve Review New Brand] Approve Changes Success',
  DisplayReviewBrandSuccessToast = '[Approve Review Brand] Display Success Toast',
  ApproveReviewBrandChangesError = '[Approve Review Brand] Approve Changes Error',
  ClearFormDirtyStatus = '[Product Detail] Clear Form Dirty Status',
  LoadProductSnapshot = '[Product Detail] Load Product Snapshot',
  LoadProductSnapshotSuccess = '[Product Detail] Load Product Snapshot Success',
  LoadNewProductHistoryList = '[Product Detail] Load New Product HistoryList',
  LoadNewProductHistoryListSuccess = '[Product Detail] Load New Product HistoryList Success',
  ApplyValuesToPricingForm = '[Product Detail] New Product Pricing Apply All',
  GetCountryOfOriginList = '[Product Detail] Product Detail  Get CountryOfOrigin List',
  GetCountryOfOriginSuccess = '[Product Detail] Product Detail   Get CountryOfOrigin List Success',
  NavigateToActiveProductDetails = '[Product Detail] Navigate To Active Product Details',
  DisplayBannerForTraitSelection = '[Product Detail] Display Banner for Trait Selection',
  DisplayBannerForReviewingRequiredFields = '[Product Detail] Display Banner for Reviewing Required Fields',
  ChangeBulkDcActivationModalVisibility = '[Dc Details View] Change Bulk Dc Activation Modal Visibility',
  ChangeBulkDcSetToSeasonalModalVisibility = '[Dc Details View] Change Bulk Dc Set To Seasonal Modal Visibility',
  ChangeBulkDcSubstituteProductModalVisibility = '[Dc Details View] Change Bulk Dc Substitute Product Modal Visibility',
  ChangeBulkDcRemoveSubstituteProductModalVisibility = '[Dc Details View] Change Bulk Dc Remove Substitute Product Modal Visibility',
  CheckDcProductGroupForBulkDcActivation = '[Dc Activation Modal] Check DcProductGroup For Bulk DcActivation',
  CheckDcProductGroupForBulkDcActivationSuccess = '[Dc Activation Modal] Check DcProductGroup For Bulk DcActivation Success',
  BulkDcActivationModalClickBack = '[Dc Activation Modal] Bulk DcActivation Modal Click Back',
  SaveBulkDCActivation = '[Dc Activation Modal] Save Bulk DC Activation',
  GetComparableUpcPricing = '[New Product Detail] Apply Comparable Upc Pricing',
  GetLinkedDraftProducts = '[New Product Detail] Get Linked Draft Products',
  GetLinkedDraftProductsSuccess = '[New Product Detail] Get Linked Draft Products Success',
  ShowLinkedDraftProductsModal = '[New Product Detail] Show Linked Draft Products Modal',
  GetComparableUpcPricingSuccess = '[New Product Detail] Apply Comparable Upc Pricing Success',
  GetComparableUpcPricingError = '[New Product Detail] Apply Comparable Upc Pricing Error',
  SaveBulkDCActivationSuccess = '[Dc Activation Modal] Save Bulk DC Activation Success',
  SaveBulkDCActivatioError = '[Dc Activation Modal] Save Bulk DC Activation Error',
  PreValidateForDcActivation = '[Dc Activation] Pre Validate For DcActivation',
  ActivationPreValidationComplete = '[Dc Activation] Activation PreValidation Complete',
  ClearDcActivationPreValidationErrors = '[Dc Activation] Cear DcActivation PreValidation Errors',
  PreValidateForProductEdit = '[Product Edit] Pre Validate For Product Edit',
  ProductEditPreValidationComplete = '[Product Edit] Product Edit PreValidation Complete',
  ClearProductEditPreValidationErrors = '[Product Edit] Cear Product Edit PreValidation Errors',
  SaveBulkDCStatusChange = '[Dc Status Set Modal] Save Bulk DC Status Change',
  SaveBulkDCStatusChangeSuccess = '[Dc Status Set Modal] Save Bulk DC Status Change Success',
  SaveBulkDCStatusChangeError = '[Dc Status Set Modal] Save Bulk DC Status Change Error',
  GetDcDetailsSubstitutionCodeNameValuePairs = '[Dc Details Substitute Product] Load Dc SubstitutionCode Name Value Pairs',
  GetDcDetailsSubstitutionCodeNameValuePairsSuccess = '[[Dc Details Substitute Product] Load Dc SubstitutionCode Name Value Pairs Success',
  SaveBulkDCSubstituteProduct = '[Dc Substitute Product Modal] Save Bulk DC Substitute Product',
  SaveBulkDCSubstituteProductSuccess = '[Dc Substitute Product Modal] Save Bulk DC Substitute Product Success',
  SaveBulkDCSubstituteProductError = '[Dc Substitute Product Modal] Save Bulk DC Substitute Product Error',
  SyncNewBrand = 'Sync New Brand',
  SyncNewBrandComplete = 'Sync New Brand Success',
  RemoveBulkDCSubstitute = '[Dc Remove Substitute Modal] Remove Bulk DC Substitutee',
  RemoveBulkDCSubstituteSuccess = '[Dc Remove Substitute Modal] Remove Bulk DC Substitute Success',
  RemoveBulkDCSubstituteError = '[Dc Remove Substitute Modal] Remove Bulk DC Substitute Error',
  LoadFlattenedProductGroups = '[New Product Detail Pricing] Load Flattened Product Groups',
  LoadFlattenedProductGroupsSuccess = '[New Product Detail Pricing] Load Flattened Product Groups Success',
  ShowUpdatePriceGroupModal = '[Product Detail Pricing Tab] Show Update Price Group Modal',
  UpdatePriceGroups = '[Update Price Group Modal] Update Price Groups',
  UpdatePriceGroupsSuccess = '[Price Group Update API] Update Price Groups Success',
  UpdatePriceGroupsError = '[Price Group Update API] Update Price Groups Error',
  ShowApplyComparableUpcModal = '[Product Detail] Show Apply Comparable Upc Modal',
  ShowRefreshPricingModal = '[Product Detail] Show Refresh Pricing Modal',
  CreatePriceGroup = '[Product Detail] Create Price Group',
  CreatePriceGroupSuccess = '[Product Detail] Create Price Group Success',
  CreatePriceGroupError = '[Product Detail] Create Price Group Error',
  GetEachDimensionWeightMedianValue = '[New Product Detail Pricing Tab] Get EachDimension Weight Median Value',
  GetEachDimensionWeightMedianValueSuccess = '[New Product Detail Pricing Tab] Get EachDimension Weight Median Value Success',
  GetEachDimensionWeightMedianValueError = 'New Product Detail Pricing Tab] Get EachDimension Weight Median Value Error',
  ShowPricingUpdateWarningModal = '[New Product Detail Storage Shipping Tab] Show Pricing Update Warning Modal',
  GetProductPricingOfActiveDc = '[DC Details View] Get Pricing Of Selected Active Dc',
  GetProductPricingOfActiveDcCompleted = '[DC Details View] Get Pricing Of Selected Active Dc Completed',
  GetDcProductsCostFactorValue = '[Product Detail Dc Details Tab] Get DcProducts CostFactor Value',
  GetDcProductsCostFactorValueSuccess = '[Product Detail Dc Details Tab] Get DcProducts CostFactor Value Success',
  GetDcProductsCostFactorValueError = '[Product Detail Dc Details Tab] Get DcProducts CostFactor Value Error',
  GetIxOneProductsUpdate = '[Product Detail Menu] Get IxOne Products Update',
  GetIxOneProductsUpdateSuccess = '[Product Detail Menu] Get IxOne Products Update Success',
  GetIxOneProductsUpdateFailure = '[Product Detail Menu] Get IxOne Products Update Failure',
  GetIxOneProductsUpdateError = '[Product Detail Menu]] Get IxOne Products Update Error',
  ShowIxOneProductsUpdateSuccessBanner = '[Product Detail Effects] Show IxOne Product Update Success Banner',
  IxOneProductsImageCheckSuccess = '[Product Detail Effects] IxOne Products Image Check Success',
  IxOneProductsImageCheckFailed = '[Product Detail Effects] IxOne Products Image Check Failed',
  IxOneProductsImageCheckSkipped = '[Product Detail Effects] IxOne Products Image Check Skipped',
  GetProductAssetsProductsUpdate = '[Product Detail Menu] Get ProductAssets Products Update',
  GetProductAssetsProductsUpdateSuccess = '[Product Detail Menu] Get ProductAssets Products Update Success',
  GetProductAssetsProductsUpdateNotFound = '[Product Detail Menu] Get ProductAssets Products Update Not Found',
  GetProductAssetsProductsUpdateFailure = '[Product Detail Menu] Get ProductAssets Products Update Failure',
  GetProductAssetsProductsUpdateError = '[Product Detail Menu]] Get ProductAssets Products Update Error',
  ShowProductAssetsProductsUpdateSuccessBanner = '[Product Detail Effects] Show ProductAssets Product Update Success Banner',
  ShowProductAssetsProductsDoesNotExistBanner = '[Product Detail Effects] Show ProductAssets Product Does Not Exist Banner',
  ProductAssetsProductsImageCheckSuccess = '[Product Detail Effects] ProductAssets Products Image Check Success',
  ProductAssetsProductsImageCheckFailed = '[Product Detail Effects] ProductAssets Products Image Check Failed',
  ProductAssetsProductsImageCheckSkipped = '[Product Detail Effects] ProductAssets Products Image Check Skipped',
  AddCutAndWrapMakeProduct = '[CutAndWrap Make Product Component] Add CutAndWrap Make Product',
  DeleteCutAndWrapMakeProduct = '[CutAndWrap Make Product Component] Delete CutAndWrap Make Product',
  UpdateCutAndWrapMakeProductQuantity = '[CutAndWrap Make Product Component] Update CutAndWrap Make Product Quantity',
  GetActiveAssociatedMakeProducts = '[CutAndWrap Buy Product Component] Get Active Associated Make Products',
  GetActiveAssociatedMakeProductsSuccess = '[CutAndWrap Buy Product Component] Get Active Associated Make Products Success',
  AddCutWrapDc = '[CutAndWrap Tab] Add CutWrap Dc',
  CutWrapDcTabChanged = '[CutAndWrap Tab] CutWrap Dc Tab Changed',
  confirmCutWrapDeleteDC = '[CutAndWrap Make Product Component] Confirm Delete CutAndWrap Make DC',
  cancelCutWrapDeleteDC = '[CutAndWrap Make Product Component] Cancel Delete CutAndWrap Make DC',
  RemoveCutWrapDc = '[CutAndWrap Make Product Component] Delete CutAndWrap Make DC',
  DeleteCutWrapDC = '[CutAndWrap Make Product Component] Deleting CutAndWrap Make DC',
  SelectedCutAndWrapCopyDC = '[CutAndWrap Tab] SelectedCutAndWrapCopyDC',
  ClickCopyCutWrapDC = '[CutAndWrap Tab] Click Copy Cut Wrap DC',
  CutWrapCopyComponentCheckSuccess = '[CutAndWrap Tab] Copy Component Check Success',
  CutWrapCopyComponentCheckFailed = '[CutAndWrap Tab] Copy Component Check Failed',
  CutWrapCopyModalClosed = '[CutAndWrap Tab] Copy Wrap Modal Closed',
  CutWrapEditClicked = '[CutAndWrap Tab] CutWrap Edit Clicked',
  LoadedGeneralViewMode = '[General View] Loaded General ViewMode',
  SetCutWrapAddProductSelectedItem = '[CutAndWrap Make Product Component] Set CutWrap AddProduct SelectedItem',
  ClearCutWrapAddProductSelectedItem = '[CutAndWrap Make Product Component] Clear CutWrap AddProduct SelectedItem'
}

export const loadProductDetail = createAction(
  ProductDetailActions.LoadProductDetail,
  props<{ upc: string }>()
);
export const loadProductDetailSuccess = createAction(
  ProductDetailActions.LoadProductDetailSuccess,
  props<{ product: ProductDetail }>()
);
export const syncDcShipperInformation = createAction(
  ProductDetailActions.SyncDcShipperInformation,
  props<{ expectedUpdatedTime: string }>()
);
export const checkAndLoadDcShipperInformation = createAction(
  ProductDetailActions.CheckAndLoadDcShipperInformation,
  props<{ upc: string }>()
);
export const loadDcShipperInformation = createAction(
  ProductDetailActions.LoadDcShipperInformation,
  props<{ upc: string }>()
);
export const loadDcShipperInformationSuccess = createAction(
  ProductDetailActions.LoadDcShipperInformationSuccess,
  props<{ data: any }>()
);
export const loadProductDetailError = createAction(
  ProductDetailActions.LoadProductDetailError,
);
export const loadNewProductDetailError = createAction(
  ProductDetailActions.LoadNewProductDetailError,
  props<{ upc: string , error: any }>()
);
export const navigateToProductDetails = createAction(
  ProductDetailActions.NavigateToProductDetails,
  props<{ upc: string }>()
);

export const navigateToSnapShotView = createAction(
  ProductDetailActions.NavigateToSnapShotView,
  props<{ upc: string }>()
);


export const navigateToActiveProductDetails = createAction(
  ProductDetailActions.NavigateToActiveProductDetails,
  props<{ upc: string }>()
);
export const editProduct = createAction(
  ProductDetailActions.EditProduct,
);
export const generalFormChanged = createAction(
  ProductDetailActions.GeneralFormChanged,
  props<{ form: ProductGeneralFormModel, isDirty: boolean, isValidated: boolean }>()
);
export const saveProductRequest = createAction(
  ProductDetailActions.SaveProductRequest,
  props<{ request: any }>()
);
export const saveChanges = createAction(
  ProductDetailActions.SaveChanges,
);
export const saveChangesSuccess = createAction(
  ProductDetailActions.SaveChangesSuccess,
  props<{ product: ProductDetail }>()
);

export const saveScheduledChangesSuccess = createAction(
  ProductDetailActions.SaveScheduledChangesSuccess,
  props<{ product: ProductDetail, form: any, isScheduledChange: boolean }>()
);

export const updateScheduledChangeSuccess = createAction(
  ProductDetailActions.UpdateScheduledChangeSuccess,
  props<{ response: any }>()
);

export const updateScheduledChangeError = createAction(
  ProductDetailActions.UpdateScheduledChangeError,
  props<{ response: any }>()
);

export const updateScheduledChange = createAction(
  ProductDetailActions.UpdateScheduledChange,
  props<{ request: any }>()
);

export const savePackChangeDataSuccess = createAction(
  ProductDetailActions.SavePackChangeDataSuccess,
  props<{ product: ProductDetail, request: any }>()
);

export const saveChangesError = createAction(
  ProductDetailActions.SaveChangesError,
  props<{ error: any }>()
);
export const clearFormDirtyStatus = createAction(
  ProductDetailActions.ClearFormDirtyStatus,
);
export const saveChangesValidationError = createAction(
  ProductDetailActions.SaveChangesValidationError,
);
export const saveProductDetailsToStore = createAction(
  ProductDetailActions.SaveProductDetailsToStore,
  props<{ product: ProductDetail }>()
);

export const attemptCancelEdit = createAction(
  ProductDetailActions.AttemptCancelEdit
);
export const confirmCancelEdit = createAction(
  ProductDetailActions.ConfirmCancelEdit,
);
export const clearProductDetail = createAction(
  ProductDetailActions.ClearProductDetail
);
export const showUnsavedChangesModal = createAction(
  ProductDetailActions.ShowUnsavedChangesModal,
);
export const hideUnsavedChangesModal = createAction(
  ProductDetailActions.HideUnsavedChangesModal,
);
export const showActivateProductModal = createAction(
  ProductDetailActions.ShowActivateProductModal,
);
export const hideActivateProductModal = createAction(
  ProductDetailActions.HideActivateProductModal,
);
export const activateNewProduct = createAction(
  ProductDetailActions.ActivateNewProduct,
);
export const approveNewProduct = createAction(
  ProductDetailActions.ApproveNewProduct,
);
export const toggleNavigationPanel = createAction(
  ProductDetailActions.ToggleNavigationPanel
);
export const setCurrentTab = createAction(
  ProductDetailActions.SetCurrentTab,
  props<{ tab: ProductDetailTabs }>()
);
export const hideAlertMessage = createAction(
  ProductDetailActions.HideAlertMessage
);
export const productDcDetailsSelectedTabChange = createAction(
  ProductDetailActions.ProductDcDetailsSelectedTabChange,
  props<{ tab: string }>()
);
export const loadProductInventory = createAction(
  ProductDetailActions.LoadProductInventory
);
export const loadProductInventorySuccess = createAction(
  ProductDetailActions.LoadProductInventorySuccess,
  props<{ inventory: Array<any> }>()
);
export const loadDistributionCenterList = createAction(
  ProductDetailActions.LoadDistributionCenterList
);
export const loadDistributionCenterListSuccess = createAction(
  ProductDetailActions.LoadDistributionCenterListSuccess,
  props<{ dcList: DistributionCenter[] }>()
);
export const loadProductTraits = createAction(
  ProductDetailActions.LoadProductTraits,
);
export const loadProductTraitsSuccess = createAction(
  ProductDetailActions.LoadProductTraitsSuccess,
  props<{ trait: any }>()
);
export const getGeneralFormNameValuePairs = createAction(
  ProductDetailActions.GetGeneralFormNameValuePairs,
);
export const getBeverageFormNameValuePairs = createAction(
  ProductDetailActions.GetBeverageFormNameValuePairs,
);
export const getStorageShippingFormNameValuePairs = createAction(
  ProductDetailActions.GetStorageShippingFormNameValuePairs,
);
export const getPricingFormValuePairs = createAction(
  ProductDetailActions.GetPricingFormValuePairs,
);
export const getPricingFormValueSuccess = createAction(
  ProductDetailActions.GetPricingFormValuePairsSuccess,
  props<{ filters: any }>()
);
export const getDcDetailsFormNameValuePairs = createAction(
  ProductDetailActions.GetDcDetailsFormNameValuePairs,
);
export const getGeneralFormNameValuePairsSuccess = createAction(
  ProductDetailActions.GetGeneralFormNameValuePairsSuccess,
  props<{ filters: any }>()
);
export const getBeverageFormNameValuePairsSuccess = createAction(
  ProductDetailActions.GetBeverageFormNameValuePairsSuccess,
  props<{ filters: any }>()
);
export const getStorageShippingFormNameValuePairsSuccess = createAction(
  ProductDetailActions.GetStorageShippingFormNameValuePairsSuccess,
  props<{ filters: any }>()
);
export const getDcDetailsFormNameValuePairsSuccess = createAction(
  ProductDetailActions.GetDcDetailsFormNameValuePairsSuccess,
  props<{ filters: any }>()
);
export const setSubstitutionUpcFilter = createAction(
  ProductDetailActions.SetSubstitutionUpcFilter,
  props<{ substitutionUpcFilter: SubstitutionUpcFilter }>()
);
export const getDcDetailsFormSubstitutionUpcList = createAction(
  ProductDetailActions.GetDcDetailsFormSubstitutionUpcList,
  props<{ substitutionUpcFilter: SubstitutionUpcFilter }>()
);
export const getDcDetailsFormSubstitutionUpcListSuccess = createAction(
  ProductDetailActions.GetDcDetailsFormSubstitutionUpcListSuccess,
  props<{ filters: Array<any> }>()
);

export const getDcDetailsSubstitutionCodeNameValuePairs = createAction(
  ProductDetailActions.GetDcDetailsSubstitutionCodeNameValuePairs,
);

export const getDcDetailsSubstitutionCodeNameValuePairsSuccess = createAction(
  ProductDetailActions.GetDcDetailsSubstitutionCodeNameValuePairsSuccess,
  props<{ response: any }>()
);

// History Tab Actions
export const loadProductHistoryList = createAction(
  ProductDetailActions.LoadProductHistoryList
);

export const loadProductHistoryListSuccess = createAction(
  ProductDetailActions.LoadProductHistoryListSuccess,
  props<{ data: Payload<Array<IAuditDetail>> }>()
);

export const loadProductHistoryListError = createAction(
  ProductDetailActions.LoadProductHistoryListError
);
export const productHistorySearchChange = createAction(
  ProductDetailActions.ProductHistorySearchChange,
  props<{ search: string }>()
);
export const productHistoryFilterOpenPanel = createAction(
  ProductDetailActions.ProductHistoryFilterOpenPanel
);
export const productHistoryFilterClosePanel = createAction(
  ProductDetailActions.ProductHistoryFilterClosePanel
);
export const productHistoryApplyFilters = createAction(
  ProductDetailActions.ProductHistoryApplyFilters,
  props<{ filters: ProductHistoryFilter }>()
);
export const productHistoryClearFilters = createAction(
  ProductDetailActions.ProductHistoryClearFilters
);
export const productHistoryChipClicked = createAction(
  ProductDetailActions.ProductHistoryChipClicked,
  props<{ chip: IChip }>()
);
export const productHistoryClearAllChipsClicked = createAction(
  ProductDetailActions.ProductHistoryClearAllChipsClicked
);
export const productHistoryLoadFiltersData = createAction(
  ProductDetailActions.ProductHistoryLoadFiltersData
);
export const productHistoryLoadFiltersDataSuccess = createAction(
  ProductDetailActions.ProductHistoryLoadFiltersDataSuccess,
  props<{ data: Array<Payload<Array<ProductHistoryFilterItem>>> }>()
);
export const productHistoryLoadFiltersDataFailure = createAction(
  ProductDetailActions.ProductHistoryLoadFiltersDataFailure
);
export const productHistoryPageIndexChanged = createAction(
  ProductDetailActions.ProductHistoryPageIndexChanged,
  props<{ index: number }>()
);
export const productHistoryPageSizeChanged = createAction(
  ProductDetailActions.ProductHistoryPageSizeChanged,
  props<{ size: number }>()
);
export const productHistoryDisplayDetailModal = createAction(
  ProductDetailActions.ProductHistoryDisplayDetailModal,
  props<{ row: IAuditDetail }>()
);
export const productHistoryCloseDetailModal = createAction(
  ProductDetailActions.ProductHistoryCloseDetailModal
);
export const showTicketReasonModal = createAction(
  ProductDetailActions.ShowTicketReasonModal
);
export const hideTicketReasonModal = createAction(
  ProductDetailActions.HideTicketReasonModal
);
export const addTicketAndReasonToForm = createAction(
  ProductDetailActions.AddTicketAndReasonToForm,
  props<{ ticket: string, reason: string }>()
);
export const setScheduleChanges = createAction(
  ProductDetailActions.SetScheduleChanges,
  props<{ isScheduleChange: boolean }>()
);
export const getScheduledChangesCount = createAction(
  ProductDetailActions.GetScheduledChangesCount
);
export const getScheduledChangesCountSuccess = createAction(
  ProductDetailActions.GetScheduledChangesCountSuccess,
  props<{ scheduleChangesCount: number }>()
);
export const getScheduledChangesCountFailure = createAction(
  ProductDetailActions.GetScheduledChangesCountFailure
);
export const activateProductSuccess = createAction(
  ProductDetailActions.ActivateProductSuccess,
  props<{ activationResponse: any, pendoEvent: PendoEvent }>()
);
export const activateProductFailure = createAction(
  ProductDetailActions.ActivateProductFailure,
  props<{ activationResponse: any }>()
);
export const approveNewProductSuccess = createAction(
  ProductDetailActions.ApproveNewProductSuccess,
  props<{ approveResponse: any, pendoEvent: PendoEvent }>()
);
export const approveProductFailure = createAction(
  ProductDetailActions.ApproveNewProductFailure,
  props<{ approveResponse: any }>()
);
export const approveNewProductSetCutWrapReviewStatus = createAction(
  ProductDetailActions.ApproveNewProductSetCutWrapReviewStatus
);
export const approveNewProductCutWrapReview = createAction(
  ProductDetailActions.ApproveNewProductCutWrapReview,
);
export const approveNewProductCutWrapReviewSuccess = createAction(
  ProductDetailActions.ApproveNewProductCutWrapReviewSuccess,
  props<{ approveResponse: any }>()
);
export const approveNewProductCutWrapReviewFailure = createAction(
  ProductDetailActions.ApproveNewProductCutWrapReviewFailure,
  props<{ approveResponse: any }>()
);
export const hideScheduledChangesAlert = createAction(
  ProductDetailActions.HideScheduledChangesAlert
);
export const getScheduledChanges = createAction(
  ProductDetailActions.GetScheduledChanges,
  props<{ query: string }>()
);
export const getScheduledChangesSuccess = createAction(
  ProductDetailActions.GetScheduledChangesSuccess,
  props<{ data: Array<IScheduleChangesItem> }>()
);
export const getScheduledChangesFailure = createAction(
  ProductDetailActions.GetScheduledChangesFailure
);
export const deleteScheduledChanges = createAction(
  ProductDetailActions.DeleteScheduledChanges,
  props<{ id: number }>()
);
export const checkScheduledChangesAfterDeleteSuccess = createAction(
  ProductDetailActions.CheckScheduledChangesAfterDeleteSuccess,
  props<{ id: number }>()
);
export const deleteScheduledChangesSuccess = createAction(
  ProductDetailActions.DeleteScheduledChangesSuccess,
  props<{ id: number }>()
);
export const deleteScheduledChangesFailure = createAction(
  ProductDetailActions.DeleteScheduledChangesFailure
);
export const saveBottleDepositCodes = createAction(
  ProductDetailActions.SaveBottleDepositCodes,
  props<{ bottleDepositCodes: Array<BottleDepositCodes> }>()
);
export const setEditingDc = createAction(
  ProductDetailActions.SetEditingDc,
  props<{ dcDetails: any }>()
);
export const getProductGroupsOfDcs = createAction(
  ProductDetailActions.GetProductGroupsOfDcs,
  props<{ supplierDcList: any[] }>()
);
export const getProductGroupsOfDcsCompleted = createAction(
  ProductDetailActions.GetProductGroupsOfDcsCompleted,
  props<{ productGroups: any }>()
);
export const setDcActivateForForm = createAction(
  ProductDetailActions.SetDcActivateForForm,
  props<{ isDcToActivate: boolean }>()
);
export const getProductPricingOfDcs = createAction(
  ProductDetailActions.GetProductPricingOfDcs,
  props<{ upc, inActiveDcList: any[] }>()
);
export const getProductPricingOfDcsCompleted = createAction(
  ProductDetailActions.GetProductPricingOfDcsCompleted,
  props<{ dcProductPricing: any }>()
);
export const setIsNewProduct = createAction(
  ProductDetailActions.SetIsNewProduct,
  props<{ isNewProduct: boolean }>()
);

export const SetIsSnapShot = createAction(
  ProductDetailActions.SetIsSnapShot,
  props<{ isSnapShot: boolean }>()
);
export const updateTabRemaningCount = createAction(
  ProductDetailActions.UpdateTabRemaningCount,
  props<{ form: UntypedFormGroup, newProductTab: NewProductDetailTabs, isTabValid?: boolean}>()
);
export const updateNewProductAllTabs = createAction(
  ProductDetailActions.UpdateNewProductAllTabs
);

export const updatePricingTabCountWithComparableUpc = createAction(
  ProductDetailActions.UpdatePricingTabCountWithComparableUpc
);

export const getComparableUpcDetailsForPricingTabCount = createAction(
  ProductDetailActions.GetComparableUpcDetailsForPricingTabCount,
  props<{ response: any[] }>()
);

export const loadDocumentTypes = createAction(
  ProductDetailActions.LoadDocumentTypes
);

export const loadDocumentTypesSuccess = createAction(
  ProductDetailActions.LoadDocumentTypesSuccess,
  props<{ documentTypes: Array<any> }>()
);

export const loadExistingDocumentTypes = createAction(
  ProductDetailActions.LoadExistingDocumentTypes
);

export const loadExistingDocumentTypesSuccess = createAction(
  ProductDetailActions.LoadExistingDocumentTypesSuccess,
  props<{ existingDocumentTypes: Array<any> }>()
);

export const loadComments = createAction(
  ProductDetailActions.LoadComments
);

export const loadCommentsSuccess = createAction(
  ProductDetailActions.LoadCommentsSuccess,
  props<{ comments: Array<any> }>()
);

export const loadNewProductHistoryList = createAction(
  ProductDetailActions.LoadNewProductHistoryList
);

export const loadNewProductHistoryListSuccess = createAction(
  ProductDetailActions.LoadNewProductHistoryListSuccess,
  props<{ newProductHistoryList: Array<any> }>()
);

export const syncLoadDocuments = createAction(
  ProductDetailActions.SyncLoadDocuments,
  props<{ expectedUpdatedTime: string }>()
);

export const checkAndLoadDocuments = createAction(
  ProductDetailActions.CheckAndLoadDocuments,
  props<{ upc: string }>()
);

export const loadDocuments = createAction(
  ProductDetailActions.LoadDocuments,
  props<{ upc: string }>()
);

export const loadDocumentsSuccess = createAction(
  ProductDetailActions.LoadDocumentsSuccess,
  props<{ response: any }>()
);

export const loadDocumentsListSuccess = createAction(
  ProductDetailActions.LoadDocumentsListSuccess,
  props<{ documents: Array<any> }>()
);

export const displayUploadDocumentsModal = createAction(
  ProductDetailActions.DisplayUploadDocumentsModal,
  props<{ show: boolean }>()
);

export const showScheduleChangeEditModal = createAction(
  ProductDetailActions.ShowScheduleChangeEditModal,
  props<{ show: boolean }>()
);

export const showNewProductCommentsModal = createAction(
  ProductDetailActions.ShowNewProductCommentsModal,
  props<{ show: boolean }>()
);

export const showAddNameValuePairModal = createAction(
  ProductDetailActions.ShowAddNameValuePairModal,
  props<{ show: boolean, nameValueType: string }>()
);

export const addNameValuePair = createAction(
  ProductDetailActions.ShowAddNameValuePairModal,
  props<{ show: boolean, nameValueType: string }>()
);

export const showNewProductReviseModal = createAction(
  ProductDetailActions.ShowNewProductReviseModal,
  props<{ show: boolean }>()
);

export const showReviewBrandModal = createAction(
  ProductDetailActions.ShowReviewBrandModal,
  props<{ show: boolean }>()
);

export const downloadDocument = createAction(
  ProductDetailActions.DownloadDocument,
  props<{ document: any }>()
);

export const deleteDocument = createAction(
  ProductDetailActions.DeleteDocument,
);

export const confirmDeleteDocument = createAction(
  ProductDetailActions.ConfirmDeleteDocument,
  props<{ document: Document }>()
);

export const cancelDeleteDocument = createAction(
  ProductDetailActions.CancelDeleteDocument,
);

export const deleteDocumentSuccess = createAction(
  ProductDetailActions.DeleteDocumentSuccess,
  props<{ upc: string }>()
);

export const deleteDocumentError = createAction(
  ProductDetailActions.DeleteDocumentError,
);

export const loadRequiredDocumentTypesSuccess = createAction(
  ProductDetailActions.LoadRequiredDocumentTypesSuccess,
  props<{ requiredDocumentTypes: DocumentType[] }>()
);

export const loadDocumentTraitsSuccess = createAction(
  ProductDetailActions.LoadDocumentTraitsSuccess,
  props<{ documentTraits: Array<any> }>()
);

export const downloadDocumentCompleted = createAction(
  ProductDetailActions.DownloadDocumentCompleted,
  props<{ document: any }>()
);

export const updateDocumentTabStatus = createAction(
  ProductDetailActions.UpdateDocumentTabStatus
);

export const uploadAndSaveDocument = createAction(
  ProductDetailActions.UploadAndSaveDocument,
  props<{ uploadDocumentRequest: any }>()
);
export const uploadDocumentToS3 = createAction(
  ProductDetailActions.UploadDocumentToS3,
  props<{ uploadDocumentRequest: any, documentUploadUrlResponse: any }>()
);
export const uploadDocument = createAction(
  ProductDetailActions.UploadDocument,
  props<{ uploadDocumentRequest: any, documentUploadUrlResponse: any }>()
);
export const uploadExistingDocument = createAction(
  ProductDetailActions.UploadExistingDocument,
  props<{ uploadDocumentRequest: any }>()
);
export const uploadAndSaveDocumentSuccess = createAction(
  ProductDetailActions.UploadAndSaveDocumentSuccess,
  props<{ upc: string }>()
);
export const uploadAndSaveDocumentError = createAction(
  ProductDetailActions.UploadAndSaveDocumentError,
);

export const saveComment = createAction(
  ProductDetailActions.SaveComment,
  props<{ request: any }>()
);
export const saveCommentSuccess = createAction(
  ProductDetailActions.SaveCommentSuccess,
  props<{ data: any }>()
);
export const saveCommentError = createAction(
  ProductDetailActions.SaveCommentError,
);

export const saveReviseStatus = createAction(
  ProductDetailActions.SaveReviseStatus,
  props<{ request: any }>()
);
export const saveReviseStatusSuccess = createAction(
  ProductDetailActions.SaveReviseStatusSuccess,
  props<{ reviseResponse: any }>()
);
export const saveReviseStatusError = createAction(
  ProductDetailActions.SaveReviseStatusError,
  props<{ reviseResponse: any }>()
);

export const savePriority = createAction(
  ProductDetailActions.SavePriority,
  props<{ request: ProductPriorityRequest }>()
);
export const savePrioritySuccess = createAction(
  ProductDetailActions.SavePrioritySuccess,
  props<{ data: any }>()
);
export const savePriorityError = createAction(
  ProductDetailActions.SavePriorityError,
);
export const removePriority = createAction(
  ProductDetailActions.RemovePriority,
  props<{ request: ProductPriorityRequest }>()
);

export const removePrioritySuccess = createAction(
  ProductDetailActions.RemovePrioritySuccess,
  props<{ data: any }>()
);
export const removePriorityError = createAction(
  ProductDetailActions.RemovePriorityError,
);

export const upsertNameValuePair = createAction(
  ProductDetailActions.UpsertNameValuePair,
  props<{ request: NameValuePayload }>()
);

export const upsertNameValuePairSuccess = createAction(
  ProductDetailActions.UpsertNameValuePairSuccess,
  props<{ nameValueType: string, code: string }>()
);
export const upsertNameValuePairError = createAction(
  ProductDetailActions.UpsertNameValuePairError,
);

export const showSetPriorityModal = createAction(
  ProductDetailActions.ShowSetPriorityModal,
  props<{ productDetailAction: ProductDetailAction }>()
);
export const hideSetPriorityModal = createAction(
  ProductDetailActions.HideSetPriorityModal
);

export const loadPriorityList = createAction(
  ProductDetailActions.LoadPriorityList
);

export const loadPriorityListSuccess = createAction(
  ProductDetailActions.LoadPriorityListSuccess,
  props<{ priorityList: Array<any> }>()
);

export const showRemovePriorityModal = createAction(
  ProductDetailActions.ShowRemovePriorityModal,
  props<{ show: boolean, productDetailAction: ProductDetailAction }>()
);

export const reviewBrandFormChanged = createAction(
  ProductDetailActions.ReviewBrandFormChanged,
  props<{ form: any, isDirty: boolean, isValidated: boolean }>()
);

export const updateFormValidityState = createAction(
  ProductDetailActions.UpdateFormValidityState,
  props<{ status: any }>()
);

export const approveReviewBrandChanges = createAction(
  ProductDetailActions.ApproveReviewBrandChanges,
);

export const createAndApproveNewBrand = createAction(
  ProductDetailActions.CreateAndApproveNewBrand,
);

export const approveReviewBrandChangesSuccess = createAction(
  ProductDetailActions.ApproveReviewBrandChangesSuccess,
  props<{ data: any }>()
);

export const approveReviewNewBrandChangesSucces = createAction(
  ProductDetailActions.ApproveReviewNewBrandChangesSuccess,
  props<{ data: any }>()
);

export const displayReviewBrandSuccessToast = createAction(
  ProductDetailActions.DisplayReviewBrandSuccessToast,
  props<{ data: any }>()
);

export const approveReviewBrandChangesError = createAction(
  ProductDetailActions.ApproveReviewBrandChangesError,
  props<{ error: any }>()
);

export const loadProductSnapshot = createAction(
  ProductDetailActions.LoadProductSnapshot,
  props<{ upc: string }>()
);
export const loadProductSnapshotSuccess = createAction(
  ProductDetailActions.LoadProductSnapshotSuccess,
  props<{ snapshot: any }>()
);

export const applyValuesToPricingForm = createAction(
  ProductDetailActions.ApplyValuesToPricingForm,
  props<{ pricingFormValues: any }>()
);

export const getCountryOfOriginList = createAction(
  ProductDetailActions.GetCountryOfOriginList
);

export const getCountryOfOriginListSuccess = createAction(
  ProductDetailActions.GetCountryOfOriginSuccess,
  props<{ list: any }>()
);

export const displayBannerForTraitSelection = createAction(
  ProductDetailActions.DisplayBannerForTraitSelection,
  props<{ isTraitSelected: boolean}>()
);

export const displayBannerForReviewingRequiredFields = createAction(
  ProductDetailActions.DisplayBannerForReviewingRequiredFields,
  props<{ isDisplay: boolean}>()
);

export const changeBulkDcActivationModalVisibility = createAction(
  ProductDetailActions.ChangeBulkDcActivationModalVisibility,
  props<{ show: boolean }>()
);

export const changeBulkDcStatusUpdateModalVisibility = createAction(
  ProductDetailActions.ChangeBulkDcSetToSeasonalModalVisibility,
  props<{ show: boolean, statusType: string }>()
);

export const changeBulkDcSubstituteProductModalVisibility = createAction(
  ProductDetailActions.ChangeBulkDcSubstituteProductModalVisibility,
  props<{ show: boolean }>()
);

export const changeBulkDcRemoveSubstituteProductModalVisibility = createAction(
  ProductDetailActions.ChangeBulkDcRemoveSubstituteProductModalVisibility,
  props<{ show: boolean }>()
);

export const checkDcProductGroupForDcActivation = createAction(
  ProductDetailActions.CheckDcProductGroupForBulkDcActivation,
  props<{ selectedDcs: CodeValue[], cloneFromDc: any}>()
);

export const checkDcProductGroupForDcActivationSuccess = createAction(
  ProductDetailActions.CheckDcProductGroupForBulkDcActivationSuccess,
  props<{ dcProductGroups: { [dc: string]: any[] } }>()
);

export const bulkDcActivationModalClickBack = createAction(
  ProductDetailActions.BulkDcActivationModalClickBack
);


export const saveBulkDCActivation = createAction(
  ProductDetailActions.SaveBulkDCActivation,
  props<{ request: any }>()
);

export const getLinkedDraftProducts = createAction(
  ProductDetailActions.GetLinkedDraftProducts,
  props<{ comparableUpc: any }>()
);

export const getLinkedDraftProductsSuccess = createAction(
  ProductDetailActions.GetLinkedDraftProductsSuccess,
  props<{ response: any }>()
);

export const showLinkedDraftProductsModal = createAction(
  ProductDetailActions.ShowLinkedDraftProductsModal,
  props<{ show: boolean }>()
);

export const getComparableUpcPricing = createAction(
  ProductDetailActions.GetComparableUpcPricing,
  props<{ comparableUpc: any }>()
);

export const getComparableUpcPricingSuccess = createAction(
  ProductDetailActions.GetComparableUpcPricingSuccess,
  props<{ response: any }>()
);

export const getComparableUpcPricingError = createAction(
  ProductDetailActions.GetComparableUpcPricingError
);

export const saveBulkDCActivationSuccess = createAction(
  ProductDetailActions.SaveBulkDCActivationSuccess,
  props<{ response: any, request: any }>()
);
export const saveBulkDCActivationError = createAction(
  ProductDetailActions.SaveBulkDCActivatioError
);

export const preValidateForDcActivation = createAction(
  ProductDetailActions.PreValidateForDcActivation
);

export const activationPreValidationComplete = createAction(
  ProductDetailActions.ActivationPreValidationComplete,
  props<{ response: string[] }>()
);

export const clearDcActivationPreValidationErrors = createAction(
  ProductDetailActions.ClearDcActivationPreValidationErrors
);

export const saveBulkDCStatusChange = createAction(
  ProductDetailActions.SaveBulkDCStatusChange,
  props<{ request: any }>()
);

export const saveBulkDCStatusChangeSuccess = createAction(
  ProductDetailActions.SaveBulkDCStatusChangeSuccess,
  props<{ response: any, request: any }>()
);
export const saveBulkDCStatusChangeError = createAction(
  ProductDetailActions.SaveBulkDCStatusChangeError,
  props<{ error: any }>()
);

export const preValidateForProductEdit = createAction(
  ProductDetailActions.PreValidateForProductEdit
);

export const productEditPreValidationComplete = createAction(
  ProductDetailActions.ProductEditPreValidationComplete,
  props<{ response: string[] }>()
);

export const clearProductEditPreValidationErrors = createAction(
  ProductDetailActions.ClearProductEditPreValidationErrors
);


export const saveBulkDCSubstituteProduct = createAction(
  ProductDetailActions.SaveBulkDCSubstituteProduct,
  props<{ request: any }>()
);

export const saveBulkDCSubstituteProductSuccess = createAction(
  ProductDetailActions.SaveBulkDCSubstituteProductSuccess,
  props<{ response: any, request: any }>()
);
export const saveBulkDCSubstituteProductError = createAction(
  ProductDetailActions.SaveBulkDCSubstituteProductError,
  props<{ error: any }>()
);

export const syncNewBrand = createAction(
  ProductDetailActions.SyncNewBrand,
  props<{ brandCode: string }>()
);

export const syncNewBrandComplete = createAction(
  ProductDetailActions.SyncNewBrandComplete,
  props<{ response: any }>()
);

export const removeBulkDCSubstitute = createAction(
  ProductDetailActions.RemoveBulkDCSubstitute,
  props<{ request: any }>()
);

export const removeBulkDCSubstituteSuccess = createAction(
  ProductDetailActions.RemoveBulkDCSubstituteSuccess,
  props<{ response: any, request: any }>()
);

export const removeBulkDCSubstituteError = createAction(
  ProductDetailActions.RemoveBulkDCSubstituteError,
);

export const loadFlattenedDcProductGroups = createAction(
  ProductDetailActions.LoadFlattenedProductGroups,
  props<{ enterpriseSupplierNumber: any }>()
);

export const loadFlattenedDcProductGroupsSuccess = createAction(
  ProductDetailActions.LoadFlattenedProductGroupsSuccess,
  props<{ response: any }>()
);

export const showApplyComparableUpcModal = createAction(
  ProductDetailActions.ShowApplyComparableUpcModal,
  props<{ show: boolean }>()
);

export const showUpdatePriceGroupModal = createAction(
  ProductDetailActions.ShowUpdatePriceGroupModal,
  props<{ show: boolean, priceGroupToUpdate: any }>()
);

export const updatePriceGroups = createAction(
  ProductDetailActions.UpdatePriceGroups,
  props<{ request: any }>()
);
export const updatePriceGroupsSuccess = createAction(
  ProductDetailActions.UpdatePriceGroupsSuccess,
  props<{ data: any }>()
);
export const updatePriceGroupsError = createAction(
  ProductDetailActions.UpdatePriceGroupsError,
  props<{ error: any }>()
);

export const showRefreshPricingModal = createAction(
  ProductDetailActions.ShowRefreshPricingModal,
  props<{ show: boolean }>()
);
export const showCreatePriceGroupModal = createAction(
  ProductDetailActions.ShowCreatePriceGroupModal,
  props<{ show: boolean }>()
);

export const createPriceGroup = createAction(
  ProductDetailActions.CreatePriceGroup,
  props<{ request: any }>()
);
export const createPriceGroupSuccess = createAction(
  ProductDetailActions.CreatePriceGroupSuccess,
  props<{ data: any }>()
);
export const createPriceGroupError = createAction(
  ProductDetailActions.CreatePriceGroupError,
  props<{ error: any }>()
);

export const getEachDimensionWeightMedianValue = createAction(
  ProductDetailActions.GetEachDimensionWeightMedianValue,
  props<{ priceGroup: any }>()
);

export const getEachDimensionWeightMedianValueSuccess = createAction(
  ProductDetailActions.GetEachDimensionWeightMedianValueSuccess,
  props<{ response: any }>()
);

export const getEachDimensionWeightMedianValueError = createAction(
  ProductDetailActions.GetEachDimensionWeightMedianValueError
);

export const showPricingUpdateWarningModal = createAction(
  ProductDetailActions.ShowPricingUpdateWarningModal,
  props<{ show: boolean }>()
);

export const getProductPricingOfActiveDc = createAction(
  ProductDetailActions.GetProductPricingOfActiveDc,
  props<{ upc, activeDc: number }>()
);
export const getProductPricingOfActiveDcCompleted = createAction(
  ProductDetailActions.GetProductPricingOfActiveDcCompleted,
  props<{ dcProductPricing: any }>()
);


export const getDcProductsCostFactorValue = createAction(
  ProductDetailActions.GetDcProductsCostFactorValue,
  props<{ upc: string }>()
);

export const getDcProductsCostFactorValueSuccess = createAction(
  ProductDetailActions.GetDcProductsCostFactorValueSuccess,
  props<{  dcProductCostFactor: any }>()
);


export const getIxOneProductsUpdate = createAction(
  ProductDetailActions.GetIxOneProductsUpdate,
  props<{  request: any }>()
);

export const getIxOneProductsUpdateSuccess = createAction(
  ProductDetailActions.GetIxOneProductsUpdateSuccess,
  props<{  IxOneProductUpdate: any }>()
);

export const getIxOneProductsUpdateFailure = createAction(
  ProductDetailActions.GetIxOneProductsUpdateFailure
);

export const ixOneProductsImageCheckSuccess = createAction(
  ProductDetailActions.ProductAssetsProductsImageCheckSuccess,
  props<{  IxOneProductUpdate: any }>()
);

export const ixOneProductsImageCheckFailed = createAction(
  ProductDetailActions.ProductAssetsProductsImageCheckFailed,
  props<{  IxOneProductUpdate: any }>()
);

export const ixOneProductsImageCheckSkipped = createAction(
  ProductDetailActions.ProductAssetsProductsImageCheckSkipped,
  props<{  IxOneProductUpdate: any }>()
);

export const showIxOneProductsUpdateSuccessBanner = createAction(
  ProductDetailActions.ShowIxOneProductsUpdateSuccessBanner,
  props<{ request: any }>()
);

export const getProductAssetsProductsUpdate = createAction(
  ProductDetailActions.GetProductAssetsProductsUpdate,
  props<{  request: any }>()
);

export const getProductAssetsProductsUpdateSuccess = createAction(
  ProductDetailActions.GetProductAssetsProductsUpdateSuccess,
  props<{  ProductAssetsProductUpdate: any }>()
);

export const getProductAssetsProductsUpdateNotFound = createAction(
  ProductDetailActions.GetProductAssetsProductsUpdateNotFound
);

export const getProductAssetsProductsUpdateFailure = createAction(
  ProductDetailActions.GetProductAssetsProductsUpdateFailure
);

export const productAssetsProductsImageCheckSuccess = createAction(
  ProductDetailActions.ProductAssetsProductsImageCheckSuccess,
  props<{  ProductAssetsProductUpdate: any }>()
);

export const productAssetsProductsImageCheckFailed = createAction(
  ProductDetailActions.ProductAssetsProductsImageCheckFailed,
  props<{  ProductAssetsProductUpdate: any }>()
);

export const productAssetsProductsImageCheckSkipped = createAction(
  ProductDetailActions.ProductAssetsProductsImageCheckSkipped,
  props<{  ProductAssetsProductUpdate: any }>()
);

export const showProductAssetsProductsUpdateSuccessBanner = createAction(
  ProductDetailActions.ShowProductAssetsProductsUpdateSuccessBanner,
  props<{ request: any }>()
);

export const showProductAssetsProductsDoesNotExistBanner = createAction(
  ProductDetailActions.ShowProductAssetsProductsDoesNotExistBanner,
  props<{ request: any }>()
);

export const deleteCutAndWrapMakeProduct = createAction(
  ProductDetailActions.DeleteCutAndWrapMakeProduct,
  props<{ upc: string }>()
);

export const updateCutAndWrapMakeProductQuantity = createAction(
  ProductDetailActions.UpdateCutAndWrapMakeProductQuantity,
  props<{ upc: string, quantity: number }>()
);

export const getActiveAssociatedMakeProducts = createAction(
  ProductDetailActions.GetActiveAssociatedMakeProducts,
  props<{ buyProductUpc: any }>()
);

export const getActiveAssociatedMakeProductsSuccess = createAction(
  ProductDetailActions.GetActiveAssociatedMakeProductsSuccess,
  props<{ response: CutAndWrapAssociatesMakeProductGridDataItem[] }>()
);

export const addCutWrapDc = createAction(
  ProductDetailActions.AddCutWrapDc,
  props<{ dcNumber: string }>()
);

export const selectedCutAndWrapCopyDC = createAction(
  ProductDetailActions.SelectedCutAndWrapCopyDC,
  props<{
    currentDc: string;
    newDc: string;
    makeUpc: string;
  }>()
);

export const cutWrapCopyComponentCheckSuccess = createAction(
  ProductDetailActions.CutWrapCopyComponentCheckSuccess,
  props<{ checkedComponents: CutWrapComponentCheck }>()
);

export const cutWrapCopyComponentCheckFailed = createAction(ProductDetailActions.CutWrapCopyComponentCheckFailed);

export const clickCopyCutWrapDCModal = createAction(
  ProductDetailActions.ClickCopyCutWrapDC,
  props<{ copyFromDc: string; copyToDc: string; }>()
);

export const cutWrapCopyModalClosed = createAction(ProductDetailActions.CutWrapCopyModalClosed);

export const cutWrapDcTabChanged = createAction(
  ProductDetailActions.CutWrapDcTabChanged,
  props<{ dcNumber: string }>()
);

export const confirmCutWrapDeleteDC = createAction(
  ProductDetailActions.confirmCutWrapDeleteDC,
  props<{ dcNumber: string }>()
);

export const cancelCutWrapDeleteDC = createAction(
  ProductDetailActions.cancelCutWrapDeleteDC,
);

export const removeCutWrapDc = createAction(
  ProductDetailActions.RemoveCutWrapDc,
);

export const deletingCutWrapDC = createAction(
  ProductDetailActions.DeleteCutWrapDC,
);

export const cutWrapEditClicked = createAction(
  ProductDetailActions.CutWrapEditClicked,
);

export const loadedGeneralViewMode = createAction(
  ProductDetailActions.LoadedGeneralViewMode,
);

export const setCutWrapAddProductSelectedItem = createAction(
  ProductDetailActions.SetCutWrapAddProductSelectedItem,
  props<{ selectedProduct: any }>()
);

export const clearCutWrapAddProductSelectedItem = createAction(
  ProductDetailActions.ClearCutWrapAddProductSelectedItem
);
