<kehe-phoenix-delete-modal
  modalTitle="Delete Supplier"
  [isDeleteInProgress]="isDeleteInProgress"
  [errorMessage]="error"
  (modalEvent)="handleKeheModalEvents($event)"
  (cancelClicked)="cancelModal()"
  (deleteClicked)="deleteSupplier()"
  size="md"
>
  <kehe-phoenix-alert
    [show]="true"
    [type]="alertTypes.Warning"
    message="You are removing this supplier from the KeHE System."
    [stick]="true"
    [hideCloseIcon]="true"
  >
  </kehe-phoenix-alert>
  <p>Are you sure you would like to delete this Supplier?</p>
  <div class="d-flex flex-row mb-2 mt-3 supplier-details">
    <strong>Name:</strong>
    <div>
      {{ supplier?.name || '--' }}
    </div>
  </div>
  <div class="d-flex flex-row mb-2 supplier-details">
    <strong>ESN:</strong>
    <div>
      {{ supplier?.esn || '--' }}
    </div>
  </div>
</kehe-phoenix-delete-modal>
