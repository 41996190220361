import { Injectable } from '@angular/core';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SupplierDetailService } from '../../services/supplier-detail.service';
import { selectSupplierDetail } from '../supplier-detail.selectors';
import { of } from 'rxjs';

import * as SupplierDetailActions from '../supplier-detail.actions';
import { PendoEvent } from '../../models/pendo-event.interface';
import { PendoTrackEvents } from '../../models/pendo-track-events.enum';

@Injectable()
export class PurchasingInfoTabEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,

    private readonly supplierDetailService: SupplierDetailService,
    private readonly toastService: PhoenixToastService,
  ) {}

  clickAppointmentSchedulingSaveButton$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.clickAppointmentModalSaveButton),
      withLatestFrom(
        this.store.select(selectSupplierDetail),
      ),
      switchMap(([action, supplier]) => this.supplierDetailService.save({
        esn: supplier.esn,
        deliveryMethodMeeting: {
          meetingScheduled: true,
          meetingDate: action.meetingDate,
        },
        updatedBySource: 'Enterprise',
      }).pipe(
        map((supplier) => SupplierDetailActions.appointmentSchedulingSuccess({ supplier })),
        catchError(() => of(SupplierDetailActions.appointmentSchedulingFailed())),
      ))
    )
  );

  appointmentScheduledToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.appointmentSchedulingSuccess),
      map(() => this.toastService.showSuccessToast('Schedule updated successfully!'))
    ),
    { dispatch: false }
  );

  updateCanUploadSIF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.updateCanUploadSIF),
      withLatestFrom(this.store.select(selectSupplierDetail)),
      switchMap(([action, supplier]) => {
        return this.supplierDetailService
          .save({
            esn: supplier.esn,
            canUploadSIF: action.canUploadSIF,
          })
          .pipe(
            map((updated) => {
             const event: PendoEvent = {
              name: PendoTrackEvents.CanUploadSIFChecked,
              eventProperties: {
                esn: supplier.esn,
                biTimestamp: Date.now(),
                canUploadSIF: updated.canUploadSIF,
                requesterType: supplier.requesterType,
                isInternational: supplier.hasInternationalCurrency,
                invitedDate: supplier.created,
                isDpiInitiated: supplier.isDpiInitiated,
                cmName: supplier.categoryManagerFirstName + " " + supplier.categoryManagerLastName
              }
             }
             return SupplierDetailActions.updateCanUploadSIFSuccess({
                canUploadSIF: updated.canUploadSIF,
                pendoEvent: event
              })
            }),
            catchError(() =>
              of(
                SupplierDetailActions.updateCanUploadSIFError({
                  canUploadSIF: action.canUploadSIF,
                })
              )
            )
          );
      })
    )
  );

  updateCanUploadSIFSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SupplierDetailActions.updateCanUploadSIFSuccess),
        tap(() =>
          this.toastService.showSuccessToast(
            'Conversation updated successfully!',
            false,
            5000
          )
        )
      ),
    { dispatch: false }
  );
}
