 import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomerListFilter } from '../models/customer-list-filter';
import { CustomerListItem } from '../models/customer-list-item';
import { Observable } from 'rxjs';
import { Payload } from '../../../../models/payload';
import { retry, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from '../../../../common/common-methods';
import {
  ICustomerFilterCheckItem,
  ICustomerFilterTerritoryItem,
} from '../models/icustomer-filter-item';
import { CustomerDetailedExport, CustomerSummaryExport } from '../models/icustomer-export';
import { CustomerFilterToHttpParams } from '../../services/CustomerFilterParser';
import { ShowListDto } from '@app/shared/models/show-list-dto.interface';
import { ICustomerBaseFilterItem, ICustomerFilterOption } from '../../models/icustomer-base-filter';
import { ICreditListFilterWithNumberItem } from '../../../credits/list/models/icredit-list-filter-with-number-item';
import { FileInfo } from '@progress/kendo-angular-upload';
import { IFileUpload } from '@kehe/phoenix-upload';

@Injectable({
  providedIn: 'root',
})
export class CustomerListService {
  private url = environment.customerEnterprise;

  constructor(private httpClient: HttpClient) { }

  public get(
    customerListFilter: CustomerListFilter
  ): Observable<Payload<Array<CustomerListItem>>> {
    return this.httpClient
      .get<Payload<Array<CustomerListItem>>>(this.url + '/api/list', {
        params: CustomerFilterToHttpParams(customerListFilter),
      })
      .pipe(retry(2));
  }

  public preferred(): Observable<Array<CustomerListItem>> {
    return this.httpClient
      .get<Array<CustomerListItem>>(this.url + '/api/preferred')
      .pipe(retry(2));
  }

  public getSingle(retailerMasterAccountNumber: number): Observable<any> {
    return this.httpClient
      .get<any>(this.url + `/api/single/${retailerMasterAccountNumber}`)
      .pipe(retry(2));
  }

  public getChains(
    chainSearchStr?: string
  ): Observable<Payload<ICustomerBaseFilterItem[]>> {
    let params = new HttpParams();

    if (chainSearchStr) {
      params = params.append('query', chainSearchStr);
    }

    return this.httpClient
      .get<Payload<ICustomerBaseFilterItem[]>>(this.url + '/api/codename/chain', {
        params: params,
      })
      .pipe(retry(2));
  }

  /**
   * Get types to filter the customers list.
   */
  public getStoreTypes(
    storeType?: string
  ): Observable<Payload<Array<ICustomerFilterOption>>> {
    let httpParams = new HttpParams();
    if (storeType) {
      httpParams = new HttpParams().set('query', storeType);
    }

    return this.httpClient
      .get<Payload<Array<ICustomerFilterOption>>>(this.url + '/api/codename/typecode', {
        params: httpParams,
      })
      .pipe(retry(2));
  }

  /**
   * Get pricing zone to filter the customers list.
   */
  public getPricingZones(
    search?: string
  ): Observable<Payload<Array<ICustomerFilterOption>>> {
    let httpParams = new HttpParams();
    if (search) {
      httpParams = new HttpParams().set('query', search);
    }

    return this.httpClient
      .get<Payload<Array<ICustomerFilterOption>>>(this.url + '/api/codename/pricingzone', {
        params: httpParams,
      })
      .pipe(retry(2));
  }

  public getActivityCodes(
    code?: string
  ): Observable<Payload<Array<ICustomerFilterOption>>> {
    let httpParams = new HttpParams();
    if (code) {
      httpParams = new HttpParams().set('query', code);
    }

    return this.httpClient
      .get<Payload<Array<ICustomerFilterOption>>>(this.url + '/api/codename/activitycodes', {
        params: httpParams,
      })
      .pipe(retry(2));
  }

  public getServiceLevels(
    serviceLevel?: string
  ): Observable<Payload<Array<ICustomerFilterOption>>> {
    let httpParams = new HttpParams();
    if (serviceLevel) {
      httpParams = new HttpParams().set('query', serviceLevel);
    }

    return this.httpClient
      .get<Payload<Array<ICustomerFilterOption>>>(this.url + '/api/codename/servicelevels', {
        params: httpParams,
      })
      .pipe(retry(2));
  }

  public getStatuses(): Observable<Payload<Array<ICustomerFilterCheckItem>>> {
    return this.httpClient
      .get<Payload<Array<ICustomerFilterCheckItem>>>(this.url + '/api/codename/statuses')
      .pipe(retry(2));
  }

  public getBusinessAreas(
    businessArea?: string
  ): Observable<Payload<Array<ICustomerBaseFilterItem>>> {
    let httpParams = new HttpParams();
    if (businessArea) {
      httpParams = new HttpParams().set('query', businessArea);
    }

    return this.httpClient.get<Payload<Array<ICustomerBaseFilterItem>>>(
      this.url + '/api/codename/businessareas',
      { params: httpParams }
    );
  }

  public getTerritories(
    territory?: string
  ): Observable<Payload<Array<ICustomerFilterTerritoryItem>>> {
    let httpParams = new HttpParams();
    if (territory) {
      httpParams = new HttpParams().set('query', territory);
    }

    return this.httpClient
      .get<Payload<Array<ICustomerFilterTerritoryItem>>>(this.url + '/api/codename/territories', {
        params: httpParams,
      })
      .pipe(retry(2));
  }

  public getDcCustomerNumbers(searchTerm: string): Observable<Payload<any>> {
    let params = new HttpParams();
    if (searchTerm) {
      params = params.set('search', searchTerm);
    }
    return this.httpClient.get<Payload<any>>(
      `${this.url}/api/dcCustomerNumbers`,
      { params },
    ).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  public getRoutes(
    route?: string
  ): Observable<Payload<Array<ICustomerBaseFilterItem>>> {
    let httpParams = new HttpParams();
    if (!!route) {
      httpParams = new HttpParams().set('query', route);
    }

    return this.httpClient
      .get<Payload<Array<ICustomerBaseFilterItem>>>(this.url + '/api/codename/route', {
        params: httpParams,
      })
      .pipe(retry(2));
  }

  public getDeactivationReasonsFilter(
    route?: string
  ): Observable<Payload<Array<ICustomerBaseFilterItem>>> {
    let httpParams = new HttpParams();
    if (!!route) {
      httpParams = new HttpParams().set('query', route);
    }

    return this.httpClient
      .get<Payload<Array<ICustomerBaseFilterItem>>>(this.url + '/api/codename/deactivationreasonfilter', {
        params: httpParams,
      })
      .pipe(retry(2));
  }

  public getStores(
    customerListFilter: CustomerListFilter
  ): Observable<Payload<Array<CustomerListItem>>> {
    let params = new HttpParams()
      .set('search', customerListFilter.search)
      .set('pagecount', customerListFilter.pageCount.toString())
      .set('pageindex', customerListFilter.pageIndex.toString())
      .set('orderdirection', customerListFilter.orderDirection.toString())
      .set('sortname', customerListFilter.sortName.toString())
      .set(
        'includeavailablecount',
        customerListFilter.includeAvailableCount.toString()
      );

    if (!isNullOrUndefined(customerListFilter.chain)) {
      params = params.append('chain', customerListFilter.chain.code);
    }

    if (customerListFilter.types.length) {
      customerListFilter.types.forEach((type) => {
        params = params.append('type', type.code);
      });
    }

    customerListFilter.status
      .filter((f) => f.checked)
      .forEach((status) => {
        params = params.append('status', status.code);
      });

    if (
      !isNullOrUndefined(customerListFilter.createdFrom) &&
      !isNullOrUndefined(customerListFilter.createdTo)
    ) {
      params = params.append('createdFrom', customerListFilter.createdFrom);
      params = params.append('createdTo', customerListFilter.createdTo);
    }

    return this.httpClient
      .get<Payload<Array<CustomerListItem>>>(`${this.url}/api/stores`, {
        params: params,
      })
      .pipe(retry(2));
  }

  public getExportSummaryCustomerListLink(
    customerListFilter: CustomerListFilter
  ): Observable<string> {
    const params = CustomerFilterToHttpParams(customerListFilter);

    return this.httpClient
      .get<CustomerSummaryExport>(`${this.url}/api/export`, {
        params: params,
      })
      .pipe(
        map((res: CustomerSummaryExport) => res.url),
        retry(2)
      );
  }


  public exportDetailedCustomerList(
    customerListFilter: CustomerListFilter
  ): Observable<CustomerDetailedExport> {
    const params = CustomerFilterToHttpParams(customerListFilter);

    return this.httpClient
      .get<CustomerDetailedExport>(`${this.url}/api/export-detailed`, {
        params: params
      });
  }

  uploadCustomers(dcCustomerNumber: string, file: FileInfo): Observable<string> {
    const dc = dcCustomerNumber.substring(0, 2);
    const customerNumber = dcCustomerNumber.substring(2);
    const url = `${this.url}/api/import/${dc}/${customerNumber}`;
    const formData = new FormData();
    formData.append('file', file.rawFile, file.name);

    return this.httpClient.post(url, formData, { responseType: 'text' });
  }

  updateCustomers(file: FileInfo): Observable<string> {
    const url = `${this.url}/api/import`;
    const formData = new FormData();
    formData.append('file', file.rawFile, file.name);

    return this.httpClient.put(url, formData, { responseType: 'text' });
  }

  deactivateCustomers(file: IFileUpload): Observable<string> {
    const url = `${this.url}/api/import/deactivate`;
    const formData = new FormData();

    if (!file?.rawFile || !file?.name) {
      throw new Error('File or name is missing');
    }

    formData.append('file', file.rawFile, file.name);

    return this.httpClient.post(url, formData, { responseType: 'text' });
  }

  public getShows(search: string): Observable<Payload<ShowListDto[]>> {
    const params = new HttpParams().set('filter', '25').set('search', search);
    return this.httpClient
      .get<Payload<ShowListDto[]>>(`${environment.showApi}/enterprise/dropdown`, {
        params: params,
      });
  }

  public getAccountsreceivablerep(
    accountRecivableRep?: string
  ): Observable<Payload<Array<ICustomerBaseFilterItem>>> {
    let httpParams = new HttpParams();
    if (accountRecivableRep) {
      httpParams = new HttpParams().set('query', accountRecivableRep);
    }
    return this.httpClient.get<Payload<Array<ICustomerBaseFilterItem>>>(
      this.url + '/api/codename/accountsreceivablerep',
      { params: httpParams }
    );
  }

  public getAvailableCustomers(customerSearchStr: string): Observable<ICreditListFilterWithNumberItem[]> {
    const params = new HttpParams()
      .set('search', customerSearchStr)
      .set('slim', 'true');

    return this.httpClient
      .get<Payload<ICreditListFilterWithNumberItem[]>>(this.url + '', { params: params })
      .pipe(
        map((response) => {
          if (!response?.data) {
            return [];
          }
          return response.data.map((customer) => {
            return {
              ...customer,
              name: `${customer.name} (DC ${customer.distributionCenterNumber}-${customer.number})`,
              number: `${customer.number}`
            }
          });
        }),
        retry(2)
      );
  }


}

