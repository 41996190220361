import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { Store } from '@ngrx/store';
import { AddNoteForm, AddNoteFormControlName } from '../../models/add-note-form';
import { saveAddNoteFormValues } from '../../store/supplier-detail.actions';
import { SupplierDetailsConstants } from '../../constants/supplier-details-constants';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
})
export class AddNoteComponent extends Destroyable implements OnInit {
  form: UntypedFormGroup;
  controlName = AddNoteFormControlName;
  requestReasonMaxLength = SupplierDetailsConstants.RequestReasonMaxCharLength;

  constructor(private _store: Store) {
    super();
  }

  ngOnInit(): void {
    this.form = this.initializeAddNoteForm();
    this._store.dispatch(saveAddNoteFormValues({ form: null }));
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        const form: AddNoteForm = {...value, isValid: this.form.valid, auditNotes: this.getAuditNote(value.ticketNumber, value.requestReason)};
        this._store.dispatch(saveAddNoteFormValues({ form }));
    });
  }

  initializeAddNoteForm() {
    return new UntypedFormGroup({
      [AddNoteFormControlName.ticketNumber]: new UntypedFormControl('', Validators.nullValidator),
      [AddNoteFormControlName.requestReason]: new UntypedFormControl('', Validators.required),
    });
  }

  getAuditNote(ticketNumber: string, requestReason: string) {
    const notes = [];
    if (ticketNumber) {
      notes.push(`Ticket: ${ticketNumber}`);
    }
    if (requestReason) {
      notes.push(`Reason: ${requestReason}`);
    }
    return notes.join(' | ');
  }

  get requestReasonCharacterCounter(): string {
    return `${this.form?.get(AddNoteFormControlName.requestReason)?.value?.length ?? 0} / ${this.requestReasonMaxLength}`;
  }
}
