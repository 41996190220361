import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from '../../../constants/constants';
import { PermissionsService } from '@app/services/permissions.service'; 

@Injectable({
  providedIn: 'root',
})
export class SupplierMassUpdateGuard  {
  constructor(
    private readonly _permissions: PermissionsService,
    private readonly _router: Router,
  ) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      this._permissions.userHasAction(Constants.UserActions.SupplierMassUpdateBuyersRead) ||
      this._permissions.userHasAction(Constants.UserActions.SupplierMassUpdateManagersRead)
    ) {
      return true;
    } else {
      return this._router.navigate(['/supplier'])
    }
  }
}
