import { createSelector } from '@ngrx/store';
import {
  selectOnBoardingStatus,
  selectSupplierValidatedTabs,
} from '../../store/supplier-detail.selectors';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag';
import { isTabApproved } from '../../utilities/onboarding-utils';
import { SupplierDetailTabs } from '../../models/supplier-detail-tabs';
import { ChecklistNavigationTabStatus } from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';

export const selectIsDocusignReady = createSelector(
  selectOnBoardingStatus,
  selectSupplierValidatedTabs,
  selectIsFeatureFlagOn(FeatureFlags.SupplierAAPLegalSignatures.key),
  (onboardingStatuses, validatedTabs, ffAAPLegalSignatures) => {
    const isTermsApproved = validatedTabs?.find(t => t.tab === SupplierDetailTabs.supplierTerms)?.status === ChecklistNavigationTabStatus.checked
    const isGeneralTabApproved = isTabApproved(
      onboardingStatuses,
      SupplierDetailTabs.general,
    );

    if (ffAAPLegalSignatures) {
      return isGeneralTabApproved && isTermsApproved;
    }

    return isTabApproved(onboardingStatuses, SupplierDetailTabs.general);
  },
);
