import { Component, OnInit } from '@angular/core';
import { Destroyable } from '../../../../abstract/destroyable';
import { select, Store } from '@ngrx/store';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { selectSupplierContacts } from '../../../supplier-contacts/store/supplier-contacts.selectors';
import { PersonDesignationTypes } from '../../../supplier-contacts/enums/person-designation-types';
import { PersonContact } from '../../../supplier-contacts/models/person-contact';
import { selectIsFeatureFlagOn } from '../../../../shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '../../../../feature-flag';
import { getRequiredContacts } from '../../../supplier-contacts/utils/supplier-contacts-utils';
import { selectIsInvitedDraftSupplier } from '../../store/supplier-detail.selectors';

@Component({
  selector: 'app-supplier-contacts-view',
  templateUrl: './supplier-contacts-view.component.html',
  styleUrls: ['./supplier-contacts-view.component.scss'],
})
export class SupplierContactsViewComponent
  extends Destroyable
  implements OnInit
{
  requiredContacts = [];
  requiredDesignations: { name: PersonDesignationTypes; exist: boolean }[] = [];

  contacts: PersonContact[] = [];

  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store
      .pipe(
        select(selectSupplierContacts),
        withLatestFrom(
          this._store.select(
            selectIsFeatureFlagOn(FeatureFlags.SupplierOnboardingV4.key)
          ),
          this._store.select(selectIsInvitedDraftSupplier)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([val, supplierOnboardingV4Flag, isInvitedDraftSupplier]) => {
        this.contacts = val || [];
        this.evaluateRequiredData(supplierOnboardingV4Flag, isInvitedDraftSupplier);
      });
  }

  evaluateRequiredData(supplierOnboardingV4Flag: boolean, isInvitedDraftSupplier: boolean) {
    const existingTypes = this.contacts.reduce(
      (accumulator, value) => accumulator.concat(value.contactTypes),
      []
    );
    this.requiredContacts = getRequiredContacts(supplierOnboardingV4Flag, isInvitedDraftSupplier).map(
      (item) => {
        return { ...item, exist: existingTypes.includes(item.type) };
      }
    );
    this.requiredDesignations = [
      {
        name: PersonDesignationTypes.PrimaryContact,
        exist: this.contacts.some((item) => item.isPrimary),
      },
      {
        name: PersonDesignationTypes.DiscontinuedProductNotification,
        exist: this.contacts.some(
          (item) => item.subscribedToDiscontinuedProductNotification
        ),
      },
    ];
  }
}
