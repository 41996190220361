import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Contact, ContactType } from '../../../../models/contact';
import { addSupplierContact } from '../../../supplier-detail/store/supplier-detail.actions';
import {
  selectIsSavingContact,
  selectNewlyAddedContact,
  selectSaveContactError
} from '../../../supplier-detail/store/supplier-detail.selectors';
import { Store } from '@ngrx/store';
import { removeContact, updateLocationError } from '../../store/supplier-locations.actions';
import {
  selectLocationError,
  selectLocationsLoading,
  selectLocationsSuccess,
  selectSupplierLocationContacts
} from '../../store/supplier-locations.selectors';
import { selectDocuments } from '../../../supplier-documents/store/supplier-documents.selectors';
import { map } from 'rxjs/operators';
import { Constants } from '../../../../constants/constants';
import { DocumentStatus } from '../../../supplier-documents/models/document-statuses';

@Component({
  selector: 'app-remit-address',
  templateUrl: './remit-address.component.html',
})
export class RemitAddressComponent {
  contacts$ = this._store.select(selectSupplierLocationContacts);

  types = ContactType;
  form = this._builder.group({
    remittanceAddress: [],
  });
  showRemove = false;

  saving$ = this._store.select(selectIsSavingContact);
  deleting$ = this._store.select(selectLocationsLoading);
  error$ = this._store.select(selectSaveContactError);
  deleteAddressError$ = this._store.select(selectLocationError);
  isDisabled$ = this._store.select(selectDocuments).pipe(
    map((documents) => {
      const invoice = documents.find((d) => d.typeId === Constants.BlankInvoiceDocumentTypeId);
      if (invoice) {
        return invoice?.status === DocumentStatus.InReview ||
          invoice?.status === DocumentStatus.NotSubmitted ||
          invoice?.status === DocumentStatus.Approved;
      }

      return false;
    }),
  );

  constructor(
    private readonly _store: Store,
    private readonly _builder: UntypedFormBuilder,
  ) {
    this.contacts$.subscribe((contacts) => {
      const address = contacts.find((c) => c.contactType === ContactType.RemittanceAddress);
      if (address) {
        this.form.get('remittanceAddress').setValue(address.uniqueId);
      }
    });

    this._store.select(selectNewlyAddedContact).subscribe((value) => {
      if (value != null && value.contactType === ContactType.RemittanceAddress) {
        this.form.get('remittanceAddress').setValue(value.uniqueId);
      }
    });

    this._store.select(selectLocationsSuccess).subscribe((success) => {
      if (success) {
        this.showRemove = false;
      }
    });

    this.isDisabled$.subscribe((value) => {
      if (value) {
        this.form.get('remittanceAddress').disable();
      } else {
        this.form.get('remittanceAddress').enable();
      }
    })
  }

  onSmartyStreetsUseAddress(contact: Contact) {
    this._store.dispatch(addSupplierContact({ contact }));
  }

  getContact(contacts: Contact[]) {
    return contacts.find((c) => c.contactType === ContactType.RemittanceAddress);
  }

  onDelete(contact: Contact) {
    this._store.dispatch(removeContact({ contact }));
  }

  onCancel() {
    this.showRemove = false;
    this._store.dispatch(updateLocationError({ error: undefined }));
  }
}
