import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map, retry } from 'rxjs/operators';
import { PersonContact } from '../models/person-contact';

@Injectable({
  providedIn: 'root'
})
export class SupplierContactsService {

  private _supplierUrl: string;

  constructor(private httpClient: HttpClient) {
    this._supplierUrl = environment.supplierApi;
  }

  getContacts(esn: string): Observable<PersonContact[]> {
    return this.httpClient
      .get<any[]>(
        `${this._supplierUrl}/api/contacts/${esn}`
      )
      .pipe(retry(1));
  }

  saveContact(esn: string, contact: PersonContact): Observable<PersonContact> {
    return this.httpClient.patch<PersonContact>(`${this._supplierUrl}/api/contacts/${esn}`, contact).pipe(map((response) => {
      return response;
    }));
  }

  deleteContact(contact: PersonContact): Observable<PersonContact> {
    return this.httpClient.delete<PersonContact>(`${this._supplierUrl}/api/contacts/${contact.uniqueId}`).pipe(map((response) => {
      return response;
    }));
  }
}
