import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs/operators';
import { Payload } from '@app/models/payload';
import { SupplierDevelopmentManager } from './supplier-development-manager';
import { CategoryManager } from './category-manager';

@Injectable()
export class SupplierService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.supplierApi;
   }

  getStatusList(): Observable<Payload<Array<string>>> {
    return this.httpClient.get<Payload<Array<string>>>(`${this._url}/api/statuses`).pipe(retry(2));
  }

  getDevelopmentManagersList(): Observable<Payload<Array<SupplierDevelopmentManager>>> {
    return this.httpClient.get<Payload<Array<SupplierDevelopmentManager>>>(`${this._url}/api/development-managers`).pipe(retry(2));
  }

  getCategoryManagersList(): Observable<Payload<Array<CategoryManager>>> {
    return this.httpClient.get<Payload<Array<CategoryManager>>>(`${this._url}/api/category-managers`).pipe(retry(2));
  }



}