export function skeletonTheme() {
  return {
    'border-radius': '2px',
    'height': '10px',
    'background-color': '#F1F1F1',
    'margin': '0px'
  };
}

export const skeleton = {
  ...skeletonTheme(),
  width: '50%'
};

export const skeletonSmallText = {
  ...skeletonTheme(),
  width: '80px'
};

export const skeletonBigText = {
  ...skeletonTheme(),
  width: '80%'
};

export const skeletonImage = {
  ...skeletonTheme(),
  width: '80px',
  height: '80px'
};