<kehe-phoenix-modal [show]="true"
                    modalTitle="Send DocuSign Email"
                    (modalEvent)="closeModal()"
                    size="md">
  <div body class="body p-1">
    <kehe-phoenix-alert [type]="alertTypes.Error" [message]="sendDocuSignError" *ngIf="sendDocuSignError"
                    [hideCloseIcon]="true" stick="true" show="true">
    </kehe-phoenix-alert>
    <kehe-phoenix-alert [type]="alertTypes.Warning" [message]="alertMessage"
                    [hideCloseIcon]="true" stick="true" show="true">
    </kehe-phoenix-alert>
    <p>
      Would you like to send the contract documents to the person below?
    </p>
    <div class="d-flex flex-row mb-2 signee-details">
      <strong>Signee:</strong>
      <div>
        {{ signer?.name || '--' }}
      </div>
    </div>
    <div class="d-flex flex-row mb-2 signee-details">
      <strong>Signee Title:</strong>
      <div>
        {{ signer?.title || '--' }}
      </div>
    </div>
    <div class="d-flex flex-row mb-2 signee-details">
      <strong>Signee Email:</strong>
      <div>
        {{ signer?.emailAddress || '--' }}
      </div>
    </div>
  </div>

  <div footer class="d-flex justify-content-end px-1">
    <kehe-phoenix-button
      class="cancel-button"
      name="cancel"
      classAttr="btn-link btn-medium"
      (click)="closeModal()">
      Cancel
    </kehe-phoenix-button>

    <kehe-phoenix-button
      classAttr="btn-primary btn-medium ms-2"
      [spinner]="isSending"
      [disabled]="isSending"
      (click)="onSendClicked()">
      Send
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
