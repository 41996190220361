import { createAction, props } from '@ngrx/store';

export const announcementViewPageOpened = createAction(
  '[Announcement View Page] Page Opened',
  props<{ id: number }>()
);

export const announcementViewPageLeft = createAction(
  '[Announcement View Page] Page Left'
);

export const announcementPublish = createAction(
  '[Announcement View Page] Publish'
);
