import { Component, OnInit } from '@angular/core';
import { Destroyable } from '../../../../abstract/destroyable';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DocumentStatus } from '../../models/document-statuses';
import { Document } from '../../models/document';
import { AlertMessage } from '../../../../models/alert-message';
import {
  selectDocumentsAlertMessage,
  selectIsDocumentActionLoading,
  selectRejectDocument,
  selectRejectDocumentFeedback
} from '../../store/supplier-documents.selectors';
import { cancelRejectDocument, updateDocumentStatus } from '../../store/supplier-documents.actions';

@Component({
  selector: 'app-reject-document-modal',
  templateUrl: './reject-document-modal.component.html',
  styleUrls: ['./reject-document-modal.component.scss']
})
export class RejectDocumentModalComponent extends Destroyable implements OnInit {

  alertMessage: AlertMessage;

  document: Document;

  isLoading: boolean;
  maxlength = 1000;
  comment = '';
  rejectDocumentFeedback: boolean;

  get counter(): string {
    let count = 0;
    if (this.comment) {
      count = this.comment.length;
    }
    return `${count}/${this.maxlength}`;
  }

  get isRejectEnabled() {
    return this.comment && this.comment.length > 0 && !this.isLoading && /\S/.test(this.comment);
  }

  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      select(selectDocumentsAlertMessage),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.alertMessage = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectRejectDocument))
      .subscribe((val) => {
        this.document = val;
        this.comment = val ? val.statusNotes || '' : '';
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectIsDocumentActionLoading))
      .subscribe((val) => {
        this.isLoading = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectRejectDocumentFeedback))
      .subscribe((val) => {
        this.rejectDocumentFeedback = val;
      });
  }

  closeModal() {
    if (this.isLoading) { return; }
    this._store.dispatch(cancelRejectDocument());
  }

  onRejectClicked() {
    if (this.isLoading || !this.comment || this.comment.length === 0) { return; }
    const req = {
      ...this.document,
      status: DocumentStatus.Rejected,
      statusNotes: this.comment
    };
    this._store.dispatch(updateDocumentStatus({ document: req }));
  }
}
