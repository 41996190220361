import { Component, OnInit } from '@angular/core';
import { Destroyable } from '@app/abstract/destroyable';
import { Store } from '@ngrx/store';
import { environment } from '@src/environments/environment';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { PriceGroupConfigData, PriceGroupConfigFacade, PriceGroupHostApp } from '@kehe/price-group-lib';

@Component({
  selector: 'app-manage-price-groups-page',
  templateUrl: './manage-price-groups-page.component.html',
})
export class ManagePriceGroupsPageComponent extends Destroyable  implements OnInit {

  constructor(
      public _priceGroupFacade: PriceGroupConfigFacade,
      public _store: Store,
    ) {
      super();
    }
  
    ngOnInit() {
      combineLatest([
        this._store.select(AuthenticationSelectors.selectUserFirstName),
        this._store.select(AuthenticationSelectors.selectUserEmail),
      ]).pipe(takeUntil(this.destroy$))
      .subscribe(([name, email]) => {
        let config: PriceGroupConfigData;
        config = {
          userEmail: email,
          userName: name,
          userApi: environment.usersMicroservice,
          // To be updated with price group Api
          priceGroupApi: '',
          ga: environment.ga,
          splitIoKey: environment.splitIoKey,
          environment: environment.current,
          hostApp: PriceGroupHostApp.Enterprise
        };
        this._priceGroupFacade.setConfigData( config );
      });
    }
}
