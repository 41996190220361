import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payload } from '../../../models/payload';
import { BrokerListFilter } from '../models/broker-list-filter';
import { BrokerListItem } from '../models/broker-list-item';
import { environment } from '../../../../environments/environment';
import { map, retry } from 'rxjs/operators';
import { BrokerDetail } from '../../broker-detail/models/broker-detail';
import { BrokerStatus } from '../../broker-detail/models/broker-status';

@Injectable()
export class BrokerService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.brokerEnterprise;
  }

  get(
    brokerListFilter: BrokerListFilter
  ): Observable<Payload<Array<BrokerListItem>>> {

    const params = new HttpParams()
      .set('search', brokerListFilter.search)
      .set('pagecount', brokerListFilter.pageCount.toString())
      .set('pageindex', brokerListFilter.pageIndex.toString())
      .set('orderdirection', brokerListFilter.orderDirection.toString())
      .set('sortname', brokerListFilter.sortName.toString())
      .set(
        'includeavailablecount',
        brokerListFilter.includeAvailableCount.toString()
      );

    return this.httpClient
      .get<any>(this._url, { params: params })
      .pipe(retry(2));
  }

  getfilteredList(search): any {
    const params = new HttpParams()
      .set('search', search)
      .set('status', BrokerStatus.active);

    return this.httpClient
      .get<any>(this._url, { params: params })
      .pipe(retry(2));
  }

  initializeBroker(form): Observable<BrokerDetail> {
    return this.httpClient.post<any>(`${environment.brokerApi}/initialize`, form)
      .pipe(map((response) => response));
  }
}
