import { createAction, props } from '@ngrx/store';
import { Payload } from '../../../models/payload';
import { ProductListFilter } from '../models/product-list-filter';
import { ProductListItem } from '../models/product-list-item';
import { IProductFilterCheckItem } from '../models/iproduct-filter-item';
import { IChip } from '@kehe/phoenix-chips';
import { IKeheTab } from '@kehe/phoenix-tabs';
import { ProductListAction } from '../models/product-list-action';
import { QuickFilterTitle } from '../quick-filter-util';

export enum ProductActions {
  LoadProductList = '[Product] Load Product List',
  LoadProductListSuccess = '[Product] Load Product List Success',
  LoadProductListError = '[Product] Load Product List Error',
  loadProductTraits = '[Product] Load Product Traits',
  ProductListPageChange = '[Product] Product List Page Change',
  OpenProductFilterPanel = '[Product] Open Product Filter Panel',
  CloseProductFilterPanel = '[Product] Close Product Filter Panel',
  ApplyFilter = '[Product] Apply Filters',
  LoadAllDefaultFilters = '[Product] All Default Filters dropdown values',
  LoadAllDefaultFiltersSuccess = '[Product] All Default Filters dropdown values Success',
  GetStatusList = '[Product] Product List Get Statuses List',
  GetScoreCardList = '[Product] Product List Get Score Card List',
  GetTempClassList = '[Product] Product List Get Temp Class List',
  GetScoreCardListSuccess = '[Product] Product List Get Statuses List Success',
  GetSupplierList = '[Product] Product List Get Suppliers List',
  GetSupplierListSuccess = '[Product] Product List Get Suppliers List Success',
  GetBrandList = '[Product] Product List Get Brand List',
  GetBrandListSuccess = '[Product] Product List Get Brand List Success',
  GetPriceGroupList = '[Product] Product List Get Price group List',
  GetPriceGroupListSuccess = '[Product] Product List Get Price Group List Success',
  GetCategoryManagerList = '[Product] Product List Get Category Manager List',
  GetCategoryManagerListSuccess = '[Product] Product List Get Categroy Manager List Success',
  GetLevelCode3List = '[Product] Product List Get Level Code3 List',
  GetLevelCode3ListSuccess = '[Product] Product List Get Level Code3 List Success',
  GetTempClassListSuccess = '[Product] Product List Get Score card List Success',
  GetStatusListSuccess = '[Product] Product List Get Temp class List Success',
  GetAssinedToFilterList = '[Product] Product List Get AssinedTo Filter List',
  GetAssinedToFilterListSuccess = '[Product] Product List  Get AssinedTo Filter List Success',
  GetPriorityFilterList = '[Product] Product List Get Priority Filter List',
  GetPriorityFilterListSuccess = '[Product] Product List Get  Priority Filter List Success',
  GetNewWorkFlowStatusFilterList = '[Product] Product List Get NewWorkFlowStatus Filter List',
  GetNewWorkFlowStatusFilterListSuccess = '[Product] Product List  Get NewWorkFlowStatus Filter List Success',
  ProductListSearchChange = '[Product] Product List Search Change',
  CheckAllProducts = '[Product] Check All Products',
  GetHeaderCheckBox = '[Product] Get Header Checkbox',
  ProductListSortChange = '[Product] Product List Sort Change',
  ProductListInitialSort = '[Product] Product List Initial Sort',
  CreateProductListFilterChips = '[Product] Create Product List Filter Chips',
  ProductListFilterChipClicked = '[Product] Product List Filter Chip Clicked',
  ProductListFilterChipsClearAll = '[Product] Product List Filter Chips Clear All',
  ProductListFilterClearAll = '[Product] Product List Filter Clear All',
  ChangeListSelectedTab = '[Product] Change List Selected Tab',
  ShowAddProductModal = '[Product] Show Add Product Modal',
  HideAddProductModal = '[Product] Hide Add Product Modal',
  ShowCloneProductModal = '[Product] Show Clone Product Modal',
  ShowDeleteProductModal = '[Product] Show Delete Product Modal',
  GetAddNewProductFormNameValuePairs = '[Add New Product] Load Add New Product Name Value Pairs',
  GetAddNewProductFormNameValuePairsSuccess = '[Add New Product] Load Add New Product Name Value Pairs Success',
  AddProductFormChanged = '[Add New Product] Form Changed',
  UpdateFormValidityState = '[Add New Product] Update Form Validity',
  SaveNewProductChanges = '[Add New Product] Save Changes',
  SaveNewProductChangesSuccess = '[Add New Product] Save Changes Success',
  SaveNewProductChangesError = '[Add New Product] Save Changes Error',
  ShowSetPriorityAssignToModal = '[Product] Show Set Priority Assign To Modal',
  HideSetPriorityAssignToModal = '[Product] Hide Set Priority Assign To Modal',
  ShowRemovePriorityOrUnassignModal = '[Product] Show/Hide Remove Priority Or Unassign Modal',
  LoadPriorityList = '[Product] Load Priority List',
  LoadPriorityListSuccess = '[Product] Load Priority List Success',
  SavePriority = '[Product] Save Priority',
  RemovePriority = '[Product] Remove Priority',
  UnassignUser = '[Product] Unassign User',
  UnassignUserSuccess = '[Product] Unassign User Success',
  UnassignUserError = '[Product] Unassign User Error',
  DeleteProduct = '[Product List] Delete Product',
  DeleteProductSuccess = '[Product List] Delete Product Success',
  DeleteProductError = '[Product List] Delete Product Error',
  SavePrioritySuccess = '[Product] Save Priority Success',
  SavePriorityError = '[Product] Save Priority Error',
  RemovePrioritySuccess = '[Product] Remove Priority Success',
  RemovePriorityError = '[Product] Remove Priority Error',
  SetSelectedProductCount = '[Product List] Selected Product Count',
  LoadAssignToUserList = '[Product] Load Assign To User List',
  LoadAssignToUserListSuccess = '[Product] Load Assign To User List Success',
  SaveAssignToUser = '[Product] Assign To User',
  SaveAssignToUserSuccess = '[Product] Assign To User Success',
  SaveAssignToUserError = '[Product] Assign To User Error',
  LoadDraftProductListFilters = '[Product] Draft Product List Filters dropdown values',
  LoadDraftProductListFiltersSuccess = '[Product] Draft Product List Filters dropdown values Success',
  LoadQuickFilterCount = '[Product] Load Quick Filter Count',
  LoadQuickFilterCountSuccess = '[Product] Load Quick Filter Count Success',
  LoadQuickFilterCountError = '[Product] Load Quick Filter Count Error',
  UpdateAssignToMeQuickFilterCountDiff = '[Product] Update AssignToMe Quick Filter CountDiff',
  SelectedQuikcFilter = '[Product] Selected Quikc Filter',
  SelectedAssignedToMeQuickFilter = '[Product] Selected AssignedToMe Quick Filter',
  SelectedStatusQuickFilter = '[Product] Selected Status Quick Filter',
  ResetQuickFilter = '[Product] Reset Quick Filter',
  ShowMassProductReviseModal = '[Product List] Show Mass Product Revise Modal',
  SaveMassReviseStatus = '[Mass Product Revise Modal] Save Mass Revise Status',
  SaveMassReviseStatusSuccess = '[Product List Update API] Save Mass Revise Status Success',
  SaveMassReviseStatusError = '[Product List Update API] Save Mass Revise Status Error',
  HideMassReviseErrorAlert = '[Product List Page] Hide Mass Revise Error Alert'
}

export const loadProductList = createAction(
  ProductActions.LoadProductList
);
export const getAddNewProductFormNameValuePairs = createAction(
  ProductActions.GetAddNewProductFormNameValuePairs,
);
export const getAddNewProductFormNameValuePairsSuccess = createAction(
  ProductActions.GetAddNewProductFormNameValuePairsSuccess,
  props<{ filters: any }>()
);
export const changeListSelectedTab = createAction(
  ProductActions.ChangeListSelectedTab,
  props<{ selectedTab: IKeheTab }>()
);
export const loadProductListSuccess = createAction(
  ProductActions.LoadProductListSuccess,
  props<{ product: Payload<Array<ProductListItem>> }>()
);

export const setSelectedProductCount = createAction(
  ProductActions.SetSelectedProductCount,
  props<{ count: number }>()
);

export const loadCustomerListError = createAction(
  ProductActions.LoadProductListError
);

export const productListPageChange = createAction(
  ProductActions.ProductListPageChange,
  props<{ pageCount: number; pageIndex: number }>()
);

export const openProductFilter = createAction(
  ProductActions.OpenProductFilterPanel
);

export const closeProductFilter = createAction(
  ProductActions.CloseProductFilterPanel
);

export const applyFilter = createAction(
  ProductActions.ApplyFilter,
  props<{ productListFilter: ProductListFilter }>()
);

export const loadAllDefaultFilters = createAction(
  ProductActions.LoadAllDefaultFilters
);

export const loadAllDefaultFiltersSuccess = createAction(
  ProductActions.LoadAllDefaultFiltersSuccess,
  props<{ filters: any }>()
);

export const getStatusList = createAction(
  ProductActions.GetStatusList
);
export const getScoreCardList = createAction(
  ProductActions.GetScoreCardList
);

export const getTempClassList = createAction(
  ProductActions.GetTempClassList
);

export const getSupplierList = createAction(
  ProductActions.GetSupplierList
);

export const getBrandList = createAction(
  ProductActions.GetBrandList
);

export const getPriceGroupList = createAction(
  ProductActions.GetPriceGroupList
);

export const getLevelCode3List = createAction(
  ProductActions.GetLevelCode3List
);

export const getCategoryManagerList = createAction(
  ProductActions.GetCategoryManagerList
);

export const getNewWorkFlowStatusFilterList = createAction(
  ProductActions.GetNewWorkFlowStatusFilterList
);

export const getPriorityFilterList = createAction(
  ProductActions.GetPriorityFilterList
);

export const getAssinedToFilterList = createAction(
  ProductActions.GetAssinedToFilterList
);

export const getStatusListSuccess = createAction(
  ProductActions.GetStatusListSuccess,
  props<{ items: IProductFilterCheckItem[] }>()
);

export const getScoreCardListSuccess = createAction(
  ProductActions.GetScoreCardListSuccess,
  props<{ items: IProductFilterCheckItem[] }>()
);

export const getTempClassListSuccess = createAction(
  ProductActions.GetTempClassListSuccess,
  props<{ items: IProductFilterCheckItem[] }>()
);

export const getNewWorkFlowStatusFilterListSuccess = createAction(
  ProductActions.GetNewWorkFlowStatusFilterListSuccess,
  props<{ response: any }>()
);

export const getSupplierListSuccess = createAction(
  ProductActions.GetSupplierListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getBrandListSuccess = createAction(
  ProductActions.GetBrandListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getPriceGroupListSuccess = createAction(
  ProductActions.GetBrandListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getLevelCode3ListSuccess = createAction(
  ProductActions.GetLevelCode3ListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getCategoryManagerListSuccess = createAction(
  ProductActions.GetCategoryManagerListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getAssinedToFilterListSuccess = createAction(
  ProductActions.GetAssinedToFilterListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getPriorityFilterListSuccess = createAction(
  ProductActions.GetPriorityFilterListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const productListSearchChange = createAction(
  ProductActions.ProductListSearchChange,
  props<{ search: string }>()
);

export const checkAllProducts = createAction(
  ProductActions.CheckAllProducts,
  props<{ isChecked: boolean }>()
);

export const productListSortChange = createAction(
  ProductActions.ProductListSortChange,
  props<{ orderDirection: number, sortName: number }>()
);

export const productListInitialSort = createAction(
  ProductActions.ProductListInitialSort,
  props<{ list: Array<ProductListItem> }>()
);

export const createProductListFilterChips = createAction(
  ProductActions.CreateProductListFilterChips
);

export const productListFilterChipClicked = createAction(
  ProductActions.ProductListFilterChipClicked,
  props<{ chip: IChip }>()
);

export const productListFilterChipsClearAll = createAction(
  ProductActions.ProductListFilterChipsClearAll
);

export const getProductsTraits = createAction(
  ProductActions.loadProductTraits
);

export const productListFilterClearAll = createAction(
  ProductActions.ProductListFilterClearAll
);

export const showAddProductModal = createAction(
  ProductActions.ShowAddProductModal
);

export const hideAddProductModal = createAction(
  ProductActions.HideAddProductModal
);

export const addProductFormChanged = createAction(
  ProductActions.AddProductFormChanged,
  props<{ form: any, isDirty: boolean, isValidated: boolean }>()
);

export const updateFormValidityState = createAction(
  ProductActions.UpdateFormValidityState,
  props<{ status: any }>()
);

export const saveNewProductChanges = createAction(
  ProductActions.SaveNewProductChanges,
);

export const saveNewProductChangesSuccess = createAction(
  ProductActions.SaveNewProductChangesSuccess,
  props<{ upc: any }>()
);

export const saveNewProductChangesError = createAction(
  ProductActions.SaveNewProductChangesError,
);

export const showSetPriorityAssignToModal = createAction(
  ProductActions.ShowSetPriorityAssignToModal,
  props<{ productListAction: ProductListAction }>()
);

export const showRemovePriorityOrUnassignModal = createAction(
  ProductActions.ShowRemovePriorityOrUnassignModal,
  props<{ show: boolean, productListAction: ProductListAction }>()
);

export const hideSetPriorityAssignToModal = createAction(
  ProductActions.HideSetPriorityAssignToModal
);
export const loadPriorityList = createAction(
  ProductActions.LoadPriorityList
);

export const loadPriorityListSuccess = createAction(
  ProductActions.LoadPriorityListSuccess,
  props<{ priorityList: Array<any> }>()
);

export const savePriority = createAction(
  ProductActions.SavePriority,
  props<{ request: any }>()
);

export const removePriority = createAction(
  ProductActions.RemovePriority,
  props<{ request: any }>()
);

export const unassignUser = createAction(
  ProductActions.UnassignUser,
  props<{ request: any }>()
);

export const unassignUserSuccess = createAction(
  ProductActions.UnassignUserSuccess,
  props<{ data: any }>()
);
export const unassignUserError = createAction(
  ProductActions.UnassignUserError,
);

export const deleteProduct = createAction(
  ProductActions.DeleteProduct,
  props<{ upc: string }>()
);

export const deleteProductSuccess = createAction(
  ProductActions.DeleteProductSuccess,
  props<{ upc: string }>()
);
export const deleteProductError = createAction(
  ProductActions.DeleteProductError,
);

export const removePrioritySuccess = createAction(
  ProductActions.RemovePrioritySuccess,
  props<{ data: any }>()
);
export const removePriorityError = createAction(
  ProductActions.RemovePriorityError,
);

export const savePrioritySuccess = createAction(
  ProductActions.SavePrioritySuccess,
  props<{ data: any }>()
);
export const savePriorityError = createAction(
  ProductActions.SavePriorityError,
);

export const loadAssignToUserList = createAction(
  ProductActions.LoadPriorityList
);

export const loadAssignToUserSuccess = createAction(
  ProductActions.LoadPriorityListSuccess,
  props<{ assignToList: Array<any> }>()
);
export const saveAssignToUser = createAction(
  ProductActions.SaveAssignToUser,
  props<{ request: any }>()
);
export const saveAssignToUserSuccess = createAction(
  ProductActions.SaveAssignToUserSuccess,
  props<{ data: any }>()
);
export const saveAssignToUserError = createAction(
  ProductActions.SaveAssignToUserError,
);

export const loadDraftProductListFilters = createAction(
  ProductActions.LoadDraftProductListFilters
);

export const loadDraftProductListFiltersSuccess = createAction(
  ProductActions.LoadDraftProductListFiltersSuccess,
  props<{ filters: any }>()
);

export const showCloneProductModal = createAction(
  ProductActions.ShowCloneProductModal,
  props<{ cloneProduct: any }>()
);

export const showDeleteProductModal = createAction(
  ProductActions.ShowDeleteProductModal,
  props<{ show: boolean, productToDelete: any }>()
);

export const loadQuickFilterCount = createAction(
  ProductActions.LoadQuickFilterCount
);

export const loadQuickFilterCountSuccess = createAction(
  ProductActions.LoadQuickFilterCountSuccess,
  props<{ quickFilterCount: any }>()
);

export const loadQuickFilterCountError = createAction(
  ProductActions.LoadQuickFilterCountError
);

export const updateAssignToMeQuickFilterCountDiff = createAction(
  ProductActions.UpdateAssignToMeQuickFilterCountDiff,
  props<{ countDiff: number }>()
);

export const selectedQuickFilter = createAction(
  ProductActions.SelectedQuikcFilter,
  props<{ filter: QuickFilterTitle }>()
);

export const selectedAssignedToMeQuickFilter = createAction(
  ProductActions.SelectedAssignedToMeQuickFilter,
  props<{ assignedToMeUser: string }>()
);

export const selectedStatusQuickFilter = createAction(
  ProductActions.SelectedStatusQuickFilter,
  props<{ filter: QuickFilterTitle }>()
);

export const resetQuickFilter = createAction(
  ProductActions.ResetQuickFilter
);

export const showMassProductReviseModal = createAction(
  ProductActions.ShowMassProductReviseModal,
  props<{  show: boolean, productListAction: ProductListAction }>()
);

export const saveMassReviseStatus = createAction(
  ProductActions.SaveMassReviseStatus,
  props<{ request }>()
);
export const saveMassReviseStatusSuccess = createAction(
  ProductActions.SaveMassReviseStatusSuccess,
  props<{ reviseResponse }>()
);
export const saveMassReviseStatusError = createAction(
  ProductActions.SaveMassReviseStatusError,
  props<{ reviseResponse }>()
);
export const hideMassReviseErrorAlert = createAction(
  ProductActions.HideMassReviseErrorAlert
);