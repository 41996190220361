import { ICodeName } from '@app/models/icode-name';
import { environment } from '../../environments/environment';
import { InboundRouteType } from '../models/dcpg-inbound-route-type';
import { DropdownOption } from '../models/dropdown-option';
import { MaxCharLengths } from '@kehe/phoenix-address/lib/models/max-char-lengths';

export class Constants {
  public static CheckBox = {
    Checked: `<i class="fas fa-check-square fa-lg" style="color:#44C4DA"></i>`,
    UnChecked: `<i class="far fa-square fa-lg" style="color:#999999"></i>`,
  };

  public static EmptyValue = '--';
  public static ExtensionTextMask = 'A000';
  public static ExtensionNumberMask = '0000';

  public static Features = {
    Products: 'Products',
    Supplier: 'Supplier',
    Documents: 'Documents',
    BIReports: 'BIReports',
  };

  public static CutWrapDCs = [
    { name: 'Ontario (45)', value: '45' },
    { name: 'Tualatin (46)', value: '46' }
  ];

  public static CutWrapESN = [
    { name: 'KEHE CUT & WRAP 45 ONT (02240005)', value: '02240005', dc: '45' },
    { name: 'KEHE CUT & WRAP 46 TUA (02240447)', value: '02240447', dc: '46' }
  ];

  public static CutWrapSearchTerm = 'KEHE CUT & WRAP';
  /**
   * User Resource Actions
   */
  public static UserActions = {
    BrokerRead: 'Broker_Read',
    BrokerUpdate: 'Broker_Update',
    BrokerCreate: 'Broker_Create',

    CarouselManagementRead: 'CarouselManagement_Read',
    CarouselManagementCreate: 'CarouselManagement_Create',
    CarouselManagementDelete: 'CarouselManagement_Delete',
    CarouselManagementUpdate: 'CarouselManagement_Update',

    CategoryDataRead: 'CategoryData_Read',

    CustomerRead: 'Customer_Read',
    CustomerDeactivate: 'Customer_Deactivate',
    CustomerUpdate: 'Customer_Update',
    CustomerCreate: 'Customer_Create',
    CustomerSalesCommissionRateRead: 'Customer_SalesCommissionRate_Read',

    // Contacts
    CustomerContactCreate: 'Customer_Contact_Create',
    CustomerContactUpdate: 'Customer_Contact_Update',
    CustomerContactRemove: 'Customer_Contact_Remove',
    CustomerContactDelete: 'Customer_Contact_Delete',

    // Custom Lists
    CustomListCreate: 'CustomList_Create',
    CustomListRead: 'CustomList_Read',
    CustomListUpdate: 'CustomList_Update',
    CustomListDelete: 'CustomList_Delete',
    CustomListPowerUser: 'CustomList_PowerUser',

    // Supplier DPI Actions
    ExportSupplierData: 'SupplierDataExportDpi_Update',

    // Manage Transit Lane Actions
    SupplierMassUpdateZipTransit: 'SupplierMassUpdateZipTransit_All',

    DistributionCenterRead: 'DistributionCenter_Read',

    DocumentRead: 'Document_Read',

    EdiUpdate: 'Edi_Update',

    EnterpriseSiteAccess: 'Enterprise_Site',

    EpodRead: 'Epod_Read',

    // KCD Customer Address
    KcdCustomerAddress_Read: 'KcdCustomerAddress_Read',

    ProductCreate: 'Product_Create',
    ProductRead: 'Product_Read',
    ProductUpdate: 'Product_Update',
    ProductActivate: 'Product_Activate',
    MassUploadCaseGTIN: 'MassUpload_CaseGTIN',
    MassUploadRead: 'MassUpload_Read',
    ProductGeneralReadOnly: 'Product_General_ReadOnly',
    ProductIdentifiersReadOnly: 'Product_Identifiers_ReadOnly',
    ProductSafetyReadOnly: 'Product_Saftey_ReadOnly',
    ProductStoragePackReadOnly: 'Product_Storage_ReadOnly',
    ProductAttributesReadOnly: 'Product_Attributes_ReadOnly',
    ProductShipperReadOnly: 'Product_Shipper_ReadOnly',
    ProductScheduledChangesReadOnly: 'Product_Scheduled_Changes_ReadOnly',
    ProductDCDetailsReadOnly: 'Product_DC_Details_ReadOnly',
    ProductDCDetailsEditOnly: 'Product_Dc_Details_Update',
    ProductCutWrapEditOnly: 'Product_CutWrap_Update',
    ProductSupplyChainDcDetailsReadOnly: 'SupplyChain_Product_DC_Details_ReadOnly',
    NewProductCreate: 'NewProduct_Create',
    NewProductUpdate: 'NewProduct_Update',
    ProhibitRestrictMaintenance: 'Prohibit_Restrict_Maintenance',
    ProhibitRestrictRead: 'Prohibit_Restrict_Read',


    PublicationManagementRead: 'PublicationManagement_Read',
    PublicationManagementCreate: 'PublicationManagement_Create',
    PublicationManagementDelete: 'PublicationManagement_Delete',
    PublicationManagementUpdate: 'PublicationManagement_Update',

    PurchasingOrderRead: 'PurchaseOrder_Read',

    PurchasingOptionsRead: 'PurchasingOptions_Read',
    PurchasingOptionsUpdate: 'PurchasingOptions_Update',
    PurchasingOptionsCreate: 'PurchasingOptions_Create',
    PurchasingOptionsDelete: 'PurchasingOptions_Delete',

    RetailerDataRead: 'RetailerData_Read',

    SalesOrderRead: 'SalesOrder_Read',
    SalesOrderCreate: 'SalesOrder_Create',
    SalesOrderUpdate: 'SalesOrder_Update',
    SalesOrderDelete: 'SalesOrder_Delete',

    ShipmentRead: 'Shipment_Read',
    ShipmentUpdate: 'Shipment_Update',
    ShipmentDelete: 'Shipment_Delete',
    ShipmentEditStatus: 'Shipment_EditStatus',


    SupplierRead: 'Supplier_Read',
    SupplierCreate: 'Supplier_Create',
    SupplierInvite: 'Supplier_Invite',
    SupplierUpdate: 'Supplier_Update',
    SupplierDelete: 'Supplier_Delete',
    SupplierBrokerUpdate: 'SupplierBroker_Update',
    SupplierDcProductGroupCreate: 'SupplierDcProductGroup_Create',
    SupplierDcProductGroupBuyerUpdate: 'SupplierDcProductGroupBuyer_Update',
    SupplierDcProductGroupDeliveryMethodUpdate:
      'SupplierDcProductGroupDeliveryMethod_Update',
    SupplierDcProductGroupLeadTimeUpdate:
      'SupplierDcProductGroupLeadTime_Update',
    SupplierDcProductGroupPoTransmissionUpdate:
      'SupplierDcProductGroupPoTransmission_Update',
    SupplierGeneralSummaryUpdate: 'SupplierGeneralSummary_Update',
    SupplierGeneralSupplierHeadquartersUpdate:
      'SupplierGeneralSupplierHeadquarters_Update',
    SupplierProductGroupCreate: 'SupplierProductGroup_Create',
    SupplierProductGroupUpdate: 'SupplierProductGroup_Update',
    SupplierTermsUpdate: 'SupplierTerms_Update',
    SupplierStatusUpdate: 'SupplierStatus_Update',
    SupplierOnboardingDocumentApproval: 'SupplierOnboardingDocument_Approval',

    SupplierMassUpdateBuyersRead: 'SupplierMassUpdateBuyers_Read',
    SupplierMassUpdateBuyersCreate: 'SupplierMassUpdateBuyers_Create',
    SupplierMassUpdateBuyersUpdate: 'SupplierMassUpdateBuyers_Update',
    SupplierMassUpdateBuyersDelete: 'SupplierMassUpdateBuyers_Delete',

    SupplierMassUpdateManagersRead: 'SupplierMassUpdateManagers_Read',
    SupplierMassUpdateManagersCreate: 'SupplierMassUpdateManagers_Create',
    SupplierMassUpdateManagersUpdate: 'SupplierMassUpdateManagers_Update',
    SupplierMassUpdateManagersDelete: 'SupplierMassUpdateManagers_Delete',

    SupplierMassUpdateDcpgsRead: 'SupplierMassUpdateDcpgs_Read',
    SupplierMassUpdateDcpgsCreate: 'SupplierMassUpdateDcpgs_Create',
    SupplierMassUpdateDcpgsUpdate: 'SupplierMassUpdateDcpgs_Update',
    SupplierMassUpdateDcpgsDelete: 'SupplierMassUpdateDcpgs_Delete',

    SupplierDcPaymentHoldUpdate: 'Supplier_DcPaymentHoldUpdate',

    SupplierMatrixRead: 'SupplierMatrix_Read',
    SupplierMatrixUpdate: 'SupplierMatrix_Update',

    RepListAdminRead: 'AccountRepManagement_Utility',

    UserCreate: 'User_Create',
    UserRead: 'User_Read',
    UserUpdate: 'User_Update',
    UserGroupCreate: 'Group_Create',
    UserGroupUpdate: 'Group_Update',
    UserGroupDelete: 'Group_Delete',

    ShowRead: 'Show_Read',
    SpinsDataRead: 'SpinsData_Read',
    DepartmentFilterManagementRead: 'DepartmentFilterManagement_Read',

    //Marketing resource action
    ManageAdvertisementsAction: 'Manage Advertisements Actions',

    // Manage Resources (Documentation)
    ResourceAdmin: 'Resource_Admin',

    // Announcements
    SupplierAnnouncementWrite: 'SupplierAnnouncement_Write',
    RetailerAnnouncementWrite: 'RetailerAnnouncement_Write',
    EnterpriseAnnouncementWrite: 'EnterpriseAnnouncement_Write',

    // Work Queue (Product)
    WorkQueueRead: 'WorkQueue_Read',

    // Event Promotions
    EventPromotion_Create: 'EventPromotion_Create',
    EventPromotion_Read: 'EventPromotion_Read',
    EventPromotion_Update: 'EventPromotion_Update',
    EventPromotion_Delete: 'EventPromotion_Delete',

    // Credits
    CreditsFinalize: 'Credits_Finalize',
    CreditsRead: 'Credits_Read',

    // EDS Admin - Vendor Broker Admin
    VendorBrokerAdmin: 'VendorBroker_Admin',
    Vendor_Admin_SS: 'Vendor_Admin_SS_Admin',

    //Manage Custom Promotions
    CustomPromotionsRead: 'Custom_Promotions_Read',
    CustomPromotionsCreate: 'Custom_Promotions_Create',
    CustomPromotionsDelete: 'Custom_Promotions_Delete',
    CustomPromotionsFinalize: 'Custom_Promotions_Finalize',
    CustomPromotionsUpdate: 'Custom_Promotions_Update',

    //Manage Hierarchies
    FreshHierarchyRead: 'FreshHierarchy_Read',
    FreshHierarchyCreate: 'FreshHierarchy_Create',
    FreshHierarchyUpdate: 'FreshHierarchy_Update',
    FreshHierarchyDelete: 'FreshHierarchy_Delete',

    InfraHierarchyRead: 'InfraHierarchy_Read',
    InfraHierarchyCreate: 'InfraHierarchy_Create',
    InfraHierarchyUpdate: 'InfraHierarchy_Update',
    InfraHierarchyDelete: 'InfraHierarchy_Delete',

    SalesHierarchyRead: 'SalesHierarchy_Read',
    SalesHierarchyCreate: 'SalesHierarchy_Create',
    SalesHierarchyUpdate: 'SalesHierarchy_Update',
    SalesHierarchyDelete: 'SalesHierarchy_Delete',

    RetailerHierarchyRead: 'RetailerHierarchy_Read',
    RetailerHierarchyCreate: 'RetailerHierarchy_Create',
    RetailerHierarchyUpdate: 'RetailerHierarchy_Update',
    RetailerHierarchyDelete: 'RetailerHierarchy_Delete',

    PhysicalLocationHierarchyRead: 'LocationHierarchy_Read',
    PhysicalLocationHierarchyCreate: 'LocationHierarchy_Create',
    PhysicalLocationHierarchyUpdate: 'LocationHierarchy_Update',
    PhysicalLocationHierarchyDelete: 'LocationHierarchy_Delete',

    // Promotional Roadmap
    PromotionalRoadmapCreate: 'Promotional_Roadmap_Create',

    ChatBotCreate: 'EnterpriseChatbot_Create',
    SPCAdmin: 'SupplierPriceChange_Admin',

    PriceGroupAdmin: 'PriceGroup_Admin',
    PriceGroupRead: 'PriceGroup_Read',

    // Ksolve
    KsolveRead: 'Ksolve_Read',
    KsolveBottleTax: 'Ksolve_BottleTax',
  };

  public static V1Groups = {
    Vendor: {
      Description: 'Vendor User',
      Id: environment.vendorGroupId,
      Name: 'Vendor',
    },
    VendorAdmin: {
      Description: 'Administers other Vendor Users',
      Id: environment.vendorAdminGroupId,
      Name: 'VendorAdmin',
    },
    VendorManager: {
      Description: 'Manages products, brands, promotions, etc',
      Id: environment.vendorManagerGroupId,
      Name: 'VendorManager',
    },
    VendorReports: {
      Description: 'Vendor Reports',
      Id: environment.vendorReportsGroupId,
      Name: 'VendorReportsVendor',
    },
    SupplierOnboarding: {
      Description:
        'The user is going through the onboarding process and has limited access to updating supplier data.',
      Id: environment.supplierOnboardingGroupId,
      Name: 'SupplierOnboarding',
    },
    SupplierOnboardingRedirect: {
      Description:
        'Group to indicate a supplier is onboarding and redirect to V2',
      Id: environment.supplierOnboardingRedirectGroupId,
      Name: 'SupplierOnboardingRedirect',
    },
    CONNECT_Supplier_Supplier: {
      Description:
        'Indicates whether the vendor user should have enhanced BI capabilities in CONNECT Supplier',
      Id: environment.connectSupplierGroupId,
      Name: 'CONNECT_Supplier_Supplier',
    },
  };

  public static Menu = {
    Products: 'Products',
    Sales: 'Sales',
    Documents: 'Documents',
    Purchasing: 'Purchasing',
    ConnectBI: 'Connect BI',
    BIReports: 'Connect Insights',
    Utilities: 'Utilities',
    Users: 'Users',
    Legacy: 'Legacy CONNECT (V1)',
  };

  public static SubMenu = {
    BIReports_Return_Promo: 'Return on Promo Investment',
    BIReports_Suggested_Order: 'Suggested Order',
    BIReports_Reports: 'Reports',
    /**
     * Products submenu item.
     */
    Products_Manage_Prod: 'Manage Products',
    ProductsManageProhibitRestricts: 'Manage Prohibits and Restricts',
    ProductsPromotions: 'Promotions',
    ProductsPublicationsCalendar: 'Publication Calendar',
    ProductsPromotionCalendar: 'Promotion Calendar',
    ProductsDiscontinuedProducts: 'Discontinued Products',
    ProductsAomWorkflow: 'AOM Workflow',
    ProductsAomV1Workflow: 'AOM CM Workflow',
    ProductsManagePricing: 'Manage Pricing',
    ProductsPricingManagement: 'Pricing Management',
    ProductsPricingAdmin: 'Pricing Admin',
    SupplierPriceChangesAdmin: 'Manage Prices',
    ManagePriceGroups: 'Manage Price Groups',

    /**
     * Sales's submenu item.
     */
    CustomerManagement: 'Manage Customers',
    SalesManageOrders: 'Manage Orders',
    SalesManageShipments: 'Manage Shipments',
    DeliveryTracking: 'Track PODs',
    AOMV1: 'AOM',
    OrderManagement: 'Order Management',
    CMWorkflow: 'CM Workflow',
    Commissions: 'Commissions',
    SalesPerformance: 'Sales Performance',
    DeliveryHub: 'Delivery Hub',
    SalesShows: 'Shows',
    SalesManageShowsAndEvents: 'Manage Shows & Events',
    SalesManageSODCustomers: 'Manage SOD Customers',
    SalesManagePromotions: 'Manage Promotions',
    SalesManagePromotionsEligibility: 'Manage Promotions Eligibility',
    SalesManageCredits: 'Manage Credits',
    SalesManageProductLists: 'Manage Product Lists',
    SalesManageCustomPromotions: 'Manage Custom Promotions',

    /**
     * Purchasing submenu items
     */
    ManageSuppliers: 'Manage Suppliers',
    ManageBrokers: 'Manage Brokers',
    PoFlow: 'Manage Orders',
    PromoRoadmap: 'Manage Promotional Roadmaps',
    RoadmapNationalDeals: 'Roadmap 2025 National Deals',
    KSolve: 'Credits',
    ManageCredits: 'Manage Credits',
    KSolveV1: 'K-Solve',
    /**
     * Utilities submenu items
     */
    DistributionCenter: 'Manage Distribution Centers',
    ManageProductCodes: 'Manage Product Codes',
    Product: 'Product',
    Marketing: 'Marketing',
    ManageAdvertisements: 'Manage Advertisements',
    ManageAnalytics: 'Manage Analytics',
    ManageAnnouncements: 'Manage Announcements',
    ManageResources: 'Manage Resources',
    ManageSupplierMatrix: 'Manage Supplier Notification Matrix',
    ManageTransitLanes: 'Manage Transit Lanes',
    BottleTax: 'Bottle Tax',
    ManageCustomerDeactivationConfigs: 'Manage Customer Deactivation Configs',
    ManageAccountRepAdmin: 'Manage Account Rep Admin',
    // Supplier: 'Supplier',
    // Customer: 'Customer',
    // User Management
    ManageUsers: 'Manage Users',
    // Group Management
    ManageGroups: 'Manage Groups',
    //Show Asset Manager
    ShowAssetManager: 'K.A.R.M.A',
    //Manage Hierarchies
    ManageHierarchies: 'Manage Hierarchies',
    SalesBusinessHierarchy: 'Sales Business Hierarchy',
    RetailerHierarchy: 'Retailer Hierarchy',
    PhysicalLocationHierarchy: 'Physical Location Hierarchy',
    InfraHierarchy: 'INFRA Hierarchy',
    FreshHierarchy: 'Fresh Hierarchy',
    ExportHierarchies: 'Export Hierarchies',

    /**
     *ConnectBI submenu items
     */
    Analytics: 'Analytics',
    LegacyReports: 'Legacy Reports',
    Reporting: 'Reporting',
    Finance: 'Finance',
    Sales: 'Sales',
    SupplyChain: 'Supply Chain',
    Merchandising: 'Merchandising',
    /*
     * V1 Legacy Submenu Items
     **/
    ItemSearch: 'Item Search',
    Reports: 'Legacy Reports',
    UserManagement: 'User Management',
    VendorManagement: 'Vendor Management',
    VendorManagementDocument: 'Vendor Mgmt. Document',
  };

  public static ComboboxCountLimit = 25;
  public static LetterOfFinancialResponsibilityTypeId = 8;
  public static W9DocumentTypeId = 5;
  public static PurchasingInfoDocumentTypeId = 24;
  public static RetailerRequestedDetailsDocumentTypeId = 26;
  public static SupplierInfoDocumentTypeId = 1;
  public static OtherTypeId = 23;
  public static BrokerRecordDocumentTypeId = 12;
  public static BlankInvoiceDocumentTypeId = 6;
  public static PoliciesProceduresAddendumTypeId = 2;
  public static SupplierCodeOfConductDocumentTypeId = 9;
  public static TermsExemptionFormDocumentTypeId = 15;
  public static PoliciesProceduresAddendumDocumentTypeId = 25;
  public static COIDocumentTypeId = 4;

  public static SpecialDocIds = [
    Constants.W9DocumentTypeId,
    Constants.SupplierInfoDocumentTypeId,
  ];

  public static DraftPlaceholderEsn = '20210000';

  public static SupplierChangeTypes: Array<{
    text: string;
    value: number;
    hasContractDate: boolean;
    alert?: string;
  }> = [
    {
      text: 'New/Updated Addendum',
      value: 1,
      hasContractDate: true,
      alert: 'All new or updated contract documents will need to be uploaded.',
    },
    {
      text: 'New/Updated Contractual Agreement',
      value: 2,
      hasContractDate: true,
      alert: 'All new or updated contract documents will need to be uploaded.',
    },
    {
      text: 'New Contractual Agreement and Addendum',
      value: 3,
      hasContractDate: true,
      alert: 'All new or updated contract documents will need to be uploaded.',
    },
    {
      text: 'Corrections (Current Contract)',
      value: 4,
      hasContractDate: false,
    },
    {
      text: 'Pending Addendum to Current Contract',
      value: 5,
      hasContractDate: true,
      alert: 'All new or updated contract documents will need to be uploaded.',
    },
    {
      text: 'Corrections (Missing Current P&P/Addendum)',
      value: 6,
      hasContractDate: false,
      alert: 'Contract documents need to be acquired and uploaded.',
    },
  ];

  public static DeliveryMethods: DropdownOption[] = [
    {
      label: 'Delivered',
      value: 'Y',
    },
    {
      label: 'Freight Services Program',
      value: 'A',
    },
    {
      label: 'Pickup - Non-stackable',
      value: 'N',
    },
    {
      label: 'Pickup - Stackable',
      value: 'S',
    },
  ];

  public static RevisionList = [
    {
      value: 'Supplier Review',
      label: 'Supplier Review',
      code: 'Supplier Review',
    },
    {
      value: 'KeHE Review - CM',
      label: 'KeHE Review - CM',
      code: 'KeHE Review - CM',
    },
  ];
  public static CutWrapReviewStatus =
    {
      value: 'Cut & Wrap Review',
      label: 'Cut & Wrap Review',
      code: 'Cut & Wrap Review',
    };

  public static POTransTypes: Array<string> = ['Broker', 'Supplier'];

  public static POOrderTypes: Array<string> = ['Cases', 'Dollars', 'Pounds'];
  public static ItemOrderTypes: Array<string> = ['Cases', 'Dollars', 'Pounds'];
  public static TempClasses: Array<string> = ['Dry', 'Frozen', 'Refrigerated'];
  public static KeHETransitTimeTooltip =
    'The KeHE Transit Time has been converted to Calendar Days from Business Days.';

  public static MCBFeeAmount = 65;
  public static MCBFeePercentage = 0.08;

  public static BuyerRegions: Array<string> = [
    'West Region',
    'East Region',
    'Central Region',
  ];

  public static InboundRouteTypes: { name: string; type: InboundRouteType }[] =
    [
      {
        name: 'LTL Dry',
        type: InboundRouteType.LTLDry,
      },
      {
        name: 'LTL Temp Control',
        type: InboundRouteType.LTLTemp,
      },
      {
        name: 'Over The Road',
        type: InboundRouteType.OTR,
      },
      {
        name: 'Cross Dock',
        type: InboundRouteType.XD,
      },
      {
        name: 'Intermodal',
        type: InboundRouteType.Intermodal,
      }
    ];

  public static TermsChangeWarning =
    'Any changes to the terms will require a new contract to be generated and signed by the supplier. Download the template to update the supplier contract agreement.';

  public static DocuSignBeingPreparedMessage =
    'The Legal Name, Headquarter Address, and W9 form are currently being validated by KeHE. Legal & Signatures will become available once the General tab has been approved.';
  public static DocuSignSendMessage =
    'The DocuSign link has been emailed to the signee for their signature.';

  public static TermsOnboardingMessage =
    'The supplier Legal Name, Headquarter Address, and W9 Form have not been completed by the supplier. These must be completed by the supplier and approved by KeHE before Supplier Terms can be edited. The Legal Name and Headquarter Address are required to generate a new contact to ensure the Legal Name is correct on the contract forms.';

  public static ExcludedDCs: number[] = [37, 26, 22, 42];

  public static NewRetailerId = 21;
  public static OtherRetailerId = 22;

  public static DocumentsTabNotSubmittedAlert =
    'Supplier documents are not ready for KeHE review.';
  public static DocumentsTabInReviewAlert =
    'Supplier documents are ready for KeHE review.';

  public static AddressFieldMaxCharLengthsDefault: MaxCharLengths = {
    attention: 25,
    street1: 30,
    street2: 30,
    city: 25,
    zipCode: 10,
  };

  public static AddressFieldMaxCharLengthsPickup: MaxCharLengths = {
    name: 30,
    attention: 25,
    street1: 30,
    street2: 30,
    city: 28,
    zipCode: 10,
  };

  public static AddressFieldEmailMaxCharLength: number = 30;
  public static AddressFieldRemitNameMaxCharLength: number = 30;
  public static AddressFieldPickupNotesMaxCharLength: number = 30;

  public static ConnectV1GroupCategory: { code: string, name: string } = {
    code: '13',
    name: 'CONNECT V1 Groups',
  }

  public static AAPFieldToolTip = "Acceptance into Administrative Allowance Program will require a 2% allowance " +
                                  "and exclude the supplier from the following fees; New Item Intro Fees, " +
                                  "New Product Set Up Fee, Lumping Fee, and EP/MCB Fee.";

  public static AAPDocumentationFile = "KeHE Supplier Administrative Allowance Program Overview.pdf";

  public static PurchasingInfoDocumentTypeName = "Partial Supplier Information Form";
  public static SupplierInfoFormDocumentTypeName = "Completed Supplier Information Form";

  public static masterPendingOptions: ICodeName[] = [
    {
      code: 'Y',
      name: 'Yes'
    },
    {
      code: 'A',
      name: 'Store'
    },
    {
      code: 'B',
      name: 'Store & Parent'
    },
    {
      code: 'N',
      name: 'Default/Blank'
    }
  ]
}