<div class="row mt-5">
  <div class="col"></div>
  <div class="col-9">
    <div class="text-center">
      <span class="icon fas fa-seedling mb-3"></span>
      <h2>View Performance in KeHE Connect</h2>
      <p>
        We are working towards bringing Performance into CONNECT Enterprise, till then Performance is still viewed and stored in KeHE CONNECT as Vendor Snapshot. 
      </p>
      <kehe-phoenix-button classAttr="btn-secondary btn-medium" (click)="goToPerformanceV1()">
        View Performance
      </kehe-phoenix-button>
    </div>
  </div>
  <div class="col"></div>
</div>
