export enum AlertTypes {
    Information = 'information',
    Success = 'success',
    Error = 'error',
    Warning = 'warning'
}

export interface IAlert {
    show: boolean;
    type: AlertTypes | string;
    message?: string;
    messageAsHtml?: string;
    action1?: string;
    action2?: string;
    hideCloseIcon?: boolean;
}
