import { Component, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { cloneDCProductGroup } from '../../store/product-groups.actions';
import { ProductGroup } from '../../../supplier-detail/models/product-group';
import { DCProductGroupClone } from '../../store/product-groups.reducer';

@Component({
  selector: 'app-clone-dcpg-modal',
  templateUrl: './clone-dcpg-modal.component.html',
})
export class CloneDcpgModalComponent {
  _dcpg: DCProductGroupClone;

  @Input()
  show = false;
  @Input()
  dcList = [];
  @Input()
  productGroup: ProductGroup
  @Input()
  set dcProductGroup(dcpg: Partial<DCProductGroupClone>) {
    if (dcpg) {
      this._dcpg = dcpg as DCProductGroupClone;
      this.form.patchValue(dcpg);
    }
  }

  @Output() closeModal = new Subject<any>();

  form = this._builder.group({
    dcDisplayName: [],
    distributionCenters: [[], [Validators.required, Validators.minLength(1)]],
  });

  constructor(
    private readonly _builder: UntypedFormBuilder,
    private readonly _store: Store
  ) {}

  onCancel(event?: any) {
    if (!event || event?.type !== 'outside-click') {
      this.form.reset();
    }
    this.closeModal.next(event);
  }

  onClone() {
    this._store.dispatch(cloneDCProductGroup({
      productGroup: this.productGroup,
      dcpg: this._dcpg,
      cloneTo: this.form.getRawValue().distributionCenters
    }));
    this.closeModal.next();
  }
}
