<div [formGroup]="form">
  <div [hidden]="!canUserEditDeliveryMethod">
    <div class="row">
      <div class="col">
        <h5>Delivery Method</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <kendo-formfield>
          <kendo-label [for]="deliveryMethodField" text="Delivery Method"></kendo-label>
          <kendo-combobox #deliveryMethodField (focus)="deliveryMethodField.toggle()"
            (valueChange)="deliveryMethodValueChange($event)" [data]="deliveryMethodList" [filterable]="true"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}" [textField]="'label'"
            [valueField]="'value'" [valuePrimitive]="true" formControlName="deliveryMethodCode"
            placeholder="Select Delivery Method" required></kendo-combobox>
          <kendo-formerror *ngIf="deliveryMethodCodeField?.errors?.required">Delivery Method is required.
          </kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-12 col-md-6" *ngIf="canShowInboundRouteType()">
        <kendo-formfield showHints="always">
          <kendo-label [for]="inboundRouteTypeComponent" text="Inbound Route Type"></kendo-label>
          <kendo-combobox [ngClass]="{'kehe-readonly-input' : isInboundRouteTypeReadonly() }" #inboundRouteTypeComponent
            (focus)="inboundRouteTypeComponent.toggle()" [data]="inboundRouteTypes" [textField]="'name'"
            [readonly]="isInboundRouteTypeReadonly()" [valueField]="'type'" [valuePrimitive]="true" [filterable]="true"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}" formControlName="inboundRouteType"
            placeholder="Select Inbound Route Type" required></kendo-combobox>
          <kendo-formhint *ngIf="isInboundRouteTypeReadonly()">
            This field cannot be edited.
          </kendo-formhint>
          <kendo-formerror *ngIf="inboundRouteTypeField?.errors?.required">
            Inbound Route Type is required.
          </kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div *ngIf="isDelivered && (isDraftSupplier$ | async) === false && (deliveryAddressFeatureFlag$ | async)">
      <app-delivered-address [parent]="form"></app-delivered-address>
    </div>
    <div *ngIf="isDelivered && (isDraftSupplier$ | async) && (deliveryAddressFeatureFlag$ | async)"
      formGroupName="pickup">
      <div class="row mt-4">
        <div class="col">
          <h5>Delivered Address</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group" data-automation-id="draft-supplier-delivered-address">
            <kendo-label [text]="isInvitedDraftSupplier ? 'Delivered Address' : 'Delivered Address (Optional)'"
              class="mb-1"></kendo-label>
            <app-contact-card formControlName="pickupAddressId" [defaultContactTypes]="contactType.DeliveredAddress"
              [contacts]="supplier?.contacts" [isInProgress]="saving$ | async" [saveContactError]="error$ | async"
              (selectedMyContact)="onSelectPickupAddress($event)"
              (smartyStreetsUseAddress)="onSmartyStreetsUseAddress($event)"
              (smartyStreetsEditAddress)="onSmartyStreetsEditAddress()" (remove)="onRemovePickupAddress()"
              (closeModal)="onClosePickupAddressModal()">
            </app-contact-card>

          </div>
        </div>
      </div>
    </div>
    <div [hidden]="!hasDeliveryMethod || isDelivered" formGroupName="pickup">
      <div class="row mt-4">
        <div class="col">
          <h5>Pickup Address</h5>
        </div>
      </div>
      <div class="row" [hidden]="showContactCard">
        <div class="col-12 col-md-6 mb-3 mb-md-0">
          <kehe-address #addressPickupComponent (addressSelected)="onAddressSelected($event)"
            (noAddressSelected)="onNoAddressSelected()" [address]="address" [displayNameField]="true"
            [street1MaxLength]="30" [street2MaxLength]="30" [cityMaxLength]="28" [nameMaxLength]="30"
            [attentionMaxLength]="25" [zipCodeMaxLength]="10" [formSaved]="validateAddressOnSave.asObservable()"
            [key]="smartyStreetsKey">
          </kehe-address>
          <kendo-formfield>
            <kendo-label [for]="pickupAddressNotes" text="Pickup Address Notes (optional)"></kendo-label>
            <kendo-textarea #pickupAddressNotesField formControlName="pickupAddressNotes" [maxlength]="maxLength"
              placeholder="Enter Pickup Address notes." [resizable]="'none'"
              (valueChange)="onPickupAddressNotesValueChange($event)">
              <kendo-textarea-suffix class="d-flex justify-content-end kehe-textarea-counter">
                <span class="ms-auto">{{ pickupAddressNotesCounter }}</span>
              </kendo-textarea-suffix>
            </kendo-textarea>
          </kendo-formfield>
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex">
            <div class="pickup-phone mb-3">
              <kehe-phoenix-phone-number-input label="Phone"
                formControlName="pickupPhone"></kehe-phoenix-phone-number-input>
            </div>
            <span class="me-2"></span>
            <div>
              <kendo-formfield>
                <kendo-label [for]="phoneExtensionField" text="Ext (optional)"></kendo-label>
                <kendo-maskedtextbox #phoneExtensionField formControlName="pickupExtension" [maskValidation]="false"
                  [prompt]="''" [mask]="mask" style="max-width: 75px !important;">
                </kendo-maskedtextbox>
              </kendo-formfield>
            </div>
          </div>
          <div class="form-group">
            <kehe-phoenix-phone-number-input formControlName="pickupFax"
              label="Fax (optional)"></kehe-phoenix-phone-number-input>
          </div>
          <div style="max-width: 298px;">
            <kendo-formfield>
              <kendo-label [for]="pickupEmailField" text="Email"></kendo-label>
              <kendo-textbox #pickupEmailField formControlName="pickupEmail" placeholder="e.g. contact@email.com">
              </kendo-textbox>
              <kendo-formerror *ngIf="pickupEmail?.errors?.email">Invalid email</kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="!showContactCard">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-label text="Pickup Address" class="mb-1"></kendo-label>
            <app-contact-card formControlName="pickupAddressId" [defaultContactTypes]="contactType.PickupAddress"
              [contacts]="supplier?.contacts" [isInProgress]="saving$ | async" [saveContactError]="error$ | async"
              (selectedMyContact)="onSelectPickupAddress($event)"
              (smartyStreetsUseAddress)="onSmartyStreetsUseAddress($event)"
              (smartyStreetsEditAddress)="onSmartyStreetsEditAddress()" (remove)="onRemovePickupAddress()"
              (closeModal)="onClosePickupAddressModal()">
            </app-contact-card>

          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
  <div *ngIf="canUserEditLeadTimes">
    <div class="row">
      <div class="col">
        <h5>Lead Times</h5>
      </div>
    </div>
    <div class="row mb-3" *ngIf="isPickup && hasValidPickupAddressForTransitTimeCalculation">
      <div class="col">
        <kehe-phoenix-alert message="KeHE transit time is calculated from the Zip Code provided above."
          [type]="alertTypes.Information" [stick]="true" [hideCloseIcon]="true" [show]="true">
        </kehe-phoenix-alert>
      </div>
    </div>
    <kendo-formhint class="warning-hint mb-3" *ngIf="showTransitTimeInitWarning && isPickup">
      Supplier Ready Time was calculated based off of the Total Lead Time minus the KeHE Transit Time.
      Please review and save to confirm the updates.
    </kendo-formhint>
    <label *ngIf="!hasDeliveryMethod">A Delivery Method is required to edit Lead Times.</label>
    <label *ngIf="isPickup && !hasValidPickupAddressForTransitTimeCalculation">
      A Pickup Address is required to edit Lead Times.
    </label>
    <div *ngIf="hasDeliveryMethod">
      <div *ngIf="isDelivered" class="row mb-3">
        <div class="col">
          <kendo-label text="Do you know supplier ready time?"></kendo-label>
          <ul class="k-list-horizontal ps-0 mt-1">
            <li class="k-radio-item me-4">
              <input #knowSupplierReadyTimeYesField [value]="true" class="me-0" formControlName="readyTimeAvailable"
                kendoRadioButton name="readyTimeAvailable" type="radio">
              <kendo-label class='k-radio-label' for="knowSupplierReadyTimeYesField">Yes</kendo-label>
            </li>
            <li class="k-radio-item">
              <input #knowSupplierReadyTimeNoField [value]="false" class="me-0" formControlName="readyTimeAvailable"
                kendoRadioButton name="readyTimeAvailable" type="radio">
              <kendo-label class='k-radio-label' for="knowSupplierReadyTimeNoField">No</kendo-label>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="isDelivered" class="row align-items-start" formGroupName="deliveredLeadTimes">
        <div *ngIf="!showOnlyTotalLeadTime" class="col-md-3">
          <kendo-formfield>
            <kendo-label text="Supplier Ready Time"></kendo-label>
            <kendo-numerictextbox formControlName="readyTime" placeholder="e.g. 3" [spinners]="false" [decimals]="0"
              [format]="'0'">
            </kendo-numerictextbox>
            <kendo-formhint>Calendar days</kendo-formhint>
            <kendo-formerror *ngIf="deliveredReadyTimeField?.errors?.required">
              Supplier Ready Time is required.
            </kendo-formerror>
            <kendo-formerror *ngIf="deliveredReadyTimeField?.errors?.min || deliveredReadyTimeField?.errors?.max">
              Between 1 and 499
            </kendo-formerror>
          </kendo-formfield>
        </div>
        <div *ngIf="!showOnlyTotalLeadTime" class="col-12 col-md-auto pe-0 ps-0 operator pt-md-1 my-3">
          +
        </div>
        <div *ngIf="!showOnlyTotalLeadTime" class="col-md-3">
          <kendo-formfield>
            <kendo-label text="Supplier Transit Time"></kendo-label>
            <kendo-numerictextbox formControlName="transitTime" placeholder="e.g. 9" [spinners]="false" [decimals]="0"
              [format]="'0'">
            </kendo-numerictextbox>
            <kendo-formhint>Calendar days</kendo-formhint>
            <kendo-formerror *ngIf="deliveredTransitTimeField?.errors?.required">
              Supplier Transit Time is required.
            </kendo-formerror>
            <kendo-formerror *ngIf="deliveredTransitTimeField?.errors?.min || deliveredTransitTimeField?.errors?.max">
              Between 1 and 499
            </kendo-formerror>
          </kendo-formfield>
        </div>
        <div *ngIf="!showOnlyTotalLeadTime" class="col-12 col-md-auto pe-0 ps-0 operator pt-md-1 my-3">
          =
        </div>
        <div class="col-md-3">
          <kendo-formfield>
            <kendo-label text="Total Lead Time"></kendo-label>
            <kendo-numerictextbox [readonly]="!showOnlyTotalLeadTime" formControlName="totalTime" placeholder="e.g. 12"
              [decimals]="0" [format]="'0'" [spinners]="false">
            </kendo-numerictextbox>
            <kendo-formhint>Calendar days</kendo-formhint>
            <kendo-formerror *ngIf="deliveredTotalTimeField?.errors?.required && showOnlyTotalLeadTime">
              Total Lead Time is required.
            </kendo-formerror>
            <kendo-formerror
              *ngIf="(deliveredTotalTimeField?.errors?.min || deliveredTotalTimeField?.errors?.max) && showOnlyTotalLeadTime">
              Between 1 and 999
            </kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
      <div *ngIf="isPickup && hasValidPickupAddressForTransitTimeCalculation" formGroupName="pickupLeadTimes">
        <div class="row align-items-start">
          <div *ngIf="!showOnlyTotalLeadTime" class="col-md-3">
            <kendo-formfield>
              <kendo-label text="Supplier Ready Time"></kendo-label>
              <kendo-numerictextbox [class.has-warning]="showTransitTimeInitWarning" formControlName="readyTime"
                placeholder="e.g. 3" [spinners]="false" [decimals]="0" [format]="'0'">
              </kendo-numerictextbox>
              <kendo-formhint [class.warning-hint]="showTransitTimeInitWarning">Calendar days</kendo-formhint>
              <kendo-formerror *ngIf="pickUpReadyTimeField?.errors?.required">
                Supplier Ready Time is required.
              </kendo-formerror>
              <kendo-formerror *ngIf="pickUpReadyTimeField?.errors?.min || pickUpReadyTimeField?.errors?.max">
                Between 1 and 499
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <ng-container *ngIf="!isCloneDCPGAction">
            <div *ngIf="!showOnlyTotalLeadTime" class="col-12 col-md-auto pe-0 ps-0 operator pt-md-1 my-3">
              +
            </div>
            <div *ngIf="!showOnlyTotalLeadTime" class="col-md-3">
              <kendo-formfield>
                <div>
                  <kendo-label text="KeHE Transit Time"></kendo-label>
                  <em class="fas fa-info-circle info-tooltip" [keheTooltip]="keHETransitTimeTooltip"></em>
                </div>
                <kendo-numerictextbox [readonly]="!isTransitTimeOverridden" formControlName="transitTime"
                  placeholder="e.g. 9" [ngClass]="showKeHETransitTimeWarning ? 'with-warning' : ''" [spinners]="false"
                  [decimals]="0" [format]="'0'">
                </kendo-numerictextbox>
                <kendo-formhint *ngIf="!showKeHETransitTimeWarning">Calendar days</kendo-formhint>
                <kendo-formhint class="warning-hint" *ngIf="pickUpTransitTimeDefaultField.value">
                  Transit time is missing in the transit table. A default has been applied.
                </kendo-formhint>
                <kendo-formhint class="warning-hint" *ngIf="hasTransitTimeOverriddenWarning">
                  Does not use transit table value.
                </kendo-formhint>
                <kendo-formerror *ngIf="keHETransitTimeNetworkError && !isTransitTimeOverridden">
                  {{ keHETransitTimeNetworkError }}
                </kendo-formerror>
                <kendo-formerror
                  *ngIf="!keHETransitTimeNetworkError && pickUpTransitTimeField?.errors?.required && !isTransitTimeOverridden">
                  Transit time is missing in the transit table.
                </kendo-formerror>
                <kendo-formerror *ngIf="pickUpTransitTimeField?.errors?.required && isTransitTimeOverridden">
                  KeHE Transit Time is required.
                </kendo-formerror>
                <kendo-formerror *ngIf="pickUpTransitTimeField?.errors?.min || pickUpTransitTimeField?.errors?.max">
                  Between 1 and 499
                </kendo-formerror>
              </kendo-formfield>
            </div>
            <div *ngIf="!showOnlyTotalLeadTime" class="col-12 col-md-auto pe-0 ps-0 operator pt-md-1 my-3">
              =
            </div>
            <div class="col-md-3">
              <kendo-formfield>
                <kendo-label text="Total Lead Time"></kendo-label>
                <kendo-numerictextbox [readonly]="true" formControlName="totalTime" placeholder="e.g. 12"
                  [spinners]="false" [format]="'0'" [decimals]="0">
                </kendo-numerictextbox>
                <kendo-formhint>Calendar days</kendo-formhint>
              </kendo-formfield>
            </div>
          </ng-container>

        </div>
        <div class="mt-3" *ngIf="!isCloneDCPGAction">
          <input type="checkbox" kendoCheckBox formControlName="isTransitTimeOverridden" class="align-middle" />
          <kendo-label class="k-checkbox-label ms-1 my-auto">
            Override KeHE Transit Time (Value must be in Calendar days)
          </kendo-label>
        </div>
      </div>
    </div>
    <hr>
  </div>
</div>