import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { SupplierDetail } from '../../models/supplier-detail';

@Component({
  selector: 'app-performance-tab',
  templateUrl: './performance-tab.component.html',
  styleUrls: ['./performance-tab.component.scss']
})
export class PerformanceTabComponent extends Destroyable implements OnInit {

  supplier: SupplierDetail;

  constructor(
    private _store: Store
  ) {
    super();
   }

  ngOnInit(): void {
    this._store
    .pipe(takeUntil(this.destroy$), select(selectSupplierDetail))
    .subscribe((value) => {
      this.supplier = value;
    });
  }

  goToPerformanceV1(): void {
    window.open(`${environment.v1ConnectHost}/vendormanagement/#/landingPage/vendorDetails/vendorSnapshots?esn=${this.supplier.esn}&userType=keheemployee`, '_blank');
  }
}
