import { Component, OnInit } from '@angular/core';
import { IFileUpload } from '@kehe/phoenix-upload';
import { select, Store } from '@ngrx/store';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { downloadNewContractTemplate } from '../../../supplier-documents/store/supplier-documents.actions';
import { readBase64 } from '../../../supplier-documents/utils/documents-utils';
import { cancelTermsNewContractForm, saveTermsForm } from '../../store/supplier-detail.actions';
import { selectSaveInProgress } from '../../store/supplier-detail.selectors';

@Component({
  selector: 'app-new-contract-modal',
  templateUrl: './new-contract-modal.component.html',
})
export class NewContractModalComponent extends Destroyable implements OnInit {

  warning = 'You have made changes to the supplier terms. Updated terms will require a new contract to be generated and signed by the supplier. This will replace any existing contract documents.';
  inProgress = false;
  error = null;
  generateContract = true;
  fileUploads = [];
  allowMultipleSelect = false;
  showUploadDocumentModal: boolean;
  uploadRestrictions: FileRestrictions = { allowedExtensions: ['.pdf']};
  isFileValid = false;

  constructor(
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSaveInProgress))
      .subscribe((val) => {
        this.inProgress = val;
      });
  }

  closeModal() {
    this._store.dispatch(cancelTermsNewContractForm());
  }

  onSaveClicked() {
    if (this.generateContract) {
      readBase64(this.fileUploads[0].rawFile)
      .then(data => {
        const addendumAsBase64String  = data.split('base64,')[1];
        this._store.dispatch(saveTermsForm({ addendumAsBase64String }));
      });
    } else {
      this._store.dispatch(saveTermsForm({ addendumAsBase64String: null }));
    }
  }

  disableSave() {
    if (this.inProgress) {
      return true;
    }
    if (this.generateContract && (this.fileUploads.length === 0 || !this.isFileValid)) {
      return true;
    }
    return false;
  }

  handleAddFiles(files: IFileUpload[]): void {
    if (this.inProgress) {
      return;
    }
    if (!this.allowMultipleSelect) {
      this.fileUploads = [];
    }
    this.isFileValid = this.isUploadedFileValid(files);
    files.forEach(file => {
      this.fileUploads = [
        {
          ...file,
          progress: 100,
          uploading: false,
          uploadComplete: true
        },
        ... this.fileUploads,
      ];
    });
  }

  handleRemoveFiles(file: IFileUpload): void {
    if (this.inProgress) {
      return;
    }
    this.fileUploads = [... this.fileUploads].filter(f => f.uid !== file.uid);
  }

  isUploadedFileValid(files: IFileUpload[]) {
    const inValidFiles = files.filter(file => file.validationErrors && file.validationErrors.length > 0);
    return inValidFiles && inValidFiles.length > 0 ? false : true;
  }

  downloadAddendumAlertClicked() {
    this._store.dispatch(downloadNewContractTemplate());
  }
}
