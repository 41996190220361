import { AlertMessage } from './../../../models/alert-message';
import { createAction, props } from '@ngrx/store';
import { SupplierDetail } from '../models/supplier-detail';
import { BrokerDetail } from '../../broker-detail/models/broker-detail';
import { BrokerDetail as BrokerContactDetail } from '../../supplier-detail/models/broker-detail';
import { SupplierGeneralFormModel } from '../models/supplier-general-form-model';
import { SupplierDetailTabs } from '../models/supplier-detail-tabs';
import { DcSupplierFilter } from '../models/dc-supplier-filter';
import { TermsFormObject } from '../models/terms-form-object';
import { CodeValue } from '../models/code-value';
import { IAuditDetail } from '@kehe/phoenix-audit-history';
import { Payload } from '../../../models/payload';
import { IChip } from '@kehe/phoenix-chips';
import { DropdownOption } from './../../../models/dropdown-option';
import { SupplierHistoryFilterItem } from '../models/supplier-history-filter';
import { SupplierHistoryFilter } from './supplier-detail.reducer';
import { SupplierProductGroupForm } from '../models/product-group-form';
import { DCProductGroup } from '../models/dc-product-group';
import { SupplierDCProductGroupForm } from '../models/product-group-dc-form';
import { ProductGroup } from '../models/product-group';
import { ProductGroupViews } from '../models/product-group-views';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { OnBoardingStatus } from '../models/onboarding-status';
import {
  ChecklistNavigationTab
} from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';
import { SupplierAction } from '../models/supplier-action';
import { TermsDropdownData } from '../models/terms-dropdown-data';
import { OnboardingTabStatuses } from '../../../models/onboarding-tab-statuses';
import { Contact, ContactType } from '../../../models/contact';
import { Signer } from '../models/signer';
import { AddNoteForm } from '../models/add-note-form';
import { PendoEvent } from '../models/pendo-event.interface';

export const resetNewSupplierFlag = createAction(
  '[Supplier Detail] Reset New Supplier Flag',
);
export const loadSupplierDetail = createAction(
  '[Supplier Detail] Load Supplier Detail',
  props<{ esn: string }>()
);
export const loadSupplierDetailSuccess = createAction(
  '[Supplier Detail] Load Supplier Detail Success',
  props<{ supplier: SupplierDetail, dcList: Array<DropdownOption> }>()
);
export const loadSupplierDetailError = createAction(
  '[Supplier Detail] Load Supplier Detail Error',
);
export const saveRetailer = createAction(
  '[Supplier Detail] Save Retailer',
  props<{ retailer: any }>()
);
export const saveRetailerFailed = createAction(
  '[Supplier Detail] Save Retailer Failed',
);
export const loadSupplierBroker = createAction(
  '[Supplier Detail] Load Supplier Broker'
);
export const loadSupplierBrokerSuccess = createAction(
  '[Supplier Detail] Load Supplier Broker Success',
  props<{ broker: BrokerDetail }>()
);
export const loadSupplierBrokerError = createAction(
  '[Supplier Detail] Load Supplier Broker Error'
);
export const loadTermsDropdownSuccess = createAction(
  '[Supplier Detail] Load Terms Dropdown Success',
  props<{ dropdownData: TermsDropdownData[] }>()
);
export const editSupplier = createAction(
  '[Supplier Detail] Edit Supplier',
);
export const editSupplierTerms = createAction(
  '[Supplier Detail] Edit Supplier Terms',
);
export const editDcSupplier = createAction(
  '[Supplier Detail] Edit Dc Supplier',
  props<{
    selectedDcSupplierDc: string,
    selectedDcSupplierNumber: string
  }>()
);
export const addDCProductGroup = createAction(
  '[Supplier Detail] Add DC Product Group',
);
export const editProductGroup = createAction(
  '[Supplier Detail] Edit Product Group',
  props<{
    selectedDcSupplierDc: string,
    selectedDcSupplierNumber: string
  }>()
);
export const saveGeneralForm = createAction(
  '[Supplier Detail] Save General Form',
);
export const saveGeneralFormSuccess = createAction(
  '[Supplier Detail] Save General Form Success',
  props<{ supplier: SupplierDetail }>()
);
export const saveUpdateFormSuccess = createAction(
  '[Supplier Detail] Save Update Form Success',
  props<{
    form: any,
  }>()
);
export const saveUpdateFormError = createAction(
  '[Supplier Detail] Save Update Form Error',
);
export const saveGeneralFormError = createAction(
  '[Supplier Detail] Save General Form Error',
);
export const showTermsNewContractForm = createAction(
  '[Supplier Detail] Show Terms Contract Form',
);
export const cancelTermsNewContractForm = createAction(
  '[Supplier Detail] Cancel Terms Contract Form',
);
export const saveTermsForm = createAction(
  '[Supplier Detail] Save Terms Form',
  props<{ addendumAsBase64String?: string }>()
);
export const saveTermsFormSuccess = createAction(
  '[Supplier Detail] Save Terms Form Success',
  props<{ supplier: SupplierDetail, isContractAgreementUploaded: boolean }>()
);
export const saveTermsFormError = createAction(
  '[Supplier Detail] Save Terms Form Error',
);
export const attemptCancelEdit = createAction(
  '[Supplier Detail] Attempt Cancel Edit',
);
export const confirmCancelEdit = createAction(
  '[Supplier Detail] Confirm Cancel Edit',
);
export const showUnsavedChangesModal = createAction(
  '[Supplier Detail] Show Unsaved Changes Modal',
);
export const hideUnsavedChangesModal = createAction(
  '[Supplier Detail] Hide Unsaved Changes Modal',
);
export const generalFormChanged = createAction(
  '[Supplier Detail] General Form Changed',
  props<{
    form: SupplierGeneralFormModel,
    isDirty: boolean,
    isHeadquarterAddressDirty: boolean,
    isGeneralFormValid: boolean,
  }>()
);
export const termsFormChanged = createAction(
  '[Supplier Detail] Terms Form Changed',
  props<{
    form: TermsFormObject,
    isDirty: boolean,
    isTermsFormValid: boolean
  }>()
);
export const toggleNavigationPanel = createAction(
  '[Supplier Detail] Toggle Navigation Panel'
);
export const setCurrentTab = createAction(
  '[Supplier Detail] Set Current Tab',
  props<{ tab: SupplierDetailTabs }>()
);
export const getPurchasingOptionsAlertMessage = createAction(
  '[Supplier Detail] Get Purchasing Options Alert',
  props<{ alert: AlertMessage }>()
);
export const hideAlertMessage = createAction(
  '[Supplier Detail] Hide Alert Message'
);
export const getFormDropdownData = createAction(
  '[Supplier Detail] Get Form Dropdown Data'
);
export const dcSupplierSearchChanged = createAction(
  '[Supplier Detail] DC Supplier Dc Detail Search Changed',
  props<{ search: string }>()
);
export const refineDcSupplierList = createAction(
  '[Supplier Detail]  Refine DC Supplier List'
);
export const dcSupplierSearchCleared = createAction(
  '[Supplier Detail] DC Supplier Dc Detail Search Cleared'
);
export const openDcSupplierFilter = createAction(
  '[Supplier Detail] Open Dc Supplier Detail Filter Panel'
);
export const closeDcSupplierFilter = createAction(
  '[Supplier Detail] Close Dc Supplier Detail Filter Panel'
);
export const dcSupplierFilterChanged = createAction(
  '[Supplier Detail] Dc Supplier Filter Changed',
  props<{ dcSupplierFilter: DcSupplierFilter }>()
);
export const dcSupplierFilterCleared = createAction(
  '[Supplier Detail] Dc Supplier Filter Cleared'
);
export const applyDcSupplierFilter = createAction(
  '[Supplier Detail] Apply Dc Supplier Filter'
);
export const dcSupplierChipClicked = createAction(
  '[Supplier Detail] Dc Supplier Chip Clicked',
  props<{ chip: any }>()
);
export const dcSupplierChipClearAll = createAction(
  '[Supplier Detail] Dc Supplier Chip Clear All'
);
export const openProductGroupsFilter = createAction(
  '[Supplier Detail] Open Product Groups Filter Panel'
);
export const closeProductGroupsFilter = createAction(
  '[Supplier Detail] Close Product Groups Filter Panel'
);
export const showAddProductGroupModal = createAction(
  '[Supplier Detail] Show Add Product Group Modal',
);
export const hideAddProductGroupModal = createAction(
  '[Supplier Detail] Hide Add Product Group Modal',
);
export const onProductGroupsDetailsView = createAction(
  '[Supplier Detail] On Product Groups Details View',
  props<{ show: boolean }>()
);
export const productGroupFormChanged = createAction(
  '[Supplier Detail] Product Group Form Changed',
  props<{
    form: SupplierProductGroupForm,
    isDirty: boolean,
    productGroupRemittanceAddressDirty: boolean,
    isProductGroupFormValid: boolean,
  }>()
);
export const saveProductGroupForm = createAction(
  '[Supplier Detail] Save Product Group Form',
);
export const saveProductGroupFormSuccess = createAction(
  '[Supplier Detail] Save Product Group Form Success',
  props<{ esn: string, productGroups: ProductGroup[], dcList: Array<DropdownOption> }>()
);
export const saveProductGroupFormError = createAction(
  '[Supplier Detail] Save Product Group Form Error',
  props<{ errorCode: number }>()
);
export const saveDCProductGroupForm = createAction(
  '[Supplier Detail] Save DC Product Group Form',
);
export const saveDCProductGroupFormSuccess = createAction(
  '[Supplier Detail] Save DC Product Group Form Success',
  props<{
    esn: string,
    productGroups: ProductGroup[],
    dcList: Array<DropdownOption>,
    productGroupView: ProductGroupViews
  }>()
);
export const saveDCProductGroupFormError = createAction(
  '[Supplier Detail] Save DC Product Group Form Error',
  props<{ errorCode: number }>()
);
export const addNoteProductGroupForm = createAction(
  '[Supplier Detail] Add Note to Product Group Form',
  props<{ notes: string }>()
);
export const addNoteDCProductGroupForm = createAction(
  '[Supplier Detail] Add Note to DC Product Group Form',
  props<{ notes: string }>()
);
export const editProductGroupDC = createAction(
  '[Supplier Detail] Edit DC Product Group',
  props<{ dcProductGroup: DCProductGroup }>()
);
export const editAddNewProductGroupDC = createAction(
  '[Supplier Detail] Edit Newly Added DC Product Group',
  props<{ newDCProductGroup: DCProductGroup }>()
);
export const clearAddNewProductGroupDC = createAction(
  '[Supplier Detail] Clear Newly Added DC Product Group'
);
export const saveNewProductGroupForm = createAction(
  '[Supplier Detail] Save New Product Group Form',
);
export const saveNewProductGroupFormSuccess = createAction(
  '[Supplier Detail] Save New Product Group Form Success',
  props<{ esn: string, productGroups: ProductGroup[], dcList: Array<DropdownOption> }>()
);
export const saveNewProductGroupFormError = createAction(
  '[Supplier Detail] Save New Product Group Form Error',
  props<{ errorCode: number }>()
);
export const showKeHETransitTimesWarning = createAction(
  '[Supplier Detail] Show KeHE Transit Times Warning'
);
export const clearKeHETransitTimesWarning = createAction(
  '[Supplier Detail] Clear KeHE Transit Times Warning'
);
export const validateHeadquarterAddress = createAction(
  '[Supplier Detail] Validate Headquarter Address'
);
export const validateRemittanceAddress = createAction(
  '[Supplier Detail] Validate Remittance Address'
);
export const generalFormNoAddressSelected = createAction(
  '[Supplier Detail] General Form No Address Selected'
);
export const clearValidateRemittanceAddress = createAction(
  '[Supplier Detail] Product Group Clear Validate RemittanceAddress'
);
export const showAddBrokerModal = createAction(
  '[Supplier Detail] Show Add Broker Modal',
);
export const hideAddBrokerModal = createAction(
  '[Supplier Detail] Hide Add Broker Modal',
);
export const showRemoveBrokerModal = createAction(
  '[Supplier Detail] Show Remove Broker Modal',
);
export const hideRemoveBrokerModal = createAction(
  '[Supplier Detail] Hide Remove Broker Modal',
);
export const brokerPatchNotesModal = createAction(
  '[Supplier Detail] Broker Patch Notes Modal',
  props<{ brokerForm: any }>()
);
export const brokerPatch = createAction(
  '[Supplier Detail] Broker Patch',
  props<{ notes: string }>()
);
export const brokerPatchSuccess = createAction(
  '[Supplier Detail] Broker Patch Success',
  props<{ brokerForm: any }>()
);
export const brokerPatchError = createAction(
  '[Supplier Detail] Broker Patch Error',
);
export const getPaymentTermsList = createAction(
  '[Supplier Detail] Get Payment Terms List'
);
export const getPaymentTermsListSuccess = createAction(
  '[Supplier Detail] Get Payment Terms List Success',
  props<{ paymentTerms: CodeValue[] }>()
);
export const getMarketingAllowanceList = createAction(
  '[Supplier Detail] Get Marketing Allowance List'
);
export const getMarketingAllowanceListSuccess = createAction(
  '[Supplier Detail] Get Marketing Allowance List Success',
  props<{ marketingAllowance: CodeValue[] }>()
);
export const getDeliveryMethodListSuccess = createAction(
  '[Supplier Detail] Get Delivery Method List Success',
  props<{ deliveryMethods: any[] }>()
);

export const saveAddNoteFormValues = createAction(
  '[Supplier Detail] Save Add Note Form Values',
  props<{ form: AddNoteForm}>()
);

export const dcSupplierFormChanged = createAction(
  '[Supplier Detail] Dc Supplier Form Changed',
  props<{
    form: any,
    isDirty: boolean,
    isDcSupplierFormValid: boolean,
    isPickupAddressDirty: boolean
  }>()
);
export const dcProductGroupFormChanged = createAction(
  '[Supplier Detail] Dc Product Group Form Changed',
  props<{
    form: SupplierDCProductGroupForm,
    isDirty: boolean,
    isDcProductGroupFormValid: boolean,
    isPickupAddressDirty: boolean
  }>()
);
export const newProductGroupFormChanged = createAction(
  '[Supplier Detail] NEW Product Group Form Changed',
  props<{
    form: SupplierProductGroupForm,
    isDirty: boolean,
    isProductGroupFormValid: boolean,
    isPickupAddressDirty: boolean
    isRemittanceAddressDirty: boolean
  }>()
);
export const loadSupplierHistoryList = createAction(
  '[Supplier Detail] Load History List'
);

export const loadSupplierHistoryListSuccess = createAction(
  '[Supplier Detail] Load History List Success',
  props<{ data: Payload<Array<IAuditDetail>> }>()
);

export const loadSupplierHistoryListError = createAction(
  '[Supplier Detail] Load History List Error'
);
export const supplierHistorySearchChange = createAction(
  '[Supplier Detail] History search changed',
  props<{ search: string }>()
);
export const supplierHistoryFilterOpenPanel = createAction(
  '[Supplier Detail] History open filter panel'
);
export const supplierHistoryFilterClosePanel = createAction(
  '[Supplier Detail] History close filter panel'
);
export const supplierHistoryApplyFilters = createAction(
  '[Supplier Detail] History apply filters',
  props<{ filters: SupplierHistoryFilter }>()
);
export const supplierHistoryClearFilters = createAction(
  '[Supplier Detail] History clear filters'
);
export const supplierHistoryChipClicked = createAction(
  '[Supplier Detail] History Chip Clicked',
  props<{ chip: IChip }>()
);
export const supplierHistoryClearAllChipsClicked = createAction(
  '[Supplier Detail] History Clear All Chips clicked'
);
export const supplierHistoryLoadFiltersData = createAction(
  '[Supplier Detail] History load filters data'
);
export const supplierHistoryLoadFiltersDataSuccess = createAction(
  '[Supplier Detail] History load filters data Success',
  props<{ data: Array<Payload<Array<SupplierHistoryFilterItem>>> }>()
);
export const supplierHistoryLoadFiltersDataFailure = createAction(
  '[Supplier Detail] History load filters data Failure'
);
export const supplierHistoryPageIndexChanged = createAction(
  '[Supplier Detail] Hisotry page index changed',
  props<{ index: number }>()
);
export const supplierHistoryPageSizeChanged = createAction(
  '[Supplier Detail] History page size changed',
  props<{ size: number }>()
);
export const supplierHistoryDisplayDetailModal = createAction(
  '[Supplier Detail] History display detail modal',
  props<{ row: IAuditDetail }>()
);
export const supplierHistoryCloseDetailModal = createAction(
  '[Supplier Detail] History close detail modal'
);
export const validatePickupAddress = createAction(
  '[Supplier Detail] Validate Pickup Address'
);
export const clearValidatingPickupAddress = createAction(
  '[Supplier Detail] Product Group Clear Validating PickupAddress'
);
export const dcProductGroupFormNoAddressSelected = createAction(
  '[Supplier Detail] DC Product Group Form No Address Selected'
);
export const loadBuyerList = createAction(
  '[App] Load Buyer List'
);
export const loadBuyerListSuccess = createAction(
  '[App] Load Buyer List Success',
  props<{ list: any[] }>()
);
export const loadBuyerListError = createAction(
  '[App] Load Buyer List Error',
);
export const showNotesModal = createAction(
  '[Supplier Detail] Show Notes Modal'
);
export const showSubmitModal = createAction(
  '[Supplier Detail] Show Submit Modal'
);
export const hideSubmitModal = createAction(
  '[Supplier Detail] Hide Submit Modal'
);
export const hideNotesModal = createAction(
  '[Supplier Detail] Hide Notes Modal'
);
export const addNoteGeneralForm = createAction(
  '[Supplier Detail] Add Note to General Form',
  props<{ notes: string }>()
);
export const addNoteTermsForm = createAction(
  '[Supplier Detail] Add Note to Terms Form',
  props<{ notes: string, changeType?: number; }>()
);
export const updateContractDateTermsForm = createAction(
  '[Supplier Detail] Update Contract Date on Terms Form',
  props<{ contractDate: any }>()
);
export const addNoteDcDetailForm = createAction(
  '[Supplier Detail] Add Note to Dc Details Form',
  props<{ notes: string }>()
);

export const noOpAction = createAction(
  '[Supplier Detail] No Op'
);

export const showSupplierStatusChangeModal = createAction(
  '[Supplier Detail] Show Supplier Status Change Modal',
);

export const hideSupplierStatusChangeModal = createAction(
  '[Supplier Detail] Hide Suppleri Status Change Modal',
);

export const supplierStatusChangeUpdate = createAction(
  '[Supplier Detail] Supplier Status Change',
  props<{ status: string, auditNotes: string, isActive: boolean, esn: string }>()
);

export const supplierValidated = createAction(
  '[Supplier Detail] Supplier Validated',
  props<{ tabs: ChecklistNavigationTab[] }>()
);

export const submitSupplier = createAction(
  '[Supplier Detail] Submit Supplier',
);
export const submitSupplierSuccess = createAction(
  '[Supplier Detail] Submit Supplier Success',
  props<{ pendoEvent: PendoEvent }>()
);
export const submitSupplierError = createAction(
  '[Supplier Detail] Submit Supplier Error',
  props<{ errorCode: number }>()
);
export const brandsSearchChanged = createAction(
  '[Supplier Detail] Brands Search Changed',
  props<{ search: string }>()
);
export const brandsSearchCleared = createAction(
  '[Supplier Detail] Brands Search Cleared',
);
export const setValidationErrors = createAction(
  '[Supplier Detail] Form Validation Errors',
  props<{ errors: any[] }>()
);

export const refreshSupplierReviewStatus = createAction(
  '[Supplier Detail] Refresh Supplier Review Status',
  props<{ supplier: SupplierDetail }>()
);

// OnBoarding Status
export const loadOnBoardingStatusSuccess = createAction(
  '[Supplier Detail] Load OnBoarding Status Success',
  props<{ onboardingStatus: OnBoardingStatus[] }>()
);
export const showGeneralTabOnBoardingStatusModal = createAction(
  '[Supplier Detail] show General Tab OnBoarding Status Modal',
  props<{ onboardingStatusChangeAction: SupplierAction }>()
);
export const closeGeneralTabOnBoardingStatusModal = createAction(
  '[Supplier Detail] Close General Tab OnBoarding Status Modal',
);
export const updateGeneralTabStatus = createAction(
  '[Supplier Detail] Update General Tab Status',
  props<{ status: OnboardingTabStatuses, statusNote: string }>()
);
export const updateGeneralTabStatusSuccess = createAction(
  '[Supplier Detail] Update General Tab Status Success',
  props<{ status: OnBoardingStatus, pendoEvent: PendoEvent }>()
);
export const updateGeneralTabStatusError = createAction(
  '[Supplier Detail] Update General Tab Status Error',
);
// OnBoarding Status

// Supplier Contact
export const addSupplierContact = createAction(
  '[Supplier Detail] Add Supplier Contact',
  props<{ contact: Contact, contactType?: ContactType, bulkEditPayload?: any }>()
);
export const addSupplierContactSuccess = createAction(
  '[Supplier Detail] Add Supplier Contact Success',
  props<{ contacts: Contact[], created: Contact }>()
);
export const addSupplierContactError = createAction(
  '[Supplier Detail] Add Supplier Contact Error',
  props<{ errorCode: number }>()
);
export const clearSupplierContactForm = createAction(
  '[Supplier Detail] Clear Supplier Contact Form',
);
// Supplier Contact

// DocuSign
export const confirmDocuSignSigner = createAction(
  '[Supplier Detail] Confirm DocuSign Signer',
  props<{ signer: Signer }>()
);
export const cancelDocuSignSigner = createAction(
  '[Supplier Detail] Cancel DocuSign Signer',
);
export const sendDocuSignEmail = createAction(
  '[Supplier Detail] Send DocuSign Email',
);
export const sendDocuSignEmailSuccess = createAction(
  '[Supplier Detail] Send DocuSign Email Success',
  props<{ supplier: SupplierDetail }>()
);
export const sendDocuSignEmailError = createAction(
  '[Supplier Detail] Send DocuSign Email Error',
);
export const invalidateDocuSign = createAction(
  '[Supplier Detail] Invalidate DocuSign',
  props<{ rejected: boolean; status: OnBoardingStatus }>()
);
// DocuSign

// Supplier Optimization Appointment Scheduling
export const clickAppointmentModalSaveButton = createAction(
  '[Supplier Detail]: Click Appointment Modal Save Button',
  props<{ meetingDate: Date }>()
);
export const clickEditAppointmentButton = createAction(
  '[Supplier Detail]: Click Edit Appointment Button'
);
export const appointmentSchedulingSuccess = createAction(
  '[Supplier Detail]: Appointment Scheduling Success',
  props<{ supplier: SupplierDetail; }>()
);
export const appointmentSchedulingFailed = createAction(
  '[Supplier Detail]: Appointment Scheduling Failed'
)

// Supplier Form (For Broker)
export const loadSupplierBrokerContact = createAction(
  '[Supplier Detail] Load Supplier Broker Contact',
  props<{ supplier: SupplierDetail }>()
);
export const loadSupplierBrokerContactSuccess = createAction(
  '[Supplier Detail] Load Supplier Broker Contact Success',
  props<{ broker: BrokerContactDetail }>()
);
export const clearSupplierBrokerContact = createAction(
  '[Supplier Detail] Clear Supplier Broker Contact',
);
export const supplierFormChanged = createAction(
  '[Supplier Detail] Supplier Form Changed',
  props<{
    form: any,
    isDirty: boolean,
    isSupplierFormValid: boolean,
  }>()
);
export const saveSupplierForm = createAction(
  '[Supplier Detail] Save Supplier Form',
);
export const saveSupplierFormSuccess = createAction(
  '[Supplier Detail] Save Supplier Form Success',
  props<{ supplier: SupplierDetail, form: any }>()
);
export const saveSupplierFormError = createAction(
  '[Supplier Detail] Save Supplier Form Error',
);
// Supplier Form (For Broker)

// Update CanUploadSIF
export const updateCanUploadSIF = createAction(
  '[Supplier Detail] Update CanUploadSIF',
  props<{ canUploadSIF: boolean }>()
);
export const updateCanUploadSIFSuccess = createAction(
  '[Supplier Detail] Update CanUploadSIF Success',
  props<{ canUploadSIF: boolean, pendoEvent: PendoEvent }>()
);
export const updateCanUploadSIFError = createAction(
  '[Supplier Detail] Update CanUploadSIF Error',
  props<{ canUploadSIF: boolean }>()
);
// Update CanUploadSIF

// Delete Supplier
export const showDeleteSupplierModal = createAction(
  '[Supplier Detail] Show Delete Supplier Modal',
);
export const hideDeleteSupplierModal = createAction(
  '[Supplier Detail] Hide Delete Supplier Modal',
);
export const deleteSupplier = createAction(
  '[Supplier Detail] Delete Supplier',
);
export const deleteSupplierSuccess = createAction(
  '[Supplier Detail] Delete Supplier Success',
);
export const deleteSupplierError = createAction(
  '[Supplier Detail] Delete Supplier Error',
);
// Delete Supplier
// Refresh Supplier Terms
export const clickTermsRefreshButton = createAction(
  '[Supplier Detail] Click Terms Refresh Button',
);
export const termsRefreshSuccess = createAction(
  '[Supplier Detail] Terms Refresh Success',
);
export const termsRefreshFailed = createAction(
  '[Supplier Detail] Terms Refresh Failed'
);

// Export DPI Supplier Data
export const userClickExportSupplierDataButton = createAction(
  '[Supplier Detail] User Click Export Supplier Data Button',
  props<{ esn: string }>(),
);

export const exportSupplierDataSuccess = createAction(
  '[Supplier Detail] Export Supplier Data Success'
);

export const exportSupplierDataFailed = createAction(
  '[Supplier Detail] Export Supplier Data Failed'
);
//

export const updateKendoBreadCrumbs = createAction(
    '[Supplier Detail API] Update BreadCrumbs',
    props <{ item: BreadCrumbItem }>(),
);

export const clearKendoBreadCrumbs = createAction(
  '[Supplier Detail API] Clear BreadCrumbs',
);
