import { FormControl, FormControlStatus, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';

export const allowedDocumentFileExtensions = ['.doc', '.docx', '.jpeg', '.xls', '.xlsx', '.pdf', '.ppt', '.txt', '.png'];

// Static field options
export const documentDepartmentList = [
  { value: 'Bakery', label: 'Bakery' },
  { value: 'Bulk', label: 'Bulk' },
  { value: 'Deli & Cheese', label: 'Deli & Cheese' },
  { value: 'Meat & Seafood', label: 'Meat & Seafood' },
  { value: 'Store Supplies', label: 'Store Supplies' }
];

export const documentFileTypes = [
  { value: '.txt', label: '.txt' },
  { value: '.xls', label: '.xls' },
];

export const documentStoreTypes = [
  { value: 'Natural', label: 'Natural' },
  { value: 'Grocery', label: 'Grocery' },
  { value: 'Natural,Grocery', label: 'Natural & Grocery' }
];

// Seperated because this value drives custom logic for Sprouts when this is the selected DocumentType on the record
export const DOCUMENT_TYPE_SPROUTS_GUIDE = 'Sprouts Order Guide';

export function getDocumentTypesForMode(mode: DocumentCreateMode): Observable<{ value: string, label: string }[]> {
  const documentTypes = [
    { value: 'Cadia - Brand Brochure', label: 'Cadia - Brand Brochure' },
    { value: 'Cadia - Promotional Items', label: 'Cadia - Promotional Items' },
    { value: 'Cadia - Turnover Order Form', label: 'Cadia - Turnover Order Form' },
    { value: 'Merchandising Solutions - Bonus Insights', label: 'Merchandising Solutions - Bonus Insights' },
    { value: 'Merchandising Solutions - Regional Spotlight', label: 'Merchandising Solutions - Regional Spotlight' },
    { value: 'New@KeHE', label: 'New@KeHE' },
    { value: 'KeHE Promotional Buying Guide', label: 'KeHE Promotional Buying Guide' },
    { value: 'KeHE Monthly Promotions', label: 'KeHE Monthly Promotions' },
    { value: 'KeHE Show & Trends', label: 'KeHE Show & Trends' },
    { value: 'Category Resources & Trends', label: 'Category Resources & Trends' },
    { value: 'Retailer Connections Webinar', label: 'Retailer Connections Webinar' },
    { value: 'Retailer Connections Newsletter', label: 'Retailer Connections Newsletter' },
  ];
  if (mode === DocumentCreateMode.file) {
    documentTypes.push({ value: DOCUMENT_TYPE_SPROUTS_GUIDE, label: DOCUMENT_TYPE_SPROUTS_GUIDE });
  }
  return of(documentTypes);
}

export function isSproutsOrderGuide(documentType: string): boolean {
  return documentType === DOCUMENT_TYPE_SPROUTS_GUIDE;
}

// Possible row actions on Document list 
export const enum DocumentActionOptions {
  Delete = 'Delete',
  Download = 'Download',
  OpenLink = 'Open Link'
}

// Used in Document form to toggle between URL only or File upload modes
export enum DocumentCreateMode {
  url = 'url',
  file = 'file'
}

export class DocumentFormGroup {
  documentType = new FormControl<string>('');
  fileUrl = new FormControl<string>('');
  title = new FormControl<string>('', [Validators.required, Validators.maxLength(250)]);
  storeType = new FormControl<string>('');
  distributionCenterNumber = new FormControl<string>('');
  department = new FormControl<string>('');

  // Start / End of document availability
  startDate = new FormControl<Date>(null);
  expireDate = new FormControl<Date>(null);
}

export function getCommonDocumentForm(): FormGroup<DocumentFormGroup> {
  return new FormGroup(new DocumentFormGroup());
}

function clearValidatorsAndReset(ctrl: FormControl): void {
  if (ctrl) {
    ctrl.reset();
    ctrl.clearValidators();
  }
}

function makeRequired(ctrl: FormControl): void {
  ctrl.addValidators([Validators.required]);
}

export function isFormValid(status: FormControlStatus): boolean {
  return status === 'VALID';
}

// Validation which runs when DocumentCreateMode and/or documentType changes
export function runDocumentFormValidations(
  editMode: DocumentCreateMode,
  docType: string,
  formGroup: FormGroup<DocumentFormGroup>
): void {

  let addValidationList: FormControl[] = [];
  let removeValidationList: FormControl[] = [];

  const fileUrlControlRef = formGroup.controls.fileUrl,
    departmentCtrlRef = formGroup.controls.department,
    dcCtrlRef = formGroup.controls.distributionCenterNumber,
    startCtrlRef = formGroup.controls.startDate,
    expCtrlRef = formGroup.controls.expireDate,
    storeTypeCtrlRef = formGroup.controls.storeType;

  // fileUrl is only required when in URL mode
  // otherwise url comes from the selected file after upload
  if (editMode === DocumentCreateMode.url) {
    fileUrlControlRef.setValidators([
      Validators.required,
      Validators.maxLength(2083)
    ]);
  } else {
    // otherwise, the control is hidden and not-required
    // so as to not affect the validity of the entire form
    removeValidationList.push(fileUrlControlRef);
  }

  // There are different rules if isSproutsOrderGuide===true
  const isSprouts = isSproutsOrderGuide(docType);

  if (isSprouts) {

    addValidationList.push(departmentCtrlRef);

    removeValidationList = [
      ... removeValidationList,
      storeTypeCtrlRef,
      dcCtrlRef,
      startCtrlRef,
      expCtrlRef
    ];

  } else {

    removeValidationList.push(departmentCtrlRef);

    addValidationList = [
      ... addValidationList,
      storeTypeCtrlRef,
      dcCtrlRef,
      startCtrlRef,
      expCtrlRef
    ];
  }

  // add/remove the validators based on the logic
  addValidationList.forEach(ctrl => makeRequired(ctrl));
  removeValidationList.forEach(ctrl => clearValidatorsAndReset(ctrl));

  // update all affected controls
  [
    ... addValidationList,
    ... removeValidationList
  ].forEach(ctrl => ctrl.updateValueAndValidity());

}

export function processFileName(filename: string): string {
  if (filename) {
    const extRegex = /\.[0-9a-z]+$/gi;
    const extension: string = extRegex.exec(filename)[0];
    return filename.length >= 32 ? filename.slice(0, 30) + '...' + extension : filename;
  }
  return '';
}

export function formatByteSize(bytes: number, decimals: number): string {
  if (!bytes) {
    return '--';
  }
  const k = 1024;
  const dc = decimals <= 0 ? 0 : decimals || 2;
  const bsz = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dc))} ${bsz[i]}`;
}