import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DropdownOption } from './../../../../models/dropdown-option';
import {
  addSupplierContact,
  clearKeHETransitTimesWarning,
  clearSupplierContactForm,
  clearValidatingPickupAddress,
  showKeHETransitTimesWarning,
} from '../../../supplier-detail/store/supplier-detail.actions';
import { select, Store } from '@ngrx/store';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import {
  getPickupAddressValidationInProgress,
  selectDeliveryMethodList,
  selectIsSavingContact,
  selectNewlyAddedContact,
  selectSaveContactError,
  selectSupplierDetail,
} from '../../../supplier-detail/store/supplier-detail.selectors';
import { Destroyable } from '../../../../abstract/destroyable';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AddressComponent } from '@kehe/phoenix-address';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { LeadTimes } from '../../../supplier-detail/models/lead-times';
import { SupplierDetailService } from '../../../supplier-detail/services/supplier-detail.service';
import { DCProductGroup } from '../../../supplier-detail/models/dc-product-group';
import { PermissionsService } from '@app/services/permissions.service';
import { Constants } from '../../../../constants/constants';
import { defaultAddress } from '../../../../utils/address-utils';
import { prepPhone } from '../../../../utils/common-utils';
import { DcpgFormService } from '../../../supplier-detail/services/dcpg-form.service';
import { SupplierDetail } from '../../../supplier-detail/models/supplier-detail';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { Contact, ContactType } from '../../../../models/contact';
import { isDraft, isInvitedDraftSupplier } from '../../../supplier-detail/utilities/supplier-utils';
import { optionalPickupAddressFieldsProperties } from '../../utils/delivered-delivery-method-util';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { InboundRouteType } from '../../../../models/dcpg-inbound-route-type';
import { ProductGroupActions } from '../../models/product-group-actions';
import { DeliveryMethodCodes } from '../../models/delivery-method-codes';
import { FeatureFlags } from '@app/feature-flag';
import { DeliveryMethod } from '@app/modules/supplier-detail/models/purchasing-options/delivery-metho.enum';

@Component({
  selector: 'app-product-group-delivery-form',
  templateUrl: './product-group-delivery-form.component.html',
  styleUrls: ['./product-group-delivery-form.component.scss'],
})
export class ProductGroupDeliveryFormComponent
  extends Destroyable
  implements OnInit
{
  contactType = ContactType;
  alertTypes = AlertTypes;

  readonly mask = Constants.ExtensionTextMask;
  keHETransitTimeTooltip =
    'The KeHE Transit Time has been converted to Calendar Days from Business Days.';
  @ViewChild('addressPickupComponent', { static: true })
  addressFormPickup: AddressComponent;
  validateAddressOnSave: Subject<void> = new Subject<void>();
  deliveryMethodList: DropdownOption[];
  inboundRouteTypes: { name: string; type: InboundRouteType }[] =
    Constants.InboundRouteTypes;

  form: UntypedFormGroup;
  smartyStreetsKey = environment.smartyStreetsKey;

  @Input()
  address;

  @Input()
  showContactCard = false;

  @Input()
  action: ProductGroupActions;

  @Input()
  supplier: SupplierDetail;

  @Output()
  pickupAddressSelected: EventEmitter<any> = new EventEmitter();

  private dcNumber: string = null;
  private keheTransitTimesAPI: Subscription;

  dcProductGroup: DCProductGroup = null;
  showTransitTimeInitWarning = false;
  keHETransitTimeNetworkError = null;
  maxLength = 30;
  pickupAddressNotesCount = 0;
  pickupAddressNotesCounter = `${this.pickupAddressNotesCount}/${this.maxLength}`;
  pickupAddressNotes = '';
  dcProductGroupNumber = '';
  canUserEditLeadTimes = false;
  canUserEditDeliveryMethod = false;

  saving$ = this._store.select(selectIsSavingContact);
  error$ = this._store.select(selectSaveContactError);

  deliveryAddressFeatureFlag: boolean;
  deliveryAddressFeatureFlag$ = this._store.select(
    selectIsFeatureFlagOn(FeatureFlags.ProductGroupDeliveryAddress.key)
  );

  isDraftSupplier: boolean;
  isDraftSupplier$ = this._store
    .select(selectSupplierDetail)
    .pipe(map((supplier) => isDraft(supplier)));

  isInvitedDraftSupplier: boolean;
  isInvitedDraftSupplier$ = this._store
      .select(selectSupplierDetail)
      .pipe(map((supplier) => isInvitedDraftSupplier(supplier)));

  get isZipTransitTime(): boolean {
    return this.isAddDCPGAction || this.dcProductGroup?.isZipTransitTime;
  }

  get isCloneDCPGAction(): boolean {
    return this.action === ProductGroupActions.CloneDCPG;
  }

  get isEditDCPGAction(): boolean {
    return this.action === ProductGroupActions.EditDCPG;
  }

  get isAddDCPGAction(): boolean {
    return this.action === ProductGroupActions.AddDCPG;
  }

  get showKeHETransitTimeWarning(): boolean {
    if (this.pickUpTransitTimeField?.errors?.pattern) {
      return false;
    }
    return (
      this.hasTransitTimeOverriddenWarning ||
      this.pickUpTransitTimeDefaultField.value
    );
  }

  get hasTransitTimeOverriddenWarning(): boolean {
    return this.isTransitTimeOverridden && this.pickUpTransitTimeField.value;
  }

  get isTransitTimeOverridden() {
    return this.isTransitTimeOverriddenField.value;
  }

  get isFormReady() {
    return this.deliveryMethodList && this.deliveryMethodList.length > 0;
  }

  get hasDeliveryMethod() {
    return this.deliveryMethodField?.value;
  }

  get deliveryMethodCodeField() {
    return this.form.get('deliveryMethodCode');
  }

  get inboundRouteTypeField() {
    return this.form.get('inboundRouteType');
  }

  get pickupAddressStateField() {
    return this.form.get('pickup.pickupAddress.state');
  }

  get pickupAddressZipCodeField() {
    return this.form.get('pickup.pickupAddress.zipCode');
  }

  get pickupAddressStreet2Field() {
    return this.form.get('pickup.pickupAddress.street2');
  }

  get pickupAddressIdField() {
    return this.form.get('pickup.pickupAddressId');
  }

  get deliveryMethodField() {
    return this.form.get('deliveryMethod');
  }

  get isDelivered(): boolean {
    return this.deliveryMethodField.value === 'Delivered';
  }

  get isPickup(): boolean {
    return (
      this.deliveryMethodCodeField.value &&
      this.deliveryMethodCodeField.value !== DeliveryMethodCodes.Delivered
    );
  }

  get pickupEmail() {
    return this.form.get('pickup.pickupEmail');
  }

  get showOnlyTotalLeadTime() {
    return this.isDelivered && !this.readyTimeAvailable;
  }

  get readyTimeAvailableField() {
    return this.form.get('readyTimeAvailable');
  }

  get readyTimeAvailable(): boolean {
    return this.readyTimeAvailableField.value;
  }

  get deliveredReadyTimeField() {
    return this.form.get('deliveredLeadTimes.readyTime');
  }

  get deliveredTransitTimeField() {
    return this.form.get('deliveredLeadTimes.transitTime');
  }

  get deliveredTotalTimeField() {
    return this.form.get('deliveredLeadTimes.totalTime');
  }

  get deliveredTransitTimeDefaultField() {
    return this.form.get('deliveredLeadTimes.isTransitTimeDefault');
  }

  get pickUpReadyTimeField() {
    return this.form.get('pickupLeadTimes.readyTime');
  }

  get pickUpTransitTimeField() {
    return this.form.get('pickupLeadTimes.transitTime');
  }

  get pickUpTotalTimeField() {
    return this.form.get('pickupLeadTimes.totalTime');
  }

  get pickUpTransitTimeDefaultField() {
    return this.form.get('pickupLeadTimes.isTransitTimeDefault');
  }

  get isTransitTimeOverriddenField() {
    return this.form.get('pickupLeadTimes.isTransitTimeOverridden');
  }

  get deliveredLeadTimesFormGroup(): UntypedFormGroup {
    return this.form.get('deliveredLeadTimes') as UntypedFormGroup;
  }

  get pickupLeadTimesFormGroup(): UntypedFormGroup {
    return this.form.get('pickupLeadTimes') as UntypedFormGroup;
  }

  get pickupAddressFormGroup(): UntypedFormGroup {
    return this.form.get('pickup.pickupAddress') as UntypedFormGroup;
  }

  get pickupAddressNotesFormGroup() {
    return this.form.get('pickup.pickupAddressNotes') as UntypedFormGroup;
  }

  get pickupPhoneField() {
    return this.form.get('pickup.pickupPhone');
  }

  get pickupPhoneExtensionField() {
    return this.form.get('pickup.pickupExtension');
  }

  get pickupFaxField() {
    return this.form.get('pickup.pickupFax');
  }

  get pickupEmailField() {
    return this.form.get('pickup.pickupEmail');
  }

  get hasValidPickupAddressForTransitTimeCalculation() {
    return this.isZipTransitTime ? this.hasValidPickupZipCode : this.hasValidPickupState;
  }

  get hasValidPickupState() {
    if (this.showContactCard) {
      const pickupAddressId = this.pickupAddressIdField.value;
      return pickupAddressId && pickupAddressId.length > 3;
    } else {
      const stateValue = this.pickupAddressStateField.value;
      return stateValue?.code?.length > 0;
    }
  }

  get hasValidPickupZipCode() {
    if (this.showContactCard) {
      const pickupAddressId = this.pickupAddressIdField.value;
      return pickupAddressId && pickupAddressId.length > 3;
    } else {
      const zipCode = this.pickupAddressZipCodeField.value;
      return zipCode?.length > 0;
    }
  }

  get pickupAddressContact(): Contact {
    return this.findPickupAddressContact(this.pickupAddressIdField.value);
  }

  constructor(
    private _store: Store,
    private _supplierDetailService: SupplierDetailService,
    private _permissionsService: PermissionsService,
    private _dcpgFormService: DcpgFormService
  ) {
    super();
  }

  ngOnInit() {
    this.setUserPermissions();
    this.initializeForm();
    this._store
      .pipe(takeUntil(this.destroy$), select(selectDeliveryMethodList))
      .subscribe((response) => {
        this.deliveryMethodList = response;
      });
    this._store
      .pipe(
        select(getPickupAddressValidationInProgress),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (res) {
          this.validateAddressOnSave.next();
        }
      });
    this._store
      .pipe(select(selectNewlyAddedContact), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value !== null) {
          this.onSelectPickupAddress(value);
        }
      });
    this.deliveryAddressFeatureFlag$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (deliveryAddressFeatureFlag) =>
          (this.deliveryAddressFeatureFlag = deliveryAddressFeatureFlag)
      );
    this.isDraftSupplier$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isDraftSupplier) => (this.isDraftSupplier = isDraftSupplier));
    this.isInvitedDraftSupplier$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isInvitedDraftSupplier) => (this.isInvitedDraftSupplier = isInvitedDraftSupplier));
  }

  initializeForm() {
    this.setUserPermissions();
    this.form = this._dcpgFormService.buildDeliveryFormGroup(
      this.addressFormPickup.createAddressFormGroup()
    );
    if (this.showContactCard) {
      this.disableOldPickupFieldStatus();
    } else {
      this.pickupAddressIdField.disable();
    }
    return this.form;
  }

  // ----------------------------------
  // Patch Forms
  // ----------------------------------
  patchForm(dcProductGroup: DCProductGroup): void {
    this.dcProductGroup = { ...dcProductGroup };

    // first patch with a default address
    this.pickupAddressFormGroup.patchValue(defaultAddress());

    if (dcProductGroup) {
      this.dcNumber = dcProductGroup?.distributionCenterNumber;
      this.patchDCProductGroupForm();
    }
    this.redrawDeliveryFormFields();
    this.redrawPickupAddressFormFields();
    this.redrawLeadTimesFormFields();
    this.redrawInboundRouteType();
    this.listenFormControlChanges();

    if (this.isZipTransitTime) {
      this.validateKeHEZipTransitTime();
    } else {
      this.validateKeHETransitTime();
    }
  }

  private patchDCProductGroupForm() {
    this.deliveryMethodCodeField.setValue(
      this.dcProductGroup?.deliveryMethodCode
    );
    this.deliveryMethodField.setValue(this.dcProductGroup?.deliveryMethod);

    this.inboundRouteTypeField.setValue(this.dcProductGroup?.inboundRouteType);

    this.deliveredLeadTimesFormGroup.setValue(
      this.getLeadTimesFormValue(this.dcProductGroup?.deliveredLeadTimes)
    );
    this.pickupLeadTimesFormGroup.setValue(
      this.getLeadTimesFormValue(this.dcProductGroup?.pickupLeadTimes)
    );

    if (this.isDelivered) {
      const leadTimes = this.dcProductGroup?.deliveredLeadTimes;
      if (leadTimes?.readyTime && leadTimes?.transitTime) {
        this.readyTimeAvailableField.setValue(
          leadTimes?.readyTime > 0 && leadTimes?.transitTime > 0
        );
      } else {
        this.readyTimeAvailableField.setValue(false);
      }
    } else {
      this.readyTimeAvailableField.setValue(true);
    }
    this.patchPickupAddress(this.dcProductGroup);
  }

  private patchPickupAddress(dcProductGroup) {
    if (dcProductGroup?.pickupAddress) {
      this.pickupAddressFormGroup.patchValue(dcProductGroup?.pickupAddress);
      this.pickupPhoneField.setValue(
        prepPhone(dcProductGroup?.pickupAddress.phone || '')
      );
      this.pickupPhoneExtensionField.setValue(
        dcProductGroup?.pickupAddress.extension || ''
      );
      this.pickupFaxField.setValue(
        prepPhone(dcProductGroup?.pickupAddress.fax || '')
      );
      this.pickupEmailField.setValue(dcProductGroup?.pickupAddress.email || '');
      this.pickupAddressNotesFormGroup.setValue(
        dcProductGroup?.pickupAddressNotes || ''
      );
      this.pickupAddressNotesCount =
        dcProductGroup?.pickupAddressNotes?.length || 0;
      this.updateCounter();
    } else {
      this.pickupAddressFormGroup.patchValue(defaultAddress());
      this.pickupPhoneField.setValue('');
      this.pickupPhoneExtensionField.setValue('');
      this.pickupFaxField.setValue('');
      this.pickupEmail.setValue('');
    }
    this.pickupAddressIdField.patchValue(dcProductGroup?.pickupAddressId);
  }

  // ----------------------------------
  // values Changed Listeners
  // ----------------------------------
  dcValueChanged(dcNumber) {
    this.dcNumber = dcNumber;
    this.notifyTransitTimeChange();
  }

  listenFormControlChanges() {
    this.listenLeadTimes();

    this.deliveryMethodField.valueChanges.subscribe((value) => {

      const isIBRTypeEmpty = !this.inboundRouteTypeField.value || this.inboundRouteTypeField.value === InboundRouteType.None;
      if (this.canShowInboundRouteType() && isIBRTypeEmpty) {
        this.inboundRouteTypeField.setValue(InboundRouteType.LTLDry, {
          emitEvent: false,
        });
      } else {
        this.inboundRouteTypeField.setValue(InboundRouteType.None, {
          emitEvent: false,
        });
      }

      if (value !== DeliveryMethod.Delivered && !this.isDraftSupplier && !this.isAddDCPGAction) {
        const ibrValue = (!this.dcProductGroup.inboundRouteType || this.dcProductGroup.inboundRouteType === InboundRouteType.None)
        ? InboundRouteType.LTLDry
        : this.dcProductGroup.inboundRouteType;

        this.dcProductGroup.isZipTransitTime = true
        this.inboundRouteTypeField.setValue(ibrValue, {
          emitEvent: false,
        });
      }

      this.inboundRouteTypeField.markAllAsTouched();
      this.inboundRouteTypeField.markAsDirty();
      this.redrawInboundRouteType();
      this.redrawPickupAddressFormFields();
      this.redrawLeadTimesFormFields();
      this.notifyTransitTimeChange();
    });

    this.isTransitTimeOverriddenField.valueChanges.subscribe((value) => {
      this.redrawLeadTimesFormFields();
      if (value) {
        this.pickUpTransitTimeDefaultField.setValue(false);
        this.pickUpTransitTimeField.setValue(null);
        this.pickUpTotalTimeField.setValue(null);
      } else {
        this.pickUpTransitTimeField.markAsUntouched();
        this.pickUpTransitTimeField.setErrors(null);
        this.notifyTransitTimeChange();
      }
    });

    this.inboundRouteTypeField.valueChanges.subscribe(() => {
      if (this.isZipTransitTime) {
        this.notifyTransitTimeChange();
      }
    });

    this.pickupAddressZipCodeField.valueChanges.pipe(debounceTime(2000)).subscribe(() => {
      if (this.isZipTransitTime) {
        this.redrawLeadTimesFormFields();
        this.notifyTransitTimeChange();
      }
    });
    this.pickupAddressStateField.valueChanges.subscribe(() => {
      if (!this.isZipTransitTime) {
        this.redrawLeadTimesFormFields();
        this.notifyTransitTimeChange();
      }
    });

    this.pickupAddressIdField.valueChanges.subscribe(() => {
      this.redrawLeadTimesFormFields();
      this.notifyTransitTimeChange();
    });
  }

  listenLeadTimes() {
    this.readyTimeAvailableField.valueChanges.subscribe(() => {
      this.redrawLeadTimesFormFields();
    });
    this.deliveredReadyTimeField.valueChanges.subscribe(() => {
      this.calculateLeadTimes();
    });
    this.deliveredTransitTimeField.valueChanges.subscribe(() => {
      this.calculateLeadTimes();
    });
    this.pickUpReadyTimeField.valueChanges.subscribe(() => {
      this.calculateLeadTimes();
    });
    this.pickUpTransitTimeField.valueChanges.subscribe(() => {
      this.calculateLeadTimes();
    });
  }

  deliveryMethodValueChange(value: any): void {
    if (value) {
      const deliveryMethod = this.deliveryMethodList.find(
        (item) => item.value === value
      );
      this.deliveryMethodField.patchValue(deliveryMethod.label);
      if (deliveryMethod.value !== DeliveryMethodCodes.Delivered) {
        this.readyTimeAvailableField.setValue(true);
      }
    } else {
      this.deliveryMethodField.patchValue(null);
    }
  }

  onPickupAddressNotesValueChange(e: any) {
    this.pickupAddressNotesCount = e.length;
    this.pickupAddressNotesCounter = `${this.pickupAddressNotesCount}/${this.maxLength}`;
    this.pickupAddressNotesFormGroup.setValue(e);
  }

  // ----------------------------------
  // Transit Time Calculations
  // ----------------------------------
  private validateKeHETransitTime() {
    const transitTime = this.pickUpTransitTimeField.value;
    const pickupAddressState = this.pickupAddressStateField.value;
    const pickupAddressId = this.pickupAddressIdField.value;

    let stateCode = this.getStateCode(pickupAddressId, pickupAddressState);

    if (!transitTime && stateCode && !this.isDelivered) {
      this.showTransitTimeInitWarning = true;
      this._store.dispatch(showKeHETransitTimesWarning());
      this.updateKeHETransitTime(true);
    }
  }

  private validateKeHEZipTransitTime() {
    const transitTime = this.pickUpTransitTimeField.value;
    const pickupAddressZipCode = this.pickupAddressZipCodeField.value;
    const pickupAddressId = this.pickupAddressIdField.value;
    const inboundRouteType = this.inboundRouteTypeField.value;

    let zipCode = this.getZipCode(pickupAddressId, pickupAddressZipCode);

    if (!transitTime && zipCode && inboundRouteType && !this.isDelivered) {
      this.showTransitTimeInitWarning = true;
      this._store.dispatch(showKeHETransitTimesWarning());
      this.updateKeHEZipTransitTime(true);
    }
  }

  private notifyTransitTimeChange() {
    // If clone no need to calculate transit time
    if (this.isCloneDCPGAction) {
      return;
    }
    this.clearTransitTime();
    this._store.dispatch(clearKeHETransitTimesWarning());
    if (this.isZipTransitTime) {
      this.updateKeHEZipTransitTime(false);
    } else {
      this.updateKeHETransitTime(false);
    }
  }

  private updateKeHETransitTime(isInitialValidation: boolean) {
    const deliveryMethodCode = this.deliveryMethodCodeField.value;
    const isTransitTimeOverridden = this.isTransitTimeOverriddenField.value;
    const pickupAddressState = this.pickupAddressStateField.value;
    const pickupAddressId = this.pickupAddressIdField.value;

    if (!this.dcNumber || this.dcNumber.length === 0) {
      return;
    }
    if (
      !deliveryMethodCode ||
      deliveryMethodCode === DeliveryMethodCodes.Delivered
    ) {
      return;
    }
    if (isTransitTimeOverridden) {
      return;
    }
    let stateCode = this.getStateCode(pickupAddressId, pickupAddressState);
    if (!stateCode) {
      return;
    }

    if (this.keheTransitTimesAPI) {
      this.keheTransitTimesAPI.unsubscribe();
    }

    this.keheTransitTimesAPI = this._supplierDetailService
      .getKeheTransitTimes(this.dcNumber, stateCode, deliveryMethodCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) =>
          this.onTransitTimeSuccess(response.data, isInitialValidation),
        (e) => this.onTransitTimeError(e)
      );
  }

  private updateKeHEZipTransitTime(isInitialValidation: boolean) {
    const deliveryMethodCode = this.deliveryMethodCodeField.value;
    const isTransitTimeOverridden = this.isTransitTimeOverriddenField.value;
    const pickupAddressZipCode = this.pickupAddressZipCodeField.value;
    const pickupAddressId = this.pickupAddressIdField.value;
    const inboundRouteType = this.inboundRouteTypeField.value;

    if (!this.dcNumber || this.dcNumber.length === 0) {
      return;
    }
    if (
      !deliveryMethodCode ||
      deliveryMethodCode === DeliveryMethodCodes.Delivered
    ) {
      return;
    }
    if (!inboundRouteType || inboundRouteType == InboundRouteType.None) {
      return;
    }
    let zipCode = this.getZipCode(pickupAddressId, pickupAddressZipCode);
    if (!zipCode) {
      return;
    }
    if (isTransitTimeOverridden) {
      return;
    }

    if (this.keheTransitTimesAPI) {
      this.keheTransitTimesAPI.unsubscribe();
    }

    this.keheTransitTimesAPI = this._supplierDetailService
      .getKeheZipTransitTimes(this.dcNumber, zipCode, inboundRouteType)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => this.onTransitTimeSuccess(response, isInitialValidation),
        (e) => this.onTransitTimeError(e)
      );
  }

  private onTransitTimeSuccess(data, isInitialValidation) {
    this.keHETransitTimeNetworkError = null;
    if (this.isAddDCPGAction) {
      this.onTransitTimeSuccessAddDCPG(data);
    } else {
      if (isInitialValidation) {
        this.onTransitTimeSuccessInitValidation(data);
      } else {
        this.onTransitTimeSuccessEditDCPG(data);
      }
    }
    this.pickUpTransitTimeField.markAsTouched();
  }

  private onTransitTimeSuccessInitValidation(response) {
    this.pickUpTransitTimeField.setValue(response.transitTime, {
      emitEvent: false,
    });
    this.pickUpTransitTimeField.markAsDirty();
    this.calculateReadyTime();
    this.pickUpTransitTimeDefaultField.setValue(false);
  }

  private onTransitTimeSuccessEditDCPG(response) {
    if (response.isTransitTimeDefault) {
      this.pickUpTransitTimeField.setValue(null);
    } else {
      this.pickUpTransitTimeField.setValue(response.transitTime, {
        emitEvent: false,
      });
      this.calculateLeadTimes();
    }
    this.pickUpTransitTimeDefaultField.setValue(false);
  }

  private onTransitTimeSuccessAddDCPG(response) {
    this.pickUpTransitTimeField.setValue(response.transitTime);
    this.pickUpTransitTimeDefaultField.setValue(response.isTransitTimeDefault);
  }

  private onTransitTimeError(e: any) {
    if (e.status === 400 && e.error) {
      this.keHETransitTimeNetworkError = e.error;
    } else {
      this.keHETransitTimeNetworkError = "Unable to retrieve transit time due to a network issue.";
    }
    this.pickUpTransitTimeField.setValue(null);
    this.pickUpTransitTimeField.markAsTouched();
  }

  private calculateReadyTime() {
    const readyTime = this.pickUpReadyTimeField.value;
    const transitTime = this.pickUpTransitTimeField.value;
    const totalTime = this.pickUpTotalTimeField.value;

    // readyTime is null but transitTime and total time is defined
    if (
      !readyTime &&
      transitTime &&
      transitTime > 0 &&
      totalTime &&
      totalTime > 0
    ) {
      const rTime = +totalTime - +transitTime;
      this.pickUpReadyTimeField.setValue(rTime, { emitEvent: false });
      this.pickUpReadyTimeField.markAsDirty();
    }
  }

  private calculateLeadTimes() {
    if (this.isDelivered) {
      const readyTime = +this.deliveredReadyTimeField.value;
      const transitTime = +this.deliveredTransitTimeField.value;

      if (readyTime && transitTime && transitTime > 0) {
        const totalTime: number = readyTime + transitTime;
        this.deliveredTotalTimeField.setValue(totalTime);
      } else {
        this.deliveredTotalTimeField.reset();
      }
    } else {
      const readyTime = +this.pickUpReadyTimeField.value;
      const transitTime = +this.pickUpTransitTimeField.value;

      // readyTime is not null, has value greater than 0
      if (readyTime && transitTime && transitTime > 0) {
        const tTime: number = readyTime + transitTime;
        this.pickUpTotalTimeField.setValue(tTime);
      }
      // readyTime is null or has value less than or equal to 0 (ie, user is editing.)
      else if (transitTime && transitTime > 0) {
        // We just set totalTime equal to transitTime since the user's probably editing
        this.pickUpTotalTimeField.setValue(transitTime);
      }
      // default - Just reset totalTime
      else {
        this.pickUpTotalTimeField.reset();
      }
    }
    this.pickUpTotalTimeField.markAsDirty();
  }

  // ----------------------------------
  // Redraw Form Controls
  // ----------------------------------
  resetForm() {
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
  }

  private redrawInboundRouteType() {
    const shouldEnable = !this.isDraftSupplier && this.canShowInboundRouteType() && this.isEditDCPGAction;
    if (shouldEnable) {
      this.inboundRouteTypeField.enable();
    } else {
      this.inboundRouteTypeField.disable();
    }
  }

  private redrawDeliveryFormFields() {
    if (this.canUserEditDeliveryMethod) {
      this.deliveryMethodCodeField.enable();
    } else {
      this.deliveryMethodCodeField.disable();
    }
  }

  private redrawPickupAddressFormFields(): void {
    if (
      !this.deliveryMethodField.value ||
      this.isDelivered ||
      !this.canUserEditDeliveryMethod
    ) {
      if (this.isDraftSupplier) {
        this.pickupAddressIdField.enable();
        if (this.isInvitedDraftSupplier) {
          // Add the required validator in case the delivered address has removed it
          this.pickupAddressIdField.addValidators(Validators.required);
        } else {
          // delivered address is optional for add supplier flow
          this.pickupAddressIdField.removeValidators(Validators.required);
        }
        this.disableOldPickupFieldStatus();
      } else if (this.showContactCard) {
        this.pickupAddressIdField.disable();
      } else {
        this.disableOldPickupFieldStatus();
      }
    } else {
      if (this.showContactCard) {
        this.pickupAddressIdField.enable();
        // Add the required validator in case the delivered address has removed it
        this.pickupAddressIdField.addValidators(Validators.required);
      } else {
        this.enableOldPickupFieldStatus();
        this.setPickupFieldsRequired();
        this.pickupAddressIdField.disable();
      }
    }

    // Used for delivered address form
    if (
      this.isDelivered &&
      this.deliveryAddressFeatureFlag &&
      !this.isDraftSupplier
    ) {
      // If we showContactCard then we need to reset the pickupAddressId remove the validator
      // we also need to enable it so that in case we need to remove the value if it was previously entered
      if (this.showContactCard) {
        this.pickupAddressIdField.removeValidators(Validators.required);
        this.pickupAddressIdField.reset();
        this.pickupAddressIdField.enable();
      }
      this.enableOldPickupFieldStatus();
      this.setPickupFieldsOptional();
    }
    this.pickupAddressStreet2Field.disable();
  }

  private setPickupFieldsOptional() {
    const pickupAddress = this.form?.get('pickup');
    optionalPickupAddressFieldsProperties.forEach((element) => {
      if (pickupAddress) {
        const control = pickupAddress.get(element) as UntypedFormControl;
        control?.removeValidators(Validators.required);
        control?.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  private setPickupFieldsRequired() {
    const pickupAddress = this.form?.get('pickup');
    optionalPickupAddressFieldsProperties.forEach((element) => {
      if (pickupAddress) {
        const control = pickupAddress.get(element) as UntypedFormControl;
        control?.addValidators(Validators.required);
        control?.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  private enableOldPickupFieldStatus() {
    this.pickupAddressFormGroup.enable();
    this.pickupPhoneField.enable();
    this.pickupPhoneExtensionField.enable();
    this.pickupFaxField.enable();
    this.pickupEmailField.enable();
    this.pickupAddressNotesFormGroup.enable();
  }

  private disableOldPickupFieldStatus() {
    this.pickupAddressFormGroup.disable();
    this.pickupPhoneField.disable();
    this.pickupPhoneExtensionField.disable();
    this.pickupFaxField.disable();
    this.pickupEmailField.disable();
    this.pickupAddressNotesFormGroup.disable();
  }

  private clearTransitTime() {
    this.pickUpTransitTimeField.setValue(null, { emitEvent: false });
    this.pickUpTotalTimeField.setValue(null, { emitEvent: false });
    this.pickUpTransitTimeDefaultField.setValue(false, { emitEvent: false });
    this.isTransitTimeOverriddenField.setValue(false, { emitEvent: false });
    this.pickUpTransitTimeField.markAsUntouched();
  }

  private redrawLeadTimesFormFields(): void {
    if (
      !this.form ||
      !this.deliveryMethodField.value ||
      !this.canUserEditLeadTimes
    ) {
      this.pickupLeadTimesFormGroup.disable({ emitEvent: false });
      this.deliveredLeadTimesFormGroup.disable({ emitEvent: false });
      return;
    }

    this.pickupLeadTimesFormGroup.markAsPristine();
    this.pickupLeadTimesFormGroup.markAsUntouched();
    this.pickupLeadTimesFormGroup.updateValueAndValidity({ emitEvent: false });

    this.deliveredLeadTimesFormGroup.markAsPristine();
    this.deliveredLeadTimesFormGroup.markAsUntouched();
    this.deliveredLeadTimesFormGroup.updateValueAndValidity({
      emitEvent: false,
    });

    if (this.isDelivered) {
      this.pickupLeadTimesFormGroup.disable({ emitEvent: false });
      this.deliveredLeadTimesFormGroup.enable({ emitEvent: false });
      if (this.readyTimeAvailable) {
        this.deliveredReadyTimeField.enable({ emitEvent: false });
        this.deliveredTransitTimeField.enable({ emitEvent: false });
        this.deliveredTransitTimeDefaultField.enable({ emitEvent: false });
      } else {
        this.deliveredTotalTimeField.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(999),
        ]);
        this.deliveredReadyTimeField.disable({ emitEvent: false });
        this.deliveredTransitTimeField.disable({ emitEvent: false });
        this.deliveredTransitTimeDefaultField.disable({ emitEvent: false });
      }
      this.deliveredTotalTimeField.updateValueAndValidity({ emitEvent: false });
    } else {
      // If zip transit time ON then should have a valid zip code
      // If zip transit time OFF then should have a valid state code
      if (this.hasValidPickupAddressForTransitTimeCalculation) {
        this.pickupLeadTimesFormGroup.enable({ emitEvent: false });
        if (this.isCloneDCPGAction) {
          this.pickUpTransitTimeField.disable({ emitEvent: false });
          this.pickUpTotalTimeField.disable({ emitEvent: false });
          this.pickUpTransitTimeDefaultField.disable({ emitEvent: false });
        }
      } else {
        this.pickupLeadTimesFormGroup.disable({ emitEvent: false });
      }
      this.deliveredLeadTimesFormGroup.disable({ emitEvent: false });
    }
  }

  // ----------------------------------
  // Utils Functions
  // ----------------------------------
  private updateCounter() {
    this.pickupAddressNotesCounter = `${this.pickupAddressNotesCount}/${this.maxLength}`;
  }

  private getLeadTimesFormValue(leadTimes: LeadTimes) {
    return {
      readyTime: leadTimes.readyTime || '',
      transitTime: leadTimes.transitTime || '',
      totalTime: leadTimes.totalTime || '',
      isTransitTimeDefault: leadTimes.isTransitTimeDefault,
      isTransitTimeOverridden: leadTimes.isTransitTimeOverridden,
    };
  }

  private setUserPermissions() {
    this.canUserEditDeliveryMethod = this._permissionsService.userHasAction(
      Constants.UserActions.SupplierDcProductGroupDeliveryMethodUpdate
    );
    this.canUserEditLeadTimes = this._permissionsService.userHasAction(
      Constants.UserActions.SupplierDcProductGroupLeadTimeUpdate
    );
  }

  private getStateCode(pickupAddressId, pickupAddressState) {
    if (this.showContactCard) {
      if (pickupAddressId) {
        const pickupContact = this.findPickupAddressContact(pickupAddressId);
        if (pickupContact) {
          return pickupContact.address.state.code;
        }
      }
    } else {
      if (pickupAddressState?.code) {
        return pickupAddressState.code;
      }
    }
    return null;
  }

  private getZipCode(pickupAddressId: string, pickupAddressZipCode: string) {
    if (this.showContactCard) {
      if (pickupAddressId) {
        const pickupContact = this.findPickupAddressContact(pickupAddressId);
        if (pickupContact) {
          return pickupContact.address.zipCode;
        }
      }
    } else {
      if (pickupAddressZipCode) {
        return pickupAddressZipCode;
      }
    }
    return null;
  }

  canShowInboundRouteType() {
    return (this.isZipTransitTime && this.isPickup);
  }

  isInboundRouteTypeReadonly() {
    return this.inboundRouteTypeField.disabled;
  }

  private findPickupAddressContact(uniqueId: string) {
    if (this.supplier?.contacts) {
      return this.supplier.contacts.find((item) => item.uniqueId === uniqueId);
    }
    return null;
  }

  // ----------------------------------
  // Address Component Functions
  // ----------------------------------
  onNoAddressSelected() {
    this._store.dispatch(clearValidatingPickupAddress());
  }

  onAddressSelected(selectedAddress) {
    this.pickupAddressFormGroup.patchValue(selectedAddress.address, {
      emitEvent: false,
    });
    this._store.dispatch(clearValidatingPickupAddress());
    if (this.pickupAddressSelected) {
      this.pickupAddressSelected.emit(selectedAddress);
    }
  }

  // ----------------------------------
  // New Contact Card for PickupAddress
  // ----------------------------------
  onSelectPickupAddress(contact: Contact) {
    this.pickupAddressIdField.markAsDirty();
    this.pickupAddressIdField.markAsTouched();
    this.pickupAddressIdField.setValue(contact.uniqueId);
  }

  onSmartyStreetsUseAddress(contact: Contact) {
    this._store.dispatch(addSupplierContact({ contact }));
  }

  onSmartyStreetsEditAddress() {
    this._store.dispatch(clearSupplierContactForm());
  }

  onClosePickupAddressModal() {
    this._store.dispatch(clearSupplierContactForm());
  }

  onRemovePickupAddress() {
    this.pickupAddressIdField.markAsDirty();
    this.pickupAddressIdField.markAsTouched();
    this.pickupAddressIdField.setValue(null);
  }
}
