import {
  Component,
  Output,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { ProgressTrackerStepStates } from '@kehe/phoenix-progress-tracker';
import { Subject } from 'rxjs';
import { AlertTypes } from '../../../../models/alert.interface';
import { Store } from '@ngrx/store';
import { loadBuyerList } from '../../../supplier-detail/store/supplier-detail.actions';
import { BulkEditFormsService } from '../../services/bulk-edit-forms.service';
import { selectBuyerList } from '../../../supplier-detail/store/supplier-detail.selectors';
import { Destroyable } from '../../../../abstract/destroyable';
import { Constants } from '../../../../constants/constants';
import { DropdownOption } from '../../../../models/dropdown-option';
import { BulkEditPages } from '../../models/bulk-edit-pages';
import { FormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DCPGEditBulkActions } from '../../models/dcpg-edit-bulk-actions';
import { DeliveryMethodCodes } from '../../models/delivery-method-codes';
import {
  Address,
  AddressService,
  AddressValidationResult,
} from '@kehe/phoenix-address';
import { environment } from '../../../../../environments/environment';
import { Contact, ContactType } from '../../../../models/contact';
import { isAnyControlTouchedAndInvalid } from '../../../../utils/form-utils';
import {
  defaultAddress,
  defaultCountry,
} from '../../../../utils/address-utils';
import { cantRemovePOEmail, hasExistingPOEmail } from '../../../../utils/dcpg-utils';
import { DCPGListItem } from '../../../supplier-dcpgs/models/dcpg-list-item';
import { InboundRouteType } from '../../../../models/dcpg-inbound-route-type';
import * as uuid from 'uuid';
import { DeliveryMethod } from '@app/modules/supplier-detail/models/purchasing-options/delivery-metho.enum';

@Component({
  selector: 'app-bulk-edit-dcpg-modal',
  templateUrl: './bulk-edit-dcpg-modal.component.html',
  styleUrls: ['./bulk-edit-dcpg-modal.component.scss'],
})
export class BulkEditDcpgModalComponent extends Destroyable implements OnChanges
{
  bulkEditPages = BulkEditPages;

  readonly contactTypes = ContactType;
  page: BulkEditPages = BulkEditPages.SelectTypePage;
  inboundRouteTypes: { name: string; type: InboundRouteType }[] =
    Constants.InboundRouteTypes;

  addressForm: Address;
  validationResponse: AddressValidationResult;
  editedDCProductGroups = [];

  form: UntypedFormGroup = this._bulkEditFormService.buildActionSelectionForm((form) =>
    this.validateSelectedDCs(form)
  );

  @Input()
  loading = false;

  @Input()
  error: string;

  @Input()
  dcList: Array<any> = [];

  @Input()
  productGroupNumber: number;

  @Input()
  initialSelectedDCPGs: Array<{
    label: string;
    value: string;
  }> = [];

  @Output() closeModal = new Subject<any>();

  @Output() applyClicked = new Subject<any>();
  @Output() applyClickedAddContact = new Subject<{contact: Contact, contactType: ContactType, bulkEditPayload: any}>();

  @Input() isEditPOEmail: boolean = false;

  alertTypes = AlertTypes;

  deliveryMethods: DropdownOption[] = Constants.DeliveryMethods;
  pOOrderTypes: string[] = Constants.POTransTypes;
  warningBannerMessage = null;
  poEmailWarnings = [];

  buyerList$ = this._store.select(selectBuyerList);

  get detailsForm(): UntypedFormGroup {
    return this.form.get('details') as UntypedFormGroup;
  }

  get notesForm() {
    return this.detailsForm?.get('notes') as UntypedFormGroup;
  }

  get steps() {
    return [
      {
        state: this.typeProgressState,
        name: 'Select Type',
      },
      {
        state: this.dcpgDetailsProgressState,
        name: 'DCPG Details',
      },
    ];
  }

  get bulkActionField() {
    return this.form?.get('bulkAction');
  }

  get typeProgressState() {
    const distributionCenters = this.form.get('distributionCenters');

    if (
      this.bulkActionField.valid &&
      distributionCenters.valid &&
      !this.form.hasError('pickupType') &&
      !this.form.hasError('deliveredType')
    ) {
      return ProgressTrackerStepStates.Completed;
    }
    if (this.showErrorAlert) {
      return ProgressTrackerStepStates.Error;
    }
    return ProgressTrackerStepStates.Open;
  }

  get dcpgDetailsProgressState() {
    const details = this.form.get('details');

    if (this.warningBannerMessage) {
      return ProgressTrackerStepStates.Warning;
    }
    if (details && isAnyControlTouchedAndInvalid(details)) {
      return ProgressTrackerStepStates.Error;
    }
    if (details?.valid && this.isLastPage) {
      return ProgressTrackerStepStates.Completed;
    }
    return ProgressTrackerStepStates.Open;
  }

  get showErrorAlert() {
    if (this.isFirstPage) {
      const distributionCenters = this.form.get('distributionCenters');
      return (
        (distributionCenters.touched && distributionCenters.invalid) ||
        (this.bulkActionField.touched && this.bulkActionField.invalid) ||
        this.form.hasError('pickupType') ||
        this.form.hasError('deliveredType')
      );
    } else if (this.isLastPage) {
      return this.error;
    }
    return isAnyControlTouchedAndInvalid(this.form);
  }

  get isFirstPage() {
    return this.page === BulkEditPages.SelectTypePage;
  }

  get isLastPage() {
    return [
      BulkEditPages.POFaxPage,
      BulkEditPages.PickupAddressPage,
      BulkEditPages.LeadTimesPickupAddressPage,
      BulkEditPages.DeliveredLeadTimesPage,
      BulkEditPages.BuyersPage,
      BulkEditPages.DeliveryMethodLeadTimesPage,
      BulkEditPages.ChangePickupTypePage,
      BulkEditPages.PickupLeadTimesPage,
      BulkEditPages.PickupContact,
      BulkEditPages.AddPOEmailPage,
      BulkEditPages.ReplacePOEmailPage,
      BulkEditPages.RemovePOEmailPage,
      BulkEditPages.DeliveredAddressPage
    ].includes(this.page);
  }

  get errorBannerMessage() {
    if (this.form.errors?.pickupType) {
      const list = this.form.errors?.pickupType;
      return (
        'The following DC Product Group(s) do not have Pickup set as Delivery Method.<br/>' +
        '<ul class="pl-3">' +
        list.reduce(
          (a: string, c: { dcDisplayName: any }) =>
            a + `<li>${c.dcDisplayName}</li>`,
          ''
        ) +
        '</ul>'
      );
    }

    if (this.form.errors?.deliveredType) {
      const list = this.form.errors?.deliveredType;
      return (
        'The following DC Product Group(s) do not have Delivered set as Delivery Method.<br/>' +
        '<ul class="pl-3">' +
        list.reduce(
          (a: string, c: { dcDisplayName: any }) =>
            a + `<li>${c.dcDisplayName}</li>`,
          ''
        ) +
        '</ul>'
      );
    }

    if (this.error) {
      return this.error;
    }

    return 'You have fields below that require your attention.';
  }

  get pickupDeliveryMethods() {
    return this.deliveryMethods.filter(
      (d) => d.value !== DeliveryMethodCodes.Delivered
    );
  }

  constructor(
    private readonly _bulkEditFormService: BulkEditFormsService,
    private readonly _store: Store,
    private readonly _builder: UntypedFormBuilder,
    private readonly _addressService: AddressService
  ) {
    super();
    this._store.dispatch(loadBuyerList());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.initialSelectedDCPGs?.firstChange) {
      this.form.patchValue(
        {
          distributionCenters: changes?.initialSelectedDCPGs?.currentValue,
        },
        { emitEvent: false }
      );
    }
  }

  onCancel(event?: any) {
    this.closeModal.next(event);
  }

  onNext() {
    switch (this.page) {
      case BulkEditPages.SelectTypePage: {
        this.onActionTypeSelected();
        break;
      }
      case BulkEditPages.DeliveryMethodPage: {
        this.onDeliveryMethodSelected();
        break;
      }
    }
    // add the notes form if the page is last
    if (this.isLastPage && this.detailsForm) {
      this.detailsForm.addControl(
        'notes',
        this._bulkEditFormService.buildNotesForm()
      );
    }
    this.form.markAsUntouched();
  }

  onActionTypeSelected() {
    switch (this.bulkActionField.value) {
      case DCPGEditBulkActions.DeliveryMethod: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildDeliveryMethodForm()
        );
        this.page = BulkEditPages.DeliveryMethodPage;
        break;
      }
      case DCPGEditBulkActions.PickupAddress: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildPickupAddressForm()
        );
        this.page = BulkEditPages.PickupAddressPage;
        break;
      }
      case DCPGEditBulkActions.DeliveredLeadTimes: {
        this.form.addControl(
          'details',
          this._builder.group({
            delivered: this._bulkEditFormService.buildTotalLeadTimesForm(999),
          })
        );
        this.page = BulkEditPages.DeliveredLeadTimesPage;
        break;
      }
      case DCPGEditBulkActions.POFax: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildPOFaxForm()
        );
        this.page = BulkEditPages.POFaxPage;
        break;
      }
      case DCPGEditBulkActions.Buyers: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildBuyerForm()
        );
        this.page = BulkEditPages.BuyersPage;
        break;
      }
      case DCPGEditBulkActions.ChangeKehePickupType: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildInboundRouteTypeForm()
        );
        this.page = BulkEditPages.ChangePickupTypePage;
        break;
      }
      case DCPGEditBulkActions.PickupLeadTimes: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildTotalLeadTimesForm()
        );
        this.page = BulkEditPages.PickupLeadTimesPage;
        break;
      }
      case DCPGEditBulkActions.PickupContact: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildPickupContactForm()
        );
        this.page = BulkEditPages.PickupContact;
        break;
      }
      case DCPGEditBulkActions.AddPOEmail: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildAddPOEmailForm()
        );
        this.page = BulkEditPages.AddPOEmailPage;
        break;
      }
      case DCPGEditBulkActions.ReplacePOEmail: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildReplacePOEmailForm()
        );
        this.page = BulkEditPages.ReplacePOEmailPage;
        break;
      }
      case DCPGEditBulkActions.RemovePOEmail: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildRemovePOEmailForm()
        );
        this.page = BulkEditPages.RemovePOEmailPage;
        break;
      }
      case DCPGEditBulkActions.DeliveredAddress: {
        this.form.addControl(
          'details',
          this._bulkEditFormService.buildDeliveredAddressForm()
        );
        this.page = BulkEditPages.DeliveredAddressPage;
        break;
      }
    }
  }

  onDeliveryMethodSelected() {
    switch (this.form.get('details.deliveryMethodCode').value) {
      case DeliveryMethodCodes.PickupStackable:
      case DeliveryMethodCodes.PickupNonStackable:
      case DeliveryMethodCodes.FreightServicesProgram: {
        this.detailsForm.addControl(
          'pickup',
          this._bulkEditFormService.buildPickupDeliveryForm(this.isAnyUseZipTransitTime() || this.isAnyDeliveredMethod())
        );
        this.page = BulkEditPages.LeadTimesPickupAddressPage;
        break;
      }
      case DeliveryMethodCodes.Delivered: {
        this.detailsForm.addControl(
          'delivered',
          this._bulkEditFormService.buildDeliveredMethodForm(999)
        );
        this.page = BulkEditPages.DeliveryMethodLeadTimesPage;
        break;
      }
    }
  }

  onBack() {
    if (this.isLastPage && this.notesForm) {
      this.error = null;
      this.detailsForm.removeControl('notes');
    }

    switch (this.page) {
      case BulkEditPages.BuyersPage:
      case BulkEditPages.PickupAddressPage:
      case BulkEditPages.DeliveryMethodPage:
      case BulkEditPages.DeliveredLeadTimesPage:
      case BulkEditPages.ChangePickupTypePage:
      case BulkEditPages.PickupLeadTimesPage:
      case BulkEditPages.POFaxPage:
      case BulkEditPages.PickupContact:
      case BulkEditPages.AddPOEmailPage:
      case BulkEditPages.ReplacePOEmailPage:
      case BulkEditPages.DeliveredAddressPage:
      case BulkEditPages.RemovePOEmailPage: {
        this.form.removeControl('details');
        this.page = BulkEditPages.SelectTypePage;
        break;
      }
      case BulkEditPages.LeadTimesPickupAddressPage: {
        this.detailsForm.removeControl('pickup');
        this.page = BulkEditPages.DeliveryMethodPage;
        break;
      }
      case BulkEditPages.DeliveryMethodLeadTimesPage: {
        this.detailsForm.removeControl('delivered');
        this.page = BulkEditPages.DeliveryMethodPage;
        break;
      }
    }
    this.form.markAsTouched();
  }

  getSelectedDCs() {
    const editedDCIds = this.form?.value.distributionCenters?.map(
      (item) => item.value
    );
    if (!editedDCIds || editedDCIds.length == 0) {
      return [];
    }
    return this.dcList.filter((item) =>
      editedDCIds.includes(item.value)
    );
  }

  isAnyUseZipTransitTime() {
    return this.getSelectedDCs().some(item => item.isZipTransitTime);
  }

  isAnyDeliveredMethod() {
    return this.getSelectedDCs().some(item => item.deliveryMethod === DeliveryMethod.Delivered)
  }

  onApply() {
    const editedDCs = this.getSelectedDCs();
    switch (this.page) {
      case BulkEditPages.PickupAddressPage: {
        const addressBook = this.form.getRawValue().details;
        delete addressBook.contact.name;
        this.editedDCProductGroups = editedDCs.map((dcpg) => {
          return {
            dcProductGroupNumber: dcpg.dcProductGroupNumber,
            distributionCenterNumber: dcpg.distributionCenterNumber,
            pickupLeadTimes: {
              readyTime: dcpg.pickupLeadTimes.readyTime,
              transitTime: null,
            },
            readyTimeAvailable: true,
            pickupAddressNotes: addressBook.pickupAddressNotes,
            pickupAddress: {
              ...addressBook.address,
              ...addressBook.contact,
              extension: addressBook.contact.phoneExtension,
            },
          };
        });
        this.validateAddress(addressBook);
        return;
      }
      case BulkEditPages.DeliveredAddressPage: {
        const addressBook = this.form.getRawValue().details;
        this.validateAddress(addressBook);
        return;
      }
      case BulkEditPages.PickupLeadTimesPage: {
        this.editedDCProductGroups = editedDCs.map((dcpg) => {
          const result = {
            dcProductGroupNumber: dcpg.dcProductGroupNumber,
            distributionCenterNumber: dcpg.distributionCenterNumber,
            pickupAddress: dcpg.pickupAddress,
            pickupAddressContact: dcpg.pickupAddressContact,
            pickupLeadTimes: {
              ...dcpg.pickupLeadTimes,
              readyTime: this.form.value.details.totalTime,
              transitTime: null,
            },
            readyTimeAvailable: true,
            deliveryMethodCode: dcpg.deliveryMethodCode,
          };

          if (!result.pickupAddress) {
            result.pickupAddress = defaultAddress();
          }
          if (!result.pickupAddress.country) {
            result.pickupAddress.country = defaultCountry();
          }
          return result;
        });
        break;
      }
      case BulkEditPages.POFaxPage: {
        this.editedDCProductGroups = editedDCs.map((dcpg) => {
          return {
            dcProductGroupNumber: dcpg.dcProductGroupNumber,
            distributionCenterNumber: dcpg.distributionCenterNumber,
            poFax: this.form.value.details.poFax,
          };
        });
        break;
      }
      case BulkEditPages.LeadTimesPickupAddressPage: {
        const addressBook = this.form.getRawValue().details.pickup.addressBook;
        const anyDelivered = this.isAnyDeliveredMethod()

        delete addressBook.contact.name;

        this.editedDCProductGroups = editedDCs.map((dcpg) => {
          const result = {
            dcProductGroupNumber: dcpg.dcProductGroupNumber,
            distributionCenterNumber: dcpg.distributionCenterNumber,
            deliveryMethodCode: this.form.value.details.deliveryMethodCode,
            pickupLeadTimes: {
              ...this.form.value.details.pickup.pickupLeadTimes,
              transitTime: null,
            },
            readyTimeAvailable: true,
            pickupAddressNotes: addressBook.pickupAddressNotes,
            pickupAddress: {
              ...addressBook.address,
              ...addressBook.contact,
              extension: addressBook.contact.phoneExtension,
            },
          };

          if (anyDelivered) {
            result['isZipTransitTime'] = true
            result['inboundRouteType'] = this.form.value.details.pickup.inboundRouteType;
          }

          if (dcpg.isZipTransitTime) {
            result['inboundRouteType'] = this.form.value.details.pickup.inboundRouteType;
          }
          return result;
        });
        this.validateAddress(addressBook);
        return;
      }
      case BulkEditPages.BuyersPage: {
        this.editedDCProductGroups = editedDCs.map((dcpg) => {
          return {
            dcProductGroupNumber: dcpg.dcProductGroupNumber,
            distributionCenterNumber: dcpg.distributionCenterNumber,
            buyerCode: this.form.value.details.buyerCode,
          };
        });
        break;
      }
      case BulkEditPages.ChangePickupTypePage: {
        this.editedDCProductGroups = editedDCs.map((dcpg) => {
          const result = {
            dcProductGroupNumber: dcpg.dcProductGroupNumber,
            distributionCenterNumber: dcpg.distributionCenterNumber,
            pickupAddress: dcpg.pickupAddress,
            pickupAddressContact: dcpg.pickupAddressContact,
            pickupLeadTimes: {
              ...dcpg.pickupLeadTimes,
              transitTime: null,
            },
            readyTimeAvailable: true,
          };
          result['inboundRouteType'] = this.form.value.details.inboundRouteType;
          result['deliveryMethodCode'] = dcpg.deliveryMethodCode;

          if (!result.pickupAddress) {
            result.pickupAddress = defaultAddress();
          }
          if (!result.pickupAddress.country) {
            result.pickupAddress.country = defaultCountry();
          }
          return result;
        });
        break;
      }
      case BulkEditPages.DeliveredLeadTimesPage: {
        this.editedDCProductGroups = editedDCs.map((dcpg) => {
          return {
            dcProductGroupNumber: dcpg.dcProductGroupNumber,
            distributionCenterNumber: dcpg.distributionCenterNumber,
            deliveryMethodCode: this.form.value.details.deliveryMethodCode,
            deliveredLeadTimes: {
              isTransitTimeOverridden: false,
              totalTime: this.form.value.details.delivered.totalTime,
            },
            readyTimeAvailable: false,
          };
        });
        break;
      }
      case BulkEditPages.DeliveryMethodLeadTimesPage: {
        const addressBook = this.form.value?.details?.delivered?.addressBook;
        // since delivered address is optional while updating delivered delivery method
        // validate address if any address has been added
        // otherwise proceed with the update
        if (addressBook && addressBook.address && addressBook.address.country) {
          this.validateAddress(addressBook);
        } else {
          this.sendDeliveredAddressChanges(addressBook);
        }
        return;
      }
      case BulkEditPages.PickupContact: {
        const contact = this.form.getRawValue().details;
        delete contact.name;
        this.editedDCProductGroups = editedDCs.map((dcpg) => {
          return {
            dcProductGroupNumber: dcpg.dcProductGroupNumber,
            distributionCenterNumber: dcpg.distributionCenterNumber,
            pickupAddress: {
              ...contact,
              extension: contact.phoneExtension,
            },
          };
        });
        break;
      }
      case BulkEditPages.AddPOEmailPage: {
        const poEmails = this.form.getRawValue().details.poEmails;
        this.editedDCProductGroups = editedDCs;
        this.sendChanges({
          additions: poEmails
        });
        return;
      }
      case BulkEditPages.RemovePOEmailPage: {
        const existingPOEmails = this.form.getRawValue().details.existingPOEmails;
        this.editedDCProductGroups = editedDCs;
        this.sendChanges({
          removals: existingPOEmails
        });
        return;
      }
      case BulkEditPages.ReplacePOEmailPage: {
        const {poEmails, existingPOEmails} = this.form.getRawValue().details;
        this.editedDCProductGroups = editedDCs;
        this.sendChanges({
          additions: poEmails,
          removals: existingPOEmails
        });
        return;
      }
    }
    this.sendChanges();
  }

  updateDcProductGroupsForDeliveredLeadTime(addressBook: any, editedDCs: any, contact: Contact) {
    this.editedDCProductGroups = editedDCs.map((dcpg) => {
      let dcpgUpdated: any = {
        dcProductGroupNumber: dcpg.dcProductGroupNumber,
        distributionCenterNumber: dcpg.distributionCenterNumber,
        deliveryMethodCode: this.form.value.details.deliveryMethodCode,
        deliveredLeadTimes: {
          isTransitTimeOverridden: false,
          totalTime: this.form.value.details.delivered.totalTime,
        },
        pickupAddressNotes: addressBook?.pickupAddressNotes,
        pickupAddressId: contact?.uniqueId,
        readyTimeAvailable: false
      };
      if (addressBook === undefined || addressBook === null) {
        // remove address if already exist
        const emptyAddressBookForm = this._bulkEditFormService.buildDeliveredAddressForm();
        const emptyAddressBookValues = emptyAddressBookForm.value;
        delete emptyAddressBookValues.contactType;
        const pickupAddress = {...emptyAddressBookValues.address, ...emptyAddressBookValues.contact }
        dcpgUpdated = {...dcpgUpdated, pickupAddress, pickupAddressNotes: '', pickupAddressId: null};
      }
      return dcpgUpdated;
    });
  }
  sendDeliveredAddressChanges(addressBook) {
    const contact = this.getAddContactPayload({ ...addressBook });
    const editedDCs = this.getSelectedDCs();
    const bulkEditPayload = this.getDCPGBulkEditPayload(addressBook, editedDCs, contact);
    this.applyClickedAddContact.next({ contact, contactType: ContactType.DeliveredAddress, bulkEditPayload });
  }

  sendChanges(payload: any = {}) {
    const auditNotes = this.getAuditNotes();
      payload = {
        ...payload,
        editedDCProductGroups: this.editedDCProductGroups,
        auditNotes
      }
      this.applyClicked.next(payload);
  }

  getAddContactPayload(addressBook: any): Contact {
    const addressContact = addressBook?.contact;
    const notes = addressBook?.pickupAddressNotes;
    delete addressBook.contact;
    delete addressBook.pickupAddressNotes;
    delete addressBook.notes;
    const contact: Contact = { ...addressBook, ...addressContact, notes };
    contact.uniqueId = uuid.v4();
    return contact;
  }

  getDCPGBulkEditPayload(addressBook: any, editedDCs: any, contact: Contact) {
    if (this.page === BulkEditPages.DeliveredAddressPage) {
      this.updateDcProductGroupsForDeliveredAddress(addressBook, editedDCs, contact);
    } else if (this.page === BulkEditPages.DeliveryMethodLeadTimesPage) {
      this.updateDcProductGroupsForDeliveredLeadTime(addressBook, editedDCs, contact);
    }
    return {
      productGroup: {
        productGroupNumber: this.productGroupNumber,
        dcProductGroups: this.editedDCProductGroups,
      },
      auditNotes: this.getAuditNotes(),
    }
  }

  updateDcProductGroupsForDeliveredAddress(addressBook: any, editedDCs: any, contact: Contact) {
    this.editedDCProductGroups = editedDCs.map((dcpg) => {
      return {
        dcProductGroupNumber: dcpg.dcProductGroupNumber,
        distributionCenterNumber: dcpg.distributionCenterNumber,
        deliveryMethodCode: dcpg.deliveryMethodCode,
        deliveredLeadTimes: dcpg.deliveredLeadTimes,
        readyTimeAvailable: true,
        pickupAddressNotes: addressBook.pickupAddressNotes,
        pickupAddressId: contact.uniqueId,
      };
    });
  }

  getAuditNotes(): string {
    const [ticket, notes] = [
      this.notesForm.value.ticket,
      this.notesForm.value.notes,
    ];
    const auditNotes = [
      !!ticket ? `Ticket: ${ticket}` : null,
      !!notes ? `Reason: ${notes}` : null,
    ].filter((v) => !!v)
      .join(' | ');
    return auditNotes;
  }

  validateSelectedDCs(form: UntypedFormGroup) {
    this.warningBannerMessage = this.getWarnings();

    const shortListedDCPGs = this.getSelectedDCs();

    const list = shortListedDCPGs.filter(
      (dcpg: { deliveryMethodCode: string }) =>
        dcpg.deliveryMethodCode === DeliveryMethodCodes.Delivered
    );

    const bulkAction = form.controls.bulkAction.value;

    if (
      (bulkAction === DCPGEditBulkActions.ChangeKehePickupType ||
        bulkAction === DCPGEditBulkActions.PickupLeadTimes ||
        bulkAction === DCPGEditBulkActions.PickupAddress ||
        bulkAction === DCPGEditBulkActions.PickupContact) &&
      list.length > 0
    ) {
      return {
        pickupType: list,
      };
    }

    if (bulkAction === DCPGEditBulkActions.DeliveredLeadTimes ||
      bulkAction === DCPGEditBulkActions.DeliveredAddress) {
      const pickupDeliveryItems = shortListedDCPGs.filter(
        (dcpg: { deliveryMethodCode: string }) =>
          dcpg.deliveryMethodCode !== DeliveryMethodCodes.Delivered
      );
      if (pickupDeliveryItems.length > 0) {
        return {
          deliveredType: pickupDeliveryItems,
        };
      }
    }
    return null;
  }

  getWarnings(): string {
    switch(this.bulkActionField?.value) {
      case DCPGEditBulkActions.AddPOEmail:
      case DCPGEditBulkActions.ReplacePOEmail: {
        const emails = this.form.get('details')?.getRawValue()?.poEmails
          .map((e: { email: string; }) => e.email.toLowerCase())
          .filter((e: string) => !!e);

        const duplicates = this.getSelectedDCs().map(item => hasExistingPOEmail(item, emails)).filter(v => !!v);
        if (duplicates.length > 0) {
          const duplicateEmails = duplicates.reduce((a, c) => a.concat(c.duplicates), [])
          this.warnDuplicateEmails(duplicateEmails);
          const warnings = duplicates.flatMap(({ dcpg, duplicates }) => {
            return duplicates.map(emailItem => {
              return `<li>${dcpg.dcNumber}-${dcpg.dcpgNumber}-${dcpg.supplierName} | ${emailItem}</li>`;
            });
          });
          return (
            'There are fields below that need your attention. The following PO Email(s) already exist at the DC Product Group' +
            '<ul class="pl-3">' + warnings.join('') + '</ul>'
          );
        } else {
          this.poEmailWarnings = (this.form.get('details.poEmails') as FormArray)?.getRawValue().map(_ => false);
        }
        return null;
      }
      case DCPGEditBulkActions.RemovePOEmail: {
        const list: DCPGListItem[] = this.getSelectedDCs().filter(item => cantRemovePOEmail(item));
        if (list.length > 0) {
          const warnings = list.flatMap(dcpg => {
            return dcpg.poTransmissionDetails?.poEmails.map(emailItem => {
              return `<li>${dcpg.dcNumber}-${dcpg.dcpgNumber} - ${emailItem.email}</li>`;
            });
          });
          return (
            'The following DC Product Group(s) has only one PO Contact that cannot be removed.<br/>' +
            '<ul class="pl-3">' + warnings.join('') + '</ul>'
          );
        }
        return null;
      }
      default:
        return null;
    }
  }

  warnDuplicateEmails(emails: string[]) {
    this.poEmailWarnings = (this.form.get('details.poEmails') as FormArray).getRawValue().map((v) => {
      const email = v.email?.toLowerCase();

      return !!email && emails.includes(email);
    });
  }

  validateAddress(addressBook) {
    this.addressForm = addressBook.address;
    this.loading = true;
    this.validationResponse = null;
    this._addressService
      .validateAddress(addressBook.address, environment.smartyStreetsKey)
      .subscribe((val) => {
        this.validationResponse = val;
        this.loading = false;
        if (val.isValid) {
          if (this.page === BulkEditPages.DeliveredAddressPage || this.page === BulkEditPages.DeliveryMethodLeadTimesPage) {
            addressBook.address = { ...addressBook.address, ...val.suggestedAddress };
            this.sendDeliveredAddressChanges(addressBook);
          } else {
            this.editedDCProductGroups.forEach((dcpg) => {
              dcpg.pickupAddress = {
                ...dcpg.pickupAddress,
                ...val.suggestedAddress,
              };
            });
            this.sendChanges();
          }
        }
      },
      error => {
        this.validationResponse = null;
        this.loading = false;
        if (this.page === BulkEditPages.DeliveredAddressPage || this.page === BulkEditPages.DeliveryMethodLeadTimesPage) {
          this.sendDeliveredAddressChanges(addressBook);
        } else {
          this.sendChanges();
        }
      });
  }

  showValidationDetails() {
    return this.validationResponse && !this.validationResponse.isValid;
  }

  onEditAddressClicked() {
    this.validationResponse = null;
  }

  useAddress() {
    if (this.page === BulkEditPages.DeliveredAddressPage || this.page === BulkEditPages.DeliveredLeadTimesPage) {
      const addressBook = this.getDeliveredAddressBookFormValue();
      this.sendDeliveredAddressChanges(addressBook);
    } else {
      this.sendChanges();
    }
  }

  getDeliveredAddressBookFormValue() {
    if (this.page === BulkEditPages.DeliveredAddressPage) {
      return this.form.getRawValue().details;
    } else if (this.page === BulkEditPages.DeliveryMethodLeadTimesPage) {
      return this.form.value?.details?.delivered?.addressBook;
    }
  }
}
