import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getEditDCProductGroup, selectBuyerList, selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import { DCProductGroup } from '../../models/dc-product-group';
import { ActivatedRoute } from '@angular/router';
import {
  dcProductGroupFormChanged,
  loadBuyerList,
  setValidationErrors,
  showNotesModal
} from '../../store/supplier-detail.actions';
import { combineLatest, Subject } from 'rxjs';
import { SupplierDetail } from '../../models/supplier-detail';
import { ProductGroup } from '../../models/product-group';
import { Buyer } from '../../models/buyer';
import { defaultAddress, defaultCountry } from '../../../../utils/address-utils';
import { PermissionsService } from '@app/services/permissions.service';
import { Constants } from '../../../../constants/constants';
import {
  ProductGroupDeliveryFormComponent
} from '../../../product-groups/components/product-group-delivery-form/product-group-delivery-form.component';
import {
  PoTransmissionsFormComponent
} from '../../../product-groups/components/po-transmissions-form/po-transmissions-form.component';
import { getFormValidationErrors, markAllFormControlsAsTouched } from '../../../../utils/common-utils';
import { isDraft, isInvitedDraftSupplier } from '../../utilities/supplier-utils';
import {
  getFormControlValueMap,
  getFormDirtyValue,
  isValidKeHEEmail,
  resetPristine
} from '../../../../utils/form-utils';
import { formatDCProductGroupResponse } from '../../utilities/product-group-utils';
import { getProductGroup } from '../../../product-groups/store/product-groups.selectors';
import { PurchaseOrderTransmissionEmail } from '../../models/purchase-order-email';
import { isEqual } from 'lodash';
import { ProductGroupActions } from '../../../product-groups/models/product-group-actions';
import { InboundRouteType } from '@app/models/dcpg-inbound-route-type';

@Component({
  selector: 'app-product-groups-dc-form',
  templateUrl: './product-groups-dc-form.component.html',
  styleUrls: ['./product-groups-dc-form.component.scss']
})
export class ProductGroupsDcFormComponent extends Destroyable implements OnInit, OnDestroy, AfterViewInit {

  readonly buyerRegions = Constants.BuyerRegions;
  readonly actionTypes = ProductGroupActions;

  initialFormValue: Map<FormControl, any>;
  initialDeliveryValue: Map<FormControl, any>;
  initialPOTransValue: Map<FormControl, any>;
  initialPOEmails: PurchaseOrderTransmissionEmail[];

  formReady = false;
  form: FormGroup;
  productGroup: ProductGroup;
  dcProductGroup: DCProductGroup;
  supplierDetail: SupplierDetail;
  buyerList: Buyer[];
  tempAddress = defaultAddress();

  private productGroupNumber: string;
  supplier: SupplierDetail;

  @ViewChild('deliveryGroup', { static: true }) deliveryGroup: ProductGroupDeliveryFormComponent;
  @ViewChild('poTransmissionsGroup', { static: true }) poTransmissionsGroup: PoTransmissionsFormComponent;

  validateAddressOnSave: Subject<void> = new Subject<void>();

  canUserEditBuyer = false;
  canUserEditPoTransmission = false;
  canUserEditDeliveryMethod = this._permissionsService.userHasAction(Constants.UserActions.SupplierDcProductGroupDeliveryMethodUpdate);
  canShowBuyerRegionEmailFlag = false;

  get isFormReady() {
    return this.formReady && this.deliveryGroup.isFormReady;
  }

  get buyerCode() {
    return this.form.get('buyerCode');
  }

  get buyerRegionField() {
    return this.form.get('buyerRegion');
  }

  get buyerEmailField() {
    return this.form.get('buyerEmail');
  }

  get poTransmissions() {
    return this.poTransmissionsGroup.form;
  }

  get isFormValid() {
    return this.form.valid &&
      (this.deliveryGroup.form.enabled ? this.deliveryGroup.form.valid : true) &&
      (this.poTransmissions.enabled ? this.poTransmissions.valid : true);
  }

  get showContactCard() {
    return isInvitedDraftSupplier(this.supplier);
  }

  get isDraftSupplier() {
    return isDraft(this.supplier);
  }

  constructor(
    private _store: Store,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _permissionsService: PermissionsService,
  ) {
    super();
  }

  ngOnInit() {
    this.setUserPermissions();
    this.productGroupNumber = this.route.snapshot.paramMap.get('productGroupNumber');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.initializeForm();
    this._store
    .pipe(select(selectSupplierDetail), takeUntil(this.destroy$))
    .subscribe((val) => {
      this.supplier = val;
    });
  }

  ngAfterViewInit() {
    this._store.select(selectSupplierDetail).pipe(
      takeUntil(this.destroy$)
    )
      .subscribe((supplier) => {
        if (supplier) {
          this.canShowBuyerRegionEmailFlag = isDraft(supplier);
          this.updateFormForResourceActions();
        }
      });

    this.dispatchStoreActions();
    this.initializeStoreSelectorObservables();
  }

  ngOnDestroy() {
    this._store.dispatch(setValidationErrors({ errors: [] }));
    super.ngOnDestroy();
  }

  private setUserPermissions() {
    this.canUserEditBuyer = this._permissionsService.userHasAction(Constants.UserActions.SupplierDcProductGroupBuyerUpdate);
    this.canUserEditPoTransmission = this._permissionsService.userHasAction(Constants.UserActions.SupplierDcProductGroupPoTransmissionUpdate);
  }

  private initializeForm(): void {
    this.form = this._formBuilder.group({
      buyerCode: ['', [Validators.required]],
      buyerRegion: ['', [Validators.required]],
      buyerEmail: ['', [Validators.required, Validators.email, isValidKeHEEmail]],
      buyerName: ['', []],
    });
  }

  private dispatchStoreActions(): void {
    this._store.dispatch(loadBuyerList());
  }

  private initializeStoreSelectorObservables(): void {
    const getSupplierDetails$ = this._store.select(selectSupplierDetail).pipe(take(1));
    const getProductGroup$ = this._store.select(getProductGroup, { productGroupNumber: this.productGroupNumber }).pipe(take(1));
    const getEditDCProductGroup$ = this._store.select(getEditDCProductGroup).pipe(take(1));
    const selectBuyerList$ = this._store.select(selectBuyerList).pipe(filter(x => x.length > 0));

    combineLatest([
      getSupplierDetails$,
      getProductGroup$,
      getEditDCProductGroup$,
      selectBuyerList$,
    ])
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(([supplier, productGroup, editDCProductGroup, buyerList]) => {

        this.supplierDetail = supplier;
        this.productGroup = productGroup;

        this.dcProductGroup = this.prepProductGroup(editDCProductGroup);

        this.buyerList = buyerList;

        this.formReady = true;
        this.patchForm();
        this.initializeFormValueChanges();
      });
  }

  private prepProductGroup(editDCProductGroup: DCProductGroup): DCProductGroup {
    if (!editDCProductGroup.pickupAddress) {
      editDCProductGroup.pickupAddress = defaultAddress();
      editDCProductGroup.pickupAddressNotes = '';
    }

    if (!editDCProductGroup.pickupAddress.country) {
      editDCProductGroup.pickupAddress.country = defaultCountry();
    }
    return editDCProductGroup;
  }

  patchForm(): void {
    this.form.controls.buyerCode.setValue(this.dcProductGroup.buyerCode);
    this.form.controls.buyerName.setValue(this.dcProductGroup.buyerName);
    this.form.controls.buyerRegion.setValue(this.dcProductGroup.buyerRegion || '');
    this.form.controls.buyerEmail.setValue(this.dcProductGroup.buyerEmail || '');
    this.deliveryGroup.patchForm(this.dcProductGroup);
    this.poTransmissionsGroup.patchForm(this.dcProductGroup.poFax, this.dcProductGroup.poEmails, this.dcProductGroup.poOrderMinimum);

    this.validateForm();
    this.initialFormValue = getFormControlValueMap(this.form);
    this.initialDeliveryValue = getFormControlValueMap(this.deliveryGroup.form);
    this.initialPOTransValue = getFormControlValueMap(this.poTransmissionsGroup.form);
    this.initialPOEmails = this.dcProductGroup.poEmails;
    this.resetForm();
  }

  resetForm() {
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();

    this.deliveryGroup.resetForm();
    this.poTransmissionsGroup.resetForm();
  }

  private initializeFormValueChanges(): void {
    combineLatest([
      this.form.valueChanges.pipe(startWith({})),
      this.deliveryGroup.form.valueChanges.pipe(startWith({})),
      this.poTransmissions.valueChanges.pipe(startWith({})),
    ])
      .pipe(
        map(([_form, _delivery, _potransmissions]) => {
          const form = { ..._form, delivery: _delivery, poTransmissions: _potransmissions };
          // This iterate all initial FormControls and check if it's current value and
          // initial value are same then it is markAsPristine which make sure form not dirty if its a reverted value
          resetPristine(this.initialFormValue);
          resetPristine(this.initialDeliveryValue);
          resetPristine(this.initialPOTransValue);

          // we will need to compare the po emails by value because the above only works for static forms
          const poEmailsForm = this.poTransmissions.controls.poEmails as FormArray;
          if (isEqual(poEmailsForm.getRawValue(), this.initialPOEmails)) {
            poEmailsForm.markAsPristine();
          }

          const dirtyValue = this.getFormDirtyValue;

          formatDCProductGroupResponse(dirtyValue, form, this.canUserEditPoTransmission, this.showContactCard, this.isDraftSupplier);
          // remove bad addresses if present
          if (!dirtyValue?.pickupAddress?.city && !dirtyValue?.pickupAddress?.zipCode && !dirtyValue?.pickupAddress?.state?.code) {
            delete dirtyValue?.pickupAddress;
          }

          if (!this.canUserEditDeliveryMethod && !!dirtyValue) {
            dirtyValue.pickupAddress = undefined;
            dirtyValue.pickupAddressNotes = undefined;
          }

          if (dirtyValue && (dirtyValue.inboundRouteType ?? InboundRouteType.None) !== InboundRouteType.None) {
            dirtyValue.isZipTransitTime = true
          }

          return dirtyValue;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((dirtyValue) => {
        this._store.dispatch(dcProductGroupFormChanged({
          form: {
            esn: this.supplierDetail ? this.supplierDetail.esn : null,
            productGroups: [
              {
                productGroupNumber: this.productGroup.productGroupNumber,
                dcProductGroups: [
                  {
                    dcProductGroupNumber: this.dcProductGroup.dcProductGroupNumber,
                    distributionCenterNumber: this.dcProductGroup.distributionCenterNumber,
                    ...(dirtyValue ?? {})
                  }
                ]
              }
            ],
            updatedBySource: 'Enterprise',
          },
          isDirty: (this.form.dirty || this.deliveryGroup.form.dirty || this.poTransmissions.dirty),
          isDcProductGroupFormValid: this.isFormValid,
          isPickupAddressDirty: this.isDelivered ? false :
            (this.deliveryGroup.pickupAddressFormGroup.dirty || this.deliveryGroup.pickupAddressNotesFormGroup.dirty),
        }));
        this.validateForm();
      });
  }

  get isDelivered(): boolean {
    return this.deliveryGroup.isDelivered;
  }

  get getFormDirtyValue() {
    const dirtyDeliveryValue = getFormDirtyValue(this.deliveryGroup.form);
    const dirtyPoTransmissionsValue = getFormDirtyValue(this.poTransmissions);
    const dirtyFormValue = getFormDirtyValue(this.form);

    const data = { ...dirtyPoTransmissionsValue, ...dirtyDeliveryValue, ...dirtyFormValue };
    if (data.pickup && data.pickup.pickupFax === null && Object.keys(data.pickup).length === 1) {
      delete data.pickup;
    }
    if (Object.keys(data).length === 0) {
      return null;
    }

    return {
      ...dirtyFormValue,
      delivery: dirtyDeliveryValue,
      poTransmissions: dirtyPoTransmissionsValue
    };
  }

  pickupAddressSelected(selectedAddress) {
    this.save();
  }

  buyerValueChange(value: any): void {
    if (value) {
      const buyer = this.buyerList.find(item => item.code === value);
      this.form.controls.buyerName.patchValue(buyer.name);
    } else {
      this.form.controls.buyerName.patchValue(null);
    }
  }

  private save(): void {
    this._store.dispatch(showNotesModal());
  }

  public onFocus(element: any): void {
    element.toggle(true);
  }

  private validateForm() {
    if (!isDraft(this.supplierDetail)) {
      markAllFormControlsAsTouched(this.form);
      this._store.dispatch(setValidationErrors({ errors: getFormValidationErrors(this.form) }));
    }
  }

  updateFormForResourceActions() {
    if (this.canUserEditPoTransmission) {
      this.poTransmissions.enable();
    } else {
      this.poTransmissions.disable();
    }

    if (this.canUserEditBuyer) {
      this.buyerCode.enable();
      if (this.canShowBuyerRegionEmailFlag) {
        this.buyerRegionField.enable();
        this.buyerEmailField.enable();
      } else {
        this.buyerRegionField.disable();
        this.buyerEmailField.disable();
      }
    } else {
      this.buyerCode.disable();
      this.buyerRegionField.disable();
      this.buyerEmailField.disable();
    }
  }
}
