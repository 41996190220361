<div *ngIf="!isFormReady">
  <kehe-phoenix-loading></kehe-phoenix-loading>
</div>
<div [formGroup]="form" [ngClass]="{'hidden': !isFormReady}">
  <div class="form-container">
    <div class="row">
      <div class="col">
        <h4>Edit Product Group ({{productGroup?.productGroupNumber}})</h4>
      </div>
    </div>
    <hr class="mt-0">
    <div class="row">
      <div class="col">
        <h5>Purchasing Details</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <kendo-formfield>
            <kendo-label [for]="productGroupNameField" text=" Product Group Name"></kendo-label>
            <kendo-textbox #productGroupNameField formControlName="name"
                           [maxlength]="maxLength" placeholder="Enter a Group Name"
                           (valueChange)="onNameValueChange($event)"
                           (blur)="onNameBlur()"
                           required>
            </kendo-textbox>
            <div class="d-flex">
              <kendo-formerror *ngIf="productGroupName?.errors?.required || productGroupNameIsEmpty">Product Group Name is required.</kendo-formerror>
              <kendo-formhint *ngIf="!productGroupName?.errors?.required">Maximum of {{maxLength}} characters</kendo-formhint>
              <kendo-formhint class="ml-auto">{{counter}}</kendo-formhint>
            </div>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <kendo-formfield>
            <kendo-label [for]="temperatureClassField" text="Temperature Class"></kendo-label>
            <kendo-combobox
              #temperatureClassField
              formControlName="temperatureClass"
              [data]="tempClasses"
              [filterable]="true"
              required
              (focus)="onFocusTemperatureClass()"
              placeholder="Select Temperature Class"
              [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
            ></kendo-combobox>
            <kendo-formerror *ngIf="temperatureClass?.errors?.required">Temperature Class is required.</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="form-group mb-0">
          <kendo-formfield>
            <kendo-label [for]="brandsField" text="Brands (optional)"></kendo-label>
            <kendo-multiselect
              #brandsField
              formControlName="brands"
              [data]="brands"
              placeholder="Select Brands"
              [textField]="'name'"
              [valueField]="'code'"
              [filterable]="true"
              [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
            ></kendo-multiselect>
          </kendo-formfield>
        </div>
      </div>
    </div>
    <hr>
    <div [hidden]="showContactCard">
      <div class="row">
        <div class="col">
          <h5>Remittance Information</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="remitNameField" text="Remit Name (on invoice)"></kendo-label>
              <kendo-textbox #remitNameField formControlName="remitName" [maxlength]="remitNameMaxLength"
                (valueChange)="remitNameChange($event)" required>
              </kendo-textbox>
              <div class="d-flex">
                <kendo-formerror *ngIf="remitName?.errors?.required || remitNameIsEmpty">Remit Name is required.
                </kendo-formerror>
                <kendo-formhint *ngIf="!remitName?.errors?.required">Maximum of {{remitNameMaxLength}} characters
                </kendo-formhint>
                <kendo-formhint class="ml-auto">{{remitNameCounter}}</kendo-formhint>
              </div>
            </kendo-formfield>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mb-2">
          <input kendoCheckBox #useHqAddressCheckBox type="checkbox" [checked]="isUseHeadquarterInfoChecked"
            (change)="onUseHeadquarterInfoChange()" />
          <kendo-label class="k-checkbox-label" [for]="useHqAddressCheckBox"
            text="Use address and contact provided in Supplier Headquarters"></kendo-label>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group mb-0">
            <kehe-address #addressComponentRemittance [address]="productGroup?.remittanceAddress"
              [formSaved]="validateRemittanceAddressOnSave.asObservable()" [key]="smartyStreetsKey"
              [street1MaxLength]="30" [street2MaxLength]="getStreet2Length()" [cityMaxLength]="getCityLength()"
              [attentionMaxLength]="getAttentionLength()" [zipCodeMaxLength]="getZipCodeLength()"
              (addressSelected)="onRemittanceAddressSelected($event)" (noAddressSelected)="onNoAddressSelected()">
            </kehe-address>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex">

            <kehe-phoenix-phone-number-input label="Phone" formControlName="remitPhone"></kehe-phoenix-phone-number-input>

            <span class="mr-2"></span>
            <div>
              <kendo-formfield>
                <kendo-label [for]="phoneExtensionField" text="Ext (optional)"></kendo-label>
                <kendo-maskedtextbox #phoneExtensionField formControlName="remitExtension" [maskValidation]="false"
                  [prompt]="''" [mask]="mask" style="max-width: 75px !important;">
                </kendo-maskedtextbox>
              </kendo-formfield>
            </div>
          </div>
          <div class="form-group">
            <kehe-phoenix-phone-number-input label="Fax (optional)" formControlName="remitFax"></kehe-phoenix-phone-number-input>
          </div>
          <div class="form-group">
            <div style="max-width: 298px;">
              <kendo-formfield>
                <kendo-label [for]="remitEmailField" text="Email"></kendo-label>
                <kendo-textbox #remitEmailField formControlName="remitEmail" [maxlength]="remitEmailMaxLength"
                  (valueChange)="remitEmailChange($event)">
                </kendo-textbox>
                <div class="d-flex">
                  <kendo-formerror *ngIf="remitEmail?.errors?.email">Invalid email format</kendo-formerror>
                  <kendo-formhint *ngIf="!remitEmail?.errors?.email">
                    Maximum of {{remitEmailMaxLength}} characters
                  </kendo-formhint>
                  <kendo-formhint class="ml-auto">{{remitEmailCounter}}</kendo-formhint>
                </div>
              </kendo-formfield>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div [hidden]="!showContactCard">
      <div class="row">
        <div class="col">
          <h5>Remittance Information</h5>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <div class="text-info">
            This information will be used for remittance.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-label text="Remittance Address" class="mb-1"></kendo-label>
            <app-contact-card formControlName="remittanceAddressId"
              [defaultContactTypes]="contactType.RemittanceAddress" [contacts]="supplier.contacts">
            </app-contact-card>
            <kendo-formhint>Manage remittance address on locations tab.</kendo-formhint>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
