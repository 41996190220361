import { isNumeric } from 'rxjs/internal/util/isNumeric';

/**
 * Returns `true` if the pressed key is a number or an accepted key (like backspace, delete, etc). Otherwise, returns `false`.
 * @param keyDownEvent - The Event returned by the KeyDown KeyboardEvent.
 * @returns boolean
 */
export function isKeyANumber(keyDownEvent: KeyboardEvent): boolean {
  return (
    isNumeric(keyDownEvent.key) ||
    keyDownEvent.key === 'Backspace' ||
    keyDownEvent.key === 'Delete' ||
    keyDownEvent.key === 'ArrowUp' ||
    keyDownEvent.key === 'ArrowRight' ||
    keyDownEvent.key === 'ArrowDown' ||
    keyDownEvent.key === 'ArrowLeft' ||
    keyDownEvent.ctrlKey || // Allows copy/paste on windows
    keyDownEvent.metaKey || // Allows copy/paste on macs
    keyDownEvent.key === ' ' ||
    keyDownEvent.key === 'Spacebar' || // For old browsers compatibility
    keyDownEvent.key === 'Tab' ||
    keyDownEvent.key === 'Enter'
  );
}

/**
 * Returns `true` if the pasted text contains only numbers and new lines. Otherwise, returns `false`.
 * @param pastedText string
 * @returns boolean
 */
export function isPastedTextOnlyNumbers(pastedText: string): boolean {
  const regex = /^[0-9\n]*$/; // For checking if the pasted text contains only numbers and new lines
  return regex.test(pastedText);
}

/**
 * Returns `true` if the string contains only digits. Otherwise, returns `false`.
 * @param value string
 * @returns boolean
 */
export function isNumber(value: string): boolean {
  return /^[0-9]*$/.test(value);
}

/**
 * Returns exec value, on user input checks if character is digit or not, if it's digit it returns it . Otherwise, returns empty.
 * @param e string
 * @returns string
 */
export function acceptOnlyDigits(e): string {
  const value = e.target.value;
  const digitRegex = /([0-9])*/;
  const execValue = digitRegex.exec(value)[0];
  return execValue;
}

/**
 * Returns s or empty value. This is to address scenarios that a text needs a letter s appended. Ex: 1 line uploaded, 2 lines uploaded.
 * @param prop number
 * @returns string
 */
export function returnLetterSIfNeeded(prop: number) {
  return prop !== 1 ? 's' : '';
}
