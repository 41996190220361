import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SupplierPageComponent } from '../pages/supplier-page/supplier-page.component';
import { SupplierListFilterClearAll } from '../store/supplier-list.actions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class ClearSupplierGuardGuard  {

  constructor(
    private _store: Store
  ) {
  }

  canDeactivate(component: SupplierPageComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!nextState.url.startsWith('/supplier')) {
      this._store.dispatch(SupplierListFilterClearAll());
    }
    return true;
  }
}
