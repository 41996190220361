import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import { Observable } from 'rxjs';
import { selectShowUnsavedChangesModal } from '../../store/supplier-detail.selectors';
import { hideUnsavedChangesModal } from '../../store/supplier-detail.actions';

@Component({
  selector: 'app-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html'
})

export class UnsavedChangesModalComponent extends Destroyable implements OnInit {
  @Output()
  checkAndContinueNavigating: EventEmitter<any> = new EventEmitter();

  @Input()
  msg = ``;

  @Input()
  submitButton = 'Stay on Page';

  @Input()
  secondaryButton = 'Leave Page';

  public showModal$: Observable<boolean>;

  constructor(
    private _store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.showModal$ = this._store.pipe(select(selectShowUnsavedChangesModal));
  }

  public hide(): void {
    this._store.dispatch(hideUnsavedChangesModal());
  }

  public save(): void {
    this._store.dispatch(hideUnsavedChangesModal());
  }

  public dontSave(): void {
   this.checkAndContinueNavigating.emit();
  }

  public setOverflowToAuto(): void {
    const elm = document.getElementsByTagName('body');
    if (elm) {
      const typ = document.createAttribute('style');
      typ.value = 'overflow: auto';
      elm[0].attributes.setNamedItem(typ);
    }
  }

  handleuUnsavedModalEvents() {
    this.hide();
    this.setOverflowToAuto();
  }

}
