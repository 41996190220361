import { Component } from '@angular/core';

@Component({
  selector: 'app-health-page',
  templateUrl: './health-page.component.html'
})
export class HealthPageComponent {

  constructor() {  
     // This is intentional
  }

}
