import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  DcPaymentHoldFormVM,
  selectDcPaymentHoldFormVm,
} from './dc-payment-hold-form.selectors';
import { DcPaymentHoldDetails } from '@app/modules/supplier-detail/models/dc-payment-hold-details';
import * as DcPaymentHoldActions from '../../store/supplier-dc-payment-holds.actions';

@Component({
  selector: 'app-dc-payment-hold-form',
  templateUrl: './dc-payment-hold-form.component.html',
})
export class DcPaymentHoldFormComponent {
  vm$ = this.store.select(selectDcPaymentHoldFormVm);

  constructor(private readonly store: Store) {
    store.dispatch(DcPaymentHoldActions.userLandedOnDcPaymentEditPage());
  }

  updateRowReleaseDate(paymentHold: DcPaymentHoldDetails, vm: DcPaymentHoldFormVM) {
    paymentHold.paymentHoldReleaseDate = new Date();
    paymentHold.lastEditedBy = vm.userData.name;

    this.checkFormDataForChanges(vm.initialHolds, vm.paymentHolds);
  }

  resetRowReleaseDate(paymentHold: DcPaymentHoldDetails, vm: DcPaymentHoldFormVM) {
    paymentHold.paymentHoldReleaseDate = null;
    paymentHold.lastEditedBy = vm.userData.name;

    this.checkFormDataForChanges(vm.initialHolds, vm.paymentHolds);
  }

  private checkFormDataForChanges(initialData: DcPaymentHoldDetails[], currentData: DcPaymentHoldDetails[]) {
    const paymentHoldComparer = (ph: DcPaymentHoldDetails) => ph.hasPaymentHold !== initialData.find((ch) => ch.dcNumber === ph.dcNumber)?.hasPaymentHold;
    const changedPaymentHolds = currentData.filter(paymentHoldComparer)

    if (changedPaymentHolds.length > 0) {
      this.store.dispatch(DcPaymentHoldActions.userChangedDcPaymentHoldForm({
        changedPaymentHolds
      }));
    } else {
      this.store.dispatch(DcPaymentHoldActions.userResetDcPaymentHoldChanges());
    }
  }
}
