import { on, createReducer } from "@ngrx/store";
import * as SupplierApiActions from '@app/supplier/supplier-api.actions';
import { SupplierDevelopmentManager } from "@app/supplier/supplier-development-manager";
import { CategoryManager } from "@app/supplier/category-manager";

export const key = 'supplier';

export interface State {
    statusList: string[];
    supplierDevelopmentManagers: SupplierDevelopmentManager[];
    categoryManagers: CategoryManager[];
}

export const initialState: State = {
    statusList: [],
    supplierDevelopmentManagers: [],
    categoryManagers: [],
};

export const reducer = createReducer(
    initialState,
    on(SupplierApiActions.loadStatusListSuccess, (state, { list }) => {
        return {
            ...state,
            statusList: list,
        };
    }),
    on(SupplierApiActions.loadSupplierDevelopmentManagerListSuccess, (state, { list }) => {
        return {
            ...state,
            supplierDevelopmentManagers: list,
        };
    }),
    on(SupplierApiActions.loadCategoryManagerListSuccess, (state, { list }) => {
        return {
            ...state,
            categoryManagers: list,
        };
    }),
);

export const getSupplierStatusList = (state: State) => state && state.statusList;
export const getSupplierDevelopmentManagerList = (state: State) => state && state.supplierDevelopmentManagers;
export const getCategoryManagersList = (state: State) => state && state.categoryManagers;
