import { Environment } from './environment-enum';
export { Environment };

export const environment = {
  current: Environment.UAT,
  production: true,
  showProfile: false,
  ga: 'UA-37320487-44',
  googleAnalyticsTrackingId: 'G-RY8CG0YV6E',
  googleTagManager: 'GTM-WDG6L4FV',
  web: 'https://uatconnectenterprise.kehe.com',
  api: 'https://uat-connect-order-management-api.kehe.com/api/',
  apiGateway: 'https://uat-api.kehe.com',
  identityApi: 'https://uat-connect-identity-server.kehe.com',
  v1ConnectApi: 'https://uatconnect.kehe.com/services/api/',
  v1UserManagement: 'https://uatentitymanagement.kehe.com',
  v1SalesOperationsHost: 'https://uatsalesoperations.kehe.com',
  v1Reports: 'https://uatreports.kehe.com',
  v1ConnectHost: 'https://uatconnect.kehe.com',
  orderServicesApi: 'https://uatorder.kehe.com/services/api',
  kcoAppGuid: 'E141BC35-5EFE-4F4D-84D5-8F1D9F8F0F39',
  logglyEndpoint: 'https://logs-01.loggly.com/inputs/',
  logglyName: 'connect-enterprise-uat',
  showApi: 'https://uat-api.kehe.com/shows',
  promotionsApi: 'https://uat-api.kehe.com/promotions',
  supplierApi: 'https://uat-api.kehe.com/suppliers',
  brokerApi: 'https://uat-api.kehe.com/brokers',
  supplierEnterprise: 'https://uat-api.kehe.com/suppliers/api/enterprise',
  usersMicroservice: 'https://uat-api.kehe.com/users',
  apiInvoices: 'https://uat-api.kehe.com/invoices',
  salesOrderDraftApi: 'https://uat-api.kehe.com/sales-order-draft/api',
  shipmentsApi: 'https://uat-api.kehe.com/shipments/api',
  manageProgressApi: 'https://uat-api.kehe.com/manage-progress/api',
  customerProductsApi: 'https://uat-api.kehe.com/customer-products/api',
  productApi: 'https://uat-api.kehe.com/products',
  resourcesApi: 'https://uat-api.kehe.com/resources',
  roadmapsApi: 'https://uat-api.kehe.com/roadmaps',
  productEnterprise: 'https://uat-api.kehe.com/products/enterprise',
  newProducts: 'https://uat-api.kehe.com/products/new-products',
  customerEnterprise: 'https://uat-api.kehe.com/customers',
  dcApiUrl: 'https://uat-api.kehe.com/distribution-centers',
  mapApiKey:
    'pk.eyJ1IjoiaW5hbi1rZWhlIiwiYSI6ImNrYTc4dnRuZzAweWEyeHBtN3BuZmVjZDcifQ._-Zme4v8wolktP6lIfxung',
  utilitiesDcsList:
    'https://uat-api.kehe.com/distribution-centers/distributioncenterlist',
  utilitiesDcsDetail:
    'https://uat-api.kehe.com/distribution-centers/distributioncenterdetail',
  brokerEnterprise: 'https://uat-api.kehe.com/brokers/enterprise',
  documentsAndDownloads: 'https://uatcommunications.kehe.com/#/documents',
  smartyStreetsKey: '17106106048628953',
  purchasingOptionsApi: 'https://uat-api.kehe.com/purchasing-options',
  deliveryTrackingUrl: 'https://uat-connect-epod-web.kehe.com/#/landing',
  ebolUrl: 'https://uat-connect-epod-api.kehe.com',
  deliveryHubUrl: 'https://uat-connect-delivery-hub-web.kehe.com/#/',
  poFlowUrl: 'https://uat-connect-po-flow-web.kehe.com',
  promotionsUrl: 'https://uatpromotions.kehe.com',
  discontinuedProductsUrl: 'https://uatdiscproducts.kehe.com',
  reportsUrl: 'https://uatreports.kehe.com',
  ksolveUrl: 'https://uatconnect.kehe.com/ksolve',
  aomWorkflowUrl: 'https://uatconnect.kehe.com/ordermgmt2/#/cm-queue',
  retailerUrl: 'https://uatconnectretailer.kehe.com/',
  splitIoKey: '31sn5keqg75c46ll1g1143jghnbiosj8ka9q',
  vendorGroupId: 24,
  vendorAdminGroupId: 168,
  vendorManagerGroupId: 26,
  vendorReportsGroupId: 69,
  supplierOnboardingGroupId: 184,
  supplierOnboardingRedirectGroupId: 158,
  connectSupplierGroupId: 155,
  v1GroupIDs: {
    keheEmployee: 142,
    aomReports: 103,
    customerCare: 114,
    customerAdmin: 177,
    vendorAdmin: 168,
    brokerAdmin: 106,
    impersonateUser: 139,
    edsReports: 185,
    it: 140,
    customerCareAdmin: 115,
    employeeReportsEmployee: 136,
    financeReportsEmployee: 137,
    gbbReports: 138,
    itReportsEmployee: 141,
    marketingReportsEmployee: 147,
    publicationReports: 158,
    salesManagerReportsEmployee: 160,
    salesReportsEmployee: 161,
    supplyChainReportsEmployee: 162,
    transportationReportsEmployee: 165,
    vendorManagementReportsEmployee: 169,
    userManagement: 166,
    vmAdmin: 175,
    vmCoordinator: 176,
    cm: 111,
    cmAdmin: 112,
    marketing: 145,
    marketingAdmin: 146,
    promoTeam: 156,
    promoTeamAdmin: 157,
    vendorManager: 170,
    vendorPerformance: 173,
    accountsPayable: 100,
    kSolveUDR: 144,
    cem: 110,
    bem: 104,
    orderMgmt2: 149,
    orderMgmtAdmin: 150,
    sales: 159,
    pricingAdmin: 154,
    pricingAnalyst: 155,
  },
  ssrsReportUrlPrefix: 'https://uatreports.kehe.com/reportview/index',
  announcementsApi: 'https://uat-api.kehe.com/announcements/api',
  vendorBrokerManagementApi: 'https://uat-api.kehe.com/vendor-broker-mgmt/',
  ixoneApi: 'https://uat-api.kehe.com/ix-one/api',
  productAssetsApi: 'https://uat-api.kehe.com/product-assets',
  customPromotionsApi: 'https://uat-api.kehe.com/custom-promotions/api',
  prohibitRestrictApi: 'https://uat-api.kehe.com/prohibit-restrict',
  pendoApiKey: '50933daf-b141-4443-4a39-33e8c4ea4e2f',
  v2PriceChangeApi: 'https://uat-api.kehe.com/price-changes',
};
