import { createAction, props } from "@ngrx/store";
import { BulkModalShipmentProductsDto } from "../../models/bulk-modal-shipment-products-dto.interface";
import { ShipmentProducts } from "../../models/shipment-products.interface";

export const bulkDeleteItemsModalGetShipmentsAndProducts = createAction(
  '[Bulk Delete Items Modal] Get Shipments And Products',
  props<{ filter: BulkModalShipmentProductsDto }>()
);

export const bulkDeleteItemsModalGetShipmentsAndProductsSuccess = createAction(
  '[Bulk Delete Items Modal] Get Shipments And Products Success',
  props<{ shipmentsAndProducts: Array<ShipmentProducts> }>()
);

export const bulkDeleteItemsModalGetShipmentsAndProductsFailure = createAction(
  '[Bulk Delete Items Modal] Get Shipments And Products Failure',
);

export const bulkDeleteItemsModalGoBackToPageOne = createAction(
  '[Bulk Delete Items Modal] Go Back To Bulk Delete Items Modal Page One'
);

export const bulkDeleteItemsModalSelectAllChanged = createAction(
  '[Bulk Delete Items Modal] Select All Changed'
);

export const bulkDeleteItemsModalSelectOneChanged = createAction(
  '[Bulk Delete Items Modal] Select One Changed',
  props<{ shipmentId: number }>()
);

export const bulkDeleteItemsModalGoToPageThree = createAction(
  '[Bulk Delete Items Modal] Go To Page Three'
);

export const bulkDeleteItemsModalGoBackToPageTwo = createAction(
  '[Bulk Delete Items Modal] Go Back To Bulk Delete Items Modal Page Two'
);

export const bulkDeleteItemsModalDeleteItems = createAction(
  '[Bulk Delete Items Modal] Delete Items'
);

export const bulkDeleteItemsModalDeleteItemsSuccess = createAction(
  '[Bulk Delete Items Modal] Delete Items Success'
);

export const bulkDeleteItemsModalDeleteItemsFailure = createAction(
  '[Bulk Delete Items Modal] Delete Items Failure'
);