<ng-container *ngIf="vm$ | async as vm">
  <kehe-phoenix-modal (closeEvent)="cancel()" *ngIf="vm.isModalOpen" [show]="true" modalTitle="Add Documents">

    <div body class="container-fluid">
      <div class="row">
        <div class="col-12 p-0 margin-bottom-16">
          <kendo-buttongroup width="100%" selection="single">
            <button kendoButton [toggleable]="true" [selected]="vm.mode === documentCreateMode.url"
              (selectedChange)="onModeChange(documentCreateMode.url)">
              Add URL
            </button>
            <button kendoButton [toggleable]="true" [selected]="vm.mode === documentCreateMode.file"
              (selectedChange)="onModeChange(documentCreateMode.file)">
              Upload File
            </button>
          </kendo-buttongroup>
        </div>
      </div>

      <div class="row">
        <div class="col-12 p-0">
          <ng-container *ngIf="vm.apiFailure">
            <div class="margin-bottom-8">
              <kehe-phoenix-alert [show]="true" type="error" message="Unable to complete your request at this time. Please try again." [stick]="true" [hideCloseIcon]="true">
              </kehe-phoenix-alert>
            </div>
          </ng-container>

          <app-document-edit-form (fileChanged)="onFileChanged($event)"
            [isSproutsOrderGuide]="vm.isSproutsOrderGuide" [mode]="vm.mode" [fileUploads]="vm.fileUploads"
            [formGroup]="formGroup"></app-document-edit-form>
        </div>
      </div>
    </div>

    <div footer>
      <div class="d-flex justify-content-end">
        <kehe-phoenix-button [disabled]="vm.isSaving" (click)="cancel()"
          classAttr="btn-medium btn-link">Cancel</kehe-phoenix-button>
        <kehe-phoenix-button [disabled]="!vm.canSubmit" [spinner]="vm.isSaving" class="ms-2" (click)="save()"
          classAttr="btn-medium btn-primary">Save</kehe-phoenix-button>
      </div>
    </div>

  </kehe-phoenix-modal>
</ng-container>