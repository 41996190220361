import { Constants } from './../../../constants/constants';
import { PermissionsService } from '@app/services/permissions.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchasingOptionsGuard  {

  constructor(
    private _permissionsService: PermissionsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._permissionsService.userHasAction(Constants.UserActions.PurchasingOptionsRead);
  }
}
