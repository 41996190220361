import { createAction, props } from '@ngrx/store';
import { Payload } from '../../../models/payload';
import { SupplierListFilter } from '../models/supplier-list-filter';
import { SupplierListItem } from '../models/supplier-list-item';
import { SupplierDetail } from '../../supplier-detail/models/supplier-detail';
import { SupplierDraftListFilter } from '../models/supplier-draft-list-filter';
import { SupplierInvitationEmailStatus } from '../../../enum/supplier-invitation-email-status';
import { IKeheTab } from '@kehe/phoenix-tabs';
import { PendoEvent } from '../models/pendo-event.interface';

export const loadSupplierList = createAction(
  '[Supplier] Load Supplier List'
);

export const loadSupplierListSuccess = createAction(
  '[Supplier] Load Supplier List Success',
  props<{ suppliers: Payload<Array<SupplierListItem>> }>()
);

export const supplierListPageChange = createAction(
  '[Supplier] Supplier List Page Change',
  props<{ pageCount: number; pageIndex: number; skip: number }>()
);

export const openSupplierFilter = createAction(
  '[Supplier] Open Supplier Filter Panel'
);

export const closeSupplierFilter = createAction(
  '[Supplier] Close Supplier Filter Panel'
);

export const applyFilter = createAction(
  '[Supplier] Apply Filters',
  props<{ supplierListFilter: SupplierListFilter }>()
);

export const getDropdownFilterData = createAction(
  '[Supplier] Get Dropdown Filter Data'
);

export const supplierListSearchChange = createAction(
  '[Supplier] Supplier List Search Change',
  props<{ search: string }>()
);

export const supplierListSortChange = createAction(
  '[Supplier] Supplier List Sort Change',
  props<{ orderDirection: number, sortName: number }>()
);

export const createSupplierListFilterChips = createAction(
  '[Supplier] Create Supplier List Filter Chips'
);

export const supplierListFilterChipClicked = createAction(
  '[Supplier] Supplier List Filter Chip Clicked',
  props<{ key: string, filterType?: string, data?: { value: string, label: string} }>()
);

export const SupplierListFilterChipsClearAll = createAction(
  '[Supplier] Supplier List Filter Chips Clear All'
);

export const SupplierListFilterClearAll = createAction(
  '[Supplier] Supplier List Filter Clear All'
);

// Add Supplier
export const showAddSupplierModal = createAction(
  '[Supplier Detail] Show Add Supplier Modal',
);
export const closeAddSupplierModal = createAction(
  '[Supplier Detail] Close Add Supplier Modal',
);
export const newSupplierFormChanges = createAction(
  '[Supplier Detail] New Supplier Form Changed',
  props<{ valid: boolean }>()
);
export const addSupplier = createAction(
  '[Supplier Detail] Add Supplier',
  props<{ supplier: SupplierListItem }>()
);
export const addSupplierSuccess = createAction(
  '[Supplier Detail] Add Supplier Success',
  props<{ supplier: SupplierDetail, pendoEvent: PendoEvent }>()
);
export const addSupplierError = createAction(
  '[Supplier Detail] Add Supplier Error',
);
export const clearAddSupplierError = createAction(
  '[Supplier Detail] Clear Add Supplier Error',
);
export const inviteSupplier = createAction(
  '[Supplier Detail] Invite Supplier',
  props<{ inviteRequest: any }>()
);
export const createUserInV1 = createAction(
  '[Supplier Detail] Create User In V1',
  props<{ inviteRequest: any }>()
);
export const createUserInV2 = createAction(
  '[Supplier Detail] Create User In V2',
  props<{ inviteRequest: any }>()
);
export const uploadRetailerRequestedDetailsDocument = createAction(
  '[Supplier Detail] Upload Retailer Requested Details Document',
  props<{ supplier: SupplierDetail; inviteRequest: any; }>()
);
export const associateV2Permissions = createAction(
  '[Supplier Detail] Associate V2 Permissions',
  props<{ supplier: SupplierDetail; inviteRequest: any; }>()
);
export const inviteSupplierSuccess = createAction(
  '[Supplier Detail] Invite Supplier Success',
  props<{ supplier: SupplierDetail, pendoEvent: PendoEvent }>()
);
export const inviteSupplierError = createAction(
  '[Supplier Detail] Invite Supplier Error',
  props<{ emailStatus?: SupplierInvitationEmailStatus }>()
);

// draft tab related actions
export const loadDraftSupplierList = createAction(
  '[Supplier List]: Load Draft Supplier List'
);

export const loadDraftSupplierListSuccess = createAction(
  '[Supplier List]: Load Draft Supplier List Success',
  props<{ suppliers: Payload<Array<SupplierListItem>> }>()
);

export const supplierDraftListSortChange = createAction(
  '[Supplier List]: Supplier Draft List Sort Change',
  props<{ orderDirection: number, sortName: SupplierDraftListFilter['sortBy'] }>()
);

export const supplierDraftListPageChange = createAction(
  '[Supplier List]: Supplier List Page Change',
  props<{ pageCount: number; pageIndex: number; skip: number }>()
);

export const supplierDraftListSearchChanged = createAction(
  '[Supplier List]: Supplier Draft List Search Changed',
  props<{ search: string }>()
);

export const openDraftSupplierFilter = createAction(
  '[Supplier List]: Open Draft Supplier Filter'
);

export const closeDraftSupplierFilter = createAction(
  '[Supplier List]: Close Draft Supplier Filter'
);

export const applyDraftSupplierFilter = createAction(
  '[Supplier List]: Apply Draft Supplier Filter',
  props<{ filter: SupplierDraftListFilter }>()
);

export const updateSupplierListTab = createAction(
  '[Supplier List]: Update Supplier List Tab',
  props<{ tab: IKeheTab }>()
);

// Supplier Analyst Alignment
export const loadSupplierAnalystAlignment = createAction(
  '[Supplier List]: Load Supplier Analyst Alignment'
);
export const loadSupplierAnalystAlignmentSuccess = createAction(
  '[Supplier List]: Load Supplier Analyst Alignment Success',
  props<{ list: any[] }>()
);
// Supplier Analyst Alignment

// DCPG List
export const clickDCPGListDropdownOption = createAction(
  '[Supplier List]: Click DCPG List Dropdown Option',
  props<{ showDCPGList: boolean; }>(),
);
