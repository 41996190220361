import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  CreateGroupRequest,
  GroupDetailRecord,
  GroupDetailResponse,
  UpdateGroupRequest,
  UserGroupListResponse,
  UserGroupSortOption
} from '../../model/group-management.service';
import { UserGroupFilterChipItem, UserGroupFilters } from '@app/modules/user-group-management/model/group-management';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';

export const loadUserGroupListAction = createAction(
  '[Group Management] Load User Group List'
);

export const loadUserGroupListSuccessAction = createAction(
  '[Group Management] Load User Group List Success',
  props<{ response: UserGroupListResponse }>()
);

export const GroupDetailsActions = createActionGroup({
  source: 'Group Details Actions',
  events: {
    setSelectedUserGroup: props<{ groupId: number }>(),
  }
});

export const loadUserGroupDetailAction = createAction(
  '[Group Management] Load User Group Detail',
  props<{ id: number }>()
);

export const loadUserGroupDetailSuccessAction = createAction(
  '[Group Management] Load User Group Detail Success',
  props<{ response: GroupDetailResponse }>()
);

export const createGroupAction = createAction(
  '[Group Management] Create Group',
  props<{ request: CreateGroupRequest }>()
);

export const createGroupSuccessAction = createAction(
  '[Group Management] Create Group Success',
  props<{ record: GroupDetailRecord }>()
);

export const createGroupBadRequestAction = createAction(
  '[Group Management] Create Group Bad Request'
);

export const resetIsBadRequestAction = createAction(
  '[Group Management] Reset isBadRequest'
);

export const updateGroupAction = createAction(
  '[Group Management] Update Group',
  props<{ request: UpdateGroupRequest }>()
);

export const updateGroupSuccessAction = createAction(
  '[Group Management] Update Group Success',
  props<{ record: GroupDetailRecord }>()
);

export const deleteGroupAction = createAction(
  '[Group Management] Delete Group',
  props<{ groupId: number }>()
);

export const deleteGroupSuccessAction = createAction(
  '[Group Management] Delete Group Success',
  props<{ groupId: number }>()
);

export const updatePageCountAction = createAction(
  '[Group Management] Update Page Count',
  props<{ pageCount: number, skip: number }>()
);

export const updatePageIndexAction = createAction(
  '[Group Management] Update Page Index',
  props<{ pageIndex: number, skip: number }>()
);

export const updateSortOptionsAction = createAction(
  '[Group Management] Update Sort Options',
  props<{ sortOrder: UserGroupSortOption, orderDirection: number }>()
);

export const updateSearchTextAction = createAction(
  '[Group Management] Update Search Text',
  props<{ searchText: string }>()
);

export const resetAlertAction = createAction(
  '[Group Management] Reset Alert'
);

export const groupBadRequestAction = createAction(
  '[Group Management] Bad Request'
);

export const groupDuplicateEntityAction = createAction(
  '[Group Management] Entity Already Exists',
  props<{ message: string }>()
);

export const groupErrorAction = createAction(
  '[Group Management] Error Response',
  props<{ message?: string }>()
);

export const clickedKendoBreadCrumbs = createAction(
  '[Group Management] Clicked Kendo Breadcrumbs',
  props<{ item: BreadCrumbItem }>()
);

export const updateKendoBreadCrumbsList = createAction(
  '[Group Management] Update BreadCrumbs List',
  props<{ items: BreadCrumbItem[] }>()
);

export const ManageGroupsFilterActions = createActionGroup({
  source: 'Manage Group Filter Actions',
  events: {
    clickFilterButton: emptyProps(),
    clickFilterCloseButton: emptyProps(),
    clickApplyFilters: props<{ filters: UserGroupFilters; }>(),
    clickFilterChip: props<{ chip: UserGroupFilterChipItem; }>(),
    resetFilters: emptyProps(),
  }
});
