import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { Constants } from '../../../constants/constants';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsFeatureFlagOn, selectHaveFeatureFlagsBeenFetched } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FeatureFlags } from '@app/feature-flag';

@Injectable({
    providedIn: 'root'
})
export class PromotionsEligibilityGuard  {

    constructor(
        private store: Store,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.select(selectHaveFeatureFlagsBeenFetched).pipe(
            filter((flagsHaveBeenFetched) => flagsHaveBeenFetched),
            switchMap(() => {
                return this.store.select(selectIsFeatureFlagOn(FeatureFlags.PromotionsEligibilityPage.key))
                    .pipe(
                        withLatestFrom(this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.CustomerRead))),
                        map(([canAccessNewPage, hasCustomerRead]) => canAccessNewPage && hasCustomerRead),
                        map((hasAccessToPage: boolean) => hasAccessToPage ? hasAccessToPage : this.router.parseUrl(''))
                    );
            })
        )
    }
}
