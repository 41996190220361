<div class="kehe-card-action col-12 col-md-11 p-3 flex-fill d-flex flex-column justify-content-between address-card" [ngClass]="{'error': hasError}">
  <div *ngIf="!contact">
    <div class="oval mx-auto d-flex justify-content-center flex-grow-0 flex-shrink-0">
      <em class="fas fa-address-book icon my-auto" aria-hidden="true"></em>
    </div>
    <kehe-phoenix-button
      class="mt-2 mx-auto d-flex justify-content-center"
      classAttr="btn-link btn-medium"
      [spinner]="false"
      [disabled]="disabled"
      (click)="onAddClicked()"
      (blur)="onBlur()"
      iconClassAttr="fa fa-plus">
      Add Address
    </kehe-phoenix-button>
  </div>
  <div *ngIf="contact" class="h-100 d-flex flex-column">
    <div>
      <kendo-label text="Address"></kendo-label>
      <div class="address-content">{{ getAddressText() }}</div>
      <div class="mt-2" *ngIf="defaultContactTypes === contactTypes.PickupAddress && contact.address?.name">
        <kendo-label text="Notes"></kendo-label>
        <div>{{ contact.notes }}</div>
      </div>
      <div class="mt-2" *ngIf="defaultContactTypes === contactTypes.DeliveredAddress && contact.notes">
        <kendo-label text="Notes"></kendo-label>
        <div>{{ contact.notes }}</div>
      </div>
      <div class="mt-2" *ngIf="getContactText() !== null">
        <kendo-label text="Contact Information"></kendo-label>
        <div class="address-content">{{ getContactText() }}</div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-end mt-auto pt-1" *ngIf="!disabled">
      <kehe-phoenix-button classAttr="btn-delete" name="button4" (click)="onRemoveClicked(contact)">
        {{ contact.contactType !== contactTypes.HeadquarterAddress ? 'Delete' : 'Remove' }}
      </kehe-phoenix-button>
    </div>
  </div>
</div>
<app-contact-book-modal *ngIf="showModal" [defaultContactType]="defaultContactTypes"
                        [contacts]="contacts"
                        [isInProgress]="isInProgress"
                        [alertMessage]="alertMessage"
                        [saveContactError]="saveContactError"
                        (selectedMyContact)="onSelectMyContact($event)"
                        (smartyStreetsUseAddress)="onSmartyStreetsUseAddress($event)"
                        (smartyStreetsEditAddress)="onSmartyStreetsEditAddress()"
                        (closeModal)="onCloseModal()">
</app-contact-book-modal>
