import { Action, createReducer, on } from '@ngrx/store';
import * as SupplierDocumentsActions from './supplier-documents.actions';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { DocumentUploadRequest } from '../models/document-upload-request';
import { Document } from '../models/document';
import { DocumentType } from '../models/document-type';
import { DocumentStatus } from '../models/document-statuses';
import { AlertMessage } from '../../../models/alert-message';
import { SupplierData } from '../models/supplier-data';
import { Constants } from '../../../constants/constants';
import { deleteBrokerV2DocumentSuccess } from '../../supplier-detail/components/broker-tab-v2/broker-actions';


export class SupplierDocumentsState {
  supplier: SupplierData;
  documents: Document[];
  documentTypes: DocumentType[];
  isDocumentUploading: boolean;
  uploadDocument: DocumentUploadRequest;
  deleteDocument: Document;
  isDeletingDocument: boolean;
  isLoadDocumentsError: boolean;
  rejectDocument: Document;
  isDocumentActionLoading: boolean;
  rejectDocumentFeedback: boolean;
  alertMessage: AlertMessage;
}

export const initializeState = (): SupplierDocumentsState => {
  return {
    supplier: null,
    documents: [],
    documentTypes: [],
    isDocumentUploading: false,
    uploadDocument: null,
    deleteDocument: null,
    isDeletingDocument: false,
    isLoadDocumentsError: false,
    rejectDocument: null,
    isDocumentActionLoading: false,
    rejectDocumentFeedback: false,
    alertMessage: null,
  };
};

export const initialState = initializeState();

function getDocumentStatusErrorAlertMessage(errorCode: number): string {
  return errorCode > 0 ? 'There was an error updating document status. Please try again.' : 'There was a network error, please try again.';
}

const rootReducer = createReducer(
  initialState,
  on(SupplierDocumentsActions.hideDocumentsAlertMessage, (state: SupplierDocumentsState) => ({
    ...state,
    alertMessage: initialState.alertMessage,
  })),
  on(SupplierDocumentsActions.loadSupplierDocumentsData, (state: SupplierDocumentsState, action) => ({
    ...state,
    supplier: action.supplier,
    alertMessage: initialState.alertMessage,
  })),
  on(SupplierDocumentsActions.loadDocumentsSuccess, (state: SupplierDocumentsState, action) => ({
    ...state,
    documents: prepareDocuments(action.documents),
    isDocumentUploading: initialState.isDocumentUploading,
    isLoadDocumentsError: false
  })),
  on(SupplierDocumentsActions.loadDocumentsError, (state: SupplierDocumentsState) => ({
    ...state,
    documents: [],
    isLoadDocumentsError: true
  })),
  on(SupplierDocumentsActions.confirmDeleteDocument, (state: SupplierDocumentsState, action) => ({
    ...state,
    deleteDocument: action.document,
    alertMessage: initialState.alertMessage,
  })),
  on(SupplierDocumentsActions.cancelDeleteDocument, (state: SupplierDocumentsState) => ({
    ...state,
    deleteDocument: null,
  })),
  on(SupplierDocumentsActions.deleteDocument, (state: SupplierDocumentsState) => ({
    ...state,
    isDeletingDocument: true,
  })),
  on(SupplierDocumentsActions.deleteDocumentSuccess, (state: SupplierDocumentsState) => ({
    ...state,
    documents: state.documents.filter(item => item.uniqueId !== state.deleteDocument.uniqueId),
    deleteDocument: null,
    isDeletingDocument: false,
  })),
  on(SupplierDocumentsActions.deleteDocumentError, (state: SupplierDocumentsState) => ({
    ...state,
    isDeletingDocument: false,
    deleteDocument: null,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'There was a problem deleting your document.',
    },
  })),
  on(SupplierDocumentsActions.loadDocumentTypesSuccess, (state: SupplierDocumentsState, action) => ({
    ...state,
    supplierLoading: false,
    documentTypes: action.documentTypes.sort((a, b) => a.name.localeCompare(b.name)),
  })),
  on(SupplierDocumentsActions.loadDocumentTypesError, (state: SupplierDocumentsState) => ({
    ...state,
    supplierLoading: false,
    documentTypes: [],
  })),
  on(SupplierDocumentsActions.updateDocumentTypeUploadStatus, (state: SupplierDocumentsState, action) => ({
    ...state,
    documentTypes: action.documentTypes,
  })),
  on(SupplierDocumentsActions.resetUploadDocumentsModal, (state: SupplierDocumentsState, action) => ({
    ...state,
    alertMessage: initialState.alertMessage,
    isLoadDocumentsError: false,
    isDocumentUploading: false,
  })),
  on(SupplierDocumentsActions.uploadAndSaveDocument, (state: SupplierDocumentsState, action) => ({
    ...state,
    isDocumentUploading: true,
    uploadDocument: action.uploadDocumentRequest,
  })),
  on(SupplierDocumentsActions.uploadAndSaveDocumentSuccess, (state: SupplierDocumentsState, action) => ({
    ...state,
    alertMessage: filterDocumentsAlertMessage(action.document, state.alertMessage, {
      type: AlertTypes.Success,
      message: 'Your documents have been uploaded successfully!',
    }),
  })),
  on(SupplierDocumentsActions.uploadAndSaveDocumentError, (state: SupplierDocumentsState) => ({
    ...state,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'There was a problem uploading your documents.',
    },
    isDocumentUploading: initialState.isDocumentUploading,
  })),
  on(SupplierDocumentsActions.downloadDocument, (state: SupplierDocumentsState, action) => ({
    ...state,
    documents: updateDocumentActionState(state.documents, action.document, true),
    alertMessage: initialState.alertMessage,
  })),
  on(SupplierDocumentsActions.downloadDocumentCompleted, (state: SupplierDocumentsState, action) => ({
    ...state,
    documents: updateDocumentActionState(state.documents, action.document, false),
    alertMessage: initialState.alertMessage,
  })),
  on(SupplierDocumentsActions.downloadDocumentFailed, (state: SupplierDocumentsState) => ({
    ...state,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'Unable to complete your request at this time. Please try again.',
    },
  })),
  on(SupplierDocumentsActions.updateDocumentStatus, (state: SupplierDocumentsState, action) => ({
    ...state,
    documents: updateDocumentActionState(state.documents, action.document, true),
    alertMessage: initialState.alertMessage,
    isDocumentActionLoading: true,
  })),
  on(SupplierDocumentsActions.updateDocumentStatusSuccess, (state: SupplierDocumentsState, action) => ({
    ...state,
    documents: [...updateDocumentState(state.documents, action.document)],
    alertMessage: initialState.alertMessage,
    isDocumentActionLoading: false,
    rejectDocument: null,
  })),
  on(SupplierDocumentsActions.updateDocumentStatusError, (state: SupplierDocumentsState, action) => ({
    ...state,
    documents: updateDocumentActionState(state.documents, action.document, false),
    alertMessage: {
      type: AlertTypes.Error,
      message: getDocumentStatusErrorAlertMessage(action.errorCode),
    },
    isDocumentActionLoading: false,
  })),
  on(SupplierDocumentsActions.confirmRejectDocument, (state: SupplierDocumentsState, action) => ({
    ...state,
    rejectDocument: action.document,
    alertMessage: initialState.alertMessage,
    isDocumentActionLoading: false,
  })),
  on(SupplierDocumentsActions.cancelRejectDocument, (state: SupplierDocumentsState) => ({
    ...state,
    rejectDocument: null,
    alertMessage: initialState.alertMessage,
    isDocumentActionLoading: false,
    rejectDocumentFeedback: false,
  })),
  on(SupplierDocumentsActions.showRejectDocumentFeedback, (state: SupplierDocumentsState, action) => ({
    ...state,
    rejectDocument: action.document,
    alertMessage: initialState.alertMessage,
    isDocumentActionLoading: false,
    rejectDocumentFeedback: true,
  })),
  on(
    deleteBrokerV2DocumentSuccess,
    (state: SupplierDocumentsState, action) => ({
        ...state,
        documents: state.documents.filter(
            (item) => item.uniqueId !== action.document.uniqueId
        )
    })
),

  //  Documents
  );

export function reducer(state: SupplierDocumentsState | undefined, action: Action) {
  return rootReducer(state, action);
}

function prepareDocuments(documents: Document[]): Document[] {
  if (documents) {
    documents.forEach(function (document) {
      if (document) {
        if (!document.status) {
          document.status = DocumentStatus.NotSubmitted;
        }
        document.statusTag = prepareDocumentStatusTag(document.status);
      }
    });
  }

  return documents;
}

function prepareDocumentStatusTag(documentStatus: string) {

  const color = getDocumentStatusTagColor(documentStatus);

  return {
    bgColor: color,
    borderColor: color,
    text: documentStatus === DocumentStatus.Rejected ? 'Revision Needed' : documentStatus,
  };
}

function getDocumentStatusTagColor(documentStatus: string): string {
  if (documentStatus === DocumentStatus.InReview || documentStatus === DocumentStatus.InReviewEDS) {
    return '#FAD981';
  }
  if (documentStatus === DocumentStatus.Approved) {
    return '#D5E48F';
  }
  if (documentStatus === DocumentStatus.Rejected) {
    return '#F7B288';
  }
  return '#E2E2E2';
}

function updateDocumentState(documents: Document[], document: Document) {
  let find = documents.find((item) => item.uniqueId === document.uniqueId);
  // if not found then this is a new document and we should add it to the list from the state.
  if (!find) {
    documents.push(document);
    find = document;
  }
  find.status = document.status;
  find.statusNotes = document.statusNotes;
  find.statusTag = prepareDocumentStatusTag(document.status);
  find.isSyncing = false;
  return documents;
}

function updateDocumentActionState(documents: Document[], document: Document, loading: boolean) {
  documents.find(item => item.uniqueId === document.uniqueId).isSyncing = loading;
  return documents;
}

function filterDocumentsAlertMessage(document: Document, initial: AlertMessage, final: AlertMessage): AlertMessage {
  if (document.typeId === Constants.SupplierInfoDocumentTypeId 
    || document.typeId === Constants.PurchasingInfoDocumentTypeId 
    || document.typeId === Constants.RetailerRequestedDetailsDocumentTypeId) {
    return initial;
  }
  return final;
}
