import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { selectIsFeatureFlagOn, selectHaveFeatureFlagsBeenFetched } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { Constants } from '../../../../constants/constants';
import { FeatureFlags } from '@app/feature-flag';

/**
 * Announcement List page Guard, actives the route only if the user has the Feature Flag View_Announcements
 * and EnterpriseSiteAccess Resource Action
 */
@Injectable({
  providedIn: 'root'
})
export class AnnouncementsListGuard  {
  constructor(private router: Router,
              private store: Store) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectHaveFeatureFlagsBeenFetched)
      .pipe(
        filter((res) => res),
        switchMap((res) => {
          return this.store.select(selectIsFeatureFlagOn(FeatureFlags.ViewAnnouncements.key))
            .pipe(
              withLatestFrom(this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.EnterpriseSiteAccess))),
              map(([ isViewAnnouncements, siteAccess ]) => isViewAnnouncements && siteAccess),
              map((canManage: boolean) => canManage ? canManage : this.router.parseUrl('/unauthorized'))
            );
        })
      );
  }
}
