import { Component, OnInit } from '@angular/core';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { select, Store } from '@ngrx/store';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { User } from '../../models/user';
import { UserAction } from '../../models/user-actions';
import { askDeleteUser, assignAdmin, resendInvitation, showAddUserModal } from '../../store/supplier-users.actions';
import {
  selectLoadingUsers,
  selectShowAddUserModal,
  selectShowDeleteUserModal,
  selectUsers
} from '../../store/supplier-users.selectors';

@Component({
  selector: 'app-supplier-users-tab',
  templateUrl: './supplier-users-tab.component.html',
  styleUrls: ['./supplier-users-tab.component.scss']
})
export class SupplierUsersTabComponent extends Destroyable implements OnInit {

  menuOptionMap = {};

  public sort: SortDescriptor[] = [
    {
      field: 'name',
      dir: 'asc'
    }
  ];

  users: User[] = [];
  showAddUserModal = false;
  showDeleteUserModal = false;
  isAdmin = false;
  loadingUsers = false;

  constructor(
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      select(selectUsers),
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.users = orderBy(value, this.sort);
      this.checkIsAdmin(value);
      value.reduce((map, item) => {
        map[item.email] = this.getMoreOptions(item);
        return map;
      }, this.menuOptionMap);
    })
    this._store
      .pipe(select(selectShowAddUserModal))
      .subscribe((value) => {
        this.showAddUserModal = value;
      });
    this._store
      .pipe(select(selectShowDeleteUserModal))
      .subscribe((value) => {
        this.showDeleteUserModal = value;
      });
    this._store
      .pipe(select(selectLoadingUsers))
      .subscribe((value) => {
        this.loadingUsers = value;
      });
  }

  checkIsAdmin(users: User[]) {
    const rootUser = users.find(item => item.isRootUser);
    if (rootUser) {
      this.isAdmin = rootUser.isAdmin;
    }
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.users = orderBy(this.users, this.sort);
  }

  onAddClick() {
    this._store.dispatch(showAddUserModal());
  }

  deleteUser(user: User) {
    if (!this.shouldDeleteBeDisabled(user)) {
      this._store.dispatch(askDeleteUser({ user }));
    }
  }

  shouldDeleteBeDisabled(user: User) {
    const adminsCount = this.users.filter(item => item.isAdmin).length;
    if (adminsCount > 1) {
      return false;
    }
    if (user.isAdmin) {
      return true;
    }
    return false;
  }

  getMoreOptions(item: User): any {
    return [
      {
        data: UserAction.Assign,
        name: 'Assign CONNECT Admin',
        enabled: !item.isAdmin,
        selected: false,
      },
      {
        data: UserAction.Resend,
        name: 'Resend Invite Email',
        enabled: true,
        selected: false,
      },
      {
        data: UserAction.Delete,
        name: 'Delete',
        enabled: !this.shouldDeleteBeDisabled(item),
        selected: false,
      }
    ];
  }

  moreOptionSelected(option: OptionMenuItem, user: User) {
    switch (option.data) {
      case UserAction.Assign:
        this._store.dispatch(assignAdmin({ user }));
        break;
      case UserAction.Resend:
        this._store.dispatch(resendInvitation({ user }));
        break;
      case UserAction.Delete:
        this._store.dispatch(askDeleteUser({ user }));
        break;
    }
  }
}
