import { createAction, props } from '@ngrx/store';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';

import { IAnnouncementsListFilters } from './interfaces/announcements-list-filters.interface';

export const announcementsListPageOpened = createAction(
  '[Announcements List Page]: Page Opened'
);

export const announcementsListPageSearch = createAction(
  '[Announcements List Page]: Search',
  props<{ searchText: string }>()
);

export const announcementsListPageFilter = createAction(
  '[Announcements List Page]: Filter',
  props<{ filters: IAnnouncementsListFilters }>()
);

export const announcementsListPageNextPage = createAction(
  '[Announcements List Page]: Next Page'
);

export const announcementsListLeavePage = createAction(
  '[Announcements List Page]: Leave Page',
);

export const clickedKendoBreadCrumbs = createAction(
  '[Announcements List Page] Clicked BreadCrumbs',
  props <{ item: BreadCrumbItem }>(),
);

