import { Action, createReducer, on } from '@ngrx/store';
import * as SupplierUsersActions from './supplier-users.actions';
import { User } from '../models/user';
import { UserDetails } from '../models/user-details';
import { ITag } from '@kehe/phoenix-indicators';
import { startCase } from 'lodash';


export class SupplierUsersState {
    esn: string;
    users: User[];
    loadingUsers: boolean;
    showAddUserModal: boolean;
    addUserInProgress: boolean;
    addUserError: string;
    deletingUser: User;
    deleteUserInProgress: boolean;
    deleteUserError: string;
}

export const initializeState = (): SupplierUsersState => {
    return {
        esn: null,
        users: [],
        loadingUsers: false,
        showAddUserModal: false,
        addUserInProgress: false,
        addUserError: null,
        deletingUser: null,
        deleteUserInProgress: false,
        deleteUserError: null,
    };
};

export const initialState = initializeState();

const rootReducer = createReducer(
    initialState,
    on(SupplierUsersActions.loadSupplierUsers, (state: SupplierUsersState, action) => ({
        ...state,
        esn: action.esn,
        loadingUsers: true,
    })),
    on(SupplierUsersActions.loadSupplierUsersSuccess, (state: SupplierUsersState, action) => ({
        ...state,
        users: action.users,
    })),
    on(SupplierUsersActions.loadSupplierUsersError, (state: SupplierUsersState, action) => ({
        ...state,
        users: [],
        loadingUsers: false,
    })),
    on(SupplierUsersActions.showAddUserModal, (state: SupplierUsersState, action) => ({
        ...state,
        showAddUserModal: true,
        addUserError: null,
    })),
    on(SupplierUsersActions.closeAddUserModal, (state: SupplierUsersState, action) => ({
        ...state,
        showAddUserModal: false,
        addUserError: null,
    })),
    on(SupplierUsersActions.requestAddUser, (state: SupplierUsersState, action) => ({
        ...state,
        addUserInProgress: true,
        addUserError: null,
    })),
    on(SupplierUsersActions.addUserSuccess, (state: SupplierUsersState, action) => ({
        ...state,
        addUserInProgress: false,
        addUserError: null,
        showAddUserModal: false,
        users: [
            ...state.users,
            action.user
        ]
    })),
    on(SupplierUsersActions.addUserError, (state: SupplierUsersState, action) => ({
        ...state,
        addUserInProgress: false,
        addUserError: action.errorMessage ?? 'Something went wrong. Please try again later.',
    })),
    on(SupplierUsersActions.assignAdmin, (state: SupplierUsersState, action) => ({
        ...state,
        users: updateUserSync(state.users, action.user, true)
    })),
    on(SupplierUsersActions.assignAdminSuccess, (state: SupplierUsersState, action) => ({
        ...state,
        users: updateUserAdminStatus(state.users, action.user)
    })),
    on(SupplierUsersActions.assignAdminError, (state: SupplierUsersState, action) => ({
        ...state,
        users: updateUserSync(state.users, action.user, false)
    })),

    // Resend Invitation
    on(SupplierUsersActions.resendInvitation, (state: SupplierUsersState, action) => ({
        ...state,
        users: updateUserSync(state.users, action.user, true)
    })),
    on(SupplierUsersActions.resendInvitationSuccess, (state: SupplierUsersState, action) => ({
        ...state,
        users: updateUserSync(state.users, action.user, false)
    })),
    on(SupplierUsersActions.resendInvitationError, (state: SupplierUsersState, action) => ({
        ...state,
        users: updateUserSync(state.users, action.user, false)
    })),
    // Resend Invitation

    // Delete User
    on(SupplierUsersActions.askDeleteUser, (state: SupplierUsersState, action) => ({
        ...state,
        deletingUser: action.user,
        deleteUserInProgress: false,
        deleteUserError: null
    })),
    on(SupplierUsersActions.cancelDeleteUser, (state: SupplierUsersState, action) => ({
        ...state,
        deletingUser: null,
        deleteUserInProgress: false,
        deleteUserError: null
    })),
    on(SupplierUsersActions.deleteUser, (state) => ({
        ...state,
        deleteUserInProgress: true,
        deleteUserError: null,
      })),
      on(SupplierUsersActions.deleteUserSuccess, (state, { user }) => ({
        ...state,
        deleteUserInProgress: false,
        deletingUser: null,
        users: state.users.filter(item => item.email !== user.email),
      })),
      on(SupplierUsersActions.deleteUserError, (state, { error }) => ({
        ...state,
        deleteUserInProgress: false,
        deleteUserError: error.message
      })),
      on(SupplierUsersActions.loadUserStatusSuccess, (state, action) => ({
        ...state,
        users: updateUserStatus(state.users, action.users),
        loadingUsers: false,
      })),
      on(SupplierUsersActions.loadUserStatusError, (state, action) => ({
        ...state,
        loadingUsers: false,
      })),
    // Delete User
);

export function reducer(state: SupplierUsersState | undefined, action: Action) {
    return rootReducer(state, action);
}

function updateUserSync(users: User[], user: User, isSyncing: boolean) {
    return users.map(item => item.email === user.email ? { ...item, isSyncing: isSyncing } : item);
}

function updateUserAdminStatus(users: User[], user: User) {
    return users.map(item => item.email === user.email ? user : item);
}

function updateUserStatus(users: User[], userDetails: UserDetails[]) {
    const statusMap = userDetails.reduce(function(map, obj) {
        map[obj.email] = obj.status;
        return map;
    }, {});
    users.forEach(item => {
        const status = statusMap[item.email];
        item.status = status;
        item.statusTag = prepareStatusTag(status);
    });
    return users;
}

function prepareStatusTag(status: string): ITag {
    if (status) {
        switch (status) {
            case 'active': {
                return {
                    bgColor: '#D5E48F',
                    borderColor: '#D5E48F',
                    text: 'Active',
                };
            }
            case 'pending': {
                return {
                    bgColor: '#FAD981',
                    borderColor: '#FAD981',
                    text: 'Pending',
                };
            }
            default: {
                return {
                    text: startCase(status.toLowerCase()),
                };
            }
        }
    }
    return null;
}
