import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Destroyable } from '@app/abstract/destroyable';
import { select, Store } from '@ngrx/store';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {combineLatest, Subject, Subscription} from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  addSupplierContact,
  attemptCancelEdit,
  clearSupplierContactForm,
  generalFormChanged,
  generalFormNoAddressSelected,
  getFormDropdownData,
  saveGeneralForm,
  setValidationErrors,
  showNotesModal,
} from '../../store/supplier-detail.actions';
import {
  getHeadquarterAddressValidationInProgress, isGeneralTabNotSubmitted,
  selectIsSavingContact,
  selectNewlyAddedContact,
  selectOnBoardingStatus,
  selectSaveContactError,
  selectSupplierDetail,
} from '../../store/supplier-detail.selectors';
import { SupplierDetail } from '../../models/supplier-detail';
import { AddressComponent } from '@kehe/phoenix-address';
import { environment } from '@src/environments/environment';
import { SupplierDetailService } from '../../services/supplier-detail.service';
import { PermissionsService } from '@app/services/permissions.service';
import { Constants } from '@app/constants/constants';
import { defaultAddress } from '@app/utils/address-utils';
import {
  getSupplierDevelopmentManagerFullName,
  isAddedDraftSupplier,
  isDraft,
  isInvitedDraftSupplier,
  shouldShowAAPFieldForGeneralTab
} from '../../utilities/supplier-utils';
import { SupplierFormService } from '../../services/supplier-form.service';
import { getFormValidationErrors, markAllFormControlsAsTouched } from '@app/utils/common-utils';
import { TradingPartner } from '../../models/trading-partner';
import {
  formatGeneralFormResponse,
  getFormControlValueMap,
  getFormDirtyValue,
  resetPristine
} from '@app/utils/form-utils';
import { Contact, ContactType } from '@app/models/contact';
import { isTabApproved, isTabInReview } from '../../utilities/onboarding-utils';
import { OnBoardingStatus } from '../../models/onboarding-status';
import { SupplierDetailTabs } from '../../models/supplier-detail-tabs';
import * as SupplierSelectors from '@app/shared/state/supplier/supplier.selectors';
import { CategoryManager } from '@app/supplier/category-manager';
import { SupplierDevelopmentManager } from '@app/supplier/supplier-development-manager';
import { CodeValue } from '../../models/code-value';
import { SupplierCurrenciesComboBox } from '@app/modules/supplier-list/constants/supplier-constants';
import {selectIsFeatureFlagOn} from "@app/shared/state/feature-flag/feature-flag.selectors";
import {FeatureFlags} from "@app/feature-flag";
import { downloadPrivateAssets } from "@app/modules/supplier-documents/store/supplier-documents.actions";

@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.scss'],
})
export class GeneralFormComponent extends Destroyable implements OnInit, OnDestroy {

  readonly tooltipAAP = Constants.AAPFieldToolTip;

  initialFormValue: Map<UntypedFormControl, any>;
  readonly mask = Constants.ExtensionTextMask;
  public form: UntypedFormGroup;
  contactType = ContactType;

  onboardingStatuses: OnBoardingStatus[] = [];

  // Need this to render some non-editable fields
  public supplier: SupplierDetail;
  public fieldMsg = 'This field cannot be edited.';
  allowedCharsMessage = 'Allowed characters are a-Z 0-9 &#32;&#33;&#34;&#35;&#36;&#37;&#38;&#39;&#40;&#41;&#42;&#43;&#44;&#45;&#46;&#47;&#58;&#59;&#60;&#61;&#62;&#63;&#64;&#91;&#92;&#93;&#94;&#95;&#96;&#123;&#124;&#125;&#126.';
  public canEditEdi = false;
  public canEditSummary = false;
  public canEditSupplierHeadquarters = false;

  public categoryManagerItems: CategoryManager[] = [];
  public supplierDevManagerItems: SupplierDevelopmentManager[] = [];
  public legalNameMaxLength = 50;
  public legalNameCounter = `0/${this.legalNameMaxLength}`;
  public ntsLegalNameCounter = `0/${this.legalNameMaxLength}`;
  public hqEmailMaxLength = 30;
  public hqEmailCounter = `0/${this.hqEmailMaxLength}`;

  tradingPartnerList: Array<TradingPartner> = [];
  isTradingPartnerListLoading = false;
  isSavingContact = false;
  saveContactError: string;
  supplierCurrencies: CodeValue[] = SupplierCurrenciesComboBox;
  ffAAPTerm = false;

  validateHeadquarterAddressOnSave: Subject<void> = new Subject<void>();
  smartyStreetsKey = environment.smartyStreetsKey;
  @ViewChild('addressComponentHeadquarters', { static: true }) addressFormHeadquarters: AddressComponent;
  private searchRequest: Subscription;

  get loadCategoryManagerItems() {
    return this.categoryManagerItems.length === 0;
  }

  get loadSupplierDevManagerItems() {
    return this.supplierDevManagerItems.length === 0;
  }

  get showContactCard() {
    return isInvitedDraftSupplier(this.supplier);
  }

  get supplierRepresentativeEmail() {
    return this.form.get('addedSupplierRepresentative.email');
  }

  constructor(
    private _store: Store,
    private _supplierDetailService: SupplierDetailService,
    private _permissionsService: PermissionsService,
    private _supplierFormService: SupplierFormService,
  ) {
    super();
  }

  get isDraft(): boolean {
    return isDraft(this.supplier);
  }

  get legalNameIsEmpty() {
    return this.form.get('name').value.length === 0;
  }

  get legalName() {
    return this.form.get('name');
  }

  get ntsLegalName() {
    return this.form.get('wmsLegalName');
  }

  get hqEmail() {
    return this.form.get('headquarterEmail');
  }

  ngOnInit() {
    this.setUserPermissions();
    this._store.dispatch(getFormDropdownData());

    this._store.select(SupplierSelectors.selectCategoryManagerList).pipe(
      takeUntil(this.destroy$)
    ).subscribe(managers => this.categoryManagerItems = managers);

    this._store.select(SupplierSelectors.selectSupplierDevelopmentManagerList).pipe(
      takeUntil(this.destroy$)
    ).subscribe(managers => this.supplierDevManagerItems = managers);

    this._store.pipe(
      select(selectOnBoardingStatus),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.onboardingStatuses = val;
        this.updateHQFieldState();
      });
    // Keep the form in sync with the actual state of the record
    combineLatest([
      this._store.select(selectIsFeatureFlagOn(FeatureFlags.AAPTerm.key)),
      this._store.select(selectSupplierDetail),
      this._store.select(isGeneralTabNotSubmitted),
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([ff, supplier, isGeneralTabNotSubmitted]) => {
      this.ffAAPTerm = ff;
      if (supplier) {
        if (!supplier.headquarterAddress) {
          supplier.headquarterAddress = defaultAddress();
        }
        this.supplier = supplier;
        if (supplier.tradingPartnerId) {
          this.loadTradingPartners(supplier.tradingPartnerId);
        }
        this.buildForm(isGeneralTabNotSubmitted);
        if (!this.supplier.isEdi) { this.form.controls['tradingPartnerId'].disable(); }
        this.legalNameChange(this.supplier.name);
        this.ntsLegalNameChange(this.supplier.wmsLegalName);
        this.hqEmailChange(this.supplier.headquarterAddress.email);
      }
    });

    // Subscribe to form changes and dispatch them to the store
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(form => {

      // This iterate all initial FormControls and check if it's current value and
      // initial value are same then it is markAsPristine which make sure form not dirty if its a reverted value
      resetPristine(this.initialFormValue);

      const dirtyValue = getFormDirtyValue(this.form);
      formatGeneralFormResponse(dirtyValue, form, this.canEditSupplierHeadquarters);

      this._store.dispatch(generalFormChanged({
        form: {
          esn: this.supplier.esn,
          ...dirtyValue
        },
        isDirty: this.form.dirty,
        isHeadquarterAddressDirty: this.form.controls.headquarterAddress.dirty,
        isGeneralFormValid: isDraft(this.supplier) ?
          form.name?.length > 0 && !this.ntsLegalName.invalid :
          this.form.valid,
      }));
      this.validateForm();
    });

    this.form.controls['isEdi'].valueChanges.pipe(
      takeUntil(this.destroy$),
      filter(isEdi => !isEdi))
      .subscribe((_) => {
        this.form.controls['tradingPartnerId'].setValue('');
        this.form.controls['tradingPartnerId'].disable();
    });

    this.form.controls['isEdi'].valueChanges.pipe(
      takeUntil(this.destroy$),
      filter(isEdi => isEdi))
      .subscribe((_) => {
        this.form.controls['tradingPartnerId'].enable();
    });

    // listen for hq and remittance address validation in progress so we can emit subject to trigger validation in address-lib
    this._store.pipe(
      select(getHeadquarterAddressValidationInProgress),
      takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.validateHeadquarterAddressOnSave.next();
        }
      });

    this._store.pipe(
      select(selectIsSavingContact),
      takeUntil(this.destroy$))
      .subscribe((value) => {
        this.isSavingContact = value;
      });
    this._store.pipe(
      select(selectNewlyAddedContact),
      takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value != null) {
          this.onSelectHeadquarterAddress(value);
        }
      });
    this._store.pipe(
      select(selectSaveContactError),
      takeUntil(this.destroy$))
      .subscribe((value) => {
        this.saveContactError = value;
      });
  }

  ngOnDestroy() {
    this._store.dispatch(setValidationErrors({ errors: [] }));
    super.ngOnDestroy();
  }

  private setUserPermissions() {
    this.canEditEdi = this._permissionsService.userHasAction(Constants.UserActions.EdiUpdate);
    this.canEditSummary = this._permissionsService.userHasAction(Constants.UserActions.SupplierGeneralSummaryUpdate);
    this.canEditSupplierHeadquarters =
      this._permissionsService.userHasAction(Constants.UserActions.SupplierGeneralSupplierHeadquartersUpdate);
  }

  cancel(): void {
    this._store.dispatch(attemptCancelEdit());
  }

  save(): void {
    if (isDraft(this.supplier)) {
      this._store.dispatch(saveGeneralForm());
    } else {
      this._store.dispatch(showNotesModal());
    }
  }

  onHeadquarterAddressSelected(selectedAddress) {
    this.form.controls.headquarterAddress.patchValue(selectedAddress.address);
    this.save();
  }

  onNoAddressSelected(): void {
    this._store.dispatch(generalFormNoAddressSelected());
  }

  getDevelopmentManagerFullName(supplier: SupplierDetail): string {
    return getSupplierDevelopmentManagerFullName(supplier);
  }

  onCategoryManagerChange(e) {
    if (e === undefined) {
      this.form.get('categoryManager.name').setValue('');
    } else {
      const find = this.categoryManagerItems.find(item => item.code === e);
      this.form.get('categoryManager.name').setValue(find.name);
    }
  }

  onSupplierDevelopmentManagerChange(e): void {
    if (e === undefined) {
      this.form.get('supplierDevelopmentManager.name').setValue('');
    } else {
      const find = this.supplierDevManagerItems.find(item => item.code === e);
      this.form.get('supplierDevelopmentManager.name').setValue(find.name);
    }
  }

  onTradingPartnerSelect(e): void {
    this.form.controls['tradingPartnerId'].setValue(e === undefined ? null : e);
  }

  loadTradingPartners(search: string): void {
    if (search.trim() === '') { return; }
    if (this.searchRequest) {
      this.searchRequest.unsubscribe();
    }
    this.tradingPartnerList = [];
    this.isTradingPartnerListLoading = true;
    this.searchRequest = this._supplierDetailService.getTradingPartners(search).pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.tradingPartnerList = response.data;
        this.isTradingPartnerListLoading = false;
      });
  }

  public onFocus(element: any): void {
    element.toggle(true);
  }

  isEdi(): boolean {
    return this.form.controls['isEdi'].value;
  }

  private buildForm(isGeneralTabNotSubmitted: boolean) {
    const addressForm = this.addressFormHeadquarters.createAddressFormGroup();
    addressForm.controls.name.disable();

    this.form = this._supplierFormService.getGeneralForm(this.supplier, addressForm,
      shouldShowAAPFieldForGeneralTab(this.supplier, isGeneralTabNotSubmitted, this.ffAAPTerm));

    if (!this.canEditSupplierHeadquarters) {
      this._supplierFormService.disableAddressFields(this.form);
    }
    this.updateHQFieldState();
    this.validateForm();
    this.initialFormValue = getFormControlValueMap(this.form);
    this.form.markAllAsTouched();

    this.form.get('name').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.form.get('wmsLegalName').setValue(value);
        this.form.get('wmsLegalName').markAsDirty();
        this.ntsLegalNameChange(value);
      });
  }

  updateHQFieldState() {
    if (this.form) {
      let isGeneralTabInReview = isTabInReview(this.onboardingStatuses, SupplierDetailTabs.general);
      let isGeneralTabApproved = isTabApproved(this.onboardingStatuses, SupplierDetailTabs.general);
      if (isGeneralTabInReview || isGeneralTabApproved) {
        this.form.get('headquarterAddressId').disable();
      }
    }
  }

  public legalNameChange(e: any) {
    this.legalNameCounter = this.charCounterUpdate(e.length, this.legalNameMaxLength);
  }

  public ntsLegalNameChange(e?: string) {
    this.ntsLegalNameCounter = this.charCounterUpdate(e?.length ?? 0, this.legalNameMaxLength);
  }

  public hqEmailChange(e: any) {
    this.hqEmailCounter = this.charCounterUpdate(e.length, this.hqEmailMaxLength);
  }

  public charCounterUpdate(length: any, maxLength: number): string {
    return `${length}/${maxLength}`;
  }

  public getStreet2Length() {
    return '30';
  }

  public getAttentionLength() {
    return '25';
  }

  public getCityLength() {
    return '25';
  }

  public getZipCodeLength() {
    return '10';
  }

  private validateForm() {
    if (!isDraft(this.supplier)) {
      markAllFormControlsAsTouched(this.form);
      this._store.dispatch(setValidationErrors({ errors: getFormValidationErrors(this.form) }));
    }
  }

  onSelectHeadquarterAddress(contact: Contact) {
    this.form.get('headquarterAddressId').markAsDirty();
    this.form.get('headquarterAddressId').markAsTouched();
    this.form.get('headquarterAddressId').setValue(contact.uniqueId);
  }

  onSmartyStreetsUseAddress(contact: Contact) {
    this._store.dispatch(addSupplierContact({ contact }));
  }

  onSmartyStreetsEditAddress() {
    this._store.dispatch(clearSupplierContactForm());
  }

  onCloseHeadquarterAddressModal() {
    this._store.dispatch(clearSupplierContactForm());
  }

  onRemoveHeadquarterAddress() {
    this.form.get('headquarterAddressId').markAsDirty();
    this.form.get('headquarterAddressId').markAsTouched();
    this.form.get('headquarterAddressId').setValue(null);
  }

  isThisAddedDraftSupplier() {
    return isAddedDraftSupplier(this.supplier);
  }


  isInvitedDraftSupplier() {
    return isInvitedDraftSupplier(this.supplier);
  }

  // Ship date constraints
  disabledShipDates = (date: Date) => {
    let limit = new Date();
    limit.setDate(limit.getDate() - 1);
    return date.valueOf() < limit.getTime();
  };

  downloadAAPDocumentation() {
    this._store.dispatch(downloadPrivateAssets({ fileName: Constants.AAPDocumentationFile }));
  }
}
