import { Injectable } from '@angular/core';
import { AddressBookFormService } from '../../../services/address-book-form.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailValidators } from '../../../utils/email-validation-utils';
import { InboundRouteType } from '../../../models/dcpg-inbound-route-type';
import { ContactType } from '@app/models/contact';

// this service is used to generate the different forms that
// go along with bulk editing dc product groups. it is mainly
// a utility service.
@Injectable()
export class BulkEditFormsService {
  constructor(
    private readonly _builder: UntypedFormBuilder,
    private readonly _addressBookService: AddressBookFormService
  ) {}

  buildActionSelectionForm(dcValidator: (form: UntypedFormGroup) => any) {
    return this._builder.group({
      distributionCenters: [[], [Validators.required, Validators.minLength(1)]],
      bulkAction: [, Validators.required]
    }, {
      validators: [(form: AbstractControl) => dcValidator(form as UntypedFormGroup)],
    });
  }

  buildBuyerForm() {
    const form = this._builder.group({
      buyerCode: [, Validators.required],
  });

    return form;
  }

  buildDeliveryMethodForm() {
    return this._builder.group({
      deliveryMethodCode: [, Validators.required],
    });
  }

  buildInboundRouteTypeForm() {
    return this._builder.group({
      inboundRouteType: [, Validators.required],
    });
  }

  buildPOFaxForm() {
    return this._builder.group({
      poFax: this._builder.group({
        type: [''],
        fax: ['', Validators.required],
      }),
    });
  }

  buildTotalLeadTimesForm(max = 499) {
    return this._builder.group({
      totalTime: ['', [Validators.required, Validators.min(1), Validators.max(max)]],
    });
  }

  buildDeliveredMethodForm(max = 499) {
    return this._builder.group({
      totalTime: ['', [Validators.required, Validators.min(1), Validators.max(max)]],
      addressBook: this._addressBookService.buildAddressBookForm(ContactType.DeliveredAddress),
    });
  }

  buildPickupDeliveryForm(useZipTransitTime: boolean) {
    return this._builder.group({
      inboundRouteType: [{value : InboundRouteType.LTLDry, disabled: !useZipTransitTime}, [Validators.required]],
      pickupLeadTimes: this._builder.group({
        readyTime: [
          '',
          [Validators.required, Validators.min(1), Validators.max(499)],
        ],
      }),
      addressBook: this._addressBookService.buildAddressBookForm(),
    });
  }

  buildPickupAddressForm() {
    return this._addressBookService.buildAddressBookForm();
  }

  buildDeliveredAddressForm() {
    return this._addressBookService.buildAddressBookForm(ContactType.DeliveredAddress);
  }

  buildPickupContactForm() {
    return this._addressBookService.buildAddressBookForm().get('contact');
  }

  buildChangePickupTypeForm() {
    return this._builder.group({
      totalTime: [, Validators.required],
    });
  }

  buildNotesForm() {
    return this._builder.group({
      notes: ['', Validators.required],
      ticket: [''],
    });
  }

  buildAddPOEmailForm() {
    return this._builder.group({
      isPONotEmpty: [false, [Validators.requiredTrue]],
      poEmails: this._builder.array([
        this._builder.group({
          emailType: [''],
          email: ['', [EmailValidators.domainEmail]]
        })
      ]),
    });
  }

  buildReplacePOEmailForm() {
    return this._builder.group({
      existingPOEmails: ['', Validators.required],
      isPONotEmpty: [false, [Validators.requiredTrue]],
      poEmails: this._builder.array([
        this._builder.group({
          emailType: [''],
          email: ['', [EmailValidators.domainEmail]]
        })
      ]),
    });
  }

  buildRemovePOEmailForm() {
    return this._builder.group({
      existingPOEmails: ['', Validators.required],
    });
  }
}
