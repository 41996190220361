import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

const domainEmail: ValidatorFn = (control: AbstractControl) => {
  const result = Validators.pattern(/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/)(control);
  if (!result) {
    return null;
  }

  return {
    email: true
  }
};

export const EmailValidators = {
  domainEmail
}

