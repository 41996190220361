<div class="row">
  <div class="col" *ngIf="_form">
    <h5>Buyers</h5>
    <p>
    All Distribution Centers must be assigned a buyer 
    ({{_form.controls.cloneToDCs.value.length - (availableDCList$ | async).length}} of {{_form.controls.cloneToDCs.value.length}} DC's Selected).
    </p>
  </div>
</div>

<div *ngIf="_form && availableDCList$ | async as availableDCList">
  <div *ngFor="let formElement of _form.controls.buyers.controls; let i = index">
    <div class="row mt-3 d-flex align-items-start" [formGroup]="formElement">
      <div class="col-12 buyer-element">
        <kendo-formfield>
          <kendo-label [for]="buyerField" text="Buyer"></kendo-label>
          <kendo-combobox
            #buyerField
            formControlName="buyerCode"
            textField="name"
            valueField="code"
            placeholder="Search by Buyer name or number"
            (focus)="onFocus(buyerField)"
            [data]="buyerList$ | async"
            [valuePrimitive]="true"
            [filterable]="true"
            [kendoDropDownFilter]="{ operator: 'contains' }"
          ></kendo-combobox>
          <kendo-formerror
            *ngIf="formElement.controls.buyerCode.errors?.required"
            >Buyer is required.</kendo-formerror
          >
        </kendo-formfield>
      </div>
      <div class="col-12 buyer-element">
        <kendo-formfield>
          <kendo-label text="Distribution Centers"></kendo-label>
          <kendo-multiselect
            [data]="availableDCList"
            [filterable]="true"
            [kendoDropDownFilter]="{
              caseSensitive: false,
              operator: 'contains'
            }"
            textField="label"
            valueField="value"
            formControlName="buyerDCs"
            placeholder="Select Distribution Centers"
          ></kendo-multiselect>
          <kendo-formerror
            *ngIf="formElement.controls.buyerDCs.errors?.required"
          >
            Distribution Centers is required.
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="d-flex justify-content-center px-3 align-self-center"
        [class.mt-3]="!formElement.touched || formElement.valid"
        *ngIf="_form.controls.buyers.controls.length > 1">
        <kehe-phoenix-button
          name="deleteBuyerRow"
          (click)="onRemoveBuyer(i)"
          classAttr="btn-medium btn-default">
          <em class="fas fa-trash-alt"></em>
        </kehe-phoenix-button>
      </div>
    </div>
  </div>
</div>
<kehe-phoenix-button
  name="addBuyerRow"
  classAttr="btn-link btn-medium mt-3"
  iconClassAttr="fas fa-plus"
  [disabled]="(availableDCList$ | async).length < 1"
  (click)="onAddBuyer()"
>
  Add Buyer
</kehe-phoenix-button>
