import { createSelector } from "@ngrx/store";
import { TreatmentsWithConfig, TreatmentWithConfig } from "@splitsoftware/splitio/types/splitio";
import { State } from "../index";
import * as fromFeatureFlag from "./feature-flag.reducer";

export const selectFeatureFlagsState = (state: State) => state.featureFlag;
export const selectFeatureFlags = createSelector(
    selectFeatureFlagsState,
    fromFeatureFlag.getFeatureFlags,
);
export const selectHaveFeatureFlagsBeenFetched = createSelector(
    selectFeatureFlagsState,
    fromFeatureFlag.getHaveFeatureFlagsBeenFetched,
)
export const selectFeatureFlagByKey = (key: string) => createSelector(
    selectFeatureFlags,
    (featureFlags: TreatmentsWithConfig) => featureFlags?.[key]
)
export const selectIsFeatureFlagOn = (key: string) => createSelector(
    selectFeatureFlagByKey(key),
    (featureFlag: TreatmentWithConfig) => {
        if (featureFlag && featureFlag.treatment === 'on') {
            return true;
        } else {
            return false;
        }
    }
);
export const selectFeatureFlagConfig = (key: string) => createSelector(
    selectFeatureFlagByKey(key),
    (featureFlag: TreatmentWithConfig) => {
        if (featureFlag?.config) {
            return JSON.parse(featureFlag.config);
        } else {
            return null;
        }
    }
);

export const selectConfigurableFeatureFlagIfOn = (key: string) => createSelector(
    selectIsFeatureFlagOn(key),
    selectFeatureFlagConfig(key),
    (isFlagOn, flagConfig) => {
        return isFlagOn ? flagConfig : isFlagOn
    }
)
