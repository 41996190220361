import { Component, OnInit } from '@angular/core';
import { Signer } from '../../models/signer';
import { Destroyable } from '../../../../abstract/destroyable';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { cancelDocuSignSigner, sendDocuSignEmail } from '../../store/supplier-detail.actions';
import {
  selectDocuSignSigner,
  selectIsSendingDocuSign,
  selectSendDocuSignError
} from '../../store/supplier-detail.selectors';

@Component({
  selector: 'app-docusign-email-modal',
  templateUrl: './docusign-email-modal.component.html',
  styleUrls: ['./docusign-email-modal.component.scss']
})
export class DocusignEmailModalComponent extends Destroyable implements OnInit {

  alertTypes = AlertTypes;
  alertMessage = 'Only the signee will receive the email from DocuSign containing the link to sign the contract documents.';
  isSending: boolean;

  signer: Signer;
  sendDocuSignError: string;

  constructor(
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this._store
      .pipe(select(selectDocuSignSigner), takeUntil(this.destroy$))
      .subscribe((val) => {
        this.signer = val;
      });
    this._store
      .pipe(select(selectIsSendingDocuSign), takeUntil(this.destroy$))
      .subscribe((val) => {
        this.isSending = val;
      });
    this._store
      .pipe(select(selectSendDocuSignError), takeUntil(this.destroy$))
      .subscribe((val) => {
        this.sendDocuSignError = val;
      });
  }

  closeModal() {
    if (this.isSending) {
      return;
    }
    this._store.dispatch(cancelDocuSignSigner());
  }

  onSendClicked() {
    if (this.isSending) {
      return;
    }
    this._store.dispatch(sendDocuSignEmail());
  }
}
