export enum SupplierAction {
  ApproveGeneralTab = 'Approve General Tab',
  RejectGeneralTab = 'Reject General Tab',
  DiscontinueSupplier = 'Discontinue Supplier',
  ActivateSupplier = 'Activate Supplier',
  ShowRevisionNotes = 'Show Revision Notes',
  DeleteSupplier = 'Delete Supplier',
  RefreshTerms = 'Refresh Terms',
  ExportDPI = 'Send Report to DPI',
}
