import { createAction, props } from '@ngrx/store';
import { Payload } from '../../../../models/payload';
import { CustomerListItem } from '../models/customer-list-item';

import { CustomerListFilter } from '../models/customer-list-filter';
import {
  ICustomerFilterCheckItem,
  ICustomerFilterTerritoryItem,
} from '../models/icustomer-filter-item';
import { IChip } from '@kehe/phoenix-chips';
import { AlertMessage } from '../../../../models/alert-message';
import { ICustomerFilterOption } from '../../models/icustomer-base-filter';

export enum CustomerDepartmentListActions {
  PageLoad = '[Customer Department List] Page Load',
  LoadCustomerList = '[Customer Department List] Load Customer List',
  LoadCustomerListSuccess = '[Customer Department List] Load Customer List Success',
  LoadCustomerListError = '[Customer Department List] Load Customer List Error',
  CustomerListPageChange = '[Customer Department List] Customer List Page Change',
  OpenCustomerFilterPanel = '[Customer Department List] Open Customer Filter Panel',
  CloseCustomerListFilterPanel = '[Customer Department List] Close Customer Filter Panel',
  ApplyFilter = '[Customer Department List] Apply Filters',
  GetTypesList = '[Customer Department List] Get Types List Dropdown Filter Data',
  CustomerListSearchChange = '[Customer Department List] Customer List Search Change',
  CustomerListSortChange = '[Customer Department List] Customer List Sort Change',
  CustomerListItemSelectOne = '[Customer Department List] Customer List Item Select One',
  CustomerListItemSelectAll = '[Customer Department List] Customer List Item Select All',
  GetStatusList = '[Customer Department List] Customer List Get Statuses List',
  GetStatusListSuccess = '[Customer Department List] Customer List Get Statuses List Success',
  ListFilterChipClicked = '[Customer Department List] Customer List Filter Chip clicked',
  ListFilterClearChips = '[Customer Department List] Customer List Filter Clear All Chips clicked',
  GetBusinessAreas = '[Customer Department List] Customer List Get Business Areas List',
  GetBusinessAreasSuccess = '[Customer Department List] Customer List Get Business Areas List Success',
  GetTerritories = '[Customer Department List] Customer List Get Territories List',
  GetTerritoriesSuccess = '[Customer Department List] Customer List Get Territories List Success',
  GetStoreTypes = '[Customer Department List] Customer List Get Store Types List',
  GetStoreTypesSuccess = '[Customer Department List] Customer List Get Store Types List Success',
  GetActivityCodes = '[Customer Department List] Customer List Get Activity Codes List',
  GetActivityCodesSuccess = '[Customer Department List] Customer List Get Activity Codes List Success',
  GetServiceLevels = '[Customer Department List] Customer List Get Service Levels List',
  GetServiceLevelsSuccess = '[Customer Department List] Customer List Get Service Levels List Success',
  GetChains = '[Customer Department List] Customer List Get Chains',
  GetChainsSuccess = '[Customer Department List] Customer List Get Chains Success',
  GetPricingZones = '[Customer Department List] Customer List Get Pricing Zones',
  GetPricingZonesSuccess = '[Customer Department List] Customer List Get Pricing Zones Success',
  ToggleAddCustomersToShowModal = '[Customer Department List] Toggle Add Customers To Show Modal',
  AddCustomersToShow = '[Customer Department List] Add Customers To Show',
  AddCustomersToShowSuccess = '[Customer Department List] Add Customers To Show Success',
  AddCustomersToShowFailure = '[Customer Department List] Add Customers To Show Failure',
  AddCustomerListAlert = '[Customer Department List] Add Customer List Alert',
  RemoveCustomerListAlert = '[Customer Department List] Remove Customer List Alert',
  GetaccountsReceivableRep = '[Customer Department List] Customer List Get Account Recievable Representatives List',
  GetaccountsReceivableRepSuccess = '[Customer Department List] Customer List Account Recievable Representatives List Success',
  GetRoutes = '[Customer Department List] Customer List Get Routes List',
  GetRoutesSuccess = '[Customer Department List] Customer List Get Routes List Success',
  GetRoutesFailure = '[Customer Department List] Customer List Get Routes List Failure',
  GetDeactivationReasonsFilter = '[Customer Department List] Customer List Get Deactivation Reasons',
  GetDeactivationReasonsFilterSuccess = '[Customer Department List] Customer List Get Deactivation Reasons Success',
  GetDeactivationReasonsFilterFailure = '[Customer Department List] Customer List Get Deactivation Reasons Failure',
}

export const pageLoad = createAction(CustomerDepartmentListActions.PageLoad);

export const loadCustomerList = createAction(
  CustomerDepartmentListActions.LoadCustomerList
);

export const loadCustomerListSuccess = createAction(
  CustomerDepartmentListActions.LoadCustomerListSuccess,
  props<{ customers: Payload<Array<CustomerListItem>> }>()
);

export const loadCustomerListError = createAction(
  CustomerDepartmentListActions.LoadCustomerListError
);

export const customerListSearchChange = createAction(
  CustomerDepartmentListActions.CustomerListSearchChange,
  props<{ search: string }>()
);

export const customerListPageChange = createAction(
  CustomerDepartmentListActions.CustomerListPageChange,
  props<{ pageCount: number; pageIndex: number, skip: number }>()
);

export const customerListSortChange = createAction(
  CustomerDepartmentListActions.CustomerListSortChange,
  props<{ orderNumb: any; dir: any }>()
);

export const customerListItemSelectOne = createAction(
  CustomerDepartmentListActions.CustomerListItemSelectOne,
  props<{ id: number }>()
);

export const customerListItemSelectAll = createAction(
  CustomerDepartmentListActions.CustomerListItemSelectAll,
);

export const openCustomerListFilter = createAction(
  CustomerDepartmentListActions.OpenCustomerFilterPanel
);

export const closeCustomerListFilter = createAction(
  CustomerDepartmentListActions.CloseCustomerListFilterPanel
);

export const applyFilter = createAction(
  CustomerDepartmentListActions.ApplyFilter,
  props<{ customerListFilter: CustomerListFilter }>()
);

export const getTypesList = createAction(
  CustomerDepartmentListActions.GetTypesList
);

export const getStatusList = createAction(
  CustomerDepartmentListActions.GetStatusList
);

export const getStatusListSuccess = createAction(
  CustomerDepartmentListActions.GetStatusListSuccess,
  props<{ items: ICustomerFilterCheckItem[] }>()
);

export const listFilterChipClicked = createAction(
  CustomerDepartmentListActions.ListFilterChipClicked,
  props<{ chip: IChip }>()
);

export const listFilterClearChips = createAction(
  CustomerDepartmentListActions.ListFilterClearChips
);

export const getBusinessAreas = createAction(
  CustomerDepartmentListActions.GetBusinessAreas
);

export const getBusinessAreasSuccess = createAction(
  CustomerDepartmentListActions.GetBusinessAreasSuccess,
  props<{ list: ICustomerFilterOption[] }>()
);

export const getTerritories = createAction(
  CustomerDepartmentListActions.GetTerritories
);

export const getTerritoriesSuccess = createAction(
  CustomerDepartmentListActions.GetTerritoriesSuccess,
  props<{ list: ICustomerFilterTerritoryItem[] }>()
);

export const getRoutes = createAction(
  CustomerDepartmentListActions.GetRoutes
);

export const getRoutesSuccess = createAction(
  CustomerDepartmentListActions.GetRoutesSuccess,
  props<{ list: ICustomerFilterOption[] }>()
);

export const getRoutesFailure = createAction(
  CustomerDepartmentListActions.GetRoutesFailure,
  props<{ errorMessage: string }>()
);

export const getDeactivationReasonsFilter = createAction(
  CustomerDepartmentListActions.GetDeactivationReasonsFilter
);

export const getDeactivationReasonsFilterSuccess = createAction(
  CustomerDepartmentListActions.GetDeactivationReasonsFilterSuccess,
  props<{ list: ICustomerFilterOption[] }>()
);

export const getDeactivationReasonsFilterFailure = createAction(
  CustomerDepartmentListActions.GetDeactivationReasonsFilterFailure,
  props<{ errorMessage: string }>()
);

export const getDcCustomerNumbers = createAction(
  '[Customer Department List] Customer List Get DcCustomerNumbers List',
  props<{ searchTerm: string }>(),
);

export const getDcCustomerNumbersSuccess = createAction(
  '[Customer Department List] Customer List Get DcCustomerNumbers List Success',
  props<{ list: ICustomerFilterOption[] }>()
);

export const getDcCustomerNumbersFailure = createAction(
  '[Customer Department List] Customer List Get DcCustomerNumbers List Failure'
);

export const uploadCustomersSuccess = createAction(
  '[Customer Department List] Customer List Upload Customers Success'
);

export const uploadCustomersFailure = createAction(
  '[Customer Department List] Customer List Upload Customers Failure',
  props<{ errorMessage: string }>()
);

export const updateCustomersSuccess = createAction(
  '[Customer Department List] Customer List Update Customers Success'
);

export const updateCustomersFailure = createAction(
  '[Customer Department List] Customer List Update Customers Failure',
  props<{ errorMessage: string }>()
);

export const getStoreTypes = createAction(
  CustomerDepartmentListActions.GetStoreTypes
);

export const getStoreTypesSuccess = createAction(
  CustomerDepartmentListActions.GetStoreTypesSuccess,
  props<{ list: ICustomerFilterOption[] }>()
);

export const getActivityCodes = createAction(
  CustomerDepartmentListActions.GetActivityCodes
);

export const getActivityCodesSuccess = createAction(
  CustomerDepartmentListActions.GetActivityCodesSuccess,
  props<{ list: ICustomerFilterOption[] }>()
);

export const getServiceLevels = createAction(
  CustomerDepartmentListActions.GetServiceLevels
);

export const getServiceLevelsSuccess = createAction(
  CustomerDepartmentListActions.GetServiceLevelsSuccess,
  props<{ list: ICustomerFilterOption[] }>()
);

export const getChains = createAction(CustomerDepartmentListActions.GetChains);

export const getChainsSuccess = createAction(
  CustomerDepartmentListActions.GetChainsSuccess,
  props<{ list: ICustomerFilterOption[] }>()
);

export const getPricingZones = createAction(CustomerDepartmentListActions.GetPricingZones);

export const getPricingZonesSuccess = createAction(
  CustomerDepartmentListActions.GetPricingZonesSuccess,
  props<{ list: ICustomerFilterOption[] }>()
);

export const openCustomerCloneModalClicked = createAction(
  '[Customer Department List] Customer List Open Clone Modal Clicked',
);

export const exportSummaryClicked = createAction(
  '[Customer Department List] Customer List Summary Export Clicked'
);

export const exportSummaryCustomerListSuccess = createAction(
  '[Customer Department List] Customer List Export Success',
  props<{ link: string }>()
);
export const exportSummaryCustomerListFailure = createAction(
  '[Customer Department List] Customer List Export Failure'
);

export const exportDetailedClicked = createAction(
  '[Customer Department List] Customer List Detailed Export Clicked'
);

export const exportDetailedSuccess = createAction(
  '[Customer Department List] Customer List Detailed Export Success',
);

export const exportDetailedFailure = createAction(
  '[Customer Department List] Customer List Detailed Export Failure',
  props<{ error: string }>()
);

export const uploadCustomersClicked = createAction(
  '[Customer Department List] Upload Customers Clicked'
);

export const updateCustomersClicked = createAction(
  '[Customer Department List] Update Customers Clicked'
);

export const getaccountsReceivableRep = createAction(
  CustomerDepartmentListActions.GetaccountsReceivableRep
);

export const getaccountsReceivableRepSuccess = createAction(
  CustomerDepartmentListActions.GetaccountsReceivableRepSuccess,
  props<{ list: ICustomerFilterOption[] }>()
);

// Adding to Show
export const toggleAddCustomersToShowModal = createAction(
  CustomerDepartmentListActions.ToggleAddCustomersToShowModal
);
export const addCustomersToShow = createAction(
  CustomerDepartmentListActions.AddCustomersToShow,
  props<{ showId: string }>()
);
export const addCustomersToShowSuccess = createAction(
  CustomerDepartmentListActions.AddCustomersToShowSuccess
);
export const addCustomersToShowFailure = createAction(
  CustomerDepartmentListActions.AddCustomersToShowFailure
);

// List Alert
export const addCustomerListAlert = createAction(
  CustomerDepartmentListActions.AddCustomerListAlert,
  props<{ alertMessage: AlertMessage }>()
);

export const removeCustomerListAlert = createAction(
  CustomerDepartmentListActions.RemoveCustomerListAlert
);