import { createReducer, on } from '@ngrx/store';
import {
  SupplierDCPaymentHoldsState,
  initializeState,
} from './supplier-dc-payment-holds.state';
import * as SupplierDcPaymentHoldActions from './supplier-dc-payment-holds.actions'

export const supplierDcPaymentHoldsStateKey = 'supplierDcPaymentHolds';

export const initialState = initializeState();

export const rootReducer = createReducer<SupplierDCPaymentHoldsState>(
  initialState,
  on(
    SupplierDcPaymentHoldActions.userLandedOnDcPaymentEditPage,
    SupplierDcPaymentHoldActions.userResetDcPaymentHoldChanges,
    (state) => ({
      ...state,
      changedDcPaymentHolds: []
    })
  ),
  on(SupplierDcPaymentHoldActions.userChangedDcPaymentHoldForm, (state, { changedPaymentHolds }) => ({
    ...state,
    changedDcPaymentHolds: changedPaymentHolds
  })),
);

