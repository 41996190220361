<div class="px-4">

  <div class="row">
    <div class="col">
      <h4>Contacts</h4>
    </div>
  </div>
  <div class="row kehe-data-value">
    <div class="col mt-2">
      Create and manage your contacts below. You will need to add all required contacts and any additional contacts that
      you choose.
      You will need to select one of your contacts to be designated as your primary contact.
      You will also need to select a contact to receive Discontinued Product Notifications.
      This can be assigned to your primary contact or any other contact that you choose.
    </div>
  </div>
  <div class="row kehe-data-value mt-2">
    <div class="col-12 col-md-6 mb-3 mb-md-0">
      <div class="row">
        <div class="col-12">
          Required Contacts:
        </div>
      </div>
      <div class="row">
        <div *ngFor="let type of requiredContacts" class="col-12">
          <em class="fas me-2 align-middle"
            [ngClass]="{'fa-check check-icon': type.exist, 'fa-times close-icon': !type.exist}">
          </em>
          {{ type.name }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="row mb-0">
        <div class="col-12">
          Required Designations:
        </div>
      </div>
      <div class="row">
        <div *ngFor="let type of requiredDesignations" class="col-12">
          <em class="fas me-2 align-middle"
            [ngClass]="{'fa-check check-icon': type.exist, 'fa-times close-icon': !type.exist}">
          </em>
          {{ type.name }}
        </div>
      </div>
    </div>
  </div>
  <div class="row kehe-data-value mt-4">
    <div class="col">
      <app-supplier-contacts-grid></app-supplier-contacts-grid>
    </div>
  </div>
</div>