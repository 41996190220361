<div class="px-4">

  <div class="row">
    <div class="col">
      <h4>Summary</h4>
    </div>
  </div>

  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Legal Name
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(supplier?.name)}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Doing Business As (dba)
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(supplier?.doingBusinessAs)}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          NTS Legal Name
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(supplier?.wmsLegalName)}}
        </div>
      </div>
      <div class="row" *ngIf="isThisInvitedSupplier() && isInvitedByRetailer()">
        <div class="col-12 col-sm-6 kehe-data-label">
          Retailer
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(requiredRetailer)}}
        </div>
      </div>
      <div class="row" *ngIf="isRetailerNotFound && isThisInvitedSupplier() && isInvitedByRetailer()">
        <div class="col-12 col-sm-6 kehe-data-label">
          Comments
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(supplier?.preferredCustomerComments)}}
        </div>
      </div>

      <div class="row" *ngIf="isSupplierInDraft() && isThisInvitedSupplier()">
        <div class="col-12 col-sm-6 kehe-data-label">
          KeHE Ship Date to Retailer(s)
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          <span class="date">{{ supplier?.keheShipToRetailerDate | date:'MM/dd/yyyy' | emptyValue }}</span>
        </div>
      </div>
        <div class="row" *ngIf="shouldShowAAPField">
            <div class="col-12 col-sm-6 kehe-data-label">
                Request to join AAP
                <em class="fas fa-info-circle info-tooltip" keheTooltipPlacement="right" [keheTooltip]="tooltipAAP"></em>
            </div>
            <div class="col-12 col-sm-6 kehe-data-value">
                {{ supplier?.administrativeAllowanceProgramRequested | displayYesNo | emptyValue }}
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Category Manager
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(categoryManagerFullName)}} ({{defaultIfEmpty(supplier?.categoryManagerCode)}})
          <div>{{defaultIfEmpty(supplier?.categoryManagerEmail)}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Supplier Dev Manager
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(developmentManagerFullName)}} ({{defaultIfEmpty(supplier?.supplierDevelopmentManagerCode)}})
          <div>{{defaultIfEmpty(supplier?.supplierDevelopmentManagerEmail)}}</div>
        </div>
      </div>
      <div class="row" *ngIf="isThisInvitedSupplier()">
        <div class="col-12 col-sm-6 kehe-data-label">
          Requested By
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(requestedBy())}}
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          International Currency Supplier
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{ supplier.hasInternationalCurrency ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Supplier Currency
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{ supplier.supplierCurrency ? supplier.supplierCurrency.value : 'USD' }}
        </div>
      </div>
    </div>
  </div>
</div>

<hr>

<div class="px-4">
  <div class="row">
    <div class="col">
      <h4>Supplier Headquarters</h4>
    </div>
  </div>
  <div class="row kehe-data-section" *ngIf="showContactCard">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Address
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          <div *ngIf="supplier.headquarterAddressContact; else elseBlock">
            <div class="address-content">{{ getAddressText() }}</div>
            <div class="address-content mt-2" *ngIf="getContactText() !== null">{{ getContactText() }}</div>
          </div>
          <ng-template #elseBlock>
            --
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row kehe-data-section" *ngIf="!showContactCard">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Attention
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(supplier?.headquarterAddress?.attention)}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Address
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          <div *ngIf="supplier?.headquarterAddress">
            <div *ngIf="supplier?.headquarterAddress?.formattedAddress else standardHeadquarterAddress">
              <div *ngFor="let line of supplier.headquarterAddress.formattedAddress.split('|')">
                {{line}}
              </div>
            </div>
            <ng-template #standardHeadquarterAddress>
              <div>{{supplier?.headquarterAddress?.street1}}</div>
              <div>{{supplier?.headquarterAddress?.street2}}</div>
              <div>{{supplier?.headquarterAddress?.city}}
                {{supplier?.headquarterAddress?.state?.code || supplier?.headquarterAddress?.province}}
                {{supplier?.headquarterAddress?.zipCode}}</div>
            </ng-template>
            <div>{{supplier?.headquarterAddress?.country?.code}}</div>
          </div>
          <span *ngIf="!supplier?.headquarterAddress">--</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Phone
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          <span>{{defaultIfEmpty(supplier?.headquarterAddress?.phone | phoneNumber)}}</span>
          <span *ngIf="supplier?.headquarterAddress?.extension">, Ext {{supplier?.headquarterAddress?.extension}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Fax
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(supplier?.headquarterAddress?.fax | phoneNumber)}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Email
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(supplier?.headquarterAddress?.email)}}
        </div>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="showSupplierRepresentative()">

<div class="px-4" *ngIf="showSupplierRepresentative()">
  <div class="row">
    <div class="col">
      <h4>Supplier Representative</h4>
    </div>
  </div>
  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Name
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(supplier?.addedSupplierRepresentative?.name)}}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Email
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(supplier?.addedSupplierRepresentative?.email)}}
        </div>
      </div>
    </div>
  </div>
</div>

<hr>

<div class="px-4" *ngIf="isSupplierInDraft() && isThisInvitedSupplier()">

  <div class="row">
    <div class="col">
      <h4>W9 Form</h4>
    </div>
  </div>

  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          W9 Form
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          <a (click)="downloadW9()" *ngIf="w9Document?.filename; else defaultIfEmptyValue">
            {{defaultIfEmpty(w9Document?.filename)}}
          </a>
          <ng-template #defaultIfEmptyValue>
            --
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="isSupplierInDraft() && isThisInvitedSupplier()">

<div class="px-4">

  <div class="row">
    <div class="col">
      <h4>Electronic Data Interchange (EDI)</h4>
    </div>
  </div>

  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          EDI Customer
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{supplier?.isEdi | displayYesNo}}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Trading Partner ID
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{supplier?.tradingPartnerId | emptyValue}}
        </div>
      </div>
    </div>
  </div>
</div>
<app-general-tab-status-update-modal *ngIf="showStatusChangeConfirmation"></app-general-tab-status-update-modal>
