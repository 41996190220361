import {
  ICreateDocuments,
  ICreateDocumentsPayload,
  ICreateDocumentsResponse,
  IDocumentUpload,
  IDocumentUploadStatusResponse
} from '../interfaces/upload-documents.interface';
import { DocumentUploadStatus } from '../enums/document-upload-status.enum';
import { IAnnouncementDetails } from '../../interfaces/announcement-details.inteface';
import { IAnnouncementDocument } from '../../interfaces/announcement-document.interface';

export const convertCreateDocumentsDataIntoPayload = (data: ICreateDocuments): ICreateDocumentsPayload =>
  data.files.map(file => ({
    documentType: data.documentType,
    fileName: file.name,
    fileSize: file.size
  }));

export const buildDocumentUploads = (data: ICreateDocuments, documents: ICreateDocumentsResponse): IDocumentUpload[] =>
  documents.map((document, index) => ({
    documentType: data.documentType,
    fileName: document.originalFileName,
    mediaUrl: document.preSignedUrl,
    documentGuid: document.documentGuid,
    status: DocumentUploadStatus.InProgress,
    file: data.files[index].rawFile
  }));

export const updateDocumentUploadsStatuses = (
  uploads: IDocumentUpload[],
  updates: IDocumentUploadStatusResponse
): IDocumentUpload[] =>
  uploads.map(upload => ({
    ...upload,
    status: updates.find(update => update.documentGuid === upload.documentGuid).status
  }));

export const moveCompleteUploadsToAnnouncement = (
  uploads: IDocumentUpload[],
  updates: IDocumentUploadStatusResponse,
  announcement: IAnnouncementDetails
): {
  documentUploads: IDocumentUpload[],
  announcementDetails: IAnnouncementDetails
} => {
  const completeUploads = updates.filter(update => update.status === DocumentUploadStatus.Complete);
  const completeUploadsGuids = completeUploads.map(update => update.documentGuid);
  const completeUploadsDocuments = completeUploads.map(update => update.document);

  const documentUploads = uploads.filter(upload => !completeUploadsGuids.includes(upload.documentGuid));
  const documents = [ ...completeUploadsDocuments, ...announcement.documents ];

  return { documentUploads, announcementDetails: { ...announcement, documents } };
};

export const removeDocumentFromAnnouncementDetails = (
  announcementDetails: IAnnouncementDetails | null,
  document: IAnnouncementDocument | null
): IAnnouncementDetails | null => {
  if (!announcementDetails || !document) {
    return announcementDetails;
  }

  return {
    ...announcementDetails,
    documents: announcementDetails.documents.filter(item => item.id !== document.id)
  };
};
