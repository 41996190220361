import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyValueBlank',
  standalone: true,
})
export class EmptyValueBlankPipe implements PipeTransform {
  transform(value: any, args?: any): string | number {
    const mask = args ? args : '';

    if (!value || (Array.isArray(value) && !value.length)) {
      return mask;
    }

    return value;
  }
}
