import { Component, OnInit } from '@angular/core';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import { selectAddUserError, selectAddUserInProgress, selectUsers, } from '../../store/supplier-users.selectors';
import { closeAddUserModal, requestAddUser, } from '../../store/supplier-users.actions';
import { ProgressTrackerStepStates } from '@kehe/phoenix-progress-tracker';
import { SupplierUserService } from '../../services/supplier-user.service';
import { catchError, map, take } from 'rxjs/operators';
import { User } from '../../models/user';
import { environment } from '../../../../../environments/environment';
import { UserDetails } from '../../models/user-details';

@Component({
  selector: 'app-add-user-wizard',
  templateUrl: './add-user-wizard.component.html',
  styleUrls: ['./add-user-wizard.component.scss'],
})
export class AddUserWizardComponent extends Destroyable implements OnInit {
  readonly alertTypes = AlertTypes;
  users: User[] = [];

  get emailField() {
    return this.form ? this.form.get('email') : null;
  }
  get firstNameField() {
    return this.form ? this.form.get('firstName') : null;
  }
  get lastNameField() {
    return this.form ? this.form.get('lastName') : null;
  }

  get steps() {
    return [
      {
        state: this.emailState,
        name: 'Email',
      },
      {
        state: this.userInfoState,
        name: 'User Info',
      },
    ];
  }

  get showHint() {
    return this.form && this.emailState === ProgressTrackerStepStates.Open;
  }

  get emailState() {
    if (this.emailRequestError) {
      return ProgressTrackerStepStates.Error;
    }

    if (this.loading) {
      return ProgressTrackerStepStates.Loading;
    }

    if (this.form && (this.emailField.dirty || this.emailField.touched)) {
      if (this.emailField?.errors) {
        return ProgressTrackerStepStates.Error;
      }

      if (this.emailField.valid) {
        return ProgressTrackerStepStates.Completed;
      }
    }
    return ProgressTrackerStepStates.Open;
  }

  get userInfoState() {
    if (this.userRequestError) {
      return ProgressTrackerStepStates.Error;
    }

    if (this.adding) {
      return ProgressTrackerStepStates.Loading;
    }

    if (this.prepopulatedUser) {
      return ProgressTrackerStepStates.Completed;
    }

    if (this.firstNameField.touched || this.lastNameField.touched) {
      if (this.form && this.form.invalid) {
        return ProgressTrackerStepStates.Error;
      } else {
        return ProgressTrackerStepStates.Completed;
      }
    }

    return ProgressTrackerStepStates.Open;
  }

  get isNextButtonEnabled() {
    if (this.form && this.emailField.valid) {
      return true;
    }
    return false;
  }

  form: UntypedFormGroup;
  loading = false;
  adding = false;
  canShowUserInfo = false;
  prepopulatedUser = false;
  emailRequestError?: string = null;
  userRequestError?: string = null;

  get errorAlert() {
    if (this.emailRequestError) {
      return this.emailRequestError;
    }
    if (this.userRequestError) {
      return this.userRequestError;
    }
    if (this.form && this.form.touched) {
      if (this.emailField?.errors?.alreadyExists) {
        return 'User already associated to ESN.';
      }
      if (
        this.emailField?.errors?.nonSupplier ||
        this.emailField?.errors?.keheEmployee
      ) {
        return 'Only suppliers can be added as a supplier user.';
      }
      if (
        this.canShowUserInfo &&
        (this.firstNameField.touched || this.lastNameField.touched) &&
        this.form.invalid
      ) {
        return 'You have required fields below.';
      }
    }
    return null;
  }

  constructor(
    private _store: Store,
    private _formBuilder: UntypedFormBuilder,
    private _userService: SupplierUserService
  ) {
    super();
  }

  ngOnInit() {
    this.buildForm();
    this._store.pipe(select(selectUsers)).subscribe((value) => {
      this.users = value;
    });
    this._store.pipe(select(selectAddUserInProgress)).subscribe((value) => {
      this.adding = value;
    });
    this._store.pipe(select(selectAddUserError)).subscribe((value) => {
      if (value) {
        this.userRequestError = 'An error has occurred. Please try again.';
      } else {
        this.userRequestError = null;
      }
    });
  }

  private buildForm() {
    this.form = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      isAdmin: [false],
    });
  }

  close(event?: { type: string }) {
    if (event && event.type === 'outside-click') {
      return;
    }

    if (this.loading) {
      return;
    }
    this._store.dispatch(closeAddUserModal());
  }

  back() {
    this.canShowUserInfo = false;
    this.prepopulatedUser = false;
    this.emailRequestError = null;
    this.userRequestError = null;

    this.form.get('firstName').reset('', { emitEvent: false });
    this.form.get('lastName').reset('', { emitEvent: false });
    this.form.get('isAdmin').reset(false, { emitEvent: false });
    this.form.get('isAdmin').enable();
  }

  onAddClicked() {
    if (this.adding || !this.form.valid) {
      return;
    }

    const data = this.form.getRawValue();
    const user = {
      ...data,
      name: `${data.firstName} ${data.lastName}`,
      isAdmin: this.form.get('isAdmin').value,
    };
    this._store.dispatch(requestAddUser({ user }));
  }

  onNextClicked() {
    const email = this.emailField.value;

    if (this.users.some((u) => u.email === email)) {
      this.emailField.setErrors({ alreadyExists: true });
      return;
    }

    if (email.includes('@kehe.com')) {
      this.emailField.setErrors({ keheEmployee: true });
      return;
    }

    this.emailRequestError = null;
    this.loading = true;

    this._userService
      .checkEmailInV1(email)
      .pipe(
        take(1),
        map((type) => {
          if (!type) {
            this.loading = false;
            this.canShowUserInfo = true;
          } else if (type === 'vendor') {
            // check for user details here
            this.getUserInformation(email);
          } else {
            this.loading = false;
            this.emailField.setErrors({ nonSupplier: true });
          }
        }),
        catchError(() => {
          this.emailRequestError = 'An error has occurred. Please try again.';
          this.loading = false;
          return null;
        })
      )
      .subscribe();
  }

  getUserInformation(email: string) {
    this._userService
      .getUserDetail(email)
      .pipe(
        take(1),
        map((response: UserDetails) => {
          const name = response.name;
          const isAdmin = !!response.groups.find(
            (g: any) => g.groupId === environment.vendorAdminGroupId
          );

          if(response.status === 'disabled') {
            this.emailRequestError = 'User could not be added, please reach out to vendorsupport@kehe.com or use a different user email.';
            this.emailField.setErrors({ userDisabled: true });
            this.loading = false;
            return null;
          }

          this.form
            .get('firstName')
            .setValue(name.substr(0, name.lastIndexOf(' ')));
          this.form
            .get('lastName')
            .setValue(name.substr(name.lastIndexOf(' ') + 1, name.length - 1));

          if (isAdmin) {
            this.form.get('isAdmin').setValue(true);
            this.form.get('isAdmin').disable();
          }

          this.prepopulatedUser = true;
          this.loading = false;
          this.canShowUserInfo = true;
        }),
        catchError(() => {
          this.emailRequestError = 'An error has occurred. Please try again.';
          this.loading = false;
          return null;
        })
      )
      .subscribe();
  }
}
