<kehe-phoenix-modal
  modalTitle="Clone DC Product Group(s)"
  [show]="show"
  (modalEvent)="onCancel($event)"
  size="md">
  <div body [formGroup]="form">
    <p>You are cloning DC product group(s), select the DC's you want to clone the DC Product Group to.</p>

    <kendo-formfield>
      <kendo-label text="Clone from DC Product Group"></kendo-label>
      <kendo-textbox class="kehe-readonly-input" formControlName="dcDisplayName" [readonly]="true"></kendo-textbox>
    </kendo-formfield>

    <div class="mt-3">
      <kendo-formfield>
        <kendo-label
          text="Distribution Centers"></kendo-label>
        <kendo-multiselect
          #dc
          [data]="dcList"
          [filterable]="true"
          [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
          textField="label"
          valueField="value"
          formControlName="distributionCenters"
          placeholder="Select Distribution Centers"></kendo-multiselect>
        <kendo-formerror *ngIf="form.controls.distributionCenters.hasError('required')">
          Distribution Centers is required.
        </kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div footer class="d-flex justify-content-end mt-2">
    <kehe-phoenix-button (click)="onCancel()" classAttr="btn-primary btn-medium btn-link mr-3">Cancel</kehe-phoenix-button>
    <kehe-phoenix-button (click)="onClone()" classAttr="btn-primary btn-medium" [disabled]="form.invalid">Clone</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
