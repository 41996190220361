import { CustomerListFilter } from '../customer-list/models/customer-list-filter';
import { isNullOrUndefined } from '../../../common/common-methods';
import { HttpParams } from '@angular/common/http';

export function CustomerFilterToHttpParams(
  customerListFilter: CustomerListFilter
): HttpParams {
  let params = new HttpParams()
    .set('search', customerListFilter.search)
    .set('pagecount', customerListFilter.pageCount.toString())
    .set('pageindex', customerListFilter.pageIndex.toString())
    .set('orderdirection', customerListFilter.orderDirection.toString())
    .set('sortname', customerListFilter.sortName.toString())
    .set(
      'includeavailablecount',
      customerListFilter.includeAvailableCount.toString()
    );

  if (!isNullOrUndefined(customerListFilter.chain)) {
    params = params.append('chain', customerListFilter.chain.code);
  }

  customerListFilter.selectedStatus
    .forEach((status) => {
      params = params.append('status', status.code);
    });

  if (customerListFilter.types.length) {
    customerListFilter.types.forEach((type) => {
      params = params.append('type', type.code);
    });
  }

  if (customerListFilter.businessAreas.length) {
    customerListFilter.businessAreas.forEach((type) => {
      params = params.append('businessArea', type.code);
    });
  }

  if (customerListFilter.distributionCenters.length) {
    customerListFilter.distributionCenters.forEach((type) => {
      params = params.append('distributionCenter', type.value);
    });
  }
  if (customerListFilter.accountsReceivableRep.length) {
    customerListFilter.accountsReceivableRep.forEach((type) => {
      params = params.append('accountsReceivableRep', type.code);
    });
  }
  customerListFilter.status
    .filter((f) => f.checked)
    .forEach((status) => {
      params = params.append('status', status.code);
    });

  if (
    !isNullOrUndefined(customerListFilter.createdFrom) &&
    !isNullOrUndefined(customerListFilter.createdTo)
  ) {
    params = params.append('createdFrom', customerListFilter.createdFrom);
    params = params.append('createdTo', customerListFilter.createdTo);
  }

  if (!isNullOrUndefined(customerListFilter.territory)) {
    params = params.append('territory', customerListFilter.territory.code);
  }

  if (customerListFilter.routes?.length > 0) {
    customerListFilter.routes.forEach((route) => {
      params = params.append('route', route.code);
    });
  }

  if (customerListFilter.dcCustomerNumbers.length) {
    customerListFilter.dcCustomerNumbers.forEach((dcCustomerNumber) => {
      params = params.append('dcCustomerNumber', dcCustomerNumber.code);
    });
  }

  if (customerListFilter.pricingZones.length) {
    customerListFilter.pricingZones.forEach((type) => {
      params = params.append('pricingzone', type.code);
    });
  }

  return params;
}
