import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '@app/services/permissions.service'; 
import { Constants } from '../../../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class SODCustomersManagementGuard  {
    constructor(private permissionsService: PermissionsService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.permissionsService.userHasAction(Constants.UserActions.ShowRead)) {
            return true;
        }
        this.router.navigateByUrl('');
        return false;
    }
}
