import { createAction, props } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { AnnouncementAudience } from '../../../enums/announcement-audience.enum';
import { AnnouncementStatus } from '../../../enums/announcement-status.enum';
import { IAnnouncementListPagination } from '../../interfaces/announcement-list.interface';
import { IAnnouncementAdd } from '../../interfaces/announcement-add.interface';

export const fetchList = createAction(
  '[Announcements List] Fetch List',
);

export const setAudience = createAction(
  '[Announcements List] Set Audience',
  props<{ value: AnnouncementAudience }>()
);

export const setStatus = createAction(
  '[Announcements List] Set Status',
  props<{ value: AnnouncementStatus }>()
);

export const setSorting = createAction(
  '[Announcements List] Set Sorting',
  props<{ value: SortDescriptor[] }>()
);

export const setSearch = createAction(
  '[Announcements List] Set Search',
  props<{ value: string }>()
);

export const setPagination = createAction(
  '[Announcements List] Set Pagination',
  props<{ value: IAnnouncementListPagination }>()
);

export const resetPagination = createAction(
  '[Announcements List] Reset Pagination'
);

export const create = createAction(
  '[Announcements List] Create',
  props<{ data: IAnnouncementAdd }>()
);

export const closeAddModalClicked = createAction(
  '[Announcements List Page] Close Add Modal Clicked'
);

export const deleteAnnouncement = createAction(
  '[Announcements List] Delete Announcement',
  props<{ announcementId: number }>()
);

export const confirmPendingDelete = createAction(
  '[Announcements List] Confirm Pending Delete'
);

export const cancelPendingDelete = createAction(
  '[Announcements List] Cancel Pending Delete'
);
