import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ApiFailureText } from '../../../../../../enum/alert-text.enum';
import { SupplierBrokerService } from '../../../../services/supplier-broker.service';
import * as _ from 'lodash';
import { BrokerContacts } from '../../../../models/broker-contacts';
import { AbstractApiStore } from '@app/abstract/abstract-api.store';

/**
 * `HeadquarterBrokerApiStore` is an Angular service that extends `AbstractApiStore` to get the supplier headquarter broker.
 *
 * @class
 * @extends AbstractApiStore
 *
 * @example
 * const headquarterBrokerApiStore = new HeadquarterBrokerApiStore(brokerDetailService, store);
 *
 * @param {BrokerDetailService} _brokerDetailService - A service that provides broker detail data.
 * @param {Store} _store - A redux store that holds the application state.
 */
@Injectable()
export class BrokerContactsApiStore extends AbstractApiStore<BrokerContacts[]> {
  constructor(private _brokerService: SupplierBrokerService) {
    super();
  }

  loadBrokerContacts$ = this.effect(
    (
      payload$: Observable<
        [
          {
            ebn: string;
            name: string;
          },
          string
        ]
      >
    ) =>
      payload$.pipe(
        tap(() => this.startLoading()),
        switchMap(([broker, search]) => {
          return this._brokerService.getBrokerContacts(broker.ebn, search).pipe(
            tapResponse(
              (contacts) => {
                const mapping = contacts
                  ?.map((contact) => ({
                    ...contact,
                    id: contact.email,
                    title: `${contact.name} (${contact.email})`,
                  }))
                  .sort((a, b) => (a.title > b.title ? 1 : -1));

                if (mapping) {
                  this.setData(_.sortBy(mapping, 'title'));
                }
              },
              () => {
                this.setError(ApiFailureText.Generic);
              }
            )
          );
        })
      )
  );
}
