import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class KeheDeviceDetectorService {

  public deviceInfo: any;
  public isMobile: any;
  public isTablet: any;
  public isDesktop: any;

  constructor(
    public deviceService: DeviceDetectorService
  ) {
    this.getDeviceInfo();
  }

  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }

}
