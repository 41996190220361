import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeheErrorConfig, KeheErrorMessages, KeheErrorSizes } from '@kehe/phoenix-errors';

@Component({
  selector: 'app-unauthorized-page',
  template:
    `
    <kehe-phoenix-error-panel class="margin-top-18" [config]="permissionsConfig">
      <ng-container body>
        <p>
          The requested page requires authorization for access. If you need help, please contact us.
        </p>
        <kehe-phoenix-button (click)="goToHome()" classAttr="btn-medium btn-primary">Home</kehe-phoenix-button>
      </ng-container>
    </kehe-phoenix-error-panel>
  `
})
export class UnauthorizedPageComponent {

  permissionsConfig = new KeheErrorConfig(
    KeheErrorSizes.LG,
    null,
    'fas fa-lock',
    KeheErrorMessages.NoPermission,
    null
  );

  constructor(
    public router: Router,
  ) { }

  goToHome() {
    this.router.navigate(['/']);
  }

}
