import { Component } from '@angular/core';
import { DocumentEditModalStore } from '@app/pages/document-list-page/store/document-edit-modal.store';
import { IFileUpload } from '@kehe/phoenix-upload';

@Component({
  selector: 'app-document-view-edit-modal',
  templateUrl: './document-view-edit-modal.component.html',
  styleUrls: ['./document-view-edit-modal.component.scss'],
})
export class DocumentViewEditModalComponent {

  vm$ = this._store.vm$;
  formGroup = this._store.formGroup;

  constructor(
    private _store: DocumentEditModalStore
  ) {

  }

  cancel(): void {
    this._store.cancel();
  }

  save(): void {
    this._store.save$();
  }

  onFileChanged(file: IFileUpload): void {
    this._store.fileChanged(file);
  }

}
