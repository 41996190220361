import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Destroyable } from '@app/abstract/destroyable';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as DistributionCenterSelectors from '@app/shared/state/distribution-center/distribution-center.selectors';
import { DropdownOption } from '@app/models/dropdown-option';
import {
  DocumentCreateMode,
  allowedDocumentFileExtensions,
  documentDepartmentList,
  documentFileTypes,
  documentStoreTypes,
  getDocumentTypesForMode
} from '@app/pages/document-list-page/store/document-utils';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { IFileUpload } from '@kehe/phoenix-upload';

@Component({
  selector: 'app-document-edit-form',
  templateUrl: './document-edit-form.component.html',
  styleUrls: ['./document-edit-form.component.scss']
})
export class DocumentEditFormComponent extends Destroyable implements OnInit, OnChanges {

  @Input()
  formGroup: FormGroup = null;

  @Input()
  fileUploads: IFileUpload[] = [];

  @Input()
  mode: DocumentCreateMode;

  @Input()
  isSproutsOrderGuide: boolean;

  @Output()
  fileChanged: EventEmitter<IFileUpload> = new EventEmitter();

  // Dropdown options
  publicationTypes: { value: string, label: string }[];
  distributionCenters: DropdownOption[];
  storeTypes = documentStoreTypes;
  departmentList = documentDepartmentList;
  fileTypes = documentFileTypes;

  // Upload component config
  uploadRestrictions: FileRestrictions = {
    allowedExtensions: allowedDocumentFileExtensions
  };

  @ViewChild('uploadFile') uploadFile: ElementRef<any>;

  constructor(
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select(DistributionCenterSelectors.selectDistributionCentersTrimmedNameAndNumber)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.distributionCenters = response;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.mode && changes?.mode?.currentValue !== changes?.mode?.previousValue) {
      getDocumentTypesForMode(this.mode).pipe(
        takeUntil(this.destroy$)
      ).subscribe((result) => {
        this.publicationTypes = result;
      })
    }
  }

  handleAddFiles(files: IFileUpload[]): void {
    if (files?.length) {
      this.fileChanged.emit(files[0]);
    }
  }

  handleRemoveFiles(): void {
    this.fileChanged.emit(null);
  }

  get isUrlCreate(): boolean {
    return this.mode === DocumentCreateMode.url;
  }

  get isFileCreate(): boolean {
    return this.mode === DocumentCreateMode.file;
  }

}
