<kehe-phoenix-modal name="submitSupplierModal" modalTitle="Activate Supplier" [show]="true" 
(modalEvent)="handleKeheModalEvents($event)" size="md">
  <div body class="submit-modal-body">
    <kehe-phoenix-alert [show]="true" [type]="'warning'" [messageAsHtml]="warningMsg" [stick]="true" [hideCloseIcon]="true"> </kehe-phoenix-alert>
    <div>
      Activating the supplier will set their status to active.
    </div>
  </div>
  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-medium btn-link" (click)="closeModal()">Cancel</kehe-phoenix-button>
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-primary btn-medium" (click)="submit()">Activate</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>