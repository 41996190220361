import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierDCPaymentHoldsState } from './supplier-dc-payment-holds.state';
import { supplierDcPaymentHoldsStateKey } from './supplier-dc-payment-holds.reducer';
import { selectSupplierDetail } from '@app/modules/supplier-detail/store/supplier-detail.selectors';
import { selectDoesUserHaveAction } from '@app/shared/state/authentication/authentication.selectors';
import { Constants } from '@app/constants/constants';

const selectSupplierDcPaymentHoldState =
  createFeatureSelector<SupplierDCPaymentHoldsState>(
    supplierDcPaymentHoldsStateKey,
  );

export const selectSupplierDcPaymentHoldsChangedPaymentHolds = createSelector(
  selectSupplierDcPaymentHoldState,
  (state) => state.changedDcPaymentHolds,
);

export const selectCanUserEditDcPaymentHolds = createSelector(
  selectSupplierDetail,
  selectDoesUserHaveAction(Constants.UserActions.SupplierDcPaymentHoldUpdate),
  (detail, hasPermission) => {
    return detail?.hasDcPaymentHoldDetails && hasPermission;
  },
);
