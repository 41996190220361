import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import { hideSupplierStatusChangeModal } from '../../store/supplier-detail.actions';

@Component({
  selector: 'app-supplier-status-change-modal',
  templateUrl: './supplier-status-change-modal.component.html',
  styleUrls: ['./supplier-status-change-modal.component.scss']
})

export class SupplierStatusChangeModalComponent extends Destroyable implements OnInit {
  constructor( private _store: Store,
    private _formBuilder: UntypedFormBuilder,
    ) {
    super();
   }

  @ViewChild('discontinueReasonField') public discontinueReasonField: any;

  @Input()
  currentTab: string;

  @Input()
  supplierStatusActive: boolean;

  @Input()
  header: string;

  @Input()
  msg: string;

  @Input()
  submitButton: string;

  public statusChangeReason: string;
  public isStatusChangeInProgress = false;
  public characterCount = 0;
  public maxLength = 250;
  public counter = `${this.characterCount}/${this.maxLength}`;
  public displayError: boolean;
  public discontinueReasonList = ['Discontinued - KeHE', 'Discontinued - Name Change/Acquisition', 'Discontinued - Manufacturer'];
  public form: UntypedFormGroup;

  @Output()
  public modalChangeSupplierStatusClicked: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.form = this._formBuilder.group({
      requestReason: [''],
      ticket: [''],
    });
    if (this.supplierStatusActive) {
      this.form.addControl('discontinueReason', this._formBuilder.control('', [Validators.required]));
    }
  }

  public onFocus() {
    this.discontinueReasonField.toggle(true);
  }

  get discontinueReason() {
    return this.form.get('discontinueReason');
  }

  get requestReason() {
    return this.form.get('requestReason');
  }

  get ticket() {
    return this.form.get('ticket');
  }

  public changeSupplierStatus() {
    if (this.isStatusChangeDisabled()) {
      return;
    }
    this._store.dispatch(hideSupplierStatusChangeModal());
    const e: any = {};
    if (this.supplierStatusActive) {
      e.ticket = this.ticket.value;
      e.status = this.discontinueReason.value;
      e.requestReason = this.requestReason.value;
      e.currentTab = this.currentTab;
      e.isActive = false;
    } else {
      e.requestReason = this.requestReason.value;
      e.currentTab = this.currentTab;
      e.status = 'Active';
      e.ticket = this.ticket.value;
      e.isActive = true;
    }

    this.modalChangeSupplierStatusClicked.emit(e);
  }

  public isStatusChangeDisabled() {
    if (this.supplierStatusActive) {
      const dropdownValid = this.requestReason.value.length === 0 || !this.requestReason.dirty || this.displayError;
      return !this.form.valid || dropdownValid;
    } else {
      return this.requestReason.value.length === 0 || !this.requestReason.dirty;
    }
  }

  cancelModal() {
    this._store.dispatch(hideSupplierStatusChangeModal());
  }

  handleKeheModalEvents(e) {
    if (e.type === 'close') {
      this.cancelModal();
    }
  }

  handleTextAreaBlur(e: any) {
    this.requestReason.markAsTouched();
    if (this.requestReason.value.length === 0) {
      this.form.controls.requestReason.setErrors({required: true});
    }
  }


  public onValueChange(e: any) {
    this.characterCount = e.length;
    this.counter = `${this.characterCount}/${this.maxLength}`;
    this.requestReason.markAsTouched();
    this.requestReason.markAsDirty();

    if (this.requestReason.value.length === 0 && this.requestReason.dirty) {
      this.form.controls.requestReason.setErrors({required: true});
    }
    if (this.characterCount > 250) {
      this.displayError = true;
    } else {
      this.displayError = false;
    }
  }
}
