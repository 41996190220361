<kehe-phoenix-modal name="SupplierStatusChangeModal" modalTitle={{header}} [show]="true"
  (modalEvent)="handleKeheModalEvents($event)" size="md">
  <div body class="add-note-body">
    <kehe-phoenix-alert [show]="true" [type]="'warning'" [message]="msg" [stick]="true" [hideCloseIcon]="true"></kehe-phoenix-alert>
    <div [formGroup]="form">
      <div class="form-group">
        <kendo-formfield *ngIf="supplierStatusActive" class="mb-3">
          <kendo-label [for]="discontinueReasonField" text="Discontinue Reason"></kendo-label>
          <kendo-combobox #discontinueReasonField [data]="discontinueReasonList" formControlName="discontinueReason"
            placeholder="Select a Discontinue Reason" required (focus)="onFocus()" [kendoDropDownFilter]="{operator: 'contains'}" required>
          </kendo-combobox>
          <kendo-formerror *ngIf="discontinueReason?.errors?.required">Discontinue Reason is required.</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="form-group">
        <div class="mb-3">
          <kendo-label [for]="ticketNumberField" text="Ticket Number (optional)"></kendo-label>
          <kendo-textbox #ticketNumberField
            placeholder="e.g. CWI-867-5309"
            formControlName="ticket"
          ></kendo-textbox>
      </div>
    </div>
    <div class="form-group">
      <kendo-formfield>
        <kendo-label [for]="requestReasonField" text="Request Reason"></kendo-label>
        <kendo-textarea #requestReasonField formControlName="requestReason" (blur)="handleTextAreaBlur($event)" [maxlength]="maxLength" placeholder="Enter a Request Reason" [resizable]="'none'" (valueChange)="onValueChange($event)">
          <kendo-textarea-suffix class="d-flex justify-content-end kehe-textarea-counter">
            <span class="ms-auto">{{ counter }}</span>
          </kendo-textarea-suffix>
        </kendo-textarea>
        <kendo-formerror *ngIf="requestReason?.errors?.required">Request Reason is required.</kendo-formerror>
        <kendo-formerror *ngIf="!requestReason?.errors?.required && displayError">Maximum of {{maxLength}} characters</kendo-formerror>
      </kendo-formfield>
      </div>
    </div>
  </div>
  <div footer class="d-flex justify-content-end status-change">
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-medium btn-link" (click)="cancelModal()">Cancel</kehe-phoenix-button>
    <kehe-phoenix-button class="modal-btn-spacing" [classAttr]="supplierStatusActive ? 'btn-medium btn-secondary' : 'btn-medium btn-primary'" (click)="changeSupplierStatus()" [disabled]="isStatusChangeDisabled() || isStatusChangeInProgress" [spinner]="isStatusChangeInProgress">{{submitButton}}</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>