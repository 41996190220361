<div class="kehe-grid no-scroll p-0 w-100">
  <kendo-grid [data]="contacts" [sortable]="{
              allowUnsort: false,
              mode: 'single'
            }" [sort]="sort" (sortChange)="sortChange($event)">

    <kendo-grid-column title="" filter="string" [width]="42">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="d-flex justify-content-center">
          <kehe-phoenix-menu-button [menuOptions]="menuOptionMap[dataItem.uniqueId]" btnClass="btn-link"
            *ngIf="!dataItem.isSyncing" title="More Options" (optionSelected)="moreOptionSelected($event, dataItem)">
          </kehe-phoenix-menu-button>
          <div *ngIf="dataItem.isSyncing" class="tooltip-icon">
            <em title="Syncing" class="fas fa-sync-alt fa-spin"></em>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="" filter="string" [width]="42">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="d-flex justify-content-center">
          <em title="Primary Contact" class="fas fa-star" *ngIf="dataItem.isPrimary"></em>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="name" title="Name" [sortable]="true" filter="string" headerClass="kehe-grid-header-hover">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="k-fw-bold">{{ dataItem.name | emptyValue }}</div>
        <div>{{ getContactTypes(dataItem) | emptyValue }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Notifications" filter="string">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="pre-wrap">{{ getNotifications(dataItem) | emptyValue }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="194" title="Phone/Fax/Email" filter="string">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="d-flex flex-row">
          <div>
            P: {{ dataItem.phone | phoneNumber }}
            <ng-container *ngIf="dataItem.phoneExtension">, Ext {{ dataItem.phoneExtension | emptyValue
              }}</ng-container>
          </div>
        </div>
        <div *ngIf="dataItem.fax">F: {{ dataItem.fax | phoneNumber }}</div>
        <div style="word-break: break-all;">
          <a class="email-link" href="mailto:{{dataItem.email}}">E: {{ dataItem.email | emptyValue }}</a>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="" filter="string" [width]="42" [class]="'item-delete-col'"
      [headerClass]="'item-delete-col'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="d-flex justify-content-center">
          <em title="Delete" class="fas fa-trash-alt mx-auto" (click)="deleteContact(dataItem)"></em>
        </div>
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
      <div class="custom-no-results-msg">
        <em class="fas fa-seedling mb-3" aria-hidden="true"></em>
        <div class="custom-msg-container">
          <span class="no-results mb-3">No Contacts</span>
        </div>
      </div>
    </ng-template>

  </kendo-grid>
</div>
<app-manage-contact-modal *ngIf="showManageContactModal"></app-manage-contact-modal>
<app-delete-contact-modal *ngIf="showDeleteContactModal"></app-delete-contact-modal>
<app-assign-primary-contact-modal *ngIf="showAssignPrimaryContactModal">
</app-assign-primary-contact-modal>
<app-assign-disc-products-notification-modal *ngIf="showNotifyDiscProductsModal">
</app-assign-disc-products-notification-modal>