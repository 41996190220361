import { createAction, props } from '@ngrx/store';

export const exportCreditListClicked = createAction(
  '[Credit List - Top Menu] Export Credit List Clicked'
);

export const exportCreditListSuccess = createAction(
  '[Credit List - API] Export Credit List Success'
);

export const exportCreditListError = createAction(
  '[Credit List - API] Export Credit List Error'
);

export const exportCreditDetailsFromListClicked = createAction(
  '[Credit List - Credits Table] Export Credit Details From List Clicked',
  props<{
    creditId: number,
    distributionCenterNumber: string,
    customerNumber: string,
  }>()
);

export const exportCreditDetailsFromListSuccess = createAction(
  '[Credit List - API] Export Credit Details From List Success'
);

export const exportCreditDetailsFromListError = createAction(
  '[Credit List - API] Export Credit Details From List Error',
  props<{ error: any }>()
);

export const exportDetailedCreditListClicked = createAction(
  '[Credit List - Top Menu] Export Detailed Credit List Clicked'
);

export const exportDetailedCreditListSuccess = createAction(
  '[Credit List - API] Export Detailed Credit List Success'
);

export const exportDetailedCreditListError = createAction(
  '[Credit List - API] Export Detailed Credit List Error'
);

export const navigatedAwayFromCredits = createAction(
  '[Credits] Navigated Away From Credits'
);
