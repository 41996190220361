import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { HierarchyService } from "../../../shared/service/hierarchy.service";
import { PhoenixToastService } from "@kehe/phoenix-notifications";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import * as ExportHierarchiesModalActions from './actions';
import { of } from "rxjs";
import { NotificationRef } from "@progress/kendo-angular-notification";
import { getManageProgressNotificationSettings } from "@kehe/phoenix-top-bar";

@Injectable()
export class ExportHierarchiesModalEffects {
    constructor(
        private actions$: Actions,
        private hierarchyService: HierarchyService,
        private toastService: PhoenixToastService,
    ) { }

    exportHierarchiesFile$ = createEffect(() => this.actions$.pipe(
        ofType(ExportHierarchiesModalActions.exportClicked),
        switchMap(() => this.hierarchyService.export().pipe(
            map(() => ExportHierarchiesModalActions.exportFileSuccess()),
            catchError((response) => of(ExportHierarchiesModalActions.exportFileFailure(
                response?.error?.message || 'Something went wrong, please try again.'
            )))
        ))
    ));

    exportHierarchiesFileSuccessToast$ = createEffect(() => this.actions$.pipe(
        ofType(ExportHierarchiesModalActions.exportFileSuccess),
        tap(() => { 
            const notification: NotificationRef = getManageProgressNotificationSettings(
                'File is being exported.', 'info', this.toastService, true
            );
            setTimeout(() => {
              notification.hide();
            }, 5000);
        }),
    ), { dispatch: false });
}