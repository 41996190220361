import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom, startWith } from 'rxjs/operators';
import { associatedSupplierAddButtonClicked, getAssociatedSuppliersSuccess } from './associated-supplier.actions';
import { AssociatedSuppliersTabGridStore } from './associated-suppliers-tab-grid/associated-suppliers-tab-grid.store';
import { UntypedFormControl } from '@angular/forms';
import { selectSupplierEsn } from '../../store/supplier-detail.selectors';
import { Store } from '@ngrx/store';
import { AssociatedSuppliersApiStore } from './api-stores/associated-suppliers.api.store';
import {
  AssociatedSuppliersSortColumn,
  AssociatedSuppliersSortOrder,
} from './models/associated-suppliers-tab.enum';
import { timer } from 'rxjs';
import { MasterVersionApiStore } from './api-stores/master-version-api.store';
import { isNullOrUndefined } from '../../../../../app/common/common-methods';

export interface AssociatedSupplierState {
  openedAddModal: boolean;
  searchTerm: string;
  sortDirection: AssociatedSuppliersSortOrder;
  sortColumn: AssociatedSuppliersSortColumn;
  masterVersionNumber: number;
  deletedEsnsToSync: string[];
  isAddAssociateSupplierAction: boolean;
}

export const initialState: AssociatedSupplierState = {
  openedAddModal: false,
  searchTerm: '',
  sortDirection: null,
  sortColumn: null,
  masterVersionNumber: null,
  deletedEsnsToSync: [],
  isAddAssociateSupplierAction: false
};

@Injectable()
export class AssociatedSupplierTabStore extends ComponentStore<AssociatedSupplierState> {
  constructor(
    private _actions$: Actions,
    private _apiStore: AssociatedSuppliersApiStore,
    private _gridStore: AssociatedSuppliersTabGridStore,
    private _masterVersionApiStore: MasterVersionApiStore,
    private _store: Store
  ) {
    super(initialState);
    this._masterVersionApiStore.getMasterRelationshipVersion$(this._store.select(selectSupplierEsn));
    this.openEditModalEffect$(this._actions$);
    this._setIsSyncing$(this._actions$);
    this._apiStore.loadAssociatedSuppliers$(
      this._loadAssociatedSuppliersTrigger$
    );
    this._listenFormSearchControlTextChange$.subscribe();
    this._masterVersionApiStore.getMasterRelationshipVersion$(this._store.select(selectSupplierEsn));
  }

  searchControl = new UntypedFormControl('');

  public _searchTerm$ = this.select((state) => state.searchTerm).pipe(
    startWith('')
  );

  setDeletedEsnsToSync = this.updater((state, deletedEsnsToSync: string[]) => ({
    ...state,
    deletedEsnsToSync: [...state.deletedEsnsToSync ,...deletedEsnsToSync],
  }));

  setIsAddAssociateSupplierAction = this.updater((state, isAddAssociateSupplierAction: boolean) => ({
    ...state,
    isAddAssociateSupplierAction,
  }));

  public _sortDirection$ = this.select((state) => state.sortDirection);

  public _sortColumn$ = this.select((state) => state.sortColumn);
  public _isAddAssociateSupplierAction$ = this.select((state) => state.isAddAssociateSupplierAction);

  public _masterVersionNumber$ = this.select(
    this._masterVersionApiStore.number$,
    (masterVersionNumber) => masterVersionNumber
  );

  public _hasNoMaster$ = this.select(
    this._masterVersionApiStore.hasNoMaster$,
    (hasNoMaster) => hasNoMaster
  );

  public _deletedEsnsToSync$ = this.select((state) => state.deletedEsnsToSync);

  public _loadAssociatedSuppliersTrigger$ = this.select(
    this._store.select(selectSupplierEsn),
    this._searchTerm$,
    this._sortDirection$,
    this._sortColumn$,
    this._masterVersionNumber$,
    this._deletedEsnsToSync$,
    this._gridStore.isSyncing$,
    this._isAddAssociateSupplierAction$,
    (
      supplierEsn,
      searchTerm,
      sortDirection,
      sortColumn,
      masterVersionNumber,
      deletedEsnsToSync,
      isSyncing,
      isAddAssociateSupplierAction
    ) => ({
      supplierEsn,
      searchTerm,
      sortDirection,
      sortColumn,
      masterVersionNumber,
      deletedEsnsToSync,
      isSyncing,
      isAddAssociateSupplierAction
    })
  );

  readonly shouldEnableBulkActions$ = this.select(
    this._gridStore._selectedRecordsCount$,
    (selectedRecordsCount) => {
      return selectedRecordsCount > 0;
    }
  );

  readonly vm$ = this.select(
    this.state$,
    this._gridStore._selectedRecordsCount$,
    this._gridStore._showDeleteConfirmationModal$,
    this.shouldEnableBulkActions$,
    (
      state,
      selectedRecordsCount,
      showDeleteConfirmationModal,
      shouldEnableBulkActions
    ) => ({
      ...state,
      selectedRecordsCount,
      showDeleteConfirmationModal,
      shouldEnableBulkActions,
    })
  );

  closeAddModal() {
    this.patchState({ openedAddModal: false });
  }

  onAssociatedSupplierDelete(
    masterVersionNumber: number,
    deletedEsns: string[]
  ) {
    this._masterVersionApiStore.setMasterVersionNumber(masterVersionNumber);
    this.clearSearchControl();
    this.setDeletedEsnsToSync(deletedEsns);
    this.setIsAddAssociateSupplierAction(false);
  }

  openEditModalEffect$ = this.effect((actions$: Actions) =>
    actions$.pipe(
      ofType(associatedSupplierAddButtonClicked),
      tap(() => this.patchState({ openedAddModal: true }))
    )
  );

  sortChanged(sort: {
    sortDirection: AssociatedSuppliersSortOrder;
    sortColumn: AssociatedSuppliersSortColumn;
  }) {
    this.patchState({
      sortDirection: sort?.sortDirection,
      sortColumn: sort?.sortColumn,
    });
  }

  showDeleteConfirmationModal() {
    this._gridStore.showDeleteConfirmationModalBulk$();
  }

  clearSearchAndApplyDefaultSort() {
    this.clearSearchControl();
    this.setIsAddAssociateSupplierAction(true);
    this.setSortParams({ sortDirection: null, sortColumn: null });
  }

  setSortParams = this.updater(
    (
      state,
      sort: {
        sortDirection: AssociatedSuppliersSortOrder;
        sortColumn: AssociatedSuppliersSortColumn;
      }
    ) => ({
      ...state,
      sortColumn: sort.sortColumn,
      sortDirection: sort.sortDirection,
    })
  );

  clearSearchControl() {
    this.searchControl?.setValue('');
  }

  // Sync Associated Suppliers when existing version is outdated
  syncAssociatedSuppliers$ = this.effect(() =>
    timer(0, 5000).pipe(
      withLatestFrom(this._apiStore.data$,
        this._masterVersionNumber$,
        this._isAddAssociateSupplierAction$,
        this._hasNoMaster$),
      filter(([t, data, newVersion, isAddAssociateSupplierAction, hasNoMaster]) => {
        const previousVersion = data?.masterVersionNumber;
        return (isAddAssociateSupplierAction && newVersion !== null && previousVersion != newVersion) ||
               (!hasNoMaster && newVersion !== null && previousVersion != newVersion);
      }),
      map(() => {
        this._apiStore.loadAssociatedSuppliers$(
          this._loadAssociatedSuppliersTrigger$
        )
      }
      )
    )
  );

  _setIsSyncing$ = this.effect((actions$: Actions) =>
    actions$.pipe(
      ofType(getAssociatedSuppliersSuccess),
    withLatestFrom(this._apiStore.data$,
      this._masterVersionNumber$,
      this._hasNoMaster$,
      this._gridStore.isSyncing$,
      this._deletedEsnsToSync$,
      this._isAddAssociateSupplierAction$),
    tap(
      ([_, data, newVersion, hasNoMaster, isSyncing, deletedEsnsToSync, isAddAssociateSupplierAction]) => {
        const previousVersion = data?.masterVersionNumber;
        // Stop skeleton loader and reset sync properties when there is no master record for the ESN
        if ((!isAddAssociateSupplierAction && hasNoMaster) || (newVersion !== null && newVersion === previousVersion)) {
            this.resetSyncProperties(isSyncing, deletedEsnsToSync, hasNoMaster);
        } else if (!isNullOrUndefined(newVersion) && !isNullOrUndefined(previousVersion)) {
          // display skeleton loader only for add associate supplier, if there are deleted esns set isSyncing to false
          const isSyncing = !deletedEsnsToSync || deletedEsnsToSync.length === 0 || isAddAssociateSupplierAction;
          this._gridStore.setIsSyncing(isSyncing);
        }
      }
    ))
  );

  // reset properties once the new and existing versions match
  resetSyncProperties(isSyncing: boolean, deletedEsnsToSync: string[], hasNoMaster: boolean) {
    if (isSyncing === true) {
      this._gridStore.setIsSyncing(false);
    }
    if (deletedEsnsToSync?.length > 0 && !hasNoMaster) {
      this.setDeletedEsnsToSync([]);
    }
    this.setIsAddAssociateSupplierAction(false);
  }

  private _listenFormSearchControlTextChange$ =
    this.searchControl.valueChanges.pipe(
      startWith(''),
      tap((searchText) => {
        this._gridStore.patchState({
          selectedEsns: [],
          collapsedEsns: [],
          expandAll: false,
        });

        this.patchState({
          searchTerm: searchText as string,
        });
      })
    );
}
