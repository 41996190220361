import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { delay, map, retry, retryWhen, take } from 'rxjs/operators';
import { CreateV1UserRequest } from '../models/create-v1-user-request';
import { User } from '../models/user';
import { Constants } from '../../../constants/constants';
import { UserDetails } from '../models/user-details';

@Injectable({
  providedIn: 'root',
})
export class SupplierUserService {
  private _url: string;
  private _usersUrl: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.supplierApi + `/api/invite/users`;
    this._usersUrl = environment.usersMicroservice;
  }

  getUserDetail(email: string): Observable<UserDetails> {
    return this.httpClient
      .get<{ data: UserDetails }>(`${this._usersUrl}/${email}/detail`)
      .pipe(
        retry(1),
        map((response) => response.data)
      );
  }

  getUsers(esn: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this._url}/${esn}`).pipe(
      retry(1),
      map((response) => response)
    );
  }

  getUserDetailList(users: User[]): Observable<UserDetails[]> {
    let params = new HttpParams();
    users.forEach((user) => {
      params = params.append('emails', user.email);
    });

    return this.httpClient
      .get<{ data: UserDetails []}>(`${this._usersUrl}/detaillist`, {
        params: params,
      })
      .pipe(
        retry(1),
        map((response) => response.data)
      );
  }

  createUserInV2(esn: string, user: User): Observable<User> {
    const request = {
      ...user,
      esn: esn,
    };
    return this.httpClient.post<User>(this._url, request).pipe(
      map((response) => {
        return response;
      })
    );
  }

  checkEmailInV1(email: string): Observable<string> {
    const request = {
      EmailAddress: email,
    };
    return this.httpClient.post<string>(
      `${environment.v1ConnectApi}user/emailcheck`,
      request
    );
  }

  createUserInV1(user: User): Observable<any> {
    const request: CreateV1UserRequest = new CreateV1UserRequest();
    request.Identifier = Constants.DraftPlaceholderEsn;
    request.Esns = [Constants.DraftPlaceholderEsn];
    if (user.isAdmin) {
      request.Groups = [
        Constants.V1Groups.Vendor,
        Constants.V1Groups.VendorReports,
        Constants.V1Groups.SupplierOnboarding,
        Constants.V1Groups.SupplierOnboardingRedirect,
        Constants.V1Groups.CONNECT_Supplier_Supplier,
      ];
    } else {
      request.Groups = [
        Constants.V1Groups.Vendor,
        Constants.V1Groups.VendorReports,
        Constants.V1Groups.SupplierOnboardingRedirect,
        Constants.V1Groups.CONNECT_Supplier_Supplier,
      ];
    }
    request.FirstName = user.firstName;
    request.LastName = user.lastName;
    request.EmailAddress = user.email.toLowerCase();
    request.Type = 'vendor';
    request.Profile = {
      Title: 'Supplier Employee',
    };
    request.Status = 'draft';

    return this.httpClient.post<any>(
      `${environment.v1ConnectApi}user`,
      request
    ).pipe(delay(3000));
  }

  associateV2Permissions(email: string): Observable<any> {
    return this.httpClient
      .put<any>(
        `${environment.supplierApi}/api/invite/associate-v2-permissions/${email}`,
        {}
      )
      .pipe(retryWhen((errors) => errors.pipe(delay(3000), take(1))));
  }

  assignAdmin(esn: string, user: User): Observable<User> {
    const request = {
      ...user,
      esn: esn,
      isAdmin: true,
    };
    return this.httpClient.put<User>(this._url, request).pipe(
      map((response) => {
        return response;
      })
    );
  }


  getUserIdFromV1(email: string): Observable<number> {
    const request = {
      Status: 'pending',
      Contains: email,
    };
    return this.httpClient.post<any>(
      `${environment.v1ConnectApi}user/filteredstatus`,
      request).pipe(
        map((response) => {
          if (response.Sections) {
            const users = response.Sections.reduce((acc, val) => acc.concat(val.Users), []);
            const user = users.find(item => item.User && item.User.EmailAddress.toLowerCase() === email.toLowerCase());
            if (user) {
              return user.User.Id;
            }
          }
          return null;
        })
      );
  }

  resendRegistrationEmailV1(Id: number): Observable<any> {
    const request = {
      UserActionItems: [
        {
          UserId: Id,
          Note: null,
        }
      ],
    };
    return this.httpClient.post<any>(
      `${environment.v1ConnectApi}user/resend`,
      request
    );
  }

  resendWelcomeEmailV2(esn: string, email: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.supplierApi}/api/resendEmail/type/SupplierInvitation/${esn}/${email}`,
      {}
    );
  }

  deleteUserV1(id: number) {
    return this.httpClient.post(
      `${environment.v1ConnectApi}user/deny`,
      {
        UserActionItems: [
          {
            UserId: id,
            Note: null,
          }
        ]
      })
  }

  deleteUserV2(email: string, esn: string) {
    return this.httpClient.delete(`${environment.supplierApi}/api/invite/users`, { body: { esn, email }});
  }
}
