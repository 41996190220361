import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Constants } from '../../../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class ProductListsManagementGuard  {
    constructor(
        private store: Store,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.CustomListRead)).pipe(
            map(canReadCustomLists => canReadCustomLists || this.router.parseUrl(''))
        );
    }
}