import { PromotionTerms } from './promotion-terms';
import { PaymentTerms } from './payment-terms';
import { Spoils } from './spoils';

export class Terms {
  acceptsCutCaseFee: boolean;
  acceptsNewItemSetupFee: boolean;
  acceptsWarehouseOutOfDateCharge: boolean;
  contractDate: Date;
  changeDate: Date;
  dueDateCalculation: string;
  hasFillRateFee: boolean;
  hasLumpingFee: boolean;
  hasPalletCharge: boolean;
  hasRetailerDiscontinuedProgram: boolean;
  hasRetailerReclamation: boolean;
  hasThirtyPercentOrderCoverage: boolean;
  hasKeheDiscount: boolean;
  palletChargeAmount: number;
  priceChangeNoticeInDays: number;
  shelfLifePercentage: number;
  spoils: Spoils;
  spoliageAllowancePercentage: number;
  newItemSetupFee: number;
  paymentTerms?: PaymentTerms;
  promotions?: PromotionTerms;

  hasTermsFormExemption: boolean;
  hasProcurementHold: boolean;
  acceptsIntroAllowance: boolean;
  acceptsImagingCharge: boolean;
  specialEventTermsSeasonalCode: string;
  specialEventTermsNewWarehouseOpeningCode: string;
  hasStatementAudit: boolean;
  auditTimeFrameCode: string;
  hasPromoAllow: boolean;
  hasPromoPriceProtect: boolean;
  paymentMethodCode: string;

  addendumAsBase64String: string;

  hasAdministrativeAllowanceProgram: boolean;
}
