<kehe-phoenix-delete-modal
  modalTitle="Delete Contact"
  [isDeleteInProgress]="inProgress"
  [errorMessage]="error"
  (modalEvent)="closeModal()"
  (cancelClicked)="closeModal()"
  (deleteClicked)="onDeleteClicked()"
  size="md"
>
  Are you sure you would like to delete this contact?
</kehe-phoenix-delete-modal>
