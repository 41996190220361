import { Component, OnInit } from '@angular/core';
import { Destroyable } from '../../../../abstract/destroyable';
import { select, Store } from '@ngrx/store';
import {
  getBrands,
  getDisplayProductGroupsFilterPanel,
  selectBuyerList,
  selectDeliveryMethodList,
} from '../../store/supplier-detail.selectors';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { closeProductGroupsFilter, loadBuyerList, } from '../../store/supplier-detail.actions';
import * as DistributionCenterSelectors from '@app/shared/state/distribution-center/distribution-center.selectors';
import { DropdownOption } from './../../../../models/dropdown-option';
import { Constants } from '../../../../constants/constants';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProductGroupsFilter } from '../../models/product-groups-filter';
import { getProductGroupsFilter } from '../../../product-groups/store/product-groups.selectors';
import { productGroupsFilterChanged } from '../../../product-groups/store/product-groups.actions';
import { isEqual, isNull, isUndefined, mapValues, omitBy } from 'lodash';
import { Brand } from '../../../product-detail/models/brand';
import { prepareBuyers } from '../../../product-groups/utils/products-util';
import { Buyer } from '../../models/buyer';
import { DeliveryMethodItem } from '../../../product-groups/models/delivery-method-item';

@Component({
  selector: 'app-product-groups-filter-slide-panel',
  templateUrl: './product-groups-filter-slide-panel.component.html',
  styleUrls: ['./product-groups-filter-slide-panel.component.scss'],
})
export class ProductGroupsFilterSlidePanelComponent
  extends Destroyable
  implements OnInit
{
  tempClasses: Array<string> = Constants.TempClasses;
  inboundRouteTypes: DropdownOption[] = Constants.InboundRouteTypes.map(item => {
    return { label: item.name, value: item.type};
  });

  form: UntypedFormGroup;
  filter: ProductGroupsFilter;
  showSidePanel = false;
  noValues = true;

  dcList: DropdownOption[];
  brands: Brand[];
  buyers: Buyer[];
  deliveryMethodList: DeliveryMethodItem[];

  constructor(private _store: Store, private _formBuilder: UntypedFormBuilder) {
    super();
    this.form = this._formBuilder.group(
      mapValues(new ProductGroupsFilter(), (value) => [value]),
      { validators: [] }
    );

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((values) => {
        this.noValues = isEqual(
          clean(values),
          clean(new ProductGroupsFilter())
        );
      });
  }

  ngOnInit() {
    this._store.dispatch(loadBuyerList());
    this._store
      .pipe(
        select(getDisplayProductGroupsFilterPanel),
        withLatestFrom(this._store.select(getProductGroupsFilter)),
        takeUntil(this.destroy$)
      )
      .subscribe(([show, filter]) => {
        this.showSidePanel = show;
        if (show) {
          this.form.reset();
          this.form.patchValue(filter);
        }
      });
    this._store
      .pipe(takeUntil(this.destroy$), select(DistributionCenterSelectors.selectDistributionCentersTrimmedNameAndNumber))
      .subscribe((response) => {
        this.dcList = response;
      });
    this._store
      .pipe(takeUntil(this.destroy$), select(getBrands))
      .subscribe((response) => {
        this.brands = response;
      });
    this._store
      .pipe(takeUntil(this.destroy$), select(selectBuyerList))
      .subscribe((response) => {
        this.buyers = prepareBuyers(response);
      });
    this._store
      .pipe(takeUntil(this.destroy$), select(selectDeliveryMethodList))
      .subscribe((response) => {
        this.deliveryMethodList = response;
      });
    this._store
      .pipe(takeUntil(this.destroy$), select(getProductGroupsFilter))
      .subscribe((response) => {
        this.filter = response;
      });
  }

  closePanel() {
    this._store.dispatch(closeProductGroupsFilter());
  }

  applyFilter() {
    this._store.dispatch(
      productGroupsFilterChanged({
        productGroupsFilter: this.form.value,
      })
    );
    this.closePanel();
  }

  handleReset() {
    this.form.patchValue(new ProductGroupsFilter());
    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }
}

function clean(data) {
  return omitBy(omitBy(data, isUndefined), isNull);
}
