import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BrokerPageComponent } from '../pages/broker-page/broker-page.component';
import { BrokerListFilterClearAll } from '../store/broker-list.actions';

@Injectable({
  providedIn: 'root'
})
export class ClearBrokerGuardGuard  {

  constructor(
    private _store: Store
  ) {
  }

  canDeactivate(component: BrokerPageComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!nextState.url.startsWith('/broker')) {
      this._store.dispatch(BrokerListFilterClearAll());
    }
    return true;
  }
}
