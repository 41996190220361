import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Destroyable } from '../../../../abstract/destroyable';
import {
  getBrands,
  getRemittanceAddressValidationInProgress,
  selectSupplierDetail
} from '../../store/supplier-detail.selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ProductGroup } from '../../models/product-group';
import { SupplierDetail } from '../../models/supplier-detail';
import { combineLatest, merge, Subject } from 'rxjs';
import {
  clearValidateRemittanceAddress,
  productGroupFormChanged,
  setValidationErrors,
  showNotesModal
} from '../../store/supplier-detail.actions';
import { AddressComponent } from '@kehe/phoenix-address';
import { environment } from '../../../../../environments/environment';
import { getFormValidationErrors, markAllFormControlsAsTouched, prepPhone } from '../../../../utils/common-utils';
import { isDraft, isInvitedDraftSupplier } from '../../utilities/supplier-utils';
import { Constants } from '../../../../constants/constants';
import {
  getFormControlValueMap,
  getFormDirtyValue,
  markAllFormControlsAsDirty,
  resetPristine
} from '../../../../utils/form-utils';
import { formatProductGroupResponse } from '../../utilities/product-group-utils';
import { ContactType } from '../../../../models/contact';
import { getProductGroup } from '../../../product-groups/store/product-groups.selectors';

@Component({
  selector: 'app-product-groups-form',
  templateUrl: './product-groups-form.component.html',
  styleUrls: ['./product-groups-form.component.scss']
})
export class ProductGroupsFormComponent extends Destroyable implements OnInit, OnDestroy {

  contactType = ContactType;

  @ViewChild('addressComponentRemittance', { static: true }) addressFormRemittance: AddressComponent;
  readonly mask = Constants.ExtensionTextMask;
  initialFormValue: Map<UntypedFormControl, any>;

  private productGroupNumber: string;
  public form: UntypedFormGroup;
  isFormReady = false;
  productGroup: ProductGroup;
  supplier: SupplierDetail;
  brands: any[];
  public tempClasses: Array<string> = ['Dry', 'Refrigerated', 'Frozen'];
  isUseHeadquarterInfoChecked: boolean;
  ignoreRemittanceValueChanged: boolean;
  remitNameMaxLength = 30;
  public remitNameCounter = `0/${this.remitNameMaxLength}`;

  @ViewChild('temperatureClassField', { static: true }) public temperatureClassField: any;

  validateRemittanceAddressOnSave: Subject<void> = new Subject<void>();
  smartyStreetsKey = environment.smartyStreetsKey;

  public characterCount = 0;
  public maxLength = 30;
  public counter = `${this.characterCount}/${this.maxLength}`;
  public remitEmailMaxLength = 30;
  public remitEmailCounter = `0/${this.remitEmailMaxLength}`;

  get remitName() {
    return this.form.get('remitName');
  }

  get remitEmail() {
    return this.form.get('remitEmail');
  }

  get temperatureClass() {
    return this.form.get('temperatureClass');
  }

  get productGroupName() {
    return this.form.get('name');
  }

  get productGroupNameIsEmpty() {
    return this.form.get('name').value.length === 0;
  }

  get remitNameIsEmpty() {
    return this.form.get('remitName').value.length === 0;
  }

  get showContactCard() {
    return isInvitedDraftSupplier(this.supplier);
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this.productGroupNumber = this.route.snapshot.paramMap.get('productGroupNumber');
    this.initializeForm();
    this.initializeStoreSelectorObservables();
    this.form.markAllAsTouched();
  }

  ngOnDestroy() {
    this._store.dispatch(setValidationErrors({ errors: [] }));
    super.ngOnDestroy();
  }

  private initializeForm(): void {
    const addressForm = this.addressFormRemittance.createAddressFormGroup();
    addressForm.controls.name.disable();

    this.form = this._formBuilder.group({
      name: ['', [Validators.maxLength(30)]],
      brands: [],
      temperatureClass: ['', [Validators.required]],
      remittanceAddressId: ['', Validators.required],
      remittanceAddress: addressForm,
      remitName: ['', [Validators.required]],
      remitPhone: [''],
      remitExtension: [''],
      remitFax: [''],
      remitEmail: ['', [Validators.email]],
    });
    this.form.get('remittanceAddressId').disable();
    if (this.showContactCard) {
      this.disableRemittanceFields();
    }
  }

  disableRemittanceFields() {
    this.form.get('remittanceAddress').disable();
    this.form.get('remitName').disable();
    this.form.get('remitPhone').disable();
    this.form.get('remitExtension').disable();
    this.form.get('remitFax').disable();
    this.form.get('remitEmail').disable();
  }

  private initializeStoreSelectorObservables(): void {

    const getProductGroup$ = this._store.select(getProductGroup, {productGroupNumber: this.productGroupNumber}).pipe(take(1));
    const selectSupplierDetail$ = this._store.select(selectSupplierDetail).pipe(take(1));
    const getBrands$ = this._store.select(getBrands).pipe(take(1));

    combineLatest([
      getProductGroup$,
      selectSupplierDetail$,
      getBrands$,
    ])
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(([
                    productGroupItem,
                    supplierDetailItem,
                    brands,
                  ]) => {
        this.productGroup = productGroupItem;
        this.supplier = supplierDetailItem;
        this.brands = brands;
        this.isFormReady = true;
        this.patchForm();
        this.initializeFormValueChanges();
        if (this.productGroup && this.productGroup.remittanceAddress) {
          this.remitNameChange(this.productGroup.remittanceAddress.name);
          this.remitEmailChange(this.productGroup.remittanceAddress.email);
        }
      });

    this._store.pipe(
      select(getRemittanceAddressValidationInProgress),
      takeUntil(this.destroy$))
      .subscribe(val => {
        if (val) {
          this.validateRemittanceAddressOnSave.next();
        }
      });
  }

  private initializeFormValueChanges(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(form => {

      resetPristine(this.initialFormValue);

      const dirtyValue = getFormDirtyValue(this.form);
      formatProductGroupResponse(dirtyValue, form);

      const action = {
        form: {
          esn: this.supplier ? this.supplier.esn : null,
          productGroups: [
            {
              productGroupNumber: this.productGroup.productGroupNumber,
              ...dirtyValue
            }
          ],
        },
        isDirty: this.form.dirty,
        productGroupRemittanceAddressDirty: this.form.controls.remittanceAddress.dirty,
        isProductGroupFormValid: this.form.valid,
      };
      this._store.dispatch(productGroupFormChanged(action));
      this.validateForm();
    });

    // subscribe to remittance changes that don't come from checkbox so we can toggle checkbox off
    merge(
      this.form.controls['remittanceAddress'].valueChanges,
      this.form.controls['remitPhone'].valueChanges,
      this.form.controls['remitExtension'].valueChanges,
      this.form.controls['remitFax'].valueChanges,
      this.form.controls['remitEmail'].valueChanges
    ).pipe(
      takeUntil(this.destroy$),
      filter(() => !this.ignoreRemittanceValueChanged))
      .subscribe(() => {
        this.isUseHeadquarterInfoChecked = false;
      });
  }

  patchForm(): void {
    this.onNameValueChange(this.productGroup.name);
    this.form.controls.name.setValue(this.productGroup.name);
    this.form.controls.brands.setValue(this.productGroup.brands);
    this.form.controls.temperatureClass.setValue(this.productGroup.temperatureClass);

    this.form.controls.remittanceAddressId.setValue(this.productGroup?.remittanceAddressId);
    this.form.controls.remittanceAddress.patchValue(this.productGroup?.remittanceAddress);
    this.form.controls.remitName.setValue(this.productGroup?.remittanceAddress?.name || '');
    this.form.controls.remitPhone.setValue(prepPhone(this.productGroup?.remittanceAddress?.phone || ''));
    this.form.controls.remitExtension.setValue(this.productGroup?.remittanceAddress?.extension || '');
    this.form.controls.remitFax.setValue(prepPhone(this.productGroup?.remittanceAddress?.fax || ''));
    this.form.controls.remitEmail.setValue(this.productGroup?.remittanceAddress?.email || '');

    if (!this.addressFormRemittance.isInternational()) {
      this.form.get('remittanceAddress.state').setValidators([Validators.required]);
      this.form.get('remittanceAddress.province').reset();
    } else {
      this.form.get('remittanceAddress.state').clearValidators();
      this.form.get('remittanceAddress.state').reset();
    }
    this.form.get('remittanceAddress.state').updateValueAndValidity({ emitEvent: false });
    this.form.get('remittanceAddress.province').updateValueAndValidity({ emitEvent: false });
    this.validateForm();
    this.initialFormValue = getFormControlValueMap(this.form);
  }

  onUseHeadquarterInfoChange(): void {
    this.isUseHeadquarterInfoChecked = !this.isUseHeadquarterInfoChecked;
    if (this.isUseHeadquarterInfoChecked) {
      this.copyHeadquarterToRemittance();
    }
  }

  save(): void {
    this._store.dispatch(showNotesModal());
  }

  onRemittanceAddressSelected(selectedAddress) {
    this.form.controls.remittanceAddress.patchValue(selectedAddress.address);
    this.save();
  }

  onNoAddressSelected(): void {
    this._store.dispatch(clearValidateRemittanceAddress());
  }

  private copyHeadquarterToRemittance() {
    this.ignoreRemittanceValueChanged = true;
    this.copyValueToFormControl('remittanceAddress', this.supplier.headquarterAddress);
    this.copyValueToFormControl('remitPhone', prepPhone(this.supplier.headquarterAddress.phone || ''));
    this.copyValueToFormControl('remitExtension', this.supplier.headquarterAddress.extension || '');
    this.copyValueToFormControl('remitFax', prepPhone(this.supplier.headquarterAddress.fax || ''));
    this.copyValueToFormControl('remitEmail', this.supplier.headquarterAddress.email || '');
    this.form.get('remittanceAddress.name').setValue(this.remitName.value);
    this.remitEmailChange(this.supplier.headquarterAddress.email);
    this.ignoreRemittanceValueChanged = false;
  }

  private copyValueToFormControl(formControlName: string, value: any) {
    const formField = this.form.get(formControlName);
    if (formField instanceof UntypedFormControl) {
      formField.markAsDirty();
      formField.setValue(value);
    } else {
      markAllFormControlsAsDirty(formField);
      formField.patchValue(value);
    }
  }

  public onFocusTemperatureClass() {
    this.temperatureClassField.toggle(true);
  }

  public onNameValueChange(e: any) {
    this.updateNameCharCount(e);
  }

  public onNameBlur() {
    this.trimNameAndUpdateCharCount();
  }

  private updateNameCharCount(val: string) {
    this.characterCount = val.length;
    this.counter = `${this.characterCount}/${this.maxLength}`;
  }

  private trimNameAndUpdateCharCount() {
    this.form.controls.name.setValue(this.form.controls.name.value.trim());
    this.updateNameCharCount(this.form.controls.name.value);
  }

  public remitNameChange(e: any) {
    this.remitNameCounter = this.charCounterUpdate(e ? e.length : 0, this.remitNameMaxLength);
  }

  public charCounterUpdate(length: any, maxLength: number): string {
    return `${length}/${maxLength}`;
  }

  public getStreet2Length() {
    return '30';
  }

  public getAttentionLength() {
    return '25';
  }

  public getCityLength() {
    return '25';
  }

  public getZipCodeLength() {
    return '10';
  }

  private validateForm() {
    if (!isDraft(this.supplier)) {
      markAllFormControlsAsTouched(this.form);
      this._store.dispatch(setValidationErrors({ errors: getFormValidationErrors(this.form) }));
    }
  }

  remitEmailChange(e: any) {
    this.remitEmailCounter = this.charCounterUpdate(e ? e.length : 0, this.remitEmailMaxLength);
  }
}
