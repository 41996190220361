import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import { Constants } from '../../../../constants/constants';
import { DocumentType } from '../../../supplier-documents/models/document-type';
import {
  selectDocuments,
  selectLegalDocumentsForDocumentsTab,
  selectRequiredDocumentTypes
} from '../../../supplier-documents/store/supplier-documents.selectors';
import { DocumentStatus } from '../../../supplier-documents/models/document-statuses';
import { combineLatest } from 'rxjs';
import { isDraft } from '../../utilities/supplier-utils';
import { SupplierDetail } from '../../models/supplier-detail';
import { selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-documents-tab',
  templateUrl: './documents-tab.component.html',
  styleUrls: ['./documents-tab.component.scss'],
})
export class DocumentsTabComponent extends Destroyable implements OnInit {
  supplier: SupplierDetail;

  excludedDocumentTypes = [Constants.PurchasingInfoDocumentTypeId, Constants.RetailerRequestedDetailsDocumentTypeId];
  requiredDocumentTypes: DocumentType[] = [];
  legalDocumentTypes: DocumentType[] = [];
  showAdditionalDocumentsModal = false;

  get isDraftSupplier() {
    return isDraft(this.supplier);
  }

  constructor(
    private _store: Store
  ) {
    super();
  }

  ngOnInit() {
    this._store
      .pipe(takeUntil(this.destroy$), select(selectSupplierDetail))
      .subscribe((value) => {
        this.supplier = value;
      });

    combineLatest([
      this._store.select(selectRequiredDocumentTypes),
      this._store.select(selectDocuments),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([types, documents]) => {
        if (
          !documents.find(
            (d) =>
              d.typeId === Constants.SupplierInfoDocumentTypeId &&
              d.status === DocumentStatus.Approved
          )
        ) {
          // have to reassign this array since changes only trigger with reference changes to inputs
          this.excludedDocumentTypes = [
            Constants.PurchasingInfoDocumentTypeId,
            Constants.SupplierInfoDocumentTypeId,
            Constants.RetailerRequestedDetailsDocumentTypeId,
          ];
        } else {
          this.excludedDocumentTypes = [Constants.PurchasingInfoDocumentTypeId, Constants.RetailerRequestedDetailsDocumentTypeId];
        }
        this.requiredDocumentTypes = types;
      });

    this._store
      .pipe(
        select(selectLegalDocumentsForDocumentsTab),
        takeUntil(this.destroy$)
      )
      .subscribe((documents) => {
        this.legalDocumentTypes = documents;
      });
  }

  displayAdditionalDocumentsModal() {
    this.showAdditionalDocumentsModal = true;
  }

  closeAdditionalDocumentsModal() {
    this.showAdditionalDocumentsModal = false;
  }

  goToDocumentsV1(): void {
    window.open(`${environment.v1ConnectHost}/vendormanagement/#/landingPage/vendorDetails/vendorDocuments?esn=${this.supplier.esn}&userType=keheemployee`, '_blank');
  }
}
