import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TermsDropdownData, TermsDropdownType } from '../models/terms-dropdown-data';

export function invalidTermsDropdownValue(termsDropdownData: TermsDropdownData[], type: TermsDropdownType): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        if (control.value === null || control.value === undefined) {
            return { 'required': true };
        }

        const listItem = termsDropdownData.find(item => item.listName === type);
        if (listItem) {
            const validValues = listItem.listValues.map(item => item.code);
            if (!validValues.includes(control.value)) {
                return { 'required': true };
            }
        }
        return null;
    };
}
