import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Destroyable } from '../../../../abstract/destroyable';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BulkEditPages } from '../../models/bulk-edit-pages';
import { EmailValidators } from '../../../../utils/email-validation-utils';

@Component({
  selector: 'app-bulk-edit-po-email',
  templateUrl: './bulk-edit-po-email.component.html',
  styleUrls: [],
})
export class BulkEditPoEmailComponent
  extends Destroyable
  implements OnInit, OnChanges
{
  bulkEditPages = BulkEditPages;
  transTypes = ['Broker', 'Supplier'];

  @Input()
  form: UntypedFormGroup;

  @Input()
  page: BulkEditPages;

  @Input()
  selectedDCs: Array<any> = [];

  @Input()
  hasWarning: boolean = false;

  @Input()
  warningInputs: boolean[] = [];

  existingPOEmails = [];
  poEmailMaxLength = 100;
  poEmailCounter = [`0/${this.poEmailMaxLength}`];

  get poEmailsFormArray() {
    return this.form.get('poEmails') as UntypedFormArray;
  }

  get isPOTouched() {
    return this.poEmailsFormArray?.controls.some(control => control.get('email').touched );
  }

  get isPOEmailRequired() {
    return this.isPOTouched && !this.isPONotEmpty;
  }

  get isPONotEmpty() {
    return this.form.get('isPONotEmpty').value;
  }

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    if (this.form) {
      this.initializeFormValueChanges();
      this.updateIsPONotEmpty();
      this.updatePoEmailCharCounts();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.selectedDCs?.firstChange) {
      const flattedEmails = changes?.selectedDCs?.currentValue.flatMap(
        (obj) => obj.poTransmissionDetails?.poEmails
      );
      const seenIds = new Set();
      this.existingPOEmails = flattedEmails.filter(item => {
        return (
          item?.email && !seenIds.has(item.email.toLowerCase()) &&
          seenIds.add(item.email.toLowerCase())
        );
      });
    }
  }

  addPOEmail() {
    this.poEmailsFormArray?.push(this.createPOEmailGroup());
    this.updatePoEmailCharCounts();
    if (this.isPOEmailRequired) {
      this.markAllPOEmailsTouched();
    }
  }

  createPOEmailGroup(): UntypedFormGroup {
    return this._formBuilder.group({
      emailType: [''],
      email: ['', [EmailValidators.domainEmail]],
    });
  }

  updatePoEmailCharCounts() {
    if (this.poEmailsFormArray) {
      for (const key of Object.keys(this.poEmailsFormArray.controls)) {
        this.poEmailChange(
          this.poEmailsFormArray.controls[key].controls.email.value,
          +key
        );
      }
    }
  }

  poEmailChange(e: any, i: number) {
    this.poEmailCounter[i] = this.charCounterUpdate(
      e.length,
      this.poEmailMaxLength
    );
  }

  charCounterUpdate(length: any, maxLength: number): string {
    return `${length}/${maxLength}`;
  }

  markAllPOEmailsTouched() {
    this.poEmailsFormArray?.controls.forEach((control, i) => {
      this.getPOEmailField(i).markAsTouched();
    });
  }

  getPOEmailField(index) {
    return this.getPoEmailsFormGroup(index).get('email');
  }

  getPoEmailsFormGroup(index): UntypedFormGroup {
    return this.poEmailsFormArray.controls[index] as UntypedFormGroup;
  }

  handlePOEmailBlur() {
    this.markAllPOEmailsTouched();
  }

  deletePoTransaction(index) {
    this.form.markAsDirty();
    this.poEmailsFormArray?.markAsDirty();
    this.poEmailsFormArray?.removeAt(index);
    this.updatePoEmailCharCounts();
  }

  updateIsPONotEmpty() {
    switch (this.page) {
      case BulkEditPages.AddPOEmailPage:
      case BulkEditPages.ReplacePOEmailPage:
        {
          const validEmails =
            this.poEmailsFormArray?.controls.filter((item) => {
              const emailField = (item as UntypedFormGroup).get('email');
              return emailField.value && emailField.value.length > 0;
            }) ?? [];
          const emailValid = validEmails.length > 0;
          this.form.get('isPONotEmpty').setValue(emailValid);
        }
        break;
    }
  }

  initializeFormValueChanges() {
    switch (this.page) {
      case BulkEditPages.AddPOEmailPage:
      case BulkEditPages.ReplacePOEmailPage:
        {
          merge(this.form.get('poEmails').valueChanges)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.updateIsPONotEmpty();
            });
        }
        break;
    }
  }
}
