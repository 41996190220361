import { Component, OnInit } from '@angular/core';
import { Destroyable } from '../../../../abstract/destroyable';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Constants } from '../../../../constants/constants';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
  downloadPrivateAssets,
} from '../../../supplier-documents/store/supplier-documents.actions';
import {
  selectDocuments,
  selectSIFNotSubmitted
} from '../../../supplier-documents/store/supplier-documents.selectors';
import { DocumentStatus } from '../../../supplier-documents/models/document-statuses';
import { Document } from '../../../supplier-documents/models/document';
import { SupplierDetail } from '../../models/supplier-detail';
import { selectAppointmentSchedulingFailed, selectAppointmentSchedulingLoading, selectAppointmentSchedulingSuccess, selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { clickAppointmentModalSaveButton, clickEditAppointmentButton, updateCanUploadSIF } from '../../store/supplier-detail.actions';
import { isSIFFormUploaded } from '../../../supplier-documents/utils/documents-utils';
import { VendorPacketFFConfig } from '../../models/vendor-packet-ff-config';
import { selectConfigurableFeatureFlagIfOn } from '../../../../shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '../../../../feature-flag';

@Component({
  selector: 'app-purchasing-info-tab',
  templateUrl: './purchasing-info-tab.component.html',
  styleUrls: ['./purchasing-info-tab.component.scss']
})
export class PurchasingInfoTabComponent extends Destroyable implements OnInit {
  appointmentLoading$ = this._store.select(selectAppointmentSchedulingLoading);
  appointmentFailed$ = this._store.select(selectAppointmentSchedulingFailed);

  readonly purchasingInfoDocumentTypeId = Constants.PurchasingInfoDocumentTypeId;
  readonly supplierInfoDocumentTypeId = Constants.SupplierInfoDocumentTypeId;

  supplier: SupplierDetail;
  downloadPurchasingFormFileName = `Purchasing Form.xlsm`;

  includedDocumentTypes = [Constants.PurchasingInfoDocumentTypeId, Constants.RetailerRequestedDetailsDocumentTypeId];

  vendorPacketFF: VendorPacketFFConfig | false;

  // Read SIF file from Feature Flag Config or take the default file. 
  // Ref documentation page to see how vendor packets can be updated without code change
  get downloadSIFFileName() {
    return (this.vendorPacketFF as VendorPacketFFConfig)?.SIF ?? 'Supplier Information Form.xlsx'
  }

  documents: Document[] = [];
  showAppointmentModal = false;
  hideSifOptions = false;
  sort: SortDescriptor[] = [
    {
      field: 'type',
      dir: 'asc'
    }
  ];

  get isTabLocked() {
    let sif: Document = null;
    if(this.documents.length > 0){
      sif = this.documents.find(d => d.typeId === Constants.SupplierInfoDocumentTypeId);
    }

    if (!sif) {
      return false;
    } else if (sif.status === DocumentStatus.InReview || sif.status === DocumentStatus.Approved) {
      return true;
    }
  }

  get disableSIFUpload() {
    return this.supplier && !this.supplier?.canUploadSIF;
  }

  get isSIFUploaded() {
    return isSIFFormUploaded(this.documents);
  }

  constructor(
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this._store
          .select(
              selectConfigurableFeatureFlagIfOn(
                  FeatureFlags.SupplierVendorPackets.key
              )
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe((value) => {
              this.vendorPacketFF = value;
          });
    this._store.select(selectSupplierDetail)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.supplier = val;
        this.setCanUploadSIF();
      });
    this._store.select(selectDocuments)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documents => {
        this.documents = documents;
        this.setCanUploadSIF();
      });
    this._store.select(selectSIFNotSubmitted)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.hideSifOptions = value;
      });
    this._store.select(selectAppointmentSchedulingSuccess)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.showAppointmentModal = false;
        }
      });
  }

  onAppointmentClose() {
    this.showAppointmentModal = false;
  }

  onAppointmentShow() {
    this.showAppointmentModal = true;
    this._store.dispatch(clickEditAppointmentButton());
  }

  onAppointmentSave(meetingDate: Date) {
    this._store.dispatch(clickAppointmentModalSaveButton({ meetingDate }));
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.documents = orderBy(this.documents, this.sort);
  }

  downloadPurchasingForm() {
    this._store.dispatch(downloadPrivateAssets({ fileName: this.downloadPurchasingFormFileName }));
  }

  downloadSIF() {
    this._store.dispatch(downloadPrivateAssets({ fileName: this.downloadSIFFileName }));
  }

  onValueChangeCanUploadSIF() {
    this._store.dispatch(updateCanUploadSIF({ canUploadSIF: this.supplier?.canUploadSIF }));
  }

  setCanUploadSIF() {
    if (this.isSIFUploaded && this.supplier) {
      this.supplier.canUploadSIF = true;
    }
  }
}
