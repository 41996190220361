import { isNullOrUndefined } from '../../../common/common-methods';
import { HttpParams } from '@angular/common/http';
import { SupplierHistoryFilter } from '../store/supplier-detail.reducer';

export function SupplierHistoryFilterToHttpParams(
  filters: SupplierHistoryFilter, esn: string
): HttpParams {
  let params = new HttpParams()
    .set('key', esn)
    .set('search', filters.search)
    .set('pagecount', filters.pageCount.toString())
    .set('pageindex', filters.pageIndex.toString())
    .set('orderdirection', filters.orderDirection.toString())
    .set('includeavailablecount', filters.includeAvailableCount.toString());

  if (!isNullOrUndefined(filters.selectedEvent)) {
    params = params.append('event', filters.selectedEvent.code);
  }

  if (!isNullOrUndefined(filters.selectedField)) {
    params = params.append('propertySearch', filters.selectedField.value);
  }

  if (!isNullOrUndefined(filters.selectedEditedBy)) {
    params = params.append('createdby', filters.selectedEditedBy.code);
  }

  if (!isNullOrUndefined(filters.selectedDC)) {
    params = params.append('propertySearch', `dcproductgroups.${filters.selectedDC.value}`);
  }

  if (filters.selectedDocument) {
    params = params.append('secondaryKey', filters.selectedDocument.id);
  }

  if (
    !isNullOrUndefined(filters.createdFrom) &&
    !isNullOrUndefined(filters.createdTo)
  ) {
    params = params.append('mindate', filters.createdFrom.toLocaleDateString());
    params = params.append('maxdate', filters.createdTo.toLocaleDateString());
  }
  return params;
}
