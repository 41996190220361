import { Injectable } from '@angular/core';
import { addKendoBreadCrumbs, changePurchasingOptionViewMode, showCommonAlert } from '@kehe/purchasing-options-lib';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { updateKendoBreadCrumbs } from '../supplier-detail.actions';

import * as SupplierDetailActions from '../supplier-detail.actions';
import * as SupplierDocumentsActions from '../../../supplier-documents/store/supplier-documents.actions';

@Injectable()
export class SupplierPurchasingOptionsEffects {
  constructor(
    private readonly actions$: Actions,
  ) {}

  addBreadcrumbs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addKendoBreadCrumbs),
      map((action) => {
        return updateKendoBreadCrumbs({ item: action.item });
      })
    )
  );

  showCommonAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showCommonAlert),
      map((action) => {
        return SupplierDetailActions.getPurchasingOptionsAlertMessage({
          alert: {
            type: action.alertType,
            message: action.message,
          },
        });
      })
    )
  );

  hideCommonAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePurchasingOptionViewMode),
      map(() => {
        return SupplierDetailActions.hideAlertMessage();
      })
    )
  );

  hideDocumentsAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SupplierDetailActions.hideAlertMessage,
        SupplierDetailActions.setCurrentTab
      ),
      map(() => {
        return SupplierDocumentsActions.hideDocumentsAlertMessage();
      })
    )
  );
}
