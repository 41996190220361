import { Component, OnInit } from '@angular/core';
import { BrokerService } from '../../../broker-list/services/broker.service';
import { takeUntil } from 'rxjs/operators';

import { Destroyable } from '../../../../abstract/destroyable';
import { select, Store } from '@ngrx/store';
import { brokerPatchNotesModal, hideAddBrokerModal } from '../../store/supplier-detail.actions';
import { selectSaveInProgress, selectSupplierEsn, } from '../../store/supplier-detail.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-broker-modal',
  templateUrl: './add-broker-modal.component.html',
  styleUrls: ['./add-broker-modal.component.scss']
})
export class AddBrokerModalComponent extends Destroyable implements OnInit {
  supplierEsn: string;
  selectedBroker: string;
  searchedBrokerList: { name, esn }[] = [];
  isLoadingSearch = false;
  brokerPatch: any;
  isSaveInProgress = false;
  private searchRequest: Subscription;

  constructor(private _brokerService: BrokerService, private _store: Store) { super(); }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSupplierEsn))
      .subscribe(esn => {
        this.supplierEsn = esn;
    });

    this._store.pipe(
      select(selectSaveInProgress),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isSaveInProgress = val;
      });
  }

  onSelectedBrokerChange(e) {
    if (e === undefined) {
      this.clearList();
    }
  }

  clearList() {
    this.searchedBrokerList = [];
  }

  onBrokerListSearchChanged(search) {
    if (search.trim() === '') { return; }
    this.clearList();
    if (this.searchRequest) {
      this.searchRequest.unsubscribe();
    }
    this.isLoadingSearch = true;
    this.searchRequest = this._brokerService.getfilteredList(search).pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.searchedBrokerList = r.data.map(element => {
          const text = element.name + ' (' + element.esn + ')';
          return { name: text, esn: element.esn };
        });
        this.isLoadingSearch = false;
      });
  }

  submitBroker() {
    if (this.selectedBroker && !this.isSaveInProgress) {
      this.brokerPatch = { esn: this.supplierEsn, broker: { esn: this.selectedBroker, isBrokerOfRecord: true} };
      this._store.dispatch(brokerPatchNotesModal({ brokerForm: this.brokerPatch}));
    }
  }

  cancelModal() {
    this.clearList();
    this._store.dispatch(hideAddBrokerModal());
  }

  handleKeheModalEvents(e) {
    if (e.type === 'close') {
      this.cancelModal();
    }
  }
}
