import { Address } from '../../../models/address';
import { PurchaseOrderTransmissionEmail } from './purchase-order-email';
import { PurchaseOrderTransmissionFax } from './purchase-order-fax';

export class DcSupplierForm {
  buyer: string;
  DeliveryMethod: string;
  shipFromAddress: Address;
  poFax: Array<PurchaseOrderTransmissionFax>;
  poEmails: Array<PurchaseOrderTransmissionEmail>;
}
