import { createAction, props } from '@ngrx/store';

import { Payload } from '../../../models/payload';
import { BrokerListItem } from '../models/broker-list-item';
import { BrokerDetail } from '../../broker-detail/models/broker-detail';

export const loadBrokerList = createAction(
  '[Broker] Load Broker List'
);

export const loadBrokerListSuccess = createAction(
  '[Broker] Load Broker List Success',
  props<{ brokers: Payload<Array<BrokerListItem>> }>()
);

export const brokerListPageChange = createAction(
  '[Broker] Broker List Page Change',
  props<{ pageCount: number; pageIndex: number; skip: number }>()
);

export const brokerListSearchChange = createAction(
  '[Broker] Broker List Search Change',
  props<{ search: string }>()
);

export const brokerListSortChange = createAction(
  '[Broker] Broker List Sort Change',
  props<{ orderDirection: number, sortName: number }>()
);

export const BrokerListFilterClearAll = createAction(
  '[Broker] Broker List Filter Clear All',
);

// Add Broker
export const showAddBrokerModal = createAction(
  '[Broker] Show Add Broker Modal',
);
export const closeAddBrokerModal = createAction(
  '[Broker] Close Add Broker Modal',
);
export const addBroker = createAction(
  '[Broker] Add Broker',
  props<{ broker: BrokerListItem }>()
);
export const addBrokerSuccess = createAction(
  '[Broker] Add Broker Success',
  props<{ broker: BrokerDetail }>()
);
export const addBrokerError = createAction(
  '[Broker] Add Broker Error',
);
