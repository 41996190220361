<kehe-phoenix-modal [show]="true" modalTitle="Generate New Contract" (modalEvent)="closeModal()" size="md">

  <div body class="p-1">
    <div>
      <kehe-phoenix-alert [show]="error !== null" [type]="'error'" [message]="error" [stick]="true" [hideCloseIcon]="true">
      </kehe-phoenix-alert>
      <kehe-phoenix-alert [show]="true" [type]="'warning'" [message]="warning" [stick]="true" [hideCloseIcon]="true">
      </kehe-phoenix-alert>
    </div>
    <div>
      <kendo-label text="Do you need to generate a new contract?"></kendo-label>
      <ul class="k-radio-list">
        <li class="k-radio-item">
          <input type="radio" name="generateContract" kendoRadioButton #generateContractYesField [value]="true"
            [(ngModel)]="generateContract">
          <kendo-label class='k-radio-label' for="generateContractYesField">Yes</kendo-label>
        </li>
        <li class="k-radio-item">
          <input type="radio" name="generateContract" kendoRadioButton #generateContractNoField [value]="false"
            [(ngModel)]="generateContract">
          <kendo-label class='k-radio-label' for="generateContractNoField">No</kendo-label>
        </li>
      </ul>
    </div>
    <div *ngIf="generateContract" class="mt-3">
      <strong>Add Contract Documents</strong>
      <p class="mt-3">
        Upload the new contract documents. This will send an updated DocuSign envelope to the supplier for their review
        and signature.
      </p>
      <kendo-label [for]="fileField" text="File Upload"></kendo-label>
      <kendo-formhint class="mb-2 mt-0">
        File must be .PDF
      </kendo-formhint>
      <kehe-phoenix-upload #fileField [fileUploads]="fileUploads" [uploadRestrictions]="uploadRestrictions"
        [allowMultipleSelect]="allowMultipleSelect" (addFiles)="handleAddFiles($event)"
        (removeFiles)="handleRemoveFiles($event)">
      </kehe-phoenix-upload>
    </div>
  </div>

  <div footer class="d-flex justify-content-between px-1">
    <div class="justify-content-start">
      <kehe-phoenix-button classAttr="btn-link btn-medium " [spinner]="false" [disabled]="false"
        iconClassAttr="fas fa-download" (click)="downloadAddendumAlertClicked()">
        Download Template
      </kehe-phoenix-button>
    </div>
    <div class="justify-content-end">
      <kehe-phoenix-button classAttr="btn-link btn-medium mr-2" (click)="closeModal()">
        Cancel
      </kehe-phoenix-button>
      <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="onSaveClicked()" [spinner]="inProgress"
        [disabled]="disableSave()">
        Save
      </kehe-phoenix-button>
    </div>
  </div>
</kehe-phoenix-modal>
