import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { getDCProductGroupForm, getNewProductGroupForm, getProductGroupForm, getSelectedProductGroupView, selectDeliveryMethodList, selectSupplierDetail } from '../supplier-detail.selectors';
import { SupplierDetailService } from '../../services/supplier-detail.service';
import { clearKendoBreadCrumbs, updateKendoBreadCrumbs } from '../supplier-detail.actions';
import { SupplierDetailTabs } from '../../models/supplier-detail-tabs';
import { Router } from '@angular/router';

import * as SupplierDetailActions from '../supplier-detail.actions';
import * as ProductGroupsActions from './../../../product-groups/store/product-groups.actions';
import * as DistributionCenterSelectors from '@app/shared/state/distribution-center/distribution-center.selectors';

@Injectable()
export class SupplierProductGroupsTabEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,

    private readonly supplierDetailService: SupplierDetailService,
    private readonly router: Router,
  ) {}

  saveProductGroupForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.saveProductGroupForm),
      withLatestFrom(
        this.store.select(getProductGroupForm),
        this.store.select(
          DistributionCenterSelectors.selectDistributionCentersTrimmedNameAndNumber
        )
      ),
      filter(([, form]) => !!form?.esn),
      switchMap(([, form, dcList]) => {
        return this.supplierDetailService.save(form).pipe(
          map((supplier) => {
            return SupplierDetailActions.saveProductGroupFormSuccess({
              esn: supplier.esn,
              productGroups: supplier.productGroups,
              dcList,
            });
          }),
          catchError((error) =>
            of(
              SupplierDetailActions.saveProductGroupFormError({
                errorCode: error.status,
              })
            )
          )
        );
      })
    )
  );

  clearProductGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.loadSupplierDetail),
      map(() =>
        ProductGroupsActions.clearAllProductsData()
      )
    )
  );

  setProductGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.loadSupplierDetailSuccess),
      map((action) =>
        ProductGroupsActions.setProductGroups({
          productGroups: action.supplier.productGroups,
          dcList: action.dcList,
          contacts: action.supplier?.contacts,
        })
      )
    )
  );

  refreshProductGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SupplierDetailActions.saveProductGroupFormSuccess,
        SupplierDetailActions.saveDCProductGroupFormSuccess,
        SupplierDetailActions.saveNewProductGroupFormSuccess
      ),
      withLatestFrom(this.store.select(selectSupplierDetail)),
      map(([action, supplier]) =>
        ProductGroupsActions.setProductGroups({
          productGroups: action.productGroups,
          dcList: action.dcList,
          contacts: supplier?.contacts,
        })
      )
    )
  );

  saveDCProductGroupForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.saveDCProductGroupForm),
      withLatestFrom(
        this.store.select(getDCProductGroupForm),
        this.store.select(getSelectedProductGroupView),
        this.store.select(DistributionCenterSelectors.selectDistributionCentersTrimmedNameAndNumber)
      ),
      switchMap(([, form, productGroupView, dcList]) => {
        return this.supplierDetailService.save(form).pipe(
          switchMap((supplier) => {
            const esn = supplier.esn;
            const productGroupId = form.productGroups[0].productGroupNumber;
            const tabOption = SupplierDetailTabs['productGroups'];

            return [
              clearKendoBreadCrumbs(),
              updateKendoBreadCrumbs({
                item: {
                  title: 'supplier/',
                  text: 'Manage Supplier',
                },
              }),
              updateKendoBreadCrumbs({
                item: {
                  title: `supplier/${esn}`,
                  text: `Supplier (${esn})`,
                },
              }),
              updateKendoBreadCrumbs({
                item: {
                  title: `supplier/${esn}/productGroups`,
                  text: `${tabOption}`,
                },
              }),
              updateKendoBreadCrumbs({
                item: {
                  title: `supplier/${esn}/productGroups/`,
                  text: `Product Group Detail (${productGroupId})`,
                },
              }),
              SupplierDetailActions.saveDCProductGroupFormSuccess({
                esn: supplier.esn,
                productGroups: supplier.productGroups,
                dcList,
                productGroupView,
              }),
            ];
          })
        );
      }),
      catchError((error) =>
        of(
          SupplierDetailActions.saveDCProductGroupFormError({
            errorCode: error.status,
          })
        )
      )
    )
  );

  editDCProductGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.editProductGroupDC),
      map((action) =>
        updateKendoBreadCrumbs({
          item: {
            title: '',
            text: `DC Product Group Detail (${action.dcProductGroup.dcProductGroupNumber})`,
          },
        })
      )
    )
  );

  saveNewProductGroupForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.saveNewProductGroupForm),
      withLatestFrom(
        this.store.select(getNewProductGroupForm),
        this.store.select(DistributionCenterSelectors.selectDistributionCentersTrimmedNameAndNumber)
      ),
      switchMap(([, form, dcList]) => {
        return this.supplierDetailService.save(form).pipe(
          map((supplier) =>
            SupplierDetailActions.saveNewProductGroupFormSuccess({
              esn: supplier.esn,
              productGroups: supplier.productGroups,
              dcList,
            })
          ),
          catchError((error) =>
            of(
              SupplierDetailActions.saveNewProductGroupFormError({
                errorCode: error.status,
              })
            )
          )
        );
      })
    )
  );

  navigateToNewProductGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.saveNewProductGroupFormSuccess),
      switchMap(async ({ esn, productGroups }) => {
        await this.router.navigateByUrl(
          `supplier/${esn}/productGroups/${
            productGroups[productGroups.length - 1].productGroupNumber
          }`
        );
      })
    )
  , { dispatch: false });

  updateDeliveryMethodInDCProductGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SupplierDetailActions.loadSupplierDetailSuccess,
        SupplierDetailActions.getDeliveryMethodListSuccess,
        SupplierDetailActions.saveProductGroupFormSuccess,
        SupplierDetailActions.saveDCProductGroupFormSuccess,
        SupplierDetailActions.saveNewProductGroupFormSuccess
      ),
      withLatestFrom(this.store.select(selectDeliveryMethodList)),
      map(([, deliveryMethodList]) =>
        ProductGroupsActions.updateDeliveryMethodInDCProductGroups({
          deliveryMethodList,
        })
      )
    )
  );
}
