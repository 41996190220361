import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Document, DocumentCreateDto } from '../models/document';
import { Payload } from '../models/payload';
import { PresignedUrlResponse } from '../models/presigned-url-response';
import { PresignedUrlRequest } from '../models/presigned-url-request';
import { retry, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as DistributionCenterSelectors from '@app/shared/state/distribution-center/distribution-center.selectors';

@Injectable()
export class DocumentService {
  private _url: string;
  private _options: any;
  private defaultPageSize: number;
  private dcs = [];

  constructor(private http: HttpClient, private store: Store) {
    this._url = `${environment.apiGateway}/documents`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._options = { headers: headers };

    this.defaultPageSize = sessionStorage.getItem('DocumentsPageSize') ? parseInt(sessionStorage.getItem('DocumentsPageSize'), 10) : 25;

    this.store.select(DistributionCenterSelectors.selectDistributionCentersTrimmedNameAndNumber).subscribe(dcs => (this.dcs = dcs));
  }

  post(document: DocumentCreateDto): Observable<Payload<Document>> {
    return this.http.post<Payload<Document>>(this._url, document);
  }

  getPresignedUrl(fileName: string, contentType: string, file: File) {
    const filen = fileName + '.' + file.name.substr(file.name.lastIndexOf('.') + 1);
    const url = `${this._url}/presigned-url-upload`;
    return this.http.post<any>(encodeURI(url), <PresignedUrlRequest>{
      fileName: filen,
      contentType: file.type,
      key: filen,
    });
  }

  getPresignedUrlDocumentDownloadLink(document: Document): Observable<Payload<string>> {
    const url = `${this._url}/presigned-url?fileUrl=${encodeURI(document.fileUrl)}`;
    return this.http.get<Payload<string>>(url);
  }

  downloadGeneratedFile(fileUrl: string, contentType: string) {
    const headers = new HttpHeaders({ 'Content-Type': contentType });
    return this.http.get<any>(fileUrl, { headers: headers, responseType: 'blob' as 'json' });
  }

  uploadFile(file: File, preSignedUrl: PresignedUrlResponse): Observable<object> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', preSignedUrl.contentType),
    };
    return this.http.put(preSignedUrl.preSignedUrl, file, options).pipe(retry(3));
  }

  getAll(pageCount: number = this.defaultPageSize, pageIndex: number = 0, searchCriteria: string = null): Observable<Payload<Document[]>> {
    let paramsUrl = `?pageCount=${pageCount}&pageIndex=${pageIndex}&source=employee`;
    if (searchCriteria) {
      paramsUrl = `${paramsUrl}&search=${encodeURI(searchCriteria)}`;
    }

    return this.http.get<Payload<Document[]>>(`${this._url}${paramsUrl}`).pipe(
      map(docs => {
        docs.data.forEach(d => {
          const dcObj = this.dcs.find(dc => dc.value === d.distributionCenterNumber) || { value: '', label: '' };
          d.distributionCenter = dcObj.label;
          d.distributionCenterName = (dcObj.label && dcObj.label.substr(5)) || '';
        });
        return docs;
      })
    );
  }

  get(id: number): Observable<Payload<Document>> {
    return this.http.get<Payload<Document>>(`${this._url}/${id}`);
  }

  generateFile(request: any) {
    const url = this._url + '/marketing-monthly';
    return this.http.get<any>(url, { params: request });
  }

  delete(id: number): Observable<Payload<Document>> {
    return this.http.delete<Payload<Document>>(`${this._url}/${id}`);
  }

  update(id: number, request: any) {
    return this.http.patch<Payload<Document>>(`${this._url}/${id}`, request, this._options);
  }
}
