<kehe-phoenix-modal *ngIf="options$ | async as options" name="keheModal" [modalTitle]="options.title" [show]="isOpen"
  [size]="options.size || 'md'" (modalEvent)="handleKeheModalEvents($event, options.closeOk)">
  <div body>
    <div *ngIf="options.description" class="confirm-msg" [innerHTML]="options.description">
    </div>
  </div>
  <div footer class="btn-grid">
    <kehe-phoenix-button (click)="handleCancel()" classAttr="btn-secondary btn-medium">{{options.cancelString}}</kehe-phoenix-button>
    <kehe-phoenix-button (click)="handleOk()" classAttr="btn-primary btn-medium">{{options.okText}}</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
