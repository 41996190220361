import { MarketingAllowance } from './marketing-allowance';

export class PromotionTerms {
  acceptsManufacturerChargeBackFee: boolean;
  hasExtraPerformance: boolean;
  hasPublicationListingFee: boolean;
  marketingAllowance?: MarketingAllowance;

  manufacturerChargeBackFeeAmount: number;
  manufacturerChargeBackFeePercentage: number;
  manufacturerChargeBackExceptionCode: string;

  freeProductsProgramCode: string;
  freeProductHandlingChargeCode: string;
}
