import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectSupplierBroker, selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { takeUntil } from 'rxjs/operators';
import { SupplierDetail } from '../../models/supplier-detail';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SupplierFormService } from '../../services/supplier-form.service';
import { combineLatest, Subscription } from 'rxjs';
import { Constants } from '../../../../constants/constants';
import { BrokerContacts } from '../../models/broker-contacts';
import { Destroyable } from '../../../../abstract/destroyable';
import { formatBrokerFormResponse, getFormControlValueMap, resetPristine } from '../../../../utils/form-utils';
import { downloadPrivateAssets } from '../../../supplier-documents/store/supplier-documents.actions';
import { supplierFormChanged } from '../../store/supplier-detail.actions';
import { SupplierBrokerService } from '../../services/supplier-broker.service';

@Component({
  selector: 'app-broker-form',
  templateUrl: './broker-form.component.html',
  styleUrls: ['./broker-form.component.scss']
})
export class BrokerFormComponent extends Destroyable implements OnInit {

  readonly brokerRecordDocumentTypeId = Constants.BrokerRecordDocumentTypeId;
  readonly brokerRequestForm = 'KeHE-Broker-of-Record-Form-v072021.xlsx';

  public supplier: SupplierDetail;
  public form: UntypedFormGroup;
  initialFormValue: Map<UntypedFormControl, any>;
  brokerList: { title, esn, name }[] = [];
  contactList: { title, email }[] = [];
  isLoadingBrokerSearch = false;
  isLoadingContactSearch = false;
  showBrokerContactField = false;
  private brokerSearchRequest: Subscription;
  private contactSearchRequest: Subscription;

  get brokerField() {
    return this.form.get('broker.esn');
  }

  get contactField() {
    return this.form.get('broker.contact');
  }

  get hasHeadquarterBrokerField() {
    return this.form.get('hasHeadquarterBroker');
  }

  get hasHeadquarterBroker() {
    return this.hasHeadquarterBrokerField.value;
  }

  get knowsHeadquarterBrokerContactField() {
    return this.form.get('knowsHeadquarterBrokerContact');
  }

  get knowsHeadquarterBrokerContact() {
    return this.knowsHeadquarterBrokerContactField.value;
  }

  get isHeadquarterBrokerNotFoundField() {
    return this.form.get('isHeadquarterBrokerNotFound');
  }

  get isHeadquarterBrokerNotFound() {
    return this.isHeadquarterBrokerNotFoundField.value;
  }

  constructor(
    private _store: Store,
    private _supplierFormService: SupplierFormService,
    private _brokerService: SupplierBrokerService,
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this._store.pipe(select(selectSupplierDetail)),
      this._store.pipe(select(selectSupplierBroker)),
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([supplier, broker]) => {
        this.supplier = supplier;
        if (broker) {
          this.showBrokerContactField = true;
          this.brokerList = [this.getBrokerItem(broker)];

          this.searchBrokerContact(supplier.broker.esn, null, true);
          if (supplier.broker && supplier.broker.contact) {
            this.contactList = [this.getContactItem(supplier.broker.contact)];
          }
        } else {
          this.brokerList = [];
        }
        this.buildForm();
        this.listenFormValueChanges();
      });
  }

  private getBrokerItem(broker) {
    return { ...broker, title: `${broker.name} (${broker.esn})`, esn: broker.esn };
  }

  private getContactItem(contact: BrokerContacts) {
    return { ...contact, title: `${contact.name} (${contact.email})`};
  }

  buildForm() {
    this.form = this._supplierFormService.getBrokerForm(this.supplier);
    this.initialFormValue = getFormControlValueMap(this.form);
  }

  private listenFormValueChanges() {
    // Subscribe to form changes and dispatch them to the store
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {

      // This iterate all initial FormControls and check if it's current value and
      // initial value are same then it is markAsPristine which make sure form not dirty if its a reverted value
      resetPristine(this.initialFormValue);
      const dirtyValue = this.form.getRawValue();
      formatBrokerFormResponse(dirtyValue);
      dirtyValue.isBrokerOfRecord = true;

      this._store.dispatch(supplierFormChanged({
        form: {
          esn: this.supplier.esn,
          ...dirtyValue
        },
        isDirty: this.form.dirty,
        isSupplierFormValid: true,
      }));
    });

    // pre-populate contacts when a broker is selected
    this.form.get('broker.esn').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(esn => {
      if (esn) {
        this.showBrokerContactField = true;
        this.searchBrokerContact(esn, null, true);
      } else {
        this.showBrokerContactField = false;
        this.contactList = [];
      }
    });

    this.hasHeadquarterBrokerField.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.clearBrokerList());
    this.isHeadquarterBrokerNotFoundField.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.clearBrokerList());
  }

  public onFocus(element: any): void {
    element.toggle(true);
  }

  onBrokerSearchChanged(search) {
    if (search.trim() === '') { return; }
    this.brokerList = [];
    if (this.brokerSearchRequest) {
      this.brokerSearchRequest.unsubscribe();
    }
    this.isLoadingBrokerSearch = true;
    this.brokerSearchRequest = this._brokerService.search(search, 20, 0)
      .pipe(takeUntil(this.destroy$))
      .subscribe(list => {
        this.brokerList = list.map(item => this.getBrokerItem(item));
        this.isLoadingBrokerSearch = false;
      });
  }

  onContactSearchChanged(search: string) {
    this.searchBrokerContact(this.form.get('broker.esn').value, search);
  }

  searchBrokerContact(brokerNumber: string, search?: string, hideLoader = false) {
    this.contactList = [];
    if (this.contactSearchRequest) {
      this.contactSearchRequest.unsubscribe();
    }
    if (!hideLoader) {
      this.isLoadingContactSearch = true;
    }
    this.contactSearchRequest = this._brokerService.getBrokerContacts(brokerNumber, search).pipe(takeUntil(this.destroy$))
      .subscribe(list => {
        this.contactList = list.map(item => this.getContactItem(item));
        this.isLoadingContactSearch = false;
      });
  }

  downloadBrokerRequestForm() {
    this._store.dispatch(downloadPrivateAssets({ fileName: this.brokerRequestForm }));
  }

  clearBrokerList(event?: any) {
    if (!event) {
      this.brokerList = [];
      this.contactList = [];
    }
  }
}
