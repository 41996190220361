import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { IFormError } from '../models/iform-error';
import { HttpParams } from '@angular/common/http';
import { Filter } from '@app/models/filter';

export function getFormValidationErrors(form: any, parentKey?: string): Array<IFormError> {

  const result: Array<IFormError> = [];
  Object.keys(form.controls).forEach(key => {
    if (form.get(key).enabled) {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      if (form.get(key) instanceof UntypedFormGroup) {
        result.push(...getFormValidationErrors(form.get(key), fullKey));
      } else if (form.get(key) instanceof UntypedFormArray) {
        result.push(...getFormValidationErrors(form.get(key), fullKey));
      } else {
        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            result.push({
              'control': fullKey,
              'error': keyError,
              'value': controlErrors[keyError]
            });
          });
        }
      }
    }
  });
  return result;
}

export function flatErrors(errors: Array<IFormError>): Array<IFormError> {
  return errors.map((value) => value.value);
}

export function prepPhone(phoneNumber: string): string {
  if (phoneNumber && !phoneNumber.startsWith('+')) { return `+1${phoneNumber}`; }
  return phoneNumber || '';
}

export function markAllFormControlsAsTouched(form) {
  Object.keys(form.controls).forEach(key => {
    if (form.get(key) instanceof UntypedFormGroup) {
      markAllFormControlsAsTouched(form.get(key));
    } else if (form.get(key) instanceof UntypedFormArray) {
      markAllFormControlsAsTouched(form.get(key));
    } else {
      // Mark as touched only for fields that have error.
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        form.get(key).markAsTouched();
        form.get(key).markAsDirty();
      }
    }
  });
}

export function markAllFormControlsAsUnTouched(form) {
  Object.keys(form.controls).forEach(key => {
    if (form.get(key) instanceof UntypedFormGroup) {
      markAllFormControlsAsUnTouched(form.get(key));
    } else if (form.get(key) instanceof UntypedFormArray) {
      markAllFormControlsAsUnTouched(form.get(key));
    } else {
      // Mark as touched only for fields that have error.
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        form.get(key).markAsUntouched();
        form.get(key).markAsPristine();
      }
    }
  });
}

export function hasProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function readCookie(name: string) {
  const list: Array<string> = document.cookie.split(';').map(item => item.replace(/^\s+/g, ''));
  const cookieName = `${name}=`;
  const found = list.find(item => item.indexOf(cookieName) === 0);
  if (found) {
    return found.substring(cookieName.length, found.length);
  }
  return null;
}

export function mapItemsToCodeValue(items: any[]) {
  return items.map((element) => {
    return {
        code: element.code,
        codeValue: element.value + ' (' + element.code + ')',
        value: element.value,
    };
  });
}

export function mapHTTPRequestParams(request: Filter): HttpParams {
  let params = new HttpParams();
  Object.entries(request)
    .filter(([_, value]) => value !== null && value !== undefined && value !== '' && value.length !== 0)
    .forEach(([key, _]) => {
      const value = request[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params = params.append(key, item);
        });
      } else {
        params = params.append(key, value);
      }
    });
  return params;
}

export function capitalizeAllWords(text: string): string {
  return text?.replace(/\b\w/g, char => char.toUpperCase());
}
