import { Component, OnInit } from '@angular/core';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { combineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  selectOnBoardingStatus,
  selectOnBoardingStatusChangeAction,
  selectOnboardingStatusChangeError,
  selectOnboardingStatusChangeLoading,
  selectSupplierDetail
} from '../../store/supplier-detail.selectors';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { SupplierDetail } from '../../models/supplier-detail';
import { closeGeneralTabOnBoardingStatusModal, updateGeneralTabStatus } from '../../store/supplier-detail.actions';
import { SupplierAction } from '../../models/supplier-action';
import { SupplierDetailTabs } from '../../models/supplier-detail-tabs';
import { OnboardingTabStatuses } from '../../../../models/onboarding-tab-statuses';
import { downloadDocument } from '../../../supplier-documents/store/supplier-documents.actions';
import { selectDocumentW9 } from '../../../supplier-documents/store/supplier-documents.selectors';
import { Document } from '../../../supplier-documents/models/document';

@Component({
  selector: 'app-general-tab-status-update-modal',
  templateUrl: './general-tab-status-update-modal.component.html',
  styleUrls: ['./general-tab-status-update-modal.component.scss']
})
export class GeneralTabStatusUpdateModalComponent extends Destroyable implements OnInit {

  readonly alertTypes = AlertTypes;
  alertMessage = 'The Legal Name and Headquarter Address must match the W9 Form exactly as it appears on the form.';
  rejectAlreadyApprovedMessage = 'If the contract documents have already been sent or signed, rejecting the General tab will void' +
    ' those documents and the supplier will need to sign their contract documents after the General tab has been approved.';

  supplier: SupplierDetail;
  document: Document;
  isLoading: boolean;
  onboardingStatusChangeAction: SupplierAction;
  maxlength = 1000;
  comment = '';
  onboardingStatusChangeError: string;

  get counter(): string {
    let count = 0;
    if (this.comment) {
      count = this.comment.length;
    }
    return `${count}/${this.maxlength}`;
  }

  get title() {
    return this.isApproveGeneralTab ? 'Approve General Tab' : 'Reject General Tab';
  }

  get isApproveGeneralTab() {
    return this.onboardingStatusChangeAction === SupplierAction.ApproveGeneralTab;
  }

  get isRejectGeneralTab() {
    return this.onboardingStatusChangeAction === SupplierAction.RejectGeneralTab;
  }

  get isShowRevisionNotes() {
    return this.onboardingStatusChangeAction === SupplierAction.ShowRevisionNotes;
  }

  get isRejectEnabled() {
    return this.comment && this.comment.length > 0 && !this.isLoading;
  }

  constructor(
    private _store: Store,
    ) {
    super();
  }

  public defaultIfEmpty(str: string) {
    return str ? str : '--';
  }

  ngOnInit() {
    combineLatest([
      this._store.pipe(select(selectSupplierDetail)),
      this._store.pipe(select(selectDocumentW9)),
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([supplier, w9]) => {
        this.supplier = supplier;
        this.document = w9;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectOnboardingStatusChangeLoading))
      .subscribe((val) => {
        this.isLoading = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectOnBoardingStatusChangeAction))
      .subscribe((val) => {
        this.onboardingStatusChangeAction = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectOnboardingStatusChangeError))
      .subscribe((val) => {
        this.onboardingStatusChangeError = val;
      });
    this._store.pipe(
      select(selectOnBoardingStatus),
      takeUntil(this.destroy$))
      .subscribe(val => {
        const generalTabStatus = val.find(item => item.tab === SupplierDetailTabs.general);
        if (generalTabStatus) {
          this.comment = generalTabStatus.statusNotes || '';
        }
      });
  }

  downloadW9() {
    this._store.dispatch(downloadDocument({document: this.document}));
  }

  closeModal() {
    if (this.isLoading) {
      return;
    }
    this._store.dispatch(closeGeneralTabOnBoardingStatusModal());
  }

  onConfirmClicked() {
    if (this.isRejectGeneralTab && !this.isRejectEnabled) {
      return;
    }
    if (this.isLoading) {
      return;
    }
    this._store.dispatch(updateGeneralTabStatus(
      {
        status: this.onboardingStatusChangeAction === SupplierAction.ApproveGeneralTab ?
          OnboardingTabStatuses.Approved : OnboardingTabStatuses.Rejected,
        statusNote: this.comment,
      }
    ));
  }
}
