<kehe-phoenix-modal name="Brands" [modalTitle]="title()" [show]="true" (modalEvent)="onClose()" size="md">
  <div body>
    <div>
      <div class="row">
        <div class="col">
          <h5>Brand(s)</h5>
        </div>
      </div>
      <div class="kehe-grid no-scroll p-0 w-100">
        <kendo-grid [data]="brands" [sortable]="{
                        allowUnsort: false,
                        mode: 'single'
                      }" [sort]="sort" (sortChange)="sortChange($event)">

          <kendo-grid-column field="name" title="Brand" filter="string" [sortable]="true" headerClass="kehe-grid-header-hover">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.name | emptyValue }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="code" title="Code" filter="string" [sortable]="true" headerClass="kehe-grid-header-hover">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.code | emptyValue }}</div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button (click)="onClose()" classAttr="btn-primary btn-medium">Close</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
