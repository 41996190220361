import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as AuthenticationSelectors from '../shared/state/authentication/authentication.selectors';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

    constructor(private store: Store) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const isAWSRequest = this.isAWSRequest(request);
        if (isAWSRequest) {
            return next.handle(request);
        }

        return this.store.select(AuthenticationSelectors.selectUserTokens).pipe(
            take(1),
            concatMap(tokens => {
                if (!!tokens) {
                    const isV1Request = this.isV1Request(request);
                    if (isV1Request) {
                        request = this.addToken(request, next, tokens.legacyToken);
                        return next.handle(request);
                    } else {
                        request = this.addToken(request, next, tokens.token);
                        return next.handle(request);
                    }
                } else {
                    return next.handle(request);
                }
            })
        );
    }

    addToken(request: HttpRequest<any>, next: HttpHandler, accessToken: string): HttpRequest<any> {
        return request.clone({ setHeaders: { Authorization: 'Bearer ' + accessToken } });
    }

    isAWSRequest(request: HttpRequest<any>) {
        return new URL(request.url).hostname.endsWith('s3.amazonaws.com');
    }

    isV1Request(request: HttpRequest<any>) {
        return request.url.indexOf(environment.v1ConnectApi) > -1;
    }
}
