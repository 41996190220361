import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayAcceptsExcludedProgram',
  standalone: true,
})
export class DisplayAcceptsExcludedProgramPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case true:
        return `Accepts Program`;
      case false:
        return `Excluded from Program`;
      default:
        return '--';
    }
  }

}
