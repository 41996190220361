import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { selectIsFeatureFlagOn, selectHaveFeatureFlagsBeenFetched } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Constants } from '../../../constants/constants';
import { FeatureFlags } from '@app/feature-flag';

@Injectable({
    providedIn: 'root'
})
export class ProductListsManagementGuard  {
    constructor(
        private store: Store,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.select(selectHaveFeatureFlagsBeenFetched).pipe(
            filter((flagsHaveBeenFetched) => flagsHaveBeenFetched),
            switchMap(() => {
                return this.store.pipe(
                    select(selectIsFeatureFlagOn(FeatureFlags.MyLists.key)),
                    withLatestFrom(this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.CustomListRead))),
                    map(([isMyListsFfOn, canReadCustomLists]) => (isMyListsFfOn && canReadCustomLists) || this.router.parseUrl(''))
                );
            })
        )
    }
}
