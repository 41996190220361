import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContactType } from '../../../../models/contact';
import { InboundRouteType } from '../../../../models/dcpg-inbound-route-type';
import { Constants } from '../../../../constants/constants';

@Component({
  selector: 'app-bulk-edit-lead-times-pickup-address-card',
  templateUrl: './bulk-edit-lead-times-pickup-address-card.component.html',
  styleUrls: []
})
export class BulkEditLeadTimesPickupAddressCardComponent {

  readonly contactTypes = ContactType;
  inboundRouteTypes: { name: string; type: InboundRouteType }[] =
    Constants.InboundRouteTypes;
    
  @Input() 
  form: UntypedFormGroup;

  get pickUpReadyTime() {
    return this.form.get('pickupLeadTimes.readyTime');
  }

  get inboundRouteTypeField() {
    return this.form.get('inboundRouteType');
  }
}
