<div *ngIf="form">
  <hr>
  <form [formGroup]="form">
    <div class="note-title">Add Note</div>
    <p>You have made changes. Add details below explaining the request origin.</p>
    <div>
        <kendo-formfield>
            <kendo-label for="ticketNumber" text="Ticket Number (Optional)"></kendo-label>
            <kendo-textbox name="ticketNumber"
                            placeholder="e.g. CWI-867-5309"
                            [formControlName]="controlName.ticketNumber"
                            maxlength="32">
            </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="mt-3">
        <kendo-formfield>
            <kendo-label for="requestReason" text="Request Reason"></kendo-label>
            <kendo-textarea name="requestReason"
                            placeholder="Enter Request Reason"
                            [formControlName]="controlName.requestReason"
                            [maxlength]="requestReasonMaxLength">
                <kendo-textarea-suffix class="kehe-textarea-counter"> {{ requestReasonCharacterCounter }}</kendo-textarea-suffix>
            </kendo-textarea>
            <kendo-formerror>Request Reason is required.</kendo-formerror>
        </kendo-formfield>
      </div>
  </form>
</div>
