<kehe-phoenix-modal
    *ngIf="vm$ | async as vm"
    name="RemoveBrokerModal"
    [show]="true"
    modalTitle="Remove Broker"
    (modalEvent)="handleKeheModalEvents($event)"
    size="md"
>
    <div body>
        <kehe-phoenix-alert
            *ngIf="vm.hasApiError"
            [show]="true"
            type="error"
            [message]="vm.apiErrorMessage"
            automationId="broker-v2-table-delete-modal-error"
            [stick]="true"
            [hideCloseIcon]="true"
            class="mb-3 api-alert"
        >
        </kehe-phoenix-alert>

        <p *ngIf="vm.isBroker">Are you certain that you would like to remove <strong>{{vm?.rowInfo?.brokerName}}</strong> from this account?</p>
        <p *ngIf="!(vm.isBroker)">Are you certain that you would like to remove this associated Broker document from this account?</p>

        <app-add-note *ngIf="!vm.isDraftSupplier"></app-add-note>
    </div>

    <div footer class="d-flex justify-content-end px-1">
        <kehe-phoenix-button
            classAttr="btn-link btn-medium"
            name="cancel"
            [disabled]="vm.isDeleting"
            automationId="broker-v2-table-delete-modal-cancel-button"
            (click)="closeModal()"
        >
            Cancel
        </kehe-phoenix-button>
        <kehe-phoenix-button
            classAttr="btn-delete btn-medium ml-2"
            name="remove-broker"
            (click)="confirmDeletion()"
            automationId="broker-v2-table-delete-modal-remove-button"
            [spinner]="vm.isDeleting"
            [disabled]="vm.isRemoveButtonDisabled"
        >
            Remove
        </kehe-phoenix-button>
    </div>
</kehe-phoenix-modal>
