import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import * as SupplierDetailActions from '../supplier-detail.actions';
import * as SupplierDocumentsActions from '../../../supplier-documents/store/supplier-documents.actions';

@Injectable()
export class SupplierDocumentsEffects {
  constructor(
    private readonly actions$: Actions,
  ) {}

  loadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.updateGeneralTabStatusSuccess),
      map(() => SupplierDocumentsActions.loadDocuments())
    )
  );
}
