import { Payload } from '@app/models/payload';
import { UserListFilter } from '@app/modules/user-list/models/user-list.filter';
import { User } from '@app/modules/user-list/models/user.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserGroupCategory } from '../models/user-group-category.model';

export const UserListLoadActions = createActionGroup({
  source: 'User List Load Actions',
  events: {
    filterChanged: props<{ filter: UserListFilter; }>(),
    clearFilters: emptyProps(),
    loadData: emptyProps(),
    loadAllGroupCategoriesSuccess: props<{ response: Payload<UserGroupCategory[]> }>(),
    loadAllGroupCategoriesFailed: props<{ error: Error; }>(),
    loadUserLists: emptyProps(),
    loadUserListsSuccess: props<{ userData: Payload<User[]>, categories: UserGroupCategory[] }>(),
    loadUserListsFailed: props<{ error: Error; }>(),
  },
});
