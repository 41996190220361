<div class="px-4" *ngIf="!isGeneralTabApproved">
  <div class="row kehe-data-section mt-5 d-flex justify-content-center">
    <div class="col-12 col-md-7 kehe-data-value">
      <div class="d-flex flex-column">
        <div class="oval mx-auto d-flex justify-content-center flex-grow-0 flex-shrink-0">
          <em class="fas fa-seedling icon my-auto pt-2" aria-hidden="true"></em>
        </div>
        <div class="mt-3">
          <h4 class="text-center">
            DocuSign documents are still being prepared.
          </h4>
          <p class="mt-2">
            The Legal Name and W9 Form are currently being validated by KeHE.
            When validation has been completed, your DocuSign documents will be ready for signing.
            You may continue working on your remaining onboarding steps.
          </p>
          <p class="mt-4 mb-2">
            Here’s a few things to work on:
          </p>
          <ul class="pl-3 font-weight-normal">
            <li>Add required contacts on the Contacts tab.</li>
            <li>Upload any additional required documents on the Documents tab.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="px-4" *ngIf="isGeneralTabApproved && form" [formGroup]="form">

  <div class="row">
    <div class="col">
      <h4>Legal & Signatures</h4>
    </div>
  </div>
  <div class="row mt-2 kehe-data-value">
    <div class="col-12">
      <div class="banner p-3">
        <div class="row">
          <div class="col-12">
            <h5>Who is your authorized signee?</h5>
          </div>
          <div class="col-12">
            KeHE uses DocuSign to electronically sign your contract documents.
          </div>
          <div class="col-12 mt-2">
            <p class="mb-0">
              1. Designate the signee responsible for signing your KeHE contract.
            </p>
            <p class="mb-0">
              2. Click “Send DocuSign Email” to send the contract link to the signee.
            </p>
            <p class="mb-0">
              3. Use the contract link sent in the email to review and sign:
            </p>
            <div class="ml-2">
              <ul class="pl-4 mb-0">
                <li>KeHE Policies & Procedures Terms Form</li>
                <li>Hold Harmless Agreement</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 col-md-6 mb-3 mb-md-0">
      <kendo-formfield>
        <kendo-label [for]="nameField" text="Signee’s Full Name"></kendo-label>
        <kendo-textbox
          #nameField
          formControlName="name"
          placeholder="Enter signee's full name">
        </kendo-textbox>
        <kendo-formerror>
          Signee’s Full Name is required.
        </kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col-12 col-md-6">
      <kendo-formfield>
        <kendo-label [for]="emailField" text="Signee’s Email"></kendo-label>
        <kendo-textbox
          #emailField
          formControlName="emailAddress"
          placeholder="Enter signee's email">
        </kendo-textbox>
        <kendo-formerror *ngIf="form.get('emailAddress')?.errors?.required">Signee’s Email is required.</kendo-formerror>
        <kendo-formerror *ngIf="form.get('emailAddress')?.errors?.email">Invalid email format</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 col-md-6">
      <kendo-formfield>
        <kendo-label [for]="titleField" text="Signee’s Title"></kendo-label>
        <kendo-textbox
          #titleField
          formControlName="title"
          placeholder="Enter signee's current job title">
        </kendo-textbox>
        <kendo-formerror>
          Signee’s Title is required.
        </kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <input type="checkbox" #isAuthorisedField kendoCheckBox formControlName="isAuthorised"/>
      <kendo-label class="k-checkbox-label" [for]="isAuthorisedField"
                   text="I certify that this individual is authorized to sign the DocuSign documents.">
      </kendo-label>
    </div>
  </div>
  <div class="row kehe-data-value mt-4">
    <div class="col-12">
      <h5>Tips for Signing</h5>
    </div>
    <div class="col-12">
      Please keep the following in mind:
    </div>
    <div class="col-12 mt-1">
      <ul class="pl-3 font-weight-normal">
        <li>
          All contract documents must reflect the same Legal name (not DBA) and must be
          signed by an authorized signee of the company (not your Broker).
        </li>
        <li>
          Any changes to these contract documents are NOT accepted and
          concerns should be addressed with your Category Manager.
        </li>
      </ul>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 d-flex flex-row-reverse">
      <kehe-phoenix-button (click)="onSendClick()" classAttr="btn-primary btn-medium" [disabled]="!readyToSend">
        Send DocuSign Email
      </kehe-phoenix-button>
    </div>
    <div class="col-12 mt-2 d-flex flex-row-reverse docusign-send-date" *ngIf="isDocuSignSend()">
      Contract Documents Sent {{supplier?.docuSignData?.sentDate | date: 'MM/dd/yyyy h:mm a'}}
    </div>
    <div class="col-12 mt-2 d-flex flex-row-reverse docusign-send-date" *ngIf="isDocuSignCompleted()">
      Contract Documents Signed
      {{supplier?.docuSignData?.completedDate | date: 'MM/dd/yyyy h:mm a'}}
    </div>
  </div>
</div>
<app-docusign-email-modal *ngIf="showDocuSignSignerConfirmation"></app-docusign-email-modal>
