import {
  selectOnBoardingStatus,
  selectSupplierDetail,
  selectTermsDropdownData,
} from '../../store/supplier-detail.selectors';
import { createSelector } from '@ngrx/store';

export const selectTermsViewVM = createSelector(
  selectSupplierDetail,
  selectOnBoardingStatus,
  selectTermsDropdownData,
  (
    supplier,
    onboardingStatus,
    termsDropDownData,) => {
    return {
      supplier,
      onboardingStatus,
      termsDropDownData,
    };
  },
);
