import { createAction, props } from '@ngrx/store';
import { User } from '../models/user';
import { UserDetails } from '../models/user-details';

export const loadSupplierUsers = createAction(
    '[Supplier Users] Load Supplier Users',
    props<{ esn: string }>()
);
export const loadSupplierUsersSuccess = createAction(
    '[Supplier Users] Load Supplier Users Success',
    props<{ users: User[] }>()
);
export const loadSupplierUsersError = createAction(
    '[Supplier Users] Load Supplier Users Error',
);

export const showAddUserModal = createAction(
    '[Supplier Users] Show Add User Modal',
);
export const closeAddUserModal = createAction(
    '[Supplier Users] Close Add User Modal',
);

export const requestAddUser = createAction(
    '[Supplier Users] Request Add User',
    props<{ user: User }>()
);
export const createUserInV1 = createAction(
    '[Supplier Users] Create User In V1',
    props<{ user: User }>()
);
export const associateV2Permissions = createAction(
    '[Supplier Users] Associate V2 Permissions',
    props<{ user: User }>()
);
export const createUserInV2 = createAction(
    '[Supplier Users] Create User In V2',
    props<{ user: User }>()
);
export const addUserSuccess = createAction(
    '[Supplier Users] Add User Success',
    props<{ user: User }>()
);
export const addUserError = createAction(
    '[Supplier Users] Add User Error',
    props<{ errorMessage: string }>()
);

export const checkUserTypeInV1 = createAction(
    '[Supplier Users] Check User Type In V1',
    props<{ user: User }>()
);

export const assignAdmin = createAction(
    '[Supplier Users] Assign Admin',
    props<{ user: User }>()
);
export const assignAdminSuccess = createAction(
    '[Supplier Users] Assign Admin Success',
    props<{ user: User }>()
);
export const assignAdminError = createAction(
    '[Supplier Users] Assign Admin Error',
    props<{ user: User, error: any }>()
);

// Resend Invitation
export const resendInvitation = createAction(
    '[Supplier Users] Resend Invitation',
    props<{ user: User }>()
);
export const resendInvitationSuccess = createAction(
    '[Supplier Users] Resend Invitation Success',
    props<{ user: User }>()
);
export const resendInvitationError = createAction(
    '[Supplier Users] Resend Invitation Error',
    props<{ user: User, error: any }>()
);
// Resend Invitation

// Delete User
export const askDeleteUser = createAction(
    '[Supplier Users] Ask Delete User',
    props<{ user: User }>()
);
export const cancelDeleteUser = createAction(
    '[Supplier Users] Cancel Delete User'
);
export const deleteUser = createAction(
    '[Supplier Users] Delete User',
    props<{ user: User }>(),
  );
  export const deleteUserSuccess = createAction(
    '[Supplier Users]: Delete User Success',
    props<{ user: User }>(),
  )
  export const deleteUserError = createAction(
    '[Supplier Users]: Delete User Error',
    props<{ user: User, error: any }>(),
  );
// Delete User

// User Status
export const loadUserStatusSuccess = createAction(
    '[Supplier Users] Load User Status Success',
    props<{ users: UserDetails[] }>()
);
export const loadUserStatusError = createAction(
    '[Supplier Users] Load User Status Error',
);
// User Status
