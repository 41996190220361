<div class="px-4">

  <div class="row">
    <div class="col">
      <h4>Summary</h4>
    </div>
  </div>

  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Product Group Name
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{ productGroup?.name }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Product Group Number
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{ productGroup?.productGroupNumber }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Temp Class
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{ tempClass }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Brands
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          <div *ngFor="let brand of brands">{{ brand }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>

<div class="px-4">

  <div class="row">
    <div class="col">
      <h4>Remittance Information</h4>
    </div>
  </div>
  <div class="row kehe-data-section" *ngIf="showContactCard">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Address
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          <div *ngIf="productGroup.remittanceAddressContact; else elseBlock">
            <div class="address-content">{{ getAddressText() }}</div>
            <div class="address-content mt-2" *ngIf="getContactText() !== null">{{ getContactText() }}</div>
          </div>
          <ng-template #elseBlock>
            --
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row kehe-data-section" *ngIf="!showContactCard">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Remit Name (On Invoice)
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{productGroup?.remittanceAddress?.name}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Attention
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{productGroup?.remittanceAddress?.attention}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Address
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          <div>
            <div *ngIf="productGroup?.remittanceAddress?.formattedAddress else standardRemittanceAddress">
              <div *ngFor="let line of productGroup.remittanceAddress.formattedAddress.split('|')">
                {{line}}
              </div>
            </div>
            <ng-template #standardRemittanceAddress>
              <div>{{productGroup?.remittanceAddress?.street1}}</div>
              <div>{{productGroup?.remittanceAddress?.street2}}</div>
              <div>{{productGroup?.remittanceAddress?.city}}
                {{productGroup?.remittanceAddress?.state?.code || productGroup?.remittanceAddress?.province}}
                {{productGroup?.remittanceAddress?.zipCode}}</div>
            </ng-template>
            <div>{{productGroup?.remittanceAddress?.country?.code}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Phone
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          <span>{{defaultIfEmpty(productGroup?.remittanceAddress?.phone | phoneNumber)}}</span>
          <span
            *ngIf="productGroup?.remittanceAddress?.extension">, Ext {{productGroup?.remittanceAddress?.extension}}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Fax
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(productGroup?.remittanceAddress?.fax | phoneNumber)}}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Email
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{defaultIfEmpty(productGroup?.remittanceAddress?.email)}}
        </div>
      </div>
    </div>
  </div>
</div>

<hr>

<div>
  <kehe-phoenix-tabs [tabs]="tabs" [activeTab]="selectedTab">
  </kehe-phoenix-tabs>
  <div class="tab-content">
    <app-product-groups-dc-tab *ngIf="productGroup" [dcProductGroups]="productGroup.dcProductGroups"
      [supplier]="supplier">
    </app-product-groups-dc-tab>
  </div>
</div>
