import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Item } from '../models/item';
import { ItemFilter } from '../models/item-filter';
import { environment } from '../../environments/environment';
import { Payload } from '../models/payload';
import { Environment } from '../../environments/environment-enum';

@Injectable()
export class ItemService {
  private _url: string;
  private _customerProducts: string;
  private _customerProductsProxyPlus: string;

  constructor(private http: HttpClient) {
    this._url = environment.api + 'items';
    this._customerProducts = `${environment.apiGateway}/customer-products/product`;
    this._customerProductsProxyPlus = `${environment.customerProductsApi}/product`;
  }

  get(filter: ItemFilter = null): Observable<Payload<Array<Item>>> {
    // We need to extract the code and name from the filter returned for traits because
    // the API needs the code for the lookup, but we have to pass the code and name around in
    // the filter so that the trait selection can handle switching warehouses and the previously
    // selected trait is no longer valid
    const traits = this.extractCode(filter.traitCode);

    // note: hopefully this will be upgraded soon, but currently we need to check
    // null values individually and set as params instead of passing the object
    // literal like we used to do when using HttpClientModule
    let params = new HttpParams()
      .set('search'               , filter.search ? filter.search : '')
      .set('categoryCode'         , filter.categoryCode ? filter.categoryCode : '')
      .set('categoryName'         , filter.categoryName ? filter.categoryName : '')
      .set('subCategoryName'      , filter.subCategoryName ? filter.subCategoryName : '')
      .set('brandName'            , filter.brandName ? filter.brandName : '')
      .set('traitCode'            , traits)
      .set('sortOrder'            , filter.sortOrder.toString())
      .set('orderDirection'       , filter.orderDirection.toString())
      .set('pageIndex'            , filter.pageIndex.toString())
      .set('pageCount'            , filter.pageCount.toString())
      .set('includeSeasonal'      , filter.includeFilters.seasonal.toString())
      .set('includeDiscontinued'  , filter.includeFilters.discontinued.toString())
      .set('includeProhibited'    , filter.includeFilters.prohibited.toString())
      .set('includeNotAuthorized' , filter.includeFilters.unauthorized.toString())
      .set('includeAvailableCount', filter.includeAvailableCount.toString())
      .set('distributionCenterItemNumberOrUpc', filter.distributionCenterItemNumberOrUpc ? filter.distributionCenterItemNumberOrUpc : '')
      .set('onlyNew'              , filter.featuredFilters.newFilter.toString())
      .set('onlyPromo'            , filter.featuredFilters.promoFilter.toString());

    if (filter.upcList.length > 0) {
      params = params.append('upcList', filter.upcList.join(','));
    }

    const dc = filter.dcCustomerNumber.slice(0, 2);
    const customerNumber = filter.dcCustomerNumber.slice(2, filter.dcCustomerNumber.length + 1);
    const url = `${this._customerProductsProxyPlus}/${dc}/${customerNumber}`;
    return this.http.get<Payload<Array<Item>>>(url, { params: params });
  }

  extractCode(codeNameList): string {
    let ret = '';
    if (codeNameList) {
      const myList = codeNameList.split('|');
      for (let i = 0; i < myList.length; i++) {
        if (myList[i] !== '') {
          const codeName = myList[i].split('~');

          // TODO: remove "Fresh" translation once traits are refactored
          if (codeName[0] !== 'Fresh') {
            ret += codeName[0];
          } else {
            ret += this.translateFreshTrait();
          }
          ret += '|';
        }
      }
    }
    return ret;
  }

  getPromotionalItems(): Observable<Array<Item>> {
    // const url = this._url + '/promo';
    // return this.http.get<Array<Item>>(url);
    return of();
  }

  getRecentItems(): Observable<Array<Item>> {
    // const url = this._url + '/recent';
    // return this.http.get<Array<Item>>(url);
    return of();
  }

  single(dcCustomerNumber: string, upc: string): Observable<Payload<Item>> {
    const dc = dcCustomerNumber.slice(0, 2);
    const customerNumber = dcCustomerNumber.slice(2, dcCustomerNumber.length + 1);
    const url = `${this._customerProducts}/${dc}/${customerNumber}/${upc}`;
    return this.http.get<Payload<Item>>(url);
  }

  exportPrice(request: any) {
    const url = this._url + '/exportprice';
    return this.http.get<any>(url, { params: request, responseType: 'blob' as 'json' });
  }

  private translateFreshTrait() {
    switch (environment.current) {
      case Environment.UAT:
      case Environment.PROD:
        return 'KeHE Fresh - Monterrey';
      default:
        return 'KeHE Fresh/Monterrey';
    }
  }
}
