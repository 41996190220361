import { Component } from '@angular/core';
import { AssociatedSuppliersTabGridStore } from './associated-suppliers-tab-grid.store';
import { AssociatedSupplierRow } from '../../../models/associated-suppliers-response';
import { SortDescriptor } from '@progress/kendo-data-query';
import { AssociatedSupplierTabStore } from '../associated-supplier-tab.store';
import { AssociatedSuppliersSortColumn, AssociatedSuppliersSortOrder } from '../models/associated-suppliers-tab.enum';
import { skeletonBigText, skeletonSmallText } from '../../../../../../app/utils/skeleton-theme';
import { KeheErrorConfig, KeheErrorMessages, KeheErrorSizes, KeheMascotStates } from '@kehe/phoenix-errors';

@Component({
  selector: 'app-associated-suppliers-tab-grid',
  templateUrl: './associated-suppliers-tab-grid.component.html',
  styleUrls: ['./associated-suppliers-tab-grid.component.scss'],
})
export class AssociatedSuppliersTabGridComponent {
  constructor(private _componentStore: AssociatedSuppliersTabGridStore,
              private _componentStoreTab: AssociatedSupplierTabStore) {}
  skeleton = skeletonSmallText;
  skeletonBigText = skeletonBigText;
  public notLoadingConfig = new KeheErrorConfig(
    KeheErrorSizes.LG,
    KeheMascotStates.Error,
    null,
    KeheErrorMessages.Error,
    null
  );
  vm$ = this._componentStore.vm$;

  handleRowSelection(row: AssociatedSupplierRow) {
    this._componentStore.handleRowSelection$(row);
  }

  gridItemExpandKey(dataItem: AssociatedSupplierRow): string {
    return dataItem?.esn;
  }

  sortChange(sortEvent: SortDescriptor[]) {
    this._componentStore.updateSortOptions(sortEvent);
    if (sortEvent && sortEvent.length > 0) {
      const dir = sortEvent[0].dir ? sortEvent[0].dir : 'asc';
      const sortDirection = dir === 'asc' ? AssociatedSuppliersSortOrder.ascending : AssociatedSuppliersSortOrder.descending;
      const sortColumn = AssociatedSuppliersSortColumn[sortEvent[0].field];
      this._componentStoreTab.sortChanged({ sortDirection, sortColumn });
    }
  }

  refreshPage() {
    window.location.reload();
  }

  expandAssociatedSupplier(esn: string): void {
    this._componentStore.updateExpandAll(false);
    this._componentStore.expandAssociatedSupplier$(esn);
  }

  collapseAssociatedSupplier(esn: string): void {
    this._componentStore.updateExpandAll(true);
    this._componentStore.collapseAssociatedSupplier$(esn);
  }

  expandAllAssociatedSuppliers(): void {
    this._componentStore.updateExpandAll(false);
    this._componentStore.expandAllAssociatedSuppliers$();
  }

  collapseAllAssociatedSuppliers(): void {
    this._componentStore.updateExpandAll(true);
    this._componentStore.collapseAllAssociatedSuppliers$();
  }

  toggleCheckAll() {
    this._componentStore.toggleCheckAll$();
  }

  deleteRowClicked(rowToDelete: AssociatedSupplierRow) {
    this._componentStore.showDeleteConfirmationModal(rowToDelete);
  }
}
