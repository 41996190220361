import { Action, createReducer, on } from '@ngrx/store';
import { PersonContact } from '../models/person-contact';
import * as SupplierContactsActions from './supplier-contacts.actions';

export class SupplierContactsState {
  esn: string;
  isLoading: boolean;
  contacts: PersonContact[];
  manageContact: PersonContact;
  isSavingContact: boolean;
  saveContactError: string;
  deleteContact: PersonContact;
  primaryContact: PersonContact;
  notifyDiscProductsContact: PersonContact;
}

export const initializeState = (): SupplierContactsState => {
  return {
    esn: null,
    isLoading: false,
    contacts: [],
    manageContact: null,
    isSavingContact: false,
    saveContactError: null,
    deleteContact: null,
    primaryContact: null,
    notifyDiscProductsContact: null,
  };
};

export const initialState = initializeState();

const rootReducer = createReducer(
  initialState,
  // Load Contacts
  on(SupplierContactsActions.loadSupplierContacts, (state, action) => ({
    ...state,
    esn: action.esn,
    isLoading: true,
  })),
  on(SupplierContactsActions.loadSupplierContactsSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    contacts: action.contacts || [],
  })),
  on(SupplierContactsActions.loadSupplierContactsError, (state) => ({
    ...state,
    isLoading: false,
  })),
  // Add Contact
  on(SupplierContactsActions.showAddSupplierContactModal, (state) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    saveContactError: initialState.saveContactError,
    manageContact: {
      esn: state.esn,
    },
  })),
  on(SupplierContactsActions.editSupplierContact, (state, action) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    saveContactError: initialState.saveContactError,
    manageContact: action.contact,
  })),
  on(SupplierContactsActions.cancelManageSupplierContact, (state) => ({
    ...state,
    manageContact: initialState.manageContact,
    primaryContact: initialState.primaryContact,
    notifyDiscProductsContact: initialState.notifyDiscProductsContact,
    isSavingContact: initialState.isSavingContact,
    saveContactError: initialState.saveContactError,
  })),
  on(
    SupplierContactsActions.deleteSupplierContact,
    SupplierContactsActions.saveSupplierContact,
    (state) => ({
      ...state,
      isSavingContact: true,
      saveContactError: initialState.saveContactError,
    })
  ),
  on(SupplierContactsActions.saveSupplierContactSuccess, (state, action) => ({
    ...state,
    contacts: [...mergeSavedContact(state.contacts, action.contact)],
    manageContact: initialState.manageContact,
    primaryContact: initialState.primaryContact,
    notifyDiscProductsContact: initialState.notifyDiscProductsContact,
    isSavingContact: initialState.isSavingContact,
    saveContactError: initialState.saveContactError,
  })),
  on(SupplierContactsActions.saveSupplierContactError, (state, action) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    saveContactError: action.errorCode === 409 ? 'User already exists.' : 'There was an error. Please try again.',
  })),

  // Delete Contact
  on(SupplierContactsActions.showDeleteSupplierContactConfirmation, (state, action) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    saveContactError: initialState.saveContactError,
    deleteContact: action.contact,
  })),
  on(SupplierContactsActions.cancelDeleteSupplierContact, (state) => ({
    ...state,
    deleteContact: initialState.manageContact,
    isSavingContact: initialState.isSavingContact,
    saveContactError: initialState.saveContactError,
  })),
  on(SupplierContactsActions.deleteSupplierContactSuccess, (state, action) => ({
    ...state,
    contacts: [...state.contacts.filter(item => item.uniqueId !== action.contact.uniqueId)],
    deleteContact: initialState.manageContact,
    isSavingContact: initialState.isSavingContact,
    saveContactError: initialState.saveContactError,
  })),
  on(SupplierContactsActions.deleteSupplierContactError, (state) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    saveContactError: 'There was an error. Please try again.',
  })),

  // Assign Primary Contact
  on(SupplierContactsActions.showAssignPrimaryContactModal, (state, action) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    saveContactError: initialState.saveContactError,
    primaryContact: action.contact,
  })),

  // Assign Discontinued Products Notification
  on(SupplierContactsActions.showNotifyDiscProductsModal, (state, action) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    saveContactError: initialState.saveContactError,
    notifyDiscProductsContact: action.contact,
  })),
);

export function reducer(state: SupplierContactsState | undefined, action: Action) {
  return rootReducer(state, action);
}


function mergeSavedContact(contacts: PersonContact[], contact: PersonContact): PersonContact[] {
  if (contact.isPrimary) {
    contacts.forEach(item => {
      item.isPrimary = false;
    });
  }
  if (contact.subscribedToDiscontinuedProductNotification) {
    contacts.forEach(item => {
      item.subscribedToDiscontinuedProductNotification = false;
    });
  }
  const existing = contacts.find(item => item.uniqueId === contact.uniqueId);
  if (existing) {
    return contacts.map(item => item.uniqueId === contact.uniqueId ? contact : item);
  } else {
    contacts.push(contact);
  }
  return contacts;
}


