<div class="supplier-detail-component">

  <!-- hide xs-md, show lg+ -->
  <div class="side-panel d-none d-lg-block mr-4" *ngIf="!generalLoading">
    <kehe-phoenix-navigation-panel *ngIf="!isSupplierInDraft()" [tabs]="detailTabs" [open]="displayTabPanel$ | async"
      (toggled)="navigationPanelToggled()" (tabSelected)="navigationPanelSetTab($event)" [activeTab]="currentTab"
      containerMarginTop="-29">
    </kehe-phoenix-navigation-panel>
    <app-checklist-navigation-panel *ngIf="isSupplierInDraft()" [tabs]="supplierValidatedTabs"
      [open]="displayTabPanel$ | async" (toggled)="navigationPanelToggled()"
      (tabSelected)="navigationPanelSetTab($event)" [activeTab]="currentTab" containerMarginTop="-29">
    </app-checklist-navigation-panel>

  </div>

  <div class="supplier-detail-content ml-lg-4">

    <div *ngIf="showNewContractWarning()">
      <kehe-phoenix-alert [show]="true" [type]="alertTypes.Warning" [message]="termsChangeWarning" [stick]="true"
      [hideCloseIcon]="true" [action1]="'Download'" (alertEvent)="downloadAddendumAlertClicked()">
    </kehe-phoenix-alert>
    </div>
    <div class="pb-3">
      <kehe-phoenix-alert *ngIf="onBoardingStatusAlertMessage()" [type]="onBoardingStatusAlertMessage().type" [message]="onBoardingStatusAlertMessage().message" stick="true"
        [show]="true" [hideCloseIcon]="true">
      </kehe-phoenix-alert>
      <kehe-phoenix-alert [type]="supplierValidationError?.type" [messageAsHtml]="supplierValidationError?.message" stick="true"
        [show]="supplierValidationError?.show" [hideCloseIcon]="true">
      </kehe-phoenix-alert>
      <kehe-phoenix-alert *ngIf="alertMessage?.message && !isBrokerV2Tab" [type]="alertMessage?.type" (alertEvent)="hideAlertMessage()"
        [message]="alertMessage?.message" [stick]="true" [show]="true">
      </kehe-phoenix-alert>
      <kehe-phoenix-alert *ngIf="leadTimeAlert?.message" [type]="leadTimeAlert?.type" (alertEvent)="hideAlertMessage()"
                  [message]="leadTimeAlert?.message" [stick]="true" [show]="true">
      </kehe-phoenix-alert>
      <kehe-phoenix-alert *ngIf="documentsAlertMessage?.message" [type]="documentsAlertMessage?.type"
        (alertEvent)="hideAlertMessage()" [message]="documentsAlertMessage?.message" [stick]="true" [show]="true">
      </kehe-phoenix-alert>
      <kehe-phoenix-alert *ngIf="(cloneDCPGSaveError$ | async) as error" type="error"
        [message]="error" [stick]="true" [show]="true">
      </kehe-phoenix-alert>
      <kehe-phoenix-alert *ngIf="dcPaymentHoldAlert" [type]="dcPaymentHoldAlert.type"
        [message]="dcPaymentHoldAlert.message" [stick]="true" [show]="true" [hideCloseIcon]="true">
      </kehe-phoenix-alert>
      <div class="mb-2" *ngFor="let alert of headerAlerts">
        <kehe-phoenix-alert [type]="alert?.type" [message]="alert?.message" [messageAsHtml]="alert?.messageAsHtml" stick="true"
          [show]="true" [hideCloseIcon]="true">
        </kehe-phoenix-alert>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8 col-sm-6 mb-2 mb-sm-0">
        <kendo-breadcrumb [items]="items$ | async" (itemClick)="onItemClick($event)"></kendo-breadcrumb>
      </div>
    </div>
    <div *ngIf="!generalLoading" class="row mt-2" [ngClass]="{' align-items-end': showRevisionNotes()}">
      <div class="col-md-8 col-sm-6 mb-2 mb-sm-0" [class.add-product-groups-spacer]="canShowAddProductGroupButton">
        <div class="comment-container-wrapper" *ngIf="showRevisionNotes()">
          <kehe-phoenix-button classAttr="btn-link btn-medium mt-2" iconClassAttr="fa fa-comment"
            (click)="revisionNotesClicked()">
            Revision Notes
          </kehe-phoenix-button>
        </div>
        <div class="d-none d-sm-block">
          <div *ngFor="let item of leftActionButtons" [ngClass]="{'comment-container-wrapper': item.showBubble}">
            <kehe-phoenix-button *ngIf="item.visible" (click)="actionButtonClicked(item)" [disabled]="item.disabled"
              [spinner]="item.loading" [classAttr]="item.classAttr" [iconClassAttr]="item.icon">
              {{item.type}}
            </kehe-phoenix-button>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-block col-md-4 col-sm-6 text-right ng-star-inserted" [class.add-product-group-buttons]="canShowAddProductGroupButton">
        <div *ngIf="canShowActionBar && currentTab !== 'Purchasing Options'" class="ellipsis-container">
          <kehe-phoenix-button *ngIf="canShowSaveCancelButton" (click)="cancel()" [disabled]="isSaveInProgress"
            classAttr="btn-secondary btn-medium">Cancel</kehe-phoenix-button>
          <kehe-phoenix-button *ngIf="canShowSaveCancelButton" (click)="onSaveButtonClick()"
            [spinner]="isSaveInProgress && !isSubmitInProgress" [disabled]="isSaveDisabled()"
            classAttr="btn-primary btn-medium ml-2">
            Save
          </kehe-phoenix-button>
          <kehe-phoenix-button *ngIf="canShowSubmitButton" (click)="onSubmitButtonClick()" [spinner]="isSubmitInProgress"
            [disabled]="isSubmitDisabled()" classAttr="btn-secondary btn-medium ml-2" id="supplier-detail-activate">
            {{getSubmitButtonText}}
          </kehe-phoenix-button>
          <kehe-phoenix-button *ngIf="canShowEditButton && !isBrokerV2Tab && !isAssociatedSuppliersTab" (click)="edit()" classAttr="btn-primary btn-medium ml-2" [disabled]="disableEditButton" id="supplier-detail-edit">
            Edit
          </kehe-phoenix-button>
          <kehe-phoenix-button *ngIf="canShowEditButton && isBrokerV2Tab" (click)="edit()" classAttr="btn-primary btn-medium ml-2" [disabled]="disableEditButton" id="supplier-detail-add">
            Add
          </kehe-phoenix-button>
          <kehe-phoenix-button *ngIf="canShowAddButton || isAssociatedSuppliersTab" (click)="onAddClick()" classAttr="btn-primary btn-medium ml-2" automationId="supplier-detail-page-add-desktop-button">
            Add</kehe-phoenix-button>
          <kehe-phoenix-button *ngIf="canShowAddProductGroupButton" (click)="onAddProductGroupClick()"
            classAttr="btn-primary btn-medium ml-2" [disabled]="!canAddProductGroup" id="supplier-detail-add-product-group">
            Add Product Group
          </kehe-phoenix-button>
          <div *ngIf="canShowOverflowButton" class="ml-2">
            <kehe-phoenix-menu-button [menuOptions]="overFlowButtonItems"
              (optionSelected)="moreOptionSelected($event)">
            </kehe-phoenix-menu-button>
          </div>
        </div>
        <div *ngIf="canShowActionBar && currentTab === 'Purchasing Options'">
          <div class="d-flex justify-content-end mr-0">
            <div *ngFor="let item of rightActionButtons">
              <kehe-phoenix-button *ngIf="item.visible" (click)="actionButtonClicked(item)" [disabled]="item.disabled"
                [spinner]="item.loading" [classAttr]="item.classAttr">{{item.type}}</kehe-phoenix-button>
            </div>
            <div class="pl-2" *ngIf="(purchasingOptionsMenuOptions$ | async) && (purchasingOptionsMenuOptions$ | async).length">
              <kehe-phoenix-menu-button
                [menuOptions]="purchasingOptionsMenuOptions$ | async"
                (optionSelected)="purchasingOptionsMenuOptionSelected($event)"></kehe-phoenix-menu-button>
            </div>
          </div>
          <kehe-phoenix-button class="ml-2" *ngIf="canShowEllipsis" classAttr="btn-default btn-medium">
            <em class="fas fa-ellipsis-h"></em>
          </kehe-phoenix-button>
        </div>
      </div>
      <div class="col-sm-auto col-xs-12">
        <div class="row d-block d-sm-none">
          <div *ngIf="canShowActionBar && currentTab === 'Purchasing Options'">
            <div class="col">
              <div *ngFor="let item of actionButtons" class="mt-2 mr-2"
                [ngClass]="{'comment-container-wrapper': item.showIcon}">
                <kehe-phoenix-button *ngIf="item.visible" (click)="actionButtonClicked(item)" [disabled]="item.disabled"
                  [spinner]="item.loading" [classAttr]="item.classAttr + ' btn-block'" [iconClassAttr]="item.icon">
                  {{item.type}}</kehe-phoenix-button>
              </div>
              <div class="mt-2">
                <kehe-phoenix-button *ngIf="canShowEllipsis" classAttr="btn-default btn-medium btn-block">
                  <em class="fas fa-ellipsis-h"></em>
                </kehe-phoenix-button>
              </div>
            </div>
          </div>
          <div *ngIf="canShowActionBar  && currentTab !== 'Purchasing Options'" class="col">
            <kehe-phoenix-button *ngIf="canShowSaveCancelButton" (click)="onSaveButtonClick()"
              [spinner]="isSaveInProgress && !isSubmitInProgress" [disabled]="isSaveDisabled()" class="mt-2"
              classAttr="btn-primary btn-medium btn-block" id="supplier-detail-save">
              Save</kehe-phoenix-button>
            <div class="mt-2">
              <kehe-phoenix-button *ngIf="canShowSaveCancelButton" (click)="cancel()" [disabled]="isSaveInProgress"
                classAttr="btn-secondary btn-medium btn-block" id="supplier-detail-cancel">Cancel
              </kehe-phoenix-button>
            </div>
            <kehe-phoenix-button *ngIf="canShowSubmitButton" (click)="onSubmitButtonClick()" [spinner]="isSubmitInProgress"
              [disabled]="isSubmitDisabled()" classAttr="btn-secondary btn-medium btn-block mt-2" id="supplier-detail-activate">
              {{getSubmitButtonText}}
            </kehe-phoenix-button>
            <kehe-phoenix-button *ngIf="canShowEditButton" (click)="edit()" classAttr="btn-primary btn-medium btn-block mt-2" [disabled]="disableEditButton" id="supplier-detail-edit">
              Edit
            </kehe-phoenix-button>
            <kehe-phoenix-button *ngIf="canShowAddButton" (click)="onAddClick()"
              classAttr="btn-primary btn-medium btn-block mt-2" automationId="supplier-detail-page-add-responsive-button">
              Add
            </kehe-phoenix-button>
            <kehe-phoenix-button *ngIf="canShowAddProductGroupButton" (click)="onAddProductGroupClick()"
              classAttr="btn-primary btn-medium btn-block mt-2" [disabled]="!canAddProductGroup" id="supplier-detail-add-product-group">
              Add Product Group
            </kehe-phoenix-button>
            <div class="mt-2">
              <kehe-phoenix-button *ngIf="canShowOverflowButton" classAttr="btn-default btn-medium btn-block">
                <em class="fas fa-ellipsis-h"></em>
              </kehe-phoenix-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 mt-sm-2 mb-3 d-lg-none">
      <kendo-dropdownlist [data]="detailTabs" textField="tab" valueField="tab" [valuePrimitive]="true"
        [(ngModel)]="currentTab" (valueChange)="navigationPanelSetTab($event)"></kendo-dropdownlist>
    </div>
    <div class="spinner-container col" *ngIf="generalLoading">
      <kehe-phoenix-loading></kehe-phoenix-loading>
    </div>
    <div *ngIf="!generalLoading">
      <div class="row mt-2">
        <div class="col-12">
          <div *ngIf="showNewDCProductGroupHeader" class="supplier-header-responsive">
            <kehe-phoenix-manage-details-record-header-layout
              dateTitle="Onboarded"
              [date]="supplier?.onboardDate || '--'"
              [recordTitle]="supplier?.name"
              recordId="ESN {{supplier?.esn}}"
              [tag]="supplier?.statusTag">
            </kehe-phoenix-manage-details-record-header-layout>
          </div>
          <div *ngIf="!showNewDCProductGroupHeader" class="supplier-header-responsive">
            <kehe-phoenix-manage-details-record-header-layout
              dateTitle="Onboarded"
              [date]="supplier?.onboardDate || '--'"
              [recordTitle]="supplier?.name"
              recordId="ESN {{supplier?.esn}}"
              [tag]="supplier?.statusTag">
            </kehe-phoenix-manage-details-record-header-layout>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <router-outlet (activate)='onActivate()'></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>


<app-unsaved-changes-modal (checkAndContinueNavigating)="checkAndContinueNavigating()"
  [msg]="unSavedChangesModalMessage" [submitButton]="unSavedChangesModalSubmitButtonName"
  [secondaryButton]="unSavedChangesModalSecondButtonName"></app-unsaved-changes-modal>

<app-notes-modal *ngIf="showModal$ | async" [currentTab]="currentTab" (modalSaveClicked)="onNoteModalSave($event)">
</app-notes-modal>

<app-supplier-status-change-modal *ngIf="showStatusChangeModal" [currentTab]="currentTab"
  [supplierStatusActive]="supplierStatusActive" [header]="statusChangeHeader" [msg]="statusChangeModalMessage"
  [submitButton]="statusChangeModalSubmitButtonName"
  (modalChangeSupplierStatusClicked)="saveSupplierStatusChange($event)">
</app-supplier-status-change-modal>

<app-submit-supplier-modal *ngIf="showSubmitModal$ | async" (modalSubmitClicked)="onSubmitModalSave()">
</app-submit-supplier-modal>

<app-new-contract-modal *ngIf="showNewContractModal">
</app-new-contract-modal>

<app-delete-supplier-modal *ngIf="showDeleteSupplierModal"></app-delete-supplier-modal>
