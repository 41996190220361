import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import { SupplierDetail } from '../../models/supplier-detail';

import {
  selectMarketingAllowanceList,
  selectPaymentTermsList,
  selectSupplierDetail,
  selectTermsDropdownData
} from '../../store/supplier-detail.selectors';

import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  getMarketingAllowanceList,
  getPaymentTermsList,
  setValidationErrors,
  termsFormChanged
} from '../../store/supplier-detail.actions';
import { PromotionTerms } from '../../models/promotion-terms';
import { SupplierTermsDropdownsService } from '../../services/supplier-terms-dropdowns.service';
import { SupplierDetailsConstants } from '../../constants/supplier-details-constants';
import { isActive, isAddedDraftSupplier, isDraft } from '../../utilities/supplier-utils';
import { SupplierFormService } from '../../services/supplier-form.service';
import {
  getFormValidationErrors,
  markAllFormControlsAsTouched,
  markAllFormControlsAsUnTouched
} from '../../../../utils/common-utils';
import { CodeValue } from '../../models/code-value';
import {
  formatTermsFormResponse,
  getFormControlValueMap,
  getFormDirtyValue,
  resetPristine
} from '../../../../utils/form-utils';
import { TermsDropdownData, TermsDropdownType } from '../../models/terms-dropdown-data';
import { CodeDescription } from '../../models/code-description';
import { SUPPLIER_INVITE_TYPES } from '../../../supplier-list/constants/supplier-constants';
import { SupplierInviteType } from '../../../supplier-list/enum/supplier-invite-type';
import {selectIsFeatureFlagOn} from "@app/shared/state/feature-flag/feature-flag.selectors";
import {FeatureFlags} from "@app/feature-flag";

@Component({
  selector: 'app-terms-form',
  templateUrl: './terms-form.component.html',
  styleUrls: ['./terms-form.component.scss']
})
export class TermsFormComponent extends Destroyable implements OnInit, OnDestroy {

  tooltipMCBExceptionCode = SupplierDetailsConstants.MCBExceptionCodeTooltip;
  tooltipPublicationListingFee = SupplierDetailsConstants.PublicationListingFeesTooltip;
  types: { label: string, value: SupplierInviteType }[] = [];

  initialFormValue: Map<UntypedFormControl, any>;
  public form: UntypedFormGroup;
  public supplier: SupplierDetail;
  public paymentTermsList: Array<CodeValue> = [];
  public loadingPaymentTermsList = false;
  public priceChangeNoticeList: CodeDescription[] = [];
  public specialEventTermsSeasonalList: CodeDescription[] = [];
  public specialEventTermsNewWarehouseOpeningList: CodeDescription[] = [];
  public auditTimeFrameList: CodeDescription[] = [];
  public freeProductsProgramList: CodeDescription[] = [];
  public freeProductHandlingChargeList: CodeDescription[] = [];
  public dueDateCalculationList: CodeDescription[] = [];
  public paymentMethodList: CodeDescription[] = [];
  public spoilsList = [];
  public marketingAllowanceList = [];
  public promotionTerms: PromotionTerms;
  public fieldMsg = 'This field cannot be edited.';
  public mcbExceptionCodes: { name, value }[] = SupplierDetailsConstants.MCBExceptionCodeList;
  public loadingMarketingAllowanceList = false;
  termsDropdownData: TermsDropdownData[] = [];

  get priceChangeNoticeInDaysField() {
    return this.form.get('terms.priceChangeNoticeInDays');
  }

  get acceptsManufacturerChargeBackFeeField() {
    return this.form.get('terms.promotions.acceptsManufacturerChargeBackFee');
  }

  get manufacturerChargeBackExceptionCodeField() {
    return this.form.get('terms.promotions.manufacturerChargeBackExceptionCode');
  }

  get contractDateField() {
    return this.form.get('terms.contractDate');
  }

  get hasPalletCharge() {
    return this.form.get('terms.hasPalletCharge');
  }

  get manufacturerChargeBackFeePercentageField() {
    return this.form.get('terms.promotions.manufacturerChargeBackFeePercentage');
  }

  get canShowMCBFeeDollars(): boolean {
    return this.canShowMCBFee(SupplierDetailsConstants.MCBExceptionCode.DollarKey);
  }

  get canShowMCBFeePercentage(): boolean {
    return this.canShowMCBFee(SupplierDetailsConstants.MCBExceptionCode.PercentageKey);
  }

  canShowMCBFee(exceptionCode: string) {
    if (!this.acceptsManufacturerChargeBackFeeField.value) {
      return false;
    }
    return SupplierDetailsConstants.MCBExceptionCode.BothKey === this.manufacturerChargeBackExceptionCodeField.value ||
      exceptionCode === this.manufacturerChargeBackExceptionCodeField.value;
  }

  get shelfLifePercentageField() {
    return this.form.get('terms.shelfLifePercentage');
  }

  get palletChargeAmount() {
    return this.form.get('terms.palletChargeAmount');
  }

  constructor(
    private _store: Store,
    private _supplierFormService: SupplierFormService,
    private tds: SupplierTermsDropdownsService
  ) {
    super();
  }

  ngOnInit() {
    this._store.dispatch(getPaymentTermsList());
    this.loadingPaymentTermsList = true;
    this._store.dispatch(getMarketingAllowanceList());
    this.loadingMarketingAllowanceList = true;
    this.spoilsList = this.tds.getSpoilsDropdownList();

    // Keep the form in sync with the actual state of the record
    this._store.pipe(
      select(selectTermsDropdownData),
      take(1),
      withLatestFrom(
          this._store.select(selectSupplierDetail),
          this._store.select(selectIsFeatureFlagOn(FeatureFlags.AAPTerm.key)),
      ),
      takeUntil(this.destroy$))
      .subscribe(([list, supplier, ffAAP]) => {
        if (list && list.length > 0 && supplier) {
          this.supplier = supplier;

          if (isAddedDraftSupplier(supplier) || supplier.supplierType === SupplierInviteType.OwnBrands) {
            // Keep OwnBrands if added supplier
            // Keep OwnBrands if selected type is own brand(in disabled state)
            this.types = SUPPLIER_INVITE_TYPES;
          } else {
            // Remove OwnBrands for other case
            this.types = SUPPLIER_INVITE_TYPES.filter(item => item.value !== SupplierInviteType.OwnBrands);
          }

          this.termsDropdownData = list;
          this.fillDropdownLists();

          this.promotionTerms = this.supplier.terms.promotions;

          this.buildForm(ffAAP);
          this.initializeFormValueChanges();
        }
      });
  }

  fillDropdownLists() {
    this.dueDateCalculationList = this.getDropdownValues(TermsDropdownType.DueDateCalculation);
    this.auditTimeFrameList = this.getDropdownValues(TermsDropdownType.AuditTimeFrame);
    this.specialEventTermsSeasonalList = this.getDropdownValues(TermsDropdownType.SpecialEventTermsSeasonal);
    this.specialEventTermsNewWarehouseOpeningList = this.getDropdownValues(TermsDropdownType.SpecialEventTermsNewWarehouseOpening);
    this.priceChangeNoticeList = this.getPriceChangeNoticeList();
    this.paymentMethodList = this.getDropdownValues(TermsDropdownType.PaymentMethod);
    this.freeProductsProgramList = this.getDropdownValues(TermsDropdownType.FreeProductsProgram);
    this.freeProductHandlingChargeList = this.getDropdownValues(TermsDropdownType.FreeProductHandlingCharge);

    this._store.pipe(
      select(selectPaymentTermsList),
      takeUntil(this.destroy$))
      .subscribe(list => {
        this.paymentTermsList = list;
        this.loadingPaymentTermsList = false;
      });

    this._store.pipe(
      select(selectMarketingAllowanceList),
      takeUntil(this.destroy$))
      .subscribe(list => {
        this.marketingAllowanceList = list;
        this.loadingMarketingAllowanceList = false;
      });
  }

  ngOnDestroy() {
    this._store.dispatch(setValidationErrors({ errors: [] }));
    super.ngOnDestroy();
  }

  hasRequiredError(control) {
    return this.form.get(control).errors
      && this.form.get(control).errors.required
      && this.form.get(control).invalid
      && (this.form.get(control).touched || this.form.get(control).dirty);
  }

  private buildForm(ffAAP: boolean) {
    this.form = this._supplierFormService.getTermsForm(this.supplier, this.termsDropdownData, ffAAP);
    this._supplierFormService.patchTermsForm(this.supplier, this.form);
    this.validateForm();
    this.initialFormValue = getFormControlValueMap(this.form);
    if (isDraft(this.supplier)) {
      markAllFormControlsAsUnTouched(this.form);
    }
  }

  onPaymentTermsChanged(e) {
    if (e === undefined) {
      this.setPaymentTerms();
    } else {
      const find = this.paymentTermsList.find(item => item.code === e);
      this.setPaymentTerms(e, find.codeValue);
    }
  }

  setPaymentTerms(code = '', description = ''): void {
    const paymentTermsDescriptionField = this.form.get('terms.paymentTerms.description');
    paymentTermsDescriptionField.setValue(description);
    paymentTermsDescriptionField.markAsDirty();
    paymentTermsDescriptionField.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  onMarketingAllowanceChanged(e) {
    if (e === undefined) {
      this.setMarketingAllowance();
    } else {
      const find = this.marketingAllowanceList.find(item => item.code === e);
      this.setMarketingAllowance(e, find.codeValue);
    }
  }

  setMarketingAllowance(code = '', description = ''): void {
    const marketingAllowanceDescriptionField = this.form.get('terms.promotions.marketingAllowance.description');
    marketingAllowanceDescriptionField.setValue(description);
    marketingAllowanceDescriptionField.markAsDirty();
    marketingAllowanceDescriptionField.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  excludeMCBFee() {
    return !this.form.get('terms.promotions.acceptsManufacturerChargeBackFee').value;
  }

  public onFocus(element: any): void {
    element.toggle(true);
  }

  private updateChangeDate() {
    this.form.get('terms.changeDate').setValue(new Date(), { emitEvent: false });
    this.form.get('terms.changeDate').markAsTouched();
    this.form.get('terms.changeDate').markAsDirty();
  }

  private initializeFormValueChanges() {
    // subscribe to form changes and dispatch any changes to store
    this.form.valueChanges.pipe(
      takeUntil(this.destroy$))
      .subscribe(form => {

        // This iterate all initial FormControls and check if it's current value and
        // initial value are same then it is markAsPristine which make sure form not dirty if its a reverted value
        resetPristine(this.initialFormValue);

        this.updateChangeDate();

        const dirtyValue = getFormDirtyValue(this.form);
        formatTermsFormResponse(dirtyValue, form);

        this._store.dispatch(termsFormChanged({
          form: {
            esn: this.supplier.esn,
            ...dirtyValue
          },
          isDirty: this.form.dirty,
          isTermsFormValid: isDraft(this.supplier) ? true : this.form.valid,
        }));
        this.validateForm();
      });
    this.form.get('terms.acceptsNewItemSetupFee').valueChanges.subscribe(value => {
      const newItemFee = this.form.get('terms.newItemSetupFee');
      if (value) {
        newItemFee.setValue(50);
      } else {
        newItemFee.setValue(0);
      }
      newItemFee.markAsDirty();
    });
    this.form.get('terms.promotions.acceptsManufacturerChargeBackFee').valueChanges.subscribe(value => {
      this._supplierFormService.setMCBExceptionCodeValues(this.supplier, this.form);
      this._supplierFormService.setMCBExceptionCodeValidators(this.form);
      this.manufacturerChargeBackFeePercentageField.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    });
  }

  public getSpoilsAllowReadOnlyStatus(): boolean {
    return this._supplierFormService.allowancePercentageFieldReadOnly;
  }

  private validateForm() {
    if (!isDraft(this.supplier)) {
      markAllFormControlsAsTouched(this.form);
      this._store.dispatch(setValidationErrors({ errors: getFormValidationErrors(this.form) }));
    }
  }

  private getDropdownValues(type: TermsDropdownType): CodeDescription[] {
    const data = this.termsDropdownData.find(item => item.listName === type);
    return data ? (data.listValues || []) : [];
  }

  private getPriceChangeNoticeList() {
    return this.getDropdownValues(TermsDropdownType.PriceChangeNotice).map(item => {
      return {code: +item.code, description: item.description};
    });
  }

  isActiveSupplier(): boolean {
    return isActive(this.supplier);
  }
}
