<ng-container *ngIf="vm$ | async as vm">
    <kendo-floatingactionbutton 
        kendoPopoverAnchor
        showOn="none"
        #anchor="kendoPopoverAnchor"
        [popover]="chatPopover"
        (click)="anchor.toggle()"
        class="q-bot-floating-action-button"
        size="none"
        [align] = "{ vertical: 'bottom', horizontal: 'start' }"
    >
        <ng-template kendoFloatingActionButtonTemplate>
            <ng-container>
                <i [class]="vm.chatIconClass"></i>
            </ng-container>
        </ng-template>
    </kendo-floatingactionbutton>

    <kendo-popover #chatPopover position="top"  [callout]="false" [animation]="{type: 'expand', direction: 'up', duration: '400'}" (shown)="onPopoverShown()" (hidden)="onPopoverHidden()">
        <ng-template kendoPopoverBodyTemplate>
            <iframe class="q-iframe" src="https://y76vylnl.chat.qbusiness.us-east-1.on.aws/#/chat"></iframe>
        </ng-template>
    </kendo-popover>   
</ng-container>

