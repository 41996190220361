import { createAction, props } from "@ngrx/store";
import { BreadCrumbItem } from "@progress/kendo-angular-navigation";
import { ProhibitRestrict } from "../models/prohibit-restrict";

export enum ProhibitRestictDetailActions{
    GetProhibitRestrictDetails = '[ProhibitRestrictDetail Page] Get Details',
    GetProhibitRestrictDetailsSuccess = '[ProhibitRestrictDetail Page] Get Details Success',
    GetProhibitRestrictDetailsError = '[ProhibitRestrictDetail Page] Get Details Error',
    ToggleNavigationPanel = '[ProhibitRestrictDetail Page] Toggle Navigation Panel',
    ClickedBreadCrumbs = '[ProhibitRestrictDetail Page] Clicked BreadCrumbs'

}

export const updateKendoBreadCrumbs = createAction(
    '[ProhibitRestrictDetail Page] Update BreadCrumbs',
    props <{ item: BreadCrumbItem }>()
);

export const clearKendoBreadCrumbs = createAction(
    '[ProhibitRestrictDetail Page] Clear BreadCrumbs'
);

export const getProhibitRestrictDetails = createAction(
    ProhibitRestictDetailActions.GetProhibitRestrictDetails,
    props <{ id: string }>()
);

export const getProhibitRestrictDetailsSuccess = createAction(
    ProhibitRestictDetailActions.GetProhibitRestrictDetailsSuccess,
    props <ProhibitRestrict>()
);

export const getProhibitRestrictDetailsError = createAction(
    ProhibitRestictDetailActions.GetProhibitRestrictDetailsError
);

export const toggleNavigationPanel = createAction(
    ProhibitRestictDetailActions.ToggleNavigationPanel
);

export const clickedKendoBreadCrumbs = createAction(
    ProhibitRestictDetailActions.ClickedBreadCrumbs,
    props <{ item: BreadCrumbItem }>(),
);