import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalWorkflowService } from '../../services/modal-workflow.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UploadOrderFileService } from '../../services/upload-order-file.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { acceptOnlyDigits } from '../../utils/text-validation-utils';

export interface UploadOrderFileData {
  DcCustomerNumber: string;
  FormFile: File;
  Name: string;
  PoNumber: string;
}

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss'],
})
export class UploadFileModalComponent implements OnInit {
  private MAX_FILE_UPLOAD_SIZE: number = 5 * 1024 * 1024; // 5MB file limit
  selectedFile: File;
  selectedFileNameStr: string;
  canUploadFile = false;
  isUploadingFile = false;
  templateUrl: string;
  errorMsg: string;
  orderForm: UntypedFormGroup;
  @ViewChild('uploadFile', { static: true }) uploadFile: ElementRef<any>;

  constructor(
    private overlayService: ModalWorkflowService,
    private uploadOrderFileService: UploadOrderFileService,
    private router: Router
  ) {}

  ngOnInit() {
    this.templateUrl = 'https://s3.amazonaws.com/connect.kehe.com/Documents/kehe-order-upload-template.xlsx';
    this.orderForm = this.createForm();
  }

  get name(): AbstractControl {
    return this.orderForm.get('orderName');
  }

  get poNumber(): AbstractControl {
    return this.orderForm.get('poNumber');
  }

  simpleCloseModal() {
    this.overlayService.orderInitialDialogRef.close();
  }

  backToInitialModal() {
    this.simpleCloseModal();
    this.overlayService.initialOrderModalSubject.next();
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    this.selectedFileNameStr = this.selectedFile.name.length && this.processFileName(this.selectedFile.name);
    let isTooLarge = false;
    if (this.selectedFile) {
      console.log('File: ', this.selectedFile);
      isTooLarge = this.selectedFile.size >= this.MAX_FILE_UPLOAD_SIZE;
      this.canUploadFile = !isTooLarge;
    } else {
      this.canUploadFile = false;
    }

    this.errorMsg = isTooLarge ? 'Your order file is too large. Please make sure your file doesn\'t exceed 5MB.' : null;
  }

  onBrowseClick() {
    this.uploadFile.nativeElement.click();
    this.name.setErrors({ required: true });
    this.name.markAsDirty();
    this.name.markAsTouched();
    this.name.updateValueAndValidity();
  }
  get isFormValid(): boolean {
    return this.orderForm.valid;
  }

  private createForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      orderName: new UntypedFormControl('', {
        validators: Validators.required,
        updateOn: 'blur',
      }),
      poNumber: new UntypedFormControl(''),
    });
  }
  uploadOrderFile() {
    if (!this.isFormValid || !this.canUploadFile || this.isUploadingFile) {
      return;
    }
    const formData: UploadOrderFileData = {
      DcCustomerNumber: JSON.parse(localStorage.getItem('selectedCustomer'))['CustomerNumber'],
      FormFile: this.selectedFile,
      Name: this.orderForm.get('orderName').value,
      PoNumber: this.orderForm.get('poNumber').value,
    };
    this.isUploadingFile = true;
    this.uploadOrderFileService
      .uploadOrderFile(formData)
      .pipe(finalize(() => console.log('upload file complete')))
      .subscribe(
        res => {
          this.isUploadingFile = false;
          console.log('response-ok: ', res);
          if (this.isResponseSuccess(res)) {
            this.simpleCloseModal();
            this.router.navigate(['orders', res.orderId]).then(() => console.log(`re-directed to orders/${res.orderId}`));
          } else {
            this.handleErrorMsgs(res);
            console.log('could\'t redirect because there\'s errors');
          }
        },
        err => {
          this.isUploadingFile = false;
          console.log('upload-error: ', err);
        }
      );
  }
  private processFileName(filename: string): string {
    const extRegex = /\.[0-9a-z]+$/gi;
    const extension: string = extRegex.exec(filename)[0];
    return filename.length >= 32 ? filename.slice(0, 30) + '...' + extension : filename;
  }
  private isResponseSuccess(res): boolean {
    const statusCode = res.httpCode;
    return statusCode >= 200 && statusCode < 300;
  }
  private handleErrorMsgs(res) {
    const errorMsg = res.errorMessage || null;
    if (!errorMsg) {
      return undefined;
    }
    this.errorMsg = errorMsg;
  }

  acceptOnlyDigits(e) {
    const formValue = acceptOnlyDigits(e);
    this.poNumber.setValue(formValue);
  }
}
