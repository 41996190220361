import { Injectable } from '@angular/core';
import { AbstractApiStore } from '@kehe/phoenix-component-store-utils';
import { Document } from './../../../../models/document';
import { DocumentService } from '@app/services/document.service';
import { Payload } from '@app/models/payload';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';

import { ApiFailureText } from '@app/enum/alert-text.enum';

@Injectable()
export class GetDocumentsListApiStore extends AbstractApiStore<Payload<Document[]>> {

  constructor(private documentService: DocumentService) {
    super();
  }

  getDocuments$ = this.effect(
    (filter$: Observable<{ pageIndex: number, pageSize: number, search: string }>) =>
      filter$.pipe(
        tap(() => {
          this.setData(undefined);
          this.startLoading();
        }),
        switchMap((filter) => {
          return this.documentService.getAll(filter.pageSize, filter.pageIndex, filter.search).pipe(
            tapResponse(
              (response) => {
                this.setData({
                  data: response.data,
                  availableCount: response.availableCount,
                });
              },
              () => {
                this.setError(ApiFailureText.Generic);
              },
            ),
          );
        }),
        catchError(() => {
          this.setError(ApiFailureText.Generic);
          return EMPTY;
        }),
      ),
  );
}
