<div class="help-container desktop">
  <div class="help-title">
    Need Help?
  </div>
  <div class="help-content-container">
    <span class="help-sub-title">Customer Care</span>
    <span>To check the status of an order (order deadline, route schedule),
      make changes to an order,
      or if you have a question regarding items on invoices,
      please contact Customer Care.</span>
    <span>Natural customers: <a href="mailto:CustSVC@KeHE.com">CustSVC&#64;KeHE.com</a> or 800-223-2910</span>
    <span>Supermarket customers: <a href="mailto:CustomerCare@KeHE.com">CustomerCare&#64;KeHE.com</a> or 800-658-8921</span>
    <span>Monterrey: <a href="mailto:CustomerService@monprov.com">CustomerService&#64;monprov.com</a> or 800-201-1600</span>


  </div>
  <div class="help-content-container">
      <span class="help-sub-title">Credits</span>
      <span>To request a credit: </span>
      <span>Contact us at: <a href="mailto:Credits@KeHE.com">Credits&#64;KeHE.com</a> or 877-541-5038</span>
  </div>
  <div class="help-content-container">
      <span class="help-sub-title">Retail Tech Support</span>
      <span>For technical support:</span>
      <span>Contact us at: <a href="mailto:RetailTechSupport@KeHE.com">RetailTechSupport&#64;KeHE.com</a> or 866-239-7348</span>

  </div>
</div>
