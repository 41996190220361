<h5>Lead Times</h5>
<kendo-formfield *ngIf="form" [formGroup]="form">
  <kendo-label *ngIf="label" [text]="label"></kendo-label>
  <kendo-numerictextbox formControlName="totalTime" placeholder="e.g. 3" format="0" [decimals]="0" [spinners]="false">
  </kendo-numerictextbox>
  <kendo-formhint>Calendar days</kendo-formhint>
  <kendo-formerror *ngIf="form.get('totalTime')?.errors?.required">
    {{ label }} is required.
  </kendo-formerror>
  <kendo-formerror
    *ngIf="form.get('totalTime')?.errors?.min || form.get('totalTime')?.errors?.max">
    Between 1 and {{ isTotalTime ? 999 : 499 }}
  </kendo-formerror>
</kendo-formfield>
