import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SupplierDetailService } from '../../services/supplier-detail.service';
import { Action, Store } from '@ngrx/store';
import {
  getSupplierForm,
  selectBrokerForm,
  selectESN,
  selectSupplierDetail,
  selectUnsavedChangesPending,
} from '../supplier-detail.selectors';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { isDraft, isSupplierInReview } from '../../utilities/supplier-utils';
import { DocumentStatus } from '../../../supplier-documents/models/document-statuses';
import { SupplierBrokerService } from '../../services/supplier-broker.service';
import { selectCloneDCPGHasChanges } from '../../../product-groups/store/product-groups.selectors';

import * as SupplierDetailActions from '../supplier-detail.actions';
import * as SupplierContactsActions from '../../../supplier-contacts/store/supplier-contacts.actions';
import * as SupplierDocumentsActions from '../../../supplier-documents/store/supplier-documents.actions';
import * as SupplierUsersActions from '../../../supplier-users/store/supplier-users.actions';
import * as ProductGroupsActions from '../../../product-groups/store/product-groups.actions';
import * as DistributionCenterActions from '@app/distribution-center/distribution-center.actions';
import * as DistributionCenterSelectors from '@app/shared/state/distribution-center/distribution-center.selectors';
import { PendoEvent } from '../../models/pendo-event.interface';
import { PendoTrackEvents } from '../../models/pendo-track-events.enum';

@Injectable()
export class SupplierDetailEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private router: Router,
    private _supplierDetailService: SupplierDetailService,
    private _brokerService: SupplierBrokerService,
  ) {}

  loadSupplierDetail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        SupplierDetailActions.loadSupplierDetail,
        DistributionCenterActions.getDistributionCenterListSuccess,
      ),
      withLatestFrom(
        this._store.select(selectESN),
        this._store.select(DistributionCenterSelectors.selectDistributionCentersTrimmedNameAndNumber)
      ),
      switchMap(([, esn, dcList]) => {
        if (esn) {
          return this._supplierDetailService.getSupplierDetail(esn).pipe(
            map((supplier) =>
              SupplierDetailActions.loadSupplierDetailSuccess({
                supplier,
                dcList,
              })
            )
          );
        } else {
          return of(SupplierDetailActions.loadSupplierDetailError());
        }
      })
    )
  );

  loadRelatedData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.loadSupplierDetailSuccess),
      withLatestFrom(
        this._store.select(selectSupplierDetail)
      ),
      switchMap(([action, supplierDetails]) => {
        const actions: Action[] = [
          SupplierContactsActions.loadSupplierContacts({
            esn: action.supplier.esn,
          }),
          SupplierDocumentsActions.loadSupplierDocumentsData({
            supplier: {
              esn: action.supplier.esn,
              inviteReasonType: action.supplier.inviteReasonType,
              supplierType: action.supplier.supplierType,
              isSupplierInReview: isSupplierInReview(action.supplier),
              addendumAsBase64String:
                action.supplier.terms.addendumAsBase64String,
              requesterType: action.supplier.requesterType,
              isInternational: action.supplier.hasInternationalCurrency,
              invitedDate: action.supplier.created,
              isDpiInitiated: action.supplier.isDpiInitiated,
              cmName: action.supplier.categoryManagerFirstName + " " + action.supplier.categoryManagerLastName
            },
          }),
          SupplierUsersActions.loadSupplierUsers({ esn: action.supplier.esn }),
        ];

        if (!isDraft(supplierDetails)) {
          actions.push(ProductGroupsActions.loadProductsData({ esn: action.supplier.esn }));
        }
        return actions;
      })
    )
  );

  // check for unsaved changes before confirming cancel
  attemptCancelEdit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.attemptCancelEdit),
      withLatestFrom(
        this._store.select(selectUnsavedChangesPending),
        this._store.select(selectCloneDCPGHasChanges)
      ),
      map(([, hasUnsavedChanges, cloneChanged]) => {
        if (hasUnsavedChanges || cloneChanged) {
          return SupplierDetailActions.showUnsavedChangesModal();
        } else {
          return SupplierDetailActions.confirmCancelEdit();
        }
      })
    )
  );

  brokerActiveSupplierPatch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.brokerPatch),
      withLatestFrom(
        this._store.select(selectBrokerForm),
        this._store.select(selectSupplierDetail)
      ),
      filter(([,, supplier]) => !isDraft(supplier)),
      switchMap(([, brokerForm]) => {
        return this._supplierDetailService.save(brokerForm).pipe(
          switchMap(() => [
            SupplierDetailActions.brokerPatchSuccess({ brokerForm }),
            SupplierDetailActions.loadSupplierBroker(),
          ]),
          catchError(() => of(SupplierDetailActions.brokerPatchError()))
        );
      })
    )
  );

  updateSupplierStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.supplierStatusChangeUpdate),
      switchMap((action) =>
        this._supplierDetailService.save(action).pipe(
          map((a: any) => SupplierDetailActions.saveUpdateFormSuccess(a)),
          catchError(() =>
            of(SupplierDetailActions.saveGeneralFormError())
          )
        )
      )
    )
  );

  clearSupplierValidation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.loadSupplierDetail),
      map(() => SupplierDetailActions.supplierValidated({ tabs: [] }))
    )
  );

  /* General */
  submitSupplier$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.submitSupplier),
      withLatestFrom(this._store.select(selectSupplierDetail)),
      switchMap(([, supplierDetail]) => {
        return this._supplierDetailService.submit(supplierDetail).pipe(
          map(() => {
            const event: PendoEvent = {
              name: PendoTrackEvents.ActivateSupplier,
              eventProperties: {
                esn: supplierDetail.esn,
                biTimestamp: Date.now(),
                requesterType: supplierDetail.requesterType,
                isInternational: supplierDetail.hasInternationalCurrency,
                invitedDate: supplierDetail.created,
                isDpiInitiated: supplierDetail.isDpiInitiated,
                cmName: supplierDetail.categoryManagerFirstName + " " + supplierDetail.categoryManagerLastName
              }
            }
           return SupplierDetailActions.submitSupplierSuccess({pendoEvent: event})
          }),
          catchError((error) =>
            of(
              SupplierDetailActions.submitSupplierError({
                errorCode: error.status,
              })
            )
          )
        );
      })
    )
  );

  rejectReview$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDocumentsActions.updateDocumentStatusSuccess),
      withLatestFrom(this._store.select(selectSupplierDetail)),
      filter(([action]) => action.document.status === DocumentStatus.Rejected),
      switchMap(([_, supplierDetails]) => {
        return this._supplierDetailService
        .getSupplierDetail(supplierDetails.esn)
        .pipe(
          map((supplier) =>
            SupplierDetailActions.refreshSupplierReviewStatus({ supplier })
          )
        );
      })
    )
  );

  refreshSupplierReviewStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.refreshSupplierReviewStatus),
      switchMap((action) => {
        if (isSupplierInReview(action.supplier)) {
          return this._supplierDetailService
          .rejectReview(action.supplier.esn)
        }
      })
    ), { dispatch: false }
  );

  // Supplier Form (For Broker)
  checkSupplierBrokerChanged$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.saveSupplierFormSuccess),
      filter((action) => action.form.broker),
      switchMap((action) => {
        return of(
          SupplierDetailActions.loadSupplierBrokerContact({
            supplier: action.supplier,
          })
        );
      })
    )
  );

  loadSupplierBroker$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        SupplierDetailActions.loadSupplierDetailSuccess,
        SupplierDetailActions.loadSupplierBrokerContact
      ),
      switchMap((action) => {
        if (action.supplier?.broker?.esn) {
          return this._brokerService
            .getBrokerDetail(action.supplier.broker.esn)
            .pipe(
              map((broker) =>
                SupplierDetailActions.loadSupplierBrokerContactSuccess({
                  broker,
                })
              )
            );
        } else {
          return of(SupplierDetailActions.clearSupplierBrokerContact());
        }
      })
    )
  );

  saveSupplierForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.saveSupplierForm),
      withLatestFrom(this._store.select(getSupplierForm)),
      switchMap(([, form]) => {
        return this._supplierDetailService.save(form).pipe(
          map((supplier) =>
            SupplierDetailActions.saveSupplierFormSuccess({ supplier, form })
          ),
          catchError(() =>
            of(SupplierDetailActions.saveSupplierFormError())
          )
        );
      })
    )
  );
  // Supplier Form (For Broker)

  // Delete Supplier
  deleteSupplier$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.deleteSupplier),
      withLatestFrom(this._store.select(selectSupplierDetail)),
      switchMap(([, supplier]) => {
        return this._supplierDetailService.delete(supplier.esn).pipe(
          map(() => SupplierDetailActions.deleteSupplierSuccess()),
          catchError(() => of(SupplierDetailActions.deleteSupplierError()))
        );
      })
    )
  );

  navigateToSupplierList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.deleteSupplierSuccess),
      switchMap(async () => {
        await this.router.navigateByUrl(`supplier`);
      })
    ), { dispatch: false }
  );
  // Delete Supplier
}
