<div class="px-4">
  <kehe-phoenix-audit-history [historyItems]="tableData | async" [loading]="loading | async"
    [totalRecordsCount]="recordsCount | async" [currentPageSize]="pageSize | async" [currentPageIndex]="pageIndex | async"
    [filterChips]="filterChips" [modalConfig]="modalConfig | async" (pageIndexChanged)="pageIndexChange($event)"
    (pageSizeChanged)="pageSizeChange($event)" (searchChangedEvent)="onSearch($event)"
    (showDetailsEvent)="openDetailModal($event)" (closeModalEvent)="closeDetailModal()"
    (filterClickEvent)="onFilterClick()" (chipClickedEvent)="chipClicked($event)" (clearAllChipsEvent)="clearAllChips()">
  </kehe-phoenix-audit-history>
</div>

<app-history-supplier-filter-panel></app-history-supplier-filter-panel>