import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { defaultAddress } from '../../../utils/address-utils';

@Injectable()
export class DcpgFormService {
  constructor(private readonly _formBuilder: UntypedFormBuilder) { }

  /**
   * @description - This method is used to create the delivery form group
   * @param {FormGroup} addressFormGroup - The address form group
   * */
  buildDeliveryFormGroup(addressFormGroup: UntypedFormGroup): any {
    const form = this._formBuilder.group({
      deliveryMethodCode: ['', [Validators.required]],
      inboundRouteType: ['', [Validators.required]],
      deliveryMethod: ['', []],
      readyTimeAvailable: [true],
      pickup: this._formBuilder.group({
        pickupAddressId: ['', Validators.required],
        pickupAddress: addressFormGroup,
        pickupPhone: ['', [Validators.required]],
        pickupExtension: [''],
        pickupFax: [''],
        pickupEmail: ['', [Validators.email]],
        pickupAddressNotes: [''],
      }),
      deliveredLeadTimes: this._formBuilder.group({
        readyTime: ['', [Validators.required, Validators.min(1), Validators.max(499)]],
        transitTime: ['', [Validators.required, Validators.min(1), Validators.max(499)]],
        totalTime: [''],
        isTransitTimeDefault: [false, []],
        isTransitTimeOverridden: [false, []],
      }),
      pickupLeadTimes: this._formBuilder.group({
        readyTime: ['', [Validators.required, Validators.min(1), Validators.max(499)]],
        transitTime: ['', [Validators.required, Validators.min(1), Validators.max(499)]],
        totalTime: [''],
        isTransitTimeDefault: [false, []],
        isTransitTimeOverridden: [false, []],
      }),
    });
    form.get('pickup.pickupAddress').patchValue(defaultAddress());
    form.get('pickup.pickupAddress.street1').setValidators([Validators.required, Validators.maxLength(30)]);

    return form;
  }

  /**
   * @description - This method generates the POTransmission form
   * @param {boolean} hasPOOrder - Whether the PO Order is present
   * */
  buildPOTransmissionsFormGroup(hasPOOrder: boolean) {
    const form = this._formBuilder.group({
      poEmails: this._formBuilder.array([]),
      poFax: this._formBuilder.group({
        type: [''],
        fax: [''],
      }),
      isPONotEmpty: [false, [Validators.requiredTrue]],
    });
    if (hasPOOrder) {
      form.addControl('poOrderMinimum', this._formBuilder.group({
        unit: [null, Validators.required],
        amount: [null, [Validators.required, Validators.min(1), Validators.max(99999)]],
      }));
      form.get('poOrderMinimum.amount').disable();
      form.get('poOrderMinimum.unit').valueChanges.subscribe(value => {
        if (value) {
          form.get('poOrderMinimum.amount').enable();
        } else {
          form.get('poOrderMinimum.amount').disable();
        }
      });
    }
    return form;
  }
}
