<kehe-phoenix-modal name="keheModal" [show]="true" [modalTitle]="getTitle()" (modalEvent)="closeModal()" size="md">

  <div body>
    <div #formTop></div>
    <kehe-phoenix-alert [show]="error !== null" [type]="'error'" [message]="error" [stick]="true"
      [hideCloseIcon]="true"> </kehe-phoenix-alert>
    <div [formGroup]="form" *ngIf="form">
      <div class="form-group mb-3">
        <kendo-formfield>
          <kendo-label [for]="typeField" text="Contact Type"></kendo-label>
          <kendo-multiselect #typeField [autoClose]='false' [data]="filteredContactTypes"
            placeholder="Select one or more Contact Type" [filterable]="true" formControlName="contactTypes"
            (filterChange)="filterTypes($event)">
          </kendo-multiselect>
          <kendo-formerror>Contact Type is required.</kendo-formerror>
        </kendo-formfield>
      </div>
      <div>
        <div class="mb-3 fw-bold">Add Contact Information</div>
        <div class="form-group">
          <kendo-formfield>
            <kendo-label text="Name"></kendo-label>
            <kendo-textbox formControlName="name" placeholder="e.g. John Doe" maxlength="100"> </kendo-textbox>
            <kendo-formerror>Name is required.</kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="d-flex">
          <div class="pe-2 flex-grow-1">
            <kehe-phoenix-phone-number-input tabOrder="2" name="phone" label="Phone" formControlName="phone"
              [required]="true">
            </kehe-phoenix-phone-number-input>
          </div>
          <div>
            <kendo-formfield>
              <kendo-label text="Ext (optional)"></kendo-label>
              <kendo-numerictextbox formControlName="phoneExtension" [format]="'#'" placeholder="e.g. 00000"
                [spinners]="false" [min]="0" maxlength="5" [pattern]="phoneExtPattern"></kendo-numerictextbox>
              <kendo-formerror>Invalid ext. format</kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
        <div class="form-group">
          <kehe-phoenix-phone-number-input tabOrder="4" name="fax" label="Fax (optional)" formControlName="fax">
          </kehe-phoenix-phone-number-input>
        </div>

        <div class="form-group">
          <kendo-formfield>
            <kendo-label text="Email"></kendo-label>
            <kendo-textbox formControlName="email" placeholder="e.g. contact@email.com" maxlength="100"
              [pattern]="emailPattern"> </kendo-textbox>
            <kendo-formerror *ngIf="!form.get('email')?.errors?.required">Invalid email format</kendo-formerror>
            <kendo-formerror *ngIf="form.get('email')?.errors?.required">Email is required.</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="form-group mb-4">
          <input type="checkbox" #isPrimaryField kendoCheckBox formControlName="isPrimary" />
          <kendo-label class="k-checkbox-label" [for]="isPrimaryField" text="Primary Contact"></kendo-label>
          <kendo-formhint>
            There can only be one Primary Contact. This will replace any previously assigned Primary Contact.
          </kendo-formhint>
        </div>
      </div>
      <div>
        <div class="mb-3 fw-bold">Notifications</div>

        <div class="form-group">
          <input type="checkbox" #discontinuedProductNotificationField kendoCheckBox
            formControlName="subscribedToDiscontinuedProductNotification" />
          <kendo-label class="k-checkbox-label" [for]="discontinuedProductNotificationField"
            text="Discontinued Product Notification">
          </kendo-label>
          <kendo-formhint>
            There can only be one Discontinued Product Notification recipient.
            This will replace any previously assigned recipient.
          </kendo-formhint>
        </div>
        <div class="form-group">
          <input type="checkbox" #keheNewsLetterField kendoCheckBox formControlName="subscribedToKeheNewsLetter" />
          <kendo-label class="k-checkbox-label" [for]="keheNewsLetterField" text="KeHE Newsletter">
          </kendo-label>
        </div>
      </div>
    </div>
  </div>

  <div footer class="d-flex justify-content-end px-1">
    <kehe-phoenix-button classAttr="btn-link btn-medium me-2" (click)="closeModal()">
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="onSaveClicked()"
      [disabled]="form && (inProgress || form.invalid || form.pristine)" [spinner]="inProgress">
      {{ getActionButtonTitle() }}
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>