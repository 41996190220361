import { Injectable } from '@angular/core';
import { ExternalTopBarEventTypes } from '@kehe/phoenix-top-bar';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopBarExternalEventsService {

  eventSubject: Subject<ExternalTopBarEventTypes> = new Subject<ExternalTopBarEventTypes>();

  constructor() { }

  dispatchExternalEvent(event: ExternalTopBarEventTypes) {
    this.eventSubject.next(event);
  }
}
