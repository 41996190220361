<div [formGroup]="form">
  <hr class="my-4"/>
  <div class="mb-3">
    <kendo-formfield>
      <kendo-label [for]="ticketTxt" text="Ticket Number (optional)"></kendo-label>
      <kendo-textbox #ticketTxt placeholder="e.g. CWI-867-5309" formControlName="ticket"
      data-automation-id="bulk-edit-dcpg-modal-ticket-number-input"></kendo-textbox>
    </kendo-formfield>
  </div>
  <div class="mb-3">
    <kendo-formfield>
      <kendo-label [for]="requestReasonTextArea" text="Request Reason"></kendo-label>
      <kendo-textarea #requestReasonTextArea resizable="auto" placeholder="Enter Request Reason"
      data-automation-id="bulk-edit-dcpg-modal-request-reason-input"
        formControlName="notes"></kendo-textarea>
      <kendo-formerror>Request Reason is required.</kendo-formerror>
    </kendo-formfield>
  </div>
</div>
