import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { SupplierDetailService } from '../../services/supplier-detail.service';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { getGeneralForm, selectOnBoardingStatusForTab, selectSupplierDetail } from '../supplier-detail.selectors';
import { SupplierDetailTabs } from '../../models/supplier-detail-tabs';
import { OnboardingTabStatuses } from '@app/models/onboarding-tab-statuses';

import * as SupplierDetailActions from '../supplier-detail.actions';
import { PendoEvent } from '../../models/pendo-event.interface';
import { PendoTrackEvents } from '../../models/pendo-track-events.enum';

@Injectable()
export class SupplierGeneralTabEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,

    private readonly supplierDetailService: SupplierDetailService,
  ) {}

  saveGeneralForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.saveGeneralForm),
      withLatestFrom(this.store.select(getGeneralForm)),
      switchMap(([, form]) => {
        return this.supplierDetailService.save(form).pipe(
          map((supplier) => {
            return SupplierDetailActions.saveGeneralFormSuccess({ supplier });
          }),
          catchError(() =>
            of(SupplierDetailActions.saveGeneralFormError())
          )
        );
      })
    )
  );

  updateGeneralTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.updateGeneralTabStatus),
      withLatestFrom(
        this.store.select(selectSupplierDetail),
        this.store.select(selectOnBoardingStatusForTab(SupplierDetailTabs.general)),
      ),
      switchMap(([action, supplier, generalTabStatus]) =>
        this.supplierDetailService
          .updateOnBoardingStatus(supplier.esn, {
            ...generalTabStatus,
            tabStatus: action.status,
            statusNotes: action.statusNote,
          })
          .pipe(
            map((data) => {
              if (action.status === OnboardingTabStatuses.Approved) {
                const event: PendoEvent = {
                  name: PendoTrackEvents.UpdateSupplierGeneralTabStatus,
                  eventProperties: {
                    esn: supplier.esn,
                    biTimestamp: Date.now(),
                    tab: data.tab,
                    tabStatus: data.tabStatus,
                    requesterType: supplier.requesterType,
                    isInternational: supplier.hasInternationalCurrency,
                    invitedDate: supplier.created,
                    isDpiInitiated: supplier.isDpiInitiated,
                    cmName: supplier.categoryManagerFirstName + " " + supplier.categoryManagerLastName
                  }
                }
                return SupplierDetailActions.updateGeneralTabStatusSuccess({
                  status: data,
                  pendoEvent: event
                });
              }
              return SupplierDetailActions.invalidateDocuSign({
                status: data,
                rejected: action.status === OnboardingTabStatuses.Rejected,
              });
            }),
            catchError(() =>
              of(SupplierDetailActions.updateGeneralTabStatusError())
            )
          )
      )
    )
  );
}
