import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-bulk-edit-delivered-lead-times-card',
  templateUrl: './bulk-edit-delivered-lead-times-card.component.html',
  styleUrls: []
})
export class BulkEditDeliveredLeadTimesCardComponent {
  @Input()
  label: string;

  @Input() 
  form: UntypedFormGroup;

  @Input()
  isTotalTime = false;
}
