import { UserNotificationEntity } from '../model/user-notifications';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { UserNotificationCard } from '@kehe/phoenix-top-bar';

export interface UserNotificationsState {
  entityState: EntityState<UserNotificationEntity>;
  displayCardState: EntityState<UserNotificationCard>;
  notificationCount: number;
}

export const userNotificationAdapter = createEntityAdapter<UserNotificationEntity>({
  selectId: entity => entity.notificationId
});

export const userNotificationCardAdapter = createEntityAdapter<UserNotificationCard>({
  selectId: card => card.notificationId
});

export const userNotificationsInitialState: UserNotificationsState = {
  entityState: userNotificationAdapter.getInitialState(),
  displayCardState: userNotificationCardAdapter.getInitialState(),
  notificationCount: 0
};
