<h4 class="mb-2 col-12 col-md-6 px-4">Pick-Up Address</h4>
<p class="px-4">If you have a Pickup Delivery Method add your pick-up address(es) below. </p>

<div [formGroup]="form" *ngIf="contacts$ | async as contacts">
  <kendo-label text="Pick-Up Address" class="px-4"></kendo-label>

  <div class="layout w-100 px-4">
    <ng-container *ngFor="let group of form.controls; let i = index">
      <div [formGroupName]="i" class="pe-4">
        <app-contact-card class="d-flex h-100" formControlName="pickupAddress"
          [defaultContactTypes]="types.PickupAddress" [contacts]="contacts" [isInProgress]="saving$ | async"
          [saveContactError]="error$ | async" (smartyStreetsUseAddress)="onSmartyStreetsUseAddress($event)"
          (remove)="onRemove($event)"></app-contact-card>
      </div>
    </ng-container>
  </div>

  <kehe-phoenix-button *ngIf="contacts.length > 0" class="mt-3 mx-auto d-flex px-4" classAttr="btn-link btn-medium"
    [spinner]="false" [disabled]="disabled" (click)="onAddClicked()" iconClassAttr="fa fa-plus">
    Add Address
  </kehe-phoenix-button>

  <app-contact-book-modal *ngIf="showModal" [defaultContactType]="types.PickupAddress" [contacts]="[]"
    [isInProgress]="saving$ | async" [saveContactError]="error$ | async"
    (smartyStreetsUseAddress)="onSmartyStreetsUseAddress($event)" (closeModal)="onCancel()">
  </app-contact-book-modal>
</div>
<app-delete-address *ngIf="stagedContact" [contact]="stagedContact" [loading]="deleting$ | async"
  [error]="deleteAddressError$ | async" (cancelRequest)="onCancel()" (delete)="onDelete($event)"></app-delete-address>