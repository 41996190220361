import { Injectable } from '@angular/core';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DPIService } from '../../services/dpi.service';

import * as SupplierDetailActions from '../supplier-detail.actions';

@Injectable()
export class SupplierDPIEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly dpiService: DPIService,
    private readonly toastService: PhoenixToastService,
  ) {}

  clickExportSupplierDataButton$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SupplierDetailActions.userClickExportSupplierDataButton),
      switchMap(({ esn }) => this.dpiService.exportSupplierData(esn).pipe(
        map(() => SupplierDetailActions.exportSupplierDataSuccess()),
        catchError(() => of(SupplierDetailActions.exportSupplierDataFailed()))
      )),
    )
  });

  exportSupplierDataSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SupplierDetailActions.exportSupplierDataSuccess),
      map(() => this.toastService.showSuccessToast('Export initiated successfully!'))
    )
  }, { dispatch: false });

  exportSupplierDataFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SupplierDetailActions.exportSupplierDataFailed),
      map(() => this.toastService.showErrorToast('Something went wrong, please try again!'))
    )
  }, { dispatch: false });
}
