import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';

import { EMPTY, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectSupplierEsn } from '../../../../store/supplier-detail.selectors';
import { ApiFailureText } from '../../../../../../enum/alert-text.enum';
import { VendorBrokerManagementService } from '../../../../services/vendor-broker-management.service';
import { NonHqBroker } from '../../../../models/non-hq-broker-response.model';
import * as _ from 'lodash';
import { AbstractApiStore } from '@kehe/phoenix-component-store-utils';

/**
 * `NonHeadquarterBrokerApiStore` is an Angular service that extends `AbstractApiStore` to get the supplier headquarter broker.
 *
 * @class
 * @extends AbstractApiStore
 *
 * @param {VendorBrokerManagementService} _vendorBrokerManagementService.
 * @param {Store} _store - A redux store that holds the application state.
 */
@Injectable({ providedIn: 'root' })
export class RelatedNonHeadquarterBrokerApiStore extends AbstractApiStore<
  NonHqBroker[]
> {
  constructor(
    private _vendorBrokerManagementService: VendorBrokerManagementService,
    private _store: Store
  ) {
    super();
    this.loadNonHeadquarterBrokers$(this._store.select(selectSupplierEsn));
  }

  loadNonHeadquarterBrokers$ = this.effect((supplierEsn$: Observable<string>) =>
    supplierEsn$.pipe(
      tap(() => this.startLoading()),
      switchMap((esn) => {
        if (!esn) {
          this.setData(undefined);
          return EMPTY;
        }

        return this._vendorBrokerManagementService
          .getRelatedNonHqBrokers(esn)
          .pipe(
            tapResponse(
              (broker) => {
                this.setData(_.sortBy(broker.payload, 'ebn'));
              },
              () => {
                this.setError(ApiFailureText.Generic);
              }
            )
          );
      })
    )
  );
}
