import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { DocumentType } from '../../models/document-type';
import {
  confirmDeleteDocument,
  confirmRejectDocument,
  downloadDocument,
  showRejectDocumentFeedback,
  updateDocumentStatus,
} from '../../store/supplier-documents.actions';

import {
  selectDocuments,
  selectDocumentTypes,
} from '../../store/supplier-documents.selectors';
import { Constants } from '../../../../constants/constants';
import { DocumentStatus } from '../../models/document-statuses';
import { DocumentAction } from '../../models/document-action';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { SupplierDetail } from '../../../supplier-detail/models/supplier-detail';
import { Document } from '../../models/document';
import { isReadOnlyDocument } from '../../utils/documents-utils';
import { isSupplierInReview } from '../../../supplier-detail/utilities/supplier-utils';
import { PermissionsService } from '@app/services/permissions.service';
import { DocumentsGridTabStore } from './documents-grid.component.store';

@Component({
  selector: 'app-documents-grid',
  templateUrl: './documents-grid.component.html',
  styleUrls: ['./documents-grid.component.scss'],
  providers: [DocumentsGridTabStore],
})
export class DocumentsGridComponent extends Destroyable implements OnInit {
  @Input()
  filterDocumentTypeId: number;

  @Input()
  showActionColumns = true;

  @Input()
  hasExpiry = true;

  @Input()
  showStatus = true;

  @Input()
  showError = true;

  @Input()
  excludedDocumentTypes = [];

  @Input()
  includedDocumentTypes = [];

  @Input()
  requiredDocumentTypes = [];

  @Input()
  disableUpload = false;

  _isTabLocked = false;

  @Input()
  set isTabLocked(value: boolean) {
    this._isTabLocked = value;
    this.documents.forEach((item) => {
      this.menuOptionMap[item.uniqueId] = this.buildMoreOptions(item);
    });
  }
  get isTabLocked() {
    return this._isTabLocked;
  }

  @Input()
  hideOptions = false;

  vm$ = this._componentStore?.vm$;

  menuOptionMap = {};
  allDocuments: Document[] = [];
  documents: Document[] = [];
  documentTypes: DocumentType[] = [];
  canApproveOnboardingDocument: boolean;

  supplier: SupplierDetail;
  sort: SortDescriptor[] = [
    {
      field: 'type',
      dir: 'asc',
    },
  ];

  moreMenuItems: OptionMenuItem[] = [
    {
      data: DocumentAction.Download,
      name: 'Download',
      enabled: true,
      selected: false,
    },
  ];

  get filterDocumentType() {
    return this.documentTypes?.find((item) => item.id === this.filterDocumentTypeId) ?? null;
  }

  get filterDocument() {
    return this.documents?.find((item) => item.typeId === this.filterDocumentTypeId) ?? null;
  }

  get includedDocumentTypesObjects() {
    return this.documentTypes?.filter((item) => this.includedDocumentTypes.includes(item.id));
  }


  get isSupplierInReview() {
    return isSupplierInReview(this.supplier);
  }

  get isUploadDisabled() {
    if (this.disableUpload) {
      return true;
    }
    return (
      this.isSupplierInReview ||
      this.noMoreUploads() ||
      !this.hasRequiredDocuments()
    );
  }

  get isSortable() {
    if (this.filterDocumentTypeId) {
      const documentType = this.filterDocumentType;
      if (documentType && !documentType.allowMultiple) {
        return false;
      }
    }
    return true;
  }

  constructor(
    private _store: Store,
    private _permissionsService: PermissionsService,
    private _componentStore: DocumentsGridTabStore,
  ) {
    super();
    this.setUserPermissions();
  }

  ngOnInit() {
    const selectDocumentTypes$ = this._store.select(selectDocumentTypes);
    const selectDocuments$ = this._store.select(selectDocuments);

    combineLatest([selectDocumentTypes$, selectDocuments$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([documentTypes, documents]) => {
        this.documentTypes = documentTypes ? documentTypes : [];
        this.allDocuments = documents;
        this.updateDocuments(documents);
      });
    this._store
      .pipe(select(selectDocumentTypes), takeUntil(this.destroy$))
      .subscribe((val) => {
        this.documentTypes = val;
      });
  }

  private updateDocuments(documents: Document[]) {
    let filtered = [];
    if (this.filterDocumentTypeId) {
      filtered = documents.filter(
        (item) => item.typeId === this.filterDocumentTypeId
      );
    } else {
      if (
        this.excludedDocumentTypes.length > 0 &&
        this.includedDocumentTypes.length > 0
      ) {
        throw new Error(
          'Only one of excludedDocumentTypes or includedDocumentTypes should be provided at a time.'
        );
      } else {
        if (this.excludedDocumentTypes.length > 0) {
          filtered = documents.filter(
            (item) => !this.excludedDocumentTypes.includes(item.typeId)
          );
        } else if (this.includedDocumentTypes.length > 0) {
          filtered = documents.filter((item) =>
            this.includedDocumentTypes.includes(item.typeId)
          );
        } else {
          filtered = [...documents];
        }
      }
    }
    this.documents = orderBy(this.excludeW9IfNotApproved(filtered), this.sort);
    this.buildMenuOptions();
  }

  private setUserPermissions() {
    this.canApproveOnboardingDocument = this._permissionsService.userHasAction(
      Constants.UserActions.SupplierOnboardingDocumentApproval
    );
  }

  hasRequiredDocuments() {
    if (this.requiredDocumentTypes.length < 1) {
      return true;
    }
    const uploadedDocTypes = this.allDocuments.map(item => item.typeId);
    return this.requiredDocumentTypes.every(item => uploadedDocTypes.includes(item));
  }

  noMoreUploads() {
    if (this.filterDocumentTypeId && this.filterDocumentType) {
      if (this.filterDocumentType.allowMultiple) {
        return false;
      } else {
        return this.filterDocument !== null;
      }
    }
    if (this.includedDocumentTypes.length > 0) {
      const hasAnyMultiUploadTypes = this.includedDocumentTypesObjects.some((item) => item.allowMultiple);
      if (hasAnyMultiUploadTypes) {
        return false;
      }
      const uploadedDocTypes = this.documents.map(item => item.typeId);
      if (this.includedDocumentTypes.every(item => uploadedDocTypes.includes(item))) {
        return true;
      }
    }
    return false;
  }

  excludeW9IfNotApproved(documents: Document[]): Document[] {
    return documents.filter((item) =>
      item.typeId === Constants.W9DocumentTypeId
        ? item.status === DocumentStatus.Approved
        : true
    );
  }

  buildMenuOptions() {
    this.menuOptionMap = {};
    this.documents.forEach((item) => {
      this.menuOptionMap[item.uniqueId] = this.buildMoreOptions(item);
    });
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.documents = orderBy(this.documents, this.sort);
  }

  defaultIfEmpty(str: string) {
    return str || Constants.EmptyValue;
  }

  deleteDocument(document: Document) {
    if (
      this.shouldDeleteBeDisabled(document)
    ) {
      return;
    }
    this._store.dispatch(confirmDeleteDocument({ document }));
    this._componentStore?.showDeleteModal(true);
  }

  onUploadClick() {
    this._componentStore?.showUploadModal(true);
  }

  shouldDeleteBeDisabled(doc: Document) {
    if (doc.typeId === Constants.PurchasingInfoDocumentTypeId) {
      return false;
    }
    if (doc.typeId === Constants.RetailerRequestedDetailsDocumentTypeId) {
      return false;
    }
    if (this.isSupplierInReview) {
      return true;
    }

    if (this.isTabLocked) {
      return true;
    }

    if (doc.status === DocumentStatus.NotSubmitted) {
      return true;
    }
    if (doc.status === DocumentStatus.Approved) {
      const docuSignTypeIds = this.documentTypes
        .filter((type) => type.isDocuSign)
        .map((type) => type.id);
      if (docuSignTypeIds.includes(doc.typeId)) {
        return true;
      }
    }
    if (doc.typeId === Constants.SupplierInfoDocumentTypeId) {
      return doc.status === DocumentStatus.NotSubmitted;
    }
    if (doc.typeId === Constants.W9DocumentTypeId) {
      return (
        doc.status === DocumentStatus.Approved ||
        doc.status === DocumentStatus.InReview
      );
    }
    return false;
  }

  shouldShowMenuOptions(doc: Document) {
    if (doc.typeId === Constants.PurchasingInfoDocumentTypeId) {
      return true;
    }
    if (doc.typeId === Constants.RetailerRequestedDetailsDocumentTypeId) {
      return true;
    }
    if (!this.isNotSubmitted(doc)) {
      return true;
    }
    return false;
  }

  hasError(document: Document) {
    return document.status === DocumentStatus.Rejected;
  }

  isNotSubmitted(document: Document) {
    return document.status === DocumentStatus.NotSubmitted;
  }

  documentFeedback(document: Document) {
    this._store.dispatch(showRejectDocumentFeedback({ document }));
  }

  moreOptionSelected(option: OptionMenuItem, document: Document) {
    switch (option.data) {
      case DocumentAction.Approve: {
        const req = {
          ...document,
          status: DocumentStatus.Approved,
        };
        if (
          document.typeId === Constants.COIDocumentTypeId &&
          !this.canApproveOnboardingDocument
        ) {
          req.status = DocumentStatus.InReviewEDS;
        }
        this._store.dispatch(updateDocumentStatus({ document: req }));
        break;
      }
      case DocumentAction.Reject: {
        this._store.dispatch(confirmRejectDocument({ document: document }));
        this._componentStore?.showRejectModal(true);
        break;
      }
      case DocumentAction.Download: {
        this._store.dispatch(downloadDocument({ document }));
        break;
      }
      case DocumentAction.Delete: {
        this.deleteDocument(document);
        break;
      }
    }
  }

  buildMoreOptions(item: Document) {
    if (isReadOnlyDocument(item, this.documentTypes)) {
      return [
        {
          data: DocumentAction.Download,
          name: 'Download',
          enabled: true,
          selected: false,
        },
        {
          data: DocumentAction.Delete,
          name: 'Delete',
          enabled: !this.shouldDeleteBeDisabled(item),
          selected: false,
        },
      ];
    }
    return [
      {
        data: DocumentAction.Approve,
        name: 'Approve',
        enabled: this.enableApprove(item),
        selected: false,
      },
      {
        data: DocumentAction.Reject,
        name: 'Reject',
        enabled: this.enableReject(item),
        selected: false,
      },
      {
        data: DocumentAction.Download,
        name: 'Download',
        enabled: true,
        selected: false,
      },
      {
        data: DocumentAction.Delete,
        name: 'Delete',
        enabled: !this.shouldDeleteBeDisabled(item),
        selected: false,
      },
    ];
  }

  enableApprove(item: Document) {
    if (
      item.typeId === Constants.COIDocumentTypeId &&
      item.status === DocumentStatus.InReviewEDS
    ) {
      return this.canApproveOnboardingDocument;
    }
    return (
      item.status === DocumentStatus.InReview ||
      item.status === DocumentStatus.Rejected
    );
  }

  enableReject(item: Document) {
    return (
      item.status === DocumentStatus.InReview ||
      item.status === DocumentStatus.InReviewEDS ||
      item.status === DocumentStatus.Approved
    );
  }

  downloadHyperlinkClicked(document: Document) {
    this._store.dispatch(downloadDocument({ document, target: '_blank' }));
  }
}
