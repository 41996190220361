import { createReducer, on, Action } from '@ngrx/store';
import * as ProductGroupsActions from './product-groups.actions';
import { ProductGroup } from '../../supplier-detail/models/product-group';
import {
  createProductGroupFilterChipsList,
  filterProductGroups,
  prepareProductGroups,
  prepareProductsData,
  updateDeliveryMethodForDCProductGroups,
  updateProductGroupsChips,
} from '../utils/products-util';
import { IChip } from '@kehe/phoenix-chips';
import { Product } from '../models/product';
import { ProductGroupsFilter } from '../../supplier-detail/models/product-groups-filter';
import { DCProductGroup } from '../../supplier-detail/models/dc-product-group';

export type DCProductGroupClone = DCProductGroup & {
  cloneToDCs: { value: string }[];
  buyers: { buyerCode: string; buyerDCs: { value: string }[] }[];
};

export class ProductGroupsState {
  isLoading: boolean;
  products: Product[];
  productGroups: ProductGroup[];
  productGroupsFiltered: ProductGroup[];
  productGroupsFilterChips: IChip[];
  productGroupsSearch: string;
  productGroupsFilter: ProductGroupsFilter;

  // to hold the initial clone data
  stagedCloneDCPG?: {
    productGroup: ProductGroup;
    dcpg: DCProductGroupClone;
    cloneTo: { value: string; }[];
  };
  // this is the final edited data that will be saved
  committedCloneDCPG?: {
    dcpg: DCProductGroupClone;
  };
  cloneDCHasChanges: boolean;
  cloneDCValid: boolean;
  cloneDCPGSaveInProgress: boolean;
  cloneDCPGSaveSuccess: boolean;
  cloneDCPGPickupChanged: boolean;
  cloneDCPGSaveError?: string;

  applyDCPGBulkEditSuccess: boolean;
  applyDCPGBulkEditFailed?: string;
}

export const initializeState = (): ProductGroupsState => {
  return {
    isLoading: false,
    products: [],
    productGroups: [],
    productGroupsFiltered: [],
    productGroupsFilterChips: [],
    productGroupsSearch: '',
    productGroupsFilter: new ProductGroupsFilter(),
    cloneDCHasChanges: false,
    cloneDCValid: true,
    cloneDCPGSaveInProgress: false,
    cloneDCPGSaveSuccess: false,
    cloneDCPGPickupChanged: false,

    applyDCPGBulkEditSuccess: false,
  };
};

export const initialState = initializeState();

const rootReducer = createReducer(
  initialState,
  // Products Data
  on(ProductGroupsActions.clearAllProductsData, (state) => ({
    ...state,
    products: initialState.products,
    productGroups: initialState.productGroups,
    productGroupsFiltered: initialState.productGroupsFiltered,
    productGroupsFilterChips: initialState.productGroupsFilterChips,
    productGroupsFilter: initialState.productGroupsFilter,
  })),
  on(ProductGroupsActions.loadProductsData, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ProductGroupsActions.loadProductsDataSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    products: action.products ?? [],
  })),
  on(ProductGroupsActions.loadProductsDataError, (state) => ({
    ...state,
    isLoading: false,
    products: [],
  })),
  on(ProductGroupsActions.prepareProductsData, (state) => ({
    ...state,
    productGroups: prepareProductsData(state.productGroups, state.products),
  })),
  // Products Data

  // Product Groups
  on(ProductGroupsActions.setProductGroups, (state, action) => ({
    ...state,
    productGroups: prepareProductGroups(
      action.productGroups,
      action.dcList,
      action.contacts ?? []
    ),
    productGroupsFiltered: prepareProductGroups(
      action.productGroups,
      action.dcList,
      action.contacts ?? []
    ),
    productGroupsSearch: initialState.productGroupsSearch,
    productGroupsFilter: initialState.productGroupsFilter,
    productGroupsFilterChips: initialState.productGroupsFilterChips,
  })),
  on(
    ProductGroupsActions.updateDeliveryMethodInDCProductGroups,
    (state, action) => ({
      ...state,
      productGroups: updateDeliveryMethodForDCProductGroups(
        state.productGroups,
        action.deliveryMethodList
      ),
    })
  ),
  on(ProductGroupsActions.filterProductGroups, (state, action) => ({
    ...state,
    productGroupsFiltered: filterProductGroups(
      state.productGroups,
      action.filter,
      action.search,
    ),
    productGroupsFilterChips: createProductGroupFilterChipsList(action.filter),
  })),
  // Product Groups
  // Product Group Search & Filter
  on(ProductGroupsActions.productGroupsSearchChanged, (state, action) => ({
    ...state,
    productGroupsSearch: action.search,
  })),
  on(ProductGroupsActions.productGroupsSearchCleared, (state) => ({
    ...state,
    productGroupsSearch: initialState.productGroupsSearch,
  })),
  on(ProductGroupsActions.productGroupsFilterChanged, (state, action) => ({
    ...state,
    productGroupsFilter: action.productGroupsFilter,
  })),
  on(ProductGroupsActions.productGroupsChipClicked, (state, action) => ({
    ...state,
    productGroupsFilter: updateProductGroupsChips(state.productGroupsFilter, action.chip),
  })),
  on(ProductGroupsActions.productGroupsFilterClearAll, (state) => ({
    ...state,
    productGroupsFilterChips: [],
    productGroupsFilter: new ProductGroupsFilter(),
  })),
  // Product Group Search & Filter

  // Clone DCPG
  on(ProductGroupsActions.cloneDCProductGroup, (state, action) => ({
    ...state,
    stagedCloneDCPG: {
      productGroup: action.productGroup,
      dcpg: action.dcpg,
      cloneTo: action.cloneTo,
    },
    committedCloneDCPG: {
      dcpg: action.dcpg,
    },
    cloneDCHasChanges: false,
    cloneDCValid: true,
    cloneDCPGPickupChanged: false,
  })),
  on(ProductGroupsActions.cloneDCProductGroupChanged, (state, action) => ({
    ...state,
    cloneDCHasChanges: action.changed,
    cloneDCPGPickupChanged: action.pickupChanged,
    cloneDCValid: action.valid,
    committedCloneDCPG: {
      ...state.committedCloneDCPG,
      dcpg: action.dcpg
    }
  })),
  on(ProductGroupsActions.cloneDCProductGroupCancelled, (state) => ({
    ...state,
    stagedCloneDCPG: undefined,
    committedCloneDCPG: undefined,
    cloneDCHasChanges: false,
    cloneDCValid: false,
    cloneDCPGSaveInProgress:false,
    cloneDCPGSaveSuccess: false,
    cloneDCPGPickupChanged: false,
    cloneDCPGSaveError: undefined,
  })),
  on(ProductGroupsActions.cloneDCPGSave, (state) => ({
    ...state,
    cloneDCPGSaveInProgress: true,
    cloneDCPGPickupChanged: false,
    cloneDCPGSaveError: undefined,
  })),
  on(ProductGroupsActions.cloneDCPGSaveSuccess, (state) => ({
    ...state,
    stagedCloneDCPG: undefined,
    committedCloneDCPG: undefined,
    cloneDCHasChanges: false,
    cloneDCValid: false,
    cloneDCPGSaveInProgress:false,
    cloneDCPGSaveSuccess: true,
    cloneDCPGPickupChanged: false,
    cloneDCPGSaveError: undefined,
  })),
  on(ProductGroupsActions.cloneDCPGSaveFailed, (state) => ({
    ...state,
    cloneDCPGSaveSuccess: false,
    cloneDCPGSaveInProgress: false,
    cloneDCPGSaveError: 'Something went wrong, please try again.',
  })),
  // Clone DCPG
  // Bulk Edit DCPGs
  on(
    ProductGroupsActions.applyDCPGBulkEdit,
    ProductGroupsActions.initDCPGBulkEdit,
    (state) => ({
      ...state,
      applyDCPGBulkEditSuccess: false,
      applyDCPGBulkEditFailed: undefined,
    })
  ),
  on(ProductGroupsActions.applyDCPGBulkEditSuccess, (state) => ({
    ...state,
    applyDCPGBulkEditSuccess: true,
    applyDCPGBulkEditFailed: undefined,
  })),
  on(ProductGroupsActions.applyDCPGBulkEditFailed, (state, { error }) => ({
    ...state,
    applyDCPGBulkEditSuccess: false,
    applyDCPGBulkEditFailed: error,
  })),
);

export function reducer(state: ProductGroupsState | undefined, action: Action) {
  return rootReducer(state, action);
}
