export interface SupplierHistoryFilterField {
  group: string;
  code: string;
  value: string;
}

const _supplierHistoryFilterFields = [
  {
    group: 'General Tab',
    code: 'Legal Name',
    value: 'legalname',
  },
  {
    group: 'General Tab',
    code: 'Doing Business AS (DBA)',
    value: 'doingbusinessas',
  },
  {
    group: 'General Tab',
    code: 'Category Manager',
    value: 'categorymanager.name',
  },
  {
    group: 'General Tab',
    code: 'Supplier Dev Manager',
    value: 'supplierdevelopmentmanager.code',
  },
  {
    group: 'General Tab',
    code: 'Headquarters Address',
    value: 'headquarteraddress',
  },
  {
    group: 'General Tab',
    code: 'EDI Customer',
    value: 'isedi',
  },
  {
    group: 'General Tab',
    code: 'Trading partner ID (TPID)',
    value: 'tradingpartnerid',
  },
  {
    group: 'Brands',
    code: 'Brand Name',
    value: 'brand.name',
  },
  {
    group: 'Brands',
    code: 'Brand Code',
    value: 'brand.code',
  },
  {
    group: 'Broker Tab',
    code: 'Headquarters Broker Name',
    value: 'no match',
  },
  {
    group: 'Broker Tab',
    code: 'Headquarters Broker Number',
    value: 'broker.esn',
  },
  {
    group: 'Broker Tab',
    code: 'Headquarters Broker association date',
    value: 'broker.created',
  },
  {
    group: 'Supplier Terms',
    code: 'Supplier Type',
    value: 'suppliertype',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Contract Date',
    value: 'terms.contractdate',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Change Date',
    value: 'terms.changedate',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Form Exemption',
    value: 'terms.hastermsformexemption',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Payment Hold',
    value: 'terms.hasprocurementhold',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Audit',
    value: 'terms.hasstatementaudit',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Audit Timeframe',
    value: 'terms.audittimeframecode',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Promotion Allowance Audits',
    value: 'terms.haspromoallow',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Promotional Price Protection Audits',
    value: 'terms.haspromopriceprotect',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Shelf Life',
    value: 'terms.shelflifepercentage',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Cut Cases',
    value: 'terms.acceptscutcasefee',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Accepts New Product Setup Fee',
    value: 'terms.acceptsnewitemsetupfee',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms New Product Setup Fee',
    value: 'terms.newitemsetupfee',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Product Imaging Charge',
    value: 'terms.acceptsimagingcharge',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms New Product Intro Allowance',
    value: 'terms.haspromoallow',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Payment Terms',
    value: 'terms.paymentterms.description',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Payment Method',
    value: 'terms.paymentmethodcode',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Due Date Calculation',
    value: 'terms.duedatecalculation',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Special Event Terms (Seasonal)',
    value: 'terms.specialeventtermsseasonalcode',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Special Event Terms (New Warehouse Opening)',
    value: 'terms.specialeventtermsnewwarehouseopeningcode',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Price Change Notification',
    value: 'terms.pricechangenoticeindays',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Lumping Fees',
    value: 'terms.haslumpingfee',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Pallet Charges',
    value: 'terms.haspalletcharge',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Pallet Charge In $$',
    value: 'terms.palletchargeamount',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Fill Rate Fee',
    value: 'terms.hasfillratefee',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Order Overage 30%',
    value: 'terms.hasthirtypercentordercoverage',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Placement Allowance (Free Fill)',
    value: 'terms.promotions.freeproductsprogramcode',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Free Fill Handling Charge',
    value: 'terms.promotions.freeproducthandlingchargecode',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms KeHE Connect BI Program',
    value: 'terms.promotions.marketingallowance.code',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Extra Performance',
    value: 'terms.promotions.hasextraperformance',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Publication Listing Fees',
    value: 'terms.promotions.haspublicationlistingfee',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms MCB Processing Fee',
    value: 'terms.promotions.acceptsmanufacturerchargebackfee',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms MCB Exception code',
    value: 'terms.promotions.manufacturerchargebackexceptioncode',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms MCB Fee Dollars',
    value: 'terms.promotions.manufacturerchargebackfeeamount',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms MCB Fee Percentage',
    value: 'terms.promotions.manufacturerchargebackfeepercentage',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Retailer Reclamation',
    value: 'terms.hasretailerreclamation',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Retailer Discontinued',
    value: 'terms.hasretailerdiscontinuedprogram',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms KeHE Discontinued',
    value: 'terms.haskehediscount',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Spoils',
    value: 'terms.spoils.type',
  },
  {
    group: 'Supplier Terms',
    code: 'Terms Warehouse Spoils',
    value: 'terms.acceptswarehouseoutofdatecharge',
  },
  {
    group: 'Product Group',
    code: 'Product Group Name',
    value: 'productgroups.000.name',
  },
  {
    group: 'Product Group',
    code: 'Product Group number',
    value: 'productgroups.000.productgroupnumber',
  },
  {
    group: 'Product Group',
    code: 'Product Group Temp Class',
    value: 'productgroups.000.temperatureclass',
  },
  {
    group: 'Product Group',
    code: 'Product Group Brand',
    value: 'productgroups.brands.name',
  },
  {
    group: 'Product Group',
    code: 'Remittance Address',
    value: 'productgroups.000.remittanceaddress',
  },
  {
    group: 'Product Group',
    code: 'DC Product Group Number',
    value: 'dcproductgroupnumber',
  },
  {
    group: 'Product Group',
    code: 'Buyer',
    value: 'buyercode',
  },
  {
    group: 'Product Group',
    code: 'Delivery Method',
    value: 'deliverymethod',
  },
  {
    group: 'Product Group',
    code: 'Pickup Address',
    value: 'pickupaddress',
  },
  {
    group: 'Product Group',
    code: 'Supplier Ready time',
    value: 'pickupleadtimes.readytime',
  },
  {
    group: 'Product Group',
    code: 'KeHE Transit Time',
    value: 'leadtimes.transittime',
  },
  {
    group: 'Product Group',
    code: 'Total Lead Time',
    value: 'leadtime',
  },
  {
    group: 'Product Group',
    code: 'PO Type',
    value: 'potype',
  },
  {
    group: 'Product Group',
    code: 'PO Fax',
    value: 'pofax.fax',
  },
  {
    group: 'Product Group',
    code: 'PO Email',
    value: 'poemails.0.email',
  },
  {
    group: 'DocuSign',
    code: 'Docusign Data Change',
    value: 'docusigndata',
  },
] as Array<SupplierHistoryFilterField>;

export const SupplierHistoryFilterFields = _supplierHistoryFilterFields.reduce((a, c, i) => {
  a.push({
    ...c,
    index: i,
  });
  return a;
}, []);
