import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FeatureFlagService } from './feature-flag.service';
import { SupplierDetailTabs } from '@app/modules/supplier-detail/models/supplier-detail-tabs';
import { OnboardingTabStatuses } from '@app/models/onboarding-tab-statuses';
import * as FeatureFlagActions from './feature-flag.actions';
import * as AuthenticationActions from '../authentication/authentication.actions';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import * as SupplierDetailActions from '@app/modules/supplier-detail/store/supplier-detail.actions';
import * as SupplierDetailSelectors from '@app/modules/supplier-detail/store/supplier-detail.selectors';

@Injectable()
export class FeatureFlagEffects {
  constructor(
    private actions$: Actions,
    private featureFlagService: FeatureFlagService,
    private store: Store,
  ) {}

  initializeSplitSdk$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logInSuccess),
        withLatestFrom(
          this.store.select(AuthenticationSelectors.selectUserEmail),
        ),
        tap(([, email]) => this.featureFlagService.initializeSplitSdk(email)),
      );
    },
    { dispatch: false },
  );

  getFeatureFlags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FeatureFlagActions.splitSdkReady,
        FeatureFlagActions.splitSdkUpdateReceived,
      ),
      withLatestFrom(
        this.store.select(AuthenticationSelectors.selectUserEmail),
      ),
      switchMap(([, email]) =>
        this.featureFlagService
          .getFeatureFlags({ email: email.toLowerCase() })
          .pipe(
            map((featureFlags) =>
              FeatureFlagActions.getFeatureFlagSuccess({ featureFlags }),
            ),
          ),
      ),
    );
  });

  loadSupplierFeatureFlags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SupplierDetailActions.loadSupplierDetailSuccess,
        SupplierDetailActions.loadOnBoardingStatusSuccess,
      ),
      withLatestFrom(
        this.store.select(AuthenticationSelectors.selectUserEmail),
        this.store.select(SupplierDetailSelectors.selectSupplierCreatedTime),
        this.store.select(
          SupplierDetailSelectors.selectOnBoardingStatusForTab(
            SupplierDetailTabs.general,
          ),
        ),
      ),
      switchMap(([_, email, supplierCreatedDate, generalTab]) =>
        this.featureFlagService
          .getFeatureFlags({
            supplierCreatedDate: +new Date(supplierCreatedDate),
            generalTabApprovedDate: generalTab?.tabStatus === OnboardingTabStatuses.Approved ? +new Date(generalTab?.created) : 0,
            email: email.toLowerCase(),
          })
          .pipe(
            map((featureFlags) =>
              FeatureFlagActions.refreshFeatureFlagSuccess({ featureFlags }),
            ),
          ),
      ),
    );
  });
}
