import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import { dcProductGroupFormChanged, loadBuyerList, saveDCProductGroupForm } from '../../store/supplier-detail.actions';
import { selectBuyerList, selectDeliveryMethodList, selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { filter, startWith, take, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ProductGroup } from '../../models/product-group';
import { SupplierDetail } from '../../models/supplier-detail';
import { Buyer } from '../../models/buyer';
import { DropdownOption } from './../../../../models/dropdown-option';
import { defaultAddress } from '../../../../utils/address-utils';
import { environment } from '../../../../../environments/environment';
import * as DistributionCenterSelectors from '@app/shared/state//distribution-center/distribution-center.selectors';
import {
  ProductGroupDeliveryFormComponent
} from '../../../product-groups/components/product-group-delivery-form/product-group-delivery-form.component';
import {
  PoTransmissionsFormComponent
} from '../../../product-groups/components/po-transmissions-form/po-transmissions-form.component';
import { isValidKeHEEmail } from '../../../../utils/form-utils';
import { Constants } from '../../../../constants/constants';
import { isDraft, isInvitedDraftSupplier } from '../../utilities/supplier-utils';
import { getProductGroup } from '../../../product-groups/store/product-groups.selectors';
import { hasProperty } from '../../../../utils/common-utils';
import { mapValues } from 'lodash';
import { ProductGroupActions } from '../../../product-groups/models/product-group-actions';

@Component({
  selector: 'app-add-dc-product-group-form',
  templateUrl: './add-dc-product-group-form.component.html',
  styleUrls: ['./add-dc-product-group-form.component.scss']
})
export class AddDcProductGroupFormComponent extends Destroyable implements OnInit, AfterViewInit {

  @ViewChild('deliveryGroup', { static: true }) deliveryGroup: ProductGroupDeliveryFormComponent;
  @ViewChild('poTransmissionsGroup', { static: true }) poTransmissionsGroup: PoTransmissionsFormComponent;

  readonly buyerRegions = Constants.BuyerRegions;
  readonly actionTypes = ProductGroupActions;

  isFormReady: boolean;
  public form: UntypedFormGroup;
  productGroup: ProductGroup;
  supplierDetail: SupplierDetail;
  public dcList: Array<object>;
  buyerList: Buyer[];
  deliveryMethodList: DropdownOption[];
  tempAddress = defaultAddress();
  smartyStreetsKey = environment.smartyStreetsKey;
  private productGroupNumber: string;
  canShowBuyerRegionEmail = false;
  supplier: SupplierDetail;

  get buyerCodeField() {
    return this.form.get('buyerCode');
  }

  get buyerRegionField() {
    return this.form.get('buyerRegion');
  }

  get buyerEmailField() {
    return this.form.get('buyerEmail');
  }

  get distributionCenterNumberField() {
    return this.form.get('distributionCenterNumber');
  }

  get deliveryFormGroup() {
    return this.deliveryGroup.form;
  }

  get showContactCard() {
    return isInvitedDraftSupplier(this.supplier);
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private _store: Store,
  ) {
    super();
  }

  get buyerCode() {
    return this.form.get('buyerCode');
  }

  get distributionCenterNumber() {
    return this.form.controls.distributionCenterNumber.value;
  }

  get isDelivered(): boolean {
    return this.deliveryFormGroup.controls.deliveryMethod.value === 'Delivered';
  }

  get isFormValid() {
    return this.form.valid &&
      (this.deliveryGroup.form.enabled ? this.deliveryGroup.form.valid : true) &&
      (this.poTransmissionsGroup.form.enabled ? this.poTransmissionsGroup.form.valid : true);
  }

  get isDraftSupplier() {
    return isDraft(this.supplier)
  }

  patchForm(): void {
    this.deliveryFormGroup.get('pickup.pickupAddress').patchValue(this.tempAddress);
    this.deliveryGroup.patchForm(null);
    this.poTransmissionsGroup.patchForm(null, []);
  }

  ngOnInit() {
    this.productGroupNumber = this.route.snapshot.paramMap.get('productGroupNumber');
    this.initializeForm();
    this.dispatchStoreActions();

    this._store.select(selectSupplierDetail).pipe(
      takeUntil(this.destroy$))
      .subscribe((supplier) => {
        this.supplier = supplier;
        if (supplier) {
          this.canShowBuyerRegionEmail = isDraft(supplier);
          this.updateBuyerFieldState();
        }
      });
  }

  ngAfterViewInit() {
    this.initializeStoreSelectorObservables();
  }

  pickupAddressSelected(selectedAddress) {
    this._store.dispatch(saveDCProductGroupForm());
  }

  public onFocus(element: any): void {
    element.toggle(true);
  }

  buyerValueChange(value: any): void {
    if (value) {
      const buyer = this.buyerList.find(item => item.code === value);
      this.form.controls.buyerName.patchValue(buyer.name);
    } else {
      this.form.controls.buyerName.patchValue(null);
    }
  }

  private initializeForm(): void {
    this.form = this._formBuilder.group({
      distributionCenterNumber: ['', [Validators.required]],
      buyerCode: ['', [Validators.required]],
      buyerRegion: ['', [Validators.required]],
      buyerEmail: ['', [Validators.required, Validators.email, isValidKeHEEmail]],
      buyerName: ['', [Validators.required]],
    });
  }

  private dispatchStoreActions(): void {
    this._store.dispatch(loadBuyerList());
  }

  private initializeStoreSelectorObservables(): void {
    const getSupplierDetails$ = this._store.select(selectSupplierDetail).pipe(take(1));
    const getProductGroup$ = this._store.select(getProductGroup, { productGroupNumber: this.productGroupNumber }).pipe(take(1));
    const selectDcList$ = this._store.select(DistributionCenterSelectors.selectDistributionCenterList).pipe(take(1));
    const selectDeliveryMethodList$ = this._store.select(selectDeliveryMethodList).pipe(filter(x => x.length > 0));
    const selectBuyerList$ = this._store.select(selectBuyerList).pipe(filter(x => x.length > 0));

    combineLatest([
      getSupplierDetails$,
      getProductGroup$,
      selectDcList$,
      selectBuyerList$,
      selectDeliveryMethodList$,
    ])
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(([supplier, productGroup, dcList, buyerList, deliveryMethodList]) => {

        this.supplierDetail = supplier;
        this.productGroup = productGroup;

        this.dcList = this.mapDcToCodeValue(this.validDCs(dcList, productGroup))
          .filter(item => !Constants.ExcludedDCs.includes(+item.value));
        this.buyerList = buyerList;
        this.deliveryMethodList = deliveryMethodList;

        this.patchForm();
        this.initializeFormValueChanges();
        // first reference to the view child component will be done in the patchForm call
        this.isFormReady = true;
      });
  }

  private initializeFormValueChanges(): void {
    combineLatest([
      this.form.valueChanges.pipe(startWith()),
      this.deliveryGroup.form.valueChanges.pipe(startWith()),
      this.poTransmissionsGroup.form.valueChanges.pipe(startWith()),
    ]).pipe(takeUntil(this.destroy$)).subscribe(([form, delivery, potransmissions]) => {
      if (!form) {
        return;
      }
      // use raw value so we can still save values from disabled address controls
      const rawFormValue = this.form.getRawValue();

      form.distributionCenterNumber = rawFormValue.distributionCenterNumber;
      form.buyerCode = rawFormValue.buyerCode;
      form.buyerName = rawFormValue.buyerName;

      if (delivery) {
        this.formatDeliveryFields(form, this.deliveryGroup.form.getRawValue());
      }

      if (potransmissions) {
        form.poEmails = potransmissions.poEmails.filter(mailItem => mailItem.email && mailItem.email.length > 0);
        form.poFax = potransmissions.poFax;

        if (hasProperty(potransmissions, 'poOrderMinimum')) {
          form.poOrderMinimum = mapValues(potransmissions.poOrderMinimum, (value) => value === undefined ? '' : value);
        }
      }

      const action = {
        form: {
          esn: this.supplierDetail !== null ? this.supplierDetail.esn : null,
          productGroups: [
            {
              ...this.productGroup,
              dcProductGroups: [{
                ...form,
              }]
            }
          ],
          updatedBySource: 'Enterprise',
        },
        isDirty: this.form.dirty || this.deliveryGroup.form.dirty || this.poTransmissionsGroup.form.dirty,
        isDcProductGroupFormValid: this.isFormValid,
        isPickupAddressDirty: this.isDelivered ? false : this.deliveryFormGroup.get('pickup.pickupAddress').dirty,
      };
      this._store.dispatch(dcProductGroupFormChanged(action));
    });
  }

  formatDeliveryFields(form, delivery) {
    form.deliveryMethodCode = delivery.deliveryMethodCode;
    form.deliveryMethod = delivery.deliveryMethod;
    form.readyTimeAvailable = delivery.readyTimeAvailable;
    form.deliveredLeadTimes = delivery.deliveredLeadTimes;
    form.pickupLeadTimes = delivery.pickupLeadTimes;
    form.inboundRouteType = delivery.inboundRouteType;

    const pickup = delivery.pickup;
    if (this.showContactCard || (this.isDraftSupplier && form.deliveryMethod === 'Delivered')) {
      form.pickupAddressId = pickup?.pickupAddressId;
      form.pickupAddressNotes = pickup?.pickupAddressNotes;
    } else {
      form.pickupAddress = pickup?.pickupAddress;
      if (form.pickupAddress) {
        form.pickupAddress.phone = pickup.pickupPhone;
        form.pickupAddress.extension = pickup.pickupExtension;
        form.pickupAddress.fax = pickup.pickupFax;
        form.pickupAddress.email = pickup.pickupEmail;
        form.pickupAddress.latitude = pickup.pickupAddress.latitude ? parseFloat(pickup.pickupAddress.latitude) : 0;
        form.pickupAddress.longitude = pickup.pickupAddress.longitude ? parseFloat(pickup.pickupAddress.longitude) : 0;
      }
      form.pickupAddressNotes = pickup?.pickupAddressNotes;
    }
  }

  private mapDcToCodeValue(dcList: any[]) {
    return dcList.map(item => ({
      code: item.name.replace('Kehe Dist - ', ''),
      value: item.number,
      codeValue: item.name.replace('Kehe Dist - ', '')
    }));
  }

  private validDCs(dcList: any[], productGroup: ProductGroup) {
    const dcNumbers = productGroup.dcProductGroups.map(item => item.distributionCenterNumber);
    return dcList.filter(item => !dcNumbers.includes(item.number));
  }

  dcValueChange($event: any) {
    this.deliveryGroup.dcValueChanged($event);
  }

  private updateBuyerFieldState() {
    if (this.canShowBuyerRegionEmail) {
      this.buyerRegionField.enable();
      this.buyerEmailField.enable();
    } else {
      this.buyerRegionField.disable();
      this.buyerEmailField.disable();
    }
  }
}
