<ng-container *ngIf="vm$ | async as vm">
  <div class="col">
    <div class="row mt-3 mb-2">
      <div class="col-md-5 col-lg-6 mb-2 mb-md-0">
      </div>
      <div class="col-md-7 col-lg-6 text-right">
        <kehe-phoenix-button (click)="onUploadClick()" [disabled]="isUploadDisabled" classAttr="btn-primary btn-medium">
          Upload
        </kehe-phoenix-button>
      </div>
    </div>
    <div class="kehe-grid no-scroll p-0 w-100">
      <kendo-grid [data]="documents" [sortable]="{
        allowUnsort: false,
        mode: 'single'
      }" [sort]="sort" (sortChange)="sortChange($event)">
        <kendo-grid-column title="" filter="string" [width]="64">
          <ng-template kendoGridCellTemplate let-dataItem class="p-0">
            <div *ngIf="shouldShowMenuOptions(dataItem)">
              <kehe-phoenix-menu-button [menuOptions]="menuOptionMap[dataItem.uniqueId]" btnClass="btn-link"
                *ngIf="!dataItem.isSyncing && !hideOptions" (optionSelected)="moreOptionSelected($event, dataItem)">
              </kehe-phoenix-menu-button>
              <div *ngIf="dataItem.isSyncing" class="tooltip-icon d-flex justify-content-center">
                <em title="Syncing" class="fas fa-sync-alt fa-spin"></em>
              </div>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="" filter="string" [width]="50" *ngIf="showActionColumns">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="text-center tooltip-icon" *ngIf="hasError(dataItem)" (click)="documentFeedback(dataItem)">
              <em title="Rejection Reason" class="fas fa-comment-exclamation cursor-pointer reject-icon"></em>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="filename" title="File Name" [sortable]="true" filter="string"
          headerClass="kehe-grid-header-hover">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a class="download-link"
              (click)="downloadHyperlinkClicked(dataItem)">{{defaultIfEmpty(dataItem.filename)}}</a>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="type" title="Type" [sortable]="true" filter="string" [width]="180"
          headerClass="kehe-grid-header-hover">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{defaultIfEmpty(dataItem.type)}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="created" title="Uploaded" [sortable]="true" filter="date" format="{MM/dd/yyyy}"
          headerClass="kehe-grid-header-hover" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{defaultIfEmpty(dataItem.created | date:'MM/dd/yyyy')}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="expires" title="Expires" [sortable]="isSortable" [width]="100" filter="date"
          format="{MM/dd/yyyy}" *ngIf="hasExpiry">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{defaultIfEmpty(dataItem.expires | date:'MM/dd/yyyy')}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="status" title="Status" [sortable]="isSortable" filter="string" [width]="135"
          *ngIf="showStatus">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kehe-phoenix-tag [tag]="dataItem.statusTag" classAttr="large"></kehe-phoenix-tag>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="" filter="string" [width]="48">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="delete-action text-center tooltip-icon">
              <em title="Delete" class="fas fa-trash-alt cursor-pointer delete-icon"
                [ngClass]="{'disabled': shouldDeleteBeDisabled(dataItem) }"
                [title]="shouldDeleteBeDisabled(dataItem)? 'Delete (Disabled)': 'Delete'"
                (click)="deleteDocument(dataItem)"></em>
            </div>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoGridNoRecordsTemplate>
          <div class="custom-no-results-msg">
            <em class="fas fa-seedling mb-3" aria-hidden="true"></em>
            <div class="custom-msg-container">
              <span class="no-results mb-3">No Document{{ filterDocumentTypeId !== null ? '' : 's' }}</span>
            </div>
          </div>
        </ng-template>

      </kendo-grid>
    </div>
  </div>

  <app-upload-documents-modal [filterDocumentTypeId]="filterDocumentTypeId"
    [includedDocumentTypes]="includedDocumentTypes" *ngIf="vm.showUploadModel">
  </app-upload-documents-modal>
  <app-delete-document-modal *ngIf="vm.showDeleteModel">
  </app-delete-document-modal>
  <app-reject-document-modal *ngIf="vm.showRejectModel">
  </app-reject-document-modal>

</ng-container>
