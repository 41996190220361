<div>
  <div class="px-4">
    <div class="row">
      <div class="col">
        <h4>Supplier Information Form</h4>
      </div>
    </div>
    <div class="row kehe-data-section">
      <div class="col-12 kehe-data-value">
        As a part of your onboarding experience, KeHE's Supplier Optimization team wants to have a conversation
        with you regarding KeHE’s freight method options and general pricing structure so we can ensure that
        your product is launching at a landed cost that meets our collective expectations and to give you an idea
        of how pricing works at KeHE.
      </div>
      <div class="col-12 kehe-data-value mt-3">
        Download the Support Information Form (SIF) to get started. Once the legal and signatures tab has been
        completed and the required information within the SIF has been uploaded, you may schedule an appointment
        with KeHE's Supplier Optimization team.
        <p class="mt-3">
          <a (click)="downloadSIF()">Download Supplier Information Form</a>
        </p>
      </div>
      <div class="col-12 kehe-data-value mt-4">
        <h5>Step 1: Upload Partial Supplier Information Form</h5>
        After downloading the Supplier Information Form, fill out all sections that say
        <strong>"Complete Prior to Freight Meeting"</strong>, and upload the partially completed SIF to the table below.
      </div>
      <div class="col-12">
        <div class="row kehe-data-section">
          <app-documents-grid [includedDocumentTypes]="includedDocumentTypes" [showActionColumns]="false"
            [hasExpiry]="false" [isTabLocked]="isTabLocked" [showStatus]="false"
            [showError]="false"></app-documents-grid>
        </div>
      </div>
      <div class="appointment-container w-100 p-4 my-4 mx-3">
        <h5>Step 2: Meeting Details</h5>
        <p class="mb-3">Please enter the date set for supplier optimal freight method meeting.</p>

        <div class="d-flex">
          <span class="kehe-data-label me-3">DATE OF MEETING</span>
          <span class="font-size-13 ms-4">
            {{ supplier?.deliveryMethodMeeting?.meetingDate ? (supplier?.deliveryMethodMeeting?.meetingDate | date) :
            '--' }}
          </span>
        </div>
        <kehe-phoenix-button (click)="onAppointmentShow()" classAttr="btn-primary btn-medium mt-3">Edit
        </kehe-phoenix-button>
      </div>
      <div class="col-12 mb-5" *ngIf="supplier">
        <kendo-label text="Did you have conversations with the supplier to set up the optimal delivery method?">
        </kendo-label>
        <kendo-formhint class="mb-2">
          Selecting yes will enable the SIF upload for the Supplier.
        </kendo-formhint>
        <input type="checkbox" #canUploadSIFField kendoCheckBox [(ngModel)]="supplier.canUploadSIF"
          (change)="onValueChangeCanUploadSIF()" [disabled]="isSIFUploaded" />
        <kendo-label class="k-checkbox-label" [for]="canUploadSIFField"
          text="Yes, I've had conversations with the supplier.">
        </kendo-label>
      </div>
    </div>
  </div>
  <hr class="k-mt-0">
  <div class="px-4">
    <div class="row">
      <div class="col">
        <h4>Step 3: Upload Completed Supplier Information Form</h4>
      </div>
    </div>
    <div class="row kehe-data-section">
      <div class="col-12 mt-2">
        <div class="row">
          <div>
            <div class="col-12 kehe-data-value">
              After meeting with the KeHE's Supplier Optimization team, upload the completed Supplier Information Form
              below. Once uploaded, click the "Validate" button to submit the document to KeHE for review.
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row kehe-data-section">
          <app-documents-grid [filterDocumentTypeId]="supplierInfoDocumentTypeId"
            [requiredDocumentTypes]="[purchasingInfoDocumentTypeId]" [showActionColumns]="true" [hasExpiry]="false"
            [hideOptions]="hideSifOptions" [showStatus]="true" [disableUpload]="disableSIFUpload">
          </app-documents-grid>
        </div>
      </div>
    </div>
  </div>
  <app-schedule-appointment-modal *ngIf="showAppointmentModal" [loading]="appointmentLoading$ | async"
    [error]="appointmentFailed$ | async" [minDate]="supplier.created" (save)="onAppointmentSave($event)"
    (closeModal)="onAppointmentClose()"></app-schedule-appointment-modal>
</div>
