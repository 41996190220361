import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SupplierEffects } from './supplier.effects';
import { SupplierService } from './supplier.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([SupplierEffects]),
  ],
  providers: [SupplierService]
})
export class SupplierModule { }
