import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  hasUnsavedChanges,
  hasValidForm,
  SupplierDetailState,
} from './supplier-detail.reducer';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { getSupplierFieldName, isDraft, isInvitedDraftSupplier } from '../utilities/supplier-utils';
import { SupplierDetailTabs } from '../models/supplier-detail-tabs';
import { SupplierAction } from '../models/supplier-action';
import { ContactType } from '../../../models/contact';
import { ChecklistNavigationTab } from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';
import { BrokerDetail } from '../models/broker-detail';
import { SupplierDetail } from '../models/supplier-detail';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag';
import {OnboardingTabStatuses} from "@app/models/onboarding-tab-statuses";
import {OnBoardingStatus} from "@app/modules/supplier-detail/models/onboarding-status";

export const getSupplierDetailState =
  createFeatureSelector<SupplierDetailState>('supplierDetail');
export const selectCurrentTab = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.currentTab
);
export const selectdisplayTabPanel = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.displayTabPanel
);
export const selectESN = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.esn
);
export const selectSupplierDetail = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.detail
);
export const selectIsInvitedDraftSupplier = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => isInvitedDraftSupplier(state.detail)
);
export const selectSupplierCreatedTime = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.detail?.created
);
export const selectSupplierDetailContacts = createSelector(
  selectSupplierDetail,
  (state) => state?.contacts
)

export const selectSupplierDetailBroker = createSelector(
  selectSupplierDetail,
  (detail) => {
    return detail?.broker;
  }
);
export const selectSupplierDetailBrokerContact = createSelector(
  selectSupplierDetailBroker,
  (broker) => {
    return broker?.contact;
  }
);
export const selectSupplierBrokerEsn = createSelector(
  selectSupplierDetailBroker,
  (supplierBroker) => {
    return supplierBroker?.esn;
  }
);
export const selectSupplierHasHeadquarterBroker = createSelector(
  selectSupplierDetail,
  (detail) => detail?.hasHeadquarterBroker
);
export const selectSupplierEsn = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => {
    if (state?.detail) {
      return state.detail.esn;
    }

    return null;
  }
);
export const selectSupplierBroker = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.broker
);
export const selectRequestedRetailer = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.requestedRetailer
);
export const selectContacts = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) =>
    state?.detail && state?.detail?.contacts ? state?.detail?.contacts : []
);
export const selectTermsDropdownData = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.termsDropdownData
);
export const selectGeneralInfoIsLoading = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.generalInfoLoading
);
export const selectBrokerDetail = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.brokerDetail
);
export const selectBrokerDetailIsLoading = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.brokerDetailLoading
);
export const selectEditMode = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.editMode
);
// returns whether there are unsaved changes pending
// this might need to become more intelligent. If the user makes changes and reverts to
// old values, the form will still be dirty
export const selectUnsavedChangesPending = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => hasUnsavedChanges(state)
);
export const selectIsFormValid = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => hasValidForm(state)
);
export const selectShowUnsavedChangesModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.showUnsavedChangesModal
);
export const selectSaveInProgress = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.saveInProgress
);
export const selectSubmitInProgress = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.submitInProgress
);
export const selectAlertMessage = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.alertMessage
);
export const selectLeadTimeAlert = createSelector(
  getSupplierDetailState,
  (state) => state.leadTimeAlert
);

export const selectShowSupplierStatusChangeModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.showSupplierStatusChangeModal
);

export const getNewDCProductGroupFormIsDirty = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => hasUnsavedChanges(state)
);

export const getGeneralForm = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.generalForm
);

export const getTermsForm = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.termsForm
);

export const selectShowNewContractModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.showNewContractModal
);

export const getDcDetailsForm = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.dcSupplierForm
);

export const getProductGroupForm = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.productGroupForm
);

export const getNewProductGroupForm = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.newProductGroupForm
);

export const getDCProductGroupForm = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.productGroupDcForm
);

export const getBrands = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => {
    if (state?.detail) {
      return state.detail.brands;
    }
    return [];
  }
);

export const getOnProductGroupsDetailsView = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.onProductGroupsDetailsView
);

export const getEditDCProductGroup = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.editDCProductGroup
);

export const getSelectedProductGroupView = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.selectedProductGroupView
);

export const getEditNewAddDCProductGroup = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.editNewAddDCProductGroup
);

export const getNewDCProductGroup = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.newDCProductGroup
);

export const getRefinedDcSupplierList = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.refinedDcSupplierList
);
export const getDisplayDcSupplierFilterPanel = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.displayDcSupplierFilterPanel
);
export const getDcSupplierFilter = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.dcSupplierFilter
);
export const getDcSupplierChips = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.dcSupplierFilterChips
);
export const getDisplayProductGroupsFilterPanel = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.productGroupsFilterPanelDisplay
);
export const getHeadquarterAddressIsDirtyFlag = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.generalFormIsHeadquarterAddressDirty
);
export const getHeadquarterAddressValidationInProgress = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.validatingHeadquarterAddress
);
export const getRemittanceAddressIsDirtyFlag = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) =>
    state.productGroupRemittanceAddressDirty ||
    state.newProductGroupFormIsRemittanceAddressDirty
);
export const getRemittanceAddressValidationInProgress = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.validatingRemittanceAddress
);
export const hasRemittanceAddressContact = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) =>
    state?.detail?.contacts &&
    state?.detail?.contacts?.some(
      (item) => item?.contactType === ContactType.RemittanceAddress
    )
);
export const getGeneralFormIsValidFlag = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.generalFormIsValid
);
export const getTermFormIsValidFlag = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.termsFormIsValid
);
export const selectShowAddProductGroupModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.showAddProductGroupModal
);
export const selectBrokerForm = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.brokerForm
);
export const selectShowAddBrokerModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.showAddBrokerModal
);
export const selectShowRemoveBrokerModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.showRemoveBrokerModal
);
export const selectPaymentTermsList = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.paymentTermsList
);
export const selectMarketingAllowanceList = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.marketingAllowanceList
);
export const selectDeliveryMethodList = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.deliveryMethodList
);
export const selectSupplierHistoryTableData = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.historyTab.list
);
export const selectSupplierHistoryLoading = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.historyTab.isLoading
);
export const selectSupplierHistoryRecordsCount = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.historyTab.availableCount
);
export const selectSupplierHistoryPageSize = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.historyTab.filter.pageCount
);
export const selectSupplierHistoryPageIndex = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.historyTab.filter.pageIndex
);
export const selectSupplierHistoryModalConfig = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.historyTab.modalConfig
);
export const selectSupplierHistoryFilters = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => {
    return state.historyTab.filter;
  }
);
export const selectSupplierHistoryDisplayFilterPanel = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.historyTab.displayFilterPanel
);
export const selectSupplierHistoryChips = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.historyTab.chips
);
export const selectSupplierHistoryFilterEvents = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.filterEvents.data
);
export const selectSupplierHistoryFilterFields = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.filterFields.data
);

export const selectSupplierHistoryFilterEditedBy = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.filterEditedBy.data
);
export const selectBuyerList = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.buyerList
);
export const getSelectedDcSupplier = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => {
    return {
      selectedDc: state.selectedDcSupplierDc,
      selectedSupplierNumber: state.selectedDcSupplierNumber,
    };
  }
);
export const getPickupAddressIsDirtyFlag = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) =>
    state.productGroupDcFormIsPickupAddressDirty ||
    state.newProductGroupFormIsPickupAddressDirty
);
export const getPickupAddressValidationInProgress = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.validatingPickupAddress
);
export const selectShowNotesModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.showNotesModal
);
export const selectShowSubmitModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.showSubmitModal
);
export const selectTermsFormContractDate = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.termsForm.terms.contractDate
);
export const selectTermsContractDate = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => {
    if (state?.detail?.terms) {
      return state.detail.terms.contractDate;
    }
    return null;
  }
);
export const selectSupplierValidatedTabs = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.supplierValidatedTabs
);
export const selectIsSupplierReadyToSubmit = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) =>
    isSupplierReadyToSubmit(state.supplierValidatedTabs)
);
export const brandsSearch = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.brandsSearch
);

export const getFilteredBrands = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => {
    if (state?.detail) {
      return filterBrands(state.detail.brands || [], state.brandsSearch || '');
    }

    return [];
  }
);

export const selectValidationError = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => {
    if (state.validationErrors) {
      const requiredErrors = state.validationErrors.filter(
        (item) => item.error === 'required' || item.error === 'pattern'
      );
      if (requiredErrors.length > 0) {
        const errors = [];
        requiredErrors.forEach((option) => {
          errors.push(
            `<li>${getSupplierFieldName(option, state.currentTab)}</li>`
          );
        });
        const message = `You have required fields below that need your attention.${errors.join(
          ''
        )}`;
        return {
          type: AlertTypes.Error,
          message,
          show: true,
        };
      }
    }
    return {
      type: AlertTypes.Error,
      message: '',
      show: false,
    };
  }
);

// OnBoarding Status
export const selectOnBoardingStatus = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.onboardingStatus
);
export const selectOnBoardingStatusForTab = (tab: SupplierDetailTabs) => createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) =>
    state.onboardingStatus.find((item) => item.tab === tab)
);
export const isGeneralTabNotSubmitted = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => isTabNotSubmitted(state.onboardingStatus, SupplierDetailTabs.general)
);
export const selectOnBoardingStatusChangeAction = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.onboardingStatusChangeAction
);
export const showOnBoardingGeneralTabStatusModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) =>
    state.onboardingStatusChangeAction === SupplierAction.ApproveGeneralTab ||
    state.onboardingStatusChangeAction === SupplierAction.RejectGeneralTab ||
    state.onboardingStatusChangeAction === SupplierAction.ShowRevisionNotes
);
export const selectOnboardingStatusChangeLoading = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.loadingOnboardingStatusChange
);
export const selectOnboardingStatusChangeError = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.onboardingStatusChangeError
);
// OnBoarding Status

// Supplier Contact
export const selectIsSavingContact = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.isSavingContact
);
export const selectNewlyAddedContact = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.newlyAddedContact
);
export const selectSaveContactError = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.saveContactError
);
// Supplier Contact

// DocuSign
export const selectShowDocuSignSignerConfirmation = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.docuSignSigner !== null
);
export const selectDocuSignSigner = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.docuSignSigner
);
export const selectIsSendingDocuSign = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.isSendingDocuSign
);
export const selectSendDocuSignError = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.sendDocuSignError
);
// DocuSign

// Supplier Form (For Broker)
export const getSupplierForm = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.supplierForm
);

export const hasBrokerFormWarning = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) =>
    hasBrokerFormAlert(state.detail, state.supplierForm, state.broker)
);
// Supplier Form (For Broker)

// Delete Supplier
export const selectShowDeleteSupplierModal = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.showDeleteSupplierModal
);
export const selectDeleteSupplierError = createSelector(
  getSupplierDetailState,
  (state: SupplierDetailState) => state.deleteSupplierError
);
// Delete Supplier

// Appointment Scheduling
export const selectAppointmentSchedulingLoading = createSelector(
  getSupplierDetailState,
  (state) => state.appointmentSchedulingLoading
);

export const selectAppointmentSchedulingSuccess = createSelector(
  getSupplierDetailState,
  (state) => state.appointmentSchedulingSuccess
);

export const selectAppointmentSchedulingFailed = createSelector(
  getSupplierDetailState,
  (state) => state.appointmentSchedulingFailed
);
//

export const selectAddNoteFormValues = createSelector(
  getSupplierDetailState,
  (state) => state.addNotesFormValues
);

export const selectIsDraftSupplier = createSelector(
  selectSupplierDetail,
  (supplierDetail) => isDraft(supplierDetail)
)

export const selectPickupAddressColumnTitle = createSelector(
  selectIsFeatureFlagOn(FeatureFlags.ProductGroupDeliveryAddress.key),
  (deliveredAddressFlag) =>
    deliveredAddressFlag
      ? 'Del./Pickup Address'
      : 'Pickup Address'
);

function filterBrands(brands: { name: string; code: string; }[], search: string) {
  if (search.length > 0) {
    return brands.filter(
      (brand) =>
        (brand?.name?.toLowerCase()?.includes(search.toLowerCase())) ||
        (brand?.code?.toLowerCase()?.includes(search.toLowerCase()))
    );
  }
  return brands;
}

function isSupplierReadyToSubmit(supplierValidatedTabs: ChecklistNavigationTab[]) {
  return (
    supplierValidatedTabs.filter((item) => !item.optional && !item.completed)
      .length === 0
  );
}

function hasBrokerFormAlert(
  supplier: SupplierDetail,
  supplierForm: { hasHeadquarterBroker: boolean; broker: { esn: string; }; knowsHeadquarterBrokerContact: boolean; },
  broker: BrokerDetail
) {
  if (supplierForm) {
    return (
      supplierForm.hasHeadquarterBroker &&
      supplierForm.broker &&
      supplierForm.broker.esn &&
      supplierForm.knowsHeadquarterBrokerContact === false
    );
  } else {
    return (
      supplier?.hasHeadquarterBroker &&
      broker &&
      supplier?.knowsHeadquarterBrokerContact === false
    );
  }
}
// Utils
function getTabStatus(onboardingTabStatuses: OnBoardingStatus[], tab: SupplierDetailTabs) {
  const tabStatus = (onboardingTabStatuses || []).find(item => item.tab === tab);
  if (tabStatus) {
    return tabStatus.tabStatus;
  }
  return OnboardingTabStatuses.NotSubmitted;
}

function isTabNotSubmitted(onboardingTabStatuses: OnBoardingStatus[], tab: SupplierDetailTabs) {
  return getTabStatus(onboardingTabStatuses, tab) === OnboardingTabStatuses.NotSubmitted;
}
