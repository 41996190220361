import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../../app/abstract/destroyable';
import { environment } from '../../../../../environments/environment';
import { SupplierDetail } from '../../models/supplier-detail';
import { selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { isDraft } from '../../utilities/supplier-utils';

@Component({
  selector: 'app-contacts-tab',
  templateUrl: './contacts-tab.component.html',
  styleUrls: ['./contacts-tab.component.scss']
})
export class ContactsTabComponent extends Destroyable implements OnInit, Destroyable {

  supplier: SupplierDetail;

  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSupplierDetail))
      .subscribe((val) => {
        this.supplier = val;
      });
  }

  isDraft() {
    return isDraft(this.supplier);
  }

  goToContactsV1(): void {
    window.open(`${environment.v1ConnectHost}/vendormanagement/#/landingPage/vendorDetails/vendorContacts?esn=${this.supplier.esn}&userType=keheemployee`, '_blank');
  }
}
