<ng-container *ngIf="(viewModel$ | async) as vm">
    <kehe-phoenix-modal
        size="md"
        [modalTitle]="vm.modalTitle"
        [show]="true"
        (modalEvent)="handleModalEvent($event)"
    >
        <div body>
            Follow the instructions for creating the Flat File for BI Upload. <br>
            <br>
            <div class="ms-2" style="display:flex">
                <span>1.</span>
                <span class="ms-2">Export this File</span>
            </div>
            <br>
            <div class="ms-2" style="display:flex">
                <span>2.</span>
                <span class="ms-2">Save as "Customer Load File - W DC LABN.csv" File Type "CSV (Comma Delimited)(*.CSV)"</span>
            </div>
            <br>
            <div class="ms-2" style="display:flex">
                <span>3.</span>
                <span class="ms-2">Post File to K:Drive/Reports/RealBIFileImports/CustomerManagement"</span>
            </div>
        </div>

        <div footer class="d-flex justify-content-end">
            <kehe-phoenix-button
                class="modal-btn-spacing"
                classAttr="btn-medium btn-secondary"
                (click)="cancelModal()"
                [disabled]="vm.isExportInProgress"
            >
                Cancel
            </kehe-phoenix-button>
            <kehe-phoenix-button
                class="modal-btn-spacing"
                classAttr="btn-medium btn-primary"
                (click)="exportClicked()"
                [spinner]="vm.isExportInProgress"
            >
                Export
            </kehe-phoenix-button>
        </div>
    </kehe-phoenix-modal>
</ng-container>