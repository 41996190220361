import { createSelector } from '@ngrx/store';
import {
  selectIsAAPLegalSignaturesFeatureOn,
  selectOnBoardingStatus,
  selectSupplierValidatedTabs,
} from '../../store/supplier-detail.selectors';
import { isTabApproved } from '../../utilities/onboarding-utils';
import { SupplierDetailTabs } from '../../models/supplier-detail-tabs';
import {
  ChecklistNavigationTabStatus
} from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';

export const selectIsDocusignReady = createSelector(
  selectOnBoardingStatus,
  selectSupplierValidatedTabs,
  selectIsAAPLegalSignaturesFeatureOn,
  (onboardingStatuses, validatedTabs, ffAAPLegalSignatures) => {
    if (ffAAPLegalSignatures) {
      const isTermsApproved = validatedTabs?.find(t => t.tab === SupplierDetailTabs.supplierTerms)?.status ===
                              ChecklistNavigationTabStatus.checked
      const isGeneralTabApproved = isTabApproved(
        onboardingStatuses,
        SupplierDetailTabs.general,
      );
      return isGeneralTabApproved && isTermsApproved;
    }
    return isTabApproved(onboardingStatuses, SupplierDetailTabs.general);
  },
);
