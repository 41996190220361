import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IKeheModalEvent } from '@kehe/phoenix-modal';

@Component({
  selector: 'app-pickup-address-notes-modal',
  templateUrl: './pickup-address-notes-modal.component.html',
  styleUrls: ['./pickup-address-notes-modal.component.scss']
})
export class PickupAddressNotesModalComponent implements OnInit {

  @Input() title = 'Pickup Address Notes';

  @Input() pickupAddressNotes: any;

  @Output() closeNotesButtonClicked = new EventEmitter<void>();

  public notes = '';

  constructor() {
     // This is intentional
  }

  ngOnInit() {
    this.notes = this.pickupAddressNotes;
  }

  handleKeHEModalEvents($event: IKeheModalEvent) {
    if ($event.type === 'close') {
      this.closeNotesButtonClicked.emit();
    }
  }

  closeModal() {
    this.closeNotesButtonClicked.emit();
  }

}
