import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payload } from '../../../models/payload';
import { environment } from '../../../../environments/environment';
import { delay, map, retry, retryWhen, take } from 'rxjs/operators';
import { SupplierDetail } from '../../supplier-detail/models/supplier-detail';
import { IAuditDetail } from '@kehe/phoenix-audit-history';
import { convertToEasternTime } from '@app/shared/utils/time-zone-util';
import { SupplierHistoryFilterToHttpParams } from './supplier-filter-parser';
import { SupplierHistoryFilterItem } from '../models/supplier-history-filter';
import { SupplierHistoryFilter } from '../store/supplier-detail.reducer';
import { formatChangesAsString } from '@app/shared/utils/audit-history-util';
import { TradingPartner } from '../models/trading-partner';
import { SupplierStatus } from '../models/supplier-status';
import { Supplier } from '../models/supplier';
import { OnBoardingStatus } from '../models/onboarding-status';
import { convertToSupplierDetails } from '../utilities/supplier-utils';

@Injectable({
  providedIn: 'root'
})
export class SupplierDetailService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.supplierApi;
  }

  getSupplierDetail(esn: string): Observable<SupplierDetail> {
    return this.httpClient
      .get<{ availableCount: number; data: SupplierDetail }>(
        `${environment.supplierEnterprise}/${esn}`
      )
      .pipe(retryWhen(errors => errors.pipe(delay(5000), take(3))), map((response) => response.data));
  }

  save(form): Observable<SupplierDetail> {
    const tmpForm = {
      ...form,
      updatedBySource: 'Enterprise'
    };

    return this.httpClient
      .patch<Supplier>(`${environment.supplierApi}/api/${tmpForm.esn}`, tmpForm)
      .pipe(
        map((response) => {
          return convertToSupplierDetails(response);
        })
      );
  }

  saveTerms(form): Observable<SupplierDetail> {
    const tmpForm = {
      ...form.terms,
      auditNotes: form.auditNotes,
      supplierType: form.supplierType,
      updatedBySource: 'Enterprise'
    };
    return this.httpClient.patch<{ data: Supplier}>(`${environment.supplierApi}/api/terms/${form.esn}`, tmpForm).pipe(map((response) => {
      return convertToSupplierDetails(response.data);
    }));
  }

  submit(supplier: SupplierDetail): Observable<SupplierDetail> {
    const tmpForm = {
      esn: supplier.esn,
      status: SupplierStatus.active,
      updatedBySource: 'Enterprise'
    };
    delete tmpForm['productGroups'];
    return this.httpClient.patch<SupplierDetail>(`${environment.supplierApi}/api/${tmpForm.esn}`, tmpForm);
  }

  getCombinedAuditHistory(esn: string, filter: SupplierHistoryFilter, aggregatedLogFF: boolean): Observable<Payload<Array<IAuditDetail>>> {
    const params = SupplierHistoryFilterToHttpParams(filter, esn);
    const auditEndPoint = aggregatedLogFF ? 'combinedListV2' : 'combinedList';
    return this.httpClient
      .get<Payload<Array<IAuditDetail>>>(
        `${this._url}/api/audit/${auditEndPoint}`,
        {
          params: params,
        }
      )
      .pipe(retry(2), map((data) => {
        return this.getMappedAuditHistory(data);
        })
      );
  }

  getMappedAuditHistory(data: Payload<IAuditDetail[]>): Payload<IAuditDetail[]> {
    data.data.forEach((row) => {
      row.created = convertToEasternTime(row.created);

      row.changes.forEach((change) => {
        if (typeof change.originalValue === 'object') {
          change.originalValue = formatChangesAsString(change.originalValue);
        }
        if (change.originalValue === undefined) {
          change.originalValue = '--';
        }
        if (typeof change.newValue === 'object') {
          change.newValue = formatChangesAsString(change.newValue);
        }
        if (change.newValue === undefined) {
          change.newValue = '--';
        }
      });
    });
    return data;
  }

  getHistoryFilterFields(): Observable<
    Payload<Array<SupplierHistoryFilterItem>>
    > {
    return this.httpClient
      .get<Payload<Array<SupplierHistoryFilterItem>>>(`${this._url}/api/audit/fields`)
      .pipe(retry(2));
  }

  getHistoryFilterEditedBy(esn: string): Observable<Payload<Array<SupplierHistoryFilterItem>>> {
    return this.httpClient
      .get<Payload<Array<SupplierHistoryFilterItem>>>(`${this._url}/api/audit/createdBy`,
        {
          params: { 'key': esn },
        })
      .pipe(retry(2));
  }

  getTradingPartners(search: string): Observable<Payload<Array<TradingPartner>>> {
    return this.httpClient
      .get<Payload<Array<TradingPartner>>>(`${this._url}/trading-partners`,
        {
          params: { 'search': search },
        })
      .pipe(retry(2), map((payload) => {
        payload.data.forEach(tradingPartner => {
          tradingPartner.name = `${tradingPartner.id} - ${tradingPartner.name}`;
        });
        return payload;
      }));
  }

  getKeheTransitTimes(dc: string, state: string, delivery: string): Observable<Payload<any>> {
    return this.httpClient
      .get<Payload<Array<any>>>(`${this._url}/kehe-transit-times`,
        {
          params: { dc, state, delivery },
        })
      .pipe(retry(2));
  }

  getKeheZipTransitTimes(dc: string, zipCode: string, inboundRouteType: string): Observable<any> {
    return this.httpClient
      .get<Payload<Array<any>>>(`${this._url}/api/enterprise/kehe-zip-transit-times`,
        {
          params: { dc, zipCode, inboundRouteType },
        })
      .pipe(retry(2));
  }

  rejectReview(esn: string): Observable<any> {
    const request = {
      esn
    };
    return this.httpClient.put<any>(`${environment.supplierApi}/api/reject-review`, request);
  }

  // OnBoarding Status
  getSupplierOnboardingStatus(esn: string): Observable<OnBoardingStatus[]> {
    return this.httpClient
      .get<OnBoardingStatus[]>(
        `${environment.supplierApi}/api/onboarding/status/${esn}`
      )
      .pipe(retry(1));
  }

  updateOnBoardingStatus(esn: string, status: OnBoardingStatus): Observable<OnBoardingStatus> {
    return this.httpClient.post<any>(`${environment.supplierApi}/api/onboarding/upsert-status/${esn}`, status)
      .pipe(retry(1), map((response) => status));
  }
  // OnBoarding Status

  // Supplier Contact
  saveContact(form): Observable<SupplierDetail> {
    const tmpForm = {
      ...form,
    };
    return this.httpClient.patch<Supplier>(`${environment.supplierApi}/api/${tmpForm.esn}/contacts`, tmpForm).pipe(map((response) => {
      return convertToSupplierDetails(response);
    }));
  }
  // Supplier Contact

  delete(esn: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.supplierApi}/api/invited/${esn}`);
  }

  refreshTerms(esn: string) {
    return this.httpClient.patch(`${environment.supplierApi}/api/termsrefresh/${esn}`, {});
  }
}
