import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BrokerGridRow, BrokerGridRowType } from '../broker-item.model';
import {
  BrokerTabGridStore,
  DeleteBrokerRow,
} from './broker-tab-v2-grid.store';
import { Document } from '../../../../supplier-documents/models/document';
import { KeheErrorConfig, KeheErrorSizes, KeheMascotStates, KeheErrorMessages } from '@kehe/phoenix-errors';

@Component({
  selector: 'app-broker-tab-v2-grid',
  templateUrl: './broker-tab-v2-grid.component.html',
  styleUrls: ['./broker-tab-v2-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrokerTabV2GridComponent {
  vm$ = this._store.vm$;
  public notLoadingConfig = new KeheErrorConfig(
    KeheErrorSizes.LG,
    KeheMascotStates.Error,
    null,
    KeheErrorMessages.Error,
    null
  );

  constructor(private _store: BrokerTabGridStore) {}

  isHeadQuarterRow(brokerGridRow: BrokerGridRow) {
    return brokerGridRow.type === BrokerGridRowType.Headquarter;
  }

  isBrokerOfRecordForm(brokerGridRow: BrokerGridRow) {
    return brokerGridRow.type === BrokerGridRowType.BrokerOfRecordForm;
  }

  refreshPage() {
    window.location.reload();
  }

  deleteRowClicked(row: BrokerGridRow) {
    const rowToDelete: DeleteBrokerRow = {
      id:
        row.type === BrokerGridRowType.BrokerOfRecordForm
          ? row.document.filename
          : row.ebn,
      type: row.type,
      brokerName: row.brokerName,
      document: row.document,
    };

    this._store.showDeleteConfirmationModal(rowToDelete);
  }

  downloadHyperlinkClicked(document: Document) {
    this._store.downloadBrokerOfRecordForm(document);
  }
}
