import { isNullOrUndefined } from '../../../../app/common/common-methods';
import { HttpParams } from '@angular/common/http';
import { ProductHistoryFilter } from '../store/product-detail.state';

export function ProductHistoryFilterToHttpParams(
  filters: ProductHistoryFilter
): HttpParams {
  let params = new HttpParams()
    .set('search', filters.search)
    .set('pagecount', filters.pageCount.toString())
    .set('pageindex', filters.pageIndex.toString())
    .set('orderdirection', filters.orderDirection.toString())
    .set('includeavailablecount', filters.includeAvailableCount.toString());

  if (!isNullOrUndefined(filters.selectedEvent)) {
    params = params.append('event', filters.selectedEvent.code);
  }

  if (!isNullOrUndefined(filters.selectedField)) {
    filters.selectedField.forEach(field => {
      params = params.append('field', field.code);
    });
  }

  if (!isNullOrUndefined(filters.selectedEditedBy)) {
    filters.selectedEditedBy.forEach(editedBy => {
      params = params.append('createdby', editedBy.code);
    });
  }

  if (!isNullOrUndefined(filters.selectedDC)) {
    params = params.append('propertySearch', filters.selectedDC.codeValue);
  }

  if (
    !isNullOrUndefined(filters.createdFrom) &&
    !isNullOrUndefined(filters.createdTo)
  ) {
    params = params.append('mindate', filters.createdFrom);
    params = params.append('maxdate', filters.createdTo);
  }
  return params;
}
