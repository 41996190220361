<h5 class="mt-4">Pickup Contact</h5>
<div *ngIf="form" [formGroup]="form">
  <div class="d-flex">
    <div class="pickup-phone">
      <kehe-phoenix-phone-number-input label="Phone" formControlName="phone" [required]="true"
        errorRequired="Phone is required."></kehe-phoenix-phone-number-input>
    </div>
    <span class="me-2"></span>
    <kendo-formfield>
      <kendo-label [for]="phoneExtensionField" text="Ext"></kendo-label>
      <kendo-maskedtextbox #phoneExtensionField formControlName="phoneExtension" [maskValidation]="false" [prompt]="''"
        [mask]="mask" style="max-width: 75px !important;">
      </kendo-maskedtextbox>
    </kendo-formfield>
  </div>

  <div class="d-flex">
    <kehe-phoenix-phone-number-input formControlName="fax" label="Fax (optional)"></kehe-phoenix-phone-number-input>
    <span class="me-2"></span>
    <div style="width: 75px !important;">
    </div>
  </div>

  <kendo-formfield>
    <kendo-label [for]="pickupEmailField" text="Email (optional)"></kendo-label>
    <kendo-textbox #pickupEmailField formControlName="email" placeholder="e.g. contact@email.com">
    </kendo-textbox>
    <kendo-formerror *ngIf="pickupEmail?.errors?.email">Invalid email</kendo-formerror>
  </kendo-formfield>
</div>