import { IChip } from '@kehe/phoenix-chips';
import { createAction, props } from '@ngrx/store';
import { DCPGListFilter } from '../models/dcpg-list-filter';
import { DCPGListItem } from '../models/dcpg-list-item';
import { DCPGEditResult } from '../models/dcpg-edit-result';
import { DistributionCenter } from '@app/distribution-center/distribution-center';

export const landSupplierDCPGList = createAction(
  '[Supplier DCPGs]: Click Supplier List DCPG Option'
);

export const loadDCPGList = createAction(
  '[Supplier DCPGs]: Load DCPG List',
);

export const loadDCPGListSuccessful = createAction(
  '[Supplier DCPGs]: Load DCPG List Successful',
  props<{
    availableCount: number;
    data: DCPGListItem[];
    // dcList is for calculating derived properties in reducer
    dcList: DistributionCenter[];
  }>()
);

export const loadDCPGListFailed = createAction(
  '[Supplier DCPGs]: Load DCPG List Failed',
  props<{ error: string }>()
);

export const clickDCPGPageChange = createAction(
  '[Supplier DCPGs]: Click DCPG Page Change',
  props<{ filter: DCPGListFilter }>(),
);

export const clickDCPGSortChange = createAction(
  '[Supplier DCPGs]: Click DCPG Sort Change',
  props<{ filter: DCPGListFilter }>(),
);

export const enterDCPGSearchChange = createAction(
  '[Supplier DCPGs]: Enter DCPG Search Change',
  props<{ filter: DCPGListFilter }>(),
)

export const dcpgFilterUpdate = createAction(
  '[Supplier DCPGs]: DCPG Filter Update',
);

export const clickDCPGFilterPanel = createAction(
  '[Supplier DCPGs]: Click DCPG Filter Panel'
);

export const closeDCPGFilterPanel = createAction(
  '[Supplier DCPGs]: Close DCPG Filter Panel'
);

export const applyDCPGFilterPanel = createAction(
  '[Supplier DCPGs]: Apply DCPG Filter Panel',
  props<{ filter: DCPGListFilter }>(),
);

export const clickDCPGFilterChip = createAction(
  '[Supplier DCPGs]: Click DCPG Filter Chip',
  props<{ chip: IChip; }>()
);

export const clickDCPGClearChips = createAction(
  '[SupplierDCPGs]: Click DCPG Clear Chips'
);


// Bulk Update

export const showBulkUpdateDCPGPOEmailsModal = createAction(
  '[Supplier DCPGs]: Show Bulk Update DCPG PO Emails Modal',
);

export const hideBulkUpdateDCPGPOEmailsModal = createAction(
  '[Supplier DCPGs]: Hide Bulk Update DCPG PO Emails Modal',
);

export const updateDCPGPOEmails = createAction(
  '[Supplier DCPGs]: Update DCPG PO Emails',
  props<{
    payload: {
      targetDcProductGroups: any[],
      additions: any[],
      removals: any[],
      auditNotes: string,
    };
  }>()
);

export const updateDCPGPOEmailsSuccess = createAction(
  '[Supplier DCPGs]: Update DCPG PO Emails Success',
  props<{ data: DCPGEditResult[] }>()
);

export const updateDCPGPOEmailsError = createAction(
  '[Supplier DCPGs]: Update DCPG PO Emails Error',
  props<{ error: string }>()
);

// Bulk Update
