import { IChip } from '@kehe/phoenix-chips';
import { DropdownOption } from '../../../models/dropdown-option';
import { ProductGroup } from '../../supplier-detail/models/product-group';
import { ProductGroupsFilter } from '../../supplier-detail/models/product-groups-filter';
import { cleanAddress } from '../../../utils/address-utils';
import { Contact } from '../../../models/contact';
import { Product } from '../models/product';
import { Brand } from '../../product-detail/models/brand';
import { DCProductGroup } from '../../supplier-detail/models/dc-product-group';
import { DCStatus } from '../models/products-dc-status';
import { cloneDeep } from 'lodash';
import { ProductGroupFilterType } from '../models/product-group-filter-type';
import { DeliveryMethod } from '../../product-detail/models/new-product-delivery-method';
import { Constants } from '../../../constants/constants';
import { DeliveryMethodCodes } from '../models/delivery-method-codes';

// Products Data
export function prepareProductsData(
  productGroups: ProductGroup[],
  products: any[]
) {
  if (
    productGroups &&
    productGroups.length > 0 &&
    products &&
    products.length > 0
  ) {
    productGroups.forEach((pg) => {
      pg.dcProductGroups.forEach((dcPg) => {
        dcPg.products = getUniqueProducts(
          filterProductsForDCPG(pg, dcPg, products)
        );
        pg.products = [...(pg.products ?? []), ...dcPg.products ];
        dcPg.productBrands = getUniqueBrands(dcPg.products);
      });
      pg.products = getUniqueProducts(pg.products);
      pg.brands = getUniqueBrands(pg.products);
    });
  }
  return productGroups;
}

function filterProductsForDCPG(
  pg: ProductGroup,
  dcPg: DCProductGroup,
  products: any[]
): Product[] {
  const list: Product[] = [];
  products.forEach((product) => {
    const pgMatch = product.productGroups.find(
      (pgItem) => +pg.productGroupNumber === +pgItem.productGroupNumber
    );
    if (pgMatch) {
      const dcPGFound = pgMatch.dcProductGroups.find(
        (dcpgItem) =>
          +dcpgItem.dcProductGroupNumber === +dcPg.dcProductGroupNumber &&
          +dcpgItem.dcNumber === +dcPg.distributionCenterNumber
      );
      if (dcPGFound) {
        list.push({
          upc: product.upc,
          uom: product.uom,
          size: product.size,
          name: product.name,
          brandCode: product.brandCode,
          brandName: product.brandName,
          costFactor: dcPGFound.costFactor,
          tempClass: dcPGFound.tempClass,
          status: dcPGFound.status,
        });
      }
    }
  });
  return list;
}

function getUniqueBrands(products: Product[]): Brand[] {
  if (products.length > 0) {
    const arrayUniqueByKey = [
      ...new Map(
        products.map((item) => [
          item['brandCode'],
          { name: item.brandName, code: item.brandCode },
        ])
      ).values(),
    ];
    return arrayUniqueByKey;
  }
  return [];
}

function getUniqueProducts(products: Product[]): Product[] {
  if (products?.length > 0) {
    const arrayUniqueByKey = [
      ...new Map(products.map((item) => [item['upc'], item])).values(),
    ];
    return arrayUniqueByKey;
  }
  return [];
}
// Products Data

// Product Groups
export function prepareProductGroups(
  productGroups: ProductGroup[],
  dcList: Array<DropdownOption>,
  contacts: Contact[]
) {
  if (typeof productGroups === 'undefined') {
    productGroups = [];
  }
  if (typeof dcList === 'undefined') {
    dcList = [];
  }
  const dcNameMap = prepDCList(dcList).reduce(function (map, obj) {
    map[obj.value] = obj.label;
    return map;
  }, {});
  productGroups.forEach((group) => {
    group.remittanceAddress = cleanAddress(group.remittanceAddress);

    if (group.remittanceAddressId && contacts) {
      group.remittanceAddressContact = contacts.find(
        (item) => item.uniqueId === group.remittanceAddressId
      );
    } else {
      group.remittanceAddressContact = null;
    }
    prepareDCProductGroups(group, dcNameMap, contacts);
  });
  return productGroups;
}

function prepareDCProductGroups(group, dcNameMap, contacts) {
  group.dcProductGroups.forEach((dcGroup) => {
    if (dcGroup.distributionCenterNumber) {
      const dcName = dcNameMap[dcGroup.distributionCenterNumber];
      dcGroup.dcDisplayName = dcName ? dcName : '--';
    }
    dcGroup.pickupAddress = cleanAddress(dcGroup.pickupAddress);
    if (dcGroup.pickupAddressId && contacts) {
      dcGroup.pickupAddressContact = contacts.find(
        (item) => item.uniqueId === dcGroup.pickupAddressId
      );
    } else {
      dcGroup.pickupAddressContact = null;
    }

    if (dcGroup.poFax && dcGroup.poFax.fax) {
      dcGroup.poFax.fax = dcGroup.poFax.fax.trim();
    }
  });
  group.dcProductGroups = group.dcProductGroups.filter((dcGroup) =>
    dcGroup.distributionCenterNumber
      ? !Constants.ExcludedDCs.includes(+dcGroup.distributionCenterNumber)
      : true
  );
}

export function updateDeliveryMethodForDCProductGroups(
  productGroups: ProductGroup[],
  deliveryMethodList: any[]
) {
  const deliveryMethodMap = deliveryMethodList.reduce(function (map, obj) {
    map[obj.value] = obj.label;
    return map;
  }, {});
  productGroups.forEach((pg) => {
    pg.dcProductGroups.forEach((dc) => {
      if (dc.deliveryMethodCode && dc.deliveryMethodCode.length > 0) {
        dc.deliveryMethod =
          deliveryMethodMap[dc.deliveryMethodCode] || dc.deliveryMethod;
      }
    });
  });
  return productGroups;
}

export function isProductGroupsFilterEmpty(filter: ProductGroupsFilter, search: string): boolean {
  return !(
    filter.dcSelection?.length > 0 ||
    filter.tempClass?.trim().length > 0 ||
    filter.brand ||
    filter.buyer ||
    filter.deliveryMethod ||
    filter.inboundRoundTypes?.length > 0 ||
    search?.length > 0
  );
}

export function filterProductGroups(
  productGroups: ProductGroup[],
  filter: ProductGroupsFilter,
  search: string,
) {
  if (isProductGroupsFilterEmpty(filter, search)) {
    return productGroups;
  }
  const list = cloneDeep(productGroups);
  list.forEach((pg) => {
    pg.dcProductGroups = filterDCPG(pg.dcProductGroups, filter, search);
  });
  return list.filter((pg) => pg.dcProductGroups?.length > 0);
}

function filterDCPG(
  dcProductGroups: DCProductGroup[],
  filter: ProductGroupsFilter,
  searchText: string
) {
  return dcProductGroups.filter((dcPG) => {
    return (
      isDCPGFilterMatch(dcPG, filter) && isDCPGSearchMatch(dcPG, searchText)
    );
  });
}

function isDCPGFilterMatch(dcPG: DCProductGroup, filter: ProductGroupsFilter) {
  return (
    isDCMatch(dcPG, filter) &&
    isTempClassMatch(dcPG, filter) &&
    isBrandsMatch(dcPG, filter) &&
    isBuyerMatch(dcPG, filter) &&
    isDeliveryMethodMatch(dcPG, filter) &&
    isInboundRouteTypeMatch(dcPG, filter)
  );
}

function isDCMatch(dcPG: DCProductGroup, filter: ProductGroupsFilter) {
  if (filter.dcSelection && filter.dcSelection.length > 0) {
    return filter.dcSelection
      .map((item) => item.value)
      .some((item) => item === dcPG.distributionCenterNumber);
  }
  return true;
}

function isTempClassMatch(dcPG: DCProductGroup, filter: ProductGroupsFilter) {
  if (filter.tempClass && filter.tempClass.length > 0) {
    return dcPG.products?.some(
      (product) =>
        product.tempClass?.toLowerCase() === filter.tempClass.toLowerCase()
    );
  }
  return true;
}

function isBrandsMatch(dcPG: DCProductGroup, filter: ProductGroupsFilter) {
  if (filter.brand) {
    return dcPG.productBrands?.some(
      (brand) => brand.code?.toLowerCase() === filter.brand?.code?.toLowerCase()
    );
  }
  return true;
}

function isBuyerMatch(dcPG: DCProductGroup, filter: ProductGroupsFilter) {
  if (filter.buyer) {
    return dcPG.buyerCode === filter.buyer?.code;
  }
  return true;
}

function isDeliveryMethodMatch(dcPG: DCProductGroup, filter: ProductGroupsFilter) {
  if (filter.deliveryMethod) {
    return dcPG.deliveryMethodCode === filter.deliveryMethod?.value;
  }
  return true;
}

function isInboundRouteTypeMatch(dcPG: DCProductGroup, filter: ProductGroupsFilter) {
  if (filter?.inboundRoundTypes?.length > 0) {
    return dcPG.isZipTransitTime && dcPG.deliveryMethodCode !== DeliveryMethodCodes.Delivered && filter.inboundRoundTypes
      .map((item) => item.value)
      .some((item) => item === dcPG.inboundRouteType);
  }
  return true;
}

function isDCPGSearchMatch(dcPG: DCProductGroup, searchText: string) {
  if (searchText && searchText.length > 0) {
    const searchItems = [];
    searchItems.push(dcPG.dcProductGroupNumber);
    searchItems.push(dcPG.distributionCenterNumber);

    if (dcPG.deliveryMethod !== DeliveryMethod.Delivered) {
      searchItems.push(dcPG.pickupAddress?.state?.code);
      searchItems.push(dcPG.pickupAddress?.state?.name);
      searchItems.push(dcPG.pickupAddress?.zipCode);
    }

    if (dcPG.productBrands) {
      searchItems.push.apply(
        searchItems,
        dcPG.productBrands.map((brand) => brand.name)
      );
    }
    if (dcPG.products) {
      searchItems.push.apply(
        searchItems,
        dcPG.products.flatMap((product) => [product.tempClass, product.upc])
      );
    }
    return searchItems
      .join(' ')
      .toLowerCase()
      .includes(searchText.toLowerCase().trim());
  } else {
    return true;
  }
}

export function createProductGroupFilterChipsList(
  filter: ProductGroupsFilter
): IChip[] {
  const chips: IChip[] = [];
  if (filter.dcSelection) {
    chips.push.apply(
      chips,
      filter.dcSelection.map((item) => ({
        text: item.label,
        extraPayload: ProductGroupFilterType.DC,
      }))
    );
  }
  if (filter.brand) {
    chips.push({
      text: filter.brand.name,
      extraPayload: ProductGroupFilterType.Brands,
    });
  }
  if (filter.tempClass && filter.tempClass.length > 0) {
    chips.push({
      text: filter.tempClass,
      extraPayload: ProductGroupFilterType.TempClass,
    });
  }
  if (filter.buyer) {
    chips.push({
      text: filter.buyer.name,
      extraPayload: ProductGroupFilterType.Buyer,
    });
  }
  if (filter.deliveryMethod) {
    chips.push({
      text: filter.deliveryMethod.label,
      extraPayload: ProductGroupFilterType.DeliveryMethod,
    });
  }
  if (filter.inboundRoundTypes) {
    chips.push.apply(
      chips,
      filter.inboundRoundTypes.map((item) => ({
        text: item.label,
        extraPayload: ProductGroupFilterType.InboundRouteType,
      }))
    );
  }
  return chips;
}

export function prepDCList(dcList: DropdownOption[]) {
  dcList.forEach((dc) => {
    const addCode = dc.label.indexOf(dc.value) === -1;
    if (addCode) {
      dc.label = `${dc.label} (${dc.value})`;
    }
  });
  return dcList;
}

export function updateProductGroupsChips(
  filter: ProductGroupsFilter,
  chip: IChip
): ProductGroupsFilter {
  switch (chip.extraPayload) {
    case ProductGroupFilterType.DC:
      return {
        ...filter,
        dcSelection: filter.dcSelection.filter(
          (item) => item.label !== chip.text
        ),
      };
    case ProductGroupFilterType.Brands:
      return {
        ...filter,
        brand: null,
      };
    case ProductGroupFilterType.TempClass:
      return {
        ...filter,
        tempClass: null,
      };
    case ProductGroupFilterType.Buyer:
      return {
        ...filter,
        buyer: null,
      };
    case ProductGroupFilterType.DeliveryMethod:
      return {
        ...filter,
        deliveryMethod: null,
      };
    case ProductGroupFilterType.InboundRouteType:
      return {
        ...filter,
        inboundRoundTypes: filter.inboundRoundTypes.filter(
          (item) => item.label !== chip.text
        ),
      };
  }
}
// Product Groups

// Color Code
export function getDCStatusITagColor(status: DCStatus): string {
  switch (status) {
    case DCStatus.InActive:
    case DCStatus.ManufactureDiscNoInventory:
    case DCStatus.KeheDiscNoInventory:
      return '#E2E2E2';
    case DCStatus.Active:
    case DCStatus.New:
      return '#D5E48F';
    case DCStatus.PendingDisc:
    case DCStatus.PendingDiscSeasonal:
    case DCStatus.PendingDiscShortShipped:
      return '#F7B288';
    case DCStatus.ActiveSeasonal:
    case DCStatus.NewSeasonal:
      return '#E3BEA2';
    case DCStatus.ActiveShortShipped:
    case DCStatus.NewShortShipped:
      return '#C2D5E4';
    case DCStatus.ManufactureOOSEveryDay:
    case DCStatus.ManufactureOOSOther:
    case DCStatus.PendingActivation:
    case DCStatus.StagedForPricing:
      return '#FAD981';
    default:
      return '';
  }
}
// Color Code

// Buyer
export function prepareBuyers(buyersList: any[]) {
  const modifiedBuyerList = [];
  buyersList.forEach((buyer) => {
    const newBuyer = Object.assign({}, buyer);
    const addCode = newBuyer.name.indexOf(newBuyer.code) === -1;
    if (addCode) {
      const updatedName = `${newBuyer.name} (${newBuyer.code})`;
      newBuyer.name = updatedName;
    }
    modifiedBuyerList.push(newBuyer);
  });
  return modifiedBuyerList;
}
// Buyer

export function isDraftDCPG(dataItem: DCProductGroup): boolean {
  return !dataItem.buyerCode || !dataItem.deliveryMethod;
}
