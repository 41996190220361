import { Injectable } from '@angular/core';
import { SpoilsTypes } from '../models/spoils-types';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TermsDropdownData } from '../models/terms-dropdown-data';

@Injectable({
  providedIn: 'root'
})
export class SupplierTermsDropdownsService {

  private readonly _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.supplierApi;
  }

  getTermsDropdownLists(): Observable<TermsDropdownData[]> {
    return this.httpClient
      .get<TermsDropdownData[]>(
        `${this._url}/api/terms-dropdown-lists`
      );
  }

  getSpoilsDropdownList() {
    return [
      { value: SpoilsTypes.fullCoverage, label: 'Full Coverage' },
      { value: SpoilsTypes.noSupport, label: 'No Support' },
      { value: SpoilsTypes.spoilageAllowance, label: 'Spoilage Allowance' }
    ];
  }
}
