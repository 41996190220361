import { Injectable } from '@angular/core';

@Injectable()
export class PriceChangesService {

  constructor() {  
     // This is intentional
  }

}
