import { Address } from '../../../models/address';

export class SupplierGeneralFormModel {
  name: string;
  status: string;
  doingBusinessAs: string;
  categoryManager: any;
  supplierDevelopmentManager: any;
  headquarterAddress: Address;
  remittanceAddress: Address;
  isEdi: boolean;
  tradingPartnerId: string;
  auditNotes?: string;
}
