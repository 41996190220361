import { createAction, props } from '@ngrx/store';

import { IAnnouncementUpdate } from '../../interfaces/announcement-update.interface';
import { IAnnouncementDocument } from '../../../interfaces/announcement-document.interface';
import { ICreateDocuments } from '../../interfaces/upload-documents.interface';

export const announcementEditPageOpened = createAction(
  '[Announcement Edit Page] Page Opened',
  props<{ id: number }>()
);

export const update = createAction(
  '[Announcement Edit Page] Update',
  props<{ id: number, data: IAnnouncementUpdate }>()
);

export const announcementEditPageReset = createAction(
  '[Announcement Edit Page] Reset'
);

export const getUploadRestrictions = createAction(
  '[Announcement Edit Page] Get Upload Restrictions'
);

export const openUploadFilesModal = createAction(
  '[Announcement Edit Page] Open Upload Files Modal'
);

export const closeUploadFilesModal = createAction(
  '[Announcement Edit Page] Close Upload Files Modal'
);

export const createDocuments = createAction(
  '[Announcement Edit Page] Create Documents',
  props<{ data: ICreateDocuments }>()
);

export const deleteFile = createAction(
  '[Announcement Edit Page] Delete File',
  props<{ data: IAnnouncementDocument }>()
);

export const confirmPendingDeleteFile = createAction(
  '[Announcement Edit Page] Confirm Pending Delete File'
);

export const cancelPendingDeleteFile = createAction(
  '[Announcement Edit Page] Cancel Pending Delete File'
);
