import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Signer } from '../../supplier-detail/models/signer';
import { SupplierDetail } from '../../supplier-detail/models/supplier-detail';
import { Supplier } from '../../supplier-detail/models/supplier';
import { convertToSupplierDetails } from '../utilities/supplier-utils';

@Injectable({
  providedIn: 'root'
})
export class SupplierDocuSignService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.supplierApi + `/api/docusign`;
  }

  sendDocuSignEmail(esn: string, request: Signer): Observable<SupplierDetail> {
    return this.httpClient.post<Supplier>(`${this._url}/send-onboarding-packet/${esn}`, request)
      .pipe(map((response) => {
        return convertToSupplierDetails(response);
    }));
  }
}
