import { createAction, props } from '@ngrx/store';
import { ProductGroupsFilter } from '../../supplier-detail/models/product-groups-filter';
import { ProductGroup } from '../../supplier-detail/models/product-group';
import { DropdownOption } from '../../../models/dropdown-option';
import { Contact } from '../../../models/contact';
import { IChip } from '@kehe/phoenix-chips';
import { SupplierDetail } from '../../supplier-detail/models/supplier-detail';
import { DCProductGroupClone } from './product-groups.reducer';

export const clearAllProductsData = createAction(
  '[Product Groups] Clear All Products Data',
);
// Products Data
export const loadProductsData = createAction(
  '[Product Groups] Load Products Data',
  props<{ esn: string }>()
);
export const loadProductsDataSuccess = createAction(
  '[Product Groups] Load Products Data Success',
  props<{ products: any[] }>()
);
export const loadProductsDataError = createAction(
  '[Product Groups] Load Products Data Error'
);
export const prepareProductsData = createAction(
  '[Product Groups] Prepare Products Data'
);
// Products Data

// Product Groups
export const setProductGroups = createAction(
  '[Product Groups] Set Product Groups',
  props<{
    productGroups: ProductGroup[];
    dcList: Array<DropdownOption>;
    contacts?: Contact[];
  }>()
);
export const updateDeliveryMethodInDCProductGroups = createAction(
  '[Product Groups] Update Delivery Method In DCProductGroups',
  props<{ deliveryMethodList: any[] }>()
);
export const filterProductGroups = createAction(
  '[Product Groups] Filter Product Groups',
  props<{ filter: ProductGroupsFilter; search: string }>()
);
// Product Groups

// Product Group Search & Filter
export const productGroupsSearchChanged = createAction(
  '[Product Groups] Product Groups Search Changed',
  props<{ search: string }>()
);
export const productGroupsSearchCleared = createAction(
  '[Product Groups] Product Groups Search Cleared',
);
export const productGroupsFilterChanged = createAction(
  '[Product Groups] Product Groups Filter Changed',
  props<{ productGroupsFilter: ProductGroupsFilter }>()
);
export const productGroupsFilterClearAll = createAction(
  '[Product Groups] Product Groups Filter Clear All'
);
export const productGroupsChipClicked = createAction(
  '[Product Groups] Product Groups Chip Clicked',
  props<{ chip: IChip }>()
);

// Product Group Search & Filter

// Clone DC Product Groups

export const cloneDCProductGroup = createAction(
  '[Product Groups] Clone DC Product Groups',
  props<{
    productGroup: ProductGroup;
    dcpg: DCProductGroupClone;
    cloneTo: { value: string }[]
  }>()
);
export const cloneDCProductGroupChanged = createAction(
  '[Product Groups] Clone DC Product Groups Changed',
  props<{
    changed: boolean;
    pickupChanged: boolean;
    valid: boolean;
    dcpg: DCProductGroupClone;
  }>()
);
export const cloneDCProductGroupCancelled = createAction(
  '[Product Groups] Clone DC Product Groups Cancelled'
);
export const cloneDCPGSave = createAction(
  '[Product Groups] Clone DCPG Save',
);
export const cloneDCPGSaveSuccess = createAction(
  '[ProductGroups] Clone DCPG Save Success',
  props<{ supplier: SupplierDetail; message: string }>(),
);
export const cloneDCPGSaveFailed = createAction(
  '[Product Groups] Clone DCPG Save Failed',
  props<{ error: string }>()
);

// Clone DC Product Groups

// Bulk Edit DCPG Actions

export const initDCPGBulkEdit = createAction(
  '[Product Groups]: Init DCPG Bulk Edit',
);
export const applyDCPGBulkEdit = createAction(
  '[Product Groups]: Apply DCPG Bulk Edit',
  props<{
    productGroup: Pick<ProductGroup, 'productGroupNumber' | 'dcProductGroups'>;
    auditNotes?: string;
  }>()
);
export const applyDCPGBulkEditSuccess = createAction(
  '[Product Group]: Apply DCPG Bulk Edit Success',
  props<{ supplier: SupplierDetail; message: string;}>(),
);
export const applyDCPGBulkEditFailed = createAction(
  '[Product Groups]: Apply DCPG Bulk Edit Failed',
  props<{ error: string }>()
);
// Bulk Edit DCPG Actions
