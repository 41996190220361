import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
  name: 'phoneNumber',
  standalone: true,
 })
export class PhoneNumberPipe implements PipeTransform {
    transform(value: string): string {
      if (value && value.length === 10) {
        return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
      } else if (value && value.startsWith('+1') && value.length > 10) {
        return value.replace(/^\+(\d)(\d{3})(\d{3})(\d{4,})$/g, '+$1 ($2) $3 $4');
      }
      return value;
    }
}
