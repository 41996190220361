import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '@app/abstract/destroyable';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  selectShowDocuSignSignerConfirmation,
  selectSupplierDetail
} from '../../store/supplier-detail.selectors';
import { SupplierDetail } from '../../models/supplier-detail';
import { DocusignStatus } from '../../models/docusign-status';
import { confirmDocuSignSigner } from '../../store/supplier-detail.actions';
import { selectIsDocusignReady } from './legal-signatures-tab.selectors';
import {
  isAAPRequestInReview
} from "@app/modules/supplier-detail/utilities/supplier-utils";

@Component({
  selector: 'app-legal-signatures-tab',
  templateUrl: './legal-signatures-tab.component.html',
  styleUrls: ['./legal-signatures-tab.component.scss']
})
export class LegalSignaturesTabComponent extends Destroyable implements OnInit {

  form: UntypedFormGroup;
  isDocusignReady = false;
  showDocuSignSignerConfirmation: boolean;
  supplier: SupplierDetail;

  get readyToSend() {
    return this.form.valid && this.form.get('isAuthorised').value;
  }

  get isAAPRequestInReview() {
    return isAAPRequestInReview(this.supplier);
  }

  constructor(
    private _store: Store,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this._store
        .pipe(select(selectSupplierDetail), takeUntil(this.destroy$))
        .subscribe((val) => {
          this.supplier = val;
          this.buildForm();
          if (this.isDocuSignSend() || this.isDocuSignCompleted()) {
            setTimeout(() => this.form.disable(), 100);
          }
        });
    this._store.select(selectIsDocusignReady)
        .pipe(takeUntil(this.destroy$))
        .subscribe((val) => {
          this.isDocusignReady = val;
        });
    this._store
        .pipe(select(selectShowDocuSignSignerConfirmation), takeUntil(this.destroy$))
        .subscribe((val) => {
          this.showDocuSignSignerConfirmation = val;
        });
  }

  private buildForm() {
    const signee = this.supplier && this.supplier.docuSignData ? this.supplier.docuSignData.signee : null;
    this.form = this._formBuilder.group({
      name: [signee ? signee.name : null, [Validators.required]],
      title: [signee ? signee.title : null, [Validators.required]],
      emailAddress: [signee ? signee.emailAddress : null, [Validators.required, Validators.email]],
      isAuthorised: [signee ? true : null]
    });
  }

  onSendClick() {
    if (!this.readyToSend) {
      return;
    }
    this._store.dispatch(confirmDocuSignSigner({ signer: this.form.value }));
  }

  private isDocuSignSend() {
    return (
      this.supplier?.docuSignData?.signee?.emailAddress &&
      this.supplier?.docuSignData?.status === DocusignStatus.sent
    );
  }

  private isDocuSignCompleted() {
    return this.supplier &&
           this.supplier.docuSignData &&
           this.supplier.docuSignData.signee && this.supplier.docuSignData.signee.emailAddress &&
           this.supplier.docuSignData.status === DocusignStatus.completed;
  }
}
