import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { DocumentService } from '@app/services/document.service';
import { DocumentCreateDto } from './../../../models/document';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { DocumentListPageStore } from './document-list-page.store';
import { Observable, combineLatest } from 'rxjs';
import {
  DocumentCreateMode,
  getCommonDocumentForm,
  isFormValid,
  isSproutsOrderGuide as isSproutsGuide,
  runDocumentFormValidations
} from './document-utils';
import { IFileUpload } from '@kehe/phoenix-upload';
import { CreateDocumentApiStore } from './api-stores/create-document-api.store';

export interface DocumentCreateModalState {
  isModelOpen: boolean;
  mode: DocumentCreateMode;
  file: IFileUpload;
}

const initialState: () => DocumentCreateModalState = () => {
  const state: DocumentCreateModalState = {
    isModelOpen: false,
    mode: DocumentCreateMode.url,
    file: null,
  };
  return state;
};

@Injectable()
export class DocumentCreateModalStore extends ComponentStore<DocumentCreateModalState> {

  formGroup = getCommonDocumentForm();

  private _createDocumentStore = new CreateDocumentApiStore(
    this._documentService
  );

  constructor(
    private _documentService: DocumentService,
    private _listStore: DocumentListPageStore,
    private _toast: PhoenixToastService
  ) {
    super(initialState());
    this.watchFormValidationLogic$(combineLatest([this.editMode$, this.deptTypeVal$]));
    this.handleSuccessfullDocumentCreate(this.createDocumentResponse$);
  }

  public readonly createDocumentResponse$ = this._createDocumentStore.data$;

  // Document type (when Is Sprouts) and Create mode affect the validation logic
  // and which form fields show
  watchFormValidationLogic$ = this.effect((combinedTrigger$: Observable<[DocumentCreateMode, string]>) => {
    return combinedTrigger$.pipe(
      tap(([editMode, docType]: [DocumentCreateMode, string]) => {
        setTimeout(() => {
          runDocumentFormValidations(editMode, docType, this.formGroup);
        });
      })
    );
  });

  cancel(): void {
    this.setState(initialState());
  }

  save$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(this.state$),
      tap(([_, state]) => {

        const formValue = this.formGroup.value;

        const doc: DocumentCreateDto = {
          fileSize: state?.file?.size || undefined,
          department: formValue.department || undefined,
          documentType: formValue.documentType,
          fileUrl: formValue.fileUrl,
          title: formValue.title,
          storeType: formValue.storeType,

          distributionCenterNumber: formValue.distributionCenterNumber,
          startDate: formValue.startDate ? new Date(formValue.startDate) : null,
          expireDate: formValue.expireDate ? new Date(formValue.expireDate) : null,
          isSingleUseUrl: state.mode === DocumentCreateMode.file
        };

        if (isSproutsGuide(doc.documentType)) {
          doc.distributionCenterNumber = undefined;
          doc.storeType = undefined;

          // date fields are not exposed for sprouts
          // the Expire date should be null and startdate is set to now when payload is created
          doc.startDate = new Date();
        }

        this._createDocumentStore.save$([state.file, doc]);
      })
    )
  );

  onSaveSuccess(): void {
    this._listStore.reloadDocumentsWithCurrentFilter$();
    this.setState(initialState());
    this._toast.showSuccessToast('Document successfully created!');
  }

  readonly handleSuccessfullDocumentCreate = this.effect(
    (response$: Observable<string>) =>
      response$.pipe(
        filter((response) => Boolean(response)),
        tap((_) => {
          this.onSaveSuccess();
        }),
      ),
  );

  // start create flow
  createDocument(): void {
    this.formGroup.reset();
    this.patchState({
      ...initialState(),
      isModelOpen: true
    });
  }

  modeChange(mode: DocumentCreateMode): void {
    this.patchState({
      mode
    });
  }

  fileChanged(file: IFileUpload): void {
    this.patchState({ file });
  }

  canSubmit(
    mode: DocumentCreateMode,
    formValid: boolean,
    file: File,
    isSaving: boolean): boolean {

    let valid = true;

    if (mode === DocumentCreateMode.file) {
      valid = Boolean(file?.size);
    }

    return formValid && valid && !isSaving;
  }

  getFileUploads(file: IFileUpload): IFileUpload[] {

    if (file) {
      return [file];
    }

    return [];
  }

  public file$ = this.select(this.state$, (state) => state.file);
  public editMode$ = this.select(this.state$, (state) => state.mode);
  public deptTypeVal$ = this.select(this.formGroup.controls.documentType.valueChanges, (value) => value)
  public isFormValid$ = this.select(this.formGroup.statusChanges, (status) => isFormValid(status));
  public isSproutsOrderGuide$ = this.select(this.deptTypeVal$,
    (deptTypeVal) => isSproutsGuide(deptTypeVal));
  public fileUploads$ = this.select(this.file$, (file) => this.getFileUploads(file));
  public canSubmit$ = this.select(
    this.editMode$,
    this.isFormValid$,
    this.file$,
    this._createDocumentStore.isLoading$,
      (mode, isFormValid, file, isSaving) => this.canSubmit(mode, isFormValid, file?.rawFile, isSaving));

  public vm$ = this.select(
    this.state$,
    this.isFormValid$,
    this.isSproutsOrderGuide$,
    this.fileUploads$,
    this.canSubmit$,
    this._createDocumentStore.isLoading$,
    this._createDocumentStore.apiError$,
    (state, isFormValid, isSproutsOrderGuide, fileUploads, canSubmit, isSaving, apiError) => ({
      isModalOpen: state.isModelOpen,
      mode: state.mode,
      isSaving,
      apiFailure: Boolean(apiError),
      isFormValid,
      isSproutsOrderGuide,
      fileUploads,
      canSubmit
    }),
  );
}
