import { Injectable } from '@angular/core';
import { AbstractApiStore } from '@kehe/phoenix-component-store-utils';
import { DocumentService } from '@app/services/document.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';

import { ApiFailureText } from '@app/enum/alert-text.enum';

@Injectable()
export class DeleteDocumentsListApiStore extends AbstractApiStore<string> {

  constructor(private _documentService: DocumentService) {
    super();
  }

  deleteDocument$ = this.effect(
    (id$: Observable<number>) =>
      id$.pipe(
        tap(() => {
          this.setData(undefined);
          this.startLoading();
        }),
        switchMap((id) => {
          return this._documentService.delete(id).pipe(
            tapResponse(
              (_) => {
                this.setData('Success');
              },
              () => {
                this.setError(ApiFailureText.Generic);
              },
            ),
          );
        }),
        catchError(() => {
          this.setError(ApiFailureText.Generic);
          return EMPTY;
        }),
      ),
  );
}
