import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { isNullOrUndefined } from '../../../common/common-methods';
import { UserNotificationType } from '../model/user-notifications';
import { UserNotificationRecord } from '../model/service/user-notifications';
import { Payload } from '../../../models/payload';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsService {

  constructor(private httpClient: HttpClient) { }

  getUserNotifications(): Observable<Payload<UserNotificationRecord[]>> {
    return this.httpClient.get<Payload<UserNotificationRecord[]>>(`${environment.apiGateway}/notifications/user`);
  }

  /**
   * Response body is ignored
   */
  clearUserNotifications(notificationIds: number[]): Observable<HttpResponse<Object>> {
    return this.httpClient.patch<HttpResponse<Object>>(`${environment.apiGateway}/notifications/user`, notificationIds);
  }

  private buildNotificationTypeParams(userNotificationTypes: UserNotificationType[]): HttpParams {
    let params = new HttpParams();
    if (isNullOrUndefined(userNotificationTypes) || userNotificationTypes.length === 0) {
      return params;
    }
    params = new HttpParams({
      fromObject: { 'types': userNotificationTypes }
    });
    return params;
  }
}
