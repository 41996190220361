import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectLocationError, selectLocationsLoading, selectLocationsSuccess, selectSupplierLocationContacts } from '../../store/supplier-locations.selectors';
import { Contact, ContactType } from '@app/models/contact';
import { map } from 'rxjs/operators';
import { selectIsSavingContact, selectNewlyAddedContact, selectSaveContactError } from '@app/modules/supplier-detail/store/supplier-detail.selectors';
import { addSupplierContact } from '@app/modules/supplier-detail/store/supplier-detail.actions';
import { removeContact, updateLocationError } from '../../store/supplier-locations.actions';

@Component({
  selector: 'app-locations-delivered-address',
  templateUrl: './locations-delivered-address.component.html',
  styleUrls: ['./locations-delivered-address.component.scss'],
})
export class LocationsDeliveredAddressComponent { 
  contacts$ = this._store.select(selectSupplierLocationContacts).pipe(
    map((contacts) => contacts.filter((c) => c.contactType === ContactType.DeliveredAddress))
  );
  saving$ = this._store.select(selectIsSavingContact);
  deleting$ = this._store.select(selectLocationsLoading);
  error$ = this._store.select(selectSaveContactError);
  deleteAddressError$ = this._store.select(selectLocationError);

  types = ContactType;
  form = this._builder.array([]);
  showModal = false;

  stagedContact?: Contact;

  constructor(
    private readonly _store: Store,
    private readonly _builder: UntypedFormBuilder,
  ) {
    this.contacts$.subscribe((contacts) => {
      this.form.clear();
      contacts.forEach((c) => {
        this.form.push(this._builder.group({
          deliveredAddress: [c.uniqueId],
        }));
      });
      if (contacts.length < 1) {
        this.form.push(this._builder.group({
          deliveredAddress: [],
        }));
      }
    });

    this._store.select(selectLocationsSuccess).subscribe((success) => {
      if (success) {
        this.stagedContact = null;
        this.showModal = false;
      }
    });

    this._store.select(selectNewlyAddedContact).subscribe((value) => {
      if (value != null) {
        this.stagedContact = null;
        this.showModal = false;
      }
    });
  }

  onSmartyStreetsUseAddress(contact: Contact) {
    this._store.dispatch(addSupplierContact({ contact }));
  }

  onDelete(contact: Contact) {
    this._store.dispatch(removeContact({ contact }));
  }

  onRemove(contact: Contact) {
    this.stagedContact = contact;
  }

  onCancel() {
    this.stagedContact = null;
    this.showModal = false;
    this._store.dispatch(updateLocationError({ error: undefined }));
  }

  onAddClicked() {
    this.showModal = true;
  }

}
