import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DcPaymentHoldDetails } from '@app/modules/supplier-detail/models/dc-payment-hold-details';
import { SupplierDetail } from '@app/modules/supplier-detail/models/supplier-detail';
import { environment } from '@src/environments/environment';

@Injectable()
export class DcPaymentHoldsService {
  constructor(private readonly httpClient: HttpClient) {}

  updateDcPaymentHolds(esn: string, masterVersionNumber: number, changedPaymentHolds: DcPaymentHoldDetails[]) {
    return this.httpClient.patch<SupplierDetail>(`${environment.supplierApi}/api/dcpaymenthold/${esn}`, {
      dcPaymentHoldDetails: changedPaymentHolds,
      masterVersionNumber
    })
  }
}
