import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProhibitRestrictPageComponent } from '../pages/prohibit-restrict-page/prohibit-restrict-page.component';
import { prohibitRestrictsListFilterClearAll } from '../store/prohibit-restrict-list.actions';

@Injectable({
  providedIn: 'root',
})
export class ClearProhibitsRestrictsGuard {
  constructor(private _store: Store) {}

  canDeactivate(
    component: ProhibitRestrictPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!nextState.url.startsWith('/prohibit-restricts')) {
      this._store.dispatch(prohibitRestrictsListFilterClearAll());
    }
    return true;
  }
}
