import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payload } from '../../../models/payload';
import { environment } from '../../../../environments/environment';
import { delay, map, retry, retryWhen, take, tap } from 'rxjs/operators';
import { BrokerDetail } from '../../broker-detail/models/broker-detail';
import { IAuditDetail } from '@kehe/phoenix-audit-history';
import { convertToEasternTime } from '@app/shared/utils/time-zone-util';
import { BrokerHistoryFilter } from '../store/broker-detail.reducer';
import { brokerHistoryFilterToHttpParams } from './broker-history-parser';
import { formatChangesAsString } from '@app/shared/utils/audit-history-util';
import { BrokerHistoryFilterItem } from '../models/broker-history-filter';
import { BrokerStatus } from '../models/broker-status';
import { HttpStatus } from '../../../../app/enum/http-status.enum';

@Injectable({
    providedIn: 'root'
})
export class BrokerDetailService {
  private _urlBrokerEnterprise: string;
  private _urlBrokerAPI: string;

  constructor(private httpClient: HttpClient) {
    this._urlBrokerEnterprise = environment.brokerEnterprise;
    this._urlBrokerAPI = environment.brokerApi;
  }

  getBrokerDetail(esn: string): Observable<BrokerDetail> {
    return this.httpClient
      .get<{ availableCount: number; data: any }>(
        `${this._urlBrokerEnterprise}/${esn}`
      )
      .pipe(retryWhen(errors => errors.pipe(delay(5000),
      tap(error => {
        if (error?.status !== HttpStatus.Success) {
          throw error;
        }
      }), take(3))), map((response) => response.data));
  }

  save(form): Observable<BrokerDetail> {
    return this.httpClient.patch<BrokerDetail>(`${environment.brokerApi}/${form.esn}`, form);
  }

  public getAuditHistory(
    key: string, filter: BrokerHistoryFilter
  ): Observable<Payload<Array<IAuditDetail>>> {

    const params = brokerHistoryFilterToHttpParams(filter, key);

    return this.httpClient
      .get<Payload<Array<IAuditDetail>>>(
        `${this._urlBrokerAPI}/audit`,
        {
          params: params,
        }
      )
      .pipe(retry(2), map((data) => {
          data.data.forEach((row) => {
            row.created = convertToEasternTime(row.created);

            row.changes.forEach((change) => {
              if (typeof change.originalValue === 'object') {
                change.originalValue = formatChangesAsString(change.originalValue);
              }
              if (typeof change.newValue === 'object') {
                change.newValue = formatChangesAsString(change.newValue);
              }
            });
          });
          return data;
        })
      );
  }

  public getHistoryFilterFields(): Observable<
    Payload<Array<BrokerHistoryFilterItem>>
    > {
    return this.httpClient
      .get<Payload<Array<BrokerHistoryFilterItem>>>(`${this._urlBrokerAPI}/audit/fields`)
      .pipe(retry(2));
  }

  public getHistoryFilterEditedBy(esn: string): Observable<Payload<Array<BrokerHistoryFilterItem>>> {
    return this.httpClient
      .get<Payload<Array<BrokerHistoryFilterItem>>>(`${this._urlBrokerAPI}/audit/createdBy`,
        {
          params: { 'key': esn },
        })
      .pipe(retry(2));
  }

  submit(broker: BrokerDetail): Observable<BrokerDetail> {
    const tmpForm = {
      esn: broker.esn,
      status: BrokerStatus.active,
      updatedBySource: 'Enterprise'
    };
    return this.httpClient.patch<BrokerDetail>(`${environment.brokerApi}/${tmpForm.esn}`, tmpForm);
  }
}
