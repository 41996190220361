import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GroupManagementPageComponent } from '@app/modules/user-group-management/pages/group-management-page/group-management-page.component';
import { ManageGroupsFilterActions } from '@app/modules/user-group-management/store/group-management/group-management.actions';

@Injectable({
  providedIn: 'root',
})
export class ResetGroupFiltersGuard {
  constructor(private store: Store) {}

  canDeactivate(
    component: GroupManagementPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.store.dispatch(ManageGroupsFilterActions.resetFilters());
    return true;
  }
}
