import { createAction, props } from '@ngrx/store';

import { IAnnouncementDetails } from '../../interfaces/announcement-details.inteface';
import { IAnnouncementListResponse } from '../interfaces/announcement-list.interface';
import {
  IDocumentUpload,
  IDocumentUploadStatusResponse,
  IUploadRestrictions
} from '../interfaces/upload-documents.interface';

export const fetchListSuccess = createAction(
  '[Announcements API] Fetch List Success',
  props<{ response: IAnnouncementListResponse }>()
);

export const fetchListError = createAction(
  '[Announcements API] Fetch List Error'
);

export const createSuccess = createAction(
  '[Announcements API] Create Success',
  props<{ data: IAnnouncementDetails }>()
);

export const createError = createAction(
  '[Announcements API] Create Error',
  props<{ error: string }>()
);

export const updateSuccess = createAction(
  '[Announcements API] Update Success',
  props<{ data: IAnnouncementDetails }>()
);

export const updateError = createAction(
  '[Announcements API] Update Error',
  props<{ error: string }>()
);

export const getAnnouncement = createAction(
  '[Announcements API] Get Announcement Details',
  props<{ id: number }>()
);

export const getAnnouncementSuccess = createAction(
  '[Announcements API] Get Announcement Details Success',
  props<{ data: IAnnouncementDetails }>()
);

export const getAnnouncementError = createAction(
  '[Announcements API] Get Announcement Details Error',
  props<{ error: string }>()
);

export const announcementPublishSuccess = createAction(
  '[Announcements API] Publish Success',
  props<{ response: IAnnouncementDetails }>()
);

export const announcementPublishError = createAction(
  '[Announcements API] Publish Error',
  props<{ error: string }>()
);

export const getUploadRestrictionsSuccess = createAction(
  '[Announcements API] Get Upload Restrictions Success',
  props<{ data: IUploadRestrictions }>()
);

export const getUploadRestrictionsError = createAction(
  '[Announcements API] Get Upload Restrictions Error'
);

export const createDocumentsSuccess = createAction(
  '[Announcements API] Create Documents Success',
  props<{ uploads: IDocumentUpload[] }>()
);

export const createDocumentsError = createAction(
  '[Announcements API] Create Documents Error',
  props<{ error: string }>()
);

export const fileUploadsComplete = createAction(
  '[Announcements API] File Uploads Complete'
);

export const fileUploadsError = createAction(
  '[Announcements API] File Uploads Error',
  props<{ error: string }>()
);

export const updateDocumentUploads = createAction(
  '[Announcements API] Update Document Uploads',
  props<{ response: IDocumentUploadStatusResponse }>()
);

export const uploadDocumentsComplete = createAction(
  '[Announcements API] Upload Documents Complete',
  props<{ response: IDocumentUploadStatusResponse }>()
);

export const fetchPendingDeleteDetailsSuccess = createAction(
  '[Announcements API] Fetch Pending Delete Details Success',
  props<{ response: IAnnouncementDetails }>()
);

export const fetchPendingDeleteDetailsError = createAction(
  '[Announcements API] Fetch Pending Delete Details Error'
);

export const deleteAnnouncementSuccess = createAction(
  '[Announcements API] Delete Announcement Success'
);

export const deleteAnnouncementError = createAction(
  '[Announcements API] Delete Announcement Error'
);

export const deleteFileSuccess = createAction(
  '[Announcements API] Delete File Success'
);

export const deleteFileError = createAction(
  '[Announcements API] Delete File Error',
  props<{ error: string }>()
);
