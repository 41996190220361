import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';import { tapResponse } from '@ngrx/operators';

import { EMPTY, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { VendorBrokerManagementService } from "../../../services/vendor-broker-management.service";

export interface MasterVersionState {
    masterVersionNumber: number;
    hasNoMaster: boolean;
}

export const initialState: MasterVersionState = {
    masterVersionNumber: null,
    hasNoMaster: false
};

@Injectable({
    providedIn: 'root'
})
export class MasterVersionApiStore extends ComponentStore<MasterVersionState> {

    constructor(
        private _vendorBrokerManagementService: VendorBrokerManagementService,
    ) {
        super();
        this.setState(initialState);
    }

    number$ = this.select((state) => state.masterVersionNumber);
    hasNoMaster$ = this.select((state) => state.hasNoMaster);

    setMasterVersionNumber = this.updater(
        (state, masterVersionNumber: number) => ({
          ...state,
          masterVersionNumber,
        })
    );

    setHasNoMaster = this.updater(
      (state, hasNoMaster: boolean) => ({
        ...state,
        hasNoMaster,
      })
    );

    getMasterRelationshipVersion$ = this.effect(
        (esn$: Observable<string>) =>
        esn$.pipe(
            switchMap((esn) => {
              if (!esn) {
                return EMPTY;
              }
              
              return this._vendorBrokerManagementService
                .getMasterRelationshipVersion(
                  esn
                )
                .pipe(
                  tapResponse(
                    (response) => {
                        this.setMasterVersionNumber(response.masterVersionNumber);
                        this.setHasNoMaster(response.hasNoMaster);
                    },
                    () => {
                        this.setMasterVersionNumber(null);
                    })
                );
            })
          )
      );
}
