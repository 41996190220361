import { Component, OnInit } from '@angular/core';
import { UserAgreementFacade } from '../../modules/user-agreement/store/user-agreement.facade';
import { Observable } from 'rxjs';
import {
  UserAgreementRequestType,
  UserAgreementStatus
} from '../../modules/user-agreement/store/user-agreement.state';
import { takeUntil } from 'rxjs/operators';
import { SafeHtml } from '@angular/platform-browser';
import { AlertTypes } from '@kehe/phoenix-notifications';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Destroyable } from '../../abstract/destroyable';

enum UserAgreementTab {
  TermsAndConditions,
  PrivacyStatement
}

@Component({
  selector: 'app-user-agreement-modal',
  templateUrl: './user-agreement-modal.component.html',
  styleUrls: ['./user-agreement-modal.component.scss']
})
export class UserAgreementModalComponent extends Destroyable implements OnInit {

  userAgreementStatus: UserAgreementStatus;
  termsAndConditionsPage$: Observable<SafeHtml>;
  privacyStatementPage$: Observable<SafeHtml>;

  activeTab = UserAgreementTab.TermsAndConditions;
  userAgreementForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userAgreementFacade: UserAgreementFacade
  ) {
    super();
  }

  ngOnInit() {
    this.userAgreementFacade.getLoadedUserAgreementStatus().pipe(
      takeUntil(this.destroy$)
    ).subscribe( status => {
      this.userAgreementStatus = status;
      if (this.userAgreementStatus === UserAgreementStatus.UserHasNotAgreed) {
        this.userAgreementFacade.loadTermsAndConditionsPage();
        this.userAgreementFacade.loadPrivacyStatementPage();
      }
    });

    this.termsAndConditionsPage$ = this.userAgreementFacade.getLoadedTermsAndConditionsPage();
    this.privacyStatementPage$ = this.userAgreementFacade.getLoadedPrivacyStatementPage();
    this.userAgreementForm = this.formBuilder.group({
      hasAcceptedAgreement: new UntypedFormControl(
        this.userAgreementStatus === UserAgreementStatus.UserHasAgreed,
       [Validators.requiredTrue]
      )
    });
  }

  onTabClick(tab: UserAgreementTab): void {
    this.activeTab = tab;
  }

  onSubmitAcceptedAgreement(): void {
    if (this.userAgreementForm.valid) {
      this.userAgreementFacade.setUserAgreementStatus();
    }
  }

  get userAgreementStatuses(): typeof UserAgreementStatus {
    return UserAgreementStatus;
  }

  get userAgreementTabs(): typeof UserAgreementTab {
    return UserAgreementTab;
  }

  get shouldDisplayAlert$(): Observable<boolean> {
    return this.userAgreementFacade.shouldDisplayAlert();
  }

  get alertType$(): Observable<AlertTypes> {
    return this.userAgreementFacade.getAlertType();
  }

  get alertMessage$(): Observable<string> {
    return this.userAgreementFacade.getAlertMessage();
  }

  get isWriteLoading$(): Observable<boolean> {
    return this.userAgreementFacade.isLoading(UserAgreementRequestType.Write);
  }
}
