import { createActionGroup } from "@ngrx/store";

export const BreakpointStoreActions = createActionGroup({
  source: 'Breakpoint',
  events: {
      'Xtra Small View Changed': (isXtraSmallView: boolean) => ({isXtraSmallView}),
      'Small View Changed': (isSmallView: boolean) => ({isSmallView}),
      'Medium View Changed': (isMediumView: boolean) => ({isMediumView}),
      'Large View Changed': (isLargeView: boolean) => ({isLargeView}),
      'Xtra Large View Changed': (isXtraLargeView: boolean) => ({isXtraLargeView}),
      'Xtra Xtra Large View Changed': (isXtraXtraLargeView: boolean) => ({isXtraXtraLargeView}),
      'Small And Below Changed': (isSmallAndBelow: boolean) => ({isSmallAndBelow}),
      'Med And Below Changed': (isMedAndBelow: boolean) => ({isMedAndBelow}),
      'Med And Up Changed': (isMedAndUp: boolean) => ({isMedAndUp}),
      'Large And Up Changed': (isLargeAndUp: boolean) => ({isLargeAndUp}),
  }
})