import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectCanUserManageAnnouncements } from '../store/announcements.selectors';

/**
 * Manage Announcement Guard, actives the route only if the user has the Resource Action and at least 
 * one of the Resource Actions EnterpriseAnnouncement_Write, SupplierAnnouncement_Write or Retailer_Announcement_Write.
 */
@Injectable({
  providedIn: 'root'
})
export class ManageAnnouncementsGuard  {

  constructor(private router: Router,
              private store: Store) {}

  canManageAnnouncements(): Observable<boolean> {
    return this.store.select(selectCanUserManageAnnouncements);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.canManageAnnouncements()
      .pipe(map((canManage: boolean) => canManage ? canManage : this.router.parseUrl('/unauthorized')));
  }
}
