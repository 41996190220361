<div [formGroup]="form">

  <div class="general-form-container" *ngIf="canEditSummary">
    <div class="row">
      <div class="col">
        <h4>Summary</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group mb-0">
          <kendo-formfield>
            <kendo-label [for]="legalNameField" text="Legal Name"></kendo-label>
            <kendo-textbox #legalNameField formControlName="name" [maxlength]="legalNameMaxLength"
              placeholder="Enter Legal Name" (valueChange)="legalNameChange($event)" required>
            </kendo-textbox>
            <div class="d-flex">
              <kendo-formerror *ngIf="legalName?.errors?.required || legalNameIsEmpty">Legal Name is
                required.</kendo-formerror>
              <kendo-formhint *ngIf="!legalName?.errors?.required">Maximum of {{legalNameMaxLength}}
                characters</kendo-formhint>
              <kendo-formhint class="ms-auto">{{legalNameCounter}}</kendo-formhint>
            </div>
          </kendo-formfield>
        </div>
        <div class="form-group">
          <kendo-formfield>
            <kendo-label [for]="dbaFormField" text="Doing Business As (DBA)"></kendo-label>
            <kendo-textbox #dbaFormField formControlName="doingBusinessAs" placeholder="Enter Doing Business As (DBA)">
            </kendo-textbox>
          </kendo-formfield>
        </div>
        <div class="form-group mb-0">
          <kendo-formfield>
            <div>
              <kendo-label text="NTS Legal Name"></kendo-label>
              <em class="fas fa-info-circle info-tooltip" [keheTooltip]="allowedCharsMessage"></em>
            </div>

            <kendo-textbox formControlName="wmsLegalName" placeholder="Enter NTS Legal Name"
              [maxlength]="legalNameMaxLength" (valueChange)="ntsLegalNameChange($event)">
            </kendo-textbox>
            <div class="d-flex">
              <kendo-formerror *ngIf="ntsLegalName?.errors?.required">NTS Legal Name is required.</kendo-formerror>
              <kendo-formerror *ngIf="ntsLegalName?.errors?.pattern">Invalid character(s) added.</kendo-formerror>
              <kendo-formhint class="ms-auto">{{ntsLegalNameCounter}}</kendo-formhint>
            </div>
          </kendo-formfield>
        </div>
        <div class="form-group" *ngIf="isInvitedDraftSupplier()">
          <kendo-formfield>
            <kendo-label [for]="shipDateField" text="KeHE Ship Date to Retailer(s) (optional)"></kendo-label>
            <kendo-datepicker #shipDateField class="w-50" formControlName="keheShipToRetailerDate"
              placeholder='mm/dd/yyyy' format="MM/dd/yyyy" data-automation-id="ship-date-time-input"
              [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }" [disabledDates]="disabledShipDates">
            </kendo-datepicker>
            <kendo-formerror *ngIf="form.get('keheShipToRetailerDate').hasError('disabledDate')">
              KeHE Ship Date to Retailer(s) is invalid.
            </kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="form-group" *ngIf="form.get('administrativeAllowanceProgramRequested').enabled">
          <kendo-label text="Request to join Administrative Allowance Program?">
            <em class="fas fa-info-circle info-tooltip" keheTooltipPlacement="right" [keheTooltip]="tooltipAAP"></em>
          </kendo-label>
          <kendo-formhint class="align-items-baseline">Download document for more information on AAP <a
              class="download-link ms-1" (click)="downloadAAPDocumentation()">here</a>.
          </kendo-formhint>
          <kendo-formerror class="k-radio-hint mt-0 mb-2"
            *ngIf="form.get('administrativeAllowanceProgramRequested').errors">
            This field is required.
          </kendo-formerror>
          <ul class="k-radio-list ps-0 mt-0">
            <li class="k-radio-item">
              <input #administrativeAllowanceProgramRequestedYes [value]="true" class="me-0"
                formControlName="administrativeAllowanceProgramRequested" kendoRadioButton
                name="administrativeAllowanceProgramRequested" type="radio">
              <kendo-label class='ms-2' [for]="administrativeAllowanceProgramRequestedYes">Yes</kendo-label>
            </li>
            <li class="k-radio-item">
              <input #administrativeAllowanceProgramRequestedNo [value]="false" class="me-0"
                formControlName="administrativeAllowanceProgramRequested" kendoRadioButton
                name="administrativeAllowanceProgramRequested" type="radio">
              <kendo-label class='ms-2' [for]="administrativeAllowanceProgramRequestedNo">No</kendo-label>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group" formGroupName="categoryManager">
          <kendo-formfield>
            <kendo-label [for]="categoryManagerFormField" text="Category Manager"></kendo-label>
            <kendo-combobox #categoryManagerFormField [data]="categoryManagerItems" [filterable]="true"
              [kendoDropDownFilter]="{operator: 'contains'}" [textField]="'name'" [valueField]="'code'"
              [valuePrimitive]="true" (focus)="onFocus(categoryManagerFormField)"
              (valueChange)="onCategoryManagerChange($event)" formControlName="code"
              [loading]="loadCategoryManagerItems" placeholder="Select Category Manager"></kendo-combobox>
            <kendo-formerror *ngIf="this.form.get('categoryManager.code')?.errors?.required">
              Category Manager is required.
            </kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="form-group" formGroupName="supplierDevelopmentManager">
          <kendo-formfield>
            <kendo-label [for]="supplierDevelopmentManagerFormField" text="Supplier Development Manager"></kendo-label>
            <kendo-combobox #supplierDevelopmentManagerFormField [data]="supplierDevManagerItems" [filterable]="true"
              [kendoDropDownFilter]="{operator: 'contains'}" [textField]="'name'" [valueField]="'code'"
              [valuePrimitive]="true" [loading]="loadSupplierDevManagerItems"
              (focus)="onFocus(supplierDevelopmentManagerFormField)"
              (valueChange)="onSupplierDevelopmentManagerChange($event)" formControlName="code"
              placeholder="Select Supplier Development Manager"></kendo-combobox>
            <kendo-formerror *ngIf="this.form.get('supplierDevelopmentManager.code')?.errors?.required">
              Supplier Development Manager is required.
            </kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="form-group">
          <kendo-label text="International Currency Supplier"></kendo-label>
          <ul class="k-radio-list ps-0 mt-1">
            <li class="k-radio-item me-4">
              <input #hasInternationalCurrencyYes [value]="true" class="me-0" disabled
                formControlName="hasInternationalCurrency" kendoRadioButton
                data-automation-id="general-tab-has-international-currency-yes-input" name="hasInternationalCurrency"
                type="radio">
              <kendo-label class='ms-2' [for]="hasInternationalCurrency">Yes</kendo-label>
            </li>
            <li class="k-radio-item">
              <input #hasInternationalCurrencyNo [value]="false" class="me-0" disabled
                formControlName="hasInternationalCurrency" kendoRadioButton
                data-automation-id="general-tab-has-international-currency-no-input" name="hasInternationalCurrency"
                type="radio">
              <kendo-label class='ms-2' [for]="hasInternationalCurrency">No</kendo-label>
            </li>
          </ul>
        </div>

        <div class="form-group">
          <kendo-formfield>
            <kendo-label [for]="supplierCurrency" text="Supplier Currency"></kendo-label>
            <kendo-combobox [data]="supplierCurrencies" #supplierCurrencyValue
              data-automation-id="general-tab-supplier-currency-input" placeholder="Supplier Currency"
              [textField]="'value'" [valueField]="'code'" [valuePrimitive]="true" placeholder="Select Supplier Currency"
              formControlName="supplierCurrencyValue" [disabled]="true">
            </kendo-combobox>
          </kendo-formfield>
        </div>
      </div>
    </div>
  </div>

  <div class="general-form-container" [hidden]="!canEditSupplierHeadquarters">
    <div class="row">
      <div class="col">
        <h4>Supplier Headquarters</h4>
      </div>
    </div>
    <div class="row" [hidden]="showContactCard">
      <div class="col-12 col-md-6">
        <kehe-address #addressComponentHeadquarters [address]="supplier?.headquarterAddress"
          [formSaved]="validateHeadquarterAddressOnSave.asObservable()" [key]="smartyStreetsKey" [street1MaxLength]="30"
          [street2MaxLength]="getStreet2Length()" [cityMaxLength]="getCityLength()"
          [attentionMaxLength]="getAttentionLength()" [zipCodeMaxLength]="getZipCodeLength()"
          (noAddressSelected)="onNoAddressSelected()" (addressSelected)="onHeadquarterAddressSelected($event)">
        </kehe-address>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex form-group">
          <kehe-phoenix-phone-number-input label="Phone"
            formControlName="headquarterPhone"></kehe-phoenix-phone-number-input>
          <span class="me-2"></span>
          <div>
            <kendo-formfield>
              <kendo-label [for]="phoneExtensionField" text="Ext (optional)"></kendo-label>
              <kendo-maskedtextbox #phoneExtensionField formControlName="headquarterExtension" [maskValidation]="false"
                [prompt]="''" [mask]="mask" style="max-width: 75px !important;">
              </kendo-maskedtextbox>
            </kendo-formfield>
          </div>
        </div>
        <div class="form-group">
          <kehe-phoenix-phone-number-input label="Fax (optional)"
            formControlName="headquarterFax"></kehe-phoenix-phone-number-input>
        </div>
        <div class="form-group" style="max-width: 298px;">
          <kendo-formfield>
            <kendo-label [for]="headquarterEmailField" text="Email"></kendo-label>
            <kendo-textbox #headquarterEmailField formControlName="headquarterEmail" [maxlength]="hqEmailMaxLength"
              placeholder="e.g. contact@email.com" (valueChange)="hqEmailChange($event)">
            </kendo-textbox>
            <div class="d-flex">
              <kendo-formerror *ngIf="hqEmail?.errors?.email">Invalid email format</kendo-formerror>
              <kendo-formhint *ngIf="!hqEmail?.errors?.email">Maximum of {{hqEmailMaxLength}}
                characters</kendo-formhint>
              <kendo-formhint class="ms-auto">{{hqEmailCounter}}</kendo-formhint>
            </div>
          </kendo-formfield>
        </div>
      </div>
    </div>
    <div class="row" [hidden]="!showContactCard">
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <kendo-label text="Headquarter Address" class="mb-1"></kendo-label>
          <kendo-formerror *ngIf="form.get('headquarterAddressId')?.touched
                       && form.get('headquarterAddressId')?.errors?.required" class="mt-0 mb-2">
            Headquarter Address is required.
          </kendo-formerror>
          <app-contact-card formControlName="headquarterAddressId"
            [alertMessage]="'This HQ address should match with the W9.'"
            [defaultContactTypes]="contactType.HeadquarterAddress" [contacts]="supplier.contacts"
            [isInProgress]="isSavingContact" [saveContactError]="saveContactError"
            (selectedMyContact)="onSelectHeadquarterAddress($event)"
            (smartyStreetsUseAddress)="onSmartyStreetsUseAddress($event)"
            (smartyStreetsEditAddress)="onSmartyStreetsEditAddress()" (remove)="onRemoveHeadquarterAddress()"
            (closeModal)="onCloseHeadquarterAddressModal()">
          </app-contact-card>
        </div>
      </div>
    </div>
  </div>

  <div class="general-form-container" *ngIf="isThisAddedDraftSupplier()">
    <div class="row">
      <div class="col">
        <h4>Supplier Representative</h4>
      </div>
    </div>
    <div class="row" formGroupName="addedSupplierRepresentative">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <kendo-formfield>
            <kendo-label [for]="supplierRepresentativeNameField" text="Name"></kendo-label>
            <kendo-textbox #supplierRepresentativeNameField formControlName="name" placeholder="John Smith">
            </kendo-textbox>
            <kendo-formerror>Name is required.</kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <kendo-formfield>
            <kendo-label [for]="supplierRepresentativeEmailField" text="Email"></kendo-label>
            <kendo-textbox #supplierRepresentativeEmailField formControlName="email" placeholder="Johnsmith@gmail.com">
            </kendo-textbox>
            <kendo-formerror *ngIf="supplierRepresentativeEmail?.errors?.required">
              Email is required.
            </kendo-formerror>
            <kendo-formerror *ngIf="supplierRepresentativeEmail?.errors?.email">
              Invalid email format
            </kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
    </div>
  </div>

  <div class="general-form-container" *ngIf="canEditEdi">
    <div class="row">
      <div class="col">
        <h4>Electronic Data Interchange (EDI)</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <kendo-label text="EDI Customer"></kendo-label>
          <ul class="k-radio-list ps-0 mt-1">
            <li class="k-radio-item me-4">
              <input #isEdiYes [value]="true" class="me-0" formControlName="isEdi" kendoRadioButton name="isEdi"
                type="radio">
              <kendo-label class='ms-2' [for]="isEdiYes">Yes</kendo-label>
            </li>
            <li class="k-radio-item">
              <input #isEdiNo [value]="false" class="me-0" formControlName="isEdi" kendoRadioButton name="isEdi"
                type="radio">
              <kendo-label class='ms-2' [for]="isEdiNo">No</kendo-label>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <kendo-formfield>
            <kendo-label [for]="tradingPartnerIdFormField" text="Trading Partner ID"></kendo-label>
            <kendo-combobox #tradingPartnerIdFormField [data]="tradingPartnerList" [filterable]="true"
              [kendoDropDownFilter]="{operator: 'contains'}" [textField]="'name'" [valueField]="'id'"
              [valuePrimitive]="true" [loading]="isTradingPartnerListLoading"
              (valueChange)="onTradingPartnerSelect($event)" (filterChange)="loadTradingPartners($event)"
              formControlName="tradingPartnerId" placeholder="e.g.TPI"></kendo-combobox>
            <kendo-formerror *ngIf="this.form.get('tradingPartnerId')?.errors?.required">
              Trading Partner ID is required.
            </kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
    </div>
  </div>

</div>