import { createAction, props } from '@ngrx/store';
import {
    UserManagementFilterType,
  UserSortOrderOption,
  UserStatus,
  UserType
} from '../model/user-management';
import {
    CodeValueRecord,
  UpdateUserRequest,
  UserDetailRecord,
  UserManagementRecord
} from '../model/service/user-management';
import { IChip } from '@kehe/phoenix-chips';
import { Payload } from '../../../models/payload';
import { UserManagementFilter } from '../model/user-management.filter';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { DiscardModalType, UserManagementPageButtonConfiguration } from './user-management.state';

export const loadUserManagementListAction = createAction(
  '[User Management] Load User Management List'
);

export const loadUserManagementListSuccessAction = createAction(
  '[User Management] Load User Management List Success',
  props<{ response: Payload<UserManagementRecord[]> }>()
);

export const loadUserDetailAction = createAction(
  '[User Management] Load User Detail',
  props<{ email: string }>()
);

export const loadUserDetailSuccessAction = createAction(
  '[User Management] Load User Detail Success',
  props<{ response: Payload<UserDetailRecord> }>()
);

export const createUserSuccessAction = createAction(
  '[User Management] Create User Success',
  props<{ userType: UserType, email: string, created: string, status: UserStatus }>()
);

export const updateIsCreateBadRequestAction = createAction(
  '[User Management] Update isCreateBadRequest',
  props<{ isCreateBadRequest: boolean }>()
);

export const updateUserAction = createAction(
  '[User Management] Update User',
  props<{ request: UpdateUserRequest }>()
);

export const updateAssociatedGroupsAction = createAction(
  '[User Management] Update Associated User Groups',
  props<{ email: string, groupIds: number[] }>()
);

export const updateUserSuccessAction = createAction(
  '[User Management] Update User Success',
  props<{ record: UserDetailRecord }>()
);

export const updateUserFailedAction = createAction(
  '[User Management] Update User Failed Action'
);

export const refreshUserManagementListAction = createAction(
  '[User Management] Refresh User Management List'
);

export const updatePageCountAction = createAction(
  '[User Management] Update Page Count',
  props<{ pageCount: number, skip: number }>()
);

export const updatePageIndexAction = createAction(
  '[User Management] Update Page Index',
  props<{ pageIndex: number, skip: number }>()
);

export const updateSortOptionsAction = createAction(
  '[User Management] Update Sort Options',
  props<{ sortOrder: UserSortOrderOption, orderDirection: number }>()
);

export const updateSearchTextAction = createAction(
  '[User Management] Update Search Text',
  props<{ searchText: string}>()
);

export const applyUserManagementFiltersAction = createAction(
  '[User Management] Apply User Management Filters',
  props<{ filter: Partial<UserManagementFilter> }>()
);

export const clearUserManagementFiltersAction = createAction(
  '[User Management] Clear User Management Filters'
);

export const clearUserManagementFilterChipAction = createAction(
  '[User Management] Clear User Management Filter Chip',
  props<{ chip: IChip }>()
);

export const clearAllUserManagementFilterChipsAction = createAction(
  '[User Management] Clear All User Management Filter Chips'
);

export const updateFilterSuggestionsAction = createAction(
  '[User Management] Update Filter Suggestions',
  props<{ data: CodeValueRecord[], dropdown: UserManagementFilterType; }>()
)

export const userManagementErrorAction = createAction(
  '[User Management] User Management Error'
);

export const noOpAction = createAction(
 '[User Management] No Op'
);

export const updateKendoBreadCrumbsList = createAction(
  '[User Management] Update BreadCrumbs List',
  props<{ items: BreadCrumbItem[] }>()
);

export const clickedKendoBreadCrumbs = createAction(
  '[User Management] Clicked Kendo Breadcrumbs',
  props<{ item: BreadCrumbItem }>()
);

export const createUserError = createAction(
  '[User Management] Error',
  props<{ error: any }>()
);

export const updateUserManagementPageActionButtons = createAction(
  '[User Management] Update User Management Page Action Buttons',
  props<{
    buttons: UserManagementPageButtonConfiguration[]
  }>()
);

export const enableSaveButtonPageAction = createAction(
  '[User Management] Enable Save Button Page Action',
  props<{
    buttons: UserManagementPageButtonConfiguration[],
  }>()
);

export const resetPageAlert = createAction(
  '[User Management] Reset Page Alert',
);

export const showDiscardChangesModal = createAction(
  '[User Management] Show Discard Changes Modal',
  props<{ modalType: DiscardModalType, attemptedUrl: string }>()
);

export const userClickedDiscardChangesModalResumeButton = createAction(
  '[User Management] User Clicked Discard Changes Modal Resume Button',
);

export const userClickedDiscardChangesModalDiscardButton = createAction(
  '[User Management] User Clicked Discard Changes Modal Discard Button',
);
