import {
  userNotificationCardAdapter,
  UserNotificationsState
} from './user-notifications.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectUserNotificationsState = createFeatureSelector<UserNotificationsState>('userNotifications');

export const selectAllUserNotificationCards = createSelector(
  selectUserNotificationsState,
  state =>
    userNotificationCardAdapter
    .getSelectors()
    .selectAll(state.displayCardState)
);

export const selectUserNotificationCount = createSelector(
  selectUserNotificationsState,
  state => state.notificationCount
);
