import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';import { tapResponse } from '@ngrx/operators';

import { AssociatedSuppliersTabGridStore } from '../../associated-supplier-tab/associated-suppliers-tab-grid/associated-suppliers-tab-grid.store';
import { Store } from '@ngrx/store';
import { selectAddNoteFormValues, selectIsDraftSupplier, selectSupplierEsn } from '../../../store/supplier-detail.selectors';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { VendorBrokerManagementService } from '../../../services/vendor-broker-management.service';
import { AssociatedSupplierTabStore } from '../associated-supplier-tab.store';
import { AddNoteForm } from '../../../models/add-note-form';

export interface AssociatedSupplierDeleteState {
  isDeleting: boolean;
  hasApiError: boolean;
  apiErrorMessage: string;
}

export const initialState: AssociatedSupplierDeleteState = {
  isDeleting: false,
  hasApiError: false,
  apiErrorMessage: null,
};

const genericApiErrorText =
  'Unable to complete your request at this time. Please try again.';
@Injectable()
export class AssociatedSupplierDeleteStore extends ComponentStore<AssociatedSupplierDeleteState> {
  constructor(
    private _associatedSuppliersTabGridStore: AssociatedSuppliersTabGridStore,
    private _associatedSuppliersTabStore: AssociatedSupplierTabStore,
    private _store: Store,
    private _vendorBrokerManagementService: VendorBrokerManagementService
  ) {
    super(initialState);
  }

  // Properties
  readonly _rowsToDelete$ =
    this._associatedSuppliersTabGridStore._rowsToDelete$;

  readonly _esnsToDelete$ = this.select(this._rowsToDelete$, (rowsToDelete) => {
    return rowsToDelete?.map((row) => row.esn);
  });

  readonly _isDraftSupplier$ = this._store.select(selectIsDraftSupplier);

  private _addNoteFormValues$ = this._store.select(selectAddNoteFormValues);

  readonly _totalChildrenCount$ = this.select(
    this._rowsToDelete$,
    (rowsToDelete) => {
      if (rowsToDelete) {
        const rowsWithChildren = rowsToDelete.filter(
          (row) => row.children !== null
        );

        if (rowsWithChildren) {
          const sum = rowsWithChildren.reduce((accumulator, object) => {
            return accumulator + object.children?.length;
          }, 0);

          if (sum === null || sum === undefined) {
            return 0;
          }

          return sum;
        } else {
          return 0;
        }
      }
    }
  );

  readonly _modalTitle$ = this.select(this._rowsToDelete$, (rowsToDelete) => {
    return 'Remove Association';
  });

  readonly _modalMessageQuestion$ = this.select(
    this._rowsToDelete$,
    this._totalChildrenCount$,
    (rowsToDelete, totalChidrenCount) => {
      // Remove 1 with no children
      if (rowsToDelete?.length === 1 && totalChidrenCount === 0) {
        return `Are you sure you would like to remove the Association of <strong>${rowsToDelete[0].name}</strong> from this Supplier?`;
      }

      // Remove 1 with children
      if (rowsToDelete?.length === 1 && totalChidrenCount > 0) {
        return '<strong>Are you sure you would like to remove this Association?</strong>';
      }

      // Remove Multi with no children
      if (rowsToDelete?.length > 1 && totalChidrenCount === 0) {
        return `Are you sure you would like to remove these <strong>${rowsToDelete.length} Associations</strong> from this Supplier?`;
      }

      // Remove Multi with Children
      if (rowsToDelete?.length > 1 && totalChidrenCount > 0) {
        return `<strong>Are you sure you would like to remove these ${rowsToDelete.length} Associations?</strong>`;
      }

      return '';
    }
  );

  readonly _modalMessageDetail$ = this.select(
    this._rowsToDelete$,
    this._totalChildrenCount$,
    (rowsToDelete, totalChidrenCount) => {
      // Remove 1 with no children
      if (rowsToDelete?.length === 1 && totalChidrenCount === 0) {
        return '';
      }

      // Remove 1 with children
      if (rowsToDelete?.length === 1 && totalChidrenCount > 0) {
        return `Removing <strong>${rowsToDelete[0].name}</strong> will also remove any additional linked Suppliers that are connected to this Association.`;
      }

      // Remove Multi with no children
      if (rowsToDelete?.length > 1 && totalChidrenCount === 0) {
        return '';
      }

      // Remove Multi with Children
      if (rowsToDelete?.length > 1 && totalChidrenCount > 0) {
        return `Removing these <strong>${rowsToDelete?.length} Associations</strong> will result in the removal of any additional linked Suppliers that are connected to these Associations.`;
      }

      return '';
    }
  );

  readonly vm$ = this.select(
    this.state$,
    this._modalMessageQuestion$,
    this._modalMessageDetail$,
    this._modalTitle$,
    this._isDraftSupplier$,
    this._addNoteFormValues$,
    (state, modalMessageQuestion, modalMessageDetail, modalTitle, isDraftSupplier, addNoteForm) => ({
      ...state,
      modalMessageQuestion,
      modalMessageDetail,
      modalTitle,
      isDraftSupplier,
      isRemoveButtonDisabled: !this.isAddNoteFormValid(addNoteForm, isDraftSupplier) || state?.isDeleting
    })
  );

  isAddNoteFormValid(addNoteForm: AddNoteForm, isDraftSupplier: boolean) {
    return isDraftSupplier ? true : addNoteForm?.isValid;
  }

  // Methods
  closeModal() {
    this._associatedSuppliersTabGridStore.closeModal();
  }

  confirmDeletion$ = this.effect((_) => {
    return _.pipe(
      withLatestFrom(
        this._store.select(selectSupplierEsn),
        this._esnsToDelete$,
        this._store.select(selectAddNoteFormValues)
      ),
      switchMap(([_, supplierEsn, esnsToDelete, addNoteForm]) => {
        this.patchState({
          isDeleting: true,
          apiErrorMessage: null,
        });
        return this._vendorBrokerManagementService
          .deleteSupplierToSupplierRelationship(supplierEsn, esnsToDelete, addNoteForm?.auditNotes)
          .pipe(
            tapResponse(
              (response) => {
                this.patchState({
                  isDeleting: false,
                  hasApiError: false,
                });
                if (response.length > 0) {
                  const masterVersionNumber = response[0].masterVersionNumber;
                  this._associatedSuppliersTabStore.onAssociatedSupplierDelete(masterVersionNumber, esnsToDelete);
                  this._associatedSuppliersTabGridStore.deletedAssociatedSuppliers(esnsToDelete);            
                }
              },
              () => {
                this.patchState({
                  hasApiError: true,
                  isDeleting: false,
                  apiErrorMessage: genericApiErrorText,
                });
              }
            )
          );
      })
    );
  });
}
