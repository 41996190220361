import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { IAnnouncementsRecentLiveCount } from '../interfaces/announcements-recent-live-count.interface';
import { IRecentAnnouncement } from '../interfaces/recent-announcement.interface';
import { AnnouncementsRecentService } from '../services/announcements-recent.service';
import {
  loadRecentAnnouncements,
  loadRecentAnnouncementsError,
  loadRecentAnnouncementsSuccess,
  loadRecentLiveCount,
  loadRecentLiveCountError,
  loadRecentLiveCountSuccess
} from './announcements-recent.actions';

@Injectable()
export class AnnouncementsRecentEffects {
  constructor(private actions$: Actions, private announcementsService: AnnouncementsRecentService) {
  }

  loadRecentLiveCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecentLiveCount),
      switchMap((action) => {
        return this.announcementsService.getRecentLiveCount()
          .pipe(
            map((recentLiveCount: IAnnouncementsRecentLiveCount) =>
              loadRecentLiveCountSuccess({ recentLiveCount })),
            catchError(() => of(loadRecentLiveCountError()))
          );
      })
    )
  );

  loadRecentAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecentAnnouncements),
      switchMap((action) => {
        return this.announcementsService.getRecentLiveAnnouncements()
          .pipe(
            map((announcements: IRecentAnnouncement[]) =>
              loadRecentAnnouncementsSuccess({ announcements })),
            catchError(response =>
              of(loadRecentAnnouncementsError(response?.error?.message ?? 'Something went wrong')))
          );
      })
    )
  );
}
