import {
  TreatmentWithConfig,
  TreatmentsWithConfig,
} from "@splitsoftware/splitio/types/splitio";

export class FeatureFlag {
  constructor(
    public key: string,
    public isOn: boolean,
    public config?: string
  ) {}
}

export const FeatureFlags = {
  EnterpriseOutageBanner: new FeatureFlag('Enterprise_Top_Level_Banner', false),
  NewSyncingEverydayOrders: new FeatureFlag('CE_PRO-457_View_syncing_and_manage_progress_for_everyday_orders', false),
  SupplierDPIExportReport: new FeatureFlag('SPK-157-Export_Existing_Supplier_Data_DPI', false),
  BulkShipmentDeleteRefactor: new FeatureFlag('CE_PRO-406_Bulk_shipment_delete_refactor', false),
  PromotionalOrdersViaUpload: new FeatureFlag('CE_PRO-367_Create_draft_promotional_orders_via_upload', false),
  SupplierVendorPackets: new FeatureFlag('CSP-Supplier_Onboarding-Vendor-Packet', false),
  LockCallNewErrorMessages: new FeatureFlag('CE_Viewing_Lock_Call_Failure_Error_Messages', false),
  ValidateTemporaryUnauthorizedItems: new FeatureFlag('CE_PRM_43_Validate_temporary_unauthorized_items', false),
  NewShipmentsSearchOption: new FeatureFlag('CE_PRM_57_Modify_Search_And_Filter_Options_For_Manage_Shipments_Page', false),
  HideEDIEditSection: new FeatureFlag('CE_Hide_EDI_Edit_Section', false),
  ViewingSnapshotsCreditsVersionHistory: new FeatureFlag('CE_PRM_55_Viewing_Version_History_For_Credits', false),
  SavingSnapshotsCreditsVersionHistory: new FeatureFlag('CE_PRM-56_Save_Snapshot_For_Version_History_Of_Credits', false),
  PromotionalRoadmapAccess: new FeatureFlag('CE_RDP-139_Promotional_Roadmap_Access', false),
  AddUser: new FeatureFlag('enterprise-web-add-user', false),
  UserNotifications: new FeatureFlag(
    'user_notifictions_UMGT-1347_entperise_front_end',
    false
  ),
  UserAgreement: new FeatureFlag('connect-user-agreements', false),
  EnterPriseProductEditScheduleNonDateFields: new FeatureFlag(
    'Enterprise_Product_Edit_ScheduleNonDateFields',
    false
  ),
  CustomerWorkdayFeatures: new FeatureFlag(
    'Enterprise_Customer_Workday_Features',
    false
  ),
  CreateOrderMultiModalPendingTab: new FeatureFlag(
    'Create_Order_Multi_Modal_Pending_Tab',
    false
  ),
  EnterpriseDraftProductApplyAllEnhancement : new FeatureFlag(
    'Enterprise_New_Product_Apply_All_Enhancement',
    false
  ),
  DefaultToZipTransitTime: new FeatureFlag(
    'CSP-Supplier-SPK_DefaultToZipTransitTime',
    false
  ),
  EnterpriseSODCustomerManagement: new FeatureFlag(
    'CE_SOD_Customer_Management',
    true
  ),
  ProductComparableUpcRefreshPricing: new FeatureFlag(
    'Product_comparable_upc_refresh_pricing',
    false
  ),
  CEManageCarousels: new FeatureFlag('CE_Manage_Carousels', false),
  CEPublicationCreation: new FeatureFlag('CE_Publication_Creation', false),
  ManageAnnouncements: new FeatureFlag('Manage_Announcements', false),
  ViewAnnouncements: new FeatureFlag('View_Announcements', false),
  EnterpriseDraftProductCreatePriceGroup: new FeatureFlag(
    'Product_automate_linepricing_pricegroup',
    false
  ),
  ShowAssetManager: new FeatureFlag('Enterprise_Show_Asset_Management', false),
  WorkQueueFlag: new FeatureFlag('WQ_FF_ROUTE_GUARD', false),
  PromotionsEligibilityPage: new FeatureFlag('CE_CR-11498_Promotions_Eligibility_Page',false),
  ProductSupplierGtinValidation: new FeatureFlag('Product_Supplier_Inner_Pack_Gtin_Validator', false),
  BrokerV2: new FeatureFlag('SBM_FE_MENU_ITEM', false),
  ShipmentsBulkExport: new FeatureFlag('CE_PRO_120_Bulk_Export_Shipments',false),
  CloneShipmentToMultipleCustomers: new FeatureFlag('CE_SOM-4387_Clone_Shipment_To_Multiple_Customers',false),
  DeleteItemsFromMultipleShipments: new FeatureFlag('CE_PRO_12_Delete_items_from_multiple_shipments',false),
  ShipmentsListPagination250: new FeatureFlag('CE_PRO_100_118_Extend_Pagination_To_250',false),
  MyLists: new FeatureFlag('CE_CR-11592_MyLists_CE', false),
  EnterpriseNewProductMiscTraits: new FeatureFlag('Enterprise_New_Product_Misc_Traits',false),
  PublishMyLists: new FeatureFlag('CE_CR-10909_Publish_MyLists', false),
  ProductGroupDeliveryAddress: new FeatureFlag('Pricing_Product_Group_Delivered',false),
  SupplierOnboardingV4: new FeatureFlag('CSP-Supplier_onboarding-v4',false),
  EnterpriseIXoneSelfService: new FeatureFlag('Enterprise_IXone_Self_Service', false),
  EnterpriseProductLinkedDrafts: new FeatureFlag('Enterprise_Product_Linked_Drafts',false),
  OrdersBulkExport: new FeatureFlag('CE_PRO_177_41_Bulk_Export_Orders', false),
  ManageSponsoredProducts: new FeatureFlag('CE_ET-2924_Manage_Sponsored_Products', false),
  ShipmentsBulkItemQuantityEdit: new FeatureFlag('CE_PRO_2_Update_Quantity_Of_Items_from_multiple_shipments', false),
  ManageCustomPromotions: new FeatureFlag('CE_PC305_Manage_Custom_Promotions', false),
  SupplierPurchasingOptionsActiveEdit: new FeatureFlag('Supplier_PurchasingOptions_Active_Edit', false),
  SupplierAggregatedAuditLog: new FeatureFlag('SBM_BE_AUDIT', false),
  EnterpriseProductCatchWeightType: new FeatureFlag('Enterprise_Product_Catch_Weight_Type', false),
  EnterpriseNewProductCatchWeightType: new FeatureFlag('Enterprise_New_Product_CatchWeight_type', false),
  Export_Invoices: new FeatureFlag('CE_PCK-239_Export_Invoices', false),
  EnterpriseMassCaseGTIN: new FeatureFlag('Enterprise_Mass_Case_GTIN', false),
  EnterpriseProductCMPFAS: new FeatureFlag('Enterprise_Product_CM_PFAS', false),
  EnterpriseProductCatchWeightError: new FeatureFlag(
    'Enterprise_Product_Catch_Weight_Error',
    false
  ),
  EnterpriseProductCutAndWrap: new FeatureFlag('CutAndWrap', false),
  EnterpriseProductMassTraits: new FeatureFlag('Enterprise_Product_Mass_Traits', false),
  ProhibitRestrict: new FeatureFlag('Prohibit_Restrict', false),
  EnterpriseProductCutAndWrapInclude46: new FeatureFlag('Enterprise_product_cut_wrap_dc_45', false),
  PurchasingOptionsIgnoreBracket: new FeatureFlag('PO_FE_IGR_BCKT', false),
  PreFilledOrderEventOptions: new FeatureFlag('CE_CR-14589_PreFilled_Order_Event_Options', false),
  TransporationTabUpdates: new FeatureFlag('CE_CR-8245_Transporation_Tab_Updates', false),
  UserListModule: new FeatureFlag('CSP-Supplier_new-users-list-tab', false),
  CustomerManagementIsElectronicInvoice: new FeatureFlag('CE_QK-4132_Customer_Management_Is_Electronic_Invoice', false),
  CatchWeight: new FeatureFlag('CR_CR-15037_Catch_Weight_CE_Display', false),
  EnterpriseProductCutandWrapPricing: new FeatureFlag('CE_Prod_CutandWrap_Pricing', false),
  EnterprisePurchasingOptionRequiredPriceChanges: new FeatureFlag('Enterprise_PurchasingOption_SubmitAlwaysWithComments', false),
  BulkDeactivateCustomers: new FeatureFlag('CR_SK-860_Bulk_Customer_Inactivate', false),
  ProhibitRestrictRelease1: new FeatureFlag('Prohibit_Restrict_Release_1', true),
  ProhibitRestrictRelease2: new FeatureFlag('Prohibit_Restrict_Release_2', false),
  AllRetailerShows: new FeatureFlag('CE_SK-960_All_Retailer_Shows', false),
  SupplierDcPaymentHolds: new FeatureFlag('CSP-7465_DC_Payment_Hold', false),
  LiveShowProducts: new FeatureFlag('CE_SK-986_Live_Show_Products', false),
  RefreshProductAssets: new FeatureFlag('Request_Item_Images_from_Rivr', false),
  Chatbot: new FeatureFlag('CONNECT_Enterprise_Chatbot', false),
  ProhibitRestrictRelease3: new FeatureFlag('Prohibit_Restrict_Release_3', false),
  AAPTerm: new FeatureFlag('CSP-Supplier_AAPTerm', false),
  SupplierAAPLegalSignatures: new FeatureFlag('CSP-Supplier_AAPTerm_legal_signatures', false),
};

export function mapFeatureFlagsToSplitTreatmentsWithConfig(): TreatmentsWithConfig {
  let treatmentsWithConfig: TreatmentsWithConfig = {};
  const featureFlags: FeatureFlag[] = Object.values(FeatureFlags);
  featureFlags.forEach((featureFlag) => {
    const treatmentWithConfig: TreatmentWithConfig = {
      treatment: featureFlag.isOn ? "on" : "off",
      config: featureFlag.config,
    };
    treatmentsWithConfig[featureFlag.key] = treatmentWithConfig;
  });
  return treatmentsWithConfig;
}

export const FeatureFlagKeys = Object.values(FeatureFlags).map((f) => f.key);
