<kehe-phoenix-modal name="unsavedChangesModal" [show]="showModal$ | async" modalTitle="Unsaved Changes"
  (modalEvent)="handleuUnsavedModalEvents()" size="md">

  <div body>
    <p> {{ msg }}</p>
  </div>

  <div footer class="d-flex justify-content-end">

    <kehe-phoenix-button classAttr="btn-secondary btn-medium" class="me-2" (click)="dontSave()">
      {{secondaryButton}}
    </kehe-phoenix-button>

    <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="save()">
      {{submitButton}}
    </kehe-phoenix-button>
  </div>

</kehe-phoenix-modal>