import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContactType } from '../../../../models/contact';
import { BulkEditFormsService } from '../../services/bulk-edit-forms.service';

@Component({
  selector: 'app-bulk-edit-lead-times-delivered-address-card',
  templateUrl: './bulk-edit-lead-times-delivered-address-card.component.html',
  styleUrls: []
})
export class BulkEditLeadTimesDeliveredAddressCardComponent {

  readonly contactTypes = ContactType;
  haveDeliveredAddress = true;
    
  @Input() 
  form: UntypedFormGroup;

  get totalTime() {
    return this.form.get('totalTime');
  }

  constructor(
    private readonly _bulkEditFormService: BulkEditFormsService,
  ) {}

  handleHaveDeliveredAddress(value: boolean) {
    this.haveDeliveredAddress = value;
    if (value) {
      const addressBookForm = this._bulkEditFormService.buildDeliveredAddressForm();
      this.form.addControl('addressBook', addressBookForm);
    } else {
      this.form.removeControl('addressBook');
    }
  }
}
