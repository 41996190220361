import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { User } from '../../models/user';
import { cancelDeleteUser, deleteUser } from '../../store/supplier-users.actions';
import {
  selectDeleteUserError,
  selectDeleteUserInProgress,
  selectDeletingUser
} from '../../store/supplier-users.selectors';

@Component({
  selector: 'app-user-delete-modal',
  templateUrl: './user-delete-modal.component.html',
})
export class UserDeleteModalComponent extends Destroyable implements OnInit {
  loading = false;
  error?: string;
  user?: User;

  constructor(private readonly _store: Store) {
    super();
  }

  ngOnInit(): void {
    this._store.select(selectDeletingUser)
    .pipe(takeUntil(this.destroy$))
    .subscribe((value) => {
      this.user = value;
    });
    this._store.select(selectDeleteUserInProgress)
    .pipe(takeUntil(this.destroy$))
    .subscribe((value) => {
      this.loading = value;
    });
    this._store.select(selectDeleteUserError)
    .pipe(takeUntil(this.destroy$))
    .subscribe((value) => {
      this.error = value;
    })
  }

  closeModal() {
    return this._store.dispatch(cancelDeleteUser());
  }

  onDeleteClicked() {
    return this._store.dispatch(deleteUser({ user: this.user }));
  }
}
