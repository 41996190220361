import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { DocumentService } from '@app/services/document.service';
import { Document, DocumentUpdateDto } from './../../../models/document';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import {
  DocumentCreateMode,
  getCommonDocumentForm,
  isFormValid,
  isSproutsOrderGuide as isSproutsGuide,
  runDocumentFormValidations
} from './document-utils';
import { Observable, combineLatest } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { DocumentListPageStore } from './document-list-page.store';
import { IFileUpload } from '@kehe/phoenix-upload';
import { EditDocumentApiStore } from './api-stores/edit-document-api.store';

export interface DocumentEditModalState {
  documentToEdit: Document;
  isModelOpen: boolean;
  mode: DocumentCreateMode;

  // If there is a file set, that means user has
  // uploaded a different file than the one initally before edit
  file: IFileUpload;
}

const initialState: () => DocumentEditModalState = () => {
  const state: DocumentEditModalState = {
    documentToEdit: null,
    isModelOpen: false,
    mode: DocumentCreateMode.url,
    file: null,
  };
  return state;
};

@Injectable()
export class DocumentEditModalStore extends ComponentStore<DocumentEditModalState> {

  formGroup = getCommonDocumentForm();

  private _editDocumentStore = new EditDocumentApiStore(
    this._documentService
  );

  constructor(
    private _documentService: DocumentService,
    private _listStore: DocumentListPageStore,
    private _toast: PhoenixToastService
  ) {
    super(initialState());
    this.watchFormValidationLogic$(combineLatest([this.editMode$, this.deptTypeVal$]));
    this.handleSuccessfullDocumentEdit(this.editDocumentResponse$);
  }

  public readonly editDocumentResponse$ = this._editDocumentStore.data$;

  // Document type (when Is Sprouts) and Create mode affect the validation logic
  // and which form fields show
  watchFormValidationLogic$ = this.effect((combinedTrigger$: Observable<[DocumentCreateMode, string]>) => {
    return combinedTrigger$.pipe(
      tap(([editMode, docType]: [DocumentCreateMode, string]) => {
        setTimeout(() => {
          runDocumentFormValidations(editMode, docType, this.formGroup);
        });
      })
    );
  });

  cancel(): void {
    this.reset();
  }

  reset(): void {
    this.formGroup.reset();
    this.setState(initialState());
  }

  viewDocument(doc: Document): void {

    this._editDocumentStore.patchState({apiError: undefined});

    // Map fields and set modes to show this document
    this.formGroup.patchValue({
      documentType: doc.documentType,
      fileUrl: doc.fileUrl,
      title: doc.title,
      storeType: doc.storeType,
      distributionCenterNumber: doc.distributionCenterNumber,
      department: doc.department,
      startDate: new Date(doc.startDate),
      expireDate: new Date(doc.expireDate),
    });

    // sets mode for document form (which does not change in edit scenario, but can be toggled in create modal)
    const mode = doc.isSingleUseUrl ? DocumentCreateMode.file : DocumentCreateMode.url;

    this.patchState({
      documentToEdit: doc,
      isModelOpen: true,
      mode,
    });
  }

  fileChanged(file: IFileUpload): void {
    this.patchState((state) => {

      const newState = { ...state, file };

      // if there wasn't a file already and we're deleting,
      // we must be removing from doc object
      if (!state.file && !file) {
        return {
          ...newState,
          documentToEdit: {
            ...newState.documentToEdit,
            fileExtension: undefined,
            fileSize: undefined,
            fileUrl: undefined,
          }
        };
      }

      return newState;
    });
  }

  save$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(this.state$),
      tap(([_, state]) => {

        const formValue = this.formGroup.value;
        const id = state.documentToEdit.id;
        const isSprouts = isSproutsGuide(formValue.documentType);

        const doc: DocumentUpdateDto = {
          fileSize: state?.file?.size || state.documentToEdit.fileSize || undefined,
          documentType: formValue.documentType,
          fileUrl: formValue.fileUrl || state.documentToEdit.fileUrl,
          title: formValue.title,

          // certain fields much change for sprouts rules
          storeType: !isSprouts ? formValue.storeType : '',
          distributionCenterNumber: !isSprouts ? formValue.distributionCenterNumber : null,
          startDate: !isSprouts ? formValue.startDate : new Date(),
          expireDate: !isSprouts ? formValue.expireDate : null,
          department: isSprouts ? formValue.department : undefined,
        };

        this._editDocumentStore.save$([id, state.file, doc]);
      })
    )
  );

  onSaveSuccess(): void {
    this._listStore.reloadDocumentsWithCurrentFilter$();
    this.setState(initialState());
    this._toast.showSuccessToast('Document successfully updated!');
  }

  readonly handleSuccessfullDocumentEdit = this.effect(
    (response$: Observable<string>) =>
      response$.pipe(
        filter((response) => Boolean(response)),
        tap((_) => {
          this.onSaveSuccess();
        }),
      ),
  );

  canSubmit(
    mode: DocumentCreateMode,
    file: IFileUpload,
    documentToEdit: Document,
    formValid: boolean,
    isSaving: boolean): boolean {

    let valid = true;

    // if in file mode, there should be a file selected or already on doc
    if (mode === DocumentCreateMode.file) {
      valid = file?.size > 0 || documentToEdit?.fileSize > 0;
    }

    return formValid && valid && !isSaving;
  }

  getFileUploads(file: IFileUpload, documentToEdit: Document): IFileUpload[] {

    // if there's a new file
    if (file) {
      return [file];

      // generate preview from old file
    } else if (documentToEdit?.fileSize) {
      return [{
        progress: 0,
        uploading: false,
        subscription: null,
        uploadComplete: true,
        name: documentToEdit.title,
        extension: documentToEdit.fileExtension,
        url: documentToEdit.fileUrl,
        size: documentToEdit.fileSize
      }];
    }

    return [];
  }

  public deptTypeVal$ = this.select(this.formGroup.controls.documentType.valueChanges, (value) => value)
  public file$ = this.select(this.state$, (state) => state.file);
  public editMode$ = this.select(this.state$, (state) => state.mode);
  public isSaving$ = this.select(this._editDocumentStore.isLoading$, (isLoading) => isLoading);
  public documentToEdit$ = this.select(this.state$, (state) => state.documentToEdit);
  public isFormValid$ = this.select(this.formGroup.statusChanges, (status) => isFormValid(status));
  public isSproutsOrderGuide$ = this.select(this.formGroup.controls.documentType.valueChanges,
    (deptTypeVal) => isSproutsGuide(deptTypeVal));

  public canSubmit$ = this.select(
    this.editMode$,
    this.isFormValid$,
    this.file$,
    this.isSaving$,
    this.documentToEdit$,
    (mode, isFormValid, file, isSaving, documentToEdit) =>
      this.canSubmit(mode, file, documentToEdit, isFormValid, isSaving));

  public fileUploads$ = this.select(this.file$, this.documentToEdit$,
    (file, documentToEdit) => this.getFileUploads(file, documentToEdit));

  public vm$ = this.select(
    this.state$,
    this.isFormValid$,
    this.isSproutsOrderGuide$,
    this.canSubmit$,
    this.fileUploads$,
    this._editDocumentStore.isLoading$,
    this._editDocumentStore.apiError$,
    (state, isFormValid, isSproutsOrderGuide, canSubmit, fileUploads, isSaving, apiError) => ({
      isModalOpen: state.isModelOpen,
      doc: state.documentToEdit,
      mode: state.mode,
      isSaving,
      apiFailure: Boolean(apiError),
      isFormValid,
      isSproutsOrderGuide,
      canSubmit,
      fileUploads,
    }),
  );
}
