import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectIsFeatureFlagOn, selectHaveFeatureFlagsBeenFetched } from '@app/shared/state/feature-flag/feature-flag.selectors';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { Constants } from '../../../../constants/constants';
import { FeatureFlags } from '@app/feature-flag';

/**
 * Manage Announcement Guard, actives the route only if the user has the Feature Flag Manage_Announcements
 * and Resource Action and at least one of the Resource Actions EnterpriseAnnouncement_Write,
 * SupplierAnnouncement_Write or Retailer_Announcement_Write.
 */
@Injectable({
  providedIn: 'root'
})
export class ManageAnnouncementsGuard  {

  constructor(private router: Router,
              private store: Store) {}

  canManageAnnouncements(): Observable<boolean> {
    // Wait until feature flags are loaded
    return this.store.select(selectHaveFeatureFlagsBeenFetched)
      .pipe(
        filter((res) => res),
        switchMap((res) => {
          // Check feature flag and access rights
          return this.store.select(selectIsFeatureFlagOn(FeatureFlags.ManageAnnouncements.key))
            .pipe(
              withLatestFrom(
                this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.EnterpriseSiteAccess)),
                this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.EnterpriseAnnouncementWrite)),
                this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.SupplierAnnouncementWrite)),
                this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.RetailerAnnouncementWrite)),
              ),
              map(([isActivate, siteAccess, enterpriseWrite, supplierWrite, retailerWrite]) =>
                isActivate && siteAccess && (enterpriseWrite || supplierWrite || retailerWrite))
            );
        })
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.canManageAnnouncements()
      .pipe(map((canManage: boolean) => canManage ? canManage : this.router.parseUrl('/unauthorized')));
  }
}
