import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import * as OrderListActions from '../../../modules/orders/list/store/orders-list.actions';
import * as BulkItemQuantityEditModalActions from '../../../modules/shipments/common/components/bulk-item-quantity-edit-modal/bulk-item-quantity-edit-modal.actions';
import * as BulkDeleteItemsModalActions from '../../../modules/shipments/common/components/bulk-delete-items-modal/bulk-delete-items-modal.actions';
import { PhoenixManageProgressPanelFacadeService } from '@kehe/phoenix-top-bar';
import * as CustomerDepartmentListActions from '../../../modules/customers/customer-list/store/customer-department-list.actions';
import * as ShipmentDetailActions from '../../../modules/shipments/detail/store/shipment-detail.actions';
import * as ShipmentListActions from '../../../modules/shipments/list/store/shipments-list.actions';
import { showSupplierUploadSuccess } from '@app/modules/show-management/show-detail/modules/suppliers/store/suppliers-api.actions';
import { ExportInvoicesUploadFileActions } from '../../../modules/shipments/list/components/export-invoices-modal/api-stores/upload-file.actions';
import { ManageProgressActions } from '../../../modules/promotional-roadmap/store/promotional-roadmap.actions';
import { showRetailersExportSuccess } from '@app/modules/show-management/show-detail/modules/retailers/store/retailers-api.actions';
import { CustomPromotionsActions } from '@app/modules/custom-promotions/store/custom-promotions.actions';

@Injectable({
  providedIn: 'root',
})
export class ManageProgressEffects {
  constructor(
    private _actions$: Actions,
    private _manageProgressFacade: PhoenixManageProgressPanelFacadeService
  ) {}

  // Used to trigger manage progress call for the first time to update the user syncing icon
  triggerGetManageProgressItems$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          CustomerDepartmentListActions.uploadCustomersSuccess,
          CustomerDepartmentListActions.updateCustomersSuccess,
          OrderListActions.bulkExportOrdersSuccess,
          OrderListActions.ordersListMultiCustOrderSubmitSuccess,
          ShipmentDetailActions.cloneShipmentByChainSuccess,
          ShipmentListActions.cloneShipmentByChainSuccess,
          ShipmentListActions.exportShipmentsBulkActionSuccess,
          ShipmentListActions.deleteShipmentSuccess,
          showSupplierUploadSuccess,
          showRetailersExportSuccess,
          ExportInvoicesUploadFileActions.s3UploadSuccess,
          BulkItemQuantityEditModalActions.bulkItemQuantityEditModalEditItemsSuccess,
          BulkDeleteItemsModalActions.bulkDeleteItemsModalDeleteItemsSuccess,
          ManageProgressActions.triggerManageProgress,
          CustomPromotionsActions.triggerManageProgress,
          CustomerDepartmentListActions.exportSummaryCustomerListSuccess,
          CustomerDepartmentListActions.exportDetailedSuccess
        ),
        tap(() => this._manageProgressFacade.requestItems())
      ),
    { dispatch: false }
  );
}
