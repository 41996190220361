import { Store } from '@ngrx/store';
import {
  clearUserNotificationsAction,
  getUserNotificationsAction
} from './user-notifications.actions';
import { Observable } from 'rxjs';
import {
  selectAllUserNotificationCards,
  selectUserNotificationCount
} from './user-notifications.selectors';
import { UserNotificationCard } from '@kehe/phoenix-top-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsFacade {
  constructor(
    private store: Store
  ) {}

  loadUserNotifications(): void {
    this.store.dispatch(getUserNotificationsAction());
  }

  getLoadedUserNotificationCards(): Observable<UserNotificationCard[]> {
    return this.store.select(selectAllUserNotificationCards);
  }

  getUserNotificationCount(): Observable<number> {
    return this.store.select(selectUserNotificationCount);
  }

  clearUserNotifications(notificationIds: number[]): void {
    this.store.dispatch(clearUserNotificationsAction({ notificationIds }));
  }
}
