import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductGroup } from '../../../supplier-detail/models/product-group';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Product } from '../../models/product';
import { DCProductGroup } from '../../../supplier-detail/models/dc-product-group';
import { getDCStatusITagColor } from '../../utils/products-util';

@Component({
  selector: 'app-pg-products-modal',
  templateUrl: './pg-products-modal.component.html',
  styleUrls: ['./pg-products-modal.component.scss']
})
export class PgProductsModalComponent implements OnInit {

  @Input() productGroup: ProductGroup;
  @Input() dcProductGroup: DCProductGroup;
  @Output() closeModal = new EventEmitter<void>();

  public sort: SortDescriptor[] = [
    {
      field: 'brandName',
      dir: 'asc'
    }
  ];

  get products() {
    if (this.productGroup) {
      return this.productGroup?.products || [];
    }
    if (this.dcProductGroup) {
      return this.dcProductGroup?.products || [];
    }
    return [];
  }
  
  ngOnInit(): void {
    this.sortProducts();
  }

  title() {
    if (this.productGroup) {
      return `${this.productGroup.name} (${this.productGroup.productGroupNumber})`;
    }
    if (this.dcProductGroup) {
      return `${this.dcProductGroup.dcDisplayName} - ${this.dcProductGroup.dcProductGroupNumber}`;
    }
    return '--';
  }

  onClose() {
    this.closeModal.emit();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.sortProducts();
  }

  sortProducts() {
    if (this.productGroup) {
      this.productGroup.products = orderBy(this.productGroup.products || [], this.sort);
    }
    if (this.dcProductGroup) {
      this.dcProductGroup.products = orderBy(this.dcProductGroup.products || [], this.sort);
    }
  }

  onClickUPC(product: Product) {
    window.open(`/products/${product.upc}`, "_blank");
  }

  getStatus(status) {
    const color = getDCStatusITagColor(status);
    return {
      text: status,
      bgColor: color,
      borderColor: color
    };
  }
}

