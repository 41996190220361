<div *ngIf="!isFormReady">
  <kehe-phoenix-loading></kehe-phoenix-loading>
</div>

<!-- using the hidden class because the nested components are required before isFormReady is true -->
<div [hidden]="!isFormReady">
  <div class="form-container">
    <div [formGroup]="form">
      <div class="row">
        <div class="col">
          <h4>Add DC Product Group</h4>
        </div>
      </div>
      <hr class="mt-0">
      <div class="row">
        <div class="col">
          <h5>Distribution Centers</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <kendo-formfield>
            <kendo-label [for]="dcField" text="Distribution Center"></kendo-label>
            <kendo-combobox
              #dcField
              (focus)="onFocus(dcField)"
              [data]="dcList"
              (valueChange)="dcValueChange($event)"
              [filterable]="true"
              [kendoDropDownFilter]="{operator: 'contains'}"
              [textField]="'code'"
              [valueField]="'value'"
              [valuePrimitive]="true"
              formControlName="distributionCenterNumber"
              placeholder="Select a Distribution Center"
            ></kendo-combobox>
            <kendo-formerror *ngIf="distributionCenterNumberField?.errors?.required">Distribution Center is required.</kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col">
          <h5>Buyers</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="mt-3 mt-md-0">
            <kendo-formfield>
              <kendo-label [for]="buyerField" text="Buyer"></kendo-label>
              <kendo-combobox
                #buyerField
                (focus)="onFocus(buyerField)"
                formControlName="buyerCode"
                (valueChange)="buyerValueChange($event)"
                [data]="buyerList"
                [textField]="'name'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                placeholder="Search by Buyer name or number"
              ></kendo-combobox>
              <kendo-formerror *ngIf="buyerCodeField?.errors?.required">Buyer is required.</kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="mt-3" *ngIf="canShowBuyerRegionEmail">
            <kendo-formfield>
              <kendo-label text="Buyer Email"></kendo-label>
              <kendo-textbox formControlName="buyerEmail" placeholder="e.g. contact@email.com">
              </kendo-textbox>
              <kendo-formerror *ngIf="buyerEmailField?.errors?.required">
                Buyer Email is required.
              </kendo-formerror>
              <kendo-formerror *ngIf="!buyerEmailField?.errors?.email && buyerEmailField?.errors?.isInvalid">
                Must be a valid KeHE email.
              </kendo-formerror>
              <kendo-formerror *ngIf="buyerEmailField?.errors?.email">
                Invalid email
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="mt-3 mt-md-0" *ngIf="canShowBuyerRegionEmail">
            <kendo-formfield>
              <kendo-label text="Buyer Region"></kendo-label>
              <kendo-combobox #buyerSelectionField (focus)="onFocus(buyerSelectionField)" formControlName="buyerRegion" [data]="buyerRegions" required
                              [filterable]="true" placeholder="Select Buyer Region" [kendoDropDownFilter]="{ operator: 'contains'}">
              </kendo-combobox>
              <kendo-formerror *ngIf="buyerRegionField?.errors?.required">Buyer Region is required.</kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
      </div>
      <hr>
    </div>

    <app-product-group-delivery-form
      #deliveryGroup
      [address]="tempAddress"
      [supplier]="supplier"
      [action]="actionTypes.AddDCPG"
      [showContactCard]="showContactCard"
      (pickupAddressSelected)="pickupAddressSelected($event)">
    </app-product-group-delivery-form>

    <ng-container>
      <div class="row">
        <div class="col">
          <h5>PO Transmissions and Details</h5>
        </div>
      </div>
      <app-product-group-po-transmissions-form #poTransmissionsGroup [hasPOOrder]="isDraftSupplier">
      </app-product-group-po-transmissions-form>
    </ng-container>
  </div>
</div>
