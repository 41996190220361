import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DcPaymentHoldsService } from '../services/dc-payment-holds.service';
import { SupplierDetailService } from '@app/modules/supplier-detail/services/supplier-detail.service';
import { catchError, delay, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { selectSupplierDetail } from '@app/modules/supplier-detail/store/supplier-detail.selectors';
import { selectSupplierDcPaymentHoldsChangedPaymentHolds } from './supplier-dc-payment-holds.selectors';

import * as DistributionCenterSelectors from '@app/shared/state/distribution-center/distribution-center.selectors';
import * as SupplierDcPaymentHoldActions from './supplier-dc-payment-holds.actions';
import * as SupplierDetailActions from '@app/modules/supplier-detail/store/supplier-detail.actions';

@Injectable()
export class SupplierDcPaymentHoldsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly dcPaymentHoldsService: DcPaymentHoldsService,
    private readonly supplierService: SupplierDetailService,
  ) {}

  saveDcPaymentHolds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SupplierDcPaymentHoldActions.userClickedSaveDcPaymentHoldChanges),
      withLatestFrom(
        this.store.select(selectSupplierDetail),
        this.store.select(selectSupplierDcPaymentHoldsChangedPaymentHolds),
      ),
      switchMap(([_, supplierDetail, changedPaymentHolds]) => {
        return this.dcPaymentHoldsService
          .updateDcPaymentHolds(
            supplierDetail.esn,
            supplierDetail.masterVersionNumber++,
            changedPaymentHolds,
          )
          .pipe(
            map((supplier) =>
              SupplierDcPaymentHoldActions.saveDcPaymentHoldsSuccess({
                supplierDetail: supplier,
              }),
            ),
            catchError(() =>
              of(SupplierDcPaymentHoldActions.saveDcPaymentHoldsError()),
            ),
          );
      }),
    );
  });

  refreshSupplierAfterActivation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SupplierDetailActions.submitSupplierSuccess),
      delay(2500),
      withLatestFrom(
        this.store.select(selectSupplierDetail),
        this.store.select(DistributionCenterSelectors.selectDistributionCentersTrimmedNameAndNumber)
      ),
      switchMap(([_, detail, dcList]) =>
        this.supplierService.getSupplierDetail(detail.esn).pipe(
          map((supplier) => SupplierDetailActions.loadSupplierDetailSuccess({ supplier, dcList })),
          catchError(() => of(SupplierDetailActions.loadSupplierDetailError()))
        ),
      ),
    );
  });
}
