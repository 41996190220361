<ng-container *ngIf="formGroup">
  <div [formGroup]="formGroup">
    <div class="form-group">
      <kendo-label [for]="publicationType" text="Publication Type"></kendo-label>
      <kendo-combobox [valuePrimitive]="true" [clearButton]="false" #publicationType formControlName="documentType"
        [data]="publicationTypes" textField="label" valueField="value" placeholder="Select Publication Type"></kendo-combobox>
    </div>

    <ng-container *ngIf="isUrlCreate">
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="url" text="File URL"></kendo-label>
          <kendo-textbox #url name="url" formControlName="fileUrl" placeholder="Enter URL"></kendo-textbox>
          <kendo-formerror>URL is required.</kendo-formerror>
        </kendo-formfield>
      </div>
    </ng-container>

    <ng-container *ngIf="isFileCreate">
      <div class="form-group">
        <kendo-label text="Upload"></kendo-label>
        <kehe-phoenix-upload
        [fileUploads]="fileUploads"
        [uploadRestrictions]="uploadRestrictions"
        (addFiles)="handleAddFiles($event)"
        (removeFiles)="handleRemoveFiles($event)">
      </kehe-phoenix-upload>
      </div>
    </ng-container>

    <div class="form-group">
      <kendo-formfield>
        <kendo-label [for]="displayName" text="Title (Display Name)"></kendo-label>
        <kendo-textbox formControlName="title" #displayName placeholder="Enter Title or Display Name"></kendo-textbox>
        <kendo-formerror>Title is required.</kendo-formerror>
      </kendo-formfield>
    </div>

    <ng-container *ngIf="!isSproutsOrderGuide">
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="storeType" text="Store Type"></kendo-label>
          <kendo-combobox [valuePrimitive]="true" [clearButton]="false" #storeType name="storeType" [data]="storeTypes"
            textField="label" valueField="value" placeholder="Select Store Type"
            formControlName="storeType"></kendo-combobox>
          <kendo-formerror>Store Type is required.</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="distributionCenter" text="Distribution Center"></kendo-label>
          <kendo-combobox [valuePrimitive]="true" [clearButton]="false" #distributionCenter
            formControlName="distributionCenterNumber" [data]="distributionCenters" textField="label" valueField="value"
            placeholder="Select Distribution Center"></kendo-combobox>
          <kendo-formerror>Distribution Center is required.</kendo-formerror>
        </kendo-formfield>
      </div>
    </ng-container>

    <ng-container *ngIf="isSproutsOrderGuide">
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="department" text="Department"></kendo-label>
          <kendo-combobox [valuePrimitive]="true" [clearButton]="false" #department name="department"
            [data]="departmentList" textField="label" valueField="value" placeholder="Select Department"
            formControlName="department"></kendo-combobox>
          <kendo-formerror>Department is required.</kendo-formerror>
        </kendo-formfield>
      </div>
    </ng-container>

    <ng-container *ngIf="!isSproutsOrderGuide">
      <div class="form-group-row">
        <div class="form-group">
          <kendo-formfield>
            <kendo-label text="Start Date"></kendo-label>
            <kendo-datepicker formControlName="startDate" [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }"
              [calendarType]="'classic'" placeholder="Choose Date"></kendo-datepicker>
            <kendo-formerror>Start Date is required.</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="form-group">
          <kendo-formfield>
            <kendo-label text="End Date"></kendo-label>
            <kendo-datepicker formControlName="expireDate"
              [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }" [calendarType]="'classic'"
              placeholder="Choose Date"></kendo-datepicker>
            <kendo-formerror>End Date is required.</kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>