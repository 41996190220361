import { createAction, props } from '@ngrx/store';
import { Document } from '../models/document';
import { DocumentGetUploadUrlResponse } from '../models/document-get-upload-url-response';
import { DocumentType } from '../models/document-type';
import { DocumentUploadRequest } from '../models/document-upload-request';
import { SupplierData } from '../models/supplier-data';
import { PendoEvent } from '../models/pendo-event.interface';

export const loadSupplierDocumentsData = createAction(
    '[Supplier Documents] Load Supplier Documents Data',
    props<{ supplier: SupplierData }>()
);
export const hideDocumentsAlertMessage = createAction(
  '[Supplier Documents] Hide Alerts'
);
// Load Documents
export const loadDocuments = createAction(
    '[Supplier Documents] Load Documents',
);
export const loadDocumentsSuccess = createAction(
    '[Supplier Documents] Load Documents Success',
    props<{ documents: Document[] }>()
);
export const loadDocumentsError = createAction(
    '[Supplier Documents] Load Documents Error',
);
export const confirmDeleteDocument = createAction(
    '[Supplier Documents] Confirm Delete Document',
    props<{ document: Document }>()
);
export const cancelDeleteDocument = createAction(
    '[Supplier Documents] Cancel Delete Document',
);
export const deleteDocument = createAction(
    '[Supplier Documents] Delete Document',
);
export const deleteDocumentSuccess = createAction(
    '[Supplier Documents] Delete Document Success',
    props<{ document: Document }>()
);
export const deleteDocumentError = createAction(
    '[Supplier Documents] Delete Document Error',
);
export const loadDocumentTypesSuccess = createAction(
    '[Supplier Documents] Load Document Types Success',
    props<{ documentTypes: DocumentType[] }>()
);
export const loadDocumentTypesError = createAction(
    '[Supplier Documents] Load Document Types Error',
);
export const updateDocumentTypeUploadStatus = createAction(
    '[Supplier Documents] Update Document Type Upload Status',
    props<{ documentTypes: DocumentType[] }>()
);
export const downloadDocument = createAction(
    '[Supplier Documents] Download Document',
    props<{ document: Document, target?: string }>()
);
export const downloadDocumentCompleted = createAction(
    '[Supplier Documents] Download Document Completed',
    props<{ document: Document }>()
);
export const downloadDocumentFailed = createAction(
  '[Supplier Documents] Download Document Failed'
);
export const downloadPrivateAssets = createAction(
    '[Supplier Documents] Download Private Assets',
    props<{ fileName: string }>()
);
export const downloadNewContractTemplate = createAction(
    '[Supplier Documents] Download New Contract Template',
);
export const updateDocumentStatus = createAction(
    '[Supplier Documents] Update Document Status',
    props<{ document: Document }>()
);
export const updateDocumentStatusSuccess = createAction(
    '[Supplier Documents] Update Document Status Success',
    props<{ document: Document, pendoEvent: PendoEvent }>()
);
export const updateDocumentStatusError = createAction(
    '[Supplier Documents] Update Document Status Error',
    props<{ errorCode: number, document: Document }>()
);
export const confirmRejectDocument = createAction(
    '[Supplier Documents] Confirm Reject Document',
    props<{ document: Document }>()
);
export const cancelRejectDocument = createAction(
    '[Supplier Documents] Cancel Reject Document',
);
export const showRejectDocumentFeedback = createAction(
    '[Supplier Documents] Reject Document Feedback',
    props<{ document: Document }>()
);
// Load Documents

// Upload Documents
export const cancelUploadDocumentsModal = createAction(
    '[Supplier Documents] Cancel Upload Documents Modal',
);
export const resetUploadDocumentsModal = createAction(
    '[Supplier Documents] Reset Upload Documents Modal',
);
export const uploadAndSaveDocument = createAction(
    '[Supplier Documents] Upload And Save Document',
    props<{ uploadDocumentRequest: DocumentUploadRequest }>()
);
export const uploadDocumentToS3 = createAction(
    '[Supplier Documents] Upload Document To S3',
    props<{ uploadDocumentRequest: DocumentUploadRequest, documentUploadUrlResponse: DocumentGetUploadUrlResponse }>()
);
export const createDocument = createAction(
    '[Supplier Documents] Create Document',
    props<{ uploadDocumentRequest: DocumentUploadRequest, documentUploadUrlResponse: DocumentGetUploadUrlResponse }>()
);
export const uploadAndSaveDocumentSuccess = createAction(
    '[Supplier Documents] Upload And Save Document Success',
  props<{ document?: Document; }>()
);
export const uploadAndSaveDocumentError = createAction(
    '[Supplier Documents] Upload And Save Document Error',
);
// Upload Documents
