import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of, timer } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import * as AuthenticationActions from './authentication.actions';
import * as CallBackPageActions from './components/callback-page/callback-page.actions';
import * as AuthenticationSelectors from '../shared/state/authentication/authentication.selectors';
import * as AppActions from '../app.actions';
import * as FeatureFlagActions from '@app/feature-flag/feature-flag.actions';
import { UserManagementService } from '@app/modules/user-management/services/user-management.service';
import { PhoenixToastService } from "@kehe/phoenix-notifications";
import { AuthenticationState } from "./authentication-state";

@Injectable()
export class AuthenticationEffects {

    constructor(
        private actions$: Actions, 
        private authenticationService: AuthenticationService, 
        private router: Router,
        private store: Store,
        private userManagementService: UserManagementService,
        private toastService: PhoenixToastService,
    ) {}

     /*
        This will dispatch the login success event when the app is loaded and the user has been previously authenticated
     */
    getUser$ = createEffect(() => {
        return this.authenticationService.getUser().pipe(
            filter(user => !!user),
            map(user => AuthenticationActions.logInSuccess({user})),
        )
    });

    redirectUnauthenticatedUserToLogin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AuthenticationActions.userNotAuthenticated,
                AuthenticationActions.handleRedirectError,
            ),
            tap(action => this.authenticationService.login(action.url)),
        )
    }, { dispatch: false });

    handleAuthenticationCallback$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CallBackPageActions.entered),
            exhaustMap(() => this.authenticationService.handleRedirect().pipe(
                map(user => AuthenticationActions.logInSuccess({user})),
                catchError(() => of(AuthenticationActions.handleRedirectError({ url: ''})))
            ))
        )}
    );

    handleLoginRedirect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            filter(action => !!action.user.state),
            map(action => action.user.state as AuthenticationState),
            tap(state => this.router.navigateByUrl(state.redirectUrl)), 
        )
    }, { dispatch: false });

    getConnectLegacyToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            exhaustMap(() => this.authenticationService.getLegacyToken().pipe(
                map(response => AuthenticationActions.getLegacyTokenSuccess({ legacyToken: response.data })),
            ))
        )
    });

    logOut$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AuthenticationActions.logOut,
                AppActions.logOut,
                ),
            tap(() => this.authenticationService.logout())
        )
    }, { dispatch: false });

    logOutUserOnTokenExpiration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AuthenticationActions.logInSuccess,
            ),
            withLatestFrom(this.store.select(AuthenticationSelectors.selectTokenExpiration)),
            filter(([, tokenExpiration]) => !!tokenExpiration),
            switchMap(([, tokenExpiration]) => {
                return timer(tokenExpiration).pipe(
                    map(() => AuthenticationActions.logOut())
                );
            }),
        )
    });

    getUserDetail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AuthenticationActions.logInSuccess,
                FeatureFlagActions.getFeatureFlagSuccess,
            ),
            withLatestFrom(
                this.store.select(AuthenticationSelectors.selectUserEmail)
            ),
            exhaustMap(([,email]) => this.userManagementService.getUserDetails(email).pipe(
                map((details) => AuthenticationActions.getUserDetailSuccess({ user: details.data })),
                catchError(() => of(AuthenticationActions.getUserDetailFailed()))
            ))
        );
    });

    getUserDetailFailed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.getUserDetailFailed),
            map(() => this.toastService.showErrorToast('Something went wrong, please try reloading the page!'))
        )
    }, { dispatch: false });

    logOutExternalUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            withLatestFrom(this.store.select(AuthenticationSelectors.selectIsInternalUser)),
            filter(([action, isUserInternal]) => !isUserInternal),
            map(() => AuthenticationActions.logOut())
        )
    }, { dispatch: false });

}