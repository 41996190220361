import { Injectable } from '@angular/core';

declare let ga: any;

@Injectable()
export class GaService {

  public emit(category: string,
    action: string,
    label: string = null,
    val: number = null) {
      ga('send', 'event', {
        eventCategory: category,
        eventLabel: label,
        eventAction: action,
        eventValue: val
    });
  }
}
