import { createSelector } from '@ngrx/store';
import { selectDcPaymentHoldGridVm } from '../dc-payment-hold-grid/dc-payment-hold-grid.selectors';
import { selectEditMode } from '@app/modules/supplier-detail/store/supplier-detail.selectors';
import { selectUserDetail } from '@app/shared/state/authentication/authentication.selectors';
import { DcPaymentHoldDetails } from '@app/modules/supplier-detail/models/dc-payment-hold-details';
import { UserDetailRecord } from '@app/modules/user-management/model/service/user-management';

export interface DcPaymentHoldFormVM {
  paymentHolds: DcPaymentHoldDetails[];
  initialHolds: DcPaymentHoldDetails[];
  userData: UserDetailRecord;
}

export const selectDcPaymentHoldFormVm = createSelector(
  selectDcPaymentHoldGridVm,
  selectUserDetail,
  // the edit mode change will refresh the data set
  selectEditMode,
  (vm, userData): DcPaymentHoldFormVM => {
    // we make a copy of the objects so we can cancel our changes to the data set
    return {
      paymentHolds: vm.paymentHolds.map((x) => ({ ...x })),
      initialHolds: vm.paymentHolds,
      userData,
    };
  },
);
