export interface WorkQueueFilter {
  id?: number;
  code: string;
  value: string;
  filterType: WorkQueueFilterType;
}

export enum WorkQueueFilterType {
  TypeFilter = 'TypeFilter',
  AssignToFilter = 'AssignToFilter',
  Priority = 'Priority',
  StatusFilter = 'StatusFilter',
  CategoryManagerFilter = 'CategoryManagerFilter',
}

export interface WorkQueueFilterResponse {
  typeFilters: WorkQueueFilter[];
  assignToFilters: WorkQueueFilter[];
  statusFilters: WorkQueueFilter[];
  categoryManagerFilters: WorkQueueFilter[];
}
