import { Component } from '@angular/core';
import { AssociateNewSupplierModalStore, AssociateSupplierFormControlName } from './associate-new-supplier-modal.store';
import { SearchSuppliersApiStore } from '../api-stores/search-suppliers-api.store';

@Component({
  selector: 'app-associate-new-supplier-modal',
  templateUrl: './associate-new-supplier-modal.component.html',
  styleUrls: ['./associate-new-supplier-modal.component.scss'],
  providers: [AssociateNewSupplierModalStore, SearchSuppliersApiStore],
})
export class AssociateNewSupplierModalComponent {
  vm$ = this._componentStore.vm$;
  form = this._componentStore.form;
  controlName = AssociateSupplierFormControlName;

  constructor(private _componentStore: AssociateNewSupplierModalStore) {}

  handleKeheModalEvents(closeEvent) {
    if (closeEvent.type === 'close') {
      this.close();
    }
  }

  close() {
    this._componentStore.closeModal();
  }

  get selectedSupplier() {
    return this._componentStore?.form?.get(
      AssociateSupplierFormControlName.Supplier
    )?.value;
  }

  submit() {
    this._componentStore.addSupplierToSupplierRelationship$();
  }

  supplierFilterChanged(searchTerm: string) {
    this._componentStore.supplierFilterChanged(searchTerm);
  }

  get formErrorText() {
    return Object.values(this.form.controls)?.some(
      (control) =>
        control?.hasError('required') && (control?.touched || control?.dirty)
    )
      ? 'There are field(s) below that require your attention.'
      : null;
  }
}
