<ng-container *ngIf="vm$ | async as vm">
  <div
    class="kehe-grid no-scroll p-0 w-100"
    [ngClass]="{ 'kehe-grid-responsive': vm.isMedAndBelowView }"
  >
    <kendo-grid
      [sortable]="{ allowUnsort: false, mode: 'single' }"
      [hideHeader]="true"
      [data]="vm.childrenRows"
    >
      <kendo-grid-column [width]="15" [sortable]="false">
        <ng-template kendoGridCellTemplate let-supplierItem> </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="15"
        [sortable]="false"
        [headerClass]="'kehe-grid-header-hover'"
      >
        <ng-template kendoGridCellTemplate let-supplierItem> </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="30"
        [locked]="false"
        [sortable]="false"
        field="esn"
      >
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-children-table-esn-header">ESN</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <span>
            <div>
              <a
                class="link-cell"
                [routerLink]="['/supplier', supplierItem.esn]"
                target="_blank"
                data-automation-id="associated-suppliers-children-table-esn"
                >{{ supplierItem.esn | emptyValue }}
              </a>
            </div>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="90"
        [locked]="false"
        field="name"
      >
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-children-table-supplier-name-header">Supplier Name</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <span data-automation-id="associated-suppliers-children-table-supplier-name">
            {{ supplierItem.name }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="50"
        [locked]="false"
        [sortable]="false"
        field="location"
      >
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-children-table-location-header">Location</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <span data-automation-id="associated-suppliers-children-table-location">
            {{ supplierItem.location | emptyValue }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="50"
        [locked]="false"
        field="status"
        title="Status"
      >
      <ng-template kendoGridHeaderTemplate let-dataItem>
        <span data-automation-id="associated-suppliers-children-table-status-header">Status</span>
      </ng-template>
        <div class="status-tag">
          <ng-template kendoGridCellTemplate let-supplierItem>
            <kehe-phoenix-tag [tag]="supplierItem.tag" classAttr="large"
                      automationId="associated-suppliers-children-table-status"
            ></kehe-phoenix-tag>
          </ng-template>
        </div>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="60"
        [locked]="false"
        field="dateAssociated"
        title="Date Associated"
      >
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-children-table-date-associated-header">Date Associated</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <span data-automation-id="associated-suppliers-children-table-date-associated-header">
            {{ supplierItem.dateAssociated | date : 'MM/dd/yyyy' | emptyValueBlank }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="15" [sortable]="false">
        <ng-template kendoGridCellTemplate let-supplierItem> </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</ng-container>
