import { Component } from '@angular/core';
import { IKeheModalEvent } from '@kehe/phoenix-modal';
import { BrokerTabV2DeleteConfirmationModalStore } from './broker-tab-v2-delete-confirmation-modal.store';

@Component({
  selector: 'app-delete-broker-v2-modal',
  templateUrl: './broker-tab-v2-delete-confirmation-modal.component.html',
  styleUrls: ['./broker-tab-v2-delete-confirmation-modal.component.scss'],
  providers: [BrokerTabV2DeleteConfirmationModalStore],
})
export class BrokerTabV2DeleteConfirmationModalComponent {
  constructor(
    private _componentStore: BrokerTabV2DeleteConfirmationModalStore
  ) {}

  public vm$ = this._componentStore.vm$;

  closeModal() {
    this._componentStore.closeModal();
  }

  handleKeheModalEvents(closeEvent: IKeheModalEvent) {
    this.closeModal();
  }

  confirmDeletion() {
    this._componentStore.confirmDeletion$();
  }
}
