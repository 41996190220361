import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { PersonContact } from '../../models/person-contact';
import { cancelManageSupplierContact, saveSupplierContact } from '../../store/supplier-contacts.actions';
import {
  selectAssignPrimaryContact,
  selectIsSavingContact,
  selectSaveContactError
} from '../../store/supplier-contacts.selectors';

@Component({
  selector: 'app-assign-primary-contact-modal',
  templateUrl: './assign-primary-contact-modal.component.html',
  styleUrls: []
})
export class AssignPrimaryContactModalComponent extends Destroyable implements OnInit {

  warning = 'There can only be one Primary Contact. This will replace the previously assigned Primary Contact.';
  contact: PersonContact;
  inProgress = false;
  error = null;

  constructor(
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectAssignPrimaryContact))
      .subscribe((val) => {
        this.contact = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectIsSavingContact))
      .subscribe((val) => {
        this.inProgress = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSaveContactError))
      .subscribe((val) => {
        this.error = val;
      });
  }

  closeModal() {
    this._store.dispatch(cancelManageSupplierContact());
  }

  onAssignClicked() {
    const request = {
      uniqueId: this.contact.uniqueId,
      email: this.contact.email,
      esn: this.contact.esn,
      isPrimary: true,
    };
    this._store.dispatch(saveSupplierContact({ contact: request }));
  }
}
