import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as SupplierContactsActions from './supplier-contacts.actions';
import { SupplierContactsService } from '../services/supplier-contacts.service';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectDeleteContact, selectESN, selectSupplierContacts } from './supplier-contacts.selectors';
import { PhoenixToastService } from '@kehe/phoenix-notifications';

@Injectable()
export class SupplierContactsEffects {

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _supplierContactsService: SupplierContactsService,
    private _toastService: PhoenixToastService,
  ) { }

  loadSupplierContactTypes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierContactsActions.loadSupplierContacts),
      switchMap((action) => {
        return this._supplierContactsService.getContacts(action.esn).pipe(
          map((contacts) => SupplierContactsActions.loadSupplierContactsSuccess({ contacts })),
          catchError(error => of(SupplierContactsActions.loadSupplierContactsError()))
        );
      })
    )
  );

  saveSupplierContact$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierContactsActions.saveSupplierContact),
      withLatestFrom(
        this._store.pipe(select(selectESN)),
        this._store.pipe(select(selectSupplierContacts)),
        ),
      switchMap(([action, esn, contacts]) => {
        return this._supplierContactsService.saveContact(esn, action.contact).pipe(
          map((contact) => {
            if (action.contact.uniqueId) {
              this._toastService.showSuccessToast('Contact has been updated.', false, 5000);
            } else {
              this._toastService.showSuccessToast('A new contact has been added.', false, 5000);
            }
            if (action.contact.isPrimary) {
              const existingPrimary = contacts.some(item => item.isPrimary);
              if (existingPrimary) {
                this._toastService.showSuccessToast('Primary Contact has been updated.', false, 5000);
              } else {
                this._toastService.showSuccessToast('Primary Contact has been assigned.', false, 5000);
              }
            }
            if (action.contact.subscribedToDiscontinuedProductNotification) {
              const existingDiscNotification = contacts.some(item => item.subscribedToDiscontinuedProductNotification);
              if (existingDiscNotification) {
                this._toastService.showSuccessToast('Discontinued Product Notification recipient has been updated.', false, 5000);
              } else {
                this._toastService.showSuccessToast('Discontinued Product Notification recipient has been assigned.', false, 5000);
              }
            }
            return SupplierContactsActions.saveSupplierContactSuccess({ contact });
          }),
          catchError(error => of(SupplierContactsActions.saveSupplierContactError({ errorCode: error.status })))
        );
      })
    )
  );

  // Delete Contact
  deleteSupplierContacts$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierContactsActions.deleteSupplierContact),
      withLatestFrom(this._store.pipe(select(selectDeleteContact))),
      switchMap(([action, contact]) => {
        return this._supplierContactsService.deleteContact(contact).pipe(
          map(() => SupplierContactsActions.deleteSupplierContactSuccess({ contact })),
          catchError(error => of(SupplierContactsActions.deleteSupplierContactError()))
        );
      })
    )
  );
}
