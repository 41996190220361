import { PersonContactTypes } from '../enums/person-contact-types';

export function getRequiredContacts(
  supplierOnboardingV4Flag: boolean,
  isInvitedDraftSupplier: boolean
): { name: string; type: PersonContactTypes }[] {
  const requiredContacts: { name: string; type: PersonContactTypes }[] = [
    {
      name: 'Sales Contact',
      type: PersonContactTypes.Sales,
    },
    {
      name: 'Supply Chain Contact',
      type: PersonContactTypes.SupplyChain,
    },
  ];
  if (supplierOnboardingV4Flag && isInvitedDraftSupplier) {
    requiredContacts.push(
      {
        name: 'Accounting / Financial Contact',
        type: PersonContactTypes.AccountingOrFinancial,
      },
      {
        name: 'Unloading Discrepancy Report Contact',
        type: PersonContactTypes.UDR,
      }
    );
  }
  return requiredContacts;
}
