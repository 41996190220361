import { selectSupplierDetail } from '@app/modules/supplier-detail/store/supplier-detail.selectors';
import { selectActiveDistributionCentersTrimmedNameAndNumber } from '@app/shared/state/distribution-center/distribution-center.selectors';
import { createSelector } from '@ngrx/store';

export const selectDcPaymentHoldGridVm = createSelector(
  selectActiveDistributionCentersTrimmedNameAndNumber,
  selectSupplierDetail,
  (dCs, detail) => {
    let paymentHolds = []

    if (detail.hasDcPaymentHoldDetails) {
      paymentHolds = (
        detail.hasDcPaymentHoldDetails ? detail.dcPaymentHoldDetails : []
      ).map((dcHold) => {
        return {
          ...dcHold,
          dcName: (dCs ?? []).find((dc) => dc.value === dcHold.dcNumber)?.label ?? dcHold.dcNumber,
        };
      });
    } else {
      paymentHolds = dCs.map((dc) => {
        return {
          dcNumber: dc.value,
          dcName: dc.label,
        }
      })
    }

    return {
      paymentHolds,
    };
  },
);
