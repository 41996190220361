import { Injectable } from '@angular/core';
import { PermissionsService } from '@app/services/permissions.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Constants } from '../../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class UserManagementGuard  {
  constructor(private permissionsService: PermissionsService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.permissionsService.userHasAction(Constants.UserActions.UserRead)
  }
}
