import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, retry } from 'rxjs/operators';
import { DistributionCenterResponse } from './distribution-center';

@Injectable()
export class DistributionCenterService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.dcApiUrl;
   }

  getList(): Observable<DistributionCenterResponse[]> {
    const url = `${this._url}/distributioncenterlist`;
    return this.httpClient.get<DistributionCenterResponse[]>(url);
  }

  public getAvailableDistributionCenters() {
    // Param is used to remove KEHE DIST - from DC Name in the response
    const params = new HttpParams().set('formattednames', true);
    return this.httpClient
      .get<Array<DistributionCenterResponse>>(this._url + '/distributioncenterdropdown', {
        params: params,
      })
      .pipe(
        retry(2),
        catchError((err) => throwError(err)),
        map((dcs: DistributionCenterResponse[]) => dcs),
      );
  }
}
