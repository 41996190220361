@if (vm$ | async; as vm) {
  <div class="kehe-grid no-scroll p-0 w-100 mt-2">
    <kendo-grid [data]="vm.paymentHolds" [pageable]="false">
      <kendo-grid-column title="Distribution Center" field="dcName" width="434">
      </kendo-grid-column>

      <kendo-grid-column
        title="Release Date"
        width="173"
      >
        <ng-template kendoGridCellTemplate let-paymentHold>{{
          (paymentHold.paymentHoldReleaseDate | date: 'MM/dd/yyyy') ?? '--'
        }}</ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Payment Hold" width="250">
        <ng-template kendoGridCellTemplate let-paymentHold>
          <ul class="k-radio-list">
            <li class="k-radio-item">
              <input
                #hasPaymentHoldYes
                kendoRadioButton
                type="radio"
                [name]="'hasPaymentHold' + paymentHold.dcNumber"
                [value]="true"
                [(ngModel)]="paymentHold.hasPaymentHold"
                (change)="resetRowReleaseDate(paymentHold, vm)"
              />
              <kendo-label class="ml-2" [for]="hasPaymentHoldYes">Yes - On Payment Hold</kendo-label>
            </li>
            <li class="k-radio-item">
              <input
                #hasPaymentHoldNo
                kendoRadioButton
                type="radio"
                [name]="'hasPaymentHold' + paymentHold.dcNumber"
                [value]="false"
                [(ngModel)]="paymentHold.hasPaymentHold"
                (change)="updateRowReleaseDate(paymentHold, vm)"
              />
              <kendo-label class="ml-2" [for]="hasPaymentHoldNo">No - Not on Payment Hold</kendo-label>
            </li>
          </ul>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
}
