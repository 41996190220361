<app-add-product-group-modal *ngIf="showModal$ | async"></app-add-product-group-modal>
<div>
  <div class="row">
    <div class="col">
      <h4>Product Groups</h4>
    </div>
  </div>
  <div class="row mt-3 mb-2">
    <div class="col-md-5 col-lg-6 mb-2 mb-md-0">
      <kehe-phoenix-chip-list *ngIf="productGroupsFilterChips.length > 0" [chips]="productGroupsFilterChips"
                  (chipClicked)="filterChipClicked($event)"
                  (clearAllChips)="filterChipClearAllClicked()"></kehe-phoenix-chip-list>
    </div>
    <div class="col-md-7 col-lg-6 text-right">
      <div class="list-search-filter">
        <kehe-phoenix-search placeholder="Search" [formControl]="searchControl"></kehe-phoenix-search>
        <kehe-phoenix-filter-button [count]="productGroupsFilterChips?.length" (click)="openFilterPanel()"></kehe-phoenix-filter-button>
      </div>
    </div>
  </div>
  <div>
    <div class="kehe-grid no-scroll p-0 w-100" [class.kehe-grid-responsive]="isMedAndBelow$ | async">
      <kendo-grid class="kehe-grid-expandable" [data]="productGroups" [sortable]="{
                allowUnsort: false,
                mode: 'single'
              }" [hideHeader]="isMedAndBelow$ | async" [sort]="sort" (sortChange)="sortChange($event)">

        <kendo-grid-column title="" [width]="40" headerClass="header-expander" [class]="'col-expander'"
                           [sortable]="false">

          <!-- header template -->
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div>
              <em
                class="fas fa-chevron-down kehe-grid-expander-header"
              ></em>
            </div>
          </ng-template>

          <!-- cell template -->
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div (click)="expandGroupRow(dataItem, rowIndex)">
              <em class="kehe-grid-expander fas" [ngClass]="getIconClass(rowIndex)"></em>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="productGroupNumber" title="Product Group" filter="string">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="link-cell" (click)="onClickName(dataItem.productGroupNumber);">{{ dataItem.name }}</div>
            <!-- Show inline label on SM (and smaller) when there are no more col headers -->
            <div><ng-container *ngIf="(isSmallAndBelow$ | async) === true">PG:&nbsp;</ng-container>({{ dataItem.productGroupNumber }})</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="DC Product Group" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div><ng-container *ngIf="(isMedAndBelow$ | async) === true">DCPG:&nbsp;</ng-container>{{ getPurchaseOrderGroupCount(dataItem) }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="(isMedAndBelow$ | async) === false" title="Brands" [sortable]="false" headerClass="text-center" [width]="70">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="!canShowProductsData">{{ getBrandsCount(dataItem) }}</div>
            <div *ngIf="canShowProductsData" class="text-center">
              <div *ngIf="dataItem.brands && dataItem.brands.length > 0;else no_pg_brands_content"
                class="link-cell" (click)="onClickPGBrandsCount(dataItem);">
                {{ dataItem.brands.length }}
              </div>
              <ng-template #no_pg_brands_content>0</ng-template>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="(isMedAndBelow$ | async) === false" title="Temp. Class" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>{{ getPGTempClass(dataItem) }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="canShowProductsData && (isMedAndBelow$ | async) === false" title="UPC Count" [sortable]="false" headerClass="text-center">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="text-center">
              <div *ngIf="dataItem.products && dataItem.products.length > 0;else no_products_content"
                class="link-cell" (click)="onClickPGUPCCount(dataItem);">
                {{ dataItem.products.length }}
              </div>
              <ng-template #no_products_content>0</ng-template>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="(isMedAndBelow$ | async) === false" title="Buyer" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>{{ getBuyerCount(dataItem) }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="(isSmallAndBelow$ | async) === false" title="Del. Method" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>{{ getDeliveryMethods(dataItem) }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="(isMedAndBelow$ | async) === false" [title]="pickupAddressColumnTitle$ | async" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>--</div>
          </ng-template>
        </kendo-grid-column>

        <!-- Row expander template for DC Product Groups -->
        <ng-template kendoGridDetailTemplate let-dataItem>
          <div class="container group-expander">
            <div class="row expander-row" *ngFor="let item of sortedDCProductGroups(dataItem.dcProductGroups)">
              <div class="expander-dummy"></div>
              <div class="col expander-row-item">
              </div>
              <div class="col expander-row-item">
                <div>{{item.dcDisplayName}}</div>
                <div>({{item.dcProductGroupNumber}})</div>
              </div>

              <div *ngIf="(isMedAndBelow$ | async) === false" class="col expander-row-item text-center expander-row-count-item">
                <div *ngIf="canShowProductsData && item.productBrands && item.productBrands.length > 0;else no_dcpg_brands_content"
                  class="link-cell" (click)="onClickDCPGBrandsCount(item);">
                  {{ item.productBrands.length }}
                </div>
                <ng-template #no_dcpg_brands_content>0</ng-template>
              </div>

              <div *ngIf="(isMedAndBelow$ | async) === false" class="col expander-row-item">
                <div>{{ getDCPGTempClass(item) }}</div>
              </div>

              <div *ngIf="canShowProductsData && (isMedAndBelow$ | async) === false" class="col expander-row-item text-center">
                <div *ngIf="item.products && item.products.length > 0;else no_dc_pg_products_content"
                  class="link-cell" (click)="onClickDCPGUPCCount(item);">
                  {{ item.products.length }}
                </div>
                <ng-template #no_dc_pg_products_content>0</ng-template>

              </div>

              <div *ngIf="(isMedAndBelow$ | async) === false" class="col expander-row-item">
                <div>{{ getBuyer(item) }}</div>
              </div>

              <div *ngIf="(isSmallAndBelow$ | async) === false" class="col expander-row-item">
                {{ getDeliveryMethod(item) }}
                <ng-container *ngIf="item.isZipTransitTime && !isDelivered(item)">
                  <br/>
                  ({{ inboundRouteTypeName(item.inboundRouteType) }})
                </ng-container>
              </div>

              <div *ngIf="(isMedAndBelow$ | async) === false" class="col expander-row-item">
                <div *ngIf="(!item.pickupAddress) else address">--</div>
                <ng-template #address>
                  <div>{{item.pickupAddress.city | emptyValue}}</div>
                  <div>{{item.pickupAddress.state?.code}} {{item.pickupAddress.zipCode}}</div>
                </ng-template>
              </div>

            </div>
          </div>
        </ng-template>

        <ng-template kendoGridNoRecordsTemplate>
          <div class="custom-no-results-msg">
            <em class="fas fa-exclamation-triangle" aria-hidden="true"></em>
            <div class="custom-msg-container">
              <span class="no-results">No Product Groups</span>
              <kehe-phoenix-button (click)="onAddClick()" classAttr="btn-primary btn-medium" [disabled]="!canAddProductGroup">
                Add Product Group
              </kehe-phoenix-button>
            </div>
          </div>
        </ng-template>

      </kendo-grid>
    </div>
  </div>
</div>
<app-product-groups-filter-slide-panel></app-product-groups-filter-slide-panel>

<app-pg-products-modal [productGroup]="showPGProductsModal" *ngIf="showPGProductsModal" (closeModal)="closePGProductsModal()">
</app-pg-products-modal>
<app-pg-products-modal [dcProductGroup]="showDCPGProductsModal" *ngIf="showDCPGProductsModal" (closeModal)="closePGProductsModal()">
</app-pg-products-modal>

<app-pg-brands-modal [productGroup]="showPGBrandsModal" *ngIf="showPGBrandsModal" (closeModal)="closePGBrandsModal()">
</app-pg-brands-modal>
<app-pg-brands-modal [dcProductGroup]="showDCPGBrandsModal" *ngIf="showDCPGBrandsModal" (closeModal)="closePGBrandsModal()">
</app-pg-brands-modal>
