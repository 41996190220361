<div *ngIf="editNewAddDCProductGroup">
  <app-add-new-product-group-form></app-add-new-product-group-form>
</div>
<div *ngIf="!editNewAddDCProductGroup" [ngSwitch]="selectedProductGroupView">
  <div *ngSwitchCase="views.ProductGroupView">
    <app-product-groups-view *ngIf="!isEdit"></app-product-groups-view>
    <app-product-groups-form *ngIf="isEdit"></app-product-groups-form>
  </div>
  <div *ngSwitchCase="views.DCProductGroupView">
    <app-product-groups-dc-form *ngIf="isEdit"></app-product-groups-dc-form>
  </div>
  <div *ngSwitchCase="views.AddDCProductGroupView">
    <app-add-dc-product-group-form>
    </app-add-dc-product-group-form>
  </div>
  <div *ngSwitchCase="views.CloneDCProductGroupView">
    <app-clone-dcpg-form></app-clone-dcpg-form>
  </div>
  <div *ngSwitchDefault>
  </div>
</div>
