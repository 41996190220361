<kehe-phoenix-delete-modal
  modalTitle="Delete File"
  [isDeleteInProgress]="isDeleting"
  (modalEvent)="handleKeHEModalEvents()"
  (cancelClicked)="closeModal()"
  (deleteClicked)="onDeleteClicked()"
  size="md"
>
  <div class="p-1">
    <kehe-phoenix-alert
      [type]="alertTypes.Warning"
      [message]="alertMessage"
      [hideCloseIcon]="true"
      stick="true"
      show="true"
    >
    </kehe-phoenix-alert>
    <p>Are you sure you would like to delete the following file?</p>
    <strong>{{ document?.filename || '--' }}</strong>
  </div>
</kehe-phoenix-delete-modal>
