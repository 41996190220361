import { Action, createReducer, on } from '@ngrx/store';

import { IAnnouncementsRecentLiveCount } from '../interfaces/announcements-recent-live-count.interface';
import { IRecentAnnouncement } from '../interfaces/recent-announcement.interface';
import {
  loadRecentAnnouncements,
  loadRecentAnnouncementsError,
  loadRecentAnnouncementsSuccess,
  loadRecentLiveCountError,
  loadRecentLiveCountSuccess
} from './announcements-recent.actions';

export class AnnouncementsRecentState {
  loading: boolean;
  recentLiveCount: IAnnouncementsRecentLiveCount;
  recentAnnouncements: IRecentAnnouncement[];
  recentAnnouncementsError: string;
}

export const initializeState = (): AnnouncementsRecentState => ({
  loading: false,
  recentLiveCount: null,
  recentAnnouncements: [],
  recentAnnouncementsError: null
});

export const initialState = initializeState();

const rootReducer = createReducer(
  initialState,

  on(loadRecentLiveCountSuccess, (state, {recentLiveCount}) => ({
    ...state,
    recentLiveCount,
  })),

  on(loadRecentLiveCountError, state => ({
    ...state,
    recentLiveCount: null
  })),

  on(loadRecentAnnouncements, state => ({
    ...state,
    loading: true,
    recentAnnouncements: null,
    recentAnnouncementsError: null
  })),

  on(loadRecentAnnouncementsSuccess, (state, { announcements }) => ({
    ...state,
    loading: false,
    recentAnnouncements: announcements
  })),

  on(loadRecentAnnouncementsError, (state, { msg }) => ({
    ...state,
    loading: false,
    recentAnnouncements: [],
    recentAnnouncementsError: msg
  }))
);

export function reducer(state: AnnouncementsRecentState | undefined, action: Action) {
  return rootReducer(state, action);
}

export const announcementsRecentFeatureKey = 'announcementsRecent';
