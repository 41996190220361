import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectDocuSignSigner, selectSupplierDetail } from '../supplier-detail.selectors';
import { SupplierDocuSignService } from '../../services/supplier-docusign.service';
import { forkJoin, of } from 'rxjs';
import { selectDocusignDocuments } from '@app/modules/supplier-documents/store/supplier-documents.selectors';
import { SupplierDocumentsService } from '@app/modules/supplier-documents/services/supplier-documents.service';
import { isSupplierInReview } from '../../utilities/supplier-utils';
import { SupplierDetailService } from '../../services/supplier-detail.service';

import * as SupplierDetailActions from '../supplier-detail.actions';
import { PendoEvent } from '../../models/pendo-event.interface';
import { PendoTrackEvents } from '../../models/pendo-track-events.enum';

@Injectable()
export class SupplierDocusignEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,

    private readonly supplierDocuSignService: SupplierDocuSignService,
    private readonly supplierDocumentsService: SupplierDocumentsService,
    private readonly supplierDetailService: SupplierDetailService,
  ) {}

  sendDocuSignEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.sendDocuSignEmail),
      withLatestFrom(
        this.store.select(selectSupplierDetail),
        this.store.select(selectDocuSignSigner)
      ),
      switchMap(([, detail, signer]) => {
        return this.supplierDocuSignService
          .sendDocuSignEmail(detail.esn, signer)
          .pipe(
            map((supplier) =>
              SupplierDetailActions.sendDocuSignEmailSuccess({ supplier })
            ),
            catchError(() =>
              of(SupplierDetailActions.sendDocuSignEmailError())
            )
          );
      })
    )
  );

  invalidateDocusign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.invalidateDocuSign),
      filter((action) => action.rejected),
      withLatestFrom(
        this.store.select(selectSupplierDetail),
        this.store.select(selectDocusignDocuments)
      ),
      switchMap(([action, supplier, docusign]) => {
        // we map the docusign docs to http requests and push the final invalidate docusign request through
        const requests: any = docusign.map((document) => {
          return this.supplierDocumentsService.deleteDocument(document);
        });

        if (isSupplierInReview(supplier)) {
          requests.push(this.supplierDetailService.rejectReview(supplier.esn));
        }
        requests.push(
          this.supplierDetailService.save({
            esn: supplier.esn,
            docuSignData: {
              signee: supplier.docuSignData?.signee,
              status: null,
              sentdate: null,
              envelopeid: null,
              rejecteddate: null,
              completeddate: null,
            },
            updatedBySource: 'Enterprise',
          })
        );

        return forkJoin(requests).pipe(
          map(() => {
            const event: PendoEvent = {
              name: PendoTrackEvents.UpdateSupplierGeneralTabStatus,
              eventProperties: {
                esn: supplier.esn,
                biTimestamp: Date.now(),
                tab: action.status.tab,
                tabStatus: action.status.tabStatus,
                requesterType: supplier.requesterType,
                isInternational: supplier.hasInternationalCurrency,
                invitedDate: supplier.created,
                isDpiInitiated: supplier.isDpiInitiated,
                cmName: supplier.categoryManagerFirstName + " " + supplier.categoryManagerLastName
              }
            }
            return SupplierDetailActions.updateGeneralTabStatusSuccess({
              status: action.status,
              pendoEvent: event
            });
          }),
          catchError(() =>
            of(SupplierDetailActions.updateGeneralTabStatusError())
          )
        );
      })
    )
  );
}
