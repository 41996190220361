import { Component } from '@angular/core';
import { DocumentCreateModalStore } from '@app/pages/document-list-page/store/document-create-modal.store';
import { DocumentCreateMode } from '@app/pages/document-list-page/store/document-utils';
import { IFileUpload } from '@kehe/phoenix-upload';

@Component({
  selector: 'app-document-create-modal',
  templateUrl: './document-create-modal.component.html',
  styleUrls: ['./document-create-modal.component.scss']
})
export class DocumentCreateModalComponent {

  vm$ = this._store.vm$;
  formGroup = this._store.formGroup;

  constructor(
    private _store: DocumentCreateModalStore
  ) {

  }

  cancel(): void {
    this._store.cancel();
  }

  get documentCreateMode(): typeof DocumentCreateMode {
    return DocumentCreateMode;
  }

  onModeChange(mode: DocumentCreateMode): void {
    this._store.modeChange(mode);
  }

  onFileChanged(file: IFileUpload): void {
    this._store.fileChanged(file);
  }

  save(): void {
    this._store.save$();
  }

}
