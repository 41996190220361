import { Component, OnInit } from '@angular/core';
import {
  AddBrokerFormStore,
  BrokerFormControlName,
} from './add-broker-v2-form.store';
import { AddBrokerModalStore } from './add-broker-v2-modal.store';
import { SearchHeadquartersBrokersApiStore } from './api-stores/search-headquarters-brokers.api.store';
import { BrokerContactsApiStore } from './api-stores/broker-contacts.api.store';

@Component({
  selector: 'app-add-broker-v2-modal',
  templateUrl: './add-broker-v2-modal.component.html',
  styleUrls: ['./add-broker-v2-modal.component.scss'],
  providers: [
    AddBrokerModalStore,
    AddBrokerFormStore,
    SearchHeadquartersBrokersApiStore,
    BrokerContactsApiStore,
  ],
})
export class AddBrokerV2ModalComponent implements OnInit {
  vm$ = this._store.vm$;
  form = this._store.form;
  controlName = BrokerFormControlName;

  constructor(private _store: AddBrokerModalStore) {}

  ngOnInit(): void {
    this.initializeBrokerType();
  }

  close() {
    this._store.closeModal();
  }

  submit() {
    this._store.submitModal();
  }

  handleKeheModalEvents(closeEvent) {
    if (closeEvent.type === 'close') {
      this.close();
    }
  }

  headquarterBrokerFilterChanged(searchTerm: string) {
    this._store.headquarterBrokerFilterChanged(searchTerm);
  }

  brokerContactFilterChanged(searchTerm: string) {
    this._store.brokerContactFilterChanged(searchTerm);
  }

  nonHeadquarterBrokerFilterChanged(searchTerm: string) {
    this._store.nonHeadquarterBrokerFilterChanged(searchTerm);
  }

  getAutomationId(automationText: string, automationSuffixText: string) {
    return 'add-broker-v2-modal-' + automationText?.split(' ')?.join('-')?.toLowerCase() + automationSuffixText;
  }

  get formErrorText() {
    return Object.values(this.form.controls)?.some(
      (control) =>
        control?.hasError('required') && (control?.touched || control?.dirty)
    )
      ? 'There are field(s) below that require your attention.'
      : null;
  }

  initializeBrokerType() {
    this._store.initializeBrokerType$();
  }
}
