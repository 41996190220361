import { IKeheTab } from '@kehe/phoenix-tabs';
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { Payload } from '../../../models/payload';
import { TransitLaneFilter } from '../models/transit-lane.filter';
import { TransitLane } from '../models/transit-lane.model';
import { IFileUpload } from '@kehe/phoenix-upload';
import { MassUpdateDetail } from '../../supplier-mass-update/models/mass-update-detail';
import { TransitLaneJobFilter } from '../models/transit-lane-job.filter';
import { TransitLaneJob } from '../models/transit-lane-job.model';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';

export const TransitLanePageUserActions = createActionGroup({
  source: 'Transit Lane Page User Actions',
  events: {
    clickToggleSideNav: emptyProps(),
    clickTab: props<{ tab: IKeheTab; }>(),
  },
});

export const TransitTableTabUserActions = createActionGroup({
  source: 'Transit Table Tab User Actions',
  events: {
    clickTab: props<{ tab: IKeheTab; }>(),
    clickPageChange: props<{ filter: TransitLaneFilter; }>(),
    clickSortColumn: props<{ filter: TransitLaneFilter; }> (),
    enterSearch: props<{ filter: TransitLaneFilter; }>(),
    clickFilter: emptyProps(),
    clickChip: props<{ filter: TransitLaneFilter; }>(),
    clickClearAllChips: props<{ filter: TransitLaneFilter; }>(),
    selectTab: props<{ tab: IKeheTab; }>(),
    clickDownloadTemplate: emptyProps(),
    clickExportTransitLanes: emptyProps(),
  }
});

export const TransitTableTabUploadsUserActions = createActionGroup({
  source: 'Transit Table Tab Uploads User Actions',
  events: {
    clickPageChange: props<{ filter: TransitLaneJobFilter; }>(),
    clickSortColumn: props<{ filter: TransitLaneJobFilter; }> (),
    enterSearch: props<{ filter: TransitLaneJobFilter; }>(),
    clickDownloadJobFile: props<{ job: TransitLaneJob; }>()
  }
})

// << Upload Zip Code Transit
export const UploadZipCodeTransitActions = createActionGroup({
  source: 'Upload Zip Code Transit Actions',
  events: {
    clickUploadButton: emptyProps(),
    clickCancelUpload: emptyProps(),
    uploadTransitTimeForm: props<{
      updates: Omit<any, 'uniqueId' | 'fileName'>;
      file: IFileUpload;
    }>(),
    createTransitTimeJob: props<{ updates: any }>(),
    createTransitTimeJobSuccess: props<{ job: MassUpdateDetail }>(),
    createTransitTimeJobFailed: props<{ message: string; }>(),
    resetTransitTimeJobError: emptyProps(),
  },
});
// Upload Zip Code Transit >>

export const TransitLaneEffectActions = createActionGroup({
  source: 'Transit Lane Effect Actions',
  events: {
    updateUploadsFilter: props<{ filter: TransitLaneJobFilter; }>(),
    getUploadsListSuccess: props<{ data: Payload<TransitLaneJob[]> }>(),
    getUploadsListFailed: props<{ error: Error; }>(),
    refreshUploads: emptyProps(),

    updateTransitLaneFilter: props<{ filter: TransitLaneFilter; }>(),
    getTransitLaneListSuccess: props<{ data: Payload<TransitLane[]> }>(),
    getTransitLaneListFailed: props<{ error: Error; }>(),

    exportTransitLanesSuccess: props<{ url: string; filename: string; }>(),
    exportTransitLanesFailed: emptyProps()
  }
});

export const TransitLaneFilterPanelUserActions = createActionGroup({
  source: 'Transit Lane Filter Panel User Actions',
  events: {
    closeFilterPanel: emptyProps(),
    clickApply: props<{ filter: TransitLaneFilter; }>()
  }
});

export const updateKendoBreadCrumbsList = createAction(
  '[Transit Lane] Update BreadCrumbs List',
  props<{ items: BreadCrumbItem[] }>()
);

export const TransitLaneGridMoreButtonActions = createActionGroup({
  source: 'Transit Lane Grid More Button Actions',
  events: {
    clickDownloadReport: props<{ job: TransitLaneJob; }>(),

    clickDeleteJob: props<{ job: TransitLaneJob; }>(),
    clickCancelDelete: emptyProps(),
    deleteTransitTimeJob: emptyProps(),
    deleteTransitTimeJobSuccess: emptyProps(),
    deleteTransitTimeJobFailed: props<{ message: string; }>(),
    resetDeleteTransitTimeJobError: emptyProps(),

    clickPublishJob: props<{ job: TransitLaneJob; }>(),
    clickCancelPublish: emptyProps(),
    publishTransitTimeJob: emptyProps(),
    publishTransitTimeJobSuccess: emptyProps(),
    publishTransitTimeJobFailed: props<{ message: string; }>(),
  }
});
