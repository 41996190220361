<div class="px-4">

  <div class="row">
    <div class="col">
      <h4>Users</h4>
    </div>
  </div>

  <div class="row kehe-data-section">
    <div class="col-12 my-2">
      <div class="row mb-2">
        <div class="col-12 kehe-data-value">
          <p>
            Add your KeHE CONNECT Users below. Users added below are granted access and editing capabilities to your
            ESN.
            If the user hasn’t signed up for KeHE CONNECT, they will receive an invitation to register before logging
            in.
          </p>
          <p class="mb-0">
            <strong>Note: </strong> Only a CONNECT Admin can add or delete users. Assign the CONNECT Admin role to other
            users to allow them to add and delete additional users.
            There can be more than one CONNECT Admin.
          </p>
        </div>
      </div>
    </div>

    <div class="col-12"  *ngIf="loadingUsers">
      <kehe-phoenix-loading></kehe-phoenix-loading>
    </div>

    <div class="col-12" *ngIf="!loadingUsers">
      <div class="row mt-3 mb-2">
        <div class="col-md-5 col-lg-6 mb-2 mb-md-0">
        </div>
        <div class="col-md-7 col-lg-6 text-right">
          <kehe-phoenix-button (click)="onAddClick()" classAttr="btn-primary btn-medium">
            Add
          </kehe-phoenix-button>
        </div>
      </div>
      <div class="kehe-grid no-scroll p-0 w-100">
        <kendo-grid [data]="users" [sortable]="{
                  allowUnsort: false,
                  mode: 'single'
                }" [sort]="sort" (sortChange)="sortChange($event)" colum>
          <kendo-grid-column title="" filter="string" [width]="42">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="d-flex justify-content-center">
                <kehe-phoenix-menu-button [menuOptions]="menuOptionMap[dataItem.email]" btnClass="btn-link"
                  *ngIf="!dataItem.isSyncing" title="More Options"
                  (optionSelected)="moreOptionSelected($event, dataItem)">
                </kehe-phoenix-menu-button>
                <div *ngIf="dataItem.isSyncing" class="tooltip-icon">
                  <em title="Syncing" class="fas fa-sync-alt fa-spin"></em>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="" filter="string" [width]="42">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="d-flex justify-content-center">
                <em title="CONNECT Admin" class="fas fa-star" *ngIf="dataItem.isAdmin"></em>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="name" title="Name" [sortable]="true" headerClass="kehe-grid-header-hover" filter="string">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.name | emptyValue }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="email" title="Email" [sortable]="true" headerClass="kehe-grid-header-hover" filter="string">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.email | emptyValue }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="status" title="Status" [sortable]="true" headerClass="kehe-grid-header-hover" filter="string">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="d-flex">
                <div *ngIf="dataItem.statusTag;else empty_content">
                  <kehe-phoenix-tag classAttr="large" [tag]="dataItem?.statusTag"></kehe-phoenix-tag>
                </div>
                <ng-template #empty_content>--</ng-template>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="" filter="string" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="text-center">
                <span class="fas fa-trash-alt" (click)="deleteUser(dataItem)"
                  [ngClass]="{'disabled': shouldDeleteBeDisabled(dataItem) }"
                  [title]="shouldDeleteBeDisabled(dataItem)? 'Delete (Disabled)': 'Delete'"></span>
              </div>
            </ng-template>
          </kendo-grid-column>

          <ng-template kendoGridNoRecordsTemplate>
            <div class="custom-no-results-msg">
              <em class="fas fa-exclamation-triangle mb-3" aria-hidden="true"></em>
              <div class="custom-msg-container">
                <span class="no-results mb-3">No Users</span>
              </div>
            </div>
          </ng-template>

        </kendo-grid>
      </div>
    </div>
  </div>
</div>
<app-add-user-wizard *ngIf="showAddUserModal"></app-add-user-wizard>
<app-user-delete-modal *ngIf="showDeleteUserModal"></app-user-delete-modal>