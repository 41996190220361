<kehe-phoenix-modal name="AddNote" modalTitle="Add Note" [show]="true" (modalEvent)="handleKeheModalEvents($event)"
  size="md">
  <div body class="add-note-body" [formGroup]="form">

    <kehe-phoenix-alert [type]="alertTypes.Warning" [message]="selectedChangeType.alert"
      *ngIf="selectedChangeType && selectedChangeType.alert" [hideCloseIcon]="true" [stick]="true" [show]="true">
    </kehe-phoenix-alert>

    <p>You have made changes. Add details below explaining the request origin.</p>

    <div *ngIf="isTerms()">
      <div class="mb-3">
        <kendo-formfield>
          <kendo-label [for]="changeTypeField" text="Change Type"></kendo-label>
          <kendo-combobox #changeTypeField (focus)="onFocus(changeTypeField)" [data]="changeTypes" [filterable]="true"
            [kendoDropDownFilter]="{operator: 'contains'}" [textField]="'text'" [valueField]="'value'"
            formControlName="changeType" placeholder="Select Change Type"></kendo-combobox>
          <kendo-formerror>Change Type is required.</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="mb-3" *ngIf="selectedChangeType && selectedChangeType.hasContractDate">
        <kendo-formfield>
          <kendo-label [for]="contractDateFormField" text="Contract Date"></kendo-label>
          <kendo-datepicker #contractDateFormField formControlName="contractDate" placeholder='mm/dd/yyyy'
            inputName="contractDate" [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }"
            [format]="'MM/dd/yyyy'">
          </kendo-datepicker>
          <kendo-formerror>Contract Date is required.</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="mb-3">
      <kendo-formfield>
        <kendo-label [for]="ticketTxt" text="Ticket Number (optional)"></kendo-label>
        <kendo-textbox #ticketTxt placeholder="e.g. CWI-867-5309" formControlName="ticket"></kendo-textbox>
      </kendo-formfield>
    </div>
    <div class="mb-3">
      <kendo-formfield>
        <kendo-label [for]="requestReasonTextArea" text="Request Reason"></kendo-label>
        <kendo-textarea #requestReasonTextArea resizable="auto" placeholder="Enter Request Reason"
          formControlName="notes"></kendo-textarea>
        <kendo-formerror>Request Reason is required.</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div footer class="add-note-footer">
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-medium btn-link" (click)="cancelModal()">
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-medium btn-primary" (click)="save()"
      [disabled]="!this.form.valid || isSaveInProgress" [spinner]="isSaveInProgress">
      Save Changes
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
