import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Constants } from '../../../../../constants/constants';
import { Store, select } from '@ngrx/store';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SalesBusinessHierarchyGuard  {
    constructor(
        private router: Router,
        private store: Store,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(
            select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.SalesHierarchyRead)),
            withLatestFrom(this.store.select(AuthenticationSelectors.selectUserActions)),
            filter(([_, userActions]) => userActions?.length > 0),
            map(([userHasSalesHierarchyReadRA]) => userHasSalesHierarchyReadRA || this.router.parseUrl(''))
        )
    }
}
