import { createAction, props } from "@ngrx/store";
import { BulkModalShipmentProductsDto } from "../../models/bulk-modal-shipment-products-dto.interface";
import { ProductInventory } from "../../models/product-inventory.interface";
import { ShipmentProductsSingleProduct } from "../../models/shipment-products-single-product.interface";
import { ShipmentProducts } from "../../models/shipment-products.interface";

export const bulkItemQuantityEditModalGetShipmentsAndProducts = createAction(
  '[Bulk Item Quantity Edit Modal] Get Shipments And Products',
  props<{ filter: BulkModalShipmentProductsDto }>()
);

export const bulkItemQuantityEditModalGetShipmentsAndProductsSuccess = createAction(
  '[Bulk Item Quantity Edit Modal] Get Shipments And Products Success',
  props<{ shipmentsAndProducts: Array<ShipmentProducts> }>()
);

export const bulkItemQuantityEditModalGetShipmentsAndProductsFailure = createAction(
  '[Bulk Item Quantity Edit Modal] Get Shipments And Products Failure',
);

export const bulkItemQuantityEditModalGoBackToPageOne = createAction(
  '[Bulk Item Quantity Edit Modal] Go Back To Page One'
);

export const bulkItemQuantityEditModalSelectAllChanged = createAction(
  '[Bulk Item Quantity Edit Modal] Select All Changed'
);

export const bulkItemQuantityEditModalSelectOneChanged = createAction(
  '[Bulk Item Quantity Edit Modal] Select One Changed',
  props<{ shipmentId: number }>()
);

export const bulkItemQuantityEditModalGoToPageThree = createAction(
  '[Bulk Item Quantity Edit Modal] Go To Page Three'
);

export const bulkItemQuantityEditModalGoBackToPageTwo = createAction(
  '[Bulk Item Quantity Edit Modal] Go Back To Page Two'
);

export const bulkItemQuantityEditModalEditItems = createAction(
  '[Bulk Item Quantity Edit Modal] Edit Items'
);

export const bulkItemQuantityEditModalEditItemsSuccess = createAction(
  '[Bulk Item Quantity Edit Modal] Edit Items Success'
);

export const bulkItemQuantityEditModalEditItemsFailure = createAction(
  '[Bulk Item Quantity Edit Modal] Edit Items Failure'
);

export const getQuantityOnHandSuccess = createAction(
  '[Bulk Item Quantity Edit Modal] Get Quantity On Hand Success',
  props<{ products: ProductInventory[] }>()
);

export const getQuantityOnHandFailure = createAction(
  '[Bulk Item Quantity Edit Modal] Get Quantity On Hand Failure'
);

export const updateEditedQuantityForSingleProduct = createAction(
  '[Bulk Item Quantity Edit Modal] Update Edited Quantity For Single Product',
  props<{ product: ShipmentProductsSingleProduct}>()
);
