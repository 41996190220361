import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SupplierDetailService } from '../../services/supplier-detail.service';
import { withLatestFrom, switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { selectSupplierDetail } from '../supplier-detail.selectors';
import { PhoenixToastService } from '@kehe/phoenix-notifications';

import * as SupplierDetailActions from '../supplier-detail.actions';
import { ContactType } from '@app/models/contact';
import { applyDCPGBulkEdit } from '@app/modules/product-groups/store/product-groups.actions';

@Injectable()
export class SupplierContactsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,

    private readonly supplierDetailService: SupplierDetailService,
    private readonly toastService: PhoenixToastService,
  ) {}

  addAddressToSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.addSupplierContact),
      withLatestFrom(this.store.select(selectSupplierDetail)),
      switchMap(([action, supplierDetails]) => {
        const form = {
          contacts: [action.contact],
          esn: supplierDetails.esn,
          masterVersionNumber: supplierDetails.masterVersionNumber,
          updatedBySource: 'Supplier',
        };
        return this.supplierDetailService.saveContact(form).pipe(
          map((supplier) => {
            if (action?.contactType === ContactType.DeliveredAddress) {
                return applyDCPGBulkEdit(action?.bulkEditPayload)
            } else {
              return SupplierDetailActions.addSupplierContactSuccess({
                contacts: supplier.contacts,
                created: action.contact,
              })
            }
          }
          ),
          catchError((error) =>
            of(
              SupplierDetailActions.addSupplierContactError({
                errorCode: error.status,
              })
            )
          )
        );
      })
    )
  );

  addSupplierContactSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SupplierDetailActions.addSupplierContactSuccess),
        map(() => {
          this.toastService.showSuccessToast(
            'A New Address has Been Added',
            false,
            5000
          );
        })
      ),
    { dispatch: false }
  );
}
