import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { DocumentService } from '@app/services/document.service';
import { DocumentActionOptions, formatByteSize } from './document-utils';
import { FilterStore } from '@app/abstract/base-filter.store';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, filter, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { Document } from './../../../models/document';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { KeheErrorConfig, KeheErrorMessages, KeheErrorSizes, KeheMascotStates } from '@kehe/phoenix-errors';
import { GetDocumentsListApiStore } from './api-stores/get-document-list-api.store';
import { DeleteDocumentsListApiStore } from './api-stores/delete-document-list-api.store';

export interface DocumentListPageState {
  isAddDocumentModalOpen: boolean;
  documentToEdit: Document;
  documentToDelete: Document;
  isDocumentDownloading: boolean;
}

const initialState: () => DocumentListPageState = () => {
  const state: DocumentListPageState = {
    isAddDocumentModalOpen: false,
    documentToEdit: null,
    documentToDelete: null,
    isDocumentDownloading: false,
  };
  return state;
};

const notLoadingConfig = new KeheErrorConfig(
  KeheErrorSizes.LG,
  KeheMascotStates.Error,
  null,
  KeheErrorMessages.Error,
  null
);

@Injectable()
export class DocumentListPageStore extends ComponentStore<DocumentListPageState> {

  searchControl = new FormControl<string>('');

  private _apiStore = new GetDocumentsListApiStore(
    this._documentService,
  );

  private _deleteApiStore = new DeleteDocumentsListApiStore(
    this._documentService
  );

  private _filterStore = new FilterStore();

  constructor(
    private _documentService: DocumentService,
    private _toast: PhoenixToastService
  ) {
    super(initialState());
    this.setSearchValueOnSearchChange$(this.searchValueChanges$);
    this.triggerGetDocuments(this.apiFilter$);
    this.handleSuccessfullDocumentDeletion(this.deleteDocumentResponse$);
  }

  private readonly triggerGetDocuments = this.effect(
    (params$: Observable<{
      pageIndex: number,
      pageSize: number,
      search: string
    }>) =>
      params$.pipe(
        tap((params: {
          pageIndex: number,
          pageSize: number,
          search: string
        }) => this._apiStore.getDocuments$(params)),
      ),
  );

  public readonly deleteDocumentResponse$ = this._deleteApiStore.data$;

  private readonly apiError$ = this.select(
    this._apiStore.apiError$,
    (error) => {
      return error || undefined;
    },
  );

  public apiFilter$ = this.select(
    this._filterStore.vm$,
    (filter) => ({
      search: filter.search,
      pageIndex: filter.pageIndex,
      pageSize: filter.pageSize,
    }),
  );

  private readonly _gridData$ = this.select(this._apiStore.data$, (payload) => {
    if (payload) {
      return payload?.data?.map((document) => {

        // all records can be deleted
        let actionOptions: OptionMenuItem[] = [
          { name: DocumentActionOptions.Delete, enabled: true, data: '' },
        ];

        // If there's a file extension, it can be downloaded
        // otherwise, it's assumed to be a link which will be opened
        if (document.fileExtension) {
          actionOptions = [
            { name: DocumentActionOptions.Download, enabled: true, data: '' },
            ...actionOptions
          ];
        } else {
          actionOptions = [
            { name: DocumentActionOptions.OpenLink, enabled: true, data: '' },
            ...actionOptions
          ];
        }

        return {
          ...document,
          fileSizeReadable: formatByteSize(document.fileSize, 2),
          actionOptions,
        };
      });
    }
    return [];
  });

  readonly availableCount$ = this.select(
    this._apiStore.data$,
    (payload) => {
      return payload?.availableCount || 0;
    },
  );

  pageChanged = this.effect((page$: Observable<PageChangeEvent>) =>
    page$.pipe(
      tap((page) => {
        this._filterStore.updatePage(page as PageChangeEvent);
      }),
    ),
  );

  searchValueChanges$ = this.searchControl.valueChanges.pipe(
    startWith(this.searchControl.value),
  );

  setSearchValueOnSearchChange$ = this.effect(
    (search$: Observable<string | null>) =>
      search$.pipe(
        withLatestFrom(this._filterStore.vm$),
        tap(([search, filter]) => {
          if (search !== null && search !== filter.search) {
            this.searchChanged(search);
          }
        }),
      ),
  );

  searchChanged = this.effect((search$: Observable<string>) =>
    search$.pipe(
      filter((search) => search.length >= 3 || search.length === 0),
      withLatestFrom(this._filterStore.vm$),
      debounceTime(500),
      tap(([search, _]) => {
        this._filterStore.updateSearch(search);
        this._filterStore.updatePageIndexAndSkip(0, 0);
      }),
    ),
  );

  tryDeleteDocument(documentToDelete: Document): void {
    this.patchState({
      documentToDelete,
    });
  }

  cancelDelete(): void {
    this.patchState({
      documentToDelete: null
    });
  }

  public documentToDelete$ = this.select(
    this.state$,
    (state) => (state.documentToDelete),
  );

  public vm$ = this.select(
    this.state$,
    this._gridData$,
    this._filterStore.vm$,
    this._apiStore.isLoading$,
    this.availableCount$,
    this.apiError$,
    this._deleteApiStore.isLoading$,
    (state, gridData, filter, isLoading, availableCount, apiError, isDeleteInProgress) => ({
      isDocumentDownloading: state.isDocumentDownloading,
      isDeleteInProgress,
      showDeleteConfirmation: Boolean(state.documentToDelete),
      documentToDelete: state.documentToDelete,
      gridData,
      isLoading,
      availableCount,
      isLoadListError: Boolean(apiError),
      emptyStateConfig: notLoadingConfig,
      pageSize: filter.pageSize,
      skip: filter.skip,
    }),
  );

  reloadDocumentsWithCurrentFilter$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(this.apiFilter$),
      tap(([_, filter]) => {
        this._apiStore.getDocuments$(filter);
      })
    )
  );

  confirmDeleteDocument$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(this.documentToDelete$),
      tap(([_, documentToDelete ]) => {
        this._deleteApiStore.deleteDocument$(documentToDelete.id);
      })
    )
  );

  readonly handleSuccessfullDocumentDeletion = this.effect(
    (response$: Observable<string>) =>
      response$.pipe(
        filter((response) => Boolean(response)),
        tap((_) => {
          this._toast.showSuccessToast('Document deleted successfully!');
          this.reloadDocumentsWithCurrentFilter$();
          this.patchState({ documentToDelete: null });
        }),
      ),
  );

  downloadFile$ = this.effect(
    (doc$: Observable<Document>) =>
      doc$.pipe(
        switchMap((doc) => {
          const toast = this._toast.showInfoToast('Download in progress.', false, 5000, null, false);
          return this._documentService.getPresignedUrlDocumentDownloadLink(doc).pipe(
            tapResponse(
              (rsp) => {
                const tmpElmA = document.createElement('a');
                tmpElmA.style.display = 'none';
                tmpElmA.href = rsp.data;
                tmpElmA.download = `${doc.title}${doc.fileExtension}`;
                document.body.appendChild(tmpElmA);
                tmpElmA.click();
                document.body.removeChild(tmpElmA);

                if (toast) {
                  toast.hide();
                }
              },
              () => {
                return EMPTY;
              },
            ),
          );
        }),
        catchError(() => {
          return EMPTY;
        }),
      ),
  );

}
