import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class DPIService {
  constructor(private readonly _http: HttpClient) {}

  exportSupplierData(esn: string) {
    return this._http.post(`${environment.supplierApi}/api/dpi/export/${esn}`, {});
  }
}
