import { IKeheTab } from '@kehe/phoenix-tabs';
import { createAction, props } from '@ngrx/store';

export const selectedTabChanged = createAction(
  '[Credit List - Tabs] Selected Tab Changed',
  props<{ selectedTab: IKeheTab }>()
);

export const creditListPageChangeClicked = createAction(
  '[Credit List - Grid] Credit List Page Change Clicked',
  props<{ pageIndex: number, pageCount: number }>()
);

export const submittedTabDateFilterChanged = createAction(
  '[Credit List - Filter] Submitted Tab Date Filter Changed',
  props<{ startDate: Date, endDate: Date }>()
);

export const processedTabDateFilterChanged = createAction(
  '[Credit List - Filter] Processed Tab Date Filter Changed',
  props<{ startDate: Date, endDate: Date }>()
);

export const submittedTabSearchCriteriaChanged = createAction(
  '[Credit List - Filter] Submitted Tab Search Criteria Changed',
  props<{ searchCriteria: string }>()
);

export const processedTabSearchCriteriaChanged = createAction(
  '[Credit List - Filter] Processed Tab Search Criteria Changed',
  props<{ searchCriteria: string }>()
);
