<div class="form-container">
  <div>
    <div class="row">
      <div class="col">
        <h4>Clone DC Product Group</h4>
      </div>
    </div>
    <hr class="mt-0" />
    <app-dc-form [form]="form"></app-dc-form>
    <hr/>
    <app-buyers-form [form]="form"></app-buyers-form>
    <hr/>
    <app-product-group-delivery-form
      #deliveryForm
      [action]="actionTypes.CloneDCPG"
      [supplier]="supplierDetail$ | async"
      [address]="(dcProductGroup$ | async)?.dcpg?.pickupAddress"
      (pickupAddressSelected)="onPickupAddressSelected()"></app-product-group-delivery-form>

    <h5 class="mb-3">PO Transmissions and Details</h5>
    <app-product-group-po-transmissions-form #poTransmissionsGroup  [hasPOOrder]="isDraftSupplier$ |async"
    ></app-product-group-po-transmissions-form>
  </div>
</div>
