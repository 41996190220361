import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
  name: 'displayYesNo',
  standalone: true,
 })
export class DisplayYesNoPipe implements PipeTransform {
  transform(value: any, args?: string): string {
    const mask = args ? args : '--';
    switch (value) {
      case true:
        return 'Yes';
      case false:
        return 'No';
      default:
        return mask;
    }
  }
}
