import { CodeDescription } from './code-description';

export enum TermsDropdownType {
  AuditTimeFrame = 'AuditTimeFrame',
  DueDateCalculation = 'DueDateCalculation',
  FreeProductsProgram = 'FreeProductsProgram',
  FreeProductHandlingCharge = 'FreeProductHandlingCharge',
  PaymentMethod = 'PaymentMethod',
  PriceChangeNotice = 'PriceChangeNotice',
  SpecialEventTermsNewWarehouseOpening = 'SpecialEventTermsNewWarehouseOpening',
  SpecialEventTermsSeasonal = 'SpecialEventTermsSeasonal',
}

export interface TermsDropdownData {
  listName: TermsDropdownType;
  listValues: CodeDescription[];
}
