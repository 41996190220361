import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ModalOptions } from '../models/modal-options.model';
import { ModalResponse } from '../models/modal-response.model';
@Injectable()
export class PromiseModalService {
    private notifyModalSubject = new ReplaySubject<ModalOptions>();
    private modalResultSubject = new ReplaySubject<ModalResponse>();
    private notifyModal$: Observable<ModalOptions>;
    private modalResult$: Observable<ModalResponse>;

    constructor() {
        this.notifyModal$ = this.notifyModalSubject.asObservable();
        this.modalResult$ = this.modalResultSubject.asObservable();
    }

    notifyModal(): Observable<ModalOptions> {
        return this.notifyModal$;
    }

    openModal(options: ModalOptions): Observable<ModalResponse> {
        this.notifyModalSubject.next(options);
        this.modalResultSubject = new ReplaySubject<ModalResponse>();
        this.modalResult$ = this.modalResultSubject.asObservable();
        return this.modalResult$;
    }

    setModalResult(response: ModalResponse) {
        this.modalResultSubject.next(response);
    }
}
