import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Destroyable } from '../../../../abstract/destroyable';
import { ProductGroup } from '../../models/product-group';
import { GridComponent } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { select, Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  hasRemittanceAddressContact,
  selectPickupAddressColumnTitle,
  selectShowAddProductGroupModal,
  selectSupplierDetail
} from '../../store/supplier-detail.selectors';
import { DCProductGroup } from '../../models/dc-product-group';
import { ActivatedRoute, Router } from '@angular/router';
import {
  confirmCancelEdit,
  hideAlertMessage,
  openProductGroupsFilter,
  showAddProductGroupModal,
} from '../../store/supplier-detail.actions';
import { ProductGroupsFilter } from '../../models/product-groups-filter';
import { IChip } from '@kehe/phoenix-chips';
import { Observable } from 'rxjs';
import { isDraft, isInvitedDraftSupplier } from '../../utilities/supplier-utils';
import { SupplierDetail } from '../../models/supplier-detail';
import {
  getProductGroups,
  getProductGroupsFilter,
  productGroupsFilterChips,
  productGroupsSearch
} from '../../../product-groups/store/product-groups.selectors';
import {
  productGroupsChipClicked,
  productGroupsFilterClearAll,
  productGroupsSearchChanged,
  productGroupsSearchCleared
} from '../../../product-groups/store/product-groups.actions';
import { getInboundRouteTypeName } from '../../../../utils/enum-utils';
import { BreakpointService } from '@kehe/phoenix-utils';

@Component({
  selector: 'app-product-groups-list',
  templateUrl: './product-groups-list.component.html',
  styleUrls: ['./product-groups-list.component.scss']
})
export class ProductGroupsListComponent extends Destroyable implements OnInit {
  productGroups: ProductGroup[];
  productGroupsFilterChips: IChip[];
  productGroupsFilter: ProductGroupsFilter;
  delMethodPlaceHolder = '--';
  supplier: SupplierDetail;

  @ViewChild(GridComponent, { static: true }) grid: GridComponent;
  public expandedDetailKeys: number[] = [];
  hasRemittanceAddressContact = false;
  showPGProductsModal: ProductGroup = null;
  showDCPGProductsModal: DCProductGroup = null;

  showPGBrandsModal: ProductGroup = null;
  showDCPGBrandsModal: DCProductGroup = null;

  pickupAddressColumnTitle$ = this._store.select(selectPickupAddressColumnTitle)
  isMedAndBelow$ = this._breakpointService.isMedAndBelow$;
  isSmallAndBelow$ = this._breakpointService.isSmallAndBelow$;

  public sort: SortDescriptor[] = [
    {
      field: 'productGroupNumber',
      dir: 'asc'
    }
  ];

  public showModal$: Observable<boolean>;
  searchControl = new UntypedFormControl('');

  get canAddProductGroup() {
    if (isInvitedDraftSupplier(this.supplier)) {
      return this.hasRemittanceAddressContact;
    }
    return true;
  }


  get canShowProductsData() {
    return !isDraft(this.supplier);
  }

  constructor(
    private _store: Store,
    private _route: Router,
    private _activatedRoute: ActivatedRoute,
    private _breakpointService: BreakpointService,
  ) {
    super();
    this._store
      .pipe(takeUntil(this.destroy$), select(hasRemittanceAddressContact))
      .subscribe((value) => {
        this.hasRemittanceAddressContact = value;
      });
  }

  ngOnInit() {
    this._store.dispatch(confirmCancelEdit());
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSupplierDetail))
      .subscribe((val) => {
        this.supplier = val;
      });
    this._store
      .pipe(takeUntil(this.destroy$), select(productGroupsSearch))
      .subscribe((search) => {
        this.searchControl.setValue(search);
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(getProductGroups))
      .subscribe(val => {
        this.productGroups = orderBy(val, this.sort);
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(productGroupsFilterChips))
      .subscribe(res => {
        this.productGroupsFilterChips = res;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(getProductGroupsFilter))
      .subscribe(res => {
        this.productGroupsFilter = res;
      });
    this.searchControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          this._store.dispatch(productGroupsSearchChanged({ search: value }));
        } else {
          this._store.dispatch(productGroupsSearchCleared());
        }
      });

    this.showModal$ = this._store.pipe(select(selectShowAddProductGroupModal));
    this._store.dispatch(hideAlertMessage());
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.productGroups = orderBy(this.productGroups, this.sort);
  }

  onAddClick() {
    this._store.dispatch(showAddProductGroupModal());
  }

  openFilterPanel() {
    this._store.dispatch(openProductGroupsFilter());
  }

  filterChipClearAllClicked() {
    this._store.dispatch(productGroupsFilterClearAll());
  }

  filterChipClicked($event) {
    this._store.dispatch(productGroupsChipClicked({ chip: $event }));
  }

  getUPCCount(dataItem: ProductGroup) {
    if (dataItem.products && dataItem.products.length > 0) {
      return dataItem.products.length;
    }
    return '--';
  }

  getPGTempClass(dataItem: ProductGroup) {
    if (this.canShowProductsData) {
      if (dataItem.dcProductGroups) {
        const products = dataItem.dcProductGroups.reduce((acc, val) => acc.concat(val.products || []), []);
        const unique = products
          .map(item => item.tempClass)
          .filter((value, index, self) => value && self.indexOf(value) === index);
        if (unique.length > 0) {
          return unique.join(', ');
        }
      }
    } else {
      if (dataItem.dcProductGroups) {
        const unique = dataItem.dcProductGroups
          .map(item => item.productTemperatureClasses)
          .filter((value, index, self) => value && self.indexOf(value) === index);
        if (unique.length > 0) {
          return unique.join(', ');
        }
      }
    }
    return '--';
  }

  getDCPGTempClass(dataItem: DCProductGroup) {
    if (this.canShowProductsData) {
      if (dataItem.products) {
        const unique = dataItem.products
          .map(item => item.tempClass)
          .filter((value, index, self) => value && self.indexOf(value) === index);
        if (unique.length > 0) {
          return unique.join(', ');
        }
      }
    }
    return '--';
  }

  getIconClass(rowIndex: number): string {
    return this.expandedDetailKeys.includes(rowIndex) ? 'fa-chevron-up' : 'fa-chevron-down';
  }

  expandGroupRow(item: ProductGroup, rowIndex: number) {
    if (this.expandedDetailKeys.includes(rowIndex)) {
      this.grid.collapseRow(rowIndex);
      this.expandedDetailKeys = this.expandedDetailKeys.filter(obj => obj !== rowIndex);
    } else {
      this.grid.expandRow(rowIndex);
      this.expandedDetailKeys.push(rowIndex);
    }
  }

  getPurchaseOrderGroupCount(dataItem: ProductGroup) {
    if (dataItem.dcProductGroups) {
      return dataItem.dcProductGroups.length;
    }
    return '--';
  }

  getBuyerCount(dataItem: ProductGroup) {
    if (dataItem.dcProductGroups) {
      const unique = dataItem.dcProductGroups
        .map(item => item.buyerCode)
        .filter((value, index, self) => self.indexOf(value) === index);
      return unique.length;
    }
    return '--';
  }

  getDeliveryMethods(dataItem: ProductGroup) {
    if (dataItem.dcProductGroups) {
      const unique = dataItem.dcProductGroups
        .map(item => item.deliveryMethod)
        .filter((value, index, self) => {
          if (value !== this.delMethodPlaceHolder && value !== null && value !== undefined && value !== '') {
            return self.indexOf(value) === index;
          }
        });
      if (unique.length > 1) {
        return `${unique[0] }, +${unique.length - 1 }`;
      }
      if (unique.length > 0) {
        return unique[0];
      }
    }
    return '--';
  }

  getDeliveryMethod(item: DCProductGroup) {
    if (!item.deliveryMethod) {
      return '--';
    }
    return item.deliveryMethod;
  }

  getBuyer(item: DCProductGroup) {
    if (item.buyerName) {
      let name = item.buyerName;
      if (item.buyerCode) {
        name += ` (${item.buyerCode})`;
      }
      return name;
    }
    return '--';
  }

  getBrandsCount(dataItem: ProductGroup) {
    if (dataItem.dcProductGroups && dataItem.dcProductGroups.length > 0) {
      const unique = dataItem.dcProductGroups
        .map(item => item.productBrands)
        .filter((value, index, self) => value && self.indexOf(value) === index);

        if (unique.length > 0) {
          return unique.length;
        }
    }
    return '--';
  }

  onClickName(productGroupNumber: string) {
    return this._route.navigate([productGroupNumber], {relativeTo: this._activatedRoute});
  }

  isDelivered(item: DCProductGroup) {
    return 'Delivered' === item.deliveryMethod;
  }

  sortedDCProductGroups(dcProductGroups: any[]) {
    return dcProductGroups.sort((a, b) => a.distributionCenterNumber.localeCompare(b.distributionCenterNumber));
  }

  onClickPGUPCCount(pg: ProductGroup) {
    this.showPGProductsModal = pg;
  }

  onClickDCPGUPCCount(dcpg: DCProductGroup) {
    this.showDCPGProductsModal = dcpg;
  }

  closePGProductsModal() {
    this.showPGProductsModal = null;
    this.showDCPGProductsModal = null;
  }

  onClickPGBrandsCount(pg: ProductGroup) {
    this.showPGBrandsModal = pg;
  }

  onClickDCPGBrandsCount(dcpg: DCProductGroup) {
    this.showDCPGBrandsModal = dcpg;
  }

  closePGBrandsModal() {
    this.showPGBrandsModal = null;
    this.showDCPGBrandsModal = null;
  }

  inboundRouteTypeName(inboundRouteType) {
    return getInboundRouteTypeName(inboundRouteType);
  }
}
