import { DCPGListItem } from '../modules/supplier-dcpgs/models/dcpg-list-item';
import { DCProductGroup } from '../modules/supplier-detail/models/dc-product-group';

export function dcpgDropdownList(
  dcPGs: DCPGListItem[]
): { label: string; value: string }[] {
  return dcPGs.map((item) => {
    return {
      ...item,
      label: `${item.dcNumber}-${item.dcpgNumber}-${item.supplierName}`,
      value: item.id.toString(),
    };
  });
}

export function dcpgDropdownListFromDCProductGroup(
  dcPGs: DCProductGroup[]
): { label: string; value: string }[] {
  return dcPGs.map((item) => {
    return {
      label: item.dcDisplayName,
      value: item.distributionCenterNumber,
    };
  });
}

export function cantRemovePOEmail(dcPG: DCPGListItem): boolean {
  return !hasPOFax(dcPG) && hasOnlyOnePOEmail(dcPG);
}

export function hasPOFax(dcPGs: DCPGListItem): boolean {
  return dcPGs.poTransmissionDetails?.poFax?.fax
    ? dcPGs.poTransmissionDetails?.poFax?.fax.length > 0
    : false;
}

export function hasOnlyOnePOEmail(dcPG: DCPGListItem): boolean {
  return dcPG.poTransmissionDetails?.poEmails?.length === 1;
}

export function hasExistingPOEmail(dcpg: DCPGListItem, emails?: string[]) {
  const duplicates = dcpg.poTransmissionDetails?.poEmails?.map(item => item?.email?.toLowerCase()).filter(item => emails?.includes(item));
  if (duplicates?.length > 0) {
    return { dcpg, duplicates };
  }
}
