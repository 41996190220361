<kehe-phoenix-modal
    *ngIf="vm$ | async as vm"
    name="RemoveAssociationModal"
    [show]="true"
    [modalTitle]="vm.modalTitle"
    (modalEvent)="handleKeheModalEvents($event)"
    size="md"
>
    <div body>
        <kehe-phoenix-alert
            *ngIf="vm.hasApiError"
            [show]="true"
            type="error"
            automationId="associated-supplier-delete-error"
            [message]="vm.apiErrorMessage"
            [stick]="true"
            [hideCloseIcon]="true"
            class="mb-3 api-alert"
        >
        </kehe-phoenix-alert>

        <div>
          <p [innerHTML]="vm.modalMessageQuestion"></p>
          <p [innerHTML]="vm.modalMessageDetail"></p>
        </div>

        <app-add-note *ngIf="!vm.isDraftSupplier"></app-add-note>

    </div>

    <div footer class="d-flex justify-content-end px-1">
        <kehe-phoenix-button
            classAttr="btn-link btn-medium"
            name="cancel"
            automationId="associated-supplier-delete-modal-cancel-button"
            [disabled]="vm.isDeleting"
            (click)="closeModal()"
        >
            Cancel
        </kehe-phoenix-button>
        <kehe-phoenix-button
            classAttr="btn-delete btn-medium ms-2"
            name="remove-broker"
            (click)="confirmDeletion()"
            [spinner]="vm.isDeleting"
            automationId="associated-supplier-delete-modal-remove-button"
            [disabled]="vm.isRemoveButtonDisabled"
        >
            Remove
        </kehe-phoenix-button>
    </div>
</kehe-phoenix-modal>
