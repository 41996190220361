import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadquarterAddressComponent } from './components/headquarter-address/headquarter-address.component';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ReactiveFormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer, supplierLocationsStateKey } from './store/supplier-locations.reducer';
import { SupplierLocationsEffects } from './store/supplier-locations.effects';
import { LocationsService } from './services/locations.service';
import { RemitAddressComponent } from './components/remit-address/remit-address.component';
import { DeleteAddressComponent } from './components/delete-address/delete-address.component';
import { PickupAddressComponent } from './components/pickup-address/pickup-address.component';
import { SupplierComponentsModule } from '../supplier-components/supplier-components.module';
import { LocationsDeliveredAddressComponent } from './components/locations-delivered-address/locations-delivered-address.component';

@NgModule({
  declarations: [
    HeadquarterAddressComponent,
    RemitAddressComponent,
    DeleteAddressComponent,
    PickupAddressComponent,
    LocationsDeliveredAddressComponent
  ],
  imports: [
    CommonModule
,
    PhoenixButtonModule,
    PhoenixModalModule,
    LabelModule,
    InputsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    StoreModule.forFeature(supplierLocationsStateKey, reducer),
    EffectsModule.forFeature([SupplierLocationsEffects]),
    SupplierComponentsModule,
  ]
,
  exports: [
    HeadquarterAddressComponent,
    RemitAddressComponent,
    DeleteAddressComponent,
    PickupAddressComponent,
    LocationsDeliveredAddressComponent
  ],
  providers: [
    LocationsService
  ]
})
export class SupplierLocationsModule { }
