import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SupplierDetail } from '../../supplier-detail/models/supplier-detail';
import { environment } from '../../../../environments/environment';

@Injectable()
export class LocationsService {
  constructor(
    private readonly _http: HttpClient
  ) { }

  delete(contact: string, esn: string) {
    return this._http.delete<SupplierDetail>(
      `${environment.supplierApi}/api/${esn}/contacts/${contact}`
    )
  }
}
