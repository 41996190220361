<kehe-phoenix-modal [show]="true" modalTitle="Assign Discontinued Products Notification" (modalEvent)="closeModal()"
  size="md">

  <div body>
    <kehe-phoenix-alert [show]="error !== null" [type]="'error'" [message]="error" [stick]="true"
      [hideCloseIcon]="true">
    </kehe-phoenix-alert>
    <kehe-phoenix-alert [show]="true" [type]="'warning'" [message]="warning" [stick]="true" [hideCloseIcon]="true">
    </kehe-phoenix-alert>
    Are you sure you would like to assign contact as the Discontinued Product Notification recipient?
  </div>

  <div footer class="d-flex justify-content-end px-1">
    <kehe-phoenix-button classAttr="btn-link btn-medium me-2" (click)="closeModal()">
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="onAssignClicked()" [spinner]="inProgress">
      Assign
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>