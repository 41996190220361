export class CreateV1UserRequest {
    Identifier: string;
    CreatedOn: Date;
    FullyQualifiedUserId: string;
    IsEmployeeAdmin: boolean;
    Esns: Array<string> = [];
    BemCodes: Array<string> = [];
    CmCodes: Array<string> = [];
    Groups: Array<any> = [];
    Customers: Array<any> = [];
    DcCustomers: Array<any> = [];
    UserAccountLocked: boolean;
    UserCompanyHasConnectAdmin: boolean;
    ConvertContactToUser: false;
    Id: number;
    FirstName: string;
    LastName: string;
    EmailAddress: string;
    Password: string;
    Type: string;
    Title: string;
    IsApproved: boolean;
    IsProfileValidated: boolean;
    IsEmailVerified: boolean;
    IsAdmin: boolean;
    Profile: any;
    Status: string;
    WorkflowId: number;
  }
  