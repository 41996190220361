import { DCProductGroup } from '../../supplier-detail/models/dc-product-group';
import { ProductGroup } from '../../supplier-detail/models/product-group';

export function cleanupProductGroupPayload(
  productGroup: ProductGroup
): ProductGroup {
  return {
    productGroupNumber: productGroup.productGroupNumber,
    name: productGroup.name,
    temperatureClass: productGroup.temperatureClass,

    dcProductGroups: productGroup.dcProductGroups.map((dcpg) => {
      return remapDCProductGroup(dcpg);
    }),
  } as ProductGroup;
}

function remapDCProductGroup(dcpg: DCProductGroup) {
  return {
    buyerCode: dcpg.buyerCode,
    buyerName: dcpg.buyerName,
    deliveryMethod: dcpg.deliveryMethod,
    deliveryMethodCode: dcpg.deliveryMethodCode,
    distributionCenterNumber: dcpg.distributionCenterNumber,
    deliveredLeadTimes: dcpg.deliveredLeadTimes,
    pickupLeadTimes: {
      readyTime: dcpg.pickupLeadTimes.readyTime
    },
    poEmails: dcpg.poEmails,
    poFax: dcpg.poFax,
    pickupAddress: dcpg.pickupAddress,
    pickupAddressNotes: dcpg.pickupAddressNotes,
    pickupAddressId: dcpg.pickupAddressId,
    inboundRouteType: dcpg.inboundRouteType,
    pickupAddressContact: dcpg.pickupAddressContact,
    poOrderMinimum: dcpg.poOrderMinimum,
  };
}
