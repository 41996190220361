import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingActionButtonModule } from '@progress/kendo-angular-buttons';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import { QBotStore } from './q-bot.store';
import { ComponentStore } from '@ngrx/component-store';

@Component({
  selector: 'app-q-bot',
  standalone: true,
  imports: [CommonModule, FloatingActionButtonModule, PopoverModule],
  providers: [QBotStore, ComponentStore],
  templateUrl: './q-bot.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrl: './q-bot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QBotComponent {

  vm$ = this.qBotStore.vm$;

  constructor(private readonly qBotStore: QBotStore) {}

  public onPopoverShown(): void {
    this.qBotStore.patchState(() => ({
      isChatOpen: true
    }));
  }

  public onPopoverHidden(): void {
    this.qBotStore.patchState(() => ({
      isChatOpen: false
    }));
  }

}
