import { Injectable } from '@angular/core';
import { BrokerDetailService } from '@app/modules/broker-detail/services/broker-detail.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BuyerService } from '../../services/buyer.service';
import { SupplierCodeValueService } from '../../services/supplier-code-value.service';
import { CustomerListService } from '../../../customers/customer-list/services/customer-list.service';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { selectBuyerList, selectSupplierDetail } from '../supplier-detail.selectors';

import * as SupplierDetailActions from '../supplier-detail.actions';
import * as SupplierDCPGListActions from '../../../supplier-dcpgs/store/supplier-dcpgs.actions';

@Injectable()
export class SupplierUtilityDataEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private brokerDetailService: BrokerDetailService,
    private supplierCodeValueService: SupplierCodeValueService,
    private buyerService: BuyerService,
    private customerListService: CustomerListService,
  ) {}

  loadRetailer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.loadSupplierDetailSuccess),
      switchMap((action) => {
        if (action.supplier?.retailerMasterAccountNumber) {
          return this.customerListService
            .getSingle(action.supplier.retailerMasterAccountNumber)
            .pipe(
              map((retailer) =>
                SupplierDetailActions.saveRetailer({ retailer })
              ),
              catchError(() => of(SupplierDetailActions.saveRetailerFailed()))
            );
        } else {
          return of(SupplierDetailActions.saveRetailer({ retailer: null }));
        }
      })
    )
  );

  loadSupplierBrokerDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.loadSupplierBroker),
      withLatestFrom(this.store.select(selectSupplierDetail)),
      switchMap(([, supplierDetail]) => {
        // not all suppliers will have a broker. return observable and dispatch success action.
        if (!supplierDetail?.broker?.esn) {
          return of({}).pipe(
            map(() =>
              SupplierDetailActions.loadSupplierBrokerSuccess({ broker: null })
            )
          );
        }
        return this.brokerDetailService
          .getBrokerDetail(supplierDetail.broker.esn)
          .pipe(
            map((broker) =>
              SupplierDetailActions.loadSupplierBrokerSuccess({ broker })
            )
          );
      })
    )
  );

  getDeliveryMethodList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SupplierDetailActions.loadSupplierDetail,
        SupplierDCPGListActions.landSupplierDCPGList,
      ),
      switchMap(() => {
        return this.supplierCodeValueService.get('deliverymethods').pipe(
          map((response) => this.mapResponseToDropdown(response.data)),
          map((data) =>
            SupplierDetailActions.getDeliveryMethodListSuccess({
              deliveryMethods: data,
            })
          )
        );
      })
    )
  );

  getBuyerList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.loadBuyerList),
      withLatestFrom(this.store.select(selectBuyerList)),
      switchMap(([, list]) => {
        // Cache: If we already have the data, just return from store
        if (list?.length) {
          return of(SupplierDetailActions.loadBuyerListSuccess({ list }));
        }

        return this.buyerService.getBuyers().pipe(
          map((data) =>
            SupplierDetailActions.loadBuyerListSuccess({ list: data })
          ),
          catchError(() => of(SupplierDetailActions.loadBuyerListError()))
        );
      })
    )
  );

  private mapResponseToDropdown(list: any[]): any[] {
    return list.map((item) => {
      return { value: item.code, label: item.codeValue };
    });
  }
}
