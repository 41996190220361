import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { selectBrokerDocument } from '../../../supplier-documents/store/supplier-documents.selectors';
import {
  selectSupplierDetail,
  selectSupplierHasHeadquarterBroker,
} from '../../store/supplier-detail.selectors';
import { brokerV2IsValidChanged } from './broker-actions';
import { RelatedNonHeadquarterBrokerApiStore } from './broker-tab-v2-grid/api-stores/related-non-headquarter-broker.api.store';

export interface BrokerValidationState {}

export const initialState: BrokerValidationState = {};

@Injectable({ providedIn: 'root' })
export class BrokerTabValidationStore extends ComponentStore<BrokerValidationState> {
  /**
   * null = no selection,
   * false = does not have a headquarter broker,
   * true = has a headquarter broker
   */

  public supplierDetail$ = this._store.select(selectSupplierDetail);

  public isActiveSupplier$ = this.select(
    this.supplierDetail$,
    (supplierDetail) => supplierDetail?.status !== 'Draft'
  );

  public hasHeadquarterBroker$ = this._store.select(
    selectSupplierHasHeadquarterBroker
  );

  public supplierDoesNotHaveABroker$ = this.select(
    this.hasHeadquarterBroker$,
    (hasHeadquarterBroker) => {
      return hasHeadquarterBroker === false;
    }
  );

  public noHeadquarterSelectionMade$ = this.select(
    this.hasHeadquarterBroker$,
    (hasHeadquarterBroker) => hasHeadquarterBroker === null
  );

  public hasNonHeadquarterBroker$ = this.select(
    this._nonHeadquarterApiStore.data$,
    (nonHeadquarterBrokers) => nonHeadquarterBrokers?.length > 0
  );

  public hasBrokerOfRecordForm$ = this.select(
    this._store.select(selectBrokerDocument),
    (document) => Boolean(document)
  );

  public isValid$ = this.select(
    this.hasBrokerOfRecordForm$,
    this.hasHeadquarterBroker$,
    this.hasNonHeadquarterBroker$,
    this.supplierDoesNotHaveABroker$,
    this.isActiveSupplier$,
    (
      hasBrokerOfRecordForm,
      hasHeadquarterBroker,
      hasNonHeadquarterBroker,
      supplierDoesNotHaveABroker,
      isActiveSupplier
    ) => {
      return (
        hasBrokerOfRecordForm ||
        hasHeadquarterBroker ||
        hasNonHeadquarterBroker ||
        supplierDoesNotHaveABroker ||
        isActiveSupplier
      );
    }
  );

  constructor(
    private _nonHeadquarterApiStore: RelatedNonHeadquarterBrokerApiStore,
    private _store: Store
  ) {
    super(initialState);
    this.brokerV2IsValidChanged$(this.isValid$);
  }

  brokerV2IsValidChanged$ = this.effect((isValid$: Observable<boolean>) =>
    isValid$.pipe(
      tap((isValid) => {
        this._store.dispatch(brokerV2IsValidChanged());
      })
    )
  );
}
