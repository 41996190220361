import { Component, OnInit } from '@angular/core';
import { IAuditDetail, IAuditModalConfiguration } from '@kehe/phoenix-audit-history';
import { IChip } from '@kehe/phoenix-chips';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import {
  loadSupplierHistoryList,
  supplierHistoryChipClicked,
  supplierHistoryClearAllChipsClicked,
  supplierHistoryClearFilters,
  supplierHistoryCloseDetailModal,
  supplierHistoryDisplayDetailModal,
  supplierHistoryFilterOpenPanel,
  supplierHistoryPageIndexChanged,
  supplierHistoryPageSizeChanged,
  supplierHistorySearchChange
} from '../../store/supplier-detail.actions';
import {
  selectSupplierHistoryChips,
  selectSupplierHistoryLoading,
  selectSupplierHistoryModalConfig,
  selectSupplierHistoryPageIndex,
  selectSupplierHistoryPageSize,
  selectSupplierHistoryRecordsCount,
  selectSupplierHistoryTableData
} from '../../store/supplier-detail.selectors';

@Component({
  selector: 'app-history-tab',
  templateUrl: './history-tab.component.html',
  styleUrls: []
})
export class HistoryTabComponent extends Destroyable implements OnInit {

  public tableData: Observable<Array<IAuditDetail>> = this.store.select(selectSupplierHistoryTableData);
  public loading: Observable<boolean> = this.store.select(selectSupplierHistoryLoading);
  public recordsCount: Observable<number> = this.store.select(selectSupplierHistoryRecordsCount);
  public pageSize: Observable<number> = this.store.select(selectSupplierHistoryPageSize);
  public pageIndex: Observable<number> = this.store.select(selectSupplierHistoryPageIndex);
  public modalConfig: Observable<IAuditModalConfiguration> = this.store.select(selectSupplierHistoryModalConfig);
  private searchSubject = new Subject<string>();
  public filterChips: Array<IChip>;

  constructor(private store: Store) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(supplierHistoryClearFilters());
    this.store.dispatch(loadSupplierHistoryList());
    this.searchSubject
      .pipe(takeUntil(this.destroy$), debounceTime(300), distinctUntilChanged())
      .subscribe((search) => {
        this.store.dispatch(supplierHistorySearchChange({ search }));
      });

    this.store.select(selectSupplierHistoryChips)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.filterChips = val;
      });
  }

  public pageIndexChange(index: number) {
    this.store.dispatch(supplierHistoryPageIndexChanged({ index }));
  }

  public pageSizeChange(size: number) {
    this.store.dispatch(supplierHistoryPageSizeChanged({ size }));
  }

  public closeDetailModal() {
    this.store.dispatch(supplierHistoryCloseDetailModal());
  }

  public openDetailModal(row: IAuditDetail) {
    this.store.dispatch(supplierHistoryDisplayDetailModal({ row }));
  }

  public onSearch(search: string) {
    this.searchSubject.next(search);
  }

  public onFilterClick() {
    this.store.dispatch(supplierHistoryFilterOpenPanel());
  }

  public chipClicked(event) {
    this.store.dispatch(supplierHistoryChipClicked({ chip: event }));
  }

  public clearAllChips() {
    this.store.dispatch(supplierHistoryClearAllChipsClicked());
  }

}
