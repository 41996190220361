import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductGroup } from '../../../supplier-detail/models/product-group';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { DCProductGroup } from '../../../supplier-detail/models/dc-product-group';

@Component({
  selector: 'app-pg-brands-modal',
  templateUrl: './pg-brands-modal.component.html',
  styleUrls: ['./pg-brands-modal.component.scss']
})
export class PgBrandsModalComponent implements OnInit {

  @Input() productGroup: ProductGroup;
  @Input() dcProductGroup: DCProductGroup;
  @Output() closeModal = new EventEmitter<void>();

  public sort: SortDescriptor[] = [
    {
      field: 'name',
      dir: 'asc'
    }
  ];

  get brands() {
    if (this.productGroup) {
      return this.productGroup?.brands || [];
    }
    if (this.dcProductGroup) {
      return this.dcProductGroup?.productBrands || [];
    }
    return [];
  }
  
  ngOnInit(): void {
    this.sortProducts();
  }

  title() {
    if (this.productGroup) {
      return `${this.productGroup.name} (${this.productGroup.productGroupNumber})`;
    }
    if (this.dcProductGroup) {
      return `${this.dcProductGroup.dcDisplayName} - ${this.dcProductGroup.dcProductGroupNumber}`;
    }
    return '--';
  }

  onClose() {
    this.closeModal.emit();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.sortProducts();
  }

  sortProducts() {
    if (this.productGroup) {
      this.productGroup.brands = orderBy(this.productGroup.brands || [], this.sort);
    }
    if (this.dcProductGroup) {
      this.dcProductGroup.productBrands = orderBy(this.dcProductGroup.productBrands || [], this.sort);
    }
  }
}

