<kehe-phoenix-modal [show]="true"
                    modalTitle="Additional Documents"
                    (modalEvent)="onCloseModal()"
                    size="md">
  <div body class="body">
    <p> Additional documents can be maintained on the documents page.
      Review the list below of additional documents that can be uploaded.
    </p>
    <ul class='additional-docs-list'>
      <li *ngFor="let document of additionalDocumentTypes; let i=index">{{ document.name }}</li>
    </ul>
  </div>

  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="onCloseModal()">
      Close
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>