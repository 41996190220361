import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierUsersState } from './supplier-users.reducer';

export const getSupplierUsersState = createFeatureSelector<SupplierUsersState>(
    'supplierUsers'
);
export const selectESN = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.esn
);
export const selectUsers = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.users
);
export const selectLoadingUsers = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.loadingUsers
);
export const selectShowAddUserModal = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.showAddUserModal
);
export const selectAddUserInProgress = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.addUserInProgress
);
export const selectAddUserError = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.addUserError
);

//  Delete User
export const selectShowDeleteUserModal = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.deletingUser !== null
);
export const selectDeletingUser = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.deletingUser
);
export const selectDeleteUserInProgress = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.deleteUserInProgress
);
export const selectDeleteUserError = createSelector(
    getSupplierUsersState,
    (state: SupplierUsersState) => state.deleteUserError
);
//  Delete User
