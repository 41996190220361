import { Brand } from '../../product-detail/models/brand';
import { DeliveryMethodItem } from '../../product-groups/models/delivery-method-item';
import { DropdownOption } from './../../../models/dropdown-option';
import { Buyer } from './buyer';

export class ProductGroupsFilter {

  dcSelection: DropdownOption[];
  tempClass: string;
  brand: Brand;
  buyer: Buyer;
  deliveryMethod: DeliveryMethodItem;
  inboundRoundTypes: DropdownOption[];


  constructor() {
    this.dcSelection = [];
    this.tempClass = null;
    this.brand = null;
    this.buyer = null;
    this.deliveryMethod = null;
    this.inboundRoundTypes = [];
  }
}
