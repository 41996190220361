import { Component } from '@angular/core';
import { AssociatedSupplierDeleteStore } from './associated-supplier-delete.store';
import { IKeheModalEvent } from '@kehe/phoenix-modal';

@Component({
  selector: 'app-associated-supplier-delete-confirmation',
  templateUrl: './associated-supplier-delete-confirmation.component.html',
  styleUrls: ['./associated-supplier-delete-confirmation.component.scss'],
  providers: [AssociatedSupplierDeleteStore]
})
export class AssociatedSupplierDeleteConfirmationComponent {

  public vm$ = this._componentStore.vm$;

  constructor(
    private _componentStore: AssociatedSupplierDeleteStore
  ) { }

  closeModal() {
    this._componentStore.closeModal();
  }

  handleKeheModalEvents(closeEvent: IKeheModalEvent) {
    this.closeModal();
  }

  confirmDeletion() {
    this._componentStore.confirmDeletion$();
  }

}
