import { createSelector } from "@ngrx/store";
import { State } from "../index";
import * as fromSupplier from "./supplier.reducer";
import { sortBy, startCase } from "lodash";

const selectSupplierState = (state: State) => state.supplier;

export const selectSupplierStatus = createSelector(
  selectSupplierState,
  fromSupplier.getSupplierStatusList,
);

const getSupplierDevelopmentManagerList = createSelector(
  selectSupplierState,
  fromSupplier.getSupplierDevelopmentManagerList,
);

const getCategoryManagersList = createSelector(
  selectSupplierState,
  fromSupplier.getCategoryManagersList,
);

export const selectSupplierDevelopmentManagerList = createSelector(
  getSupplierDevelopmentManagerList,
  (managerList) => {
    return sortBy(managerList, 'name').map(manager => {
      return {
        ...manager,
        name: `${startCase(manager.name.toLowerCase())} (${manager.code})`
      }
    });
  }
);

export const selectCategoryManagerList = createSelector(
  getCategoryManagersList,
  (managerList) => {
    return sortBy(managerList, 'name').map(manager => {
      return {
        ...manager,
        name: `${startCase(manager.name.toLowerCase())} (${manager.code})`
      }
    });
  }
);
