import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { VendorBrokerManagementService } from '../../../services/vendor-broker-management.service';
import { SupplierDropdownItem } from '../models/supplier-dropdown-item.model';
import { ApiFailureText } from '../../../../../enum/alert-text.enum';
import { AbstractApiStore } from '@kehe/phoenix-component-store-utils';

/**
 * `SearchSuppliersApiStore` is an Angular service that extends `AbstractApiStore` to get the supplier list.
 *
 * @class
 * @extends AbstractApiStore
 *
 * @example
 * const searchSuppliersApiStore = new SearchSuppliersApiStore(_service);
 *
 * @param {VendorBrokerManagementService} _service - A service that provides broker detail data.
 */
@Injectable()
export class SearchSuppliersApiStore extends AbstractApiStore<SupplierDropdownItem[]> {
  constructor(private _service: VendorBrokerManagementService) {
    super();
  }

  loadSuppliers$ = this.effect((searchText$: Observable<string>) =>
    searchText$.pipe(
      tap(() => this.startLoading()),
      switchMap((search) => {
        return this._service.getSuppliers(search).pipe(
          tapResponse(
            (suppliers) => {
              const supplierList = suppliers.map((supplier) => ({
                esn: supplier?.esn,
                name: `${supplier.name} (${supplier?.esn})`,
              }));

              this.setData(_.sortBy(supplierList, 'name'));
            },
            () => {
              this.setError(ApiFailureText.Generic);
            }
          )
        );
      })
    )
  );
}
