import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';
import { assignWith, endsWith, isEqual } from 'lodash';
import { hasProperty } from './common-utils';
import { SpoilsTypes } from '../modules/supplier-detail/models/spoils-types';
import { Constants } from '../constants/constants';
import { isNullOrUndefined } from '../../app/common/common-methods';
import { isDraft, isStandardSupplier } from '@app/modules/supplier-detail/utilities/supplier-utils';
import { SupplierDetail } from '@app/modules/supplier-detail/models/supplier-detail';

export function isValidKeHEEmail(control: UntypedFormControl) {
  if (control.value && !endsWith(control.value.toLowerCase(), 'kehe.com')) {
    return { 'isInvalid': true };
  }
  return null;
}

export function isAnyControlTouchedAndInvalid(form) {
  const controls = getAllFormControls(form);
  return controls.some(control => control.touched && control.invalid);
}

export function getAllFormControls(form): UntypedFormControl[] {

  const controls: UntypedFormControl[] = [];
  if (form instanceof UntypedFormGroup) {
    Object.keys(form.controls).forEach(key => {
      controls.push(...getAllFormControls(form.get(key)));
    });
  } else if (form instanceof UntypedFormArray) {
    Object.keys(form.controls).forEach(key => {
      controls.push(...getAllFormControls(form.get(key)));
    });
  } else {
    controls.push(form);
  }
  return controls;
}

export function getFormControlValueMap(form): Map<UntypedFormControl, any> {
  const map = new Map<UntypedFormControl, any>();
  getAllFormControls(form).forEach(function (control) {
    map.set(control, control.value);
  });
  return map;
}

export function resetPristine(formValueMap: Map<UntypedFormControl, any>) {
  formValueMap.forEach((value, control) => {
    if (control.dirty && isEqual(value, control.value)) {
      control.markAsPristine();
    }
  });
}

export function getFormDirtyValue(form: any) {
  if (form instanceof UntypedFormGroup) {
    const result = {};
    Object.keys(form.controls).forEach(key => {
      if (form.get(key).dirty) {
        result[key] = getFormDirtyValue(form.get(key));
      }
    });
    return result;
  } else if (form instanceof UntypedFormArray) {
    const result = [];
    Object.keys(form.controls).forEach(key => {
      if (form.get(key).dirty) {
        result.push(getFormDirtyValue(form.get(key)));
      }
    });
    return result;
  } else {
    return form.value === undefined ? null : form.value;
  }
}

export function formatGeneralFormResponse(dirtyValue, form, canEditHeadquarterAddress) {

  if (canEditHeadquarterAddress) {
    formatHeadquarterAddress(dirtyValue, form);
  }
}

function formatHeadquarterAddress(dirtyValue, form) {
  if (form.headquarterAddress) {
    dirtyValue.headquarterAddress = form.headquarterAddress;
    dirtyValue.headquarterAddress.latitude = form.headquarterAddress.latitude ? parseFloat(form.headquarterAddress.latitude) : 0;
    dirtyValue.headquarterAddress.longitude = form.headquarterAddress.longitude ? parseFloat(form.headquarterAddress.longitude) : 0;
  } else {
    dirtyValue.headquarterAddress = {};
  }

  dirtyValue.headquarterAddress.phone = form.headquarterPhone;
  delete dirtyValue.headquarterPhone;

  dirtyValue.headquarterAddress.extension = form.headquarterExtension;
  delete dirtyValue.headquarterExtension;

  dirtyValue.headquarterAddress.fax = form.headquarterFax;
  delete dirtyValue.headquarterFax;

  dirtyValue.headquarterAddress.email = form.headquarterEmail;
  delete dirtyValue.headquarterEmail;
}

export function formatTermsFormResponse(dirtyValue, form, supplier: SupplierDetail) {
  if (hasProperty(dirtyValue, 'terms')) {
    const dirtyTermsForm = dirtyValue.terms;
    const rowTermsForm = form.terms;

    if (hasProperty(dirtyTermsForm, 'hasPalletCharge')) {
      dirtyTermsForm.palletChargeAmount = rowTermsForm.palletChargeAmount;
    }
    if (hasProperty(dirtyTermsForm, 'acceptsNewItemSetupFee')) {
      dirtyTermsForm.newItemSetupFee = rowTermsForm.newItemSetupFee;
    }
    if (hasProperty(dirtyTermsForm, 'hasAdministrativeAllowanceProgram')) {
      const aapPercentageValue = (isStandardSupplier(supplier) || (!isDraft(supplier) && !supplier.supplierType)) ? 0.02 * 100 : 0;
      dirtyTermsForm.administrativeAllowanceProgramPercentage = dirtyTermsForm.hasAdministrativeAllowanceProgram ? aapPercentageValue : 0;
    }
    formatTermsFormSpoilsResponse(dirtyTermsForm, rowTermsForm);
    formatTermsFormPromotionsResponse(dirtyTermsForm, rowTermsForm);
  }
}

export function formatTermsFormSpoilsResponse(dirtyTermsForm, rowTermsForm) {
  if (hasProperty(dirtyTermsForm, 'spoils')) {
    const dirtySpoilsForm = dirtyTermsForm.spoils;
    const rowSpoilsForm = rowTermsForm.spoils;

    if (hasProperty(dirtySpoilsForm, 'spoilsType')) {
      dirtySpoilsForm.allowancePercentage = rowSpoilsForm.allowancePercentage;
      if (dirtySpoilsForm.spoilsType === null || dirtySpoilsForm.spoilsType === undefined) {
        dirtySpoilsForm.type = null;
        dirtySpoilsForm.allowancePercentage = null;
      } else {
        if (dirtySpoilsForm.spoilsType === SpoilsTypes.fullCoverage) {
          dirtySpoilsForm.type = 'Y';
        } else {
          dirtySpoilsForm.type = 'N';
        }
      }
    }
  }
}

export function formatTermsFormPromotionsResponse(dirtyTermsForm, rowTermsForm) {
  if (hasProperty(dirtyTermsForm, 'promotions')) {
    const dirtyPromotionsForm = assignWith({}, dirtyTermsForm.promotions, (_, value) => value === undefined ? null : value);
    const rowPromotionsForm = assignWith({}, rowTermsForm.promotions, (_, value) => value === undefined ? null : value);

    if (hasProperty(dirtyPromotionsForm, 'acceptsManufacturerChargeBackFee') ||
      hasProperty(dirtyPromotionsForm, 'manufacturerChargeBackExceptionCode')) {

        dirtyPromotionsForm.acceptsManufacturerChargeBackFee = rowPromotionsForm.acceptsManufacturerChargeBackFee;
        if (rowPromotionsForm.acceptsManufacturerChargeBackFee === true) {
          dirtyPromotionsForm.manufacturerChargeBackExceptionCode = rowPromotionsForm.manufacturerChargeBackExceptionCode;
          dirtyPromotionsForm.manufacturerChargeBackFeeAmount = rowPromotionsForm.manufacturerChargeBackFeeAmount;
          dirtyPromotionsForm.manufacturerChargeBackFeePercentage = rowPromotionsForm.manufacturerChargeBackFeePercentage;
        } else {
          dirtyPromotionsForm.manufacturerChargeBackExceptionCode = '';
          dirtyPromotionsForm.manufacturerChargeBackFeeAmount = Constants.MCBFeeAmount;
          dirtyPromotionsForm.manufacturerChargeBackFeePercentage = Constants.MCBFeePercentage;
        }
    }
  }
}

export function markAllFormControlsAsDirty(form: any) {
  if (form instanceof UntypedFormGroup) {
    Object.keys(form.controls).forEach(key => {
      markAllFormControlsAsDirty(form.get(key));
    });
  } else if (form instanceof UntypedFormArray) {
    Object.keys(form.controls).forEach(key => {
      markAllFormControlsAsDirty(form.get(key));
    });
  } else {
    form.markAsDirty();
  }
}

/** Mark form controls as Touched and updates Validity */
export function validateControls(form: UntypedFormGroup): void {
  for (const key in form.controls) {
    if (Object.prototype.hasOwnProperty.call(form.controls, key)) {
      const control: AbstractControl = form.controls[key];
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity({ emitEvent: false });
    }
  }
  form.updateValueAndValidity({ emitEvent: false });
}

export function convertTermsDecimalFields(termsFormValue) {
  if (hasProperty(termsFormValue, 'terms')) {
    const termsValue = termsFormValue.terms;

    if (hasProperty(termsValue, 'shelfLifePercentage')) {
      const shelfLifePercentage = termsValue.shelfLifePercentage;
      if (shelfLifePercentage !== null) {
        if (!isNaN(Number(shelfLifePercentage))) {
          termsFormValue.terms.shelfLifePercentage = Number(Number(shelfLifePercentage * 100).toFixed(2));
        }
      }
    }
  }
}


export function formatSupplyChainFormResponse(dirtyValue, form) {
  if (hasProperty(dirtyValue, 'remittanceInfo')) {
    const remittanceInfo = dirtyValue.remittanceInfo;

    if (hasProperty(remittanceInfo, 'contactId')) {
      if (remittanceInfo.contactId === null) {
        dirtyValue.remittanceInfo = null;
      }
    }
  }
  if (hasProperty(dirtyValue, 'poTransmissionDetails')) {
    dirtyValue.poTransmissionDetails = {...form.poTransmissionDetails, ...form.poTransmissionDetails.poTransmissions};
    delete dirtyValue.poTransmissionDetails.poTransmissions;
    delete dirtyValue.poTransmissionDetails.isPONotEmpty;
  }
}

export function formatSupplyChainDCFormResponse(dirtyValue, rawFormValue) {
  formatSupplyChainDCFormPOOrderMinimum(dirtyValue, rawFormValue);
  formatSupplyChainDCFormItemOrderMinimum(dirtyValue, rawFormValue);

  if (hasProperty(dirtyValue, 'leadTimes')) {
    dirtyValue.leadTimes = rawFormValue.leadTimes;
  }
}

export function formatSupplyChainDCFormPOOrderMinimum(dirtyValue, rawFormValue) {
  if (hasProperty(dirtyValue, 'poOrderMinimum')) {
    dirtyValue.poOrderMinimum = assignWith({}, rawFormValue.poOrderMinimum, (_, value) => value === undefined ? null : value);
    const poOrderMinAmountIsEmpty = dirtyValue.poOrderMinimum.amount === null ||
      dirtyValue.poOrderMinimum.amount === undefined;

    const poOrderMinUnitIsEmpty = dirtyValue.poOrderMinimum.unit === null ||
      dirtyValue.poOrderMinimum.unit === undefined;

    if (poOrderMinAmountIsEmpty && poOrderMinUnitIsEmpty) {
      dirtyValue.poOrderMinimum = null;
    }
  }
}

export function formatSupplyChainDCFormItemOrderMinimum(dirtyValue, rawFormValue) {
  if (hasProperty(dirtyValue, 'itemOrderMinimum')) {
    dirtyValue.itemOrderMinimum = assignWith({}, rawFormValue.itemOrderMinimum, (_, value) => value === undefined ? null : value);

    const isItemOrderMinAmountEmpty = dirtyValue.itemOrderMinimum.amount === null
      || dirtyValue.itemOrderMinimum.amount === undefined;

    const isItemOrderMinUnitEmpty = dirtyValue.itemOrderMinimum.unit === null
      || dirtyValue.itemOrderMinimum.unit === undefined;

    if (isItemOrderMinAmountEmpty && isItemOrderMinUnitEmpty) {
      dirtyValue.itemOrderMinimum = null;
    }
  }
}

export function stringPatternValidator(
  stringRegex: RegExp,
  required = true
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;
    if (required || (!isNullOrUndefined(value) && value.length > 0)) {
      const pattern = stringRegex.test(control.value);
      return !pattern ? { pattern: { value: control.value } } : null;
    } else {
      return null;
    }
  };
}

export function formatBrokerFormResponse(dirtyValue) {
  if (hasProperty(dirtyValue, 'hasHeadquarterBroker')) {
    if (!dirtyValue.hasHeadquarterBroker) {
      dirtyValue['broker'] = {};
    }
  }
}

export function concatenateDateAndTime(date: Date, time: Date): Date {
  if (!date || !time) { return null; }
  return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
  );
};

export const orderConfirmationEmailList = [
  'CCSouthWest@kehe.com',
  'CCSouthEast@kehe.com',
  'CCSouthCentral@kehe.com',
  'CCNorthWest@kehe.com',
  'CCNorthEast@kehe.com',
  'CCNorthCentral@kehe.com',
  'CCNational@kehe.com',
  'KeHESolutions-AcctSupport@kehe.com'
];


/**
 * Utility function for troubleshooting form validation states.
 *
 * This function returns an array of control objects with their names and statuses.
 * It can be used for console logging or rendering in HTML to check the form's control statuses.
 *
 * Example usage in HTML:
 *   <ul>
 *     <li *ngFor="let controlStatus of controlStatuses">{{controlStatus.name}}: {{controlStatus.status}}</li>
 *   </ul>
 *
 * @param {FormGroup} form - The FormGroup instance to retrieve control statuses from.
 * @returns {Array<{name: string, status: string}>} An array of control objects with name and status properties.
 */
export function getControlStatuses(form: UntypedFormGroup) {
  return Object.entries(form.controls).map(([name, { status, value }]) => {
    const controls = (form.get(name) as UntypedFormGroup)?.controls
    const controlStatuses = controls ? getControlStatuses((form.get(name) as UntypedFormGroup)) : undefined
      return {
        name,
        status,
        value,
        controls: controlStatuses,
      };
  });
}


export function checkIfFormHasErrors(form: FormGroup) {
  return Object.values(form.controls).some((control) => control?.errors !== null && control?.touched)
}
