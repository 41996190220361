import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { PersonContact } from '../../models/person-contact';
import { cancelDeleteSupplierContact, deleteSupplierContact } from '../../store/supplier-contacts.actions';
import {
  selectIsSavingContact,
  selectManageContact,
  selectSaveContactError
} from '../../store/supplier-contacts.selectors';

@Component({
  selector: 'app-delete-contact-modal',
  templateUrl: './delete-contact-modal.component.html',
  styleUrls: []
})
export class DeleteContactModalComponent extends Destroyable implements OnInit {

  contact: PersonContact;
  inProgress = false;
  error = null;

  constructor(
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectManageContact))
      .subscribe((val) => {
        this.contact = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectIsSavingContact))
      .subscribe((val) => {
        this.inProgress = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSaveContactError))
      .subscribe((val) => {
        this.error = val;
      });
  }

  closeModal() {
    this._store.dispatch(cancelDeleteSupplierContact());
  }

  onDeleteClicked() {
    this._store.dispatch(deleteSupplierContact());
  }
}
