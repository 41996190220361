<kehe-phoenix-modal modalTitle="Edit DC Product Group(s)" [show]="true" (modalEvent)="onCancel($event)" size="md">
  <div body class="mt-4 bulk-edit-container" *ngIf="showValidationDetails()">
    <kehe-address-verification *ngIf="validationResponse" [addressForm]="addressForm"
      [addressValidation]="validationResponse">
    </kehe-address-verification>
  </div>
  <div body [formGroup]="form" class="bulk-edit-container" *ngIf="!showValidationDetails()">
    <kehe-phoenix-progress-tracker containerBreakWidth="0" [steps]="steps"></kehe-phoenix-progress-tracker>
    <div class="mt-2">
      <kehe-phoenix-alert *ngIf="showErrorAlert" [messageAsHtml]="errorBannerMessage" [type]="alertTypes.Error" automationId="bulk-edit-dcpg-modal-error" [stick]="true"

        [hideCloseIcon]="true" [show]="true"></kehe-phoenix-alert>
      <kehe-phoenix-alert *ngIf="warningBannerMessage" [messageAsHtml]="warningBannerMessage" [type]="alertTypes.Warning" automationId="bulk-edit-dcpg-modal-warning" [stick]="true"
        [hideCloseIcon]="true" [show]="true"></kehe-phoenix-alert>
    </div>

    <ng-container [ngSwitch]="page">
      <div *ngSwitchCase="bulkEditPages.SelectTypePage">
        <app-bulk-edit-select-action-card [dcList]="dcList" [form]="form" [isEditPOEmail]="isEditPOEmail"
        [hasWarning]="warningBannerMessage">
        </app-bulk-edit-select-action-card>
      </div>
      <div *ngSwitchCase="bulkEditPages.DeliveryMethodPage">
        <kendo-formfield formGroupName="details">
          <h5>Delivery Method</h5>
          <kendo-combobox #deliveryMethod formControlName="deliveryMethodCode" textField="label" valueField="value"
            data-automation-id="bulk-edit-dcpg-modal-delivered-method-input"
            placeholder="Select Delivery Method" (focus)="deliveryMethod.toggle()" [data]="deliveryMethods"
            [valuePrimitive]="true" [filterable]="true" [kendoDropDownFilter]="{ operator: 'contains' }">
          </kendo-combobox>
          <kendo-formerror>Delivery Method is required.</kendo-formerror>
        </kendo-formfield>
      </div>
      <div *ngSwitchCase="bulkEditPages.PickupAddressPage">
        <div class="row mt-4">
          <div class="col">
            <h5>Pickup Address</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-contact-form [defaultContactType]="contactTypes.PickupAddress" [form]="detailsForm"
              [hideContactType]="true">
            </app-contact-form>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="bulkEditPages.DeliveredAddressPage">
        <div class="row mt-4">
          <div class="col">
            <h5>Delivered Address</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-contact-form [defaultContactType]="contactTypes.DeliveredAddress" [form]="detailsForm"
              [hideContactType]="true">
            </app-contact-form>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="bulkEditPages.POFaxPage" formGroupName="details" class="mt-4">
        <h5>PO Transmissions and Details</h5>
        <div class="row mt-4" formGroupName="poFax">
          <div class="col-4">
            <kendo-formfield>
              <kendo-label text="Type"></kendo-label>
              <kendo-combobox #poFaxType (focus)="poFaxType.toggle()" [data]="pOOrderTypes" formControlName="type"
                placeholder="Select Type">
              </kendo-combobox>
              <kendo-formerror>
                Type is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="col-6 pl-2">
            <kehe-phoenix-phone-number-input formControlName="fax" [required]="true" errorRequired="PO Fax is required."
              label="PO Fax">
            </kehe-phoenix-phone-number-input>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="bulkEditPages.LeadTimesPickupAddressPage">
        <app-bulk-edit-lead-times-pickup-address-card [form]="detailsForm.get('pickup')">
        </app-bulk-edit-lead-times-pickup-address-card>
      </div>
      <div *ngSwitchCase="bulkEditPages.BuyersPage">
        <h5>Buyers</h5>
        <p>All Distribution Centers must be assigned a buyer.</p>
        <kendo-formfield class="mt-3" formGroupName="details">
          <kendo-label [for]="buyerField" text="Buyer"></kendo-label>
          <kendo-combobox #buyerField formControlName="buyerCode" textField="name" valueField="code"
            placeholder="Select Buyer" (focus)="buyerField.toggle()" [data]="buyerList$ | async" [valuePrimitive]="true"
            [filterable]="true" [kendoDropDownFilter]="{ operator: 'contains' }"></kendo-combobox>
          <kendo-formerror>Buyer is required.</kendo-formerror>
        </kendo-formfield>
      </div>
      <div *ngSwitchCase="bulkEditPages.DeliveredLeadTimesPage">
        <app-bulk-edit-delivered-lead-times-card [form]="detailsForm.get('delivered')" [isTotalTime]="true"
          label="Total Lead Time">
        </app-bulk-edit-delivered-lead-times-card>
      </div>
      <div *ngSwitchCase="bulkEditPages.DeliveryMethodLeadTimesPage">
        <app-bulk-edit-lead-times-delivered-address-card [form]="detailsForm.get('delivered')">
        </app-bulk-edit-lead-times-delivered-address-card>
      </div>
      <div *ngSwitchCase="bulkEditPages.PickupLeadTimesPage">
        <app-bulk-edit-delivered-lead-times-card [form]="form.get('details')" label="Supplier Ready Time">
        </app-bulk-edit-delivered-lead-times-card>
      </div>
      <div *ngSwitchCase="bulkEditPages.ChangePickupTypePage">
        <kendo-formfield formGroupName="details">
          <kendo-label [for]="inboundRouteTypeComponent" text="Inbound Route Type"></kendo-label>
          <kendo-combobox
            #inboundRouteTypeComponent
            (focus)="inboundRouteTypeComponent.toggle()"
            [data]="inboundRouteTypes"
            [textField]="'name'"
            [valueField]="'type'"
            [valuePrimitive]="true"
            [filterable]="true"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
            formControlName="inboundRouteType"
            placeholder="Select Inbound Route Type"
            data-automation-id="bulk-edit-dcpg-modal-delivered-method-input"
          ></kendo-combobox>
          <kendo-formerror>
            Inbound Route Type is required.
          </kendo-formerror>
        </kendo-formfield>
      </div>
      <div *ngSwitchCase="bulkEditPages.PickupContact">
        <app-bulk-edit-pickup-contact [form]="detailsForm">
        </app-bulk-edit-pickup-contact>
      </div>
      <div *ngSwitchCase="bulkEditPages.AddPOEmailPage">
        <app-bulk-edit-po-email [form]="detailsForm" [page]="page" [selectedDCs]="getSelectedDCs()" [hasWarning]="warningBannerMessage" [warningInputs]="poEmailWarnings">
        </app-bulk-edit-po-email>
      </div>
      <div *ngSwitchCase="bulkEditPages.ReplacePOEmailPage">
        <app-bulk-edit-po-email [form]="detailsForm" [page]="page" [selectedDCs]="getSelectedDCs()" [hasWarning]="warningBannerMessage" [warningInputs]="poEmailWarnings">
        </app-bulk-edit-po-email>
      </div>
      <div *ngSwitchCase="bulkEditPages.RemovePOEmailPage">
        <app-bulk-edit-po-email [form]="detailsForm" [page]="page" [selectedDCs]="getSelectedDCs()"
        [hasWarning]="warningBannerMessage">
        </app-bulk-edit-po-email>
      </div>
      <app-bulk-edit-notes-form *ngIf="isLastPage" [form]="notesForm"></app-bulk-edit-notes-form>
    </ng-container>
  </div>
  <div footer class="d-flex justify-content-end mt-2" *ngIf="!showValidationDetails()">
    <kehe-phoenix-button *ngIf="isFirstPage" (click)="onCancel()" classAttr="btn-primary btn-medium btn-link mr-3"
                         automationId="bulk-edit-dcpg-modal-cancel-button">
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button *ngIf="!isFirstPage" [disabled]="loading" (click)="onBack()"
      automationId="bulk-edit-dcpg-modal-back-button"
      classAttr="btn-primary btn-medium btn-link mr-3">
      Back
    </kehe-phoenix-button>
    <kehe-phoenix-button *ngIf="!isLastPage" (click)="onNext()" classAttr="btn-primary btn-medium"
                         automationId="bulk-edit-dcpg-modal-next-button"
                         [disabled]="form.invalid">
      Next
    </kehe-phoenix-button>
    <kehe-phoenix-button *ngIf="isLastPage" (click)="onApply()" classAttr="btn-primary btn-medium"
      automationId="bulk-edit-dcpg-modal-apply-button"
      [disabled]="form.invalid || loading" [spinner]="loading">
      Apply
    </kehe-phoenix-button>
  </div>
  <div footer class="d-flex flex-row justify-content-end" *ngIf="showValidationDetails()">
    <kehe-phoenix-button (click)="onEditAddressClicked()" class="modal-btn-spacing mr-2" classAttr="btn-medium btn-link"
      automationId="bulk-edit-dcpg-modal-edit-address-button"
      [disabled]="loading">
      Edit Address
    </kehe-phoenix-button>
    <kehe-phoenix-button name="addPickupAddress" classAttr="btn-primary btn-modal btn-medium" (click)="useAddress()"
      automationId="bulk-edit-dcpg-modal-use-address-button"
      [spinner]="loading">
      Use Address
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
