import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { selectEditMode } from '../../store/supplier-detail.selectors';
import { confirmCancelEdit } from '../../store/supplier-detail.actions';

@Component({
  selector: 'app-terms-tab',
  templateUrl: './terms-tab.component.html'
})

export class TermsTabComponent extends Destroyable implements OnInit, OnDestroy {

  isEdit = false;

  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store.dispatch(confirmCancelEdit());
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectEditMode))
      .subscribe((val) => {
        this.isEdit = val;
    });
  }

  ngOnDestroy() {
    if (this.isEdit) {
      this._store.dispatch(confirmCancelEdit());
    }
    super.ngOnDestroy();
  }
}

