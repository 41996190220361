import {
  userNotificationAdapter,
  userNotificationCardAdapter,
  userNotificationsInitialState,
  UserNotificationsState
} from './user-notifications.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  clearUserNotificationsSuccessAction,
  getUserNotificationsSuccessAction
} from './user-notifications.actions';
import { UserNotificationRecord } from '../model/service/user-notifications';
import { Payload } from '../../../models/payload';
import { UserNotificationEntity } from '../model/user-notifications';
import { UserNotificationCard } from '@kehe/phoenix-top-bar';

const reducer = createReducer(userNotificationsInitialState,
  on(getUserNotificationsSuccessAction,
    (state, { response }) => getUserNotificationsSuccess(state, response)
  ),
  on(clearUserNotificationsSuccessAction,
    (state, { notificationIds }) => clearUserNotificationsSuccess(state, notificationIds)
  )
);

export function userNotificationReducer(state: UserNotificationsState | undefined, action: Action) {
  return reducer(state, action);
}

function getUserNotificationsSuccess(
  state: UserNotificationsState,
  response: Payload<UserNotificationRecord[]>
): UserNotificationsState {
  return {
    ...state,
    entityState: userNotificationAdapter.setAll(
      !!response && !!response.data ? response.data.map(record => convertToUserNotificationEntity(record)) : [],
      state.entityState
    ),
    displayCardState: userNotificationCardAdapter.setAll(
      !!response && !!response.data ? response.data.map(record => buildUserNotificationCard(record)) : [],
      state.displayCardState
    ),
    notificationCount: !!response && !!response.availableCount ? response.availableCount : 0
  };
}

function clearUserNotificationsSuccess(
  state: UserNotificationsState,
  notificationIds: number[] = []
): UserNotificationsState {
  return {
    ...state,
    entityState: userNotificationAdapter.removeMany(
      notificationIds,
      state.entityState
    ),
    displayCardState: userNotificationCardAdapter.removeMany(
      notificationIds,
      state.displayCardState
    ),
    notificationCount: state.notificationCount - notificationIds.length
  };
}

function convertToUserNotificationEntity(record: UserNotificationRecord): UserNotificationEntity {
  return {
    created: record.created,
    email: record.email,
    isCleared: record.isCleared,
    linkText: record.linkText,
    linkUrl: record.linkUrl,
    message: record.message,
    notificationId: record.notificationId,
    origin: record.origin,
    subtitle: record.subtitle,
    title: record.title
  };
}

function buildUserNotificationCard(record: UserNotificationRecord): UserNotificationCard {
  return {
    notificationId: record.notificationId,
    title: record.title,
    titleIcon: getTitleIcon(record.title),
    created: record.created,
    subtitle: record.subtitle,
    message: record.message,
    linkText: record.linkText,
    linkUrl: record.linkUrl,
  };
}

function getTitleIcon(title: string): string {
  switch (title) {
    case 'Countered':
      return 'far fa-exchange';
    case 'Declined':
      return 'fas fa-times-circle';
    case 'Proposed':
      return 'fas fa-envelope';
    case 'Submitted':
      return 'fas fa-handshake';
    case 'Updated':
      return 'fas fa-bell';
    default:
      return undefined;
  }
}
