<div class="px-4">

  <div class="row">
    <div class="col">
      <h4>Headquarter Broker</h4>
    </div>
  </div>
  <div class="row kehe-data-section">
    <div class="col-12 mt-2">
      <div class="row mb-3">
        <div class="col-12 kehe-data-value">
          KeHE will grant access to the supplier's information in CONNECT normally provided to brokers and
          manufacturers.
          This includes but is not limited to promotion submissions, price management, sales and reporting.
        </div>
      </div>
      <div class="row mb-2 mt-1">
        <div class="col-12 kehe-data-value">
          <h5>Supplier and Broker Responsibilities</h5>
        </div>
        <div class="col-12 kehe-data-value">
          <ul class="ps-3">
            <li>All personnel within the brokerage will have access to the supplier.</li>
            <li>The Broker of Record is ultimately responsible for submitting promotions through CONNECT.</li>
            <li>The supplier is responsible for removing the broker in the event of supplier/broker termination.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row kehe-data-section">
    <div class="col-12 col-md-6 mt-3">
      <div class="row">
        <div class="col-12 col-sm-6 kehe-data-label">
          Headquarter Broker
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{ hasHeadquarterBroker() }}
        </div>
      </div>
      <div class="row" *ngIf="supplier && supplier.hasHeadquarterBroker && !isHeadquarterBrokerNotFound()">
        <div class="col-12 col-sm-6 kehe-data-label">
          Broker Name
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{ brokerName | emptyValue }}
        </div>
      </div>
      <div class="row" *ngIf="canShowBrokerContact && !!this.broker">
        <div class="col-12 col-sm-6 kehe-data-label">
          Broker Contact
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{ brokerContact | emptyValue: knowsHeadquarterBrokerContact === undefined || knowsHeadquarterBrokerContact
          === null || contactNotSpecified ? '--' : 'No' }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <div class="row" *ngIf="supplier && supplier.hasHeadquarterBroker && !isHeadquarterBrokerNotFound()">
        <div class="col-12 col-sm-6 kehe-data-label">
          Association Date
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{supplier?.broker?.created | date: 'MM/dd/yy' | emptyValue}}
        </div>
      </div>
      <div class="row" *ngIf="isHeadquarterBrokerNotFound()">
        <div class="col-12 col-sm-6 kehe-data-label">
          Broker Form
        </div>
        <div class="col-12 col-sm-6 kehe-data-value">
          {{ brokerDocument?.filename | emptyValue }}
        </div>
      </div>
    </div>
  </div>
</div>