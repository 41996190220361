import { IKeheTab } from '@kehe/phoenix-tabs';
import { IFileUpload } from '@kehe/phoenix-upload';
import { createAction, props } from '@ngrx/store';
import { Payload } from '../../../models/payload';
import { MassUpdateTabs } from '../models/mass-update-tabs';
import { MassUpdateFilter } from '../models/mass-update-filter';
import { MassUpdateDetail } from '../models/mass-update-detail';
import { MassUploadForm } from '../models/mass-upload-form';
import { SupplierMassUpdateTabs } from '../models/supplier-mass-update-tabs';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';

export const initializeSupplierMassUpdatePage = createAction(
  '[Supplier Mass Update]: InitializeSupplierMassUpdatePage',
  props<{
    tab?: string;
    tabList: { tab: SupplierMassUpdateTabs }[];
  }>(),
);

export const setSupplierMassUpdateTabList = createAction(
  '[Supplier Mass Update]: Update Supplier Mass Update Page',
  props<{
    tab?: string;
    tabList: { tab: SupplierMassUpdateTabs }[];
  }>()
);

export const setSupplierMassUpdateTab = createAction(
  '[Supplier Mass Update] Update Supplier Mass Update Tab',
  props<{ currentTab: SupplierMassUpdateTabs; }>()
)

export const toggleSupplierMassUpdateNavPanel = createAction(
  '[Supplier Mass Update]: Toggle Supplier Mass Update Nav Panel'
);

export const downloadMassUpdateTemplate = createAction(
  '[Supplier Mass Update] download Mass Update Template'
);

// List Action
export const updateSelectedTab = createAction(
  '[Supplier Mass Update] Update Buyer Selected Tab',
  props<{ tab: IKeheTab; }>()
);

export const loadMassUpdates = createAction(
  '[Supplier Mass Update] Load Buyer Mass Updates',
);

export const loadMassUpdatePendingSuccessful = createAction(
  '[Supplier Mass Update] Load Buyer Mass Update Pending Successful',
  props<Payload<MassUpdateDetail[]> & { currentTab: SupplierMassUpdateTabs; }>()
);

export const loadMassUpdateCompletedSuccessful = createAction(
  '[Supplier Mass Update] Load Buyer Mass Update Completed Successful',
  props<Payload<MassUpdateDetail[]> & { currentTab: SupplierMassUpdateTabs; }>()
);

export const updateMassUpdateFilter = createAction(
  '[Supplier Mass Update] Update Buyer mass Update Filter',
  props<{
    filterType: MassUpdateTabs;
    filter: MassUpdateFilter;
    currentTab: SupplierMassUpdateTabs;
  }>()
);

export const loadMassUpdatesError = createAction(
  '[Supplier Mass Update] Load Mass Updates Error',
);

// Create Action
export const clickMassUploadButton = createAction(
  '[Supplier Mass Update]: Click Mass Upload Button'
);

export const clickCancelMassUpload = createAction(
  '[Supplier Mass Update]: Click Cancel Mass Upload'
);

export const createMassUpdateJob = createAction(
  '[Supplier Mass Update]: Create Mass Update Job',
  props<{
    updates: Omit<MassUploadForm, 'uniqueId' | 'fileName'>;
    file: IFileUpload
  }>()
);

export const uploadMassUpdateForm = createAction(
  '[Supplier Mass Update]: Upload Mass Update Template',
  props<{ updates: MassUploadForm }>()
);

export const uploadMassUpdateFormSuccess = createAction(
  '[Supplier Mass Update]: Upload Mass Update Form Success',
  props<{ job: MassUpdateDetail }>()
);

export const createMassUpdateJobFailed = createAction(
  '[Supplier Mass Update]: Create Mass Update Job Failed',
  props<{ message: string; }>()
);

export const resetMassUpdateError = createAction(
  '[Supplier Mass Update]: Reset Mass Update Error'
);
// Delete action
export const deleteMassUpdateJob = createAction(
  '[Supplier Mass Update]: Delete Mass Update Job',
  props<{ massUpdate: MassUpdateDetail; }>()
);

export const deleteMassUpdateJobSuccess = createAction(
  '[Supplier Mass Update]: Delete Mass Update Job Success',
);

export const deleteMassUpdateJobFailed = createAction(
  '[Supplier Mass Update] Delete Mass Update Job Failed',
  props<{ message: string; }>()
);
// Edit Action
export const showEditModal = createAction(
  '[Supplier Mass Update] Show Edit Modal',
  props<{ item: MassUpdateDetail }>()
);
export const hideEditModal = createAction(
  '[Supplier Mass Update] Hide Edit Modal',
);
export const submitEdit = createAction(
  '[Supplier Mass Update] Submit Edit',
  props<{ uniqueId: string, updates: MassUploadForm }>()
);
export const editSuccess = createAction(
  '[Supplier Mass Update]: Edit Success',
  props<{ job: MassUpdateDetail }>()
);

export const editFailed = createAction(
  '[Supplier Mass Update]: Edit Failed',
  props<{ message: string; }>()
);
// Edit Action

// Download Result
export const downloadResult = createAction(
  '[Supplier Mass Update]: Download Result',
  props<{ job: MassUpdateDetail }>()
);

export const downloadOriginal = createAction(
  '[Supplier Mass Update]: Download Original',
  props<{ job: MassUpdateDetail }>()
);
// Download Result

export const updateKendoBreadCrumbsList = createAction(
    '[Supplier Mass Update] Update BreadCrumbs List',
    props<{ items: BreadCrumbItem[] }>()
);
