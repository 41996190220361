<kehe-phoenix-modal
  *ngIf="vm$ | async as vm"
  name="AddSupplier"
  modalTitle="Add Association"
  [show]="true"
  (modalEvent)="handleKeheModalEvents($event)"
  size="md"
  [formGroup]="form"
>
  <div body class="add-supplier-body">
    <kehe-phoenix-alert *ngIf="vm.apiErrorMessage; else formAlert" [show]="true" type="error" [message]="vm.apiErrorMessage" [stick]="true"
      automationId="associated-new-supplier-modal-api-error"
      [hideCloseIcon]="true" class="mb-3 api-alert">
    </kehe-phoenix-alert>

    <ng-template #formAlert>
      <kehe-phoenix-alert *ngIf="formErrorText" [show]="true" type="error" [message]="formErrorText" [stick]="true"
        automationId="associated-new-supplier-modal-form-error"
        [hideCloseIcon]="true" class="mb-3 form-alert">
      </kehe-phoenix-alert>
    </ng-template>

    <kehe-phoenix-alert [show]="true" type="information" message="Adding an association will connect the two Suppliers, as well as all other Suppliers that are currently associated." [stick]="true"
      automationId="associated-new-supplier-modal-info"
      [hideCloseIcon]="true" class="mb-3 api-alert">
    </kehe-phoenix-alert>

    <div class="mt-2 pad-bottom">
        <kendo-formfield>
          <kendo-label text="Associate Supplier"></kendo-label>
          <kendo-combobox
            [formControlName]="controlName.Supplier"
            [data]="vm.suppliers"
            placeholder="Search by Supplier name or ESN"
            data-automation-id="associate-new-supplier-modal-supplier-input"
            [loading]="vm.isLoadingSuppliers"
            [filterable]="true"
            textField="name"
            valueField="esn"
            (filterChange)="supplierFilterChanged($event)"
          >
          </kendo-combobox>
          <kendo-formerror
            >Associate Supplier is required.</kendo-formerror
          >
        </kendo-formfield>
    </div>

    <app-add-note *ngIf="!vm.isDraftSupplier && selectedSupplier"></app-add-note>
  </div>
  <div footer class="d-flex justify-content-end px-1">
    <kehe-phoenix-button
      class="cancel-button"
      classAttr="btn-medium btn-link"
      automationId="associate-new-supplier-modal-cancel-button"
      (click)="close()"
    >
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button
      classAttr="btn-medium btn-primary ml-2"
      (click)="submit()"
      [spinner]="vm.addButton.isSpinning"
      automationId="associate-new-supplier-modal-add-button"
      [disabled]="vm.addButton.isDisabled"
    >
      Add
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
