import { Component } from '@angular/core';
import { BrokerTabStore } from './broker-v2-tab.store';
import { BrokerTabGridStore } from './broker-tab-v2-grid/broker-tab-v2-grid.store';

@Component({
  selector: 'app-broker-tab-v2',
  templateUrl: './broker-tab-v2.component.html',
  styleUrls: ['./broker-tab-v2.component.scss'],
  providers: [BrokerTabStore, BrokerTabGridStore],
})
export class BrokerTabV2Component {
  vm$ = this._brokerTabStore.vm$;

  constructor(private _brokerTabStore: BrokerTabStore) {}

  noBrokerModal() {
    this._brokerTabStore.openSkipModal();
  }

  onAddClick() {
    this._brokerTabStore.openEditModal();
  }
}
