import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactFormService } from '@kehe/phoenix-contact';
import { select, Store } from '@ngrx/store';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { getFormDirtyValue, stringPatternValidator } from '../../../../utils/form-utils';
import { PersonContactTypes } from '../../enums/person-contact-types';
import { PersonContact } from '../../models/person-contact';
import { cancelManageSupplierContact, saveSupplierContact } from '../../store/supplier-contacts.actions';
import {
  selectIsSavingContact,
  selectManageContact,
  selectSaveContactError
} from '../../store/supplier-contacts.selectors';
import { selectIsFeatureFlagOn } from '../../../../shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '../../../../feature-flag';
import { selectIsInvitedDraftSupplier } from '../../../supplier-detail/store/supplier-detail.selectors';

@Component({
  selector: 'app-manage-contact-modal',
  templateUrl: './manage-contact-modal.component.html',
  styleUrls: []
})
export class ManageContactModalComponent extends Destroyable implements OnInit {

  contact: PersonContact;

  contactTypes: string[] = [];
  filteredContactTypes: string[] = [];

  form: UntypedFormGroup;
  inProgress = false;
  error = null;
  phoneExtPattern: RegExp;
  emailPattern: RegExp;

  @ViewChild('formTop') formTop: ElementRef<HTMLDivElement>;

  constructor(
    private _store: Store,
    private _formBuilder: UntypedFormBuilder,
    private _contactFormService: ContactFormService,
  ) {
    super();

    this._store
    .pipe(
      select(selectIsInvitedDraftSupplier),
      withLatestFrom(
        this._store.select(
          selectIsFeatureFlagOn(FeatureFlags.SupplierOnboardingV4.key)
        ),
      ),
      takeUntil(this.destroy$)
    )
    .subscribe(([isInvitedDraftSupplier, supplierOnboardingV4Flag]) => {
      let personContactTypes = Object.values(PersonContactTypes).sort((a, b) => a.localeCompare(b));
      if (!supplierOnboardingV4Flag || !isInvitedDraftSupplier) {
        // Remove PersonContactTypes.UDR if the flag is false
        personContactTypes = personContactTypes.filter(type => type !== PersonContactTypes.UDR);
      }
      this.contactTypes = personContactTypes;
      this.filteredContactTypes = personContactTypes;
    });
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectManageContact))
      .subscribe((val) => {
        if (val) {
          this.contact = val;
          this.buildForm();
        }
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectIsSavingContact))
      .subscribe((val) => {
        this.inProgress = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSaveContactError))
      .subscribe((val) => {
        this.error = val;

        if (this.error !== null) {
          this.formTop.nativeElement.scrollIntoView({
            behavior: 'smooth',
          });
        }
      });
  }

  private buildForm() {
    this.emailPattern = this._contactFormService.getEmailRegex();
    this.phoneExtPattern = this._contactFormService.getPhoneExtRegex();
    this.form = this._formBuilder.group({
      uniqueId: [this.contact.uniqueId],
      esn: [this.contact.esn],
      contactTypes: [this.contact.contactTypes, [Validators.required]],
      name: [this.contact.name || '', [Validators.required, Validators.maxLength(100)]],
      phone: [this.contact.phone || '', [Validators.required]],
      phoneExtension: [
        this.contact.phoneExtension ?  +this.contact.phoneExtension : null,
        [
          Validators.maxLength(5),
          stringPatternValidator(this._contactFormService.getPhoneExtRegex(), false),
        ],
      ],
      fax: [this.contact.fax || ''],
      email: [
        this.contact.email || '',
        [
          Validators.required,
          stringPatternValidator(this._contactFormService.getEmailRegex(), false),
          Validators.maxLength(100),
        ],
      ],
      isPrimary: [this.contact.isPrimary || false],
      subscribedToDiscontinuedProductNotification: [this.contact.subscribedToDiscontinuedProductNotification || false],
      subscribedToKeheNewsLetter: [this.getSubscribedToKeheNewsLetter()],
    });
    this.form.get('subscribedToKeheNewsLetter').disable();
    if (!this.contact.uniqueId) {
      this.form.get('uniqueId').disable();
    }
  }

  getSubscribedToKeheNewsLetter() {
    if (this.contact.subscribedToKeheNewsLetter !== null &&
      this.contact.subscribedToKeheNewsLetter !== undefined) {
      return this.contact.subscribedToKeheNewsLetter;
    }
    return true;
  }

  getTitle() {
    if (this.contact?.uniqueId) {
      return 'Edit Contact';
    }
    return 'Add Contact';
  }

  getActionButtonTitle() {
    if (this.contact?.uniqueId) {
      return 'Update';
    }
    return 'Add';
  }

  closeModal() {
    this._store.dispatch(cancelManageSupplierContact());
  }

  onFocus(element: any): void {
    element.toggle(true);
  }

  onSaveClicked() {
    const dirtyValue = getFormDirtyValue(this.form);
    const request = {
      uniqueId: this.contact.uniqueId,
      esn: this.contact.esn,
      email: this.form.getRawValue().email,
      ...dirtyValue,
    };
    this._store.dispatch(saveSupplierContact({ contact: request }));
  }

  filterTypes(filterText: string) {
    if (filterText && filterText.length > 0) {
      this.filteredContactTypes = this.contactTypes.filter((item) => item.toLowerCase().includes(filterText.toLowerCase()));
    } else {
      this.filteredContactTypes = this.contactTypes;
    }
  }
}
