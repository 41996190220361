import { Action, createReducer, on } from '@ngrx/store';
import * as SupplierDetailActions from './supplier-detail.actions';
import * as ProductGroupActions from '../../product-groups/store/product-groups.actions';
import * as SupplierDcPaymentHoldActions from '@app/modules/supplier-dc-payment-holds/store/supplier-dc-payment-holds.actions'
import { SupplierDetail } from '../models/supplier-detail';
import { SupplierDetailTabs } from '../models/supplier-detail-tabs';
import { SupplierGeneralFormModel } from '../models/supplier-general-form-model';
import { UpdateSupplierStatusForm } from '../models/update-supplier-status-form-model';
import { AlertMessage } from '../../../models/alert-message';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { IChip } from '@kehe/phoenix-chips';
import { DcSupplierFilter } from '../models/dc-supplier-filter';
import { BrokerDetail } from '../../broker-detail/models/broker-detail';
import { BrokerDetail as BrokerContactDetails } from '../../supplier-detail/models/broker-detail';
import { TermsFormObject } from '../models/terms-form-object';
import { CodeValue } from '../models/code-value';
import { DcSupplierListItem } from '../models/dc-supplier-list-item';
import { DcSupplierForm } from '../models/dc-supplier-form';
import { IAuditDetail, IAuditModalConfiguration } from '@kehe/phoenix-audit-history';
import { Payload } from '../../../models/payload';
import { SupplierHistoryFilterItem, SupplierHistoryFilterOption } from '../models/supplier-history-filter';
import { Filter } from '../../../models/filter';
import { ProductGroup } from '../models/product-group';
import { ProductGroupsFilter } from '../models/product-groups-filter';
import { SupplierProductGroupForm } from '../models/product-group-form';
import { DCProductGroup } from '../models/dc-product-group';
import { SupplierDCProductGroupForm } from '../models/product-group-dc-form';
import { ProductGroupViews } from '../models/product-group-views';
import { Buyer } from '../models/buyer';
import { isDCProductGroupTransitTimeDefault } from '../utilities/product-group-utils';
import { cleanAddress, defaultAddress } from '../../../utils/address-utils';
import { PromotionTerms } from '../models/promotion-terms';
import { SupplierStatus } from '../models/supplier-status';
import { Terms } from '../models/terms';
import { PaymentTerms } from '../models/payment-terms';
import { SpoilsTypes } from '../models/spoils-types';
import { OnBoardingStatus } from '../models/onboarding-status';
import {
  ChecklistNavigationTab
} from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';
import { SupplierAction } from '../models/supplier-action';
import { TermsDropdownData } from '../models/terms-dropdown-data';
import { Contact, ContactType } from '../../../models/contact';
import { Signer } from '../models/signer';
import { DocumentType } from '../../supplier-documents/models/document-type';
import { AddNoteForm } from '../models/add-note-form';
import { Spoils } from '../models/spoils';
import { DateUtils } from '@kehe/phoenix-utils';

export const supplierDetailStateKey = 'supplierDetail';

export class SupplierHistoryListState<T = any, U = any> {
  isLoading: boolean;
  list: Array<T>;
  availableCount: number;
  filter: U;
  displayFilterPanel: boolean;
}

export interface SupplierHistoryTab extends SupplierHistoryListState<IAuditDetail, SupplierHistoryFilter> {
  modalConfig: IAuditModalConfiguration;
  chips: Array<IChip>;
}

export interface SupplierHistoryFilter extends Filter {
  search?: string;
  selectedEvent?: SupplierHistoryFilterItem;
  selectedField?: SupplierHistoryFilterOption;
  selectedEditedBy?: SupplierHistoryFilterItem;
  selectedDC?: SupplierHistoryFilterItem;
  selectedDocument?: DocumentType;
  createdFrom?: Date;
  createdTo?: Date;
}

export class SupplierDetailState {
  generalInfoLoading: boolean;
  esn: string;
  detail: SupplierDetail;
  broker: BrokerContactDetails;
  brokerDetailLoading: boolean;
  termsDropdownData: TermsDropdownData[];
  brokerDetail: BrokerDetail;
  onboardingStatus: OnBoardingStatus[];
  onboardingStatusChangeAction: SupplierAction;
  loadingOnboardingStatusChange: boolean;
  onboardingStatusChangeError: string;
  currentTab: SupplierDetailTabs;
  editMode: boolean;
  saveInProgress: boolean;
  submitInProgress: boolean;
  showUnsavedChangesModal: boolean;
  showAddBrokerModal: boolean;
  showRemoveBrokerModal: boolean;
  showAddProductGroupModal: boolean;
  brokerForm: any;
  generalForm: SupplierGeneralFormModel;
  generalFormIsDirty: boolean;
  generalFormIsHeadquarterAddressDirty: boolean;
  displayTabPanel: boolean;

  supplierForm: any;
  supplierFormIsDirty: boolean;
  supplierFormIsValid: boolean;

  // alert message that displays at the top of the detail screen
  alertMessage: AlertMessage;
  leadTimeAlert: AlertMessage;
  selectedProductGroupView: ProductGroupViews;
  productGroupForm: SupplierProductGroupForm;
  productGroupFormIsDirty: boolean;
  productGroupRemittanceAddressDirty: boolean;
  productGroupFormIsValid: boolean;
  editDCProductGroup: DCProductGroup;
  refinedDcSupplierList: any;
  dcSupplierSearch: string;
  displayDcSupplierFilterPanel: boolean;
  dcSupplierFilter: DcSupplierFilter;
  dcSupplierFilterChips: any;
  productGroupsFilterPanelDisplay: boolean;
  onProductGroupsDetailsView: boolean;
  validatingHeadquarterAddress: boolean;
  validatingRemittanceAddress: boolean;
  validatingPickupAddress: boolean;
  termsForm: TermsFormObject;
  generalFormIsValid: boolean;
  termsFormIsDirty: boolean;
  termsFormIsValid: boolean;
  paymentTermsList: CodeValue[];
  marketingAllowanceList: CodeValue[];
  deliveryMethodList: any[];
  buyerList: any[];
  selectedDcSupplierDc: string;
  selectedDcSupplierNumber: string;
  dcSupplierForm: any;
  dcSupplierFormIsDirty: boolean;
  dcSupplierFormIsValid: boolean;
  dcSupplierFormIsPickupAddressDirty: boolean;
  productGroupDcForm: SupplierDCProductGroupForm;
  productGroupDcFormIsDirty: boolean;
  productGroupDcFormIsValid: boolean;
  productGroupDcFormIsPickupAddressDirty: boolean;
  showNotesModal: boolean;
  showSubmitModal: boolean;
  historyTab: SupplierHistoryTab;
  filterEvents: Payload<Array<SupplierHistoryFilterItem>>;
  filterFields: Payload<Array<SupplierHistoryFilterItem>>;
  filterEditedBy: Payload<Array<SupplierHistoryFilterItem>>;
  isPurchasingOptionFormDirty: boolean;
  isPurchasingOptionFormValid: boolean;
  editNewAddDCProductGroup: boolean;
  newDCProductGroup: any;
  newProductGroupForm: SupplierProductGroupForm;
  newProductGroupFormIsDirty: boolean;
  newProductGroupFormIsValid: boolean;
  newProductGroupFormIsPickupAddressDirty: boolean;
  newProductGroupFormIsRemittanceAddressDirty: boolean;
  showSupplierStatusChangeModal: boolean;
  updateSupplierStatusForm: UpdateSupplierStatusForm;
  supplierValidatedTabs: ChecklistNavigationTab[];
  brandsSearch: string;
  validationErrors: {
    control: string,
    error: string,
    value: any
  }[];
  isSavingContact: boolean;
  newlyAddedContact: Contact;
  saveContactError: string;
  showNewContractModal: boolean;
  docuSignSigner: Signer;
  isSendingDocuSign: boolean;
  sendDocuSignError: string;
  requestedRetailer: any;
  showDeleteSupplierModal: boolean;
  deleteSupplierError: string;

  // appointment scheduling
  appointmentSchedulingLoading: boolean;
  appointmentSchedulingSuccess: boolean;
  appointmentSchedulingFailed: boolean;
  addNotesFormValues: AddNoteForm;

  dcPaymentHoldFormIsDirty: boolean;
}

export const initializeState = (): SupplierDetailState => {
  return {
    generalInfoLoading: false,
    esn: null,
    detail: null,
    broker: null,
    termsDropdownData: [],
    brokerDetailLoading: false,
    brokerDetail: null,
    onboardingStatus: [],
    onboardingStatusChangeAction: null,
    loadingOnboardingStatusChange: false,
    onboardingStatusChangeError: null,
    currentTab: SupplierDetailTabs.general,
    editMode: false,
    saveInProgress: false,
    submitInProgress: false,
    showUnsavedChangesModal: false,
    generalForm: new SupplierGeneralFormModel(),
    generalFormIsDirty: false,
    generalFormIsHeadquarterAddressDirty: false,
    supplierForm: null,
    supplierFormIsDirty: false,
    supplierFormIsValid: false,
    displayTabPanel: true,
    alertMessage: new AlertMessage(),
    leadTimeAlert: new AlertMessage(),
    refinedDcSupplierList: [],
    dcSupplierSearch: '',
    displayDcSupplierFilterPanel: false,
    dcSupplierFilter: new DcSupplierFilter(),
    dcSupplierFilterChips: [],
    selectedProductGroupView: ProductGroupViews.ProductGroupView,
    productGroupsFilterPanelDisplay: false,
    onProductGroupsDetailsView: false,
    productGroupForm: new SupplierProductGroupForm(),
    productGroupFormIsDirty: false,
    productGroupRemittanceAddressDirty: false,
    productGroupFormIsValid: false,
    editDCProductGroup: null,
    validatingHeadquarterAddress: false,
    validatingRemittanceAddress: false,
    validatingPickupAddress: false,
    generalFormIsValid: false,
    showAddBrokerModal: false,
    showRemoveBrokerModal: false,
    brokerForm: null,
    showAddProductGroupModal: false,
    termsFormIsValid: false,
    termsFormIsDirty: false,
    termsForm: new TermsFormObject(),
    paymentTermsList: [],
    marketingAllowanceList: [],
    deliveryMethodList: [],
    buyerList: [],
    selectedDcSupplierDc: '',
    selectedDcSupplierNumber: '',
    dcSupplierForm: new DcSupplierForm(),
    dcSupplierFormIsDirty: false,
    dcSupplierFormIsValid: false,
    dcSupplierFormIsPickupAddressDirty: false,
    productGroupDcForm: new SupplierDCProductGroupForm(),
    productGroupDcFormIsDirty: false,
    productGroupDcFormIsValid: false,
    productGroupDcFormIsPickupAddressDirty: false,
    showNotesModal: false,
    showSubmitModal: false,
    historyTab: {
      availableCount: 0,
      list: [],
      isLoading: false,
      displayFilterPanel: false,
      filter: {
        pageIndex: 0,
        pageCount: 25,
        includeAvailableCount: true,
        orderDirection: 0,
        search: ''
      },
      modalConfig: {
        show: false,
        changes: []
      },
      chips: []
    },
    filterEditedBy: returnEmptyPayLoad(),
    filterEvents: returnEventsPayLoad(),
    filterFields: returnEmptyPayLoad(),
    isPurchasingOptionFormDirty: false,
    isPurchasingOptionFormValid: false,
    editNewAddDCProductGroup: false,
    newDCProductGroup: null,
    newProductGroupForm: null,
    newProductGroupFormIsDirty: false,
    newProductGroupFormIsValid: false,
    newProductGroupFormIsPickupAddressDirty: false,
    newProductGroupFormIsRemittanceAddressDirty: false,
    showSupplierStatusChangeModal: false,
    updateSupplierStatusForm: {
      status: '',
      auditNotes: '',
      isActive: true,
      esn: ''
    },
    supplierValidatedTabs: [],
    brandsSearch: '',
    validationErrors: [],
    isSavingContact: false,
    newlyAddedContact: null,
    saveContactError: null,
    showNewContractModal: false,
    docuSignSigner: null,
    isSendingDocuSign: false,
    sendDocuSignError: null,
    requestedRetailer: null,
    showDeleteSupplierModal: false,
    deleteSupplierError: null,

    // appointment scheduling
    appointmentSchedulingLoading: false,
    appointmentSchedulingFailed: false,
    appointmentSchedulingSuccess: false,
    addNotesFormValues: null,

    dcPaymentHoldFormIsDirty: false
  };
};

export const initialState = initializeState();

function getErrorAlertMessage(errorCode: number): string {
  return errorCode > 0 ? 'There was a problem saving the record.' : 'There was a network error, please try again.';
}

function getProductGroupCreatedAlertMessage(productGroups: ProductGroup[]) {
  if (productGroups.length > 0) {
    const productGroup = productGroups[productGroups.length - 1];
    if (productGroup.dcProductGroups && productGroup.dcProductGroups.length > 0) {
      const hasAnyDefaultTransitTime = productGroup.dcProductGroups.find(item => isDCProductGroupTransitTimeDefault(item));
      if (hasAnyDefaultTransitTime) {
        return {
          type: AlertTypes.Warning,
          message: 'Your groups have been created successfully. There were errors with the KeHE Transit Time, please review below.'
        };
      }
    }
  }
  return {
    type: AlertTypes.Success,
    message: 'Your groups have been created successfully.'
  };
}

const rootReducer = createReducer(
  initialState,
  on(SupplierDcPaymentHoldActions.userChangedDcPaymentHoldForm, (state) => ({
    ...state,
    dcPaymentHoldFormIsDirty: true,
  })),
  on(SupplierDcPaymentHoldActions.userResetDcPaymentHoldChanges, (state) => ({
    ...state,
    dcPaymentHoldFormIsDirty: false,
  })),
  on(SupplierDcPaymentHoldActions.userClickedSaveDcPaymentHoldChanges, (state) => ({
    ...state,
    saveInProgress: true
  })),
  on(SupplierDcPaymentHoldActions.saveDcPaymentHoldsSuccess, (state, { supplierDetail }) => ({
    ...state,
    saveInProgress: false,
    editMode: false,
    dcPaymentHoldFormIsDirty: false,
    alertMessage: {
      type: AlertTypes.Success,
      message: 'Your changes have been saved successfully.'
    },
    detail: prepareSupplierDetail(supplierDetail),
  })),
  on(SupplierDcPaymentHoldActions.saveDcPaymentHoldsError, (state) => ({
    ...state,
    saveInProgress: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'Something went wrong. Please try again.'
    },
  })),
  on(SupplierDetailActions.clickEditAppointmentButton, (state) => ({
    ...state,
    appointmentSchedulingLoading: false,
    appointmentSchedulingSuccess: false,
    appointmentSchedulingFailed: false,
  })),
  on(SupplierDetailActions.clickAppointmentModalSaveButton, (state) => ({
    ...state,
    appointmentSchedulingLoading: true,
    appointmentSchedulingSuccess: false,
    appointmentSchedulingFailed: false,
  })),
  on(SupplierDetailActions.appointmentSchedulingSuccess, (state, action) => ({
    ...state,
    appointmentSchedulingLoading: false,
    appointmentSchedulingSuccess: true,
    appointmentSchedulingFailed: false,
    detail: prepareSupplierDetail({ ...state.detail, ...action.supplier }),
  })),
  on(SupplierDetailActions.appointmentSchedulingFailed, (state) => ({
    ...state,
    appointmentSchedulingLoading: false,
    appointmentSchedulingSuccess: false,
    appointmentSchedulingFailed: true,
  })),
  on(SupplierDetailActions.resetNewSupplierFlag, (state) => ({
    ...state,
    isNewSupplier: false,
  })),
  on(SupplierDetailActions.loadSupplierDetail, (state, action) => ({
    ...state,
    esn: action.esn,
    isNewSupplier: false,
    generalInfoLoading: true,
    currentTab: SupplierDetailTabs.general,
    detail: null,
    editMode: false,
    onboardingStatus: [],
  })),
  on(SupplierDetailActions.loadSupplierDetailError, (state) => ({
    ...state,
    generalInfoLoading: false,
  })),
  on(SupplierDetailActions.loadSupplierDetailSuccess, (state, action) => {
    return {
      ...state,
      generalInfoLoading: false,
      detail: prepareSupplierDetail(action.supplier),
      selectedProductGroupView: initialState.selectedProductGroupView,
      productGroupsFilter: new ProductGroupsFilter(),
      brandsSearch: '',
      refinedDcSupplierList: action.supplier.dcSuppliers,
      dcSupplierFilter: new DcSupplierFilter(),
      dcSupplierFilterChips: [],
      dcSupplierSearch: '',
      filter: {
        ...state.historyTab.filter,
        selectedDC: null,
        selectedEditedBy: null,
        selectedEvent: null,
        selectedDocument: null,
        selectedField: null,
        createdFrom: null,
        createdTo: null,
        search: null
      }
    };
  }),
  on(SupplierDetailActions.saveRetailer, (state, action) => ({
    ...state,
    requestedRetailer: action.retailer,
  })),
  on(SupplierDetailActions.loadSupplierBroker, (state) => ({
    ...state,
    brokerDetailLoading: true,
    brokerDetail: null,
  })),
  on(SupplierDetailActions.loadSupplierBrokerSuccess, (state, action) => ({
    ...state,
    brokerDetailLoading: false,
    brokerDetail: action.broker,
  })),
  on(SupplierDetailActions.loadSupplierBrokerError, (state) => ({
    ...state,
    brokerDetailLoading: false,
  })),
  on(SupplierDetailActions.loadTermsDropdownSuccess, (state, action) => ({
    ...state,
    termsDropdownData: action.dropdownData || [],
  })),
  on(SupplierDetailActions.editSupplier, (state) => ({
    ...state,
    editMode: true,
    generalFormIsDirty: false,
    generalFormIsValid: initialState.generalFormIsValid,
    dcPaymentHoldFormIsDirty: false,
    supplierForm: initialState.supplierForm,
    supplierFormIsDirty: initialState.supplierFormIsDirty,
    supplierFormIsValid: initialState.supplierFormIsValid,
    alertMessage: initialState.alertMessage,
    showSupplierStatusChangeModal: false,
    isSavingContact: initialState.isSavingContact,
    newlyAddedContact: initialState.newlyAddedContact,
    saveContactError: initialState.saveContactError,
  })),
  on(SupplierDetailActions.editSupplierTerms, (state) => ({
    ...state,
    editMode: true,
    termsFormIsDirty: false,
    termsFormIsValid: initialState.termsFormIsValid,
    alertMessage: initialState.alertMessage,
    showSupplierStatusChangeModal: false
  })),
  on(
    SupplierDetailActions.editDcSupplier,
    SupplierDetailActions.editProductGroup,
    (state, action) => ({
      ...state,
      editMode: true,
      selectedDcSupplierDc: action.selectedDcSupplierDc,
      selectedDcSupplierNumber: action.selectedDcSupplierNumber,
      alertMessage: initialState.alertMessage,
    })
  ),
  on(SupplierDetailActions.addDCProductGroup, (state) => ({
    ...state,
    selectedProductGroupView: ProductGroupViews.AddDCProductGroupView,
    editMode: true,
    newlyAddedContact: initialState.newlyAddedContact,
  })),
  // We will need to check for unsaved changes before doing this
  on(SupplierDetailActions.confirmCancelEdit, (state) => ({
    ...state,
    leadTimeAlert: initialState.leadTimeAlert,
    dcSupplierFormIsDirty: false,
    editMode: initialState.editMode,
    generalForm: initialState.generalForm,
    generalFormIsDirty: initialState.generalFormIsDirty,
    generalFormIsValid: initialState.generalFormIsValid,
    generalFormIsHeadquarterAddressDirty: initialState.generalFormIsHeadquarterAddressDirty,
    dcPaymentHoldFormIsDirty: initialState.dcPaymentHoldFormIsDirty,
    supplierFormIsDirty: initialState.supplierFormIsDirty,
    supplierFormIsValid: initialState.supplierFormIsValid,
    supplierForm: initialState.supplierForm,
    isPurchasingOptionFormDirty: initialState.isPurchasingOptionFormDirty,
    isPurchasingOptionFormValid: initialState.isPurchasingOptionFormValid,
    newProductGroupForm: initialState.newProductGroupForm,
    newProductGroupFormIsDirty: initialState.newProductGroupFormIsDirty,
    newProductGroupFormIsValid: initialState.newProductGroupFormIsValid,
    productGroupDcForm: initialState.productGroupDcForm,
    productGroupDcFormIsDirty: initialState.productGroupDcFormIsDirty,
    productGroupDcFormIsValid: initialState.productGroupDcFormIsValid,
    productGroupDcFormIsPickupAddressDirty: initialState.productGroupDcFormIsPickupAddressDirty,
    productGroupForm: initialState.productGroupForm,
    productGroupFormIsDirty: initialState.productGroupFormIsDirty,
    productGroupFormIsValid: initialState.productGroupFormIsValid,
    productGroupRemittanceAddressDirty: initialState.productGroupRemittanceAddressDirty,
    selectedProductGroupView: initialState.selectedProductGroupView,
    showUnsavedChangesModal: initialState.showUnsavedChangesModal,
    termsForm: initialState.termsForm,
    termsFormIsDirty: initialState.termsFormIsDirty,
    termsFormIsValid: initialState.termsFormIsValid,
  })),
  on(SupplierDetailActions.generalFormChanged, (state, action) => ({
    ...state,
    generalForm: { ...action.form },
    generalFormIsDirty: action.isDirty,
    generalFormIsHeadquarterAddressDirty: action.isHeadquarterAddressDirty,
    generalFormIsValid: action.isGeneralFormValid,
  })),
  on(SupplierDetailActions.showUnsavedChangesModal, (state) => ({
    ...state,
    showUnsavedChangesModal: true
  })),
  on(SupplierDetailActions.hideUnsavedChangesModal, (state) => ({
    ...state,
    showUnsavedChangesModal: false
  })),
  on(SupplierDetailActions.saveGeneralForm, (state) => ({
    ...state,
    saveInProgress: true,
    showUnsavedChangesModal: false
  })),
  on(SupplierDetailActions.saveUpdateFormSuccess, (state, action: any) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: initialState.showNotesModal,
    alertMessage: {
      type: AlertTypes.Success,
      message: action.status === 'Active' ? 'You have successfully reactivated this supplier.' : 'You have successfully discontinued this supplier.',
    },
    detail: {
      ...state.detail,
      status: action.status,
      statusTag: prepareStatusTag(action.status),
    },
  })),
  on(SupplierDetailActions.saveUpdateFormError, (state) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'There was an error discontinuing this supplier. Please try again.',
    },
    validatingHeadquarterAddress: initialState.validatingHeadquarterAddress,
  })),
  on(SupplierDetailActions.saveGeneralFormSuccess, (state, action) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: initialState.showNotesModal,
    generalFormIsDirty: initialState.generalFormIsDirty,
    generalFormIsHeadquarterAddressDirty: initialState.generalFormIsHeadquarterAddressDirty,
    editMode: false,
    alertMessage: {
      type: AlertTypes.Success,
      message: 'Your changes have been saved successfully.'
    },
    detail: prepareSupplierDetail(action.supplier),
    validatingHeadquarterAddress: initialState.validatingHeadquarterAddress,
  })),
  on(SupplierDetailActions.saveGeneralFormError, (state) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'There was a problem saving the record.',
    },
    validatingHeadquarterAddress: initialState.validatingHeadquarterAddress,
  })),
  on(SupplierDetailActions.termsFormChanged, (state, action) => ({
    ...state,
    termsForm: convertDecimals(action.form),
    termsFormIsDirty: action.isDirty,
    termsFormIsValid: action.isTermsFormValid,
  })),
  on(SupplierDetailActions.showTermsNewContractForm, (state) => ({
    ...state,
    showNewContractModal: true,
  })),
  on(SupplierDetailActions.cancelTermsNewContractForm, (state) => ({
    ...state,
    showNewContractModal: false,
  })),
  on(
    SupplierDetailActions.saveProductGroupForm,
    SupplierDetailActions.saveDCProductGroupForm,
    SupplierDetailActions.saveNewProductGroupForm,
    SupplierDetailActions.saveTermsForm,
    (state) => ({
      ...state,
      saveInProgress: true,
      showUnsavedChangesModal: false,
    })
  ),
  on(SupplierDetailActions.saveTermsFormSuccess, (state, action) => {
    return {
      ...state,
      saveInProgress: false,
      showNotesModal: initialState.showNotesModal,
      termsFormIsDirty: initialState.termsFormIsDirty,
      showNewContractModal: false,
      editMode: false,
      alertMessage: {
        type: AlertTypes.Success,
        message: action.isContractAgreementUploaded ?
          'Your changes have been saved successfully and the updated supplier contract agreement has been uploaded.' :
          'Your changes have been saved successfully.'
      },
      detail: prepareSupplierDetail(action.supplier),
    };
  }),
  on(SupplierDetailActions.saveTermsFormError, (state) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: false,
    showNewContractModal: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'There was a problem saving the record.',
    },
  })),
  on(SupplierDetailActions.toggleNavigationPanel, (state) => ({
    ...state,
    displayTabPanel: !state.displayTabPanel
  })),
  on(SupplierDetailActions.setCurrentTab, (state, action) => ({
    ...state,
    currentTab: action.tab,
    alertMessage: initialState.alertMessage,
  })),
  on(SupplierDetailActions.hideAlertMessage, (state) => ({
    ...state,
    alertMessage: initialState.alertMessage,
  })),
  on(SupplierDetailActions.getPurchasingOptionsAlertMessage, (state, action) => ({
    ...state,
    alertMessage: action.alert,
  })),
  on(SupplierDetailActions.refineDcSupplierList, (state) => ({
    ...state,
    refinedDcSupplierList: refineDcSupplierList(state.dcSupplierSearch, state.dcSupplierFilter, state.detail.dcSuppliers),
    dcSupplierFilterChips: createDcSupplierFilterChipsList(state.dcSupplierFilter)
  })),
  on(SupplierDetailActions.dcSupplierSearchChanged, (state, action) => ({
    ...state,
    dcSupplierSearch: action.search,
  })),
  on(SupplierDetailActions.dcSupplierSearchCleared, (state) => ({
    ...state,
    dcSupplierSearch: initialState.dcSupplierSearch,
    refinedDcSupplierList: state.detail.dcSuppliers,
  })),
  on(SupplierDetailActions.openDcSupplierFilter, (state) => ({
    ...state,
    displayDcSupplierFilterPanel: true
  })),
  on(SupplierDetailActions.closeDcSupplierFilter, (state) => ({
    ...state,
    displayDcSupplierFilterPanel: false
  })),
  on(SupplierDetailActions.dcSupplierFilterChanged, (state, action) => ({
    ...state,
    dcSupplierFilter: action.dcSupplierFilter
  })),
  on(SupplierDetailActions.dcSupplierChipClicked, (state, action) => ({
    ...state,
    dcSupplierFilterChips: refineChipsAfterClick(action.chip, state.dcSupplierFilter),
    dcSupplierFilter: refineChipsAfterClick(action.chip, state.dcSupplierFilter),
  })),
  on(SupplierDetailActions.dcSupplierChipClearAll, (state) => ({
    ...state,
    dcSupplierFilterChips: [],
    dcSupplierFilter: new DcSupplierFilter(),
  })),
  on(SupplierDetailActions.openProductGroupsFilter, (state) => ({
    ...state,
    productGroupsFilterPanelDisplay: true
  })),
  on(SupplierDetailActions.closeProductGroupsFilter, (state) => ({
    ...state,
    productGroupsFilterPanelDisplay: false
  })),
  on(SupplierDetailActions.onProductGroupsDetailsView, (state, action) => ({
    ...state,
    onProductGroupsDetailsView: action.show,
  })),
  on(ProductGroupActions.cloneDCProductGroup, (state) => ({
    ...state,
    editMode: true,
    alertMessage: initialState.alertMessage,
    selectedProductGroupView: ProductGroupViews.CloneDCProductGroupView,
  })),
  on(SupplierDetailActions.editProductGroupDC, (state, action) => ({
    ...state,
    editMode: true,
    selectedProductGroupView: ProductGroupViews.DCProductGroupView,
    editDCProductGroup: action.dcProductGroup,
    alertMessage: initialState.alertMessage,
  })),
  on(SupplierDetailActions.editAddNewProductGroupDC, (state, action) => ({
    ...state,
    editMode: true,
    editNewAddDCProductGroup: true,
    newDCProductGroup: action.newDCProductGroup,
    showAddProductGroupModal: false
  })),
  on(SupplierDetailActions.clearAddNewProductGroupDC, (state) => ({
    ...state,
    editMode: false,
    editNewAddDCProductGroup: false,
    newDCProductGroup: null,
    productGroupDcFormIsDirty: false,
    newProductGroupFormIsDirty: false,
    newProductGroupFormIsPickupAddressDirty: false,
    newProductGroupFormIsRemittanceAddressDirty: false,
    showAddProductGroupModal: false
  })),
  on(SupplierDetailActions.validateHeadquarterAddress, (state) => ({
    ...state,
    validatingHeadquarterAddress: true,
  })),
  on(SupplierDetailActions.validateRemittanceAddress, (state) => ({
    ...state,
    validatingRemittanceAddress: true,
  })),
  on(SupplierDetailActions.generalFormNoAddressSelected, (state) => ({
    ...state,
    validatingHeadquarterAddress: initialState.validatingHeadquarterAddress,
  })),
  on(SupplierDetailActions.showAddProductGroupModal, (state) => ({
    ...state,
    showAddProductGroupModal: true,
    newlyAddedContact: initialState.newlyAddedContact,
  })),
  on(SupplierDetailActions.hideAddProductGroupModal, (state) => ({
    ...state,
    editMode: false,
    editNewAddDCProductGroup: false,
    newDCProductGroup: null,
    showAddProductGroupModal: false
  })),
  on(SupplierDetailActions.clearValidateRemittanceAddress, (state) => ({
    ...state,
    validatingRemittanceAddress: initialState.validatingRemittanceAddress,
  })),
  on(SupplierDetailActions.showAddBrokerModal, (state) => ({
    ...state,
    showAddBrokerModal: true
  })),
  on(SupplierDetailActions.hideAddBrokerModal, (state) => ({
    ...state,
    showAddBrokerModal: false
  })),
  on(SupplierDetailActions.showRemoveBrokerModal, (state) => ({
    ...state,
    showRemoveBrokerModal: true
  })),
  on(SupplierDetailActions.hideRemoveBrokerModal, (state) => ({
    ...state,
    showRemoveBrokerModal: false
  })),
  on(SupplierDetailActions.brokerPatchNotesModal, (state, action) => ({
    ...state,
    showRemoveBrokerModal: false,
    showNotesModal: true,
    saveInProgress: false,
    brokerForm: action.brokerForm
  })),
  on(SupplierDetailActions.brokerPatch, (state, action) => ({
    ...state,
    saveInProgress: true,
    brokerForm: {
      ...state.brokerForm,
      auditNotes: action.notes,
    },
  })),
  on(SupplierDetailActions.brokerPatchSuccess, (state, action) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: false,
    brokerForm: initialState.brokerForm,
    editMode: false,
    alertMessage: {
      type: AlertTypes.Success,
      message: 'Your changes have been saved successfully.'
    },
    detail: {
      ...state.detail,
      broker: {
        ...state.detail.broker,
        esn: action.brokerForm.broker.esn,
        isBrokerOfRecord: action.brokerForm.broker.isBrokerOfRecord,
        created: new Date().toLocaleString(),
      }
    },
    showAddBrokerModal: false,
    showRemoveBrokerModal: false
  })),
  on(SupplierDetailActions.brokerPatchError, (state) => ({
    ...state,
    saveInProgress: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'There was a problem saving the record.',
    },
  })),
  on(SupplierDetailActions.getPaymentTermsListSuccess, (state, action) => ({
    ...state,
    paymentTermsList: action.paymentTerms,
  })),
  on(SupplierDetailActions.getMarketingAllowanceListSuccess, (state, action) => ({
    ...state,
    marketingAllowanceList: action.marketingAllowance,
  })),
  on(SupplierDetailActions.getDeliveryMethodListSuccess, (state, action) => ({
    ...state,
    deliveryMethodList: action.deliveryMethods,
  })),
  on(SupplierDetailActions.loadSupplierHistoryList, (state) => ({
    ...state,
    historyTab: { ...state.historyTab, availableCount: 0, isLoading: true, list: [] },
  })),
  on(SupplierDetailActions.loadSupplierHistoryListSuccess, (state, action) => ({
    ...state,
    historyTab: { ...state.historyTab, availableCount: action.data.availableCount, isLoading: false, list: action.data.data },
  })),
  on(SupplierDetailActions.loadSupplierHistoryListError, (state) => ({
    ...state,
    historyTab: { ...state.historyTab, availableCount: 0, isLoading: false, list: [] },
  })),
  on(SupplierDetailActions.supplierHistorySearchChange, (state, action) => ({
    ...state,
    historyTab: { ...state.historyTab, filter: { ...state.historyTab.filter, search: action.search } },
  })),
  on(SupplierDetailActions.supplierHistoryFilterOpenPanel, (state) => ({
    ...state,
    historyTab: { ...state.historyTab, displayFilterPanel: true },
  })),
  on(SupplierDetailActions.supplierHistoryFilterClosePanel, (state) => ({
    ...state,
    historyTab: { ...state.historyTab, displayFilterPanel: false },
  })),
  on(SupplierDetailActions.supplierHistoryApplyFilters, (state, action) => ({
    ...state,
    historyTab: {
      ...state.historyTab,
      filter: { ...state.historyTab.filter, ...action.filters },
      displayFilterPanel: false,
      chips: getChipsForFilter(action.filters)
    },
  })),
  on(SupplierDetailActions.supplierHistoryClearFilters, (state) => ({
    ...state,
    historyTab: {
      ...state.historyTab, filter: {
        ...state.historyTab.filter,
        search: '',
        selectedField: null,
        selectedEvent: null,
        selectedEditedBy: null,
        selectedDocument: null,
        selectedDC: null,
        createdFrom: null,
        createdTo: null,
        dateRangeString: null,
      }, displayFilterPanel: false, chips: []
    },
  })),
  on(SupplierDetailActions.supplierHistoryChipClicked, (state, params) => (onRemoveChip(state, params.chip))),
  on(SupplierDetailActions.supplierHistoryClearAllChipsClicked, (state) => (onClearAllChips(state))),
  on(SupplierDetailActions.supplierHistoryPageSizeChanged, (state, action) => ({
    ...state,
    historyTab: { ...state.historyTab, filter: { ...state.historyTab.filter, pageCount: action.size } },
  })),
  on(SupplierDetailActions.supplierHistoryPageIndexChanged, (state, action) => ({
    ...state,
    historyTab: { ...state.historyTab, filter: { ...state.historyTab.filter, pageIndex: action.index } },
  })),
  on(SupplierDetailActions.supplierHistoryDisplayDetailModal, (state, action) => ({
    ...state,
    historyTab: { ...state.historyTab, modalConfig: { ...action.row, show: true } },
  })),
  on(SupplierDetailActions.supplierHistoryCloseDetailModal, (state) => ({
    ...state,
    historyTab: { ...state.historyTab, modalConfig: { ...state.historyTab.modalConfig, show: false } },
  })),
  on(SupplierDetailActions.supplierHistoryLoadFiltersDataSuccess, (state, action) => ({
    ...state,
    historyTab: {
      ...state.historyTab, filter: { ...state.historyTab.filter },
    },
    filterFields: action.data[0],
    filterEditedBy: action.data[1],
  })),
  on(SupplierDetailActions.loadBuyerListSuccess, (state, action) => ({
    ...state,
    buyerList: prepBuyers(action.list),
  })),
  on(SupplierDetailActions.loadBuyerListError, (state) => ({
    ...state,
    buyerList: [],
  })),
  on(SupplierDetailActions.dcSupplierFormChanged, (state, action) => ({
    ...state,
    dcSupplierForm: { ...action.form },
    dcSupplierFormIsDirty: action.isDirty,
    dcSupplierFormIsValid: action.isDcSupplierFormValid,
    dcSupplierFormIsPickupAddressDirty: action.isPickupAddressDirty,
  })),
  on(SupplierDetailActions.dcProductGroupFormChanged, (state, action) => ({
    ...state,
    productGroupDcForm: { ...action.form },
    productGroupDcFormIsDirty: action.isDirty,
    productGroupDcFormIsValid: action.isDcProductGroupFormValid,
    productGroupDcFormIsPickupAddressDirty: action.isPickupAddressDirty,
  })),
  on(SupplierDetailActions.productGroupFormChanged, (state, action) => ({
    ...state,
    productGroupForm: action.form,
    productGroupFormIsDirty: action.isDirty,
    productGroupRemittanceAddressDirty: action.productGroupRemittanceAddressDirty,
    productGroupFormIsValid: action.isProductGroupFormValid,
  })),
  on(SupplierDetailActions.newProductGroupFormChanged, (state, action) => ({
    ...state,
    newProductGroupForm: { ...action.form },
    newProductGroupFormIsDirty: action.isDirty,
    newProductGroupFormIsValid: action.isProductGroupFormValid,
    newProductGroupFormIsPickupAddressDirty: action.isPickupAddressDirty,
    newProductGroupFormIsRemittanceAddressDirty: action.isRemittanceAddressDirty,
  })),
  on(SupplierDetailActions.showKeHETransitTimesWarning, (state) => ({
    ...state,
    leadTimeAlert: {
      type: AlertTypes.Warning,
      message: 'Lead Times have been updated automatically, please save to confirm these updates or make changes to lead times below.'
    }
  })),
  on(SupplierDetailActions.clearKeHETransitTimesWarning, (state) => ({
    ...state,
    leadTimeAlert: initialState.leadTimeAlert
  })),
  on(SupplierDetailActions.saveProductGroupFormSuccess, (state) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: initialState.showNotesModal,
    productGroupFormIsDirty: initialState.productGroupFormIsDirty,
    productGroupFormIsValid: initialState.productGroupFormIsValid,
    productGroupRemittanceAddressDirty: initialState.productGroupRemittanceAddressDirty,
    editMode: false,
    selectedProductGroupView: initialState.selectedProductGroupView,
    alertMessage: {
      type: AlertTypes.Success,
      message: 'Your changes have been saved successfully.'
    },
    validatingRemittanceAddress: initialState.validatingRemittanceAddress,
  })),
  on(SupplierDetailActions.saveProductGroupFormError, (state, action) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: getErrorAlertMessage(action.errorCode),
    },
    validatingRemittanceAddress: initialState.validatingRemittanceAddress,
  })),
  on(SupplierDetailActions.saveDCProductGroupFormSuccess, (state, action) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: initialState.showNotesModal,
    editMode: false,
    selectedProductGroupView: initialState.selectedProductGroupView,
    alertMessage: {
      type: AlertTypes.Success,
      message: getProductSaveSuccessMessage(action.productGroupView),
    },
    leadTimeAlert: initialState.leadTimeAlert,
    productGroupDcForm: initialState.productGroupDcForm,
    productGroupDcFormIsDirty: initialState.productGroupDcFormIsDirty,
    productGroupDcFormIsValid: initialState.productGroupDcFormIsValid,
    productGroupDcFormIsPickupAddressDirty: initialState.productGroupDcFormIsPickupAddressDirty,
    validatingPickupAddress: initialState.validatingPickupAddress,
  })),
  on(SupplierDetailActions.saveDCProductGroupFormError, (state, action) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: getErrorAlertMessage(action.errorCode),
    },
  })),
  on(SupplierDetailActions.saveNewProductGroupFormSuccess, (state, action) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: initialState.showNotesModal,
    editMode: false,
    editModeDCProductGroup: false,
    editNewAddDCProductGroup: false,
    newDCProductGroup: null,
    showAddProductGroupModal: false,
    alertMessage: getProductGroupCreatedAlertMessage(action.productGroups),
    newProductGroupForm: initialState.newProductGroupForm,
    newProductGroupFormIsDirty: initialState.newProductGroupFormIsDirty,
    newProductGroupFormIsValid: initialState.newProductGroupFormIsValid,
    newProductGroupFormIsPickupAddressDirty: initialState.newProductGroupFormIsPickupAddressDirty,
    newProductGroupFormIsRemittanceAddressDirty: initialState.newProductGroupFormIsRemittanceAddressDirty,
  })),
  on(SupplierDetailActions.saveNewProductGroupFormError, (state, action) => ({
    ...state,
    saveInProgress: false,
    showNotesModal: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: getErrorAlertMessage(action.errorCode),
    },
  })),
  on(SupplierDetailActions.validatePickupAddress, (state) => ({
    ...state,
    validatingPickupAddress: true,
  })),
  on(SupplierDetailActions.clearValidatingPickupAddress, (state) => ({
    ...state,
    validatingPickupAddress: initialState.validatingPickupAddress,
  })),
  on(SupplierDetailActions.dcProductGroupFormNoAddressSelected, (state) => ({
    ...state,
    validatingPickupAddress: initialState.validatingPickupAddress,
  })),
  on(SupplierDetailActions.showNotesModal, (state) => ({
    ...state,
    showNotesModal: true,
  })),
  on(SupplierDetailActions.hideNotesModal, (state) => ({
    ...state,
    showNotesModal: initialState.showNotesModal,
    brokerForm: initialState.brokerForm,
    validatingPickupAddress: initialState.validatingPickupAddress,
    validatingRemittanceAddress: initialState.validatingRemittanceAddress,
    validatingHeadquarterAddress: initialState.validatingHeadquarterAddress,
  })),
  on(SupplierDetailActions.addNoteGeneralForm, (state, action) => ({
    ...state,
    generalForm: {
      ...state.generalForm,
      auditNotes: action.notes,
    },
  })),
  on(SupplierDetailActions.addNoteTermsForm, (state, action) => ({
    ...state,
    termsForm: {
      ...state.termsForm,
      auditNotes: action.notes,
      terms: {
        ...state.termsForm.terms,
        changeType: action.changeType
      }
    },
  })),
  on(SupplierDetailActions.updateContractDateTermsForm, (state, action) => ({
    ...state,
    termsForm: {
      esn: state.termsForm.esn,
      updatedBySource: state.termsForm.updatedBySource,
      auditNotes: state.termsForm.auditNotes,
      terms: {
        ...state.termsForm.terms,
        contractDate: action.contractDate
      }
    },
  })),
  on(SupplierDetailActions.addNoteDcDetailForm, (state, action) => ({
    ...state,
    dcSupplierForm: {
      ...state.dcSupplierForm,
      auditNotes: action.notes,
    },
  })),
  on(SupplierDetailActions.addNoteProductGroupForm, (state, action) => ({
    ...state,
    productGroupForm: {
      ...state.productGroupForm,
      auditNotes: action.notes,
    },
  })),
  on(SupplierDetailActions.addNoteDCProductGroupForm, (state, action) => ({
    ...state,
    productGroupDcForm: {
      ...state.productGroupDcForm,
      auditNotes: action.notes,
    },
  })),
  on(SupplierDetailActions.hideSupplierStatusChangeModal, (state) => ({
    ...state,
    showSupplierStatusChangeModal: initialState.showSupplierStatusChangeModal,
  })),
  on(SupplierDetailActions.showSupplierStatusChangeModal, (state) => ({
    ...state,
    showSupplierStatusChangeModal: true,
  })),
  on(SupplierDetailActions.supplierStatusChangeUpdate, (state, action) => ({
    ...state,
    updateSupplierStatusForm: {
      status: action.status,
      auditNotes: action.auditNotes,
      isActive: action.isActive,
      esn: action.esn
    },
  })),
  on(SupplierDetailActions.supplierValidated, (state, action) => ({
    ...state,
    supplierValidatedTabs: action.tabs,
  })),
  on(SupplierDetailActions.submitSupplier, (state) => ({
    ...state,
    saveInProgress: true,
    submitInProgress: true,
  })),
  on(SupplierDetailActions.submitSupplierSuccess, (state) => ({
    ...state,
    saveInProgress: initialState.saveInProgress,
    submitInProgress: initialState.submitInProgress,
    alertMessage: {
      type: AlertTypes.Success,
      message: 'You have successfully activated the supplier.',
    },
    detail: {
      ...state.detail,
      status: SupplierStatus.active,
      statusTag: prepareStatusTag(SupplierStatus.active),
      onboardDate: getFormattedDate(new Date()),
    },
    showSubmitModal: initialState.showSubmitModal,
  })),
  on(SupplierDetailActions.submitSupplierError, (state, action) => ({
    ...state,
    saveInProgress: initialState.saveInProgress,
    submitInProgress: initialState.submitInProgress,
    alertMessage: {
      type: AlertTypes.Error,
      message: getErrorAlertMessage(action.errorCode),
    },
  })),
  on(SupplierDetailActions.brandsSearchChanged, (state, action) => ({
    ...state,
    brandsSearch: action.search,
  })),
  on(SupplierDetailActions.brandsSearchCleared, (state) => ({
    ...state,
    brandsSearch: initialState.brandsSearch,
  })),
  on(SupplierDetailActions.setValidationErrors, (state, action) => ({
    ...state,
    validationErrors: action.errors,
  })),
  on(SupplierDetailActions.showSubmitModal, (state) => ({
    ...state,
    showSubmitModal: true,
  })),
  on(SupplierDetailActions.hideSubmitModal, (state) => ({
    ...state,
    showSubmitModal: initialState.showSubmitModal,
  })),
  on(SupplierDetailActions.refreshSupplierReviewStatus, (state, action) => ({
    ...state,
    detail: {
      ...state.detail,
      invitedSupplierSubmittedForReviewDate: action.supplier.invitedSupplierSubmittedForReviewDate
    },
  })),

  // OnBoarding Status
  on(SupplierDetailActions.loadOnBoardingStatusSuccess, (state, action) => ({
    ...state,
    onboardingStatus: action.onboardingStatus
  })),
  on(SupplierDetailActions.showGeneralTabOnBoardingStatusModal, (state, action) => ({
    ...state,
    onboardingStatusChangeAction: action.onboardingStatusChangeAction,
    onboardingStatusChangeError: null,
  })),
  on(SupplierDetailActions.closeGeneralTabOnBoardingStatusModal, (state) => ({
    ...state,
    onboardingStatusChangeAction: null,
    onboardingStatusChangeError: null,
  })),
  on(SupplierDetailActions.updateGeneralTabStatus, (state) => ({
    ...state,
    loadingOnboardingStatusChange: true,
    onboardingStatusChangeError: null,
  })),
  on(SupplierDetailActions.updateGeneralTabStatusSuccess, (state, action) => ({
    ...state,
    onboardingStatus: updateOnBoardingStatus(state.onboardingStatus, action.status),
    onboardingStatusChangeAction: null,
    loadingOnboardingStatusChange: false,
    onboardingStatusChangeError: null,
  })),
  on(SupplierDetailActions.updateGeneralTabStatusError, (state) => ({
    ...state,
    loadingOnboardingStatusChange: false,
    onboardingStatusChangeError: 'There was a problem saving the record.',
  })),
  // OnBoarding Status

  // Supplier Contact
  on(SupplierDetailActions.addSupplierContact, (state) => ({
    ...state,
    isSavingContact: true,
    saveContactError: initialState.saveContactError,
    newlyAddedContact: initialState.newlyAddedContact,
  })),
  on(SupplierDetailActions.addSupplierContactSuccess, (state, action) => ({
    ...state,
    detail: updateContacts(state.detail, action.contacts),
    isSavingContact: initialState.isSavingContact,
    newlyAddedContact: action.contacts.find(item => item.uniqueId === action.created.uniqueId),
    saveContactError: initialState.saveContactError,
  })),
  on(SupplierDetailActions.addSupplierContactError, (state, action) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    newlyAddedContact: initialState.newlyAddedContact,
    saveContactError: getContactSaveErrorMessage(action.errorCode),
  })),
  on(SupplierDetailActions.clearSupplierContactForm, (state) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    newlyAddedContact: initialState.newlyAddedContact,
    saveContactError: initialState.saveContactError,
  })),
  // Supplier Contact

  // DocuSign
  on(SupplierDetailActions.confirmDocuSignSigner, (state, action) => ({
    ...state,
    docuSignSigner: action.signer,
    sendDocuSignError: null,
  })),
  on(SupplierDetailActions.cancelDocuSignSigner, (state) => ({
    ...state,
    docuSignSigner: null,
    sendDocuSignError: null,
  })),
  on(SupplierDetailActions.sendDocuSignEmail, (state) => ({
    ...state,
    isSendingDocuSign: true,
    sendDocuSignError: null,
  })),
  on(SupplierDetailActions.sendDocuSignEmailSuccess, (state, action) => ({
    ...state,
    detail: prepareSupplierDetail(action.supplier),
    isSendingDocuSign: false,
    docuSignSigner: null,
    sendDocuSignError: null,
  })),
  on(SupplierDetailActions.sendDocuSignEmailError, (state) => ({
    ...state,
    isSendingDocuSign: false,
    sendDocuSignError: 'Something went wrong. Please try again.',
  })),
  // DocuSign

  // Supplier Form (For Broker)
  on(SupplierDetailActions.loadSupplierBrokerContactSuccess, (state, action) => ({
    ...state,
    broker: action.broker,
  })),
  on(SupplierDetailActions.clearSupplierBrokerContact, (state) => ({
    ...state,
    broker: null,
  })),
  on(SupplierDetailActions.supplierFormChanged, (state, action) => ({
    ...state,
    supplierForm: { ...action.form },
    supplierFormIsDirty: action.isDirty,
    supplierFormIsValid: action.isSupplierFormValid,
  })),
  on(SupplierDetailActions.saveSupplierForm, (state) => ({
    ...state,
    showLeaveUnsavedChangesModal: false,
    saveInProgress: true,
  })),
  on(SupplierDetailActions.saveSupplierFormSuccess, (state, action) => ({
    ...state,
    editMode: initialState.editMode,
    supplierFormIsDirty: initialState.supplierFormIsDirty,
    supplierFormIsValid: initialState.supplierFormIsValid,
    supplierForm: initialState.supplierForm,
    saveInProgress: false,
    alertMessage: {
      type: AlertTypes.Success,
      message: 'Your changes have been saved successfully.'
    },
    detail: prepareSupplierDetail(action.supplier),
    addNotesFormValues: null
  })),
  on(SupplierDetailActions.saveSupplierFormError, (state) => ({
    ...state,
    saveInProgress: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'There was a problem saving the record.',
    },
  })),
  // Supplier Form (For Broker)

  // Update CanUploadSIF
  on(SupplierDetailActions.updateCanUploadSIFSuccess, (state, action) => ({
    ...state,
    detail: {
      ...state.detail,
      canUploadSIF: action.canUploadSIF
    },
  })),
  on(SupplierDetailActions.updateCanUploadSIFError, (state, action) => ({
    ...state,
    detail: {
      ...state.detail,
      canUploadSIF: !action.canUploadSIF
    },
    alertMessage: {
      type: AlertTypes.Error,
      message: 'Something went wrong. Please try again.',
    },
  })),
  // Update CanUploadSIF

  // Delete Supplier
  on(SupplierDetailActions.showDeleteSupplierModal, (state) => ({
    ...state,
    showDeleteSupplierModal: true,
    deleteSupplierError: null,
  })),
  on(SupplierDetailActions.hideDeleteSupplierModal, (state) => ({
    ...state,
    showDeleteSupplierModal: false,
    saveInProgress: false,
    deleteSupplierError: null,
  })),
  on(SupplierDetailActions.deleteSupplier, (state) => ({
    ...state,
    saveInProgress: true,
    deleteSupplierError: null,
  })),
  on(SupplierDetailActions.deleteSupplierSuccess, (state) => ({
    ...state,
    saveInProgress: false,
    showDeleteSupplierModal: false,
    deleteSupplierError: null,
  })),
  on(SupplierDetailActions.deleteSupplierError, (state) => ({
    ...state,
    saveInProgress: false,
    deleteSupplierError: 'There was a problem deleting the record.',
  })),
  // Delete Supplier
  on(SupplierDetailActions.saveAddNoteFormValues, (state, action) => ({
    ...state,
    addNotesFormValues: action.form,
  })),
);

function returnEventsPayLoad(): Payload<Array<SupplierHistoryFilterItem>> {
  return {
    availableCount: 4,
    data: [
      {
        code: 'Added',
        codeValue: 'Added',
        value: 'Added',
      },
      {
        code: 'Deleted',
        codeValue: 'Deleted',
        value: 'Deleted',
      },
      {
        code: 'Modified',
        codeValue: 'Modified',
        value: 'Modified',
      },
      {
        code: 'Status Changed',
        codeValue: 'Status Changed',
        value: 'Status Changed',
      },
    ]
  };
}

export function returnDocumentEventsPayload() {
  const payload = returnEventsPayLoad();
  payload.data = payload.data.concat([
    {
      code: 'Submitted',
      codeValue: 'Submitted',
      value: 'Submitted'
    },
    {
      code: 'Rejected',
      codeValue: 'Rejected',
      value: 'Rejected'
    },
    {
      code: 'Approved',
      codeValue: 'Approved',
      value: 'Approved',
    }
  ]);
  payload.availableCount = payload.data.length;

  return payload;
}

function returnEmptyPayLoad<T = any>(): Payload<Array<T>> {
  return {
    availableCount: 0,
    data: []
  };
}

function onRemoveChip(state: SupplierDetailState, chip: IChip) {
  const chips = state.historyTab.chips;
  const idx = chips.indexOf(chip);
  chips.splice(idx, 1);

  const filter = { ...state.historyTab.filter };

  switch (chip.extraPayload.key) {
    case 'selectedEvent':
      filter.selectedEvent = null;
      break;
    case 'selectedField':
      filter.selectedField = null;
      break;
    case 'selectedEditedBy':
      filter.selectedEditedBy = null;
      break;
    case 'selectedDC':
      filter.selectedDC = null;
      break;
    case 'dateRangeString':
      filter.createdFrom = null;
      filter.createdTo = null;
      break;
    case 'selectedDocument':
      filter.selectedDocument = null;
      break;
    default:
      break;
  }

  return {
    ...state,
    historyTab: {
      ...state.historyTab,
      filter
    }
  } as SupplierDetailState;
}

function onClearAllChips(state: SupplierDetailState) {
  state.historyTab.chips = [];
  const filter = { ...state.historyTab.filter };

  filter.selectedEvent = null;
  filter.selectedField = null;
  filter.selectedEditedBy = null;
  filter.selectedDC = null;
  filter.selectedDocument = null;
  filter.createdFrom = null;
  filter.createdTo = null;

  return {
    ...state,
    historyTab: {
      ...state.historyTab,
      filter,
    }
  };
}

export function reducer(state: SupplierDetailState | undefined, action: Action) {
  return rootReducer(state, action);
}

// Other business logic
export function prepareSupplierDetail(supplier: SupplierDetail): SupplierDetail {
  if (!supplier) {
    return supplier;
  }

  supplier.statusTag = prepareStatusTag(supplier.status);

  if (!supplier.headquarterAddress) {
    supplier.headquarterAddress = defaultAddress();
  }
  supplier.headquarterAddress = cleanAddress(supplier.headquarterAddress);

  if (supplier.headquarterAddressId && supplier.contacts) {
    supplier.contacts = supplier.contacts.filter(c => !!c);
    supplier.headquarterAddressContact = supplier.contacts.find(item => item?.uniqueId === supplier.headquarterAddressId);
  } else {
    supplier.headquarterAddressContact = null;
  }

  if (supplier.contacts) {
    supplier.remittanceAddressContact = supplier.contacts.find(item => item?.contactType === ContactType.RemittanceAddress);
  }

  if (supplier.dcSuppliers) {
    supplier.dcSuppliers.forEach(dcSupplier => prepareDcSupplierDisplayProperties(dcSupplier));
  }
  prepareSupplierDetailTerms(supplier);

  if (supplier.keheShipToRetailerDate) {
    supplier.keheShipToRetailerDate = new Date(supplier.keheShipToRetailerDate);
  }

  return supplier;
}

function prepareSupplierDetailTerms(supplier: SupplierDetail) {
  if (supplier.terms) {
    prepareTerms(supplier);
  } else {
    supplier.terms = new Terms();
  }

  if (supplier.terms.promotions === undefined) {
    supplier.terms.promotions = new PromotionTerms();
  }
  if (supplier.terms.paymentTerms === undefined) {
    supplier.terms.paymentTerms = new PaymentTerms();
  }

  return supplier;
}

function prepareTerms(supplier: SupplierDetail) {
  if (supplier.terms.contractDate) {
    supplier.terms.contractDate = new Date(supplier.terms.contractDate);
  }
  if (supplier.terms.changeDate) {
    supplier.terms.changeDate = new Date(supplier.terms.changeDate);
  }
  if (supplier.terms.spoils) {
    const spoils = supplier.terms.spoils;
    prepareSpoils(spoils);
  }

  supplier.terms.administrativeAllowanceProgramPercentage = (supplier.terms.administrativeAllowanceProgramPercentage ?? 0) / 100
}

function prepareSpoils(spoils: Spoils) {
  if (spoils.type) {
    if (spoils.type.toLowerCase() === 'y') {
      spoils.spoilsType = SpoilsTypes.fullCoverage;
    } else {
      if (spoils.allowancePercentage && spoils.allowancePercentage > 0) {
        spoils.spoilsType = SpoilsTypes.spoilageAllowance;
      } else {
        spoils.spoilsType = SpoilsTypes.noSupport;
      }
    }
  }
}

function prepareDcSupplierDisplayProperties(dcSupplier: DcSupplierListItem): DcSupplierListItem {
  if (dcSupplier.wareHouseLocation) {
    dcSupplier.dcDisplayName = prepareDcSupplierDcDisplayName(dcSupplier.wareHouseLocation.name);
  }
  return dcSupplier;
}

function prepareDcSupplierDcDisplayName(warehouseName: string): string {
  return parseFullDcName(warehouseName);
}

function prepareStatusTag(status: string) {
  const color = status === 'Active' ? '#D5E48F' : '#E2E2E2';
  const text = {
    'Discontinued - KeHE': 'Discontinued - KeHE', 'Discontinued - Name Change/Acquisition': 'Discontinued - Name/Acq.',
    'Discontinued - Manufacturer': 'Discontinued Manuf.', 'Active': 'Active'
  };
  return {
    bgColor: color,
    borderColor: color,
    text: text[status],
  };
}

function parseFullDcName(dcFullName: string) {
  // trim off everything before the hyphen and the dc number in parens
  return dcFullName.substring(dcFullName.lastIndexOf('-') + 1);
}

export function refineDcSupplierList(search: string, dcSupplierFilter: any, dcSupplierList: any[]) {
  const searchFilteredList = dcSupplierList.filter(x => x.supplierNumber.includes(search));
  if (dcSupplierFilter.dcSelection.length === 0) {
    return searchFilteredList;
  }
  return filterRefinedDcSupplierList(searchFilteredList, dcSupplierFilter);
}

export function filterRefinedDcSupplierList(searchedList: { distributionCenterNumber: string; }[], dcSupplierFilter: { dcSelection: { text: string; }[]; }) {
  const dcNumbers = [];
  const selectedDcs = dcSupplierFilter.dcSelection;
  for (let x = 0; x < selectedDcs.length; x++) {
    const number = selectedDcs[x].text.substring(selectedDcs[x].text.lastIndexOf('(') + 1).replace(')', '').trim();
    dcNumbers.push(number);
  }
  return searchedList.filter(function (item) {
    return dcNumbers.indexOf(item.distributionCenterNumber) !== -1;
  });
}

export function createDcSupplierFilterChipsList(dcSupplierFilter: DcSupplierFilter) {
  return dcSupplierFilter.dcSelection;
}

export function refineChipsAfterClick(chip: { text: string; }, dcSupplierFilter: DcSupplierFilter) {
  dcSupplierFilter.dcSelection = dcSupplierFilter.dcSelection.filter((filter: { text: string; }) => filter.text !== chip.text);
  return dcSupplierFilter;
}

export function hasUnsavedChanges(state: SupplierDetailState): boolean {
  return state.generalFormIsDirty ||
    state.dcPaymentHoldFormIsDirty ||
    state.termsFormIsDirty ||
    state.productGroupFormIsDirty ||
    state.isPurchasingOptionFormDirty ||
    state.productGroupDcFormIsDirty ||
    state.newProductGroupFormIsDirty ||
    state.newProductGroupFormIsPickupAddressDirty ||
    state.newProductGroupFormIsRemittanceAddressDirty ||
    state.supplierFormIsDirty;
}

function getProductSaveSuccessMessage(productGroupView: ProductGroupViews) {
  if (ProductGroupViews.AddDCProductGroupView === productGroupView) {
    return 'Your group has been created successfully.';
  }
  return 'Your changes have been saved successfully.';
}

export function hasValidForm(state: SupplierDetailState): boolean {
  return state.generalFormIsValid ||
    state.termsFormIsValid ||
    state.productGroupFormIsValid ||
    state.isPurchasingOptionFormValid ||
    state.productGroupDcFormIsValid ||
    state.newProductGroupFormIsValid ||
    state.supplierFormIsValid;
}

function getChipsForFilter(filter: SupplierHistoryFilter): Array<IChip> {
  const chips: IChip[] = [];

  if (filter.selectedEvent) {
    chips.push({
      text: filter.selectedEvent.code,
      extraPayload: {
        key: 'selectedEvent'
      }
    });
  }

  if (filter.selectedField) {
    chips.push({
      text: filter.selectedField.code,
      extraPayload: {
        key: 'selectedField',
      }
    });
  }

  if (filter.selectedEditedBy) {
    chips.push({
      text: filter.selectedEditedBy.code,
      extraPayload: {
        key: 'selectedEditedBy'
      }
    });
  }

  if (filter.selectedDC) {
    chips.push({
      text: filter.selectedDC.code,
      extraPayload: {
        key: 'selectedDC'
      }
    });
  }

  if (filter.selectedDocument) {
    chips.push({
      text: filter.selectedDocument.name,
      extraPayload: {
        key: 'selectedDocument'
      }
    });
  }

  if (filter.createdFrom && filter.createdTo) {
    const format = 'MM/DD/YYYY';
    chips.push({
      text: `${DateUtils.create(filter.createdFrom).format(format)} - ${DateUtils.create(filter.createdTo).format(format)}`,
      extraPayload: {
        key: 'dateRangeString'
      }
    });
  }

  return chips;
}

function prepBuyers(buyerList: Buyer[]) {
  buyerList.forEach(buyer => {
    const addCode = buyer.name.indexOf(buyer.code) === -1;
    if (addCode) {
      buyer.name = `${buyer.name} (${buyer.code})`;
    }
  });
  return buyerList.sort((a, b) => (
    Number(a.code) > Number(b.code)) ? 1 : -1
  );;
}

function getFormattedDate(today: Date): string {
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yy = today.getFullYear().toString().substring(-2);

  return mm + '/' + dd + '/' + yy;
}

function convertDecimals(form: TermsFormObject) {
  if (form.terms) {
    if (form.terms.shelfLifePercentage) {
      form.terms.shelfLifePercentage = convertDecimalField(form.terms.shelfLifePercentage, true);
    }
    if (form?.terms?.promotions?.manufacturerChargeBackFeePercentage) {
      form.terms.promotions.manufacturerChargeBackFeePercentage =
        convertDecimalField(form.terms.promotions.manufacturerChargeBackFeePercentage, true);
    }
  }
  return form;
}

// convert numeric fields to decimal. allow fields to be null if value is 0 else return original value
function convertDecimalField(field: any, isNullable0: boolean) {
  if (!isNaN(Number(field)) && field > 0 && field % 1 === 0) {
    return Number(field) / 100;
  } else if (isNullable0 && (field === 0 || field === '')) {
    return null;
  }
  return field;
}

function updateOnBoardingStatus(onboardingStatus: OnBoardingStatus[], status: OnBoardingStatus) {
  onboardingStatus = onboardingStatus.map(item => item.tab === status.tab ? status : item);
  return onboardingStatus;
}

function updateContacts(detail: SupplierDetail, contacts: Contact[]) {
  detail.contacts = contacts;
  return prepareSupplierDetail(detail);
}

function getContactSaveErrorMessage(errorCode: number): string {
  return errorCode === 400 ? 'This address and contact combination already exists.' :
    'There was an error. Please try again.';
}

