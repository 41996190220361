import { Injectable } from '@angular/core';
import {
  Address,
  AddressService,
} from '@kehe/phoenix-address';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { Contact, ContactType } from '../../../../../../models/contact';
import { selectSupplierDetailContacts } from '../../../../../supplier-detail/store/supplier-detail.selectors';
import { DeliveredAddressStore } from '../delivered-address.store';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { pickupContactMap } from '../../../../utils/address-format-util';

export enum PickupAddressMode {
  Add,
  Edit,
}

export interface AddDeliveredState {
  isAddressModalOpen: boolean;
  showSupplierContactError: boolean;
  supplierContactErrorMessage: string;
  showAddContactModal: boolean;
  showAddContactPreviewModal: boolean;
  isAddressValidationInProgress: boolean;
  pickupAddressMode: PickupAddressMode;
  selectedContact: Contact;
  isAddingPickupAddress: boolean;
  supplierContactPreviewErrorMessage: string;
}

export const initialState: AddDeliveredState = {
  isAddressModalOpen: false,
  showSupplierContactError: false,
  supplierContactErrorMessage: '',
  showAddContactModal: false,
  showAddContactPreviewModal: false,
  isAddressValidationInProgress: false,
  pickupAddressMode: PickupAddressMode.Add,
  selectedContact: null,
  isAddingPickupAddress: false,
  supplierContactPreviewErrorMessage:
    'We were unable to verify the address. Please review the address below.',
};

@Injectable({ providedIn: 'root' })
export class AddDeliveredContactStore extends ComponentStore<AddDeliveredState> {
  parentForm: UntypedFormGroup;

  selectShowSupplierContactError$ = this.select(
    (state) => state.showSupplierContactError
  );
  selectSupplierContactErrorMessage$ = this.select(
    (state) => state.supplierContactErrorMessage
  );
  selectShowAddContactModal$ = this.select(
    (state) => state.showAddContactModal
  );

  selectShowAddContactPreviewModal$ = this.select(
    (state) => state.showAddContactPreviewModal
  );

  getSupplierContactPreviewErrorMessage$ = this.select(
    (state) => state.supplierContactPreviewErrorMessage
  );

  selectSupplierContactDropdownSource$ = this._store.select(
    selectSupplierDetailContacts
  );

  selectIsAddAddressInProgress$ = this.select(
    (state) =>
      state.isAddressValidationInProgress || state.isAddingPickupAddress
  );
  selectPickupAddressContact$ = this.select((state) => state.selectedContact);
  selectPickupAddressMode$ = this.select((state) => state.pickupAddressMode);

  constructor(
    private _store: Store,
    private _addressService: AddressService,
    private _deliveredAddressStore: DeliveredAddressStore
  ) {
    super(initialState);
  }

  connectFormGroup(parentForm: UntypedFormGroup) {
    this.parentForm = parentForm;

    combineLatest([
      this._deliveredAddressStore.isEditAddressMode$,
      parentForm.valueChanges.pipe(startWith('')),
    ])
      .pipe(
        filter(([isEditMode]) => isEditMode),
        first(),
        tap(() => {
          if (!this.parentForm.value) {
            return;
          }

          const {
            pickupAddressId,
            pickupExtension,
            pickupAddress,
            pickupEmail,
            pickupFax,
            pickupPhone,
            pickupAddressNotes,
          } = this.parentForm.value?.pickup || this.parentForm.value;
          const contact: Contact = {
            uniqueId: pickupAddressId,
            phoneExtension: pickupExtension
              ? Number(pickupExtension)
              : undefined,
            address: pickupAddress,
            contactType: ContactType.PickupAddress,
            phone: pickupPhone,
            email: pickupEmail,
            fax: pickupFax,
            notes: pickupAddressNotes,
          };
          this.showEditPickupAddressModal(contact);
        })
      )
      .subscribe();
  }

  changeAddContactModalVisibility(visible: boolean): void {
    if (visible) {
      this._deliveredAddressStore.openAddressModal();
    } else {
      this._deliveredAddressStore.closeAddressModal();
    }
  }

  closeModal = this._deliveredAddressStore.closeAddressModal;
  tryAddPickupAddressContact(contact: Contact): void {
    this.patchState({
      selectedContact: contact,
    });

    this.validatePickupAddress$(contact?.address);
  }
  editPickupAddressContact(): void {
    this.patchState({
      showAddContactModal: true,
      showAddContactPreviewModal: false,
      showSupplierContactError: initialState.showSupplierContactError,
    });
  }
  usePickupAddressContact(): void {
    this.usePickupAddressAfterValidation$();
  }

  showAddPickupAddressModal() {
    this.patchState({
      pickupAddressMode: PickupAddressMode.Add,
      selectedContact: null,
      showAddContactModal: true,
      showSupplierContactError: false,
    });
  }

  showEditPickupAddressModal(selectedContact: Contact) {
    this.patchState({
      pickupAddressMode: PickupAddressMode.Edit,
      selectedContact,
      showAddContactModal: true,
      showSupplierContactError: false,
    });
  }

  validatePickupAddress$ = this.effect((address$: Observable<Address>) =>
    address$.pipe(
      tap(() => {
        this.patchState({
          isAddressValidationInProgress: true,
        });
      }),
      withLatestFrom(this.state$),
      switchMap(([address, state]) => {
        return this._addressService
          .validateAddress(address, environment.smartyStreetsKey)
          .pipe(
            map((response) => {
              this.patchState({
                isAddressValidationInProgress: false,
                showAddContactModal: !response.isValid,
                selectedContact: response.isValid
                  ? {
                      ...state.selectedContact,
                      address: {
                        ...state.selectedContact.address,
                        ...response.suggestedAddress,
                      },
                    }
                  : state.selectedContact,
              });
              this.completeValidatePickupAddress$(response.isValid);
              return EMPTY;
            }),
            catchError((error) => {
              this.patchState({
                isAddressValidationInProgress: false,
                showAddContactModal: true,
                selectedContact: state.selectedContact,
              });
              this.completeValidatePickupAddress$(false);

              return EMPTY;
            })
          );
      })
    )
  );

  completeValidatePickupAddress$ = this.effect(
    (isValid$: Observable<boolean>) =>
      isValid$.pipe(
        withLatestFrom(this.state$),
        mergeMap(([isValid, state]) => {
          if (isValid) {
            this.updateSupplierWithPickupAddress(state);
            this._deliveredAddressStore.closeAddressModal();
          } else {
            this.patchState({
              showAddContactModal: false,
              isAddressValidationInProgress: false,
              showAddContactPreviewModal: true,
              selectedContact: state.selectedContact,
              supplierContactErrorMessage:
                initialState.supplierContactErrorMessage,
            });
          }
          return EMPTY;
        })
      )
  );

  updateSupplierWithPickupAddress(state: AddDeliveredState) {
    this.updateSelectedAddress(state.selectedContact);
    this._deliveredAddressStore.closeAddressModal();
  }

  usePickupAddressAfterValidation$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(this.state$),
      map(([action, state]) => {
        this.updateSupplierWithPickupAddress(state);
      })
    )
  );

  updateSelectedAddress(contact: Contact) {
    const pickupAddresses =
      (this.parentForm.get('pickup.pickupAddress') as UntypedFormGroup) ||
      (this.parentForm.get('pickupAddress') as UntypedFormGroup);
    const pickupAddressNotes =
      (this.parentForm.get('pickup.pickupAddressNotes') as UntypedFormControl) ||
      this.parentForm.get('pickupAddressNotes');
    pickupAddresses.markAsTouched();
    pickupAddresses.markAsDirty();
    pickupAddresses.enable();
    const allKeys = Object.keys(pickupAddresses.controls);
    const address = {
      ...contact.address,
    };
    Object.keys(address).forEach((key) => {
      const isExist = allKeys.some((k) => k === key);
      if (!isExist) {
        delete address[key];
      }
    });
    pickupAddresses.patchValue(address);
    pickupAddressNotes.patchValue(contact?.notes);

    const prefix = this.parentForm.get('pickup') ? 'pickup.' : '';
    Object.keys(pickupContactMap).forEach((key) => {
      const pickupControl = this.parentForm.get(
        `${prefix}${key}`
      ) as UntypedFormControl;
      if (pickupControl) {
        pickupControl.enable();
        if (key === 'pickupExtension') {
          pickupControl.patchValue(contact[pickupContactMap[key]]?.toString());
        } else {
          pickupControl.patchValue(contact[pickupContactMap[key]]);
        }
        pickupControl.markAsTouched();
        pickupControl.markAsDirty();
      }
    });
  }
}
