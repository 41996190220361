<div class="modal-component">
  <div class="modal__header">
    <div class="modal__header__title">
      <p>Create Order / Upload File</p>
    </div>
    <div (click)="simpleCloseModal()" class="modal__header__cross">
      <span class="close"></span>
    </div>
  </div>
  <div class="modal__main">
    <form class="modal-form" [formGroup]="orderForm" novalidate>
      <div class="custom-order-number">
        <small [ngClass]="{ 'error-msg': name.invalid && (name.dirty || name.touched) }">
          Custom Order Name
        </small>
        <input
          type="text"
          placeholder="e.g. Easter Weekend"
          [ngClass]="{ 'error-border': name.invalid && (name.dirty || name.touched) }"
          formControlName="orderName"
        />
        <small *ngIf="name.invalid && (name.dirty || name.touched)" class="error-msg">Order Name is required</small>
      </div>
      <div class="po-number">
        <small>PO Number (Optional)</small>
        <input type="text" placeholder="e.g 12345" (input)="acceptOnlyDigits($event)" formControlName="poNumber" />
      </div>
      <div class="upload-file">
        <div class="upload-file-title-name">
          <small>Upload File</small>
        </div>
        <div class="input-and-button">
          <div [class.error-border]="errorMsg" class="fake-btn">
            <ng-template #selectedFileName>
              <p>{{ selectedFileNameStr }}</p>
            </ng-template>
            <ng-container *ngIf="!selectedFile; else selectedFileName">
              <p>filename.csv, .xls, or .xlsx</p>
            </ng-container>
          </div>
          <input
            style="display: none"
            type="file"
            (change)="onFileChanged($event)"
            accept=".csv,.xls, .xlsx"
            placeholder="filename csv, xls, or xlsx"
            #uploadFile
          />
          <div (click)="onBrowseClick()" class="input-inline-btn"><p>Browse</p></div>
          <div class="input-inline-spaceholder"></div>
        </div>
        <small *ngIf="errorMsg" class="error-msg-too-big">{{ errorMsg }}</small>
        <a href="{{ templateUrl }}" download class="download-template">Download Template</a>
        <div *ngIf="!errorMsg" class="spacer-15"></div>
      </div>
    </form>
  </div>
  <div class="modal__footer">
    <div (click)="backToInitialModal()" class="modal__footer__title">
      <p>Previous</p>
    </div>
    <div class="modal__footer__upload">
      <kehe-phoenix-button
        name="button_primary"
        (click)="uploadOrderFile()"
        classAttr="btn-primary"
        [disabled]="!canUploadFile || !isFormValid || !!isUploadingFile"
        [spinner]="isUploadingFile"
      >
        Upload
      </kehe-phoenix-button>
    </div>
  </div>
</div>
