import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DocumentsGridComponent } from './components/documents-grid/documents-grid.component';
import { PhoenixButtonModule, PhoenixMenuButtonModule, } from '@kehe/phoenix-button';
import { GridModule } from '@progress/kendo-angular-grid';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SupplierDocumentsEffects } from './store/supplier-documents.effects';
import * as fromSupplierDocuments from './store/supplier-documents.reducer';
import { PhoenixAlertsModule } from '@kehe/phoenix-notifications';
import { DeleteDocumentModalComponent } from './components/delete-document-modal/delete-document-modal.component';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';
import { UploadDocumentsModalComponent } from './components/upload-documents-modal/upload-documents-modal.component';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { DateInputModule, DateInputsModule, DatePickerModule, } from '@progress/kendo-angular-dateinputs';
import { PhoenixUploadModule } from '@kehe/phoenix-upload';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RejectDocumentModalComponent } from './components/reject-document-modal/reject-document-modal.component';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  AdditionalDocumentsModalComponent
} from './components/additional-documents-modal/additional-documents-modal.component';

@NgModule({
  declarations: [
    DocumentsGridComponent,
    DeleteDocumentModalComponent,
    UploadDocumentsModalComponent,
    RejectDocumentModalComponent,
    AdditionalDocumentsModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    LabelModule,
    DatePickerModule,
    PhoenixUploadModule,
    InputsModule,
    DateInputsModule,
    PhoenixButtonModule,
    GridModule,
    PhoenixModalModule,
    PhoenixAlertsModule,
    PhoenixIndicatorsModule,
    ComboBoxModule,
    DateInputModule,
    StoreModule.forFeature('supplierDocuments', fromSupplierDocuments.reducer),
    EffectsModule.forFeature([SupplierDocumentsEffects]),
    PhoenixMenuButtonModule,
  ],
  providers: [DatePipe],
  exports: [
    DocumentsGridComponent,
    AdditionalDocumentsModalComponent,
    UploadDocumentsModalComponent,
    DeleteDocumentModalComponent,
    RejectDocumentModalComponent,
  ],
})
export class SupplierDocumentsModule {}
