<div *ngIf="!isFormReady">
  <kehe-phoenix-loading></kehe-phoenix-loading>
</div>

<!-- using the hidden class because the nested components are required before isFormReady is true -->
<div [hidden]="!isFormReady">
  <div class="form-container">
    <div [formGroup]="form">
      <div class="row">
        <div class="col">
          <h4>Add Product Group</h4>
        </div>
      </div>
      <hr class="mt-0">
      <div class="row">
        <div class="col">
          <h5>Purchasing Details</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="productGroupNameField" text="Product Group Name (optional)"></kendo-label>
              <kendo-textbox #productGroupNameField formControlName="name" [maxlength]="maxLength"
                             placeholder="Enter a Group Name" (valueChange)="onNameValueChange($event)" (blur)="onNameBlur()">
              </kendo-textbox>
              <div class="d-flex">
                <kendo-formhint>Maximum of {{maxLength}} characters</kendo-formhint>
                <kendo-formhint class="ml-auto">{{counter}}</kendo-formhint>
              </div>
            </kendo-formfield>
          </div>
          <div class="form-group mb-md-0">
            <kendo-formfield>
              <kendo-label [for]="distributionCentersField" text="Distribution Centers"></kendo-label>
              <kendo-multiselect #distributionCentersField [autoClose]="false" [data]="modifiedDCList"
                                 [textField]="'code'" [valueField]="'value'" formControlName="distributionCenters"
                                 placeholder="Select one or more DCs" (valueChange)="updateBuyerOptions(false)" [filterable]="true"
                                 [kendoDropDownFilter]="{operator: 'contains'}" required>
              </kendo-multiselect>
              <kendo-formerror>Distribution Centers is required.</kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="temperatureClassField" text="Temperature Class"></kendo-label>
              <kendo-combobox #temperatureClassField formControlName="temperatureClass" [data]="tempClasses"
                              [filterable]="true" required (focus)="onFocusTemperatureClass()" placeholder="Select Temperature Class"
                              [kendoDropDownFilter]="{ operator: 'contains'}"></kendo-combobox>
              <kendo-formerror *ngIf="temperatureClass?.errors?.required">Temperature Class is required.</kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group mb-0">
            <kendo-formfield>
              <kendo-label [for]="brandsField" text="Brands (optional)"></kendo-label>
              <kendo-multiselect #brandsField formControlName="brands" [data]="brands" placeholder="Select Brands"
                                 [textField]="'name'" [valueField]="'code'" [filterable]="true"
                                 [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"></kendo-multiselect>
            </kendo-formfield>
          </div>
        </div>
      </div>
      <hr>
      <div [hidden]="showContactCard">
        <div class="row">
          <div class="col">
            <h5>Remittance Information</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <kendo-formfield>
                <kendo-label [for]="remitNameField" text="Remit Name (on invoice)"></kendo-label>
                <kendo-textbox #remitNameField formControlName="remitName" [maxlength]="remitNameMaxLength"
                               (valueChange)="remitNameChange($event)" required>
                </kendo-textbox>
                <div class="d-flex">
                  <kendo-formerror *ngIf="remitName?.errors?.required && (remitName?.dirty || remitName?.touched)">Remit
                    Name is required.</kendo-formerror>
                  <kendo-formhint
                    *ngIf="!remitName?.errors?.required || (remitNameIsEmpty && !remitName?.dirty && !remitName?.touched)">
                    Maximum of {{remitNameMaxLength}} characters</kendo-formhint>
                  <kendo-formhint class="ml-auto">{{remitNameCounter}}</kendo-formhint>
                </div>
              </kendo-formfield>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <input kendoCheckBox #useHqAddressCheckBox type="checkbox" [checked]="isUseHeadquarterInfoChecked"
                   (change)="onUseHeadquarterInfoChange()" />
            <kendo-label class="k-checkbox-label" [for]="useHqAddressCheckBox"
                         text="Use address and contact provided in Supplier Headquarters"></kendo-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group mb-0">
              <kehe-address #addressComponentRemittance [key]="smartyStreetsKey" [address]="tempAddress"
                            [street1MaxLength]="30" [street2MaxLength]="getStreet2Length()" [cityMaxLength]="getCityLength()"
                            [attentionMaxLength]="getAttentionLength()" [zipCodeMaxLength]="getZipCodeLength()"
                            [formSaved]="validateRemittanceAddressOnSave.asObservable()"
                            (addressSelected)="onRemittanceAddressSelected($event)" (noAddressSelected)="noRemittanceAddressSelected()">
              </kehe-address>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="d-flex">
              <kehe-phoenix-phone-number-input label="Phone" formControlName="remitPhone"></kehe-phoenix-phone-number-input>
              <span class="mr-2"></span>
              <div>
                <kendo-formfield>
                  <kendo-label [for]="remitExtensionField" text="Ext (optional)"></kendo-label>
                  <kendo-maskedtextbox #remitExtensionField formControlName="remitExtension" [maskValidation]="false"
                                       [prompt]="''" [mask]="mask" style="max-width: 75px !important;">
                  </kendo-maskedtextbox>
                </kendo-formfield>
              </div>
            </div>
            <div class="form-group">
              <kehe-phoenix-phone-number-input label="Fax (optional)" formControlName="remitFax"></kehe-phoenix-phone-number-input>
            </div>
            <div>
              <div style="max-width: 298px;">
                <kendo-formfield>
                  <kendo-label [for]="remitEmailField" text="Email"></kendo-label>
                  <kendo-textbox #remitEmailField formControlName="remitEmail" placeholder="e.g. contact@email.com"
                                 [maxlength]="remitEmailMaxLength" (valueChange)="remitEmailChange($event)">
                  </kendo-textbox>
                  <div class="d-flex">
                    <kendo-formerror *ngIf="remitEmail?.errors?.email">Invalid email format</kendo-formerror>
                    <kendo-formhint *ngIf="!remitEmail?.errors?.email">
                      Maximum of {{remitEmailMaxLength}} characters
                    </kendo-formhint>
                    <kendo-formhint class="ml-auto">{{remitEmailCounter}}</kendo-formhint>
                  </div>
                </kendo-formfield>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [hidden]="!showContactCard">
        <div class="row">
          <div class="col">
            <h5>Remittance Information</h5>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <div class="text-info">
              This information will be used for remittance.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <kendo-label text="Remittance Address" class="mb-1"></kendo-label>
              <app-contact-card formControlName="remittanceAddressId"
                                [defaultContactTypes]="contactType.RemittanceAddress"
                                [contacts]="supplier?.contacts">
              </app-contact-card>
              <kendo-formhint>Manage remittance address on locations tab.</kendo-formhint>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col">
          <h5>Buyers</h5>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <div [ngClass]="renderBuyerDCWarning ? 'text-info-warning': 'text-info'">All Distribution Centers must be
            assigned a buyer ({{selectedDcs}} of {{expectedDcs}} DCs selected).</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div>
            <div formArrayName="buyerNames">
              <div *ngFor="let dc of buyerNames.controls; let i = index">
                <div [formGroupName]="i" class="row mb-2">
                  <div class="col-md-4 pr-md-0">
                    <div class="mb-3">
                      <kendo-formfield>
                        <kendo-label [for]="buyerNames" text="Buyer"></kendo-label>
                        <kendo-combobox #buyerField (focus)="onFocus(buyerField)" formControlName="buyer" [data]="modifiedBuyerList"
                                        [textField]="'name'" [valueField]="'code'" (valueChange)="alignBuyerSelections(true)"
                                        [valuePrimitive]="true" [filterable]="true" required placeholder="Search by Buyer name or number"
                                        [kendoDropDownFilter]="{ operator: 'contains'}"></kendo-combobox>
                        <kendo-formerror *ngIf="getDCBuyer(i)?.errors?.required">Buyer is required.</kendo-formerror>
                      </kendo-formfield>
                    </div>
                    <div class="mb-3" *ngIf="canShowBuyerRegionEmail">

                      <kendo-formfield>
                        <kendo-label text="Buyer Email"></kendo-label>
                        <kendo-textbox formControlName="buyerEmail" placeholder="e.g. contact@email.com">
                        </kendo-textbox>
                        <kendo-formerror *ngIf="getDCBuyerEmailField(i)?.errors?.required">
                          Buyer Email is required.
                        </kendo-formerror>
                        <kendo-formerror *ngIf="!getDCBuyerEmailField(i)?.errors?.email && getDCBuyerEmailField(i)?.errors?.isInvalid">
                          Must be a valid KeHE email.
                        </kendo-formerror>
                        <kendo-formerror *ngIf="getDCBuyerEmailField(i)?.errors?.email">
                          Invalid email
                        </kendo-formerror>
                      </kendo-formfield>
                    </div>
                  </div>
                  <div class="col-md-4 pl-md-2 pr-md-0 mb-3">
                    <div class="mb-3" *ngIf="canShowBuyerRegionEmail">
                      <kendo-formfield>
                        <kendo-label text="Buyer Region"></kendo-label>
                        <kendo-combobox #buyerSelectionField (focus)="onFocus(buyerSelectionField)" formControlName="buyerRegion" [data]="buyerRegions" required
                                        [filterable]="true" placeholder="Select Buyer Region" [kendoDropDownFilter]="{ operator: 'contains'}">
                        </kendo-combobox>
                        <kendo-formerror *ngIf="getDCBuyerRegionField(i)?.errors?.required">Buyer Region is required.</kendo-formerror>
                      </kendo-formfield>
                    </div>
                    <div class="mb-3">
                      <kendo-formfield>
                        <kendo-label [for]="assignedDistributionCentersField" text="Distribution Centers"></kendo-label>
                        <kendo-multiselect #assignedDistributionCentersField [autoClose]="false"
                                           [data]="unassignedDCList[i]" [textField]="'code'"
                                           [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                           (valueChange)="alignBuyerSelections(true)" [valueField]="'value'" formControlName="dcs"
                                           placeholder="Select one or more DCs" required>
                        </kendo-multiselect>
                        <kendo-formerror>Distribution Centers is required.</kendo-formerror>
                      </kendo-formfield>
                    </div>
                  </div>
                  <div class="col-md-auto mt-md-3 pl-md-2 mb-4 mb-md-0 d-flex justify-content-end"
                       *ngIf="buyersFormGroup.controls.length > 1">
                    <kehe-phoenix-button name="deleteBuyerRow" (click)="removeBuyerRow(i)" classAttr="btn-medium btn-default">
                      <em class="fas fa-trash-alt"></em>
                    </kehe-phoenix-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <kehe-phoenix-button [disabled]="!enableAddBuyerBtn" name="addBuyerRow" classAttr="btn-link btn-medium"
                       (click)="addBuyer()" iconClassAttr="fas fa-plus">
            Add Buyer
          </kehe-phoenix-button>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col">
          <h5>Delivery Method</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <kendo-formfield>
            <kendo-label [for]="deliveryMethodField" text="Delivery Method"></kendo-label>
            <kendo-combobox #deliveryMethodField formControlName="deliveryMethodCode" [data]="deliveryMethodList"
                            (valueChange)="deliveryMethodValueChange($event)" [textField]="'label'" [valueField]="'value'"
                            [valuePrimitive]="true" [filterable]="true" required (focus)="onFocus(deliveryMethodField)"
                            placeholder="Select Delivery Method" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}">
            </kendo-combobox>
            <kendo-formerror *ngIf="deliveryMethodCode?.errors?.required">Delivery Method is required.</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col-12 col-md-6" *ngIf="canShowInboundRouteType()">
          <kendo-formfield showHints="always">
            <kendo-label [for]="inboundRouteTypeComponent" text="Inbound Route Type"></kendo-label>
            <kendo-combobox
              class="kehe-readonly-input"
              [readonly]="true"
              #inboundRouteTypeComponent
              (focus)="inboundRouteTypeComponent.toggle()"
              [data]="inboundRouteTypes"
              [textField]="'name'"
              [valueField]="'type'"
              [valuePrimitive]="true"
              [filterable]="true"
              [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
              formControlName="inboundRouteType"
              placeholder="Select Inbound Route Type"
            ></kendo-combobox>
            <kendo-formhint>
              This field cannot be edited.
            </kendo-formhint>
            <kendo-formerror *ngIf="inboundRouteTypeField?.errors?.required">
              Inbound Route Type is required.
            </kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
          <div *ngIf="isDelivered && (isDraftSupplier$ | async) === false && (deliveryAddressFeatureFlag$ | async)">
            <app-delivered-address [parent]="form"></app-delivered-address>
          </div>
      <div *ngIf="isDelivered && (isDraftSupplier$ | async) && (deliveryAddressFeatureFlag$ | async)">
        <div class="row mt-4">
          <div class="col">
            <h5>Delivered Address</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group" data-automation-id="draft-supplier-delivered-address">
              <kendo-label [text]="isInvitedDraftSupplier ? 'Delivered Address' : 'Delivered Address (Optional)'" class="mb-1"></kendo-label>
              <kendo-formerror *ngIf="form.get('pickupAddressId')?.touched
                  && form.get('pickupAddressId')?.errors?.required" class="mt-0 mb-2">
                  Delivered Address is required.
              </kendo-formerror>
              <app-contact-card formControlName="pickupAddressId"
                [defaultContactTypes]="contactType.DeliveredAddress"
                [contacts]="supplier?.contacts"
                [isInProgress]="saving$ | async"
                [saveContactError]="error$ | async"
                (selectedMyContact)="onSelectPickupAddress($event)"
                (smartyStreetsUseAddress)="onSmartyStreetsUseAddress($event)"
                (smartyStreetsEditAddress)="onSmartyStreetsEditAddress()"
                (remove)="onRemovePickupAddress()"
                (closeModal)="onClosePickupAddressModal()">
              </app-contact-card>

            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="{'hidden': hidePickupAddress }">
        <div class="row mt-4">
          <div class="col">
            <h5>Pickup Address</h5>
          </div>
        </div>
        <div class="row" [hidden]="showContactCard">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <kehe-address #addressPickupComponent [formSaved]="validateAddressOnSave.asObservable()"
                          [displayNameField]="true" [address]="tempAddress" [street1MaxLength]="30"
                          [street2MaxLength]="getStreet2Length()" [cityMaxLength]="getCityLengthPickup()"
                          [nameMaxLength]="getNameLength()" [attentionMaxLength]="getAttentionLength()"
                          [zipCodeMaxLength]="getZipCodeLength()" [key]="smartyStreetsKey"
                          (addressSelected)="onPickUpAddressSelected($event)" (noAddressSelected)="noPickUpAddressSelected()">
            </kehe-address>
            <div class="form-group">
              <kendo-formfield>
                <kendo-label [for]="pickupAddressNotes" text="Pickup Address Notes (optional)"></kendo-label>
                <kendo-textarea #pickupAddressNotesField formControlName="pickupAddressNotes" [maxlength]="maxLength"
                                placeholder="Enter Pickup Address notes." [resizable]="'none'"
                                (valueChange)="onPickupAddressNotesValueChange($event)">
                  <kendo-textarea-suffix class="d-flex justify-content-end kehe-textarea-counter">
                    <span class="ml-auto">{{ pickupAddressNotesCounter }}</span>
                  </kendo-textarea-suffix>
                </kendo-textarea>
              </kendo-formfield>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="d-flex">
              <div class="pickup-phone mb-3">
                <kehe-phoenix-phone-number-input label="Phone" formControlName="pickupPhone"></kehe-phoenix-phone-number-input>
              </div>
              <span class="mr-2"></span>
              <div>
                <kendo-formfield>
                  <kendo-label [for]="pickupExtensionField" text="Ext (optional)"></kendo-label>
                  <kendo-maskedtextbox #pickupExtensionField formControlName="pickupExtension" [maskValidation]="false"
                                       [prompt]="''" [mask]="mask" style="max-width: 75px !important;">
                  </kendo-maskedtextbox>
                </kendo-formfield>
              </div>
            </div>
            <div class="form-group">
              <kehe-phoenix-phone-number-input label="Fax (optional)" formControlName="pickupFax"></kehe-phoenix-phone-number-input>
            </div>
            <div style="max-width: 298px;">
              <kendo-formfield>
                <kendo-label [for]="pickupEmailField" text="Email"></kendo-label>
                <kendo-textbox #pickupEmailField formControlName="pickupEmail" placeholder="e.g. contact@email.com">
                </kendo-textbox>
                <kendo-formerror *ngIf="pickupEmail?.errors?.email">Invalid email</kendo-formerror>
              </kendo-formfield>
            </div>
          </div>
        </div>
        <div class="row" [hidden]="!showContactCard">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <kendo-label text="Pickup Address" class="mb-1"></kendo-label>
              <kendo-formerror *ngIf="form.get('pickupAddressId')?.touched
                  && form.get('pickupAddressId')?.errors?.required" class="mt-0 mb-2">
                  Pickup Address is required.
              </kendo-formerror>
              <app-contact-card formControlName="pickupAddressId"
                [defaultContactTypes]="contactType.PickupAddress"
                [contacts]="supplier?.contacts"
                [isInProgress]="saving$ | async"
                [saveContactError]="error$ | async"
                (selectedMyContact)="onSelectPickupAddress($event)"
                (smartyStreetsUseAddress)="onSmartyStreetsUseAddress($event)"
                (smartyStreetsEditAddress)="onSmartyStreetsEditAddress()"
                (remove)="onRemovePickupAddress()"
                (closeModal)="onClosePickupAddressModal()">
              </app-contact-card>

            </div>
          </div>
        </div>
      </div>
      <hr>
      <div>
        <div class="row">
          <div class="col">
            <h5>Lead Times</h5>
          </div>
        </div>
        <label *ngIf="!hasDeliveryMethod">Select a Delivery Method to enter Lead Times.</label>
        <div *ngIf="hasDeliveryMethod">
          <div class="row" *ngIf="isDelivered">
            <div class="col">
              <kendo-label text="Do you know supplier ready time?"></kendo-label>
              <ul class="k-list-horizontal pl-0 mt-1">
                <li class="k-radio-item mr-4">
                  <input type="radio" class="mr-0" name="readyTimeAvailable" kendoRadioButton
                         #knowSupplierReadyTimeYesField [value]="true" formControlName="readyTimeAvailable">
                  <kendo-label class='k-radio-label' for="knowSupplierReadyTimeYesField">Yes</kendo-label>
                </li>
                <li class="k-radio-item">
                  <input type="radio" class="mr-0" name="readyTimeAvailable" kendoRadioButton
                         #knowSupplierReadyTimeNoField [value]="false" formControlName="readyTimeAvailable">
                  <kendo-label class='k-radio-label' for="knowSupplierReadyTimeNoField">No</kendo-label>
                </li>
              </ul>
            </div>
          </div>
          <div formGroupName="deliveredLeadTimes" class="row align-items-start" *ngIf="isDelivered">
            <div class="col-md-3" *ngIf="!showOnlyTotalLeadTime">
              <kendo-formfield>
                <kendo-label text="Supplier Ready Time"></kendo-label>
                <kendo-numerictextbox formControlName="readyTime" placeholder="e.g. 3" [spinners]="false" [decimals]="0"
                                      [format]="'0'">
                </kendo-numerictextbox>
                <kendo-formhint>Calendar days</kendo-formhint>
                <kendo-formerror *ngIf="deliveredReadyTime?.errors?.required">
                  Supplier Ready Time is required.
                </kendo-formerror>
                <kendo-formerror *ngIf="deliveredReadyTime?.errors?.min || deliveredReadyTime?.errors?.max">
                  Between 1 and 499
                </kendo-formerror>
              </kendo-formfield>
            </div>
            <div class="col-12 col-md-auto pr-0 pl-0 operator pt-md-1 my-3" *ngIf="!showOnlyTotalLeadTime">
              +
            </div>
            <div class="col-md-3" *ngIf="!showOnlyTotalLeadTime">
              <kendo-formfield>
                <kendo-label text="Supplier Transit Time"></kendo-label>
                <kendo-numerictextbox formControlName="transitTime" placeholder="e.g. 9" [spinners]="false" [decimals]="0"
                                      [format]="'0'">
                </kendo-numerictextbox>
                <kendo-formhint>Calendar days</kendo-formhint>
                <kendo-formerror *ngIf="deliveredTransitTime?.errors?.required">
                  Supplier Transit Time is required.
                </kendo-formerror>
                <kendo-formerror *ngIf="deliveredTransitTime?.errors?.min || deliveredTransitTime?.errors?.max">
                  Between 1 and 499
                </kendo-formerror>
              </kendo-formfield>
            </div>
            <div class="col-12 col-md-auto pr-0 pl-0 operator pt-md-1 my-3" *ngIf="!showOnlyTotalLeadTime">
              =
            </div>
            <div class="col-md-3">
              <kendo-formfield>
                <kendo-label text="Total Lead Time"></kendo-label>
                <kendo-numerictextbox [readonly]="!showOnlyTotalLeadTime" formControlName="totalTime"
                                      placeholder="e.g. 12" [decimals]="0" [format]="'0'" [spinners]="false">
                </kendo-numerictextbox>
                <kendo-formhint>Calendar days</kendo-formhint>
                <kendo-formerror *ngIf="deliveredTotalTime?.errors?.required && showOnlyTotalLeadTime">
                  Total Lead Time is required.
                </kendo-formerror>
                <kendo-formerror
                  *ngIf="(deliveredTotalTime?.errors?.min || deliveredTotalTime?.errors?.max) && showOnlyTotalLeadTime">
                  Between 1 and 999
                </kendo-formerror>
              </kendo-formfield>
            </div>
          </div>
          <div formGroupName="pickupLeadTimes" class="row" *ngIf="!isDelivered">
            <div class="col-md-3" *ngIf="!showOnlyTotalLeadTime">
              <kendo-formfield>
                <kendo-label text="Supplier Ready Time"></kendo-label>
                <kendo-numerictextbox formControlName="readyTime" placeholder="e.g. 3" [spinners]="false" [decimals]="0"
                                      [format]="'0'">
                </kendo-numerictextbox>
                <kendo-formhint>Calendar days</kendo-formhint>
                <kendo-formerror *ngIf="pickUpReadyTime?.errors?.required">
                  Supplier Ready Time is required.
                </kendo-formerror>
                <kendo-formerror *ngIf="pickUpReadyTime?.errors?.min || pickUpReadyTime?.errors?.max">
                  Between 1 and 499
                </kendo-formerror>
              </kendo-formfield>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>
    <div class="row">
      <div class="col">
        <h5>PO Transmissions and Details</h5>
      </div>
    </div>
    <app-product-group-po-transmissions-form #poTransmissionsGroup [hasPOOrder]="isDraftSupplier">
    </app-product-group-po-transmissions-form>
  </div>
</div>
