<div class="px-4" *ngIf="isDraftSupplier">

  <div class="row">
    <div class="col">
      <h4>Documents</h4>
    </div>
  </div>

  <div class="row kehe-data-section">
    <div class="col-12 my-2">
      <div class="row mb-3">
        <div class="col-12 kehe-data-value">
          Upload and manage documents below. A current form must be uploaded for all required documents.
          Additional documents can also be maintained on this page. For a complete list of additional documents, click
          <a (click)="displayAdditionalDocumentsModal()"> here.</a>
        </div>
        <div class="col-12 kehe-data-value mt-3">
          Legal & other documents will appear in the table below as they are completed on other tabs.
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="row mb-0">
            <div class="col-12 kehe-data-value">
              Required documents:
            </div>
          </div>
          <div class="row">
            <div *ngFor="let document of requiredDocumentTypes" class="col-12 kehe-data-value">
              <em class="fas mr-2 align-middle"
                [ngClass]="{'fa-check check-icon': document.isApproved, 'fa-times close-icon': !document.isApproved}">
              </em>
              {{ document.name }}
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-3 mt-md-0">
          <div class="row mb-0">
            <div class="col-12 kehe-data-value">
              Legal & other documents:
            </div>
          </div>
          <div class="row">
            <div *ngFor="let document of legalDocumentTypes" class="col-12 kehe-data-value">
              <em class="fas mr-2 align-middle"
                [ngClass]="{'fa-check check-icon': document.isApproved, 'fa-times close-icon': !document.isApproved}">
              </em>
              {{ document.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="row">
        <app-documents-grid [excludedDocumentTypes]="excludedDocumentTypes"></app-documents-grid>
      </div>
    </div>
  </div>
</div>
<app-additional-documents-modal *ngIf="showAdditionalDocumentsModal" (closeModal)="closeAdditionalDocumentsModal()">
</app-additional-documents-modal>
<div class="row mt-5" *ngIf="!isDraftSupplier">
  <div class="col"></div>
  <div class="col-9">
    <div class="text-center">
      <span class="icon fas fa-seedling mb-3"></span>
      <h2>View Documents in KeHE Connect</h2>
      <p>
        We are working towards bringing Documents into CONNECT Enterprise, till then Documents are still managed and stored in KeHE CONNECT. 
      </p>
      <kehe-phoenix-button classAttr="btn-secondary btn-medium" (click)="goToDocumentsV1()">
        View Documents
      </kehe-phoenix-button>
    </div>
  </div>
  <div class="col"></div>
</div>
