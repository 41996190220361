import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { Destroyable } from '../../../../abstract/destroyable';
import { ActivatedRoute } from '@angular/router';
import { ProductGroup } from '../../models/product-group';
import { IKeheTab } from '@kehe/phoenix-tabs';
import { ProductGroupsViewTabIds } from '../../models/product-groups-view-tab-ids';
import { isInvitedDraftSupplier } from '../../utilities/supplier-utils';
import { SupplierDetail } from '../../models/supplier-detail';
import { formattedAddress, formattedContact } from '../../utilities/address-format-util';
import { getProductGroup } from '../../../product-groups/store/product-groups.selectors';

@Component({
  selector: 'app-product-groups-view',
  templateUrl: './product-groups-view.component.html',
  styleUrls: ['./product-groups-view.component.scss']
})
export class ProductGroupsViewComponent extends Destroyable implements OnInit, OnDestroy {

  private productGroupNumber: string;
  productGroup: ProductGroup;
  supplier: SupplierDetail;

  tabs: IKeheTab[] = [{text: ProductGroupsViewTabIds.dcProductGroups}];
  selectedTab: IKeheTab = {text: ProductGroupsViewTabIds.dcProductGroups};

  get showContactCard() {
    return isInvitedDraftSupplier(this.supplier);
  }

  constructor(
    private _store: Store,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.productGroupNumber = this.route.snapshot.paramMap.get('productGroupNumber');

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSupplierDetail))
      .subscribe((val) => {
        this.supplier = val;
      });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getProductGroup, { productGroupNumber: this.productGroupNumber }))
      .subscribe(val => {
        this.productGroup = val;
      });
  }

  get tempClass() {
    if (this.productGroup && this.productGroup.temperatureClass) {
      return this.productGroup.temperatureClass;
    }
    return '--';
  }

  get brands() {
    if (this.productGroup && this.productGroup.brands && this.productGroup.brands.length > 0) {
      return this.productGroup.brands.map(item => item.name);
    }
    return ['--'];
  }

  public defaultIfEmpty(str: string) {
    return str ? str : '--';
  }

  getAddressText() {
    return formattedAddress(this.productGroup.remittanceAddressContact);
  }

  getContactText() {
    return formattedContact(this.productGroup.remittanceAddressContact);
  }
}
