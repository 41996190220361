import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { SupplierDetailService } from '../../services/supplier-detail.service';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, catchError, map } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { selectSupplierDetail, selectSupplierHistoryFilters } from '../supplier-detail.selectors';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag';

import * as SupplierDetailActions from '../supplier-detail.actions';

@Injectable()
export class SupplierHistoryTabEffects {
  constructor(
    private readonly actions$: Actions,

    private readonly store: Store,
    private readonly supplierDetailService: SupplierDetailService,
  ) {}

  triggerSupplierHistoryRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SupplierDetailActions.supplierHistorySearchChange,
        SupplierDetailActions.supplierHistoryPageIndexChanged,
        SupplierDetailActions.supplierHistoryPageSizeChanged,
        SupplierDetailActions.supplierHistoryApplyFilters,
        SupplierDetailActions.supplierHistoryChipClicked,
        SupplierDetailActions.supplierHistoryClearAllChipsClicked
      ),
      map(() => SupplierDetailActions.loadSupplierHistoryList())
    )
  );

  loadSupplierHistoryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.loadSupplierHistoryList),
      withLatestFrom(
        this.store.select(selectSupplierDetail),
        this.store.select(selectSupplierHistoryFilters),
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.SupplierAggregatedAuditLog.key))
      ),
      switchMap(([, supplierDetail, filters, aggregatedLogFF]) => {
        return this.supplierDetailService.getCombinedAuditHistory(supplierDetail.esn, filters, aggregatedLogFF).pipe(
          map((data) =>
            SupplierDetailActions.loadSupplierHistoryListSuccess({ data })
          ),
          catchError(() =>
            of(SupplierDetailActions.loadSupplierHistoryListError())
          )
        );
      })
    )
  );

  loadSupplierHistoryFilters3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.supplierHistoryLoadFiltersData),
      withLatestFrom(this.store.select(selectSupplierDetail)),
      switchMap(([, supplierDetail]) =>
        forkJoin([
          this.supplierDetailService.getHistoryFilterFields(),
          this.supplierDetailService.getHistoryFilterEditedBy(
            supplierDetail.esn
          ),
        ]).pipe(
          map((data) =>
            SupplierDetailActions.supplierHistoryLoadFiltersDataSuccess({
              data,
            })
          ),
          catchError(() =>
            of(SupplierDetailActions.supplierHistoryLoadFiltersDataFailure())
          )
        )
      )
    )
  );
}
