import { ResourcesPageComponent } from './modules/resources-module/pages/resources-page/resources-page.component';
import { PhoenixSearchModule } from '@kehe/phoenix-search';
import { PhoenixSlidePanelModule } from '@kehe/phoenix-slide-panel';
import { KeheDeviceDetectorService } from './services/kehe-device-detector.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SupplierDetailModule } from './modules/supplier-detail/supplier-detail.module';
import { AppComponent } from './app.component';
import { OmNavigationComponent } from './components/om-navigation/om-navigation.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { DocumentListPageComponent } from './pages/document-list-page/document-list-page.component';
import { ItemService } from './services/item.service';
import { DocumentService } from './services/document.service';
import { MockComponentComponent } from './tests/mock-component/mock-component.component';
import { LoadMoreComponent } from './components/load-more/load-more.component';
import { GlobalService } from './services/global.service';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { UploadFileModalComponent } from './components/upload-file-modal/upload-file-modal.component';
import { ImportComponent } from './pages/import/import.component';
import { ImportService } from './services/import.service';
import { ValidationService } from './services/validation.service';
import { PriceChangesService } from './services/price-changes.service';
import { CommonService } from './services/common.service';
import { DollarPercentToggleComponent } from './components/dollar-percent-toggle/dollar-percent-toggle.component';
import { HealthPageComponent } from './pages/health-page/health-page.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StatusService } from './services/status.service';
import { CategoryManagerService } from './services/category-manager.service';
import { SupplierDevManagerService } from './services/supplier-dev-manager.service';
import { AppRoutingModule } from './app-routing.module';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';
import { PhoenixAddressModule } from '@kehe/phoenix-address';
import { PromiseModalComponent } from './components/promise-modal/promise-modal.component';
import { PromiseModalService } from './services/promise-modal.service';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  CheckBoxModule,
  InputsModule,
  FormFieldModule,
  NumericTextBoxModule,
} from '@progress/kendo-angular-inputs';
import { PhoenixTopBarModule } from '@kehe/phoenix-top-bar';
import {
  StoreRouterConnectingModule,
  FullRouterStateSerializer,
} from '@ngrx/router-store';
import { UserNotificationsStoreModule } from './modules/user-notifications/user-notifications-store.module';
import { GaService } from './services/ga.service';
import { UserAgreementModalComponent } from './components/user-agreement-modal/user-agreement-modal.component';
import {
  ButtonGroupModule,
  ButtonModule,
} from '@progress/kendo-angular-buttons';
import {
  PhoenixButtonModule,
  PhoenixMenuButtonModule,
} from '@kehe/phoenix-button';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import { PhoenixAlertsModule } from '@kehe/phoenix-notifications';
import { PhoenixChipsModule } from '@kehe/phoenix-chips';
import { PagerModule } from '@progress/kendo-angular-pager';
import {
  ComboBoxModule,
  DropDownsModule,
  MultiSelectModule,
} from '@progress/kendo-angular-dropdowns';
import { BreadCrumbModule } from '@progress/kendo-angular-navigation';
import { GridModule } from '@progress/kendo-angular-grid';
import { PhoenixTopNavigationPanelModule } from '@kehe/phoenix-top-navigation-panel';
import { PhoenixFooterModule } from '@kehe/phoenix-footer';
import { MerchandisingComponent } from './pages/merchandising/merchandising.component';
import { UnauthorizedPageComponent } from './pages/unauthorized-page/unauthorized-page.component';
import { PhoenixErrorsModule } from '@kehe/phoenix-errors';
import {
  PhoenixCommonLayoutsModule,
  PhoenixManageListPageLayoutsModule,
} from '@kehe/phoenix-layouts';
import {
  AbstractFeedbackService,
  PhoenixFeedbackModule,
  FeedbackStore,
} from '@kehe/phoenix-feedback';
import { FeedbackService } from './services/feedback.service';
import { AnnouncementsRecentModule } from './modules/announcements/announcements-recent/announcements-recent.module';
import { WorkQueueBannerComponent } from './components/work-queue-banner/work-queue-banner.component';
import { reducers } from './shared/state/index';
import { FeatureFlagModule } from './feature-flag/feature-flag.module';
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';
import { CustomPromotionsPageComponent } from './modules/custom-promotions/pages/custom-promotions-page/custom-promotions-page.component';
import { BreakpointEffects } from './shared/state/breakpoint/breakpoint.effects';
import { PromotionalRoadmapPageComponent } from './modules/promotional-roadmap/pages/promotional-roadmap-page/promotional-roadmap-page.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { DeviceModule } from './device/device.module';
import { DistributionCenterModule } from './distribution-center/distribution-center.module';
import { SupplierModule } from './supplier/supplier.module';
import { BreadcrumbsEffects } from './breadcrumbs/breadcrumbs.effects';
import { MonitoringEffects } from './monitoring/monitoring.effects';
import { BreadCrumbsService } from './breadcrumbs/breadcrumbs.service';
import { UserAgreementStoreModule } from './modules/user-agreement/store/user-agreement-store.module';
import { ManageProgressEffects } from './shared/state/manage-progress/manage-progress.effects';
import { ExportHierarchiesModule } from './modules/utilities/hierarchies/export-hierarchies/export-hierarchies.module';
import { KehePipesModule } from '@kehe/phoenix-utils';
import { DocumentViewEditModalComponent } from './components/document-view-edit-modal/document-view-edit-modal.component';
import { DocumentCreateModalComponent } from './components/document-create-modal/document-create-modal.component';
import { DocumentEditFormComponent } from './components/document-edit-form/document-edit-form.component';
import { PhoenixUploadModule } from '@kehe/phoenix-upload';
import { UploadModule } from '@progress/kendo-angular-upload';
import { ManagePricePageComponent } from './modules/manage-prices/pages/manage-price-page/manage-price-page.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { ManagePriceGroupsPageComponent } from './modules/manage-price-groups/pages/manage-price-groups-page/manage-price-groups-page.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

const conditionalProviders = [];
if (environment.googleTagManager) {
  conditionalProviders.push({
    provide: 'googleTagManagerId',
    useValue: environment.googleTagManager,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    OmNavigationComponent,
    ImportComponent,
    HomePageComponent,
    MockComponentComponent,
    LoadMoreComponent,
    HelpPageComponent,
    UploadFileModalComponent,
    DocumentListPageComponent,
    DollarPercentToggleComponent,
    HealthPageComponent,
    PromiseModalComponent,
    UserAgreementModalComponent,
    MerchandisingComponent,
    ResourcesPageComponent,
    PromotionalRoadmapPageComponent,
    UnauthorizedPageComponent,
    WorkQueueBannerComponent,
    CustomPromotionsPageComponent,
    DocumentViewEditModalComponent,
    DocumentCreateModalComponent,
    DocumentEditFormComponent,
    ManagePricePageComponent,
    ManagePriceGroupsPageComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    EffectsModule.forRoot([
      BreadcrumbsEffects,
      BreakpointEffects,
      MonitoringEffects,
      ManageProgressEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      name: 'Enterprise Web',
      connectInZone: true,
    }),
    AuthenticationModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    OverlayModule,
    AppRoutingModule,
    PhoenixFeedbackModule,
    PhoenixAddressModule,
    SupplierDetailModule,
    PhoenixModalModule,
    LabelModule,
    InputsModule,
    PhoenixTopBarModule,
    UserNotificationsStoreModule,
    ButtonGroupModule,
    ButtonModule,
    CheckBoxModule,
    PhoenixButtonModule,
    PhoenixMenuButtonModule,
    PhoenixIndicatorsModule,
    PhoenixSlidePanelModule,
    DateInputsModule,
    PhoenixAlertsModule,
    PhoenixSearchModule,
    PhoenixChipsModule,
    PagerModule,
    BreadCrumbModule,
    FormFieldModule,
    MultiSelectModule,
    ComboBoxModule,
    NumericTextBoxModule,
    DropDownsModule,
    BreadCrumbModule,
    GridModule,
    PhoenixTopNavigationPanelModule,
    PhoenixFooterModule,
    PhoenixErrorsModule,
    AnnouncementsRecentModule,
    PhoenixCommonLayoutsModule,
    FeatureFlagModule,
    DeviceModule,
    DistributionCenterModule,
    SupplierModule,
    UserAgreementStoreModule,
    ExportHierarchiesModule,
    PhoenixManageListPageLayoutsModule,
    KehePipesModule,
    PhoenixUploadModule,
    UploadModule,
    ChatBotComponent,
    ScrollingModule,
    ExperimentalScrollingModule,
  ],
  providers: [
    CanDeactivateGuardService,
    CommonService,
    ImportService,
    ValidationService,
    PriceChangesService,
    GlobalService,
    ItemService,
    KeheDeviceDetectorService,
    DocumentService,
    StatusService,
    CategoryManagerService,
    SupplierDevManagerService,
    PromiseModalService,
    GaService,
    FeedbackStore,
    { provide: AbstractFeedbackService, useClass: FeedbackService },
    ...conditionalProviders,
    [{ provide: ICON_SETTINGS, useValue: { type: 'font' } }],
    BreadCrumbsService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
