import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import { UploadOrderFileData } from '../models/upload-order-file-data';

@Injectable({
  providedIn: 'root'
})
export class UploadOrderFileService {
  private readonly uploadOrderFileUrl: string;
  private readonly endpointUrl = 'Orders/UploadFile';

  constructor(private http: HttpClient) {
    this.uploadOrderFileUrl = environment.api + this.endpointUrl;
  }

  uploadOrderFile(formData: UploadOrderFileData) {
    const dataToSend = this.createFormDataObject(formData);
    return this.http.post<any>(this.uploadOrderFileUrl, dataToSend);
  }
  // aux methods
  createFormDataObject(formData: UploadOrderFileData) {
    const uploadData = new FormData();
    const dataKeys = Object.keys(formData);
    dataKeys.forEach(key => uploadData.append(key, formData[key]));
    return uploadData;
  }
  // error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
