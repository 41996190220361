import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import {
  selectOnBoardingStatus,
  selectSupplierDetail,
  selectTermsDropdownData,
} from '../../store/supplier-detail.selectors';
import { FeatureFlags } from '@app/feature-flag';
import { createSelector } from '@ngrx/store';

export const selectTermsViewVM = createSelector(
  selectSupplierDetail,
  selectOnBoardingStatus,
  selectTermsDropdownData,
  selectIsFeatureFlagOn(FeatureFlags.AAPTerm.key),
  (
    supplier,
    onboardingStatus,
    termsDropDownData,
    ffAAPField,
  ) => {
    return {
      supplier,
      onboardingStatus,
      termsDropDownData,
      ffAAPField,
    };
  },
);
