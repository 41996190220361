import { createAction, props } from "@ngrx/store";
import { BreadCrumbItem } from "@progress/kendo-angular-navigation";

export const updateKendoBreadCrumbs = createAction(
    '[Product Detail] Update BreadCrumbs',
    props <{ item: BreadCrumbItem }>(),
);

export const clearKendoBreadCrumbs = createAction(
    '[Product Detail] Clear BreadCrumbs',
);

export const clickedKendoBreadCrumbs = createAction(
    '[Product Detail] Clicked BreadCrumbs',
    props <{ item: BreadCrumbItem }>(),
);
