import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { DocumentType } from '../../models/document-type';
import { selectAdditionalDocumentTypes } from '../../store/supplier-documents.selectors';
import { Constants } from '../../../../constants/constants';

@Component({
  selector: 'app-additional-documents-modal',
  templateUrl: './additional-documents-modal.component.html',
  styleUrls: []
})
export class AdditionalDocumentsModalComponent extends Destroyable implements OnInit {

  @Output()
  public closeModal: EventEmitter<any> = new EventEmitter();

  additionalDocumentTypes: DocumentType[] = [];

  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectAdditionalDocumentTypes))
      .subscribe((val) => {
        this.additionalDocumentTypes = val
          .filter(item => item.id !== Constants.PurchasingInfoDocumentTypeId)
          .sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  onCloseModal() {
    this.closeModal.emit();
  }
}
