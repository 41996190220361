import { Injectable } from '@angular/core';
import { EventProperties, Visitor } from './interfaces/pendo.interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PendoService {
  private scriptLoaded = false;
  private pendoInitialized = false;

  constructor() {
    this.loadPendoScript();
  }

  /**
   * Adds the pendo snippet to the document upon service initialization.
   */
  private loadPendoScript() {
    if (this.scriptLoaded) {
      return;
    }

    const pendoInit = (p: any, e: Document, n: string, d: string, apiKey: string) => {
      let v: string[], w: number, x: number, y: HTMLScriptElement, z: Element;
      const o: any = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w) {
        (function (m: string) {
          o[m] = o[m] || function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
        })(v[w]);
      }
      y = e.createElement(n) as HTMLScriptElement;
      y.async = true;
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0];
      z.parentNode!.insertBefore(y, z);
    };

    pendoInit(window, document, 'script', 'pendo', environment.pendoApiKey);
    this.scriptLoaded = true;
  }

  /**
   * Initializes a Pendo user. This should be called after authentication.
   */
  public initializePendo(visitor: Visitor) {
    if (this.pendoInitialized) {
      return;
    }

    (window as any)['pendo'].initialize({
      visitor: visitor,
    });

    this.pendoInitialized = true;
  }

  /**
   * Records a track event programmatically. It can be used to associate properties
   * to an event that are not accessible through Pendo's UI designer functionality.
   */
  public trackEvent(eventName: string, eventProperties?: EventProperties) {
    if (typeof (window as any)['pendo'] !== 'undefined' && (window as any)['pendo'].track) {
      (window as any)['pendo'].track(eventName, eventProperties);
    } else {
      console.warn('Pendo is not initialized or track method is not available');
    }
  }
}