import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierDocumentsState } from './supplier-documents.reducer';
import { Constants } from '../../../constants/constants';
import {
  documentsTabLiveStatus,
  legalDocumentsForDocumentsTab,
  requiredDocumentsForDocumentsTab
} from '../utils/documents-utils';
import { DocumentStatus } from '../models/document-statuses';

export const getSupplierDocumentsState = createFeatureSelector<SupplierDocumentsState>(
  'supplierDocuments'
);
export const selectDocumentsAlertMessage = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state.alertMessage
);
export const selectSupplierDetail = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state.supplier
);
export const selectDocuments = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state?.documents
);
export const SelectIsLoadDocumentsError = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state?.isLoadDocumentsError
);
export const selectBrokerDocument = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state.documents.find(item => item.typeId === Constants.BrokerRecordDocumentTypeId)
);
export const selectDocumentW9 = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state?.documents.find(item => item.typeId === Constants.W9DocumentTypeId)
);
export const selectDocumentTypes = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state?.documentTypes
);
export const selectRequiredDocumentTypes = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => requiredDocumentsForDocumentsTab(state?.documentTypes)
);
export const selectLegalDocumentsForDocumentsTab = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => legalDocumentsForDocumentsTab(state.documentTypes, state.supplier)
);
export const selectAdditionalDocumentTypes = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state?.documentTypes.filter(item => !item.required && !item.isDocuSign
    && item.id !== Constants.LetterOfFinancialResponsibilityTypeId)
);
export const selectUploadDocument = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state.uploadDocument);
export const selectIsDocumentUploading = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state.isDocumentUploading
);
export const selectDeleteDocument = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state.deleteDocument
);
export const selectIsDeletingDocument = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state.isDeletingDocument
);
export const selectRejectDocument = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state?.rejectDocument
);
export const selectIsDocumentActionLoading = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state.isDocumentActionLoading
);
export const selectRejectDocumentFeedback = createSelector(
  getSupplierDocumentsState,
  (state: SupplierDocumentsState) => state.rejectDocumentFeedback
);
export const selectSIFNotSubmitted = createSelector(
  getSupplierDocumentsState,
  (state) => {
    return state?.documents.find(item => item.typeId === Constants.SupplierInfoDocumentTypeId)?.status === DocumentStatus.NotSubmitted;
  }
);
export const selectSIFReadyForReview = createSelector(
  getSupplierDocumentsState,
  (state) => {
    return state?.documents.find(item => item.typeId === Constants.SupplierInfoDocumentTypeId)?.status === DocumentStatus.InReview;
  }
);

export const selectDocumentsTabLiveStatus = createSelector(
  getSupplierDocumentsState,
  (state) => {
    return documentsTabLiveStatus(state.documentTypes, state.documents);
  }
);

export const selectDocusignDocuments = createSelector(
  getSupplierDocumentsState,
  (state) => {
    const docusign = state.documentTypes.filter((type) => type.isDocuSign).map((type) => type.id);
    return state.documents.filter((document) => docusign.includes(document.typeId));
  }
);
