import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { retry } from 'rxjs/operators';
import { NonHqBrokersResponse } from '../models/non-hq-broker-response.model';
import { AssociatedSuppliersResponse, DeleteAssociatedSupplierApiResponse } from '../models/associated-suppliers-response';
import { SupplierDropdownItem } from '../components/associated-supplier-tab/models/supplier-dropdown-item.model';
import { BrokerAssociatedSuppliersPayload, BrokerAssociatedSuppliersResponse } from '../../broker-detail/components/associated-supplier-tab/models/broker-associated-suppliers-response';
import { AssociatedSuppliersPayload } from '../components/associated-supplier-tab/api-stores/associated-suppliers.api.store';
import { MasterVersionNumberResponse } from '../models/master-version-number-response';

@Injectable({
  providedIn: 'root',
})
export class VendorBrokerManagementService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.vendorBrokerManagementApi;
  }

  getBrokers(search?: string): Observable<{ ebn: string; name: string }[]> {
    const params = search ? { search } : {};
    return this.httpClient
      .get<{ ebn: string; name: string }[]>(`${this._url}query/brokers`, {
        params,
      })
      .pipe(retry(1));
  }

  addNonHqBroker(
    esn: string,
    ebn: string,
    auditNotes: string
  ): Observable<{ id: number; esn: string }> {
    const payload = { ebn, auditNotes };
    return this.httpClient
      .post<{ id: number; esn: string }>(
        `${this._url}master/broker/${esn}/relationships/add`,
        payload
      )
      .pipe(retry(1));
  }

  getRelatedNonHqBrokers(esn: string): Observable<NonHqBrokersResponse> {
    return this.httpClient
      .get<NonHqBrokersResponse>(
        `${this._url}query/supplier/${esn}/nonhqbrokers`
      )
      .pipe(retry(1));
  }

  deleteNonHqBroker(esn: string, ebn: string, auditNotes: string): Observable<{ esn: string }> {
    const payload = {
      existingEbn: ebn,
      deleteRelationShip: true,
      hqBroker: false,
      auditNotes: auditNotes
    };

    return this.httpClient
      .patch<{ esn: string }>(
        `${this._url}master/broker/${esn}/relationships/update`,
        payload
      )
      .pipe(retry(1));
  }

  getMasterRelationshipVersion(supplierEsn: string): Observable<MasterVersionNumberResponse> {
    return this.httpClient
      .get<MasterVersionNumberResponse>(
        `${this._url}query/${supplierEsn}/version/0`
      );
  }

  getAssociatedSuppliers(
    payload: AssociatedSuppliersPayload
  ): Observable<AssociatedSuppliersResponse> {

    let params = new HttpParams()
    .set('search', payload?.searchTerm?.trim())
    .set('sortcolumn', payload?.sortColumn)
    .set('sortdirection', payload?.sortDirection); 

    return this.httpClient
      .get<AssociatedSuppliersResponse>(
        `${this._url}query/supplier/${payload?.supplierEsn}/suppliers`,
        {
          params,
        }
      )
      .pipe(retry(1));
  }

  getBrokerAssociatedSuppliers(payload: BrokerAssociatedSuppliersPayload): Observable<BrokerAssociatedSuppliersResponse[]> {
    let params = new HttpParams()
    .set('sortcolumn', payload?.sortColumn?.toString())
    .set('sortdirection', payload?.sortDirection?.toString()); 
  
    return this.httpClient
      .get<BrokerAssociatedSuppliersResponse[]>(
        `${this._url}query/broker/${payload?.brokerEsn}/suppliers`,
        {
          params
        }
      )
      .pipe(retry(1));
  }

  getSuppliers(search?: string): Observable<SupplierDropdownItem[]> {
    let params: { status: string[]; search?: string } = {
      status: ['Active'],
    };
    if (search) {
      params = {
        ...params,
        search,
      };
    }
    return this.httpClient
      .get<SupplierDropdownItem[]>(`${this._url}query/suppliers`, {
        params,
      })
      .pipe(retry(1));
  }

  addSupplierToSupplierRelationship(
    esn: string,
    associatedSupplierEsn: string,
    auditNotes: string
  ): Observable<{ esn: string, masterVersionNumber: number }> {
    const payload = {
      esn: associatedSupplierEsn,
      auditNotes: auditNotes
    };

    return this.httpClient
      .post<{ esn: string, masterVersionNumber: number  }>(
        `${this._url}master/supplier/${esn}/relationships/add`,
        payload
      )
      .pipe(retry(1));
  }

  deleteSupplierToSupplierRelationship(
    esn: string,
    esnsToDelete: string[],
    auditNotes: string
  ): Observable<DeleteAssociatedSupplierApiResponse[]> {
    const request = {
      esnsToDelete: esnsToDelete,
      auditNotes: auditNotes
    };
    return this.httpClient
      .request<DeleteAssociatedSupplierApiResponse[]>(
        'delete',
        `${this._url}master/supplier/${esn}/relationships`,
        { body: request }
      )
      .pipe(retry(1));
  }

  getBrokerAssociatedSuppliersExport(ebn: string): Observable<any> {
    const url = `${this._url}query/broker/${ebn}/suppliers/export`;
    return this.httpClient
      .get(url, {
        responseType: 'text',
      })
      .pipe(retry(1));
  }
}
