import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { Buyer } from '../models/buyer';

@Injectable()
export class BuyerService {

    private _url: string;

    constructor(
        private _httpClient: HttpClient
    ) {
        this._url = environment.supplierApi;
    }

    getBuyers(): Observable<any> {
        return this._httpClient
            .get<{ availableCount: number; data: Buyer[] }>(
                `${this._url}/api/buyers`
            )
            .pipe(map((response) => response.data))
            .pipe(retry(2));
    }

}
