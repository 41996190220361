import { Component, OnInit } from '@angular/core';
import { Document } from './../../models/document';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { Destroyable } from '@app/abstract/destroyable';
import { DocumentListPageStore } from './store/document-list-page.store';
import { DocumentActionOptions } from './store/document-utils';
import { BreakpointService } from '@kehe/phoenix-utils';
import { DocumentEditModalStore } from './store/document-edit-modal.store';
import { DocumentCreateModalStore } from './store/document-create-modal.store';

@Component({
  selector: 'app-document-list-page',
  templateUrl: './document-list-page.component.html',
  styleUrls: ['./document-list-page.component.scss'],
  providers: [
    DocumentListPageStore,
    DocumentEditModalStore,
    DocumentCreateModalStore
  ]
})
export class DocumentListPageComponent extends Destroyable implements OnInit {

  vm$ = this._documentListStore.vm$;
  searchControl = this._documentListStore.searchControl;

  isMedAndBelow$ = this._breakpointService.isMedAndBelow$;
  isSmallAndBelow$ = this._breakpointService.isSmallAndBelow$;

  constructor(
    private _documentListStore: DocumentListPageStore,
    private _documentEditStore: DocumentEditModalStore,
    private _documentCreateStore: DocumentCreateModalStore,
    private _breakpointService: BreakpointService,
  ) {
    super();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  handleEllipsisOption($event: OptionMenuItem, dataItem: Document): void {
    switch ($event.name) {
      case DocumentActionOptions.Delete:
        this.tryDeleteDocument(dataItem);
        break;
      case DocumentActionOptions.Download:
        this.tryDownloadDocument(dataItem);
        break;
      case DocumentActionOptions.OpenLink:
        this.openLinkInNewTab(dataItem.fileUrl);
      default:
        break;
    }
  }

  onPageChange(event: PageChangeEvent): void {
    this._documentListStore.pageChanged(event);
  }

  openLinkInNewTab(linkUrl: string): void {
    window.open(linkUrl, '_blank');
  }

  handleTrashCanClick(item: Document): void {
    this.tryDeleteDocument(item);
  }

  // begin delete with confirmation
  tryDeleteDocument(item: Document): void {
    this._documentListStore.tryDeleteDocument(item);
  }

  // deleted confirmed -- call API
  handleConfirmDeleteDocumentDlgSubmitted(): void {
    this._documentListStore.confirmDeleteDocument$();
  }

  // cancel delete
  handleConfirmDeleteDocumentDlgClose(): void {
    this._documentListStore.cancelDelete();
  }

  tryDownloadDocument(file: Document): void {
    this._documentListStore.downloadFile$(file);
  }

  // refresh provided for error state feature
  refresh(): void {
    this._documentListStore.reloadDocumentsWithCurrentFilter$();
  }

  viewDocument(doc: Document): void {
    this._documentEditStore.viewDocument(doc);
  }

  addDocument(): void {
    this._documentCreateStore.createDocument();
  }
}
