import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ContactType } from '../../../../models/contact';
import { Store } from '@ngrx/store';
import { selectHeadquarterAddress, selectSupplierLocationContacts } from '../../store/supplier-locations.selectors';

@Component({
  selector: 'app-headquarter-address',
  templateUrl: './headquarter-address.component.html',
  styleUrls: []
})
export class HeadquarterAddressComponent {
  saving = false;
  types = ContactType;
  form = this._builder.group({
    headquarterAddressId: [],
  });

  error?: string;

  address$ = this._store.select(selectHeadquarterAddress);
  contacts$ = this._store.select(selectSupplierLocationContacts);

  constructor(
    private readonly _store: Store,
    private readonly _builder: UntypedFormBuilder,
  ) {
    this.form.get('headquarterAddressId').disable();

    this.address$.subscribe((address) => {
      if (address) {
        this.form.get('headquarterAddressId').setValue(address);
      }
    })
  }
}
