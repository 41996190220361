import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApiFailureText } from '../../../../../../enum/alert-text.enum';
import { VendorBrokerManagementService } from '../../../../services/vendor-broker-management.service';
import { NonHqBroker } from '../../../../models/non-hq-broker-response.model';
import * as _ from 'lodash';
import { AbstractApiStore } from '@app/abstract/abstract-api.store';

/**
 * `NonHeadquarterBrokerApiStore` is an Angular service that extends `AbstractApiStore` to get the supplier headquarter broker.
 *
 * @class
 * @extends AbstractApiStore
 *
 * @param {VendorBrokerManagementService} _vendorBrokerManagementService.
 * @param {Store} _store - A redux store that holds the application state.
 */
@Injectable({ providedIn: 'root' })
export class SearchNonHeadquarterBrokerApiStore extends AbstractApiStore<
  NonHqBroker[]
> {
  constructor(
    private _vendorBrokerManagementService: VendorBrokerManagementService,
    private _store: Store
  ) {
    super();
  }

  loadNonHQBrokers$ = this.effect((searchText$: Observable<string>) =>
    searchText$.pipe(
      tap(() => this.startLoading()),
      switchMap((search) => {
        return this._vendorBrokerManagementService.getBrokers(search).pipe(
          tapResponse(
            (brokers) => {
              const mapping = brokers.map((broker) => ({
                ebn: broker.ebn,
                name: `${broker.name} (${broker?.ebn})`,
                associatedOn: '',
                status: '',
              }));

              this.setData(_.sortBy(mapping, 'name'));
            },
            () => {
              this.setError(ApiFailureText.Generic);
            }
          )
        );
      })
    )
  );
}
