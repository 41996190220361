import { ITag } from '@kehe/phoenix-indicators';
import { Document } from '../../../supplier-documents/models/document';

export interface BrokerItem {
  brokerName: string;
  ebn: string;
  brokerContact: string;
  status: string;
  associatedOn: string;
  statusColor?: string;
  document?: Document;
}

export enum BrokerGridRowType {
  Headquarter,
  NonHeadquarter,
  BrokerOfRecordForm,
}

export type BrokerGridRow = Omit<BrokerItem, 'status' | 'statusColor'> & {
  type: BrokerGridRowType;
  tag: ITag;
};

export interface BrokerDropdownItem {
  ebn: string;
  name: string;
}
