<div class="px-4">
  <div class="row">
    <div class="col">
      <h4>Brands</h4>
    </div>
  </div>
  <div class="row kehe-data-section">
    <div class="col-12 my-2">
      <div class="row mb-3">
        <div class="col-12 kehe-data-value">
          We are working towards bringing GBB Score into CONNECT Enterprise, till then view GBB Score in
          <a (click)="goToVendorBrandScoreCardV1()"> KeHE CONNECT.</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3 mb-2">
    <div class="col-md-5 col-lg-6 mb-2 mb-md-0">
      <!--Chips goes here-->
    </div>
    <div class="col-md-7 col-lg-6 text-end">
      <div class="list-search-filter">
        <kehe-phoenix-search placeholder="Search by Brand or Brand Code" [formControl]="searchControl">
        </kehe-phoenix-search>
      </div>
    </div>
  </div>
  <div>
    <div class="kehe-grid no-scroll p-0 w-100">
      <kendo-grid [data]="brands" [sortable]="{
                allowUnsort: false,
                mode: 'single'
              }" [sort]="sort" (sortChange)="sortChange($event)">

        <kendo-grid-column field="name" title="Brand" filter="string">
        </kendo-grid-column>
        <kendo-grid-column field="code" title="Brand Code" filter="string">
        </kendo-grid-column>

        <ng-template kendoGridNoRecordsTemplate>
          <div class="custom-no-results-msg">
            <em class="fas fa-exclamation-triangle" aria-hidden="true"></em>
            <div class="custom-msg-container">
              <span class="no-results">No Brands</span>
            </div>
          </div>
        </ng-template>

      </kendo-grid>
    </div>
  </div>
</div>