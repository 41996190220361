import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectIsFeatureFlagOn, selectHaveFeatureFlagsBeenFetched } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag';

@Injectable({
  providedIn: 'root',
})
export class BrokerV2Guard  {
  constructor(private router: Router, private store: Store) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(selectHaveFeatureFlagsBeenFetched).pipe(
      withLatestFrom(
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.BrokerV2.key)),
      ),
      filter(([flagsFetched]) => flagsFetched),
      map(([, isActive]) =>
        isActive
          ? isActive
          : this.router.parseUrl('/unauthorized')
      )
    );
  }
}
