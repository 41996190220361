import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  clearUserNotificationsAction,
  clearUserNotificationsSuccessAction,
  getUserNotificationsAction,
  getUserNotificationsSuccessAction,
  userNotificationsErrorAction
} from './user-notifications.actions';
import { UserNotificationsService } from '../service/user-notifications.service';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsEffects {

  constructor(
    private actions$: Actions,
    private notificationsService: UserNotificationsService
  ) {}

  
  getUserNotifications$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(getUserNotificationsAction),
    exhaustMap(() => this.notificationsService.getUserNotifications().pipe(
      map(response => getUserNotificationsSuccessAction({ response }))
    ))
  ));

  
  routerNavigated$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    map(() => getUserNotificationsAction())
  ));

  
  getUserNotificationsInterval$: Observable<Action> = createEffect(() => interval(300000).pipe(
    map(() => getUserNotificationsAction())
  ));

  
  clearUserNotifications$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(clearUserNotificationsAction),
    switchMap(({ notificationIds }) => this.notificationsService.clearUserNotifications(notificationIds).pipe(
      map(() => clearUserNotificationsSuccessAction({ notificationIds })),
      catchError((err: HttpErrorResponse) => of(userNotificationsErrorAction({ err })))
    ))
  ));
}
