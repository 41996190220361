import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Constants } from '../../../constants/constants';
import { selectDoesUserHaveAction, selectUserActions } from '@app/shared/state/authentication/authentication.selectors';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ShowManagementGuard  {

    constructor(
        private store: Store,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.select(selectUserActions).pipe(
            filter((userActions) => userActions?.length > 0),
            withLatestFrom(this.store.select(selectDoesUserHaveAction(Constants.UserActions.ShowRead))),
            map(([userActions, canSeeShows]) => canSeeShows ? canSeeShows : this.router.parseUrl(''))
        )
    }
}
