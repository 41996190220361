import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IAnnouncementsRecentLiveCount } from '../interfaces/announcements-recent-live-count.interface';
import { IRecentAnnouncement } from '../interfaces/recent-announcement.interface';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AnnouncementsRecentService {
  private _url = `${environment.announcementsApi}/enterprise`;

  constructor(private http: HttpClient) {
  }

  getRecentLiveCount(): Observable<IAnnouncementsRecentLiveCount> {
    return this.http.get<IAnnouncementsRecentLiveCount>(`${this._url}/recent-live-count`);
  }

  getRecentLiveAnnouncements(): Observable<IRecentAnnouncement[]> {
    return this.http.get<IRecentAnnouncement[]>(`${this._url}/recent-live-announcements`);
  }
}
