import { Address } from "@kehe/phoenix-address";
import { Contact } from "../../../models/contact";

export function formattedAddress(address: Address): string {
    if (!address) {
      return;
    }
    const { name, street1, state, zipCode, city, province } = address;
    if (!address) {
      return;
    }
    let addressParts = [address?.country?.name, name, street1];
  
    if (state) {
      const section =
        city && state.code && zipCode
          ? `${city} ${state.code}, ${zipCode}`
          : null;
      addressParts = [...addressParts, section];
    } else {
      const citySection = city && province && zipCode ? `${city}` : null;
      const provinceSection =
        province && zipCode ? `${province}, ${zipCode}` : null;
      addressParts = [...addressParts, citySection, provinceSection];
    }
    const addressString = addressParts
      .filter(
        (section) =>
          section !== null && section !== undefined && section.length > 0
      )
      .join('\n');
    return addressString;
  }
  
  export function formattedContact(contact: Contact): string {
    let contactParts = [];
    contactParts.push(contact.name);
    if (contact.phone) {
      if (contact.phoneExtension) {
        contactParts.push(`P: ${contact.phone} EXT: ${contact.phoneExtension}`);
      } else {
        contactParts.push(`P: ${contact.phone}`);
      }
    }
    if (contact.fax) {
      contactParts.push(`F: ${contact.fax}`);
    }
    if (contact.email) {
      contactParts.push(`E: ${contact.email}`);
    }
    contactParts = contactParts.filter(
      (a) => a !== null && a !== undefined && a.length > 0
    );
    if (contactParts.length > 0) {
      const contactString = contactParts.join('\n');
      return contactString;
    } else {
      return null;
    }
  }

  export const pickupContactMap = {
    pickupPhone: 'phone',
    pickupExtension: 'phoneExtension',
    pickupFax: 'fax',
    pickupEmail: 'email'
  };
  