import { Constants } from './../../constants/constants';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { Component, OnDestroy, AfterViewInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { GlobalService } from './../../services/global.service';
import { Subject } from 'rxjs';
import { GaService } from '../../services/ga.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnDestroy, AfterViewInit {
  env: any = environment;

  private unsubscribe: Subject<boolean> = new Subject();
  _documentsUrl: string;
  // _insightsUrl: string;
  // _suggestedOrderUrl: string;

  viewAnnouncementsFeatureFlag$ = this._store.select(
    selectIsFeatureFlagOn(FeatureFlags.ViewAnnouncements.key)
  );

  viewWorkQueueBannerFeatureFlag$ = this._store.select(
    selectIsFeatureFlagOn(FeatureFlags.WorkQueueFlag.key)
  );

  public canShowWorkQueueBanner$ = this._store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.WorkQueueRead));

  constructor(
    private gaService: GaService,
    private globalService: GlobalService,
    private router: Router,
    private _store: Store,
  ) {
    this._documentsUrl = environment.documentsAndDownloads;
    // this._insightsUrl = environment.insights;
    // this._suggestedOrderUrl = environment.suggestedOrder;
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.globalService.searchClearText = true;
    }, 100);
  }

  navigate(url: string) {
    window.open(url, '_blank');
  }

  // TODO :: update to use navigate method to open in new tab
  navigateToResourcesLib() {
    this.router.navigateByUrl('resources/view');
  }

  handleClickVideo() {
    this.gaService.emit(
      'show',
      `hear-from-kehe-video`,
      `hear-from-kehe-video`,
      1
    );
  }
}
