import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class SupplierDevManagerService {

  private _url: string;

  constructor(
    private _httpClient: HttpClient
  ) {
    this._url = environment.supplierApi;
  }

  getSupplierDevelopmentManagers(): Observable<any[]> {
    return this._httpClient
      .get<{ availableCount: number; data: any[] }>(
        `${this._url}/api/development-managers`
      )
      .pipe(map((response) => response.data))
      .pipe(retry(2));
  }

}
