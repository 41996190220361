/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { ComponentOverlayRef } from '../components/overlay-ref/component-overlay-ref';

export const InitialOrderModalData = {};

interface PortalDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: any;
}

const DEFAULT_CONFIG: PortalDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'order-initial-dialog-panel'
};

@Injectable({
  providedIn: 'root'
})
export class ModalWorkflowService {

  public initialOrderModalSubject: Subject<void> = new Subject();
  public triggerInitialOrderModal$: Observable<void>;
  public newOrderModalSubject: Subject<void> = new Subject();
  public triggerNewOrderModal$: Observable<void>;
  public uploadFileModalSubject: Subject<void> = new Subject();
  public triggerUploadFileModal$: Observable<void>;
  public orderInitialDialogRef: ComponentOverlayRef;

  constructor(private overlay: Overlay, private injector: Injector) {
    this.triggerNewOrderModal$ = this.newOrderModalSubject.asObservable();
    this.triggerUploadFileModal$ = this.uploadFileModalSubject.asObservable();
    this.triggerInitialOrderModal$ = this.initialOrderModalSubject.asObservable();
  }

  openSmartComponent<T>(component: ComponentType<T>, config: PortalDialogConfig = {}): ComponentOverlayRef {
    // Override default configuration
    const dialogConfig: PortalDialogConfig = { ...DEFAULT_CONFIG, ...config };
    // returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);
    const dialogRef = new ComponentOverlayRef(overlayRef);
    // Create a ComponentPortal from a dynamic Component, this will be attached to PortalHost
    const orderInitialModal = this.attachDialogContainer<T>(overlayRef, dialogConfig, dialogRef, component);
    overlayRef.backdropClick().subscribe(() => overlayRef.dispose());
    this.orderInitialDialogRef = dialogRef; // get a better name
    return dialogRef;
  }

  private getOverlayConfig(config: PortalDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop, backdropClass: config.backdropClass,
      panelClass: config.panelClass, scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
  }
  private createOverlay(config: PortalDialogConfig): OverlayRef {
    // returns the getOverlayConfig
    const overlayConfig = this.getOverlayConfig(config);
    // and returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }

  private createInjector(config: PortalDialogConfig, dialogRef: ComponentOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    // custom injection tokens
    injectionTokens.set(ComponentOverlayRef, dialogRef);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private attachDialogContainer<T>(overlayRef: OverlayRef
    , config: PortalDialogConfig
    , dialogRef: ComponentOverlayRef
    , component: ComponentType<T>) {
    const injector = this.createInjector(config, dialogRef);
    const containerPortal = new ComponentPortal(component, null, injector);
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }
}
