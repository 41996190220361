import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Constants } from '../../../../constants/constants';

@Component({
  selector: 'app-bulk-edit-pickup-contact',
  templateUrl: './bulk-edit-pickup-contact.component.html',
  styleUrls: []
})
export class BulkEditPickupContactComponent {
  readonly mask = Constants.ExtensionTextMask;

  @Input()
  form: UntypedFormGroup;

  get pickupPhoneField() {
    return this.form.get('phone') as UntypedFormGroup;
  }

  get pickupEmail() {
    return this.form.get('email');
  }
}
