import { Component, OnInit } from '@angular/core';
import {
  IKeheModalEvent,
  KeheModalEventsTypes,
} from '@kehe/phoenix-modal';
import { take, takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../../../abstract/destroyable';
import { Contact } from '../../../../../../models/contact';
import { AddDeliveredContactStore } from '../add-delivered-contact/add-delivered-contact.store';

interface KeyValueData {
  key: string;
  value: string;
}

@Component({
  selector: 'app-add-contact-preview',
  templateUrl: './add-contact-preview.component.html',
  styleUrls: ['./add-contact-preview.component.scss'],
})
export class AddContactPreviewComponent extends Destroyable implements OnInit {
  disableUseAddress = false;
  isAddingAddress = false;
  dataItems: KeyValueData[] = [];
  supplierContactErrorMessage: string;
  get errorType() {
    if (
      this.supplierContactErrorMessage ===
      'We were unable to verify the address. Please review the address below.'
    ) {
      return 'warning';
    }
    return 'error';
  }
  constructor(private _addDeliveredContactStore: AddDeliveredContactStore) {
    super();
  }

  ngOnInit() {
    this._addDeliveredContactStore.getSupplierContactPreviewErrorMessage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val) {
          this.supplierContactErrorMessage = val;
        }
      });
    this._addDeliveredContactStore.selectPickupAddressContact$
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((val) => this.updateDataItems(val));
    this._addDeliveredContactStore.selectIsAddAddressInProgress$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.isAddingAddress = val;
        this.disableUseAddress = val;
      });
  }

  updateDataItems(contact: Contact) {
    this.dataItems = [];
    if (!contact) {
      return;
    }
    this.dataItems.push({
      key: 'Country:',
      value: contact.address.country.name,
    });
    this.dataItems.push({
      key: 'Name:',
      value: contact.address.name,
    });
    this.dataItems.push({
      key: 'Address 1:',
      value: contact.address.street1,
    });
    this.dataItems.push({
      key: 'Address 2:',
      value: '--',
    });
    this.dataItems.push({
      key: 'City:',
      value: contact.address.city,
    });
    if (contact.address.state) {
      this.dataItems.push({
        key: 'State:',
        value: contact.address.state.name,
      });
    } else {
      this.dataItems.push({
        key: 'Province:',
        value: contact.address.province,
      });
    }
    this.dataItems.push({
      key: 'Zip:',
      value: contact.address.zipCode,
    });
  }

  handleAddContactModalEvent(event: IKeheModalEvent) {
    if (event.type === KeheModalEventsTypes.Close) {
      this._addDeliveredContactStore.closeModal();
    }
  }

  handleEditAddress() {
    this._addDeliveredContactStore.editPickupAddressContact();
  }

  handleUseClicked() {
    this._addDeliveredContactStore.usePickupAddressContact();
  }
}
