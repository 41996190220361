import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { navigatedAwayFromCredits } from '../list/store/actions';
import { CreditListPageComponent } from '../list/pages/credit-list-page/credit-list-page.component';

@Injectable({
  providedIn: 'root'
})
export class ClearCreditListGuard  {

  constructor(
    private _store: Store
  ) {
  }

  canDeactivate(component: CreditListPageComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!nextState.url.startsWith('/credits')) {
      this._store.dispatch(navigatedAwayFromCredits());
    }
    return true;
  }
}
