import { createReducer, on, Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

import { IAnnouncement } from '../interfaces/announcement.interface';
import { IAnnouncementDetails } from '../../interfaces/announcement-details.inteface';
import { IAnnouncementDocument } from '../../interfaces/announcement-document.interface';
import { IAnnouncementListPagination } from '../interfaces/announcement-list.interface';
import {
  ICreateDocuments,
  IDocumentUpload,
  IUploadRestrictions
} from '../interfaces/upload-documents.interface';
import { AnnouncementStatus } from '../../enums/announcement-status.enum';
import { AnnouncementAudience } from '../../enums/announcement-audience.enum';
import {
  cancelPendingDelete,
  confirmPendingDelete,
  create,
  fetchList,
  resetPagination,
  setAudience,
  setPagination,
  setSearch,
  setSorting,
  setStatus,
  deleteAnnouncement,
  closeAddModalClicked
} from '../pages/announcement-list-page/announcement-list-page.actions';
import {
  announcementPublishError,
  announcementPublishSuccess,
  createDocumentsError,
  createDocumentsSuccess,
  createError,
  createSuccess,
  deleteAnnouncementSuccess,
  deleteFileError,
  deleteFileSuccess,
  fetchListError,
  fetchListSuccess,
  fetchPendingDeleteDetailsError,
  fetchPendingDeleteDetailsSuccess,
  getAnnouncementError,
  getAnnouncementSuccess,
  getUploadRestrictionsError,
  getUploadRestrictionsSuccess,
  updateError,
  updateSuccess,
  updateDocumentUploads,
  uploadDocumentsComplete,
  fileUploadsComplete,
  fileUploadsError,
  getAnnouncement
} from './announcements.actions';
import {
  announcementEditPageReset,
  cancelPendingDeleteFile,
  closeUploadFilesModal,
  confirmPendingDeleteFile,
  createDocuments,
  deleteFile,
  getUploadRestrictions,
  openUploadFilesModal,
  update
} from '../pages/announcement-edit-page/announcement-edit-page.action';
import {
  announcementViewPageLeft,
  announcementPublish
} from '../pages/announcement-view-page/announcement-view-page.action';
import {
  moveCompleteUploadsToAnnouncement,
  removeDocumentFromAnnouncementDetails,
  updateDocumentUploadsStatuses
} from '../utils/document-uploads';

export class AnnouncementsState {
  data: IAnnouncement[];
  loading: boolean;
  addAnnouncementLoading: boolean;
  addAnnouncementError: string;
  error: boolean;
  audience: AnnouncementAudience;
  status: AnnouncementStatus;
  sorting: SortDescriptor[];
  search: string | null;
  pagination: IAnnouncementListPagination;
  total: number;
  announcementDetails: IAnnouncementDetails | null;
  isPublishing: boolean;
  publishError: string | null;
  toastMessage: string;
  uploadRestrictions: IUploadRestrictions | null;
  uploadRestrictionsLoading: boolean;
  showUploadFilesModal: boolean;
  createDocumentsData: ICreateDocuments | null;
  createDocumentsLoading: boolean;
  createDocumentsError: string | null;
  fileUploads: IDocumentUpload[] | null;
  fileUploadsError: string | null;
  documentUploads: IDocumentUpload[];
  showDeleteConfirmation: boolean;
  pendingDeleteDetails: IAnnouncementDetails | null;
  pendingDeleteLoading: boolean;
  pendingDeleteError: boolean;
  pendingDeleteConfirmed: boolean;
  updateAnnouncementError: string;
  showDeleteFileConfirmation: boolean;
  pendingDeleteFileData: IAnnouncementDocument | null;
  pendingDeleteFileLoading: boolean;
  pendingDeleteFileError: string | null;
}

export const initialState: AnnouncementsState = {
  data: [],
  loading: false,
  addAnnouncementLoading: false,
  addAnnouncementError: null,
  error: false,
  audience: AnnouncementAudience.Enterprise,
  status: AnnouncementStatus.Draft,
  sorting: [{
    dir: 'desc',
    field: 'startDate'
  }],
  search: null,
  pagination: {
    offset: 0,
    limit: 25
  },
  total: 0,
  announcementDetails: null,
  isPublishing: false,
  publishError: null,
  toastMessage: '',
  uploadRestrictions: null,
  uploadRestrictionsLoading: false,
  showUploadFilesModal: false,
  createDocumentsData: null,
  createDocumentsLoading: false,
  createDocumentsError: null,
  fileUploads: null,
  fileUploadsError: null,
  documentUploads: [],
  showDeleteConfirmation: false,
  pendingDeleteDetails: null,
  pendingDeleteLoading: false,
  pendingDeleteError: false,
  pendingDeleteConfirmed: false,
  updateAnnouncementError: null,
  showDeleteFileConfirmation: false,
  pendingDeleteFileData: null,
  pendingDeleteFileLoading: false,
  pendingDeleteFileError: null
};

const rootReducer = createReducer(
  initialState,

  on(fetchList, state => ({
    ...state,
    data: [],
    loading: true,
    error: false
  })),

  on(fetchListSuccess, (state, action) => ({
    ...state,
    loading: false,
    data: action.response.data,
    total: action.response.availableCount
  })),

  on(fetchListError, state => ({
    ...state,
    loading: false,
    error: true
  })),

  on(setAudience, (state, action) => ({
    ...state,
    audience: action.value
  })),

  on(setStatus, (state, action) => ({
    ...state,
    status: action.value
  })),

  on(setSorting, (state, action) => ({
    ...state,
    sorting: action.value
  })),

  on(setSearch, (state, action) => ({
    ...state,
    search: action.value
  })),

  on(setPagination, (state, action) => ({
    ...state,
    pagination: action.value
  })),

  on(resetPagination, state => ({
    ...state,
    pagination: {
      offset: 0,
      limit: 25
    }
  })),

  on(create, state => ({
    ...state,
    addAnnouncementLoading: true,
    addAnnouncementError: initialState.addAnnouncementError
  })),

  on(createSuccess, state => ({
    ...state,
    addAnnouncementLoading: false,
    toastMessage: 'Announcement created successfully!'
  })),

  on(createError, (state, action) => ({
    ...state,
    addAnnouncementError: action.error,
    addAnnouncementLoading: false
  })),

  on(closeAddModalClicked, state => ({
    ...state,
    addAnnouncementError: null,
    addAnnouncementLoading: false
  })),

  on(getAnnouncement, state => ({
    ...state,
    loading: true
  })),

  on(getAnnouncementSuccess, (state, action) => ({
    ...state,
    announcementDetails: action.data,
    loading: false,
    toastMessage: ''
  })),

  on(getAnnouncementError, state => ({
    ...state,
    announcementDetails: null,
    loading: false,
    toastMessage: ''
  })),

  on(update, state => ({
    ...state,
    loading: true
  })),

  on(updateSuccess, state => ({
    ...state,
    announcementDetails: null,
    loading: false,
    toastMessage: 'Announcement saved successfully!',
    updateAnnouncementError: null
  })),

  on(updateError, (state, action) => ({
    ...state,
    loading: false,
    updateAnnouncementError: action.error
  })),

  on(announcementEditPageReset, announcementViewPageLeft, state => ({
    ...state,
    loading: false,
    announcementDetails: null,
    updateAnnouncementError: null,
    isPublishing: false,
    publishError: null,
    fileUploads: null,
    documentUploads: []
  })),

  on(announcementPublish, state => ({
    ...state,
    isPublishing: true,
    publishError: null
  })),

  on(announcementPublishSuccess, (state, action) => ({
    ...state,
    announcementDetails: action.response,
    isPublishing: false
  })),

  on(announcementPublishError, (state, action) => ({
    ...state,
    isPublishing: false,
    publishError: action.error
  })),

  on(deleteAnnouncement, state => ({
    ...state,
    showDeleteConfirmation: true,
    pendingDeleteDetails: null,
    pendingDeleteLoading: true,
    pendingDeleteError: false,
    pendingDeleteConfirmed: false
  })),

  on(fetchPendingDeleteDetailsSuccess, (state, action) => ({
    ...state,
    pendingDeleteLoading: false,
    pendingDeleteDetails: action.response
  })),

  on(fetchPendingDeleteDetailsError, state => ({
    ...state,
    pendingDeleteLoading: false,
    pendingDeleteError: true
  })),

  on(confirmPendingDelete, state => ({
    ...state,
    pendingDeleteConfirmed: true
  })),

  on(cancelPendingDelete, deleteAnnouncementSuccess, state => ({
    ...state,
    showDeleteConfirmation: false
  })),

  on(getUploadRestrictions, state => ({
    ...state,
    uploadRestrictions: null,
    uploadRestrictionsLoading: true
  })),

  on(getUploadRestrictionsSuccess, (state, action) => ({
    ...state,
    uploadRestrictions: action.data,
    uploadRestrictionsLoading: false
  })),

  on(getUploadRestrictionsError, state => ({
    ...state,
    uploadRestrictionsLoading: false
  })),

  on(openUploadFilesModal, state => ({
    ...state,
    showUploadFilesModal: true,
    createDocumentsData: null,
    createDocumentsLoading: false,
    createDocumentsError: null,
    fileUploads: null,
    fileUploadsError: null
  })),

  on(closeUploadFilesModal, state => ({
    ...state,
    showUploadFilesModal: false
  })),

  on(createDocuments, (state, action) => ({
    ...state,
    createDocumentsData: action.data,
    createDocumentsLoading: true,
    createDocumentsError: null,
    fileUploads: null,
    fileUploadsError: null
  })),

  on(createDocumentsSuccess, (state, action) => ({
    ...state,
    createDocumentsLoading: false,
    fileUploads: action.uploads
  })),

  on(createDocumentsError, (state, action) => ({
    ...state,
    createDocumentsLoading: false,
    createDocumentsError: action.error,
    fileUploads: null
  })),

  on(fileUploadsComplete, state => ({
    ...state,
    showUploadFilesModal: false,
    fileUploads: null,
    documentUploads: [ ...state.fileUploads, ...state.documentUploads ]
  })),

  on(fileUploadsError, (state, action) => ({
    ...state,
    fileUploads: null,
    fileUploadsError: action.error
  })),

  on(updateDocumentUploads, (state, action) => ({
    ...state,
    documentUploads: updateDocumentUploadsStatuses(state.documentUploads, action.response)
  })),

  on(uploadDocumentsComplete, (state, action) => ({
    ...state,
    ...moveCompleteUploadsToAnnouncement(state.documentUploads, action.response, state.announcementDetails)
  })),

  on(deleteFile, (state, action) => ({
    ...state,
    showDeleteFileConfirmation: true,
    pendingDeleteFileData: action.data,
    pendingDeleteFileLoading: false,
    pendingDeleteFileError: null
  })),

  on(confirmPendingDeleteFile, state => ({
    ...state,
    pendingDeleteFileLoading: true
  })),

  on(cancelPendingDeleteFile, state => ({
    ...state,
    showDeleteFileConfirmation: false
  })),

  on(deleteFileSuccess, state => ({
    ...state,
    announcementDetails: removeDocumentFromAnnouncementDetails(state.announcementDetails, state.pendingDeleteFileData),
    pendingDeleteFileData: null,
    showDeleteFileConfirmation: false,
    pendingDeleteFileLoading: false
  })),

  on(deleteFileError, (state, action) => ({
    ...state,
    pendingDeleteFileLoading: false,
    pendingDeleteFileError: action.error
  })),
);

export function reducer(state: AnnouncementsState | undefined, action: Action) {
  return rootReducer(state, action);
}

export const announcementsFeatureKey = 'announcements';
