import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { SupplierDetailTabs } from '../../models/supplier-detail-tabs';
import { hideNotesModal } from '../../store/supplier-detail.actions';
import {
  selectSaveInProgress,
  selectTermsContractDate,
  selectTermsFormContractDate
} from '../../store/supplier-detail.selectors';
import { Constants } from '../../../../constants/constants';
import { combineLatest } from 'rxjs';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent extends Destroyable implements OnInit  {

  readonly alertTypes = AlertTypes;

  public changeTypes: Array<{ text: string; value: number; hasContractDate: boolean; alert?: string; }> = Constants.SupplierChangeTypes;

  get selectedChangeType() {
    return this.form.get('changeType').value;
  }

  @Input()
  currentTab: string;

  form: UntypedFormGroup;

  public isSaveInProgress = false;
  public formContractDate: any;
  public stateContractDate: any;
  public showDatePicker = false;

  @Output()
  public modalSaveClicked: EventEmitter<any> = new EventEmitter();

  constructor(
    private _store: Store,
    private _formBuilder: UntypedFormBuilder ) {
    super();
  }

  ngOnInit() {
    this.buildForm();
    this._store.pipe(
      select(selectSaveInProgress),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.isSaveInProgress = val;
      });

    if (this.currentTab ===  SupplierDetailTabs.supplierTerms) {
      combineLatest([
        this._store.select(selectTermsContractDate),
        this._store.select(selectTermsFormContractDate),
      ])
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe(([termsContractDate, termsFormContractDate]) => {
          this.stateContractDate = termsContractDate;
          this.formContractDate = termsFormContractDate;
          if (this.formContractDate != null && this.stateContractDate !== this.formContractDate) {
            this.form.get('contractDate').setValue(this.formContractDate);
          }
        });
    }
  }

  private buildForm() {
    this.form = this._formBuilder.group({
      changeType: [null, this.isTerms() ? [Validators.required] : []],
      contractDate: [],
      ticket: [],
      notes: ['', [this.forbiddenStringValidator()]],
    });

    this.form.get('changeType').valueChanges.subscribe(value => {
      if (value && value.hasContractDate) {
        this.form.get('contractDate').setValidators([Validators.required]);
      } else {
        this.form.get('contractDate').setValidators([]);
        this.form.get('contractDate').setValue(null, { onlySelf: true, emitEvent: false });
      }
      this.form.get('contractDate').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
  }

  forbiddenStringValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = control.value && control.value.trim().length > 0;
      return valid ? null : {invalid: {value: control.value}};
    };
  }

  public save() {
    if (!this.form.valid) {
      return;
    }
    const request = {
      ...this.form.value,
      currentTab: this.currentTab,
      changeType : this.form.value.changeType ? this.form.value.changeType.value : null,
    };
    this.modalSaveClicked.emit(request);
  }

  cancelModal() {
    this._store.dispatch(hideNotesModal());
  }

  handleKeheModalEvents(e) {
    if (e.type === 'close') {
      this.cancelModal();
    }
  }

  public isTerms() {
    return this.currentTab === SupplierDetailTabs.supplierTerms;
  }

  public onFocus(element: any): void {
    element.toggle(true);
  }
}
