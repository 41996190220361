import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  AssociatedUserGroupRecord,
  CodeValueRecord,
  ListRequest,
  UserCustomerRecord,
  UserDetailListItem,
  UserDetailRecord,
  UserManagementListRequest,
  UserManagementRecord,
  UserSupplierRecord
} from '../model/service/user-management';
import {
  UserManagementFilterType,
  UserStatus,
  UserType
} from '../model/user-management';
import { Payload } from '../../../models/payload';
import { retryWhen, take, delay } from 'rxjs/operators';
import { Filter } from '@app/models/filter';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getUserManagementListDropDown(search: string, excludeGroups: number[]) {
    let params = new HttpParams();
    params = params.set('search', search);
    excludeGroups.forEach((groupId) => {
      params = params.append('NotInGroupIds', groupId);
    });

    return this.httpClient.get<Payload<UserDetailListItem[]>>(
        `${environment.apiGateway}/users/users-list`, {
        params
      }
    );
  }

  getUserManagementList(request: UserManagementListRequest): Observable<Payload<UserManagementRecord[]>> {
    return this.httpClient.get<Payload<UserManagementRecord[]>>(
        `${environment.apiGateway}/users/list`, {
        params: this.mapRequestParams(request)
      }
    );
  }

  getSuggestiveSearch(type: UserManagementFilterType, searchText: string): Observable<Payload<CodeValueRecord[]>> {
    const url = `${environment.apiGateway}/users/${type}`;
    const params = !!searchText && searchText !== '' ? { query: searchText } : undefined;
    return this.httpClient.get<Payload<CodeValueRecord[]>>(url, {
      params: params
    });
  }

  getUserDetails(email: string): Observable<Payload<UserDetailRecord>> {
    return this.httpClient.get<Payload<UserDetailRecord>>(`${environment.apiGateway}/users/${email}/detail`).pipe(
      // retry 3 times and wait 1000ms before each retry
      retryWhen(errors => {
        return errors.pipe(delay(1000), take(3));
      })
    );
  }

  createUser(type: UserType, email: string, created: string, status: UserStatus): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>(
    `${environment.apiGateway}/users/create`,
    { type, email, created, status }
    );
  }

  updateUser(request): Observable<UserDetailRecord> {
    return this.httpClient.patch<UserDetailRecord>(`${environment.apiGateway}/users/update`, request);
  }

  updateMultipleUsersAssociatedGroups(emails: string[], group: number) {
    return this.httpClient.post(`${environment.apiGateway}/users/emails/addgroup`, {
      groupId: group,
      emails,
    });
  }

  updateAssociatedGroups(email: string, groupIds: number[]): Observable<any> {
    return this.httpClient.put<UserDetailRecord>(`${environment.apiGateway}/users/${email}/groups`, groupIds);
  }

  removeAssociatedGroup(email: string, group: string) {
    return this.httpClient.delete<UserDetailRecord>(`${environment.apiGateway}/users/${email}/group`, {
      body: `"${group}"`,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getAssociatedCustomers(email: string, listOptions: ListRequest): Observable<Payload<UserCustomerRecord[]>> {
    return this.httpClient.get<Payload<UserCustomerRecord[]>>(`${environment.apiGateway}/users/${email}/customers`, {
      params: this.mapRequestParams(listOptions)
    });
  }

  getAssociatedSuppliers(email: string, listOptions: ListRequest): Observable<Payload<UserSupplierRecord[]>> {
    return this.httpClient.get<Payload<UserSupplierRecord[]>>(`${environment.apiGateway}/users/${email}/suppliers`, {
      params: this.mapRequestParams(listOptions)
    });
  }

  getAllUserGroups(email: string): Observable<Payload<AssociatedUserGroupRecord[]>> {
    return this.httpClient.get<Payload<AssociatedUserGroupRecord[]>>(`${environment.apiGateway}/users/${email}/groups`);
  }

  getUserList(filter: Filter): Observable<Payload<UserDetailRecord[]>> {
    return this.httpClient.get<Payload<UserDetailRecord[]>>(
      `${environment.apiGateway}/users/users-list`,
      {
        params: this.mapRequestParams(filter)
      }
    );
  }

  public getCustomer(searchTerm: string): Observable<Payload<{ data: [{masterAccountNumber  }] }>> {
    let params = new HttpParams();
    params = params.set('search', searchTerm);
    return this.httpClient.get<Payload<{ data: [{masterAccountNumber  }] }>>(
      `${environment.customerEnterprise}/api/list`,
      { params },
    );
  }

  private mapRequestParams(request: UserManagementListRequest | ListRequest | Filter): HttpParams {
    let params = new HttpParams();
    Object.entries(request)
      .filter(([_, value]) => value !== null && value !== undefined && value !== '' && value.length !== 0)
      .forEach(([key, _]) => {
        const value = request[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item);
          });
        } else {
          params = params.append(key, value);
        }
      });
    return params;
  }
}
