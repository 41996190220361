import { Constants } from '../../../constants/constants';
import { OnboardingTabStatuses } from '../../../models/onboarding-tab-statuses';
import { Document } from '../models/document';
import { DocumentStatus } from '../models/document-statuses';
import { DocumentType } from '../models/document-type';
import { SupplierData } from '../models/supplier-data';

export function isReadOnlyDocument(document: Document, types: DocumentType[]) {
  if (document.typeId === Constants.W9DocumentTypeId || document.typeId === Constants.PurchasingInfoDocumentTypeId || document.typeId === Constants.RetailerRequestedDetailsDocumentTypeId) {
    return true;
  }
  const type = types.find(item => item.id === document.typeId);
  if (type) {
    return type.isDocuSign;
  }
  return false;
}

export function readBase64(file): Promise<any> {
  const reader = new FileReader();
  const future = new Promise((resolve, reject) => {
    reader.addEventListener('load', function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener('error', function (event) {
      reject(event);
    }, false);
    reader.readAsDataURL(file);
  });
  return future;
}

export function requiredDocumentsForDocumentsTab(documentTypes: DocumentType[]): DocumentType[] {
  if (!documentTypes) {
    return [];
  }
  return documentTypes.filter(item => item.required && !item.isDocuSign && !Constants.SpecialDocIds.includes(item.id));
}

export function legalDocumentsForDocumentsTab(documentTypes: DocumentType[], supplier: SupplierData): DocumentType[] {
  if (!documentTypes) {
    return [];
  }
  const isStandardSupplier = supplier?.supplierType === 'Standard';
  const isKrogerSupplier = supplier?.supplierType === 'KrogerPrivateLabel';

  const hasTermsFormExemption = (isStandardSupplier || isKrogerSupplier) && supplier?.addendumAsBase64String?.length > 0;

  const hasPoliciesProceduresAddendum = (!isStandardSupplier && !isKrogerSupplier) && supplier?.addendumAsBase64String?.length > 0;

  const hasSupplierCodeOfConduct = !isStandardSupplier && !isKrogerSupplier;

  let legalDocs = documentTypes.filter(item => item.isDocuSign || Constants.SpecialDocIds.includes(item.id));
  if (!hasTermsFormExemption) {
    legalDocs = legalDocs.filter(item => item.id !== Constants.TermsExemptionFormDocumentTypeId);
  }
  else {
    legalDocs = legalDocs.filter(item => item.id !== Constants.PoliciesProceduresAddendumTypeId);
  }
  if (!hasPoliciesProceduresAddendum) {
    legalDocs = legalDocs.filter(item => item.id !== Constants.PoliciesProceduresAddendumDocumentTypeId);
  }
  if (!hasSupplierCodeOfConduct) {
    legalDocs = legalDocs.filter(item => item.id !== Constants.SupplierCodeOfConductDocumentTypeId);
  }
  return legalDocs;
}

export function documentsTabLiveStatus(documentTypes: DocumentType[], documents: Document[]): OnboardingTabStatuses {
  const reqDocTypes = requiredDocumentsForDocumentsTab(documentTypes);
  const reqDoctypeIds = reqDocTypes.map(item => item.id);
  const uploadedDocTypeIds = documents.map(doc => doc.typeId);
  const missingDocTypeIds = reqDoctypeIds.filter(id => !uploadedDocTypeIds.includes(id));
  const reqDocs = documents.filter(doc => reqDoctypeIds.includes(doc.typeId));

  if (missingDocTypeIds.length > 0) {
    return OnboardingTabStatuses.NotSubmitted;
  }
  if (reqDocs.every(item => item.status === DocumentStatus.Approved)) {
    return OnboardingTabStatuses.Approved;
  }
  if (reqDocs.some(item => item.status === DocumentStatus.Rejected)) {
    return OnboardingTabStatuses.Rejected;
  }
  if (reqDocs.some(item => item.status === DocumentStatus.NotSubmitted)) {
    return OnboardingTabStatuses.NotSubmitted;
  }
  return OnboardingTabStatuses.InReview;
}

export function isSIFFormUploaded(documents: Document[]): boolean {
  return documents && documents.some(item => item.typeId === Constants.SupplierInfoDocumentTypeId);
}
