<div *ngIf="form" [formGroup]="form" class="body">
  <div class="broker-form-container">
    <div class="row">
      <div class="col">
        <h4>Headquarter Broker</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <kendo-label text="Do you have a headquarter broker?"></kendo-label>
          <ul class="k-radio-list ps-0 mt-0">
            <li class="k-radio-item">
              <input #hasHeadquarterBrokerYes [value]="true" class="me-0" formControlName="hasHeadquarterBroker"
                kendoRadioButton name="hasHeadquarterBroker" type="radio">
              <kendo-label class='ms-2' [for]="hasHeadquarterBrokerYes">Yes</kendo-label>
            </li>
            <li class="k-radio-item">
              <input #hasHeadquarterBrokerNo [value]="false" class="me-0" formControlName="hasHeadquarterBroker"
                kendoRadioButton name="hasHeadquarterBroker" type="radio">
              <kendo-label class='ms-2' [for]="hasHeadquarterBrokerNo">No</kendo-label>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="hasHeadquarterBroker">
      <div class="col-12 col-md-6 mb-1" formGroupName="broker">
        <kendo-formfield>
          <kendo-label [for]="brokerFormField" text="Broker"></kendo-label>
          <kendo-combobox #brokerFormField (focus)="onFocus(brokerFormField)" [data]="brokerList"
            [loading]="isLoadingBrokerSearch" [filterable]="true" [kendoDropDownFilter]="{operator: 'contains'}"
            [textField]="'title'" [valueField]="'esn'" [valuePrimitive]="true"
            (filterChange)="onBrokerSearchChanged($event)" (valueChange)="clearBrokerList($event)" formControlName="esn"
            placeholder="Search by broker name or broker number"></kendo-combobox>
          <kendo-formerror *ngIf="brokerField?.errors?.required">
            Broker is required.
          </kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-12 mt-2">
        <input type="checkbox" #isHeadquarterBrokerNotFoundField kendoCheckBox
          formControlName="isHeadquarterBrokerNotFound" />
        <kendo-label class="k-checkbox-label" [for]="isHeadquarterBrokerNotFoundField"
          text="I don’t see my headquarter broker.">
        </kendo-label>
      </div>
      <div class="col-12" *ngIf="isHeadquarterBrokerNotFound">
        <div class="row mt-4">
          <p class="col mb-0">
            Don’t see your Headquarter Broker?
            No problem! Download and complete the Broker of Record Form
            <a (click)="downloadBrokerRequestForm()">here</a>.
            Then upload it below to submit a request to KeHE.
          </p>
        </div>
        <div class="row">
          <app-documents-grid [filterDocumentTypeId]="brokerRecordDocumentTypeId" [showActionColumns]="false"
            [hasExpiry]="false">
          </app-documents-grid>
        </div>
      </div>
    </div>

    <ng-container *ngIf="showBrokerContactField">
      <div class="row" *ngIf="hasHeadquarterBroker && !isHeadquarterBrokerNotFound">
        <div class="col-12 col-md-6 mb-1 k-mt-4">
          <div class="form-group">
            <kendo-label text="Do you know your broker contact?"></kendo-label>
            <kendo-formhint class="warning-hint" *ngIf="knowsHeadquarterBrokerContact === false">Your broker will not
              receive notification about your account without a contact assigned.</kendo-formhint>
            <ul class="k-radio-list ps-0 mt-0">
              <li class="k-radio-item">
                <input #knowsBrokerContactYes [value]="true" class="me-0"
                  formControlName="knowsHeadquarterBrokerContact" kendoRadioButton name="knowsHeadquarterBrokerContact"
                  type="radio">
                <kendo-label class='ms-2' [for]="knowsBrokerContactYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #knowBrokerContactNo [value]="false" class="me-0"
                  [class.has-warning]="knowsHeadquarterBrokerContact === false"
                  formControlName="knowsHeadquarterBrokerContact" kendoRadioButton name="knowsHeadquarterBrokerContact"
                  type="radio">
                <kendo-label class='ms-2' [for]="knowBrokerContactNo">No</kendo-label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="hasHeadquarterBroker && knowsHeadquarterBrokerContact && !isHeadquarterBrokerNotFound">
        <div class="col-12 col-md-6 mb-1" formGroupName="broker">
          <kendo-formfield>
            <kendo-label [for]="brokerContactFormField" text="Broker Contact"></kendo-label>
            <kendo-combobox #brokerContactFormField (focus)="onFocus(brokerContactFormField)" [data]="contactList"
              [loading]="isLoadingContactSearch" [filterable]="true" [kendoDropDownFilter]="{operator: 'contains'}"
              [textField]="'title'" [valueField]="'email'" [valuePrimitive]="false"
              (filterChange)="onContactSearchChanged($event)" formControlName="contact"
              placeholder="Search contact by name or email"></kendo-combobox>
            <kendo-formhint>The broker contact will be copied on all email notification.</kendo-formhint>
            <kendo-formerror *ngIf="contactField?.errors?.required">
              Broker contact is required. If you don't see your broker contact, reach out to your brokerage for
              assistance.
            </kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
    </ng-container>
  </div>
</div>