import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export const optionalPickupAddressFieldsProperties = [
  'pickupPhone',
  'pickupAddress.country',
  'pickupAddress.name',
  'pickupAddress.street1',
  'pickupAddress.street2',
  'pickupAddress.city',
  'pickupAddress.state',
  'pickupAddress.zipCode',
];

export const optionalPickupAddressFields = [
  ...optionalPickupAddressFieldsProperties,
  ...optionalPickupAddressFieldsProperties.map((field) => 'pickup.' + field),
];

export function clearFormFieldValues(formField) {
  if (formField instanceof UntypedFormGroup) {
    Object.keys(formField.controls).forEach((key) => {
      const control = formField.get(key);
      clearFormFieldValues(control);
    });
  } else if (formField instanceof UntypedFormArray) {
    const formArray = formField as UntypedFormArray;
    formArray.controls.forEach((element) => {
      clearFormFieldValues(element);
    });
  } else if (formField instanceof UntypedFormControl) {
    const control = formField as UntypedFormControl;
    control.removeValidators(Validators.required);
    control.setValue(null);
    control.enable();
    control.markAsTouched();
    control.markAsDirty();
  }
}
