import { CommonModule } from '@angular/common';
import { Component, Input, EventEmitter, Output } from '@angular/core';

export enum ChecklistNavigationTabStatus {
  checked,
  unchecked,
  info,
  warning,
  locked,
}

export interface ChecklistNavigationTab {
  automationId?: string;
  completed?: boolean;
  disabled?: boolean;
  optional?: boolean;
  remainingCount: number;
  status?: ChecklistNavigationTabStatus;
  tab: string;
}

@Component({
  selector: 'app-checklist-navigation-panel',
  templateUrl: './checklist-navigation-panel.component.html',
  styleUrls: ['./checklist-navigation-panel.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ChecklistNavigationPanelComponent {

  readonly tabStatus = ChecklistNavigationTabStatus;

  @Input() activeTab: string;
  @Input() containerMarginTop: number;
  @Input() open: boolean;
  @Input() tabs: ChecklistNavigationTab[] = [];
  @Input() tabContainerWidth: number = null;
  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tabSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    // This is intentional
  }

  public togglePanel() {
    this.toggled.emit(this.open);
  }

  public setTab(tabItem: ChecklistNavigationTab) {
    const thisTab = this.tabs.find(t => t.tab === tabItem.tab);
    if (thisTab !== null && thisTab.disabled) {
      return;
    }
    this.tabSelected.emit(tabItem.tab);
  }

  hasStatus(tabItem: ChecklistNavigationTab) {
    return (tabItem.status !== null) && (tabItem.status !== undefined);
  }
}
