import { Component } from '@angular/core';
import { selectEditMode } from '@app/modules/supplier-detail/store/supplier-detail.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-dc-payment-hold-tab',
  templateUrl: './dc-payment-hold-tab.component.html',
})
export class DcPaymentHoldTabComponent {
  isEdit$ = this.store.select(selectEditMode);

  constructor(private readonly store: Store) {}
}
