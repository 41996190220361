import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { exhaustMap, filter, map, tap, withLatestFrom } from "rxjs/operators";
import * as AuthenticationActions from '@app/authentication/authentication.actions';
import * as BreadCrumbsApiActions from '@app/breadcrumbs/breadcrumbs.actions';
import * as BreadCrumbsSelectors from "@app/shared/state/breadcrumbs/breadcrumbs.selectors";
import * as AnnouncementsListActions from "@app/modules/announcements/announcements-list/announcements-list.actions";
import * as BrokerDetailPageActions from '@app/modules/broker-detail/pages/broker-detail-page/broker-detail-page.actions';
import * as ProductDetailPageActions from '@app/modules/product-detail/pages/product-detail-page/product-detail-page.actions';
import * as ProductUploadPageActions from '@app/modules/product-upload/pages/product-upload-page/product-upload-page.actions';
import * as SupplierDetailPageActions from '@app/modules/supplier-detail/pages/supplier-detail-page/supplier-detail-page.actions';
import * as SupplierMassUpdatePageActions from '@app/modules/supplier-mass-update/pages/supplier-mass-update-page/supplier-mass-update-page.actions';
import * as ProductsDataPageActions from '@app/modules/utilities/products/pages/products-data-page/products-data-page.actions';
import * as ProductPageActions from '@app/modules/product-list/pages/product-page/product-page.actions';
import * as SupplierDetailApiActions from '@app/modules/supplier-detail/store/supplier-detail.actions';
import * as SupplierPageActions from '@app/modules/supplier-list/pages/supplier-page/supplier-page.actions';
import * as SupplierMassUpdateActions from '@app/modules/supplier-mass-update/store/supplier-mass-update.actions';
import * as SupplierTransitLanesActions from '@app/modules/supplier-transit-lanes/store/supplier-transit-lanes.actions';
import * as GroupManagementActions from '@app/modules/user-group-management/store/group-management/group-management.actions';
import * as UserManagementActions from '@app/modules/user-management/store/user-management.actions';
import * as ProhibitRestictDetailActions from '@app/modules/prohibit-restrict-detail/store/prohibit-restrict-detail.actions';
import { removeKendoBreadCrumbs } from '@kehe/purchasing-options-lib';
import { BreadCrumbsService } from "./breadcrumbs.service";
import { of } from "rxjs";

@Injectable()
export class BreadcrumbsEffects {

    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private breadCrumbsService: BreadCrumbsService,
    ) {}

    getBreadCrumbs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            exhaustMap(() => of(this.breadCrumbsService.get()).pipe(
                filter(breadCrumbs => !!breadCrumbs),
                map(breadCrumbs => BreadCrumbsApiActions.getBreadCrumbsSuccess({ breadCrumbs })),
            )),
        )
    })

    navigateToRoute$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AnnouncementsListActions.clickedKendoBreadCrumbs,
                BrokerDetailPageActions.clickedKendoBreadCrumbs,
                ProductDetailPageActions.clickedKendoBreadCrumbs,
                ProductUploadPageActions.clickedKendoBreadCrumbs,
                SupplierDetailPageActions.clickedKendoBreadCrumbs,
                SupplierMassUpdatePageActions.clickedKendoBreadCrumbs,
                ProductsDataPageActions.clickedKendoBreadCrumbs,
                GroupManagementActions.clickedKendoBreadCrumbs,
                UserManagementActions.clickedKendoBreadCrumbs,
                ProhibitRestictDetailActions.clickedKendoBreadCrumbs,
                ),
            tap(action => this.router.navigate([action.item.title]))
        )
    }, { dispatch: false });

    setBreadCrumbsInLocalStorage = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                BrokerDetailPageActions.updateKendoBreadCrumbs,
                ProductDetailPageActions.updateKendoBreadCrumbs,
                ProductPageActions.updateKendoBreadCrumbs,
                ProductUploadPageActions.updateKendoBreadCrumbs,
                SupplierDetailPageActions.updateKendoBreadCrumbs,
                SupplierDetailApiActions.updateKendoBreadCrumbs,
                SupplierPageActions.updateKendoBreadCrumbs,
                ProductsDataPageActions.updateKendoBreadCrumbs,
                SupplierMassUpdateActions.updateKendoBreadCrumbsList,
                SupplierTransitLanesActions.updateKendoBreadCrumbsList,
                GroupManagementActions.updateKendoBreadCrumbsList,
                removeKendoBreadCrumbs,
            ),
            withLatestFrom(this.store.select(BreadCrumbsSelectors.selectKendoBreadCrumbs)),
            tap(([action, breadCrumbs]) => this.breadCrumbsService.set(JSON.stringify(breadCrumbs))),
        )
    }, { dispatch: false });

}
