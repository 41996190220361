// Will be replaced with Phoenix package once we are in 2.x

import { IKeheTab } from '@kehe/phoenix-tabs';
import { ComponentStore } from '@ngrx/component-store';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

export interface FilterState {
  search: string;
  pageIndex: number;
  pageSize: number;
  orderDirection: number;
  sortName: string;
  skip: number;
  tab?: IKeheTab;
}

export class FilterStore extends ComponentStore<FilterState> {
  constructor(initialState: Partial<FilterState> = {}) {
    super({
      search: '',
      pageIndex: 0,
      pageSize: 25,
      orderDirection: 0,
      sortName: '',
      skip: 0,
      tab: undefined,
      ...initialState,
    });
  }
  readonly tabState$ = this.select((state) => state);
  readonly search$ = this.select((state) => state.search);
  readonly pageIndex$ = this.select((state) => state.pageIndex);
  readonly pageSize$ = this.select((state) => state.pageSize);
  readonly sortName$ = this.select((state) => state.sortName);
  readonly orderDirection$ = this.select((state) => state.orderDirection);
  readonly skip$ = this.select((state) => state.skip);
  readonly tab$ = this.select((state) => state.tab);

  readonly vm$ = this.select(
    this.search$,
    this.pageIndex$,
    this.pageSize$,
    this.sortName$,
    this.orderDirection$,
    this.skip$,
    this.tab$,
    (search, pageIndex, pageSize, sortName, orderDirection, skip, tab) => ({
      search,
      pageIndex,
      pageSize,
      sortName,
      orderDirection,
      skip,
      tab,
    }),
  );

  updateSearch(search: string) {
    this.patchState({ search });
  }

  updateSort(sort: { sortName: string; orderDirection: number }) {
    this.patchState({
      sortName: sort.sortName,
      orderDirection: sort.orderDirection,
    });
  }

  updateSkip(skip: number) {
    this.patchState({ skip });
  }

  updateTab(tab: IKeheTab) {
    this.patchState({ tab });
  }

  updatePage(page: PageChangeEvent) {
    this.patchState({
      pageSize: page.take,
      pageIndex: page.skip / page.take,
      skip: page.skip,
    });
  }

  updatePageIndexAndSkip(pageIndex: number, skip: number) {
    this.patchState({
      pageIndex,
      skip,
    });
  }
}
