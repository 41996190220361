<div *ngIf="form" [formGroup]="form">

  <div class="row" *ngIf="inboundRouteTypeField.enabled">
    <div class="col">
      <h5>Delivery Method</h5>
    </div>
  </div>
  <div class="row mb-4" *ngIf="inboundRouteTypeField.enabled">
    <div class="col">
      <kendo-formfield showHints="always">
        <kendo-label [for]="inboundRouteTypeComponent" text="Inbound Route Type"></kendo-label>
        <kendo-combobox
          #inboundRouteTypeComponent
          (focus)="inboundRouteTypeComponent.toggle()"
          [data]="inboundRouteTypes"
          [textField]="'name'"
          [valueField]="'type'"
          [valuePrimitive]="true"
          [filterable]="true"
          [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
          formControlName="inboundRouteType"
          placeholder="Select Inbound Route Type"
        ></kendo-combobox>
        <kendo-formerror>
          Inbound Route Type is required.
        </kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  
  <div class="row">
    <div class="col">
      <h5>Lead Times</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4" formGroupName="pickupLeadTimes">
      <kendo-formfield>
        <kendo-label text="Supplier Ready Time"></kendo-label>
        <kendo-numerictextbox formControlName="readyTime" placeholder="e.g. 3" [spinners]="false" [decimals]="0"
          [format]="'0'">
        </kendo-numerictextbox>
        <kendo-formhint>Calendar days</kendo-formhint>
        <kendo-formerror *ngIf="pickUpReadyTime?.errors?.required">
          Supplier Ready Time is required.
        </kendo-formerror>
        <kendo-formerror *ngIf="pickUpReadyTime?.errors?.min || pickUpReadyTime?.errors?.max">
          Between 1 and 499
        </kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col">
      <h5>Pickup Address</h5>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-contact-form [defaultContactType]="contactTypes.PickupAddress" [form]="form.get('addressBook')"
        [hideContactType]="true">
      </app-contact-form>
    </div>
  </div>
</div>
