<kehe-phoenix-modal [show]="true"
                    modalTitle="Reject Document"
                    (modalEvent)="closeModal()"
                    size="md">
  <div body class="p-1">
    <kehe-phoenix-alert [type]="alertMessage.type" [message]="alertMessage.message"
                    *ngIf="alertMessage && alertMessage.message" [hideCloseIcon]="true" stick="true" show="true">
    </kehe-phoenix-alert>
    <p>You have chosen to reject a document.
      Add a note explaining the reason.
      This will be visible to the supplier so add as much detail as possible.
    </p>
    <div class="d-flex flex-row mb-2 document-details">
      <strong>Document:</strong>
      <div>
        {{ document?.filename || '--' }}
      </div>
    </div>
    <div class="d-flex flex-row mb-3 document-details">
      <strong>Type:</strong>
      <div>
        {{ document?.type || '--' }}
      </div>
    </div>
    <kendo-formfield>
      <kendo-label [for]="commentField" text="Rejection Reason"></kendo-label>
      <kendo-textarea #commentField resizable="auto" [maxlength]="maxlength" [readonly]="rejectDocumentFeedback"
                      placeholder="Enter a rejection reason." [(ngModel)]="comment">
        <kendo-textarea-suffix class="kehe-textarea-counter">
          {{ counter }}
        </kendo-textarea-suffix>
      </kendo-textarea>
      <kendo-formhint>This will be visible to the supplier.</kendo-formhint>
    </kendo-formfield>

  </div>

  <div footer class="d-flex justify-content-end" *ngIf="!rejectDocumentFeedback">
    <kehe-phoenix-button
      class="cancel-button"
      name="cancel"
      classAttr="btn-link btn-medium"
      (click)="closeModal()">
      Cancel
    </kehe-phoenix-button>

    <kehe-phoenix-button
      classAttr="btn-delete btn-medium ms-2"
      [spinner]="isLoading"
      [disabled]="!isRejectEnabled"
      (click)="onRejectClicked()">
      Reject
    </kehe-phoenix-button>
  </div>
  <div footer class="d-flex justify-content-end" *ngIf="rejectDocumentFeedback">
    <kehe-phoenix-button
      name="close"
      classAttr="btn-primary btn-medium ms-2"
      (click)="closeModal()">
      Close
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
