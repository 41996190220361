<ng-container *ngIf="vm$ | async as vm">
  <div class="px-4">
    <div class="row">
      <div class="col">
        <h4>Broker</h4>
      </div>
    </div>

    <div class="row kehe-data-section">
      <div class="col-12 mt-2">
        <ng-container *ngIf="!vm.isBrokerTabValid">
          <div class="row mb-3">
            <div class="col-12 kehe-data-value">
              <strong>Add Broker or Skip this Step</strong>
            </div>
          </div>
        </ng-container>

        <div class="row mb-3">
          <div class="col-12 kehe-data-value">
            Add your headquarter and non-headquarter Brokers below. KeHE will
            grant all brokerage personnel access to your Supplier information in
            KeHE CONNECT&#174;.
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 kehe-data-value">
            The Headquarter Brokers access includes, but is not limited to
            promotion submissions, price management, sales and reporting.
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 kehe-data-value">
            Non-headquarter Brokers are traditionally not responsible for
            managing your data, but can be granted access to view promotions,
            pricing, and sales reports. The Non-headquarter Broker will only
            receive access when your account is active.
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 kehe-data-value">
            <strong>Note:</strong> The Supplier is responsible for removing the
            Broker in the event of a Supplier/Broker termination.
          </div>
        </div>

        <ng-container *ngIf="!vm.isBrokerTabValid">
          <kehe-phoenix-button
            (click)="onAddClick()"
            classAttr="btn-primary btn-medium ml"
            automationId="broker-tab-v2-add-broker-button"
          >
            Add Broker
          </kehe-phoenix-button>

          <div class="row mb-3 pt-2">
            <div class="col-12 col-sm-6 kehe-data-value">
              Don't have a Broker?
              <a class="link" (click)="noBrokerModal()" data-automation-id="broker-tab-v2-skip-add-broker-link"> Skip this step.</a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-broker-tab-v2-grid
          data-automation-id="broker-tab-v2-table"
          *ngIf="vm.isBrokerTabValid"
        ></app-broker-tab-v2-grid>
      </div>
    </div>
  </div>

  <app-add-broker-v2-modal *ngIf="vm.isEditModalOpen"></app-add-broker-v2-modal>
  <app-delete-broker-v2-modal
    *ngIf="vm.isDeleteModalOpen"
  ></app-delete-broker-v2-modal>
</ng-container>
