import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ModalOptions } from '../../models/modal-options.model';
import { ModalResponse } from '../../models/modal-response.model';
import { PromiseModalService } from '../../services/promise-modal.service';
import { IKeheModalEvent, KeheModalEventsTypes } from '@kehe/phoenix-modal';

@Component({
  selector: 'app-promise-modal',
  templateUrl: './promise-modal.component.html',
  styleUrls: ['./promise-modal.component.scss']
})
export class PromiseModalComponent implements OnInit {
  public options$: Observable<ModalOptions>;
  public isOpen = false;
  constructor(
    private modalService: PromiseModalService
  ) { }

  ngOnInit() {
    this.options$ = this.modalService
      .notifyModal()
      .pipe(
        filter(m => !!m),
        tap(() => {
          this.isOpen = true;
        }));
  }

  public handleOk(): void {
    this.modalService.setModalResult(ModalResponse.Cancel);
    this.isOpen = false;
  }

  public handleCancel(): void {
    this.modalService.setModalResult(ModalResponse.Ok);
    this.isOpen = false;
  }

  public handleKeheModalEvents($event: IKeheModalEvent, closeOk = false): void {
    if (closeOk) {
      this.handleOk();
    } else if ($event.type === KeheModalEventsTypes.Close) {
      this.handleCancel();
    } else {
      this.isOpen = false;
    }
  }
}
