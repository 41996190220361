import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { delay, map, retry, retryWhen, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BrokerDetail } from '../models/broker-detail';
import { BrokerStatus } from '../../broker-detail/models/broker-status';
import { BrokerContacts } from '../models/broker-contacts';

@Injectable({
  providedIn: 'root'
})
export class SupplierBrokerService {
  private _url: string;
  private _usersURL: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.brokerEnterprise;
    this._usersURL = `${environment.apiGateway}/users/supplier/list`;
  }

  getBrokerDetail(esn: string): Observable<BrokerDetail> {
    return this.httpClient
      .get<{ availableCount: number; data: any }>(
        `${this._url}/${esn}`
      )
      .pipe(retryWhen(errors => errors.pipe(delay(5000), take(3))), map((response) => response.data));
  }

  search(search: string, pageCount: number, pageIndex: number): Observable<BrokerDetail[]> {
    const params = new HttpParams()
      .set('search', search)
      .set('pagecount', String(pageCount))
      .set('pageindex', String(pageIndex))
      .set('sortName', '2')
      .set('isAscending', String(true))
      .set('status', BrokerStatus.active);
    return this.httpClient
      .get<{ availableCount: number; data: BrokerDetail[] }>(
        this._url, { params: params }
      )
      .pipe(retry(1), map((response) => response.data));
  }

  getBrokerContacts(brokerNumber: string, search: string): Observable<Array<BrokerContacts>> {
    let params = new HttpParams().set('number', brokerNumber).set('type', 'broker');
    if (search) {
      params = params.set('search', search);
    }
    return this.httpClient
      .get<{ availableCount: number; data: BrokerContacts[] }>(
        this._usersURL, { params: params }
      )
      .pipe(retry(1), map((response) => response.data));
  }
}
