import { Address } from './address';

export enum ContactType {
  HeadquarterAddress = 'Headquarter Address',
  PickupAddress = 'Pickup Address',
  RemittanceAddress = 'Remittance Address',
  DeliveredAddress = 'Delivered Address',
}
export class Contact {
  id?: number;
  uniqueId: string;
  name?: string;
  phoneExtension?: number;
  phone?: string;
  fax?: string;
  email?: string;
  alternateEmail?: string;
  contactType: ContactType;
  address?: Address;
  isActive?: boolean;
  notes?: string;
}
