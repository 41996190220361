import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierContactsGridComponent } from './components/supplier-contacts-grid/supplier-contacts-grid.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { SupplierContactsService } from './services/supplier-contacts.service';
import { EffectsModule } from '@ngrx/effects';
import { SupplierContactsEffects } from './store/supplier-contacts.effects';
import { StoreModule } from '@ngrx/store';
import * as fromSupplierContacts from './store/supplier-contacts.reducer';
import { PhoenixTooltipModule } from '@kehe/phoenix-tooltip';
import { ManageContactModalComponent } from './components/manage-contact-modal/manage-contact-modal.component';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { PhoenixButtonModule, PhoenixMenuButtonModule, } from '@kehe/phoenix-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComboBoxModule, MultiSelectModule, } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { CheckBoxModule, FormFieldModule, NumericTextBoxModule, TextBoxModule, } from '@progress/kendo-angular-inputs';
import { PhoenixPhoneNumberModule } from '@kehe/phoenix-phone-number';
import { PhoenixAlertsModule } from '@kehe/phoenix-notifications';
import { DeleteContactModalComponent } from './components/delete-contact-modal/delete-contact-modal.component';
import {
  AssignPrimaryContactModalComponent
} from './components/assign-primary-contact-modal/assign-primary-contact-modal.component';
import {
  AssignDiscProductsNotificationModalComponent
} from './components/assign-disc-products-notification-modal/assign-disc-products-notification-modal.component';
import { EmptyValuePipe } from '@app/shared/pipes/empty-value.pipe';
import { PhoneNumberPipe } from '@app/shared/pipes/phoneNumber.pipe';

@NgModule({
  declarations: [
    SupplierContactsGridComponent,
    ManageContactModalComponent,
    DeleteContactModalComponent,
    AssignPrimaryContactModalComponent,
    AssignDiscProductsNotificationModalComponent,
  ],
  imports: [
    CommonModule,
    GridModule,
    PhoenixTooltipModule,
    PhoenixModalModule,
    PhoenixButtonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MultiSelectModule,
    ComboBoxModule,
    LabelModule,
    FormFieldModule,
    TextBoxModule,
    PhoenixPhoneNumberModule,
    NumericTextBoxModule,
    CheckBoxModule,
    PhoenixAlertsModule,
    StoreModule.forFeature('supplierContacts', fromSupplierContacts.reducer),
    EffectsModule.forFeature([SupplierContactsEffects]),
    PhoenixMenuButtonModule,
    EmptyValuePipe,
    PhoneNumberPipe,
  ],
  providers: [SupplierContactsService],
  exports: [SupplierContactsGridComponent],
})
export class SupplierContactsModule {}
