
export enum BulkEditPages {
    SelectTypePage,
    PickupAddressPage,
    DeliveryMethodPage,
    DeliveredLeadTimesPage,
    PickupLeadTimesPage,
    PickupContact,
    POFaxPage,
    LeadTimesPickupAddressPage,
    BuyersPage,
    DeliveryMethodLeadTimesPage,
    ChangePickupTypePage,
    AddPOEmailPage,
    ReplacePOEmailPage,
    RemovePOEmailPage,
    DeliveredAddressPage,
  }

