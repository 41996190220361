import { createReducer, on } from '@ngrx/store';
import * as ExportHierarchiesModalActions from '../effects/actions';

export const key = 'exportHierarchiesModal';

export class ExportHierarchiesModalState {
    errorMessage: string;
    isExportInProgress: boolean;
    isOpen: boolean;
    modalTitle: string;
}

export const initializeState = (): ExportHierarchiesModalState => ({
    errorMessage: null,
    isExportInProgress: false,
    isOpen: false,
    modalTitle: "Export Hierarchies"
});

export const initialState: ExportHierarchiesModalState = initializeState();

export const reducer = createReducer(
    initialState,
    on(ExportHierarchiesModalActions.openClicked, (state): ExportHierarchiesModalState => ({
        ...state,
        isOpen: true
    })),
    on(ExportHierarchiesModalActions.closeClicked, (state): ExportHierarchiesModalState => ({
        ...state,
        isOpen: false
    })),
    on(ExportHierarchiesModalActions.exportClicked, (state): ExportHierarchiesModalState => ({
        ...state,
        isExportInProgress: true
    })),
    on(ExportHierarchiesModalActions.exportFileSuccess, (state): ExportHierarchiesModalState => ({
        ...state,
        isExportInProgress: false,
        isOpen: false
    })),
    on(ExportHierarchiesModalActions.exportFileFailure, (state, { errorMessage }): ExportHierarchiesModalState => ({
        ...state,
        errorMessage,
        isExportInProgress: false,
        isOpen: false
    })),
);