import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { Constants } from '../../../../constants/constants';
import { getAllProductGroups } from '../../store/product-groups.selectors';
import { ActivatedRoute } from '@angular/router';
import * as DistributionCenterSelectors from '@app/shared/state//distribution-center/distribution-center.selectors';
import { isDraftDCPG } from '../../utils/products-util';

@Component({
  selector: 'app-dc-form',
  templateUrl: './dc-form.component.html',
})
export class DcFormComponent {
  dcList$ = this._store
    .select(DistributionCenterSelectors.selectDistributionCenterList)
    .pipe(
      map((list) =>
        list
        .filter((item) => !Constants.ExcludedDCs.includes(+(item as any).number))
        .map(item => ({
          label: (item as any).name.replace('Kehe Dist - ', ''),
          value: (item as any).number,
        }))
      ),
      withLatestFrom(
        this._store.select(getAllProductGroups)
      ),
      map(([dcs, pgs]) => {
        const dcpgs = pgs
          .find((pg) => this._route.snapshot.paramMap.get('productGroupNumber') === pg.productGroupNumber)?.dcProductGroups;
        const dcpgNumbers = dcpgs?.map((dcpg) => dcpg.distributionCenterNumber) ?? [];
        return dcs.filter((dc) => {
          const alreadyExists = dcpgNumbers.includes(dc.value);
          return !alreadyExists || (alreadyExists && isDraftDCPG(dcpgs.find(dcpg => dcpg.distributionCenterNumber === dc.value)));
        });
      })
    );

  @Input()
  form: UntypedFormGroup;

  constructor(
    private readonly _store: Store,
    private readonly _route: ActivatedRoute
  ) {}
}
