import { Contact } from '../../../models/contact';
import { createAction, props } from '@ngrx/store';
import { SupplierLocationsState } from './supplier-locations.reducer';

export const updateContacts = createAction(
  '[Supplier Locations]: Update Contacts',
  props<{
    contacts: SupplierLocationsState['contacts'];
    headquarterAddress?: string;
  }>()
);

export const removeContact = createAction(
  '[Supplier Locations]: Remove Contact',
  props<{ contact: Contact; }>()
);

export const updateLocationError = createAction(
  '[Supplier Locations]: Update Location Error',
  props<{ error: string }>()
);
