import { IChip } from '@kehe/phoenix-chips';
import { createAction, props } from '@ngrx/store';
import { Payload } from '../../../../models/payload';
import { IShipmentsCloneDto } from '../../common/models/ishipments-clone-dto';
import { DeleteShipmentResponseObj } from '../../common/models/delete-shipments-dto.interface';
import { ShipmentDetail } from '../../common/models/shipment-detail';
import { CombineShipmentsDto, CombineShipmentsShipmentsMsDto } from '../models/combine-shipments-models';
import { ShipmentsListFilter } from '../models/shipments-list-filter';
import { ShipmentsListItem } from '../models/shipments-list-item';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { DeleteShipmentObj } from '../../common/models/delete-shipment-obj';
import { Params } from '@angular/router';
import { IShipmentsFilterWithNumberItem } from '../models/ishipments-filter-with-number-item';
import { ICodeName } from '../../../../models/icode-name';
import { CloneShipmentByCustomerGuid } from '../../common/models/clone-shipment-by-customer-guid.interface';
import { CloneShipmentByCustomerResponse } from '../../common/models/clone-shipment-by-customer-response.interface';
import { IShipmentsCloneByCustomerDto } from '../../common/models/ishipments-clone-by-customer';
import { IShipmentsCloneByChainDto } from '../../common/models/ishipments-clone-by-chain';
import { SyncCheck } from '../../common/models/sync-check.interface';
import { IShipmentsFilterWithNumberName } from '../models/ishipments-filter-with-number-name';

export enum ShipmentsListActions {
  ManageShipmentsPageAccessed = '[ShipmentsList] Manage Shipments Page Accessed',

  SetShipmentListFiltersFromQueryParams = '[ShipmentsList] Set Shipment List Filters From Query Params',
  SetShipmentListFiltersFromQueryParamsSuccess = '[ShipmentsList] Set Shipment List Filters From Query Params Success',
  LoadShipmentsList = '[ShipmentsList] Load Shipments List',
  LoadShipmentsListSuccess = '[ShipmentsList] Load Shipments List Success',
  LoadShipmentsListError = '[ShipmentsList] Load Shipments List Error',
  ShipmentsListPageChange = '[ShipmentsList] Shipments List Page Change',
  OpenShipmentsListFilterPanel = '[ShipmentsList] Open Shipments Filter Panel',
  CloseShipmentsListFilterPanel = '[ShipmentsList] Close Shipments Filter Panel',
  ApplyFilter = '[ShipmentsList] Apply Filters',
  ApplyQueryParamsFilters = '[ShipmentsList] Apply Query Params Filters',
  RefreshPage = '[ShipmentsList] Refresh Page',
  GetDropdownFilterData = '[ShipmentsList] Get Dropdown Filter Data',

  SelectOneChanged = '[ShipmentsList] Select One Changed',
  SelectAllChanged = '[ShipmentsList] Select All Changed',

  ShipmentsListSearchChange = '[ShipmentsList] Shipments List Search Change',
  ShipmentsListSortChange = '[ShipmentsList] Shipments List Sort Change',
  CreateShipmentsListFilterChips = '[ShipmentsList] Create Shipments List Filter Chips',
  ShipmentsListFilterChipClicked = '[ShipmentsList] Shipments List Filter Chip Clicked',
  ShipmentsListFilterChipsClearAll = '[ShipmentsList] Shipments List Filter Chips Clear All',
  RebuildFilterChips = '[ShipmentsList] Rebuild Filter Chips',
  ShipmentsListPopulateAllDcsForFilter = '[ShipmentsList] Orders List Populate All Dcs For Filter',
  ShipmentsListPopulateAllDcsForFilterSuccess = '[ShipmentsList] Orders List Populate All Dcs For Filter Success',

  ExportShipmentsBulkAction = '[ShipmentsList] Bulk Export Shipments',
  ExportShipmentsBulkActionSuccess = '[ShipmentsList] Bulk Export Shipments success',
  ExportShipmentsBulkActionError = '[ShipmentsList] Bulk Export Shipments error',


  ExportShipment = '[ShipmentsList] Export Shipment',
  ExportShipmentSuccess = '[ShipmentsList] Export Shipment Success',
  ExportShipmentError = '[ShipmentsList] Export Shipment Error',

  ShipmentsListCloneShipment = '[ShipmentsList] Shipments List Clone Shipment',
  ShipmentsListCloneShipmentSuccess = '[ShipmentsList] Shipments List Clone Shipment Success',
  ShipmentsListCloneShipmentError = '[ShipmentsList] Shipments List Clone Shipment Error',

  GetClonedShipmentDraftID = '[ShipmentsList] Get Cloned Shipment Draft ID',
  GetClonedShipmentDraftIDSuccess = '[ShipmentsList] Get Cloned Shipment Draft ID Success',
  GetClonedShipmentDraftIDError = '[ShipmentsList] Get Cloned Shipment Draft ID Error',

  CloneShipmentByCustomer = '[ShipmentsList] Clone Shipment By Customer',
  CloneShipmentByCustomerSuccess = '[ShipmentsList] Clone Shipment By Customer Success',
  CloneShipmentByCustomerError = '[ShipmentsList] Clone Shipment By Customer Error',

  GetClonedShipmentDraftIDForMultipleCustomers = '[ShipmentsList] Get Cloned Shipment Draft ID For Multiple Customers',
  GetClonedShipmentDraftIDForMultipleCustomersSuccess = '[ShipmentsList] Get Cloned Shipment Draft ID For Multiple Customers Success',
  GetClonedShipmentDraftIDForMultipleCustomersError = '[ShipmentsList] Get Cloned Shipment Draft ID For Multiple Customers Error',

  ClearCloneToMultipleCustomersInfoBanner = '[ShipmentsList] Clear Clone To Multiple Customers Info Banner',
  ClearCloneToMultipleCustomersErrorBanner = '[ShipmentsList] Clear Clone To Multiple Customers Error Banner',

  CloneShipmentByChain = '[ShipmentsList] Clone Shipment By Chain',
  CloneShipmentByChainSuccess = '[ShipmentsList] Clone Shipment By Chain Success',
  CloneShipmentByChainError = '[ShipmentsList] Clone Shipment By Chain Error',

  ShipmentsListOpenCloneShipmentModal = '[ShipmentsList] Open Clone Shipment Modal',
  ShipmentsListCloseCloneShipmentModal = '[ShipmentsList] Close Clone Shipment Modal',

  ToggleBulkDeleteItemsModalVisibility = '[ShipmentsList] Toggle Bulk Delete Items Modal Visibility',
  ToggleBulkItemQuantityEditModalVisibility = '[ShipmentsList] Toggle Bulk Item Quantity Edit Modal Visibility',

  ValidateCombineShipments = '[ShipmentsList] Validate Combine Shipments',
  ValidateCombineShipmentsSuccess = '[ShipmentsList] Validate Combine Shipments Success and Open Modal',
  ValidateCombineShipmentsError = '[ShipmentsList] Validate Combine Shipments Error',

  CloseCombineShipmentsModal = '[ShipmentsList] Close Combine Shipments Modal',

  CombineShipments = '[ShipmentsList] Combine Shipments',
  CombineShipmentsSuccess = '[ShipmentsList] Combine Shipments Success',
  CombineShipmentsError = '[ShipmentsList] Combine Shipments Error',

  DeleteShipmentBulkActionClicked = '[ShipmentsList] Delete Shipment Bulk Action Clickedl',
  DeleteShipmentEllipsesActionClicked = '[ShipmentsList] Delete Shipment Ellipses Action Clicked',
  DeleteShipmentTrashCanClicked = '[ShipmentsList] Delete Shipment Trash Can Clicked',
  CloseDeleteShipmentsModal = '[ShipmentsList] Close Delete Shipment Modal',
  ShowDeleteModal = '[ShipmentsList] Show Delete Shipment Modal',

  ConfirmDeleteClicked = '[ShipmentsList] Confirm Delete Clicked',
  ShipmentsListDeleteSuccess = '[ShipmentsList] Delete Shipment Success',
  ShipmentsListDeleteError = '[ShipmentsList] Delete Shipment Error',

  ShipmentsListCloseDeleteShipmentErrorBanner = '[ShipmentsList] Shipments List Delete Shipment Error Banner',

  CloseCombineSuccessBanner = '[ShipmentsList] Close Combine Success Banner',
  CloseChangesInProgressBanner = '[ShipmentsList] Close Changes In Progress Banner',
  CloseBulkActionSuccessBanner = '[ShipmentsList] Close Bulk Action Success Banner',

  GetOutOfSyncShipments = '[ShipmentsList] Get Out Of Sync Shipments',
  GetOutOfSyncShipmentsSuccess = '[ShipmentsList] Get Out Of Sync Shipments Success',
  GetOutOfSyncShipmentsFailure = '[ShipmentsList] Get Out Of Sync Shipments Failure',

  // Side panel filter
  GetSidePanelFilterAvailableDistributionCenters = '[ShipmentsList] Get Side Panel Filter Available Distribution Centers',
  GetSidePanelFilterAvailableDistributionCentersSuccess = '[ShipmentsList] Get Side Panel Filter Available Distribution Centers Success',
  GetSidePanelFilterAvailableDistributionCentersFailure = '[ShipmentsList] Get Side Panel Filter Available Distribution Centers Failure',
  GetSidePanelFilterAvailableCustomers = '[ShipmentsList] Get Side Panel Filter Available Customers',
  GetSidePanelFilterAvailableCustomersSuccess = '[ShipmentsList] Get Side Panel Filter Available Customers Success',
  GetSidePanelFilterAvailableCustomersFailure = '[ShipmentsList] Get Side Panel Filter Available Customers Failure',
  GetSidePanelFilterAvailableSources = '[ShipmentsList] Get Side Panel Filter Available Sources',
  GetSidePanelFilterAvailableSourcesSuccess = '[ShipmentsList] Get Side Panel Filter Available Sources Success',
  GetSidePanelFilterAvailableSourcesFailure = '[ShipmentsList] Get Side Panel Filter Available Sources Failure',
  GetSidePanelFilterAvailableTypes = '[ShipmentsList] Get Side Panel Filter Available Types',
  GetSidePanelFilterAvailableTypesSuccess = '[ShipmentsList] Get Side Panel Filter Available Types Success',
  GetSidePanelFilterAvailableTypesFailure = '[ShipmentsList] Get Side Panel Filter Available Types Failure',
  GetSidePanelFilterAvailableChains = '[ShipmentsList] Get Side Panel Filter Available Chains',
  GetSidePanelFilterAvailableChainsSuccess = '[ShipmentsList] Get Side Panel Filter Available Chains Success',
  GetSidePanelFilterAvailableChainsFailure = '[ShipmentsList] Get Side Panel Filter Available Chains Failure',
  GetSidePanelFilterAvailableStatuses = '[ShipmentsList] Get Side Panel Filter Available Statuses',
  GetSidePanelFilterAvailableStatusesSuccess = '[ShipmentsList] Get Side Panel Filter Available Statuses Success',
}

export const manageShipmentsPageAccessed = createAction(
  ShipmentsListActions.ManageShipmentsPageAccessed,
  props<{ userNavigatedFromParent: boolean }>()
);

export const setShipmentListFiltersFromQueryParams = createAction(
  ShipmentsListActions.SetShipmentListFiltersFromQueryParams
);

export const setShipmentListFiltersFromQueryParamsSuccess = createAction(
  ShipmentsListActions.SetShipmentListFiltersFromQueryParamsSuccess,
  props<{ params: Params }>()
);

export const loadShipmentsList = createAction(
  ShipmentsListActions.LoadShipmentsList
);

export const loadShipmentsListSuccess = createAction(
  ShipmentsListActions.LoadShipmentsListSuccess,
  props<{ shipments: Payload<Array<ShipmentsListItem>>, ellipsisOptions: Array<OptionMenuItem>}>()
);

export const loadShipmentsListError = createAction(
  ShipmentsListActions.LoadShipmentsListError
);

export const shipmentsListPageChange = createAction(
  ShipmentsListActions.ShipmentsListPageChange,
  props<{ pageCount: number; pageIndex: number, skip: number }>()
);

export const openShipmentsListFilter = createAction(
  ShipmentsListActions.OpenShipmentsListFilterPanel
);

export const closeShipmentsListFilter = createAction(
  ShipmentsListActions.CloseShipmentsListFilterPanel
);

export const applyFilter = createAction(
  ShipmentsListActions.ApplyFilter,
  props<{ shipmentsListFilter: ShipmentsListFilter }>()
);

export const applyQueryParamsFilters = createAction(
  ShipmentsListActions.ApplyQueryParamsFilters,
  props<{ shipmentsListFilter: ShipmentsListFilter }>()
);

export const refreshPage = createAction(
  ShipmentsListActions.RefreshPage
);

export const getDropdownFilterData = createAction(
  ShipmentsListActions.GetDropdownFilterData
);

export const shipmentsListSearchChange = createAction(
  ShipmentsListActions.ShipmentsListSearchChange,
  props<{ search: string }>()
);

export const shipmentsListSearchOptionChanged = createAction(
  '[ShipmentsList] Shipments List Search Option Changed',
  props<{ searchOption: string }>()
);

export const selectAllChanged = createAction(
  ShipmentsListActions.SelectAllChanged,
);
export const selectOneChanged = createAction(
  ShipmentsListActions.SelectOneChanged,
  props<{ shipmentId: number }>()
);

export const shipmentsListPopulateAllDcsForFilterSuccess = createAction(
  ShipmentsListActions.ShipmentsListPopulateAllDcsForFilterSuccess,
  props<{ dcs: Array<any> }>()
);


export const shipmentsListSortChange = createAction(
  ShipmentsListActions.ShipmentsListSortChange,
  props<{ orderDirection: number, sortName: string }>()
);

export const createShipmentsListFilterChips = createAction(
  ShipmentsListActions.CreateShipmentsListFilterChips
);

export const shipmentsListFilterChipClicked = createAction(
  ShipmentsListActions.ShipmentsListFilterChipClicked,
  props<{ chip: IChip }>()
);

export const shipmentsListFilterChipsClearAll = createAction(
  ShipmentsListActions.ShipmentsListFilterChipsClearAll
);

export const rebuildFilterChips = createAction(
  ShipmentsListActions.RebuildFilterChips
);

export const exportShipmentsBulkAction = createAction(
  ShipmentsListActions.ExportShipmentsBulkAction,
);
export const exportShipmentsBulkActionSuccess = createAction(
  ShipmentsListActions.ExportShipmentsBulkActionSuccess,
  props<{ bulkExportGuid: string }>()
);
export const exportShipmentsBulkActionError = createAction(
  ShipmentsListActions.ExportShipmentsBulkActionError,
  props<{ error: any }>()
);

export const exportShipment = createAction(
  ShipmentsListActions.ExportShipment,
  props<{ shipment: ShipmentsListItem }>()
);
export const exportShipmentSuccess = createAction(
  ShipmentsListActions.ExportShipmentSuccess,
);
export const exportShipmentError = createAction(
  ShipmentsListActions.ExportShipmentError,
);
export const closeSingleExportErrorAlert = createAction(
  '[ShipmentsList] Close Single Export Error Banner Alert',
);

// Start: Clone Shipment Logic
export const openCloneShipmentModal = createAction(
  ShipmentsListActions.ShipmentsListOpenCloneShipmentModal
);
export const closeCloneShipmentModal = createAction(
  ShipmentsListActions.ShipmentsListCloseCloneShipmentModal
);

export const cloneShipment = createAction(
  ShipmentsListActions.ShipmentsListCloneShipment,
  props<{ shipmentToClone: IShipmentsCloneDto }>()
);
export const cloneShipmentSuccess = createAction(
  ShipmentsListActions.ShipmentsListCloneShipmentSuccess,
  props<{ cloneGUID: string }>()
);
export const cloneShipmentError = createAction(
  ShipmentsListActions.ShipmentsListCloneShipmentError,
  props<{ error: any }>()
);

export const getClonedShipmentDraftID = createAction(
  ShipmentsListActions.GetClonedShipmentDraftID,
);
export const getClonedShipmentDraftIDSuccess = createAction(
  ShipmentsListActions.GetClonedShipmentDraftIDSuccess,
  props<{ draftOrderId: string }>()
);
export const getClonedShipmentDraftIDError = createAction(
  ShipmentsListActions.GetClonedShipmentDraftIDError,
  props<{ error: any }>()
);

export const cloneShipmentByCustomer = createAction(
  ShipmentsListActions.CloneShipmentByCustomer,
  props<{ shipmentToClone: IShipmentsCloneByCustomerDto }>()
);
export const cloneShipmentByCustomerSuccess = createAction(
  ShipmentsListActions.CloneShipmentByCustomerSuccess,
  props<{ cloneShipmentByCustomerResponseArray: CloneShipmentByCustomerResponse[] }>()
);
export const cloneShipmentByCustomerError = createAction(
  ShipmentsListActions.CloneShipmentByCustomerError,
  props<{ errors: CloneShipmentByCustomerResponse[] }>()
);

export const getClonedShipmentDraftIDForMultipleCustomers = createAction(
  ShipmentsListActions.GetClonedShipmentDraftIDForMultipleCustomers,
);
export const getClonedShipmentDraftIDForMultipleCustomersSuccess = createAction(
  ShipmentsListActions.GetClonedShipmentDraftIDForMultipleCustomersSuccess,
  props<{ cloneGuids: CloneShipmentByCustomerGuid[] }>()
);
export const getClonedShipmentDraftIDForMultipleCustomersError = createAction(
  ShipmentsListActions.GetClonedShipmentDraftIDForMultipleCustomersError,
  props<{ cloneGuids: CloneShipmentByCustomerGuid[] }>()
);
export const openOrdersPageInNewTab = createAction(
  '[ShipmentsList] Open Orders Page In New Tab'
);

export const clearCloneToMultipleCustomersInfoBanner = createAction(
  ShipmentsListActions.ClearCloneToMultipleCustomersInfoBanner,
);
export const clearCloneToMultipleCustomersErrorBanner = createAction(
  ShipmentsListActions.ClearCloneToMultipleCustomersErrorBanner,
);

export const cloneShipmentByChain = createAction(
  ShipmentsListActions.CloneShipmentByChain,
  props<{ shipmentToClone: IShipmentsCloneByChainDto }>()
);
export const cloneShipmentByChainSuccess = createAction(
  ShipmentsListActions.CloneShipmentByChainSuccess,
  props<{ manageProgressGuid: string }>()
);
export const cloneShipmentByChainError = createAction(
  ShipmentsListActions.CloneShipmentByChainError,
  props<{ error: any }>()
);
// End: Clone Shipment Logic

// Start: Bulk Delete Items
export const toggleBulkDeleteItemsModalVisibility = createAction(
  ShipmentsListActions.ToggleBulkDeleteItemsModalVisibility,
  props<{ open: boolean }>()
);
// End: Bulk Delete Items

// Start: Bulk Bulk Item Quantity Edit
export const toggleBulkItemQuantityEditModalVisibility = createAction(
  ShipmentsListActions.ToggleBulkItemQuantityEditModalVisibility,
  props<{ open: boolean }>()
);
// End: Bulk Bulk Item Quantity Edit

// Start: Combine Shipment Logic
export const validateCombineShipments = createAction(
  ShipmentsListActions.ValidateCombineShipments,
);
export const validateCombineShipmentsSuccess = createAction(
  ShipmentsListActions.ValidateCombineShipmentsSuccess,
  props<{ validationErrors: Array<string> }>()
);
export const validateCombineShipmentsError = createAction(
  ShipmentsListActions.ValidateCombineShipmentsError,
  props<{ error: any }>()
);
export const closeCombineShipmentsModal = createAction(
  ShipmentsListActions.CloseCombineShipmentsModal,
);
export const combineShipments = createAction(
  ShipmentsListActions.CombineShipments,
  props<{ combineShipmentsDto: CombineShipmentsDto, combineShipmentsShipmentsMsDto: CombineShipmentsShipmentsMsDto }>()
);
export const combineShipmentsSuccess = createAction(
  ShipmentsListActions.CombineShipmentsSuccess,
);
export const combineShipmentsError = createAction(
  ShipmentsListActions.CombineShipmentsError,
  props<{ error: any, isNewErrorMessagesFFEnabled: boolean }>()
);
export const closeCombineSuccessBanner = createAction(
  ShipmentsListActions.CloseCombineSuccessBanner,
);
export const closeCombineErrorBanner = createAction(
  '[ShipmentsList] Close Combine Error Banner'
);
export const closeChangesInProgressBanner = createAction(
  ShipmentsListActions.CloseChangesInProgressBanner,
);
// End: Combine Shipment Logic

// Start: Delete Shipment logic
export const deleteShipmentBulkActionClicked = createAction(
  ShipmentsListActions.DeleteShipmentBulkActionClicked,
);
export const deleteShipmentEllipsesActionClicked = createAction(
  ShipmentsListActions.DeleteShipmentEllipsesActionClicked,
  props<{ shipmentToDelete: ShipmentsListItem }>()
);
export const deleteShipmentTrashCanClicked = createAction(
  ShipmentsListActions.DeleteShipmentTrashCanClicked,
  props<{ shipmentToDelete: ShipmentsListItem }>()
);
export const closeDeleteShipmentsModal = createAction(
  ShipmentsListActions.CloseDeleteShipmentsModal,
);
export const confirmDeleteClicked = createAction(
  ShipmentsListActions.ConfirmDeleteClicked,
);
export const deleteShipmentSuccess = createAction(
  ShipmentsListActions.ShipmentsListDeleteSuccess,
  props<{ deletedItems: Array<DeleteShipmentObj>, response: Array<DeleteShipmentResponseObj>, isFFEnabled: boolean }>()
);
export const deleteShipmentError = createAction(
  ShipmentsListActions.ShipmentsListDeleteError,
  props<{ deletedItems: Array<DeleteShipmentObj>, error: any }>()
);

export const shipmentsListCloseDeleteShipmentErrorBanner = createAction(
  ShipmentsListActions.ShipmentsListCloseDeleteShipmentErrorBanner,
);

// End: Delete Shipment logic

export const getOutOfSyncShipments = createAction(
  ShipmentsListActions.GetOutOfSyncShipments,
);
export const getOutOfSyncShipmentsSuccess = createAction(
  ShipmentsListActions.GetOutOfSyncShipmentsSuccess,
  props<{ responseItems: ShipmentDetail[], ellipsesMenuOptions: OptionMenuItem[]}>()
);
export const getOutOfSyncShipmentsFailure = createAction(
  ShipmentsListActions.GetOutOfSyncShipmentsFailure,
  props<{ error: any }>()
);

// Start Side Panel Filters

export const getSidePanelFilterAvailableDistributionCenters = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableDistributionCenters,
);
export const getSidePanelFilterAvailableDistributionCentersSuccess = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableDistributionCentersSuccess,
  props<{ availableDcs: Array<IShipmentsFilterWithNumberItem> }>()
);
export const getSidePanelFilterAvailableDistributionCentersFailure = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableDistributionCentersFailure
);
export const getSidePanelFilterAvailableCustomers = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableCustomers,
  props<{ customerSearchStr: string }>()
);
export const getSidePanelFilterAvailableCustomersSuccess = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableCustomersSuccess,
  props<{ availableCustomers: Array<IShipmentsFilterWithNumberItem> }>()
);
export const getSidePanelFilterAvailableCustomersFailure = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableCustomersFailure
);
export const getSidePanelFilterAvailableDCItemNumbers = createAction(
  '[ShipmentsList] Get Side Panel Filter Available DC Item Numbers',
  props<{ dcItemNumberSearchStr: string, selectedDCs: string }>()
);
export const getSidePanelFilterAvailableDCItemNumbersSuccess = createAction(
  '[ShipmentsList] Get Side Panel Filter Available DC Item Numbers Success',
  props<{ availableDCItemNumbers: Array<IShipmentsFilterWithNumberName> }>()
);
export const getSidePanelFilterAvailableDCItemNumbersFailure = createAction(
  '[ShipmentsList] Get Side Panel Filter Available DC Item Numbers Failure'
);
export const getSidePanelFilterAvailableUpcs = createAction(
  '[ShipmentsList] Get Side Panel Filter Available UPCs',
  props<{ upcSearchStr: string, selectedDCs: string }>()
);
export const getSidePanelFilterAvailableUpcsSuccess = createAction(
  '[ShipmentsList] Get Side Panel Filter Available UPCs Success',
  props<{ availableUpcs: Array<IShipmentsFilterWithNumberName> }>()
);
export const getSidePanelFilterAvailableUpcsFailure = createAction(
  '[ShipmentsList] Get Side Panel Filter Available UPCs Failure'
);
export const getSidePanelFilterAvailableSources = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableSources,
);
export const getSidePanelFilterAvailableSourcesSuccess = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableSourcesSuccess,
  props<{ availableSources: Array<ICodeName> }>()
);
export const getSidePanelFilterAvailableSourcesFailure = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableSourcesFailure
);
export const getSidePanelFilterAvailableTypes = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableTypes,
);
export const getSidePanelFilterAvailableTypesSuccess = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableTypesSuccess,
  props<{ availableTypes: Array<ICodeName> }>()
);
export const getSidePanelFilterAvailableTypesFailure = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableTypesFailure
);
export const getSidePanelFilterAvailableChains = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableChains,
);
export const getSidePanelFilterAvailableChainsSuccess = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableChainsSuccess,
  props<{ availableChains: Array<ICodeName> }>()
);
export const getSidePanelFilterAvailableChainsFailure = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableChainsFailure
);
export const getSidePanelFilterAvailableStatuses = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableStatuses,
);
export const getSidePanelFilterAvailableStatusesSuccess = createAction(
  ShipmentsListActions.GetSidePanelFilterAvailableStatusesSuccess
);
export const displayShipmentsBulkActionErrorBanner = createAction(
  '[ShipmentsList] Display Shipments Bulk Action Error Banner',
  props<{ message: string }>()
);
export const closeShipmentsBulkActionErrorBanner = createAction(
  '[ShipmentsList] Close Shipments Bulk Action Error Banner'
);

// Start: NEW isInProgress syncing logic
export const triggerSyncCheck = createAction(
  '[ShipmentsList] Trigger Sync Check'
);
export const getSyncCheckSuccess = createAction(
  '[ShipmentsList] Get Sync Check Success',
  props<{ shipments: SyncCheck[], ellipsisOptions: Array<OptionMenuItem> }>()
);
export const getSyncCheckFailure = createAction(
  '[ShipmentsList] Get Sync Check Failure',
  props<{ error: any }>()
);
// End: NEW isInProgress syncing logic
