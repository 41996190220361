import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { clearUserManagementFiltersAction } from '../store/user-management.actions';

@Injectable({
  // this is provided in the root since the guard is attached to a route on the root
  providedIn: 'root'
})
export class UserManagementResetFiltersGuard {
  constructor(private store: Store) {}

  canDeactivate() {
    this.store.dispatch(clearUserManagementFiltersAction());
    return true;
  }
}
