import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';

import { EMPTY, Observable } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiFailureText } from '../../../../../enum/alert-text.enum';
import { VendorBrokerManagementService } from '../../../services/vendor-broker-management.service';
import { ApiStoreAssociatedSuppliersResponse, AssociatedSupplierItem, AssociatedSuppliersResponse } from '../../../models/associated-suppliers-response';
import { formatLocation, getStatusTag } from '../../../../../utils/supplier-response-format';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { getAssociatedSuppliersSuccess } from '../associated-supplier.actions';
import { Store } from '@ngrx/store';
import { AbstractApiStore } from '@kehe/phoenix-component-store-utils';

export interface AssociatedSuppliersPayload {
  supplierEsn: string;
  searchTerm: string;
  sortDirection: number;
  sortColumn: number;
  masterVersionNumber: number;
  deletedEsnsToSync: string[];
  isSyncing: boolean;
  isAddAssociateSupplierAction: boolean;
}

/**
 * `NonHeadquarterBrokerApiStore` is an Angular service that extends `AbstractApiStore` to get the supplier headquarter broker.
 *
 * @class
 * @extends AbstractApiStore
 *
 * @param {VendorBrokerManagementService} _vendorBrokerManagementService.
 * @param {Store} _store - A redux store that holds the application state.
 */
@Injectable({ providedIn: 'root' })
export class AssociatedSuppliersApiStore extends AbstractApiStore<
  ApiStoreAssociatedSuppliersResponse
> {
  constructor(
    private _vendorBrokerManagementService: VendorBrokerManagementService,
    private _toastService: PhoenixToastService,
    private _store: Store
  ) {
    super();
  }

  loadAssociatedSuppliers$ = this.effect(
    (payload$: Observable<AssociatedSuppliersPayload>) =>
      payload$.pipe(
        tap((payload) => this.setLoading$(payload?.deletedEsnsToSync)),
        switchMap((payload) => {
          if (!payload.supplierEsn) {
            this.setData(undefined);
            return EMPTY;
          }
          
          return this._vendorBrokerManagementService
            .getAssociatedSuppliers(
              payload
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.setResponseData(response, payload)
                  this.displayAssociatedNewSupplierToast(payload, response.masterVersionNumber);
                  this._store.dispatch(getAssociatedSuppliersSuccess());
                },
                () => {
                  this.setError(ApiFailureText.Generic);
                }
              )
            );
        })
      )
  );

  private setLoading$ = this.effect(
    (deletedESNs$: Observable<string[]>) =>
      deletedESNs$.pipe(
        filter(
          (deletedESNs) => deletedESNs.length === 0
        ),
        tap(() => {
          this.startLoading();
        })
      )
  );

  setResponseData(response: AssociatedSuppliersResponse, payload: AssociatedSuppliersPayload) {
    const associatedSuppliersRows = this.getAssociatedSuppliersMapping(response?.payload);
    const apiStoreResponse: ApiStoreAssociatedSuppliersResponse = {
      associatedSuppliers: associatedSuppliersRows,
      masterVersionNumber: response.masterVersionNumber
    }
    this.setData(apiStoreResponse);
    if (payload?.deletedEsnsToSync?.length > 0) {
      this.deleteAssociation$(payload?.deletedEsnsToSync);
    }
  }

  displayAssociatedNewSupplierToast(payload: AssociatedSuppliersPayload, existingVersion: number) {
    if (payload?.masterVersionNumber === existingVersion && payload?.isSyncing && payload?.isAddAssociateSupplierAction) {
      this._toastService.showSuccessToast(
        'Supplier associated successfully!',
        false,
        5000
      );
    }
  }

  getAssociatedSuppliersMapping(associatedSuppliersAll: AssociatedSupplierItem[]) {
    return associatedSuppliersAll.map(
      (associatedSupplier) => ({
        esn: associatedSupplier.esn,
        name: associatedSupplier.name,
        location: formatLocation(associatedSupplier.address),
        status: associatedSupplier.status,
        relationshipFrom: associatedSupplier.relationshipFrom,
        dateAssociated: associatedSupplier.associatedOn,
        tag: getStatusTag(associatedSupplier?.status),
        children: associatedSupplier.relatedSuppliers,
      })
    );
  }

  deleteAssociation$ = this.effect(
    (associatedSupplierEsns$: Observable<string[]>) =>
      associatedSupplierEsns$.pipe(
        withLatestFrom(this.data$),
        map(([esns, data]) => {
          const filteredData = data?.associatedSuppliers.filter(
            (d) => !esns.some((e) => e === d.esn)
          );
          this.setData({associatedSuppliers: filteredData, masterVersionNumber: data?.masterVersionNumber});
        })
      )
  );
}


