import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { Constants } from '../../../../constants/constants';
import * as DistributionCenterSelectors from '@app/shared/state//distribution-center/distribution-center.selectors';
import { BrandListItem } from '../../models/purchasing-options/brand-list-item';
import { SupplierDetail } from '../../models/supplier-detail';
import { editAddNewProductGroupDC, hideAddProductGroupModal } from '../../store/supplier-detail.actions';
import { selectSupplierDetail } from '../../store/supplier-detail.selectors';

@Component({
  selector: 'app-add-product-group-modal',
  templateUrl: './add-product-group-modal.component.html',
  styleUrls: []
})
export class AddProductGroupModalComponent extends Destroyable implements OnInit {

  constructor(
    private _store: Store,
    private router: Router
  ) {
    super();
  }

  @ViewChild('temperatureClassField', { static: true }) public temperatureClassField: any;

  supplier: SupplierDetail;

  public brandList: Array<BrandListItem>;
  public modifiedDCList: Array<object>;
  public form: UntypedFormGroup;
  public isSaveInProgress = false;
  public temperatureClassList: Array<string>;

  public characterCount = 0;
  public maxLength = 30;
  public counter = `${this.characterCount}/${this.maxLength}`;

  ngOnInit() {

    this._store.pipe(
      takeUntil(this.destroy$),
      select(DistributionCenterSelectors.selectDistributionCenterList))
      .subscribe(response => {
        if (response) {
          this.modifiedDCList = this.mapDcToCodeValue(response.slice()).filter(item => !Constants.ExcludedDCs.includes(+item.value));;
        }
      });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSupplierDetail))
      .subscribe((val) => {
        if (val) {
          this.brandList = val.brands;
        }
      });

    this.form = new UntypedFormGroup({
      brands: new UntypedFormControl([]),
      distributionCenters: new UntypedFormControl([]),
      name: new UntypedFormControl(''),
      temperatureClass: new UntypedFormControl()
    });

    this.temperatureClassList = ['Dry', 'Frozen', 'Refrigerated'];

  }

  public cancelModal(): void {
    this._store.dispatch(hideAddProductGroupModal());
  }

  public continue(): void {
    if (this.form.valid) {
      const newProductGroupValues = {...this.form.value};
      newProductGroupValues.dcProductGroupNumber = 'TBD';
      newProductGroupValues.pickupAddress = '';
      newProductGroupValues.distributionCenterNumber = '';
      newProductGroupValues.leadTime = '';
      const editRoute = this.router.url + '/edit';
      this._store.dispatch(editAddNewProductGroupDC({newDCProductGroup : newProductGroupValues}));
      this.router.navigate([editRoute]);
    }
   }

  public handleKeheModalEvents(e: any): void {
    if (e.type === 'close') {
      this.cancelModal();
    }
  }

  public isSaveDisabled(): boolean {
    return !this.form.valid;
  }

  private mapDcToCodeValue(dcList: any[]) {
    return dcList.map(item => ({
      code: item.name.replace('Kehe Dist - ', ''),
      value: item.number,
      codeValue: item.name.replace('Kehe Dist - ', '')
    }));
  }

  public onFocus() {
    this.temperatureClassField.toggle(true);
  }

  public onValueChange(e: any) {
    this.characterCount = e.length;
    this.counter = `${this.characterCount}/${this.maxLength}`;
  }
}
