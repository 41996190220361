<kehe-phoenix-modal [show]="true"
                    [modalTitle]="title"
                    (modalEvent)="handleKeHEModalEvents()"
                    size="md">
  <div body class="body">
    <kehe-phoenix-alert [type]="alertTypes.Warning" [message]="alertMessage" *ngIf="!!alertMessage"
                [hideCloseIcon]="true" stick="true" show="true">
    </kehe-phoenix-alert>
    <ng-container *ngIf="isBlankInvoice">
      <kehe-phoenix-alert [type]="alertTypes.Warning" [message]="blankInvoiceAlertMessage" *ngIf="remittanceAddress"
                    [hideCloseIcon]="true" stick="true" show="true">
      </kehe-phoenix-alert>
      <kehe-phoenix-alert [type]="alertTypes.Error" [message]="remitAddressRequiredMessage" *ngIf="!remittanceAddress"
                    [hideCloseIcon]="true" stick="true" show="true">
      </kehe-phoenix-alert>
    </ng-container>

    <p> {{ message }} </p>

    <div [formGroup]="form" *ngIf="form">
      <div class="mb-3" *ngIf="!filterDocumentTypeId">
        <kendo-label [for]="documentTypeField" text="Type"></kendo-label>
        <kendo-combobox
          #documentTypeField
          formControlName="documentTypeId"
          (focus)="onFocus(documentTypeField)"
          (filterChange)="onFilterChange($event)"
          [data]="groupedData"
          [showStickyHeader]="showStickyHeader"
          [filterable]="true"
          [kendoDropDownFilter]="{operator: 'contains'}"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          placeholder="Select Type"
        ></kendo-combobox>
        <kendo-formerror *ngIf="documentTypeIdField.touched && documentTypeIdField?.errors && documentTypeIdField?.errors?.required">
          Type is required.
        </kendo-formerror>
      </div>
      <div class="mb-3" *ngIf="isBlankInvoice">
        <kendo-formfield>
          <kendo-label text="Remittance Address"></kendo-label>
          <kendo-textbox formControlName="remittanceAddress" [readonly]="true"></kendo-textbox>
          <kendo-formerror>Remittance Address is required.</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="mb-3" *ngIf="expirationDateVisible">
        <kendo-label [for]="expirationDateField" text="Expiration Date"></kendo-label>
        <kendo-datepicker
          #expirationDateField
          formControlName="expires"
          [disabledDates]="disabledDates"
          placeholder='mm/dd/yyyy'
          [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }"
          [format]="'MM/dd/yyyy'">
        </kendo-datepicker>
        <kendo-formerror *ngIf="expiresField.touched && expiresField?.errors?.required">
          Expiration Date is required.
        </kendo-formerror>
        <kendo-formerror *ngIf="expiresField?.errors && !expiresField?.errors?.required">
          Must be a future date
        </kendo-formerror>
      </div>
      <div>
        <kendo-label [for]="documentUploadFiled" text="File Upload"></kendo-label>
        <kendo-formhint *ngIf="hint" class="mt-0 mb-2">{{ hint }}</kendo-formhint>

        <kehe-phoenix-upload
          #documentUploadFiled
          [fileUploads]="fileUploads"
          [uploadRestrictions]="uploadRestrictions"
          [allowMultipleSelect]="allowMultipleSelect"
          (addFiles)="handleAddFiles($event)"
          (removeFiles)="handleRemoveFiles($event)">
        </kehe-phoenix-upload>
      </div>
    </div>
  </div>

  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button classAttr="btn-link btn-medium mr-2" (click)="onCancelClicked()">
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="onUploadClicked()"
                 [disabled]="!isUploadEnabled" [spinner]="isDocumentUploading">
      Upload
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
