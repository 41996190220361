import { DcPaymentHoldDetails } from '@app/modules/supplier-detail/models/dc-payment-hold-details';

export interface SupplierDCPaymentHoldsState {
  changedDcPaymentHolds: DcPaymentHoldDetails[];
}

export function initializeState(): SupplierDCPaymentHoldsState {
  return {
    changedDcPaymentHolds: []
  }
}
