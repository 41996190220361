<div class="col-12 col-md-6 px-4" [formGroup]="form">
  <h4 class="mb-3">Supplier Headquarter Address</h4>

  <kendo-label text="Headquarter Address"></kendo-label>
  <app-contact-card
    *ngIf="contacts$ | async as contacts"
    formControlName="headquarterAddressId"
    [defaultContactTypes]="types.HeadquarterAddress"
    [contacts]="contacts"
    [isInProgress]="saving"
    [saveContactError]="error"></app-contact-card>
  <kendo-formhint>Manage HQ Address on General Tab</kendo-formhint>
</div>
