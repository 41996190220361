import { createSelector } from '@ngrx/store';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { FeatureFlags } from '@app/feature-flag';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { ManageProgressFeatureOption, TopBarProfileOption } from '@kehe/phoenix-top-bar';
import { Constants } from './constants/constants';
import { TopNavProfileOptionName } from './enum/top-nav-profile-option-name.enum';

export const selectTopNavProfileOptions = createSelector(
    selectIsFeatureFlagOn(FeatureFlags.WorkQueueFlag.key),
    AuthenticationSelectors.selectUserActions,
    (workQueueFlag, userActions) => {
      const profileOptions: TopBarProfileOption[] = [];
  
      if (workQueueFlag && userActions && userActions.includes(Constants.UserActions.WorkQueueRead)) {
        profileOptions.push({
          name: TopNavProfileOptionName.WorkQueue,
          data: '',
          icon: null,
        });
      }
      profileOptions.push(ManageProgressFeatureOption);
  
      // Logout option must be last always
      profileOptions.push({
        name: TopNavProfileOptionName.Logout,
        data: '',
        icon: null,
      });
      return profileOptions;
    }
);

export const selectIsChatBotAvailable = createSelector(
  AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.ChatBotCreate),
  selectIsFeatureFlagOn(FeatureFlags.Chatbot.key),
  (doesUserHaveAction, isFlagOn) => doesUserHaveAction && isFlagOn
);