import { on, createReducer } from '@ngrx/store';
import { User } from 'oidc-client-ts';
import * as AuthenticationActions from '@app/authentication/authentication.actions';
import { UserDetailRecord } from '@app/modules/user-management/model/service/user-management';

export const authKey = 'authentication';

export interface State {
  user: User | null;
  legacyToken: string | null;
  userDetail: UserDetailRecord;
}

export const initialState: State = {
  user: null,
  legacyToken: null,
  userDetail: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthenticationActions.logInSuccess, (state, action) => {
    return {
      ...state,
      user: action.user,
    };
  }),
  on(AuthenticationActions.getLegacyTokenSuccess, (state, action) => {
    return {
      ...state,
      legacyToken: action.legacyToken,
    };
  }),
  on(AuthenticationActions.getUserDetailSuccess, (state, { user }) => ({
    ...state,
    userDetail: user,
  }))
);

export const getUser = (state: State) => state && state.user;
export const getLegacyToken = (state: State) => state.legacyToken;
export const getUserDetail = (state: State) => state && state.userDetail;
