import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SupplierUsersRoutingModule } from './supplier-users-routing.module';
import { SupplierUsersTabComponent } from './pages/supplier-users-tab/supplier-users-tab.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { PhoenixButtonModule, PhoenixMenuButtonModule } from '@kehe/phoenix-button';
import { GridModule } from '@progress/kendo-angular-grid';
import { StoreModule } from '@ngrx/store';
import * as fromSupplierUsers from './store/supplier-users.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SupplierUsersEffects } from './store/supplier-users.effects';
import { PhoenixAlertsModule, PhoenixToastService } from '@kehe/phoenix-notifications';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { PhoenixUploadModule } from '@kehe/phoenix-upload';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LabelModule } from '@progress/kendo-angular-label';
import { CheckBoxModule, InputsModule } from '@progress/kendo-angular-inputs';
import { PhoenixProgressTrackerModule } from '@kehe/phoenix-progress-tracker';
import { AddUserWizardComponent } from './components/add-user-wizard/add-user-wizard.component';
import { UserDeleteModalComponent } from './components/user-delete-modal/user-delete-modal.component';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';
import { EmptyValuePipe } from '@app/shared/pipes/empty-value.pipe';


@NgModule({
  declarations: [
    SupplierUsersTabComponent,
    AddUserWizardComponent,
    UserDeleteModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    HttpClientModule,
    LabelModule,
    InputsModule,
    ComboBoxModule,
    PhoenixAlertsModule,
    SupplierUsersRoutingModule,
    ButtonModule,
    PhoenixButtonModule,
    PhoenixMenuButtonModule,
    CheckBoxModule,
    GridModule,
    PhoenixProgressTrackerModule,
    ComboBoxModule,
    DatePickerModule,
    PhoenixUploadModule,
    PhoenixModalModule,
    PhoenixIndicatorsModule,
    StoreModule.forFeature('supplierUsers', fromSupplierUsers.reducer),
    EffectsModule.forFeature([SupplierUsersEffects]),
    EmptyValuePipe,
  ],
  providers: [PhoenixToastService, DatePipe],
  exports: [
    SupplierUsersTabComponent,
  ]
})
export class SupplierUsersModule { }
