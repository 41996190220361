import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { SupplierDetailService } from '../../services/supplier-detail.service';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, catchError, map, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getTermsForm,
  selectMarketingAllowanceList,
  selectPaymentTermsList,
  selectSupplierDetail,
} from '../supplier-detail.selectors';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { SupplierTermsDropdownsService } from '../../services/supplier-terms-dropdowns.service';
import { SupplierCodeValueService } from '../../services/supplier-code-value.service';
import { SupplierDocuSignService } from '../../services/supplier-docusign.service';

import * as SupplierDetailActions from '../supplier-detail.actions';

@Injectable()
export class SupplierTermsTabEffects {
  constructor(
    private readonly actions$: Actions,

    private readonly store: Store,
    private readonly supplierDetailService: SupplierDetailService,
    private readonly termsDropdownsService: SupplierTermsDropdownsService,
    private readonly supplierDocuSignService: SupplierDocuSignService,
    private readonly supplierCodeValueService: SupplierCodeValueService,
    private readonly toastService: PhoenixToastService
  ) {}

  supplierTermsRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.clickTermsRefreshButton),
      withLatestFrom(this.store.select(selectSupplierDetail)),
      switchMap(([, { esn }]) => {
        return this.supplierDetailService.refreshTerms(esn).pipe(
          map(() => SupplierDetailActions.termsRefreshSuccess()),
          catchError(() => of(SupplierDetailActions.termsRefreshFailed()))
        );
      })
    )
  );

  supplierTermsRefreshSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SupplierDetailActions.termsRefreshSuccess),
        map(() =>
          this.toastService.showSuccessToast('Terms refreshed successfully!')
        )
      ),
    { dispatch: false }
  );

  supplierTermsRefreshFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SupplierDetailActions.termsRefreshFailed),
        map(() => this.toastService.showErrorToast('Terms refresh failed!'))
      ),
    { dispatch: false }
  );

  loadTermsDropdown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.loadSupplierDetailSuccess),
      switchMap(() => {
        return this.termsDropdownsService
          .getTermsDropdownLists()
          .pipe(
            map((dropdownData) =>
              SupplierDetailActions.loadTermsDropdownSuccess({ dropdownData })
            )
          );
      })
    )
  );

  getPaymentTermsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.getPaymentTermsList),
      withLatestFrom(this.store.select(selectPaymentTermsList)),
      switchMap(([, list]) => {
        // Cache: If we already have the data, just return from store
        if (list?.length) {
          return of(
            SupplierDetailActions.getPaymentTermsListSuccess({
              paymentTerms: list,
            })
          );
        }
        return this.supplierCodeValueService.get('paymentterms').pipe(
          map((response) =>
            SupplierDetailActions.getPaymentTermsListSuccess({
              paymentTerms: response.data,
            })
          )
        );
      })
    )
  );

  getMarketingAllowanceList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.getMarketingAllowanceList),
      withLatestFrom(this.store.select(selectMarketingAllowanceList)),
      switchMap(([, list]) => {
        // Cache: If we already have the data, just return from store
        if (list?.length) {
          return of(
            SupplierDetailActions.getMarketingAllowanceListSuccess({
              marketingAllowance: list,
            })
          );
        }

        return this.supplierCodeValueService.get('marketingallowance').pipe(
          map((response) =>
            SupplierDetailActions.getMarketingAllowanceListSuccess({
              marketingAllowance: response.data,
            })
          )
        );
      })
    )
  );

  saveTermsForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.saveTermsForm),
      withLatestFrom(this.store.select(getTermsForm)),
      switchMap(([action, form]) => {
        let isContractAgreementUploaded = false;
        if (action.addendumAsBase64String) {
          form.terms.addendumAsBase64String = action.addendumAsBase64String;
          isContractAgreementUploaded = true;
        }
        return this.supplierDetailService.saveTerms(form).pipe(
          map((supplier) =>
            SupplierDetailActions.saveTermsFormSuccess({
              supplier,
              isContractAgreementUploaded,
            })
          ),
          catchError(() => of(SupplierDetailActions.saveTermsFormError()))
        );
      })
    )
  );

  saveTermsFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierDetailActions.saveTermsFormSuccess),
      withLatestFrom(this.store.select(selectSupplierDetail)),
      filter(
        ([action, detail]) =>
          !!(
            action.isContractAgreementUploaded &&
            detail.docuSignData &&
            detail.docuSignData.envelopeId &&
            detail.docuSignData.signee
          )
      ),
      switchMap(([, detail]) => {
        return this.supplierDocuSignService
          .sendDocuSignEmail(detail.esn, detail.docuSignData.signee)
          .pipe(
            map((supplier) =>
              SupplierDetailActions.sendDocuSignEmailSuccess({ supplier })
            ),
            catchError(() => of(SupplierDetailActions.sendDocuSignEmailError()))
          );
      })
    )
  );
}
