import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import {
  selectDeleteSupplierError,
  selectSaveInProgress,
  selectSupplierDetail
} from '../../store/supplier-detail.selectors';
import { takeUntil } from 'rxjs/operators';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { SupplierDetail } from '../../models/supplier-detail';
import { deleteSupplier, hideDeleteSupplierModal } from '../../store/supplier-detail.actions';

@Component({
  selector: 'app-delete-supplier-modal',
  templateUrl: './delete-supplier-modal.component.html',
  styleUrls: ['./delete-supplier-modal.component.scss']
})
export class DeleteSupplierModalComponent extends Destroyable implements OnInit {

  alertTypes = AlertTypes;

  supplier: SupplierDetail;
  isDeleteInProgress: boolean;
  error: string;

  constructor(private _store: Store) { super(); }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSupplierDetail))
      .subscribe(supplier => {
        this.supplier = supplier;
    });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSaveInProgress))
      .subscribe(val => {
        this.isDeleteInProgress = val;
    });
    this._store.pipe(
      select(selectDeleteSupplierError),
      takeUntil(this.destroy$))
      .subscribe(val => {
        this.error = val;
      });
  }

  deleteSupplier() {
    this._store.dispatch(deleteSupplier());
  }

  cancelModal() {
    this._store.dispatch(hideDeleteSupplierModal());
  }

  handleKeheModalEvents(e) {
   this.cancelModal();
  }

  isDeleteDisabled() {
    return this.isDeleteInProgress;
  }
}

