<div class="row mt-5" *ngIf="!isDraft()">
  <div class="col"></div>
  <div class="col-6">
    <div class="text-center">
      <span class="icon fas fa-seedling mb-3"></span>
      <h2>View Contacts in KeHE Connect</h2>
      <p>We are working towards bringing Contacts into Connect Enterprise, till then Contacts are still managed and
        stored in
        KeHE CONNECT.</p>
      <kehe-phoenix-button classAttr="btn-secondary btn-medium" (click)="goToContactsV1()">View Contacts</kehe-phoenix-button>
    </div>
  </div>
  <div class="col"></div>
</div>
<app-supplier-contacts-view *ngIf="isDraft()"></app-supplier-contacts-view>