import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocationsService } from '../services/locations.service';
import { selectSupplierDetail } from '../../supplier-detail/store/supplier-detail.selectors';

import * as supplierLocationsActions from './supplier-locations.actions';
import * as supplierDetailActions from '../../supplier-detail/store/supplier-detail.actions'

@Injectable()
export class SupplierLocationsEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _locations: LocationsService,
  ) {}

  updateContacts$ = createEffect(() => this._actions$
    .pipe(
      ofType(
        supplierDetailActions.loadSupplierDetailSuccess,
        supplierDetailActions.saveSupplierFormSuccess,
        supplierDetailActions.addSupplierContactSuccess,
        supplierDetailActions.saveGeneralFormSuccess,
      ),
      withLatestFrom(this._store.select(selectSupplierDetail)),
      map(
        ([,supplier]) => supplierLocationsActions
        .updateContacts({
          contacts: supplier.contacts ?? [],
          headquarterAddress: supplier.headquarterAddressId
        })
      )
    )
  );

  removeContact$ = createEffect(() => this._actions$
    .pipe(
      ofType(supplierLocationsActions.removeContact),
      withLatestFrom(this._store.select(selectSupplierDetail)),
      switchMap(
        ([{ contact }, supplier]) => 
        this._locations.delete(contact.uniqueId, supplier.esn).pipe(
          map(({ contacts }) => supplierDetailActions.loadSupplierDetailSuccess({ supplier: {
              ...supplier,
              contacts
            }, dcList: [] })
          ),
          catchError(({ error }) => of(supplierLocationsActions.updateLocationError({ error })))
        )
      )
    )
  );
}
