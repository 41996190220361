import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AddressService } from '@kehe/phoenix-address';
import { ContactFormService } from '@kehe/phoenix-contact';
import {
  IKeheModalEvent,
  KeheModalEventsTypes,
} from '@kehe/phoenix-modal';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../../../abstract/destroyable';
import { v4 as uuidv4 } from 'uuid';
import { PickupAddressMode } from '../../../../../supplier-detail/models/purchasing-options/pickup-address-mode.enum';
import { AddDeliveredContactStore } from './add-delivered-contact.store';
import { Contact } from '../../../../../../models/contact';

export enum SupplierContactType {
  PickupAddress = 'Pickup Address',
}

export interface AddressDropdownSource {
  title: string;
  subTitle: string;
  value: string;
}

@Component({
  selector: 'app-add-delivered-contact',
  templateUrl: './add-delivered-contact.component.html',
  styleUrls: ['./add-delivered-contact.component.scss'],
  providers: [AddDeliveredContactStore],
})
export class AddDeliveredContactComponent
  extends Destroyable
  implements OnInit, OnChanges
{
  @Input() parent: UntypedFormGroup;
  @Output() handleClose = new EventEmitter();

  viewMode: PickupAddressMode;
  showModal$: Observable<boolean>;
  showSupplierContactError$: Observable<boolean>;
  showAddContactPreviewModal$ =
    this._addDeliveredContactStore.selectShowAddContactPreviewModal$;
  supplierContactErrorMessage: string;
  supplierContacts: Contact[];

  isAddingAddress = false;
  dropdownDataSource: AddressDropdownSource[] = [];
  selectedValue: AddressDropdownSource;
  contactTypeDataSource = [
    this.displayValueForContactType(SupplierContactType.PickupAddress),
  ];
  addressForm: UntypedFormGroup;
  addAddressForm: UntypedFormGroup;
  selectedContact: Contact;
  phoneExtPattern: RegExp;
  emailPattern: RegExp;

  get pickupAddressNotesCounter() {
    return this.addAddressForm.get('pickupAddressNotes')?.value.length || 0;
  }

  get modalTitle() {
    return this.viewMode === PickupAddressMode.Add
      ? 'Add Contact'
      : 'Edit Address';
  }

  get showButtonGroup() {
    return this.viewMode === PickupAddressMode.Add;
  }

  get showAddButton() {
    return this.viewMode === PickupAddressMode.Add;
  }

  get showUpdateButton() {
    return this.viewMode === PickupAddressMode.Edit;
  }

  get disableAddAndUpdate() {
    return (
      !(
        this.addAddressForm &&
        this.addAddressForm.valid &&
        this.addAddressForm.dirty
      ) || this.isAddingAddress
    );
  }

  constructor(
    private _addDeliveredContactStore: AddDeliveredContactStore,
    private _addressService: AddressService,
    private _formBuilder: UntypedFormBuilder,
    private _contactFormService: ContactFormService
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parent) {
      this._addDeliveredContactStore.connectFormGroup(this.parent);
    }
  }

  ngOnInit() {
    this.initializeForm();
    this.showSupplierContactError$ =
      this._addDeliveredContactStore.selectShowSupplierContactError$;
    this._addDeliveredContactStore.selectSupplierContactErrorMessage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val) {
          this.supplierContactErrorMessage = val;
        }
      });
    this.showModal$ = this._addDeliveredContactStore.selectShowAddContactModal$;
    this._addDeliveredContactStore.selectSupplierContactDropdownSource$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.supplierContacts = val as any;
        this.dropdownDataSource = val?.map((c) => {
          const addressParts = [];
          addressParts.push(c.address.street1);
          if (c.address.street2) {
            addressParts.push(c.address.street2);
          }
          addressParts.push(c.address.city);
          if (c.address.state) {
            addressParts.push(c.address.state.name);
            addressParts.push(`${c.address.state.code} ${c.address.zipCode}`);
          } else {
            addressParts.push(`${c.address.province} ${c.address.zipCode}`);
          }
          const formattedAddress = addressParts.join(', ');
          const source: AddressDropdownSource = {
            title: c.address.name,
            subTitle: formattedAddress,
            value: c.uniqueId,
          };
          return source;
        });
      });
    this._addDeliveredContactStore.selectIsAddAddressInProgress$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.isAddingAddress = val;
      });
    this._addDeliveredContactStore.selectPickupAddressContact$
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((val) => this.updateContactData(val));
    this._addDeliveredContactStore.selectPickupAddressMode$
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((val) => (this.viewMode = val));
  }

  initializeForm() {
    this.emailPattern = this._contactFormService.getEmailRegex();
    this.phoneExtPattern = this._contactFormService.getPhoneExtRegex();
    this.addressForm = this._addressService.createDefaultAddressFormGroup();
    this.addressForm.get('street2');
    this.addressForm.get('state').setValidators([Validators.required]);
    this.addressForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        const isInternational = this._addressService.isInternational(val);
        if (isInternational) {
          this.addressForm.get('state').setValidators(null);
          this.addressForm.get('state').setErrors(null);
          this.addressForm.get('province').setValidators([Validators.required]);
        } else {
          this.addressForm.get('state').setValidators([Validators.required]);
          this.addressForm.get('province').setValidators(null);
          this.addressForm.get('province').setErrors(null);
        }
      });
    this.addAddressForm = this._formBuilder.group({
      contactType: [
        {
          value: this.displayValueForContactType(
            SupplierContactType.PickupAddress
          ),
          disabled: true,
        },
        [Validators.required],
      ],
      addressForm: this.addressForm,
      contact: this._formBuilder.group({
        name: ['', [Validators.maxLength(100)]],
        phone: ['', Validators.required],
        phoneExtension: [
          '',
          [
            Validators.maxLength(4),
            this.stringPatternValidator(
              this._contactFormService.getPhoneExtRegex(),
              false
            ),
          ],
        ],
        fax: [''],
        email: [
          '',
          [
            this.stringPatternValidator(
              this._contactFormService.getEmailRegex(),
              false
            ),
            Validators.maxLength(100),
          ],
        ],
      }),
      pickupAddressNotes: ['', Validators.maxLength(30)],
    });
  }

  get addressTitle() {
    return this.viewMode === PickupAddressMode.Add
      ? 'Add Address'
      : 'Edit Address Information';
  }

  get contactTitle() {
    return this.viewMode === PickupAddressMode.Add
      ? 'Add Contact Information'
      : 'Edit Contact Information';
  }

  updateContactData(contact: Contact) {
    if (contact) {
      this.selectedContact = contact;
      this.addressForm.patchValue(contact.address);
      this.addAddressForm.get('contact').patchValue(contact);
      this.addAddressForm.get('pickupAddressNotes').patchValue(contact.notes);
    }
  }

  selectionChange(value: AddressDropdownSource): void {
    this.selectedValue = value;
  }

  handleAddClicked() {
    if (!this.disableAddAndUpdate) {
      this._addDeliveredContactStore.parentForm = this.parent;

      this._addDeliveredContactStore.tryAddPickupAddressContact(
        this.getContactFromForm()
      );
    }
  }

  handleUpdateClicked() {
    this._addDeliveredContactStore.tryAddPickupAddressContact(
      this.getContactFromForm()
    );
  }

  private getContactFromForm(): Contact {
    const uniqueId = this.selectedContact
      ? this.selectedContact.uniqueId
      : uuidv4();
    const contact: Contact = {
      ...this.addAddressForm.get('contact').value,
      uniqueId,
      contactType: SupplierContactType.PickupAddress,
      notes: this.addAddressForm.get('pickupAddressNotes').value,
    };
    contact.address = this.addressForm.value;
    return contact;
  }

  private displayValueForContactType(type: SupplierContactType) {
    if (type === SupplierContactType.PickupAddress) {
      return 'Pickup Address';
    }
  }
  private stringPatternValidator(
    stringRegex: RegExp,
    required = true
  ): ValidatorFn {
    // tslint:disable-next-line: no-any
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value: string = control.value;
      if (
        required ||
        (value !== null && value !== undefined && value.length > 0)
      ) {
        const pattern = stringRegex.test(control.value);
        return !pattern ? { pattern: { value: control.value } } : null;
      } else {
        return null;
      }
    };
  }

  closeModal(event?: IKeheModalEvent) {
    if (event?.type === KeheModalEventsTypes.OutsideClick) {
      return;
    }
    this.handleClose.emit();
  }
}
