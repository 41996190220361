import { Constants } from '../constants/constants';
import { InboundRouteType } from '../models/dcpg-inbound-route-type';

export const getInboundRouteTypeName = (type: InboundRouteType): string => Constants.InboundRouteTypes.find(item => item.type == type)?.name ?? 'None';

export const getNameOfEnumAttr = (enumType: any, value: string | number, ignoreEmpty: boolean): string => {
    if (ignoreEmpty && (!enumType || !value)) {
        return;
    }

    return Object.keys(enumType).find(key => enumType[key] === value);
}