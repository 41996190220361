<kehe-phoenix-slide-panel [overlay]="true" [width]="500" position="right" [visibility]="showSidePanel === true ? 'show' : ''"
  (slidePanelEvent)="closePanel()">
  <div class="header d-flex align-items-bottom">
    <h3 class="mr-3">Filter</h3>
    <kehe-phoenix-button classAttr="btn-link" [disabled]="noValues" (click)="handleReset()">Clear All</kehe-phoenix-button>
  </div>
  <form [formGroup]="form" *ngIf="form">
    <div class="form-group">
      <kendo-formfield>
        <kendo-label [for]="dcField" text="Distribution Centers"></kendo-label>
        <kendo-multiselect #dcField [data]="dcList" formControlName="dcSelection"
          placeholder="Search by DC Name or DC No." [textField]="'label'" [valueField]="'value'" [filterable]="true"
          [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"></kendo-multiselect>
      </kendo-formfield>
    </div>
    <div>
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="brandsField" text="Brands"></kendo-label>
          <kendo-combobox #brandsField [data]="brands" placeholder="Search Brands" (focus)="brandsField.toggle()"
            formControlName="brand" [textField]="'name'" [valueField]="'code'" [filterable]="true" [kendoDropDownFilter]="{ operator: 'contains'}">
          </kendo-combobox>
        </kendo-formfield>
      </div>
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="temperatureClassField" text="Temp. Class"></kendo-label>
          <kendo-combobox #temperatureClassField [data]="tempClasses" placeholder="Select Temp. Class"
            (focus)="temperatureClassField.toggle()" formControlName="tempClass" [filterable]="true"
            [kendoDropDownFilter]="{ operator: 'contains'}">
          </kendo-combobox>
        </kendo-formfield>
      </div>
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="buyerField" text="Buyer"></kendo-label>
          <kendo-combobox #buyerField (focus)="buyerField.toggle()" formControlName="buyer" [data]="buyers"
            [textField]="'name'" [valueField]="'code'" [filterable]="true"
            placeholder="Search Buyer" [kendoDropDownFilter]="{ operator: 'contains'}">
          </kendo-combobox>
        </kendo-formfield>
      </div>
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="deliveryMethodField" text="Delivery Method"></kendo-label>
          <kendo-combobox #deliveryMethodField formControlName="deliveryMethod" [data]="deliveryMethodList" [textField]="'label'" [valueField]="'value'"
                          [filterable]="true" (focus)="deliveryMethodField.toggle()"
                          placeholder="Select Delivery Method" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}">
          </kendo-combobox>
        </kendo-formfield>
      </div>
      <div class="form-group">
        <kendo-formfield>
          <kendo-label [for]="dcField" text="Inbound Route Type"></kendo-label>
          <kendo-multiselect #dcField [data]="inboundRouteTypes" formControlName="inboundRoundTypes"
            placeholder="Select Inbound Route Type" [textField]="'label'" [valueField]="'value'" [filterable]="true"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"></kendo-multiselect>
        </kendo-formfield>
      </div>
    </div>
  </form>

  <div class="actions">
    <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="applyFilter()" [disabled]="form.pristine">
      Apply
    </kehe-phoenix-button>
    <kehe-phoenix-button class="cancel" classAttr="btn-secondary btn-medium" (click)="closePanel()">Cancel</kehe-phoenix-button>
  </div>
</kehe-phoenix-slide-panel>
