import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-bulk-edit-notes-form',
  templateUrl: './bulk-edit-notes-form.component.html',
})
export class BulkEditNotesFormComponent {
  @Input() form: UntypedFormGroup;
}
