import {Address} from '../models/address';
import { ContactType } from '../models/contact';
import {Country} from '../models/country';
import { startCase } from 'lodash';
import { MaxCharLengths } from '@kehe/phoenix-address/lib/models/max-char-lengths';
import { Constants } from '../constants/constants';

export function defaultAddress(): Address {
  return new Address('', '', '', '', null, '', '', defaultCountry(),
    '', '', '', '', '');
}

export function  defaultCountry(): Country {
  return {code: 'USA', name: 'United States'};
}

export function cleanAddress(address: Address) {
  if (address) {
    cleanCountry(address);
    cleanState(address);
    if (address.phone) {
      address.phone = address.phone.trim();
    }
    if (address.fax) {
      address.fax = address.fax.trim();
    }
  }
  return address;
}

export function cleanCountry(address: Address) {
  if (address.country) {
    if (!address.country.code || address.country.code.length === 0) {
      address.country = null;
    } else {
      if (address.country.name) {
        address.country.name = startCase(address.country.name.trim().toLowerCase());
      }
    }
  } else {
    address.country = null;
  }
}

export function cleanState(address: Address) {
  if (address.state) {
    if (!address.state.code || address.state.code.length === 0) {
      address.state = null;
    } else {
      if (address.state.name) {
        address.state.name = startCase(address.state.name.trim().toLowerCase());
      }
    }
  } else {
    address.state = null;
  }
}

export function getMaxLengths(contactType: ContactType): MaxCharLengths {
  return contactType === ContactType.PickupAddress ? Constants.AddressFieldMaxCharLengthsPickup : Constants.AddressFieldMaxCharLengthsDefault;
}
