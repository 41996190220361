import { Injectable } from '@angular/core';
import { BreakpointService } from '@kehe/phoenix-utils';
import { ComponentStore } from '@ngrx/component-store';
import { AssociatedSuppliersTabGridStore } from '../associated-suppliers-tab-grid/associated-suppliers-tab-grid.store';
import { AssociatedSupplierItem } from '../../../models/associated-suppliers-response';
import { formatLocation, getStatusTag } from '../../../../../utils/supplier-response-format';

export interface AssociatedSuppliersTabChildrenGridState {
  associatedSuppliers: AssociatedSupplierItem[];
}

export const initialState: AssociatedSuppliersTabChildrenGridState = {
  associatedSuppliers: null,
};

@Injectable()
export class AssociatedSuppliersTabChildrenGridStore extends ComponentStore<AssociatedSuppliersTabChildrenGridState> {
  constructor(
    private _breakpointService: BreakpointService,
    private _associatedSuppliersTabGridStore: AssociatedSuppliersTabGridStore
  ) {
    super(initialState);
  }

  private associatedSuppliers$ = this.select(
    (store) => store.associatedSuppliers
  );

  private gridRows$ = this.select(
    this.associatedSuppliers$,
    (associatedSuppliers) =>
      associatedSuppliers.map((associatedSupplier) => ({
        esn: associatedSupplier.esn,
        name: associatedSupplier.name,
        location:
          formatLocation(
            associatedSupplier.address
          ),
        status: associatedSupplier.status,
        relationshipFrom: associatedSupplier.relationshipFrom,
        dateAssociated: associatedSupplier.associatedOn,
        tag: getStatusTag(
          associatedSupplier?.status
        ),
        children: associatedSupplier.relatedSuppliers,
      }))
  );

  readonly vm$ = this.select(
    this.state$,
    this.gridRows$,
    (state, childrenRows) => ({
      ...state,
      childrenRows,
    })
  );
}
