<div class="container-fluid w-100 px-0">
  <header>
    <ng-container *ngIf="displayEnterpriseOutageBanner$ | async as outageBanner">
      <kehe-phoenix-global-alert
      *ngIf="!outageAlertClosed"
      [showClose]="true"
      [type]="'warning'"
      (closeEvent)="handleCloseOutageAlert()"
    >
      <div class="global-alert-container" content>
        <strong *ngIf="isSmallOrBelowView$ | async">
          <span class="fas fa-warning"></span>
          {{outageBanner.message}}
        </strong>
        <h4 *ngIf="(isSmallOrBelowView$ | async) === false">
          <em class="fas fa-warning"></em>
          {{outageBanner.message}}
        </h4>
      </div>
    </kehe-phoenix-global-alert>
    </ng-container>

    <kehe-phoenix-top-bar
      appTitle="Enterprise"
      appIcon="fas fa-globe"
      appIconColor="#44C4DA"
      [userName]="userFirstName$ | async"
      [showSearch]="false"
      [notificationShow]="isUserNotificationsEnabled$ | async"
      [notifications]="userNotifications$ | async"
      [notificationCount]="userNotificationCount$ | async"
      (notificationIconClick)="onNotificationIconClick($event)"
      (notificationRedirect)="onNotificationRedirect($event)"
      (clearNotifications)="onClearNotifications($event)"
      [profileOptions]="profileOptions$ | async"
      [helpShow]="false"
      (userNameOptionClick)="userSectionClick($event)"
      [externalEvents]="topBarExternalEventsSubject?.asObservable()"
    >
    </kehe-phoenix-top-bar>
    <app-om-navigation></app-om-navigation>
  </header>
</div>

<div
  class="container d-flex flex-column pt-2 pb-5 main-container"
  #mainContainer
>
  <router-outlet></router-outlet>
</div>

<div class="container-fluid w-100 px-0">
  <footer>
    <kehe-phoenix-feedback position="right"></kehe-phoenix-feedback>
    <kehe-phoenix-footer
      privacyDocsUrl="https://kehe-connect.s3.amazonaws.com/prod/public_common/managed-content/static_pages/"
    ></kehe-phoenix-footer>
  </footer>
</div>

<app-promise-modal></app-promise-modal>
<app-user-agreement-modal
  *ngIf="isUserAgreementEnabled$ | async"
></app-user-agreement-modal>

<app-export-hierachies-modal *ngIf="(isExportHierarchiesModalOpen$ | async)"></app-export-hierachies-modal>

@if (isQbotChatAvailable$ | async) {
  <app-q-bot></app-q-bot>
}