import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromReducer from '../reducer/export-hierarchies-modal.reducer';

export const selectExportHierarchiesModalState = createFeatureSelector<fromReducer.ExportHierarchiesModalState>(
    fromReducer.key
);

export const selectExportHierarchiesModalViewModel = createSelector(
    selectExportHierarchiesModalState,
    (state) => state
);

export const selectIsExportHierarchiesOpen = createSelector(
    selectExportHierarchiesModalState,
    (state) => state.isOpen
);
