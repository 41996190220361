import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map, retry } from 'rxjs/operators';
import { Document } from '../models/document';
import { DocumentUploadRequest } from '../models/document-upload-request';
import { DocumentGetUploadUrlResponse } from '../models/document-get-upload-url-response';
import { DocumentCreateRequest } from '../models/document-create-request';
import { DocumentType } from '../models/document-type';

@Injectable({
  providedIn: 'root'
})
export class SupplierDocumentsService {

  constructor(private httpClient: HttpClient) {
  }

  getDocuments(esn: string): Observable<Document[]> {
    return this.httpClient
      .get<Document[]>(
        `${environment.supplierApi}/api/documents/${esn}`
      )
      .pipe(retry(1));
  }

  deleteDocument(document: Document): Observable<void> {
    return this.httpClient
      .delete<void>(
        `${environment.supplierApi}/api/documents/${document.uniqueId}`
      )
      .pipe(retry(1));
  }

  getDocumentTypes(reasonType: string, supplierType: string): Observable<DocumentType[]> {
    const request: any = {
      supplierTypeId: supplierType,
      onboardingReasonId: reasonType,
    };
    return this.httpClient
      .get<DocumentType[]>(
        `${environment.supplierApi}/api/documents/types`,
        { params: request }
      )
      .pipe(retry(1));
  }


  uploadAndSaveDocument(esn: string, request: DocumentUploadRequest) {

    this.getUploadUrl(esn, request.file.name).pipe(retry(1)).subscribe((getUploadUrlResponse) => {
      const createDocumentRequest: DocumentCreateRequest = {
        documentTypeId: request.documentTypeId,
        esn: esn,
        originalFilename: request.file.name,
        uniqueId: getUploadUrlResponse.uniqueId
      };

      this.createDocument(createDocumentRequest).subscribe((document) => console.log(document),
        (error) => console.log(error));
    }, (error) => console.log(error));
  }

  public createDocument(request: DocumentCreateRequest) {
    return this.httpClient.post<Document>(`${environment.supplierApi}/api/documents`, request).pipe(retry(1));
  }

  public getUploadUrl(esn: string, originalFilename: string): Observable<DocumentGetUploadUrlResponse> {
    return this.httpClient
      .get<DocumentGetUploadUrlResponse>(
        `${environment.supplierApi}/api/documents/upload-url/${esn}/${encodeURIComponent(originalFilename)}`
      )
      .pipe(retry(1));
  }

  uploadToS3(file: any, url: string): Observable<Object> {
    const headers = new HttpHeaders().set('Content-Type', '');
    return this.httpClient.put(url, file, { headers }).pipe(retry(1));
  }

  getPreSignedDownloadURL(esn: string, uniqueId: string): Observable<string> {
    return this.httpClient
      .get<{ url: string }>(
        `${environment.supplierApi}/api/documents/download-url/${esn}/${uniqueId}`
      )
      .pipe(retry(1), map((response) => response.url));
  }

  getPrivateAssetsPreSignedDownloadURL(filename: string): Observable<string> {
    return this.httpClient
      .get<{ url: string }>(
        `${environment.supplierApi}/api/private-assets/${filename}`
      )
      .pipe(retry(1), map((response) => response.url));
  }

  updateDocumentStatus(esn: string, document: Document): Observable<any> {
    const request = {
      statusId: document.status,
      statusNotes: document.statusNotes,
    };
    return this.httpClient.put<any>(`${environment.supplierApi}/api/documents/update-status/${esn}/${document.uniqueId}`, request);
  }
}
