import { Component, Input } from '@angular/core';
import { ContactType } from '../../../models/contact';
import { UntypedFormGroup } from '@angular/forms';
import { ContactFormService } from '@kehe/phoenix-contact';
import { Constants } from '../../../constants/constants';
import { getMaxLengths } from '../../../utils/address-utils';
import { LabelConfig } from '@kehe/phoenix-address/lib/models/label-config';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  contactTypes = ContactType;
  emailMaxLength = Constants.AddressFieldEmailMaxCharLength;
  remitNameMaxLength = Constants.AddressFieldRemitNameMaxCharLength;
  pickupNotesMaxLength = Constants.AddressFieldPickupNotesMaxCharLength;

  @Input()
  defaultContactType: ContactType;

  @Input()
  form: UntypedFormGroup;

  @Input()
  hideContactType = false;

  phoneExtPattern: RegExp;
  emailPattern: RegExp;

  get labelConfig(): LabelConfig | null {
    if (this.defaultContactType === ContactType.PickupAddress) {
      return {
        name: 'Pickup Location Name'
      }
    }

    if (this.defaultContactType === ContactType.DeliveredAddress) {
      return {
        name: 'Delivered Location Name'
      }
    }

    return null
  }

  get maxLengths() {
    return getMaxLengths(this.defaultContactType);
  }

  get shouldDisplayAddressNameField() {
    return this.defaultContactType === ContactType.PickupAddress || this.defaultContactType === ContactType.DeliveredAddress;
  }

  get shouldDisplayContactNameField() {
    return this.defaultContactType !== ContactType.PickupAddress && this.defaultContactType !== ContactType.DeliveredAddress;
  }

  get emailCounter(): string {
    return `${this.form?.get('contact.email')?.value.length ?? 0} / ${this.emailMaxLength}`;
  }

  get remitNameCounter(): string {
    return `${this.form?.get('address.name')?.value.length ?? 0} / ${this.remitNameMaxLength}`;
  }

  get pickupNotesCounter(): string {
    return `${this.form?.get('pickupAddressNotes')?.value.length ?? 0} / ${this.pickupNotesMaxLength}`;
  }

  constructor(private _contactFormService: ContactFormService) {
    this.emailPattern = this._contactFormService.getEmailRegex();
    this.phoneExtPattern = this._contactFormService.getPhoneExtRegex();
  }
}
