import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { QBotViewModel } from "./q-bot.vm";

export interface QBotState {
    isChatOpen: boolean;
  }
  
  @Injectable()
  export class QBotStore extends ComponentStore<QBotState> {
    
    constructor() {
        super({
            isChatOpen: false,
        });
    };

    readonly isChatOpen$ = this.select(state => state.isChatOpen);
    readonly chatIconClass$ = this.select(
        this.isChatOpen$,
        (isChatOpen) => `fa-xl fa-fw fa-regular fa-${isChatOpen ? 'xmark' : 'messages-question'}`
    );

    readonly vm$ = this.select(
        this.isChatOpen$,
        this.chatIconClass$,
        (isChatOpen, chatIconClass) => {
            const vm: QBotViewModel = {
                isChatOpen,
                chatIconClass
            };
            return vm;
        }
    )

 

  }