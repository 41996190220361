<div *ngIf="form" [formGroup]="form">
  <p class="mt-3">
    Select the DC Product Group(s) you want to make edits for. The change will be applied across all
    selected DC Product Group(s).</p>
  <kendo-formfield>
    <kendo-label text="Distribution Centers"></kendo-label>
    <kendo-multiselect *ngIf="!isEditPOEmail" [data]="dcList" [filterable]="true" textField="label" valueField="value"
      formControlName="distributionCenters" placeholder="Select Distribution Centers"
      data-automation-id="bulk-edit-dc-product-group-modal-dc-multi-select"
      [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}">
    </kendo-multiselect>
    <kendo-multiselect *ngIf="isEditPOEmail" [data]="searchList" [filterable]="true" textField="label"
      valueField="value" formControlName="distributionCenters" placeholder="Select Distribution Centers"
      data-automation-id="bulk-edit-dc-product-group-modal-dc-multi-select" (filterChange)="handleFilter($event)"
      [loading]="loadingDCPGs" [ngClass]="{'has-warning': hasWarning}">
    </kendo-multiselect>
    <kendo-formerror *ngIf="form.controls.distributionCenters.hasError('required')">
      Distribution Centers is required.
    </kendo-formerror>
    <kendo-formhint *ngIf="hasWarning" class="warning-hint">
      Listed DC Product Group(s) only has one PO Contact.
    </kendo-formhint>
  </kendo-formfield>

  <div class="mt-3 w-100">
    <kendo-label class="k-radio-intro-text mt-3 mb-0 fw-bold" text="Select what you would like to update">
    </kendo-label>
    <kendo-formerror class="mt-0 mb-2"
      *ngIf="form.controls.bulkAction.touched && form.controls.bulkAction.hasError('required')">
      Update type is required.
    </kendo-formerror>
    <kendo-formfield>
      <div class="d-flex flex-wrap mt-2">
        <ng-container *ngFor="let action of bulkActions">
          <div *ngIf="action.show" class="d-flex mb-2 align-items-center"
            [ngClass]="{'selection-option-single-column': isEditPOEmail, 'selection-option-double-column': !isEditPOEmail}">
            <input type="radio" [value]="action.value" (click)="form.get('bulkAction').markAsTouched()" kendoRadioButton
              data-automation-id="bulk-edit-dc-product-group-modal-action-radio-selection"
              formControlName="bulkAction" />
            <kendo-label class="k-radio-label" [text]="action.label"></kendo-label>
            <em *ngIf="action.tooltip" class="fas fa-info-circle ms-1 info-tooltip" [keheTooltip]="action.tooltip"></em>
          </div>
        </ng-container>
      </div>
    </kendo-formfield>
  </div>
</div>