import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectIsFeatureFlagOn, selectHaveFeatureFlagsBeenFetched } from '@app/shared/state/feature-flag/feature-flag.selectors';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { Constants } from './../../../constants/constants';
import { FeatureFlags } from '@app/feature-flag';

@Injectable({
  providedIn: 'root',
})
export class AssociatedSupplierTabGuard  {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(selectHaveFeatureFlagsBeenFetched).pipe(
      withLatestFrom(
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.BrokerV2.key)),
        this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.Vendor_Admin_SS)),
      ),
      filter(([flagsFetched]) => flagsFetched),
      map(([, flagIsActive, hasAction]) =>
        flagIsActive && hasAction
          ? flagIsActive
          : this.router.parseUrl('/unauthorized')
      )
    );
  }
}
