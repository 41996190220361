import { OnboardingTabStatuses } from '../../../models/onboarding-tab-statuses';
import { OnBoardingStatus } from '../models/onboarding-status';
import { SupplierDetailTabs } from '../models/supplier-detail-tabs';

export function isTabInReview(onboardingTabStatuses: OnBoardingStatus[], tab: SupplierDetailTabs) {
  const status = onboardingTabStatuses.find(item => item.tab === tab);
  return status && status.tabStatus === OnboardingTabStatuses.InReview;
}

export function isTabRejected(onboardingTabStatuses: OnBoardingStatus[], tab: SupplierDetailTabs) {
  const status = onboardingTabStatuses.find(item => item.tab === tab);
  return status && status.tabStatus === OnboardingTabStatuses.Rejected;
}

export function isTabNotSubmitted(onboardingTabStatuses: OnBoardingStatus[], tab: SupplierDetailTabs) {
  const status = onboardingTabStatuses.find(item => item.tab === tab);
  return !status || status.tabStatus === OnboardingTabStatuses.NotSubmitted;
}

export function isTabApproved(onboardingTabStatuses: OnBoardingStatus[], tab: SupplierDetailTabs) {
  const status = onboardingTabStatuses.find(item => item.tab === tab);
  return status && status.tabStatus === OnboardingTabStatuses.Approved;
}
