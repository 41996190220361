import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';

import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ApiFailureText } from '../../../../../../enum/alert-text.enum';
import { BrokerDropdownItem } from '../../broker-item.model';
import { SupplierBrokerService } from '../../../../services/supplier-broker.service';
import * as _ from 'lodash';
import { AbstractApiStore } from '@kehe/phoenix-component-store-utils';

/**
 * `HeadquarterBrokerApiStore` is an Angular service that extends `AbstractApiStore` to get the supplier headquarter broker.
 *
 * @class
 * @extends AbstractApiStore
 *
 * @example
 * const headquarterBrokerApiStore = new HeadquarterBrokerApiStore(brokerDetailService, store);
 *
 * @param {BrokerDetailService} _brokerDetailService - A service that provides broker detail data.
 * @param {Store} _store - A redux store that holds the application state.
 */
@Injectable()
export class SearchHeadquartersBrokersApiStore extends AbstractApiStore<
  BrokerDropdownItem[]
> {
  constructor(private _brokerService: SupplierBrokerService) {
    super();
  }

  loadHeadquarterBrokers$ = this.effect((searchText$: Observable<string>) =>
    searchText$.pipe(
      tap(() => this.startLoading()),
      switchMap((search) => {
        return this._brokerService.search(search, 20, 0).pipe(
          tapResponse(
            (brokers) => {
              const brokerList = brokers.map((broker) => ({
                ebn: broker?.esn,
                name: `${broker.name} (${broker?.esn})`,
              }));

              this.setData(_.sortBy(brokerList, 'name'));
            },
            () => {
              this.setError(ApiFailureText.Generic);
            }
          )
        );
      })
    )
  );
}
