import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable()
export class CommonService {
   // for error handling
   alertMessage: string;
   isAlertSuccess: boolean;
   errorStatus: any;
   isAlertSticky: boolean;
   alertType: string;
   displayMessageTime: number;


      constructor(private httpClient: HttpClient) { }


      validateErrorStatus() {
        if (this.errorStatus === 404 ||
            this.errorStatus === 503 ||
            this.errorStatus === 502 ||
            this.errorStatus === 500 ||
            this.errorStatus === 0   ||
            this.errorStatus === undefined) {

              // this.isAlertSuccess = false;
              this.alertType = 'error';
              this.alertMessage = 'Oops! Something went wrong. Please try again in 5 minutes.';
        }
    }

    handleAlertEvent(event) {
      this.alertType = null;
      this.alertMessage = null;
      this.isAlertSticky = false;
    }
    showSuccessAlert() {
      this.alertMessage = 'Success! Requested action is complete.';
      this.alertType = 'success';
    }

    showFailureAlert() {
        this.alertMessage = 'This is an error message with a close icon, only goes away when you close.';
        this.alertType = 'error';
        this.isAlertSticky = true;
    }

    showWarningAlert() {
        this.alertMessage = 'This is a warning message. This disappears in 4 seconds but you can modify that value.';
        this.alertType = 'warning';
    }

  uploadToS3(file: any, url: string): Observable<Object> {
    // Content-Type is set to '' when we get presigned url for upload so that we can upload different filetypes
    // If the content-type is not '' here, we will get SignatureNotMatched or TokenInvalid Error
    const headers = new HttpHeaders().set('Content-Type', '');
    return this.httpClient.put(url, file, {headers}).pipe(retry(1));
  }
}
