import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { PhoenixErrorsModule } from '@kehe/phoenix-errors';
import { ExportHierachiesModalComponent } from './components/export-hierarchies-modal/export-hierachies-modal.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as ExportHierarchiesModalReducer from './store/reducer/export-hierarchies-modal.reducer';
import { ExportHierarchiesModalEffects } from './store/effects/export-hierarchies-modal.effects';
import { HierarchyService } from '../shared/service/hierarchy.service';

@NgModule({
    declarations: [
        ExportHierachiesModalComponent
    ],
    imports: [
        CommonModule,
        PhoenixModalModule,
        PhoenixButtonModule,
        PhoenixErrorsModule,
        StoreModule.forFeature(ExportHierarchiesModalReducer.key, ExportHierarchiesModalReducer.reducer),
        EffectsModule.forFeature([ExportHierarchiesModalEffects]),
    ],  
    exports: [
        ExportHierachiesModalComponent
    ],
    providers: [
        HierarchyService
    ]
  })
  export class ExportHierarchiesModule { }