<kehe-phoenix-modal name="AddBroker" modalTitle="Add Broker" [show]="true"
  (modalEvent)="handleKeheModalEvents($event)" size="md">
  <div body class="add-broker-body">
    <div>
      <kendo-formfield >
        <kendo-label [for]="brokerFormField" text="Broker"></kendo-label>
        <kendo-combobox
          #brokerFormField
          [data]="searchedBrokerList"
          [filterable]="true"
          [textField]="'name'"
          [valueField]="'esn'"
          [valuePrimitive]="true"
          [loading]="isLoadingSearch"
          (valueChange)="onSelectedBrokerChange($event)"
          (filterChange)="onBrokerListSearchChanged($event)"
          [(ngModel)]="selectedBroker"
          placeholder="Search by Broker Name or EBN"
        ></kendo-combobox>
      </kendo-formfield>
    </div>
  </div>
  <div footer class="add-broker-footer">
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-medium btn-link"
                (click)="cancelModal()">Cancel</kehe-phoenix-button>
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-medium btn-primary"
                (click)="submitBroker()" [disabled]="!selectedBroker || isSaveInProgress"
                [spinner]="isSaveInProgress">Add</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
