import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { takeUntil, filter, map, startWith, take } from 'rxjs/operators';
import { selectSupplierDetail } from '../../../supplier-detail/store/supplier-detail.selectors';
import { Destroyable } from '../../../../abstract/destroyable';
import { cloneDCPGSave, cloneDCProductGroupChanged } from '../../store/product-groups.actions';
import { selectCloneDCPG } from '../../store/product-groups.selectors';
import { DCProductGroup } from '../../../supplier-detail/models/dc-product-group';
import { ProductGroupDeliveryFormComponent } from '../product-group-delivery-form/product-group-delivery-form.component';
import { PoTransmissionsFormComponent } from '../po-transmissions-form/po-transmissions-form.component';
import { combineLatest } from 'rxjs';
import { defaultAddress, defaultCountry } from '../../../../utils/address-utils';
import { PickupAddress } from '../../models/pickup-address';
import { isDraft } from '../../../supplier-detail/utilities/supplier-utils';
import { ProductGroupActions } from '../../models/product-group-actions';
import { InboundRouteType } from '../../../../models/dcpg-inbound-route-type';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag';

@Component({
  selector: 'app-clone-dcpg-form',
  templateUrl: './clone-dcpg-form.component.html',
  styleUrls: ['./clone-dcpg-form.component.scss']
})
export class CloneDcpgFormComponent extends Destroyable implements AfterViewInit {

  actionTypes = ProductGroupActions;

  @ViewChild('deliveryForm', { static: true }) deliveryFormComponent: ProductGroupDeliveryFormComponent;
  @ViewChild('poTransmissionsGroup', { static: true }) poTransmissionsGroup: PoTransmissionsFormComponent;

  _initialCloneData: any;
  _dcProductGroup: DCProductGroup;
  _initialDeliveryMethod: any;
  _initialTransmissions: any;

  supplierDetail$ = this._store.select(selectSupplierDetail);
  dcProductGroup$ = this._store.select(selectCloneDCPG);
  isDraftSupplier$ = this._store.select(selectSupplierDetail).pipe(map(supplier => isDraft(supplier)));

  form = this._builder.group({
    cloneFromDC: [],
    cloneToDCs: [[], Validators.required],
    buyers: this._builder.array([]),
  }, {
    validators: [(form) => {
      // validator to check that all DCs are assigned
      const cloneDCs = (form as UntypedFormGroup).controls.cloneToDCs.value;
      const buyerDCs = (form as UntypedFormGroup).controls.buyers.value.reduce((a: any, c: { buyerDCs: any; }) => ([...a, ...c.buyerDCs]), []);
      if (cloneDCs.length === buyerDCs.length) {
        return null;
      }

      return {
        dcsAssigned: true
      };
    }]
  });
  zipTransitTimeDefaultFF = false;

  constructor(
    private readonly _builder: UntypedFormBuilder,
    private readonly _store: Store,
    private readonly _cdr: ChangeDetectorRef
  ) {
    super();

    this._store.select(selectIsFeatureFlagOn(FeatureFlags.DefaultToZipTransitTime.key))
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => {
        this.zipTransitTimeDefaultFF = v;
      });
    this.dcProductGroup$.pipe(
      filter((d) => !!d),
      takeUntil(this.destroy$),
    ).subscribe((data) => {
      this.form.patchValue({
        cloneFromDC: data.dcpg.dcDisplayName,
        cloneToDCs: data.cloneTo,
      });

      // place all the DCs on the same buyer
      (this.form.controls.buyers as UntypedFormArray).push(
        this._builder.group({
          buyerCode: [data.dcpg.buyerCode, Validators.required],
          buyerName: [data.dcpg.buyerName],
          buyerEmail: [data.dcpg.buyerEmail],
          buyerRegion: [data.dcpg.buyerRegion],
          buyerDCs: [data.cloneTo, Validators.required]
        })
      );

      this._dcProductGroup = this.prepDCProductGroup(data.dcpg);
      this._initialCloneData = this.form.getRawValue();
    });

    // correct buyers if DCs change
    this.form.controls.cloneToDCs.valueChanges.pipe(
      map((list: any[]) => list.map((a: any) => a.value)),
      takeUntil(this.destroy$)
    )
      .subscribe((dcList: Array<string>) => {
        // remove any DCs not in the list and patch buyers
        const buyers = this.form.controls.buyers.value.map((buyer: any) => {
          buyer.buyerDCs = buyer.buyerDCs.filter((dc: any) => dcList.includes(dc.value));
          return buyer;
        });

        this.form.controls.buyers.patchValue(buyers);
      });

    this.form.markAsTouched();
  }

  // we use the after view init hook because the delivery form uses it for the address
  // component. this can be removed after the address component has been updated
  // to no longer require the view child decorator
  ngAfterViewInit() {

    // If inboundRouteType is not set then we can default it to LTLDry
    if (this.zipTransitTimeDefaultFF && this._dcProductGroup && (!this._dcProductGroup.inboundRouteType || this._dcProductGroup.inboundRouteType === InboundRouteType.None)) {
      this._dcProductGroup.inboundRouteType = InboundRouteType.LTLDry;
    }

    // we call the patch form of the delivery method component
    this.deliveryFormComponent.patchForm(this._dcProductGroup);
    this.poTransmissionsGroup.patchForm(this._dcProductGroup?.poFax, this._dcProductGroup?.poEmails, this._dcProductGroup?.poOrderMinimum);

    // trigger validation
    this.deliveryFormComponent.form.markAllAsTouched();
    this.poTransmissionsGroup.form.markAllAsTouched();

    this._initialDeliveryMethod = this.deliveryFormComponent.form.getRawValue();
    this._initialTransmissions = this.poTransmissionsGroup.form.getRawValue();

    // trigger change detection since child component has changed.
    this._cdr.detectChanges();

    // set valid and changed flags
    const formData$ = combineLatest([
      this.form.valueChanges.pipe(startWith(this.form.getRawValue())),
      this.deliveryFormComponent.form.valueChanges.pipe(startWith(this._initialDeliveryMethod)),
      this.poTransmissionsGroup.form.valueChanges.pipe(startWith(this._initialTransmissions)),
    ]).pipe(
      takeUntil(this.destroy$)
    );

    // this is being done because of the address updates that occur as well as the http calls
    // that update the form of the delivery method component, so we have to update the initial
    // data after the call happens.
    formData$.pipe(take(1)).subscribe(([, delivery, transmissions]) => {
      // get the initial state of these two forms
      this._initialDeliveryMethod = delivery;
      this._initialTransmissions = transmissions;
    })

    formData$.subscribe(([changes, delivery, transmissions]) => {
      // check the difference between the changes and the initial data
      const dcpg = {
        ...this._dcProductGroup,
        ...changes,
        ...this.prepDelivery(delivery),
        ...this.prepTransmission(transmissions)
      };
      const pickupChanged = delivery.deliveryMethod === 'Delivered' ? false :
        (this.deliveryFormComponent.pickupAddressFormGroup.dirty || this.deliveryFormComponent.pickupAddressNotesFormGroup.dirty)
      const changed = !isEqual(changes, this._initialCloneData) ||
        !isEqual(delivery, this._initialDeliveryMethod) ||
        isEqual(transmissions, this._initialTransmissions);
      const valid = this.form.valid && this.deliveryFormComponent.form.valid && this.poTransmissionsGroup.form.valid;

      this._store.dispatch(cloneDCProductGroupChanged({
        changed,
        dcpg,
        pickupChanged,
        valid
      }));
    });

    // scroll the user to the top of the page
    window.scrollTo({ top: 0 });
  }

  onPickupAddressSelected() {
    this._store.dispatch(cloneDCPGSave());
  }

  private prepDelivery(delivery: DCProductGroup & { readyTimeAvailable: string; pickup: PickupAddress & { pickupAddress: PickupAddress } }) {
    const result = { ...delivery } as unknown as Partial<DCProductGroup & { readyTimeAvailable: string; pickupAddress: PickupAddress }>;

    if (delivery) {
      result.deliveryMethodCode = delivery.deliveryMethodCode;
      result.deliveryMethod = delivery.deliveryMethod;
      result.readyTimeAvailable = delivery.readyTimeAvailable;
      result.deliveredLeadTimes = delivery.deliveredLeadTimes;
      result.pickupLeadTimes = delivery.pickupLeadTimes;

      const pickup = delivery.pickup;
      result.pickupAddress = pickup?.pickupAddress;
      if (result.pickupAddress) {
        result.pickupAddress.phone = pickup.pickupPhone;
        result.pickupAddress.extension = pickup.pickupExtension;
        result.pickupAddress.fax = pickup.pickupFax;
        result.pickupAddress.email = pickup.pickupEmail;
        result.pickupAddress.latitude = pickup.pickupAddress.latitude
        result.pickupAddress.longitude = pickup.pickupAddress.longitude;
      }
      result.pickupAddressNotes = pickup?.pickupAddressNotes;
    }

    return result;
  }

  private prepTransmission(transmissions: { poEmails: any[]; }) {
    if (transmissions) {
      transmissions.poEmails = transmissions.poEmails.filter(mailItem => mailItem.email && mailItem.email.length > 0);
    }
    return transmissions;
  }

  private prepDCProductGroup(dcProductGroup: DCProductGroup): DCProductGroup {
    if (!dcProductGroup.pickupAddress) {
      dcProductGroup.pickupAddress = defaultAddress();
      dcProductGroup.pickupAddressNotes = '';
    }

    if (!dcProductGroup.pickupAddress.country) {
      dcProductGroup.pickupAddress.country = defaultCountry();
    }
    return dcProductGroup;
  }
}
