import { Component, OnInit } from '@angular/core';
import { Destroyable } from '../../../../abstract/destroyable';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
  selectShowAssignPrimaryContactModal,
  selectShowDeleteContactModal,
  selectShowManageContactModal,
  selectShowNotifyDiscProductsModal,
  selectSupplierContacts
} from '../../store/supplier-contacts.selectors';
import { PersonContact } from '../../models/person-contact';
import { OptionMenuItem } from '@kehe/phoenix-button';
import {
  editSupplierContact,
  showAssignPrimaryContactModal,
  showDeleteSupplierContactConfirmation,
  showNotifyDiscProductsModal
} from '../../store/supplier-contacts.actions';

export enum ContactAction {
  Edit,
  AssignPrimaryContact,
  NotifyDiscProducts,
  Delete,
}
@Component({
  selector: 'app-supplier-contacts-grid',
  templateUrl: './supplier-contacts-grid.component.html',
  styleUrls: ['./supplier-contacts-grid.component.scss']
})
export class SupplierContactsGridComponent extends Destroyable implements OnInit {


  contacts: PersonContact[] = [];
  showManageContactModal = false;
  showDeleteContactModal = false;
  showAssignPrimaryContactModal = false;
  showNotifyDiscProductsModal = false;
  menuOptionMap = {};

  public sort: SortDescriptor[] = [
    {
      field: 'name',
      dir: 'asc'
    }
  ];

  constructor(
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSupplierContacts))
      .subscribe((val) => {
        this.contacts = orderBy(val, this.sort);
        this.menuOptionMap = val.reduce(function (map, item) {
          map[item.uniqueId] = getMoreOptions(item);
          return map;
        }, {});
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectShowManageContactModal))
      .subscribe((val) => {
        this.showManageContactModal = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectShowDeleteContactModal))
      .subscribe((val) => {
        this.showDeleteContactModal = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectShowAssignPrimaryContactModal))
      .subscribe((val) => {
        this.showAssignPrimaryContactModal = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectShowNotifyDiscProductsModal))
      .subscribe((val) => {
        this.showNotifyDiscProductsModal = val;
      });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.contacts = orderBy(this.contacts, this.sort);
  }

  getNotifications(contact: PersonContact) {
    const notifications = [];
    if (contact.subscribedToDiscontinuedProductNotification) {
      notifications.push('Discontinued Product Notification');
    }
    if (contact.subscribedToKeheNewsLetter) {
      notifications.push('KeHE Newsletter');
    }
    return notifications.length > 0 ? notifications.join('\n\n') : null;
  }

  moreOptionSelected(option: OptionMenuItem, contact: PersonContact) {
    switch (option.data) {
      case ContactAction.Edit: {
        this._store.dispatch(editSupplierContact({ contact }));
        break;
      }
      case ContactAction.AssignPrimaryContact: {
        this._store.dispatch(showAssignPrimaryContactModal({ contact }));
        break;
      }
      case ContactAction.NotifyDiscProducts: {
        this._store.dispatch(showNotifyDiscProductsModal({ contact }));
        break;
      }
      case ContactAction.Delete: {
        this._store.dispatch(showDeleteSupplierContactConfirmation({ contact }));
        break;
      }
    }
  }

  deleteContact(contact: PersonContact) {
    this._store.dispatch(showDeleteSupplierContactConfirmation({ contact }));
  }

  getContactTypes(contact: PersonContact) {
    return contact.contactTypes ? contact.contactTypes.join(', ') : null;
  }
}


function getMoreOptions(item: PersonContact): any {
  return [
    {
      data: ContactAction.Edit,
      name: 'Edit',
      enabled: true,
      selected: false,
    },
    {
      data: ContactAction.AssignPrimaryContact,
      name: 'Assign Primary Contact',
      enabled: !item.isPrimary,
      selected: false,
    },
    {
      data: ContactAction.NotifyDiscProducts,
      name: 'Assign Disc. Products Notification',
      enabled: !item.subscribedToDiscontinuedProductNotification,
      selected: false,
    },
    {
      data: ContactAction.Delete,
      name: 'Delete',
      enabled: true,
      selected: false,
    }
  ];
}
