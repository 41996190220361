import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';import { tapResponse } from '@ngrx/operators';

import { BrokerTabStore } from '../broker-v2-tab.store';
import { AddBrokerFormStore } from './add-broker-v2-form.store';
import {
  map,
  tap,
  switchMap,
  filter,
  takeUntil,
  debounceTime,
  distinctUntilChanged,
  startWith,
  withLatestFrom,
  delay,
} from 'rxjs/operators';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { SupplierDetailService } from '../../../services/supplier-detail.service';
import { SupplierFormService } from '../../../services/supplier-form.service';
import { SupplierDetail } from '../../../models/supplier-detail';
import { formatBrokerFormResponse, getFormControlValueMap, resetPristine } from '../../../../../utils/form-utils';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { Store } from '@ngrx/store';
import { saveSupplierFormSuccess } from '../../../store/supplier-detail.actions';
import { SearchHeadquartersBrokersApiStore } from './api-stores/search-headquarters-brokers.api.store';
import { BrokerContactsApiStore } from './api-stores/broker-contacts.api.store';
import { BrokerTabValidationStore } from '../broker-v2-tab-validation.store';
import { SearchNonHeadquarterBrokerApiStore } from './api-stores/search-non-headquarter-broker.api.store';
import { VendorBrokerManagementService } from '../../../services/vendor-broker-management.service';
import { RelatedNonHeadquarterBrokerApiStore } from '../broker-tab-v2-grid/api-stores/related-non-headquarter-broker.api.store';
import { BrokerTabGridStore } from '../broker-tab-v2-grid/broker-tab-v2-grid.store';
import { selectAddNoteFormValues, selectIsDraftSupplier } from '../../../store/supplier-detail.selectors';

enum SubmissionType {
  AddHQ,
  AddNonHQ,
  SupplierDoesNotHaveABroker,
}

export enum BrokerType {
  Headquarter = 'Headquarter',
  NonHeadquarter = 'Non-Headquarter',
  DoesNotHaveABroker = "Supplier doesn't have a Broker",
}

export interface AddBrokerModalState {
  isSubmitting: boolean;
  headquarterBrokersSearch: string;
  nonHeadquarterBrokersSearch: string;
  contactsSearch: string;
  apiErrorMessage: string;
}

export const initialState: AddBrokerModalState = {
  isSubmitting: false,
  headquarterBrokersSearch: '',
  contactsSearch: '',
  apiErrorMessage: '',
  nonHeadquarterBrokersSearch: '',
};

const genericApiErrorText =
  'Unable to complete your request at this time. Please try again.';

@Injectable()
export class AddBrokerModalStore extends ComponentStore<AddBrokerModalState> {
  constructor(
    private _brokerTabStore: BrokerTabStore,
    private _brokerFormStore: AddBrokerFormStore,
    private _brokerTabValidationStore: BrokerTabValidationStore,
    private _headquarterBrokersApiStore: SearchHeadquartersBrokersApiStore,
    private _nonHeadquarterBrokersApiStore: SearchNonHeadquarterBrokerApiStore,
    private _brokerContactsApiStore: BrokerContactsApiStore,
    private _supplierDetailService: SupplierDetailService,
    private _vendorBrokerManagementService: VendorBrokerManagementService,
    private _supplierFormService: SupplierFormService,
    private _toastService: PhoenixToastService,
    private _supplierDetailStore: Store,
    private _nonHeadquartersGridApiStore: RelatedNonHeadquarterBrokerApiStore,
    private _gridStore: BrokerTabGridStore
  ) {
    super(initialState);

    this._headquarterBrokersApiStore.loadHeadquarterBrokers$(
      this._loadHeadquarterBrokerTrigger$
    );
    this._nonHeadquarterBrokersApiStore.loadNonHQBrokers$(
      this._loadNonHeadquarterBrokerTrigger$
    );
    this._brokerContactsApiStore.loadBrokerContacts$(
      this._loadBrokerContactsTrigger$
    );
    this.clearApiErrorsOnFormChanges$(this._brokerFormStore.formStatusChanges$);
    this.initializeBrokerForm$(this._brokerTabStore._supplierDetail$);
  }
  private _isSubmitting$ = this.select((state) => state.isSubmitting);

  initialFormValue: Map<UntypedFormControl, any>;
  public brokerForm: UntypedFormGroup;
  form = this._brokerFormStore.form;
  formControls = this._brokerFormStore;

  isAddModal$ = this._brokerTabStore.isAddModalOpen$;

  private _addNoteFormValues$ = this._supplierDetailStore.select(selectAddNoteFormValues);

  private _isDraftSupplier$ = this._supplierDetailStore.select(selectIsDraftSupplier);

  private _primaryButton$ = this.select(
    this.isAddModal$,
    this._brokerFormStore.isFormValid$,
    this._addNoteFormValues$,
    this._isSubmitting$,
    this._isDraftSupplier$,
    (isAddModal, isFormValid, addNoteForm, isSubmitting, isDraftSupplier) => {
      const isAddNoteFormValid = isDraftSupplier ? true : addNoteForm?.isValid;
      return {
        text: isAddModal ? 'Add' : 'Continue',
        isDisabled: !isFormValid || !isAddNoteFormValid,
        isSpinning: isSubmitting,
      };
    }
  );

  private _headquarterBrokerEbn$ = this._brokerTabStore._supplierBroker$.pipe(
    map((broker) => broker?.esn)
  );

  private _brokerTypeRadios$ = this.select(
    this._brokerTabValidationStore.isValid$,
    (tabIsValid) => {
      const brokerTypeValues = Object.values(BrokerType);
      const brokerTypes = tabIsValid
        ? brokerTypeValues.filter(
            (brokerType) => brokerType !== BrokerType.DoesNotHaveABroker
          )
        : brokerTypeValues;

      return brokerTypes?.map((brokerType, i) => {
        return {
          id: 'radio' + i.toString(),
          value: brokerType,
          text: brokerType,
        };
      });
    }
  );

  private _headquarterBrokersSearch$ = this.select(
    (state) => state.headquarterBrokersSearch
  ).pipe(startWith(''));

  private _nonHeadquarterBrokersSearch$ = this.select(
    (state) => state.nonHeadquarterBrokersSearch
  ).pipe(startWith(''));

  private _contactsSearch$ = this.select((state) => state.contactsSearch);

  private _loadHeadquarterBrokerTrigger$ = combineLatest([
    this._headquarterBrokersSearch$,
    this._brokerFormStore.isHeadquarterBroker$,
  ]).pipe(
    filter(([search, isHeadquarterBroker]) => isHeadquarterBroker),
    takeUntil(this.destroy$),
    debounceTime(200),
    distinctUntilChanged(),
    map(([search]) => search)
  );

  private _loadNonHeadquarterBrokerTrigger$ = combineLatest([
    this._nonHeadquarterBrokersSearch$,
    this._brokerFormStore.isNonHeadquarterBroker$,
  ]).pipe(
    filter(([search, isNonHeadquarterBroker]) => isNonHeadquarterBroker),
    takeUntil(this.destroy$),
    debounceTime(200),
    distinctUntilChanged(),
    map(([search]) => search)
  );

  private _contactSearchLoadBrokers$ = combineLatest([
    this._contactsSearch$,
    this._brokerFormStore.isHeadquarterBroker$,
  ]).pipe(
    filter(([search, headquarterBroker]) => headquarterBroker),
    takeUntil(this.destroy$),
    debounceTime(200),
    distinctUntilChanged(),

    map(([search]) => search)
  );

  private _loadBrokerContactsTrigger$ = combineLatest([
    this._brokerFormStore.headquarterBrokerValueChanges$,
    this._contactSearchLoadBrokers$,
  ]).pipe(
    takeUntil(this.destroy$),
    filter(([broker]) => Boolean(broker))
  );

  private _warningMessage$ = this.select(
    this._headquarterBrokerEbn$,
    this._brokerFormStore.isHeadquarterBroker$,
    (currentBrokerEbn, isHeadquarterBroker) => {
      if (currentBrokerEbn && isHeadquarterBroker) {
        return 'There is already a Headquarter Broker listed on this account. By continuing, this new Broker will replace the existing Headquarter Broker.';
      }
    }
  );

  readonly _headquarterBrokers$ = this.select(
    this._headquarterBrokersApiStore.data$,
    this._gridStore._gridBrokerRows$,
    (allHQBrokers, relatedBrokers) => {
      return allHQBrokers?.filter(
        (broker) =>
          !relatedBrokers?.find((related) => related.ebn === broker.ebn)
      );
    }
  );

  readonly _nonHeadquarterBrokers$ = this.select(
    this._nonHeadquarterBrokersApiStore.data$,
    this._gridStore._gridBrokerRows$,
    (allBrokers, relatedBrokers) => {
      return allBrokers?.filter(
        (broker) =>
          !relatedBrokers?.find((related) => related.ebn === broker.ebn)
      );
    }
  );

  readonly _brokerContacts$ = this.select(
    this._brokerContactsApiStore.data$,
    (allContacts) => {
      return allContacts;
    }
  );

  readonly submissionType$ = this.select(
    this._brokerFormStore.isHeadquarterBroker$,
    this._brokerFormStore.isNonHeadquarterBroker$,
    this._brokerFormStore.isDoesNotHaveBroker$,
    (isHeadquarterBroker, isNonHeadquarterBroker, isDoesNotHaveABroker) => {
      if (isHeadquarterBroker) {
        return SubmissionType.AddHQ;
      }
      if (isNonHeadquarterBroker) {
        return SubmissionType.AddNonHQ;
      }
      if (isDoesNotHaveABroker) {
        return SubmissionType.SupplierDoesNotHaveABroker;
      }
    }
  );

  readonly vm$ = this.select(
    this.state$,
    this._brokerTypeRadios$,
    this._primaryButton$,
    this._brokerFormStore.isHeadquarterBroker$,
    this._brokerFormStore.headquarterBrokerValueChanges$,
    this._brokerFormStore.nonHeadquarterBrokerValueChanges$,
    this._isDraftSupplier$,
    this._brokerTabStore._supplierDetail$,
    this._headquarterBrokerEbn$,
    this._warningMessage$,
    this._headquarterBrokersApiStore.isLoading$,
    this._headquarterBrokers$,
    this._brokerContactsApiStore.isLoading$,
    this._brokerContacts$,
    this._nonHeadquarterBrokersApiStore.isLoading$,
    this._nonHeadquarterBrokers$,
    this._brokerFormStore.isNonHeadquarterBroker$,
    (
      state,
      brokerTypeRadios,
      primaryButton,
      isHeadquarterBroker,
      hasHeadquarterBroker,
      hasNonHeadquarterBroker,
      isDraftSupplier,
      supplierDetail,
      headquarterBrokerEbn,
      warningMessage,
      isLoadingBrokers,
      headquarterBrokers,
      isLoadingBrokerContacts,
      brokerContacts,
      isLoadingNonHQBrokers,
      nonHeadquarterBrokers,
      nonHeadquarterRadioSelected
    ) => ({
      ...state,
      brokerTypeRadios,
      primaryButton,
      infoIconTooltip:
        "Broker Type identifies the Broker's level of access to Supplier's account.",
      isHeadquarterBroker,
      hasHeadquarterBroker,
      hasNonHeadquarterBroker,
      isDraftSupplier,
      supplierDetail,
      headquarterBrokerEbn,
      warningMessage,
      isLoadingBrokers,
      headquarterBrokers,
      isLoadingBrokerContacts,
      brokerContacts,
      isLoadingNonHQBrokers,
      nonHeadquarterBrokers,
      nonHeadquarterRadioSelected,
    })
  );

  // Methods
  closeModal() {
    this._brokerTabStore.closeModal();
  }

  submitModal = this.effect((_) => {
    return _.pipe(
      withLatestFrom(this.submissionType$),
      tap(([_, submissionType]: [void, SubmissionType]) => {
        switch (submissionType) {
          case SubmissionType.AddHQ:
            // Add HQ Broker
            this.submitHeadquarterBroker$();
            break;
          case SubmissionType.AddNonHQ:
            this.submitNonHeadquarterBroker$();
            break;
          case SubmissionType.SupplierDoesNotHaveABroker:
            this.submitSupplierDoesNotHaveABroker$();
            break;
          default:
            break;
        }
      })
    );
  });

  headquarterBrokerFilterChanged(headquarterBrokersSearch: string) {
    this.patchState({
      headquarterBrokersSearch,
    });
  }

  brokerContactFilterChanged(contactsSearch: string) {
    this.patchState({
      contactsSearch,
    });
  }

  nonHeadquarterBrokerFilterChanged(nonHeadquarterBrokersSearch: string) {
    this.patchState({
      nonHeadquarterBrokersSearch,
    });
  }

  initializeBrokerType$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(this._brokerTabStore.isDoesNotHaveBrokerTypeByDefault$),
      delay(50),
      // Added delay because form service status change validation happens before this
      tap(([_, isSkipAction]) => {
        if (isSkipAction) {
          this._brokerFormStore.patchState({
            skipByDefault: true,
          });
        }
      })
    )
  );

  initializeBrokerForm$ = this.effect((payload$: Observable<SupplierDetail>) =>
    payload$.pipe(
      tap((supplierDetail: SupplierDetail) => {
        this.brokerForm =
          this._supplierFormService.getBrokerForm(supplierDetail);
        this.initialFormValue = getFormControlValueMap(this.form);
      })
    )
  );

  clearApiErrorsOnFormChanges$ = this.effect((formValueChanges$: Observable<string>) =>
    formValueChanges$.pipe(
      tap(() => {
        this.patchState({
          apiErrorMessage: '',
        });
      })
    )
  );

  submitHeadquarterBroker$ = this.effect((_) =>
    _.pipe(
      tap(() => {
        this.patchState({
          isSubmitting: true,
        });
      }),
      withLatestFrom(
        this._brokerTabStore._supplierEsn$,
        this._brokerFormStore.headquarterBrokerValueChanges$,
        this._brokerFormStore.headquarterContactValueChanges$,
        this._addNoteFormValues$,
      ),
      switchMap(([_, supplierEsn, selectedBroker, brokerContact, addNoteForm]) => {
        resetPristine(this.initialFormValue);
        const dirtyValue = this.brokerForm.getRawValue();
        formatBrokerFormResponse(dirtyValue);

        dirtyValue.isBrokerOfRecord = true;
        dirtyValue.isHeadquarterBrokerNotFound = null;
        dirtyValue.hasHeadquarterBroker = true;
        dirtyValue.updatedBySource = 'Enterprise';
        dirtyValue.broker.esn = selectedBroker.ebn;

        if (brokerContact) {
          dirtyValue.broker.contact = brokerContact;
          dirtyValue.knowsHeadquarterBrokerContact = true;
        } else {
          dirtyValue.broker.contact = null;
          dirtyValue.knowsHeadquarterBrokerContact = false;
        }

        dirtyValue.auditNotes = addNoteForm?.auditNotes;

        const param = {
          esn: supplierEsn,

          ...dirtyValue,
        };

        return this._supplierDetailService.save(param).pipe(
          tapResponse(
            (supplier) => {
              this._toastService.showSuccessToast(
                'Broker added successfully!',
                false,
                5000
              );

              this.patchState({
                isSubmitting: false,
              });

              //supplierDetailActions.saveGeneralFormSuccess({ supplier })
              this._supplierDetailStore.dispatch(
                saveSupplierFormSuccess({ supplier, form: param })
              );

              this.closeModal();
            },
            (error) => {
              this.patchState({
                apiErrorMessage: genericApiErrorText,
                isSubmitting: false,
              });
            }
          ),
          tap(() => {
            this.patchState({ isSubmitting: false });
          })
        );
      })
    )
  );

  submitNonHeadquarterBroker$ = this.effect((_) =>
    _.pipe(
      tap(() => {
        this.patchState({
          isSubmitting: true,
        });
      }),
      withLatestFrom(
        this._brokerTabStore._supplierEsn$,
        this._brokerFormStore.nonHeadquarterBrokerValueChanges$,
        this._addNoteFormValues$,
      ),
      switchMap(([_, supplierEsn, selectedBroker, addNoteForm]) => {
        const updateRequests = [
          this._vendorBrokerManagementService.addNonHqBroker(supplierEsn, selectedBroker.ebn, addNoteForm?.auditNotes),
          this._supplierDetailService.save({ esn: supplierEsn, hasHeadquarterBroker: false, updatedBySource: 'Enterprise' }),
        ];

        return forkJoin(updateRequests)
          .pipe(
            tapResponse(
              () => {
                this._toastService.showSuccessToast(
                  'Broker added successfully!',
                  false,
                  5000
                );

                this.patchState({
                  isSubmitting: false,
                });

                this._nonHeadquartersGridApiStore.loadNonHeadquarterBrokers$(
                  supplierEsn
                );

                this.closeModal();
              },
              (error) => {
                this.patchState({
                  apiErrorMessage: genericApiErrorText,
                  isSubmitting: false,
                });
              }
            ),
            tap(() => {
              this.patchState({ isSubmitting: false });
            })
          );
      })
    )
  );

  submitSupplierDoesNotHaveABroker$ = this.effect((_) =>
    _.pipe(
      tap(() => {
        this.patchState({
          isSubmitting: true,
        });
      }),
      withLatestFrom(this._brokerTabStore._supplierEsn$),
      switchMap(([_, supplierEsn]) => {
        resetPristine(this.initialFormValue);
        const dirtyValue = this.brokerForm.getRawValue();
        formatBrokerFormResponse(dirtyValue);

        dirtyValue.broker = {};
        dirtyValue.hasHeadquarterBroker = false;
        dirtyValue.isBrokerOfRecord = true;
        dirtyValue.isHeadquarterBrokerNotFound = null;
        dirtyValue.knowsHeadquarterBrokerContact = null;
        dirtyValue.updatedBySource = 'Enterprise';

        const param = {
          esn: supplierEsn,

          ...dirtyValue,
        };

        return this._supplierDetailService.save(param).pipe(
          tapResponse(
            (supplier) => {
              this.patchState({
                isSubmitting: false,
              });

              //supplierDetailActions.saveGeneralFormSuccess({ supplier })
              this._supplierDetailStore.dispatch(
                saveSupplierFormSuccess({ supplier, form: param })
              );

              this.closeModal();
            },
            (error) => {
              this.patchState({
                apiErrorMessage: genericApiErrorText,
                isSubmitting: false,
              });
            }
          ),
          tap(() => {
            this.patchState({ isSubmitting: false });
          })
        );
      })
    )
  );
}
