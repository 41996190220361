import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '@app/services/permissions.service'; 
import { Constants } from '../../../constants/constants';

/**
 * Supplier Guard, actives the route only if the user has read access to Supplier (Supplier_Read).
 */
@Injectable({
  providedIn: 'root'
})
export class SupplierGuard  {
  constructor(private permSvc: PermissionsService) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.permSvc.userHasAction(Constants.UserActions.SupplierRead);
  }
}
