export enum DCStatus {
    InActive = 'Inactive',
    ManufactureDiscNoInventory = 'Manuf. Disc. - No Inventory',
    KeheDiscNoInventory = 'KeHE Disc. - No Inventory',
    Active = 'Active',
    New = 'New',
    PendingDisc = 'Pending Disc.',
    ActiveSeasonal = 'Active - Seasonal',
    NewSeasonal = 'New - Seasonal',
    PendingDiscSeasonal = 'Pending Disc. - Seasonal',
    ActiveShortShipped = 'Active - Short Shipped',
    NewShortShipped = 'New - Short Shipped',
    PendingDiscShortShipped = 'Pending Disc. - Short Shipped',
    ManufactureOOSEveryDay = 'Manuf. OOS - Every Day',
    ManufactureOOSOther = 'Manuf. OOS - Other',
    StagedForPricing = 'Staged For Pricing',
    PendingActivation = 'Pending Activation',
    RequiresPricing = 'Requires Pricing',
    PricingComplete = 'Pricing Complete',
    Complete = 'Complete'
}