<ng-container *ngIf="vm$ | async as vm">
  <div class="px-4">
    <div class="mb-4">
      <div class="row">
        <div class="col">
          <h4>Associated Suppliers</h4>
        </div>
      </div>
    </div>

    <div class="row mb-3 justify-content-between">
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12 bulk-actions">
        <div class="row">
          <div class="col-auto">
            <kehe-phoenix-menu-button [enabled]="vm.shouldEnableBulkActions"
            [menuOptions]="bulkButtonMenuOptions"
            automationId="associated-suppliers-table-bulk-actions"
            menuTitle="Actions"
            (optionSelected)="handleBulkActionClick($event)"
            >
            </kehe-phoenix-menu-button>
          </div>
          <div class="col-auto pl-0">
            <div class="items-count bold" data-automation-id="associated-suppliers-table-selected-records-count">{{vm.selectedRecordsCount}} Item(s) Selected</div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-md-6 col-sm-12 text-right mb-4">
        <div class="list-search-filter justify-content-end pl-0">
          <kehe-phoenix-search
            class="ml-0 mr-0"
            placeholder="Search Suppliers"
            name="txtSearchSuppliers"
            [formControl]="searchControl"
            automationId="associated-suppliers-table-search-input"
            ngDefaultControl
          ></kehe-phoenix-search>
        </div>
      </div>

      <div class="col-12">
          <app-associated-suppliers-tab-grid data-automation-id="associated-suppliers-tab-table"></app-associated-suppliers-tab-grid>
      </div>
    </div>

  </div>
  <app-associate-new-supplier-modal *ngIf="vm.openedAddModal"></app-associate-new-supplier-modal>
  <app-associated-supplier-delete-confirmation *ngIf="vm.showDeleteConfirmationModal"></app-associated-supplier-delete-confirmation>
</ng-container>
