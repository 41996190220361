<div [formGroup]="form">
  <div class="row">
    <div class="col mb-2">
      <kendo-formerror *ngIf="form && isPOTransRequired">At least one fax or email is required.</kendo-formerror>
    </div>
  </div>
  <div class="row po-trans">
    <div class="col">
      <div class="row mb-2 mb-md-0" formGroupName="poFax">
        <div class="col-md-3 pr-md-0 mb-3">
          <kendo-label text="Type"></kendo-label>
          <kendo-combobox #poFaxType
                          (focus)="onFocus(poFaxType)"
                          (blur)="handlePOTypeBlur()"
                          [data]="transTypes"
                          formControlName="type"
                          placeholder="Select Type">
          </kendo-combobox>
          <kendo-formerror *ngIf="form && isPOTypeRequired && isPOTransRequired">
            Type is required.
          </kendo-formerror>
        </div>
        <div class="col-md-4 pl-md-2 pr-md-0 mb-3">
          <kehe-phoenix-phone-number-input formControlName="fax" [required]="isPOTransRequired" errorRequired="PO Fax is required." label="PO Fax" (blurEvent)="handlePOEmailBlur()"></kehe-phoenix-phone-number-input>
        </div>
      </div>
      <div formArrayName="poEmails">
        <div *ngFor="let po of poEmailsFormArray.controls; let i = index">
          <div [formGroupName]="i" class="row mb-2 mb-md-0">
            <div class="col-md-3 pr-md-0 mb-3">
              <kendo-label text="Type"></kendo-label>
              <kendo-combobox
                #poEmailType
                (focus)="onFocus(poEmailType)"
                (blur)="handlePOTypeBlur()"
                [data]="transTypes"
                formControlName="type"
                placeholder="Select Type"
              ></kendo-combobox>
              <kendo-formerror *ngIf="form && isPOTypeRequired && isPOTransRequired">
                Type is required.
              </kendo-formerror>
            </div>
            <div class="col-md-4 pl-md-2 pr-md-0 mb-3">
              <kendo-formfield showErrors="always">
                <kendo-label text="PO Email"></kendo-label>
                <kendo-textbox (blur)="handlePOEmailBlur()" [required]="isPOTransRequired" formControlName="email" (valueChange)="poEmailChange($event, i)"
                               [maxlength]="poEmailMaxLength" placeholder="e.g. contact@email.com">
                </kendo-textbox>
                <div class="d-flex">
                  <kendo-formerror *ngIf="getPOEmailField(i)?.touched && getPOEmailField(i)?.errors?.email">
                    Invalid email
                  </kendo-formerror>
                  <kendo-formhint *ngIf="!getPOEmailField(i)?.errors?.email  && !(form && isPOTransRequired)">Maximum of {{poEmailMaxLength}} characters</kendo-formhint>
                  <kendo-formerror *ngIf="form && isPOTransRequired">
                    PO Email is required.
                  </kendo-formerror>
                  <kendo-formhint class="ml-auto">{{poEmailCounter[i]}}</kendo-formhint>
                </div>
              </kendo-formfield>
            </div>
            <div *ngIf="poEmailsFormArray.controls.length > 1"
                 class="col-md-auto mt-md-3 pl-md-2 mb-3 mb-md-0 d-flex justify-content-end pt-md-1">
              <kehe-phoenix-button (click)="deletePoTransaction(i)" classAttr="btn-medium btn-delete" name="deletePoItem">
                <em class="fas fa-trash-alt"></em>
              </kehe-phoenix-button>
            </div>
          </div>
        </div>
      </div>
      <kehe-phoenix-button (click)="addPoTransaction()" classAttr="btn-link btn-medium" iconClassAttr="fas fa-plus"
                  name="addPoItem">Add Email
      </kehe-phoenix-button>
    </div>
  </div>
  <div class="row po-trans mt-4" *ngIf="hasPOOrderFormGroup">
    <div class="col">
      <div class="row mb-2 mb-md-0" formGroupName="poOrderMinimum">
        <div class="col-md-3 pr-md-0 mb-3">
          <kendo-formfield>
            <kendo-label text="PO Order Type"></kendo-label>
            <kendo-combobox #poOrderUnit
                            (focus)="onFocus(poOrderUnit)"
                            [data]="orderUnits"
                            formControlName="unit"
                            placeholder="Select Type">
            </kendo-combobox>
            <kendo-formerror *ngIf="form && form.get('poOrderMinimum.unit')?.errors?.required">
              PO Order Type is required.
            </kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col-md-4 pl-md-2 mb-3">
          <kendo-formfield>
            <kendo-label [for]="poOrderMinField" text="PO Order Min"></kendo-label>
            <kendo-numerictextbox
              [format]="getOrderMinFormat('poOrderMinimum.unit')"
              decimals="0"
              [min]="1"
              [max]="99999"
              [maxlength]="5"
              #poOrderMinField
              placeholder="Enter Min"
              formControlName="amount"
              [spinners]="true">
            </kendo-numerictextbox>
            <kendo-formhint>
              PO Minimum Order Quantity (MOQ) is maintained in FROST.
            </kendo-formhint>
            <kendo-formerror *ngIf="this.form.get('poOrderMinimum.amount')?.errors?.min ||
                this.form.get('poOrderMinimum.amount')?.errors?.max">
              Between 1 and 99999
            </kendo-formerror>
            <kendo-formerror *ngIf="form && form.get('poOrderMinimum.amount')?.errors?.required">
              PO Order Min is required.
            </kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
    </div>
  </div>
</div>
