import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { AssociatedSupplierTabStore } from '../associated-supplier-tab.store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  switchMap,
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  startWith,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { SearchSuppliersApiStore } from '../api-stores/search-suppliers-api.store';
import { Store } from '@ngrx/store';
import { selectAddNoteFormValues, selectIsDraftSupplier, selectSupplierEsn } from '../../../store/supplier-detail.selectors';
import { VendorBrokerManagementService } from '../../../services/vendor-broker-management.service';
import { AssociatedSuppliersTabGridStore } from '../associated-suppliers-tab-grid/associated-suppliers-tab-grid.store';
import { MasterVersionApiStore } from '../api-stores/master-version-api.store';

export enum AssociateSupplierFormControlName {
  Supplier = 'Supplier',
}

export interface AssociateNewSupplierModalState {
  isSubmitting: boolean;
  apiErrorMessage: string;
  supplierSearch: string;
}

export const initialState: AssociateNewSupplierModalState = {
  isSubmitting: false,
  apiErrorMessage: null,
  supplierSearch: '',
};

const genericApiErrorText =
  'Unable to complete your request at this time. Please try again.';

@Injectable()
export class AssociateNewSupplierModalStore extends ComponentStore<AssociateNewSupplierModalState> {
  constructor(
    private _associatedSupplierTabStore: AssociatedSupplierTabStore,
    private _associatedSuppliersTabGridStore: AssociatedSuppliersTabGridStore,
    private _searchSuppliersApiStore: SearchSuppliersApiStore,
    private _masterVersionApiStore: MasterVersionApiStore,
    private _store: Store,
    private _vendorBrokerManagementService: VendorBrokerManagementService,
    private _gridStore: AssociatedSuppliersTabGridStore
  ) {
    super(initialState);
    this._searchSuppliersApiStore.loadSuppliers$(this._loadSuppliersTrigger$);
    this.clearApiErrorsOnFormChanges$.subscribe();
    this._searchSuppliersApiStore.apiError$.pipe(takeUntil(this.destroy$)).subscribe((apiError) => {
      this.patchState({apiErrorMessage: apiError && apiError.length > 0 ? genericApiErrorText : null});
    });
  }

  public form = new UntypedFormGroup({
    [AssociateSupplierFormControlName.Supplier]: new UntypedFormControl(
      null,
      Validators.required
    ),
  });

  private _currentSupplierEsn$ = this._store.select(selectSupplierEsn);

  private _isDraftSupplier$ = this._store.select(selectIsDraftSupplier);

  private _addNoteFormValues$ = this._store.select(selectAddNoteFormValues);

  private _isFormValid$ = this.select(
    this.form.statusChanges.pipe(startWith('')),
    (formStatus) => {
      return formStatus === 'VALID';
    }
  );
  private _isSubmitting$ = this.select((state) => state.isSubmitting);

  private _addButton$ = this.select(
    this._isFormValid$,
    this._isSubmitting$,
    this._addNoteFormValues$,
    (isFormValid, isSubmitting, addNoteForm) => {
      return {
        isDisabled: !isFormValid || !addNoteForm?.isValid,
        isSpinning: isSubmitting,
      };
    }
  );

  readonly _suppliers$ = this.select(
    this._searchSuppliersApiStore.data$,
    this._gridStore.associatedSuppliersRows$,
    this._currentSupplierEsn$,
    (allSuppliers, associatedSuppliers, currentEsn) => {
      return allSuppliers?.filter(
        (dropdownSupplier) =>
        currentEsn !== dropdownSupplier.esn &&
          !associatedSuppliers?.some(
            (related) =>
              related.esn === dropdownSupplier.esn ||
              related?.children?.some(
                (child) => child.esn === dropdownSupplier.esn
              )
          )
      );
    }
  );

  readonly vm$ = this.select(
    this.state$,
    this._searchSuppliersApiStore.isLoading$,
    this._suppliers$,
    this._addButton$,
    this._isDraftSupplier$,
    (state, isLoadingSuppliers, suppliers, addButton, isDraftSupplier) => ({
      ...state,
      isLoadingSuppliers,
      suppliers,
      addButton,
      isDraftSupplier,
    })
  );

  closeModal() {
    this._associatedSupplierTabStore.closeAddModal();
  }

  supplierFilterChanged(supplierSearch: string) {
    this.patchState({
      supplierSearch,
    });
  }

  private _supplierSearch$ = this.select((state) => state.supplierSearch).pipe(
    startWith('')
  );

  private _loadSuppliersTrigger$ = this._supplierSearch$.pipe(
    takeUntil(this.destroy$),
    debounceTime(200),
    distinctUntilChanged(),
    map((search) => search)
  );

  clearApiErrorsOnFormChanges$ = this.form.valueChanges.pipe(
    tap(() => {
      this.patchState({
        apiErrorMessage: '',
      });
    })
  );

  // Effects
  addSupplierToSupplierRelationship$ = this.effect((_) => {
    return _.pipe(
      withLatestFrom(this._store.select(selectSupplierEsn),
      this._store.select(selectAddNoteFormValues)
      ),
      switchMap(([_, supplierEsn, addNoteForm]) => {
        this.patchState({
          isSubmitting: true,
          apiErrorMessage: null,
        });

        const selectedSupplier = this.form.get(
          AssociateSupplierFormControlName.Supplier
        ).value;
        const selectedEsn = selectedSupplier?.esn;

        return this._vendorBrokerManagementService
          .addSupplierToSupplierRelationship(supplierEsn, selectedEsn, addNoteForm?.auditNotes)
          .pipe(
            tapResponse(
              (response) => {
                this.onAssociateNewSupplierSuccess(response);
              },
              (error) => {
                this.patchState({
                  isSubmitting: false,
                  apiErrorMessage: genericApiErrorText,
                });
              }
            )
          );
      })
    );
  });

  onAssociateNewSupplierSuccess(response: {esn: string, masterVersionNumber: number}) {
    this.patchState({
      isSubmitting: false,
      apiErrorMessage: null,
    });
    this._masterVersionApiStore.setMasterVersionNumber(response?.masterVersionNumber);
    this._masterVersionApiStore.setHasNoMaster(false);
    this._associatedSupplierTabStore.clearSearchAndApplyDefaultSort();
    this._associatedSuppliersTabGridStore.setIsSyncing(true);
    this._gridStore.updateSortOptions([{ dir: null, field: null}]);
    this.closeModal();
  }
}
