import { createAction, props } from "@ngrx/store";
import { CategoryManager } from "./category-manager";
import { SupplierDevelopmentManager } from "./supplier-development-manager";

export const loadStatusListSuccess = createAction(
    '[Supplier API] Load Status List Success',
    props<{ list: string[] }>()
);

export const loadCategoryManagerListSuccess = createAction(
    '[Supplier API] Load Category Manager List Success',
    props<{ list: CategoryManager[] }>()
);

export const loadSupplierDevelopmentManagerListSuccess = createAction(
    '[Supplier API] Load Development Manager List Success',
    props<{ list: SupplierDevelopmentManager[] }>());