import { CreateOrderResponse, MultiCustSubmitReponse } from '../../common/models/order-create-response-objects.interface';
import { IOrdersNewMultiOrderSubmit, IOrdersNewOrderSubmit, IOrdersNewPromotionalOrderSubmit } from './../models/orders-new-order-submit';
import { IOrdersCloneOrderDto } from '../../common/models/iorders-clone-dto';
import { IFileUpload } from './../models/file-upload';
import { ShowParticipation } from './../../common/models/show-participation';
import { OrdersListCommonFilter } from './../models/orders-list-common-filter';
import { IChip } from '@kehe/phoenix-chips';
import { createAction, props } from '@ngrx/store';
import { Payload } from '../../../../models/payload';
import { OrdersListItem } from '../models/orders-list-item';
import { OrderListTabId } from '../models/eorder-list-tab-id';
import { ConfirmationReportMainEvent } from '../../common/models/confirmation-report-main-event.interface';
import { OrdersListActionError } from '../models/orders-list-action-error';
import { SubmitResponse } from '../models/submit-response.interface';
import { DeleteOrderResponse } from '../../common/models/delete-order-response.interface';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { IActionItem } from '../../../../models/iaction-item';
import { Params } from '@angular/router';
import { ICodeName } from '../../../../models/icode-name';
import { IOrdersFilterWithNumberItem } from '../models/iorders-filter-with-number-item';
import { UploadType } from '../models/upload-type';
import { SyncCheck } from '../../common/models/sync-check.interface';

export enum OrdersListActions {
  SetOrderListFiltersFromQueryParams = '[OrdersList] Set Order List Filters From Query Params',
  SetOrderListFiltersFromQueryParamsSuccess = '[OrdersList] Set Order List Filters From Query Params Success',
  ManageOrdersPageAccessed = '[OrdersList] Manage Orders Page Accessed',
  LoadOrdersListData = '[OrdersList] Load Orders List Data',
  LoadOrdersListDataAndCount = '[OrdersList] Load Orders List Data and Count',
  LoadOrdersListSuccess = '[OrdersList] Load Orders List Success',
  LoadOrdersListError = '[OrdersList] Load Orders List Error',

  OrdersListPageChange = '[OrdersList] Orders List Page Change',
  OpenOrdersListFilterPanel = '[OrdersList] Open Orders Filter Panel',
  CloseOrdersListFilterPanel = '[OrdersList] Close Orders Filter Panel',
  ApplyFilter = '[OrdersList] Apply Filters',
  ApplyQueryParamsFilters = '[OrdersList] Apply Query Params Filters',
  GetDropdownFilterData = '[OrdersList] Get Dropdown Filter Data',
  OrdersListItemSelectAll = '[OrdersList] Orders List Select All',
  OrdersListSearchChange = '[OrdersList] Orders List Search Change',
  OrdersListSortChange = '[OrdersList] Orders List Sort Change',
  OrdersListPopulateAllDcsForFilterSuccess = '[OrdersList] Orders List Populate All Dcs For Filter Success',
  CreateOrdersListFilterChips = '[OrdersList] Create Orders List Filter Chips',
  OrdersListFilterChipClicked = '[OrdersList] Orders List Filter Chip Clicked',
  OrdersListFilterChipsClearAll = '[OrdersList] Orders List Filter Chips Clear All',
  OrdersListChangeSelectedTab = '[OrdersList] Orders List Change Selected Tab',
  OrdersListToggle = '[OrdersList] Orders Toggle',

  GetAvailableCountsSuccess = '[OrdersList] Get Available Counts For Tabs Success',
  GetAvailableCountsError = '[OrdersList] Get Available Counts For Tabs Error',

  OrdersListCloseCreateNewModal = '[OrdersList] Orders List Close Create New Modal',
  OrdersListOpenCreateNewModal = '[OrdersList] Orders List Open Create New Modal',
  OrdersListClearCreateNewModal = '[OrdersList] Orders List Clear Create New Modal',

  OrdersListAddFilesToUpload = '[OrdersList] Orders List Add Files To Upload',
  OrdersListAddFilesToUploadValidationError = '[OrdersList] Orders List Add Files To Upload Validation Error',

  SyncNewListItems = '[OrdersList] Sync New List Items',
  SyncNewListItemsSuccess = '[OrdersList] Sync New List Items Success',
  SyncNewListItemsError = '[OrdersList] Sync New List Items Error',

  SyncOutOfSyncListItems = '[OrdersList] Sync Out Of Sync List Items',
  SyncOutOfSyncListItemsSuccess = '[OrdersList] Sync Out Of Sync List Items Success',
  SyncOutOfSyncListItemsError = '[OrdersList] Sync Out Of Sync List Items Error',

  OrdersListSetBannerMessage = '[OrdersList] Orders List Set Banner Message',
  OrdersListRemoveFilesToUpload = '[OrdersList] Orders List Remove Files To Upload',

  OrdersListGetOrderUploadUrl = '[OrdersList] Get Order Upload Url',
  OrdersListGetOrderUploadUrlSuccess = '[OrdersList] Get Order Upload Url Success',
  OrdersListGetOrderUploadUrlError = '[OrdersList] Get Order Upload Url Error',

  OrdersListUploadFileSuccess = '[OrdersList] Upload File Success',
  OrdersListUploadFileError = '[OrdersList] Upload File Error',
  OrdersListUploadProgress = '[OrdersList] Upload Progress',

  OrdersListNewOrderModalClearErrors = '[OrdersList] New Order Modal Clear Errors',
  OrdersListNewOrderModalWithFileSubmit = '[OrdersList] New Order Modal With File Submit',
  OrdersListNewOrderModalWithFileSubmitSuccess = '[OrdersList] New Order Modal With File Submit Success',
  OrdersListNewOrderModalWithFileSubmitError = '[OrdersList] New Order Modal With File Submit Error',

  OrdersListPendingOrderSubmit = '[OrdersList] Orders List Pending Order Submit',
  OrdersListPendingOrderSubmitSuccess = '[OrdersList] Orders List Pending Order Submit Success',
  OrdersListPendingOrderSubmitError = '[OrdersList] Orders List Pending Order Submit Error',

  OrdersListMultiCustOrderSubmit = '[OrdersList] Orders List Multi Cust Order Submit',
  OrdersListMultiCustOrderSubmitSuccess = '[OrdersList] Orders List Multi Cust Order Submit Success',
  OrdersListMultiCustOrderSubmitError = '[OrdersList] Orders List Multi Cust Order Submit Error',
  OrdersListNavigateToDraftTab = '[OrdersList] Orders List Navigate To Draft Tab',

  OrdersListPromotionalOrderSubmit = '[OrdersList] Orders List Promotional Order Submit',
  OrdersListPromotionalOrderSubmitSuccess = '[OrdersList] Orders List Promotional Order Submit Success',
  OrdersListPromotionalOrderSubmitError = '[OrdersList] Orders List Promotional Order Submit Error',

  OrdersListNewOrderModalNoFileSubmit = '[OrdersList] New Order Modal No File Submit',
  OrdersListNewOrderModalNoFileSubmitSuccess = '[OrdersList] New Order Modal No File Submit Success',
  OrdersListNewOrderModalNoFileSubmitError = '[OrdersList] New Order Modal No File Submit Error',

  OrdersListExportOrder = '[OrdersList] Export Single Order',
  OrdersListExportOrderSuccess = '[OrdersList] Export Single Order Success',
  OrdersListExportOrderError = '[OrdersList] Export Single Order Error',

  OrdersListDelete = '[OrdersList] Delete Order',
  OrdersListDeleteSuccess = '[OrdersList] Delete Order Success',
  OrdersListDeleteError = '[OrdersList] Delete Order Error',

  CloseDeleteOrderModal = '[OrdersList] Close Delete Order Modal',
  OpenDeleteOrderModal = '[OrdersList] Open Delete Order Modal',

  OrdersListCloseDeleteOrderErrorBanner = '[OrdersList] Orders List Delete Order Error Banner',

  OrdersListCloneOrder = '[OrdersList] Orders List Clone Order',
  OrdersListCloneOrderSuccess = '[OrdersList] Orders List Clone Order Success',
  OrdersListCloneOrderError = '[OrdersList] Orders List Clone OrderError',

  CloneEllipsesOptionClicked = '[OrdersList] Clone Ellipses Option Clicked',
  OrdersListCloseCloneOrderModal = '[OrdersList] Close Clone Order Modal',
  OrdersListCloneOrderErrorBanner = '[OrdersList] Clone Order Error Banner',

  LoadEnterpriseShowHistory = '[OrdersList] Load Enterprise Show History',
  LoadEnterpriseShowHistorySuccess = '[OrdersList] Load Enterprise Show History Success',
  LoadEnterpriseShowHistoryError = '[OrdersList] Load Enterprise Show History Success Error',

  getShowHistory = '[OrdersList] Load Show History',
  getShowHistorySuccess = '[OrdersList] Load Show History Success',
  getShowHistoryError = '[OrdersList] Load Enterprise Show History Success Error',

  DownloadShowReport = '[OrdersList] Download Show Report',
  DownloadShowReportHistory = '[OrdersList] Download Show Report History',
  DownloadShowReportSuccess = '[OrdersList] Download Show Report Success',
  DownloadShowReportError = '[OrdersList] Download Show Report Error',

  CloseShowReportBanner = '[OrdersList] Close Show Report Banner Alert',
  CloseCreateSuccessAlert = '[OrdersList] Close Multi Cust Order Create Success Banner Alert',
  CloseCreateErrorAlert = '[OrdersList] Close Multi Cust Order Create Error Banner Alert',

  SubmitBulkActionClicked = '[OrdersList] Submit Bulk Action Clicked',
  SubmitElipsesActionClicked = '[OrdersList] Submit Elipses Action Clicked',
  CloseConfirmationReportModalClicked = '[OrdersList] Close Confirmation Report Modal Clicked',

  OrdersListSubmitOrders = '[OrdersList] Submit Orders',
  OrdersListSubmitOrdersSuccess = '[OrdersList] Submit Orders Success',
  OrdersListSubmitOrdersError = '[OrdersList] Submit Orders Error',

  OrdersListSubmitOrdersWithConfirmationReport = '[OrdersList] Submit Orders With Confirmation Report',
  OrdersListSubmitOrdersWithConfirmationReportSuccess = '[OrdersList] Submit Orders With Confirmation Report Success',
  OrdersListSubmitOrdersWithConfirmationReportError = '[OrdersList] Submit Orders With Confirmation Report Error',

  GenerateConfirmationReport = '[OrdersList] Generate Confirmation Report',
  GenerateConfirmationReportSuccess = '[OrdersList] Generate Confirmation Report Success',
  GenerateConfirmationReportError = '[OrdersList] Generate Confirmation Report Error',

  LoadOrdersListAfterSubmitSuccess = '[OrdersList] Load Orders List After Submit Success',
  LoadOrdersListAfterSubmitError = '[OrdersList] Load Orders List After Submit Error',

  CloseSubmitSuccessAlert = '[OrdersList] Close Multi Cust Order Create Success Banner Alert',
  CloseSubmitErrorAlert = '[OrdersList] Close Multi Cust Order Create Error Banner Alert',

  SetEllipsesMenuOptions = '[OrdersList] Set Ellipses Menu Options',
  SetShowDownloadReportModal = '[Orders] Set Show Download Report Modal',

  // Side panel filter
  GetSidePanelFilterAvailableDistributionCenters = '[OrdersList] Get Side Panel Filter Available Distribution Centers',
  GetSidePanelFilterAvailableDistributionCentersSuccess = '[OrdersList] Get Side Panel Filter Available Distribution Centers Success',
  GetSidePanelFilterAvailableDistributionCentersFailure = '[OrdersList] Get Side Panel Filter Available Distribution Centers Failure',
  GetSidePanelFilterAvailableCustomers = '[OrdersList] Get Side Panel Filter Available Customers',
  GetSidePanelFilterAvailableCustomersSuccess = '[OrdersList] Get Side Panel Filter Available Customers Success',
  GetSidePanelFilterAvailableCustomersFailure = '[OrdersList] Get Side Panel Filter Available Customers Failure',
  GetSidePanelFilterAvailableSources = '[OrdersList] Get Side Panel Filter Available Sources',
  GetSidePanelFilterAvailableSourcesSuccess = '[OrdersList] Get Side Panel Filter Available Sources Success',
  GetSidePanelFilterAvailableSourcesFailure = '[OrdersList] Get Side Panel Filter Available Sources Failure',
  GetSidePanelFilterAvailableTypes = '[OrdersList] Get Side Panel Filter Available Types',
  GetSidePanelFilterAvailableTypesSuccess = '[OrdersList] Get Side Panel Filter Available Types Success',
  GetSidePanelFilterAvailableTypesFailure = '[OrdersList] Get Side Panel Filter Available Types Failure',
  GetSidePanelFilterAvailableChains = '[OrdersList] Get Side Panel Filter Available Chains',
  GetSidePanelFilterAvailableChainsSuccess = '[OrdersList] Get Side Panel Filter Available Chains Success',
  GetSidePanelFilterAvailableChainsFailure = '[OrdersList] Get Side Panel Filter Available Chains Failure',
}

export const setOrderListFiltersFromQueryParams = createAction(
  OrdersListActions.SetOrderListFiltersFromQueryParams
);

export const setOrderListFiltersFromQueryParamsSuccess = createAction(
  OrdersListActions.SetOrderListFiltersFromQueryParamsSuccess,
  props<{ params: Params }>()
);

export const syncNewListItems = createAction(
  OrdersListActions.SyncNewListItems,
);

export const syncNewListItemsSuccess = createAction(
  OrdersListActions.SyncNewListItemsSuccess,
  props<{ newListItems: OrdersListItem[], availableCount: number, ellipsesMenuOptions: Array<OptionMenuItem>, itemActions: Array<IActionItem> }>()
);

export const syncNewListItemsError = createAction(
  OrdersListActions.SyncNewListItemsError,
);

export const syncOutOfSyncListItems = createAction(
  OrdersListActions.SyncOutOfSyncListItems,
);

export const syncOutOfSyncListItemsSuccess = createAction(
  OrdersListActions.SyncOutOfSyncListItemsSuccess,
  props<{ newListItems: OrdersListItem[], ellipsesMenuOptions: Array<OptionMenuItem>, itemActions: Array<IActionItem> }>()
);

export const syncOutOfSyncListItemsError = createAction(
  OrdersListActions.SyncOutOfSyncListItemsError,
);

export const ordersListAddFilesToUpload = createAction(
  OrdersListActions.OrdersListAddFilesToUpload,
  props<{ file: IFileUpload }>()
);

export const ordersListNewOrderModalWithFileSubmit = createAction(
  OrdersListActions.OrdersListNewOrderModalWithFileSubmit,
  props<{ salesOrder: IOrdersNewOrderSubmit }>()
);

export const ordersListNewOrderModalWithFileSubmitSuccess = createAction(
  OrdersListActions.OrdersListNewOrderModalWithFileSubmitSuccess,
  props<{ newOrder: CreateOrderResponse }>()
);

export const ordersListNewOrderModalWithFileSubmitError = createAction(
  OrdersListActions.OrdersListNewOrderModalWithFileSubmitError,
  props<{ error: any }>()
);

export const ordersListNewOrderModalNoFileSubmit = createAction(
  OrdersListActions.OrdersListNewOrderModalNoFileSubmit,
  props<{ salesOrder: IOrdersNewOrderSubmit }>()
);

export const ordersListNewOrderModalNoFileSubmitSuccess = createAction(
  OrdersListActions.OrdersListNewOrderModalNoFileSubmitSuccess,
  props<{ newOrder: CreateOrderResponse }>()
);

export const ordersListNewOrderModalNoFileSubmitError = createAction(
  OrdersListActions.OrdersListNewOrderModalNoFileSubmitError,
  props<{ error: any }>()
);

export const ordersListNewOrderModalClearErrors = createAction(
  OrdersListActions.OrdersListNewOrderModalClearErrors,
);

export const ordersListPendingOrderSubmit = createAction(
  OrdersListActions.OrdersListPendingOrderSubmit,
  props<{ pendingOrder: IOrdersNewOrderSubmit }>()
);

export const ordersListPendingOrderSubmitSuccess = createAction(
  OrdersListActions.OrdersListPendingOrderSubmitSuccess,
  props<{ newOrder: CreateOrderResponse }>()
);

export const OrdersListPendingOrderSubmitError = createAction(
  OrdersListActions.OrdersListPendingOrderSubmitError,
  props<{ error: any }>()
);

export const ordersListMultiCustOrderSubmit = createAction(
  OrdersListActions.OrdersListMultiCustOrderSubmit,
  props<{ salesOrder: IOrdersNewMultiOrderSubmit }>()
);

export const ordersListMultiCustOrderSubmitSuccess = createAction(
  OrdersListActions.OrdersListMultiCustOrderSubmitSuccess,
  props<{
    orders: Payload<Array<OrdersListItem>>,
    multiCustSubmitReponse: MultiCustSubmitReponse,
    ellipsesMenuOptions: Array<OptionMenuItem>,
    itemActions: Array<IActionItem>,
    isFlagOn: boolean,
    uploadType: UploadType
  }>()
);

export const ordersListMultiCustOrderSubmitError = createAction(
  OrdersListActions.OrdersListMultiCustOrderSubmitError,
  props<{ error: any }>()
);

export const ordersListPromotionalOrderSubmit = createAction(
  OrdersListActions.OrdersListPromotionalOrderSubmit,
  props<{ salesOrder: IOrdersNewPromotionalOrderSubmit }>()
);

export const ordersListPromotionalOrderSubmitSuccess = createAction(
  OrdersListActions.OrdersListPromotionalOrderSubmitSuccess,
  props<{
    orders: Payload<Array<OrdersListItem>>,
    multiCustSubmitReponse: MultiCustSubmitReponse,
    ellipsesMenuOptions: Array<OptionMenuItem>,
    itemActions: Array<IActionItem>
    uploadType: UploadType}>()
);

export const ordersListPromotionalOrderSubmitError = createAction(
  OrdersListActions.OrdersListPromotionalOrderSubmitError,
  props<{ error: any }>()
);

export const ordersListNavigateToDraftTab = createAction(
  OrdersListActions.OrdersListNavigateToDraftTab,
  props<{ multiCustSubmitReponse: MultiCustSubmitReponse, uploadType: UploadType }>()
);

export const ordersListSetBannerMessage = createAction(
  OrdersListActions.OrdersListSetBannerMessage,
  props<{ message: string }>()
);

export const ordersListAddFilesToUploadValidationError = createAction(
  OrdersListActions.OrdersListAddFilesToUploadValidationError
);

export const ordersListRemoveFilesToUpload = createAction(
  OrdersListActions.OrdersListRemoveFilesToUpload,
);

export const ordersListUploadProgress = createAction(
  OrdersListActions.OrdersListUploadProgress,
  props<{ progress: number}>()
);

export const ordersListGetOrderUploadUrl = createAction(
  OrdersListActions.OrdersListGetOrderUploadUrl,
  props<{ uid: string; extension: string}>()
);

export const ordersListGetOrderUploadUrlSuccess = createAction(
  OrdersListActions.OrdersListGetOrderUploadUrlSuccess,
  props<{ url: string }>()
);
export const ordersListGetOrderUploadUrlError = createAction(
  OrdersListActions.OrdersListGetOrderUploadUrlError,
);

export const ordersListUploadFileSuccess = createAction(
  OrdersListActions.OrdersListUploadFileSuccess,
);

export const ordersListUploadFileError = createAction(
  OrdersListActions.OrdersListUploadFileError,
);

export const manageOrdersPageAccessed = createAction(
  OrdersListActions.ManageOrdersPageAccessed,
  props<{ userNavigatedFromParent: boolean }>()
);

export const loadOrdersListError = createAction(
  OrdersListActions.LoadOrdersListError
);

export const loadOrdersListData = createAction(
  OrdersListActions.LoadOrdersListData
);

export const loadOrdersListAndCount = createAction(
  OrdersListActions.LoadOrdersListDataAndCount
);

export const loadOrdersListSuccess = createAction(
  OrdersListActions.LoadOrdersListSuccess,
  props<{ orders: Payload<Array<OrdersListItem>>, ellipsesMenuOptions: Array<OptionMenuItem>, itemActions: Array<IActionItem> }>()
);

export const getAvailableCountsSuccess = createAction(
  OrdersListActions.GetAvailableCountsSuccess,
  props<{ counts: Array<Payload<Array<OrdersListItem>>> }>()
);

export const getAvailableCountsError = createAction(
  OrdersListActions.GetAvailableCountsError
);

export const ordersListPageChange = createAction(
  OrdersListActions.OrdersListPageChange,
  props<{ pageCount: number; pageIndex: number, skip: number }>()
);

export const openOrdersListFilter = createAction(
  OrdersListActions.OpenOrdersListFilterPanel
);

export const closeOrdersListFilter = createAction(
  OrdersListActions.CloseOrdersListFilterPanel
);

export const applyFilter = createAction(
  OrdersListActions.ApplyFilter,
  props<{ OrdersListCommonFilter: OrdersListCommonFilter }>()
);

export const applyQueryParamsFilters = createAction(
  OrdersListActions.ApplyQueryParamsFilters,
  props<{ OrdersListCommonFilter: OrdersListCommonFilter }>()
);

export const getDropdownFilterData = createAction(
  OrdersListActions.GetDropdownFilterData
);

export const ordersListSearchChange = createAction(
  OrdersListActions.OrdersListSearchChange,
  props<{ search: string }>()
);

export const ordersListItemSelectAll = createAction(
  OrdersListActions.OrdersListItemSelectAll,
);

export const ordersListSortChange = createAction(
  OrdersListActions.OrdersListSortChange,
  props<{ orderDirection: number, sortName: string }>()
);

export const ordersListCloseCreateNewModal = createAction(
  OrdersListActions.OrdersListCloseCreateNewModal
);

export const ordersListOpenCreateNewModal = createAction(
  OrdersListActions.OrdersListOpenCreateNewModal
);

export const ordersListClearCreateNewModal = createAction(
  OrdersListActions.OrdersListClearCreateNewModal
);

export const ordersListPopulateAllDcsForFilterSuccess = createAction(
  OrdersListActions.OrdersListPopulateAllDcsForFilterSuccess,
  props<{ dcs: Array<any> }>()
);

export const createOrdersListFilterChips = createAction(
  OrdersListActions.CreateOrdersListFilterChips
);

export const ordersListFilterChipClicked = createAction(
  OrdersListActions.OrdersListFilterChipClicked,
  props<{ chip: IChip }>()
);

export const ordersListFilterChipsClearAll = createAction(
  OrdersListActions.OrdersListFilterChipsClearAll
);

export const ordersListChangeSelectedTab = createAction(
  OrdersListActions.OrdersListChangeSelectedTab,
  props<{selectedTab: OrderListTabId}>()
);

export const ordersListToggle = createAction(
  OrdersListActions.OrdersListToggle,
  props<{selectedOrderId: number}>()
);

export const deleteOrder = createAction(
  OrdersListActions.OrdersListDelete,
);

export const deleteOrderSuccess = createAction(
  OrdersListActions.OrdersListDeleteSuccess,
  props<{ response: Array<DeleteOrderResponse | OrdersListActionError> }>()
);

export const deleteOrderError = createAction(
  OrdersListActions.OrdersListDeleteError,
  props<{ error: any }>()
);

export const openDeleteOrderModal = createAction(
  OrdersListActions.OpenDeleteOrderModal,
  props<{ orders: Array<OrdersListItem> }>()
);

export const closeDeleteOrderModal = createAction(
  OrdersListActions.CloseDeleteOrderModal,
);
export const exportOrder = createAction(
  OrdersListActions.OrdersListExportOrder,
  props<{order: OrdersListItem}>()
);
export const exportOrderSuccess = createAction(
  OrdersListActions.OrdersListExportOrderSuccess
);
export const exportOrderError = createAction(
  OrdersListActions.OrdersListExportOrderError,
  props<{error: any}>()
);


export const ordersListCloseDeleteOrderErrorBanner = createAction(
  OrdersListActions.OrdersListCloseDeleteOrderErrorBanner,
);


export const cloneOrder = createAction(
  OrdersListActions.OrdersListCloneOrder,
  props<{orderToClone: IOrdersCloneOrderDto}>()
);
export const cloneOrderSuccess = createAction(
  OrdersListActions.OrdersListCloneOrderSuccess,
  props<{newOrder: CreateOrderResponse}>()
);
export const cloneOrderError = createAction(
  OrdersListActions.OrdersListCloneOrderError,
  props<{error: { error: { errorMessages: string[] }}}>()
);

export const cloneEllipsesOptionClicked = createAction(
  OrdersListActions.CloneEllipsesOptionClicked,
  props<{orderToClone: OrdersListItem}>()
);
export const closeCloneOrderModal = createAction(
  OrdersListActions.OrdersListCloseCloneOrderModal,
);

// Show History
export const loadEnterpriseShowHistory = createAction(
  OrdersListActions.LoadEnterpriseShowHistory
);

export const loadEnterpriseShowHistorySuccess = createAction(
  OrdersListActions.LoadEnterpriseShowHistorySuccess,
  props<{ data: ShowParticipation[] }>()
);

export const loadEnterpriseShowHistoryError = createAction(
  OrdersListActions.LoadEnterpriseShowHistoryError
);

export const downloadShowReport = createAction(
  OrdersListActions.DownloadShowReport,
  props<{ showId: string }>()
);

export const getShowHistory = createAction(
  OrdersListActions.getShowHistory
);

export const getShowHistorySuccess = createAction(
  OrdersListActions.getShowHistorySuccess,
  props<{ payload: ShowParticipation[] }>()
);

export const getShowHistoryError = createAction(
  OrdersListActions.getShowHistoryError
);

export const downloadShowReportHistory = createAction(
  OrdersListActions.DownloadShowReportHistory,
  props<{ showId: string }>()
);

export const downloadShowReportSuccess = createAction(
  OrdersListActions.DownloadShowReportSuccess,
  props<{ responseMessage?: string }>()
);

export const downloadShowReportError = createAction(
  OrdersListActions.DownloadShowReportError,
  props<{ error: any }>()
);

export const downloadShowReportHistoryError = createAction(
  OrdersListActions.DownloadShowReportError
  );

export const closeShowReportBannerAlert = createAction(
  OrdersListActions.CloseShowReportBanner
);

export const closeCreateSuccessAlert = createAction(
  OrdersListActions.CloseCreateSuccessAlert,
);

export const closeCreateErrorAlert = createAction(
  OrdersListActions.CloseCreateErrorAlert,
);

// Bulk Submit
export const submitBulkActionClicked = createAction(
  OrdersListActions.SubmitBulkActionClicked,
  props<{ orders: Array<OrdersListItem> }>()
);
export const displayConfirmationReportModal = createAction(
  '[OrdersList] Display Confirmation Report Modal',
  props<{ orders: Array<OrdersListItem> }>()
);
export const submitElipsesActionClicked = createAction(
  OrdersListActions.SubmitElipsesActionClicked,
  props<{ order: OrdersListItem }>()
);
export const closeConfirmationReportModalClicked = createAction(
  OrdersListActions.CloseConfirmationReportModalClicked,
);

export const submitOrders = createAction(
  OrdersListActions.OrdersListSubmitOrders,
);
export const submitOrdersSuccess = createAction(
  OrdersListActions.OrdersListSubmitOrdersSuccess,
  props<{ successItems: SubmitResponse[], errorItems: OrdersListActionError[] }>()
);
export const submitOrdersError = createAction(
  OrdersListActions.OrdersListSubmitOrdersError,
  props<{error: any}>()
);

export const submitOrdersWithConfirmationReport = createAction(
  OrdersListActions.OrdersListSubmitOrdersWithConfirmationReport,
  props<{ confirmationInfo: ConfirmationReportMainEvent }>()
);
export const submitOrdersWithConfirmationReportSuccess = createAction(
  OrdersListActions.OrdersListSubmitOrdersWithConfirmationReportSuccess,
  props<{ successItems: SubmitResponse[], errorItems: OrdersListActionError[], confirmationInfo: ConfirmationReportMainEvent }>()
);
export const submitOrdersWithConfirmationReportError = createAction(
  OrdersListActions.OrdersListSubmitOrdersWithConfirmationReportError,
  props<{error: any}>()
);

export const generateConfirmationReport = createAction(
  OrdersListActions.GenerateConfirmationReport,
  props<{ confirmationInfo: ConfirmationReportMainEvent, selectedIds: Array<number> }>()
);
export const generateConfirmationReportSuccess = createAction(
  OrdersListActions.GenerateConfirmationReportSuccess,
);
export const generateConfirmationReportError = createAction(
  OrdersListActions.GenerateConfirmationReportError,
  props<{ error: any }>()
);

export const closeSubmitSuccessAlert = createAction(
  OrdersListActions.CloseSubmitSuccessAlert,
);
export const closeSubmitErrorAlert = createAction(
  OrdersListActions.CloseSubmitErrorAlert,
);
export const closeSingleExportErrorAlert = createAction(
  '[OrdersList] Close Single Export Error Banner Alert',
);

export const setShowDownloadReportModal = createAction(
  OrdersListActions.SetShowDownloadReportModal,
  props<{ showReportModal: boolean }>()
);

// Start Side Panel Filters
export const getSidePanelFilterAvailableDistributionCenters = createAction(
  OrdersListActions.GetSidePanelFilterAvailableDistributionCenters,
);
export const getSidePanelFilterAvailableDistributionCentersSuccess = createAction(
  OrdersListActions.GetSidePanelFilterAvailableDistributionCentersSuccess,
  props<{ availableDcs: Array<IOrdersFilterWithNumberItem> }>()
);
export const getSidePanelFilterAvailableDistributionCentersFailure = createAction(
  OrdersListActions.GetSidePanelFilterAvailableDistributionCentersFailure
);
export const getSidePanelFilterAvailableCustomers = createAction(
  OrdersListActions.GetSidePanelFilterAvailableCustomers,
  props<{ customerSearchStr: string }>()
);
export const getSidePanelFilterAvailableCustomersSuccess = createAction(
  OrdersListActions.GetSidePanelFilterAvailableCustomersSuccess,
  props<{ availableCustomers: Array<IOrdersFilterWithNumberItem> }>()
);
export const getSidePanelFilterAvailableCustomersFailure = createAction(
  OrdersListActions.GetSidePanelFilterAvailableCustomersFailure
);
export const getSidePanelFilterAvailableSources = createAction(
  OrdersListActions.GetSidePanelFilterAvailableSources,
);
export const getSidePanelFilterAvailableSourcesSuccess = createAction(
  OrdersListActions.GetSidePanelFilterAvailableSourcesSuccess,
  props<{ availableSources: Array<ICodeName> }>()
);
export const getSidePanelFilterAvailableSourcesFailure = createAction(
  OrdersListActions.GetSidePanelFilterAvailableSourcesFailure
);
export const getSidePanelFilterAvailableTypes = createAction(
  OrdersListActions.GetSidePanelFilterAvailableTypes,
);
export const getSidePanelFilterAvailableTypesSuccess = createAction(
  OrdersListActions.GetSidePanelFilterAvailableTypesSuccess,
  props<{ availableTypes: Array<ICodeName> }>()
);
export const getSidePanelFilterAvailableTypesFailure = createAction(
  OrdersListActions.GetSidePanelFilterAvailableTypesFailure
);
export const getSidePanelFilterAvailableChains = createAction(
  OrdersListActions.GetSidePanelFilterAvailableChains,
);
export const getSidePanelFilterAvailableChainsSuccess = createAction(
  OrdersListActions.GetSidePanelFilterAvailableChainsSuccess,
  props<{ availableChains: Array<ICodeName> }>()
);
export const getSidePanelFilterAvailableChainsFailure = createAction(
  OrdersListActions.GetSidePanelFilterAvailableChainsFailure
);
// End Side Panel Filters

// Start Export Orders in Bulk
export const bulkExportOrders = createAction(
  '[OrdersList] Bulk Export Orders'
);
export const bulkExportOrdersSuccess = createAction(
  '[OrdersList] Bulk Export Orders Success',
  props<{ bulkExportGuid: string }>()
);
export const bulkExportOrdersError = createAction(
  '[OrdersList] Bulk Export Orders Error',
  props<{ error: any }>()
);
// End Export Orders in Bulk
export const displayOrdersBulkActionErrorBanner = createAction(
  '[OrdersList] Display Orders Bulk Action Error Banner',
  props<{ message: string }>()
);
export const closeOrdersBulkActionErrorBanner = createAction(
  '[OrdersList] Close Orders Bulk Action Error Banner'
);

export const downloadTemplate = createAction(
  '[OrdersList] Template Document',
  props<{ template: string }>()
);

export const downloadTemplateCompleted = createAction(
  '[OrdersList] Download Template Completed',
  props<{ template: string }>()
);

// Start: NEW isInProgress syncing logic
export const triggerSyncCheck = createAction(
  '[OrdersList] Trigger Sync Check'
);
export const getSyncCheckSuccess = createAction(
  '[OrdersList] Get Sync Check Success',
  props<{ orders: SyncCheck[], ellipsesMenuOptions: Array<OptionMenuItem>, itemActions: Array<IActionItem> }>()
);
export const getSyncCheckFailure = createAction(
  '[OrdersList] Get Sync Check Failure',
  props<{ error: any }>()
);
// End: NEW isInProgress syncing logic

export const openManageProgressSidePanel = createAction(
  '[OrdersList] Open Manage Progress Side Panel'
);
