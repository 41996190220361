import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import { brokerPatchNotesModal, hideRemoveBrokerModal } from '../../store/supplier-detail.actions';
import { selectSaveInProgress, selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-delete-broker-modal',
  templateUrl: './delete-broker-modal.component.html',
  styleUrls: ['./delete-broker-modal.component.scss']
})
export class DeleteBrokerModalComponent extends Destroyable implements OnInit {
  supplierEsn: string;
  isSaving = false;
  brokerPatch: any;
  isSaveInProgress: boolean;

  constructor(private _store: Store) { super(); }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSupplierDetail))
      .subscribe(supplier => {
        this.supplierEsn = supplier.esn;
    });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSaveInProgress))
      .subscribe(val => {
        this.isSaveInProgress = val;
    });
  }


  deleteBroker() {
    this.brokerPatch = { esn: this.supplierEsn, broker: {} };
    this._store.dispatch(brokerPatchNotesModal({ brokerForm: this.brokerPatch}));
  }

  cancelModal() {
    this._store.dispatch(hideRemoveBrokerModal());
  }
  handleKeheModalEvents(e) {
    if (e.type === 'close' && !this.isSaveInProgress) {
      this.cancelModal();
    }
  }
  public isSaveDisabled() {
    return this.isSaveInProgress;
  }
}
