import { Component } from '@angular/core';
import { selectDcPaymentHoldGridVm } from './dc-payment-hold-grid.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-dc-payment-hold-grid',
  templateUrl: './dc-payment-hold-grid.component.html',
})
export class DcPaymentHoldGridComponent {
  vm$ = this.store.select(selectDcPaymentHoldGridVm);

  constructor(private readonly store: Store) {}
}
