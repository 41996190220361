<div *ngIf="supplier" [formGroup]="form">
  <div class="terms-form-container">
    <div class="row">
      <div class="col">
        <h4>Summary</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <kendo-formfield>
            <kendo-label [for]="typeFormField" text="Supplier Type"></kendo-label>
            <kendo-combobox
              #typeFormField
              formControlName="supplierType"
              [data]="types"
              [textField]="'label'"
              [valueField]="'value'"
              [valuePrimitive]="true"
              [filterable]="true"
              [kendoDropDownFilter]="{operator: 'contains'}"
              placeholder="Select Supplier Type"
              (focus)="onFocus(typeFormField)"
            ></kendo-combobox>
            <kendo-formerror *ngIf="hasRequiredError('supplierType')">
              Supplier Type is required.
            </kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="form-group" formGroupName="terms">
          <kendo-formfield>
            <kendo-label [for]="contractDateFormField" text="Contract Date"></kendo-label>
            <kendo-datepicker #contractDateFormField tabindex="0"
                              formControlName="contractDate"
                              placeholder='mm/dd/yyyy'
                              [readonly]="isActiveSupplier()"
                              inputName="contractDate"
                              [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }"
                              [format]="'MM/dd/yyyy'">
            </kendo-datepicker>
            <kendo-formhint *ngIf="isActiveSupplier()">
              This field cannot be edited.
            </kendo-formhint>
            <kendo-formerror *ngIf="contractDateField?.errors?.required">
              Contract Date is required.
            </kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="form-group" formGroupName="terms">
          <kendo-formfield>
            <kendo-label [for]="contractDateFormField" text="Change Date"></kendo-label>
            <kendo-datepicker #contractDateFormField tabindex="0"
                              formControlName="changeDate"
                              placeholder='mm/dd/yyyy'
                              inputName="changeDate"
                              [readonly]="true"
                              [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }"
                              [format]="'MM/dd/yyyy'">
            </kendo-datepicker>
            <kendo-formhint>This field cannot be edited.</kendo-formhint>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-12 col-md-6" formGroupName="terms">
        <div class="form-group">
          <kendo-label text="Terms Form Exemption"></kendo-label>
          <kendo-formhint *ngIf="isActiveSupplier() && !hasRequiredError('terms.hasTermsFormExemption')">
            This field cannot be edited.
          </kendo-formhint>
          <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasTermsFormExemption')">
            Terms Form Exemption is required.
          </kendo-formerror>
          <ul class="k-radio-list pl-0 mt-1">
            <li class="k-radio-item">
              <input #hasTermsFormExemptionYes
                     [value]="true"
                     class="mr-0"
                     formControlName="hasTermsFormExemption"
                     kendoRadioButton
                     name="hasTermsFormExemption"
                     type="radio">
              <kendo-label class='ml-2' [for]="hasTermsFormExemptionYes">Yes</kendo-label>
            </li>
            <li class="k-radio-item">
              <input #hasTermsFormExemptionNo
                     [value]="false"
                     class="mr-0"
                     formControlName="hasTermsFormExemption"
                     kendoRadioButton
                     name="hasTermsFormExemption"
                     type="radio">
              <kendo-label class='ml-2' [for]="hasTermsFormExemptionNo">No</kendo-label>
            </li>
          </ul>
        </div>
        <div class="form-group">
          <kendo-label text="Payment Hold"></kendo-label>
          <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasProcurementHold')">
            Payment Hold is required.
          </kendo-formerror>
          <ul class="k-radio-list pl-0 mt-1">
            <li class="k-radio-item">
              <input #hasProcurementHoldYes
                     [value]="true"
                     class="mr-0"
                     formControlName="hasProcurementHold"
                     kendoRadioButton
                     name="hasProcurementHold"
                     type="radio">
              <kendo-label class='ml-2' [for]="hasProcurementHoldYes">Accepts Program</kendo-label>
            </li>
            <li class="k-radio-item">
              <input #hasProcurementHoldNo
                     [value]="false"
                     class="mr-0"
                     formControlName="hasProcurementHold"
                     kendoRadioButton
                     name="hasProcurementHold"
                     type="radio">
              <kendo-label class='ml-2' [for]="hasProcurementHoldNo">Excluded from Program</kendo-label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div formGroupName="terms">
    <div class="terms-form-container">
      <div class="row">
        <div class="col">
          <h4>Audits</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-label text="Audits"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasStatementAudit')">
              Audits is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasStatementAuditYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasStatementAudit"
                       kendoRadioButton
                       name="hasStatementAudit"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasStatementAuditYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasStatementAuditNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasStatementAudit"
                       kendoRadioButton
                       name="hasStatementAudit"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasStatementAuditNo">No</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="auditTimeFrameFormField" text="Audit Timeframe"></kendo-label>
              <kendo-combobox
                #auditTimeFrameFormField
                (focus)="onFocus(auditTimeFrameFormField)"
                [data]="auditTimeFrameList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'description'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                formControlName="auditTimeFrameCode"
                placeholder="Select Audit Timeframe"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.auditTimeFrameCode')?.errors?.required">
                Audit Timeframe is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-label text="Promotion Allowance Audits"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasPromoAllow')">
              Promotion Allowance Audits is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasPromoAllowYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasPromoAllow"
                       kendoRadioButton
                       name="hasPromoAllow"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasPromoAllowYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasPromoAllowNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasPromoAllow"
                       kendoRadioButton
                       name="hasPromoAllow"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasPromoAllowNo">No</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <kendo-label text="Promotional Price Protection Audits"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasPromoPriceProtect')">
              Promotional Price Protection Audits is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasPromoPriceProtectYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasPromoPriceProtect"
                       kendoRadioButton
                       name="hasPromoPriceProtect"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasPromoPriceProtectYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasPromoPriceProtectNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasPromoPriceProtect"
                       kendoRadioButton
                       name="hasPromoPriceProtect"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasPromoPriceProtectNo">No</kendo-label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="terms-form-container">
      <div class="row">
        <div class="col">
          <h4>Item Requirements</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="shelfLifePercentageFormField" text="Shelf Life Commitment"></kendo-label>
              <kendo-numerictextbox
                format="##\%"
                [decimals]="0"
                [min]="50"
                [max]="100"
                #shelfLifePercentageFormField
                formControlName="shelfLifePercentage"
                placeholder="e.g. 50%"
                [spinners]="false">
              </kendo-numerictextbox>
              <kendo-formhint>Between 50% and 100%</kendo-formhint>
              <kendo-formerror *ngIf="shelfLifePercentageField.errors?.required">
                Shelf Life Commitment is required.
              </kendo-formerror>
              <kendo-formerror *ngIf="shelfLifePercentageField.errors?.min || shelfLifePercentageField.errors?.max">
                Between 50% and 100%
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group">
            <kendo-label text="Cut Cases"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.acceptsCutCaseFee')">
              Cut Cases is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #acceptsCutCaseFeeYes
                       [value]="true"
                       class="mr-0"
                       formControlName="acceptsCutCaseFee"
                       kendoRadioButton
                       name="acceptsCutCaseFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsCutCaseFeeYes">Accepts Program</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #acceptsCutCaseFeeFeeNo
                       [value]="false"
                       class="mr-0"
                       formControlName="acceptsCutCaseFee"
                       kendoRadioButton
                       name="acceptsCutCaseFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsCutCaseFeeFeeNo">Excluded from Program</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <kendo-label text="New Product Setup Fee"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.acceptsNewItemSetupFee')">
              New Product Setup Fee is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #acceptsNewItemSetupFeeYes
                       [value]="true"
                       class="mr-0"
                       formControlName="acceptsNewItemSetupFee"
                       kendoRadioButton
                       name="acceptsNewItemSetupFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsNewItemSetupFeeYes">Accepts Program</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #acceptsNewItemSetupFeeNo
                       [value]="false"
                       class="mr-0"
                       formControlName="acceptsNewItemSetupFee"
                       kendoRadioButton
                       name="acceptsNewItemSetupFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsNewItemSetupFeeNo">Excluded from Program</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group" *ngIf="this.form.get('terms.acceptsNewItemSetupFee').value">
            <kendo-formfield>
              <kendo-label [for]="newItemSetupFeeFormField" text="New Product Setup Fee"></kendo-label>
              <kendo-numerictextbox #newItemSetupFeeFormField
                                    [format]="'c2'" [min]="0"
                                    formControlName="newItemSetupFee"
                                    readonly="true"
                                    [spinners]="false">
              </kendo-numerictextbox>
              <kendo-formhint>This field cannot be edited.</kendo-formhint>
              <kendo-formerror *ngIf="this.form.get('terms.newItemSetupFee')?.errors?.required">
                New Product Setup Fee is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-label text="Product Imaging Charge"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.acceptsImagingCharge')">
              Product Imaging Charge is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #acceptsImagingChargeYes
                       [value]="true"
                       class="mr-0"
                       formControlName="acceptsImagingCharge"
                       kendoRadioButton
                       name="acceptsImagingCharge"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsImagingChargeYes">Accepts Program</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #acceptsImagingChargeNo
                       [value]="false"
                       class="mr-0"
                       formControlName="acceptsImagingCharge"
                       kendoRadioButton
                       name="acceptsImagingCharge"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsImagingChargeNo">Excluded from Program</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <kendo-label text="New Product Intro Allowance"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2"
                             *ngIf="hasRequiredError('terms.acceptsIntroAllowance')">
              New Product Intro Allowance is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #acceptsIntroAllowanceYes
                       [value]="true"
                       class="mr-0"
                       formControlName="acceptsIntroAllowance"
                       kendoRadioButton
                       name="acceptsIntroAllowance"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsIntroAllowanceYes">Accepts Program</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #acceptsIntroAllowanceNo
                       [value]="false"
                       class="mr-0"
                       formControlName="acceptsIntroAllowance"
                       kendoRadioButton
                       name="acceptsIntroAllowance"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsIntroAllowanceNo">Excluded from Program</kendo-label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="terms-form-container">
      <div class="row">
        <div class="col">
          <h4>Payment Terms and Pricing</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group" formGroupName="paymentTerms">
            <kendo-formfield>
              <kendo-label [for]="paymentTermsFormField" text="Payment Terms"></kendo-label>
              <kendo-combobox
                #paymentTermsFormField
                (focus)="onFocus(paymentTermsFormField)"
                [data]="paymentTermsList"
                [loading]="loadingPaymentTermsList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'codeValue'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                (valueChange)="onPaymentTermsChanged($event)"
                formControlName="code"
                placeholder="e.g. 2% 10 NET 30"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.paymentTerms.code')?.errors?.required">
                Payment Terms is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="paymentMethodFormField" text="Payment Method"></kendo-label>
              <kendo-combobox
                #paymentMethodFormField
                (focus)="onFocus(paymentMethodFormField)"
                [data]="paymentMethodList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'description'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                formControlName="paymentMethodCode"
                placeholder="Select Payment Method"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.paymentMethodCode')?.errors?.required">
                Payment Method is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="dueDateCalculationFormField" text="Due Date Calculation"></kendo-label>
              <kendo-combobox
                #dueDateCalculationFormField
                (focus)="onFocus(dueDateCalculationFormField)"
                [data]="dueDateCalculationList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'description'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                formControlName="dueDateCalculation"
                placeholder="Select Due Date Calculation"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.dueDateCalculation')?.errors?.required">
                Due Date Calculation is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="specialEventTermsSeasonalFormField" text="Special Event Terms (Seasonal)"></kendo-label>
              <kendo-combobox
                #specialEventTermsSeasonalFormField
                (focus)="onFocus(specialEventTermsSeasonalFormField)"
                [data]="specialEventTermsSeasonalList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'description'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                formControlName="specialEventTermsSeasonalCode"
                placeholder="Select Special Event Terms"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.specialEventTermsSeasonalCode')?.errors?.required">
                Special Event Terms is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="specialEventTermsWarehouseFormField"
                           text="Special Event Terms (New Warehouse Opening)"></kendo-label>
              <kendo-combobox
                #specialEventTermsWarehouseFormField
                (focus)="onFocus(specialEventTermsWarehouseFormField)"
                [data]="specialEventTermsSeasonalList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'description'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                formControlName="specialEventTermsNewWarehouseOpeningCode"
                placeholder="Select Special Event Terms"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.specialEventTermsNewWarehouseOpeningCode')?.errors?.required">
                Special Event Terms is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="priceChangeNoticeFormField" text="Price Change Notification"></kendo-label>
              <kendo-combobox
                #priceChangeNoticeFormField
                (focus)="onFocus(priceChangeNoticeFormField)"
                [data]="priceChangeNoticeList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'description'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                formControlName="priceChangeNoticeInDays"
                placeholder="Select Price Change Notification"
              ></kendo-combobox>
              <kendo-formerror *ngIf="priceChangeNoticeInDaysField?.errors?.required">
                Price Change Notification is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
      </div>
    </div>
    <div class="terms-form-container">
      <div class="row">
        <div class="col">
          <h4>Shipping and Receiving</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-label text="Lumping Fees"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasLumpingFee')">
              Lumping Fees is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasLumpingFeeYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasLumpingFee"
                       kendoRadioButton
                       name="hasLumpingFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasLumpingFeeYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasLumpingFeeNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasLumpingFee"
                       kendoRadioButton
                       name="hasLumpingFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasLumpingFeeNo">No</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <kendo-label text="Pallet Charges"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasPalletCharge')">
              Pallet Charges is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasPalletChargeYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasPalletCharge"
                       kendoRadioButton
                       name="hasPalletCharge"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasPalletChargeYes">Accepts Program</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasPalletChargeNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasPalletCharge"
                       kendoRadioButton
                       name="hasPalletCharge"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasPalletChargeNo">Excluded from Program</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <kendo-formfield>
              <kendo-label text="Pallet Charge in $$"></kendo-label>
              <kendo-numerictextbox formControlName="palletChargeAmount" placeholder="e.g. $0.00" [spinners]="false"
                                    [format]="'c2'" [decimals]="2">
              </kendo-numerictextbox>
              <kendo-formhint>Up to $20.00</kendo-formhint>
              <kendo-formerror *ngIf="palletChargeAmount.errors?.required">
                Pallet Charge in $$ is required.
              </kendo-formerror>
              <kendo-formerror *ngIf="palletChargeAmount.errors?.min || palletChargeAmount.errors?.max">
                Up to $20.00
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-label text="Fill Rate Fee"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasFillRateFee')">
              Fill Rate Fee is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasFillRateFeeYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasFillRateFee"
                       kendoRadioButton
                       name="hasFillRateFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasFillRateFeeYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasFillRateFeeNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasFillRateFee"
                       kendoRadioButton
                       name="hasFillRateFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasFillRateFeeNo">No</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <kendo-label text="Order Overage 30%"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasThirtyPercentOrderCoverage')">
              Order Overage 30% is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasThirtyPercentOrderCoverageYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasThirtyPercentOrderCoverage"
                       kendoRadioButton
                       name="hasThirtyPercentOrderCoverage"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasThirtyPercentOrderCoverageYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasThirtyPercentOrderCoverageNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasThirtyPercentOrderCoverage"
                       kendoRadioButton
                       name="hasThirtyPercentOrderCoverage"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasThirtyPercentOrderCoverageNo">No</kendo-label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="terms-form-container">
      <div class="row">
        <div class="col">
          <h4>Marketing, Promotions, & Publications</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6" formGroupName="promotions">
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="freeProductsProgramFormField" text="Placement Allowances (Free Fill)"></kendo-label>
              <kendo-combobox
                #freeProductsProgramFormField
                (focus)="onFocus(freeProductsProgramFormField)"
                [data]="freeProductsProgramList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'description'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                formControlName="freeProductsProgramCode"
                placeholder="Select Placement Allowances"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.promotions.freeProductsProgramCode')?.errors?.required">
                Placement Allowances is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group">
            <kendo-formfield>
              <kendo-label [for]="freeProductHandlingChargeFormField"
                           text="Free Fill Handling Charge"></kendo-label>
              <kendo-combobox
                #freeProductHandlingChargeFormField
                (focus)="onFocus(freeProductHandlingChargeFormField)"
                [data]="freeProductHandlingChargeList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'description'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                formControlName="freeProductHandlingChargeCode"
                placeholder="Select Free Fill Handling Charge"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.promotions.freeProductHandlingChargeCode')?.errors?.required">
                Free Fill Handling Charge is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group" formGroupName="marketingAllowance">
            <kendo-formfield>
              <kendo-label [for]="marketingAllowanceFormField" text="KeHE CONNECT BI Program"></kendo-label>
              <kendo-combobox
                #marketingAllowanceFormField
                (focus)="onFocus(marketingAllowanceFormField)"
                [data]="marketingAllowanceList"
                [loading]="loadingMarketingAllowanceList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'codeValue'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                (valueChange)="onMarketingAllowanceChanged($event)"
                formControlName="code"
                placeholder="Select KeHE CONNECT BI Program"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.promotions.marketingAllowance.code')?.errors?.required">
                KeHE CONNECT BI Program is required.
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group">
            <kendo-label text="Extra Performance"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2"
                             *ngIf="hasRequiredError('terms.promotions.hasExtraPerformance')">
              Extra Performance is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasExtraPerformanceYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasExtraPerformance"
                       kendoRadioButton
                       name="hasExtraPerformance"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasExtraPerformanceYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasExtraPerformanceNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasExtraPerformance"
                       kendoRadioButton
                       name="hasExtraPerformance"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasExtraPerformanceNo">No</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <div class="m-0 d-flex align-items-center">
              <kendo-label text="Publication Listing Fees"></kendo-label>
              <em class="fas fa-info-circle info-tooltip" [keheTooltip]="tooltipPublicationListingFee"
                  keheTooltipPlacement="right"></em>
            </div>

            <kendo-formerror class="k-radio-hint mt-0 mb-2"
                             *ngIf="hasRequiredError('terms.promotions.hasPublicationListingFee')">
              Publication Listing Fees is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasPublicationListingFeeYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasPublicationListingFee"
                       kendoRadioButton
                       name="hasPublicationListingFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasPublicationListingFeeYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasPublicationListingFeeNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasPublicationListingFee"
                       kendoRadioButton
                       name="hasPublicationListingFee"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasPublicationListingFeeNo">No</kendo-label>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group" *ngIf="form.get('terms.hasAdministrativeAllowanceProgram').enabled">
            <kendo-label text="AAP Program">
            </kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasAdministrativeAllowanceProgram')">
              AAP Program field is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-0">
              <li class="k-radio-item">
                <input #aapProgramYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasAdministrativeAllowanceProgram"
                       kendoRadioButton
                       name="aapProgram"
                       type="radio">
                <kendo-label class='ml-2' [for]="aapProgramYes">Yes</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #aapProgramNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasAdministrativeAllowanceProgram"
                       kendoRadioButton
                       name="aapProgram"
                       type="radio">
                <kendo-label class='ml-2' [for]="aapProgramNo">No</kendo-label>
              </li>
            </ul>
          </div>

          @if (form.get('terms.hasAdministrativeAllowanceProgram').value) {
            <div class="mb-3">
              <kendo-formfield>
                <kendo-label [for]="aapPercentageField" text="AAP Allowance"></kendo-label>
                <kendo-numerictextbox
                  #aapPercentageField
                  formControlName="administrativeAllowanceProgramPercentage"
                  [disabled]="true"
                  [spinners]="false"
                  [format]="{ minimumFractionDigits: 1, style: 'percent' }">
                </kendo-numerictextbox>
                <kendo-formhint>{{fieldMsg}}</kendo-formhint>
              </kendo-formfield>
            </div>
          }

          <div formGroupName="promotions">
            <div class="form-group">
              <kendo-label text="MCB Processing Fee"></kendo-label>
              <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.promotions.acceptsManufacturerChargeBackFee')">
                MCB Processing Fee is required.
              </kendo-formerror>
              <ul class="k-radio-list pl-0 mt-1">
                <li class="k-radio-item">
                  <input #acceptsManufacturerChargeBackFeeYes
                         [value]="true"
                         class="mr-0"
                         formControlName="acceptsManufacturerChargeBackFee"
                         kendoRadioButton
                         name="acceptsManufacturerChargeBackFee"
                         type="radio">
                  <kendo-label class='ml-2' [for]="acceptsManufacturerChargeBackFeeYes">Accepts Program</kendo-label>
                </li>
                <li class="k-radio-item">
                  <input #acceptsManufacturerChargeBackFeeNo
                         [value]="false"
                         class="mr-0"
                         formControlName="acceptsManufacturerChargeBackFee"
                         kendoRadioButton
                         name="acceptsManufacturerChargeBackFee"
                         type="radio">
                  <kendo-label class='ml-2' [for]="acceptsManufacturerChargeBackFeeNo">Excluded from Program</kendo-label>
                </li>
              </ul>
            </div>
            <div class="mb-3" *ngIf="this.form.get('terms.promotions.acceptsManufacturerChargeBackFee').value">
              <kendo-formfield>
                <div class="m-0 d-flex align-items-center">
                  <kendo-label [for]="mcbExceptionCodeField" text="MCB Exception Code"></kendo-label>
                  <em class="fas fa-info-circle info-tooltip" [keheTooltip]="tooltipMCBExceptionCode"></em>
                </div>
                <kendo-combobox
                  #mcbExceptionCodeField
                  (focus)="onFocus(mcbExceptionCodeField)"
                  [data]="mcbExceptionCodes"
                  [filterable]="true"
                  [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                  [textField]="'name'"
                  [valueField]="'value'"
                  [valuePrimitive]="true"
                  formControlName="manufacturerChargeBackExceptionCode"
                  placeholder="Select MCB Exception Code"
                  [disabled]="excludeMCBFee()"
                ></kendo-combobox>
                <kendo-formerror *ngIf="manufacturerChargeBackExceptionCodeField?.errors?.required">
                  MCB Exception Code is required.
                </kendo-formerror>
              </kendo-formfield>
            </div>
            <div class="mb-3" *ngIf="canShowMCBFeeDollars">
              <kendo-formfield>
                <kendo-label [for]="mcbFeeDollarField" text="MCB Fee Dollar"></kendo-label>
                <kendo-numerictextbox
                  [format]="'c2'"
                  #mcbFeeDollarField
                  formControlName="manufacturerChargeBackFeeAmount"
                  [readonly]="true"
                  [spinners]="false">
                </kendo-numerictextbox>
                <kendo-formhint>{{fieldMsg}}</kendo-formhint>
              </kendo-formfield>
            </div>
            <div class="mb-3" *ngIf="canShowMCBFeePercentage">
              <kendo-formfield>
                <kendo-label [for]="mcbFeePercentageField" text="MCB Fee Percentage"></kendo-label>
                <kendo-numerictextbox
                  [format]="'p'"
                  [min]="0"
                  [max]="100"
                  #mcbFeePercentageField
                  formControlName="manufacturerChargeBackFeePercentage"
                  [readonly]="true"
                  [spinners]="false"
                >
                </kendo-numerictextbox>
                <kendo-formhint>{{fieldMsg}}</kendo-formhint>
              </kendo-formfield>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="terms-form-container">
      <div class="row">
        <div class="col">
          <h4>Retail Marketplace Expenses</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <kendo-label text="Retailer Reclamation"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasRetailerReclamation')">
              Retailer Reclamation is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasRetailerReclamationYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasRetailerReclamation"
                       kendoRadioButton
                       name="hasRetailerReclamation"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasRetailerReclamationYes">Accepts Program</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasRetailerReclamationNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasRetailerReclamation"
                       kendoRadioButton
                       name="hasRetailerReclamation"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasRetailerReclamationNo">Excluded from Program</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <kendo-label text="Retailer Discontinued"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.hasRetailerDiscontinuedProgram')">
              Retailer Discontinued is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasRetailerDiscontinuedProgramYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasRetailerDiscontinuedProgram"
                       kendoRadioButton
                       name="hasRetailerDiscontinuedProgram"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasRetailerDiscontinuedProgramYes">Accepts Program</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasRetailerDiscontinuedProgramNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasRetailerDiscontinuedProgram"
                       kendoRadioButton
                       name="hasRetailerDiscontinuedProgram"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasRetailerDiscontinuedProgramNo">Excluded from Program</kendo-label>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <kendo-label text="KeHE Discontinued"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2"
                             *ngIf="hasRequiredError('terms.hasKeheDiscount')">
              KeHE Discontinued is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #hasKeheDiscountYes
                       [value]="true"
                       class="mr-0"
                       formControlName="hasKeheDiscount"
                       kendoRadioButton
                       name="hasKeheDiscount"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasKeheDiscountYes">Accepts Program</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #hasKeheDiscountNo
                       [value]="false"
                       class="mr-0"
                       formControlName="hasKeheDiscount"
                       kendoRadioButton
                       name="hasKeheDiscount"
                       type="radio">
                <kendo-label class='ml-2' [for]="hasKeheDiscountNo">Excluded from Program</kendo-label>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group" formGroupName="spoils">
            <kendo-formfield>
              <kendo-label [for]="spoilsFormField" text="Spoils"></kendo-label>
              <kendo-combobox
                #spoilsFormField
                (focus)="onFocus(spoilsFormField)"
                [data]="spoilsList"
                [filterable]="true"
                [kendoDropDownFilter]="{operator: 'contains'}"
                [textField]="'label'"
                [valueField]="'value'"
                [valuePrimitive]="true"
                formControlName="spoilsType"
                placeholder="Select Spoils"
              ></kendo-combobox>
              <kendo-formerror *ngIf="this.form.get('terms.spoils.spoilsType')?.errors?.required">
                Spoils is required.
              </kendo-formerror>
            </kendo-formfield>

          </div>
          <div class="form-group" formGroupName="spoils"
               *ngIf="this.form.get('terms.spoils.spoilsType').value && !getSpoilsAllowReadOnlyStatus()">
            <kendo-formfield>
              <kendo-label [for]="allowancePercentageField" text="Spoilage Allowance Percent"></kendo-label>
              <kendo-numerictextbox #allowancePercentageField
                                    format="#.00\%" [min]="0" [max]="100" [decimals]="2"
                                    formControlName="allowancePercentage"
                                    [readonly]="getSpoilsAllowReadOnlyStatus()"
                                    [spinners]="false"
                                    placeholder="e.g. 2.00%">
              </kendo-numerictextbox>
              <kendo-formhint>Between 0.01% and 100.00%</kendo-formhint>
              <kendo-formerror *ngIf="this.form.get('terms.spoils.allowancePercentage')?.errors?.required">
                Spoilage Allowance Percent is required.
              </kendo-formerror>
              <kendo-formerror
                *ngIf="this.form.get('terms.spoils.allowancePercentage')?.errors?.min || this.form.get('terms.spoils.allowancePercentage')?.errors?.max">
                Between 0.01% and 100.00%
              </kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-group">
            <kendo-label text="Warehouse Spoils"></kendo-label>
            <kendo-formerror class="k-radio-hint mt-0 mb-2" *ngIf="hasRequiredError('terms.acceptsWarehouseOutOfDateCharge')">
              Warehouse Spoils is required.
            </kendo-formerror>
            <ul class="k-radio-list pl-0 mt-1">
              <li class="k-radio-item">
                <input #acceptsWarehouseOutOfDateChargeYes
                       [value]="true"
                       class="mr-0"
                       formControlName="acceptsWarehouseOutOfDateCharge"
                       kendoRadioButton
                       name="acceptsWarehouseOutOfDateCharge"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsWarehouseOutOfDateChargeYes">Accepts Program</kendo-label>
              </li>
              <li class="k-radio-item">
                <input #acceptsWarehouseOutOfDateChargeNo
                       [value]="false"
                       class="mr-0"
                       formControlName="acceptsWarehouseOutOfDateCharge"
                       kendoRadioButton
                       name="acceptsWarehouseOutOfDateCharge"
                       type="radio">
                <kendo-label class='ml-2' [for]="acceptsWarehouseOutOfDateChargeNo">Excluded from Program</kendo-label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


