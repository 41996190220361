import { Component, Input, OnInit } from '@angular/core';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { DCProductGroup } from '../../models/dc-product-group';
import { Store } from '@ngrx/store';
import { Destroyable } from '../../../../abstract/destroyable';
import {
  addDCProductGroup,
  addSupplierContact,
  editProductGroupDC,
  hideAlertMessage,
} from '../../store/supplier-detail.actions';
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as DistributionCenterSelectors from '@app/shared/state//distribution-center/distribution-center.selectors';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { PermissionsService } from '@app/services/permissions.service'; 
import { Constants } from '../../../../constants/constants';
import {
  isDCProductGroupTransitTimeDefault,
  isDCProductGroupTransitTimeOverridden,
} from '../../utilities/product-group-utils';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { ActivatedRoute } from '@angular/router';
import {
  getAllProductGroups,
  selectApplyBulkEditFailed,
  selectApplyBulkEditSuccess,
} from '../../../product-groups/store/product-groups.selectors';
import { SupplierDetail } from '../../models/supplier-detail';
import {
  isDraft,
  isInvitedDraftSupplier,
} from '../../utilities/supplier-utils';
import { UntypedFormBuilder } from '@angular/forms';
import {
  applyDCPGBulkEdit,
  initDCPGBulkEdit,
} from '../../../product-groups/store/product-groups.actions';
import {
  selectPickupAddressColumnTitle,
  selectSupplierDetail,
} from '../../store/supplier-detail.selectors';
import { dcpgDropdownListFromDCProductGroup } from '../../../../utils/dcpg-utils';
import { ProductGroup } from '../../models/product-group';
import { getInboundRouteTypeName } from '../../../../utils/enum-utils';
import { FeatureFlags } from '@app/feature-flag';
import { Contact, ContactType } from '../../../../models/contact';

@Component({
  selector: 'app-product-groups-dc-tab',
  templateUrl: './product-groups-dc-tab.component.html',
  styleUrls: ['./product-groups-dc-tab.component.scss'],
})
export class ProductGroupsDcTabComponent extends Destroyable implements OnInit {
  bulkEditLoading = false;
  bulkEditError: string = null;

  deliveredAddressFlag$ = this._store.select(
    selectIsFeatureFlagOn(FeatureFlags.ProductGroupDeliveryAddress.key)
  );

  isDraftSupplier$ = this._store
    .select(selectSupplierDetail)
    .pipe(map((supplier) => isDraft(supplier)));

  pickupAddressColumnTitle$ = this._store.select(
    selectPickupAddressColumnTitle
  );

  productGroup$ = this._store.select(getAllProductGroups).pipe(
    map((pgs) => {
      return pgs.find(
        (pg) =>
          this._route.snapshot.paramMap.get('productGroupNumber') ===
          pg.productGroupNumber
      );
    })
  );

  _dcProductGroups: Array<DCProductGroup>;
  get dcProductGroups() {
    return this._dcProductGroups;
  }

  @Input()
  set dcProductGroups(list: Array<DCProductGroup>) {
    this._dcProductGroups = orderBy(list, this.sort);

    // we then map out a form group
    const formData = this._dcProductGroups.map((c) =>
      this._builder.group({
        value: [false],
        dcpg: [c],
      })
    );
    this.bulkEditForm.clear();
    formData.forEach((g) => this.bulkEditForm.push(g), { emitEvent: false });
    // rebuild menu options
    this._dcProductGroups.forEach((dcpg) => {
      this.dcpgOptions[dcpg.distributionCenterNumber] =
        this.getDCPGMenuOptions(dcpg);
    });
  }

  set editAll(value: boolean) {
    this.bulkEditForm.patchValue(
      this.dcProductGroups.map((dcpg) => ({
        value: value && !this.isMissingRequiredFields(dcpg),
        dcpg,
      }))
    );
  }

  get editAll() {
    return !(
      this.selectedDCs.length <
      this.dcProductGroups.filter((dcpg) => !this.isMissingRequiredFields(dcpg))
        .length
    );
  }

  @Input()
  supplier: SupplierDetail;

  poTransModalViewData: DCProductGroup;
  showPOTransModal = false;
  showPickUpAddressNotesModal = false;
  showCloneDCPGModal = false;
  showBulkEditDCPGModal = false;
  pickupAddressNotes: any;
  pickupAddressNotesTitle: string;
  hasUnAssignedDCs = false;
  userCanAddDcProductGroup = false;
  userCanEditDcProductGroup = false;

  bulkEditForm = this._builder.array([]);
  sort: SortDescriptor[] = [
    {
      field: 'distributionCenterNumber',
      dir: 'asc',
    },
  ];
  dcpgOptions: { [dcpg: string]: OptionMenuItem[] } = {};
  bulkEditMenuOptions = [
    {
      name: 'Edit',
      enabled: true,
      selected: false,
    },
  ];
  availableDCList = [];
  eligibleDCPGs = [];
  productGroup: ProductGroup;
  stagedDCPGClone: DCProductGroup;
  showDCPGProductsModal: DCProductGroup = null;

  // this is used instead of the get to avoid the change detection triggers
  selectedDCs$ = this.bulkEditForm.valueChanges.pipe(
    map((value) =>
      (value as Array<{ value: boolean; dcpg: DCProductGroup }>)
        .filter((v) => !!v.value)
        .map((v) => v.dcpg)
    )
  );

  get canShowProductsData() {
    return !isDraft(this.supplier);
  }

  get selectedDCs() {
    return dcpgDropdownListFromDCProductGroup(
      (
        this.bulkEditForm.getRawValue() as Array<{
          value: boolean;
          dcpg: DCProductGroup;
        }>
      )
        .filter((v) => !!v.value)
        .map((v) => v.dcpg)
    );
  }

  get bulkEditEnabled() {
    return this.selectedDCs.length > 0;
  }

  get showContactCard() {
    return isInvitedDraftSupplier(this.supplier);
  }

  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _permissionsService: PermissionsService,
    private _builder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.setUserPermissions();
    this.dcProductGroups = orderBy(this.dcProductGroups, this.sort);
    this._store
      .select(DistributionCenterSelectors.selectDistributionCenterList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.checkAvailableDCs(response);
      });

    const dcList$ = this._store.select(DistributionCenterSelectors.selectDistributionCenterList).pipe(
      take(1),
      map((list) =>
        list
          .map((item) => ({
            label: (item as any).name.replace('Kehe Dist - ', ''),
            value: (item as any).number,
            active: item.isActive,
          }))
          .filter((item) => !Constants.ExcludedDCs.includes(+item.value))
      )
    );
    this.productGroup$
      .pipe(withLatestFrom(dcList$), takeUntil(this.destroy$))
      .subscribe(([pg, dcs]) => {
        this.productGroup = pg;
        const dcpgNumbers =
          pg.dcProductGroups?.map((dcpg) => dcpg.distributionCenterNumber) ??
          [];
        this.availableDCList = dcs.filter((dc) => {
          const alreadyExists = dcpgNumbers.includes(dc.value);
          return (
            !alreadyExists ||
            (alreadyExists &&
              this.isMissingRequiredFields(
                pg.dcProductGroups.find(
                  (dcpg) => dcpg.distributionCenterNumber === dc.value
                )
              ))
          );
        });

        const allDCPGs =
          pg.dcProductGroups?.filter(
            (dcpg) => !this.isMissingRequiredFields(dcpg)
          ) ?? [];
        this.eligibleDCPGs = allDCPGs.map((item) => {
          return {
            ...item,
            label: item.dcDisplayName,
            value: item.distributionCenterNumber,
          };
        });
      });

    // generate the menu options for the grid view
    this.dcProductGroups.forEach((dcpg) => {
      this.dcpgOptions[dcpg.distributionCenterNumber] =
        this.getDCPGMenuOptions(dcpg);
    });

    this._store
      .select(selectApplyBulkEditSuccess)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.editAll = false;
          this.bulkEditLoading = false;
          this.onCloseBulkEditModal();
        }
      });

    this._store
      .select(selectApplyBulkEditFailed)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.bulkEditError = value;
        this.bulkEditLoading = false;
      });
  }

  getTotalLeadTime(dataItem: DCProductGroup) {
    if (dataItem.leadTime) {
      return dataItem.leadTime;
    }
    return '--';
  }

  private setUserPermissions() {
    this.userCanAddDcProductGroup = this._permissionsService.userHasAction(
      Constants.UserActions.SupplierDcProductGroupCreate
    );

    this.userCanEditDcProductGroup =
      this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupDeliveryMethodUpdate
      ) ||
      this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupBuyerUpdate
      ) ||
      this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupLeadTimeUpdate
      ) ||
      this._permissionsService.userHasAction(
        Constants.UserActions.SupplierDcProductGroupPoTransmissionUpdate
      );
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.dcProductGroups = orderBy(this.dcProductGroups, this.sort);
    this.bulkEditForm.patchValue(
      this.dcProductGroups.map((dcpg) => ({
        dcpg,
        value:
          this.bulkEditForm
            .getRawValue()
            .find(
              (value) =>
                value.dcpg.distributionCenterNumber ===
                dcpg.distributionCenterNumber
            )?.value ?? false,
      }))
    );
  }

  isDelivered(dataItem: DCProductGroup) {
    return dataItem.deliveryMethod && dataItem.deliveryMethod === 'Delivered';
  }

  onDcProductGroupEdit(dataItem: DCProductGroup) {
    this._store.dispatch(editProductGroupDC({ dcProductGroup: dataItem }));
  }

  addButtonClicked() {
    this._store.dispatch(hideAlertMessage());
    if (this.hasUnAssignedDCs) {
      this._store.dispatch(addDCProductGroup());
    }
  }

  private checkAvailableDCs(dcList: any[]) {
    const dcNumbers = this.dcProductGroups.map(
      (item) => item.distributionCenterNumber
    );
    this.hasUnAssignedDCs =
      dcList.filter((item) => !dcNumbers.includes(item.Number)).length > 0;
  }

  onClickPOTrans(dataItem: DCProductGroup) {
    this.poTransModalViewData = dataItem;
    this.showPOTransModal = true;
  }

  onClosePOTransModal() {
    this.poTransModalViewData = null;
    this.showPOTransModal = false;
  }

  isMissingRequiredFields(dataItem: DCProductGroup): boolean {
    return !dataItem.buyerCode || !dataItem.deliveryMethod;
  }

  showDCProductGroupWarning(dataItem: DCProductGroup) {
    return (
      this.isMissingRequiredFields(dataItem) ||
      isDCProductGroupTransitTimeDefault(dataItem) ||
      isDCProductGroupTransitTimeOverridden(dataItem)
    );
  }

  dcProductGroupWarningMessage(dataItem: DCProductGroup) {
    if (this.isMissingRequiredFields(dataItem)) {
      return 'There are missing fields in this DC Product Group.';
    }
    if (isDCProductGroupTransitTimeOverridden(dataItem)) {
      return 'The KeHE Transit Time has been overridden.';
    }
    if (isDCProductGroupTransitTimeDefault(dataItem)) {
      return 'The KeHE Transit Time is missing in the transit table and a default value has been applied.';
    }
    return '';
  }

  onClickPickupAddressNotes(dataItem: DCProductGroup) {
    this.pickupAddressNotes = dataItem.pickupAddressNotes;
    this.pickupAddressNotesTitle = this.isDelivered(dataItem)
        ? 'Delivered Address Notes'
        : 'Pickup Address Notes';
    this.showPickUpAddressNotesModal = true;
  }

  onClosePickUpAddressNotesModal() {
    this.pickupAddressNotes = null;
    this.showPickUpAddressNotesModal = false;
    this.pickupAddressNotesTitle = undefined;
  }

  onCloneDCPG(dcpg: DCProductGroup) {
    this.stagedDCPGClone = dcpg;
    this.showCloneDCPGModal = true;
  }

  onCloseCloneDCPGModal(event: { type: string }) {
    if (event?.type === 'outside-click') {
      return;
    }
    this.showCloneDCPGModal = false;
    this.stagedDCPGClone = null;
  }

  onCloseBulkEditModal(event?: { type: string }) {
    if (event?.type === 'outside-click') {
      return;
    }
    this.showBulkEditDCPGModal = false;
  }

  bulkEditApplyClicked(payload: any) {
    this.bulkEditLoading = true;
    const editedProductGroup = {
      productGroupNumber: this.productGroup.productGroupNumber,
      dcProductGroups: payload.editedDCProductGroups,
    };
    this._store.dispatch(
      applyDCPGBulkEdit({
        productGroup: editedProductGroup,
        auditNotes: payload.auditNotes,
      })
    );
  }

  bulkEditApplyClickedAddContact(event: { contact: Contact, contactType: ContactType, bulkEditPayload: any }) {
    this.bulkEditLoading = true;
    // since delivered address is optional while updating delivered delivery method
    // check if the user entered any address, if yes add the address and do the PG update
    // otherwise just do the PG update
    if (event?.contact && event?.contact.address && event?.contact.address?.country) {
      this.addSupplierContact(event?.contact, event?.contactType, event?.bulkEditPayload);
    } else {
      this._store.dispatch(applyDCPGBulkEdit({productGroup: event?.bulkEditPayload.productGroup, auditNotes: event?.bulkEditPayload.auditNotes}));
    }
  }

  addSupplierContact(contact: Contact, contactType: ContactType, bulkEditPayload: any) {
    this._store.dispatch(addSupplierContact({ contact, contactType, bulkEditPayload }));
  }

  optionSelected(item: OptionMenuItem) {
    switch (item.name) {
      case 'Edit':
        this.onDcProductGroupEdit(item.data);
        break;
      case 'Clone':
        this.onCloneDCPG(item.data);
        break;
      case 'Delete':
        break;
    }
  }

  bulkOptionSelected() {
    this._store.dispatch(initDCPGBulkEdit());
    this.showBulkEditDCPGModal = true;
  }

  getDCPGMenuOptions(dcpg: DCProductGroup) {
    const items = [
      {
        data: dcpg,
        name: 'Edit',
        enabled: this.userCanEditDcProductGroup,
        selected: false,
      },
    ] as OptionMenuItem[];

    if (!isDraft(this.supplier)) {
      items.push({
        data: dcpg,
        name: 'Clone',
        enabled:
          this.availableDCList.length > 0 &&
          !this.isMissingRequiredFields(dcpg) &&
          this.userCanAddDcProductGroup,
        selected: false,
      });
    }

    return items;
  }

  onClickDCPGUPCCount(dcpg: DCProductGroup) {
    this.showDCPGProductsModal = dcpg;
  }

  closePGProductsModal() {
    this.showDCPGProductsModal = null;
  }

  inboundRouteTypeName(inboundRouteType) {
    return getInboundRouteTypeName(inboundRouteType);
  }
}
