import {
  TreatmentWithConfig,
  TreatmentsWithConfig,
} from "@splitsoftware/splitio/types/splitio";

export class FeatureFlag {
  constructor(
    public key: string,
    public isOn: boolean,
    public config?: string
  ) {}
}

export const FeatureFlags = {
  BulkShipmentDeleteRefactor: new FeatureFlag('CE_PRO-406_Bulk_shipment_delete_refactor', false),
  PromotionalOrdersViaUpload: new FeatureFlag('CE_PRO-367_Create_draft_promotional_orders_via_upload', false),
  ValidateTemporaryUnauthorizedItems: new FeatureFlag('CE_PRM_43_Validate_temporary_unauthorized_items', false),
  NewShipmentsSearchOption: new FeatureFlag('CE_PRM_57_Modify_Search_And_Filter_Options_For_Manage_Shipments_Page', false),
  ViewingSnapshotsCreditsVersionHistory: new FeatureFlag('CE_PRM_55_Viewing_Version_History_For_Credits', false),
  SavingSnapshotsCreditsVersionHistory: new FeatureFlag('CE_PRM-56_Save_Snapshot_For_Version_History_Of_Credits', false),
  EnterPriseProductEditScheduleNonDateFields: new FeatureFlag(
    'Enterprise_Product_Edit_ScheduleNonDateFields',
    false
  ),
  CreateOrderMultiModalPendingTab: new FeatureFlag(
    'Create_Order_Multi_Modal_Pending_Tab',
    false
  ),
  EnterpriseDraftProductApplyAllEnhancement : new FeatureFlag(
    'Enterprise_New_Product_Apply_All_Enhancement',
    false
  ),
  EnterpriseSODCustomerManagement: new FeatureFlag(
    'CE_SOD_Customer_Management',
    true
  ),
  ProductComparableUpcRefreshPricing: new FeatureFlag(
    'Product_comparable_upc_refresh_pricing',
    false
  ),
  CEManageCarousels: new FeatureFlag('CE_Manage_Carousels', false),
  CEPublicationCreation: new FeatureFlag('CE_Publication_Creation', false),
  EnterpriseDraftProductCreatePriceGroup: new FeatureFlag(
    'Product_automate_linepricing_pricegroup',
    false
  ),
  WorkQueueFlag: new FeatureFlag('WQ_FF_ROUTE_GUARD', false),
  PromotionsEligibilityPage: new FeatureFlag('CE_CR-11498_Promotions_Eligibility_Page',false),
  ProductSupplierGtinValidation: new FeatureFlag('Product_Supplier_Inner_Pack_Gtin_Validator', false),
  BrokerV2: new FeatureFlag('SBM_FE_MENU_ITEM', false),
  ShipmentsBulkExport: new FeatureFlag('CE_PRO_120_Bulk_Export_Shipments',false),
  CloneShipmentToMultipleCustomers: new FeatureFlag('CE_SOM-4387_Clone_Shipment_To_Multiple_Customers',false),
  DeleteItemsFromMultipleShipments: new FeatureFlag('CE_PRO_12_Delete_items_from_multiple_shipments',false),
  ShipmentsListPagination250: new FeatureFlag('CE_PRO_100_118_Extend_Pagination_To_250',false),
  EnterpriseNewProductMiscTraits: new FeatureFlag('Enterprise_New_Product_Misc_Traits',false),
  ProductGroupDeliveryAddress: new FeatureFlag('Pricing_Product_Group_Delivered',false),
  EnterpriseProductLinkedDrafts: new FeatureFlag('Enterprise_Product_Linked_Drafts',false),
  OrdersBulkExport: new FeatureFlag('CE_PRO_177_41_Bulk_Export_Orders', false),
  ShipmentsBulkItemQuantityEdit: new FeatureFlag('CE_PRO_2_Update_Quantity_Of_Items_from_multiple_shipments', false),
  ManageCustomPromotions: new FeatureFlag('CE_PC305_Manage_Custom_Promotions', false),
  EnterpriseProductCatchWeightType: new FeatureFlag('Enterprise_Product_Catch_Weight_Type', false),
  EnterpriseNewProductCatchWeightType: new FeatureFlag('Enterprise_New_Product_CatchWeight_type', false),
  Export_Invoices: new FeatureFlag('CE_PCK-239_Export_Invoices', false),
  EnterpriseProductCatchWeightError: new FeatureFlag(
    'Enterprise_Product_Catch_Weight_Error',
    false
  ),
  ProhibitRestrict: new FeatureFlag('Prohibit_Restrict', false),
  TransporationTabUpdates: new FeatureFlag('CE_CR-8245_Transporation_Tab_Updates', false),
  CustomerManagementIsElectronicInvoice: new FeatureFlag('CE_QK-4132_Customer_Management_Is_Electronic_Invoice', false),
  BulkDeactivateCustomers: new FeatureFlag('CR_SK-860_Bulk_Customer_Inactivate', false),
  ProhibitRestrictRelease1: new FeatureFlag('Prohibit_Restrict_Release_1', true),
  ProhibitRestrictRelease2: new FeatureFlag('Prohibit_Restrict_Release_2', false),
  AllRetailerShows: new FeatureFlag('CE_SK-960_All_Retailer_Shows', false),
  LiveShowProducts: new FeatureFlag('CE_SK-986_Live_Show_Products', false),
  RefreshProductAssets: new FeatureFlag('Request_Item_Images_from_Rivr', false),
  Chatbot: new FeatureFlag('CONNECT_Enterprise_Chatbot', false),
  ProhibitRestrictRelease3: new FeatureFlag('Prohibit_Restrict_Release_3', false),
  TransportationTabUpdates: new FeatureFlag('CE_CR-8245_Transporation_Tab_Updates', false),
  ManageProductCodes: new FeatureFlag('CodeTables_CE_DS-155_ManageProductCodes', false),
  ProductFoodTraceability: new FeatureFlag('Food_Traceability', false),
  ProductEditFoodTraceability: new FeatureFlag('Edit_Food_Traceability', false),
  PriceGroupMenu: new FeatureFlag('PRC_PRICE_GROUP_MENU', true),
  ManageSupplierCodes: new FeatureFlag('CE_DS-451_Manage_Supplier_Codes', false),


  /***************************
   * Permanent Feature Flags *
   ***************************/
  SupplierVendorPackets: new FeatureFlag('CSP-Supplier_Onboarding-Vendor-Packet', false),
  EnterpriseOutageBanner: new FeatureFlag('Enterprise_Top_Level_Banner', false),
  /***************************
   * * * * * * end * * * * * *
   ***************************/
};

export function mapFeatureFlagsToSplitTreatmentsWithConfig(): TreatmentsWithConfig {
  let treatmentsWithConfig: TreatmentsWithConfig = {};
  const featureFlags: FeatureFlag[] = Object.values(FeatureFlags);
  featureFlags.forEach((featureFlag) => {
    const treatmentWithConfig: TreatmentWithConfig = {
      treatment: featureFlag.isOn ? "on" : "off",
      config: featureFlag.config,
    };
    treatmentsWithConfig[featureFlag.key] = treatmentWithConfig;
  });
  return treatmentsWithConfig;
}

export const FeatureFlagKeys = Object.values(FeatureFlags).map((f) => f.key);
