import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  GuardResult,
  MaybeAsync,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { UserListLoadActions } from '../store/user-list.actions';

@Injectable({
  providedIn: 'root',
})
export class UsersFilterClearGuard {

  constructor(
    private _store: Store
  ) {
  }
  
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): MaybeAsync<GuardResult> {
    if (!nextState.url.startsWith('/users')) {
      this._store.dispatch(UserListLoadActions.clearFilters());
    }
    return true;
  }
}
