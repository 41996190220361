<div class="report-page-container">
    <div class="row title">
        <div class="col-sm-6">
            <h2>Reporting</h2>
        </div>
    </div>


    <div class="row mb-2">
        <div class="col d-flex flex-row-reverse">
            <div class="w-50">
                <kendo-dropdownlist [defaultItem]="defaultItem" [data]="reportOptions" (valueChange)="reportSelectionChanged($event)">
                </kendo-dropdownlist>

            </div>
        </div>
    </div>


    <div class="row report-viewer-row" *ngIf="reportsVisible">
        <div class="col-sm-12">
            <div class="report">
                <div class="iframe-container">
                    <iframe title="embedded-report" [src]="reportUrl">
                    </iframe>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!reportsVisible">
        <div class="col-sm-12">
            <div class="report">
                <div class="instructions-container">
                    <div class="fas fa-seedling seedling"></div>
                    <h3>No Report Selected</h3>
                    <ol class="steps-list">
                        <li>Select your report in the upper right</li>
                        <li>Start getting reports</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>