import { Contact } from '../../../models/contact';

export function formatRemitAddress(address: Contact) {
  const data: string[] = [];
  data.push(address.address?.street1 ?? '');

  if (address.address?.city) {
    data.push(address.address?.city + ',');
  }

  if (address.address?.state?.code) {
    data.push(address.address.state.code + ',');
  }
  data.push(address.address?.zipCode ?? '');

  return data.join(' ');
}
