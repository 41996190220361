import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductPageComponent } from '../pages/product-page/product-page.component';
import { productListFilterClearAll } from '../store/product-list.actions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ClearProductGuard  {
  constructor(private _store: Store) {}

  canDeactivate(
    component: ProductPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!nextState.url.startsWith('/products')) {
      this._store.dispatch(productListFilterClearAll());
    }
    return true;
  }
}
