@if (vm$ | async; as vm) {
  <div class="kehe-grid no-scroll p-0 w-100 mt-2">
    <kendo-grid [data]="vm.paymentHolds" [pageable]="false">
      <kendo-grid-column
        title="Distribution Center"
        field="dcName"
        width="437"
      >
      </kendo-grid-column>

      <kendo-grid-column
        title="Last edited"
        field="lastEditedBy"
        width="120"
      >
        <ng-template kendoGridCellTemplate let-paymentHold>{{ paymentHold.lastEditedBy ?? '--' }}</ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="Release Date"
        width="112"
      >
        <ng-template kendoGridCellTemplate let-paymentHold>{{ (paymentHold.paymentHoldReleaseDate | date:'MM/dd/yyyy') ?? '--' }}</ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Payment Hold" width="112">
        <ng-template kendoGridCellTemplate let-paymentHold>{{ paymentHold.hasPaymentHold | displayYesNo }}</ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
}
