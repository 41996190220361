<kehe-phoenix-slide-panel [overlay]="true" [width]="500" position="right" [visibility]="displayFilterPanel$ | async"
                 (slidePanelEvent)="closePanel(filter)"
                 *ngIf="filter$ | async as filter">
  <div class="header">
    <h3>Filter</h3>
    <kehe-phoenix-button classAttr="btn-link" (click)="clearAll()">
      Clear All
    </kehe-phoenix-button>

  </div>
  <div class="options" [formGroup]="form">
    <div class="filter-item">
      <label class="summary-info-label" for="filterEvents">Events</label>
      <kendo-dropdownlist
        [popupSettings]="{ popupClass: 'kehe-dropdown-popup' }"
        [data]="filterEvents"
        textField="code"
        valueField="value"
        name="filterEvents"
        formControlName="selectedEvent">
      </kendo-dropdownlist>
    </div>
    <div class="filter-item">
      <label class="summary-info-label" for="filterFields">Fields</label>
      <kendo-combobox
        #fields
        textField="code"
        valueField="index"
        name="filterFields"
        placeholder="Search Fields"
        formControlName="selectedField"
        [popupSettings]="{ popupClass: 'kehe-dropdown-popup' }"
        [data]="filterFields$ | async"
        [filterable]="true"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [valuePrimitive]="false" 
        (filterChange)="onFilterChange($event)"
        (focus)="onFocus(fields)">
      </kendo-combobox>
      <kendo-formhint *ngIf="form.controls.selectedField.disabled">To enable Fields unselect Documents.</kendo-formhint>
    </div>
    <div class="filter-item">
      <label class="summary-info-label" for="filterDocuments">Documents</label>
      <kendo-combobox
          #documents
          placeholder="Select Document"
          name="filterDocuments"
          textField="name"
          valueField="id"
          formControlName="selectedDocument"
          [filterable]="true"
          [kendoDropDownFilter]="{ operator: 'contains'}"
          [valuePrimitive]="false" 
          [popupSettings]="{ popupClass: 'kehe-dropdown-popup' }"
          [data]="documentTypes$ | async"
          (focus)="onFocus(documents)"
          (filterChange)="onFilterChange($event)"
        ></kendo-combobox>
      <kendo-formhint *ngIf="form.controls.selectedDocument.disabled">To enable Documents unselect Fields.</kendo-formhint>
    </div>
    <div class="filter-item">
      <label class="summary-info-label" for="filterEditedBy">Edited By</label>
      <kendo-dropdownlist
        [popupSettings]="{ popupClass: 'kehe-dropdown-popup' }"
        [data]="filterEditedBy$ | async"
        textField="code"
        valueField="value"
        name="filterEditedBy"
        formControlName="selectedEditedBy">
      </kendo-dropdownlist>
    </div>
    <div class="filter-item">
      <label class="summary-info-label" for="filterDC">DC</label>
      <kendo-dropdownlist
        [popupSettings]="{ popupClass: 'kehe-dropdown-popup' }"
        [data]="filterDCs$ | async"
        textField="code"
        valueField="value"
        name="filterDC"
        formControlName="selectedDC">
      </kendo-dropdownlist>
    </div>

    <div class="filter-item">
      <label class="created-date-label">Date Range</label>
      <div>
        <kendo-daterange>
          <div class="form-group kehe-daterange-group">
              <kendo-dateinput kendoDateRangeStartInput
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }"
                formControlName="createdFrom"></kendo-dateinput>
              <kendo-dateinput kendoDateRangeEndInput
                placeholder="mm/dd/yyyy"
                format="MM/dd/yyyy"
                [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }"
                formControlName="createdTo"></kendo-dateinput>
          </div>
        </kendo-daterange>
      </div>
    </div>
  </div>

  <div class="actions">
    <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="applyFilter()">Apply</kehe-phoenix-button>
    <kehe-phoenix-button class="cancel" classAttr="btn-secondary btn-medium" (click)="closePanel(filter)">Cancel</kehe-phoenix-button>
  </div>
</kehe-phoenix-slide-panel>
