import { Component, Input, OnChanges } from '@angular/core';
import { AssociatedSuppliersTabChildrenGridStore } from './associated-suppliers-tab-children-grid.store';
import { AssociatedSupplierItem } from '../../../models/associated-suppliers-response';

@Component({
  selector: 'app-associated-suppliers-tab-children-grid',
  templateUrl: './associated-suppliers-tab-children-grid.component.html',
  styleUrls: ['./associated-suppliers-tab-children-grid.component.scss'],
  providers: [AssociatedSuppliersTabChildrenGridStore],
})
export class AssociatedSuppliersTabChildrenGridComponent implements OnChanges {
  @Input() associatedSuppliers: AssociatedSupplierItem[];

  constructor(
    private _componentStore: AssociatedSuppliersTabChildrenGridStore
  ) {}

  vm$ = this._componentStore.vm$;

  ngOnChanges(): void {
    this._componentStore.patchState({
      associatedSuppliers: this.associatedSuppliers,
    });
  }
}
