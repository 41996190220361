import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { BrokerTabStore } from '../broker-v2-tab.store';
import { BrokerTabGridStore } from '../broker-tab-v2-grid/broker-tab-v2-grid.store';
import { BrokerGridRowType } from '../broker-item.model';
import { tap, withLatestFrom, switchMap } from 'rxjs/operators';
import { SupplierDetailService } from '../../../services/supplier-detail.service';
import { saveSupplierFormSuccess } from '../../../store/supplier-detail.actions';
import { VendorBrokerManagementService } from '../../../services/vendor-broker-management.service';
import { RelatedNonHeadquarterBrokerApiStore } from '../broker-tab-v2-grid/api-stores/related-non-headquarter-broker.api.store';
import { SupplierDocumentsService } from '../../../../supplier-documents/services/supplier-documents.service';
import { Document } from '../../../../supplier-documents/models/document';
import {
  brokerV2IsValidChanged,
  deleteBrokerV2DocumentSuccess,
} from '../broker-actions';
import { selectAddNoteFormValues, selectIsDraftSupplier, selectSupplierHasHeadquarterBroker } from '../../../store/supplier-detail.selectors';
import { AddNoteForm } from '../../../models/add-note-form';

export interface BrokerTabV2DeleteConfirmationModalState {
  isDeleting: boolean;
  hasApiError: boolean;
  apiErrorMessage: string;
}

export const initialState: BrokerTabV2DeleteConfirmationModalState = {
  isDeleting: false,
  hasApiError: false,
  apiErrorMessage:
    'Unable to complete your request at this time. Please try again.',
};

const genericApiErrorText =
  'Unable to complete your request at this time. Please try again.';

@Injectable()
export class BrokerTabV2DeleteConfirmationModalStore extends ComponentStore<BrokerTabV2DeleteConfirmationModalState> {
  constructor(
    private _store: Store,
    private _brokerTabStore: BrokerTabStore,
    private _brokerGridStore: BrokerTabGridStore,
    private _supplierDetailService: SupplierDetailService,
    private _supplierDocumentService: SupplierDocumentsService,
    private _vendorBrokerManagementService: VendorBrokerManagementService,
    private _nonHeadquartersGridApiStore: RelatedNonHeadquarterBrokerApiStore
  ) {
    super();
    this.setState(initialState);
  }

  // Properties
  readonly _rowToDelete$ = this._brokerGridStore._rowToDelete$;

  private _addNoteFormValues$ = this._store.select(selectAddNoteFormValues);

  private _isDraftSupplier$ = this._store.select(selectIsDraftSupplier);

  private _document$ = this.select(this._rowToDelete$, (row) => row?.document);
  private _rowType$ = this.select(
    this._rowToDelete$,
    (rowToDelete) => rowToDelete.type
  );

  private _isBroker$ = this.select(
    this._rowToDelete$,
    (rowToDelete) => rowToDelete.type !== BrokerGridRowType.BrokerOfRecordForm
  );

  private _gridRowsCount$ = this.select(
    this._brokerGridStore.gridRows$,
    (currentRows) => currentRows.length
  );

  readonly vm$ = this.select(
    this.state$,
    this._rowToDelete$,
    this._isBroker$,
    this._addNoteFormValues$,
    this._isDraftSupplier$,
    (state, rowInfo, isBroker, addNoteForm, isDraftSupplier) => ({
      ...state,
      rowInfo,
      isBroker,
      isRemoveButtonDisabled: !this.isAddNoteFormValid(addNoteForm, isDraftSupplier) || state?.isDeleting,
      isDraftSupplier,
    })
  );

  isAddNoteFormValid(addNoteForm: AddNoteForm, isDraftSupplier: boolean) {
    return isDraftSupplier ? true : addNoteForm?.isValid;
  }

  // Methods
  closeModal() {
    this._brokerTabStore.closeModal();
  }

  readonly confirmDeletion$ = this.effect((_) => {
    return _.pipe(
      withLatestFrom(this._rowType$),
      tap(([_, type]: [void, BrokerGridRowType]) => {
        this.patchState({
          isDeleting: true,
          hasApiError: false,
        });

        switch (type) {
          case BrokerGridRowType.Headquarter:
            this.removeHQBroker$();
            break;
          case BrokerGridRowType.BrokerOfRecordForm:
            this.deleteDocument$();
            break;
          case BrokerGridRowType.NonHeadquarter:
            this.deleteNonHqBroker$();
            break;
        }
      })
    );
  });

  removeHQBroker$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(
        this._brokerTabStore._supplierEsn$,
        this._brokerTabStore._supplierIsActive$,
        this._addNoteFormValues$,
      ),
      switchMap(([_, supplierEsn, supplierIsActive, addNoteForm]) => {
        const param = {
          broker: {},
          // The property hasHeadquarterBroker is set to false if the Supplier is active, that makes it default to "Supplier does not have a broker"
          // For Draft Suppliers is set to null in order to reset the tab validity
          hasHeadquarterBroker: supplierIsActive ? false : null,
          isBrokerOfRecord: true,
          isHeadquarterBrokerNotFound: null,
          knowsHeadquarterBrokerContact: null,
          updatedBySource: 'Enterprise',
          esn: supplierEsn,
          auditNotes: addNoteForm?.auditNotes
        };

        return this._supplierDetailService.save(param).pipe(
          tapResponse(
            (supplier) => {
              this.patchState({
                isDeleting: false,
              });

              this._store.dispatch(
                saveSupplierFormSuccess({ supplier, form: param })
              );

              this.closeModal();
            },
            (error) => {
              this.patchState({
                apiErrorMessage: genericApiErrorText,
                isDeleting: false,
                hasApiError: true,
              });
            }
          ),
          tap(() => {
            this.patchState({ isDeleting: false });
          })
        );
      })
    )
  );

  deleteNonHqBroker$ = this.effect((_) => {
    return _.pipe(
      withLatestFrom(
        this._brokerTabStore._supplierEsn$,
        this._rowToDelete$,
        this._gridRowsCount$,
        this._store.select(selectSupplierHasHeadquarterBroker),
        this._store.select(selectAddNoteFormValues)
      ),
      switchMap(
        ([_, supplierEsn, rowToBeDeleted, currentCount, hasHQBroker, addNoteForm]) => {
          return this._vendorBrokerManagementService
            .deleteNonHqBroker(supplierEsn, rowToBeDeleted.id, addNoteForm?.auditNotes)
            .pipe(
              tapResponse(
                () => {
                  this.patchState({
                    isDeleting: false,
                    hasApiError: false,
                  });

                  this._nonHeadquartersGridApiStore.loadNonHeadquarterBrokers$(
                    supplierEsn
                  );

                  if (currentCount == 1 && hasHQBroker) {
                    // Supplier flag for hasHeadQuarterBroker is true
                    this.removeHQBroker$();
                  } else {
                    this.closeModal();
                  }
                },
                (error) => {
                  this.patchState({
                    hasApiError: true,
                    isDeleting: false,
                    apiErrorMessage: genericApiErrorText,
                  });
                }
              )
            );
        }
      )
    );
  });

  readonly deleteDocument$ = this.effect((_) => {
    return _.pipe(
      withLatestFrom(this._document$, this._gridRowsCount$),
      switchMap(
        ([_, documentToDelete, currentCount]: [void, Document, number]) =>
          this._supplierDocumentService.deleteDocument(documentToDelete).pipe(
            tapResponse(
              () => {
                this.patchState({
                  hasApiError: false,
                  isDeleting: false,
                });

                this._store.dispatch(
                  deleteBrokerV2DocumentSuccess({
                    document: documentToDelete,
                  })
                );

                if (currentCount === 1) {
                  this.removeHQBroker$();
                } else {
                  this._store.dispatch(brokerV2IsValidChanged());

                  this.closeModal();
                }
              },
              (error) => {
                this.patchState({
                  hasApiError: true,
                  isDeleting: false,
                });
              }
            )
          )
      )
    );
  });
}
