export function getStatusTag(status: string) {
    return {
      text: status,
      bgColor: status === 'Active' ? '#D5E48F' : '',
      borderColor: status === 'Active' ? '#D5E48F' : '',
    };
  }

export function formatLocation(address) {
    let formattedAddress = null;
    if (address) {
      const state = (address?.state?.code ? address?.state?.code : address?.province);
      const addressParts = [address?.city, state];
      const validAddressParts = addressParts.filter(
        (a) => a != null && a != undefined
      );
      if (validAddressParts.length > 0) {
        formattedAddress = validAddressParts.join(', ');
      }
    }
    return formattedAddress;
}
