import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductGroup } from '../../supplier-detail/models/product-group';
import { isDraftDCPG } from '../utils/products-util';
import { DCProductGroupClone, ProductGroupsState } from './product-groups.reducer';

export const getProductGroupsState =
  createFeatureSelector<ProductGroupsState>('productGroups');

// Products Data
export const selectProductsDataLoading = createSelector(
  getProductGroupsState,
  (state: ProductGroupsState) => state.isLoading
);
// Products Data

// Product Groups
export const getAllProductGroups = createSelector(
  getProductGroupsState,
  (state: ProductGroupsState) => state.productGroups
);
export const getProductGroup = createSelector(
  getProductGroupsState,
  (state: ProductGroupsState, props: { productGroupNumber: string }) =>
    state.productGroups.find(
      (item) => item.productGroupNumber === props.productGroupNumber
    )
);
export const getProductGroups = createSelector(
  getProductGroupsState,
  (state: ProductGroupsState) => state.productGroupsFiltered
);
export const productGroupsFilterChips = createSelector(
  getProductGroupsState,
  (state: ProductGroupsState) => state.productGroupsFilterChips
);
// Product Groups

// Product Group Search & Filter
export const productGroupsSearch = createSelector(
  getProductGroupsState,
  (state: ProductGroupsState) => state.productGroupsSearch
);
export const getProductGroupsFilter = createSelector(
  getProductGroupsState,
  (state: ProductGroupsState) => state.productGroupsFilter
);
// Product Group Search & Filter

// Clone DCPG
export const selectCloneDCPG = createSelector(
  getProductGroupsState,
  (state) => state.stagedCloneDCPG
);

export const selectCloneDCPGHasChanges = createSelector(
  getProductGroupsState,
  (state) => state.cloneDCHasChanges
);

export const selectCloneDCPGValid = createSelector(
  getProductGroupsState,
  (state) => state.cloneDCValid
);

export const selectCloneDCPGSaveInProgress = createSelector(
  getProductGroupsState,
  (state) => state.cloneDCPGSaveInProgress
);

export const selectCloneDCPGPickupChanged = createSelector(
  getProductGroupsState,
  (state) => state.cloneDCPGPickupChanged
);

export const selectCommittedCloneDCPG = createSelector(
  getProductGroupsState,
  (state) => {
    if (state.stagedCloneDCPG) {
      const existingDraftDCPGs = state.stagedCloneDCPG.productGroup.dcProductGroups
        .filter((dcpg) => isDraftDCPG(dcpg));
      const dcpgs = state.committedCloneDCPG?.dcpg?.buyers?.reduce((a, buyer) => {
        buyer.buyerDCs.forEach((dc) => {
          a.push({
            ...state.committedCloneDCPG.dcpg,
            buyerCode: buyer.buyerCode,
            distributionCenterNumber: dc.value,
            // if the DC already exists as a draft DCPG, then retain the dcProductGroupNumber
            dcProductGroupNumber: existingDraftDCPGs.find((dcpg) => dcpg.distributionCenterNumber === dc.value)?.dcProductGroupNumber
          });
        });
        return a;
      }, [] as Array<DCProductGroupClone>)
        .map((dcpg: any) => {
          delete dcpg.buyers;
          delete dcpg.cloneFromDC;
          delete dcpg.cloneToDCs;
          delete dcpg.dcDisplayName;
          delete dcpg.isActiveInDc;
          delete dcpg.isPONotEmpty;
          delete dcpg.products;

          return dcpg;
        });

      const productGroup = {
        name: state.stagedCloneDCPG.productGroup.name,
        productGroupNumber: state.stagedCloneDCPG.productGroup.productGroupNumber,
        temperatureClass: state.stagedCloneDCPG.productGroup.temperatureClass,
        dcProductGroups: dcpgs,
        cloneToDCs: state.committedCloneDCPG.dcpg.cloneToDCs,
      } as ProductGroup & { cloneToDCs: { value: string }[] };

      return productGroup;
    }
    return null;
  }
);

export const selectCloneDCPGSaveError = createSelector(
  getProductGroupsState,
  (state) => state.cloneDCPGSaveError,
)

// Bulk Edit DCPGs

export const selectApplyBulkEditSuccess = createSelector(
  getProductGroupsState,
  (state) => state.applyDCPGBulkEditSuccess
);
export const selectApplyBulkEditFailed = createSelector(
  getProductGroupsState,
  (state) => state.applyDCPGBulkEditFailed
);
