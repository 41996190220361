<kehe-phoenix-delete-modal
  name="remove-contact-address"
  modalTitle="Delete Address"
  [isDeleteInProgress]="loading"
  [errorMessage]="error"
  size="md"
  (modalEvent)="cancel.next()"
  (cancelClicked)="cancel.next()"
  (deleteClicked)="delete.next(contact)"
>
  <div class="body">
    <p>Are you sure you would like to delete the address?</p>

    <div class="d-flex">
      <p class="font-weight-bold col-2 p-0">Address:</p>
      <div class="col-6 address-content">{{ getAddressText() }}</div>
    </div>
    <div class="d-flex">
      <p class="font-weight-bold col-2 p-0">Contact:</p>
      <div class="col-6 address-content">{{ getContactText() }}</div>
    </div>
  </div>
</kehe-phoenix-delete-modal>
