import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { prepPhone } from '../../../../utils/common-utils';
import { Destroyable } from '../../../../abstract/destroyable';
import { takeUntil } from 'rxjs/operators';
import { PurchaseOrderTransmissionFax } from '../../../supplier-detail/models/purchase-order-fax';
import { PurchaseOrderTransmissionEmail } from '../../../supplier-detail/models/purchase-order-email';
import { DcpgFormService } from '../../../supplier-detail/services/dcpg-form.service';
import { merge } from 'rxjs';
import { OrderMinimum } from '../../../supplier-detail/models/order-minimum';
import { EmailValidators } from '../../../../utils/email-validation-utils';

@Component({
  selector: 'app-product-group-po-transmissions-form',
  templateUrl: './po-transmissions-form.component.html',
  styleUrls: ['./po-transmissions-form.component.scss'],
})
export class PoTransmissionsFormComponent extends Destroyable implements OnInit {

  @Input() isPOTypeRequired = false;
  @Input() hasPOOrder = false;

  transTypes = ['Broker', 'Supplier'];
  orderUnits = ['Cases', 'Dollars', 'Pounds'];
  poEmailMaxLength = 100;
  poEmailCounter = [`0/${ this.poEmailMaxLength }`];

  get hasPOOrderFormGroup(): boolean {
    return this.form.controls.hasOwnProperty('poOrderMinimum');
  }

  get isPOTouched() {
    const faxField = this.poFaxFormField;
    if (faxField.touched) {
      return true;
    }
    this.poEmailsFormArray.controls.forEach((control, i) => {
      const emailField = control as UntypedFormControl;
      if (emailField.touched) {
        return true;
      }
    });
    return false;
  }

  get isPOTransRequired() {
    return this.isPOTouched && !this.isPONotEmpty;
  }

  get poEmailsFormArray() {
    return this.form.controls.poEmails as UntypedFormArray;
  }

  get poFaxFormField() {
    const poFaxGroup = this.form.controls.poFax as UntypedFormGroup;
    return poFaxGroup.controls.fax;
  }

  get isPONotEmpty() {
    return this.form.controls.isPONotEmpty.value;
  }

  form: UntypedFormGroup;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _dcpgFormService: DcpgFormService,
  ) {
    super();
  }

  ngOnInit() {
    this.initializeForm(this.hasPOOrder);

    this.updatePoEmailCharCounts();
  }

  initializeForm(hasPOOrder: boolean) {
    this.form = this._dcpgFormService.buildPOTransmissionsFormGroup(hasPOOrder);
    return this.form;
  }

  updateIsPONotEmpty() {
    const faxValid = this.poFaxFormField.value && this.poFaxFormField.value.length > 0;
    const validEmails = this.poEmailsFormArray.controls.filter(item => {
      const emailField = (item as UntypedFormGroup).controls.email;
      return emailField.value && emailField.value.length > 0;
    });
    const emailValid = validEmails.length > 0;
    this.form.get('isPONotEmpty').setValue(faxValid || emailValid);
  }

  onFocus(element: any): void {
    element.toggle(true);
  }

  getPOEmailField(index) {
    return this.getPoEmailsFormGroup(index).controls.email;
  }

  getPoEmailsFormGroup(index): UntypedFormGroup {
    return this.poEmailsFormArray.controls[index] as UntypedFormGroup;
  }

  handlePOEmailBlur() {
    this.markAllPOEmailsTouched();
    this.poFaxFormField.markAsTouched();
  }

  deletePoTransaction(index) {
    this.form.markAsDirty();
    this.poEmailsFormArray.markAsDirty();
    this.poEmailsFormArray.removeAt(index);
    this.updatePoEmailCharCounts();
  }

  addPoTransaction() {
    this.poEmailsFormArray.push(this.createPOEmailGroup());
    this.updatePoEmailCharCounts();
    if (this.isPOTransRequired) {
      this.markAllPOEmailsTouched();
    }
  }

  createPOEmailGroup(): UntypedFormGroup {
    return this._formBuilder.group({
      type: [''],
      email: ['', [EmailValidators.domainEmail]]
    });
  }

  patchForm(poFax: PurchaseOrderTransmissionFax, poEmails: Array<PurchaseOrderTransmissionEmail>, poOrderMinimum?: OrderMinimum) {
    this.createInitialPoTransactions(poEmails);
    this.form.controls.poFax.setValue(this.setPoFaxType(poFax));

    if (this.hasPOOrder && poOrderMinimum) {
      this.form.get('poOrderMinimum').setValue(poOrderMinimum);
    }

    this.initializeFormValueChanges();
    this.updateIsPONotEmpty();
    this.updatePoEmailCharCounts();
  }

  resetForm() {
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
  }

  clearFormArray(formArray: UntypedFormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  createInitialPoTransactions(poEmails: Array<PurchaseOrderTransmissionEmail>) {
    this.clearFormArray(this.poEmailsFormArray);
    if (!poEmails || poEmails.length === 0) {
      this.poEmailsFormArray.push(this.createPOEmailGroup());
    } else {
      for (let t = 0; t < poEmails.length; t++) {
        this.poEmailsFormArray.push(this._formBuilder.group({
          type: [poEmails[t].type ? poEmails[t].type.trim() : ''],
          email: [poEmails[t].email ? poEmails[t].email.trim() : '',
            [EmailValidators.domainEmail, Validators.maxLength(this.poEmailMaxLength)]],
        }));
      }
    }
  }

  poEmailChange(e: any, i: number) {
    this.poEmailCounter[i] = this.charCounterUpdate(e.length, this.poEmailMaxLength);
  }

  charCounterUpdate(length: any, maxLength: number): string {
    return `${ length }/${ maxLength }`;
  }

  getOrderMinFormat(control: string) {
    const orderType = this.form.get(control).value;
    if (orderType && orderType === 'Dollars') {
      return 'c2';
    }
    return '0';
  }

  handlePOTypeBlur() {
    if (this.isPOTypeRequired) {
      this.handlePOEmailBlur();
    }
  }

  private markAllPOEmailsTouched() {
    this.poEmailsFormArray.controls.forEach((control, i) => {
      this.getPOEmailField(i).markAsTouched();
    });
  }

  private initializeFormValueChanges() {
    merge(
      this.form.controls.poFax.valueChanges,
      this.form.controls.poEmails.valueChanges
    ).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateIsPONotEmpty();
    });
  }

  private updatePoEmailCharCounts() {
    for (const key of Object.keys(this.poEmailsFormArray.controls)) {
      this.poEmailChange(this.poEmailsFormArray.controls[key].controls.email.value, +key);
    }
  }

  private setPoFaxType(poFax: PurchaseOrderTransmissionFax) {
    if (poFax) {
      const fax = poFax.fax && poFax.fax.length > 0 ? poFax.fax : null;
      return {type: poFax.type || '', fax: prepPhone(fax)};
    }
    return {type: '', fax: ''};
  }
}
