import { createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BreakpointService } from '@kehe/phoenix-utils';
import { BreakpointStoreActions } from './breakpoint.actions';

@Injectable()
export class BreakpointEffects {
  constructor(private _breakpointService: BreakpointService) {}

  xtraSmallViewChanged$ = createEffect(() =>
    this._breakpointService.isXtraSmallView$.pipe(
      map((isXtraSmallView) =>
        BreakpointStoreActions.xtraSmallViewChanged(isXtraSmallView)
      )
    )
  );

  smallViewChanged$ = createEffect(() =>
    this._breakpointService.isSmallView$.pipe(
      map((isSmallView) => BreakpointStoreActions.smallViewChanged(isSmallView))
    )
  );

  mediumViewChanged$ = createEffect(() =>
    this._breakpointService.isMediumView$.pipe(
      map((isMediumView) =>
        BreakpointStoreActions.mediumViewChanged(isMediumView)
      )
    )
  );

  largeViewChanged$ = createEffect(() =>
    this._breakpointService.isLargeView$.pipe(
      map((isLargeView) => BreakpointStoreActions.largeViewChanged(isLargeView))
    )
  );

  xtraLargeViewChanged$ = createEffect(() =>
    this._breakpointService.isXtraLargeView$.pipe(
      map((isXtraLargeView) =>
        BreakpointStoreActions.xtraLargeViewChanged(isXtraLargeView)
      )
    )
  );

  xtraXtraLargeViewChanged$ = createEffect(() =>
    this._breakpointService.isXtraXtraLargeView$.pipe(
      map((isXtraXtraLargeView) =>
        BreakpointStoreActions.xtraXtraLargeViewChanged(isXtraXtraLargeView)
      )
    )
  );

  smallAndBelowChanged$ = createEffect(() =>
    this._breakpointService.isSmallAndBelow$.pipe(
      map((isSmallAndBelow) =>
        BreakpointStoreActions.smallAndBelowChanged(isSmallAndBelow)
      )
    )
  );

  medAndBelowChanged$ = createEffect(() =>
    this._breakpointService.isMedAndBelow$.pipe(
      map((isMedAndBelow) =>
        BreakpointStoreActions.medAndBelowChanged(isMedAndBelow)
      )
    )
  );

  largeAndBelowChanged$ = createEffect(() =>
    this._breakpointService.isMedAndBelow$.pipe(
      map((isMedAndBelow) =>
        BreakpointStoreActions.medAndBelowChanged(isMedAndBelow)
      )
    )
  );

  medAndUpChanged$ = createEffect(() =>
    this._breakpointService.isMedAndUp$.pipe(
      map((isMedAndUp) => BreakpointStoreActions.medAndUpChanged(isMedAndUp))
    )
  );

  largeAndUpChanged$ = createEffect(() =>
    this._breakpointService.isLargeAndUp$.pipe(
      map((isLargeAndUp) =>
        BreakpointStoreActions.largeAndUpChanged(isLargeAndUp)
      )
    )
  );
}
