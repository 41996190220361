import { KeheNavigationItem } from '@kehe/phoenix-top-navigation-panel';
import { Constants } from '../../constants/constants';
import { environment } from '../../../environments/environment';
import { UserDetailGroupRecord } from '@app/modules/user-management/model/service/user-management';


function getProductsV1PromotionsMenu(groups: number[]): KeheNavigationItem {
  const requiredV1Groups = [
    environment.v1GroupIDs.cm,
    environment.v1GroupIDs.cmAdmin,
    environment.v1GroupIDs.marketing,
    environment.v1GroupIDs.marketingAdmin,
    environment.v1GroupIDs.promoTeam,
    environment.v1GroupIDs.promoTeamAdmin,
    environment.v1GroupIDs.vendorManager,
    environment.v1GroupIDs.vendorPerformance
  ];

  if (!groups.some(g => requiredV1Groups.includes(g))) {
    return;
  }
  const subitems = [
    new KeheNavigationItem(
      `${Constants.SubMenu.ProductsPromotions} (V1)`,
      environment.promotionsUrl,
      { exact: true }
    )
  ];
  if (groups.some(g => g === environment.v1GroupIDs.promoTeamAdmin || g === environment.v1GroupIDs.marketingAdmin)) {
    subitems.push(
      new KeheNavigationItem(
        `${Constants.SubMenu.ProductsPublicationsCalendar} (V1)`,
        `${environment.promotionsUrl}/#/publication_calendar/view`,
        { exact: true }
      )
    );
    subitems.push(
      new KeheNavigationItem(
        `${Constants.SubMenu.ProductsPromotionCalendar} (V1)`,
        `${environment.promotionsUrl}/#/calendar`,
        { exact: true }
      )
    );
  }

  return new KeheNavigationItem(
    `${Constants.SubMenu.ProductsPromotions} (V1)`,
    '/',
    {},
    null,
    subitems
  )
}

function getProductsV1DiscontinuedMenu(groups: number[]): KeheNavigationItem {
  const requiredGroups = [
    environment.v1GroupIDs.cm,
    environment.v1GroupIDs.cem,
    environment.v1GroupIDs.bem,
  ];
  if (groups.some(g => requiredGroups.includes(g))) {
    return  new KeheNavigationItem(
      `${Constants.SubMenu.ProductsDiscontinuedProducts} (V1)`,
      environment.discontinuedProductsUrl,
      { exact: true }
    );
  }
}

function getProductsV1AOMMenu(groups: number[]): KeheNavigationItem {
  if (groups.includes(environment.v1GroupIDs.cm)) {
    return new KeheNavigationItem(
      `${Constants.SubMenu.ProductsAomV1Workflow} (V1)`,
      environment.aomWorkflowUrl,
      { exact: true }
    )
  }
}

function getProductsV1PricingMenu(groups: number[]): KeheNavigationItem {
  const requiredGroups = [
    environment.v1GroupIDs.pricingAdmin,
    environment.v1GroupIDs.pricingAnalyst,
  ];
  if (groups.some(g => requiredGroups.includes(g))) {
    const subitems = [];
    if (groups.includes(environment.v1GroupIDs.pricingAdmin)) {
      subitems.push(
        new KeheNavigationItem(
          `${Constants.SubMenu.ProductsPricingAdmin} (V1)`,
          `${environment.v1ConnectHost}/pricingmanagement/#/admin/landing-page`,
          { exact: true }
        )
      );
    }
    subitems.push(
      new KeheNavigationItem(
        `${Constants.SubMenu.ProductsPricingManagement} (V1)`,
        `${environment.v1ConnectHost}/pricingmanagement`,
        { exact: true }
      )
    );

    return new KeheNavigationItem(
      `${Constants.SubMenu.ProductsManagePricing} (V1)`,
      '/',
      {},
      null,
      subitems,
    )
  }
}

export function getProductsMenu(userActions: string[], userGroups: UserDetailGroupRecord[], isProhibitRestrictFeatureFlagEnabled: boolean, isProhibitRestrictRelease1Enabled: boolean): KeheNavigationItem {
  const doesUserHaveProductReadResourceAction = userActions?.includes(Constants.UserActions.ProductRead);
  const doesUserHaveProhibitRestrictReadResourceAction = userActions?.includes(Constants.UserActions.ProhibitRestrictRead);
  const accessToSupplierPriceChanges = userActions?.includes(Constants.UserActions.SPCAdmin);
  const doesUserHavePriceGroupAccess = userActions?.includes(Constants.UserActions.PriceGroupAdmin) || userActions?.includes(Constants.UserActions.PriceGroupRead);
  let productsMenu: KeheNavigationItem = null;

  if (doesUserHaveProductReadResourceAction) {
    const productManagementSubItems = new Array<KeheNavigationItem>(
      new KeheNavigationItem(
        Constants.SubMenu.Products_Manage_Prod,
        '/products',
        { exact: false },
        undefined,
        undefined,
        undefined,
        'manage-products'
      )
    );
    const groups = userGroups?.map(u => u.groupId) ?? []

    const showProhibitRestrcit = isProhibitRestrictFeatureFlagEnabled && isProhibitRestrictRelease1Enabled && doesUserHaveProhibitRestrictReadResourceAction;
    const prohibitsRestrictsItem = getProductsManageProhibitRestrictsListMenu(showProhibitRestrcit);
    if (showProhibitRestrcit && prohibitsRestrictsItem ) {
      productManagementSubItems.push(prohibitsRestrictsItem);
    }
    const pricingV1Item = getProductsV1PricingMenu(groups);
    if (pricingV1Item) {
      productManagementSubItems.push(pricingV1Item);
    }
    const promotionsV1Item = getProductsV1PromotionsMenu(groups);
    if (promotionsV1Item) {
      productManagementSubItems.push(promotionsV1Item);
    }
    const discontinuedV1Item = getProductsV1DiscontinuedMenu(groups)
    if (discontinuedV1Item) {
      productManagementSubItems.push(discontinuedV1Item);
    }

    const aomV1Item = getProductsV1AOMMenu(groups);
    if (aomV1Item) {
      productManagementSubItems.push(aomV1Item);
    }

    if (accessToSupplierPriceChanges) {
      productManagementSubItems.push(
        new KeheNavigationItem(
          Constants.SubMenu.SupplierPriceChangesAdmin,
          '/manage-prices',
          { exact: false },
          undefined,
          undefined,
          undefined,
          'manage-prices'
        )
      )
    }
    
    getPriceGroupMenu(productManagementSubItems, doesUserHavePriceGroupAccess);

    productsMenu = new KeheNavigationItem(
      Constants.Features.Products,
      '/',
      {},
      null,
      productManagementSubItems
    );
  }
  return productsMenu;
}

function getSalesCustomerMenu(menuItemsList: KeheNavigationItem[], doesUserHaveCustomerReadResourceAction: boolean) {
  if (doesUserHaveCustomerReadResourceAction) {
    menuItemsList.push(
      new KeheNavigationItem(
        Constants.SubMenu.CustomerManagement,
        '/customer',
        { exact: false }
      )
    );
  }
}

function getPriceGroupMenu(menuItemsList: KeheNavigationItem[], doesUserHavePriceGroupAccess: boolean) {
  if (doesUserHavePriceGroupAccess) {
    menuItemsList.push(
      new KeheNavigationItem(
        Constants.SubMenu.ManagePriceGroups,
        '/manage-price-groups',
        { exact: false },
        undefined,
        undefined,
        undefined,
        'manage-price-groups'
      )
    );
  }
}
function getSalesOrdersMenu(menuItemsList: KeheNavigationItem[], doesUserHaveSalesOrderReadResourceAction: boolean) {
  if (doesUserHaveSalesOrderReadResourceAction) {
    menuItemsList.push(
      new KeheNavigationItem(
        Constants.SubMenu.SalesManageOrders,
        '/sales-orders',
        { exact: false }
      )
    );
  }
}

function getSalesShipmentsListMenu(menuItemsList: KeheNavigationItem[], doesUserHaveShipmentReadResourceAction: boolean) {
  if (doesUserHaveShipmentReadResourceAction) {
    menuItemsList.push(
      new KeheNavigationItem(
        Constants.SubMenu.SalesManageShipments,
        '/shipments',
        { exact: false }
      )
    );
  }
}

function getProductsManageProhibitRestrictsListMenu(isProhibitRestrictFeatureFlagEnabled: boolean) {
  if(isProhibitRestrictFeatureFlagEnabled){
    return new KeheNavigationItem(
      Constants.SubMenu.ProductsManageProhibitRestricts,
      '/prohibit-restricts',
      { exact: false },
        undefined,
        undefined,
        undefined,
        'products-prohibits-restricts-list-menu'
    );
  }
}

function getSalesManageCustomPromotionMenu(menuItemsList: KeheNavigationItem[],
  doesUserHaveCustomPromotionsReadResourceAction: boolean,
    isEnterpriseSalesManagesCustomPromotionsFeatureFlagEnabled: boolean) {
  if (doesUserHaveCustomPromotionsReadResourceAction && isEnterpriseSalesManagesCustomPromotionsFeatureFlagEnabled) {
    menuItemsList.push(
      new KeheNavigationItem(
        Constants.SubMenu.SalesManageCustomPromotions,
        '/custom-promotions',
        { exact: true }
      )
    );
  }
}

function getSalesShowMenu(menuItemList: KeheNavigationItem[], doesUserHaveShowReadResourceAction: boolean) {
  if (doesUserHaveShowReadResourceAction) {
    menuItemList.push(
      new KeheNavigationItem(Constants.SubMenu.SalesManageShowsAndEvents, '/shows', {
        exact: true,
      })
    );
  }
}

function getSalesDeliveryMenu(menuItemList: KeheNavigationItem[], doesUserHaveEpodReadResourceAction: boolean) {
  if (doesUserHaveEpodReadResourceAction) {
    menuItemList.push(
      new KeheNavigationItem(
        Constants.SubMenu.DeliveryHub,
        environment.deliveryHubUrl,
        { exact: true }
      )
    );
    menuItemList.push(
      new KeheNavigationItem(
        Constants.SubMenu.DeliveryTracking,
        environment.deliveryTrackingUrl,
        { exact: true }
      )
    );
  }
}

function getSalesV1AOMMenu(menuItemList: KeheNavigationItem[], groups: number[]) {
  const requiredAOMGroups = [
    environment.v1GroupIDs.cm,
    environment.v1GroupIDs.orderMgmt2,
    environment.v1GroupIDs.orderMgmtAdmin,
  ];

  if (groups?.some(g => requiredAOMGroups.includes(g))) {
    const aomsubitems = [];

    if(groups?.includes(environment.v1GroupIDs.orderMgmt2) || groups?.includes(environment.v1GroupIDs.orderMgmtAdmin)) {
      aomsubitems.push(
        new KeheNavigationItem(
          `${Constants.SubMenu.OrderManagement} (V1)`,
          `${environment.v1ConnectHost}/ordermgmt2`,
          { exact: true }
        )
      );
    }
    if(groups?.includes(environment.v1GroupIDs.cm) || groups?.includes(environment.v1GroupIDs.orderMgmtAdmin)) {
      aomsubitems.push(
        new KeheNavigationItem(
          `${Constants.SubMenu.CMWorkflow} (V1)`,
          environment.aomWorkflowUrl,
          { exact: true },
        )
      );
    }
    menuItemList.push(
      new KeheNavigationItem(
        `${Constants.SubMenu.AOMV1} (V1)`,
        '/',
        {},
        null,
        aomsubitems
      )
    );
  }

  if (groups?.includes(environment.v1GroupIDs.sales)) {
    menuItemList.push(
      new KeheNavigationItem(
        `${Constants.SubMenu.Commissions} (V1)`,
        `${environment.v1SalesOperationsHost}/#/commissions/`,
        { exact: true },
      )
    );
    menuItemList.push(
      new KeheNavigationItem(
        `${Constants.SubMenu.SalesPerformance} (V1)`,
        `${environment.v1SalesOperationsHost}/#/sales_performance`,
        { exact: true },
      )
    );
  }
}

function getSalesSODCustomerManagementMenu(
  salesMenu: KeheNavigationItem,
  doesUserHaveEventPromotionReadResourceAction: boolean,
  isPromotionsEligibilityPageEnabled: boolean,
  isEnterpriseSODCustomerManagementFeatureFlagEnabled: boolean,
  subitemsIndex: number,
): KeheNavigationItem {
  const salesMenuClone = { ...salesMenu };
  if (isEnterpriseSODCustomerManagementFeatureFlagEnabled) {
    const showSubItem = new KeheNavigationItem(
      Constants.SubMenu.SalesManageShowsAndEvents,
      '/shows',
      { exact: true }
    );
    let promotionsSubItem = new KeheNavigationItem(
      Constants.SubMenu.SalesManageSODCustomers,
      '/sod-customers',
      { exact: true }
    );
    if (isPromotionsEligibilityPageEnabled) {
      promotionsSubItem = new KeheNavigationItem(
        Constants.SubMenu.SalesManagePromotionsEligibility,
        '/promotions-eligibility',
        { exact: true }
      );
    }
    let managePromotionsSubItem: KeheNavigationItem;
    if (doesUserHaveEventPromotionReadResourceAction) {
      managePromotionsSubItem = new KeheNavigationItem(
        Constants.SubMenu.SalesManagePromotions,
        '/promotions',
        { exact: false }
      );
    }

    const subitems = [showSubItem, promotionsSubItem];
    managePromotionsSubItem && subitems.splice(1, 0, managePromotionsSubItem);

    salesMenuClone.items[subitemsIndex] = new KeheNavigationItem(
      Constants.SubMenu.SalesShows,
      '/',
      { exact: true },
      null,
      subitems
    );
  } else {
    salesMenuClone.items[subitemsIndex] =
      new KeheNavigationItem(
        Constants.SubMenu.SalesManageShowsAndEvents,
        '/shows',
        { exact: true }
      );
  }

  return salesMenuClone;
}

export function getSalesMenu(
  userActions: string[],
  userGroups: UserDetailGroupRecord[],
  isEnterpriseSODCustomerManagementFeatureFlagEnabled: boolean,
  isPromotionsEligibilityPageEnabled: boolean,
  isMyListsFfOn: boolean,
  isEnterpriseSalesManagesCustomPromotionsFeatureFlagEnabled: boolean,
  ): KeheNavigationItem {
  const doesUserHaveCustomerReadResourceAction = userActions?.includes(Constants.UserActions.CustomerRead);
  const doesUserHaveSalesOrderReadResourceAction = userActions?.includes(Constants.UserActions.SalesOrderRead);
  const doesUserHaveShipmentReadResourceAction = userActions?.includes(Constants.UserActions.ShipmentRead);
  const doesUserHaveCustomPromotionsReadResourceAction = userActions?.includes(Constants.UserActions.CustomPromotionsRead);
  const doesUserHaveShowReadResourceAction = userActions?.includes(Constants.UserActions.ShowRead);
  const doesUserHaveEpodReadResourceAction = userActions?.includes(Constants.UserActions.EpodRead);
  const doesUserHaveEventPromotionReadResourceAction = userActions?.includes(Constants.UserActions.EventPromotion_Read);
  const userCanViewCustomLists = userActions?.includes(Constants.UserActions.CustomListRead);

  let salesMenu: KeheNavigationItem = null;
  const salesSubItems: Array<KeheNavigationItem> = [];

  const groups = userGroups?.map(u => u.groupId);

  // Customer List
  getSalesCustomerMenu(salesSubItems, doesUserHaveCustomerReadResourceAction);
  // Order List
  getSalesOrdersMenu(salesSubItems, doesUserHaveSalesOrderReadResourceAction);
  // Shipment List
  getSalesShipmentsListMenu(salesSubItems, doesUserHaveShipmentReadResourceAction);
  // Manage Custom Promotions
  getSalesManageCustomPromotionMenu(salesSubItems, doesUserHaveCustomPromotionsReadResourceAction, isEnterpriseSalesManagesCustomPromotionsFeatureFlagEnabled);
  // Show List
  getSalesShowMenu(salesSubItems, doesUserHaveShowReadResourceAction);
  // Show Delivery Hub and Delivery Tracking
  getSalesDeliveryMenu(salesSubItems, doesUserHaveEpodReadResourceAction);
  // Sales V1 AOM
  getSalesV1AOMMenu(salesSubItems, groups);

  if (salesSubItems.length > 0) {
    salesMenu = new KeheNavigationItem(
      Constants.Menu.Sales,
      '/',
      {},
      null,
      salesSubItems,
      undefined,
      'sales-list-menu'
    );
  }

  // Submenu Items for Show related stuff
  if (salesMenu) {
    const subitemsIndex = salesMenu.items.findIndex(
      (subItem) =>
        subItem.display === Constants.SubMenu.SalesShows ||
        subItem.display === Constants.SubMenu.SalesManageShowsAndEvents
    );

    if (subitemsIndex > -1) {
      salesMenu = getSalesSODCustomerManagementMenu(
        salesMenu,
        doesUserHaveEventPromotionReadResourceAction,
        isPromotionsEligibilityPageEnabled,
        isEnterpriseSODCustomerManagementFeatureFlagEnabled,
        subitemsIndex
      );
    }

    // Submenu Items for Credits stuff
    if (salesMenu) {
      const salesMenuClone = { ...salesMenu };
      const subitemIndex = salesMenuClone.items.findIndex(
        (item) => item.display === Constants.SubMenu.SalesManageCredits
      );
      if (subitemIndex === -1) {
        salesMenuClone.items.push(
          new KeheNavigationItem(
            Constants.SubMenu.SalesManageCredits,
            '/credits',
            { exact: false }
          )
        );
        salesMenu = salesMenuClone;
      }
    }

    // Product Lists (My Lists)
    if (isMyListsFfOn && userCanViewCustomLists) {
      salesSubItems.push(
        new KeheNavigationItem(
          Constants.SubMenu.SalesManageProductLists,
          '/product-lists',
          { exact: false }
        )
      );
    }
  }

  return salesMenu;
}

export function getDocumentsMenu(userActions: string[]): KeheNavigationItem {
  const doesUserHaveDocumentReadResourceAction = userActions?.includes(Constants.UserActions.DocumentRead);
  let documentsMenu: KeheNavigationItem = null;

  if (doesUserHaveDocumentReadResourceAction) {
    documentsMenu = new KeheNavigationItem(
      Constants.Menu.Documents,
      '/documents',
      {}
    );
  }

  return documentsMenu;
}

export function getPurchasingV1Menu(userGroups: number[]): KeheNavigationItem {
  const requiredGroups = [
    environment.v1GroupIDs.cm,
    environment.v1GroupIDs.kSolveUDR,
    environment.v1GroupIDs.vendorPerformance,
    environment.v1GroupIDs.vmAdmin,
    environment.v1GroupIDs.vmCoordinator,
    environment.v1GroupIDs.accountsPayable,
  ];
  if (userGroups.some(u => requiredGroups.includes(u))) {
    const subitems = [
      new KeheNavigationItem(
        `${Constants.SubMenu.KSolveV1} (V1)`,
        environment.ksolveUrl,
        { exact: true },
      )
    ];
    if (userGroups.includes(environment.v1GroupIDs.vendorPerformance)) {
      subitems.push(new KeheNavigationItem(
        `${Constants.SubMenu.KSolveV1} Admin (V1)`,
        `${environment.ksolveUrl}/#/validation-page`,
        { exact: true },
      ));
    }

    if (userGroups.includes(environment.v1GroupIDs.kSolveUDR)) {
      subitems.push(new KeheNavigationItem(
        `${Constants.SubMenu.KSolveV1} UDR Documents (V1)`,
        `${environment.ksolveUrl}/#/udr-search`,
        { exact: true },
      ));
    }

    return new KeheNavigationItem(
      `${Constants.SubMenu.ManageCredits} (V1)`,
      '/',
      {},
      null,
      subitems,
    );
  }
}

export function getPurchasingMenu(userActions: string[],
  userGroups: UserDetailGroupRecord[]): KeheNavigationItem {
  const doesUserHaveSuppplierReadResourceAction = userActions?.includes(Constants.UserActions.SupplierRead);
  const doesUserHaveBrokerReadResourceAction = userActions?.includes(Constants.UserActions.BrokerRead);
  const doesUserHavePurchasingOrderReadResourceAction = userActions?.includes(Constants.UserActions.PurchasingOrderRead);
  const doesUserHaveEnterpriseSiteAccessResourceAction = userActions?.includes(Constants.UserActions.EnterpriseSiteAccess);

  let purchasingMenu: KeheNavigationItem = null;
  const purchasingSubItems: Array<KeheNavigationItem> = [];

  if (doesUserHaveSuppplierReadResourceAction) {
    purchasingSubItems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ManageSuppliers,
        '/supplier',
        { exact: false }
      )
    );
  }

  if (doesUserHaveBrokerReadResourceAction) {
    purchasingSubItems.push(
      new KeheNavigationItem(Constants.SubMenu.ManageBrokers, '/broker', {
        exact: false,
      })
    );
  }

  // Show Po Flow
  if (doesUserHavePurchasingOrderReadResourceAction) {
    purchasingSubItems.push(
      new KeheNavigationItem(
        Constants.SubMenu.PoFlow,
        environment.poFlowUrl,
        { exact: true }
      )
    );
  }

  purchasingSubItems.push(
    new KeheNavigationItem(
      Constants.SubMenu.PromoRoadmap,
      '/promotional-roadmap',
      { exact: false },
      null,
      [],
      null,
      'manage-promotional-roadmap-nav-option'
    )
  );

  purchasingSubItems.push(
    new KeheNavigationItem(
      Constants.SubMenu.RoadmapNationalDeals,
      '/promotional-roadmap/national-deals-template-download',
      { exact: true },
      null,
      [],
      null,
      'roadmap-2025-national-deals-nav-option'
    )
  );

  if (doesUserHaveEnterpriseSiteAccessResourceAction) {
    const credits = getPurchasingV1Menu(userGroups?.map(u => u.groupId) ?? []);
    if (credits) {
      purchasingSubItems.push(credits);
    }
  }

  if (purchasingSubItems.length > 0) {
    purchasingMenu = new KeheNavigationItem(
      Constants.Menu.Purchasing,
      '/',
      {},
      null,
      purchasingSubItems
    );
  }

  return purchasingMenu;
}

export function getConnectBiMenu(userActions: string[]): KeheNavigationItem {
  const doesUserHaveUserReadResourceAction = userActions?.includes(Constants.UserActions.UserRead);
  let connectBiMenu: KeheNavigationItem = null;

  if (doesUserHaveUserReadResourceAction) {
    const usersSubitems: KeheNavigationItem[] = [
      new KeheNavigationItem(
        Constants.SubMenu.LegacyReports,
        environment.reportsUrl
      ),
      new KeheNavigationItem(Constants.SubMenu.Reporting, '/reporting'),
    ];
    connectBiMenu = new KeheNavigationItem(
      Constants.Menu.ConnectBI,
      '/',
      {},
      null,
      usersSubitems
    );
  }

  // Sub menu items for Reporting
  if (connectBiMenu) {
    const subitemsIndex = connectBiMenu.items.findIndex(
      (subItem) => subItem.display === Constants.SubMenu.Reporting
    );

    if (subitemsIndex > -1) {
      const connectBiMenuClone = { ...connectBiMenu };
      const Merchandising = new KeheNavigationItem(
        Constants.SubMenu.Merchandising,
        '/merchandising',
        { exact: true }
      );
      const subitems = [Merchandising];
      connectBiMenuClone.items[subitemsIndex] =
        new KeheNavigationItem(
          Constants.SubMenu.Reporting,
          '/',
          { exact: true },
          null,
          subitems
        );
        connectBiMenu = connectBiMenuClone;
    }
  }

  return connectBiMenu;
}

function getUtilitiesMarketingMenu(utilitiesMenu: KeheNavigationItem, isCEManageCarouselsFeatureFlagEnabled: boolean, doesUserHaveCarouselManagementReadResourceAction: boolean): KeheNavigationItem {
  if (isCEManageCarouselsFeatureFlagEnabled && doesUserHaveCarouselManagementReadResourceAction) {
    if (utilitiesMenu) {
      const utilitiesMenuClone = { ...utilitiesMenu };
      utilitiesMenuClone.items.push(
        new KeheNavigationItem(Constants.SubMenu.Marketing, '/', {
          exact: true,
        })
      );
      const subitemsIndex = utilitiesMenuClone.items.findIndex(
        (subItem) =>
          subItem.display === Constants.SubMenu.Marketing ||
          subItem.display === Constants.SubMenu.ManageAdvertisements
      );

      if (subitemsIndex > -1) {
        const utilitiesMenuClone = { ...utilitiesMenu };
        const managementItem = new KeheNavigationItem(
          Constants.SubMenu.ManageAdvertisements,
          '/advertisements',
        );
        const subitems = [managementItem];
        utilitiesMenuClone.items[subitemsIndex] =
          new KeheNavigationItem(
            Constants.SubMenu.Marketing,
            '/',
            { exact: true },
            null,
            subitems
          );
        return utilitiesMenuClone;
      }
    }
  }
}

function getUtilitiesHierarchiesMenu(
  utilitiesMenu: KeheNavigationItem,
  doesUserHaveSalesBusinessHierarchyReadResourceAction: boolean,
  doesUserHaveRetailerHierarchyReadResourceAction: boolean,
  doesUserHavePhysicalLocationHierarchyReadResourceAction: boolean,
  doesUserHaveInfraHierarchyReadResourceAction: boolean,
  doesUserHaveFreshHierarchyReadResourceAction: boolean,
): KeheNavigationItem {
  if (doesUserHaveSalesBusinessHierarchyReadResourceAction || doesUserHaveRetailerHierarchyReadResourceAction) {
    if (utilitiesMenu) {
      const utilitiesMenuClone = { ...utilitiesMenu };
      utilitiesMenuClone.items.push(
        new KeheNavigationItem(Constants.SubMenu.ManageHierarchies, '/', {
          exact: true,
        })
      );
      const subitemsIndex = utilitiesMenuClone.items.findIndex(
        (subItem) =>
          subItem.display === Constants.SubMenu.ManageHierarchies
      );

      if (subitemsIndex > -1) {
        const subitems = [];
        const utilitiesMenuClone = { ...utilitiesMenu };

        if (doesUserHaveSalesBusinessHierarchyReadResourceAction) {
          subitems.push(new KeheNavigationItem(
            Constants.SubMenu.SalesBusinessHierarchy,
            '/sales-business-hierarchy',
            { exact: true }
          ));
        }

        if (doesUserHaveRetailerHierarchyReadResourceAction) {
          subitems.push(new KeheNavigationItem(
          Constants.SubMenu.RetailerHierarchy,
            '/retailer-hierarchy',
            { exact: true }
          ));
        }

        if (doesUserHavePhysicalLocationHierarchyReadResourceAction) {
          subitems.push(new KeheNavigationItem(
            Constants.SubMenu.PhysicalLocationHierarchy,
            '/physical-location-hierarchy',
          ));
        };

        if (doesUserHaveInfraHierarchyReadResourceAction) {
          subitems.push(new KeheNavigationItem(
            Constants.SubMenu.InfraHierarchy,
            '/infra-hierarchy',
          ));
        };

        if (doesUserHaveFreshHierarchyReadResourceAction) {
          subitems.push(new KeheNavigationItem(
            Constants.SubMenu.FreshHierarchy,
            '/fresh-hierarchy',
          ));
        };

        if (doesUserHaveSalesBusinessHierarchyReadResourceAction) {
          subitems.push(new KeheNavigationItem(
            Constants.SubMenu.ExportHierarchies,
            '/sales-business-hierarchy',
            { exact: true },
            null,
            null,
            'exportHierarchyClicked'
          ));
        }

        utilitiesMenuClone.items[subitemsIndex] =
          new KeheNavigationItem(
            Constants.SubMenu.ManageHierarchies,
            '/',
            { exact: true },
            null,
            subitems
          );
        return utilitiesMenuClone;
      }
    }
  }
}

export function getUtilitiesMenu(
  userActions: string[],
  isCEManageCarouselsFeatureFlagEnabled: boolean,
  isManageAnnouncementsFeatureFlagEnabled: boolean,
  isShowAssetsFeatureFlagEnabled: boolean,
  isManageProductCodesFeatureFlagEnabled: boolean): KeheNavigationItem {

  // Read resource actions
  const doesUserHaveCarouselManagementReadResourceAction = userActions?.includes(Constants.UserActions.CarouselManagementRead);
  const doesUserHaveDistributionCenterReadResourceAction = userActions?.includes(Constants.UserActions.DistributionCenterRead);
  const doesUserHaveSupplierMatrixReadResourceAction = userActions?.includes(Constants.UserActions.SupplierMatrixRead);
  const doesUserHaveRepListAdminReadResourceAction = userActions?.includes(Constants.UserActions.RepListAdminRead);
  const doesUserHaveSalesBusinessHierarchyReadResourceAction = userActions?.includes(Constants.UserActions.SalesHierarchyRead);
  const doesUserHaveRetailerHierarchyReadResourceAction = userActions?.includes(Constants.UserActions.RetailerHierarchyRead);
  const doesUserHavePhysicalLocationHierarchyReadResourceAction = userActions?.includes(Constants.UserActions.PhysicalLocationHierarchyRead);
  const doesUserHaveInfraHierarchyReadResourceAction = userActions?.includes(Constants.UserActions.InfraHierarchyRead);
  const doesUserHaveFreshHierarchyReadResourceAction = userActions?.includes(Constants.UserActions.FreshHierarchyRead);

  // Write resource actions
  const doesUserHaveEnterpriseAnnouncementWriteResourceAction = userActions?.includes(Constants.UserActions.EnterpriseAnnouncementWrite);
  const doesUserHaveSupplierAnnouncementWriteResourceAction = userActions?.includes(Constants.UserActions.SupplierAnnouncementWrite);
  const doesUserHaveRetailerAnnouncementWriteResourceAction = userActions?.includes(Constants.UserActions.RetailerAnnouncementWrite);

  // Other resource actions
  const doesUserHaveEnterpriseSiteAccessResourceAction = userActions?.includes(Constants.UserActions.EnterpriseSiteAccess);
  const doesUserHaveResourceAdminResourceAction = userActions?.includes(Constants.UserActions.ResourceAdmin);


  let utilitiesMenu: KeheNavigationItem = null;

  const utilitiesSubitems: Array<KeheNavigationItem> = [];
  // Utilities DC
  if (doesUserHaveDistributionCenterReadResourceAction) {
    utilitiesSubitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.DistributionCenter,
        '/utilities-dc',
        { exact: false }
      )
    );
  }

  if (isManageProductCodesFeatureFlagEnabled && doesUserHaveEnterpriseSiteAccessResourceAction) {
    utilitiesSubitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ManageProductCodes,
        '/code-tables/product',
        { exact: false }
      )
    );
  }

  if (userActions?.includes(Constants.UserActions.SupplierMassUpdateZipTransit)) {
    utilitiesSubitems.push(
      new KeheNavigationItem(Constants.SubMenu.ManageTransitLanes, '/transitlanes', { exact: false })
    );
  }

  if (userActions?.includes(Constants.UserActions.CustomerDeactivate)) {
    utilitiesSubitems.push(
      new KeheNavigationItem(Constants.SubMenu.ManageCustomerDeactivationConfigs, '/manage-cust-deact-config', { exact: false })
    );
  }

  if (userActions?.includes(Constants.UserActions.KsolveBottleTax)) {
    utilitiesSubitems.push(
      new KeheNavigationItem(Constants.SubMenu.BottleTax, '/bottle-tax', { exact: false })
    );
  }

  // Manage Announcements
  if (
    isManageAnnouncementsFeatureFlagEnabled &&
    doesUserHaveEnterpriseSiteAccessResourceAction &&
    (
      doesUserHaveEnterpriseAnnouncementWriteResourceAction ||
      doesUserHaveSupplierAnnouncementWriteResourceAction ||
      doesUserHaveRetailerAnnouncementWriteResourceAction
    )
  ) {
    utilitiesSubitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ManageAnnouncements,
        '/announcements',
        { exact: false }
      ),
    );
  }

  // Manage Data

  if (doesUserHaveEnterpriseSiteAccessResourceAction) {
    utilitiesSubitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.Product,
        '/manage-data/product',
        { exact: false }
      )
    );
  }

  // Supplier Notification Matrix
  if (doesUserHaveSupplierMatrixReadResourceAction) {
    utilitiesSubitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ManageSupplierMatrix,
        '/matrix',
        { exact: true }
      )
    );
  }

   // Rep List admin
   if (doesUserHaveRepListAdminReadResourceAction) {
    utilitiesSubitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ManageAccountRepAdmin,
        '/utilities-replist',
        { exact: true }
      )
    );
  }

  if (doesUserHaveResourceAdminResourceAction) {
    utilitiesSubitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ManageResources,
        '/resources/',
        { exact: true }
      )
    );
  }
  if (isShowAssetsFeatureFlagEnabled) {
    utilitiesSubitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ShowAssetManager,
        '/asset-manager',
        { exact: true }
      )
    );
  }

  if (utilitiesSubitems.length > 0) {
    utilitiesMenu = new KeheNavigationItem(
      Constants.Menu.Utilities,
      '/',
      {},
      null,
      utilitiesSubitems
    );
  }

  // Submenu Items for Marketing
  const marketingMenuUpdate = getUtilitiesMarketingMenu(utilitiesMenu, isCEManageCarouselsFeatureFlagEnabled, doesUserHaveCarouselManagementReadResourceAction);
  if (marketingMenuUpdate) {
    utilitiesMenu = marketingMenuUpdate;
  }

  // Submenu Items for Hierarchies
  const hierarchiesMenuUpdate = getUtilitiesHierarchiesMenu(
    utilitiesMenu,
    doesUserHaveSalesBusinessHierarchyReadResourceAction,
    doesUserHaveRetailerHierarchyReadResourceAction,
    doesUserHavePhysicalLocationHierarchyReadResourceAction,
    doesUserHaveInfraHierarchyReadResourceAction,
    doesUserHaveFreshHierarchyReadResourceAction,
  );
  if (hierarchiesMenuUpdate) {
    utilitiesMenu = hierarchiesMenuUpdate;
  }

  return utilitiesMenu;
}

export function getUsersMenu(userActions: string[]): KeheNavigationItem {
  const doesUserHaveUserReadResourceAction = userActions?.includes(Constants.UserActions.UserRead);
  let usersMenu: KeheNavigationItem = null;

  if (doesUserHaveUserReadResourceAction) {
    const usersSubitems: KeheNavigationItem[] = [
      new KeheNavigationItem(Constants.SubMenu.ManageUsers, '/users'),
      new KeheNavigationItem(Constants.SubMenu.ManageGroups, '/groups'),
    ];
    usersMenu = new KeheNavigationItem(
      Constants.Menu.Users,
      '/',
      {},
      null,
      usersSubitems
    );
  }

  return usersMenu;
}

function getV1LegacySimpleMenuItems(subitems: KeheNavigationItem[], groups: number[]) {
  if (groups.includes(environment.v1GroupIDs.orderMgmt2) || groups.includes(environment.v1GroupIDs.orderMgmtAdmin)) {
    subitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.AOMV1,
        `${environment.v1ConnectHost}/ordermgmt2`,
        { exact: true }
      )
    );
  }

  if (groups.includes(environment.v1GroupIDs.cm) || groups.includes(environment.v1GroupIDs.orderMgmtAdmin)) {
    subitems.push(new KeheNavigationItem(
      Constants.SubMenu.ProductsAomV1Workflow,
      environment.aomWorkflowUrl,
      { exact: true }
    ));
  }

  if (groups.includes(environment.v1GroupIDs.sales)) {
    subitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.Commissions,
        `${environment.v1SalesOperationsHost}/#/commissions/`,
        { exact: true },
      )
    );
  }

  if (groups.includes(environment.v1GroupIDs.keheEmployee)) {
    subitems.push(new KeheNavigationItem(Constants.SubMenu.ItemSearch, `${environment.v1ConnectHost}/itemmanagement`))
  }

  if (groups.includes(environment.v1GroupIDs.pricingAdmin)) {
    subitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ProductsPricingAdmin,
        `${environment.v1ConnectHost}/pricingmanagement/#/admin/landing-page`,
        { exact: true }
      )
    );
  }

  if (groups.some(g => g === environment.v1GroupIDs.promoTeamAdmin || g === environment.v1GroupIDs.marketingAdmin)) {
    subitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ProductsPromotionCalendar,
        `${environment.promotionsUrl}/#/calendar`,
        { exact: true }
      )
    );
    subitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ProductsPublicationsCalendar,
        `${environment.promotionsUrl}/#/publication_calendar/view`,
        { exact: true }
      )
    );
  }

  if (groups.includes(environment.v1GroupIDs.sales)) {
    subitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.SalesPerformance,
        `${environment.v1SalesOperationsHost}/#/sales_performance`,
        { exact: true },
      )
    );
  }
  if (groups.includes(environment.v1GroupIDs.keheEmployee)) {
    subitems.push(new KeheNavigationItem(Constants.SubMenu.VendorManagement, `${environment.v1ConnectHost}/vendormanagement/`))
  }
  if (groups.includes(environment.v1GroupIDs.vmAdmin) || groups.includes(environment.v1GroupIDs.vmCoordinator)) {
    subitems.push(new KeheNavigationItem(Constants.SubMenu.VendorManagementDocument, `${environment.v1ConnectHost}/vendormanagement/#/documentQueue`))
  }
}

export function getV1LegacyMenu(userGroups: UserDetailGroupRecord[]): KeheNavigationItem {
  if (!userGroups) {
    return null;
  }

  const subitems: KeheNavigationItem[] = [];

  const groups = userGroups.map(u => u.groupId);
  const reports = [
    environment.v1GroupIDs.aomReports,
    environment.v1GroupIDs.customerCareAdmin,
    environment.v1GroupIDs.employeeReportsEmployee,
    environment.v1GroupIDs.financeReportsEmployee,
    environment.v1GroupIDs.gbbReports,
    environment.v1GroupIDs.edsReports,
    environment.v1GroupIDs.itReportsEmployee,
    environment.v1GroupIDs.marketingReportsEmployee,
    environment.v1GroupIDs.publicationReports,
    environment.v1GroupIDs.salesManagerReportsEmployee,
    environment.v1GroupIDs.salesReportsEmployee,
    environment.v1GroupIDs.supplyChainReportsEmployee,
    environment.v1GroupIDs.transportationReportsEmployee,
    environment.v1GroupIDs.vendorManagementReportsEmployee,
  ];
  const promotions = [
    environment.v1GroupIDs.cm,
    environment.v1GroupIDs.cmAdmin,
    environment.v1GroupIDs.marketing,
    environment.v1GroupIDs.marketingAdmin,
    environment.v1GroupIDs.promoTeam,
    environment.v1GroupIDs.promoTeamAdmin,
    environment.v1GroupIDs.vendorManager,
    environment.v1GroupIDs.vendorPerformance
  ];
  const credits = [
    environment.v1GroupIDs.cm,
    environment.v1GroupIDs.kSolveUDR,
    environment.v1GroupIDs.vendorPerformance,
    environment.v1GroupIDs.vmAdmin,
    environment.v1GroupIDs.vmCoordinator,
    environment.v1GroupIDs.accountsPayable,
  ];
  const usermanagement = [
    environment.v1GroupIDs.vmAdmin,
    environment.v1GroupIDs.vmCoordinator,
    environment.v1GroupIDs.customerCare,
    environment.v1GroupIDs.customerAdmin,
    environment.v1GroupIDs.vendorAdmin,
    environment.v1GroupIDs.brokerAdmin,
    environment.v1GroupIDs.it,
    environment.v1GroupIDs.impersonateUser,
  ];
  const discontinued = [
    environment.v1GroupIDs.cm,
    environment.v1GroupIDs.cem,
    environment.v1GroupIDs.bem,
  ];
  const pricing = [
    environment.v1GroupIDs.pricingAdmin,
    environment.v1GroupIDs.pricingAnalyst,
  ];

  getV1LegacySimpleMenuItems(subitems, groups);

  if (groups.some(g => discontinued.includes(g))) {
    subitems.push(new KeheNavigationItem(
      Constants.SubMenu.ProductsDiscontinuedProducts,
      environment.discontinuedProductsUrl,
      { exact: true }
    ));
  }

  if (groups.some(g => credits.includes(g))) {
    subitems.push(new KeheNavigationItem(
      Constants.SubMenu.KSolveV1,
      environment.ksolveUrl,
      { exact: true },
    ));
    if (groups.includes(environment.v1GroupIDs.vendorPerformance)) {
      subitems.push(new KeheNavigationItem(
        `${Constants.SubMenu.KSolveV1} Admin`,
        `${environment.ksolveUrl}/#/validation-page`,
        { exact: true },
      ));
    }
    if (groups.includes(environment.v1GroupIDs.kSolveUDR)) {
      subitems.push(new KeheNavigationItem(
        `${Constants.SubMenu.KSolveV1} UDR Documents`,
        `${environment.ksolveUrl}/#/udr-search`,
        { exact: true },
      ));
    }
  }

  if (groups.some(g => pricing.includes(g))) {
    subitems.push(new KeheNavigationItem(
      Constants.SubMenu.ProductsPricingManagement,
      `${environment.v1ConnectHost}/pricingmanagement`,
      { exact: true }
    ));
  }
  if (groups.some(g => promotions.includes(g))) {
    subitems.push(
      new KeheNavigationItem(
        Constants.SubMenu.ProductsPromotions,
        environment.promotionsUrl,
        { exact: true }
      )
    )
  }

  if (groups.some(g => reports.includes(g))) {
    subitems.push(new KeheNavigationItem(Constants.SubMenu.LegacyReports, environment.v1Reports))
  }

  if (groups.some(g => usermanagement.includes(g))) {
    subitems.push(new KeheNavigationItem(Constants.SubMenu.UserManagement, environment.v1UserManagement))
  }

  if (subitems.length < 1) {
    return null;
  }

  subitems.sort((a, b) => a.display.localeCompare(b.display));

  return new KeheNavigationItem(
    Constants.Menu.Legacy,
    '/legacy',
    {},
    null,
    subitems
  )
}
