import { Address } from '../../../models/address';
import { Contact, ContactType } from '../../../models/contact';

export function formattedAddressForGrid(contact: Contact): string {
  if (contact && contact.address) {
    const address = contact.address;
    let addressParts = [];
    addressParts.push(address.street1);
    addressParts.push(address.street2);
    if (address.state) {
      addressParts.push(`${address.city}, ${address.state.code} ${address.zipCode}`);
    } else {
      addressParts.push(`${address.city}`);
      addressParts.push(`${address.province} ${address.zipCode}`);
    }
    addressParts = addressParts.filter(a => a !== null && a !== undefined && a.length > 0);
    return addressParts.join('\n');
  }
  return null;
}

export function formattedAddress(contact: Contact): string {
  if (contact?.address) {
    const address = contact.address;
    let addressParts = [];
    if (contact.contactType === ContactType.RemittanceAddress || contact.contactType === ContactType.PickupAddress || contact.contactType === ContactType.DeliveredAddress) {
      addressParts.push(address.name);
    }
    addressParts.push(address?.country?.name ?? '');
    addressParts.push(address?.attention ?? '');
    addressParts.push(address?.street1 ?? '');
    addressParts.push(address?.street2 ?? '');
    if (address?.state) {
      addressParts.push(`${address.city}, ${address.state.code} ${address.zipCode}`);
    } else {
      addressParts.push(`${address?.city ?? ''}`);
      addressParts.push(`${address?.province ?? ''} ${address?.zipCode ?? ''}`);
    }
    addressParts = addressParts.filter(a => a !== null && a !== undefined && a.length > 0);
    return addressParts.join('\n');
  }
  return null;
}


export function formattedApGridAddress(address: Address): string {
  let addressParts = [];
  addressParts.push(address.street1);
  addressParts.push(`${address.city},`);
  if (address.state) {
    addressParts.push(`${address.state.code}`);
    addressParts.push(`${address.zipCode}`);
  } else {
    addressParts.push(`${address.province}`);
    addressParts.push(`${address.zipCode}`);
    addressParts.push(address.country.name);
  }
  addressParts = addressParts.filter(a => a !== null && a !== undefined && a.length > 0);
  return addressParts.join(' ');
}

export function formattedContact(contact: Contact): string {
    let contactParts = [];
    contactParts.push(contact.name);
    if (contact.phone) {
      if (contact.phoneExtension) {
        contactParts.push(`P: ${contact.phone} EXT: ${contact.phoneExtension}`);
      } else {
        contactParts.push(`P: ${contact.phone}`);
      }
    }
    if (contact.fax) {
      contactParts.push(`F: ${contact.fax}`);
    }
    if (contact.email) {
      contactParts.push(`E: ${contact.email}`);
    }
    contactParts = contactParts.filter(a => a !== null && a !== undefined && a.length > 0);
    if (contactParts.length > 0) {
      return contactParts.join('\n');
    } else {
        return null;
    }
}
