import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddressService, AddressValidationResult, SelectedAddressType } from '@kehe/phoenix-address';
import { ContactFormService } from '@kehe/phoenix-contact';
import { environment } from '../../../../environments/environment';
import { Contact, ContactType } from '../../../models/contact';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { stringPatternValidator } from '../../../utils/form-utils';
import { Destroyable } from '../../../abstract/destroyable';

import * as uuid from 'uuid';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-contact-book-modal',
  templateUrl: './contact-book-modal.component.html',
  styleUrls: ['./contact-book-modal.component.scss']
})
export class ContactBookModalComponent extends Destroyable implements OnInit {
  alertTypes = AlertTypes;

  @Output() closeModal = new EventEmitter<void>();
  @Output() selectedMyContact = new EventEmitter<Contact>();
  @Output() smartyStreetsUseAddress = new EventEmitter<Contact>();
  @Output() smartyStreetsEditAddress = new EventEmitter<void>();
  @Input() defaultContactType: ContactType;
  @Input() contacts: Contact[] = [];
  @Input() isInProgress = false;
  @Input() saveContactError: string;
  @Input() alertMessage?: string;

  isNewContact = false;
  newContactForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  contactForm: UntypedFormGroup;
  contactTypes = ContactType;
  myContacts: { title, subTitle, id }[]  = [];
  selectedContact: Contact;
  validationResponse: AddressValidationResult;
  phoneExtPattern: RegExp;
  emailPattern: RegExp;
  selectedAddressType: SelectedAddressType;

  get disableAction() {
    if (this.isInProgress) {
      return true;
    }
    if (this.isNewContact) {
      return this.newContactForm.invalid;
    } else {
      return this.selectedContact === null || this.selectedContact === undefined;
    }
  }

  constructor(
    private _addressService: AddressService,
    private _contactFormService: ContactFormService,
    private _formBuilder: UntypedFormBuilder,
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this.initializeForm();
    if (this.defaultContactType) {
      this.myContacts = this.getMyContacts(this.contacts.filter(item => item.contactType === this.defaultContactType));
    } else {
      this.myContacts = this.getMyContacts(this.contacts);
    }
  }

  private getMyContacts(contacts: Contact[]) {
    return contacts.map(c => {
      const addressParts = [];
      if (c.address) {
        if (c.address.street1) {
          addressParts.push(c.address.street1);
        }
        if (c.address.street2) {
          addressParts.push(c.address.street2);
        }
        if (c.address.city) {
          addressParts.push(c.address.city);
        }
        if (c.address.state) {
          addressParts.push(`${c.address.state.code} ${c.address.zipCode}`);
        } else {
          addressParts.push(`${c.address.province} ${c.address.zipCode}`);
        }
      }
      const formattedAddress = addressParts.join(', ');
      return {
        title: c.address && c.address.name ? c.address.name : '',
        subTitle: formattedAddress,
        id: c.uniqueId
      };
    });
  }

  initializeForm() {
    this.emailPattern = this._contactFormService.getEmailRegex();
    this.phoneExtPattern = this._contactFormService.getPhoneExtRegex();
    this.addressForm = this._addressService.createDefaultAddressFormGroup();
    if (this.defaultContactType === ContactType.HeadquarterAddress) {
      this.addressForm.get('name').disable();
    }
    if (this.defaultContactType === ContactType.PickupAddress) {
      this.addressForm.get('street2').disable();
    }
    if (this.defaultContactType === ContactType.DeliveredAddress) {
      this.addressForm.get('street2').disable();
    }
    this.addressForm.get('state').setValidators([Validators.required]);
    this.addressForm.valueChanges.subscribe(val => {
      const isInternational = this._addressService.isInternational(val);
      if (isInternational) {
        this.addressForm.get('state').setValidators(null);
        this.addressForm.get('state').setErrors(null);
        this.addressForm.get('province').setValidators([Validators.required]);
      } else {
        this.addressForm.get('state').setValidators([Validators.required]);
        this.addressForm.get('province').setValidators(null);
        this.addressForm.get('province').setErrors(null);
      }
    });
    this.contactForm = this._formBuilder.group({
      name: ['', [Validators.maxLength(100)]],
      phone: ['', this.defaultContactType === ContactType.PickupAddress || this.defaultContactType === ContactType.DeliveredAddress ? [Validators.required] : []],
      phoneExtension: [
        '',
        [
          Validators.maxLength(4),
          stringPatternValidator(this._contactFormService.getPhoneExtRegex(), false),
        ],
      ],
      fax: [''],
      email: [
        '',
        [
          stringPatternValidator(this._contactFormService.getEmailRegex(), false),
          Validators.maxLength(100),
        ],
      ],
    });
    this.newContactForm = this._formBuilder.group({
      contactType: [this.defaultContactType, [Validators.required]],
      address: this.addressForm,
      contact: this.contactForm,
      pickupAddressNotes: [''],
    });
  }

  onCloseModal() {
    this.selectedAddressType = null;
    this.validationResponse = null;
    this.saveContactError = null;
    this.closeModal.emit();
  }

  onSelectedMyContact() {
    this.saveContactError = null;
    this.isNewContact = false;
  }

  onSelectedAddContact() {
    this.saveContactError = null;
    this.isNewContact = true;
  }

  onAddClicked() {
    if (this.isNewContact) {
      this.validateAddress();
    } else {
      this.selectedMyContact.emit(this.selectedContact);
    }
  }

  addressValidationSuccess() {
    return this.validationResponse && this.validationResponse.isValid;
  }

  showValidationDetails() {
    return this.validationResponse;
  }

  onMyContactChanged(value: any) {
    this.selectedContact = this.contacts.find(item => item.uniqueId === value.id);
  }

  smartyStreetsSelectedAddressChanged($event: SelectedAddressType) {
    this.selectedAddressType = $event;
  }

  onEditAddressClicked() {
    this.selectedAddressType = null;
    this.validationResponse = null;
    this.saveContactError = null;
    this.smartyStreetsEditAddress.emit();
  }

  useAddress() {
    this.saveContactError = null;
    const contact = {...this.newContactForm.value, ...this.contactForm.value};
    delete contact.contact;
    if (this.selectedAddressType && this.selectedAddressType === SelectedAddressType.Suggested) {
      contact.address = this.validationResponse.suggestedAddress;
    } else {
      contact.address = this.addressForm.value;
    }
    if (this.defaultContactType === ContactType.PickupAddress) {
      contact.notes = this.newContactForm.value.pickupAddressNotes;
    }
    if (this.defaultContactType === ContactType.DeliveredAddress) {
      contact.notes = this.newContactForm.value.pickupAddressNotes;
    }
    contact.uniqueId = uuid.v4();
    this.smartyStreetsUseAddress.emit(contact);
  }

  getValues(obj: any) {
    return Object.values(obj);
  }

  private validateAddress() {
    this.isInProgress = true;
    this.selectedAddressType = null;
    this.validationResponse = null;
    this.saveContactError = null;
    this._addressService.validateAddress(this.addressForm.value, environment.smartyStreetsKey)
    .subscribe(
      result => {
        this.validationResponse = result;
        this.isInProgress = false;
      },
      error => {
        this.selectedAddressType = SelectedAddressType.User;
        this.validationResponse = null;
        this.isInProgress = false;
        this.useAddress();
      }
    );

  }
}
