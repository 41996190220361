import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IAnnouncementsRecentLiveCount } from '../interfaces/announcements-recent-live-count.interface';
import { IRecentAnnouncementsState } from '../interfaces/recent-announcements-state.interface';
import { announcementsRecentFeatureKey, AnnouncementsRecentState } from './announcements-recent.reducer';

export const getAnnouncementsRecentState = createFeatureSelector<AnnouncementsRecentState>(
  announcementsRecentFeatureKey
);

export const selectAnnouncementRecentLiveCount = createSelector(
  getAnnouncementsRecentState,
  (state: AnnouncementsRecentState): IAnnouncementsRecentLiveCount => state.recentLiveCount
);

export const selectRecentAnnouncementsState = createSelector(
  getAnnouncementsRecentState,
  ({
     loading,
     recentAnnouncementsError,
     recentAnnouncements
  }: AnnouncementsRecentState): IRecentAnnouncementsState => {
    return {
      loading,
      err: recentAnnouncementsError,
      list: recentAnnouncements,
    }
  }
);
