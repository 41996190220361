import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IKeheModalEvent } from '@kehe/phoenix-modal';
import { DCProductGroup } from '../../../supplier-detail/models/dc-product-group';
import { Constants } from '../../../../constants/constants';
import { PhoneNumberPipe } from '@app/shared/pipes/phoneNumber.pipe';

@Component({
  selector: 'app-product-group-po-transmissions-modal',
  templateUrl: './product-group-po-transmissions-modal.component.html',
  styleUrls: ['./product-group-po-transmissions-modal.component.scss']
})
export class ProductGroupPoTransmissionsModalComponent implements OnChanges {

  @Input() dcProductGroup: DCProductGroup;
  @Input() hideEdit = false;

  @Output() closeButtonClicked = new EventEmitter<void>();

  @Output() editButtonClicked = new EventEmitter<DCProductGroup>();

  public title: string;
  public poTransData: { type: string, value: string }[] = [];

  constructor() {
     // This is intentional
  }

  handleKeHEModalEvents($event: IKeheModalEvent) {
    if ($event.type === 'close') {
      this.closeButtonClicked.emit();
    }
  }

  edit() {
    this.editButtonClicked.emit(this.dcProductGroup);
  }

  closeModal() {
    this.closeButtonClicked.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dcProductGroup && changes.dcProductGroup.currentValue) {
      const dcPG: DCProductGroup = changes.dcProductGroup.currentValue;
      this.title = `${this.dcProductGroup.dcDisplayName} ${this.dcProductGroup.dcProductGroupNumber}`;

      if (dcPG.poFax && dcPG.poFax.fax) {
        const phonePipe = new PhoneNumberPipe();
        this.poTransData.push({
          type: dcPG.poFax.type || Constants.EmptyValue,
          value: phonePipe.transform(dcPG.poFax.fax)
        });
      }
      if (dcPG.poEmails) {
        dcPG.poEmails.forEach(item => {
          if (item.email) {
            this.poTransData.push({
              type: item.type || Constants.EmptyValue,
              value: item.email
            });
          }
        });
      }
    }
  }
}
