import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";
import { SupplierService } from "./supplier.service";
import * as SupplierDetailActions from '@app/modules/supplier-detail/store/supplier-detail.actions';
import * as SupplierListActions from '@app/modules/supplier-list/store/supplier-list.actions'
import * as SupplierApiActions from './supplier-api.actions';
import { Store } from "@ngrx/store";
import * as SupplierSelectors from '@app/shared/state/supplier/supplier.selectors';

@Injectable()
export class SupplierEffects {

    constructor(
        private actions$: Actions, 
        private store: Store,
        private supplierService: SupplierService
    ) {}

    getStatusList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                SupplierListActions.getDropdownFilterData,
                SupplierDetailActions.getFormDropdownData,
            ),
            withLatestFrom(this.store.select(SupplierSelectors.selectSupplierStatus)),
            filter(([action, list]) => !list || list.length === 0),
            exhaustMap(() => this.supplierService.getStatusList()),
            map(response => SupplierApiActions.loadStatusListSuccess({ list: response.data })),
        )
    });

    getDevelopmentManagersList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                SupplierListActions.getDropdownFilterData,
                SupplierDetailActions.getFormDropdownData,
            ),
            withLatestFrom(this.store.select(SupplierSelectors.selectSupplierDevelopmentManagerList)),
            filter(([action, list]) => !list || list.length === 0),
            exhaustMap(() => this.supplierService.getDevelopmentManagersList()),
            map(response => SupplierApiActions.loadSupplierDevelopmentManagerListSuccess({ list: response.data })),
        )
    });

    getCategoryManagersList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                SupplierListActions.getDropdownFilterData,
                SupplierDetailActions.getFormDropdownData,
            ),
            withLatestFrom(this.store.select(SupplierSelectors.selectCategoryManagerList)),
            filter(([action, list]) => !list || list.length === 0),
            exhaustMap(() => this.supplierService.getCategoryManagersList()),
            map(response => SupplierApiActions.loadCategoryManagerListSuccess({ list: response.data })),
        )
    });

}