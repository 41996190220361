import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-load-more',
  templateUrl: './load-more.component.html'
})
export class LoadMoreComponent {

  @Input()
  searching = false;

  @Input()
  data: any = null;

  @Input()
  availableCount = 0;

  @Input()
  pageIndex = 0;

  @Input()
  pageCount = 0;

  @Output()
  clicked: EventEmitter<any> = new EventEmitter();

  constructor() { 
    // This is intentional
  }

  loadMore(): void {
    this.clicked.emit();
  }

}
