import { Contact } from '../../../models/contact';
import { Action, createReducer, on } from '@ngrx/store';
import * as supplierLocationsActions from './supplier-locations.actions';

export const supplierLocationsStateKey = 'supplierLocationsState';

export interface SupplierLocationsState {
  contacts: Array<Contact>;
  headquarterAddress?: string;
  loading: boolean;
  success: boolean;
  error?: string;
}

export const initialState: SupplierLocationsState = {
  contacts: [],
  loading: false,
  success: false,
};

const rootReducer = createReducer(
  initialState,
  on(supplierLocationsActions.updateContacts, (state, { contacts, headquarterAddress }) => ({
    ...state,
    contacts,
    headquarterAddress,
    loading: false,
    success: true,
    error: undefined,
  })),
  on(supplierLocationsActions.removeContact, (state) => ({
    ...state,
    loading: true,
    success: false,
    error: undefined
  })),
  on(supplierLocationsActions.updateLocationError, (state, { error }) => ({
    ...state,
    loading: false,
    success: false,
    error
  })),
);

export function reducer(state: SupplierLocationsState, action: Action) {
  return rootReducer(state, action);
}
