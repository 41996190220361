import { createAction, props } from '@ngrx/store';
import { Document } from '../../../supplier-documents/models/document';

export const brokerAddButtonClicked = createAction(
  '[SUPPLIER DETAIL PAGE - BROKER V2] Add Button Clicked'
);

export const brokerV2IsValidChanged = createAction(
  '[SUPPLIER DETAIL PAGE - BROKER V2] Is Tab Valid Changed'
);

export const deleteBrokerV2DocumentSuccess = createAction(
  '[SUPPLIER DETAIL PAGE - BROKER V2] Delete Document from Broker V2',
  props<{ document: Document }>()
);
