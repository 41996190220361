import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectExportHierarchiesModalViewModel } from '../../store/selectors/export-hierarchies-modal.selectors';
import * as ExportHierarchiesModalActions from '../../store/effects/actions';
import { IKeheModalEvent, KeheModalEventsTypes } from '@kehe/phoenix-modal';

@Component({
  selector: 'app-export-hierachies-modal',
  templateUrl: './export-hierachies-modal.component.html'
})
export class ExportHierachiesModalComponent {
  viewModel$ = this.store.select(selectExportHierarchiesModalViewModel);

  constructor(private store: Store) { }

  handleModalEvent(event: IKeheModalEvent) {
    if (event.type === KeheModalEventsTypes.Close) {
      this.store.dispatch(ExportHierarchiesModalActions.closeClicked());
    }
  }

  cancelModal() {
    this.store.dispatch(ExportHierarchiesModalActions.closeClicked());
  }

  exportClicked() {
    this.store.dispatch(ExportHierarchiesModalActions.exportClicked());
  }
}
