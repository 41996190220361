<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.featureFlag">
    <div class="col-12 col-md-5 mt-3">
      <kendo-label class="row" text="Delivery Address (Optional)"></kendo-label>
      <div class="row">
        <div *ngIf="!hasAddress" class="kehe-card-action d-flex flex-column align-items-center mt-1 address-container">
          <em class="fas fa-address-book address-icon mb-2" aria-hidden="true"></em>
          <kehe-phoenix-button name="addAddress" classAttr="btn-link btn-medium" iconClassAttr="fa fa-plus"
          (click)="addAddressClicked()">
            Add Address
          </kehe-phoenix-button>
        </div>
        <div *ngIf="hasAddress" class="kehe-card-action d-flex flex-column p-3 mt-1 address-container">
          <div>
            <kendo-label text="Address"></kendo-label>
            <div class="address-content">{{ getAddressText(addressContact) }}</div>
            <div class="mt-2" *ngIf="getContactText(addressContact) !== null">
              <kendo-label text="Contact Information"></kendo-label>
              <div class="address-content">{{ getContactText(addressContact) }}</div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-end mt-1">
            <kehe-phoenix-button name="edit-address" classAttr="btn-link btn-modal btn-small"
              class="mr-2"
              (click)="handleAddressEdit()">
              Edit
            </kehe-phoenix-button>
            <kehe-phoenix-button name="remove-address" classAttr="btn-secondary btn-delete btn-modal btn-small"
              (click)="handleAddressRemove()">
              Remove
            </kehe-phoenix-button>
          </div>
        </div>
      </div>
    </div>

    <app-add-delivered-contact *ngIf="vm.isAddressModalOpen" [parent]="parent" (handleClose)="closeModal()"></app-add-delivered-contact>
  </ng-container>
</ng-container>
