import { Address } from '@app/models/address';
import { PhoneNumberPipe } from '@app/shared/pipes/phoneNumber.pipe';


export function formatChangesAsString(changedValue: any): string {
  const camelCase = convertToCamelCase(changedValue);
  if (isAddress(camelCase)) {
    return formatAddressAsString(camelCase);
  }
}

function convertToCamelCase(json: any): any {

  return JSON.parse(JSON.stringify(json), toCamelCase);

  function toCamelCase(key, value) {
    if (value && typeof value === 'object') {
      for (const k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }
}

function formatAddressAsString(address: Address): string {
  let addressString = '';

  addressString += address.name ? `${address.name} | ` : '';

  addressString += address.attention ? `${address.attention} | ` : '';

  if (isDomestic(address)) {
    addressString += `${address.street1} ${address.street2} ${address.city}, `
      + `${!address.state ? '' : address.state.code} ${address.zipCode}`;
  } else {
    addressString += `${address.street1} ${address.street2} ${address.city}, ${address.province} ${address.zipCode}`;
  }

  addressString += address.country ? ` ${address.country.code}` : '';

  const phonePipe = new PhoneNumberPipe();
  const phone = phonePipe.transform(address.phone) + `${!address.extension ? '' : ', ' + address.extension}`;
  const fax = phonePipe.transform(address.fax);

  addressString += address.phone ? ` | P: ${phone}` : '';
  addressString += address.fax ? ` | F: ${fax}` : '';
  addressString += address.email ? ` | E: ${address.email}` : '';

  return addressString;

  function isDomestic(a): boolean {
    return !a.country
      || a.country.code === 'US'
      || a.country.code === 'USA';
  }
}

function isAddress(toBeDetermined: any): toBeDetermined is Address {

  return toBeDetermined !== undefined &&
    toBeDetermined.city !== undefined
    && toBeDetermined.country !== undefined
    && (toBeDetermined.state !== undefined || toBeDetermined.province !== undefined)
    && (toBeDetermined.street1 !== undefined || toBeDetermined.street2 !== undefined)
    && toBeDetermined.zipCode !== undefined;
}
