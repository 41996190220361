import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payload } from '../../../models/payload';
import { CodeValue } from '../.../../models/code-value';
import { environment } from '../../../../environments/environment';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SupplierCodeValueService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = `${environment.supplierApi}/api/code-value-pairs`;
  }

  get(identifier: string): Observable<Payload<Array<CodeValue>>> {
    return this.httpClient.get<Payload<Array<CodeValue>>>
      (`${this._url}/${identifier}`).pipe(retry(1));
  }

}
