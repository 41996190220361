import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class StatusService {

  private _url: string;

  constructor(
    private _httpClient: HttpClient
  ) {
    this._url = environment.supplierApi;
  }

  getStatuses(): Observable<string[]> {
    return this._httpClient
      .get<{ availableCount: number; data: string[] }>(
        `${this._url}/api/statuses`
      )
      .pipe(map((response) => response.data))
      .pipe(retry(2));
  }

}
