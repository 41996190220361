import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { startWith, tap } from 'rxjs/operators';
import { BrokerType } from './add-broker-v2-modal.store';
import { Store } from '@ngrx/store';

export enum BrokerFormControlName {
  BrokerType = 'brokerType',
  HeadquarterBroker = 'headquarterBroker',
  HeadquarterContact = 'headquarterContact',
  nonHeadquarterBroker = 'nonHeadquarterBroker',
}

export interface AddBrokerFormState {
  skipByDefault: boolean;
}
export const initialState: AddBrokerFormState = {
  skipByDefault: false,
};

@Injectable()
export class AddBrokerFormStore extends ComponentStore<AddBrokerFormState> {
  public form = new UntypedFormGroup({
    [BrokerFormControlName.BrokerType]: new UntypedFormControl(
      '',
      Validators.required
    ),
    [BrokerFormControlName.HeadquarterBroker]: new UntypedFormControl(
      '',
      brokerValidator
    ),
    [BrokerFormControlName.HeadquarterContact]: new UntypedFormControl(''),
    [BrokerFormControlName.nonHeadquarterBroker]: new UntypedFormControl(
      '',
      brokerValidator
    ),
  });
  public formControls = getBrokerFormControls(this.form);
  public isFormValid$ = this.select(
    this.form.statusChanges.pipe(startWith('')),
    (formStatus) => {
      return formStatus === 'VALID';
    }
  );

  public formStatusChanges$ = this.form.statusChanges.pipe(startWith(''));
  public brokerTypeValueChanges$ =
    this.formControls.brokerTypeControl.valueChanges.pipe(startWith(''));
  public headquarterBrokerValueChanges$ =
    this.formControls.headquarterBrokerControl.valueChanges.pipe(startWith(''));
  public headquarterContactValueChanges$ =
    this.formControls.headquarterContactControl.valueChanges.pipe(
      startWith('')
    );
  public nonHeadquarterBrokerValueChanges$ =
    this.formControls.nonHeadquarterBrokerControl.valueChanges.pipe(
      startWith('')
    );

  public isHeadquarterBroker$ = this.select(
    this.brokerTypeValueChanges$,
    (brokerType) => {
      return brokerType === BrokerType.Headquarter;
    }
  );
  public isNonHeadquarterBroker$ = this.select(
    this.brokerTypeValueChanges$,
    (brokerType) => {
      return brokerType === BrokerType.NonHeadquarter;
    }
  );
  public isDoesNotHaveBroker$ = this.select(
    this.brokerTypeValueChanges$,
    (brokerType) => {
      return brokerType === BrokerType.DoesNotHaveABroker;
    }
  );

  constructor(private _store: Store) {
    super(initialState);
    this.handleBrokerTypeChanges$(this.brokerTypeValueChanges$);
    this.handleHeadquarterBrokerControl$(this.headquarterBrokerValueChanges$);
    this.handleNonHeadquarterBrokerControl$(
      this.nonHeadquarterBrokerValueChanges$
    );
    this.intializeSkipByDefault$.subscribe();
  }

  handleBrokerTypeChanges$ = this.effect((valueChanges$) =>
    valueChanges$.pipe(
      tap(() => {
        this.formControls.headquarterBrokerControl.reset(null);
        this.formControls.nonHeadquarterBrokerControl.reset(null);
      })
    )
  );
  handleHeadquarterBrokerControl$ = this.effect((valueChanges$) =>
    valueChanges$.pipe(
      tap((broker) => {
        if (broker) {
          this.formControls.headquarterContactControl.updateValueAndValidity({
            emitEvent: false,
          });
        } else {
          this.formControls.headquarterContactControl.reset(null);
        }
        this.formControls.nonHeadquarterBrokerControl.updateValueAndValidity({
          emitEvent: false,
        });
      })
    )
  );
  handleNonHeadquarterBrokerControl$ = this.effect((valueChanges$) =>
    valueChanges$.pipe(
      tap(() => {
        this.formControls.headquarterBrokerControl.updateValueAndValidity({
          emitEvent: false,
        });
      })
    )
  );

  public intializeSkipByDefault$ = this.select(
    (state) => state.skipByDefault
  ).pipe(
    tap((skipByDefault) => {
      if (skipByDefault) {
        const brokerTypeControl = this.form.get(
          BrokerFormControlName.BrokerType
        );

        brokerTypeControl.patchValue(BrokerType.DoesNotHaveABroker);
      }
    })
  );
}

function brokerValidator(control: AbstractControl) {
  const formGroup = control.parent as UntypedFormGroup;
  const {
    brokerTypeControl,
    headquarterBrokerControl,
    nonHeadquarterBrokerControl,
  } = getBrokerFormControls(formGroup);
  if (formGroup) {
    if (
      brokerTypeControl?.value !== BrokerType.DoesNotHaveABroker &&
      ((brokerTypeControl?.value === BrokerType.Headquarter &&
        !headquarterBrokerControl.value) ||
        (brokerTypeControl?.value === BrokerType.NonHeadquarter &&
          !nonHeadquarterBrokerControl.value))
    ) {
      return Validators.required(control);
    }
  }
  return null;
}

function getBrokerFormControls(formGroup: UntypedFormGroup) {
  if (!formGroup) {
    return {};
  }
  return {
    brokerTypeControl: formGroup.get(BrokerFormControlName.BrokerType),
    headquarterBrokerControl: formGroup.get(
      BrokerFormControlName.HeadquarterBroker
    ),
    headquarterContactControl: formGroup.get(
      BrokerFormControlName.HeadquarterContact
    ),
    nonHeadquarterBrokerControl: formGroup.get(
      BrokerFormControlName.nonHeadquarterBroker
    ),
  };
}


