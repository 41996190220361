import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeatureFlags } from '@app/feature-flag';

export interface DeliveredAddressState {
  isAddressModalOpen: boolean;
  isEditAddressMode: boolean;
}

export const initialState: DeliveredAddressState = {
  isAddressModalOpen: false,
  isEditAddressMode: false,
};

@Injectable()
export class DeliveredAddressStore extends ComponentStore<DeliveredAddressState> {
  private _featureFlag$ = this._store.select(
    selectIsFeatureFlagOn(FeatureFlags.ProductGroupDeliveryAddress.key)
  );

  private _isAddressModalOpen$ = this.select(
    (state) => state.isAddressModalOpen
  );
  public isEditAddressMode$ = this.select((state) => state.isEditAddressMode);

  readonly vm$ = this.select(
    this.state$,
    this._featureFlag$,
    (state, featureFlag) => ({
      ...state,
      featureFlag,
    })
  );

  constructor(private _store: Store) {
    super(initialState);

    this.resetEditModeOnModalClose$(this._isAddressModalOpen$);
  }

  openAddressModal = this.updater((state) => ({
    ...state,
    isAddressModalOpen: true,
  }));

  closeAddressModal = this.updater((state) => ({
    ...state,
    isAddressModalOpen: false,
  }));

  editAddress = this.updater((state) => ({
    ...state,
    isAddressModalOpen: true,
    isEditAddressMode: true,
  }));

  resetEditModeOnModalClose$ = this.effect(
    (isModalOpen$: Observable<boolean>) =>
      isModalOpen$.pipe(
        tap((isModalOpen) => {
          if (!isModalOpen) {
            this.patchState({
              isEditAddressMode: false,
            });
          }
        })
      )
  );
}
