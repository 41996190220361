<kehe-phoenix-modal name="Products" [modalTitle]="title()" [show]="true" (modalEvent)="onClose()" size="lg">
  <div body>
    <div>
      <div class="row">
        <div class="col">
          <h5>Product(s)</h5>
        </div>
      </div>
      <div class="kehe-grid no-scroll w-100">
        <kendo-grid [data]="products" [sortable]="{
                      allowUnsort: false,
                      mode: 'single'
                    }" [sort]="sort" (sortChange)="sortChange($event)">

          <kendo-grid-column field="name" title="UPC / Description" filter="string" [sortable]="true" headerClass="kehe-grid-header-hover">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="link-cell" (click)="onClickUPC(dataItem);">
                {{ dataItem.upc }}
              </div>
              <div>{{ dataItem.name }}</div>
              <span *ngIf="dataItem.status" class="tag-container">
                <kehe-phoenix-tag classAttr="small" [tag]="getStatus(dataItem.status)"></kehe-phoenix-tag>
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="brandCode" title="Brand / Code" filter="string" [sortable]="true" headerClass="kehe-grid-header-hover">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div>{{ dataItem.brandName | emptyValue }}</div>
                <div>({{ dataItem.brandCode | emptyValue }})</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="tempClass" title="Temp Class" filter="string" [sortable]="true" headerClass="kehe-grid-header-hover" *ngIf="dcProductGroup">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div>{{ dataItem.tempClass | emptyValue }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="size" title="Size / UOM" filter="number" [sortable]="true" headerClass="kehe-grid-header-hover">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div>{{ dataItem.size | emptyValue }} {{ dataItem.uom | emptyValue }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="costFactor" title="Cost Factor" filter="string" [sortable]="true" headerClass="kehe-grid-header-hover" *ngIf="dcProductGroup">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div>{{ dataItem.costFactor | emptyValue }}</div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button (click)="onClose()" classAttr="btn-primary btn-medium">Close</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
