import { DCProductGroup } from '../models/dc-product-group';
import { hasProperty } from '../../../utils/common-utils';
import { mapValues } from 'lodash';

export function isDCProductGroupDelivered(dcProductGroup: DCProductGroup): boolean {
  if (dcProductGroup.deliveryMethod && 'Delivered' === dcProductGroup.deliveryMethod) {
    return true;
  }
  return dcProductGroup.deliveryMethodCode && 'Y' === dcProductGroup.deliveryMethodCode;
}

export function isDCProductGroupTransitTimeDefault(dcProductGroup: DCProductGroup): boolean {
  if (isDCProductGroupDelivered(dcProductGroup)) {
    if (dcProductGroup.deliveredLeadTimes) {
      return dcProductGroup.deliveredLeadTimes.isTransitTimeDefault;
    }
  } else {
    if (dcProductGroup.pickupLeadTimes) {
      return dcProductGroup.pickupLeadTimes.isTransitTimeDefault;
    }
  }
  return false;
}

export function isDCProductGroupTransitTimeOverridden(dcProductGroup: DCProductGroup): boolean {
  if (!isDCProductGroupDelivered(dcProductGroup)) {
    if (dcProductGroup.pickupLeadTimes) {
      return dcProductGroup.pickupLeadTimes.isTransitTimeOverridden;
    }
  }
  return false;
}

export function formatDCProductGroupResponse(dirtyValue, form, canEditPoTransmission, showContactCard, isDraftSupplier) {
  if (!dirtyValue) {
    return;
  }

  if (hasProperty(dirtyValue, 'delivery')) {
    formatDCProductGroupDelivery(dirtyValue, form, showContactCard, isDraftSupplier);
  }
  if (hasProperty(dirtyValue, 'poTransmissions')) {
    const poTransmissions = dirtyValue.poTransmissions;
    const rawPOTransmissions = form.poTransmissions;
    if (hasProperty(poTransmissions, 'poFax')) {
      dirtyValue.poFax = mapValues(rawPOTransmissions.poFax, (value) => value === undefined ? '' : value);
    }
    if (hasProperty(poTransmissions, 'poOrderMinimum')) {
      dirtyValue.poOrderMinimum = mapValues(rawPOTransmissions.poOrderMinimum, (value) => value === undefined ? '' : value);
    }
    delete dirtyValue.poTransmissions;
  }
  // We need to send whole poEmails list irrespective of if it is dirty or not.
  // Because in back-end we delete emails those not present this poEmails list
  // So we copied poEmails from form.poTransmissions but not from dirtyValue.poTransmissions
  if (form.poTransmissions && canEditPoTransmission) {
    const rowPOTransmissions = form.poTransmissions;
    if (rowPOTransmissions.poEmails) {
      dirtyValue.poEmails = rowPOTransmissions.poEmails.filter(mailItem => mailItem.email && mailItem.email.length > 0);
    }
  }
}

function formatDCProductGroupDelivery(dirtyValue, form, showContactCard, isDraftSupplier) {
  const deliveryForm = dirtyValue.delivery;
  const rowDeliveryForm = form.delivery;
  const rowPickup = form.delivery.pickup;

  if (hasProperty(deliveryForm, 'inboundRouteType')) {
    dirtyValue.inboundRouteType = deliveryForm.inboundRouteType;
  }

  if (hasProperty(deliveryForm, 'deliveryMethodCode')) {
    dirtyValue.deliveryMethodCode = deliveryForm.deliveryMethodCode;
  }
  if (hasProperty(deliveryForm, 'deliveryMethod')) {
    dirtyValue.deliveryMethod = deliveryForm.deliveryMethod;
  }

  if (hasProperty(deliveryForm, 'readyTimeAvailable')) {
    dirtyValue.readyTimeAvailable = deliveryForm.readyTimeAvailable;
  }
  if (hasProperty(deliveryForm, 'deliveredLeadTimes')) {
    dirtyValue.deliveredLeadTimes = rowDeliveryForm.deliveredLeadTimes;
    dirtyValue.readyTimeAvailable = rowDeliveryForm.readyTimeAvailable;
  }
  if (hasProperty(deliveryForm, 'pickupLeadTimes')) {
    dirtyValue.pickupLeadTimes = rowDeliveryForm.pickupLeadTimes;
    dirtyValue.readyTimeAvailable = rowDeliveryForm.readyTimeAvailable;
  }

  if (showContactCard || (isDraftSupplier && hasProperty(rowDeliveryForm, 'deliveryMethod') && rowDeliveryForm.deliveryMethod === 'Delivered')) {
    if (rowPickup && rowPickup.pickupAddressId) {
      dirtyValue.pickupAddressId = rowPickup.pickupAddressId;
    }
    if (rowPickup && rowPickup.pickupAddressId === null) {
      dirtyValue.pickupAddressId = null;
      dirtyValue.pickupAddress = emptyAddressValues();
    }
  } else {
    formatDCProductGroupDeliveryPickupAddress(dirtyValue, rowPickup);
  }

  delete dirtyValue.delivery;
}

function formatDCProductGroupDeliveryPickupAddress(dirtyValue, rowPickup) {
  if (rowPickup?.pickupAddress) {
    dirtyValue.pickupAddress = rowPickup.pickupAddress;
    dirtyValue.pickupAddress.latitude = rowPickup.pickupAddress.latitude ?
      parseFloat(rowPickup.pickupAddress.latitude) : 0;
    dirtyValue.pickupAddress.longitude = rowPickup.pickupAddress.longitude ?
      parseFloat(rowPickup.pickupAddress.longitude) : 0;
  } else {
    dirtyValue.pickupAddress = {};
  }

  if (rowPickup?.pickupPhone) {
    dirtyValue.pickupAddress.phone = rowPickup.pickupPhone;
  }
  if (rowPickup?.pickupExtension) {
    dirtyValue.pickupAddress.extension = rowPickup.pickupExtension;
  }
  if (rowPickup?.pickupFax || rowPickup?.pickupFax === '') {
    dirtyValue.pickupAddress.fax = rowPickup.pickupFax;
  }
  if (rowPickup?.pickupEmail || rowPickup?.pickupEmail === '') {
    dirtyValue.pickupAddress.email = rowPickup.pickupEmail;
  }
  if (Object.entries(dirtyValue.pickupAddress).length < 1) {
    dirtyValue.pickupAddress = undefined;
  }

  if (rowPickup?.pickupAddressNotes) {
    dirtyValue.pickupAddressNotes = rowPickup.pickupAddressNotes;
  }
}

export function formatProductGroupResponse(dirtyValue, form) {
  if (form.remittanceAddress) {
    dirtyValue.remittanceAddress = form.remittanceAddress;
    dirtyValue.remittanceAddress.latitude = form.remittanceAddress.latitude ? parseFloat(form.remittanceAddress.latitude) : 0;
    dirtyValue.remittanceAddress.longitude = form.remittanceAddress.longitude ? parseFloat(form.remittanceAddress.longitude) : 0;
  } else {
    dirtyValue.remittanceAddress = {};
  }
  dirtyValue.remittanceAddress.name = form.remitName;
  delete dirtyValue.remitName;

  dirtyValue.remittanceAddress.phone = form.remitPhone;
  delete dirtyValue.remitPhone;

  dirtyValue.remittanceAddress.extension = form.remitExtension;
  delete dirtyValue.remitExtension;

  dirtyValue.remittanceAddress.fax = form.remitFax;
  delete dirtyValue.remitFax;

  dirtyValue.remittanceAddress.email = form.remitEmail;
  delete dirtyValue.remitEmail;
}

function emptyAddressValues() {
  return {
    country: null,
    name: '',
    attention: '',
    street1: '',
    city: '',
    state: {
      code: '',
    },
    province: '',
    zipCode: '',
    latitude: '',
    longitude: '',
    phone: '',
    phoneExtension: '',
    fax: '',
    email: ''
  };
}
