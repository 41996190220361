<ng-container *ngIf="vm$ | async as vm">
  <div
    class="kehe-grid no-scroll p-0 w-100"
    [ngClass]="{ 'kehe-grid-responsive': vm.isMedAndBelowView }"
  >
    <kendo-grid
      [sortable]="{ allowUnsort: false, mode: 'single' }"
      [sort]="vm.sort"
      [hideHeader]="vm.isMedAndBelowView"
      [data]="vm.associatedSuppliersRows"
      [kendoGridExpandDetailsBy]="gridItemExpandKey"
      (sortChange)="sortChange($event)"
      [(expandedDetailKeys)]="vm.expandedEsns"
    >
      <kendo-grid-column [width]="15" [sortable]="false">
        <ng-template kendoGridHeaderTemplate let-supplierItem>
          <input
            type="checkbox"
            data-automation-id="associated-suppliers-table-checkbox-header"
            kendoCheckBox
            [checked]="vm.allChecked"
            (change)="toggleCheckAll()"
          />
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <div *ngIf="supplierItem?.name === 'sync'">
            <em class="fas fa-sync-alt fa-spin" title="Syncing"></em>
          </div>
          <div *ngIf="supplierItem?.name !== 'sync'">
            <input
              type="checkbox"
              kendoCheckBox
              data-automation-id="associated-suppliers-table-checkbox"
              [checked]="supplierItem.isSelected"
              (change)="handleRowSelection(supplierItem)"
            />
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="15"
        [sortable]="false"
        [headerClass]="'kehe-grid-header-hover'"
      >
        <ng-template kendoGridHeaderTemplate>
          <div
            *ngIf="!vm.expandAll"
            class="align-chevron"
            (click)="collapseAllAssociatedSuppliers()"
          >
            <em
              class="fas fa-chevron-up kehe-grid-expander-header"
              title="Collapse"
              data-automation-id="associated-suppliers-table-collapse-icon-header"
            ></em>
          </div>
          <div
            *ngIf="vm.expandAll"
            class="align-chevron"
            (click)="expandAllAssociatedSuppliers()"
          >
            <em
              class="fas fa-chevron-down kehe-grid-expander-header"
              title="Expand"
              data-automation-id="associated-suppliers-table-expand-icon-header"
            ></em>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <ngx-skeleton-loader
            *ngIf="supplierItem?.name === 'sync'"
            [theme]="skeleton"
          ></ngx-skeleton-loader>
          <ng-container
            *ngIf="
              supplierItem?.name !== 'sync' &&
              supplierItem?.children?.length > 0
            "
          >
            <div
              *ngIf="vm.expandedEsns?.includes(supplierItem?.esn)"
              class="align-chevron"
              (click)="collapseAssociatedSupplier(supplierItem?.esn)"
              data-automation-id="associated-suppliers-table-collapse-icon"
            >
              <em
                class="kehe-grid-expander fas fa-chevron-up"
                title="Collapse"
              ></em>
            </div>
            <div
              *ngIf="!vm.expandedEsns?.includes(supplierItem?.esn)"
              class="align-chevron"
              (click)="expandAssociatedSupplier(supplierItem?.esn)"
              data-automation-id="associated-suppliers-table-expand-icon"
            >
              <em
                class="kehe-grid-expander fas fa-chevron-down"
                title="Expand"
              ></em>
            </div>
          </ng-container>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="30"
        [locked]="false"
        [sortable]="false"
        field="esn"
      >
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-table-esn-header">ESN</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <ngx-skeleton-loader
            *ngIf="supplierItem?.name === 'sync'"
            [theme]="skeleton"
          ></ngx-skeleton-loader>
          <span>
            <div *ngIf="supplierItem?.name !== 'sync'">
              <a
                class="link-cell"
                [routerLink]="['/supplier', supplierItem.esn]"
                target="_blank"
                data-automation-id="associated-suppliers-table-esn"
                >{{ supplierItem.esn | emptyValue }}
              </a>
            </div>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="90"
        [locked]="false"
        field="name"
      >
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-table-supplier-name-header">Supplier Name</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <ngx-skeleton-loader
            *ngIf="supplierItem?.name === 'sync'"
            [theme]="skeletonBigText"
          ></ngx-skeleton-loader>
          <span *ngIf="supplierItem?.name !== 'sync'" data-automation-id="associated-suppliers-table-supplier-name-header">
            {{ supplierItem.name }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="50"
        [locked]="false"
        [sortable]="false"
        field="location"
      >
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-table-location-header">Location</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <ngx-skeleton-loader
            *ngIf="supplierItem?.name === 'sync'"
            [theme]="skeleton"
          ></ngx-skeleton-loader>
          <span *ngIf="supplierItem?.name !== 'sync'" data-automation-id="associated-suppliers-table-location">
            {{ supplierItem.location | emptyValue }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="50"
        [locked]="false"
        field="status"
      >
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-table-status-header">Status</span>
        </ng-template>
        <div class="status-tag">
          <ng-template kendoGridCellTemplate let-supplierItem>
            <ngx-skeleton-loader
              *ngIf="supplierItem?.name === 'sync'"
              [theme]="skeleton"
            ></ngx-skeleton-loader>
            <kehe-phoenix-tag
              *ngIf="supplierItem?.name !== 'sync'"
              [tag]="supplierItem.tag"
              automationId="associated-suppliers-table-status"
              classAttr="large"
            ></kehe-phoenix-tag>
          </ng-template>
        </div>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="60"
        [locked]="false"
        field="dateAssociated"
      >
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-table-date-associated-header">Date Associated</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <ngx-skeleton-loader
            *ngIf="supplierItem?.name === 'sync'"
            [theme]="skeleton"
          ></ngx-skeleton-loader>
          <span *ngIf="supplierItem?.name !== 'sync'" data-automation-id="associated-suppliers-table-date-associated">
            {{ supplierItem.dateAssociated | date : 'MM/dd/yyyy' | emptyValue }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="15" [sortable]="false">
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="associated-suppliers-table-delete-header"></span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-supplierItem>
          <div
            *ngIf="supplierItem?.name !== 'sync'"
            class="d-flex justify-content-between ml-1 mr-1"
          >
            <span
              class="fas fa-trash-alt"
              title="Remove"
              (click)="deleteRowClicked(supplierItem)"
              data-automation-id="associated-suppliers-table-delete-icon"
            >
            </span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridDetailTemplate let-supplierItem>
        <div class="expanded-view" *ngIf="supplierItem?.children?.length > 0">
          <div class="children-view">
            <app-associated-suppliers-tab-children-grid
              [associatedSuppliers]="supplierItem.children"
            >
            </app-associated-suppliers-tab-children-grid>
          </div>
        </div>
      </ng-template>

      <ng-template kendoGridNoRecordsTemplate>
        <kehe-phoenix-loading *ngIf="vm.isLoading"></kehe-phoenix-loading>
        <div class="mt-4" *ngIf="!vm.isLoading && !vm.showApiErrorPanel">
          <kehe-phoenix-empty-state [config]="vm.emptyState"></kehe-phoenix-empty-state>
        </div>
        <kehe-phoenix-error-panel *ngIf="!vm.isLoading && vm.showApiErrorPanel" [config]="notLoadingConfig">
          <ng-container body>
            <p>Please try again at a later time. If error persists, please contact us.</p>
              <kehe-phoenix-button
                classAttr="btn-medium btn-primary"
                (click)="refreshPage()"
                automationId="associated-suppliers-table-error-panel-refresh-button"
              >Refresh</kehe-phoenix-button>
          </ng-container>
        </kehe-phoenix-error-panel>
      </ng-template>
    </kendo-grid>
  </div>
</ng-container>
