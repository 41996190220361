import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierLocationsState, supplierLocationsStateKey } from './supplier-locations.reducer';

export const selectSupplierLocationsState = createFeatureSelector<SupplierLocationsState>(supplierLocationsStateKey);

export const selectSupplierLocationContacts = createSelector(
  selectSupplierLocationsState,
  (state) => state.contacts
);

export const selectHeadquarterAddress = createSelector(
  selectSupplierLocationsState,
  (state) => state.headquarterAddress
);

export const selectLocationsLoading = createSelector(
  selectSupplierLocationsState,
  (state) => state.loading
);

export const selectLocationsSuccess = createSelector(
  selectSupplierLocationsState,
  (state) => state.success
);

export const selectLocationError = createSelector(
  selectSupplierLocationsState,
  (state) => state.error
);
