<kehe-phoenix-modal
  modalTitle="Meeting Details"
  size="md"
  [show]="true"
  (modalEvent)="onClose($event)"
>
  <div body [formGroup]="form">
			<kehe-phoenix-alert
        message="Something went wrong, please try again."
				type="error"
				[show]="error"
				[stick]="true"
				[hideCloseIcon]="true">
			</kehe-phoenix-alert>

    <p>Enter the date set for the supplier optimal delivery method meeting.</p>

    <div class="w-50">
      <kendo-label text="Meeting Date"></kendo-label>
      <kendo-datepicker
        formControlName="meetingDate"
        placeholder="mm/dd/yyyy"
        [incompleteDateValidation]="true"
        [disabledDates]="disabledDates"
        [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }"
        [format]="'MM/dd/yyyy'"
      >
      </kendo-datepicker>
      <kendo-formerror *ngIf="hasFormError">Invalid meeting date.</kendo-formerror>
    </div>
  </div>
  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button
      class="modal-btn-spacing"
      classAttr="btn-medium btn-link"
      (click)="onClose()"
      >Cancel</kehe-phoenix-button
    >
    <kehe-phoenix-button
      class="modal-btn-spacing"
      classAttr="btn-primary btn-medium"
      [disabled]="loading || hasFormError"
      [spinner]="loading"
      (click)="onSave()"
      >Save</kehe-phoenix-button
    >
  </div>
</kehe-phoenix-modal>
