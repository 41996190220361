<div *ngIf="form">
  <ng-container [ngSwitch]="page">

    <div *ngSwitchCase="bulkEditPages.AddPOEmailPage">

      <h5>Add PO Email</h5>
      <div [formGroup]="form">
        <div formArrayName="poEmails">
          <div *ngFor="let po of poEmailsFormArray.controls; let i = index">
            <div [formGroupName]="i" class="row mb-2 mb-md-0 align-items-start">
              <div class="col-4 pr-md-0 mb-3">
                <kendo-formfield>
                  <kendo-label text="Type"></kendo-label>
                  <kendo-combobox #addPOEmailTypeField (focus)="addPOEmailTypeField.toggle(true)" [data]="transTypes"
                    formControlName="emailType" placeholder="Select Type"></kendo-combobox>
                </kendo-formfield>
              </div>
              <div class="col-6 pl-md-2 pr-md-0 mb-3">
                <kendo-formfield showErrors="always">
                  <kendo-label text="PO Email"></kendo-label>
                  <kendo-textbox (blur)="handlePOEmailBlur()" [required]="isPOEmailRequired" formControlName="email"
                    (valueChange)="poEmailChange($event, i)" [maxlength]="poEmailMaxLength"
                    [class.has-warning]="warningInputs[i]"
                    placeholder="Enter PO Email">
                    <ng-template kendoTextBoxSuffixTemplate>
                      <span>{{ poEmailCounter[i] }}</span>
                    </ng-template>
                  </kendo-textbox>
                  <kendo-formerror *ngIf="getPOEmailField(i)?.touched && getPOEmailField(i)?.errors?.email">
                    Invalid email
                  </kendo-formerror>
                  <kendo-formerror *ngIf="form && isPOEmailRequired">
                    PO Email is required.
                  </kendo-formerror>
                </kendo-formfield>
              </div>
              <div *ngIf="poEmailsFormArray.controls.length > 1"
                class="col-md-auto mt-3 pl-md-2 mb-3 mb-md-0 d-flex justify-content-end pt-md-1">
                <kehe-phoenix-button (click)="deletePoTransaction(i)" classAttr="btn-medium btn-delete" name="deletePoItem">
                  <em class="fas fa-trash-alt"></em>
                </kehe-phoenix-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <kehe-phoenix-button (click)="addPOEmail()" classAttr="btn-link btn-medium" iconClassAttr="fas fa-plus" name="addPoItem">
        Add Email
      </kehe-phoenix-button>

    </div>

    <div *ngSwitchCase="bulkEditPages.ReplacePOEmailPage">
      <h5 class="mb-3">Replace PO Email</h5>

      <div [formGroup]="form">
        <p><strong>1. </strong>Select Existing PO Email you would like to remove.</p>

        <div class="mb-3">
          <kendo-formfield>
            <kendo-label text="Existing PO Email"></kendo-label>
            <kendo-multiselect [data]="existingPOEmails" [filterable]="true"
              [kendoDropDownFilter]="{ caseSensitive: false, operator: 'contains' }" textField="email"
              valueField="email" formControlName="existingPOEmails" placeholder="Select Existing PO Email(s)">
            </kendo-multiselect>
            <kendo-formerror *ngIf="form.get('existingPOEmails')?.errors?.required">
              Existing PO Email is required.
            </kendo-formerror>
          </kendo-formfield>
        </div>

        <p><strong>2. </strong>Enter New PO Email to replace existing.</p>

        <div formArrayName="poEmails">
          <div *ngFor="let po of poEmailsFormArray.controls; let i = index">
            <div [formGroupName]="i" class="row mb-2 mb-md-0">
              <div class="col-4 pr-md-0 mb-3">
                <kendo-formfield>
                  <kendo-label text="Type"></kendo-label>
                  <kendo-combobox #replacePOEmailTypeField (focus)="replacePOEmailTypeField.toggle(true)"
                    [data]="transTypes" formControlName="emailType" placeholder="Select Type"></kendo-combobox>
                </kendo-formfield>
              </div>
              <div class="col-6 pl-md-2 pr-md-0 mb-3">
                <kendo-formfield showErrors="always">
                  <kendo-label text="PO Email"></kendo-label>
                  <kendo-textbox (blur)="handlePOEmailBlur()" [required]="isPOEmailRequired" formControlName="email"
                    [class.has-warning]="warningInputs[i]"
                    (valueChange)="poEmailChange($event, i)" [maxlength]="poEmailMaxLength"
                    placeholder="Enter PO Email">
                    <ng-template kendoTextBoxSuffixTemplate>
                      <span>{{ poEmailCounter[i] }}</span>
                    </ng-template>
                  </kendo-textbox>
                  <kendo-formerror *ngIf="getPOEmailField(i)?.touched && getPOEmailField(i)?.errors?.email">
                    Invalid email
                  </kendo-formerror>
                  <kendo-formerror *ngIf="form && isPOEmailRequired">
                    PO Email is required.
                  </kendo-formerror>
                </kendo-formfield>
              </div>
              <div *ngIf="poEmailsFormArray.controls.length > 1"
                class="col-md-auto mt-3 pl-md-2 mb-3 mb-md-0 d-flex justify-content-end pt-md-1">
                <kehe-phoenix-button (click)="deletePoTransaction(i)" classAttr="btn-medium btn-delete" name="deletePoItem">
                  <em class="fas fa-trash-alt"></em>
                </kehe-phoenix-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <kehe-phoenix-button (click)="addPOEmail()" classAttr="btn-link btn-medium" iconClassAttr="fas fa-plus" name="addPoItem">
        Add Email
      </kehe-phoenix-button>


    </div>

    <div *ngSwitchCase="bulkEditPages.RemovePOEmailPage">

      <h5 class="mb-3">Remove PO Email</h5>

      <div [formGroup]="form">
        <p>Select the PO Email you would like to remove.</p>
        <kendo-formfield>
          <kendo-label text="Remove PO Email"></kendo-label>
          <kendo-multiselect #removePOEmailsField [data]="existingPOEmails" [filterable]="true"
            [kendoDropDownFilter]="{ caseSensitive: false, operator: 'contains' }" textField="email" valueField="email"
            formControlName="existingPOEmails" placeholder="Select PO Emails" [ngClass]="{'has-warning': hasWarning}">
          </kendo-multiselect>
          <kendo-formerror *ngIf="form.get('existingPOEmails')?.errors?.required">
            Remove PO Email is required.
          </kendo-formerror>
          <kendo-formhint *ngIf="hasWarning" class="warning-hint">
            Listed Email(s) may not be removed.
          </kendo-formhint>
        </kendo-formfield>
      </div>

    </div>
  </ng-container>
</div>
