import { Component, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-schedule-appointment-modal',
  templateUrl: './schedule-appointment-modal.component.html',
})
export class ScheduleAppointmentModalComponent {
  _minDate: Date;

  @Output() closeModal = new Subject();
  @Output() save = new Subject<Date>();

  @Input() loading = false;
  @Input() error = false;
  @Input()
  set minDate(date: Date | string) {
    this._minDate = new Date(date);
  }

  form = this._builder.group({
    meetingDate: [],
  })

  disabledDates = (date: Date): boolean => {
    return date.valueOf() < this._minDate.getTime();
  }

  get hasFormError() {
    const meeting = this.form.controls.meetingDate;
    return meeting.touched && !!meeting.errors;
  }

  constructor(
    private readonly _builder: UntypedFormBuilder,
  ) {
    const min = new Date();
    min.setFullYear(2022, 11, 31);
    this._minDate = min;
  }

  onClose(event?: { type: string; }) {
    if (event?.type === 'outside-click') {
      return;
    }
    this.closeModal.next(event);
  }

  onSave() {
    this.loading = true;
    this.save.next(this.form.getRawValue().meetingDate);
  }
}
