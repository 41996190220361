<ng-container *ngIf="vm$ | async as vm">
  <div class="kehe-grid no-scroll p-0 w-100" [ngClass]="{ 'kehe-grid-responsive': vm.isMedAndBelowView }">
    <kendo-grid [sortable]="{ allowUnsort: false, mode: 'single' }" [data]="vm.gridRows"
      [hideHeader]="vm.isMedAndBelowView">
      <kendo-grid-column [width]="15" [sortable]="false">
        <ng-template kendoGridCellTemplate let-brokerItem>
          <div class="text-center star-icon">
            <span data-automation-id="broker-v2-table-is-hq-broker">
              <span *ngIf="isHeadQuarterRow(brokerItem)" class="fas fa-star" title="Headquarter">
              </span>
            </span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="90" [locked]="false" [sortable]="false" field="brokerName">
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="broker-v2-table-broker-name-header">Broker Name</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-brokerItem>
          <span data-automation-id="broker-v2-table-broker-name">
            <a *ngIf="isBrokerOfRecordForm(brokerItem)" class="link"
              (click)="downloadHyperlinkClicked(brokerItem.document)">
              {{ brokerItem.brokerName | emptyValue }}
            </a>
            <span *ngIf="!isBrokerOfRecordForm(brokerItem)">
              {{brokerItem.brokerName}}
            </span>
          </span>

        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="30" [locked]="false" [sortable]="false" field="ebn">
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="broker-v2-table-ebn-header">EBN</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-brokerItem>
          <span data-automation-id="broker-v2-table-ebn">
            <a [ngClass]="{'link-cell': brokerItem.ebn}" [routerLink]="['/broker', brokerItem.ebn]" target="_blank">{{
              brokerItem.ebn | emptyValue }}</a>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="50" [locked]="false" [sortable]="false" field="brokerContact">
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="broker-v2-table-broker-contact-header">Broker Contact</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-brokerItem>
          <span data-automation-id="broker-v2-table-broker-contact">
            <span>
              {{brokerItem.brokerContact | emptyValue}}
            </span>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="50" [locked]="false" [sortable]="false" field="status">
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="broker-v2-table-status-header">Status</span>
        </ng-template>
        <div class="status-tag">
          <ng-template kendoGridCellTemplate let-brokerItem>
            <span>
              <kehe-phoenix-tag automationId="broker-v2-table-status" [tag]="brokerItem.tag"
                classAttr="large"></kehe-phoenix-tag>
            </span>
          </ng-template>
        </div>
      </kendo-grid-column>
      <kendo-grid-column [width]="50" [locked]="false" [sortable]="false" field="associatedOn">
        <ng-template kendoGridHeaderTemplate let-dataItem>
          <span data-automation-id="broker-v2-table-associated-on-header">Associated On</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-brokerItem>
          <span data-automation-id="broker-v2-table-associated-on">
            {{ brokerItem.associatedOn | date: 'MM/dd/yyyy' | emptyValue }}
          </span>
        </ng-template>

      </kendo-grid-column>
      <kendo-grid-column [width]="30" [sortable]="false">
        <ng-template kendoGridCellTemplate let-brokerItem>
          <div class="d-flex justify-content-between ms-1 me-1">
            <span *ngIf="isBrokerOfRecordForm(brokerItem)" class="fas fa-download" title="Download"
              data-automation-id="broker-v2-table-download-icon"
              (click)="downloadHyperlinkClicked(brokerItem.document)">
            </span>
            <!-- empty span for styling placeholder -->
            <span *ngIf="!isBrokerOfRecordForm(brokerItem)" class="link-button">
            </span>
            <span class="fas fa-trash-alt" title="Remove" data-automation-id="broker-v2-table-delete-icon"
              (click)="deleteRowClicked(brokerItem)">
            </span>

          </div>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridNoRecordsTemplate>
        <kehe-phoenix-loading *ngIf="vm.isLoading"></kehe-phoenix-loading>
        <div class="mt-4" *ngIf="!vm.isLoading && !vm.showApiErrorPanel">
          <kehe-phoenix-empty-state [config]="vm.emptyState"></kehe-phoenix-empty-state>
        </div>
        <kehe-phoenix-error-panel *ngIf="!vm.isLoading && vm.showApiErrorPanel" [config]="notLoadingConfig">
          <ng-container body>
            <p>Please try again at a later time. If error persists, please contact us.</p>
            <kehe-phoenix-button classAttr="btn-medium btn-primary" (click)="refreshPage()"
              automationId="broker-v2-table-error-panel-refresh-button">Refresh</kehe-phoenix-button>
          </ng-container>
        </kehe-phoenix-error-panel>
      </ng-template>
    </kendo-grid>

  </div>
</ng-container>