import { Component, OnInit } from '@angular/core';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { Document } from '../../models/document';
import { cancelDeleteDocument, deleteDocument } from '../../store/supplier-documents.actions';
import { selectDeleteDocument, selectIsDeletingDocument } from '../../store/supplier-documents.selectors';

@Component({
  selector: 'app-delete-document-modal',
  templateUrl: './delete-document-modal.component.html',
  styleUrls: []
})
export class DeleteDocumentModalComponent extends Destroyable implements OnInit {

  alertTypes = AlertTypes;
  alertMessage = 'You have selected to delete a file.  ' +
    'If this file was previously approved, it will need to be submitted for KeHE review again.';

  show: boolean;

  document: Document;

  isDeleting: boolean;

  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectDeleteDocument))
      .subscribe((val) => {
        this.document = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectIsDeletingDocument))
      .subscribe((val) => {
        this.isDeleting = val;
      });
  }

  handleKeHEModalEvents() {
    if (this.isDeleting) { return; }
    this._store.dispatch(cancelDeleteDocument());
  }

  closeModal() {
    if (this.isDeleting) { return; }
    this._store.dispatch(cancelDeleteDocument());
  }

  onDeleteClicked() {
    if (this.isDeleting) { return; }
    this._store.dispatch(deleteDocument());
  }
}
