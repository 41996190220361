import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import {
  selectSupplierDetail,
  selectSupplierBroker,
  selectSupplierEsn,
} from '../../store/supplier-detail.selectors';
import { brokerAddButtonClicked } from './broker-actions';
import { BrokerTabValidationStore } from './broker-v2-tab-validation.store';
import { BrokerType } from './add-broker-v2-modal/add-broker-v2-modal.store';

export enum BrokerModal {
  Add,
  Edit,
  Delete,
}

export interface BrokerTabState {
  openedModal: BrokerModal;
  supplierEsn: string;
  defaultBrokerType: BrokerType;
}

export const initialState: BrokerTabState = {
  openedModal: null,
  supplierEsn: '',
  defaultBrokerType: null,
};

@Injectable()
export class BrokerTabStore extends ComponentStore<BrokerTabState> {
  public _supplierDetail$ = this._store.select(selectSupplierDetail);
  public _supplierEsn$ = this._store.select(selectSupplierEsn);
  public _supplierBroker$ = this._store.select(selectSupplierBroker);

  public _supplierIsActive$ = this.select(
    this._supplierDetail$,
    (supplierDetail) => supplierDetail.status !== 'Draft'
  );

  // Modal State
  private _openedModal$ = this.select((store) => store.openedModal);
  public isAddModalOpen$ = this.select(
    this._openedModal$,
    (openedModal) => openedModal === BrokerModal.Add
  );
  public isEditModalOpen$ = this.select(
    this._openedModal$,
    (openedModal) => openedModal === BrokerModal.Edit
  );
  private _isDeleteModalOpen$ = this.select(
    this._openedModal$,
    (openedModal) => openedModal === BrokerModal.Delete
  );

  public isDoesNotHaveBrokerTypeByDefault$ = this.select(
    (store) => store.defaultBrokerType === BrokerType.DoesNotHaveABroker
  );

  //

  readonly vm$ = this.select(
    this.state$,
    this.isAddModalOpen$,
    this.isEditModalOpen$,
    this._isDeleteModalOpen$,
    this._brokerTabValidationStore.isValid$,
    (
      state,
      isAddModalOpen,
      isEditModalOpen,
      isDeleteModalOpen,
      isBrokerTabValid
    ) => ({
      ...state,
      isAddModalOpen,
      isEditModalOpen,
      isDeleteModalOpen,
      isBrokerTabValid,
    })
  );

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _brokerTabValidationStore: BrokerTabValidationStore
  ) {
    super(initialState);

    this.openEditModalEffect$(this._actions$);
  }

  /**
   * Handles opening of all Broker V2 Tab Modals
   *
   * @param modalType
   */
  openModal(modalType: BrokerModal) {
    this.patchState({
      openedModal: modalType,
    });
  }
  /**
   * Handles closing of all Broker V2 Tab Modals
   */
  closeModal() {
    this.patchState({
      openedModal: initialState.openedModal,
      defaultBrokerType: null,
    });
  }

  openEditModalEffect$ = this.effect((actions$: Actions) =>
    actions$.pipe(
      ofType(brokerAddButtonClicked),
      tap(() => this.openModal(BrokerModal.Edit))
    )
  );

  public openEditModal() {
    this.patchState({
      defaultBrokerType: null,
    });
    this.openModal(BrokerModal.Edit);
  }
  public openSkipModal() {
    this.patchState({
      defaultBrokerType: BrokerType.DoesNotHaveABroker,
    });
    this.openModal(BrokerModal.Edit);
  }
}
