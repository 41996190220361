import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { Spoils } from '../../models/spoils';
import { Terms } from '../../models/terms';
import { SupplierDetailsConstants } from '../../constants/supplier-details-constants';
import { Constants } from '../../../../constants/constants';
import { TermsDropdownData, TermsDropdownType } from '../../models/terms-dropdown-data';
import { SpoilsTypes } from '../../models/spoils-types';
import { SupplierDetail } from '../../models/supplier-detail';
import { SUPPLIER_INVITE_TYPES } from '../../../supplier-list/constants/supplier-constants';
import {shouldShowAAPFieldForTermsTab} from "@app/modules/supplier-detail/utilities/supplier-utils";
import { OnBoardingStatus } from '../../models/onboarding-status';
import { selectTermsViewVM } from './terms-view.selectors';

@Component({
  selector: 'app-terms-view',
  templateUrl: './terms-view.component.html'
})

export class TermsViewComponent extends Destroyable implements OnInit {

  supplier: SupplierDetail;
  tabStatuses: OnBoardingStatus[];
  terms: Terms;
  defaultNullValue = '--';
  tooltipMCBExceptionCode = SupplierDetailsConstants.MCBExceptionCodeTooltip;
  termsDropdownData: TermsDropdownData[] = [];
  shouldShowAAPField = false;

  get canShowMCBExceptionCode(): boolean {
    if (this.terms?.promotions?.acceptsManufacturerChargeBackFee) {
      return !!this.terms.promotions.manufacturerChargeBackExceptionCode;
    }
    return false;
  }

  get canShowMCBFeeDollars(): boolean {
    return this.canShowMCBFee(SupplierDetailsConstants.MCBExceptionCode.DollarKey);
  }

  get canShowMCBFeePercentage(): boolean {
    return this.canShowMCBFee(SupplierDetailsConstants.MCBExceptionCode.PercentageKey);
  }

  constructor(
    private _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    this._store.select(selectTermsViewVM).pipe(takeUntil(this.destroy$))
      .subscribe(({ supplier, onboardingStatus, termsDropDownData, ffAAPField }) => {
        this.supplier = supplier;
        this.tabStatuses = onboardingStatus;
        this.termsDropdownData = termsDropDownData;
        this.terms = supplier?.terms;
        this.shouldShowAAPField = shouldShowAAPFieldForTermsTab(this.supplier, ffAAPField);
      });
  }

  canShowMCBFee(exceptionCode: string) {
    if (this.terms?.promotions) {
      if (!this.terms.promotions.acceptsManufacturerChargeBackFee) {
        return false;
      }
      if (SupplierDetailsConstants.MCBExceptionCode.BothKey === this.terms.promotions.manufacturerChargeBackExceptionCode ||
        exceptionCode === this.terms.promotions.manufacturerChargeBackExceptionCode) {
        return true;
      }
    }
    return false;
  }

  getNullDefaultDisplayValue(value: any) {
    return !this.isNullOrUndefined(value) && value.length > 0 ? value : this.defaultNullValue;
  }

  getPercentDisplayValue(value: any, digitsInfo: string = '1.0-0') {
    const percentPipe = new PercentPipe('en-US');
    return this.isNullOrUndefined(value) ? this.defaultNullValue : percentPipe.transform(value, digitsInfo);
  }

  getSpoilagePercentDisplayValue(value: any, digitsInfo: string = '1.0-0') {
    const percentPipe = new PercentPipe('en-US');
    return this.isNullOrUndefined(value) ? this.defaultNullValue : percentPipe.transform((value / 100), digitsInfo);
  }
  getDateDisplayValue(value: any) {
    const datePipe = new DatePipe('en-US');
    return this.isNullOrUndefined(value) ? this.defaultNullValue : datePipe.transform(value, 'MM/dd/yyyy');
  }
  getCurrencyDisplayValue(value: any) {
    const currencyPipe = new CurrencyPipe('en-US');
    return this.isNullOrUndefined(value) ? this.defaultNullValue : currencyPipe.transform(value);
  }

  getDueDateCalculation(value: any) {
    if (this.isNullOrUndefined(value)) { return this.defaultNullValue; }

    const receiptOfGoods = 'Receipt of Goods';
    const invoiceDate = 'Vendor Invoice Date';
    const later = 'Later';

    switch (value.toLowerCase()) {
      case 'i':
        return invoiceDate;
      case 'l':
        return later;
      default:
        return receiptOfGoods;
    }
  }

  getSpoilsValue(spoils: Spoils) {
    if (this.isNullOrUndefined(spoils) || this.isNullOrUndefined(spoils.type)) {
      return this.defaultNullValue;
    }

    const value = spoils.type.toLowerCase();
    if (value === 'y') {
      return 'Full Coverage';
    }

    if (value === 'n') {
      if (Number(spoils.allowancePercentage) === 0) {
        return 'No Support';
      }
      return 'Spoilage Allowance';
    }

    return this.defaultNullValue;
  }

  private isNullOrUndefined(value) {
    return value === null || value === undefined;
  }

  getMCBExceptionValue() {
    if (this.terms?.promotions) {
      if (!this.terms.promotions.acceptsManufacturerChargeBackFee) {
        return Constants.EmptyValue;
      }
      if (this.terms.promotions.manufacturerChargeBackExceptionCode) {
        const exceptionCode = SupplierDetailsConstants.MCBExceptionCodeList
          .find(item => item.value === this.terms.promotions.manufacturerChargeBackExceptionCode);
        if (exceptionCode) {
          return exceptionCode.name;
        }
      }
    }
    return Constants.EmptyValue;
  }

  getDescription(type: TermsDropdownType, code: string): string {
    if (this.isNullOrUndefined(code)) {
      return this.defaultNullValue;
    }
    const data = this.termsDropdownData.find(item => item.listName === type);
    if (data) {
      const codeDescription = data.listValues.find(item => item.code === code);
      return codeDescription ? codeDescription.description : this.defaultNullValue;
    }
    return this.defaultNullValue;
  }

  getSpecialEventTermsSeasonal() {
    return this.getDescription(TermsDropdownType.SpecialEventTermsSeasonal, this.terms.specialEventTermsSeasonalCode);
  }

  getSpecialEventTermsNewWarehouseOpening() {
    return this.getDescription(TermsDropdownType.SpecialEventTermsNewWarehouseOpening, this.terms.specialEventTermsNewWarehouseOpeningCode);
  }

  getPaymentMethod() {
    return this.getDescription(TermsDropdownType.PaymentMethod, this.terms.paymentMethodCode);
  }

  getAuditTimeFrame() {
    return this.getDescription(TermsDropdownType.AuditTimeFrame, this.terms.auditTimeFrameCode);
  }

  getPriceChangeNoticeInDays() {
    if (this.terms?.priceChangeNoticeInDays) {
      return this.getDescription(TermsDropdownType.PriceChangeNotice, this.terms.priceChangeNoticeInDays.toString());
    }
    return this.defaultNullValue;
  }

  getFreeProductsProgram() {
    if (this.terms?.promotions) {
      return this.getDescription(TermsDropdownType.FreeProductsProgram, this.terms.promotions.freeProductsProgramCode);
    }
    return this.defaultNullValue;
  }

  getFreeProductHandlingCharge() {
    if (this.terms?.promotions) {
      return this.getDescription(TermsDropdownType.FreeProductHandlingCharge, this.terms.promotions.freeProductHandlingChargeCode);
    }
    return this.defaultNullValue;
  }

  showSpoilagePercent() {
    if (this.terms?.spoils?.spoilsType) {
      return this.terms.spoils.spoilsType === SpoilsTypes.spoilageAllowance && this.terms.spoils.allowancePercentage > 0;
    }
    return false;
  }

  getSupplierType() {
    if (this.supplier?.supplierType) {
      const type = SUPPLIER_INVITE_TYPES.find(item => item.value === this.supplier.supplierType);
      return type ? type.label : null;
    }
    return null;
  }
}

