import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeheNavigationItem } from '@kehe/phoenix-top-navigation-panel';
import { selectNavigationItems } from './om-navigation.selectors';
import { Destroyable } from '../../abstract/destroyable';
import { takeUntil } from 'rxjs/operators';
import * as ExportHierarchiesModalActions from '../../modules/utilities/hierarchies/export-hierarchies/store/effects/actions';

@Component({
  selector: 'app-om-navigation',
  templateUrl: './om-navigation.component.html',
  styleUrls: ['./om-navigation.component.scss'],
})
export class OmNavigationComponent extends Destroyable implements OnInit
{
  items: KeheNavigationItem[] = [];

  constructor(private _store: Store) {
    super();
  }

  ngOnInit(): void {
    this._store.select(selectNavigationItems)
      .pipe(takeUntil(this.destroy$))
      .subscribe((navigationItems: KeheNavigationItem[]) => {
        this.items = [...navigationItems];
      });
  }

  subItemClickEventHandler($event) {
    if ($event.clickEventName === 'exportHierarchyClicked') {
      this._store.dispatch(ExportHierarchiesModalActions.openClicked());
    }
  }
}
