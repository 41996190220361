import { createReducer, on } from '@ngrx/store';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { removeKendoBreadCrumbs } from '@kehe/purchasing-options-lib';
import * as BreadCrumbsApiActions from '@app/breadcrumbs/breadcrumbs.actions';
import * as SupplierMassUpdateActions from '@app/modules/supplier-mass-update/store/supplier-mass-update.actions';
import * as SupplierTransitLanesActions from '@app/modules/supplier-transit-lanes/store/supplier-transit-lanes.actions';
import * as BrokerDetailPageActions from '@app/modules/broker-detail/pages/broker-detail-page/broker-detail-page.actions';
import * as ProductDetailPageActions from '@app/modules/product-detail/pages/product-detail-page/product-detail-page.actions';
import * as ProductPageActions from '@app/modules/product-list/pages/product-page/product-page.actions';
import * as ProductUploadPageActions from '@app/modules/product-upload/pages/product-upload-page/product-upload-page.actions';
import * as SupplierDetailPageActions from '@app/modules/supplier-detail/pages/supplier-detail-page/supplier-detail-page.actions';
import * as SupplierDetailApiActions from '@app/modules/supplier-detail/store/supplier-detail.actions';
import * as SupplierPageActions from '@app/modules/supplier-list/pages/supplier-page/supplier-page.actions';
import * as ProductsDataPageActions from '@app/modules/utilities/products/pages/products-data-page/products-data-page.actions';
import * as GroupManagementActions from '@app/modules/user-group-management/store/group-management/group-management.actions';
import * as UserManagementActions from '@app/modules/user-management/store/user-management.actions';
import * as ProhibitRestrictDetailActions from '@app/modules/prohibit-restrict-detail/store/prohibit-restrict-detail.actions';

export const key = 'breadCrumbs';

export interface State {
    kendoBreadCrumbs: BreadCrumbItem[];
}

export const initialState: State = {
    kendoBreadCrumbs: [],
};

export const reducer = createReducer(
    initialState,
    on(BreadCrumbsApiActions.getBreadCrumbsSuccess, (state, { breadCrumbs }) => {
      return {
        ...state,
        kendoBreadCrumbs: [...state.kendoBreadCrumbs, ...breadCrumbs],
      }
    }),
    on(
        BrokerDetailPageActions.updateKendoBreadCrumbs,
        ProductDetailPageActions.updateKendoBreadCrumbs,
        ProductPageActions.updateKendoBreadCrumbs,
        ProductUploadPageActions.updateKendoBreadCrumbs,
        SupplierDetailPageActions.updateKendoBreadCrumbs,
        SupplierDetailApiActions.updateKendoBreadCrumbs,
        SupplierPageActions.updateKendoBreadCrumbs,
        ProductsDataPageActions.updateKendoBreadCrumbs,
        ProhibitRestrictDetailActions.updateKendoBreadCrumbs,
        (state, params) => {
          if (state.kendoBreadCrumbs.some(breadCrumb => breadCrumb.text === params.item.text)) {
            return {...state};
          } else {
            return {
              ...state,
              kendoBreadCrumbs: [...state.kendoBreadCrumbs, params.item],
            }
          }
        }
      ),
      on(
        BrokerDetailPageActions.clearKendoBreadCrumbs,
        ProductDetailPageActions.clearKendoBreadCrumbs,
        ProductPageActions.clearKendoBreadCrumbs,
        ProductUploadPageActions.clearKendoBreadCrumbs,
        SupplierDetailPageActions.clearKendoBreadCrumbs,
        SupplierDetailApiActions.clearKendoBreadCrumbs,
        SupplierPageActions.clearKendoBreadCrumbs,
        ProductsDataPageActions.clearKendoBreadCrumbs,
        ProhibitRestrictDetailActions.clearKendoBreadCrumbs,
        (state) => {
          return {
            ...state,
            kendoBreadCrumbs: [],
          }
        }
      ),
      on(
        SupplierMassUpdateActions.updateKendoBreadCrumbsList,
        SupplierTransitLanesActions.updateKendoBreadCrumbsList,
        GroupManagementActions.updateKendoBreadCrumbsList,
        UserManagementActions.updateKendoBreadCrumbsList,
        (state, params) => {
        return {
          ...state,
          kendoBreadCrumbs: [...params.items],
        };
      }),
      on(removeKendoBreadCrumbs, (state, params) => {
        const index = state.kendoBreadCrumbs.findIndex(e => e.text === params.item.text);
        if (index === -1) {
          return {...state};
        } else {
          return {
            ...state,
            kendoBreadCrumbs: [...state.kendoBreadCrumbs.slice(0, index), params.item],
          }
        }
      }),
);

export const getBreadCrumbs = (state: State) => state && state.kendoBreadCrumbs;
