import { Component, OnInit } from '@angular/core';
import { PriceChangeConfigData, PriceChangeFacade, SupplierPriceChangeHostApp } from '@kehe/supplier-price-change-lib';
import { Store } from '@ngrx/store';
import { environment } from '../../../../../environments/environment';
import { combineLatest } from 'rxjs';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '@app/abstract/destroyable';

@Component({
  selector: 'app-manage-price-page',
  templateUrl: './manage-price-page.component.html',
})
export class ManagePricePageComponent extends Destroyable  implements OnInit {

  constructor(
    public _priceChangeFacade: PriceChangeFacade,
    public _store: Store,
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this._store.select(AuthenticationSelectors.selectUserFirstName),
      this._store.select(AuthenticationSelectors.selectUserEmail),
    ]).pipe(takeUntil(this.destroy$))
    .subscribe(([name, email]) => {
      let config: PriceChangeConfigData;
      config = {
        userEmail: email,
        userName: name,
        userApi: environment.usersMicroservice,
        supplierApi: environment.supplierApi,
        priceChangeApi: environment.v2PriceChangeApi,
        ga: environment.ga,
        splitIoKey: environment.splitIoKey,
        environment: environment.current,
        hostApp: SupplierPriceChangeHostApp.Enterprise
      };
      this._priceChangeFacade.setConfigData( config );
    });
  }

}
