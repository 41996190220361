import { createAction, props } from '@ngrx/store';

import {
  IAnnouncementsRecentLiveCount
} from '../interfaces/announcements-recent-live-count.interface';
import { IRecentAnnouncement } from '../interfaces/recent-announcement.interface';

export const loadRecentLiveCount = createAction(
  '[Announcements Recent API]: Load recent live count'
);

export const loadRecentLiveCountSuccess = createAction(
  '[Announcements Recent API]: Load recent live count success',
  props<{recentLiveCount: IAnnouncementsRecentLiveCount }>()
);

export const loadRecentLiveCountError = createAction(
  '[Announcements Recent API]: Load recent live count error'
);

export const loadRecentAnnouncements = createAction(
  '[Announcements Recent API]: Load recent announcements'
);

export const loadRecentAnnouncementsSuccess = createAction(
  '[Announcements Recent API]: Load recent announcements success',
  props<{announcements: IRecentAnnouncement[] }>()
);

export const loadRecentAnnouncementsError = createAction(
  '[Announcements Recent API]: Load recent announcements error',
  props<{msg: string }>()
);
