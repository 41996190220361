export interface AddNoteForm {
    ticketNumber: string;
    requestReason: string;
    isValid: boolean;
    auditNotes: string;
}

export enum AddNoteFormControlName {
    ticketNumber = 'ticketNumber',
    requestReason = 'requestReason',
}
