import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as supplierUsersActions from './supplier-users.actions';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { SupplierUserService } from '../services/supplier-user.service';
import { selectESN, selectUsers } from './supplier-users.selectors';
import { SupplierUserStatus } from '../models/user-status.enum';

@Injectable()
export class SupplierUsersEffects {

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _userService: SupplierUserService,
    private _toastService: PhoenixToastService,
  ) { }

  loadUsers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        supplierUsersActions.loadSupplierUsers,
      ),
      withLatestFrom(
        this._store.pipe(select(selectESN)),
      ),
      switchMap(([action, esn]) => {
        return this._userService.getUsers(esn).pipe(
          map((users) => supplierUsersActions.loadSupplierUsersSuccess({ users })),
          catchError(error => of(supplierUsersActions.loadSupplierUsersError()))
        );
      })
    )
  );

  loadUserStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        supplierUsersActions.loadSupplierUsersSuccess,
        supplierUsersActions.addUserSuccess,
      ),
      withLatestFrom(
        this._store.pipe(select(selectUsers)),
      ),
      switchMap(([action, users]) => {
        return this._userService.getUserDetailList(users).pipe(
          map((users) => supplierUsersActions.loadUserStatusSuccess({users})),
          catchError(error => of(supplierUsersActions.loadUserStatusError()))
        );
      })
    )
  );

  requestAddUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.requestAddUser),
      switchMap((action) => {
        return this._userService.checkEmailInV1(action.user.email).pipe(
          map((type) => {
            if (!type) {
              return supplierUsersActions.createUserInV1({ user: action.user });
            } else if (type === 'vendor') {
              return supplierUsersActions.createUserInV2({ user: action.user });
            } else {
              return supplierUsersActions.addUserError({ errorMessage: 'Only suppliers can be added as supplier users.' })
            }
          }),
          catchError(error => of(supplierUsersActions.addUserError({ errorMessage: error.error.message })))
        );
      })
    )
  );

  createUserInV1$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.createUserInV1),
      switchMap((action) => {
        return this._userService.createUserInV1(action.user).pipe(
          map(() => supplierUsersActions.createUserInV2({ user: action.user })),
          catchError(error => of(supplierUsersActions.addUserError({ errorMessage: error.error.message })))
        );
      })
    )
  );

  createUserInV2$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.createUserInV2),
      withLatestFrom(
        this._store.pipe(select(selectESN)),
      ),
      switchMap(([action, esn]) => {
        return this._userService.createUserInV2(esn, action.user)
          .pipe(
            map((user) => {
              // Set name from user input form as we dont get name field in api response due to latency
              user.name = action.user.name;
              return supplierUsersActions.associateV2Permissions({ user });
            }),
            catchError(error => of(supplierUsersActions.addUserError({ errorMessage: error.error.message })))
          );
      })
    )
  );

  associateV2Permissions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.associateV2Permissions),
      switchMap(action => {
        return this._userService.associateV2Permissions(action.user.email).pipe(
          map(() => supplierUsersActions.addUserSuccess({ user: action.user })),
          catchError(error => of(supplierUsersActions.addUserError({ errorMessage: error.error.message })))
        );
      })
    )
  );

  showUserAddSuccessMessage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.addUserSuccess),
      tap((action) => this._toastService.showSuccessToast('User added successfully!', false, 5000))
    ), { dispatch: false }
  );

  assignAdmin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.assignAdmin),
      withLatestFrom(
        this._store.pipe(select(selectESN)),
      ),
      switchMap(([action, esn]) => {
        return this._userService.assignAdmin(esn, action.user)
          .pipe(
            map((user) => supplierUsersActions.assignAdminSuccess({ user })),
            catchError(error => of(supplierUsersActions.assignAdminError({ user: action.user, error: error.error })))
          );
      })
    )
  );

  assignAdminSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.assignAdminSuccess),
      tap((action) => this._toastService.showSuccessToast('User updated successfully!', false, 5000))
    ), { dispatch: false }
  );

  assignAdminError$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.assignAdminError),
      tap((action) => this._toastService.showErrorToast(action.error.message, false, 5000))
    ), { dispatch: false }
  );

  // Resend Invitation
  resendInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.resendInvitation),
      withLatestFrom(this._store.pipe(select(selectESN))),
      switchMap(([action, esn]) => {
        const requests = [
          this._userService.resendWelcomeEmailV2(esn, action.user.email),
        ];
        if (action.user.status !== SupplierUserStatus.Active) {
          requests.push(
            this._userService.getUserIdFromV1(action.user.email)
            .pipe(
              filter(userId => !!userId),
              switchMap(userId => this._userService.resendRegistrationEmailV1(userId)),
            )
          );
        }
        return forkJoin(requests).pipe(
          map(() => supplierUsersActions.resendInvitationSuccess({ user: action.user })),
          catchError((error) =>
            of(supplierUsersActions.resendInvitationError({ user: action.user, error: error.error }))
          )
        )
      })
    )
  );

  resendInvitationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.resendInvitationSuccess),
      tap((action) => this._toastService.showSuccessToast('Invitation sent successfully', false, 5000))
    ), { dispatch: false }
  );

  resendInvitationError$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.resendInvitationError),
      tap((action) => this._toastService.showErrorToast(action.error.message ?? action.error.Message, false, 5000))
    ), { dispatch: false }
  );
  // Resend Invitation

    // Delete User

    deleteUser$ =createEffect(() =>
    this._actions$.pipe(
      ofType(supplierUsersActions.deleteUser),
      withLatestFrom(this._store.pipe(select(selectESN))),
      switchMap(([action, esn]) =>
        forkJoin([
          this._userService.getUserIdFromV1(action.user.email)
            .pipe(
              filter(userId => !!userId),
              switchMap(userId => this._userService.deleteUserV1(userId)),
            ),
          this._userService.deleteUserV2(action.user.email, esn),
        ]).pipe(
          map(() => supplierUsersActions.deleteUserSuccess({ user: action.user })),
          catchError((error) =>
            of(supplierUsersActions.deleteUserError({ user: action.user, error: error.error }))
          )
        )
      )
    ));

  // Delete User
}
