import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import {
  cancelDeleteDocument,
  cancelRejectDocument,
  cancelUploadDocumentsModal,
  deleteDocumentSuccess,
  updateDocumentStatusSuccess,
  uploadAndSaveDocumentSuccess,
} from '../../store/supplier-documents.actions';

export interface DocumentsGridState {
  showUploadModel: boolean;
  showDeleteModel: boolean;
  showRejectModel: boolean;
}

export const initialState: DocumentsGridState = {
  showUploadModel: false,
  showDeleteModel: false,
  showRejectModel: false,
};

@Injectable()
export class DocumentsGridTabStore extends ComponentStore<DocumentsGridState> {
  constructor(private _actions$: Actions) {
    super(initialState);
    this.listenUploadModalVisibility$(this._actions$);
    this.listenDeleteModalVisibility$(this._actions$);
    this.listenRejectModalVisibility$(this._actions$);
  }

  readonly vm$ = this.select(this.state$, (state) => ({
    ...state,
  }));

  // Upload
  showUploadModal(show: boolean) {
    this.patchState({ showUploadModel: show });
  }

  listenUploadModalVisibility$ = this.effect((actions$: Actions) =>
    actions$.pipe(
      ofType(uploadAndSaveDocumentSuccess, cancelUploadDocumentsModal),
      tap(() => this.patchState({ showUploadModel: false }))
    )
  );

  // Delete
  showDeleteModal(show: boolean) {
    this.patchState({ showDeleteModel: show });
  }

  listenDeleteModalVisibility$ = this.effect((actions$: Actions) =>
    actions$.pipe(
      ofType(deleteDocumentSuccess, cancelDeleteDocument),
      tap(() => this.patchState({ showDeleteModel: false }))
    )
  );

  // Reject
  showRejectModal(show: boolean) {
    this.patchState({ showRejectModel: show });
  }

  listenRejectModalVisibility$ = this.effect((actions$: Actions) =>
    actions$.pipe(
      ofType(updateDocumentStatusSuccess, cancelRejectDocument),
      tap(() => this.patchState({ showRejectModel: false }))
    )
  );
}
