import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IAnnouncementsRecentLiveCount } from './interfaces/announcements-recent-live-count.interface';
import { selectAnnouncementRecentLiveCount } from './store/announcements-recent.selectors';
import { loadRecentLiveCount } from './store/announcements-recent.actions';
import { AnnouncementsRouterLinks } from '../enums/announcements-router-links.enum';

@Component({
  selector: 'app-announcements-recent',
  templateUrl: './announcements-recent.component.html',
  styleUrls: ['./announcements-recent.component.scss']
})
export class AnnouncementsRecentComponent implements OnInit {
  recentLiveCount$: Observable<IAnnouncementsRecentLiveCount> = this.store.select(selectAnnouncementRecentLiveCount);

  constructor(private store: Store, private router: Router) { }

  ngOnInit(): void {
    this.store.dispatch(loadRecentLiveCount());
  }

  goToAnnouncements(): void {
    this.router.navigate([ AnnouncementsRouterLinks.List ]);
  }
}
