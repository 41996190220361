import { Component, EventEmitter, Input, Output, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { formattedAddress, formattedContact } from '../../../modules/supplier-detail/utilities/address-format-util';
import { Contact, ContactType } from '../../../models/contact';
import { Destroyable } from '../../../abstract/destroyable';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent
  extends Destroyable
  implements ControlValueAccessor
{
  touched = false;
  disabled = false;
  contactId: string;
  showModal = false;
  contactTypes = ContactType;
  @Input() defaultContactTypes: ContactType;
  @Input() contacts: Contact[];
  @Output() selectedMyContact = new EventEmitter<Contact>();
  @Output() smartyStreetsUseAddress = new EventEmitter<Contact>();
  @Output() smartyStreetsEditAddress = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();
  @Output() remove = new EventEmitter<Contact>();
  @Input() isInProgress = false;
  @Input() saveContactError: string;
  @Input() alertMessage?: string;

  propagateChange = (_: any) => {
    return;
  };
  onTouched = () => {
    return;
  };

  get contact(): Contact {
    return this.contacts
      ? this.contacts.find((item) => item.uniqueId === this.contactId)
      : null;
  }

  get hasError() {
    return (
      (this.touched || this.ngControl?.touched) && !!this.ngControl?.errors
    );
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private _store: Store
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onCloseModal() {
    this.showModal = false;
    this.markAsTouched();
    this.closeModal.emit();
  }

  onAddClicked() {
    this.showModal = true;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.contactId = obj;
    this.showModal = false;
  }

  markAsTouched() {
    this.onTouched();
    this.touched = true;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onBlur() {
    this.markAsTouched();
  }

  onSelectMyContact(contact: Contact) {
    this.contactId = contact.uniqueId;
    this.showModal = false;
    this.selectedMyContact.emit(contact);
  }

  onSmartyStreetsUseAddress(contact: Contact) {
    this.smartyStreetsUseAddress.emit(contact);
  }

  onSmartyStreetsEditAddress() {
    this.smartyStreetsEditAddress.emit();
  }

  getAddressText() {
    return formattedAddress(this.contact);
  }

  getNotesText() {
    return this.contact.address?.name;
  }

  getContactText() {
    return formattedContact(this.contact);
  }

  onRemoveClicked(contact: Contact) {
    this.markAsTouched();
    this.remove.emit(contact);
  }
}
