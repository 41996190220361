<div *ngIf="form" [formGroup]="form">
  <div class="heading mt-3 mb-3">Add New {{ defaultContactType }}</div>
  <div class="mb-3" *ngIf="!hideContactType">
    <kendo-formfield>
      <kendo-label text="Contact Type"></kendo-label>
      <kendo-textbox [readonly]="defaultContactType !== null" formControlName="contactType"></kendo-textbox>
      <kendo-formerror>
        Contact Type is required.
      </kendo-formerror>
    </kendo-formfield>
  </div>
  <div class="mb-3" *ngIf="defaultContactType === contactTypes.RemittanceAddress">
    <kendo-formfield>
      <kendo-label text="Remit Name (on invoice)"></kendo-label>
      <kendo-textbox [formControl]="form.get('address.name')" placeholder="Enter Remit Name"
        [maxlength]="remitNameMaxLength">
        <ng-template kendoTextBoxSuffixTemplate>
          <span>{{ remitNameCounter }}</span>
        </ng-template>
      </kendo-textbox>
      <kendo-formerror>
        Remit Name is required.
      </kendo-formerror>
    </kendo-formfield>
  </div>
  <kehe-address-form [addressForm]="form.get('address')" [shouldDisplayNameField]="shouldDisplayAddressNameField"
    [shouldDisplayAttention]="true" [maxCharLengths]="maxLengths" automationId="bulk-edit-dc-product-group-modal"
    [labelConfig]="labelConfig">
  </kehe-address-form>

  <div class="mb-3"
    *ngIf="defaultContactType === contactTypes.DeliveredAddress || defaultContactType === contactTypes.PickupAddress">
    <kendo-formfield>
      <kendo-label [text]="defaultContactType + ' Notes (optional)'"></kendo-label>
      <kendo-textarea formControlName="pickupAddressNotes" placeholder="Enter Notes"
        data-automation-id="bulk-edit-dc-product-group-modal-address-notes-input" [maxlength]="pickupNotesMaxLength">
        <kendo-textarea-suffix class="kehe-textarea-counter">
          {{ pickupNotesCounter }}
        </kendo-textarea-suffix>
      </kendo-textarea>
    </kendo-formfield>
  </div>

  <div class="heading mt-2 mb-3">Add Contact Information<ng-container
      *ngIf="defaultContactType !== contactTypes.PickupAddress && defaultContactType !== contactTypes.DeliveredAddress">
      (Optional)</ng-container>
  </div>
  <div formGroupName="contact">
    <div class="form-group" *ngIf="shouldDisplayContactNameField">
      <kendo-formfield>
        <kendo-label text="Name"></kendo-label>
        <kendo-textbox formControlName="name" placeholder="e.g. John Doe"
          data-automation-id="bulk-edit-dc-product-group-modal-name-input" maxlength="100"></kendo-textbox>
      </kendo-formfield>
    </div>

    <div class="d-flex">
      <div class="pe-2 flex-grow-1">
        <kehe-phoenix-phone-number-input tabOrder="2" class="mb-0" name="phone" label="Phone" formControlName="phone"
          automationId="bulk-edit-dc-product-group-modal-phone-input">
        </kehe-phoenix-phone-number-input>
      </div>
      <div>
        <kendo-formfield>
          <kendo-label text="Ext (optional)"></kendo-label>
          <kendo-numerictextbox formControlName="phoneExtension" [format]="'#'" placeholder="e.g. 00000"
            data-automation-id="bulk-edit-dc-product-group-modal-phone-extension-input" [spinners]="false" [min]="0"
            maxlength="5" [pattern]="phoneExtPattern"></kendo-numerictextbox>
          <kendo-formerror>Invalid ext. format</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="form-group">
      <kehe-phoenix-phone-number-input tabOrder="4" name="fax" label="Fax (optional)"
        automationId="bulk-edit-dc-product-group-modal-fax-input" formControlName="fax">
      </kehe-phoenix-phone-number-input>
    </div>

    <div class="form-group">
      <kendo-formfield>
        <kendo-label text="Email"></kendo-label>
        <kendo-textbox formControlName="email" placeholder="e.g. contact@email.com" maxlength="100"
          data-automation-id="bulk-edit-dc-product-group-modal-email-input" [pattern]="emailPattern"
          [maxlength]="emailMaxLength">
          <ng-template kendoTextBoxSuffixTemplate>
            <span>{{ emailCounter }}</span>
          </ng-template>
        </kendo-textbox>
        <kendo-formerror>Invalid email format</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
</div>