<kehe-phoenix-modal name="DeleteBroker" modalTitle="Remove Broker" [show]="true" (modalEvent)="handleKeheModalEvents($event)"
  size="md">
  <div body class="delete-broker-body">
    <div>
      Are you sure you want to remove this broker?
    </div>
  </div>
  <div footer class="delete-broker-footer">
    <kehe-phoenix-button class="modal-btn-spacing" (click)="cancelModal()" classAttr="btn-medium btn-link">Cancel</kehe-phoenix-button>
    <kehe-phoenix-button class="modal-btn-spacing" [spinner]="isSaveInProgress" [disabled]="isSaveDisabled()" (click)="deleteBroker()" classAttr="btn-medium btn-primary">Remove
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>