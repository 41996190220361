import { ComponentStore } from '@ngrx/component-store';

/**
 * `ApiServiceState` is an interface representing the state of the API service.
 *
 * @typedef {Object} ApiServiceState
 * @property {boolean} isLoading - Indicates if the API service is loading data.
 * @property {string} apiError - The error message returned by the API service, if any.
 * @property {T} data - The data returned by the API service.
 */
export interface ApiServiceState<T> {
  isLoading: boolean;
  apiError: string;
  data: T;
}

/**
 * `AbstractApiStore` is an abstract class representing a base store for managing API state.
 * It extends `ComponentStore` and provides base actions and selectors.
 *
 * @class
 * @extends ComponentStore
 *
 * @public
 * @see {@link https://ngrx.io/guide/component-store ComponentStore}
 *
 * @example
 * class ExampleStore extends AbstractApiStore<ExampleData> {
 *  constructor() {
 *    super();
 *  }
 * }
 *
 * @param {ApiServiceState<T>} state - The initial state of the API service.
 */
export abstract class AbstractApiStore<T> extends ComponentStore<
  ApiServiceState<T>
> {
  constructor() {
    super({ isLoading: false, apiError: '', data: null });
  }

  /**
   * `isLoading$` is a selector that returns the loading state of the API service.
   */
  readonly isLoading$ = this.select((state) => state.isLoading);

  /**
   * `apiError$` is a selector that returns the error state of the API service.
   */
  readonly apiError$ = this.select((state) => state.apiError);
  /**
   * `data$` is a selector that returns the data state of the API service.
   */
  readonly data$ = this.select((state) => state.data);

  /**
   * `startLoading` is an action that sets the loading state to true and clears any previous errors.
   */
  readonly startLoading = this.updater((state) => ({
    ...state,
    isLoading: true,
    apiError: '',
  }));

  /**
   * `setError` is an action that sets the error state and stops the loading state.
   * @param {string} error - The error message to set.
   */
  readonly setError = this.updater((state, error: string) => ({
    ...state,
    isLoading: false,
    apiError: error,
  }));

  /**
   * `setData` is an action that sets the data state, clears any previous errors, and stops the loading state.
   * @param {T} data - The data to set.
   */
  readonly setData = this.updater((state, data: T) => ({
    ...state,
    isLoading: false,
    apiError: '',
    data,
  }));
}
