import { IKeheTab } from '@kehe/phoenix-tabs';
import { SupplierInviteReason } from '../enum/supplier-invite-reason';
import { SupplierInviteType } from '../enum/supplier-invite-type';
import { SupplierInviteRequester } from '../enum/supplier-invite-requester';
import { CodeValue } from '@app/models/code-value';

export const SUPPLIER_INVITE_REASONS: {
  label: string;
  value: SupplierInviteReason;
}[] = [
  {
    label: 'Name Change',
    value: SupplierInviteReason.NameChange,
  },
  {
    label: 'New Supplier',
    value: SupplierInviteReason.NewSupplier,
  },
];

export const SUPPLIER_INVITE_TYPES: {
  label: string;
  value: SupplierInviteType;
}[] = [
  {
    label: 'Standard',
    value: SupplierInviteType.Standard,
  },
  {
    label: 'Fresh Thyme Market Private Label & Exclusive',
    value: SupplierInviteType.FreshThyme,
  },
  {
    label: 'Sprouts Private Label',
    value: SupplierInviteType.SproutsPrivateLabel,
  },
  {
    label: 'Own Brands',
    value: SupplierInviteType.OwnBrands,
  },
  {
    label: 'Kroger Private Label',
    value: SupplierInviteType.KrogerPrivateLabel,
  },
];

export const SUPPLIER_TYPE_REQUESTER_MAP: Record<SupplierInviteType, SupplierInviteRequester> = {
  [SupplierInviteType.FreshThyme]: SupplierInviteRequester.Retailer,
  [SupplierInviteType.SproutsPrivateLabel]: SupplierInviteRequester.Retailer,
  [SupplierInviteType.Standard]: SupplierInviteRequester.CategoryManager,
  [SupplierInviteType.OwnBrands]: SupplierInviteRequester.CategoryManager,
  [SupplierInviteType.KrogerPrivateLabel]: SupplierInviteRequester.Retailer
};

export const SUPPLIER_TYPE_RETAILER_MAP: Record<SupplierInviteType, number> = {
  [SupplierInviteType.FreshThyme]: 10,
  [SupplierInviteType.SproutsPrivateLabel]: 13,
  [SupplierInviteType.Standard]: null,
  [SupplierInviteType.OwnBrands]: null,
  [SupplierInviteType.KrogerPrivateLabel]: 23
};

export const SUPPLIER_LIST_TABS = [
  { text: 'Draft' },
  { text: 'Active' },
] as IKeheTab[];

export const SUPPLIER_CURRENCY: {
  label: string;
  value: string;
}[] = [
  {
    label: 'EURO',
    value: 'EUR',
  },
  {
    label: 'USD',
    value: 'USD',
  }
]

export const SupplierCurrenciesComboBox: CodeValue[] = [
  {
    code: 'EUR',
    codeValue: 'EURO (EUR)',
    value: 'EURO'
  },
  {
    code: 'USD',
    codeValue: 'USD (USD)',
    value: 'USD'
  }
]
