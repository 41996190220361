import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  WorkQueueFilter,
  WorkQueueFilterResponse,
  WorkQueueFilterType,
} from '../pages/work-queue-page/models/work-queue-filter';
import { WorkQueueSortColumn, WorkQueueSortDirection } from '../pages/work-queue-page/models/work-queue-sort';
import { WorkQueueTaskResponse } from '../pages/work-queue-page/models/work-queue-task-response';

@Injectable({
  providedIn: 'root',
})
export class WorkQueueService {
  constructor(private httpClient: HttpClient) {}

  getFilters(): Observable<WorkQueueFilterResponse> {
    return this.httpClient.get<WorkQueueFilterResponse>(
      `${environment.apiGateway}/work-queue/query/filters`
    );
  }

  getTasks(
    filters: WorkQueueFilter[],
    searchText: string,
    sortColumn: WorkQueueSortColumn,
    sortDirection: WorkQueueSortDirection,
    page: number,
    size: number
  ): Observable<WorkQueueTaskResponse> {
    const queryParams = [];
    const priorityFilter = filters?.find(
      (f) => f.filterType === WorkQueueFilterType.Priority
    );
    if (priorityFilter) {
      queryParams.push('Priority=true');
    }
    const typeFilters = filters?.filter(
      (f) => f.filterType === WorkQueueFilterType.TypeFilter
    );
    if (typeFilters) {
      const values = typeFilters.map((t) => `TypeFilters=${t.value}`);
      queryParams.push(...values);
    }
    const assignToFilters = filters?.filter(
      (f) => f.filterType === WorkQueueFilterType.AssignToFilter
    );
    if (assignToFilters) {
      const values = assignToFilters.map((t) => `AssignToFilters=${t.value}`);
      queryParams.push(...values);
    }
    const statusFilters = filters?.filter(
      (f) => f.filterType === WorkQueueFilterType.StatusFilter
    );
    if (statusFilters) {
      const values = statusFilters.map((t) => `StatusFilters=${t.value}`);
      queryParams.push(...values);
    }
    const categoryManagerFilters = filters?.filter(
      (f) => f.filterType === WorkQueueFilterType.CategoryManagerFilter
    );
    if (categoryManagerFilters) {
      const values = categoryManagerFilters.map(
        (t) => `CategoryManagerFilters=${t.code}`
      );
      queryParams.push(...values);
    }

    if (searchText) {
      queryParams.push(`search=${searchText}`);
    }

    queryParams.push(`sortname=${sortColumn}`)
    queryParams.push(`orderDirection=${sortDirection}`)
    queryParams.push(`page=${page}`);
    queryParams.push(`size=${size}`);

    const queryString = queryParams.join('&');
    return this.httpClient.get<WorkQueueTaskResponse>(
      `${environment.apiGateway}/work-queue/query/tasks?${queryString}`
    );
  }

  getTaskCount(): Observable<{ taskItemAssigned: number }> {
    return this.httpClient.get<{ taskItemAssigned: number }>(
      `${environment.apiGateway}/work-queue/query/tasks/count`
    );
  }
}
