import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DCPGListItem } from '../../supplier-dcpgs/models/dcpg-list-item';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DcpgListService {

  constructor(private readonly _httpClient: HttpClient) {}

  loadDCPGs(poModalSearch: string) {
    let params = new HttpParams()
      .set('POModalSearch', poModalSearch);

    return this._httpClient.get<{
      availableCount: number;
      data: DCPGListItem[];
    }>(`${environment.supplierApi}/api/dcproductgroups`, { params });
  }
}
