import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProductGroups from './store/product-groups.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProductGroupsEffects } from './store/product-groups.effects';
import { PgProductsModalComponent } from './components/pg-products-modal/pg-products-modal.component';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { GridModule } from '@progress/kendo-angular-grid';
import { PgBrandsModalComponent } from './components/pg-brands-modal/pg-brands-modal.component';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';
import { CloneDcpgModalComponent } from './components/clone-dcpg-modal/clone-dcpg-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FormFieldModule,
  InputsModule,
  MaskedTextBoxModule,
  NumericTextBoxModule,
  RadioButtonModule,
  TextAreaModule,
  TextBoxModule,
} from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  ComboBoxModule,
  DropDownsModule,
  MultiSelectModule,
} from '@progress/kendo-angular-dropdowns';
import { CloneDcpgFormComponent } from './components/clone-dcpg-form/clone-dcpg-form.component';
import { DcFormComponent } from './components/dc-form/dc-form.component';
import { BuyersFormComponent } from './components/buyers-form/buyers-form.component';
import { ProductGroupDeliveryFormComponent } from './components/product-group-delivery-form/product-group-delivery-form.component';
import { SupplierComponentsModule } from '../supplier-components/supplier-components.module';
import { PhoenixAddressModule } from '@kehe/phoenix-address';
import { PhoenixPhoneNumberModule } from '@kehe/phoenix-phone-number';
import { PhoenixTooltipModule } from '@kehe/phoenix-tooltip';
import { PoTransmissionsFormComponent } from './components/po-transmissions-form/po-transmissions-form.component';
import { PhoenixProgressTrackerModule } from '@kehe/phoenix-progress-tracker';
import { PhoenixAlertsModule } from '@kehe/phoenix-notifications';
import { BulkEditFormsService } from './services/bulk-edit-forms.service';
import { BulkEditDcpgModalComponent } from './components/bulk-edit-dcpg-modal/bulk-edit-dcpg-modal.component';
import { BulkEditSelectActionCardComponent } from './components/bulk-edit-select-action-card/bulk-edit-select-action-card.component';
import { BulkEditLeadTimesPickupAddressCardComponent } from './components/bulk-edit-lead-times-pickup-address-card/bulk-edit-lead-times-pickup-address-card.component';
import { AddressBookFormService } from '../../services/address-book-form.service';
import { BulkEditDeliveredLeadTimesCardComponent } from './components/bulk-edit-delivered-lead-times-card/bulk-edit-delivered-lead-times-card.component';
import { BulkEditNotesFormComponent } from './components/bulk-edit-notes-form/bulk-edit-notes-form.component';
import { BulkEditPickupContactComponent } from './components/bulk-edit-pickup-contact/bulk-edit-pickup-contact.component';
import { DeliveredAddressComponent } from './components/product-group-delivery-form/delivered-address/delivered-address.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { AddDeliveredContactComponent } from './components/product-group-delivery-form/delivered-address/add-delivered-contact/add-delivered-contact.component';
import { AddContactPreviewComponent } from './components/product-group-delivery-form/delivered-address/add-contact-preview/add-contact-preview.component';
import { BulkEditPoEmailComponent } from './components/bulk-edit-po-email/bulk-edit-po-email.component';
import { EmptyValuePipe } from '@app/shared/pipes/empty-value.pipe';
import { BulkEditLeadTimesDeliveredAddressCardComponent } from './components/bulk-edit-lead-times-delivered-address-card/bulk-edit-lead-times-delivered-address-card.component';

@NgModule({
  declarations: [
    PgProductsModalComponent,
    PgBrandsModalComponent,
    CloneDcpgModalComponent,
    CloneDcpgFormComponent,
    DcFormComponent,
    BuyersFormComponent,
    ProductGroupDeliveryFormComponent,
    PoTransmissionsFormComponent,
    BulkEditDcpgModalComponent,
    BulkEditSelectActionCardComponent,
    BulkEditLeadTimesPickupAddressCardComponent,
    BulkEditDeliveredLeadTimesCardComponent,
    BulkEditNotesFormComponent,
    BulkEditPickupContactComponent,
    DeliveredAddressComponent,
    AddDeliveredContactComponent,
    AddContactPreviewComponent,
    BulkEditPoEmailComponent,
    BulkEditLeadTimesDeliveredAddressCardComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('productGroups', fromProductGroups.reducer),
    EffectsModule.forFeature([ProductGroupsEffects]),
    PhoenixModalModule,
    PhoenixButtonModule,
    GridModule,
    PhoenixIndicatorsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    FormFieldModule,
    TextBoxModule,
    LabelModule,
    MultiSelectModule,
    ComboBoxModule,
    NumericTextBoxModule,
    SupplierComponentsModule,
    MaskedTextBoxModule,
    TextAreaModule,
    PhoenixAddressModule,
    RadioButtonModule,
    PhoenixPhoneNumberModule,
    InputsModule,
    PhoenixTooltipModule,
    PhoenixProgressTrackerModule,
    PhoenixAlertsModule,
    ButtonsModule,
    DropDownsModule,
    EmptyValuePipe,
  ],
  exports: [
    PgProductsModalComponent,
    PgBrandsModalComponent,
    CloneDcpgModalComponent,
    CloneDcpgFormComponent,
    ProductGroupDeliveryFormComponent,
    PoTransmissionsFormComponent,
    BulkEditDcpgModalComponent,
    DeliveredAddressComponent,
    AddDeliveredContactComponent,
    AddContactPreviewComponent,
  ],
  providers: [BulkEditFormsService, AddressBookFormService],
})
export class ProductGroupsModule {}
