import { Injectable } from '@angular/core';
import { BreakpointService } from '@kehe/phoenix-utils';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { selectBrokerDocument, SelectIsLoadDocumentsError } from '../../../../supplier-documents/store/supplier-documents.selectors';
import {
  downloadDocument,
  hideDocumentsAlertMessage,
} from '../../../../supplier-documents/store/supplier-documents.actions';
import { Document } from '../../../../supplier-documents/models/document';
import {
  selectSupplierDetailBroker,
} from '../../../store/supplier-detail.selectors';
import { BrokerGridRow, BrokerGridRowType } from '../broker-item.model';
import { HeadquarterBrokerApiStore } from './api-stores/headquarter-broker.api.store';
import { BrokerModal, BrokerTabStore } from '../broker-v2-tab.store';
import { RelatedNonHeadquarterBrokerApiStore } from './api-stores/related-non-headquarter-broker.api.store';

export interface DeleteBrokerRow {
  id: string;
  brokerName: string;
  type: BrokerGridRowType;
  document: Document;
}

export interface BrokerTabGridState {
  rowToDelete: DeleteBrokerRow;
}

export const initialState: BrokerTabGridState = {
  rowToDelete: null,
};

@Injectable()
export class BrokerTabGridStore extends ComponentStore<BrokerTabGridState> {
  private _headquarterBrokerRow = this.select(
    this._headquarterApiStore.data$,
    this._store.select(selectSupplierDetailBroker),
    (headquarterBroker, brokerAssociation): BrokerGridRow => {
      if (!headquarterBroker) {
        return;
      }
      const row: BrokerGridRow = {
        brokerName: headquarterBroker?.name,
        ebn: headquarterBroker?.esn,
        brokerContact: brokerAssociation?.contact?.name,
        tag: {
          text: 'Linked',
          bgColor: '#D5E48F',
          borderColor: '#D5E48F',
        },
        type: BrokerGridRowType.Headquarter,
        associatedOn: brokerAssociation?.created,
        document: null,
      };
      return row;
    }
  );

  readonly _showApiErrorPanel$ = this.select(
    this._nonHeadquarterApiStore.apiError$,
    this._headquarterApiStore.apiError$,
    this._store.select(SelectIsLoadDocumentsError),
    (
      nonHeadquaterApiError: string,
      headQuaterApiError: string,
      isDocumentLoadError: boolean
    ) => {
      return nonHeadquaterApiError || headQuaterApiError || isDocumentLoadError;
    }
  );

  public _nonHeadquarterBrokerRows$ = this.select(
    this._nonHeadquarterApiStore.data$,
    (nonHeadquarterBrokers) => {
      if (!nonHeadquarterBrokers) {
        return [];
      }
      return nonHeadquarterBrokers?.map((broker): BrokerGridRow => {
        return {
          brokerName: broker?.name,
          ebn: broker?.ebn,
          brokerContact: undefined,
          tag: {
            text: 'Linked',
            bgColor: '#D5E48F',
            borderColor: '#D5E48F',
          },
          type: BrokerGridRowType.NonHeadquarter,
          associatedOn: broker?.associatedOn,
          document: null,
        };
      });
    }
  );

  private _documentRow$ = this.select(
    this._store.select(selectBrokerDocument),
    (document) => {
      if (!document) {
        return;
      }

      const documentRow: BrokerGridRow = {
        brokerName: document.filename,
        ebn: undefined,
        brokerContact: undefined,
        tag: {
          text: 'Pending',
          bgColor: '',
          borderColor: '',
        },
        associatedOn: undefined,
        type: BrokerGridRowType.BrokerOfRecordForm,
        document,
      };

      return documentRow;
    }
  );

  private _isLoading$ = this.select(
    this._headquarterApiStore.isLoading$,
    this._nonHeadquarterApiStore.isLoading$,
    (isHeadquarterLoading, isNonHeadquaterLoading) => {
      return isHeadquarterLoading || isNonHeadquaterLoading;
    }
  );

  readonly gridRows$ = this.select(
    this._headquarterBrokerRow,
    this._nonHeadquarterBrokerRows$,
    this._documentRow$,
    this._showApiErrorPanel$,
    this._isLoading$,
    (
      headquarterRow,
      nonHeadquarterBrokerRows,
      documentRow,
      showApiErrorPanel,
      isLoading
    ) => {
      if (showApiErrorPanel || isLoading) {
        return [];
      } else {
        return [
          headquarterRow,
          ...nonHeadquarterBrokerRows,
          documentRow,
        ].filter((row) => Boolean(row));
      }
    }
  );

  readonly _gridBrokerRows$ = this.select(
    this._headquarterBrokerRow,
    this._nonHeadquarterBrokerRows$,
    (headquarterRow, nonHeadquarterBrokerRows) => {
      return [headquarterRow, ...nonHeadquarterBrokerRows].filter((row) =>
        Boolean(row)
      );
    }
  );

  readonly vm$ = this.select(
    this.state$,
    this._breakpointService.isMedAndBelow$,
    this.gridRows$,
    this._isLoading$,
    this._showApiErrorPanel$,
    (state, isMedAndBelowView, gridRows, isLoading, showApiErrorPanel) => ({
      ...state,
      isMedAndBelowView,
      gridRows,
      isLoading,
      emptyState: {
        header: 'No Brokers',
      },
      showApiErrorPanel,
    })
  );

  readonly _rowToDelete$ = this.select((store) => store.rowToDelete);

  constructor(
    private _breakpointService: BreakpointService,
    private _headquarterApiStore: HeadquarterBrokerApiStore,
    private _brokerTabStore: BrokerTabStore,
    private _nonHeadquarterApiStore: RelatedNonHeadquarterBrokerApiStore,
    private _store: Store
  ) {
    super(initialState);
  }

  // Methods
  showDeleteConfirmationModal(rowToDelete: DeleteBrokerRow) {
    this._store.dispatch(hideDocumentsAlertMessage());

    this.patchState({
      rowToDelete,
    });

    this._brokerTabStore.patchState({
      openedModal: BrokerModal.Delete,
    });
  }
  downloadBrokerOfRecordForm(document: Document) {
    this._store.dispatch(downloadDocument({ document, target: '_blank' }));
  }
}
