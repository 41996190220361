import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { brandsSearchChanged, brandsSearchCleared } from '../../store/supplier-detail.actions';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { brandsSearch, getFilteredBrands, selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { Destroyable } from '../../../../abstract/destroyable';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { BrandListItem } from '../../models/purchasing-options/brand-list-item';
import { environment } from '../../../../../environments/environment';
import { SupplierDetail } from '../../models/supplier-detail';

@Component({
  selector: 'app-brands-tab',
  templateUrl: './brands-tab.component.html',
  styleUrls: ['./brands-tab.component.scss']
})
export class BrandsTabComponent extends Destroyable implements OnInit {

  brands: BrandListItem[];
  searchControl = new UntypedFormControl('');
  supplier: SupplierDetail;

  public sort: SortDescriptor[] = [
    {
      field: 'name',
      dir: 'asc'
    }
  ];

  constructor(
    private _store: Store
  ) {
    super();
  }

  ngOnInit() {
    this._store
    .pipe(takeUntil(this.destroy$), select(selectSupplierDetail))
    .subscribe((value) => {
      this.supplier = value;
    });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getFilteredBrands))
      .subscribe((val) => {
        this.brands = val;
      });

    this._store
      .pipe(
        takeUntil(this.destroy$),
        select(brandsSearch))
      .subscribe((search) => {
        this.searchControl.setValue(search);
      });

    this.searchControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.destroy$))
    .subscribe(value => {
      if (value) {
        this._store.dispatch(brandsSearchChanged({ search: value }));
      } else {
        this._store.dispatch(brandsSearchCleared());
      }
    });
  }

  onSearchChange(value: string) {
    if (value) {
      this._store.dispatch(brandsSearchChanged({ search: value }));
    } else {
      this._store.dispatch(brandsSearchCleared());
    }
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.brands = orderBy(this.brands, this.sort);
  }

  goToVendorBrandScoreCardV1(): void {
    window.open(`${environment.v1ConnectHost}/vendormanagement/#/landingPage/vendorDetails/vendorBrandScorecard?esn=${this.supplier.esn}&userType=keheemployee`, '_blank');
  }
}
