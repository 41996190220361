<kehe-phoenix-modal
  [show]="true"
  modalTitle="Add User"
  (modalEvent)="close($event)"
  size="sm"
>
  <div body class="body p-1">
    <kehe-phoenix-progress-tracker containerBreakWidth="0" [steps]="steps">
    </kehe-phoenix-progress-tracker>
    <kehe-phoenix-alert
      [type]="alertTypes.Error"
      [message]="errorAlert"
      *ngIf="errorAlert"
      [hideCloseIcon]="true"
      class="mt-3 d-inline-block"
      stick="true"
      show="true"
    >
    </kehe-phoenix-alert>
    <div class="kehe-data-value mt-2 mb-3" *ngIf="showHint">
      Please add supplier users only. Broker users are managed in the broker
      tab.
    </div>
    <div [formGroup]="form" *ngIf="form">
      <div [class.mb-3]="canShowUserInfo">
        <kendo-formfield>
          <kendo-label [for]="email" text="User Email"></kendo-label>
          <kendo-textbox
            #email
            [readonly]="canShowUserInfo"
            formControlName="email"
            placeholder="Enter User Email"
          >
          </kendo-textbox>
          <kendo-formerror *ngIf="emailField?.errors?.required">
            User Email is required.
          </kendo-formerror>
          <kendo-formerror *ngIf="emailField?.errors?.email">
            Invalid email format
          </kendo-formerror>
          <kendo-formerror *ngIf="emailField?.errors?.alreadyExists">
            User is already associated to the ESN.
          </kendo-formerror>
          <kendo-formerror *ngIf="emailField?.errors?.nonSupplier">
            Broker user can not be added.
          </kendo-formerror>
          <kendo-formerror *ngIf="emailField?.errors?.keheEmployee">
            Supplier users can only be added.
          </kendo-formerror>
          <kendo-formerror *ngIf="emailField?.errors?.userDisabled">
            User can not be added.
          </kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="mb-3" *ngIf="canShowUserInfo">
        <kendo-formfield>
          <kendo-label [for]="firstNameField" text="First Name"></kendo-label>
          <kendo-textbox
            #firstNameField
            formControlName="firstName"
            placeholder="Enter First Name"
            [readonly]="prepopulatedUser"
          >
          </kendo-textbox>
          <kendo-formerror> First Name is required. </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="mb-3" *ngIf="canShowUserInfo">
        <kendo-formfield>
          <kendo-label [for]="lastNameField" text="Last Name"></kendo-label>
          <kendo-textbox
            #lastNameField
            formControlName="lastName"
            placeholder="Enter Last Name"
            [readonly]="prepopulatedUser"
          >
          </kendo-textbox>
          <kendo-formerror> Last Name is required. </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="mb-3" *ngIf="canShowUserInfo">
        <kendo-formhint class="d-block mb-2">
          <strong>Permissions</strong>
          <br />
          Only a KeHE CONNECT Supplier<sup>TM</sup> Admin can add or delete
          users as well as grant admin access to other users.
        </kendo-formhint>
        <input
          type="checkbox"
          #isAdminField
          kendoCheckBox
          formControlName="isAdmin"
        />
        <kendo-label class="k-checkbox-label" [for]="isAdminField"
          >Assign CONNECT Supplier<sup>TM</sup> Admin</kendo-label
        >
      </div>
    </div>
  </div>

  <div footer class="d-flex justify-content-end px-1" *ngIf="!canShowUserInfo">
    <kehe-phoenix-button classAttr="btn-link btn-medium mr-2" (click)="close()">
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button
      classAttr="btn-primary btn-medium"
      (click)="onNextClicked()"
      [disabled]="!isNextButtonEnabled"
      [spinner]="loading"
    >
      Next
    </kehe-phoenix-button>
  </div>

  <div footer class="d-flex justify-content-end px-1" *ngIf="canShowUserInfo">
    <kehe-phoenix-button classAttr="btn-link btn-medium mr-2" (click)="back()">
      Back
    </kehe-phoenix-button>
    <kehe-phoenix-button
      classAttr="btn-primary btn-medium"
      (click)="onAddClicked()"
      [disabled]="form.invalid || adding"
      [spinner]="adding"
    >
      Add
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
