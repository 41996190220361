<kehe-phoenix-modal *ngIf="(showAddContactPreviewModal$ | async)  === false" name="addPurchasingOptionContact"
  [modalTitle]="modalTitle" [show]="true" (modalEvent)="closeModal($event)" size="md">
  <div body>
    <div [formGroup]="addAddressForm">
      <kehe-phoenix-alert [show]="(showSupplierContactError$ | async)" [type]="'error'"
        message="{{supplierContactErrorMessage}}" [stick]="true" [hideCloseIcon]="true"></kehe-phoenix-alert>
      <div class="heading mt-3 mb-3">{{addressTitle}}</div>

      <kehe-address-form [addressForm]="addressForm" [shouldDisplayNameField]="true" [shouldDisplayAttention]="true">
      </kehe-address-form>

      <kendo-formfield>
        <kendo-label [for]="pickupAddressNotes" text="Delivered Address Notes (optional)"></kendo-label>
        <kendo-textarea #pickupAddressNotesField formControlName="pickupAddressNotes" [maxlength]="30"
          placeholder="Enter Delivered Address notes." [resizable]="'none'">
          <kendo-textarea-suffix class="d-flex justify-content-end kehe-textarea-counter">
            <span class="ms-auto">{{ pickupAddressNotesCounter }}/30</span>
          </kendo-textarea-suffix>
        </kendo-textarea>
      </kendo-formfield>
      <div class="heading mt-3 mb-3">{{contactTitle}}</div>
      <div formGroupName="contact">

        <div class="d-flex justify-content-between">
          <div class="pe-2">
            <kehe-phoenix-phone-number-input [required]="true" tabOrder="2" name="phone" label="Phone"
              formControlName="phone">
            </kehe-phoenix-phone-number-input>
          </div>
          <div>
            <kendo-formfield>
              <kendo-label text="Ext (optional)"></kendo-label>
              <kendo-numerictextbox formControlName="phoneExtension" [format]="'#'" placeholder="e.g. 00000"
                [spinners]="false" [min]="0" maxlength="5" [pattern]="phoneExtPattern"></kendo-numerictextbox>
              <kendo-formerror>Invalid ext. format</kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
        <div class="form-group">
          <kehe-phoenix-phone-number-input tabOrder="4" name="fax" label="Fax (optional)" formControlName="fax">
          </kehe-phoenix-phone-number-input>
        </div>

        <div class="form-group">
          <kendo-formfield>
            <kendo-label text="Email (optional)"></kendo-label>
            <kendo-textbox formControlName="email" placeholder="e.g. contact@email.com" maxlength="100"
              [pattern]="emailPattern"> </kendo-textbox>
            <kendo-formerror>Invalid email format</kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
    </div>
  </div>
  <div footer class="d-flex flex-row justify-content-end">
    <kehe-phoenix-button (click)="closeModal()" class="modal-btn-spacing me-2" classAttr="btn-medium btn-link"
      [disabled]="isAddingAddress">Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button *ngIf="showAddButton" name="addPickupAddress" classAttr="btn-primary btn-modal btn-medium"
      (click)="handleAddClicked()" [disabled]="disableAddAndUpdate" [spinner]="isAddingAddress">
      Add
    </kehe-phoenix-button>
    <kehe-phoenix-button *ngIf="showUpdateButton" name="updatePickupAddress"
      classAttr="btn-primary btn-modal btn-medium" (click)="handleUpdateClicked()" [disabled]="disableAddAndUpdate"
      [spinner]="isAddingAddress">
      Update
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>

<app-add-contact-preview *ngIf="showAddContactPreviewModal$ | async">
</app-add-contact-preview>