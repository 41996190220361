import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import * as AuthenticationActions from "@app/authentication/authentication.actions";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Store } from '@ngrx/store';
import { PendoService } from '@app/pendo/pendo.service';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { Visitor } from '@app/pendo/interfaces/pendo.interfaces';
import * as ProductDetailActions  from '@app/modules/product-detail/store/product-detail.actions';
import * as SupplierDetailActions  from '@app/modules/supplier-detail/store/supplier-detail.actions';
import * as SupplierDocumentsActions  from '@app/modules/supplier-documents/store/supplier-documents.actions';
import * as SupplierListActions  from '@app/modules/supplier-list/store/supplier-list.actions';
import { PendoTrackEventAction } from '@app/pendo/interfaces/pendo-track-event-action.interface';


/** Google Analytics function, defined in our index.html file */
declare const gtag: Function;

@Injectable()
export class MonitoringEffects {
  constructor(
    private actions$: Actions,
    private injector: Injector,
    private store: Store,
    private pendoService: PendoService
  ) {}

  setUpGoogleAnalytics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActions.logInSuccess),
        filter(() => !!environment.googleAnalyticsTrackingId),
        tap(() => {
          const gtagScript: HTMLScriptElement =
            document.createElement('script');
          gtagScript.async = true;
          gtagScript.src =
            'https://www.googletagmanager.com/gtag/js?id=' +
            environment.googleAnalyticsTrackingId;
          document.head.prepend(gtagScript);
          gtag('config', environment.googleAnalyticsTrackingId);
        })
      ),
    { dispatch: false }
  );

  setUpGoogleTagManager$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logInSuccess),
        filter(() => !!environment.googleTagManager),
        tap(() => {
          const gtmService = <GoogleTagManagerService>(
            this.injector.get(GoogleTagManagerService)
          );
          gtmService.addGtmToDom();
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Init func for Pendo.
   */
  setUpPendo$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.getUserDetailSuccess),
        withLatestFrom(
          this.store.select(AuthenticationSelectors.selectUserEmail),
          this.store.select(AuthenticationSelectors.selectIsInternalUser),
          this.store.select(AuthenticationSelectors.selectUserDetail)
        ),
        tap(([action, userEmail, isInternalUser, userDetails]) => {
          const visitorGroups = userDetails.groups.map((group) => group.name);
          const visitor: Visitor = {
            id: userEmail,
            isInternalUser: isInternalUser,
            title: userDetails.title,
            groups: visitorGroups,
          };

          this.pendoService.initializePendo(visitor);
        })
      );
    },
    { dispatch: false }
  );

    trackPendoEvent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ProductDetailActions.approveNewProductSuccess,
          ProductDetailActions.activateProductSuccess,
          SupplierDetailActions.updateGeneralTabStatusSuccess,
          SupplierDetailActions.updateCanUploadSIFSuccess,
          SupplierDocumentsActions.updateDocumentStatusSuccess,
          SupplierDetailActions.submitSupplierSuccess,
          SupplierListActions.inviteSupplierSuccess,
          SupplierListActions.addSupplierSuccess
        ),
        tap((action: PendoTrackEventAction) => {
          this.pendoService.trackEvent(
            action.pendoEvent.name,
            action.pendoEvent.eventProperties
          );
        })
      );
    },
    { dispatch: false }
  );


}