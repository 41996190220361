import { State } from './../modules/broker-detail/models/state';

import { Country } from './country';


export class Address {

  constructor(
    public name: string,
    public street1: string,
    public street2: string,
    public city: string,
    public state: State,
    public province: string,
    public zipCode: string,
    public country: Country,
    public phone: string,
    public extension: string,
    public fax: string,
    public email: string,
    public attention?: any,
    public formattedAddress?: string,
    public uniqueId?: string,
  ) {}

}
