import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {

  constructor() {  
     // This is intentional
  }

}
