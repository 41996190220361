<kehe-phoenix-modal name="AddProductGroup" [modalTitle]="title" [show]="true"
                    (modalEvent)="handleKeHEModalEvents($event)">
  <div body>
    <div class="row">
      <div class="col">
        <h5>PO Transmissions</h5>
      </div>
    </div>
    <div class="row">
      <div class="col kehe-grid no-scroll p-0 w-100">
        <kendo-grid [data]="poTransData">
          <kendo-grid-column field="type" title="Type" filter="string" [sortable]="false" [width]="150">
          </kendo-grid-column>
          <kendo-grid-column field="value" title="Fax/Email" filter="string" [sortable]="false">
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button *ngIf="!hideEdit" class="modal-btn-spacing" classAttr="btn-secondary btn-medium" (click)="edit()">Edit</kehe-phoenix-button>
    <kehe-phoenix-button class="modal-btn-spacing" classAttr="btn-medium btn-primary" (click)="closeModal()">Close</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
