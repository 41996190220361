import { createAction, props } from "@ngrx/store";

export const openClicked = createAction(
    '[Export Hierarchies Modal] - Open Modal'
);

export const closeClicked = createAction(
    '[Export Hierarchies Modal] - Close Modal'
);

export const exportClicked = createAction(
    '[Export Hierarchies Modal] - Export Clicked'
);

export const exportFileSuccess = createAction(
    '[Export Hierarchies Modal] - Export File Success'
);

export const exportFileFailure = createAction(
    '[Export Hierarchies Modal] - Export File Failure',
    props<{ errorMessage: string }>()
);