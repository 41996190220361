import { Component, OnDestroy, OnInit } from '@angular/core';
import { Destroyable } from '../../../../abstract/destroyable';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
  getEditNewAddDCProductGroup,
  getSelectedProductGroupView,
  selectEditMode
} from '../../store/supplier-detail.selectors';
import { onProductGroupsDetailsView } from '../../store/supplier-detail.actions';
import { ProductGroupViews } from '../../models/product-group-views';

@Component({
  selector: 'app-product-groups-tab',
  templateUrl: './product-groups-tab.component.html',
  styleUrls: []
})
export class ProductGroupsTabComponent extends Destroyable implements OnInit, OnDestroy {
  isEdit = false;
  editNewAddDCProductGroup = false;
  selectedProductGroupView = null;
  views = ProductGroupViews;

  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store.dispatch(onProductGroupsDetailsView({show : true}));
    this._store.select(selectEditMode)
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.isEdit = val;
      });
    this._store
      .select(getSelectedProductGroupView)
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.selectedProductGroupView = val;
      });
    this._store
      .select(getEditNewAddDCProductGroup)
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.editNewAddDCProductGroup = val;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._store.dispatch(onProductGroupsDetailsView({show : false}));
  }
}
