import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { KehePipesModule } from '@kehe/phoenix-utils';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DcPaymentHoldTabComponent } from './components/dc-payment-hold-tab/dc-payment-hold-tab.component';
import { DcPaymentHoldGridComponent } from './components/dc-payment-hold-grid/dc-payment-hold-grid.component';
import { DcPaymentHoldFormComponent } from './components/dc-payment-hold-form/dc-payment-hold-form.component';

import { DcPaymentHoldsService } from './services/dc-payment-holds.service';

import { SupplierDcPaymentHoldsEffects } from './store/supplier-dc-payment-holds.effects';

import * as fromSupplierDcPaymentHolds from './store/supplier-dc-payment-holds.reducer';

@NgModule({
  declarations: [
    DcPaymentHoldTabComponent,
    DcPaymentHoldGridComponent,
    DcPaymentHoldFormComponent,
  ],
  imports: [
    CommonModule,
    GridModule,
    InputsModule,
    FormsModule,
    KehePipesModule,
    StoreModule.forFeature(
      fromSupplierDcPaymentHolds.supplierDcPaymentHoldsStateKey,
      fromSupplierDcPaymentHolds.rootReducer,
    ),
    EffectsModule.forFeature([
      SupplierDcPaymentHoldsEffects
    ])
  ],
  exports: [DcPaymentHoldTabComponent],
  providers: [DcPaymentHoldsService],
})
export class SupplierDcPaymentHoldsModule {}
