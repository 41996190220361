import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { userNotificationReducer } from './store/user-notifications.reducer';
import { UserNotificationsEffects } from './store/user-notifications.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('userNotifications', userNotificationReducer),
    EffectsModule.forFeature([UserNotificationsEffects])
  ]
})
export class UserNotificationsStoreModule {}
