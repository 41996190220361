import { Injectable } from '@angular/core';
import { BreakpointService } from '@kehe/phoenix-utils';
import { ComponentStore } from '@ngrx/component-store';
import { AssociatedSuppliersApiStore } from '../api-stores/associated-suppliers.api.store';
import { AssociatedSupplierRow } from '../../../models/associated-suppliers-response';
import { Observable } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectSupplierEsn } from '../../../store/supplier-detail.selectors';
import { SortDescriptor } from '@progress/kendo-data-query';

export interface AssociatedSuppliersTabGridState {
  selectedEsns: string[];
  collapsedEsns: Array<string>;
  rowsToDelete: AssociatedSupplierRow[];
  expandAll: boolean;
  checkAllChecked: boolean;
  showDeleteConfirmationModal: boolean;
  isSyncing: boolean;
  sort: SortDescriptor[];
}

export const initialState: AssociatedSuppliersTabGridState = {
  selectedEsns: [],
  collapsedEsns: [],
  rowsToDelete: [],
  expandAll: false,
  checkAllChecked: false,
  showDeleteConfirmationModal: false,
  isSyncing: false,
  sort: [{ dir: 'asc', field: ''}]
};

@Injectable()
export class AssociatedSuppliersTabGridStore extends ComponentStore<AssociatedSuppliersTabGridState> {
  constructor(
    private _breakpointService: BreakpointService,
    public _associatedSuppliersApiStore: AssociatedSuppliersApiStore,
    private _store: Store
  ) {
    super(initialState);
  }

  private _currentSupplierEsn$ = this._store.select(selectSupplierEsn);

  private _selectedEsns$ = this.select((state) => state.selectedEsns);

  public _showDeleteConfirmationModal$ = this.select(
    (state) => state.showDeleteConfirmationModal
  );

  isSyncing$ = this.select((state) => state.isSyncing);

  sort$ = this.select((state) => state.sort);

  public _gridRows$ = this.select(
    this._currentSupplierEsn$,
    this._associatedSuppliersApiStore.data$,
    (currentSupplierEsn, data) => {
      if (data) {
        return data?.associatedSuppliers.filter(
          (associatedSupplier) =>
            associatedSupplier.relationshipFrom === currentSupplierEsn
        );
      }
    }
  );

  private _checkAllChecked$ = this.select(
    this._gridRows$,
    this._selectedEsns$,
    (allRows, selectedEsns) => {
      return (
        allRows?.length === selectedEsns?.length && selectedEsns?.length > 0
      );
    }
  );

  readonly _rowsToDelete$ = this.select((state) => state.rowsToDelete);

  private rowsWithSelection = (
    associatedSuppliersRows: AssociatedSupplierRow[],
    selectedEsns: string[]
  ) => {
    return associatedSuppliersRows?.map((associatedSupplierRow) => {
      const isSelected = selectedEsns?.indexOf(associatedSupplierRow.esn) >= 0;
      return {
        ...associatedSupplierRow,
        isSelected,
      };
    });
  };

  private getEmptyRow(): AssociatedSupplierRow {
    return {
      esn: '',
      name: 'sync',
      location: '',
      status: '',
      relationshipFrom: '',
      dateAssociated: '',
      tag: {
        text: '',
        bgColor: '',
        borderColor: '',
      },
      children: [],
    };
  }

  private addEmptyRow = (
    associatedSuppliersRows: AssociatedSupplierRow[],
    isSyncing: boolean
  ) => {
    const emptyRow: AssociatedSupplierRow = this.getEmptyRow();
    if (isSyncing) {
      if (!associatedSuppliersRows || associatedSuppliersRows.length === 0) {
        associatedSuppliersRows = [emptyRow];
      } else {
        associatedSuppliersRows = [emptyRow].concat(associatedSuppliersRows);
      }
    }
    return associatedSuppliersRows;
  };

  private _isLoading$ = this.select(
    this._associatedSuppliersApiStore.isLoading$,
    (isAssociatedSuppliersLoading) => {
      return isAssociatedSuppliersLoading;
    }
  );

  associatedSuppliersRows$ = this.select(
    this._gridRows$,
    this._selectedEsns$,
    this.isSyncing$,
    (associatedSuppliers, selectedEsns, isSyncing): AssociatedSupplierRow[] => {
      let suppliers = this.rowsWithSelection(associatedSuppliers, selectedEsns);
      return this.addEmptyRow(suppliers, isSyncing);
    }
  );

  public _selectedRecordsCount$ = this.select(
    (state) => state.selectedEsns.length
  );

  readonly _showApiErrorPanel$ = this.select(
    this._associatedSuppliersApiStore.apiError$,
    (associatedSuppliersApiError: string) => {
      return associatedSuppliersApiError;
  });

  private _collapsedEsns$ = this.select((state) => state.collapsedEsns);

  private _expandedEsns$ = this.select(
    this.associatedSuppliersRows$,
    this._collapsedEsns$,
    (allAssociatedSuppliers, collapsedEsns) => {
      if (allAssociatedSuppliers) {
        return allAssociatedSuppliers
          .filter(
            (associatedSupplier) =>
              collapsedEsns?.indexOf(associatedSupplier?.esn) < 0 &&
              associatedSupplier?.children?.length > 0
          )
          .map((filteredSupplier) => filteredSupplier.esn);
      }
    }
  );

  private _parentRowsCount$ = this.select(
    this._associatedSuppliersApiStore.data$,
    (data) => data?.associatedSuppliers?.length
  );

  readonly vm$ = this.select(
    this.state$,
    this._breakpointService.isMedAndBelow$,
    this._breakpointService.isLargeAndUp$,
    this.associatedSuppliersRows$,
    this._isLoading$,
    this._selectedRecordsCount$,
    this._expandedEsns$,
    this._collapsedEsns$,
    this._checkAllChecked$,
    this.sort$,
    this._showApiErrorPanel$,
    (
      state,
      isMedAndBelowView,
      isLargeAndUpView,
      associatedSuppliersRows,
      isLoading,
      selectedRecordsCount,
      expandedEsns,
      collapsedEsns,
      allChecked,
      sort,
      showApiErrorPanel
    ) => ({
      ...state,
      isMedAndBelowView,
      isLargeAndUpView,
      associatedSuppliersRows,
      emptyState: {
        header: 'No Results Found',
      },
      isLoading,
      selectedRecordsCount,
      expandedEsns,
      collapsedEsns,
      allChecked,
      sort,
      showApiErrorPanel
    })
  );

  handleRowSelection$ = this.effect((row$: Observable<AssociatedSupplierRow>) =>
    row$.pipe(
      withLatestFrom(this._selectedEsns$, this._parentRowsCount$),
      tap(
        ([row, selectedEsns, parentRowsCount]: [
          AssociatedSupplierRow,
          string[],
          number
        ]) => {
          const newEsns = [...selectedEsns];

          if (selectedEsns?.find((esn) => esn === row.esn)) {
            // Selected, remove selection
            const index = newEsns.indexOf(row.esn);
            newEsns.splice(index, 1);
          } else {
            // Not Selected, mark as selected
            newEsns.push(row.esn);
          }

          this.patchState({
            selectedEsns: newEsns,
            checkAllChecked: newEsns.length === parentRowsCount,
          });
        }
      )
    )
  );
  collapseAssociatedSupplier$ = this.effect(
    (esnToCollapse$: Observable<string>) =>
      esnToCollapse$.pipe(
        withLatestFrom(this._collapsedEsns$),
        tap(([esnToCollapse, collapsedEsns]: [string, string[]]) => {
          const newCollapsedEsns = [...collapsedEsns, esnToCollapse];
          this.patchState({
            collapsedEsns: newCollapsedEsns,
          });
        })
      )
  );

  expandAssociatedSupplier$ = this.effect((esnToExpand$: Observable<string>) =>
    esnToExpand$.pipe(
      withLatestFrom(this._collapsedEsns$),
      tap(([esnToExpand, collapsedEsns]: [string, string[]]) => {
        const newCollapsedEsns = collapsedEsns?.filter(
          (esn) => esn !== esnToExpand
        );
        this.patchState({
          collapsedEsns: newCollapsedEsns,
        });
      })
    )
  );

  collapseAllAssociatedSuppliers$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(this._gridRows$),
      tap(([_, associatedSuppliers]: [void, AssociatedSupplierRow[]]) => {
        const newCollapsedEsns = associatedSuppliers
          .filter(
            (associatedSupplier) => associatedSupplier.children?.length > 0
          )
          .map((item) => item?.esn);
        this.patchState({
          collapsedEsns: newCollapsedEsns,
        });
      })
    )
  );

  expandAllAssociatedSuppliers$ = this.effect((_) =>
    _.pipe(
      tap(() => {
        this.patchState({
          collapsedEsns: [],
        });
      })
    )
  );

  toggleCheckAll$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(this._gridRows$, this._checkAllChecked$),
      tap(
        ([_, dataRows, checkAllChecked]: [
          void,
          AssociatedSupplierRow[],
          boolean
        ]) => {
          if (checkAllChecked) {
            // Mark All as Unchecked
            this.patchState({
              selectedEsns: [],
              checkAllChecked: false,
            });
          } else {
            // Mark All as Checked
            const newSelectedEsns = dataRows.map(
              (associatedSupplierRow) => associatedSupplierRow.esn
            );
            this.patchState({
              selectedEsns: newSelectedEsns,
              checkAllChecked: true,
            });
          }
        }
      )
    )
  );

  showDeleteConfirmationModal(rowToDelete: AssociatedSupplierRow) {
    const rowsToDelete: AssociatedSupplierRow[] = [rowToDelete];

    this.patchState({
      rowsToDelete,
      showDeleteConfirmationModal: true,
    });
  }

  showDeleteConfirmationModalBulk$ = this.effect((_) =>
    _.pipe(
      withLatestFrom(this._selectedEsns$, this._gridRows$),
      tap(
        ([_, selectedEsns, allRows]: [
          void,
          string[],
          AssociatedSupplierRow[]
        ]) => {
          const rowsToDelete = allRows.filter((associatedSupplierRow) =>
            selectedEsns.some((esn) => esn === associatedSupplierRow.esn)
          );

          this.patchState({
            rowsToDelete,
            showDeleteConfirmationModal: true,
          });
        }
      )
    )
  );

  closeModal() {
    this.patchState({
      rowsToDelete: null,
      showDeleteConfirmationModal: null,
    });
  }

  deletedAssociatedSuppliers(esns: string[]) {
    this._associatedSuppliersApiStore.deleteAssociation$(esns);
    this.closeModal();
  }

  updateExpandAll = this.updater((state, expandAll: boolean) => ({
    ...state,
    expandAll,
  }));

  setIsSyncing(isSyncing: boolean) {
    this.patchState({
      isSyncing: isSyncing,
    });
  }

  updateSortOptions = this.updater((state, sort: SortDescriptor[]) => ({
    ...state,
    sort,
  }));
}
