<kehe-phoenix-modal
  *ngIf="vm$ | async as vm"
  name="AddBroker"
  modalTitle="Add Broker"
  [show]="true"
  (modalEvent)="handleKeheModalEvents($event)"
  size="md"
  [formGroup]="form"
>
  <div body class="add-broker-body">
    <kehe-phoenix-alert *ngIf="vm.apiErrorMessage; else formAlert" [show]="true" type="error" [message]="vm.apiErrorMessage" [stick]="true"
      automationId="add-broker-v2-modal-error"
      [hideCloseIcon]="true" class="mb-3 api-alert">
    </kehe-phoenix-alert>

    <ng-template #formAlert>
      <kehe-phoenix-alert *ngIf="formErrorText" [show]="true" type="error" [message]="formErrorText" [stick]="true"
        automationId="add-broker-v2-modal-form-error"
        [hideCloseIcon]="true" class="mb-3 form-alert">
      </kehe-phoenix-alert>
    </ng-template>

    <kehe-phoenix-alert
      *ngIf="vm.warningMessage"
      [show]="true"
      type="warning"
      [message]="vm.warningMessage"
      automationId="add-broker-v2-modal-warning"
      [stick]="true"
      [hideCloseIcon]="true"
      class="mb-3"
    >
    </kehe-phoenix-alert>

    <p class="mb-0">
      <strong>Broker Type </strong>
      <span>
        <em
          class="fas fa-exclamation-circle info-icon"
          keheTooltipPlacement="right"
          [keheTooltip]="vm.infoIconTooltip"
        >
        </em>
      </span>
    </p>

    <ul class="k-radio-list">
      <li
        class="k-radio-item"
        *ngFor="let brokerTypeRadio of vm.brokerTypeRadios"
      >
        <input
          type="radio"
          [id]="brokerTypeRadio.id"
          kendoRadioButton
          [attr.data-automation-id]="getAutomationId(brokerTypeRadio.value, '-radio-input')"
          [value]="brokerTypeRadio.value"
          [formControlName]="controlName.BrokerType"
        />
        <kendo-label
          class="k-radio-label"
          [for]="brokerTypeRadio.id"
          [text]="brokerTypeRadio.text"
        ></kendo-label>
      </li>
    </ul>

    <div class="mt-3" *ngIf="vm.isHeadquarterBroker">
      <kendo-formfield>
        <kendo-label text="Broker"></kendo-label>
        <kendo-combobox
          [formControlName]="controlName.HeadquarterBroker"
          [data]="vm.headquarterBrokers"
          placeholder="Search by Broker name or number"
          data-automation-id="add-broker-v2-modal-broker-input"
          [loading]="vm.isLoadingBrokers"
          [filterable]="true"
          textField="name"
          valueField="ebn"
          (filterChange)="headquarterBrokerFilterChanged($event)"
        >
        </kendo-combobox>
        <kendo-formerror
          >Broker is required.</kendo-formerror
        >
      </kendo-formfield>

      <div *ngIf="vm.hasHeadquarterBroker" class="mt-3">
        <kendo-formfield>
          <kendo-label text="Broker Contact (Optional)"></kendo-label>
          <kendo-combobox
            [data]="vm.brokerContacts"
            placeholder="Search contact by name or email"
            [loading]="vm.isLoadingBrokerContacts"
            data-automation-id="add-broker-v2-modal-broker-contact-input"
            [filterable]="true"
            textField="title"
            valueField="id"
            (filterChange)="brokerContactFilterChanged($event)"
            [formControlName]="controlName.HeadquarterContact"
          >
          </kendo-combobox>
          <kendo-formhint
            >The Broker contact will be copied on all email
            notifications.</kendo-formhint
          >
          <kendo-formerror
            >If you're unable to find your broker contact, reach out to your
            brokerage for assistance. Alternatively, select the box below to
            continue.</kendo-formerror
          >
        </kendo-formfield>
      </div>
    </div>

    <div class="mt-3" *ngIf="vm.nonHeadquarterRadioSelected">
      <kendo-formfield>
        <kendo-label text="Broker"></kendo-label>
        <kendo-combobox
          [formControlName]="controlName.nonHeadquarterBroker"
          [data]="vm.nonHeadquarterBrokers"
          placeholder="Search by Broker name or number"
          [loading]="vm.isLoadingNonHQBrokers"
          data-automation-id="add-broker-v2-modal-non-hq-broker-input"
          [filterable]="true"
          textField="name"
          valueField="ebn"
          (filterChange)="nonHeadquarterBrokerFilterChanged($event)"
        >
        </kendo-combobox>
        <kendo-formerror
          >Broker is required.</kendo-formerror
        >
      </kendo-formfield>
    </div>

    <app-add-note *ngIf="!vm.isDraftSupplier && (vm.hasHeadquarterBroker || vm.hasNonHeadquarterBroker)"></app-add-note>
  </div>
  <div footer class="d-flex justify-content-end px-1">
    <kehe-phoenix-button
      class="cancel-button"
      classAttr="btn-medium btn-link"
      (click)="close()"
      automationId="add-broker-v2-modal-cancel-button"
      [disabled]="vm.isSubmitting"
    >
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button
      classAttr="btn-medium btn-primary ms-2"
      (click)="submit()"
      [spinner]="vm.primaryButton.isSpinning"
      [automationId]="getAutomationId(vm.primaryButton.text, '-button')"
      [disabled]="vm.primaryButton.isDisabled"
    >
      {{ vm.primaryButton.text }}
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
