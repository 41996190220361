<div class="row">
  <div class="col">
    <h5>Distribution Centers</h5>
  </div>
</div>

<div *ngIf="form" [formGroup]="form">
  <div class="col-12 col-md-6 p-0">
    <kendo-formfield>
      <kendo-label text="Clone from DC Product Group"></kendo-label>
      <kendo-textbox [readonly]="true" class="kehe-readonly-input" formControlName="cloneFromDC"></kendo-textbox>
    </kendo-formfield>
  </div>
  <div class="col-12 col-md-6 mt-3 p-0">
    <kendo-formfield>
      <kendo-label text="Distribution Centers"></kendo-label>
      <kendo-multiselect
        [data]="dcList$ | async"
        [filterable]="true"
        [kendoDropDownFilter]="{ caseSensitive: false, operator: 'contains' }"
        textField="label"
        valueField="value"
        formControlName="cloneToDCs"
        placeholder="Select Distribution Centers"
      ></kendo-multiselect>
      <kendo-formerror
        *ngIf="form.controls.cloneToDCs.hasError('required')"
      >
        Distribution Centers is required.
      </kendo-formerror>
    </kendo-formfield>
  </div>
</div>
