<kehe-phoenix-modal [show]="true"
                    [modalTitle]=title
                    (modalEvent)="closeModal()"
                    size="md">
  <div body class="p-1">
    <kehe-phoenix-alert [type]="alertTypes.Error" [message]="onboardingStatusChangeError"
                    *ngIf="onboardingStatusChangeError" [hideCloseIcon]="true" [stick]="true" [show]="true">
    </kehe-phoenix-alert>
    <div *ngIf="isApproveGeneralTab">
      <kehe-phoenix-alert [type]="alertTypes.Warning" [message]="alertMessage"
                      [hideCloseIcon]="true" [stick]="true" [show]="true">
      </kehe-phoenix-alert>
      <p>
        You are about to approve the supplier’s Legal Name, Headquarter Address, and W9 Form.
        The legal name will be used to create the contract documents for signature in DocuSign.
      </p>
      <p>
        Are you sure you would like to approve the Legal Name, Headquarter Address, and W9 Form?
      </p>
      <div class="col">
        <div class="row mt-1">
          <span class="col-3 col-sm-3 p-0"><strong>Legal Name:</strong></span>
          <span class="col-9 col-sm-9 kehe-data-value">{{defaultIfEmpty(supplier?.name)}}</span>
        </div>

        <div class="row mt-2 mb-2">
          <span class="col-3 col-sm-3 p-0"><strong>Document:</strong></span>
          <span class="col-9 col-sm-9 kehe-data-value">
            <a (click)="downloadW9()" *ngIf="document?.filename; else defaultIfEmptyValue">
              {{defaultIfEmpty(document?.filename)}}
            </a>
            <ng-template #defaultIfEmptyValue>
              --
            </ng-template>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="isRejectGeneralTab || isShowRevisionNotes">
      <kehe-phoenix-alert [type]="alertTypes.Warning" [message]="rejectAlreadyApprovedMessage"
                      [hideCloseIcon]="true" [stick]="true" [show]="true">
      </kehe-phoenix-alert>
      <p>
        You have chosen to reject the supplier’s Legal Name, Headquarter Address, or W9 Form.
        Add a note explaining the reason.
        This will be visible to the supplier so add as much detail as possible.
      </p>
      <div class="col">
        <div class="row mt-1">
          <span class="col-3 col-sm-3 p-0"><strong>Legal Name:</strong></span>
          <span class="col-9 col-sm-9 kehe-data-value">{{defaultIfEmpty(supplier?.name)}}</span>
        </div>

        <div class="row mt-2 mb-2">
          <span class="col-3 col-sm-3 p-0"><strong>Document:</strong></span>
          <span class="col-9 col-sm-9 kehe-data-value">
            <a (click)="downloadW9()" *ngIf="document?.filename; else defaultIfEmptyValue">
              {{defaultIfEmpty(document?.filename)}}
            </a>
            <ng-template #defaultIfEmptyValue>
              --
            </ng-template>
          </span>
        </div>
      </div>
      <kendo-formfield>
        <kendo-label [for]="commentField" text="Rejection Reason"></kendo-label>
        <kendo-textarea #commentField resizable="auto" [maxlength]="maxlength"
                        [readonly]="isShowRevisionNotes"
                        placeholder="Enter a rejection reason." [(ngModel)]="comment">
          <kendo-textarea-suffix class="kehe-textarea-counter">
            {{ counter }}
          </kendo-textarea-suffix>
        </kendo-textarea>
        <kendo-formhint>This will be visible to the supplier.</kendo-formhint>
      </kendo-formfield>
    </div>
  </div>
  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button *ngIf="!isShowRevisionNotes"
      class="cancel-button"
      name="cancel"
      classAttr="btn-link btn-medium"
      (click)="closeModal()">
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button *ngIf="isApproveGeneralTab"
      classAttr="btn-primary btn-medium ml-2"
      [spinner]="isLoading"
      [disabled]="isLoading"
      (click)="onConfirmClicked()">
      Approve
    </kehe-phoenix-button>
    <kehe-phoenix-button *ngIf="isRejectGeneralTab"
      classAttr="btn-delete btn-medium ml-2"
      [disabled]="!isRejectEnabled"
      [spinner]="isLoading"
      (click)="onConfirmClicked()">
      Reject
    </kehe-phoenix-button>
    <kehe-phoenix-button *ngIf="isShowRevisionNotes"
                 classAttr="btn-primary btn-medium ml-2"
                 (click)="closeModal()">
      Close
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
