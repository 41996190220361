import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { Constants } from '@app/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ManagePriceGroupsGuard {

  constructor(private _store: Store) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.PriceGroupRead)) || 
        this._store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.PriceGroupAdmin));
  }
}
