<kehe-phoenix-modal
  name="contact-book"
  modalTitle="Add Contact"
  [show]="true"
  (modalEvent)="onCloseModal()"
  size="md">
  <div body class="body p-1">
    <kehe-phoenix-alert [type]="alertTypes.Error" [message]="saveContactError"
                    [hideCloseIcon]="true" stick="true" [show]="saveContactError !== null">
    </kehe-phoenix-alert>
    <kehe-phoenix-alert [type]="alertTypes.Warning" [message]="alertMessage"
                    [hideCloseIcon]="true" stick="true" [show]="!!alertMessage">
    </kehe-phoenix-alert>
    <div *ngIf="showValidationDetails(); else elseBlock" [ngClass]="{'mt-4': !addressValidationSuccess() }">
      <kehe-address-verification *ngIf="validationResponse"
                                 [addressForm]="addressForm?.value"
                                 [addressValidation]="validationResponse"
                                 (selectedAddressChanged)="smartyStreetsSelectedAddressChanged($event)">
      </kehe-address-verification>
    </div>
    <ng-template #elseBlock>
      <kendo-buttongroup width="100%" selection="single">
        <button kendoButton [toggleable]="true" [selected]="!isNewContact" (selectedChange)="onSelectedMyContact()">
          My Contacts
        </button>
        <button kendoButton [toggleable]="true"  [selected]="isNewContact" (selectedChange)="onSelectedAddContact()">
          Add New Contact
        </button>
      </kendo-buttongroup>
      <div *ngIf="!isNewContact" class="pb-5">
        <div class="heading my-3">My {{ defaultContactType }} Contacts</div>
        <kendo-label text="{{ defaultContactType }}es"></kendo-label>
        <kendo-dropdownlist [data]="myContacts" [textField]="'title'" [valueField]="'id'"
                            [valuePrimitive]="true" (selectionChange)="onMyContactChanged($event)">
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div class="kendo-dropdown-list-item-template">
              <div class="item-header">{{ dataItem?.title }}</div>
              <div>{{ dataItem?.subTitle }}</div>
            </div>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate  let-dataItem>
            <div class="kendo-dropdown-list-value-template">
              <div class="text-truncate item-header">{{ dataItem?.title }}</div>
              <div class="text-truncate">{{ dataItem?.subTitle }}</div>
            </div>
          </ng-template>
        </kendo-dropdownlist>
      </div>
      <div *ngIf="isNewContact" [formGroup]="newContactForm">
        <app-contact-form [defaultContactType]="defaultContactType" [form]="newContactForm">
        </app-contact-form>
      </div>
    </ng-template>
  </div>
  <div footer>
    <div *ngIf="showValidationDetails(); else elseFooterBlock" class="d-flex flex-row justify-content-end">
      <kehe-phoenix-button
        (click)="onEditAddressClicked()"
        class="modal-btn-spacing mr-2"
        classAttr="btn-medium btn-link"
        [disabled]="isInProgress">
        Edit Address
      </kehe-phoenix-button>
      <kehe-phoenix-button
        name="addPickupAddress"
        classAttr="btn-primary btn-modal btn-medium"
        (click)="useAddress()"
        [disabled]="disableAction"
        [spinner]="isInProgress">
        Use Address
      </kehe-phoenix-button>
    </div>
    <ng-template #elseFooterBlock>
      <div class="d-flex flex-row justify-content-end">
        <kehe-phoenix-button
          (click)="onCloseModal()"
          class="modal-btn-spacing mr-2"
          classAttr="btn-medium btn-link"
          [disabled]="isInProgress"
        >Cancel
        </kehe-phoenix-button>
        <kehe-phoenix-button
          name="addPickupAddress"
          classAttr="btn-primary btn-modal btn-medium"
          (click)="onAddClicked()"
          [disabled]="disableAction"
          [spinner]="isInProgress">
          Add
        </kehe-phoenix-button>
      </div>
    </ng-template>
  </div>
</kehe-phoenix-modal>
