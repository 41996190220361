import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { EndpointPaths } from '../models/endpoint-paths.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdName } from '../models/id-name.interface';
import { Payload } from '../../../../../models/payload';
import { map, retry } from 'rxjs/operators';
import { SearchFilter } from '../../../../../shared/models/search-filter.interface';

@Injectable()
export class HierarchyService {

    constructor(private httpClient: HttpClient) { }

    export(): Observable<void> {
        const path = `${environment.customerEnterprise}/api/export/hierarchies`;
        return this.httpClient.post<void>(path, {});
    }

    getIds(path: EndpointPaths): Observable<IdName[]> {
        const pathWithBaseUrl = this.getPathWithBaseUrl(path);
        return this.httpClient.get<Payload<IdName[]>>(
            `${pathWithBaseUrl}/ids`
        ).pipe(
            retry(2),
            map((response) => response?.data || []),
        )
    }

    getCustomerSubtypeIds(): Observable<IdName[]> {
        return this.httpClient.get<Payload<IdName[]>>(
            `${environment.usersMicroservice}/${EndpointPaths.CustomerSubtype}`,
        ).pipe(
            retry(2),
            map((response) => response?.data || []),
        )
    }

    post<DtoType>(payload: DtoType, path: EndpointPaths): Observable<DtoType> {
        const pathWithBaseUrl = this.getPathWithBaseUrl(path);
        return this.httpClient.post<DtoType>(
            pathWithBaseUrl, payload
        );
    }

    put<DtoType>(payload: DtoType, path: EndpointPaths): Observable<DtoType> {
        const pathWithBaseUrl = this.getPathWithBaseUrl(path);
        return this.httpClient.put<DtoType>(
            pathWithBaseUrl, payload
        );
    }

    delete(id: number, path: EndpointPaths): Observable<void> {
        const pathWithBaseUrl = this.getPathWithBaseUrl(path);
        return this.httpClient.delete<void>(`${pathWithBaseUrl}/${id}`);
    }

    getList<ReturnType>(filter: SearchFilter<number>, path: EndpointPaths): Observable<Payload<ReturnType[]>> {
        const pathWithBaseUrl = this.getPathWithBaseUrl(path);
        const params = new HttpParams()
            .set('search', filter.search)
            .set('pageCount', filter.pageCount)
            .set('pageIndex', filter.pageIndex)
            .set('sortOption', filter.sortOption)
            .set('orderDirection', filter.orderDirection);

        return this.httpClient.get<Payload<ReturnType[]>>(
            pathWithBaseUrl, { params }
        );
    }

    getPathWithBaseUrl(path: EndpointPaths): string {
        return `${environment.usersMicroservice}/${path}`;
    }
}
