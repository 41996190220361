import { ActionReducerMap } from "@ngrx/store";
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import * as fromAuthentication from "./authentication/authentication.reducer";
import * as fromFeatureFlag from "./feature-flag/feature-flag.reducer";
import { breakpoint } from "./breakpoint/breakpoint.reducer";
import * as fromDevice from "./device/device.reducer";
import * as fromDistributionCenter from "./distribution-center/distribution-center.reducer";
import * as fromSupplier from "./supplier/supplier.reducer";
import * as fromBreadCrumbs from './breadcrumbs/breadcrumbs.reducer';

export const routerStoreKey = 'router';

export interface State {
    [routerStoreKey]: RouterReducerState;
    [fromAuthentication.authKey]: fromAuthentication.State;
    [fromFeatureFlag.featureFlagKey]: fromFeatureFlag.State;
    [breakpoint.name]: breakpoint.State;
    [fromDevice.deviceKey]: fromDevice.State,
    [fromDistributionCenter.distributionCenterKey]: fromDistributionCenter.State,
    [fromSupplier.key]: fromSupplier.State,
    [fromBreadCrumbs.key]: fromBreadCrumbs.State,
}

export const reducers: ActionReducerMap<State> = {
    [routerStoreKey]: routerReducer,
    [fromAuthentication.authKey]: fromAuthentication.reducer,
    [fromFeatureFlag.featureFlagKey]: fromFeatureFlag.reducer,
    [breakpoint.name]: breakpoint.reducer,
    [fromDevice.deviceKey]: fromDevice.reducer,
    [fromDistributionCenter.distributionCenterKey]: fromDistributionCenter.reducer,
    [fromSupplier.key]: fromSupplier.reducer,
    [fromBreadCrumbs.key] : fromBreadCrumbs.reducer,
};