import { SalesOrderGuard } from './modules/orders/guards/sales-order.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AuthGuardService } from './authentication/auth-guard.service';
import { CallbackPageComponent } from './authentication/components/callback-page/callback-page.component';
import { DocumentListPageComponent } from './pages/document-list-page/document-list-page.component';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { CustomerGuard } from './modules/customers/services/customer.guard';
import { BrokerGuard } from './modules/broker-list/services/broker.guard';
import { SupplierGuard } from './modules/supplier-list/services/supplier.guard';
import { UserManagementGuard } from './modules/user-management/guards/user-management.guard';
import { ShowManagementGuard } from './modules/show-management/guard/show-management.guard';
import { SODCustomersManagementGuard } from './modules/sod-customers-management/guards/sod-customers-management.guard';
import { HealthPageComponent } from './pages/health-page/health-page.component';
import { ClearSupplierGuardGuard } from './modules/supplier-list/services/clear-supplier-guard.guard';
import { ClearBrokerGuardGuard } from './modules/broker-list/services/clear-broker-guard.guard';
import { ClearProductGuard } from './modules/product-list/services/clear-product.guard';
import { CreditsGuard } from './modules/credits/guards/credits.guard';
import { MerchandisingComponent } from './pages/merchandising/merchandising.component';
import { ResourcesPageComponent } from './modules/resources-module/pages/resources-page/resources-page.component';
import { UnauthorizedPageComponent } from './pages/unauthorized-page/unauthorized-page.component';
import { ManageAnnouncementsGuard } from './modules/announcements/announcements-manage/guards/manage-announcements.guard';
import { AnnouncementsListGuard } from './modules/announcements/announcements-list/guards/announcements-list.guard';
import { SupplierMassUpdateGuard } from './modules/supplier-mass-update/guards/supplier-mass-update.guard';
import { PromotionsEligibilityGuard } from './modules/promotions-eligibility/guard/promotions-eligibility.guard';
import { PromotionsManagementGuard } from './modules/promotions-management/guard/promotions-management.guard';
import { ClearCreditListGuard } from './modules/credits/guards/clear-credit-list.guard';
import { ProductListsManagementGuard } from './modules/product-lists/guard/product-lists-management.guard';
import { CustomPromotionsGuard } from './modules/custom-promotions/guards/custom-promotions.guard';
import { CustomPromotionsPageComponent } from './modules/custom-promotions/pages/custom-promotions-page/custom-promotions-page.component';
import { SalesBusinessHierarchyGuard } from './modules/utilities/hierarchies/sales-business-hierarchy/guards/sales-business-hierarchy.guard';
import { RetailerHierarchyGuard } from './modules/utilities/hierarchies/retailer-hierarchy/guards/retailer-hierarchy.guard';
import { PromotionalRoadmapPageComponent } from './modules/promotional-roadmap/pages/promotional-roadmap-page/promotional-roadmap-page.component';
import { PhysicalLocationHierarchyGuard } from './modules/utilities/hierarchies/physical-location-hierarchy/guard/physical-location-hierarchy.guard';
import { InfraHierarchyGuard } from './modules/utilities/hierarchies/infra-hierarchy/guards/infra-hierarchy.guard';
import { ResetGroupFiltersGuard } from '@app/modules/user-group-management/guard/reset-group-filters.guard';
import { UserManagementResetFiltersGuard } from './modules/user-management/guards/user-management-reset-filter.guard';
import { ClearProhibitsRestrictsGuard } from './modules/prohibit-restrict-list/services/clear-prohibits-restricts.guard';
import { UsersFilterClearGuard } from './modules/user-list/guards/users-filter-clear.guard';
import { ManagePricePageComponent } from './modules/manage-prices/pages/manage-price-page/manage-price-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, canActivate: [AuthGuardService] },
  { path: 'callback', component: CallbackPageComponent },
  { path: 'unauthorized', component: UnauthorizedPageComponent },
  {
    path: 'documents',
    component: DocumentListPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'supplier',
    data: {
      breadcrumb: 'Supplier Search',
    },
    loadChildren: () =>
      import('./modules/supplier-list/supplier-list.module').then(
        (m) => m.SupplierListModule
      ),
    canActivate: [AuthGuardService, SupplierGuard],
    canDeactivate: [ClearSupplierGuardGuard],
  },
  {
    path: 'supplier/upload',
    data: {
      breadcrumb: 'Supplier Upload',
    },
    loadChildren: () =>
      import('./modules/supplier-mass-update/supplier-mass-update.module').then(
        (m) => m.SupplierMassUpdateModule
      ),
    canActivate: [AuthGuardService, SupplierMassUpdateGuard],
    canDeactivate: [ClearSupplierGuardGuard],
  },
  {
    path: 'supplier/:esn',
    data: {
      breadcrumb: 'Supplier Detail',
    },
    loadChildren: () =>
      import('./modules/supplier-detail/supplier-detail.module').then(
        (m) => m.SupplierDetailModule
      ),
    canActivate: [AuthGuardService, SupplierGuard],
    canDeactivate: [ClearSupplierGuardGuard],
  },
  {
    path: 'broker',
    loadChildren: () =>
      import('./modules/broker-list/broker-list.module').then(
        (m) => m.BrokerListModule
      ),
    canActivate: [AuthGuardService, BrokerGuard],
    canDeactivate: [ClearBrokerGuardGuard],
  },
  {
    path: 'broker/:esn',
    loadChildren: () =>
      import('./modules/broker-detail/broker-detail.module').then(
        (m) => m.BrokerDetailModule
      ),
    canActivate: [AuthGuardService, BrokerGuard],
    canDeactivate: [ClearBrokerGuardGuard],
  },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'products',
    data: {
      breadcrumb: 'Product Search',
    },
    loadChildren: () =>
      import('./modules/product-list/product-list.module').then(
        (m) => m.ProductListModule
      ),
    canActivate: [AuthGuardService],
    canDeactivate: [ClearProductGuard],
  },
  {
    path: 'products/upload',
    data: {
      breadcrumb: 'Product Upload',
    },
    loadChildren: () =>
      import('./modules/product-upload/product-upload.module').then(
        (m) => m.ProductUploadModule
      ),
    canActivate: [AuthGuardService],
    canDeactivate: [ClearProductGuard],
  },
  {
    path: 'products/:upc',
    data: {
      breadcrumb: 'Product Detail',
    },
    loadChildren: () =>
      import('./modules/product-detail/product-detail.module').then(
        (m) => m.ProductDetailModule
      ),
    canActivate: [AuthGuardService],
    canDeactivate: [ClearProductGuard],
  },
  { path: 'help', component: HelpPageComponent },
  {
    path: 'customer',
    loadChildren: () =>
      import('./modules/customers/customers.module').then(
        (m) => m.CustomersModule
      ),
    canActivate: [AuthGuardService, CustomerGuard],
  },
  {
    path: 'prohibit-restricts',
    data: {
      breadcrumb: 'Prohibit Restrict Search',
    },
    loadChildren: () =>
      import('./modules/prohibit-restrict-list/prohibit-restrict-list.module').then(
        (m) => m.ProhibitRestrictListModule
      ),
    canActivate: [AuthGuardService, SalesOrderGuard],
    canDeactivate: [ClearProhibitsRestrictsGuard],
  },
  {
    path: 'prohibit-restricts/:id',
    data: {
      breadcrumb: 'Prohibit Restrict Detail',
    },
    loadChildren: () =>
      import('./modules/prohibit-restrict-detail/prohibit-restrict-detail.module').then(
        (m) => m.ProhibitRestrictDetailModule
      ),
    canActivate: [AuthGuardService],
    canDeactivate: [ClearProhibitsRestrictsGuard],
  },
  {
    path: 'sales-orders',
    loadChildren: () =>
      import('./modules/orders/list/orders-list.module').then(
        (m) => m.OrdersListModule
      ),
    canActivate: [AuthGuardService, SalesOrderGuard],
  },
  {
    path: 'sales-orders/:id',
    loadChildren: () =>
      import('./modules/orders/detail/order-detail.module').then(
        (m) => m.OrderDetailModule
      ),
    canActivate: [AuthGuardService, SalesOrderGuard],
  },
  {
    path: 'sales-orders/:tabId/:id',
    loadChildren: () =>
      import('./modules/orders/detail/order-detail.module').then(
        (m) => m.OrderDetailModule
      ),
    canActivate: [AuthGuardService, SalesOrderGuard],
  },
  {
    path: 'shipments',
    loadChildren: () =>
      import('./modules/shipments/shipments.module').then(
        (m) => m.ShipmentsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'shipments/:id',
    loadChildren: () =>
      import('./modules/shipments/detail/shipment-detail.module').then(
        (m) => m.ShipmentDetailModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'utilities-dc',
    loadChildren: () =>
      import(
        './modules/utilities/distribution-center/list/dcs-list.module'
      ).then((m) => m.DcsListModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'utilities-dc/:id',
    loadChildren: () =>
      import(
        './modules/utilities/distribution-center/detail/dcs-detail.module'
      ).then((m) => m.DcsDetailModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./modules/user-list/user-list.module').then(
        (m) => m.UserListModule
      ),
    canActivate: [AuthGuardService, UserManagementGuard],
    canDeactivate: [UserManagementResetFiltersGuard, UsersFilterClearGuard],
  },
  {
    path: 'users/:id',
    loadChildren: () =>
      import('./modules/user-management/user-management.module').then(
        (m) => m.UserManagementModule
      ),
    canActivate: [AuthGuardService, UserManagementGuard],
    canDeactivate: [UserManagementResetFiltersGuard, UsersFilterClearGuard]
  },
  {
    path: 'transitlanes',
    loadChildren: () =>
      import(
        './modules/supplier-transit-lanes/supplier-transit-lanes.module'
      ).then((m) => m.SupplierTransitLanesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'bottle-tax',
    loadChildren: () =>
      import(
        './modules/bottle-tax/bottle-tax.module'
        ).then((m) => m.BottleTaxModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'matrix',
    loadChildren: () =>
      import('./modules/supplier-matrix/supplier-matrix.module').then(
        (m) => m.SupplierMatrixModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'groups',
    loadChildren: () =>
      import(
        './modules/user-group-management/user-group-management.module'
      ).then((m) => m.UserGroupManagementModule),
    canActivate: [AuthGuardService, UserManagementGuard],
    canDeactivate: [ResetGroupFiltersGuard]
  },
  {
    path: 'shows',
    loadChildren: () =>
      import('./modules/show-management/show-management.module').then(
        (m) => m.ShowManagementModule
      ),
    canActivate: [AuthGuardService, ShowManagementGuard],
  },
  {
    path: 'sod-customers',
    loadChildren: () =>
      import(
        './modules/sod-customers-management/sod-customers-management.module'
      ).then((m) => m.SODCustomersManagementModule),
    canActivate: [AuthGuardService, SODCustomersManagementGuard],
  },
  {
    path: 'promotions',
    loadChildren: () =>
      import(
        './modules/promotions-management/promotions-management.module'
      ).then((m) => m.PromotionsManagementModule),
    canActivate: [AuthGuardService, PromotionsManagementGuard],
  },
  {
    path: 'promotions-eligibility',
    loadChildren: () =>
      import(
        './modules/promotions-eligibility/promotions-eligibility.module'
      ).then((m) => m.PromotionsEligibilityModule),
    canActivate: [AuthGuardService, PromotionsEligibilityGuard],
  },
  {
    path: 'product-lists',
    loadChildren: () =>
      import('./modules/product-lists/product-lists-management.module').then(
        (m) => m.ProductListsManagementModule
      ),
    canActivate: [AuthGuardService, ProductListsManagementGuard],
  },
  {
    path: 'manage-data/product',
    loadChildren: () =>
      import('./modules/utilities/products/products.module').then(
        (m) => m.ProductsModule
      ),
    canActivate: [AuthGuardService, ShowManagementGuard],
  },
  {
    path: 'credits',
    loadChildren: () =>
      import('./modules/credits/credits-routing.module').then(
        (m) => m.CreditsRoutingModule
      ),
    canActivate: [AuthGuardService, CreditsGuard],
    canDeactivate: [ClearCreditListGuard],
  },
  {
    path: 'advertisements',
    loadChildren: () =>
      import('./modules/utilities/marketing/marketing.module').then(
        (m) => m.MarketingModule
      ),
  },
  {
    path: 'advertisements/carousels/:id',
    loadChildren: () =>
      import(
        './modules/utilities/marketing/carousels/detail/carousels-detail.module'
      ).then((m) => m.CarouselsDetailModule),
  },
  {
    path: 'advertisements/publications/:id',
    loadChildren: () =>
      import(
        './modules/utilities/marketing/publications/detail/publication-detail.module'
      ).then((m) => m.PublicationDetailModule),
  },
  {
    path: 'sales-business-hierarchy',
    loadChildren: () =>
      import(
        './modules/utilities/hierarchies/sales-business-hierarchy/sales-business-hierarchy.module'
      ).then((m) => m.SalesBusinessHierarchyModule),
    canActivate: [AuthGuardService, SalesBusinessHierarchyGuard],
  },
  {
    path: 'retailer-hierarchy',
    loadChildren: () =>
      import(
        './modules/utilities/hierarchies/retailer-hierarchy/retailer-hierarchy.module'
      ).then((m) => m.RetailerHierarchyModule),
    canActivate: [AuthGuardService, RetailerHierarchyGuard],
  },
  {
    path: 'physical-location-hierarchy',
    loadChildren: () =>
      import(
        './modules/utilities/hierarchies/physical-location-hierarchy/physical-location-hierarchy.module'
      ).then((m) => m.PhysicalLocationHierarchyModule),
    canActivate: [AuthGuardService, PhysicalLocationHierarchyGuard],
  },
  {
    path: 'infra-hierarchy',
    loadChildren: () =>
      import(
        './modules/utilities/hierarchies/infra-hierarchy/infra-hierarchy.module'
      ).then((m) => m.InfraHierarchyModule),
    canActivate: [AuthGuardService, InfraHierarchyGuard],
  },
  {
    path: 'fresh-hierarchy',
    loadChildren: () =>
      import(
        './modules/utilities/hierarchies/fresh-hierarchy/fresh-hierarchy.module'
      ).then((m) => m.FreshHierarchyModule),
    canActivate: [AuthGuardService, PhysicalLocationHierarchyGuard],
  },
  { path: 'health', component: HealthPageComponent },
  { path: 'merchandising', component: MerchandisingComponent },
  {
    path: 'resources',
    component: ResourcesPageComponent,
    loadChildren: () =>
      import('./modules/resources-module/resources.module').then(
        (m) => m.ResourcesWrapperModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'promotional-roadmap',
    component: PromotionalRoadmapPageComponent,
    loadChildren: () =>
      import('./modules/promotional-roadmap/promotional-roadmap.module').then(
        (m) => m.PromotionalRoadmapWrapperModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'custom-promotions',
    component: CustomPromotionsPageComponent,
    loadChildren: () =>
      import('./modules/custom-promotions/custom-promotions.module').then(
        (m) => m.CustomPromotionsWrapperModule
      ),
    canActivate: [AuthGuardService, CustomPromotionsGuard],
  },
  {
    path: 'announcements',
    loadChildren: () =>
      import(
        './modules/announcements/announcements-manage/announcements-manage.module'
      ).then((m) => m.AnnouncementsManageModule),
    canActivate: [AuthGuardService, ManageAnnouncementsGuard],
  },
  {
    path: 'announcements-list',
    loadChildren: () =>
      import(
        './modules/announcements/announcements-list/announcements-list.module'
      ).then((m) => m.AnnouncementsListModule),
    canActivate: [AuthGuardService, AnnouncementsListGuard],
  },
  {
    path: 'asset-manager',
    loadChildren: () =>
      import('./modules/show-assets/show-assets.module').then(
        (m) => m.ShowAssetsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'workqueue',
    data: {
      breadcrumb: 'Work Queue',
    },
    loadChildren: () =>
      import('./modules/work-queue/work-queue.module').then(
        (m) => m.WorkQueueModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'utilities-replist',
    loadChildren: () =>
      import(
        './modules/utilities/account-rep-list/account-rep-list.module'
      ).then((m) => m.AccountRepListModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'manage-cust-deact-config',
    loadChildren: () =>
      import(
        './modules/utilities/customer-deactivation-cfg-mgmt/customer-deact-cfg-mgmt.module'
      ).then((m) => m.CustomerDeactCfgMgmtModule),
    canActivate: [AuthGuardService],
  },
  {
      path: 'manage-prices',
      component: ManagePricePageComponent,
      loadChildren: () =>
          import('./modules/manage-prices/manage-prices.module').then(
              (m) => m.SupplierPriceChangeWrapperModule
          ),
      canActivate: [AuthGuardService]
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
