import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { hideSubmitModal } from '../../store/supplier-detail.actions';

@Component({
  selector: 'app-submit-supplier-modal',
  templateUrl: './submit-supplier-modal.component.html',
  styleUrls: ['./submit-supplier-modal.component.scss']
})
export class SubmitSupplierModalComponent {

  @Output()
  public modalSubmitClicked: EventEmitter<any> = new EventEmitter();
  public warningMsg = 'Are you sure you want to activate this supplier?';
  constructor(private _store: Store) { }

  public submit() {
    this.modalSubmitClicked.emit();
  }
  closeModal() {
    this._store.dispatch(hideSubmitModal());
  }

  handleKeheModalEvents(e) {
    if (e.type === 'close') {
      this.closeModal();
    }

  }
}
