<h4 class="mb-2 col-12 col-md-6 px-4">Remittance Address</h4>
<p class="px-4">The Remittance Address must match the Blank Invoice provided on the Documents tab.</p>

<div class="col-12 col-md-6 px-4" [formGroup]="form" *ngIf="contacts$ | async as contacts">
  <kendo-label text="Remittance Address"></kendo-label>

  <app-contact-card
    formControlName="remittanceAddress"
    [defaultContactTypes]="types.RemittanceAddress"
    [contacts]="contacts"
    [isInProgress]="saving$ | async"
    [saveContactError]="error$ | async"
    (smartyStreetsUseAddress)="onSmartyStreetsUseAddress($event)"
    (remove)="showRemove = true"
    ></app-contact-card>

  <app-delete-address
      *ngIf="showRemove"
      [contact]="getContact(contacts)"
      [loading]="deleting$ | async"
      [error]="deleteAddressError$ | async"
      (cancel)="onCancel()"
      (delete)="onDelete($event)"></app-delete-address>
</div>
