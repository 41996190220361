import { DcPaymentHoldDetails } from '@app/modules/supplier-detail/models/dc-payment-hold-details';
import { SupplierDetail } from '@app/modules/supplier-detail/models/supplier-detail';
import { createAction, props } from '@ngrx/store';

export const userLandedOnDcPaymentEditPage = createAction(
  '[SupplierDcPaymentHolds]: User Landed On DC Payment Edit Page'
)

export const userChangedDcPaymentHoldForm = createAction(
  '[SupplierDCPaymentHolds]: User Changed DC Payment Hold Form',
  props<{
    changedPaymentHolds: DcPaymentHoldDetails[]
  }>()
);

export const userResetDcPaymentHoldChanges = createAction(
  '[SupplierDCPaymentHolds]: User Reset DC Payment Hold changes'
);

export const userClickedSaveDcPaymentHoldChanges = createAction(
  '[SupplierDCPaymentHolds]: User Clicked Save DC Payment Hold changes'
);

export const saveDcPaymentHoldsSuccess = createAction(
  '[SupplierDCPaymentHolds]: Save DC Payment Holds Success',
  props<{ supplierDetail: SupplierDetail }>()
)

export const saveDcPaymentHoldsError = createAction(
  '[SupplierDCPaymentHolds]: Save DC Payment Holds Error'
);
