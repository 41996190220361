<div *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col">
      <h5>Lead Times</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-md-auto">
      <kendo-formfield>
        <kendo-label text="Total Ready Time"></kendo-label>
        <kendo-numerictextbox formControlName="totalTime" placeholder="e.g. 3" [spinners]="false" [decimals]="0"
          data-automation-id="bulk-edit-dcpg-modal-total-ready-time-input"
          [format]="'0'">
        </kendo-numerictextbox>
        <kendo-formhint>Calendar days</kendo-formhint>
        <kendo-formerror *ngIf="totalTime?.errors?.required">
          Total Ready Time is required.
        </kendo-formerror>
        <kendo-formerror *ngIf="totalTime?.errors?.min || totalTime?.errors?.max">
          Between 1 and 499
        </kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <hr>

  <div class="row mt-4">
    <div class="col">
      <h5>Delivered Address (Optional)</h5>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <kendo-label text="Do you have a Delivered address?"></kendo-label>
      <ul class="k-radio-list">
        <li class="k-radio-item">
          <input class="k-radio" type="radio" (click)="handleHaveDeliveredAddress(true)"
            [checked]="haveDeliveredAddress" #HaveDeliveredAddress1 kendoRadioButton [value]="true" />
          <kendo-label class="k-radio-label" [for]="HaveDeliveredAddress1" text="Yes"></kendo-label>
        </li>
        <li class="k-radio-item">
          <input class="k-radio" type="radio" (click)="handleHaveDeliveredAddress(false)"
            [checked]="!haveDeliveredAddress" #HaveDeliveredAddress2 kendoRadioButton [value]="false" />
          <kendo-label class="k-radio-label" [for]="HaveDeliveredAddress2" text="No"></kendo-label>
        </li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="haveDeliveredAddress">
    <div class="col">
      <app-contact-form [defaultContactType]="contactTypes.DeliveredAddress" [form]="form.get('addressBook')"
        [hideContactType]="true">
      </app-contact-form>
    </div>
  </div>
</div>
