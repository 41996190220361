import { Component } from '@angular/core';
import { AssociatedSuppliersTabGridStore } from './associated-suppliers-tab-grid/associated-suppliers-tab-grid.store';
import { AssociatedSupplierTabStore } from './associated-supplier-tab.store';
import { OptionMenuItem } from '@kehe/phoenix-button';


export enum BulkMenuOptions {
  Delete = 'Delete',
}

@Component({
  selector: 'app-associated-supplier-tab',
  templateUrl: './associated-supplier-tab.component.html',
  styleUrls: ['./associated-supplier-tab.component.scss'],
  providers: [AssociatedSuppliersTabGridStore, AssociatedSupplierTabStore],
})
export class AssociatedSupplierTabComponent {
  constructor(private _store: AssociatedSupplierTabStore) {}

  vm$ = this._store.vm$;

  searchControl = this._store.searchControl;

  bulkButtonMenuOptions: OptionMenuItem[] = Object.values(BulkMenuOptions).map(
    (ellipsisOption) => ({
      name: ellipsisOption,
      data: Object.keys(BulkMenuOptions)[
        Object.values(BulkMenuOptions).indexOf(ellipsisOption)
      ],
      automationId: 'associated-suppliers-table-bulk-delete-option',
      enabled: true,
    })
  );

  handleBulkActionClick(menuItem: OptionMenuItem): void {
    if (menuItem.data === BulkMenuOptions.Delete) {
      this._store.showDeleteConfirmationModal();
    }
  }
}
