
export enum DCPGEditBulkActions {
  DeliveryMethod,
  PickupLeadTimes,
  ChangeKehePickupType,
  Buyers,
  PickupContact,
  PickupAddress,
  POFax,
  DeliveredLeadTimes,
  AddPOEmail,
  ReplacePOEmail,
  RemovePOEmail,
  DeliveredAddress,
}
