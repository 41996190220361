import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private _userActions: string[];

  constructor(private _store: Store) {
    this._store.pipe(select(AuthenticationSelectors.selectUserActions)).subscribe((actions) => {
      this._userActions = actions;
    });
  }

  /**
   * Validate if a user action exists.
   * @param action User Action.
   * @deprecated in favor of selectDoesUserHaveAction authentication selector
   */
  public userHasAction(action: string): boolean {
    return this._userActions && this._userActions.includes(action);
  }

}
