<app-product-group-po-transmissions-modal *ngIf="showPOTransModal"
  [dcProductGroup]="poTransModalViewData"
  (closeButtonClicked)="onClosePOTransModal()"
  (editButtonClicked)="onDcProductGroupEdit($event)"
></app-product-group-po-transmissions-modal>
<app-pickup-address-notes-modal *ngIf="showPickUpAddressNotesModal"
  [pickupAddressNotes]="pickupAddressNotes"
  [title]="pickupAddressNotesTitle"
  (closeNotesButtonClicked)="onClosePickUpAddressNotesModal($event)"
></app-pickup-address-notes-modal>
<app-clone-dcpg-modal
  [show]="showCloneDCPGModal"
  [productGroup]="productGroup"
  [dcProductGroup]="stagedDCPGClone"
  [dcList]="availableDCList"
  (closeModal)="onCloseCloneDCPGModal($event)"></app-clone-dcpg-modal>
<app-bulk-edit-dcpg-modal
  *ngIf="showBulkEditDCPGModal"
  [loading]="bulkEditLoading"
  [error]="bulkEditError"
  [productGroupNumber]="productGroup?.productGroupNumber"
  [initialSelectedDCPGs]="selectedDCs"
  [dcList]="eligibleDCPGs"
  (applyClicked)="bulkEditApplyClicked($event)"
  (applyClickedAddContact)="bulkEditApplyClickedAddContact($event)"
  (closeModal)="onCloseBulkEditModal($event)">
</app-bulk-edit-dcpg-modal>

<div *ngIf="{
  isDraft: isDraftSupplier$ | async
  } as context">
  <div class="row mt-3 mb-2">
    <div class="col-md-5 col-lg-6 mb-2 mb-md-0 d-flex align-items-center">
      <kehe-phoenix-menu-button
        *ngIf="userCanEditDcProductGroup && !context.isDraft"
        menuTitle="Actions"
        (optionSelected)="bulkOptionSelected()"
        [menuOptions]="bulkEditMenuOptions"
        [enabled]="bulkEditEnabled"></kehe-phoenix-menu-button>
      <p *ngIf="userCanEditDcProductGroup && bulkEditEnabled && !context.isDraft"
        class="m-0 ml-3 kehe-data-label">{{selectedDCs.length}} DC(s) Selected</p>
    </div>
    <div class="col-md-7 col-lg-6 text-right">
      <kehe-phoenix-button *ngIf="userCanAddDcProductGroup" (click)="addButtonClicked()"
                  [disabled]="!hasUnAssignedDCs" classAttr="btn-secondary btn-medium">Add
      </kehe-phoenix-button>
    </div>
  </div>
  <div class="row">
    <div class="col kehe-grid no-scroll py-0 px-auto" *ngIf="bulkEditForm">
      <kendo-grid
        keheKendoGridScroller
        [data]="dcProductGroups"
        [sortable]="{
          allowUnsort: false,
          mode: 'single'
        }"
        [sort]="sort"
        (sortChange)="sortChange($event)">

        <kendo-grid-column *ngIf="userCanEditDcProductGroup"
          title=""
          [width]="40"
          [locked]="true">
          <ng-template kendoGridHeaderTemplate let-column>
            <input type="checkbox" kendoCheckBox [(ngModel)]="editAll" *ngIf="!context.isDraft"/>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <input
              *ngIf="!isMissingRequiredFields(dataItem) && !context.isDraft"
              type="checkbox"
              kendoCheckBox
              [formControl]="bulkEditForm.controls[rowIndex].controls.value" />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column *ngIf="userCanEditDcProductGroup"
          [title]="''"
          [width]="40"
          [locked]="true">
          <ng-template class="p-0" kendoGridCellTemplate let-dataItem>
            <div class="d-flex justify-content-center">
              <kehe-phoenix-menu-button
                btnClass="btn-link"
                [menuOptions]="dcpgOptions[dataItem.distributionCenterNumber]"
                (optionSelected)="optionSelected($event)"></kehe-phoenix-menu-button>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="" [width]="40" [locked]="true">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>
              <em *ngIf="showDCProductGroupWarning(dataItem)"
                  class="fas fa-warning k-warning"
                  [keheTooltip]="dcProductGroupWarningMessage(dataItem)"
                  [keheTooltipShowDelay]='500'></em>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="distributionCenterNumber" headerClass="dcGroupLock" title="DC Product Group"
                           filter="string" [locked]="true"
                           [width]="180">
          <ng-template kendoGridHeaderTemplate let-column>
            {{ column.title }}  <span class="fas fa-lock"></span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>{{ dataItem.dcDisplayName }}</div>
            <div>({{ dataItem.dcProductGroupNumber }})</div>
            <div>
              <kehe-phoenix-tag *ngIf="!isMissingRequiredFields(dataItem); else activeDcElseBlock" [tag]="{text: 'Active', bgColor: '#D5E48F', borderColor:'#D5E48F'}" classAttr="large"></kehe-phoenix-tag>
              <ng-template #activeDcElseBlock><kehe-phoenix-tag [tag]="{text: 'Draft'}" classAttr="large"></kehe-phoenix-tag></ng-template>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="deliveryMethod" title="Del. Method" filter="string" [sortable]="false" [width]="140">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.deliveryMethod }}
            <ng-container *ngIf="dataItem.isZipTransitTime && !isDelivered(dataItem)">
              <br/>
              ({{ inboundRouteTypeName(dataItem.inboundRouteType) }})
            </ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="pickupAddress" [title]="pickupAddressColumnTitle$ | async" filter="string" [sortable]="false" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="!dataItem.pickupAddress || !dataItem.pickupAddress?.street1 || (deliveredAddressFlag$ | async) === false else address">--</div>
            <ng-template #address>
              <div *ngIf="!showContactCard" class="address">
                <div>{{ dataItem.pickupAddress.name }}</div>
                <div>{{ dataItem.pickupAddress.street1 }}</div>
                <div>{{ dataItem.pickupAddress.street2 }}</div>
                <div>
                  {{ dataItem.pickupAddress.city}}
                  <span *ngIf="dataItem.pickupAddress.state?.code">, {{ dataItem.pickupAddress.state?.code }}</span>
                  {{ dataItem.pickupAddress.zipCode }}
                </div>
                <div>{{ dataItem.pickupAddress.country?.name }}</div>
                <div *ngIf="dataItem.pickupAddress.phone">P: {{ dataItem.pickupAddress.phone | phoneNumber }}</div>
                <div *ngIf="dataItem.pickupAddress.fax">F: {{ dataItem.pickupAddress.fax | phoneNumber }}</div>
                <div *ngIf="dataItem.pickupAddress.email">E: {{ dataItem.pickupAddress.email }}</div>
                <div *ngIf="dataItem.pickupAddressNotes" class="link-cell pickupAddressNotesLink"
                  (click)="onClickPickupAddressNotes(dataItem)">{{!isDelivered(dataItem) ? 'Pickup Address Notes' : 'Delivered Address Notes'}}
                </div>
              </div>
              <div *ngIf="showContactCard" class="address">
                <div>{{ dataItem.pickupAddressContact?.address?.name }}</div>
                <div>{{ dataItem.pickupAddressContact?.address?.street1 }}</div>
                <div>{{ dataItem.pickupAddressContact?.address?.street2 }}</div>
                <div>{{ dataItem.pickupAddressContact?.address?.city}}, {{ dataItem.pickupAddressContact?.address?.state?.code }}
                  {{ dataItem.pickupAddressContact?.address?.zipCode }}</div>
                <div>{{ dataItem.pickupAddressContact?.address?.country?.name }}</div>
                <div *ngIf="dataItem.pickupAddressContact?.phone">P: {{ dataItem.pickupAddressContact?.phone | phoneNumber }}</div>
                <div *ngIf="dataItem.pickupAddressContact?.fax">F: {{ dataItem.pickupAddressContact?.fax | phoneNumber }}</div>
                <div *ngIf="dataItem.pickupAddressContact?.email">E: {{ dataItem.pickupAddressContact?.email }}</div>
                <div *ngIf="dataItem.pickupAddressContact?.notes" class="link-cell pickupAddressNotesLink"
                  (click)="onClickPickupAddressNotes(dataItem)">{{!isDelivered(dataItem) ? 'Pickup Address Notes' : 'Delivered Address Notes'}}
                </div>
              </div>
            </ng-template>
          </ng-template>
        </kendo-grid-column>

        <!-- Total Lead Time Column -->
        <kendo-grid-column field="leadTime" title="Total Lead Time" [sortable]="false" [width]="140"
                           [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}"
                           *ngIf="!shouldShowZipTransitTime()">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div> {{ dataItem.leadTime ? dataItem.leadTime : '--' }}</div>
          </ng-template>
        </kendo-grid-column>

        <!-- Total Lead Time Zip Column -->
        <kendo-grid-column headerClass="kehe-grid-header-hover" [width]="140" *ngIf="shouldShowZipTransitTime()">
          <ng-template kendoGridHeaderTemplate let-product>
            <div keheTooltip="Total Lead Times marked with an asterisk (*) are calculated using the new zip code transit table."
              keheTooltipClass="higher-tooltip" class="w-100">
              <div class="kehe-grid-hover-indicator"></div>
              <div class="w-100 text-center">
                Total Lead Time
              </div>
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="w-100 text-center"> {{ getTotalLeadTime(dataItem) }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="UPC Count" [sortable]="false" *ngIf="canShowProductsData" headerClass="text-center" [width]="140">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="text-center">
              <div *ngIf="dataItem.products && dataItem.products.length > 0;else no_products_content"
                class="link-cell" (click)="onClickDCPGUPCCount(dataItem);">
                {{ dataItem.products.length }}
              </div>
              <ng-template #no_products_content>0</ng-template>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="poTransmission" title="PO Trans" [sortable]="false" [width]="200"
                           [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="!dataItem.poFax && dataItem?.poEmails?.length === 0 else poTransmission">--</div>
            <ng-template #poTransmission>
              <div *ngIf="(dataItem.poFax && dataItem.poFax.fax && dataItem.poFax.fax.length > 0) else poEmails">
                <div>{{dataItem.poFax.fax | phoneNumber}}</div>
                <div *ngIf="dataItem.poEmails?.length > 0" class="link-cell"
                     (click)="onClickPOTrans(dataItem);">{{dataItem.poEmails.length}} more
                </div>
              </div>
              <ng-template #poEmails>
                <div *ngIf="dataItem?.poEmails?.length > 0">{{dataItem.poEmails[0].email}}</div>
                <div *ngIf="dataItem?.poEmails?.length > 1" class="link-cell"
                     (click)="onClickPOTrans(dataItem);">{{dataItem.poEmails.length - 1 }} more
                </div>
              </ng-template>
            </ng-template>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="buyerName" title="Buyer" [sortable]="false" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="!dataItem.buyerName else buyerNameColumn">--</div>
            <ng-template #buyerNameColumn>
              <div> {{ dataItem.buyerName }} ({{ dataItem.buyerCode }})</div>
              <ng-container *ngIf="isDraftSupplier$ | async">
                <div *ngIf="dataItem.buyerRegion"> {{dataItem.buyerRegion}} </div>
                <div *ngIf="dataItem.buyerEmail" class="link-cell"><a href="mailto:{{dataItem.buyerEmail}}">{{dataItem.buyerEmail}}</a></div>
              </ng-container>
            </ng-template>
          </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <div class="custom-no-results-msg">
            <em class="fas fa-exclamation-triangle" aria-hidden="true"></em>
            <div class="custom-msg-container">
              <span class="no-results">No DC Product Groups</span>
            </div>
          </div>
        </ng-template>

      </kendo-grid>
    </div>
  </div>
</div>
<app-pg-products-modal [dcProductGroup]="showDCPGProductsModal" *ngIf="showDCPGProductsModal" (closeModal)="closePGProductsModal()">
</app-pg-products-modal>
