<kehe-phoenix-modal
  name="addPurchasingOptionContact"
  modalTitle="Add Contact"
  [show]="true"
  (modalEvent)="handleAddContactModalEvent($event)"
  size="md">
  <div body>
    <div class="row ">
      <div class="d-flex flex-column col">
        <div class="m-1 pb-2">
          <kehe-phoenix-alert
          [show]="true"
          [type]="errorType"
          message="{{supplierContactErrorMessage}}" [stick]="true" [hideCloseIcon]="true"></kehe-phoenix-alert>
        </div>
        <div *ngFor="let item of dataItems" class="d-flex row justify-content-between mt-2">
          <div class="key col-3">{{item.key}}</div>
          <div class="value col-8">{{item.value}}</div>
        </div>
      </div>
    </div>

  </div>
  <div footer class="d-flex flex-row justify-content-end">
    <kehe-phoenix-button (click)="handleEditAddress()" class="modal-btn-spacing mr-2" classAttr="btn-medium btn-link"
      [disabled]="isAddingAddress">Edit Address
    </kehe-phoenix-button>
    <kehe-phoenix-button name="use-address" classAttr="btn-primary btn-modal btn-medium" (click)="handleUseClicked()"
      [disabled]="disableUseAddress || errorType === 'error'" [spinner]="isAddingAddress">
      Use Address
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
