import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { Destroyable } from '../../../../abstract/destroyable';
import {
  isGeneralTabNotSubmitted,
  selectRequestedRetailer,
  selectSupplierDetail,
  showOnBoardingGeneralTabStatusModal
} from '../../store/supplier-detail.selectors';

import { SupplierDetail } from '../../models/supplier-detail';
import { combineLatest } from 'rxjs';
import {
  getSupplierCategoryManagerFullName,
  getSupplierDevelopmentManagerFullName,
  isAddedDraftSupplier,
  isDraft,
  isInvitedDraftSupplier,
  isInvitedSupplier, shouldShowAAPFieldForGeneralTab
} from '../../utilities/supplier-utils';
import { SupplierInviteRequester } from '../../../supplier-list/enum/supplier-invite-requester';
import { selectDocumentW9 } from '../../../supplier-documents/store/supplier-documents.selectors';
import { downloadDocument } from '../../../supplier-documents/store/supplier-documents.actions';
import { Document } from '../../../supplier-documents/models/document';
import { Constants } from '../../../../constants/constants';
import { formattedAddress, formattedContact } from '../../utilities/address-format-util';

@Component({
  selector: 'app-general-view',
  templateUrl: './general-view.component.html',
  styleUrls: ['./general-view.component.scss']
})
export class GeneralViewComponent extends Destroyable implements OnInit {

  readonly tooltipAAP = Constants.AAPFieldToolTip;

  supplier: SupplierDetail;
  w9Document: Document;
  showStatusChangeConfirmation: boolean;
  retailer: any;
  shouldShowAAPField = false;

  get requiredRetailer() {
    return this.supplier?.preferredCustomerName || this.retailer?.name;
  }

  get categoryManagerFullName() {
    return getSupplierCategoryManagerFullName(this.supplier);
  }

  get developmentManagerFullName() {
    return getSupplierDevelopmentManagerFullName(this.supplier);
  }

  get isRetailerNotFound() {
    return this.supplier?.preferredCustomerId === Constants.OtherRetailerId || this.supplier?.preferredCustomerId === Constants.NewRetailerId;
  }

  get showContactCard() {
    return isInvitedDraftSupplier(this.supplier);
  }
  
  constructor(
    private _store: Store,
    ) {
    super();
  }

  public defaultIfEmpty(str: string) {
    return str ? str : '--';
  }

  ngOnInit() {
    combineLatest([
      this._store.pipe(select(selectSupplierDetail)),
      this._store.pipe(select(selectDocumentW9)),
      this._store.select(isGeneralTabNotSubmitted),
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([supplier, w9, isGeneralTabNotSubmitted]) => {
        this.supplier = supplier;
        this.w9Document = w9;
        this.shouldShowAAPField = shouldShowAAPFieldForGeneralTab(this.supplier, isGeneralTabNotSubmitted);
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(showOnBoardingGeneralTabStatusModal))
      .subscribe((val) => {
        this.showStatusChangeConfirmation = val;
      });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectRequestedRetailer))
      .subscribe((val) => {
        this.retailer = val;
      });
  }

  downloadW9() {
    this._store.dispatch(downloadDocument({ document: this.w9Document }));
  }

  isSupplierInDraft() {
    return isDraft(this.supplier);
  }

  isThisInvitedSupplier() {
    return isInvitedSupplier(this.supplier);
  }

  showSupplierRepresentative() {
    return isAddedDraftSupplier(this.supplier);
  }

  isInvitedByRetailer() {
    return this.supplier && this.supplier.requesterType === SupplierInviteRequester.Retailer;
  }

  requestedBy() {
    if (this.supplier && this.supplier.requesterType) {
      return this.supplier.requesterType === SupplierInviteRequester.CategoryManager ? 'Category Manager' : this.supplier.requesterType;
    }
    return null;
  }

  getAddressText() {
    return formattedAddress(this.supplier.headquarterAddressContact);
  }

  getContactText() {
    return formattedContact(this.supplier.headquarterAddressContact);
  }
}
