import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactCardComponent } from '../../components/contact-book/contact-card/contact-card.component';
import { ContactBookModalComponent } from '../../components/contact-book/contact-book-modal/contact-book-modal.component';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { PhoenixAlertsModule } from '@kehe/phoenix-notifications';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ReactiveFormsModule } from '@angular/forms';
import { PhoenixPhoneNumberModule } from '@kehe/phoenix-phone-number';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PhoenixAddressModule } from '@kehe/phoenix-address';
import { ContactFormComponent } from '../../components/contact-book/contact-form/contact-form.component';
import { PickupAddressNotesModalComponent } from './components/pickup-address-notes-modal/pickup-address-notes.modal.component';
import { ProductGroupPoTransmissionsModalComponent } from './components/product-group-po-transmissions-modal/product-group-po-transmissions-modal.component';
import { GridModule } from '@progress/kendo-angular-grid';

@NgModule({
  declarations: [
    ContactCardComponent,
    ContactBookModalComponent,
    ContactFormComponent,
    PickupAddressNotesModalComponent,
    ProductGroupPoTransmissionsModalComponent,
  ],
  imports: [
    CommonModule,
    ButtonsModule,
    PhoenixAddressModule,
    PhoenixButtonModule,
    PhoenixModalModule,
    PhoenixAlertsModule,
    DropDownsModule,
    InputsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    PhoenixPhoneNumberModule,
    LabelModule,
    GridModule,
  ],
  exports: [
    ContactCardComponent,
    ContactBookModalComponent,
    ContactFormComponent,
    PickupAddressNotesModalComponent,
    ProductGroupPoTransmissionsModalComponent,
  ]
})
export class SupplierComponentsModule { }
