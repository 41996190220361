import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-dollar-percent-toggle',
  templateUrl: './dollar-percent-toggle.component.html',
  styleUrls: ['./dollar-percent-toggle.component.scss']
})
export class DollarPercentToggleComponent implements OnInit {

  public dollarsSelected: boolean;

  @Output()
  toggleUnitOfMeasure: EventEmitter<{dollarsSelected: boolean}> = new EventEmitter<{dollarsSelected: boolean}>();

  constructor() { 
    // This is intentional
    
  }

  ngOnInit() {
    this.dollarsSelected = true;
  }

  dollarsClicked() {
    if (!this.dollarsSelected) {
      this.dollarsSelected = true;
      this.toggleUnitOfMeasure.emit({dollarsSelected: this.dollarsSelected});
    }
  }

  percentClicked() {
    if (this.dollarsSelected) {
      this.dollarsSelected = false;
      this.toggleUnitOfMeasure.emit({dollarsSelected: this.dollarsSelected});
    }
  }

}
