export function filterCategoryManagerItems(items, isNewProduct) {
    // Filter out unassigned (00) option from category manager items for new product
    return isNewProduct ? items.filter(item => item.code !== '00') : items;
}

export function isNullOrUndefined(value) {
    return value === null || value === undefined;
}


export function hasValidValueString(value) {
    return value !== null && value !== undefined && value.trim().length > 0;
}
