import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Destroyable } from '../../../../abstract/destroyable';
import {
  supplierHistoryApplyFilters,
  supplierHistoryFilterClosePanel,
  supplierHistoryLoadFiltersData
} from '../../store/supplier-detail.actions';
import {
  returnDocumentEventsPayload,
  SupplierHistoryFilter
} from '../../store/supplier-detail.reducer';
import {
  selectSupplierHistoryDisplayFilterPanel,
  selectSupplierHistoryFilterEditedBy,
  selectSupplierHistoryFilters
} from '../../store/supplier-detail.selectors';
import * as DistributionCenterSelectors from '@app/shared/state//distribution-center/distribution-center.selectors';
import { groupBy } from '@progress/kendo-data-query';
import { SupplierHistoryFilterFields } from '../../utilities/history-fields-values';
import { selectDocumentTypes } from '../../../supplier-documents/store/supplier-documents.selectors';
import { Constants } from '../../../../constants/constants';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-history-supplier-filter-panel',
  templateUrl: './history-supplier-filter-panel.component.html',
  styleUrls: ['./history-supplier-filter-panel.component.scss']
})
export class HistorySupplierFilterPanelComponent extends Destroyable implements OnInit {
  search$ = new Subject<string>();
  filterFields$ = this.search$.asObservable().pipe(
    map((search) => {
      if (search) {
        return groupBy(
          SupplierHistoryFilterFields.filter((t) => t.code.toLocaleLowerCase().includes(search)),
          [{ field: 'group' }]
        );
      }
      return groupBy(SupplierHistoryFilterFields, [{ field: 'group' }]);
    })
  );
  documentTypes$ = this.search$.asObservable().pipe(
    withLatestFrom(
      this._store.select(selectDocumentTypes)
    ),
    map(([search, types]) => {
      types = types
      .filter((t) => !t.isDocuSign && t.id !== Constants.LetterOfFinancialResponsibilityTypeId)
      .map((t: any) => {
        if (t.id === Constants.PurchasingInfoDocumentTypeId) {
          t.required = true;
        }
        t.category = t.required ? 'Required' : 'Optional';
        return t;
      });

      if (search) {
        return groupBy(types.filter((t) => t.name.toLocaleLowerCase().includes(search)), [{ field: 'category' }]).reverse();
      }
      return groupBy(types, [{ field: 'category' }]).reverse();
    })
  );
  displayFilterPanel$ = this._store.select(selectSupplierHistoryDisplayFilterPanel).pipe(
    map((value) => value ? 'show' : '')
  );
  filterEvents = returnDocumentEventsPayload().data;
  filterEditedBy$ = this._store.select(selectSupplierHistoryFilterEditedBy);
  filterDCs$ = this._store.select(DistributionCenterSelectors.selectDistributionCenterList).pipe(
    map((value) => value ? this.mapDcToFilterItem(value) : [])
  );
  filter$ = this._store.select(selectSupplierHistoryFilters);

  form = this._builder.group({
    selectedEvent: [],
    selectedField: [],
    selectedEditedBy: [],
    selectedDC: [],
    selectedDocument: [],
    createdFrom: [],
    createdTo: [],
  });

  constructor(
    private readonly _store: Store,
    private readonly _builder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this._store.dispatch(supplierHistoryLoadFiltersData());
    this.filter$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(value => {
      this.form.patchValue(value);
    });

    this.initFieldToggling();
  }

  closePanel(filter: SupplierHistoryFilter) {
    this.form.reset(filter);
    this._store.dispatch(supplierHistoryFilterClosePanel());
  }

  clearAll() {
    this.form.reset();
  }

  applyFilter() {
    const filter = this.form.getRawValue();
    this._store.dispatch(supplierHistoryApplyFilters({ filters: filter }));
  }

  onFilterChange(value: string) {
    this.search$.next(value.toLocaleLowerCase().trim());
  }

  onFocus(element: any): void {
    element.toggle(true);
    this.search$.next();
  }

  private mapDcToFilterItem(dcList: any[]) {
    return dcList.map(item => ({
      code: item.name.replace('Kehe Dist - ', ''),
      value: item.number,
      codeValue: item.name.replace('Kehe Dist - ', '')
    }));
  }

  private initFieldToggling() {
    this.form.get('selectedField').valueChanges.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(value => {
      if (value) {
        this.form.get('selectedDocument').disable({ emitEvent: false });
      }
      else {
        this.form.get('selectedDocument').enable({ emitEvent: false });
      }
    });

    this.form.get('selectedDocument').valueChanges.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(value => {
      if (value) {
        this.form.get('selectedField').disable({ emitEvent: false });
      }
      else {
        this.form.get('selectedField').enable({ emitEvent: false });
      }
    })
  }
}
