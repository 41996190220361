import { Component, OnInit } from '@angular/core';
import { SupplierDetail } from '../../models/supplier-detail';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { BrokerDetail } from '../../models/broker-detail';
import { Document } from '../../../supplier-documents/models/document';
import { Destroyable } from '../../../../abstract/destroyable';
import { selectSupplierBroker, selectSupplierDetail } from '../../store/supplier-detail.selectors';
import { selectBrokerDocument } from '../../../supplier-documents/store/supplier-documents.selectors';

@Component({
  selector: 'app-broker-view',
  templateUrl: './broker-view.component.html',
  styleUrls: ['./broker-view.component.scss']
})
export class BrokerViewComponent extends Destroyable implements OnInit {

  supplier: SupplierDetail;
  broker: BrokerDetail;
  brokerDocument: Document;

  get brokerName() {
    if (this.broker) {
      return `${this.broker.name} (${this.broker.esn})`;
    }
    return null;
  }

  get brokerContact() {
    if (this.supplier && this.supplier.broker && this.supplier.broker.contact) {
      return `${this.supplier.broker.contact.name} (${this.supplier.broker.contact.email})`;
    }
    return null;
  }

  get knowsHeadquarterBrokerContact() {
    return this.supplier && this.supplier.knowsHeadquarterBrokerContact;
  }

  get contactNotSpecified() {
    return this.knowsHeadquarterBrokerContact === true && this.supplier && this.supplier.broker && !this.supplier.broker.contact;
  }

  get canShowBrokerContact() {
    return this.supplier && this.supplier.hasHeadquarterBroker && !this.supplier.isHeadquarterBrokerNotFound;
  }

  hasHeadquarterBroker() {
    if (this.supplier) {
      if (this.supplier.hasHeadquarterBroker === false) {
        return 'No';
      }
      if (this.supplier.hasHeadquarterBroker === true) {
        return 'Yes';
      }
    }
    return '--';
  }

  isHeadquarterBrokerNotFound() {
    return this.supplier && this.supplier.hasHeadquarterBroker && this.supplier.isHeadquarterBrokerNotFound;
  }


  constructor(
    private _store: Store,
    ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this._store.pipe(select(selectSupplierDetail)),
      this._store.pipe(select(selectSupplierBroker)),
      this._store.pipe(select(selectBrokerDocument)),
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([supplier, broker, brokerDocument]) => {
        this.supplier = supplier;
        this.broker = broker;
        this.brokerDocument = brokerDocument;
      });
  }
}
