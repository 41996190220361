import { Injectable } from '@angular/core';
import { AbstractApiStore } from '@kehe/phoenix-component-store-utils';
import { DocumentService } from '@app/services/document.service';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';

import { ApiFailureText } from '@app/enum/alert-text.enum';
import { PresignedUrlResponse } from '@app/models/presigned-url-response';
import { DocumentCreateDto } from '@app/models/document';
import { HttpErrorResponse } from '@angular/common/http';
import { IFileUpload } from '@kehe/phoenix-upload';

@Injectable()
export class CreateDocumentApiStore extends AbstractApiStore<string> {

  constructor(private _documentService: DocumentService) {
    super();
  }

  save$ = this.effect((formGroup: Observable<[IFileUpload, DocumentCreateDto]>) =>
    formGroup.pipe(
      tap(() => {
        this.setData(undefined);
        this.startLoading();
      }),
      switchMap(([file, doc]) => {
        return this.postFile(file?.rawFile, doc).pipe(
          switchMap(
            (_) => {
              return this._documentService.post(doc).pipe(
                tapResponse(
                  (_) => {
                    this.setData('Success');
                  },
                  (_: HttpErrorResponse) => {
                    this.setError(ApiFailureText.Generic);
                  }
                )
              );
            },
            (_: HttpErrorResponse) => {
              this.setError(ApiFailureText.Generic);
            }
          )
        );
      })
    )
  );

  postFile(file: File, doc: DocumentCreateDto): Observable<object | null> {
    if (file && file.name) {
      return this._documentService
        .getPresignedUrl(doc.title, doc.documentType, file).pipe(
          switchMap((presignedUrlResponse: PresignedUrlResponse) => {
            const fileUrl = presignedUrlResponse.preSignedUrl.substr(0, presignedUrlResponse.preSignedUrl.indexOf('?'));
            doc.fileUrl = fileUrl;
            return this._documentService.uploadFile(file, presignedUrlResponse);
          })
        );
    } else {
      return of(null);
    }
  }
}
