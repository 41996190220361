<div class="row">
  <div class="col">
    <h4>DC Payment Holds</h4>
  </div>
</div>

@if (isEdit$ | async) {
  <app-dc-payment-hold-form></app-dc-payment-hold-form>
}
@else {
  <app-dc-payment-hold-grid></app-dc-payment-hold-grid>
}
