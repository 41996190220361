import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PersonContact } from '../models/person-contact';
import { SupplierContactsState } from './supplier-contacts.reducer';
import { getRequiredContacts } from '../utils/supplier-contacts-utils';
import { selectIsInvitedDraftSupplier, selectIsSupplierOnboardingV4FFOn } from '../../supplier-detail/store/supplier-detail.selectors';

export const getSupplierContactsState = createFeatureSelector<SupplierContactsState>(
  'supplierContacts'
);

export const selectESN = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.esn
);

export const selectSupplierContactsLoading = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.isLoading
);

export const selectSupplierContacts = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.contacts
);

// << Manage Contact
export const selectShowManageContactModal = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.manageContact !== null
);

export const selectManageContact = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.manageContact
);

export const selectIsSavingContact = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.isSavingContact
);

export const selectSaveContactError = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.saveContactError
);
// Manage Contact >>

// Delete Contact
export const selectShowDeleteContactModal = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.deleteContact !== null
);

export const selectDeleteContact = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.deleteContact
);

// Assign Primary Contact
export const selectShowAssignPrimaryContactModal = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.primaryContact !== null
);
export const selectAssignPrimaryContact = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.primaryContact
);

// Assign Primary Contact
export const selectShowNotifyDiscProductsModal = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.notifyDiscProductsContact !== null
);
export const selectNotifyDiscProductsContact = createSelector(
  getSupplierContactsState,
  (state: SupplierContactsState) => state.notifyDiscProductsContact
);
export const selectContactsTabValidated = createSelector(
  getSupplierContactsState,
  selectIsSupplierOnboardingV4FFOn,
  selectIsInvitedDraftSupplier,
  (state: SupplierContactsState, supplierOnboardingV4Flag: boolean, isInvitedDraftSupplier: boolean) => validateContactTab(state.contacts, supplierOnboardingV4Flag, isInvitedDraftSupplier)
);

// Utils functions
function validateContactTab(constants: PersonContact[], supplierOnboardingV4Flag: boolean, isInvitedDraftSupplier: boolean) {
  const hasPrimary = constants.some(item => item.isPrimary);
  if (!hasPrimary) {
    return false;
  }
  const hasNotifyDisc = constants.some(item => item.subscribedToDiscontinuedProductNotification);
  if (!hasNotifyDisc) {
    return false;
  }
  for (const item of getRequiredContacts(supplierOnboardingV4Flag, isInvitedDraftSupplier)) {
    const found = constants.find(contact => contact.contactTypes.includes(item.type));
    if (found === null || found === undefined) {
      return false;
    }
  }
  return true;
}

