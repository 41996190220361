import { createFeature, createReducer, on } from '@ngrx/store';
import { BreakpointStoreActions } from './breakpoint.actions';

export interface State {
  isXtraSmallView: boolean;
  isSmallView: boolean;
  isMediumView: boolean;
  isLargeView: boolean;
  isXtraLargeView: boolean;
  isXtraXtraLargeView: boolean;
  isSmallAndBelow: boolean;
  isMedAndBelow: boolean;
  isMedAndUp: boolean;
  isLargeAndUp: boolean;
}

export const initialState: State = {
  isXtraSmallView: false,
  isSmallView: false,
  isMediumView: false,
  isLargeView: false,
  isXtraLargeView: false,
  isXtraXtraLargeView: false,
  isSmallAndBelow: false,
  isMedAndBelow: false,
  isMedAndUp: false,
  isLargeAndUp: false,
};

export const feature = createFeature({
  name: 'breakpoint',
  reducer: createReducer(
    initialState,
    on(
      BreakpointStoreActions.xtraSmallViewChanged,
      (state, { isXtraSmallView }) => ({
        ...state,
        isXtraSmallView,
      })
    ),
    on(BreakpointStoreActions.smallViewChanged, (state, { isSmallView }) => ({
      ...state,
      isSmallView,
    })),
    on(BreakpointStoreActions.mediumViewChanged, (state, { isMediumView }) => ({
      ...state,
      isMediumView,
    })),
    on(BreakpointStoreActions.largeViewChanged, (state, { isLargeView }) => ({
      ...state,
      isLargeView,
    })),
    on(
      BreakpointStoreActions.xtraLargeViewChanged,
      (state, { isXtraLargeView }) => ({
        ...state,
        isXtraLargeView,
      })
    ),
    on(
      BreakpointStoreActions.xtraXtraLargeViewChanged,
      (state, { isXtraXtraLargeView }) => ({
        ...state,
        isXtraXtraLargeView,
      })
    ),
    on(
      BreakpointStoreActions.smallAndBelowChanged,
      (state, { isSmallAndBelow }) => ({
        ...state,
        isSmallAndBelow,
      })
    ),
    on(
      BreakpointStoreActions.medAndBelowChanged,
      (state, { isMedAndBelow }) => ({
        ...state,
        isMedAndBelow,
      })
    ),
    on(BreakpointStoreActions.medAndUpChanged, (state, { isMedAndUp }) => ({
      ...state,
      isMedAndUp,
    })),
    on(BreakpointStoreActions.largeAndUpChanged, (state, { isLargeAndUp }) => ({
      ...state,
      isLargeAndUp,
    }))
  ),
});

export const {
  name,
  reducer,
  selectIsXtraSmallView,
  selectIsSmallView,
  selectIsMediumView,
  selectIsLargeView,
  selectIsXtraLargeView,
  selectIsXtraXtraLargeView,
  selectIsSmallAndBelow,
  selectIsMedAndBelow,
  selectIsMedAndUp,
  selectIsLargeAndUp,
} = feature;

export * as breakpoint from './breakpoint.reducer';
