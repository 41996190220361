<div *ngIf="!isFormReady">
  <kehe-phoenix-loading></kehe-phoenix-loading>
</div>

<!-- we use the hidden class since we need to reference the child components in the parent logic -->
<div [hidden]="!isFormReady">
  <div class="form-container">
    <div [formGroup]="form">
      <div class="row">
        <div class="col">
          <h4>{{dcProductGroup?.dcDisplayName}} {{dcProductGroup?.dcProductGroupNumber}}</h4>
        </div>
      </div>
      <hr class="mt-0">
      <div *ngIf="canUserEditBuyer">
        <div class="row">
          <div class="col">
            <h5>Buyers</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div>
              <kendo-formfield>
                <kendo-label [for]="buyerField" text="Buyer"></kendo-label>
                <kendo-combobox
                  #buyerField
                  (focus)="onFocus(buyerField)"
                  formControlName="buyerCode"
                  (valueChange)="buyerValueChange($event)"
                  [data]="buyerList"
                  [textField]="'name'"
                  [valueField]="'code'"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  [kendoDropDownFilter]="{operator: 'contains'}"
                  placeholder="Search Buyer">
                </kendo-combobox>
                <kendo-formerror *ngIf="buyerCode?.errors?.required">Buyer is required.</kendo-formerror>
              </kendo-formfield>
            </div>
            <div class="mt-3" *ngIf="canShowBuyerRegionEmailFlag">

              <kendo-formfield>
                <kendo-label text="Buyer Email"></kendo-label>
                <kendo-textbox formControlName="buyerEmail" placeholder="e.g. contact@email.com">
                </kendo-textbox>
                <kendo-formerror *ngIf="buyerEmailField?.errors?.required">
                  Buyer Email is required.
                </kendo-formerror>
                <kendo-formerror *ngIf="!buyerEmailField?.errors?.email && buyerEmailField?.errors?.isInvalid">
                  Must be a valid KeHE email.
                </kendo-formerror>
                <kendo-formerror *ngIf="buyerEmailField?.errors?.email">
                  Invalid email
                </kendo-formerror>
              </kendo-formfield>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="canShowBuyerRegionEmailFlag">
            <div class="mt-3 mt-md-0">
              <kendo-formfield>
                <kendo-label text="Buyer Region"></kendo-label>
                <kendo-combobox #buyerSelectionField (focus)="onFocus(buyerSelectionField)" formControlName="buyerRegion" [data]="buyerRegions" required
                                [filterable]="true" placeholder="Select Buyer Region" [kendoDropDownFilter]="{ operator: 'contains'}">
                </kendo-combobox>
                <kendo-formerror *ngIf="buyerRegionField?.errors?.required">Buyer Region is required.</kendo-formerror>
              </kendo-formfield>
            </div>
          </div>

        </div>
        <hr>
      </div>
    </div>

    <app-product-group-delivery-form #deliveryGroup [address]="tempAddress"
      [supplier]="supplier"
      [action]="actionTypes.EditDCPG"
      [showContactCard]="showContactCard"
      (pickupAddressSelected)="pickupAddressSelected($event)">
    </app-product-group-delivery-form>

    <!-- since we need to be able to reference the component in the component parent logic we have to use the hidden class -->
    <div [hidden]="!canUserEditPoTransmission">
      <div class="row">
        <div class="col">
          <h5>PO Transmissions and Details</h5>
        </div>
      </div>
      <app-product-group-po-transmissions-form [hasPOOrder]="isDraftSupplier"
        #poTransmissionsGroup>
      </app-product-group-po-transmissions-form>
    </div>
  </div>
</div>
