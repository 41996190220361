export enum EndpointPaths {

    RetailerHierarchy = 'retailer-hierarchy',
    Retailer = 'retailer-hierarchy/retailer',
    Area = 'retailer-hierarchy/area',
    Organization = 'retailer-hierarchy/organization',

    SalesBusinessHierarchy = 'sales-hierarchy',
    Account = 'sales-hierarchy/account',
    Region = 'sales-hierarchy/region',
    DivisionGroup = 'sales-hierarchy/division-group',
    BusinessGroup = 'sales-hierarchy/business-group',

    InfraHierarchy = 'sales-hierarchy/infra',
    InfraDirector = 'sales-hierarchy/infra-director',
    InfraVicePresident = 'sales-hierarchy/infra-vice-president',
    CustomerSubtype = 'sales-hierarchy/customer-subtype',

    PhysicalLocationHierarchy = 'physical-location-hierarchy',

    FreshHierarchy = 'sales-hierarchy/fresh',
}
