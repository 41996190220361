import { createAction, props } from '@ngrx/store';
import { UserNotificationRecord } from '../model/service/user-notifications';
import { Payload } from '../../../models/payload';
import { HttpErrorResponse } from '@angular/common/http';

export const getUserNotificationsAction = createAction(
  '[User Notifications] Get User Notifications',
);

export const getUserNotificationsSuccessAction = createAction(
  '[User Notifications] Get User Notifications Success',
  props<{ response: Payload<UserNotificationRecord[]> }>()
);

export const clearUserNotificationsAction = createAction(
  '[User Notifications] Clear User Notifications',
  props<{ notificationIds: number[] }>()
);

export const clearUserNotificationsSuccessAction = createAction(
  '[User Notifications] Clear User Notifications Success',
  props<{ notificationIds: number[] }>()
);

export const userNotificationsErrorAction = createAction(
  '[User Notifications] Clear User Notifications Error',
  props<{ err: HttpErrorResponse }>()
);
