import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectNavigationItems } from './om-navigation.selectors';
import { Destroyable } from '../../abstract/destroyable';
import * as ExportHierarchiesModalActions from '../../modules/utilities/hierarchies/export-hierarchies/store/effects/actions';

@Component({
  selector: 'app-om-navigation',
  templateUrl: './om-navigation.component.html',
  styleUrls: ['./om-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class OmNavigationComponent extends Destroyable
{
  items$ = this._store.select(selectNavigationItems);

  constructor(private _store: Store) {
    super();
  }

  subItemClickEventHandler($event) {
    if ($event.clickEventName === 'exportHierarchyClicked') {
      this._store.dispatch(ExportHierarchiesModalActions.openClicked());
    }
  }
}
