<ng-container *ngIf="vm$ | async as vm">
  <kehe-phoenix-manage-list-page-title-layout pageTitle="Documents">
    <ng-template #actionsBlock>
      <kehe-phoenix-button (click)="addDocument()" classAttr="btn-primary btn-medium btn-block">Add
        Document</kehe-phoenix-button>
    </ng-template>
  </kehe-phoenix-manage-list-page-title-layout>
  <div class="page-header-controls">
    <kehe-phoenix-search placeholder="Search Documents" [formControl]="searchControl">
    </kehe-phoenix-search>
  </div>

  <div class="kehe-grid no-scroll p-0 w-100" [class.kehe-grid-responsive]="isMedAndBelow$ | async">

    <kendo-grid [data]="vm.gridData" [pageable]="false" [sortable]="false" [hideHeader]="isMedAndBelow$ | async">

      <kendo-grid-column [sortable]="false" [width]="42" class="overflow-column" [headerClass]="'overflow-column'">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div class="d-flex justify-content-center">
            <kehe-phoenix-menu-button [menuOptions]="dataItem.actionOptions" btnClass="btn-link"
              (optionSelected)="handleEllipsisOption($event, dataItem)"></kehe-phoenix-menu-button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="title" [title]="'Title'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a (click)="viewDocument(dataItem)" class="link">{{dataItem.title}}</a>
          <ng-container *ngIf="(isMedAndBelow$ | async) === true">
            <!-- show publication type on med and below -->
            <br>
            <span class="font-size-11">{{dataItem.documentType}}</span>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="(isMedAndBelow$ | async) === false" field="documentType" [title]="'Publication Type'"
        [width]="200" [class]="'document-grid-item-publication-type'">
      </kendo-grid-column>

      <kendo-grid-column *ngIf="(isMedAndBelow$ | async) === false" field="fileExtension" [title]="'File Type'"
        [width]="75">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem.fileSize ? dataItem.fileExtension : 'URL'}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="(isMedAndBelow$ | async) === false" field="distributionCenterNumber" [title]="'DC(s)'"
        [width]="60">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem.distributionCenterNumber || '--'}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [title]="'Live Date'" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.startDate | date: 'MM/dd/yyyy '}} -
          {{ dataItem?.expireDate | date: 'MM/dd/yyyy '}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="(isSmallAndBelow$ | async) === false" field="lastUpdated" [title]="'Modified'"
        [width]="(isMedAndBelow$ | async) === true ? 50 : 125">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.lastUpdated | date: 'MM/dd/yyyy ' | emptyValue}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="(isMedAndBelow$ | async) === false" field="fileSize" [title]="'File Size'" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [innerHtml]="dataItem?.fileSizeReadable">
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="(isMedAndBelow$ | async) === false" title="" [width]="42" class="text-center">
        <ng-template kendoGridCellTemplate let-dataItem>
          <em title="Delete" class="fas fa-trash-alt kehe-action-icon-destructive"
            (click)="handleTrashCanClick(dataItem)"></em>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridNoRecordsTemplate>

        <ng-container *ngIf="!vm.isLoadListError">
          <div *ngIf="!vm.isLoading" class="custom-no-results-msg">
            <em class="fas fa-seedling" aria-hidden="true"></em>
            <div class="custom-msg-container">
              <span class="no-results">No Results Found</span>
            </div>
          </div>
          <div *ngIf="vm.isLoading" class="custom-no-results-msg">
            <kehe-phoenix-loading></kehe-phoenix-loading>
          </div>
        </ng-container>

      </ng-template>

    </kendo-grid>

    <kendo-datapager *ngIf="!vm.isLoading && !vm.isLoadListError" [pageSize]="vm.pageSize" [skip]="vm.skip"
      [total]="vm.availableCount" [buttonCount]="4" [pageSizeValues]="[25, 50, 100, 250]"
      (pageChange)="onPageChange($event)">
    </kendo-datapager>

    <kehe-phoenix-error-panel *ngIf="vm.isLoadListError" [config]="vm.emptyStateConfig">
      <ng-container body>
        <p>Please try again at a later time.</p>
        <kehe-phoenix-button (click)="refresh()" classAttr="btn-medium btn-primary">Refresh</kehe-phoenix-button>
      </ng-container>
    </kehe-phoenix-error-panel>
  </div>

  <kehe-phoenix-delete-modal *ngIf="vm.showDeleteConfirmation" name="deleteDoc" modalTitle="Delete Document"
    [isDeleteInProgress]="vm.isDeleteInProgress" (modalEvent)="handleConfirmDeleteDocumentDlgClose($event)"
    (cancelClicked)="handleConfirmDeleteDocumentDlgClose($event)"
    (deleteClicked)="handleConfirmDeleteDocumentDlgSubmitted($event)">
    <p>Are you sure you want to delete this file?</p>
    <p>
      <strong>{{
        vm.documentToDelete?.title + vm.documentToDelete?.fileExtension
        }}</strong>
    </p>
  </kehe-phoenix-delete-modal>

</ng-container>

<!-- Edit/View Modal -->
<app-document-view-edit-modal></app-document-view-edit-modal>

<!-- Document Create modal -->
<app-document-create-modal></app-document-create-modal>