import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import * as ProductGroupsActions from './product-groups.actions';
import { of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ProductDetailService } from '../../product-detail/services/product-detail.service';
import {
  getProductGroupsFilter,
  productGroupsSearch,
  selectCommittedCloneDCPG,
} from './product-groups.selectors';
import * as DistributionCenterSelectors from '@app/shared/state/distribution-center/distribution-center.selectors';
import * as SupplierDetailActions from '../../supplier-detail/store/supplier-detail.actions';
import { selectSupplierDetail } from '../../supplier-detail/store/supplier-detail.selectors';
import { SupplierDetailService } from '../../supplier-detail/services/supplier-detail.service';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { cleanupProductGroupPayload } from '../utils/clone-effects-util';

@Injectable()
export class ProductGroupsEffects {
  constructor(
    private _actions$: Actions,
    private _productDetailService: ProductDetailService,
    private readonly _supplierDetailService: SupplierDetailService,
    private readonly _store: Store,
    private readonly _toastService: PhoenixToastService
  ) {}

  // Products Data
  loadProductGroups$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductGroupsActions.loadProductsData),
      switchMap((action) => {
        return this._productDetailService
          .getSlimProductDetails(action.esn)
          .pipe(
            map((products) =>
              ProductGroupsActions.loadProductsDataSuccess({ products })
            ),
            catchError(() => of(ProductGroupsActions.loadProductsDataError()))
          );
      })
    )
  );
  updateDeliveryMethodInDCProductGroups$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ProductGroupsActions.loadProductsDataSuccess,
        ProductGroupsActions.setProductGroups
      ),
      map(() => ProductGroupsActions.prepareProductsData())
    )
  );
  // Products Data

  // Product Group Search & Filter
  triggerProductGroupsRefresh$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ProductGroupsActions.productGroupsSearchChanged,
        ProductGroupsActions.productGroupsSearchCleared,
        ProductGroupsActions.productGroupsChipClicked,
        ProductGroupsActions.productGroupsFilterClearAll,
        ProductGroupsActions.productGroupsFilterChanged
      ),
      withLatestFrom(
        this._store.pipe(select(getProductGroupsFilter)),
        this._store.pipe(select(productGroupsSearch))
      ),
      map(([, filter, search]) =>
        ProductGroupsActions.filterProductGroups({
          filter,
          search,
        })
      )
    )
  );
  // Product Group Search & Filter
  // Clone DCPG
  cancelCloneDCPG$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierDetailActions.confirmCancelEdit),
      map(() => ProductGroupsActions.cloneDCProductGroupCancelled())
    )
  );

  saveClonedDCPG$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductGroupsActions.cloneDCPGSave),
      withLatestFrom(
        this._store.select(selectCommittedCloneDCPG),
        this._store.select(selectSupplierDetail)
      ),
      switchMap(([, productGroup, supplier]) => {
        const payload = {
          esn: supplier.esn,
          productGroups: [cleanupProductGroupPayload(productGroup)],
          updatedBySource: 'Enterprise',
        };
        const message = `DC Product Group(s) cloned successfully!`;
        return this._supplierDetailService.save(payload).pipe(
          map((supplier) => {
            return ProductGroupsActions.cloneDCPGSaveSuccess({
              supplier,
              message,
            });
          }),
          catchError((error) =>
            of(
              ProductGroupsActions.cloneDCPGSaveFailed({
                error: error as string,
              })
            )
          )
        );
      })
    )
  );

  saveCloneSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductGroupsActions.cloneDCPGSaveSuccess),
      map(() => SupplierDetailActions.confirmCancelEdit())
    )
  );
  saveCloneUpdateSupplier$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ProductGroupsActions.cloneDCPGSaveSuccess,
        ProductGroupsActions.applyDCPGBulkEditSuccess
      ),
      withLatestFrom(this._store.select(DistributionCenterSelectors.selectDistributionCentersTrimmedNameAndNumber)),
      map(([supplier, dcList]) => {
        return SupplierDetailActions.loadSupplierDetailSuccess({
          supplier: supplier.supplier,
          dcList: dcList,
        });
      })
    )
  );
  saveSuccessToast$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          ProductGroupsActions.cloneDCPGSaveSuccess,
          ProductGroupsActions.applyDCPGBulkEditSuccess
        ),
        map(({ message }) => this._toastService.showSuccessToast(message))
      ),
    { dispatch: false }
  );
  // Clone DCPG
  // Bulk Edit DCPGS
  applyDCPGBulkEdit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductGroupsActions.applyDCPGBulkEdit),
      withLatestFrom(this._store.select(selectSupplierDetail)),
      switchMap(([{ productGroup, auditNotes }, supplier]) => {
        const payload = {
          esn: supplier.esn,
          auditNotes,
          productGroups: [productGroup],
          updatedBySource: 'Enterprise',
        };

        return this._supplierDetailService.save(payload).pipe(
          map((response) => {
            const edited = productGroup.dcProductGroups.length;
            const message = `${edited} of ${edited} DC Product Group(s) updated successfully!`;
            return ProductGroupsActions.applyDCPGBulkEditSuccess({
              supplier: response,
              message,
            });
          }),
          catchError(() =>
            of(
              ProductGroupsActions.applyDCPGBulkEditFailed({
                error: 'Something went wrong. Please try again.',
              })
            )
          )
        );
      })
    )
  );
}
