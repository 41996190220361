import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BrokerDetail } from '../../../../../broker-detail/models/broker-detail';
import { BrokerDetailService } from '../../../../../broker-detail/services/broker-detail.service';
import { Store } from '@ngrx/store';
import { selectSupplierBrokerEsn } from '../../../../store/supplier-detail.selectors';
import { ApiFailureText } from '../../../../../../enum/alert-text.enum';
import { AbstractApiStore } from '@kehe/phoenix-component-store-utils';

/**
 * `HeadquarterBrokerApiStore` is an Angular service that extends `AbstractApiStore` to get the supplier headquarter broker.
 *
 * @class
 * @extends AbstractApiStore
 *
 * @example
 * const headquarterBrokerApiStore = new HeadquarterBrokerApiStore(brokerDetailService, store);
 *
 * @param {BrokerDetailService} _brokerDetailService - A service that provides broker detail data.
 * @param {Store} _store - A redux store that holds the application state.
 */
@Injectable({ providedIn: 'root' })
export class HeadquarterBrokerApiStore extends AbstractApiStore<BrokerDetail> {
  constructor(
    private _brokerDetailService: BrokerDetailService,
    private _store: Store
  ) {
    super();
    this.loadSupplierBrokerDetail$(this._store.select(selectSupplierBrokerEsn));
  }

  loadSupplierBrokerDetail$ = this.effect((brokerEsn$: Observable<string>) =>
    brokerEsn$.pipe(
      tap(() => this.startLoading()),
      switchMap((brokerEsn) => {
        if (!brokerEsn) {
          this.setData(undefined);
          return EMPTY;
        }

        return this._brokerDetailService.getBrokerDetail(brokerEsn).pipe(
          tapResponse(
            (broker) => {
              this.setData(broker);
            },
            () => {
              this.setError(ApiFailureText.Generic);
            }
          )
        );
      })
    )
  );
}
