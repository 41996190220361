import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactType } from '../models/contact';
import { AddressService } from '@kehe/phoenix-address';
import { ContactFormService } from '@kehe/phoenix-contact';
import { stringPatternValidator } from '../utils/form-utils';

@Injectable()
export class AddressBookFormService {

  constructor(
    private _addressService: AddressService,
    private _contactFormService: ContactFormService,
    private _formBuilder: UntypedFormBuilder,
  ) { }

  buildAddressBookForm(contactType?: ContactType) {
    return this._formBuilder.group({
      contactType: [contactType ? contactType : ContactType.PickupAddress, [Validators.required]],
      address: this.getAddressForm(),
      contact: this.getContactForm(),
      pickupAddressNotes: [''],
    });
  }

  getAddressForm(): UntypedFormGroup {
    const addressForm = this._addressService.createDefaultAddressFormGroup();
    addressForm.get('street2').disable();
    addressForm.get('state').setValidators([Validators.required]);
    addressForm.valueChanges.subscribe(val => {
      const isInternational = this._addressService.isInternational(val);
      if (isInternational) {
        addressForm.get('state').setValidators(null);
        addressForm.get('state').setErrors(null);
        addressForm.get('province').setValidators([Validators.required]);
      } else {
        addressForm.get('state').setValidators([Validators.required]);
        addressForm.get('province').setValidators(null);
        addressForm.get('province').setErrors(null);
      }
    });
    return addressForm;
  }

  getContactForm(): UntypedFormGroup {
    const contactForm = this._formBuilder.group({
      name: ['', [Validators.maxLength(100)]],
      phone: ['', [Validators.required]],
      phoneExtension: [
        '',
        [
          Validators.maxLength(4),
          stringPatternValidator(this._contactFormService.getPhoneExtRegex(), false),
        ],
      ],
      fax: [''],
      email: [
        '',
        [
          stringPatternValidator(this._contactFormService.getEmailRegex(), false),
          Validators.maxLength(100),
        ],
      ],
    });
    return contactForm;
  }
}
