import { createFeatureSelector, createSelector } from '@ngrx/store';
import { announcementsFeatureKey , AnnouncementsState } from './announcements.reducer';
import { IAnnouncementDetails } from '../../interfaces/announcement-details.inteface';
import { IDocumentUpload } from '../interfaces/upload-documents.interface';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { Constants } from '@app/constants/constants';

export const getAnnouncementsState = createFeatureSelector<AnnouncementsState>(
  announcementsFeatureKey
);

export const selectAnnouncementsData = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.data,
);

export const selectAnnouncementsLoading = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.loading,
);

export const selectAnnouncementsAudience = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.audience,
);

export const selectAnnouncementsStatus = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.status,
);

export const selectAnnouncementsSorting = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.sorting,
);

export const selectAnnouncementsSearch = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.search,
);

export const selectAnnouncementsPagination = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.pagination,
);

export const selectAnnouncementsTotal = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.total,
);

export const selectAddAnnouncementError = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.addAnnouncementError
);

export const selectAddAnnouncementLoading = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.addAnnouncementLoading
);

export const selectToastMessage = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState): string => state.toastMessage
);

export const selectAnnouncementDetails = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState): IAnnouncementDetails | null => state.announcementDetails
);

export const selectAnnouncementPublishing = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.isPublishing
);

export const selectAnnouncementPublishError = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.publishError
);

export const selectAnnouncementsShowDeleteConfirmation = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.showDeleteConfirmation,
);

export const selectAnnouncementsPendingDeleteData = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.pendingDeleteDetails,
);

export const selectAnnouncementsPendingDeleteLoading = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.pendingDeleteLoading,
);

export const selectAnnouncementsPendingDeleteError = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.pendingDeleteError,
);

export const selectAnnouncementsPendingDeleteConfirmed = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.pendingDeleteConfirmed,
);

export const selectUpdateAnnouncementError = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.updateAnnouncementError
);

export const selectUploadRestrictions = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.uploadRestrictions
);

export const selectUploadRestrictionsLoading = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.uploadRestrictionsLoading
);

export const selectShowUploadFilesModal = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.showUploadFilesModal
);

export const selectCreateDocumentsData = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.createDocumentsData
);

export const selectFileUploads = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.fileUploads
);

export const selectFileUploadsError = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.fileUploadsError
);

export const selectDocumentUploads = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.documentUploads
);

export const selectHasActiveUploads = createSelector(
  selectDocumentUploads,
  (documentUploads: IDocumentUpload[]) => !!documentUploads.length
);

export const selectCreateDocumentsLoading = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.createDocumentsLoading
);

export const selectCreateDocumentsError = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.createDocumentsError
);

export const selectAnnouncementsShowDeleteFileConfirmation = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.showDeleteFileConfirmation
);

export const selectAnnouncementsPendingDeleteFileData = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.pendingDeleteFileData
);

export const selectAnnouncementsPendingDeleteFileLoading = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.pendingDeleteFileLoading
);

export const selectAnnouncementsPendingDeleteFileError = createSelector(
  getAnnouncementsState,
  (state: AnnouncementsState) => state.pendingDeleteFileError
);

export const selectCanUserManageAnnouncements = createSelector(
  AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.EnterpriseSiteAccess),
  AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.EnterpriseAnnouncementWrite),
  AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.SupplierAnnouncementWrite),
  AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.RetailerAnnouncementWrite),
  (siteAccess, enterpriseWrite, supplierWrite, retailerWrite) => siteAccess && (enterpriseWrite || supplierWrite || retailerWrite)
);